import React from 'react'
import Header from '../../components/Header';
import StartInvestorSentimentsDetails from '../containers/investorSentimentsDetails';

const App = () => (
  <>
    <Header
      username={window.localStorage.getItem('username')}
      role={window.localStorage.getItem('role')}
    />
    <StartInvestorSentimentsDetails/>
    
  </>
)

export default App
import React, { Component } from "react";
import "./FinalPreview.scss";
import "react-phone-input-2/lib/style.css";
import Header from "../../../components/Header";
import HeaderImg from "../../../components/HeaderImage";
import LoadingSpinner from "../../../components/Loader";
import common from "../../../common";
import { withRouter } from "react-router-dom";
import ProfileImage from "../../../components/ProfileImage";
import LaddaButton, { XS, EXPAND_RIGHT } from "react-ladda";
import axios from "axios";
import swal from "sweetalert";
import images from "../../../images";
import _ from "lodash";
import TButton from "../../../components/TButton";
import ReactToPrint from "react-to-print";
import { Markup } from "interweave";
import "../Editor/editor.scss";

class FinalPreview extends Component {
  constructor(props) {
    super(props);
    this.companyId = "";
    this.investmentId = "";
    this.state = {
      companyOverview: null,
      investmentOverview: null,
      marketOverview: null,
      leadership: null,
      board: null,
      productDesc: null,
      differentiation: null,
      problemStatementDesc: null,
      solutionSummaryDesc: null,
      majorInvestor: null,
      fundingDesc: null,
      histAndProjRevenue: null,
      keyCustomers: null,
      currentPartners: null,
      iirList: null,
      totalEstimatedValue: "",
      fundingDescimgtext: null,
    };
  }
  componentWillMount() {
    const url = new URL(window.location.href);
    this.companyId = url.searchParams.get("companyId");
    this.investmentId = url.searchParams.get("investmentId");
    this.getIIRList();
    this.getData();
    this.getLeadershipData();
    this.getBoardData();
    this.getMajorInvestorData();
    this.getCurrentPartnersData();
    this.getKeyInvestorData();
    this.getPressData();
    this.getmarketOverviewData();
    this.getmarketTrendData();
    this.getmarketPrefData();
    this.getfundingDescData();
    this.getproductDescData();
    this.getproblemStatementDescData();
    this.getsolutionSummaryDescData();
    this.getDifferentiation();
    this.getcompetitionDescData();
    this.gethistAndProjRevenueData();
    this.getInvestmentOverviewData();
    this.getTotalMarketValue();
    const userRole = window.localStorage.getItem("role");
    this.setState({ Role: userRole });
  }

  getIIRList() {
    let temp = this;
    let token = window.localStorage.getItem("token");
    axios
      .get(`${common.path.base_url}/getInvestmentIIR/${this.investmentId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        temp.setState({ loading: false });
        const sampleArray = response.data.data;
        const keys = Object.keys(sampleArray);
        const values = Object.values(sampleArray);
        let z = [];
        keys.forEach((key, i) => {
          // z.push({ title: key, state: values[i] })
          z.push(common.iirMapKey({ key, values, i }));
        });
        z.length = common.iirArrayLength;
        z = z.filter((element) => element.state == true);
        this.setState({ iirList: sampleArray });
        // temp.props.history.push(`/iir/leadership?companyId=${this.companyId}`);
        temp.setState({ loadinglada: false });
      })
      .catch(function (error) {
        temp.setState({ loadinglada: false });
        console.log("error fetch", error.response);
        if (
          common.AUTH_EXPIRED === error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.code === 204
        ) {
          window.localStorage.clear();
          window.location.href = "/";
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }

  getData() {
    let temp = this;
    temp.setState({ loading: true });
    const id = window.localStorage.getItem("id");
    let token = window.localStorage.getItem("token");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getIIRCompany?companyId=${this.companyId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.length > 0
        ) {
          this.setState({
            companyOverview: response.data.data[0],
          });
        }
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getLeadershipData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getBoardorLeaderIIR/${this.investmentId}/Leadership`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({
          leadership: response.data.data,
        });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getBoardData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getBoardorLeaderIIR/${this.investmentId}/Board`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ board: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getMajorInvestorData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getCustomerorInvestorInvestment/${this.investmentId}/MajorInvestor`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        if (response && response.data && response.data.data) {
          this.setState({ majorInvestor: response.data.data });
        }
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getCurrentPartnersData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getCustomerorInvestorInvestment/${this.investmentId}/CurrentPartners`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        if (response && response.data && response.data.data) {
          this.setState({ currentPartners: response.data.data });
        }
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getKeyInvestorData() {
    let temp = this;
    let token = window.localStorage.getItem("token");
    temp.setState({ loading: true });
    axios
      .get(
        `${common.path.base_url}/getCustomerorInvestorInvestment/${this.investmentId}/KeyCustomers`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        if (response && response.data && response.data.data) {
          this.setState({ keyCustomers: response.data.data });
        }
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getPressData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getIIRPressInvestment/${this.investmentId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ PressData: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getmarketOverviewData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getMarketInvestment/${this.investmentId}/MarketSize`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        if (response.data.data.length) {
          this.setState({ marketOverview: response.data.data });
        }
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getmarketTrendData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getMarketInvestment/${this.investmentId}/MarketTrend`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ marketTrendData: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getmarketPrefData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getMarketInvestment/${this.investmentId}/MarketPerf`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ marketOverview: response.data.data });
        console.log("market", response.data.data);
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getfundingDescData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getMarketInvestment/${this.investmentId}/FundingDesc`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        if (
          response.data &&
          response.data.data &&
          response.data.data.length > 0 &&
          response.data.data[0].data &&
          response.data.data[0].data.length > 0
        ) {
          temp.setState({ fundingDescimgtext: response.data.data[0].data });
          let responselen = response.data.data[0].data;
          let respData = response.data.data[0].data[responselen.length - 1].fundingDesc;
          if (respData !== undefined) {
            this.setState({ fundingDesc: respData });
          }
        }
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getproductDescData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getMarketInvestment/${this.investmentId}/ProductDesc`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ productDesc: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getproblemStatementDescData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getMarketInvestment/${this.investmentId}/ProblemStatementDesc`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ problemStatementDesc: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getsolutionSummaryDescData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getMarketInvestment/${this.investmentId}/SolutionSummaryDesc`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ solutionSummaryDesc: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }

  getDifferentiation() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getMarketInvestment/${this.investmentId}/Differentiation`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ differentiation: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getcompetitionDescData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getMarketInvestment/${this.investmentId}/CompetitionDesc`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ competitionDescData: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  gethistAndProjRevenueData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getMarketInvestment/${this.investmentId}/HistAndProjRevenue`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ histAndProjRevenue: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getInvestmentOverviewData() {
    let temp = this;
    // temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getIIRInvestmentOverview/${this.investmentId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        // this.setState({ investmentOverviewData: response.data.data });
        if (response.data.data && response.data.data.length > 0) {
          temp.setState({ loading: false });
          temp.setState({ investmentOverview: response.data.data[0] });
        } else {
          this.setState({ investmentOverviewId: null });
        }
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }

  getTotalMarketValue() {
    let temp = this;
    let token = window.localStorage.getItem("token");
    axios
      .get(
        `${common.path.base_url}/getTotalMarketValue/${this.companyId}/${this.investmentId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({
          totalEstimatedValue: response.data.data.EstimatedTotalMarketValue,
        });
      })
      .catch(function (error) {
        temp.setState({ totalEstimatedValue: "" });
      });
  }

  redirectForEdit(section) {
    const roleCode = window.localStorage.getItem("roleCode");
    if (roleCode !== "005") {
      this.props.history.push(
        `/iir/${section}?companyId=${this.companyId}&investmentId=${this.investmentId}`
      );
      return;
    }
  }
  redirectForEditCompany(type) {
    const roleCode = window.localStorage.getItem("roleCode");
    if (roleCode !== "005") {
      this.props.history.push(
        `/iir/company?companyId=${this.companyId}&investmentId=${this.investmentId}&type=${type}`
      );
      return;
    }
  }
  render() {
    const username = window.localStorage.getItem("username");
    const {
      companyOverview,
      investmentOverview,
      marketOverview,
      leadership,
      board,
      productDesc,
      differentiation,
      problemStatementDesc,
      solutionSummaryDesc,
      majorInvestor,
      fundingDesc,
      histAndProjRevenue,
      keyCustomers,
      currentPartners,
      totalEstimatedValue,
      fundingDescimgtext,
    } = this.state;
    return (
      <div>
        <div className="finalPreview">
          {this.state.loading ? <LoadingSpinner /> : ""}
          <Header username={username} role={this.state.Role} />
          <div className="investment">
            <div className="container-fluid">
              <h2>Investment Intelligence Report </h2>
            </div>
          </div>
          <div
            className="preview-container"
            ref={(elements) => (this.componentRef = elements)}
          >
            <div className="investment-spot">
              <div className="cancel-btn-wraper">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={() => this.props.history.goBack()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {companyOverview && companyOverview.companyLogo && (
                <img
                  style={{ cursor: "pointer", marginBottom: "20px" }}
                  onClick={() => this.redirectForEdit("company")}
                  src={`${common.path.base_url}/getFileNew?fileId=${companyOverview.companyLogo}`}
                  className="logo2"
                  alt=""
                />
              )}

              <div className="investment-spotsec company-overview">
                <ul>
                  <li onClick={() => this.redirectForEditCompany("totalFund")}>
                    <label>
                      <img src={images.path.dollarIcon} alt="" />
                      Total funding to date:
                    </label>
                    <span className=".investment-spot .investment-spotsec ul li fundinglablespan">
                      {companyOverview &&
                        companyOverview.totalFunding &&
                        companyOverview.totalFunding}{" "}
                    </span>
                  </li>
                  <li
                    onClick={() =>
                      this.redirectForEditCompany("fundingRoundDate")
                    }
                  >
                    <label>
                      <img src={images.path.fundingRoundDate} alt="" />
                      Last funding round and date:{" "}
                    </label>
                    <span className=".investment-spot .investment-spotsec ul li fundinglablespan">
                      {companyOverview &&
                        companyOverview.lastFunding &&
                        companyOverview.lastFunding}
                    </span>
                  </li>
                  <li onClick={() => this.redirectForEditCompany("IPOStatus")}>
                    <label>
                      <img src={images.path.ipoStatusIcon} alt="" />
                      IPO Status:
                    </label>
                    <span className=".investment-spot .investment-spotsec ul li fundinglablespan">
                      {companyOverview &&
                        companyOverview.IPOStatus &&
                        companyOverview.IPOStatus}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="investment-overview">
                <h3 onClick={() => this.redirectForEdit("investmentOverview")}>
                  Investment Overview
                </h3>
                {investmentOverview &&
                  investmentOverview.investmentType === "series" && (
                    <h4>
                      Participating directly in the Series{" "}
                      {investmentOverview.series}, buying preferred shares at $
                      {investmentOverview.share} per share{" "}
                    </h4>
                  )}
                {investmentOverview &&
                  investmentOverview.investmentType === "percentage" && (
                    <h4>
                      Investing in the future Series {investmentOverview.series}
                      , buying preferred shares in advance at a{" "}
                      {investmentOverview.percentage}% discount to the pricing
                      of the future round
                    </h4>
                  )}
                {/* <h4>Purchasing Common Stock at $15.60 per share.</h4> */}
                {investmentOverview && investmentOverview.message && (
                  <p className="ql-editor">
                    <Markup content={investmentOverview.message} />
                  </p>
                )}

                {/* <p>TSG has been granted an exclusive strategic allocation in a secondary offering of ThoughtSpot common stock through the Nasdaq Private Market. Other participants in this offering include Susquehanna, Sapphire, Advanced Technology Fund, March Capital, Roundtable Capital, and the SharesPost 100 Fund. The last primary round of funding was in 2019 at $13.82 per share, with the company experiencing over 75% growth since. While ThoughtSpot has not indicated publicly any specific plans or timelines for ThoughtSpot to IPO, some industry insiders are referring to this as a pre-IPO opportunity as there is potential (according to current and projected growth numbers) for the company to pursue a public exit before needing to raise another primary round of funding. In addition, as is often the case, an acquisition is possible as much larger companies like Snowflake and others seek to get deeper into the Business Intelligence space and weigh the risks/benefits of building in-house vs. acquiring.</p> */}
              </div>
              <div className="investment-overview">
                <h3 onClick={() => this.redirectForEdit("company")}>
                  Company Overview
                </h3>
                {companyOverview && companyOverview.overview && (
                  <p className="ql-editor">
                    <Markup content={companyOverview.overview} />
                  </p>
                )}
              </div>
              {this.state.marketOverview &&
                this.state.marketOverview.length > 0 && (
                  <div className="investment-overview no-italic">
                    <h3
                      onClick={() =>
                        this.redirectForEdit("marketPerformanceandSummary")
                      }
                    >
                      Market Overview
                    </h3>
                    {totalEstimatedValue && (
                      <div className="fixed-subSec">
                        <img
                          className="mark-img"
                          src={images.path.marketopertunity}
                          alt=""
                        />
                        <p className="Fixedheader">
                          Estimated total market opportunity
                        </p>
                        <label className="value">
                          {this.state.totalEstimatedValue}
                        </label>
                      </div>
                    )}
                    {this.state.marketOverview.map((item) => {
                      return (
                        <>
                          {item.title && <h4>{item.title}</h4>}
                          {item &&
                            item.data &&
                            item.data.length > 0 &&
                            item.data.map((innerItem) => {
                              return (
                                <>
                                  {innerItem.type === "text" && (
                                    <p className="ql-editor">
                                      <Markup content={innerItem.text} />
                                    </p>
                                  )}
                                  {innerItem.type === "image" &&
                                    innerItem.pictureId && (
                                      <div className="overviewimg">
                                        <img
                                          src={`${common.path.base_url}/getFileNew?fileId=${innerItem.pictureId}`}
                                          alt=""
                                        />
                                      </div>
                                    )}
                                </>
                              );
                            })}
                        </>
                      );
                    })}
                    {/* <div className="inner-spotsec">
                                    <ul>
                                        <li><label><img src={images.path.dollarIcon} alt='' />Estimated total market opportunity:</label><span>$35B in 2024 </span></li>
                                    </ul>
                                </div>
                                <p>The future of data and & analytics is in the cloud. According to Gartner, by 2022, 75% of all databases will be deployed or migrated to a cloud platform. By 2023, cloud DBMS revenue will account for 50% of the total market revenue.</p> */}
                  </div>
                )}
              {leadership && leadership.length > 0 && (
                <div className="investment-overview">
                  <h3 onClick={() => this.redirectForEdit("leadership")}>
                    Leadership
                  </h3>
                  {leadership.map((item) => {
                    return (
                      <>
                        {item.fullName && (
                          <h4 className="ceoProfile">
                            {item.fullName} - {item.title}
                          </h4>
                        )}
                        <div className="horizontal-overview">
                          {item.picture && (
                            <div className="leadershipimg">
                              <img
                                src={`${common.path.base_url}/getFileNew?fileId=${item.picture}`}
                                alt=""
                              />
                            </div>
                          )}
                          {item.bio && <Markup content={item.bio} />}
                        </div>
                      </>
                    );
                  })}
                  {/* <h4 className="ceoProfile">Sudheesh Nair- CEO</h4>
                                    <p>Sudheesh is the CEO of ThoughtSpot. Prior to ThoughtSpot he was President of Nutanix, the leader in enterprise cloud infrastructure. During his tenure at Nutanix, he was responsible for leading the sales organization and helped grow the company from $0 to over $1 billion in revenue and over $9 billion in market capitalization. Sudheesh focuses on building world-class teams of enablers and disruptors who believe that innovation can, and should, happen in every function and at every level. Prior to Nutanix, Sudheesh worked in various field-facing roles for many California startup companies including XIV (an IBM Company), ONStor and Zambeel.</p>
                                    <h4 className="ceoProfile">Ajeet Singh- Co-founder & Executive Chairman</h4>
                                    <p>Ajeet Singh is the co-founder and Executive Chairman at ThoughtSpot, a company revolutionizing analytics with search and AI. Driven by his passion for creation, Ajeet has built two-multibillion-dollar technology companies from the ground up - ThoughtSpot and Nutanix. Prior to starting ThoughtSpot, he was co-founder and Chief Products Officer at Nutanix, the leader in the enterprise cloud industry and largest tech IPO of 2016. Prior to Nutanix, Ajeet learned the ropes of enterprise technology startups as the Senior Director of Product Management at Aster Data, later acquired by Teradata for $300M. Ajeet has also held business and technical roles at Oracle, where he was part of the team that first launched Oracle Database to the Amazon EC2 cloud. Ajeet holds an MBA from the Indian Institute of Management, Calcutta, and a Bachelor of Science in Chemical Engineering from the Indian Institute of Technology, Kanpur, where he graduated at the top of his class.</p>
                                    <h4 className="ceoProfile">Amit Prakash- Co-founder & CTO</h4>
                                    <p>Amit is Co-founder and CTO at ThoughtSpot and has deep experience in building large scale analytics systems. Prior to ThoughtSpot, Amit led multiple analytics engineering teams in the Google AdSense businesses, contributing $50M+ quarter-on-quarter growth to the business through improving analytical algorithms for AdSense. Prior to that, Amit was a founding engineer in the Bing team at Microsoft, where he implemented the page rank algorithms for search from scratch. Amit received his PhD in Computer Engineering from the University of Texas at Austin and a Bachelor of Technology in Electrical Engineering from the Indian Institute of Technology, Kanpur.</p> */}
                </div>
              )}
              {board && board.length > 0 && (
                <div className="investment-overview">
                  <h3 onClick={() => this.redirectForEdit("board")}>Board</h3>
                  {board.map((item) => {
                    return (
                      <>
                        {item.fullName && (
                          <h4 className="ceoProfile">
                            {item.fullName} - {item.title}
                          </h4>
                        )}
                        <div className="horizontal-overview">
                          {item.picture && (
                            <div className="leadershipimg">
                              <img
                                src={`${common.path.base_url}/getFileNew?fileId=${item.picture}`}
                                alt=""
                              />
                            </div>
                          )}
                          {item.bio && <Markup content={item.bio} />}
                        </div>
                      </>
                    );
                  })}
                  {/* <h4 className="ceoProfile">Sudheesh Nair- CEO</h4>
                                    <p>Sudheesh is the CEO of ThoughtSpot. Prior to ThoughtSpot he was President of Nutanix, the leader in enterprise cloud infrastructure. During his tenure at Nutanix, he was responsible for leading the sales organization and helped grow the company from $0 to over $1 billion in revenue and over $9 billion in market capitalization. Sudheesh focuses on building world-class teams of enablers and disruptors who believe that innovation can, and should, happen in every function and at every level. Prior to Nutanix, Sudheesh worked in various field-facing roles for many California startup companies including XIV (an IBM Company), ONStor and Zambeel.</p>
                                    <h4 className="ceoProfile">Ajeet Singh- Co-founder & Executive Chairman</h4>
                                    <p>Ajeet Singh is the co-founder and Executive Chairman at ThoughtSpot, a company revolutionizing analytics with search and AI. Driven by his passion for creation, Ajeet has built two-multibillion-dollar technology companies from the ground up - ThoughtSpot and Nutanix. Prior to starting ThoughtSpot, he was co-founder and Chief Products Officer at Nutanix, the leader in the enterprise cloud industry and largest tech IPO of 2016. Prior to Nutanix, Ajeet learned the ropes of enterprise technology startups as the Senior Director of Product Management at Aster Data, later acquired by Teradata for $300M. Ajeet has also held business and technical roles at Oracle, where he was part of the team that first launched Oracle Database to the Amazon EC2 cloud. Ajeet holds an MBA from the Indian Institute of Management, Calcutta, and a Bachelor of Science in Chemical Engineering from the Indian Institute of Technology, Kanpur, where he graduated at the top of his class.</p>
                                    <h4 className="ceoProfile">Amit Prakash- Co-founder & CTO</h4>
                                    <p>Amit is Co-founder and CTO at ThoughtSpot and has deep experience in building large scale analytics systems. Prior to ThoughtSpot, Amit led multiple analytics engineering teams in the Google AdSense businesses, contributing $50M+ quarter-on-quarter growth to the business through improving analytical algorithms for AdSense. Prior to that, Amit was a founding engineer in the Bing team at Microsoft, where he implemented the page rank algorithms for search from scratch. Amit received his PhD in Computer Engineering from the University of Texas at Austin and a Bachelor of Technology in Electrical Engineering from the Indian Institute of Technology, Kanpur.</p> */}
                </div>
              )}
              {problemStatementDesc && problemStatementDesc.length > 0 && (
                <div className="investment-overview no-italic">
                  <h3 onClick={() => this.redirectForEdit("productStatement")}>
                    Problem Statement
                  </h3>
                  {problemStatementDesc.map((item) => {
                    return (
                      <>
                        {item.title && <h4>{item.title}</h4>}
                        {item &&
                          item.data &&
                          item.data.length > 0 &&
                          item.data.map((innerItem) => {
                            return (
                              <>
                                {innerItem.type === "text" && (
                                  <p className="ql-editor">
                                    <Markup content={innerItem.text} />
                                  </p>
                                )}
                                {innerItem.type === "image" &&
                                  innerItem.pictureId && (
                                    <div className="overviewimg">
                                      <img
                                        src={`${common.path.base_url}/getFileNew?fileId=${innerItem.pictureId}`}
                                        alt=""
                                      />
                                    </div>
                                  )}
                              </>
                            );
                          })}
                      </>
                    );
                  })}
                </div>
              )}
              {solutionSummaryDesc && solutionSummaryDesc.length > 0 && (
                <div className="investment-overview no-italic">
                  <h3 onClick={() => this.redirectForEdit("solutionSummary")}>
                    Solution Summary
                  </h3>
                  {solutionSummaryDesc.map((item) => {
                    return (
                      <>
                        {item.title && <h4>{item.title}</h4>}
                        {item && item.data && item.data.length > 0 && (
                          <div className="inner-spotsec-vertical-line">
                            <ul>
                              {item.data.map((innerItem) => {
                                return (
                                  <li>
                                    {innerItem.type === "text" ? (
                                      <p className="ql-editor">
                                        <Markup content={innerItem.text} />
                                      </p>
                                    ) : (
                                      // <label className="ql-editor">{innerItem.text}</label>
                                      <div className="overviewimg">
                                        <img
                                          src={`${common.path.base_url}/getFileNew?fileId=${innerItem.pictureId}`}
                                          alt=""
                                        />
                                      </div>
                                    )}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              )}
              {productDesc && productDesc.length > 0 && (
                <div className="investment-overview">
                  <h3 onClick={() => this.redirectForEdit("product")}>
                    Product
                  </h3>
                  {productDesc.map((item) => {
                    return (
                      <>
                        {item.title && <h4>{item.title}</h4>}
                        {item &&
                          item.data &&
                          item.data.length > 0 &&
                          item.data.map((innerItem) => {
                            return (
                              <>
                                {innerItem.type === "text" && (
                                  <p className="ql-editor">
                                    <Markup content={innerItem.text} />
                                  </p>
                                )}
                                {innerItem.type === "image" &&
                                  innerItem.pictureId && (
                                    <div className="overviewimg">
                                      <img
                                        src={`${common.path.base_url}/getFileNew?fileId=${innerItem.pictureId}`}
                                        alt=""
                                      />
                                    </div>
                                  )}
                              </>
                            );
                          })}
                      </>
                    );
                  })}
                </div>
              )}
              {differentiation && differentiation.length > 0 && (
                <div className="investment-overview">
                  <h3 onClick={() => this.redirectForEdit("differentiation")}>
                    Differentiation
                  </h3>
                  {differentiation.map((item) => {
                    return (
                      <>
                        {item.title && <h4>{item.title}</h4>}
                        {item &&
                          item.data &&
                          item.data.length > 0 &&
                          item.data.map((innerItem) => {
                            return (
                              <>
                                {innerItem.type === "text" && (
                                  <p className="ql-editor">
                                    <Markup content={innerItem.text} />
                                  </p>
                                )}
                                {innerItem.type === "image" &&
                                  innerItem.pictureId && (
                                    <div className="overviewimg">
                                      <img
                                        src={`${common.path.base_url}/getFileNew?fileId=${innerItem.pictureId}`}
                                        alt=""
                                      />
                                    </div>
                                  )}
                              </>
                            );
                          })}
                      </>
                    );
                  })}
                </div>
              )}
              {/* <div className="investment-overview">
                                <h3>Differentiation:</h3>
                                <img src={images.path.differentiation} alt='' />
                                <p>Gartner released a magic quadrant for analytics and business intelligence platforms. ThoughtSpot sits in the leader quadrant along with competitors Microsoft, Tableau and Qlik. 2020 marks ThoughtSpot�s second year in a row as a leader in the space. In fact, ThoughtSpot was the first new leader in this category in 7 years and had the fastest path ever to becoming a magic quadrant a leader.</p>
                            </div> */}
              {majorInvestor && majorInvestor.length > 0 && (
                <div className="investment-overview">
                  <h3 onClick={() => this.redirectForEdit("majorInvestor")}>
                    Key Investors
                  </h3>
                  <div className="major-investor-main">
                    <div className="productsContainer">
                      {majorInvestor.map((item) => {
                        return (
                          <>
                            {
                              item && item.logo && (
                                //  <div className='major-investor-inner partners'>
                                <div className="products">
                                  <img
                                    src={`${common.path.base_url}/getFileNew?fileId=${item.logo}`}
                                    alt=""
                                  />
                                </div>
                              )
                              //  </div>
                            }
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
              {
                fundingDesc && fundingDesc.length > 0 && (
                  <div className="investment-overview table-responsive">
                    <h3 onClick={() => this.redirectForEdit("funding")}>
                      Funding Information
                    </h3>
                    <table className="funding_table">
                      <thead>
                        <tr>
                          {Object.keys(fundingDesc[0]).map((key, index) => {
                            if (index <= fundingDesc[2] - 1) {
                              return (
                                <>
                                  <th className="headings">
                                    {fundingDesc[0][key]}
                                  </th>
                                </>
                              );
                            }
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {fundingDesc[1].map((actual_row, index) => {
                          if (index <= fundingDesc[3] - 1) {
                            return (
                              <tr key={"actual_row" + index}>
                                {Object.keys(actual_row).map(
                                  (key, anotherIndex) => {
                                    if (anotherIndex <= fundingDesc[2] - 1) {
                                      return (
                                        <>
                                          <td
                                            className="rowValues"
                                            key={key + index}
                                          >
                                            {actual_row[key]
                                              ? actual_row[key]
                                              : "-"}
                                          </td>
                                        </>
                                      );
                                    }
                                  }
                                )}
                              </tr>
                            );
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                )
                //:
              }
              {fundingDescimgtext && fundingDescimgtext.length > 0 && (
                <div
                  className={
                    fundingDesc && fundingDesc.length > 0
                      ? "investment-overview table-responsive lineRemove"
                      : "investment-overview table-responsive"
                  }
                >
                  {fundingDesc && fundingDesc.length > 0 ? (
                    ""
                  ) : (
                    <h3 onClick={() => this.redirectForEdit("funding")}>
                      Funding Information
                    </h3>
                  )}
                  {fundingDescimgtext.map((item) => {
                    return (
                      <>
                        {item.text && item.text != null && (
                          <p className="ql-editor">
                            <Markup content={item.text} />
                          </p>
                        )}
                        {item.pictureId && item.pictureId != "null" && (
                          <div className="overviewimg">
                            <img
                              src={`${common.path.base_url}/getFileNew?fileId=${item.pictureId}`}
                              alt=""
                            />
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              )}

              {histAndProjRevenue && histAndProjRevenue.length > 0 && (
                <div className="investment-overview">
                  <h3
                    onClick={() =>
                      this.redirectForEdit("historicalandProjectedRevenue")
                    }
                  >
                    Historical and Projected Revenue
                  </h3>
                  {histAndProjRevenue.map((item) => {
                    return (
                      <>
                        {item.title && <h4>{item.title}</h4>}
                        {item &&
                          item.data &&
                          item.data.length > 0 &&
                          item.data.map((innerItem) => {
                            return (
                              <>
                                {innerItem.type === "text" && (
                                  <p className="ql-editor">
                                    <Markup content={innerItem.text} />
                                  </p>
                                )}
                                {innerItem.type === "image" &&
                                  innerItem.pictureId && (
                                    <div className="overviewimg">
                                      <img
                                        src={`${common.path.base_url}/getFileNew?fileId=${innerItem.pictureId}`}
                                        alt=""
                                      />
                                    </div>
                                  )}
                              </>
                            );
                          })}
                      </>
                    );
                  })}
                </div>
              )}
              {keyCustomers && keyCustomers.length > 0 && (
                <div className="investment-overview">
                  <h3 onClick={() => this.redirectForEdit("keyCustomers")}>
                    Key Customers
                  </h3>
                  <div className="major-investor-main">
                    <div className="productsContainer">
                      {keyCustomers.map((item) => {
                        return (
                          <>
                            {item && item.logo && (
                              //  <div className='major-investor-inner partners'>
                              <div className="products">
                                <img
                                  src={`${common.path.base_url}/getFileNew?fileId=${item.logo}`}
                                  alt=""
                                />
                              </div>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
              {currentPartners && currentPartners.length > 0 && (
                <div className="investment-overview">
                  <h3 onClick={() => this.redirectForEdit("currentPartners")}>
                    Key Partners
                  </h3>
                  <div className="major-investor-main">
                    <div className="productsContainer">
                      {currentPartners.map((item) => {
                        return (
                          <>
                            {item && item.logo && (
                              //  <div className='major-investor-inner partners'>
                              <div className="products">
                                <img
                                  src={`${common.path.base_url}/getFileNew?fileId=${item.logo}`}
                                  alt=""
                                />
                              </div>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(FinalPreview);

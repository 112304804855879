import React, { Component } from 'react';
import './Preview.scss';
import Header from '../../../components/Header';
import HeaderImg from '../../../components/HeaderImage';
import LoadingSpinner from '../../../components/Loader';
import common from '../../../common';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import ReactToPrint from 'react-to-print';
import TButton from '../../../components/TButton';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;





class Preview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            iirPDF: null,
            numPages: null,
            setNumPages: [],
            pageNumber:1,
            setPageNumber:1

        }
        
    }
    onDocumentLoadSuccess(pdf) {
            // console.log(31, pdf._pdfInfo.numPages);
            const setNumPages = [];
            if (pdf._pdfInfo.numPages > 0) {
                
                for (let i=0; i < pdf._pdfInfo.numPages; i++ ){
                    setNumPages.push(i+1);
                }
            }
            console.log(setNumPages);
            this.setState({ numPages:pdf._pdfInfo.numPages, setNumPages });
        
      }

    componentWillMount() {
        
    }
    redirect() {
        this.props.history.goBack()
    }
    render() {
        const username = window.localStorage.getItem("username");
        const iirPDF = '5ff81877ba4a3982aa5db2fb';
        return (
            <div>
                <div className="preview">
                    {this.state.loading ? <LoadingSpinner /> : ""}
                    <Header username={username} role={this.state.Role}  />
                    <HeaderImg heading={`Investment Intelligence Report`} />
                    <div className="preview-main" ref={elements => this.componentRef = elements}>
                    <div className="close-btn icons">
                        </div>
                        <div className="cancel-btn-wraper">
                            <button type="button" className="close" aria-label="Close" onClick={() => this.redirect()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        
                        {
                            iirPDF && 
                            <div style={{marginLeft: '50px'}}>
                            <Document
                                file={`${common.path.base_url}/getDocument?fileId=${iirPDF}`}
                                onLoadSuccess={(pdf) => this.onDocumentLoadSuccess(pdf)}
                            >
                            
                            {
                                this.state.setNumPages.length > 0 &&
                                this.state.setNumPages.map(page => (
                                    <Page pageNumber={page} width={800} />
                                ))}
                            </Document>
                            
                            {/* <p>Page {this.state.pageNumber} of {this.state.numPages}</p> */}
                            </div>
                        }
                        

                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(Preview);
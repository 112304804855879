import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { path } from "../../imagesPath";
import "./NotParticipating.scss";
import NotParticipatingCard from "./NotParticipatingCard";

const NotParticipating = (props) => {
  const { notParticipation } = props;
  const [notParticipating, setNotParticipating] = useState([]);

  useEffect(() => {
    if (notParticipation.length > 0) {
      const data = notParticipation
        .filter((dataRow) => dataRow.stage !== "open");
      setNotParticipating(data);
    }
  }, []);
  return (
    <>
      {notParticipating.length > 0 && (
        <div className="container" style={{ padding: "15px" }}>
          <div className="invitations_sec">
            <div className="row d-flex" style={{ alignItems: "center" }}>
              <img className="intial_icon" style={{
                height: "30px", width: "40px", float: 'left',
                marginBottom: '1.25em',
                fontSize: "1.125em",
                marginTop: '1.25em',
                paddingLeft: '10px'
              }} src={path.notParticipating} />
              <div className="col-md-3">
                <h3 style={{ fontSize: "20px", color: "#383F59" }}>
                  Not Participating ({notParticipating.length})
                </h3>
              </div>
            </div>
            <div className="row">
              {notParticipating
                .filter((dataRow) => dataRow.stage !== "open")
                .map((invitation) => {
                  return (
                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 col-xxl-3" key={invitation._id}>
                      <NotParticipatingCard
                        invitation={invitation}
                        key={invitation._id}
                        fromToDo={false}
                        {...props}
                        callFrom="NotParticipating"
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    notParticipation:
      state.investorDashboardData.notParticipation.length > 0
        ? state.investorDashboardData.notParticipation
        : [],
  };
};

export default connect(mapStateToProps, null)(NotParticipating);

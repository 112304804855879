import React, { Component } from 'react';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import './ManagementFeesandCarry.scss';
import axios from 'axios';
import swal from 'sweetalert';
import common from '../../common';
import { withRouter } from 'react-router-dom';


class ManagementFeesandCarry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            managementFee: '',
            carry: '',
            managementFeesOptions: [
                { label: '', value: '' },
                { label: '5%', value: '5' },
            ],
            carryOptions: [
                { label: '20%', value: '20' },
            ],
        }
        this.investmentId = null;
    }

    componentWillMount() {
        this.setState({ loading: false });
        if (this.props.location
            && this.props.match
            && this.props.match.params
            && this.props.match.params.id) {
            this.investmentId = this.props.match.params.id;
        }
        if (this.investmentId) {
            this.getInvestmentSettings()
        } else {
            this.getInvestmentGlobalSettings()
        }

    }
    getInvestmentGlobalSettings() {
        const temp = this;
        temp.setState({ loading: true });
        let token = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/settings`, {
            headers: {
                "Authorization": token
            }
        })
            .then(function (response) {
                temp.setState({ loading: false });
                if (response && response.data && response.data.data) {
                    temp.setState({
                        managementFee: (response.data.data.managementFee) ? response.data.data.managementFee : ""
                    });
                    temp.setState({
                        carry: response.data.data.carry
                    });
                }

            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else {
                    swal({
                        title: "Network Error!",
                        text: "Something went wrong",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    };
    getInvestmentSettings() {
        const temp = this;
        temp.setState({ loading: true });
        let token = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/investmentFollowUpSettings/${this.investmentId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then(function (response) {
                axios.get(`${common.path.base_url}/settings`, {
                    headers: {
                        "Authorization": token
                    }
                })
                    .then(function (res) {
                        let bankDetail = null;
                        let globalBankDetail = null;
                        temp.setState({ loading: false });
                        if (response && response.data && response.data.data && response.data.data.bankDetail) {
                            bankDetail = response.data.data.bankDetail;
                        }
                        if (res && res.data && res.data.data && res.data.data.bankDetail) {
                            globalBankDetail = res.data.data.bankDetail;
                        }
                        const bankName = (bankDetail && bankDetail.bankName) || (globalBankDetail && globalBankDetail.bankName) || '';
                        const address = (bankDetail && bankDetail.address) || (globalBankDetail && globalBankDetail.address) || '';
                        const accountName = (bankDetail && bankDetail.accountName) || '';
                        const accountNumber = (bankDetail && bankDetail.accountNumber) || '';
                        const routingNumber = (bankDetail && bankDetail.routingNumber) || (globalBankDetail && globalBankDetail.routingNumber) || '';
                        const tsgAddress = (bankDetail && bankDetail.tsgAddress) || (globalBankDetail && globalBankDetail.tsgAddress) || '';
                        const managementFees = (response.data.data && response.data.data.managementFee);
                        const carry = (response.data.data && response.data.data.carry);
                        temp.setState({
                            bankName,
                            address,
                            accountName,
                            accountNumber,
                            routingNumber,
                            tsgAddress,
                            managementFees,
                            carry
                        })

                    })
                    .catch(function (error) {
                        temp.setState({ loading: false });
                        if (common.AUTH_EXPIRED === error.response.data.message) {
                            window.localStorage.clear();
                            window.location.href = '/';
                            return;
                        } else {
                            swal({
                                title: "Network Error!",
                                text: "Something went wrong",
                                icon: "error",
                                button: "Ok!",
                            });
                        }
                    });
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else {
                    swal({
                        title: "Network Error!",
                        text: "Something went wrong",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    };
    save() {
        this.setState({ loading: true });
        const data = {
            carry: this.state.carry,
            managementFee: this.state.managementFee,
        };

        let url = `${common.path.base_url}/saveManagementFeeAndCarry`;
        // if (this.investmentId) {
        //     url = `${common.path.base_url}/saveInvestmentFundingSettings/${this.investmentId}`;
        // }
        axios.post(`${url}`, data, {
            headers: { Authorization: window.localStorage.getItem('token') },
        })
            .then((response) => {
                this.setState({ loading: false });
                swal({
                    title: "Success!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(() => {

                    });
            })
            .catch((error) => {
                this.setState({ loading: false });
                if (common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }
    isValidate() {
        if (this.investmentId) {
            if (!this.state.managementFee || !this.state.carry) {
                return false;
            }
        } else {
            if (!this.state.managementFee || !this.state.carry) {
                return false;
            }
        }
        return true;
    }
    onChange(id, value) {
        this.setState({ [id]: value })
    }

    render() {
        return (
            <div className="checklist-container container-fluid">
                <div className="new-investment">
                    <h2>Management Fee & Carry</h2>
                </div>
                <div className="invitations_right invitations_right_fund recommend-startup">
                    <div className="col-lg-6 col-md-6">
                        <div className="form-horizontal">
                            <div className="row">
                                <div className="col-sm-5 col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="name">Management Fee <sup>*</sup> :</label>
                                        <div className='input-group recommendation-input'>
                                            <input type="text"
                                                value={this.state.managementFee && this.state.managementFee}
                                                onChange={(event) => this.onChange(event.target.id, event.target.value)}
                                                name="managementFee"
                                                className="form-control "
                                                id="managementFee"
                                                placeholder="Enter Management Fee"
                                            />
                                            <div className="input-group-addon">%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-5 col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="name">Carry <sup>*</sup> :</label>
                                        <div className='input-group recommendation-input'>
                                            <input type="text"
                                                value={this.state.carry && this.state.carry}
                                                onChange={(event) => this.onChange(event.target.id, event.target.value)}
                                                name="carry"
                                                className="form-control"
                                                id="carry"
                                                placeholder="Enter Carry Value"
                                            />
                                            <div className="input-group-addon">%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <select onChange={(event) => console.log(event.target.value)}>
                                <option value='' key={0}></option>
                                <option value='5' key={1}>5</option>
                            </select> */}
                            {/* <div className="row">
                                <div className="col-sm-7 col-md-12">
                                    <div className="form-group">
                                        <label className="title-text">Management Fee </label>
                                        <div className="custom-drop">
                                            <select className="form-control form-control-lg" onChange={(event) => this.onChange(event.target.id, event.target.value)}>
                                                <option value=''></option>
                                                <option value='5%' selected>5%</option>
                                                <option value='>' selected={this.state.valuationType === '>'}>></option>
                                                {
                                                    this.state.managementFeesOptions.map((options, index) => {
                                                        return <option value={options.value} key={index}>{options.label}</option>
                                                    })
                                                }
                                            </select>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-7 col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="name">Carry </label>
                                        <div className="custom-drop">
                                            <select className="form-control form-control-lg" name="valuationtype" id="validationCustom" onChange={(event) => {
                                                console.log(event.target.value)
                                                this.setState({ carry: event.target.value })
                                            }}>
                                                <option value=''></option>
                                                <option value='20%' selected>20%</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div> */}


                            {/* <div className="row">
                                <div className="col-sm-7 col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="name">Price Per Class A Share ($) </label>
                                        <div className='recommendation-input'>
                                            <input type="text"
                                                value={this.state.routingNumber}
                                                onChange={(event) => this.onChange(event.target.id, event.target.value)}
                                                name="routingNumber"
                                                className="form-control custom-input-height"
                                                id="routingNumber"
                                                placeholder="Enter Routing Number"
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div> */}

                            <div className="row">
                                <div className="col-sm-7 col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="name"></label>
                                        <div className='fundding-instructions'>
                                            <LaddaButton
                                                id='lada-btn'
                                                loading={this.state.loadinglada}
                                                onClick={() => this.save()}
                                                data-color="#eee"
                                                data-size={XS}
                                                className={!this.isValidate() ? 'disable' : ''}
                                                data-style={EXPAND_RIGHT}
                                                data-spinner-size={30}
                                                data-spinner-color="#ddd"
                                                data-spinner-lines={12}
                                            >
                                                SAVE
                                            </LaddaButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(ManagementFeesandCarry);

import React, { Component } from 'react'
import Header from '../../components/Header';
import PipelineList from '../components/PipelineList';

const Pipeline = props => {

    return (
        <>  
            <Header
            username={window.localStorage.getItem('username')}
            role={window.localStorage.getItem('role')}
            />
            <PipelineList />
                
        </>
    )       
}

export default Pipeline;
import React, { useState } from 'react';
import { connect } from 'react-redux';
import common from '../../../common';
import images from "../../../images";
import useScreenWidthLessThan768 from '../../shared/window_resize_hook';
import "../ExitInProcessInvestments/ExitInProcessInvestments.scss";
import "../NewInvitations/NewInvitations.scss";
import "../Recommendations/Recommendations.scss";
import "../ToDoSection/ToDoSection.scss";
import "./PipelineInvestments.scss";

const Popover = props => {
  const [comment, setComment] = useState('');
  const saveComment = (event) => {
    setComment(event.target.value)
    props.saveAddtionalThoughts(comment, props.pipelineInvestment._id)
  }
  return (
    <div className={((props.className != "no-thanks-body" && props.className != "no-thanks-body-sentiments") ? `${props.className} popover-body` : props.className)} onClick={(event) => event.stopPropagation()}>
      <textarea value={comment} onChange={(event) => {
        event.stopPropagation()
        setComment(event.target.value)
      }} className="content-box" placeholder="Enter any additional thoughts if you have."></textarea>
      <div className="btn-group">
        <button className="btn" onClick={() => props.cancelPopOver()}>Close</button>
        <button className={comment ? "active btn" : "btn"} onClick={(event) => saveComment(event)}>Save</button>
      </div>
    </div>
  )
};

const Consideration = (props) => {
  const { pipelineInvestment, fromSentiments } = props;
  const isScreenResized = useScreenWidthLessThan768();
  const pipelineRedirect = (link) => {
    let url;
    if (link) {
      if (!link.includes("http")) {
        url = `http://${link}`;
      } else {
        url = link;
      }
      window.open(url, "_blank");
      return;
    }
  };
  return (
    <div className={props.popover && !fromSentiments ? "card clicked" : fromSentiments && !props.popover ? "" : props.popover ? "clicked" : "card"}>
      <div className="col-12" >
        <div className="row" id={pipelineInvestment._id}>
          <div
            className="invitations_left"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "3px 20px",
            }}
          >
            <div
              className="row padding-image"
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              {!fromSentiments && (<div className="invitations_left_img">
                {pipelineInvestment.companyProfileImage != "" &&
                  pipelineInvestment.companyProfileImage != null &&
                  pipelineInvestment.companyProfileImage != "undefined" ? (
                  <img
                    id="compProfilePreview"
                    src={pipelineInvestment.companyProfileImage}
                    alt=""
                  />
                ) : pipelineInvestment.profileImg != "" &&
                  pipelineInvestment.profileImg != null &&
                  pipelineInvestment.profileImg != "undefined" ? (
                  <img
                    id="compProfilePreview"
                    src={`${common.path.base_url}/getImage?type=company&id=${pipelineInvestment._id}`}
                    alt=""
                  />
                ) : (
                  <img
                    id="compProfilePreview"
                    src={images.path.placeholder}
                    alt=""
                  />
                )}
              </div>)}
              <div className="invitations_left_content">
                <h3 onClick={() => pipelineRedirect(pipelineInvestment.website)}>{pipelineInvestment.companyName}</h3>
              </div>
            </div>
            <div
              className="btn-box"
              onClick={() => pipelineRedirect(pipelineInvestment.website)}
            >
              {(!isScreenResized || fromSentiments) ? (
                <button type="submit" className="btn btn-danger">
                  Learn More
                </button>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="todo_invitations_right todo_invitations_right_3">
              <div className="row row-margin div-spacing">
                {props.popover &&
                  props.popover ===
                  `${pipelineInvestment._id}_first_line` && (
                    <Popover {...props} className="first-line-body" />
                  )}
                <button
                  onClick={(event) => {
                    event.stopPropagation();
                    props.investorSentimentInterest(
                      pipelineInvestment,
                      "First Line"
                    );
                  }}
                  className={
                    pipelineInvestment.pipelineStartupinterests &&
                      pipelineInvestment.pipelineStartupinterests.interestType ===
                      "First Line"
                      ? "btn-radius sentiment-active"
                      : "btn-radius"
                  }
                >
                  Definitely!
                </button>

                {props.popover &&
                  props.popover ===
                  `${pipelineInvestment._id}_learn_more` && (
                    <Popover {...props} className={fromSentiments ? "sentimental-popover-body" : "left-side-body"} />
                  )}
                <button
                  onClick={(event) => {
                    event.stopPropagation();
                    props.investorSentimentInterest(
                      pipelineInvestment,
                      "Learn More"
                    );
                  }}
                  className={
                    pipelineInvestment.pipelineStartupinterests &&
                      pipelineInvestment.pipelineStartupinterests.interestType ===
                      "Learn More"
                      ? "btn-radius sentiment-active"
                      : "btn-radius"
                  }
                >
                  Likely In
                </button>

                {props.popover &&
                  props.popover ===
                  `${pipelineInvestment._id}_participate` && (
                    <Popover {...props} className={fromSentiments ? "consideration-sentimental consideration-popover-body" : "participate-body"} />
                  )}
                <button
                  onClick={(event) => {
                    event.stopPropagation();
                    props.investorSentimentInterest(
                      pipelineInvestment,
                      "Participate"
                    );
                  }}
                  className={
                    pipelineInvestment.pipelineStartupinterests &&
                      pipelineInvestment.pipelineStartupinterests.interestType ===
                      "Participate"
                      ? "btn-radius sentiment-active"
                      : "btn-radius"
                  }
                >
                  Considering
                </button>
                {props.popover &&
                  props.popover ===
                  `${pipelineInvestment._id}_no_thanks` && (
                    <Popover {...props} className={fromSentiments ? "no-thanks-body-sentiments" : "no-thanks-body"} />
                  )}
                <button
                  onClick={(event) => {
                    event.stopPropagation();
                    props.investorSentimentInterest(
                      pipelineInvestment,
                      "No Thanks"
                    );
                  }}
                  className={
                    pipelineInvestment.pipelineStartupinterests &&
                      pipelineInvestment.pipelineStartupinterests.interestType ===
                      "No Thanks"
                      ? "btn-radius sentiment-active"
                      : "btn-radius"
                  }
                >
                  No Thanks
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PipelineInvestmentCard = props => {
  const { pipelineInvestment, index, total, fromSentiments } = props;

  const { pipelineStartupinterests } = pipelineInvestment;
  const isScreenResized = useScreenWidthLessThan768();

  const redirect = (link) => {
    let url;
    if (link) {
      if (!link.includes('http')) {
        url = `http://${link}`;
      } else {
        url = link;
      }
      window.open(url, '_blank');
      return;
    }
  }

  return (
    <>
      {
        fromSentiments && (!isScreenResized || window.innerWidth >= 768) &&
        <div className="row list-alignment">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="invitations_left">
              {/* <div className="invitations_left_img">
                              <img src="assets/images/series1.png" />
                          </div> */}
              <div className="invitations_left_content">
                <h4>{pipelineInvestment.companyName}</h4>
                <p></p>
                <div className="btn-box" onClick={() => redirect(pipelineInvestment.website)}>
                  <button type="submit" className="btn btn-danger">Learn More</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="invitations_right invitations_right_3 sentiment-interest-right">
              <ul>
                <li >
                  {
                    props.popover && props.popover === `${pipelineInvestment._id}_first_line` &&
                    <Popover
                      {...props}
                      className="first-line-body"
                    />
                  }
                  <button onClick={(event) => {
                    event.stopPropagation();
                    props.investorSentimentInterest(pipelineInvestment, 'First Line')
                  }}
                    className={pipelineStartupinterests && pipelineStartupinterests.interestType === 'First Line' ? 'btn-radius sentiment-active' : 'btn-radius'}>Definitely!</button>
                </li>
                <li>
                  {
                    props.popover && props.popover === `${pipelineInvestment._id}_learn_more` &&
                    <Popover
                      {...props}
                      className="likely-body"
                    />

                  }
                  <button onClick={(event) => {
                    event.stopPropagation();
                    props.investorSentimentInterest(pipelineInvestment, 'Learn More')
                  }}
                    className={pipelineStartupinterests && pipelineStartupinterests.interestType === 'Learn More' ? 'btn-radius sentiment-active' : 'btn-radius'}>Likely In</button>
                </li>
                <li>
                  {
                    props.popover && props.popover === `${pipelineInvestment._id}_participate` &&
                    <Popover
                      {...props}
                      className="consideration-popover-body"
                    />
                  }
                  <button onClick={(event) => {
                    event.stopPropagation();
                    props.investorSentimentInterest(pipelineInvestment, 'Participate')
                  }}
                    className={pipelineStartupinterests && pipelineStartupinterests.interestType === 'Participate' ? 'btn-radius sentiment-active' : 'btn-radius'}>Considering</button>
                </li>
                <li>
                  {
                    props.popover && props.popover === `${pipelineInvestment._id}_no_thanks` &&
                    <Popover
                      {...props}
                      fromSentiments={fromSentiments}
                      className="no-thanks-body-sentiments"
                    />
                  }
                  <button onClick={(event) => {
                    event.stopPropagation();
                    props.investorSentimentInterest(pipelineInvestment, 'No Thanks')
                  }}
                    className={pipelineStartupinterests && pipelineStartupinterests.interestType === 'No Thanks' ? 'btn-radius sentiment-active' : 'btn-radius'}>No Thanks</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      }
      {
        !fromSentiments && (
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 col-xxl-3 card-min-width"
            key={index} >
            <Consideration
              pipelineInvestment={pipelineInvestment}
              fromSentiments={false}
              index={index}
              key={index}
              {...props}
              popover={props.popover}
            />
          </div>
        )
      }
      {
        fromSentiments && isScreenResized && window && window.innerWidth < 768 && (
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 col-xxl-3 card-min-width"
            key={index} >
            <Consideration
              pipelineInvestment={pipelineInvestment}
              fromSentiments={true}
              index={index}
              key={index}
              {...props}
              popover={props.popover}
            />
          </div>
        )
      }
      {/* {
        !fromSentiments && !isScreenResized &&
        <div className="padding-card">
          <div className="card">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row">
                <div className="invitations_left" style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="row padding-image" style={{ display: "flex", alignItems: "center" }}>
                    <div className="invitations_left_img">
                      {(pipelineInvestment.companyProfileImage != "" && pipelineInvestment.companyProfileImage != null && pipelineInvestment.companyProfileImage != 'undefined') ?
                        <img id='compProfilePreview' src={pipelineInvestment.companyProfileImage} alt='' />
                        : (pipelineInvestment.profileImg != "" && pipelineInvestment.profileImg != null && pipelineInvestment.profileImg != 'undefined') ?
                          <img id='compProfilePreview' src={`${common.path.base_url}/getImage?type=company&id=${pipelineInvestment._id}`} alt='' />
                          :
                          <img id='compProfilePreview' src={images.path.placeholder} alt='' />
                      }
                    </div>
                    <div className="invitations_left_content">
                      <h3>{pipelineInvestment.companyName}</h3>
                    </div>
                  </div>
                  <p></p>
                  <div className="btn-box" onClick={() => redirect(pipelineInvestment.website)}>
                    <button type="submit" className="btn btn-danger">Learn More</button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="invitations_right invitations_right_3 sentiment-interest-right">
                  <ul>
                    <li >
                      {
                        props.popover && props.popover === `${pipelineInvestment._id}_first_line` &&
                        <Popover
                          {...props}
                          className="first-line"
                        />
                      }
                      <button onClick={(event) => {
                        event.stopPropagation();
                        props.investorSentimentInterest(pipelineInvestment, 'First Line')
                      }}
                        className={pipelineStartupinterests && pipelineStartupinterests.interestType === 'First Line' ? 'btn-radius sentiment-active' : 'btn-radius'}>Definitely!</button>
                    </li>
                    <li>
                      {
                        props.popover && props.popover === `${pipelineInvestment._id}_learn_more` &&
                        <Popover
                          {...props}
                        />

                      }
                      <button onClick={(event) => {
                        event.stopPropagation();
                        props.investorSentimentInterest(pipelineInvestment, 'Learn More')
                      }}
                        className={pipelineStartupinterests && pipelineStartupinterests.interestType === 'Learn More' ? 'btn-radius sentiment-active' : 'btn-radius'}>Likely In</button>
                    </li>
                    <li>
                      {
                        props.popover && props.popover === `${pipelineInvestment._id}_participate` &&
                        <Popover
                          {...props}
                          className="participate"
                        />
                      }
                      <button onClick={(event) => {
                        event.stopPropagation();
                        props.investorSentimentInterest(pipelineInvestment, 'Participate')
                      }}
                        className={pipelineStartupinterests && pipelineStartupinterests.interestType === 'Participate' ? 'btn-radius sentiment-active' : 'btn-radius'}>Considering</button>
                    </li>
                    <li>
                      {
                        props.popover && props.popover === `${pipelineInvestment._id}_no_thanks` &&
                        <Popover
                          {...props}
                        />
                      }
                      <button onClick={(event) => {
                        event.stopPropagation();
                        props.investorSentimentInterest(pipelineInvestment, 'No Thanks')
                      }}
                        className={pipelineStartupinterests && pipelineStartupinterests.interestType === 'No Thanks' ? 'btn-radius sentiment-active' : 'btn-radius'}>No<br />Thanks</button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      } */}
      {
        fromSentiments && index < total - 1 &&
        <div className="pipeline-card">
        </div>
      }
    </>
  )
}
const mapStateToProps = state => {
  return {
    popover: state.popover && state.popover.id ? state.popover.id : null,
  };
};
export default connect(mapStateToProps, null)(PipelineInvestmentCard);
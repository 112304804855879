import React from 'react'
import './Start.scss';
import GraphView from '../../components/GraphView/Graph'
import DetailView from '../../components/DetailView/DetailView'

const redirect = () => {
    const url = new URL(window.location.href);
    const investorId = url.searchParams.get('investorId');
    if (investorId) {
        window.location.href=`/investor-dashboard?investorId=${investorId}`
    } else {
      window.location.href = `/investor-dashboard`;
    }
    
    
  }
  

const DashboardStart = (props) => (
    <>
    <div className='back-dashboard' onClick={()=>redirect(props)}>
      <button className='btn btn-danger' >Back to Dashboard</button>
    </div>
    <div className="new_investment">
        <div className="container-fluid">
            <h2>Investment Details</h2>

        </div>
    </div>
    <div className="invitations_sec new_investment">
        <div className="container-fluid">
            <div className="row">
                {/* <GraphView /> */}
                <DetailView />
            </div>
        </div>
    </div>
    </>
)


export default DashboardStart
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import RecommendAStartup from "../../../src/images/assets/images/RecommendAStartup.svg";
import ReferAMember from "../../../src/images/assets/images/ReferAMember.svg";
import MemberFaq from "../../../src/images/assets/images/faq.svg";
import TSGReview from "../../../src/images/assets/images/tsg_review.svg";
import {
  hideSidebarNotification,
  reset,
} from "../../InvestorDashboard/actions/hideSidebarNotifications";
import SidebarNotifications from "../../InvestorDashboard/components/SidebarNotifications";
import useScreenWidthLessThan768 from "../../InvestorDashboard/shared/window_resize_hook";
import { setUserLogin } from "../../UserLogin/actions/login";
import common from "../../common";
import images from "../../images";
import ProfileImage from "../ProfileImage";
import "./Header.scss";
import RecommendStartup from "./recommendStartup";
import ReferAFriendModal from "./referFriend";

// Higher Order Component to use the hook and pass values as props
function withWindowSize(Component) {
  return function WrappedComponent(props) {
    const size = useScreenWidthLessThan768();
    return <Component {...props} windowSize={size} />;
  };
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileToggle: false,
      id: "",
      isSidebarActive: false,
      isSidebarDataLoaded: false,
      sidebarNotificationData: [],
      updatedSidebarNotificationData: [],
      notificationsPageData: {
        currentPage: 0,
        totalPages: 1,
        total: 0,
      },
      isFromLoadMore: false,
      hasMore: false,
      screenHeight: 0,
      isAllRead: false,
    };
    this.isSidebarLoaded = false;
  }
  componentWillMount() {
    const id = window.localStorage.getItem("id");
    this.screenHeight = window.screen.height;
    if (this.screenHeight > 1200) {
      this.screenHeight = 800;
    }
    this.setState({ id: id });
    this.getNotifications();
  }

  getNotifications(isFromLoadMore) {
    const temp = this;
    let token = window.localStorage.getItem("token");
    const currentPage =
      this.state.notificationsPageData && isFromLoadMore
        ? this.state.notificationsPageData.currentPage + 1
        : 1;
    this.setState({ loadinglada: true });
    const data = {
      page: currentPage,
    };
    axios
      .post(`${common.path.base_url}/notifications`, data, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (response) {
        if (response && response.data && response.data.data) {
          let hasMore = false;
          if (response.data.currentPage < response.data.totalPages) {
            hasMore = true;
          }
          temp.setState({
            loading: false,
            sidebarNotificationData: isFromLoadMore
              ? [...temp.state.sidebarNotificationData, ...response.data.data]
              : response.data.data,
            notificationsPageData: {
              currentPage: response.data.currentPage,
              totalPages: response.data.totalPages,
              total: response.data.total,
            },
            isSidebarDataLoaded: true,
            hasMore,
          });
        }

        temp.setState({ isSidebarDataLoaded: true });
      })
      .catch(function (error) {
        temp.setState({ isSidebarDataLoaded: true });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else {
        }
      });
  }
  referFriend() { }
  bellIconClickHandler() {
    if (this.props.isSidebarHide) {
      this.props.reset();
      let sidebarNotificationData = this.state.sidebarNotificationData;
      if (sidebarNotificationData && sidebarNotificationData.length > 0) {
        sidebarNotificationData = sidebarNotificationData.map((item) => {
          return {
            ...item,
            isRead: true,
          };
        });
        this.setState({
          updatedSidebarNotificationData: sidebarNotificationData,
        });
      }
    } else {
      this.props.hideSidebarNotification();
    }
  }
  hideNotificationSidebar() {
    this.props.hideSidebarNotification();
  }
  getSidebarNotificationData() {
    return [...this.state.sidebarNotificationData];
  }
  componentWillReceiveProps() {
    const temp = this;
    if (
      this.state.updatedSidebarNotificationData.length > 0 &&
      this.props.isSidebarHide &&
      !this.isSidebarLoaded
    ) {
      this.isSidebarLoaded = true;
      let token = window.localStorage.getItem("token");
      axios
        .put(
          `${common.path.base_url}/notifications`,
          {},
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(function (response) {
          temp.setState({
            sidebarNotificationData: temp.state.updatedSidebarNotificationData,
            isAllRead: true,
          });
        })
        .catch(function (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message &&
            common.AUTH_EXPIRED === error.response.data.message
          ) {
            window.localStorage.clear();
            window.location.href = "/";
            return;
          } else {
          }
        });
    } else if (
      this.state.updatedSidebarNotificationData.length > 0 &&
      !this.props.isSidebarHide
    ) {
      temp.setState({ isAllRead: true });
    }
  }
  notificationRedirectHandler(data) {
    if (data && data.type) {
      switch (data.type) {
        case "invitation":
          if (window.location.href.includes("investor-dashboard")) {
            let elementExists = document.getElementById(
              `invitation_${data.investmentId}`
            );
            elementExists && this.props.hideSidebarNotification();
          } else {
            window.location.href = `/investor-dashboard`;
          }
          break;
        case "invitationReminders":
          if (window.location.href.includes("investor-dashboard")) {
            let elementExists = document.getElementById(
              `invitation_${data.investmentId}`
            );
            elementExists && this.props.hideSidebarNotification();
          } else {
            window.location.href = `/investor-dashboard`;
          }
          break;
        case "profileReminder":
          window.location.href = `/investment-process?investmentId=${data.investmentId}`;
          break;
        case "fundReminder":
          window.location.href = `/investment-process?investmentId=${data.investmentId}`;
          break;
        case "yearInReview":
          window.location.href = `/year-in-review`;
          break;
        case "docUploaded":
          window.location.href = `/investment-detail?investmentId=${data.investmentId}`;
          break;
        case "investmentCompleted":
          window.location.href = `/investment-detail?investmentId=${data.investmentId}`;
          break;
        case "adjustAmount":
          window.location.href = `/investment-process?investmentId=${data.investmentId}`;
          break;
        case "fundingInstructions":
          window.location.href = `/investment-process?investmentId=${data.investmentId}`;
          break;
        case "fundReceived":
          window.location.href = `/investment-process?investmentId=${data.investmentId}`;
          break;
        case "approveMaxAmount":
          window.location.href = `/investment-process?investmentId=${data.investmentId}`;
          break;
        default:
          break;
      }
    }
  }
  componentDidMount() {
    // let elementExists = document.getElementById('invitation_603d061ea0544cd92a785688');
    // console.log(184, elementExists);
  }
  isAllread() {
    const sidebarNotificationData = [...this.state.sidebarNotificationData];
    const filterIsUnRead =
      sidebarNotificationData &&
      sidebarNotificationData.length > 0 &&
      sidebarNotificationData.filter((item) => !item.isRead);
    if (filterIsUnRead && filterIsUnRead.length > 0) {
      return false;
    }
    return true;
  }

  render() {
    const roleCode = window.localStorage.getItem("roleCode");
    const { windowSize } = this.props;
    return (
      <>
        <ReferAFriendModal onRef={(ref) => (this.referFriendChild = ref)} />
        <RecommendStartup onRef={(ref) => (this.recommendStartupChild = ref)} />
        <div className="header" onClick={() => this.hideNotificationSidebar()}>
          <nav className="navbar navbar-default navbar-expand-xl navbar-light">
            <div className="navbar-header d-flex logo" style={{ justifyContent: "space-between" }}>
              <a className="logo-width" href=
                {this.props.redirectUrl ? this.props.redirectUrl : "/dashboard"}
              >
                <img src={images.path.productLogo} alt="" />
              </a>
              {roleCode && roleCode == "005" && windowSize && <>
                <a></a>
                <a></a>
              </>}
              <button
                id=""
                style={{ marginLeft: 10 }}
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#navbarCollapseMobile"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            </div>
            <div id="navbarCollapseMobile" className="navbar-collapse collapse">
              <ul className="nav navbar-nav navbar-right">
                <li id="user" className="nav-item">
                  <div className="user-details">
                    {roleCode && roleCode === "005" && (
                      <div
                        className="notification"
                        onClick={(event) => {
                          event.stopPropagation();
                          this.bellIconClickHandler();
                        }}
                      >
                        {this.isAllread() || this.state.isAllRead ? (
                          <img src={images.path.bellReadAll} alt="" />
                        ) : (
                          <>
                            <img src={images.path.bell} alt="" />
                            <div className="bell"></div>
                          </>
                        )}
                      </div>
                    )}
                    <ProfileImage
                      imgSrc={`${common.path.base_url}/getImage?id=${this.state.id}`}
                    />
                    <div id="name-role">
                      <b>{this.props.username} </b>&nbsp;&nbsp;&nbsp;&nbsp;
                      <br />
                      {this.props.role}
                    </div>
                  </div>
                  <div className="hr-line"></div>
                  <div className="user-dropdown">
                    <ul className="dropdown-menu11">
                      <li>
                        <a href="/dashboard" className="dropdown-item">
                          <i className="fa fa-dashboard"></i> Dashboard
                        </a>
                      </li>
                      <li className="divider dropdown-divider"></li>
                      <li>
                        <a
                          data-target={`#referFriendModal`}
                          data-toggle={`modal`}
                          className="dropdown-item">
                          <i className="fa fa-user-plus"></i> Refer A Member
                        </a>
                      </li>
                      <li className="divider dropdown-divider"></li>
                      <li>
                        <a
                          data-target={`#recommendStartupModal`}
                          data-toggle={`modal`}
                          className="dropdown-item">
                          <i className="fa fa-users"></i> Recommend A Startup
                        </a>
                      </li>
                      <li className="divider dropdown-divider"></li>
                      <li>
                        <a onClick={() => { window.open('/year-in-review', '_blank') }} className="dropdown-item">
                          <i className="fa fa-file-pdf-o"></i> TSG Review
                        </a>
                      </li>
                      <li className="divider dropdown-divider"></li>
                      <li>
                        <a onClick={() => { window.open('/investorFaqPreview', '_blank') }} className="dropdown-item">
                          <i className="fa fa-question-circle"></i> Member FAQ
                        </a>
                      </li>
                      <li className="divider dropdown-divider"></li>
                      <li>
                        <a href="/profile" className="dropdown-item">
                          <i className="fa fa-user-o"></i> Profile
                        </a>
                      </li>
                      <li className="divider dropdown-divider"></li>
                      {roleCode && roleCode === "005" && <>
                        <li>
                          <a href="/investorWallet" className="dropdown-item">
                            <i className="fa fa-university"></i> Wallet
                          </a>
                        </li>
                        <li className="divider dropdown-divider"></li>
                      </>}
                      <li id="logout">
                        <a
                          href="javascript:this.gotoLogin()"
                          onClick={() => {
                            let temp = this;
                            temp.props.history.push("/");
                            window.localStorage.clear();
                            temp.props.setUserLogin("Guest");
                          }}
                          className="dropdown-item"
                        >
                          <i className="material-icons">&#xE8AC;</i> Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div id="navbarCollapse" className="navbar-collapse collapse">
              <ul className="nav navbar-nav navbar-right">
                <li id="user" className="nav-item">
                  {roleCode && roleCode === "005" && (!windowSize && window.innerWidth > 1024) && (
                    <>
                      <div className="image-container" style={{ cursor: "pointer", marginRight: "-30px" }}>
                        <div className="hover-wrapper" style={{ width: "20%" }}>
                          <img
                            src={ReferAMember}
                            className="min-logo"
                            data-target={`#referFriendModal`}
                            data-toggle={`modal`}
                          />
                          <div className="watermelon-bar"></div>
                        </div>

                        <div className="hover-wrapper" style={{ width: "25%" }}>
                          <img
                            src={RecommendAStartup}
                            className="min-logo"
                            data-target={`#recommendStartupModal`}
                            data-toggle={`modal`}
                          />
                          <div className="watermelon-bar"></div>
                        </div>

                        <div className="hover-wrapper" style={{ width: "16%" }}>
                          <img
                            src={TSGReview}
                            className="min-logo"
                            onClick={() => {
                              window.open('/year-in-review', '_blank');
                            }}
                          />
                          <div className="watermelon-bar"></div>
                        </div>

                        <div className="hover-wrapper" style={{ width: "17%" }}>
                          <img
                            src={MemberFaq}
                            className="min-logo"
                            onClick={() => {
                              window.open('/investorFaqPreview', '_blank');
                            }}
                          />
                          <div className="watermelon-bar"></div>
                        </div>
                      </div>
                    </>
                  )}

                  <div
                    className="notification"
                    onClick={(event) => {
                      event.stopPropagation();
                      this.bellIconClickHandler();
                    }}
                  >
                    {this.isAllread() || this.state.isAllRead ? (
                      <img src={images.path.bellReadAll} alt="" />
                    ) : (
                      <>
                        <img src={images.path.bell} alt="" />
                        <div className="bell"></div>
                      </>
                    )}
                  </div>

                  <ProfileImage
                    imgSrc={`${common.path.base_url}/getImage?id=${this.state.id}`}
                  />
                  <div id="name-role">
                    <b>{this.props.username} </b>&nbsp;&nbsp;&nbsp;&nbsp;
                    <br />
                    {this.props.role}
                  </div>
                  <a
                    href="#"
                    id="circle-down"
                    data-toggle="dropdown"
                    className="nav-link dropdown-toggle user-action"
                  >
                    <i
                      className="fa fa-chevron-circle-down"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a href="/dashboard" className="dropdown-item">
                        <i className="fa fa-dashboard"></i> Dashboard
                      </a>
                    </li>
                    <li className="divider dropdown-divider"></li>
                    {(window.innerWidth === 768 || window.innerWidth < 1024) &&
                      <>
                        <li>
                          <a
                            data-target={`#referFriendModal`}
                            data-toggle={`modal`}
                            className="dropdown-item">
                            <i className="fa fa-user-plus"></i> Refer A Member
                          </a>
                        </li>
                        <li className="divider dropdown-divider"></li>
                        <li>
                          <a
                            data-target={`#recommendStartupModal`}
                            data-toggle={`modal`}
                            className="dropdown-item">
                            <i className="fa fa-users"></i> Recommend A Startup
                          </a>
                        </li>
                        <li className="divider dropdown-divider"></li>
                        <li>
                          <a onClick={() => { window.open('/year-in-review', '_blank') }} className="dropdown-item">
                            <i className="fa fa-file-pdf-o"></i> TSG Review
                          </a>
                        </li>
                        <li className="divider dropdown-divider"></li>
                        <li>
                          <a onClick={() => { window.open('/investorFaqPreview', '_blank') }} className="dropdown-item">
                            <i className="fa fa-question-circle"></i> Member FAQ
                          </a>
                        </li>
                        <li className="divider dropdown-divider"></li>
                      </>
                    }
                    <li
                      onClick={() =>
                        this.props.history.push(
                          `/viewProfile?id=${this.state.id}`
                        )
                      }
                    >
                      <a className="dropdown-item">
                        <i className="fa fa-user-o"></i> Profile
                      </a>
                    </li>
                    {/* <li><a href="/profile" className="dropdown-item"><i className="fa fa-user-o"></i> Profile</a></li> */}
                    <li className="divider dropdown-divider"></li>
                    {roleCode && roleCode === "005" && <>
                      <li>
                        <a href="/investorWallet" className="dropdown-item">
                          <i className="fa fa-university"></i> Wallet
                        </a>
                      </li>
                      <li className="divider dropdown-divider"></li>
                    </>}
                    <li>
                      <a
                        href="javascript:this.gotoLogin()"
                        onClick={() => {
                          let temp = this;
                          // window.localStorage.setItem('isLoggedIn', false);
                          temp.props.history.push("/");
                          // const ss = window.localStorage.getItem('isLoggedIn');
                          window.localStorage.clear();
                          temp.props.setUserLogin("Guest");
                        }}
                        className="dropdown-item"
                      >
                        <i className="material-icons">&#xE8AC;</i> Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            {/* {
                        roleCode && roleCode === '005' &&
                            <div className="refer-a-friend">
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    data-target={`#referFriendModal`}
                                    data-toggle={`modal`}
                                >Refer A Friend</button>
                            </div>

                    } */}
          </nav >
        </div >
        {
          this.state.isSidebarDataLoaded && (
            <SidebarNotifications
              isSidebarActive={this.state.isSidebarActive}
              hideNotificationSidebar={() => this.hideNotificationSidebar()}
              sidebarNotificationData={() => this.getSidebarNotificationData()}
              getNotifications={(isFromLoadMore) =>
                this.getNotifications(isFromLoadMore)
              }
              hasMore={this.state.hasMore}
              screenHeight={this.screenHeight}
              notificationRedirect={(data) =>
                this.notificationRedirectHandler(data)
              }
              {...this.props}
            />
          )
        }
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isSidebarHide:
      state.hideSidebarNotification && state.hideSidebarNotification.isHide
        ? true
        : false,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    reset: bindActionCreators(reset, dispatch),
    hideSidebarNotification: bindActionCreators(
      hideSidebarNotification,
      dispatch
    ),
    setUserLogin: bindActionCreators(setUserLogin, dispatch),
  };
};
const ConnectedHeader = withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

export default withWindowSize(ConnectedHeader);

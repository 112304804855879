import React, { Component } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import common from '../../common';


class IntialNotificationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            message: null,
            count: 0,
            isFromMultipleSendMessage: false,
            multipleId: [],
        }
    }

    componentWillReceiveProps(nextProps) {
        nextProps.onRef && nextProps.onRef(this);
    }

    resetIntialNotificationData() {
        this.setState({
            user: null,
            message: null,
            count: this.state.count + 1,
            isFromMultipleSendMessage: false,
        });

    }

    getMessage({ investmentType }) {
        // const isIPO = `You are receiving this email as an investor in [${companyName}] via [${correspondingllcname}]. [${companyName}] has announced an IPO which is currently set for [${ipodate}]. Because we will likely be subject to a six - month lock - up period along with other major investors, your specific return amount may change . We are in the process of attaining more specific information and will continue to keep you updated every step of the way.`;
        // const isAcquisition = `You are receiving this notification as you invested in [${companyName}] via [${correspondingllcname}]. We are in the process of attaining more specific information and will continue to keep you updated every step of the way.`;
        const isIPO = `You are receiving this email as an investor in [COMPANY NAME] via [CORRESPONDING LLC NAME]. [COMPANY NAME] has announced an IPO which is currently set for [IPO DATE]. Because we will likely be subject to a six - month lock - up period along with other major investors, your specific return amount may change . We are in the process of attaining more specific information and will continue to keep you updated every step of the way.`;
        const isAcquisition = `You are receiving this notification as you invested in [COMPANY NAME] via [CORRESPONDING LLC NAME]. [COMPANY NAME] has announced its acquisition by [AQUIRING COMPANY]. We are in the process of attaining more specific information and will continue to keep you updated every step of the way.`;
        if (investmentType === "ipo")
            return isIPO;
        else if (investmentType === "acquisition" || investmentType === "acquisitionwithescrow")
            return isAcquisition;
    };

    setData({
        user,
        companyName,
        investmentId,
        isFromMultipleSendMessage,
        investmentType,
        correspondingllcname,
        acquiringcompanyname,
        ipodate,
        usersid,
    }) {
        this.setState({
            user,
            multipleId: usersid,
            message: this.getMessage({ investmentType }),
            // this.getMessage({ companyName, investmentType, correspondingllcname, ipodate })
            investmentId,
            isFromMultipleSendMessage,
            correspondingllcname,
            acquiringcompanyname,
            ipodate,
            usersid,
            companyName,
            investmentType,

        })
    }

    replaceString(content, companyName, correspondingllcname, acquiringcompanyname, ipodate) {
        let finalContent = null;
        finalContent = content.replaceAll('[COMPANY NAME]', companyName);
        finalContent = finalContent.replaceAll('[CORRESPONDING LLC NAME]', '{CORRESPONDING LLC NAME}');
        finalContent = finalContent.replaceAll('[AQUIRING COMPANY]', acquiringcompanyname);
        finalContent = finalContent.replaceAll('[IPO DATE]', ipodate);
        return finalContent;
    }
    sendInitialNotification() {
        const finalMessage = this.replaceString(this.state.message, this.state.companyName, this.state.correspondingllcname, this.state.acquiringcompanyname, this.state.ipodate);
        let users = [];
        let investmentIds = [];
        if (this.state.isFromMultipleSendMessage) {
            this.state.multipleId && this.state.multipleId.length > 0 && this.state.multipleId.map((item) => {
                users.push(item.user._id);
                investmentIds.push(item.investmentId)
            });
            let temp = this;
            temp.setState({ loading: true });
            // temp.props.onInitialNotificationClick(true);
            const message = finalMessage;
            const url = new URL(window.location.href);
            const companyId = url.searchParams.get('id');
            let tokenpass = window.localStorage.getItem('token');
            const data = {
                companyId,
                users,
                investmentIds,
                message,
                isInitialCommunicationDone: true,
            };
            axios.post(`${common.path.base_url}/sendIntialNotificationMessage`, data, {
                headers: {
                    "Authorization": tokenpass
                }
            })
                .then((response) => {
                    swal({
                        title: "Email Sent",
                        text: `Email has been sent.`,
                        icon: "success",
                        button: "Ok!",
                    })
                        .then(function () {
                            window.location.reload();
                        });
                    temp.setState({ loading: false, user: null, message: null });
                    temp.props.onSendInitialNotification();
                    // temp.props.onInitialNotificationClick(false);

                })
                .catch(function (error) {
                    temp.setState({ loading: false });
                    // temp.props.onInitialNotificationClick(false);
                    if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        window.localStorage.clear();
                        window.location.href = '/';
                        return;
                    } else {
                        swal({
                            title: "Error!",
                            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                });

        } else {
            let temp = this;
            temp.setState({ loading: true });
            // temp.props.onInitialNotificationClick(true);
            let investmentIds = [];
            users.push(this.state.user._id);
            investmentIds.push(this.state.investmentId);
            const user = this.state.user;
            const message = finalMessage;
            const url = new URL(window.location.href);
            const companyId = url.searchParams.get('id');
            let tokenpass = window.localStorage.getItem('token');
            const data = {
                companyId,
                users,
                investmentIds,
                message,
            };
            axios.post(`${common.path.base_url}/sendIntialNotificationMessage`, data, {
                headers: {
                    "Authorization": tokenpass
                }
            })
                .then((response) => {
                    swal({
                        title: "Email Sent",
                        text: `Email has been sent to ${user.emailAddress}`,
                        icon: "success",
                        button: "Ok!",
                    })
                        .then(function () {

                        });
                    temp.setState({ loading: false, user: null, message: null });
                    temp.props.onSendInitialNotification();
                    // temp.props.onInitialNotificationClick(false);
                })
                .catch(function (error) {
                    temp.setState({ loading: false });
                    // temp.props.onInitialNotificationClick(false);
                    if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        window.localStorage.clear();
                        window.location.href = '/';
                        return;
                    } else {
                        swal({
                            title: "Error!",
                            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                });
        }
    }


    render() {
        return (
            <div id="intialNotificationModal" className="modal fade">
                <div className="modal-dialog">
                    <div className="modal-content invite-contact">
                        <div className="modal-header">
                            <h4 className="modal-title">Initial Notification</h4>
                        </div>
                        <div className="modal-body">
                            <form className="form-horizontal" id='myform'>
                                {
                                    !this.state.isFromMultipleSendMessage &&
                                    <>
                                        <div className="form-group">
                                            <label className="col-lg-4 col-sm-3  col-md-3 control-label">Name :</label>
                                            <div className="col-lg-8 col-sm-8 col-md-8">
                                                {this.state.user && this.state.user.firstName}
                                                {` `}
                                                {this.state.user && this.state.user.lastName}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-lg-4 col-sm-3  col-md-3 control-label">Email :</label>
                                            <div className="col-lg-8 col-sm-8 col-md-8">
                                                {this.state.user && this.state.user.emailAddress}
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="form-group">
                                    <label className="col-lg-4 col-sm-3  col-md-3 control-label">MSG :</label>
                                    <div className="col-lg-8 col-sm-8 col-md-8">
                                        <textarea
                                            key={this.state.count}
                                            className="form-control" name='preferredStockTerms' id="notes" type="message" placeholder='Message' rows="4" cols="50"
                                            onChange={(evt) => this.setState({ message: evt.target.value })}
                                            value={this.state.message}></textarea>
                                        <span>{"Note: Please do not modify the variable in []"}</span>
                                    </div>

                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <LaddaButton
                                id='lada-btn'
                                loading={this.state.loadinglada}
                                onClick={() => this.sendInitialNotification()}
                                data-color="#eee"
                                data-size={XS}
                                data-style={EXPAND_RIGHT}
                                data-spinner-size={30}
                                data-spinner-color="#ddd"
                                data-spinner-lines={12}
                                data-dismiss="modal"
                                disabled={this.state.message ? false : true}
                                className="send-button-modal"
                            >
                                {'SEND'}
                            </LaddaButton>
                            <input type="button" id="cancelAgreementModal" className="btn btn-default btn-cancel" data-dismiss="modal" value="CANCEL" onClick={() => { }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default IntialNotificationModal;
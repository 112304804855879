import React, { Component } from 'react';
import './Press.scss';
import LoadingSpinner1 from '../../../components/IIR Loader';
import { withRouter } from 'react-router-dom';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import axios from 'axios';
import swal from 'sweetalert';
import common from '../../../common';
import _ from 'lodash';
import Modal from 'react-bootstrap-modal';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

// Imported Images
import images from "../../../images";

const SortableItem = SortableElement(({ value, deletePress, editClick }) =>
    <div className='press-options'>
        <div className='press-option '>
            <h4>{value.title}</h4>
            <div className='icons'>
                <i onClick={() => editClick(value)}
                    className="fa fa-pencil add-address"></i>
                <img src={images.path.deleteIcon} onClick={() => deletePress(value._id)}></img>
            </div>
        </div>
        <a href={value.link} className='press-content'>{value.link}</a>
    </div>);

const SortableList = SortableContainer(({ items, deletePress, editClick }) => {
    return (
        <ul>
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} deletePress={(i) => deletePress(i)} editClick={(value) => editClick(value)} />
            ))}
        </ul>
    );
});


class Press extends Component {
    constructor(props) {
        super(props);
        this.companyId = '';
        this.investmentId = '';
        this.state = {
            title: '',
            link: '',
            PressData: [],
            loading: false,
            loadinglada: false,
            loadinglada2: false,
            Role: '',
            titleValidation: '',
            linkValidation: '',
            profileFormValidated: false,
            show: false,
            titleUpadte: '',
            linkUpdate: '',
            titleUpadteValidation: '',
            linkUpdateValidation: '',
            pressId: '',
            enterKeyState: false,
        }
    }

    componentWillMount() {
        const url = new URL(window.location.href);
        this.companyId = url.searchParams.get('companyId');
        this.investmentId = url.searchParams.get('investmentId');
        // this.companyId = window.localStorage.getItem('companyId');
        this.getPressData();
    }

    componentDidUpdate() {
        document.addEventListener('keypress', function (event) {
            if (event.keyCode === 13) {
                // event.preventDefault()
            }
        })
    }
    onKeyPress(event) {
        if (event.which === 13 /* Enter */) {
            event.preventDefault();
        }
    }

    getIIRList() {
        let temp = this;
        temp.setState({ loadinglada1: true });
        let token = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/getInvestmentIIR/${this.investmentId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                const sampleArray = response.data.data
                const keys = Object.keys(sampleArray)
                const values = Object.values(sampleArray)
                let z = [];
                keys.forEach((key, i) => {
                    // z.push({ title: key, state: values[i] })
                    z.push(common.iirMapKey({ key, values, i }))
                })
                z.forEach((ele, i) => {
                    let title = ele.path.split(" ");
                    let path = '';
                    for (const word of title) {
                        path += word;
                    }
                    path = path.substring(0, 1).toLowerCase() + path.substring(1);
                    ele.path = path;
                })
                z.length = common.iirArrayLength;
                this.props.setIirList(z);
                // this.props.history.push(`/iir/marketSize?companyId=${this.companyId}`);
                temp.setState({ loadinglada1: false });

            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    getPressData() {
        let temp = this;
        temp.setState({ loading: true })
        let token = window.localStorage.getItem('token');
        const id = window.localStorage.getItem('id');
        this.setState({ id: id })
        axios.get(`${common.path.base_url}/getIIRPressInvestment/${this.investmentId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                this.setState({ PressData: response.data.data });
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    addPressData() {
        let temp = this;
        temp.setState({ loadinglada: true });
        let tokenpass = window.localStorage.getItem('token');
        axios.post(`${common.path.base_url}/addIIRPressInvestment`, {
            companyId: this.companyId,
            investmentId: this.investmentId,
            title: this.state.title,
            link: temp.state.link,
        }, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then(function (response) {
                temp.setState({ loadinglada: false });
                swal({
                    title: "Added",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(function () {
                        temp.getPressData();
                        temp.resetFields();
                        window.location.reload();
                    });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                  }
               else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });

            });
    }

    deletePress(index) {
        this.setState({ enterKeyState: true })
        // if (this.state.PressData.length === 1) {
        //     swal({
        //         title: "Warning",
        //         text: "Can not delete the last data",
        //         icon: "warning",
        //         buttons: true,
        //         dangerMode: true,
        //     })
        //         .then(() => {
        //             this.setState({ enterKeyState: false })
        //         })
        // }
        // else {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willdelete) => {
                const token = localStorage.getItem('token');
                this.setState({ loading: false, enterKeyState: false })
                if (willdelete) {
                    this.setState({ loading: true })
                    axios.delete(`${common.path.base_url}/deleteIIRPress`, { headers: { "Authorization": token }, data: { id: index } }
                    )
                        .then((response) => {
                            this.setState({ loading: false })
                            swal({
                                title: "Success!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            })
                                .then(() => {
                                    this.getPressData();
                                });

                        })
                        .catch((error) => {
                            this.setState({ loading: false });
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else {
                                swal({
                                    title: "Error!",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });
                }
            })
        // }
    }

    updatePress() {
        let temp = this;
        temp.setState({ loadinglada2: true });
        const token = localStorage.getItem('token');
        axios.put(`${common.path.base_url}/updateIIRPress`, {
            companyId: this.companyId,
            title: this.state.titleUpadte,
            link: temp.state.linkUpdate,
            id: this.state.pressId
        },
            {
                headers: {

                    "Authorization": token
                }
            })
            .then((response) => {
                swal({
                    title: "Updated!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(() => {
                        temp.getPressData();
                        temp.setState({ show: false })
                    })
                temp.setState({ loadinglada2: false, profileFormValidated: false });
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                    temp.setState({ loadinglada2: false });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    handleChange(id, newValue) {
        if (id === "title")
            this.setState({ title: newValue });
        else if (id === 'link')
            this.setState({ link: newValue });
        else if (id === 'linkUpdate')
            this.setState({ linkUpdate: newValue });
        else if (id === 'titleUpdate')
            this.setState({ titleUpadte: newValue });
    }

    formValidaton(id, val) {
        if (id === 'title') {
            if (val === '') {
                this.setState({
                    titleValidation: "Title should not be empty*",
                })
            } else {
                this.setState({
                    titleValidation: "",
                })
            }
        }
        else if (id === 'link') {
            let websiteRegex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
            if (val === '') {
                this.setState({
                    linkValidation: "Link should not be empty*",
                })
            } else if (!websiteRegex.test(val)) {
                this.setState({
                    linkValidation: "Please enter Valid Link*",
                })
            }
            else {
                this.setState({
                    linkValidation: "",
                })
            }
        }
        else if (id === 'titleUpdate') {
            if (val === '') {
                this.setState({
                    titleUpadteValidation: "Title should not be empty*",
                })
            } else {
                this.setState({
                    titleUpadteValidation: "",
                })
            }
        } else if (id === 'linkUpdate') {
            let websiteRegex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
            if (val === '') {
                this.setState({
                    linkUpdateValidation: "Link should not be empty*",
                })
            } else if (!websiteRegex.test(val)) {
                this.setState({
                    linkUpdateValidation: "Please enter Valid Link*",
                })
            } else {
                this.setState({
                    linkUpdateValidation: "",
                })
            }
        }
    }

    resetFields() {
        this.setState({
            title: '',
            link: ''
        })
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState(({ PressData }) => ({
            PressData: arrayMove(PressData, oldIndex, newIndex),
        }));
        let order = this.state.PressData.map((ele, i) => {
            return ele.order
        })
        let id = this.state.PressData.map((ele, i) => {
            return ele._id
        })
        const token = localStorage.getItem('token');
        axios.put(`${common.path.base_url}/updateOrderPress`, {
            id: id[0],
            sequence: order,
        },
            {
                headers: {

                    "Authorization": token
                }
            })
            .then((response) => {
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else { }
            });
    }

    onEditClick(element) {
        this.setState({ show: true, titleUpadte: element.title, linkUpdate: element.link, pressId: element._id })
    }

    render() {
        return (
            <div className="press">
                {this.state.loading ? <LoadingSpinner1 /> : ""}
                <div className="press-main">
                    <div className='heading'>
                        <h3>Press</h3>
                    </div>
                    <div className="press">
                        <div className='press-details'>
                            <React.Fragment>
                                <form className="form-horizontal">
                                    <div className="form-group">
                                        <label className="col-md-3 control-label">Title:</label>
                                        <div className="col-md-8">
                                            <input className="form-control" id="title" type='text' placeholder='Enter Title'
                                                onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                onKeyPress={this.onKeyPress}
                                                value={this.state.title} />
                                            <p >{this.state.titleValidation}</p>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-3 control-label">Link:</label>
                                        <div className="col-md-8">
                                            <input className="form-control" id="link" type="message" placeholder='Enter Link'
                                                onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                onKeyPress={this.onKeyPress}
                                                value={this.state.link} />
                                            <p>{this.state.linkValidation}</p>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-3 control-label1"></label>
                                        <div className="col-md-8 addNewButton ">
                                            <LaddaButton
                                                id="update-btn"
                                                loading={this.state.loadinglada}
                                                onClick={() => this.addPressData()}
                                                data-color="#eee"
                                                data-size={XS}
                                                data-style={EXPAND_RIGHT}
                                                data-spinner-size={30}
                                                data-spinner-color="#ddd"
                                                data-spinner-lines={12}
                                                className={_.isEmpty(this.state.title && this.state.link) || !_.isEmpty(this.state.linkValidation) ? 'disable' : ''}
                                            >
                                                ADD NEW
                                            </LaddaButton>
                                            <div className="save-btn">
                                                <LaddaButton
                                                    id="update-btn"
                                                    loading={this.state.loadinglada1}
                                                    onClick={() => this.getIIRList()}
                                                    data-color="#eee"
                                                    data-size={XS}
                                                    data-style={EXPAND_RIGHT}
                                                    data-spinner-size={30}
                                                    data-spinner-color="#ddd"
                                                    data-spinner-lines={12}
                                                    className={_.isEmpty(this.state.PressData) ? 'disable' : ''}
                                                >
                                                    SAVE
                                            </LaddaButton>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </React.Fragment>
                        </div>
                    </div>
                    <div className="footBorder"></div>
                    <SortableList
                        pressDelay={200}
                        items={this.state.PressData}
                        onSortEnd={this.onSortEnd}
                        deletePress={(i) => this.deletePress(i)}
                        editClick={(value) => this.onEditClick(value)}
                    />
                </div>
                <Modal
                    aria-labelledby="ModalHeader"
                    show={this.state.show}
                >
                    <Modal.Header>
                        <Modal.Title id='ModalHeader'>Update Key Investors</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="majorInvestor">
                            <form className="form-horizontal">
                                <div className="form-group">
                                    <label className="col-md-3 control-label">Title:</label>
                                    <div className="col-md-8">
                                        <input className="form-control" id="titleUpdate" type='text' placeholder='Enter Title'
                                            onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                            onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                            value={this.state.titleUpadte} />
                                        <p >{this.state.titleUpadteValidation}</p>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-md-3 control-label">Link:</label>
                                    <div className="col-md-8">
                                        <input className="form-control" id="linkUpdate" type="message" placeholder='Enter Link'
                                            onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                            onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                            value={this.state.linkUpdate} />
                                        <p>{this.state.linkUpdateValidation}</p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <LaddaButton
                            id='lada-btn'
                            loading={this.state.loadinglada2}
                            data-color="#eee"
                            data-size={XS}
                            onClick={() => this.updatePress()}
                            className={_.isEmpty(this.state.titleUpadte && this.state.linkUpdate) || !_.isEmpty(this.state.linkUpdateValidation) ? 'disable' : ''}
                            data-style={EXPAND_RIGHT}
                            data-spinner-size={30}
                            data-spinner-color="#ddd"
                            data-spinner-lines={12}
                        >UPDATE</LaddaButton>
                        <input type="button" id='company-cancel' className="btn btn-default" data-dismiss="modal" value="CANCEL" onClick={() => this.setState({ companyName1: '', inputFile1: false, show: false })} />
                    </Modal.Footer>
                </Modal>
            </div>

        );
    }
}
export default withRouter(Press);

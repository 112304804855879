import React, { Component } from 'react';
import './ImportContacts.scss';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import FileUploadBlobDoc from '../../../components/FileUploadBlobDoc';
import RequiredSign from '../../../components/RequiredSign';
import axios from 'axios';
import swal from 'sweetalert';
import common from '../../../common';



class ImportContactsModal extends Component {
    constructor() {
        super();
        this.state = {
            data: null
        }
    }
    componentWillReceiveProps(nextProps) {
        nextProps.onRef && nextProps.onRef(this);

    }
    setData(data, callback) {
        console.log(25, data);
        this.setState({ data })
        setTimeout(() => {
            callback()
        }, 100);

    }
    resetData() {
        this.setState(
            {
                data: null,

            })
    }
    downloadContactConflictsFile() {
        window.open(` ${common.path.doc_download_url}/downloadContactConflictsFile?id=${this.state.data.id}`, '_blank', '', 2)

    }
    proceedUpload() {
        let token = window.localStorage.getItem('token');
        let temp = this;
        temp.setState({ loadinglada: true });
        let data = new FormData();
        const param = JSON.stringify({
            companyId: this.state.data.companyId,
            investmentId: this.state.data.investmentId,
            createdFor: this.state.data.createdFor,
            documentId: this.state.data.id,
            isAccept: true,
        })
        data.append('params', param);
        axios.post(`${common.path.base_url}/imortContacts`, data,
            {
                headers: {

                    "Authorization": token
                }
            })
            .then(function (response) {
                temp.setState({ loadinglada: false });
                const uploadedContacts = temp.state.data.totalContacts - temp.state.data.conflictsContacts
                const totalContacts = temp.state.data.totalContacts;
                document.getElementById('cancelUploadDoc').click();
                swal({
                    title: "Success!",
                    text: `${uploadedContacts} out of ${totalContacts} contacts added or modified with new referral startup successfully!`,
                    icon: "success",
                    button: "Ok!",
                });

            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response && error.response.data && error.response.data.code && error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });
            });

    }
    render() {
        return (
            <>
                {
                    this.state.data &&
                    <div id="successImportContactsModal" className="modal fade">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Import Contacts</h4>
                                </div>
                                <div className="modal-body">
                                    <div className="form-horizontal" id=' myform'>
                                        <div className="form-group">
                                            <label className="col-sm-6  col-md-6 control-label" style={{ "paddingLeft": "0px" }}>Total Contacts :</label>
                                            <div className="col-sm-6 col-md-6 upload-section" style={{ "padding": "0px", "border": "none" }}>

                                                <div className="upload-block">
                                                    {this.state.data.totalContacts}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-6  col-md-6 control-label" style={{ "paddingLeft": "0px" }}>Conflicts Contacts :</label>
                                            <div className="col-sm-6 col-md-6 upload-section" style={{ "padding": "0px", "border": "none" }}>

                                                <div className="upload-block">
                                                    {this.state.data.conflictsContacts}
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.state.data &&
                                                this.state.data.conflictsContacts &&
                                                this.state.data.conflictsContacts > 0 ?
                                                <div className="form-group">
                                                    <div className="col-sm-6 col-md-6 upload-section" style={{ "padding": "0px", "border": "none" }}>
                                                        <div className="upload-block">
                                                            <a style={{ color: '#F15B4B', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.downloadContactConflictsFile()}>Download Conflict Contact File</a>
                                                        </div>
                                                    </div>
                                                </div> :
                                                ''
                                        }

                                        {
                                            !this.state.Proceed &&
                                                this.state.data &&
                                                this.state.data.conflictsContacts &&
                                                this.state.data.conflictsContacts > 0 &&
                                                this.state.data.totalContacts > this.state.data.conflictsContacts &&
                                                !this.state.data.isAccept ?
                                                <>
                                                    <div className="form-group">
                                                        <div className="col-sm-12 col-md-12 upload-section" style={{ "padding": "0px", "border": "none" }}>

                                                            <div className="upload-block">
                                                                {this.state.data.totalContacts - this.state.data.conflictsContacts} Out of {this.state.data.totalContacts} contacts will be added or modified with new referral startup.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <LaddaButton
                                                            id='lada-btn'
                                                            loading={this.state.loadinglada}
                                                            onClick={() => this.proceedUpload()}
                                                            data-color="#eee"
                                                            data-size={XS}
                                                            data-style={EXPAND_RIGHT}
                                                            data-spinner-size={30}
                                                            data-spinner-color="#ddd"
                                                            data-spinner-lines={12}
                                                        >
                                                            PROCEED
                                            </LaddaButton>
                                                        <input type="button" id="cancelUploadDoc" className="btn btn-default" data-dismiss="modal" onClick={() => this.resetData()} value="CANCEL" />
                                                    </div> </> :
                                                <div className="modal-footer">
                                                    <input type="button" id="cancelUploadDoc" className="btn btn-default" data-dismiss="modal" onClick={() => this.resetData()} value="DONE" />
                                                </div>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </>
        );
    }
}
export default ImportContactsModal;
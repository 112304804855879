import React, { Component } from "react";
import "./Preview.scss";
import "react-phone-input-2/lib/style.css";
import Header from "../../../components/Header";
import HeaderImg from "../../../components/HeaderImage";
import LoadingSpinner from "../../../components/Loader";
import common from "../../../common";
import { withRouter } from "react-router-dom";
import ProfileImage from "../../../components/ProfileImage";
import LaddaButton, { XS, EXPAND_RIGHT } from "react-ladda";
import axios from "axios";
import swal from "sweetalert";
import images from "../../../images";
import _ from "lodash";
import TButton from "../../../components/TButton";
import ReactToPrint from "react-to-print";
import { Markup } from "interweave";

class Preview extends Component {
  constructor(props) {
    super(props);
    this.companyId = "";
    this.investmentId = "";
    this.state = {
      companyLogoId: "",
      overview: "",
      description: "",
      lastFunding: "",
      totalFunding: "",
      employees: "",
      IPOStatus: "",
      leadershipData: [],
      boardData: [],
      majorInvestorData: [],
      currentPartnersData: [],
      investmentOverview: [],
      keyInvestorData: [],
      PressData: [],
      marketOverviewData: [],
      marketTrendData: [],
      marketPerfData: [],
      fundingDescData: [],
      productDescData: [],
      problemStatementDescData: [],
      solutionSummaryDescData: [],
      differentiation: [],
      competitionDescData: [],
      histAndProjRevenueData: [],
      iirList: [],
    };
  }
  componentWillMount() {
    const url = new URL(window.location.href);
    this.companyId = url.searchParams.get("companyId");
    this.investmentId = url.searchParams.get("investmentId");
    this.getIIRList();
    this.getData();
    this.getLeadershipData();
    this.getBoardData();
    this.getMajorInvestorData();
    this.getCurrentPartnersData();
    this.getKeyInvestorData();
    this.getPressData();
    this.getmarketOverviewData();
    this.getmarketTrendData();
    this.getmarketPrefData();
    this.getfundingDescData();
    this.getproductDescData();
    this.getproblemStatementDescData();
    this.getsolutionSummaryDescData();
    this.getDifferentiation();
    this.getcompetitionDescData();
    this.gethistAndProjRevenueData();
    this.getInvestmentOverviewData();
    const userRole = window.localStorage.getItem("role");
    this.setState({ Role: userRole });
  }

  getIIRList() {
    let temp = this;
    let token = window.localStorage.getItem("token");
    axios
      .get(`${common.path.base_url}/getInvestmentIIR/${this.investmentId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        temp.setState({ loading: false });
        const sampleArray = response.data.data;
        const keys = Object.keys(sampleArray);
        const values = Object.values(sampleArray);
        let z = [];
        keys.forEach((key, i) => {
          // z.push({ title: key, state: values[i] })
          z.push(common.iirMapKey({ key, values, i }));
        });
        z.length = common.iirArrayLength;
        z = z.filter((element) => element.state == true);
        console.log(z);
        this.setState({ iirList: z });
        console.log(this.state.iirList);
        // temp.props.history.push(`/iir/leadership?companyId=${this.companyId}`);
        temp.setState({ loadinglada: false });
      })
      .catch(function (error) {
        temp.setState({ loadinglada: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.code === 204
        ) {
          window.localStorage.clear();
          window.location.href = "/";
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }

  getData() {
    let temp = this;
    temp.setState({ loading: true });
    const id = window.localStorage.getItem("id");
    let token = window.localStorage.getItem("token");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getIIRCompany?companyId=${this.companyId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        const companyDetails = response.data.data[0];
        this.setState({
          overview: companyDetails.overview,
          description: companyDetails.description,
          address: companyDetails.address,
          companyLogo: companyDetails.companyLogo,
          name: companyDetails.companyName,
          lastFunding: companyDetails.lastFunding,
          totalFunding: companyDetails.totalFunding,
          employees: companyDetails.numberOfEmployee,
          IPOStatus: companyDetails.IPOStatus,
        });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getLeadershipData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getBoardorLeaderIIR/${this.investmentId}/Leadership`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({
          leadershipData: response.data.data,
        });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getBoardData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getBoardorLeaderIIR/${this.investmentId}/Board`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ boardData: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getMajorInvestorData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getCustomerorInvestorInvestment/${this.investmentId}/MajorInvestor`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ majorInvestorData: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getCurrentPartnersData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getCustomerorInvestorInvestment/${this.investmentId}/CurrentPartners`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ currentPartnersData: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getKeyInvestorData() {
    let temp = this;
    let token = window.localStorage.getItem("token");
    temp.setState({ loading: true });
    axios
      .get(
        `${common.path.base_url}/getCustomerorInvestorInvestment/${this.investmentId}/KeyCustomers`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ keyInvestorData: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getPressData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getIIRPressInvestment/${this.investmentId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ PressData: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getmarketOverviewData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getMarketInvestment/${this.investmentId}/MarketSize`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ marketOverviewData: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getmarketTrendData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getMarketInvestment/${this.investmentId}/MarketTrend`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ marketTrendData: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getmarketPrefData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getMarketInvestment/${this.investmentId}/MarketPerf`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ marketPerfData: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getfundingDescData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getMarketInvestment/${this.investmentId}/FundingDesc`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ fundingDescData: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getproductDescData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getMarketInvestment/${this.investmentId}/ProductDesc`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ productDescData: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getproblemStatementDescData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getMarketInvestment/${this.investmentId}/ProblemStatementDesc`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ problemStatementDescData: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getsolutionSummaryDescData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getMarketInvestment/${this.investmentId}/SolutionSummaryDesc`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ solutionSummaryDescData: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }

  getDifferentiation() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getMarketInvestment/${this.investmentId}/Differentiation`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ differentiation: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getcompetitionDescData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getMarketInvestment/${this.investmentId}/CompetitionDesc`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ competitionDescData: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  gethistAndProjRevenueData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getMarketInvestment/${this.investmentId}/HistAndProjRevenue`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ histAndProjRevenueData: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getInvestmentOverviewData() {
    let temp = this;
    // temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getIIRInvestmentOverview/${this.investmentId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        // this.setState({ investmentOverviewData: response.data.data });
        if (response.data.data && response.data.data.length > 0) {
          temp.setState({ loading: false });
          console.log(720, response.data.data);
          temp.setState({ investmentOverview: response.data.data });
        } else {
          this.setState({ investmentOverviewId: null });
        }
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  render() {
    const username = window.localStorage.getItem("username");
    const roleCode = window.localStorage.getItem("roleCode");
    return (
      <div>
        <div className="preview">
          {this.state.loading ? <LoadingSpinner /> : ""}
          <Header username={username} role={this.state.Role} />
          <HeaderImg heading={`Investment Intelligence Report`} />
          <div
            className="preview-main"
            ref={(elements) => (this.componentRef = elements)}
          >
            <div className="close-btn icons">
              <ReactToPrint
                trigger={() => <TButton text={`Print`} className="share-btn" />}
                content={() => this.componentRef}
                pageStyle={"padding : 40px"}
              />
            </div>
            <div className="cancel-btn-wraper">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={() => this.props.history.goBack()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="preview-heading">
              <div className="previewLogo">
                <img
                  className="companyLogo-pic"
                  src={`${common.path.base_url}/getFileNew?fileId=${this.state.companyLogo}`}
                  alt=""
                />
                <div className="leftComp icons">{this.state.name}</div>
              </div>
              <div className="rightPreview">
                <div className="compHeading">{this.state.name}</div>
                <div className="preview-address">{this.state.address}</div>
              </div>
            </div>
            <div className="preview-container">
              {/* ---Section -Company Overview--- */}
              {this.state.iirList.find(
                (element) => element.title == "Company Overview"
              ) ? (
                <div className="preview-company">
                  {roleCode !== "005" && (
                    <h3 className="edit-section">
                      <span className="prevHead">Company </span> Overview{" "}
                      <i
                        className="fa fa-pencil add-address icons"
                        onClick={() =>
                          this.props.history.push(
                            `/iir/company?companyId=${this.companyId !== undefined ? this.companyId : ""
                            }&investmentId=${this.investmentId}`
                          )
                        }
                      ></i>
                    </h3>
                  )}
                  <div
                    className="companyoverview-content overview-content"
                    style={{ marginTop: "10px" }}
                  >
                    {this.state.description}
                  </div>
                  {/* <h3><span className='prevHead'>Company </span>Overview <i  className="fa fa-pencil add-address" onClick={() => this.props.history.push(`/iir/company?companyId=${this.companyId !== undefined ? this.companyId : ''}`)}></i></h3> */}
                  <div className="companyoverview-content overview-content">
                    <Markup content={this.state.overview} />
                  </div>
                  <div className="companyoverview-content">
                    <span className="content"> Total Funding to Date : </span>{" "}
                    {this.state.totalFunding}{" "}
                  </div>
                  <div className="companyoverview-content">
                    <span className="content">
                      {" "}
                      Last Funding Round and Date :{" "}
                    </span>{" "}
                    {this.state.lastFunding}{" "}
                  </div>
                  <div className="companyoverview-content">
                    <span className="content"> Number of Employees : </span>{" "}
                    {this.state.employees}{" "}
                  </div>
                  <div className="companyoverview-content">
                    <span className="content"> IPO Status : </span>{" "}
                    {this.state.IPOStatus}{" "}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {/* ---Section Investment Overview--- */}
              {this.state.iirList.find(
                (element) => element.title == "Investment Overview"
              ) ? (
                <div className="preview-company">
                  {roleCode !== "005" && (
                    <h3 className="edit-section">
                      <span className="prevHead">Investment </span> Overview{" "}
                      <i
                        className="fa fa-pencil add-address icons"
                        onClick={() =>
                          this.props.history.push(
                            `/iir/company?companyId=${this.companyId !== undefined ? this.companyId : ""
                            }&investmentId=${this.investmentId}`
                          )
                        }
                      ></i>
                    </h3>
                  )}
                  {this.state.investmentOverview &&
                    this.state.investmentOverview.length > 0 && (
                      <>
                        {this.state.investmentOverview[0].investmentType ===
                          "series" ? (
                          <div
                            className="companyoverview-content"
                            style={{ marginTop: "10px" }}
                          >
                            <span className="content">
                              {" "}
                              Participating directly in the Series{" "}
                              {this.state.investmentOverview[0].series}, buying
                              preferred shares at $
                              {this.state.investmentOverview[0].share} per share{" "}
                            </span>
                          </div>
                        ) : this.state.investmentOverview[0].investmentType ===
                          "percentage" ? (
                          <div
                            className="companyoverview-content"
                            style={{ marginTop: "10px" }}
                          >
                            <span className="content">
                              Investing in the future Series{" "}
                              {this.state.investmentOverview[0].series}, buying
                              preferred shares in advance at a{" "}
                              {this.state.investmentOverview[0].share}% discount
                              to the pricing of the future round
                            </span>{" "}
                            {this.state.investmentOverview[0].percentage}{" "}
                          </div>
                        ) : null}
                        <div
                          className="companyoverview-content overview-content"
                          style={{ marginTop: "10px", fontStyle: "italic" }}
                        >
                          <Markup
                            content={this.state.investmentOverview[0].message}
                          />
                        </div>
                      </>
                    )}
                </div>
              ) : (
                <div></div>
              )}
              {/* ---Section -Leadership--- */}
              {this.state.iirList.find(
                (element) => element.title == "Leadership"
              ) ? (
                <div className="preview-company">
                  {roleCode !== "005" && (
                    <h3 className="edit-section">
                      Leadership{" "}
                      <i
                        className="fa fa-pencil add-address icons"
                        onClick={() =>
                          this.props.history.push(
                            `/iir/leadership?companyId=${this.companyId !== undefined ? this.companyId : ""
                            }&investmentId=${this.investmentId}`
                          )
                        }
                      ></i>
                    </h3>
                  )}

                  {this.state.leadershipData.map((element) => {
                    return (
                      <div className="leader-detail">
                        <div className="tsgProfile-img">
                          <ProfileImage
                            imgSrc={`${common.path.base_url}/getFileNew?fileId=${element.picture}`}
                          />
                        </div>
                        <div className="leader-profile">
                          <h4> {` ${element.fullName}`}</h4>
                          <div className="marketOverview-content">
                            {" "}
                            {` ${element.title}`}{" "}
                          </div>
                          <div className="marketOverview-content">
                            {" "}
                            <Markup content={element.bio} />{" "}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div></div>
              )}
              {/* ---Section -Board--- */}
              {this.state.iirList.find(
                (element) => element.title == "Board"
              ) ? (
                <div className="preview-company">
                  {roleCode !== "005" && (
                    <h3 className="edit-section">
                      Board{" "}
                      <i
                        className="fa fa-pencil add-address icons"
                        onClick={() =>
                          this.props.history.push(
                            `/iir/board?companyId=${this.companyId !== undefined ? this.companyId : ""
                            }&investmentId=${this.investmentId}`
                          )
                        }
                      ></i>
                    </h3>
                  )}
                  {this.state.boardData.map((element, index) => {
                    return (
                      <div className="board-detail">
                        <div className="tsgProfile-img">
                          <ProfileImage
                            imgSrc={`${common.path.base_url}/getFileNew?fileId=${element.picture}`}
                          />
                        </div>
                        <div className="board-profile">
                          <h4> {` ${element.fullName}`}</h4>
                          <div className="marketOverview-content">
                            {` ${element.title}`}{" "}
                          </div>
                          <div className="marketOverview-content">
                            <Markup content={element.bio} />{" "}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div></div>
              )}
              {/* ---Section -Current Partners--- */}
              {this.state.iirList.find(
                (element) => element.title == "Current Partners"
              ) ? (
                <div className="preview-company">
                  {roleCode !== "005" && (
                    <h3 className="edit-section">
                      <span className="prevHead">Current</span> Partners{" "}
                      <i
                        className="fa fa-pencil add-address icons"
                        onClick={() =>
                          this.props.history.push(
                            `/iir/majorInvestor?companyId=${this.companyId !== undefined ? this.companyId : ""
                            }&investmentId=${this.investmentId}`
                          )
                        }
                      ></i>
                    </h3>
                  )}
                  <div className="major-investors">
                    {this.state.currentPartnersData.map((element, index) => {
                      return (
                        <div className="investors-detail">
                          <div className="tsgPreview-img">
                            <ProfileImage
                              imgSrc={`${common.path.base_url}/getFileNew?fileId=${element.logo}`}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {/* Market-Overview */}
              {this.state.iirList.find(
                (element) => element.title == "Market Overview"
              ) ? (
                <div className="preview-company">
                  {roleCode !== "005" && (
                    <h3 className="edit-section">
                      <span className="prevHead">Market Overview</span>{" "}
                      <i
                        className="fa fa-pencil add-address icons"
                        onClick={() =>
                          this.props.history.push(
                            `/iir/marketPerformanceandSummary?companyId=${this.companyId !== undefined ? this.companyId : ""
                            }&investmentId=${this.investmentId}`
                          )
                        }
                      ></i>
                    </h3>
                  )}
                  {this.state.marketPerfData.map((element, i) => {
                    return (
                      <div className="market-trends">
                        <div className="marketTrends-heading">
                          <h3 className="previewTitle">{element.title}</h3>
                        </div>
                        {element &&
                          element.data &&
                          element.data.length > 0 &&
                          element.data.map((element, i) => {
                            return (
                              <div>
                                <div className="marketTrends-option ">
                                  {element.type === "image" ? (
                                    <div className="tsgPreview-img">
                                      <ProfileImage
                                        imgSrc={`${common.path.base_url}/getFileNew?fileId=${element.pictureId}`}
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {element.type === "text" ? (
                                    <div className="marketOverview-options">
                                      <Markup content={element.text} />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div></div>
              )}
              {/* ---Section Problem Statement Description------ */}
              {this.state.iirList.find(
                (element) => element.title == "Problem Statement"
              ) ? (
                <div className="preview-company">
                  {roleCode !== "005" && (
                    <h3 className="edit-section">
                      <span className="prevHead">Problem Statement </span>
                      <i
                        className="fa fa-pencil add-address icons"
                        onClick={() =>
                          this.props.history.push(
                            `/iir/productStatement?companyId=${this.companyId !== undefined ? this.companyId : ""
                            }&investmentId=${this.investmentId}`
                          )
                        }
                      ></i>
                    </h3>
                  )}
                  {this.state.problemStatementDescData.map((element, i) => {
                    return (
                      <div className="market-trends">
                        <div className="marketTrends-heading">
                          <h3 className="previewTitle">{element.title}</h3>
                        </div>
                        {element.data.map((element, i) => {
                          return (
                            <div>
                              <div className="marketTrends-option ">
                                {element.type === "image" ? (
                                  <div className="tsgPreview-img">
                                    <ProfileImage
                                      imgSrc={`${common.path.base_url}/getFileNew?fileId=${element.pictureId}`}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {element.type === "text" ? (
                                  <div className="marketOverview-options">
                                    <div className="marketOverview-content">
                                      <Markup content={element.text} />
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div></div>
              )}
              {/* ---Section Solution Summary Description------ */}
              {this.state.iirList.find(
                (element) => element.title == "Solution Summary"
              ) ? (
                <div className="preview-company">
                  {roleCode !== "005" && (
                    <h3 className="edit-section">
                      <span className="prevHead">Solution Summary </span>
                      <i
                        className="fa fa-pencil add-address icons"
                        onClick={() =>
                          this.props.history.push(
                            `/iir/solutionSummary?companyId=${this.companyId !== undefined ? this.companyId : ""
                            }&investmentId=${this.investmentId}`
                          )
                        }
                      ></i>
                    </h3>
                  )}
                  {this.state.solutionSummaryDescData.map((element, i) => {
                    return (
                      <div className="market-trends">
                        <div className="marketTrends-heading">
                          <h3 className="previewTitle">{element.title}</h3>
                        </div>
                        {element.data.map((element, i) => {
                          return (
                            <div>
                              <div className="marketTrends-option ">
                                {element.type === "image" ? (
                                  <div className="tsgPreview-img">
                                    <ProfileImage
                                      imgSrc={`${common.path.base_url}/getFileNew?fileId=${element.pictureId}`}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {element.type === "text" ? (
                                  <div className="marketOverview-options">
                                    <div className="marketOverview-content">
                                      <Markup content={element.text} />
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div></div>
              )}

              {/* ---Section Differentiation ------ */}
              {this.state.iirList.find(
                (element) => element.title == "Differentiation"
              ) ? (
                <div className="preview-company">
                  {roleCode !== "005" && (
                    <h3 className="edit-section">
                      <span className="prevHead">Differentiation</span>
                      <i
                        className="fa fa-pencil add-address icons"
                        onClick={() =>
                          this.props.history.push(
                            `/iir/differentiation?companyId=${this.companyId !== undefined ? this.companyId : ""
                            }&investmentId=${this.investmentId}`
                          )
                        }
                      ></i>
                    </h3>
                  )}
                  {this.state.differentiation.map((element, i) => {
                    return (
                      <div className="market-trends">
                        <div className="marketTrends-heading">
                          <h3 className="previewTitle">{element.title}</h3>
                        </div>
                        {element.data.map((element, i) => {
                          return (
                            <div>
                              <div className="marketTrends-option ">
                                {element.type === "image" ? (
                                  <div className="tsgPreview-img">
                                    <ProfileImage
                                      imgSrc={`${common.path.base_url}/getFileNew?fileId=${element.pictureId}`}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {element.type === "text" ? (
                                  <div className="marketOverview-options">
                                    <div className="marketOverview-content">
                                      <Markup content={element.text} />
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div></div>
              )}
              {/* ---Section Product Description------ */}
              {this.state.iirList.find(
                (element) => element.title == "Product"
              ) ? (
                <div className="preview-company">
                  {roleCode !== "005" && (
                    <h3 className="edit-section">
                      <span className="prevHead">Product</span>{" "}
                      <i
                        className="fa fa-pencil add-address icons"
                        onClick={() =>
                          this.props.history.push(
                            `/iir/product?companyId=${this.companyId !== undefined ? this.companyId : ""
                            }&investmentId=${this.investmentId}`
                          )
                        }
                      ></i>
                    </h3>
                  )}
                  {this.state.productDescData.map((element, i) => {
                    return (
                      <div className="product-description">
                        <div className="productDesc-heading">
                          <h3 className="previewTitle">{element.title}</h3>
                        </div>
                        {element.data.map((element, i) => {
                          return (
                            <div>
                              <div className="productDesc-option ">
                                {element.type === "image" ? (
                                  <div className="tsgPreview-img">
                                    <ProfileImage
                                      imgSrc={`${common.path.base_url}/getFileNew?fileId=${element.pictureId}`}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {element.type === "text" ? (
                                  <div className="marketOverview-options">
                                    <div className="marketOverview-content">
                                      <Markup content={element.text} />
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div></div>
              )}
              {/* ---Section -Major-Investors--- */}
              {this.state.iirList.find(
                (element) => element.title == "Key Investors"
              ) ? (
                <div className="preview-company">
                  {roleCode !== "005" && (
                    <h3 className="edit-section">
                      <span className="prevHead">Key</span> Investors{" "}
                      <i
                        className="fa fa-pencil add-address icons"
                        onClick={() =>
                          this.props.history.push(
                            `/iir/majorInvestor?companyId=${this.companyId !== undefined ? this.companyId : ""
                            }&investmentId=${this.investmentId}`
                          )
                        }
                      ></i>
                    </h3>
                  )}
                  <div className="major-investors">
                    {this.state.majorInvestorData.map((element, index) => {
                      return (
                        <div className="investors-detail">
                          <div className="tsgPreview-img">
                            <ProfileImage
                              imgSrc={`${common.path.base_url}/getFileNew?fileId=${element.logo}`}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {/* ---Section Funding Description------ */}
              {this.state.iirList.find(
                (element) => element.title == "Funding Information / History"
              ) ? (
                <div className="preview-company">
                  <h3>
                    <span className="prevHead">
                      Funding Informaton / History
                    </span>
                  </h3>
                  {this.state.fundingDescData.map((element, i) => {
                    return (
                      <div className="market-trends">
                        <div className="marketTrends-heading">
                          <h3 className="previewTitle">{element.title}</h3>
                        </div>
                        {element.data.map((element, i) => {
                          return (
                            <div>
                              <div className="marketTrends-option ">
                                {element.type === "image" ? (
                                  <div className="tsgPreview-img">
                                    <ProfileImage
                                      imgSrc={`${common.path.base_url}/getFileNew?fileId=${element.pictureId}`}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {element.type === "text" ? (
                                  <div className="marketOverview-options">
                                    <div className="marketOverview-content">
                                      <Markup content={element.text} />
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div></div>
              )}
              {/* ---Section History & Projected Revenue------ */}
              {this.state.iirList.find(
                (element) => element.title == "Historical and Projected Revenue"
              ) ? (
                <div className="preview-company">
                  {roleCode !== "005" && (
                    <h3 className="edit-section">
                      <span className="prevHead">
                        Historical and Projected Revenue
                      </span>
                      <i
                        className="fa fa-pencil add-address icons"
                        onClick={() =>
                          this.props.history.push(
                            `/iir/historicalandProjectedRevenue?companyId=${this.companyId !== undefined ? this.companyId : ""
                            }`
                          )
                        }
                      ></i>
                    </h3>
                  )}
                  {this.state.histAndProjRevenueData.map((element, i) => {
                    return (
                      <div className="historyRevenue">
                        <div className="historyRevenue-heading">
                          <h3 className="previewTitle">{element.title}</h3>
                        </div>
                        {element.data.map((element, i) => {
                          return (
                            <div>
                              <div className="historyRevenue-option ">
                                {element.type === "image" ? (
                                  <div className="tsgPreview-img">
                                    <ProfileImage
                                      imgSrc={`${common.path.base_url}/getFileNew?fileId=${element.pictureId}`}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {element.type === "text" ? (
                                  <div className="marketOverview-options">
                                    <div className="marketOverview-content">
                                      <Markup content={element.text} />
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div></div>
              )}
              {/* ---Section Key Customers--- */}
              {this.state.iirList.find(
                (element) => element.title == "Key Customers"
              ) ? (
                <div className="preview-company">
                  {roleCode !== "005" && (
                    <h3 className="edit-section">
                      <span className="prevHead">Key </span> Customers{" "}
                      <i
                        className="fa fa-pencil add-address icons"
                        onClick={() =>
                          this.props.history.push(
                            `/iir/keyCustomers?companyId=${this.companyId !== undefined ? this.companyId : ""
                            }&investmentId=${this.investmentId}`
                          )
                        }
                      ></i>
                    </h3>
                  )}
                  <div className="key-customers">
                    {this.state.keyInvestorData.map((element, index) => {
                      return (
                        <div className="customers-detail">
                          <div className="tsgPreview-img">
                            <ProfileImage
                              imgSrc={`${common.path.base_url}/getFileNew?fileId=${element.logo}`}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {/* ---Section Press--- */}
              {this.state.iirList.find(
                (element) => element.title == "Press"
              ) ? (
                <div className="preview-company">
                  {roleCode !== "005" && (
                    <h3 className="edit-section">
                      Press{" "}
                      <i
                        className="fa fa-pencil add-address icons"
                        onClick={() =>
                          this.props.history.push(
                            `/iir/press?companyId=${this.companyId !== undefined ? this.companyId : ""
                            }&investmentId=${this.investmentId}`
                          )
                        }
                      ></i>
                    </h3>
                  )}
                  <div className="press">
                    {this.state.PressData.map((element, index) => {
                      return (
                        <div className="press-detail">
                          <i className="fa fa-paperclip paperIcon"></i>{" "}
                          <a
                            href={element.link}
                            className="press-content"
                            target="_blank"
                          >
                            {element.title}
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {/* ---Section Market-size------ */}
              {/* <div className="preview-company">
                       <h3><span className='prevHead'>Market </span> Size</h3>
                        {this.state.marketOverviewData.map((element, i) => {
                            return (
                                <div className="market-size">
                                    <div className="marketSize-heading">
                                        <h3 className='previewTitle'>{element.title}</h3>
                                    </div>
                                    {element.data.map((element, i) => {
                                        return (
                                            <div>
                                                <div className='marketSize-option '>
                                                {element.type === 'image' ?
                                                    <div className='tsgPreview-img'>
                                                         <ProfileImage
                                                        imgSrc={`${common.path.base_url}/getFileNew?fileId=${element.pictureId}`}
                                                                />                                                       
                                                    </div> : ''}
                                                {element.type === 'text' ? <div className='marketOverview-options'>
                                                        <div className="marketOverview-content">{element.text}</div>                                                
                                                </div> : ''}
                                                </div>
                                            </div>
                                        )
                                        })}
                                    </div>
                                )
                            })}
                        </div> */}
              {/* ---Section Market-size------ */}
              {/* <div className="preview-company">
                       <h3><span className='prevHead'>Market </span>Trends</h3>
                        {this.state.marketTrendData.map((element, i) => {
                            return (
                                <div className="market-trends">
                                    <div className="marketTrends-heading">
                                        <h3 className='previewTitle'>{element.title}</h3>
                                    </div>
                                    {element.data.map((element, i) => {
                                        return (
                                            <div>
                                                <div className='marketTrends-option '>
                                                {element.type === 'image' ?
                                                    <div className='tsgPreview-img'>
                                                         <ProfileImage
                                                        imgSrc={`${common.path.base_url}/getFileNew?fileId=${element.pictureId}`}
                                                                />                                                       
                                                    </div> : ''}
                                                {element.type === 'text' ? <div className='marketOverview-options'>
                                                        <div className="marketOverview-content">{element.text}</div>                                                
                                                </div> : ''}
                                                </div>
                                            </div>
                                        )
                                        })}
                                    </div>
                                )
                            })}
                        </div> */}
              {/* ---Section Performace------ */}
              {/* ---Section Competition Description------ */}
              {/* <div className="preview-company">
                       <h3><span className='prevHead'>Competition</span></h3>
                        {this.state.competitionDescData.map((element,i) => {
                            return (
                                <div className="market-trends">
                                    <div className="marketTrends-heading">
                                        <h3 className='previewTitle'>{element.title}</h3>
                                    </div>
                                    {element.data.map((element, i) => {
                                        return (
                                            <div>
                                                <div className='marketTrends-option '>
                                                {element.type === 'image' ?
                                                    <div className='tsgPreview-img'>
                                                         <ProfileImage
                                                        imgSrc={`${common.path.base_url}/getFileNew?fileId=${element.pictureId}`}
                                                                />                                                       
                                                    </div> : ''}
                                                {element.type === 'text' ? <div className='marketOverview-options'>
                                                        <div className="marketOverview-content">{element.text}</div>                                                
                                                </div> : ''}
                                                </div>
                                            </div>
                                        )
                                        })}
                                    </div>
                                )
                            })}
                        </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Preview);

import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import './ContactList.scss';
import ProfileImage from '../../components/ProfileImage';
import common from '../../common';
import { path } from '../../InvestorDashboard/imagesPath';

class ContactList extends Component {
    constructor(props) {
        super(props);
    }

    getStatusColor() {
        switch (this.props.contactData.invitationAccepted) {
            case '0':
                return 'red';

            case '1':
                return 'green';

            case '2':
                return 'amber';

            default:
                return 'red';
        }
    }

    mapDateFormat = mongoDate => {
        if (!mongoDate) return null;

        var monthNames = [
            "01", "02", "03",
            "04", "05", "06", "07",
            "08", "09", "10",
            "11", "12"
        ];
        let date = new Date(mongoDate);
        let day = date.getDate();
        let monthIndex = date.getMonth();
        let year = date.getFullYear();

        return monthNames[monthIndex] + '/' + day + '/ ' + year;

    }

    getTitle(title, companyName, investorCompanyName) {
        let finalCompanyName;
        if (companyName) {
            finalCompanyName = companyName;
        } else if (investorCompanyName) {
            finalCompanyName = investorCompanyName;
        }
        if (title && finalCompanyName) {
            return <label className="title-label">{title}&nbsp;<span>at&nbsp;</span>{finalCompanyName}</label>;
        } else if (title) {
            return <label className="title-label">{title}</label>;
        } else if (finalCompanyName) {
            return <label className="title-label">{finalCompanyName}</label>;
        }
    }
    
    render() {
        const { contactData } = this.props;
        let checkBoxSelect = this.props.allSelectedCard;
        let checkMobile = this.props.allSelectMobile;
        let allSelectedUser = this.props.allSelectedUser;
        
        let currentUserRole = window.localStorage.getItem('roleCode')
        if ((contactData.role === '001' || contactData.role === '002') &&
            (contactData.emailAddress && (contactData.emailAddress === 'tsgadmin@mailsac.com' || contactData.emailAddress.toLowerCase() === 'dev@68labs.com' || contactData.emailAddress.toLowerCase() === 'hello@68labs.com'
                || contactData.emailAddress === 'hemant@68labs.com'
                || contactData.emailAddress === 'hiro.jaisinghani@solutionanalysts.com'
            ))) {
            return null;
        } else {
            return (
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 custom-width" data-key='card-click'>
                    <Card key={contactData._id} className='nav navbar-nav contact-list admin custom-card new-card added'>
                        
                        {
                            (contactData.emailAddress || contactData.mobile )&& 
                            <>
                                <label className="checkbox-container">
                                    <input type='checkBox' name="userCheckbox" onClick={(event) => { event.stopPropagation(); this.props.selectCardBox(contactData, event) }} checked={( allSelectedUser.includes(contactData._id)) ? true : false } />
                                    <span className={ `checkmark ${ ( allSelectedUser.includes(contactData._id) ) ? 'chk-selected' : ''}` }></span>
                                </label>
                            
                            </>
                        }
                        <div className="card-top-box" onClick={(event) => { event.stopPropagation(); this.props.showDataInPopup(contactData) }} >
                            
                            <ProfileImage
                                imgSrc={`${common.path.base_url}/getImage?id=${contactData._id || ''}`}
                                status={this.getStatusColor()}
                            />
                            {/* onClick={() => { this.props.showDataInPopup(contactData) }} */}
                            <div id="name-role">
                                {
                                    contactData &&
                                    <>  
                                        {
                                            contactData.name &&
                                            <b><div className="card-name-title">{contactData.name}</div></b>
                                        }
                                        {
                                            <>
                                                <br />
                                                <label className="title-label">{this.getTitle(contactData.title, contactData.companyName, contactData.investorCompanyName)}</label>
                                                {/* <label className="title-label">{contactData.title ? contactData.title : '-'}&nbsp;<span>at&nbsp;</span>&nbsp;{contactData.companyName ? contactData.companyName : '-'}</label> */}
                                            </>
                                        }
                                    </>

                                }
                            </div>
                        </div>
                        <div className="card-bottom-box">
                            {this.props.contactData.role && this.props.contactData.role === '005' && this.props.canResend === false &&
                                <div className="bottom-content" onClick={() => this.props.history.push(`/investor-dashboard?investorId=${contactData._id}`)}>
                                    <img src={path.dashboard_new} alr='' />
                                    <label>Dashboard</label>
                                </div>
                            }
                            {this.props.contactData.role && (this.props.contactData.role === '003' || this.props.contactData.role === '004') && this.props.canResend === false &&
                                <div className="bottom-content" onClick={() => this.props.history.push(`/startup-dashboard/${contactData._id}`)}>
                                    <img src={path.dashboard_new} alr='' />
                                    <label>Dashboard</label>
                                </div>
                            }
                            {this.props.canResend &&
                                <div className={contactData.emailAddress === null || contactData.emailAddress === '' || contactData.emailAddress === undefined ? "bottom-content bottom-content-disable" : "bottom-content"} onClick={(event) => {
                                    setTimeout(() => {
                                        this.props.resendInvite()
                                    }, 1)
                                }} >
                                    <img src={contactData.emailAddress === null || contactData.emailAddress === '' || contactData.emailAddress === undefined ? path.send_mail_new_disable : path.send_mail_new} alr='' />
                                    <label className={contactData.emailAddress === null || contactData.emailAddress === '' || contactData.emailAddress === undefined ? "label-disable" : null}>Send Invitation</label>
                                </div>
                            }
                            {
                                (currentUserRole === '001')? 
                            <div className="bottom-content" onClick={() => this.props.history.push(`/profile?id=${contactData._id}`)}>
                                <img src={path.edit_new} alr='' />
                                <label>Edit </label>
                            </div>:""}
                            {this.props.currentUser !== contactData._id && (currentUserRole === '001' || currentUserRole === '002') &&
                                <div className="bottom-content" onClick={() => { this.props.onActionClick && this.props.onActionClick() }}>
                                    <img src={path.delete_new} alr='' />
                                    <label>Delete</label>
                                </div>
                            }
                        </div>
                    </Card>
                </div >
            )

        }
    }
}
export default ContactList;
// {this.props.canResend && (contactData.emailAddress === null || contactData.emailAddress === '' || contactData.emailAddress === undefined) ?
// <div className="bottom-content">
//     <img src={path.send_mail_new_disable} alr='' />
//     <label className="label-disable">Send Invitation</label>
// </div> :
// <div className="bottom-content" onClick={(event) => {
//     setTimeout(() => {
//         this.props.resendInvite()
//     }, 1)
// }} >
//     <img src={path.send_mail_new} alr='' />
//     <label>Send Invitation</label>
// </div>
// }
import React, { Component } from 'react'
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import swal from 'sweetalert';
import common from '../../../common';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import './WireInformationForm.scss';
import Header from '../../../components/Header';

class WireInformationForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      amount: 10,
    }
  }
  componentDidMount() {
  }
  render() {
    return (
      <>
        <div className="wireform_maincontainer">
          <Header/>
          {/* <div className="container-fluid">
            <div className="row"> */}
            <div className="container">
              <div className="col-lg-10 col-md-10 col-sm-10 col-lg-offset-1 col-md-offset-1 col-sm-offset-1">
                <h2>Wire Information</h2>
              </div>
              <div className="col-lg-10 col-md-10 col-sm-10 col-lg-offset-1 col-md-offset-1 col-sm-offset-1">
                  <div className="card">
                    <h3>Valtix Series A-1</h3>

                    <div className="invitations_right">

                      <h4>Basic Details</h4>
                      <div className="row">
                        <div className="col-lg-2 col-md-3 field-label-responsive">
                          <label htmlFor="name">Bank Name<sup>*</sup> :</label>
                        </div>
                        <div className="col-lg-6 col-md-9">
                          <div className="form-group">
                            <input className="form-control" id="entityName" type="text"
                              placeholder='Enter Entity Name'
                              defaultValue='Wells Fargo Bank' />
                            <p>{this.state.entityValidationMsg}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-2 col-md-3 field-label-responsive">
                          <label htmlFor="name">SWIFT CODE<sup>*</sup> :</label>
                        </div>
                        <div className="col-lg-6 col-md-9">
                          <div className="form-group">
                            <input className="form-control" id="title" type="text"
                              placeholder='Enter Title'
                              defaultValue='6346342557' />
                            <p>{this.state.titleValidationMsg}</p>
                          </div>
                        </div>
                      </div>


                      <div className="row">
                        <div className="col-lg-2 col-md-3 field-label-responsive">
                          <label htmlFor="name">IBAN #<sup>*</sup> :</label>
                        </div>
                        <div className="col-lg-6 col-md-9">
                          <div className="form-group">
                            <input className="form-control" id="firstName" type="text"
                              placeholder='Enter First Name'
                              defaultValue='650525540251' />
                            <p>{this.state.firstNameValidationMsg}</p>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-2 col-md-3 field-label-responsive">
                          <label htmlFor="name">Account Name <sup>*</sup>  :</label>
                        </div>

                        <div className="col-lg-6 col-md-9">
                          <div className="form-group">
                            <input className="form-control" id="middleName" type="text"
                              placeholder='Enter Middle Name'
                              defaultValue='John Snow Enterprises' />
                            <p>{this.state.middleNameValidationMsg}</p>
                          </div>
                        </div>
                      </div>


                      <div className="row">
                        <div className="col-lg-2 col-md-3 field-label-responsive">
                          <label htmlFor="name">Account Number<sup>*</sup> :</label>
                        </div>
                        <div className="col-lg-6 col-md-9">
                          <div className="form-group">
                            <input className="form-control" id="lastName" type="text"
                              placeholder='Enter Last Name'
                              defaultValue='1125562523' />
                            <p>{this.state.lastNameValidationMsg}</p>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-2 col-md-3 field-label-responsive">
                        </div>
                        <div className="col-lg-10 col-md-9">
                          <hr></hr>
                        </div>
                      </div>

                      <h4>Bank Address</h4>
                      <div className="row">
                        <div className="col-lg-2 col-md-3 field-label-responsive">
                          <label htmlFor="name">Address Line 1<sup>*</sup> :</label>
                        </div>
                        <div className="col-lg-6 col-md-9">
                          <div className="form-group">
                            <input className="form-control" id="address1" type="text"
                              placeholder='Enter Address 1'
                              defaultValue='123 Main St' />
                            <p>{this.state.address1ValidationMsg}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-2 col-md-3 field-label-responsive">
                          <label htmlFor="name">Address Line 2 :</label>
                        </div>
                        <div className="col-lg-6 col-md-9">
                          <div className="form-group">
                            <input className="form-control" id="address2" type="text"
                              placeholder='Enter Address 2'
                              defaultValue='#605' />
                            <p>{this.state.address2ValidationMsg}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-2 col-md-3 field-label-responsive">
                          <label htmlFor="name">City<sup>*</sup> :</label>
                        </div>
                        <div className="col-lg-6 col-md-9">
                          <div className="form-group">
                            <input className="form-control" id="city" type="text"
                              placeholder='Enter City'
                              defaultValue='Santa Monica' />
                            <p>{this.state.cityValidationMsg}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-2 col-md-3 field-label-responsive">
                          <label htmlFor="name">State :</label>
                          {/* <label htmlFor="name">State <sup>*</sup> :</label> */}
                        </div>
                        <div className="col-lg-6 col-md-9">
                          <div className="form-group">
                            <input className="form-control" id="state" type="text"
                              placeholder='Enter State'
                              defaultValue='CA' />
                            <p>{this.state.stateValidationMsg}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-2 col-md-3 field-label-responsive">
                          {/* <label htmlFor="name">Zipcode<sup>*</sup> :</label> */}
                          <label htmlFor="name">Postal code :</label>
                          {/* <label htmlFor="name">Postal code :</label> */}
                        </div>
                        <div className="col-lg-6 col-md-9">
                          <div className="form-group amount-investment">
                            <input className="form-control" id="state" type="text"
                              placeholder={'Enter Postal Code'}
                              defaultValue='90405' />
                            <p>{this.state.zipcodeValidationMsg}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-2 col-md-3 field-label-responsive">
                          <label htmlFor="name">Country<sup>*</sup> :</label>
                        </div>
                        <div className="col-lg-6 col-md-9">
                          <div className="form-group">
                            <input className="form-control" id="country" type="text"
                              placeholder='Enter Country'
                              defaultValue='90405' />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-2 col-md-3 field-label-responsive">
                        </div>
                        <div className="col-lg-10 col-md-9">
                          <hr></hr>
                        </div>
                      </div>

                      <h4>Beneficiary Address</h4>
                      <div className="row">
                        <div className="col-lg-2 col-md-3 field-label-responsive">
                          <label htmlFor="name">Address Line 1<sup>*</sup> :</label>
                        </div>
                        <div className="col-lg-6 col-md-9">
                          <div className="form-group">
                            <input className="form-control" id="address1" type="text"
                              placeholder='Enter Address 1'
                              defaultValue='123 Main St' />
                            <p>{this.state.address1ValidationMsg}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-2 col-md-3 field-label-responsive">
                          <label htmlFor="name">Address Line 2 :</label>
                        </div>
                        <div className="col-lg-6 col-md-9">
                          <div className="form-group">
                            <input className="form-control" id="address2" type="text"
                              placeholder='Enter Address 2'
                              defaultValue='#605' />
                            <p>{this.state.address2ValidationMsg}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-2 col-md-3 field-label-responsive">
                          <label htmlFor="name">City<sup>*</sup> :</label>
                        </div>
                        <div className="col-lg-6 col-md-9">
                          <div className="form-group">
                            <input className="form-control" id="city" type="text"
                              placeholder='Enter City'
                              defaultValue='Santa Monica' />
                            <p>{this.state.cityValidationMsg}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-2 col-md-3 field-label-responsive">
                          <label htmlFor="name">State :</label>
                          {/* <label htmlFor="name">State <sup>*</sup> :</label> */}
                        </div>
                        <div className="col-lg-6 col-md-9">
                          <div className="form-group">
                            <input className="form-control" id="state" type="text"
                              placeholder='Enter State'
                              defaultValue='CA' />
                            <p>{this.state.stateValidationMsg}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-2 col-md-3 field-label-responsive">
                          {/* <label htmlFor="name">Zipcode<sup>*</sup> :</label> */}
                          <label htmlFor="name">Postal code :</label>
                          {/* <label htmlFor="name">Postal code :</label> */}
                        </div>
                        <div className="col-lg-6 col-md-9">
                          <div className="form-group amount-investment">
                            <input className="form-control" id="state" type="text"
                              placeholder={'Enter Postal Code'}
                              defaultValue='90405' />
                            <p>{this.state.zipcodeValidationMsg}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-2 col-md-3 field-label-responsive">
                          <label htmlFor="name">Country<sup>*</sup> :</label>
                        </div>
                        <div className="col-lg-6 col-md-9">
                          <div className="form-group">
                            <input className="form-control" id="country" type="text"
                              placeholder='Enter Country'
                              defaultValue='90405' />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-2 col-md-3 field-label-responsive">
                        </div>
                        <div className="col-lg-10 col-md-9">
                          <hr></hr>
                        </div>
                      </div>

                      <h4>Disclaimer</h4>
                      <div className="row">
                        <div className="col-lg-2 col-md-3 field-label-responsive">
                          <label htmlFor="name">Disclaimer:</label>
                        </div>
                        <div className="col-lg-6 col-md-9">
                          <div className="form-group">
                            <textarea className="form-control" id="disclaimer" type="message" placeholder='Notes' rows="4" cols="50"
                              defaultValue='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim' ></textarea>

                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-2 col-md-3" />
                        <div className="col-lg-6 col-md-9 document-btn">
                          <LaddaButton
                            id='generateDoc'
                            loading={this.state.loadinglada}
                            onClick={() => { }}
                            data-color="#eee"
                            data-size={XS}
                            className={''}
                            data-style={EXPAND_RIGHT}
                            data-spinner-size={30}
                            data-spinner-color="#ddd"
                            data-spinner-lines={12}
                          >
                            SUBMIT
                                  </LaddaButton>
                                  <LaddaButton
                            id='generateDoc'
                            loading={this.state.loadinglada}
                            onClick={() => { }}
                            data-color="#eee"
                            data-size={XS}
                            className={'disable'}
                            data-style={EXPAND_RIGHT}
                            data-spinner-size={30}
                            data-spinner-color="#ddd"
                            data-spinner-lines={12}
                          >
                            CANCEL
                                  </LaddaButton>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
        {/* </div>
        </div> */}
      </>
    );
  }
}
export default WireInformationForm;
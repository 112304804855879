import React, { Component } from 'react';
import axios from 'axios';
import swal from 'sweetalert';

import './globalInvestorsFollowup.scss';
import common from '../../../../common';
import InvestmentFollowupMobileView from './GlobalInvestorsFollowupMobileView';

class GlobalInvestorsFollowup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      invited: { duration: 1, times: 1 },
      participated: { duration: 1, times: 1 },
      interested: { duration: 1, times: 1 },
      executed: { duration: 1, times: 1 },
      daysOptions: [
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 },
        { label: 6, value: 6 },
        { label: 7, value: 7 },
        { label: 8, value: 8 },
        { label: 9, value: 9 },
        { label: 10, value: 10 },
        { label: 11, value: 11 },
        { label: 12, value: 12 },
        { label: 13, value: 13 },
        { label: 14, value: 14 },
        { label: 15, value: 15 },
      ],
      timeOptions: [
        { label: 0, value: 0 },
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 },
      ],
    }
  }

  componentDidMount() {
    this.getInvestmentSettings();
  }

  onDaysChange = (event, type) => {
    this.setState({
      [type]: { ...this.state[type], duration: +event.target.value }
    });
  };

  onTimeChange = (event, type) => {
    this.setState({
      [type]: { ...this.state[type], times: +event.target.value }
    })
  }

  getInvestmentSettings() {
    const temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem('token');
    axios.get(`${common.path.base_url}/followUpSettings`, {
      headers: {
        "Authorization": token
      }
    })
      .then(function (response) {
        temp.setState({ loading: false });
        if (response && response.data && response.data.data && response.data.data.automatedFollowUP) {
          temp.setState({ ...response.data.data.automatedFollowUP });
        }
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
          window.localStorage.clear();
          window.location.href = '/';
          return;
        } else {
          swal({
            title: "Network Error!",
            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  };

  saveInvestmentSettings() {
    const temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem('token');
    axios.post(`${common.path.base_url}/followUpSettings`, {
      automatedFollowUP: {
        invited: this.state.invited,
        participated: this.state.participated,
        interested: this.state.interested,
        executed: this.state.executed,
      },
    },
      {
        headers: {
          "Authorization": token
        }
      })
      .then(function (response) {
        temp.setState({ loading: false });
        if (response && response.data && response.data.message) {
          swal({
            title: "Settings Done",
            text: response.data.message,
            icon: "success",
            button: "Ok!",
          });
        }
        temp.getInvestmentSettings();
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        swal({
          title: "Network Error!",
          text: "Something went wrong",
          icon: "error",
          button: "Ok!",
        });
        temp.getInvestmentSettings();
      });
  };

  render() {
    return (
      <>
        <div className="investors-followup desktop-view">
          <h2>Investor Follow Up Settings</h2>
          <table>
            <thead>
              <tr>
                <th width="20%">Investment Stage</th>
                <th width="20%">Duration(Days)</th>
                <th width="20%">Times</th>
                <th width="40%"></th>
              </tr>
            </thead>
            <tbody>

              <tr>
                <td>
                  <div className="document">Invited <span>(Pending Invited User)</span></div>
                </td>
                <td>
                  <div className="select-item">
                    <select className="form-control" value={this.state.invited.duration} onChange={(event) => this.onDaysChange(event, 'invited')}>
                      {
                        this.state.daysOptions.map((singleDay, index) => {
                          return <option value={singleDay.value} key={index}>{singleDay.label}</option>
                        })
                      }
                    </select>
                  </div>
                </td>
                <td>
                  <div className="select-item">
                    <select className="form-control" value={this.state.invited.times} onChange={(event) => this.onTimeChange(event, 'invited')}>
                      {
                        this.state.timeOptions.map((singleTime, index) => {
                          return <option value={singleTime.value} key={index}>{singleTime.label}</option>
                        })
                      }
                    </select>
                  </div>
                </td>
                <td>
                  <div className="notified">Investor will be notified every<span>{this.state.invited.duration} days</span>for<span>{this.state.invited.times} times</span></div>
                </td>
              </tr>

              <tr>
                <td>
                  <div className="document">Participated <span>(Generate Docs Pending)</span></div>
                </td>
                <td>
                  <div className="select-item">
                    <select className="form-control" value={this.state.participated.duration} onChange={(event) => this.onDaysChange(event, 'participated')}>
                    
                      {
                        this.state.daysOptions.map((singleDay, index) => {
                          return <option value={singleDay.value} key={index}>{singleDay.label}</option>
                        })
                      }
                    </select>
                  </div>
                </td>
                <td>
                  <div className="select-item">
                    <select className="form-control" value={this.state.participated.times} onChange={(event) => this.onTimeChange(event, 'participated')}>
                      {
                        this.state.timeOptions.map((singleTime, index) => {
                          return <option value={singleTime.value} key={index}>{singleTime.label}</option>
                        })
                      }
                    </select>
                  </div>
                </td>
                <td>
                  <div className="notified">Investor will be notified every<span>{this.state.participated.duration} days</span>for<span>{this.state.participated.times} times</span></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="document">Generated Docs <span>(Docs Sign Pending)</span></div>
                </td>
                <td>
                  <div className="select-item">
                    <select className="form-control" value={this.state.interested.duration} onChange={(event) => this.onDaysChange(event, 'interested')}>
                      {
                        this.state.daysOptions.map((singleDay, index) => {
                          return <option value={singleDay.value} key={index}>{singleDay.label}</option>
                        })
                      }
                    </select>
                  </div>
                </td>
                <td>
                  <div className="select-item">
                    <select className="form-control" value={this.state.interested.times} onChange={(event) => this.onTimeChange(event, 'interested')}>
                      {
                        this.state.timeOptions.map((singleTime, index) => {
                          return <option value={singleTime.value} key={index}>{singleTime.label}</option>
                        })
                      }
                    </select>
                  </div>
                </td>
                <td>
                  <div className="notified">Investor will be notified every<span>{this.state.interested.duration} days</span>for<span>{this.state.interested.times} times</span></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="document">Signed Docs <span>(Fund Pending)</span></div>
                </td>
                <td>
                  <div className="select-item">
                    <select className="form-control" value={this.state.executed.duration} onChange={(event) => this.onDaysChange(event, 'executed')}>
                      {
                        this.state.daysOptions.map((singleDay, index) => {
                          return <option value={singleDay.value} key={index}>{singleDay.label}</option>
                        })
                      }
                    </select>
                  </div>
                </td>
                <td>
                  <div className="select-item">
                    <select className="form-control" value={this.state.executed.times} onChange={(event) => this.onTimeChange(event, 'executed')}>
                      {
                        this.state.timeOptions.map((singleTime, index) => {
                          return <option value={singleTime.value} key={index}>{singleTime.label}</option>
                        })
                      }
                    </select>
                  </div>
                </td>
                <td>
                  <div className="notified">Investor will be notified every<span>{this.state.executed.duration} days</span>for<span>{this.state.executed.times} times</span></div>
                </td>
              </tr>
            </tbody>
          </table>
          <button className="tsg-btn" onClick={() => this.saveInvestmentSettings()}>Save</button>
        </div>
        <InvestmentFollowupMobileView
          participated={this.state.participated}
          interested={this.state.interested}
          executed={this.state.executed}
          daysOptions={this.state.daysOptions}
          timeOptions={this.state.timeOptions}
          onDaysChange={(event, type) => this.onDaysChange(event, type)}
          onTimeChange={(event, type) => this.onTimeChange(event, type)}
          saveInvestmentSettings={() => this.saveInvestmentSettings()}
        />
      </>
    )
  }
}

export default GlobalInvestorsFollowup;
import axios from 'axios';
import _ from 'lodash';
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import ReactTags from 'react-tag-autocomplete';
import swal from 'sweetalert';
import common from '../../common';
import TButton from '../../components/TButton';
import global from '../../images/global.png';
import downarrow from '../../images/icons/downarrow.svg';
import exitright from '../../images/icons/exitright.svg';
import search from '../../images/icons/search.svg';
import warning from '../../images/icons/warning.svg';
import './StartupDetail.scss';

class ExitProcessEscrow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [
                { name: 'Previously invested in this Startup investment ', id: 1, isChecked: false },
                { name: 'Referred by Startup ', id: 2, isChecked: false },
                { name: 'Previously invested in any TSG investment', id: 3, isChecked: false },
                { name: 'Previously invited to a TSG investment', id: 4, isChecked: false },
            ],
            pagination: {
                currentPage: 0,
                totalPages: 1,
            },
            isFilterActive: false,
            searchInputTags: [],
            suggestions: [
                { id: 'YPO', name: 'YPO' },
                { id: 'Institutional', name: 'Institutional' },
                { id: 'Strategic', name: 'Strategic' },
                { id: 'Preferred', name: 'Preferred' },
                { id: 'Investment(s)', name: 'Investment(s)' }],
            arrowType: {
                name: 'down',
                emailAddress: 'down',
                company: 'down',
            },
            $sort: null,
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, false);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
    }

    handleScroll = () => {
        if ((Math.round(window.innerHeight + document.documentElement.scrollTop) === document.documentElement.offsetHeight)
            && (this.state.pagination.currentPage < this.state.pagination.totalPages && !this.state.isFilterActive)) {
        }
    };

    mapTags(tags) {
        if (!tags) {
            tags = this.state.searchInputTags;
        }
        let tagIds = []
        if (tags && tags.length > 0) {
            tagIds = tags.map(tag => tag.name)
        }
        return tagIds;
    }

    handleSearchInputDelete(i) {
        const tags = this.state.searchInputTags.slice(0)
        tags.splice(i, 1)
        this.setState({ searchInputTags: tags }, () => {
            this.props.getExitProcessSecondEscrowUsers(false, this.mapTags())
        });
    }

    handleSearchInputAddition(tag) {
        const tags = [].concat(this.state.searchInputTags, tag);
        this.setState({ searchInputTags: tags }, () => {
            this.props.getExitProcessSecondEscrowUsers(false, this.mapTags())
        });
    }

    mapDateFormat(mongoDate) {
        if (!mongoDate) return 'N/A';
        const monthNames = [
            "01", "02", "03",
            "04", "05", "06", "07",
            "08", "09", "10",
            "11", "12"
        ];
        let date = new Date(mongoDate);
        let day = date.getDate();
        let monthIndex = date.getMonth();
        let year = date.getFullYear();
        return monthNames[monthIndex] + '/' + day + '/' + year;
    }

    viewDocumentRedirect(envId, type) {
        window.open(` ${common.path.doc_download_url}/downloadPDF?envId=${envId}&type=${type}`, '_blank', '', 2)
    }

    getUserCitizenForm(isForeignCitizen) {
        return isForeignCitizen === true ? 'W8' : 'W9'
    }

    setSecondEscrowDisbursementDate(date, id) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const data = {
            isSecondEscrowDate: true,
            secondEscrowDisbursementDate: date,
            processId: id,
        }
        axios.post(`${common.path.base_url}/setSecondEscrowDisbursementDate`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                temp.props.getExitProcessSecondEscrowUsers(false);
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    exitDisburement(user, amount, isWirePayment) {
        let temp = this;
        temp.setState({ loading: true });
        temp.props.showLoading(true);
        const token = window.localStorage.getItem('token');
        let data = {
            legalName: this.props.investmentData.legalName,
            investmentId: user.investmentId,
            userId: user.user._id,
            investmentProcessId: user._id,
            isEscrow: false,
            isSecondEscrow: true,
            isTwoEscrowAcuquision: this.props.investmentData.exitProcessType === 'acquisitionwithtwoescrow',
            emailAddress: user.emailId,
            userName: user.firstName,
            investmentName: user.investmentName,
            disbursementAmount: Number(amount.replaceAll(",", "")),
            isWirePayment
        };

        axios.post(`${common.path.base_url}/exitDisbursement`, data, {
            headers: {
                Authorization: token,
            }
        }).then((response) => {
            temp.props.showLoading(false);
            temp.setSecondEscrowDisbursementDate(new Date(), user._id);
            swal({
                title: "Success",
                text: response.data.message,
                icon: "success",
                button: "Ok!",
            }).then((response) => {
            });
        }).catch((error) => {
            temp.props.showLoading(false);
            temp.setState({ loading: false });
            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                window.localStorage.clear();
                window.location.href = '/';
                return;
            }
            else if (error.response) {
                swal({
                    title: "Error!",
                    text: error.response.data.message,
                    icon: "error",
                    button: "Ok!",
                });

            }
            else {
                swal({
                    title: "Network Error!",
                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                    icon: "error",
                    button: "Ok!",
                });
            }
        });
    }

    render() {
        return (
            <div className="startup-box-wrapper-main margin-zero">

            <TButton onClick={() => { }} id='bankDetailsModal-btn' dataTarget={`#bankDetailsModal`} dataToggle={`modal`} style={{ display: 'none' }} />

                <div className='header-investment exit-in-process'>
                    <div className="row searchpaddingtwopart">
                        <div className='search' style={{ width: '88%', position: 'relative' }}>
                            <ReactTags
                                allowNew={true}
                                placeholder='Search'
                                suggestions={this.state.suggestions}
                                tags={this.state.searchInputTags}
                                handleDelete={this.handleSearchInputDelete.bind(this)}
                                handleAddition={this.handleSearchInputAddition.bind(this)}
                            />
                            <img src={search} className="search-icon" alt="Search Icon"></img>
                        </div>

                        <div className="all">
                            <div className="select-box">
                                <div className='status'>
                                    <select className='status-box' onChange={(event) => this.props.getExitProcessSecondEscrowUsers(false, this.mapTags(), this.state.$sort, event.target.value)}>
                                        <option value="all">All Investment</option>
                                        {this.props.companyInvestmentsData && this.props.companyInvestmentsData.map((item) => {
                                            return <option value={item.id}>{item.name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="custom-table-view table-responsive table-disbursement">
                        <table className="table table-custom sortable">
                            <thead>
                                <tr>
                                    <th>INVESTOR <img src={downarrow} className="menu-icon" alt="Sort"></img></th>
                                    <th>INITIAL DISBURSEMENT AMOUNT <img src={downarrow} className="menu-icon" alt="Sort"></img></th>
                                    <th>INITIAL DISBURSEMENT DATE <img src={downarrow} className="menu-icon" alt="Sort"></img></th>
                                    <th>ESCROW DISBURSEMENT AMOUNT<img src={downarrow} className="menu-icon" alt="Sort"></img></th>
                                    <th>ESCROW DISBURSEMENT DATE <img src={downarrow} className="menu-icon" alt="Sort"></img></th>
                                    <th>ESCROW DISBURSEMENT STATUS<img src={downarrow} className="menu-icon" alt="Sort"></img></th>
                                    <th>PAYMENT</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.exitInProcessEscrowUsers.length > 0 &&
                                    this.props.exitInProcessEscrowUsers.map(user => {

                                        return (
                                            <tr key={user._id}>
                                                <td style={{ minWidth: "200px" }} >
                                                    <div className='row d-flex' style={{
                                                        justifyContent: "start",
                                                        flexDirection: 'row',
                                                        paddingLeft: '15px',
                                                    }}>
                                                        <div>
                                                            <span className='cursor' onClick={() => { this.props.showDataInPopup(user.user._id) }}>{user.name}</span>
                                                        </div>
                                                        <div className={user.isAcountVerified ? "tooltipcover" : this.props.investmentData.exitProcessType === 'ipo' ? "tooltipcover-brokerage" : ""}>
                                                            <span className="tooltiptext">{user.isAcountVerified ? 'Verified' : this.props.investmentData.exitProcessType === 'ipo' ? 'Brokerage account not verified' : ''}</span>
                                                            <img src={user.isAcountVerified ? exitright : this.props.investmentData.exitProcessType === 'ipo' ? warning : ""} className="menu-icon" alt=""></img>
                                                        </div>
                                                        {user.isForeignCitizen && <div className="tooltipcover">
                                                            <span className="tooltiptext">International Investor</span>
                                                            <img src={global} className="menu-icon" style={{ width: '13px', height: '13px' }} alt="Global Icon"></img>
                                                        </div>}
                                                    </div>
                                                </td>
                                                <td>
                                                    <NumberFormat
                                                        value={Number(user.disbursement1).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'} />
                                                </td>
                                                <td>
                                                    {this.mapDateFormat(user.disbursementDate)}
                                                </td>
                                                <td>
                                                    <NumberFormat
                                                        value={Number(user.disbursement2).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'} />
                                                    &nbsp;/&nbsp;
                                                    <NumberFormat
                                                        value={Number(user.disbursement3).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'} />
                                                </td>
                                                <td>
                                                    {this.mapDateFormat(user.escrowDisbursementDate)}
                                                </td>
                                                <td>
                                                    <span style={{ color: "#00CC00" }}>{user.disbursementFunded2?.toString().toUpperCase()}</span>
                                                </td>
                                                <td>                                                    
                                                    <div class="d-flex">
                                                        <TButton text="Disbursment" style={{ margin: "5px" }}
                                                            disabled={user.isForeignCitizen}
                                                            onClick={() => {
                                                                if (Number(user.disbursement2) > 0) {
                                                                    this.exitDisburement(user, Number(user.disbursement2).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }), false);
                                                                } else {
                                                                    swal({
                                                                        title: "Error!",
                                                                        text: "The amount should be greater than zero.",
                                                                        icon: "error",
                                                                        button: "Ok!",
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                        <TButton text="Wire" style={{ margin: "5px" }}
                                                            onClick={() => {
                                                                if (Number(user.disbursement2) > 0) {
                                                                        swal({
                                                                            title: "Wire Payment",
                                                                            text: "Have you made the wire payment for the escrow disbursement?",
                                                                            icon: "warning",
                                                                            buttons: true,
                                                                            dangerMode: true,
                                                                        }).then((accept) => {
                                                                            if (accept) {
                                                                                this.exitDisburement(user, Number(user.disbursement2).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }), true);
                                                                            }
                                                                        })
                                                                } else {
                                                                    swal({
                                                                        title: "Error!",
                                                                        text: "The amount should be greater than zero.",
                                                                        icon: "error",
                                                                        button: "Ok!",
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    this.props.exitInProcessEscrowUsers.length === 0 &&
                                    <tr>
                                        <td> No investors found for Exit In Process!</td>
                                    </tr>
                                }

                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        );
    }

}

export default ExitProcessEscrow;
import React, { Component, useState, useEffect } from "react";
import "./MarketTrend.scss";
import "react-phone-input-2/lib/style.css";
import TButton from "../../../components/TButton";
import LoadingSpinner1 from "../../../components/IIR Loader";
import ProfileImage from "../../../components/ProfileImage";
import FileUploadBlob from "../../../components/FileUploadBlob";
import { withRouter } from "react-router-dom";
import LaddaButton, { XS, EXPAND_RIGHT } from "react-ladda";
import axios from "axios";
import swal from "sweetalert";
import common from "../../../common";
import _ from "lodash";
import Modal from "react-bootstrap-modal";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
// Imported Images
import images from "../../../images";

const SubSortableList = SortableContainer(
  ({ items, deleteMarketTrendData, editClick, editClickText }) => {
    return (
      <ul>
        {items.map((value, index) => (
          <SubSortableItem
            key={`item-${index}`}
            index={index}
            value={value}
            deleteMarketTrendData={(i) => deleteMarketTrendData(i)}
            editClick={(value) => editClick(value)}
            editClickText={(value) => editClickText(value)}
          />
        ))}
      </ul>
    );
  }
);

const SubSortableItem = SortableElement(
  ({ value, deleteMarketTrendData, editClick, editClickText }) => (
    <div>
      {value.type === "image" ? (
        <div className="marketTrend-options">
          <div className="marketTrend-option ">
            <div className="leader-img">
              <ProfileImage
                imgSrc={`${common.path.base_url}/getFileNew?fileId=${value.pictureId}`}
              />
              <div className="icons">
                <i
                  onClick={() => editClick(value)}
                  className="fa fa-pencil add-address"
                ></i>
                <img
                  className="delete"
                  onClick={() => {
                    deleteMarketTrendData(value._id);
                  }}
                  src={images.path.deleteIcon}
                ></img>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {value.type === "text" ? (
        <div className="marketTrend-options">
          <div className="marketTrend-option ">
            <p className="marketTrend-content">{value.text}</p>
            <div className="icons">
              <i
                onClick={() => editClickText(value)}
                className="fa fa-pencil add-address"
              ></i>
              <img
                className="delete"
                onClick={() => {
                  deleteMarketTrendData(value._id);
                }}
                src={images.path.deleteIcon}
              ></img>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  )
);

const SortableItem = SortableElement(
  ({ value, deleteMarketTrendData, editClick, editClickText }) => {
    let [MarketTrendData, setMarketTrendData] = useState(value.data);

    useEffect(() => {
      setMarketTrendData(value.data);
    }, [value]);

    const onSortEnd = ({ oldIndex, newIndex }) => {
      setMarketTrendData(
        (MarketTrendData = arrayMove(MarketTrendData, oldIndex, newIndex))
      );
      let order = MarketTrendData.map((ele, i) => {
        return ele.suborder;
      });
      let id = MarketTrendData.map((ele, i) => {
        return ele._id;
      });
      const token = localStorage.getItem("token");
      axios
        .put(
          `${common.path.base_url}/updateSuborderMarketIIR`,
          {
            id: id[0],
            subsequence: order,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response) => { })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message &&
            common.AUTH_EXPIRED === error.response.data.message
          ) {
            window.localStorage.clear();
            window.location.href = "/";
            return;
          } else {
          }
        });
    };

    return (
      <div className="options">
        <div className="option-heading">
          <h3>{value.title}</h3>
        </div>
        <SubSortableList
          pressDelay={200}
          items={MarketTrendData}
          onSortEnd={onSortEnd}
          deleteMarketTrendData={(i) => deleteMarketTrendData(i)}
          editClick={(value) => editClick(value)}
          editClickText={(value) => editClickText(value)}
        />
      </div>
    );
  }
);

const SortableList = SortableContainer(
  ({ items, deleteMarketTrendData, editClick, editClickText }) => {
    return (
      <ul>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={value}
            deleteMarketTrendData={(i) => deleteMarketTrendData(i)}
            editClick={(value) => editClick(value)}
            editClickText={(value) => editClickText(value)}
          />
        ))}
      </ul>
    );
  }
);

class MarketTrend extends Component {
  constructor(props) {
    super(props);
    this.companyId = "";
    this.state = {
      sampleArray: [1, 2, 3],
      text: "",
      textValidation: "",
      heading: "",
      headingValidation: "",
      show: false,
      loading: false,
      loadinglada: false,
      loadinglada2: false,
      Role: "",
      profileFormValidated: false,
      marketTrendData: [],
      choosenFile: null,
      picture: "",
      pictureUpdate: "",
      leaderId: "",
      isEdit: false,
      show2: false,
      imagePop: false,
      textPop: false,
      selectedFieldId: "",
      imageUploading: false,
    };
  }

  componentWillMount() {
    const userRole = window.localStorage.getItem("role");
    this.setState({ Role: userRole });
    const url = new URL(window.location.href);
    this.companyId = url.searchParams.get("companyId");
    // this.companyId = window.localStorage.getItem('companyId');
    this.getmarketTrendData();
  }

  componentDidUpdate() {
    document.addEventListener("keypress", function (event) {
      if (event.keyCode === 13) {
        // event.preventDefault()
      }
    });
  }

  getIIRList() {
    let temp = this;
    temp.setState({ loadinglada2: true });
    let token = window.localStorage.getItem("token");
    axios
      .get(`${common.path.base_url}/getIIR/${this.companyId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const sampleArray = response.data.data;
        const keys = Object.keys(sampleArray);
        const values = Object.values(sampleArray);
        let z = [];
        keys.forEach((key, i) => {
          z.push({ title: key, state: values[i] });
        });
        z.forEach((ele, i) => {
          let title = ele.title.split(" ");
          let path = "";
          for (const word of title) {
            path += word;
          }
          path = path.substring(0, 1).toLowerCase() + path.substring(1);
          ele.path = path;
        });
        z.length = 16;
        this.props.setIirList(z);
        // this.props.history.push(`/iir/marketPerformanceandSummary?companyId=${this.companyId}`)
        temp.setState({ loadinglada2: true });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response.data.code === 204) {
          window.localStorage.clear();
          window.location.href = "/";
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }

  getmarketTrendData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(`${common.path.base_url}/getMarket/${this.companyId}/MarketTrend`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ marketTrendData: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }

  apiMarket() {
    const temp = this;
    temp.setState({ loadinglada: true });
    let tokenpass = window.localStorage.getItem("token");
    if (temp.state.isEdit) {
      if (temp.state.imagePop) {
        temp.setState({ loadinglada: true });
        axios
          .put(
            `${common.path.base_url}/updateIIRMarket`,
            {
              companyId: this.companyId,
              type: "image",
              id: temp.state.selectedFieldId,
              marketType: "MarketTrend",
              pictureId: temp.state.picture,
            },
            {
              headers: {
                Authorization: tokenpass,
              },
            }
          )
          .then((response) => {
            swal({
              title: "Updated!",
              text: response.data.message,
              icon: "success",
              button: "Ok!",
            }).then(() => {
              temp.getmarketTrendData();
              temp.resetFields();
              temp.setState({ show: false });
            });
            temp.setState({ loadinglada: false });
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message &&
              common.AUTH_EXPIRED === error.response.data.message
            ) {
              window.localStorage.clear();
              window.location.href = "/";
              return;
            } else if (error.response) {
              swal({
                title: "Error!",
                text: error.response.data.message,
                icon: "error",
                button: "Ok!",
              });
              temp.setState({ loadinglada: false });
            } else {
              swal({
                title: "Network Error!",
                text:
                  error.response &&
                    error.response.data &&
                    error.response.data.message
                    ? error.response.data.message
                    : error.message,
                icon: "error",
                button: "Ok!",
              });
            }
          });
      }
      if (temp.state.textPop) {
        temp.setState({ loadinglada: true });
        axios
          .put(
            `${common.path.base_url}/updateIIRMarket`,
            {
              companyId: this.companyId,
              type: "text",
              id: temp.state.selectedFieldId,
              marketType: "MarketTrend",
              text: temp.state.text,
            },
            {
              headers: {
                Authorization: tokenpass,
              },
            }
          )
          .then((response) => {
            swal({
              title: "Updated!",
              text: response.data.message,
              icon: "success",
              button: "Ok!",
            }).then(() => {
              temp.getmarketTrendData();
              temp.resetFields();
              temp.setState({ show: false });
            });
            temp.setState({ loadinglada: false });
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message &&
              common.AUTH_EXPIRED === error.response.data.message
            ) {
              window.localStorage.clear();
              window.location.href = "/";
              return;
            } else if (error.response) {
              swal({
                title: "Error!",
                text: error.response.data.message,
                icon: "error",
                button: "Ok!",
              });
              temp.setState({ loadinglada: false });
            } else {
              swal({
                title: "Network Error!",
                text:
                  error.response &&
                    error.response.data &&
                    error.response.data.message
                    ? error.response.data.message
                    : error.message,
                icon: "error",
                button: "Ok!",
              });
            }
          });
      }
    } else if (temp.state.imagePop) {
      axios
        .post(
          `${common.path.base_url}/addIIRMarket`,
          {
            companyId: this.companyId,
            type: "image",
            title: temp.state.heading,
            marketType: "MarketTrend",
            pictureId: temp.state.picture,
          },
          {
            headers: {
              Authorization: tokenpass,
            },
          }
        )
        .then(function (response) {
          temp.setState({ loadinglada: false });
          swal({
            title: "Added",
            text: response.data.message,
            icon: "success",
            button: "Ok!",
          }).then(function () {
            temp.getmarketTrendData();
            temp.resetFields();
            window.location.reload();
          });
        })
        .catch(function (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message &&
            common.AUTH_EXPIRED === error.response.data.message
          ) {
            window.localStorage.clear();
            window.location.href = "/";
            return;
          } else if (error.response) {
            swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              button: "Ok!",
            });
          } else {
            swal({
              title: "Network Error!",
              text:
                error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
          temp.setState({ loadinglada: false });
        });
    } else if (temp.state.textPop) {
      axios
        .post(
          `${common.path.base_url}/addIIRMarket`,
          {
            companyId: this.companyId,
            type: "text",
            title: temp.state.heading,
            marketType: "MarketTrend",
            text: temp.state.text,
          },
          {
            headers: {
              Authorization: tokenpass,
            },
          }
        )
        .then(function (response) {
          temp.setState({ loadinglada: false });
          swal({
            title: "Added",
            text: response.data.message,
            icon: "success",
            button: "Ok!",
          }).then(function () {
            temp.getmarketTrendData();
            temp.resetFields();
            window.location.reload();
          });
        })
        .catch(function (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message &&
            common.AUTH_EXPIRED === error.response.data.message
          ) {
            window.localStorage.clear();
            window.location.href = "/";
            return;
          } else if (error.response) {
            swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              button: "Ok!",
            });
          } else {
            swal({
              title: "Network Error!",
              text:
                error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
          temp.setState({ loadinglada: false });
        });
    }
  }

  uploadLogo(file) {
    if (file.size) {
      const temp = this;
      temp.setState({ imageUploading: true });
      let tokenpass = window.localStorage.getItem("token");
      let data = new FormData();
      data.append("file", file);
      const isPrivate = true;
      data.append("isPrivate", isPrivate);
      axios
        .post(`${common.path.base_url}/uploadFile`, data, {
          headers: {
            Authorization: tokenpass,
          },
        })
        .then((response) => {
          temp.setState({ picture: response.data.data.id });
          temp.setState({ imageUploading: false });
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message &&
            common.AUTH_EXPIRED === error.response.data.message
          ) {
            window.localStorage.clear();
            window.location.href = "/";
            return;
          } else {
          }
        });
    }
  }

  deleteMarketTrendData(index) {
    if (this.state.marketTrendData.length === 1) {
      swal({
        title: "Warning",
        text: "Can not delete the last data",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });
    } else {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willdelete) => {
        const token = localStorage.getItem("token");
        if (willdelete) {
          this.setState({ loading: true });
          axios
            .delete(`${common.path.base_url}/deleteMarketIIR`, {
              headers: { Authorization: token },
              data: { id: index },
            })
            .then((response) => {
              this.setState({ loading: false });
              swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
                button: "Ok!",
              }).then(() => {
                this.getmarketTrendData();
              });
            })
            .catch((error) => {
              this.setState({ loading: false });
              if (
                error.response &&
                error.response.data &&
                error.response.data.message &&
                common.AUTH_EXPIRED === error.response.data.message
              ) {
                window.localStorage.clear();
                window.location.href = "/";
                return;
              } else {
                swal({
                  title: "Error!",
                  text:
                    error.response &&
                      error.response.data &&
                      error.response.data.message
                      ? error.response.data.message
                      : "Something Went Wrong",
                  icon: "error",
                  button: "Ok!",
                });
              }
            });
        }
      });
    }
  }

  handleChange(id, newValue) {
    if (id === "heading") this.setState({ heading: newValue });
    else if (id === "text") this.setState({ text: newValue });
  }

  formValidaton(id, val) {
    if (id === "heading") {
      if (val === "") {
        this.setState({
          headingValidation: "Heading should not be empty*",
        });
      } else {
        this.setState({
          headingValidation: "",
        });
      }
    } else if (id === "text") {
      if (val === "") {
        this.setState({
          textValidation: "Text should not be empty*",
        });
      } else {
        this.setState({
          textValidation: "",
        });
      }
    }
  }

  validateProfileForm() {
    if (!_.isEmpty(this.state.name && this.state.title && this.state.Bio)) {
      this.setState({ profileFormValidated: true });
    } else {
      this.setState({ profileFormValidated: false });
    }
  }

  resetFields() {
    this.setState({
      text: "",
      heading: "",
      show: false,
      imagePop: false,
      textPop: false,
      isEdit: false,
      choosenFile: null,
      picture: "",
      textValidation: "",
      headingValidation: "",
      selectedFieldId: "",
    });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ marketTrendData }) => ({
      marketTrendData: arrayMove(marketTrendData, oldIndex, newIndex),
    }));
    let order = this.state.marketTrendData.map((ele, i) => {
      return ele.order;
    });
    let id = this.state.marketTrendData.map((ele, i) => {
      return ele.data;
    });
    const token = localStorage.getItem("token");
    axios
      .put(
        `${common.path.base_url}/updateOrderMarketIIR`,
        {
          id: id[0][0]._id,
          sequence: order,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => { })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else {
        }
      });
  };

  onEditClick(element) {
    this.setState({
      show: true,
      isEdit: true,
      imagePop: true,
      modalTitle: "Update Image",
      modalButtonTitle: "UPDATE",
      picture: element.pictureId,
      selectedFieldId: element._id,
    });
  }

  onEditClickText(element) {
    this.setState({
      show: true,
      isEdit: true,
      textPop: true,
      modalTitle: "Update Text",
      modalButtonTitle: "UPDATE",
      text: element.text,
      selectedFieldId: element._id,
    });
  }

  render() {
    return (
      <div className="marketTrend">
        {this.state.loading ? <LoadingSpinner1 /> : ""}
        <div className="marketTrend-main">
          <div className="heading">
            <h3>Market Trends</h3>
          </div>
          <div className="marketTrend">
            <div className="marketTrend-details">
              <form className="form-horizontal">
                <div className="form-group">
                  <div className="col-md-8 addNewButton ">
                    <TButton
                      onClick={() => {
                        this.setState({
                          show: true,
                          imagePop: true,
                          modalTitle: "Add Image",
                          modalButtonTitle: "ADD",
                        });
                      }}
                      text={`ADD IMAGE`}
                      id="update-btn"
                    />

                    <div className="add-txt">
                      <TButton
                        onClick={() => {
                          this.setState({
                            show: true,
                            textPop: true,
                            modalTitle: "Add Text",
                            modalButtonTitle: "ADD",
                          });
                        }}
                        text={`ADD TEXT`}
                        id="update-btn"
                      />
                    </div>
                    <div className="save-btn">
                      {/* <TButton
                                                id="update-btn"
                                                text={`SAVE & NEXT`}
                                                disabled={_.isEmpty(this.state.marketTrendData) ? true : false}
                                                onClick={() => {
                                                    this.getIIRList();
                                                    this.props.history.push(`/iir/marketPerformanceandSummary?companyId=${this.companyId}`)
                                                }}
                                            /> */}
                      <LaddaButton
                        id="update-btn"
                        loading={this.state.loadinglada2}
                        onClick={() => {
                          this.getIIRList();
                        }}
                        data-color="#eee"
                        data-size={XS}
                        data-style={EXPAND_RIGHT}
                        data-spinner-size={30}
                        data-spinner-color="#ddd"
                        data-spinner-lines={12}
                        className={
                          _.isEmpty(this.state.marketTrendData) ? "disable" : ""
                        }
                      >
                        SAVE
                      </LaddaButton>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="footBorder"></div>
          <SortableList
            pressDelay={200}
            items={this.state.marketTrendData}
            onSortEnd={this.onSortEnd}
            deleteMarketTrendData={(i) => this.deleteMarketTrendData(i)}
            editClick={(value) => this.onEditClick(value)}
            editClickText={(value) => this.onEditClickText(value)}
          />
        </div>

        <Modal
          id="addimage"
          aria-labelledby="ModalHeader"
          show={this.state.show}
        >
          <Modal.Header>
            <Modal.Title id="ModalHeader">{this.state.modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.imagePop ? (
              <div className="marketTrend">
                <div className="marketTrend">
                  <form className="form-horizontal">
                    {this.state.isEdit ? (
                      ""
                    ) : (
                      <div className="form-group">
                        <label className="col-md-3 control-label">
                          Heading:
                        </label>
                        <div className="col-md-8">
                          <input
                            className="form-control"
                            id="heading"
                            type="text"
                            placeholder="Enter Heading"
                            onKeyUp={(event) =>
                              this.formValidaton(
                                event.target.id,
                                event.target.value
                              )
                            }
                            onChange={(event) =>
                              this.handleChange(
                                event.target.id,
                                event.target.value
                              )
                            }
                            value={this.state.heading}
                          />
                          <p>{this.state.headingValidation}</p>
                        </div>
                      </div>
                    )}
                    <div className="form-group picture">
                      <label className="col-md-3 control-label company-label">
                        Image:
                      </label>
                      <div className="col-md-8">
                        <div className="upload-marketTrend-pic">
                          {this.state.isEdit ? (
                            <div className="marketTrend-pic-box">
                              <img
                                id="preview"
                                className="profile-pic"
                                src={
                                  this.state.imageUploading
                                    ? `${images.path.loader}`
                                    : `${common.path.base_url}/getFileNew?fileId=${this.state.picture}`
                                }
                                alt=""
                              />
                              <div>
                                <div className="p-image1">
                                  <i
                                    onClick={() =>
                                      document
                                        .querySelector(`#chooseFileUpdate`)
                                        .click()
                                    }
                                    className="fa fa-pencil edit-button"
                                  ></i>
                                  <FileUploadBlob
                                    id={`chooseFileUpdate`}
                                    getFile={(file) => {
                                      this.setState({ choosenFile: file });
                                      this.uploadLogo(this.state.choosenFile);
                                    }}
                                    acceptType={`image/*`}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="marketTrend-pic-box">
                              <img
                                id="preview"
                                className="profile-pic"
                                src={
                                  this.state.imageUploading
                                    ? `${images.path.loader}`
                                    : `${common.path.base_url}/getFileNew?fileId=${this.state.picture}`
                                }
                                alt=""
                              />
                              {this.state.choosenFile !== null ? (
                                ""
                              ) : (
                                <div>
                                  <div className="p-image">
                                    <i
                                      onClick={() =>
                                        document
                                          .querySelector(`#chooseFile${1}`)
                                          .click()
                                      }
                                      className="fa fa-plus-circle upload-button"
                                    ></i>
                                    <FileUploadBlob
                                      id={`chooseFile${1}`}
                                      getFile={(file) => {
                                        this.setState({ choosenFile: file });
                                        this.uploadLogo(this.state.choosenFile);
                                      }}
                                      acceptType={`image/*`}
                                    />
                                  </div>
                                  <div className="logo-name">Image</div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              <div className="marketTrend">
                <div className="marketTrend">
                  <form className="form-horizontal">
                    {this.state.isEdit ? (
                      ""
                    ) : (
                      <div className="form-group">
                        <label className="col-md-3 control-label">
                          Heading:
                        </label>
                        <div className="col-md-8">
                          <input
                            className="form-control"
                            id="heading"
                            type="text"
                            placeholder="Enter Heading"
                            onKeyUp={(event) =>
                              this.formValidaton(
                                event.target.id,
                                event.target.value
                              )
                            }
                            onChange={(event) =>
                              this.handleChange(
                                event.target.id,
                                event.target.value
                              )
                            }
                            value={this.state.heading}
                          />
                          <p>{this.state.headingValidation}</p>
                        </div>
                      </div>
                    )}
                    <div className="form-group">
                      <label className="col-md-3 control-label">Text:</label>
                      <div className="col-md-8">
                        <input
                          className="form-control"
                          id="text"
                          type="text"
                          placeholder="Enter Text"
                          onKeyUp={(event) =>
                            this.formValidaton(
                              event.target.id,
                              event.target.value
                            )
                          }
                          onChange={(event) =>
                            this.handleChange(
                              event.target.id,
                              event.target.value
                            )
                          }
                          value={this.state.text}
                        />
                        <p>{this.state.textValidation}</p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {this.state.imagePop ? (
              <LaddaButton
                id="lada-btn"
                loading={this.state.loadinglada}
                data-color="#eee"
                data-size={XS}
                onClick={() => this.apiMarket()}
                className={
                  this.state.isEdit
                    ? this.state.choosenFile === null ||
                      this.state.imageUploading
                      ? "disable"
                      : ""
                    : _.isEmpty(this.state.heading) ||
                      this.state.choosenFile === null ||
                      this.state.imageUploading
                      ? "disable"
                      : ""
                }
                data-style={EXPAND_RIGHT}
                data-spinner-size={30}
                data-spinner-color="#ddd"
                data-spinner-lines={12}
              >
                {this.state.modalButtonTitle}
              </LaddaButton>
            ) : (
              <LaddaButton
                id="lada-btn"
                loading={this.state.loadinglada}
                data-color="#eee"
                data-size={XS}
                onClick={() => this.apiMarket()}
                className={
                  this.state.isEdit
                    ? !_.isEmpty(this.state.text)
                      ? ""
                      : "disable"
                    : !_.isEmpty(this.state.text && this.state.heading)
                      ? ""
                      : "disable"
                }
                data-style={EXPAND_RIGHT}
                data-spinner-size={30}
                data-spinner-color="#ddd"
                data-spinner-lines={12}
              >
                {this.state.modalButtonTitle}
              </LaddaButton>
            )}
            <input
              type="button"
              id="company-cancel"
              className="btn btn-default"
              data-dismiss="modal"
              value="CANCEL"
              onClick={() => this.resetFields()}
            />
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
// export default Profile;
export default withRouter(MarketTrend);

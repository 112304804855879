import React, { Component } from 'react';

import './globalSettings.scss';
import LoadingSpinner from '../../components/Loader';
import Header from '../../components/Header';
import HeaderImage from '../../components/HeaderImage';
import GlobalInvestorFollowup from './components/GlobalInvestorsFollowup';
import InvestorFaq from './components/investorFaqPreview/investorFaqPreview';
import StartupFaq from './components/FaqPreview';
import ChecklistManagement from '../Checklist-Management/ChecklistManagement.container';
import PostCloseChecklistManagement from '../Post-Checklist-Management/PostChecklistManagement.container';
import ExitChecklistManagement from '../ExitChecklistManagement/exitChecklistManagement.container'
import YearInReviewSetting from '../YearReview/YearReviewSettings';
import FundingInstructions from '../FundingInstructions/FundingInstructionsSettings';
import ManagementFeesandCarry from '../ManagementFees-Carry/ManagementFeesandCarry';

import TempleteEmail from './components/TempleteEmail/TempleteEmail';


class GlobalSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userRoleCode: '',
      role: '',
      name: '',
      leftMenu: [
        { label: 'Investment Checklist Managment', isActive: false },
        { label: 'Post Close Checklist Managment', isActive: false },
        { label: 'Exit Checklist Managment', isActive: true },
        { label: 'Startup FAQ', isActive: false },
        { label: 'Investor FAQ', isActive: false },
        { label: 'Investor Follow Up', isActive: false },
        { label: 'TSG Review', isActive: false },
        { label: 'Funding Instructions', isActive: false },
        { label: 'Template', isActive: false , isToggle:false },
        { label: 'Management Fee & Carry', isActive: false },
      ],
      tempSubMenu:[
        { label: 'Email', isActive: true },
        { label: 'SMS', isActive: false },
      ],
    }
  }

  componentDidMount() {
    this.setState({
      userRoleCode: window.localStorage.getItem('roleCode'),
      role: window.localStorage.getItem('role'),
      name: window.localStorage.getItem('username'),
    });
  }

  onLeftMenuClick = (index) => {
    const leftMenuClone = this.state.leftMenu.map(singleMenu => {
      singleMenu.isActive = false;
      return singleMenu;
    });
    leftMenuClone[index].isActive = true;
    if (index == 8) {
      leftMenuClone[index].isToggle = (leftMenuClone[index].isToggle == true) ? false : true;
    }
    //console.log(leftMenuClone);
    this.setState({ leftMenu: leftMenuClone });
  }

  subMenuTag = (index) => {
    
    const tempSubClone = this.state.tempSubMenu.map(singleMenu => {
      singleMenu.isActive = false;
      return singleMenu;
    });
    tempSubClone[index].isActive = true;
    this.setState({ tempSubMenu: tempSubClone });
  
  }

  render() {
    return (
      <div className="globel-sec">
        <Header
          username={this.state.name}
          role={this.state.role}
        />
        {
          (this.state.userRoleCode === '001' || this.state.userRoleCode === '002') &&
          <HeaderImage
            heading={this.state.currentInvestorName ? this.state.currentInvestorName : this.state.role}
          />
        }

        <div className="title-section wrapper">
          <div className="header-txt">Global Settings</div>
        </div>
        <div className="hr-line wrapper"></div>

        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-2 col-sm-2">
              <div className="left-menu">
                <ul>
                  {
                    this.state.leftMenu.map((singleMenu, index) => {
                      
                      if(index != 8) {
                        return <li key={index} onClick={() => this.onLeftMenuClick(index)}>
                          <span className={singleMenu.isActive ? 'active' : ''}>{singleMenu.label}</span>
                        </li>
                      } else {
                        return <li key={index} onClick={() => this.onLeftMenuClick(index)} className="">
                            <span className={singleMenu.isActive ? 'active' : ''}>{singleMenu.label}<i className="fa fa-angle-down pull-right" aria-hidden="true"></i></span>
                            
                            <ul className={ singleMenu.isActive ? 'temp-menu-css' : 'temp-view-menu' }>
                              {
                                this.state.tempSubMenu.map((subMenu, subIndex) => {
                                  return <li key={subIndex}><span className={subMenu.isActive ? 'active' : ''} onClick={() => this.subMenuTag(subIndex)} >{subMenu.label }</span></li>
                                })
                              }
                            </ul>
                        </li>

                        // <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                        //     Template 
                        //     <i className="fa fa-angle-down pull-right" aria-hidden="true"></i>
                        //   </span>
                        //   <div className="dropdown-menu email-sms-dropdown" aria-labelledby="navbarDropdown">
                        //     <li><a className="dropdown-item2" href="javascript:void(0)">Email</a></li>
                        //     <li><a className="dropdown-item2" href="javascript:void(0)">SMS</a></li>
                        //   </div>
                      }
                    })
                  }
                </ul>
              </div>
            </div>

            <div className="col-lg-10 col-md-10 col-sm-10">
              {
                this.state.leftMenu[0].isActive &&
                <ChecklistManagement />
              }
              {
                this.state.leftMenu[1].isActive &&
                <PostCloseChecklistManagement />
              }
              {
                this.state.leftMenu[2].isActive &&
                <ExitChecklistManagement />
              }
              {
                this.state.leftMenu[3].isActive &&
                <StartupFaq />
              }
              {
                this.state.leftMenu[4].isActive &&
                <InvestorFaq />
              }
              {
                this.state.leftMenu[5].isActive &&
                <GlobalInvestorFollowup />
              }
              {
                this.state.leftMenu[6].isActive &&
                <YearInReviewSetting
                  {...this.props}
                />
              }
              {
                this.state.leftMenu[7].isActive &&
                <FundingInstructions
                  {...this.props}
                />
              }
              {
                this.state.leftMenu[8].isActive &&
                <TempleteEmail
                  getTempSubMenu={this.state.tempSubMenu}
                  {...this.props}
                 />
              }
              {
                this.state.leftMenu[9].isActive &&
                <ManagementFeesandCarry
                  {...this.props}
                />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GlobalSettings;
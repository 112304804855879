import React from 'react';
import './FileUploadBlobUpdate.scss';


const FileUploadBlobUpdate = (props) => {

    const previewFile1 = () => {
        
        const preview = document.getElementById('preview1');
        const file1 = document.querySelector(props.id? '#'+props.id :`#chooseFileUpdate`).files[0];
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            preview.src = reader.result;
            if (props.getFile1) {
                props.getFile1(file1);
            }
        }, false);

        if (file1) {
            reader.readAsDataURL(file1);
        }


    }

    return (
        <input
            id={props.id? props.id :`chooseFileUpdate`}
            className={`file-upload ${props.className && props.className}`}
            onChange={() => previewFile1()}
            type="file"
            accept={props.acceptType ? props.acceptType : ``}
        />
    );

}

export default FileUploadBlobUpdate;
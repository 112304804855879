import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import axios from 'axios';
import images from "../../images";
import common from '../../common';
import swal from 'sweetalert';
import './pipelineCss/pipeline.scss';
import './pipelineCss/default.scss';
import { Icons } from './Icons';
import NumberFormat from 'react-number-format';

const IncumbentData = (props) => {

    const { index, singleData, incumbentDelete, companyId } = props;
    
    const incumbentuplodRef = useRef(null);

    const [generalCall, setGeneralCall] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [incumbentImg, setIncumbentImg] = useState('');
    const [incumbentId, setIncumbentId] = useState('');
    
    const updateIncumbent = (e ) => {

        setGeneralCall(true);
        setCompanyName(e.target.value);
    }

    useEffect(() => {
        setCompanyName(singleData.companyName);
        setIncumbentId(singleData._id);
    }, [ singleData ]);
    
    useEffect(() => {

        if(generalCall) {
        
            const timer = setTimeout(() => {
        
                //console.log(companyName);
                let newObj = {
                    'companyName' : companyName,
                    'imgUrl' : ""
                }
                let sendData = { 'incumbentPlayers' : newObj, 'incumbentPlayersId':incumbentId, 'companyId' : companyId, 'imgType' : 'incumbentPlayers', 'incumbentType': 'update' }
                let dataInfo = new FormData();
                if (Object.keys(incumbentImg).length > 0 ) {
                    dataInfo.append('file', incumbentImg.inputFile);
                } 
                dataInfo.append('data', JSON.stringify(sendData));

                let token = window.localStorage.getItem('token');
                axios.post(`${common.path.base_url}/updatePipline`, dataInfo, {
                    headers: {
                        "Authorization": token
                    }
                })
                .then((response) => {
                
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    
                        window.localStorage.clear();
                        window.location.href = '/';
                        return;
                    } else {
                        swal({
                            title: "Error! #33005",
                            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                });
        
            }, 2000);
            return () => clearTimeout(timer);    
        
        }
    }, [ companyName, incumbentImg ]);

    
    const previewFile = (index) => {
        let preview
        let imageFiles
        let id = 'inputIncumbentFile_'+index;
        let perviewId = 'incumbentPreview_'+index;
        preview = document.getElementById(perviewId);
        imageFiles = document.getElementById(id);
        
        const file = imageFiles.files[0];
        const data = new FormData()
        data.append('file', file);
        setGeneralCall(true);
        setIncumbentImg({ inputFile: file });
        
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            preview.src = reader.result;
        }, false);
        
        if (file) {
            reader.readAsDataURL(file);
        }
    }

    return (
        <>  
            <div key={ "incumbent_"+index } className='listitemCont'>
                <div className='mr-10' onClick={(e) => (incumbentuplodRef.current.click())} >
                    { ( singleData.imgUrl != "" && singleData.imgUrl != null && singleData.imgUrl != 'undefined') ? 
                        <img id={ 'incumbentPreview_'+index } src={`${common.path.base_url}/getImage?type=company&typeCode=incumbentPlayers&codeId=${singleData._id}&id=${companyId}`} className='incumbentImage' alt='' />
                    : 
                        <img id={ 'incumbentPreview_'+index } src={ images.path.placeholder } className='incumbentImage' alt='' />
                    }

                    <input id={"inputIncumbentFile_"+index} className='piple-input' type="file" ref={incumbentuplodRef} onChange={() => previewFile(index)} accept="image/jpeg,image/png,image/jpg" />

                </div>
                <div className='socialinputCont'>
                    <input type="text" className='defaultInput piple-input' placeholder='Enter Key Partner' value={ companyName } onChange={ (e) => updateIncumbent(e) } />
                </div>
                <div className='keypartnersTrash mr-10' onClick={() => { incumbentDelete(index); }}>{ Icons.Trash }</div>
            </div>
        </>
    )
}

export default IncumbentData;
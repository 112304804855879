import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import axios from 'axios';
import images from "../../images";
import common from '../../common';
import swal from 'sweetalert';
import Moment from 'react-moment';
import './pipelineCss/pipeline.scss';
import './pipelineCss/default.scss';
import { Icons } from './Icons';

const Tasks = (props) => {

    const { companyId, companyData, taskArr, deleteHandle, editHandle, newAddHandle } = props;
    
    const [taskArrData, setTaskArrData] = useState([...taskArr]);
    

    useEffect(() => {
        if( companyData != "" && companyData != undefined && companyData != null) {
            setTaskArrData(companyData.tasks);
        }
    }, [companyData]);

    useEffect(() => {
        setTaskArrData([...taskArr]);
    }, [taskArr]);
    
    return (
        <>
            <div className='noteCont'>
                <div className='sectionHeading' >Task</div>
                <div>
                    <button className='addnewBTN' onClick={(e) => newAddHandle() }>Add New</button>
                </div>
            </div>
            <div>
                { (taskArrData.length > 0) ?  
                    taskArrData.map((singleData, index) => { 
                        return <div key={ 'task_'+index } className='noteitemlistCont border-b mt-20'>
                            <div className='notemetadataCont'>
                                <div>
                                    <div className='sectionHeading2'>{ singleData.assigned }</div>
                                    <div className='notedatetime'>
                                        <Moment
                                            date={ singleData.date }
                                            format="MMMM DD, Y"
                                        />
                                    </div>
                                </div>
                                <div className='actioniconCont'>
                                    <div onClick={(e) => editHandle(index) } className='mr-10'>{Icons.EditOrng}</div>
                                    <div onClick={(e) => deleteHandle(index) }>{Icons.Trash}</div>
                                </div>
                            </div>
                            <div className='notecontentCont mt-10'>
                                <div className='noteLabel'>Task</div>
                                <div className='noteDetail'>{ singleData.task }</div>
                            </div>
                        </div>
                    })
                    : ""
                }
                {/* <div className='addmoreCont no-border '>
                    <div className='loadmore' >LOAD MORE</div>
                </div> */}
            </div>
        </>
    )
}

export default Tasks;
import React, { Component } from 'react';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import axios from 'axios';
import swal from 'sweetalert';
import common from '../../common';
import RequiredSign from '../RequiredSign';
import Autosuggest from 'react-autosuggest';
import InvestorService from '../../InvestorDashboard/services/investor.service';



class RecommendStartup extends Component {
    constructor() {
        super();
        this.state = {
            companyName: '',
            companyId: '',
            email: '',
            errorWebisteUrl: '',
            website: '',
            likeThemComments: '',
            currentPartner: '',
            makeIntro: '',
            isCurrentPartner: false,
            isWebsiteReadOnly: false,
            suggestions: [],
            isMakeIntro: false,
            loadinglada: false,
            value: '',
            recommendations: [],
        }
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    }
    componentDidMount() {
        InvestorService.recommendations().then((value) => {
            this.state.recommendations = value.data.data;
        });
    }
    componentWillReceiveProps(nextProps) {
        nextProps.onRef && nextProps.onRef(this);
    }
    currentPartnerChange(data, type) {
        if (type == 'partner') {
            this.setState({ currentPartner: data });
        }
        if (type == 'make') {
            this.setState({ makeIntro: data });
        }
    }
    onSuggestionSelected(event, { suggestion }) {
        if (suggestion && suggestion._id) {
            this.setState({ companyId: suggestion._id })
        }
        if (suggestion && suggestion.website) {
            this.setState({ website: suggestion.website });
            this.setState({ isWebsiteReadOnly: true });
        }
        this.setState({ errorWebisteUrl: '' });
    }
    getSuggestionValue = suggestion => suggestion.companyName;
    toInputUppercase = (value) => {
        const sentence = value.toLowerCase().replace(/(^\w|\.\s*\w)/g, (match) =>
            match.toUpperCase()
        );
        return sentence;
    };
    renderSuggestion = suggestion => (
        <div>
            {suggestion.companyName}
        </div>
    );

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({ suggestions: this.getSuggestions(value) });
    };

    onSuggestionsClearRequested = () => {
        this.setState({ suggestions: [] });
    };

    onChangeInput(id, value) {
        if (id === 'website') {
            const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
            const regex = new RegExp(expression);
            if (value.match(regex)) {
                this.setState({ errorWebisteUrl: '' });
            } else {
                this.setState({ errorWebisteUrl: 'Please enter a valid Website URL' });
            }
            this.setState({ website: value });
        } else if (id === 'likeThem') {
            const newValue = this.toInputUppercase(value);
            this.setState({ likeThemComments: newValue });
        }
    }
    getSuggestions = (value) => {
        const inputValue = value.toLowerCase();
        const inputLength = inputValue.length;
        return inputLength === 0 ? [] : this.state.recommendations.filter(recommendation =>
            recommendation.companyName.toLowerCase().slice(0, inputLength) === inputValue
        );
    };
    onChange = (event, { newValue }) => {
        const value = this.toInputUppercase(newValue);
        this.setState({
            companyId: '',
            companyName: value,
            isWebsiteReadOnly: false,
            website: '',
            value: value,
        });
    };

    resetData() {
        this.setState(this.state = {
            companyName: '',
            companyId: '',
            email: '',
            errorWebisteUrl: '',
            website: '',
            likeThemComments: '',
            currentPartner: '',
            makeIntro: '',
            isCurrentPartner: false,
            isWebsiteReadOnly: false,
            suggestions: [],
            isMakeIntro: false,
            loadinglada: false,
        })
    }
    recommendStartup() {
        const temp = this;
        this.setState({ loadinglada: true });
        if (this.state.currentPartner === "yes") {
            this.state.isCurrentPartner = true;
        } else {
            this.state.isCurrentPartner = false;
        }
        if (this.state.makeIntro === "yes") {
            this.state.isMakeIntro = true;
        } else {
            this.state.isMakeIntro = false;
        }

        const data = {
            companyId: this.state.companyId,
            companyName: this.state.companyName,
            isCurrentPartner: this.state.isCurrentPartner,
            isMakeIntro: this.state.isMakeIntro,
            likeThemComments: this.state.likeThemComments,
            website: this.state.website,
        };
        InvestorService.saveRecommendation(data)
            .then(() => {
                return InvestorService.recommendations();
            })
            .then((recommendations) => {
                if (
                    recommendations &&
                    recommendations.data &&
                    recommendations.data.data
                ) {
                    this.setState({ recommendations: recommendations.data.data });
                }
                swal({
                    title: "Success!",
                    text: "Thank you for your recommendation!",
                    icon: "success",
                    button: "Ok!",
                }).then(() => {
                    temp.setState({ loadinglada: false });
                });
            })
            .catch((error) => {
                temp.setState({ loadinglada: false });
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message &&
                    common.AUTH_EXPIRED === error.response.data.message
                ) {
                    window.localStorage.clear();
                    window.location.href = "/";
                    return;
                } else {
                    swal({
                        title: "Error!",
                        text:
                            error.response &&
                                error.response.data &&
                                error.response.data.message
                                ? error.response.data.message
                                : "Something Went Wrong",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    render() {
        return (
            <div id="recommendStartupModal" className="modal fade">
                <div className="modal-dialog">
                    <div className="modal-content invite-contact">
                        <div className="modal-header">
                            <h4 className="modal-title">Recommend a Startup</h4>
                        </div>
                        <div className="modal-body">
                            <form className="form-horizontal recommand_form">
                                <div className="row">
                                    <div className="col-sm-7 col-md-12">
                                        <div className="form-group">
                                            <label style={{ fontWeight: "600" }} htmlFor="companyName">Company Name <RequiredSign /> :</label>
                                            <div className='recommendation-input'>
                                                {/* <input type="text"
                                                    value={this.state.name}
                                                    onChange={(event) => this.setState({ name: event.target.value })}
                                                    name="name" className="form-control" id="name" placeholder=""
                                                /> */}
                                                <Autosuggest
                                                    suggestions={this.state.suggestions}
                                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                    getSuggestionValue={this.getSuggestionValue}
                                                    renderSuggestion={this.renderSuggestion}
                                                    onSuggestionSelected={(event, { suggestion }) => this.onSuggestionSelected(event, { suggestion })}
                                                    inputProps={{
                                                        placeholder: 'Enter Company Name...',
                                                        onChange: this.onChange,
                                                        value: this.state.value,
                                                    }}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-7 col-md-12">
                                        <div className="form-group">
                                            <label style={{ fontWeight: "600" }} htmlFor="name">Company Website :</label>
                                            <div className='recommendation-input'>
                                                <input type="text"
                                                    value={this.state.website}
                                                    readOnly={this.state.isWebsiteReadOnly}
                                                    onChange={(event) => {
                                                        this.onChangeInput('website', event.target.value)
                                                    }}
                                                    name="website" className="form-control" id="website" placeholder="Enter Company Website"
                                                />
                                                <p style={{ color: 'red' }}>{this.state.errorWebisteUrl}</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-7 col-md-12">
                                        <div className="form-group">
                                            <label style={{ fontWeight: "600" }} htmlFor="name">Why I Like Them <RequiredSign />:</label>
                                            <div className="recommendation-input">
                                                <textarea
                                                    name="likeThem" className="form-control like-them"
                                                    id="likeThem"
                                                    placeholder="Enter Reason"
                                                    required
                                                    value={this.state.likeThemComments}
                                                    // autofocus
                                                    onChange={(event) => this.onChangeInput('likeThem', event.target.value)}
                                                >
                                                </textarea>
                                                {/* <img src={path.investorRecommendation} alt="Recommendation" className="mob-app" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label style={{ fontWeight: "600" }} htmlFor="name">Current Partner? <RequiredSign />:</label>
                                            <div className="recommendation-input">
                                                <div className="checklist_bg">
                                                    <div className="chiller_cb">
                                                        <input id="myCheckbox5" type="radio" name="partner"
                                                            value='yes'
                                                            //defaultChecked={isMakeIntro ? true : false}
                                                            checked={this.state.currentPartner === 'yes'}
                                                            onChange={(event) => this.currentPartnerChange(event.target.value, 'partner')}
                                                        />
                                                        <label style={{ fontWeight: "600" }} htmlFor="myCheckbox5" className='radio-label'>Yes</label>
                                                        <span />
                                                    </div>
                                                    <div className="chiller_cb">
                                                        <input id="myCheckbox6" type="radio" name="partner"
                                                            value='no'
                                                            checked={this.state.currentPartner === 'no'}
                                                            onChange={(event) => this.currentPartnerChange(event.target.value, 'partner')}
                                                        />
                                                        <label style={{ fontWeight: "600" }} htmlFor="myCheckbox6" className='radio-label'>No</label>
                                                        <span />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label style={{ fontWeight: "600" }} htmlFor="name">I Can Make An Intro <RequiredSign />:</label>
                                            <div className="recommendation-input">
                                                <div className="checklist_bg">
                                                    <div className="chiller_cb">
                                                        <input id="myCheckbox3" type="radio" name="makeIntro"
                                                            value='yes'
                                                            //defaultChecked={isMakeIntro ? true : false}
                                                            checked={this.state.makeIntro === 'yes'}
                                                            onChange={(event) => this.currentPartnerChange(event.target.value, 'make')}
                                                        />
                                                        <label style={{ fontWeight: "600" }} htmlFor="myCheckbox3" className='radio-label'>Yes</label>
                                                        <span />
                                                    </div>
                                                    <div className="chiller_cb">
                                                        <input id="myCheckbox4" type="radio" name="makeIntro"
                                                            value='no'
                                                            checked={this.state.makeIntro === 'no'}
                                                            onChange={(event) => this.currentPartnerChange(event.target.value, 'make')}
                                                        />
                                                        <label style={{ fontWeight: "600" }} htmlFor="myCheckbox4" className='radio-label'>No</label>
                                                        <span />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <input type="button" id="cancelRecommendStartup" className="btn btn-default" data-dismiss="modal" value="CANCEL" onClick={() => { this.resetData() }} />
                            <LaddaButton
                                id='lada-btn'
                                style={{ width: '25%' }}
                                loading={this.state.loadinglada}
                                onClick={() => this.recommendStartup()}
                                data-color="#eee"
                                data-size={XS}
                                data-style={EXPAND_RIGHT}
                                data-spinner-size={30}
                                data-spinner-color="#ddd"
                                data-spinner-lines={12}
                                data-dismiss="modal"
                                className={this.state.companyName && this.state.likeThemComments && !this.state.errorWebisteUrl ? '' : 'disable'}

                            >
                                {'RECOMMEND A STARTUP'}
                            </LaddaButton>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default RecommendStartup;
import React from 'react';
import './TempleteEmail.scss';

const ListTempleteEmail = (props) => {

    const setData = (templateData) => {
        if(templateData.investments){
            props.editTemplate(templateData.investments)
        }else{
            props.editTemplate(templateData)
        }
    }
        return (
            <div>
                <div className="new-investment">
                    <h2>Email Template</h2>
                </div>
                <div className="custom-table-view">
                    <table className='table table-custom'>
                        <thead>
                            <tr>
                                <th width="40%">Title</th>
                                <th width="50%">Subject</th>
                                <th width="10%">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.getTempleteList && 
                                props.getTempleteList.map((templateData) => {
                                    
                                    return <tr>
                                        <td>
                                            <div className="document">{ templateData.title }</div>
                                        </td>
                                        <td>
                                            <div className="select-item">
                                            { templateData.subject }
                                            </div>
                                        </td>
                                        <td>
                                            <div className="select-item">
                                                <a title="Edit" style={{ cursor: 'pointer' }} onClick={()=>setData(templateData)}>
                                                    <i className="fa fa-pencil fa-cust-pencil" aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                })
                            }    
                        </tbody>
                    </table>
                </div>
            </div>
        );
}
export default ListTempleteEmail;    
const reducer = (state = {}, action) => {
  switch (action.type) {
    case 'IS_LOADING':
      return true;
    case 'IS_NOT_LOADING':
      return false;
    default:
      return state;
  }
}

export default reducer
import React from 'react';
import { connect } from 'react-redux';
import Documents from './Documents.component';
import { getInvestmentLogoFromS3 } from '../../shared/helper';
import { viewDocs } from './viewDocs';
import { path } from "../../imagesPath"

const Initiate = props => {
  const { investmentProcess, userData } = props;
  let companyName = '';
  let legalName = '';
  if (investmentProcess && investmentProcess.investmentCompanies && investmentProcess.investmentCompanies.length > 0) {
    companyName = investmentProcess.investmentCompanies[0].companyName;
    legalName = investmentProcess.investmentCompanies[0].legalName;
  }
  const mapDateFormat = (mongoDate) => {
    if (!mongoDate) return null;
    const monthNames = [
      "01", "02", "03",
      "04", "05", "06", "07",
      "08", "09", "10",
      "11", "12"
    ];
    let date = new Date(mongoDate);
    let day = date.getDate();
    let monthIndex = date.getMonth();
    let year = date.getFullYear();
    return monthNames[monthIndex] + '/' + day + '/' + year;

  }

  const Document = props => {
    return (
      <ul>
        <li onClick={() => viewDocs(investmentProcess.investmentCompanies[0]._id, investmentProcess.investmentprocesses[0].investmentId, 'IIR')}>
          <img src={path.report} alt='' className='report' />
          <p>Investment Intelligence Report</p>
        </li>
        <li onClick={() => viewDocs(investmentProcess.investmentCompanies[0]._id, investmentProcess.investmentprocesses[0].investmentId, 'FAQ')}>
          <img src={path.question && path.question} alt='' className='question' />
          <p>Investor FAQs</p>
        </li>
      </ul>
    )
  }

  return (
    <>
      {/* <div className='back-dashboard' onClick={() => window.location.href = '/investor-dashboard'}>
        <button className='btn btn-danger' >Back to Dashboard</button>
      </div> */}
      <div className="new_investment">
        <div className="container-fluid">
          {
            investmentProcess && investmentProcess.investmentCompanies && investmentProcess.investmentCompanies.length > 0 &&
            <div className='process-detail'>
              <div className='profile-process-image'>
                <img src={getInvestmentLogoFromS3(investmentProcess.investmentCompanies[0])} alt='' />
              </div>
              <div className="investment-content">
                <h2 className=''>{investmentProcess.invitedInvestments[0].name}</h2>
                <h5>{investmentProcess.invitedInvestments[0].isShowToInvestor && 'Investment Deadline :'} {investmentProcess.invitedInvestments[0].isShowToInvestor && mapDateFormat(investmentProcess.invitedInvestments[0].investmentDeadlineDate)}</h5>
              </div>
              <div className="investment_right">
                <Document
                  {...props}
                />
              </div>
            </div>
          }
        </div>
      </div>
      {
        investmentProcess && investmentProcess.invitedInvestments && investmentProcess.invitedInvestments.length > 0 &&
        userData &&
        <Documents
          invitedInvestment={investmentProcess.invitedInvestments[0]}
          phoneNumber={investmentProcess.mobile}
          title={investmentProcess.title}
          companyName={companyName}
          legalName={legalName}
          userData={userData}
          {...props}
        />
      }
    </>
  );
}

const mapStateToProps = state => {
  return {
    investmentProcess: state.investmentProcessData.investmentProcess ? state.investmentProcessData.investmentProcess : null,
    userData: state.investmentProcessData.userData ? state.investmentProcessData.userData : null,
    bankDetail: state.settings && state.settings.data && state.settings.data.bankDetail ? state.settings.data.bankDetail : null,
  };
};

export default connect(mapStateToProps, null)(Initiate);

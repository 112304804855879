
import React, { Component } from 'react';
import './AddInvestmentStartupModal.scss';
import axios from 'axios';
import swal from 'sweetalert';
import common from '../../../common';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import _ from 'lodash';
import RequiredSign from '../../../../src/components/RequiredSign';

class AddInvestmentStartupModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyName: '',
            description: '',


        }
    }



    componentWillMount() {
        this.props.onRef && this.props.onRef(this);
    }

    componentWillReceiveProps(nextProps) {
        nextProps.onRef && nextProps.onRef(this);

    }

    resetInviteData() {
        this.setState({
            companyName: '',
            description: '',
        });
    }










    updateCompany() {
        let temp = this;
        const fulladdress = this.state.address1.concat("\\$#", this.state.address2, "\\$#", this.state.city, "\\$#", this.state.state1, "\\$#", this.state.zipcode);
        this.setState({ loadinglada2: true });
        let mobile = this.state.phone.replace(/[- )(]/g, '');
        // if (data.stage === 'exited' || data.stage === 'exit in process') {
        //     if (this.state.selectedExitProcess && this.state.selectedExitProcess.value) {
        //         data.exitProcessType = this.state.selectedExitProcess.value;
        //     } else {
        //         data.exitProcessType = 'ipo';
        //     }
        //     if (data.exitProcessType === 'ipo') {
        //         data.lockupDate = this.state.lockupDate;
        //     } else {
        //         data.disbursementDate = this.state.disbursementDate;
        //     }
        //     data.acquiringCompanyName = this.state.acquiringCompanyName;
        // }
        axios.put(`${common.path.base_url}/updateCompany`, {
            companyName: this.state.companyName,
            companyNickName: this.state.description,
            companyId: this.state.editcompanyId,
        })
            .then(function (response) {
                swal({
                    // title: "Sent!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(function () {

                        temp.resetAddCompanyData();
                        document.getElementById('company-cancel').click();

                    });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                else {
                    if (error.response) {
                        swal({
                            title: "Error! #Network",
                            text: ( error.response && error.response.data && error.response.data.message ) ? error.response.data.message : error.response,
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                    else {
                        swal({
                            title: "Network Error!",
                            text: ( error.response && error.response.data && error.response.data.message ) ? error.response.data.message : error.message,
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                }
                temp.setState({ loadinglada2: false });

            });
    }

    render() {
        return (
            <div>
                {/* <!-- Invite Modal HTML --> */}
                <div id="addInvestmentStartupModal" className="modal fade">
                    <div className="modal-dialog " key={this.state.key}>
                        <div className="modal-content invite-contact">
                            <form>
                                <div className="modal-header">
                                    <h4 className="modal-title">Add Investment</h4>
                                </div>
                                <div className="modal-body">
                                    <form className="form-horizontal" id=' myform'>
                                        <div className="form-group">
                                            <div className="form-group">
                                                <label className="col-lg-4 col-sm-3  col-md-3 control-label">First Name (Legal) <RequiredSign /> :</label>
                                                <div className="col-lg-8 col-sm-8 col-md-8">
                                                    <input className="form-control" id="firstName" type="text"
                                                        onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                        placeholder='First Name (Legal)'
                                                        value={this.state.firstName} />
                                                    <p>{this.state.firstNameValidation}</p>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-lg-4 col-sm-3  col-md-3 control-label">Middle Name :</label>
                                                <div className="col-lg-8 col-sm-8 col-md-8">
                                                    <input className="form-control" id="middleName" type="text"
                                                        onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                        placeholder='Middle Name'
                                                        value={this.state.middleName} />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <LaddaButton
                                        id='lada-btn'
                                        loading={this.state.loadinglada}
                                        onClick={(event) => this.sendInvitation(event.preventDefault())}
                                        data-color="red"
                                        data-size={XS}
                                        className={!this.state.validInvitaionForm ? 'disable' : ''}
                                        data-style={EXPAND_RIGHT}
                                        data-spinner-size={30}
                                        data-spinner-color="#ddd"
                                        data-spinner-lines={12}
                                    >
                                        ADD
                                    </LaddaButton>
                                    <input type="button" id="cancel" className="btn btn-default" data-dismiss="modal" value="CANCEL" onClick={() => this.resetInviteData()} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
export default AddInvestmentStartupModal;




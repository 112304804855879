import React from 'react'
import Header from '../../components/Header';
import UserRecommendtationsDetail from '../containers/userRecommendationsDetail';

const App = () => (
  <>
    <Header
      username={window.localStorage.getItem('username')}
      role={window.localStorage.getItem('role')}
    />
    <UserRecommendtationsDetail/>
  </>
)

export default App
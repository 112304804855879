
const initialState = {
  selectedValue:{ value: 'all', label: 'All' },
  options: [
    { value: 'all', label: 'All' },
    { value: 'complete', label: 'Complete' },
    { value: 'closed', label: 'Closed' },
  ],
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SELECTED_VALUE':
      return { ...state, selectedValue: action.selectedValue };
    default:
      return state;
  }
}

export default reducer
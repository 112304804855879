import React, { Component, useState, useEffect } from "react";
import "./ProblemStatementDesc.scss";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Header from "../../../components/Header";
import TButton from "../../../components/TButton";
import HeaderImg from "../../../components/HeaderImage";
import LoadingSpinner1 from "../../../components/IIR Loader";
import ProfileImage from "../../../components/ProfileImage";
import FileUploadBlob from "../../../components/FileUploadBlob";
import FileUploadBlobUpdate from "../../../components/FileUploadBlobUpdate";
import { withRouter } from "react-router-dom";
import LaddaButton, { XS, EXPAND_RIGHT } from "react-ladda";
import axios from "axios";
import swal from "sweetalert";
import common from "../../../common";
import _ from "lodash";
import Modal from "react-bootstrap-modal";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { Markup } from "interweave";
// Imported Images
import images from "../../../images";
import Editor from "../Editor";

const SubSortableList = SortableContainer(
  ({ items, deleteProblemStatementDescData, editClick, editClickText }) => {
    return (
      <ul>
        {items.map((value, index) => (
          <SubSortableItem
            key={`item-${index}`}
            index={index}
            value={value}
            deleteProblemStatementDescData={(i) =>
              deleteProblemStatementDescData(i)
            }
            editClick={(value) => editClick(value)}
            editClickText={(value) => editClickText(value)}
          />
        ))}
      </ul>
    );
  }
);

const SubSortableItem = SortableElement(
  ({ value, deleteProblemStatementDescData, editClick, editClickText }) => (
    <li className={value.headerId == null ? `` : `sortableStyle`}>
      {value.type === "image" ? (
        <div className="problemStatementDesc-options">
          <i
            className={value.headerId == null ? `disp-none` : `fa fa-bars`}
            aria-hidden="true"
          ></i>
          <div
            className={
              value.headerId == null
                ? `problemStatementDesc-option`
                : `problemStatementDesc-option marg-l-1p`
            }
          >
            <div className="leader-img">
              <ProfileImage
                imgSrc={`${common.path.base_url}/getFileNew?fileId=${value.pictureId}`}
              />
              <div className="icons">
                <i
                  onClick={() => editClick(value)}
                  className="fa fa-pencil add-address"
                ></i>
                <img
                  className="delete"
                  onClick={() => {
                    deleteProblemStatementDescData(value._id);
                  }}
                  src={images.path.deleteIcon}
                ></img>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {value.type === "text" ? (
        <div className="problemStatementDesc-options">
          <i
            className={value.headerId == null ? `disp-none` : `fa fa-bars`}
            aria-hidden="true"
          ></i>
          <div
            className={
              value.headerId == null
                ? `problemStatementDesc-option`
                : `problemStatementDesc-option marg-l-1p`
            }
          >
            {/* <Markup content={value.text} /> */}
            <p className="ql-editor">
              <Markup content={value.text} />
            </p>
            <div className="icons">
              <i
                onClick={() => editClickText(value)}
                className="fa fa-pencil add-address"
              ></i>
              <img
                className="delete"
                onClick={() => {
                  deleteProblemStatementDescData(value._id);
                }}
                src={images.path.deleteIcon}
              ></img>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </li>
  )
);

const SortableItem = SortableElement(
  ({
    MainHandleProps,
    value,
    deleteProblemStatementDescData,
    editClick,
    editClickText,
    addTextHandler,
    addImageHandler,
    updateHeaderText,
    deleteHeader,
  }) => {
    let [ProblemStatementDescData, setProblemStatementDescData] = useState(
      value.data
    );

    useEffect(() => {
      setProblemStatementDescData(value.data);
    }, [value]);

    const onSortEnd = ({ oldIndex, newIndex }) => {
      setProblemStatementDescData(
        (ProblemStatementDescData = arrayMove(
          ProblemStatementDescData,
          oldIndex,
          newIndex
        ))
      );
      let order = ProblemStatementDescData.map((ele, i) => {
        return ele.suborder;
      });
      let id = ProblemStatementDescData.map((ele, i) => {
        return ele._id;
      });
      const token = localStorage.getItem("token");
      axios
        .put(
          `${common.path.base_url}/updateSuborderMarketIIR`,
          {
            id: id[0],
            subsequence: order,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response) => { })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message &&
            common.AUTH_EXPIRED === error.response.data.message
          ) {
            window.localStorage.clear();
            window.location.href = "/";
            return;
          } else {
          }
        });
    };
    return (
      <div style={{ display: "inline-block", width: "97%" }}>
        {value.title && (
          <div className="option-heading">
            <i
              className="fa fa-bars"
              aria-hidden="true"
              {...MainHandleProps}
              style={{ display: "inline-block", width: "3%" }}
            ></i>
            <h3
              style={{
                marginBottom: "10px",
                width: "40%",
                display: "inline-block",
              }}
            >
              {value.title}
              <i
                onClick={() => updateHeaderText(value)}
                className="fa fa-pencil fa-fw"
              ></i>
              <img
                onClick={() => deleteHeader(value)}
                className="delete"
                alt="remove"
                src={images.path.deleteIcon}
              ></img>
            </h3>

            <div style={{ display: "inline-block", width: "40%" }}>
              <TButton
                onClick={() => addImageHandler(value)}
                text={`ADD IMAGE`}
                id="update-btn"
                style={{ marginRight: "10px" }}
              />

              <div className="add-txt" style={{ marginLeft: "10px" }}>
                <TButton
                  onClick={() => addTextHandler(value)}
                  text={`ADD TEXT`}
                  id="update-btn"
                />
              </div>
            </div>
          </div>
        )}
        <SubSortableList
          pressDelay={200}
          items={ProblemStatementDescData}
          onSortEnd={onSortEnd}
          deleteProblemStatementDescData={(i) =>
            deleteProblemStatementDescData(i)
          }
          editClick={(value) => editClick(value)}
          editClickText={(value) => editClickText(value)}
        />
      </div>
    );
  }
);

const SortableList = SortableContainer(
  ({
    items,
    deleteProblemStatementDescData,
    editClick,
    editClickText,
    addTextHandler,
    addImageHandler,
    onDragEndHandler,
    updateHeaderText,
    deleteHeader,
  }) => {
    return (
      <DragDropContext onDragEnd={(result) => onDragEndHandler(result)}>
        <Droppable droppableId="checklists" type="HEADER">
          {(provided) => (
            <ul
              className="characters"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {items.map((value, index) => (
                <Draggable
                  key={`item-${index + 1}`}
                  draggableId={`item-${index + 1}`}
                  index={index + 1}
                >
                  {(provided) => (
                    <li ref={provided.innerRef} {...provided.draggableProps}>
                      <div className="options">
                        {value.title === "" ? (
                          <i
                            className="fa fa-bars"
                            aria-hidden="true"
                            {...provided.dragHandleProps}
                            style={{ display: "inline-block", width: "3%" }}
                          ></i>
                        ) : (
                          ""
                        )}
                        <SortableItem
                          MainHandleProps={provided.dragHandleProps}
                          key={`item-${index}`}
                          index={index}
                          value={value}
                          deleteProblemStatementDescData={(i) =>
                            deleteProblemStatementDescData(i)
                          }
                          editClick={(value) => editClick(value)}
                          editClickText={(value) => editClickText(value)}
                          addTextHandler={(value) => addTextHandler(value)}
                          addImageHandler={(value) => addImageHandler(value)}
                          updateHeaderText={(value) => updateHeaderText(value)}
                          deleteHeader={(data) => deleteHeader(data)}
                        />
                      </div>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

class ProblemStatementDesc extends Component {
  constructor(props) {
    super(props);
    this.companyId = "";
    this.investmentId = "";
    this.state = {
      sampleArray: [1, 2, 3],
      text: "",
      textValidation: "",
      heading: "",
      headingValidation: "",
      show: false,
      loading: false,
      loadinglada: false,
      loadinglada2: false,
      Role: "",
      profileFormValidated: false,
      problemStatementDescData: [],
      choosenFile: null,
      picture: "",
      pictureUpdate: "",
      leaderId: "",
      isEdit: false,
      show2: false,
      imagePop: false,
      textPop: false,
      selectedFieldId: "",
      imageUploading: false,
      isUploaded: false,
      headerId: null,
    };
  }

  componentWillMount() {
    const userRole = window.localStorage.getItem("role");
    this.setState({ Role: userRole });
    const url = new URL(window.location.href);
    this.companyId = url.searchParams.get("companyId");
    this.investmentId = url.searchParams.get("investmentId");
    // this.companyId=window.localStorage.getItem('companyId');
    this.getproblemStatementDescData();
  }

  componentDidUpdate() {
    document.addEventListener("keypress", function (event) {
      if (event.keyCode === 13) {
        // event.preventDefault()
      }
    });
  }

  getIIRList(init = true) {
    let temp = this;
    temp.setState({ loadinglada2: true });
    let token = window.localStorage.getItem("token");
    axios
      .get(`${common.path.base_url}/getInvestmentIIR/${this.investmentId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const sampleArray = response.data.data;
        const keys = Object.keys(sampleArray);
        const values = Object.values(sampleArray);
        let z = [];
        keys.forEach((key, i) => {
          // z.push({ title: key, state: values[i] })
          z.push(common.iirMapKey({ key, values, i }));
        });
        if (init) {
          z.forEach((ele, i) => {
            let title = ele.path.split(" ");
            let path = "";
            for (const word of title) {
              path += word;
            }
            path = path.substring(0, 1).toLowerCase() + path.substring(1);
            ele.path = path;
          });
          z.length = common.iirArrayLength;
          this.props.setIirList(z);
          // this.props.history.push(`/iir/solutionSummaryDescription?companyId=${this.companyId}`)
        }
        temp.setState({ loadinglada2: false });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response.data.code === 204) {
          window.localStorage.clear();
          window.location.href = "/";
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  getproblemStatementDescData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    const id = window.localStorage.getItem("id");
    this.setState({ id: id });
    axios
      .get(
        `${common.path.base_url}/getMarketInvestment/${this.investmentId}/ProblemStatementDesc`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ problemStatementDescData: response.data.data });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  addHeader() {
    if (this.state.isEdit && this.state.selectedFieldId) {
      const temp = this;
      temp.setState({ loadinglada: true });
      let tokenpass = window.localStorage.getItem("token");
      temp.setState({ loadinglada: true });
      axios
        .put(
          `${common.path.base_url}/updateIIRMarketInvestmentHeader/${this.state.selectedFieldId}`,
          {
            title: temp.state.heading ? temp.state.heading : "",
          },
          {
            headers: {
              Authorization: tokenpass,
            },
          }
        )
        .then((response) => {
          swal({
            title: "Updated!",
            text: response.data.message,
            icon: "success",
            button: "Ok!",
          }).then(() => {
            temp.getproblemStatementDescData();
            temp.resetFields();
            temp.setState({ show: false });
          });
          temp.setState({ loadinglada: false });
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message &&
            common.AUTH_EXPIRED === error.response.data.message
          ) {
            window.localStorage.clear();
            window.location.href = "/";
            return;
          } else if (error.response) {
            swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              button: "Ok!",
            });
            temp.setState({ loadinglada: false });
          } else {
            swal({
              title: "Network Error!",
              text:
                error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        });
    } else {
      const temp = this;
      temp.setState({ loadinglada: true });
      let tokenpass = window.localStorage.getItem("token");
      temp.setState({ loadinglada: true });
      axios
        .post(
          `${common.path.base_url}/addIIRMarketInvestmentHeader`,
          {
            companyId: this.companyId,
            investmentId: this.investmentId,
            marketType: "ProblemStatementDesc",
            title: temp.state.heading ? temp.state.heading : "",
            type: "header",
          },
          {
            headers: {
              Authorization: tokenpass,
            },
          }
        )
        .then((response) => {
          swal({
            title: "Updated!",
            text: response.data.message,
            icon: "success",
            button: "Ok!",
          }).then(() => {
            temp.getproblemStatementDescData();
            temp.resetFields();
            temp.setState({ show: false });
            window.location.reload();
          });
          temp.setState({ loadinglada: false });
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message &&
            common.AUTH_EXPIRED === error.response.data.message
          ) {
            window.localStorage.clear();
            window.location.href = "/";
            return;
          } else if (error.response) {
            swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              button: "Ok!",
            });
            temp.setState({ loadinglada: false });
          } else {
            swal({
              title: "Network Error!",
              text:
                error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        });
    }
  }
  apiMarket() {
    const temp = this;
    temp.setState({ loadinglada: true });
    let tokenpass = window.localStorage.getItem("token");
    if (temp.state.isEdit) {
      if (temp.state.imagePop) {
        temp.setState({ loadinglada: true });
        axios
          .put(
            `${common.path.base_url}/updateIIRMarketInvestment`,
            {
              companyId: this.companyId,
              investmentId: this.investmentId,
              type: "image",
              id: temp.state.selectedFieldId,
              marketType: "ProblemStatementDesc",
              pictureId: temp.state.picture,
              title: temp.state.heading ? temp.state.heading : "",
            },
            {
              headers: {
                Authorization: tokenpass,
              },
            }
          )
          .then((response) => {
            swal({
              title: "Updated!",
              text: response.data.message,
              icon: "success",
              button: "Ok!",
            }).then(() => {
              temp.getproblemStatementDescData();
              temp.resetFields();
              temp.setState({ show: false });
            });
            temp.setState({ loadinglada: false });
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message &&
              common.AUTH_EXPIRED === error.response.data.message
            ) {
              window.localStorage.clear();
              window.location.href = "/";
              return;
            } else if (error.response) {
              swal({
                title: "Error!",
                text: error.response.data.message,
                icon: "error",
                button: "Ok!",
              });
              temp.setState({ loadinglada: false });
            } else {
              swal({
                title: "Network Error!",
                text:
                  error.response &&
                    error.response.data &&
                    error.response.data.message
                    ? error.response.data.message
                    : error.message,
                icon: "error",
                button: "Ok!",
              });
            }
          });
      }
      if (temp.state.textPop) {
        temp.setState({ loadinglada: true });
        axios
          .put(
            `${common.path.base_url}/updateIIRMarketInvestment`,
            {
              companyId: this.companyId,
              investmentId: this.investmentId,
              type: "text",
              id: temp.state.selectedFieldId,
              marketType: "ProblemStatementDesc",
              text: temp.state.text,
              title: temp.state.heading ? temp.state.heading : "",
            },
            {
              headers: {
                Authorization: tokenpass,
              },
            }
          )
          .then((response) => {
            swal({
              title: "Updated!",
              text: response.data.message,
              icon: "success",
              button: "Ok!",
            }).then(() => {
              temp.getproblemStatementDescData();
              temp.resetFields();
              temp.setState({ show: false });
            });
            temp.setState({ loadinglada: false });
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message &&
              common.AUTH_EXPIRED === error.response.data.message
            ) {
              window.localStorage.clear();
              window.location.href = "/";
              return;
            } else if (error.response) {
              swal({
                title: "Error!",
                text: error.response.data.message,
                icon: "error",
                button: "Ok!",
              });
              temp.setState({ loadinglada: false });
            } else {
              swal({
                title: "Network Error!",
                text:
                  error.response &&
                    error.response.data &&
                    error.response.data.message
                    ? error.response.data.message
                    : error.message,
                icon: "error",
                button: "Ok!",
              });
            }
          });
      }
    } else if (temp.state.imagePop) {
      axios
        .post(
          `${common.path.base_url}/addIIRMarketInvestment`,
          {
            companyId: this.companyId,
            investmentId: this.investmentId,
            type: "image",
            title: temp.state.heading,
            marketType: "ProblemStatementDesc",
            pictureId: temp.state.picture,
            headerId: this.state.headerId,
          },
          {
            headers: {
              Authorization: tokenpass,
            },
          }
        )
        .then(function (response) {
          temp.setState({ loadinglada: false });
          swal({
            title: "Added",
            text: response.data.message,
            icon: "success",
            button: "Ok!",
          }).then(function () {
            temp.getproblemStatementDescData();
            temp.resetFields();
            window.location.reload();
          });
        })
        .catch(function (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message &&
            common.AUTH_EXPIRED === error.response.data.message
          ) {
            window.localStorage.clear();
            window.location.href = "/";
            return;
          } else if (error.response) {
            swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              button: "Ok!",
            });
          } else {
            swal({
              title: "Network Error!",
              text:
                error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
          temp.setState({ loadinglada: false });
        });
    } else if (temp.state.textPop) {
      axios
        .post(
          `${common.path.base_url}/addIIRMarketInvestment`,
          {
            companyId: this.companyId,
            investmentId: this.investmentId,
            type: "text",
            title: temp.state.heading,
            marketType: "ProblemStatementDesc",
            text: temp.state.text,
            headerId: this.state.headerId,
          },
          {
            headers: {
              Authorization: tokenpass,
            },
          }
        )
        .then(function (response) {
          temp.setState({ loadinglada: false });
          swal({
            title: "Added",
            text: response.data.message,
            icon: "success",
            button: "Ok!",
          }).then(function () {
            temp.getproblemStatementDescData();
            temp.resetFields();
            window.location.reload();
          });
        })
        .catch(function (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message &&
            common.AUTH_EXPIRED === error.response.data.message
          ) {
            window.localStorage.clear();
            window.location.href = "/";
            return;
          } else if (error.response) {
            swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              button: "Ok!",
            });
          } else {
            swal({
              title: "Network Error!",
              text:
                error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
          temp.setState({ loadinglada: false });
        });
    }
  }

  uploadLogo(file) {
    if (file.size) {
      const temp = this;
      temp.setState({ imageUploading: true });
      let tokenpass = window.localStorage.getItem("token");
      let data = new FormData();
      data.append("file", file);
      const isPrivate = true;
      data.append("isPrivate", isPrivate);
      axios
        .post(`${common.path.base_url}/uploadFile`, data, {
          headers: {
            Authorization: tokenpass,
          },
        })
        .then((response) => {
          temp.setState({ picture: response.data.data.id, isUploaded: true });
          temp.setState({ imageUploading: false });
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message &&
            common.AUTH_EXPIRED === error.response.data.message
          ) {
            window.localStorage.clear();
            window.location.href = "/";
            return;
          } else {
          }
        });
    }
  }

  deleteProblemStatementDescData(index) {
    // if (this.state.problemStatementDescData.length === 1) {
    //     swal({
    //         title: "Warning",
    //         text: "Can not delete the last data",
    //         icon: "warning",
    //         buttons: true,
    //         dangerMode: true,
    //     })
    // }
    // else {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willdelete) => {
      const token = localStorage.getItem("token");
      if (willdelete) {
        this.setState({ loading: true });
        axios
          .delete(`${common.path.base_url}/deleteMarketIIR`, {
            headers: { Authorization: token },
            data: { id: index },
          })
          .then((response) => {
            this.setState({ loading: false });
            swal({
              title: "Success!",
              text: response.data.message,
              icon: "success",
              button: "Ok!",
            }).then(() => {
              this.getproblemStatementDescData();
            });
          })
          .catch((error) => {
            this.setState({ loading: false });
            if (
              error.response &&
              error.response.data &&
              error.response.data.message &&
              common.AUTH_EXPIRED === error.response.data.message
            ) {
              window.localStorage.clear();
              window.location.href = "/";
              return;
            } else {
              swal({
                title: "Error!",
                text:
                  error.response &&
                    error.response.data &&
                    error.response.data.message
                    ? error.response.data.message
                    : "Something Went Wrong",
                icon: "error",
                button: "Ok!",
              });
            }
          });
      }
    });
    // }
  }

  deleteHeader(element) {
    if (
      element &&
      element.data &&
      element.data.length > 0 &&
      element.data[0].headerId
    ) {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willdelete) => {
        const token = localStorage.getItem("token");
        if (willdelete) {
          this.setState({ loading: true });
          axios
            .delete(`${common.path.base_url}/deleteHeader`, {
              headers: { Authorization: token },
              data: { id: element.data[0].headerId },
            })
            .then((response) => {
              this.setState({ loading: false });
              swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
                button: "Ok!",
              }).then(() => {
                this.getproblemStatementDescData();
              });
            })
            .catch((error) => {
              this.setState({ loading: false });
              if (
                error.response &&
                error.response.data &&
                error.response.data.message &&
                common.AUTH_EXPIRED === error.response.data.message
              ) {
                window.localStorage.clear();
                window.location.href = "/";
                return;
              } else {
                swal({
                  title: "Error!",
                  text:
                    error.response &&
                      error.response.data &&
                      error.response.data.message
                      ? error.response.data.message
                      : "Something Went Wrong",
                  icon: "error",
                  button: "Ok!",
                });
              }
            });
        }
      });
    }
  }

  handleChange(id, newValue) {
    if (id === "heading") this.setState({ heading: newValue });
    else if (id === "text") this.setState({ text: newValue });
  }

  formValidaton(id, val) {
    if (id === "heading") {
      if (val === "") {
        // this.setState({
        //     headingValidation: "Heading should not be empty*",
        // })
      } else {
        this.setState({
          headingValidation: "",
        });
      }
    } else if (id === "text") {
      if (val === "") {
        this.setState({
          textValidation: "Text should not be empty*",
        });
      } else {
        this.setState({
          textValidation: "",
        });
      }
    }
  }

  validateProfileForm() {
    if (!_.isEmpty(this.state.name && this.state.title && this.state.Bio)) {
      this.setState({ profileFormValidated: true });
    } else {
      this.setState({ profileFormValidated: false });
    }
  }

  resetFields() {
    this.setState({
      text: "",
      heading: "",
      show: false,
      imagePop: false,
      textPop: false,
      isEdit: false,
      choosenFile: null,
      picture: "",
      textValidation: "",
      headingValidation: "",
      selectedFieldId: "",
      headerId: null,
    });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ problemStatementDescData }) => ({
      problemStatementDescData: arrayMove(
        problemStatementDescData,
        oldIndex,
        newIndex
      ),
    }));
    let order = this.state.problemStatementDescData.map((ele, i) => {
      return ele.order;
    });
    let id = this.state.problemStatementDescData.map((ele, i) => {
      return ele.data;
    });
    const token = localStorage.getItem("token");
    axios
      .put(
        `${common.path.base_url}/updateOrderMarketIIR`,
        {
          id: id[0][0]._id,
          sequence: order,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => { })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else {
        }
      });
  };

  onEditClick(element) {
    const selectedPrbStatement = this.state.problemStatementDescData.find(
      (singleStatement) => singleStatement.order === element.order
    );
    this.setState({
      show: true,
      isEdit: true,
      imagePop: true,
      modalTitle: "Update Image",
      modalButtonTitle: "UPDATE",
      picture: element.pictureId,
      selectedFieldId: element._id,
      heading: selectedPrbStatement ? selectedPrbStatement.title : "",
    });
  }

  onEditClickText(element) {
    const selectedPrbStatement = this.state.problemStatementDescData.find(
      (singleStatement) => singleStatement.order === element.order
    );
    this.setState({
      show: true,
      isEdit: true,
      textPop: true,
      modalTitle: "Update Text",
      modalButtonTitle: "UPDATE",
      text: element.text,
      selectedFieldId: element._id,
      heading: selectedPrbStatement ? selectedPrbStatement.title : "",
    });
  }
  onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  addTextHandler(data) {
    let headerId = null;
    if (data && data.data && data.data.length > 0 && data.data[0].headerId) {
      headerId = data.data[0].headerId;
    }
    this.setState({
      show: true,
      textPop: true,
      modalTitle: "Add Text",
      modalButtonTitle: "ADD",
      headerId,
    });
  }
  addImageHandler(data) {
    let headerId = null;
    if (data && data.data && data.data.length > 0 && data.data[0].headerId) {
      headerId = data.data[0].headerId;
    }
    this.setState({
      show: true,
      imagePop: true,
      modalTitle: "Add Image",
      modalButtonTitle: "ADD",
      headerId,
    });
  }

  onDragEnd(result) {
    const Reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex - 1, 1);
      result.splice(endIndex - 1, 0, removed);

      return result;
    };
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    if (result.type === "HEADER") {
      const problemStatementDescData = Reorder(
        this.state.problemStatementDescData,
        result.source.index,
        result.destination.index
      );
      const orderArray = [];
      if (problemStatementDescData && problemStatementDescData.length > 0) {
        problemStatementDescData.forEach((marketPerf, order) => {
          if (marketPerf && marketPerf.data && marketPerf.data.length > 0) {
            marketPerf.data.forEach((data) => {
              orderArray.push({ _id: data._id, order: order + 1 });
            });
          }
        });
        this.updateOrder(orderArray);
        this.setState({
          problemStatementDescData,
        });
      }
    }

    // this.setState({marketPerfData: []})
  }
  updateOrder = (orderArray) => {
    if (orderArray && orderArray.length > 0) {
      const temp = this;
      const payload = { orderArray };
      let tokenpass = window.localStorage.getItem("token");
      axios
        .post(`${common.path.base_url}/changeOrder`, payload, {
          headers: {
            Authorization: tokenpass,
          },
        })
        .then((response) => { })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message &&
            common.AUTH_EXPIRED === error.response.data.message
          ) {
            window.localStorage.clear();
            window.location.href = "/";
            return;
          } else if (error.response) {
            swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              button: "Ok!",
            });
          } else {
            swal({
              title: "Network Error!",
              text:
                error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        });
    }
  };
  onUpdateHeaderText(element) {
    if (
      element &&
      element.data &&
      element.data.length > 0 &&
      element.data[0].headerId
    ) {
      this.setState({
        show: true,
        isEdit: true,
        headerPop: true,
        heading: element.title,
        selectedFieldId: element.data[0].headerId,
        modalTitle: "UPDATE HEADER",
        modalButtonTitle: "UPDATE",
      });
    }
  }

  //  quill editor changes
  editorHandleChange(editorHtml) {
    this.setState({ text: editorHtml });
  }

  render() {
    return (
      <div className="problemStatementDesc">
        {this.state.loading ? <LoadingSpinner1 /> : ""}
        <div className="problemStatementDesc-main">
          <div className="heading">
            <h3>Problem Statement</h3>
          </div>
          <div className="problemStatementDesc">
            <div className="problemStatementDesc-details">
              <form className="form-horizontal">
                <div className="form-group">
                  <div className="col-md-8 addNewButton ">
                    <TButton
                      onClick={() => {
                        this.setState({
                          show: true,
                          imagePop: true,
                          modalTitle: "Add Image",
                          modalButtonTitle: "ADD",
                        });
                      }}
                      text={`ADD IMAGE`}
                      id="update-btn"
                    />

                    <div className="add-txt">
                      <TButton
                        onClick={() => {
                          this.setState({
                            show: true,
                            textPop: true,
                            modalTitle: "Add Text",
                            modalButtonTitle: "ADD",
                          });
                        }}
                        text={`ADD TEXT`}
                        id="update-btn"
                      />
                    </div>
                    {/* <div className="save-btn">
                                            <LaddaButton
                                                id="update-btn"
                                                loading={this.state.loadinglada2}
                                                onClick={() => {
                                                    this.getIIRList();
                                                }}
                                                data-color="#eee"
                                                data-size={XS}
                                                data-style={EXPAND_RIGHT}
                                                data-spinner-size={30}
                                                data-spinner-color="#ddd"
                                                data-spinner-lines={12}
                                                className={_.isEmpty(this.state.problemStatementDescData) ? 'disable' : ''}
                                            >
                                                SAVE
                                            </LaddaButton>
                                        </div> */}
                    <div className="add-txt">
                      <TButton
                        onClick={() => {
                          this.setState({
                            show: true,
                            headerPop: true,
                            modalTitle: "Add HEADER",
                            modalButtonTitle: "ADD",
                          });
                        }}
                        text={`ADD HEADER`}
                        id="update-btn"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="footBorder"></div>
          <SortableList
            pressDelay={200}
            items={this.state.problemStatementDescData}
            onSortEnd={this.onSortEnd}
            deleteProblemStatementDescData={(i) =>
              this.deleteProblemStatementDescData(i)
            }
            editClick={(value) => this.onEditClick(value)}
            editClickText={(value) => this.onEditClickText(value)}
            addTextHandler={(id) => this.addTextHandler(id)}
            addImageHandler={(id) => this.addImageHandler(id)}
            onDragEndHandler={(result) => this.onDragEnd(result)}
            updateHeaderText={(value) => this.onUpdateHeaderText(value)}
            deleteHeader={(data) => this.deleteHeader(data)}
            {...this.props}
          />
        </div>

        <Modal
          id="addimage"
          aria-labelledby="ModalHeader"
          show={this.state.show}
        >
          <Modal.Header>
            <Modal.Title id="ModalHeader">{this.state.modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.imagePop ? (
              <div className="problemStatementDesc">
                <div className="problemStatementDesc">
                  <form className="form-horizontal">
                    {/* <div className="form-group">
                                        <label className="col-md-3 control-label">Heading:</label>
                                        <div className="col-md-8">
                                            <input className="form-control" id="heading" type='text' placeholder='Enter Heading'
                                                onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                onKeyPress={this.onKeyPress}
                                                value={this.state.heading} />
                                        </div>
                                    </div> */}
                    <div className="form-group picture">
                      <label className="col-md-3 control-label company-label">
                        Image:
                      </label>
                      <div className="col-md-8">
                        <div className="upload-problemStatementDesc-pic img-box">
                          {this.state.isEdit ? (
                            <div className="problemStatementDesc-pic-box">
                              <img
                                id="preview"
                                className="profile-pic"
                                src={
                                  this.state.imageUploading
                                    ? `${images.path.loader}`
                                    : `${common.path.base_url}/getFileNew?fileId=${this.state.picture}`
                                }
                                alt=""
                              />
                              <div>
                                <div className="p-image1">
                                  <i
                                    onClick={() =>
                                      document
                                        .querySelector(`#chooseFileUpdate`)
                                        .click()
                                    }
                                    className="fa fa-pencil edit-button"
                                  ></i>
                                  <FileUploadBlob
                                    id={`chooseFileUpdate`}
                                    getFile={(file) => {
                                      this.setState({ choosenFile: file });
                                      this.uploadLogo(this.state.choosenFile);
                                    }}
                                    acceptType={`image/*`}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="problemStatementDesc-pic-box">
                              <img
                                id="preview"
                                className={`profile-pic ${this.state.isUploaded ? "" : "hide-icon"
                                  }`}
                                src={
                                  this.state.imageUploading
                                    ? `${images.path.loader}`
                                    : `${common.path.base_url}/getFileNew?fileId=${this.state.picture}`
                                }
                                alt=""
                              />
                              {this.state.choosenFile !== null ? (
                                ""
                              ) : (
                                <div>
                                  <div className="p-image">
                                    <i
                                      onClick={() =>
                                        document
                                          .querySelector(`#chooseFile${1}`)
                                          .click()
                                      }
                                      className="fa fa-plus-circle upload-button"
                                    ></i>
                                    <FileUploadBlob
                                      id={`chooseFile${1}`}
                                      getFile={(file) => {
                                        this.setState({ choosenFile: file });
                                        this.uploadLogo(this.state.choosenFile);
                                      }}
                                      acceptType={`image/*`}
                                    />
                                  </div>
                                  <div className="logo-name">Image</div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            ) : this.state.textPop ? (
              <Editor
                placeholder={"Enter Text..."}
                {...this.props}
                editorHtml={this.state.text}
                handleChange={(editorHtml) =>
                  this.editorHandleChange(editorHtml)
                }
              />
            ) : (
              // <div className="problemStatementDesc">
              //     <div className="problemStatementDesc">
              //         <form className="form-horizontal">
              //             <div className="form-group">
              //                 <label className="col-md-3 control-label">Heading:</label>
              //                 <div className="col-md-8">
              //                     <input className="form-control" id="heading" type='text' placeholder='Enter Heading'
              //                         onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
              //                         onChange={(event) => this.handleChange(event.target.id, event.target.value)}
              //                         onKeyPress={this.onKeyPress}
              //                 </div>
              //             </div>
              //             <div className="form-group">
              //                 <label className="col-md-3 control-label">Text:</label>
              //                 <div className="col-md-8">
              //                     <input className="form-control" id="text" type='text' placeholder='Enter Text'
              //                         onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
              //                         onChange={(event) => this.handleChange(event.target.id, event.target.value)}
              //                         value={this.state.text} />
              //                     <textarea className="form-control" name='preferredStockTerms' id="text" type="message" placeholder='Enter Text' rows="4" cols="50"
              //                         onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
              //                         onChange={(event) => this.handleChange(event.target.id, event.target.value)}
              //                         value={this.state.text}></textarea>
              //                     <p >{this.state.textValidation}</p>
              //                 </div>
              //             </div>
              //         </form>
              //     </div>
              // </div>
              <div className="marketPerf">
                <div className="marketPerf">
                  <form className="form-horizontal">
                    <div className="form-group">
                      <label className="col-md-3 control-label">Heading:</label>
                      <div className="col-md-8">
                        <input
                          className="form-control"
                          id="heading"
                          type="text"
                          placeholder="Enter Heading"
                          onKeyUp={(event) =>
                            this.formValidaton(
                              event.target.id,
                              event.target.value
                            )
                          }
                          onChange={(event) =>
                            this.handleChange(
                              event.target.id,
                              event.target.value
                            )
                          }
                          onKeyPress={this.onKeyPress}
                          value={this.state.heading}
                        />
                        <p>{this.state.headingValidation}</p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {this.state.imagePop ? (
              <LaddaButton
                id="lada-btn"
                loading={this.state.loadinglada}
                data-color="#eee"
                data-size={XS}
                onClick={() => this.apiMarket()}
                className={
                  this.state.isEdit
                    ? this.state.imageUploading
                      ? "disable"
                      : ""
                    : this.state.choosenFile === null ||
                      this.state.imageUploading
                      ? "disable"
                      : ""
                }
                data-style={EXPAND_RIGHT}
                data-spinner-size={30}
                data-spinner-color="#ddd"
                data-spinner-lines={12}
              >
                {this.state.modalButtonTitle}
              </LaddaButton>
            ) : this.state.textPop ? (
              <LaddaButton
                id="lada-btn"
                loading={this.state.loadinglada}
                data-color="#eee"
                data-size={XS}
                onClick={() => this.apiMarket()}
                className={
                  this.state.isEdit
                    ? !_.isEmpty(this.state.text)
                      ? ""
                      : "disable"
                    : !_.isEmpty(this.state.text)
                      ? ""
                      : "disable"
                }
                data-style={EXPAND_RIGHT}
                data-spinner-size={30}
                data-spinner-color="#ddd"
                data-spinner-lines={12}
              >
                {this.state.modalButtonTitle}
              </LaddaButton>
            ) : (
              <LaddaButton
                id="lada-btn"
                loading={this.state.loadinglada}
                data-color="#eee"
                data-size={XS}
                onClick={() => this.addHeader()}
                className={
                  this.state.isEdit
                    ? !_.isEmpty(this.state.heading)
                      ? ""
                      : "disable"
                    : !_.isEmpty(this.state.heading)
                      ? ""
                      : "disable"
                }
                data-style={EXPAND_RIGHT}
                data-spinner-size={30}
                data-spinner-color="#ddd"
                data-spinner-lines={12}
              >
                {this.state.modalButtonTitle}
              </LaddaButton>
            )}
            <input
              type="button"
              id="company-cancel"
              className="btn btn-default"
              data-dismiss="modal"
              value="CANCEL"
              onClick={() => this.resetFields()}
            />
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
// export default Profile;
export default withRouter(ProblemStatementDesc);


import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import swal from 'sweetalert';
import _ from 'lodash';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import './Container.scss';
import LoadingSpinner from '../../components/Loader';
import searchIcon from '../../images/search-icon.svg';
import { isLoading, isNotLoading } from '../../shared/actions/loadingSpinner';
import PipelineStartupsService from '../services/PipelineStartups.service';
import { setUserRecommendations, setCompanyData } from '../actions/userRecommendations';
import common from '../../common';


class MainContainer extends Component {
    constructor() {
        super()
        this.adminAccess = false;
        this.id = null;
    }
    componentDidMount() {
        window.addEventListener('popstate', (event) => {
            window.location.href = 'pipeline-startups?tab=recommendation'
        });
        this.props.isNotLoading()
        const url = new URL(window.location.href);
        this.id = url.searchParams.get('id');
        if (!this.id) {
            this.props.history.push('/pipeline-startups')
        } else {
            this.loadUserRecommendations()
        }
    }
    loadUserRecommendations = () => {
        this.props.isLoading();
        this.recommendationDetail()
            .then(() => {
                return this.getUserRecommendations();
            })
            .then(() => {
                this.props.isNotLoading()
            })
            .catch((error) => {
                this.props.isNotLoading()
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error! #33002",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                if (error.response && error.response.data && error.response.data.code && error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
            })

    }
    getUserRecommendations = () => {
        return new Promise((resolve, reject) => {
            PipelineStartupsService.recommendation(this.id)
                .then(response => {
                    if (response && response.data && response.data.data) {
                        this.props.setUserRecommendations(response.data.data);
                    }
                    resolve()
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    recommendationDetail = () => {
        return new Promise((resolve, reject) => {
            PipelineStartupsService.recommendationDetail(this.id)
                .then(response => {
                    if (response && response.data && response.data.data) {
                        this.props.setCompanyData(response.data.data)
                    }
                    resolve()
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    redirect(link) {
        let url;
        if (link) {
            if (!link.includes('http')) {
                url = `http://${link}`;
            } else {
                url = link;
            }
            window.open(url, '_blank');
            return;
        }
    }
    searchUserRecommendation = _.debounce((search) => {
        PipelineStartupsService.recommendation(this.id, search)
            .then(response => {
                if (response && response.data && response.data.data) {
                    this.props.setUserRecommendations(response.data.data);
                }
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else { 
                    swal({
                        title: "Error! #33001",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }, 500);
    render() {
        return (
            <>
                {
                    this.props.loadingSpinner ? <LoadingSpinner /> :
                        <>
                            <div className="pipeline-startup-section recommendations-list investor-sentiments-list">
                                <div className="container-fluid">
                                    <div className="row align-items-center">
                                        <div className="col-md-12">
                                            <div className="title">
                                                Investor Recommendations for
                                </div>
                                            <div className="main-title">
                                                {this.props.recommendation && this.props.recommendation.companyName}
                                                <p><a style={{ cursor: 'pointer' }} target='_blank' onClick={() => this.redirect(this.props.recommendation && this.props.recommendation.website)}>{this.props.recommendation && this.props.recommendation.website}</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="segment-statrup">
                                        <div className="full-search-box">
                                            <form className="form-group">
                                                <input className="form-control mr-sm-2" onChange={(event) => this.searchUserRecommendation(event.target.value)} type="search" placeholder="Search" aria-label="Search" />
                                                <button className="btn btn-outline-success my-2 my-sm-0" type="submit"><img src={searchIcon} alt='' /></button>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="recommendations-box-list">
                                        <div className="row">
                                            {
                                                this.props.userRecommendations.length > 0 &&
                                                this.props.userRecommendations.map(card => {
                                                    return (
                                                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                            <div className="box-list-item">
                                                                <div className="profile-icon" style={{ cursor: 'pointer' }} onClick={() => this.props.history.push(`/investor-dashboard?investorId=${card.userId}`)}>
                                                                    <img src={`${common.path.base_url}/getImage?id=${card.userId}`} alt='pic' /> {card.name}
                                                                </div>
                                                                <div className="partner">
                                                                    <div className="partner-option">
                                                                        <p>Current Partner</p>
                                                                        <strong>{card.isCurrentPartner ? 'Yes' : 'No'}</strong>
                                                                    </div>
                                                                    <div className="partner-option">
                                                                        <p>Can Make an Intro?</p>
                                                                        <strong>{card.isMakeIntro ? 'Yes' : 'No'}</strong>
                                                                    </div>
                                                                </div>
                                                                <div className="hr"></div>
                                                                <div className="discription-section">
                                                                    <h2>Why they like?</h2>
                                                                    <p>{card.likeThemComments}</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    );
                                                })

                                            }

                                            {/* <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                    <div className="box-list-item">
                                        <div className="profile-icon">
                                            <img src='' /> Micheal Jordan
                                        </div>
                                        <div className="partner">
                                            <div className="partner-option">
                                                <p>Current Partner</p>
                                                <strong>Yes</strong>
                                            </div>
                                            <div className="partner-option">
                                                <p>Can Make an Intro?</p>
                                                <strong>No</strong>
                                            </div>
                                        </div>
                                        <div className="hr"></div>
                                        <div className="discription-section">
                                            <h2>Why they like?</h2>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliq ua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                    <div className="box-list-item">
                                        <div className="profile-icon">
                                            <img src='' /> Micheal Jordan
                                        </div>
                                        <div className="partner">
                                            <div className="partner-option">
                                                <p>Current Partner</p>
                                                <strong>Yes</strong>
                                            </div>
                                            <div className="partner-option">
                                                <p>Can Make an Intro?</p>
                                                <strong>No</strong>
                                            </div>
                                        </div>
                                        <div className="hr"></div>
                                        <div className="discription-section">
                                            <h2>Why they like?</h2>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliq ua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                    <div className="box-list-item">
                                        <div className="profile-icon">
                                            <img src='' /> Micheal Jordan
                                        </div>
                                        <div className="partner">
                                            <div className="partner-option">
                                                <p>Current Partner</p>
                                                <strong>Yes</strong>
                                            </div>
                                            <div className="partner-option">
                                                <p>Can Make an Intro?</p>
                                                <strong>No</strong>
                                            </div>
                                        </div>
                                        <div className="hr"></div>
                                        <div className="discription-section">
                                            <h2>Why they like?</h2>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliq ua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                    <div className="box-list-item">
                                        <div className="profile-icon">
                                            <img src='' /> Micheal Jordan
                                        </div>
                                        <div className="partner">
                                            <div className="partner-option">
                                                <p>Current Partner</p>
                                                <strong>Yes</strong>
                                            </div>
                                            <div className="partner-option">
                                                <p>Can Make an Intro?</p>
                                                <strong>No</strong>
                                            </div>
                                        </div>
                                        <div className="hr"></div>
                                        <div className="discription-section">
                                            <h2>Why they like?</h2>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliq ua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                                        </div>
                                    </div>
                                </div> */}


                                        </div>
                                    </div>
                                </div></div>
                        </>
                }

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        loadingSpinner: state.loadingSpinner,
        userRecommendations: state.userRecommendations.data && state.userRecommendations.data.length > 0 ? state.userRecommendations.data : [],
        recommendation: state.userRecommendations.company && state.userRecommendations.company ? state.userRecommendations.company : null,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        isLoading: bindActionCreators(isLoading, dispatch),
        isNotLoading: bindActionCreators(isNotLoading, dispatch),
        setUserRecommendations: bindActionCreators(setUserRecommendations, dispatch),
        setCompanyData: bindActionCreators(setCompanyData, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainContainer));
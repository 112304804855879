import React, { useState, useRef } from 'react'
import { connect } from 'react-redux';
import ReactTags from 'react-tag-autocomplete'
import './InvestorSentimentsDetails.scss';
import common from '../../../common';
import TButton from '../../../components/TButton';
import ReactApexChart from 'react-apexcharts'
import ContactDetailsModal from '../../../components/Contact List/ContactDetailsModal';


const CompanyDetail = props => (
    <div className="row align-items-center">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="semi-title">
                Investor Sentiments For
                                    </div>
            <div className="title">
                {props.company && props.company.companyName}
            </div>
        </div>
    </div>
);

const PieGraph = props => {
    const { noThanks, learnMore, participate, firstLine, totalInvestors } = props;
    const getNotRespondedCount = () => {
        return totalInvestors - (noThanks.data.length + learnMore.data.length + participate.data.length + firstLine.data.length);
    }
    const series = [firstLine.data.length, participate.data.length, learnMore.data.length, noThanks.data.length, getNotRespondedCount()];
    // const series = [30, 50, 50, 60, 10];
    const options = {
        chart: {
            width: 380,
            type: 'pie',
        },
        labels: ['Want to be 1st in line', 'Want to Participate', 'Want to Learn More', 'No Thanks', 'No Response'],

        legend: {
            show: false
        },
        colors: ['#72d8c8', '#f46a67', '#f6d53e', '#53aed5', '#ff956d'],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },

            }
        }]
    };
    return (
        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12">
            <div className="total-investor-chart">
                <div className="chart-img">
                    {/* <img src={chartIcon} /> */}
                    <ReactApexChart options={options} series={series} type="pie" width={380} />


                    {/* <PieChart
                                            slices={[
                                                {
                                                color: '#72d8c8',
                                                value: 15,
                                                },
                                                {
                                                    color: '#f46a67',
                                                    value: 25,
                                                
                                                },
                                                {
                                                    color: '#f6d53e',
                                                    value: 25,
                                                    
                                                },
                                                {
                                                    color: '#53aed5',
                                                    value: 30,
                                                    
                                                },
                                                {
                                                    color: '#ff956d',
                                                    value: 5,
                                                    
                                                },

                                            ]}></PieChart> */}

                </div>
                <div className="investor-title">

                    {totalInvestors} Total Investors
                                    </div>
                <hr />
                <div className="investore-list">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="investore-list-item">
                                <h3 className="green">{firstLine && firstLine.data.length}</h3>
                                <p>Want to be 1st in line</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="investore-list-item">
                                <h3 className="red">{participate && participate.data.length}</h3>
                                <p>Want to Participate</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="investore-list-item">
                                <h3 className="yellow">{learnMore && learnMore.data.length}</h3>
                                <p>Want to Learn More</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="investore-list-item">
                                <h3 className="blue">{noThanks && noThanks.data.length}</h3>
                                <p>No Thanks</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="investore-list-item">
                                <h3 className="orange">{getNotRespondedCount()}</h3>
                                <p>No Response</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};
const CardInterest = props => {

    // const onRef = useRef(null);
    // const showDataInPopup = (data, event) => {
    //     //console.log(data, "-----", event.target);
    //     openContactDetailModal(data);
    // }

    // const openContactDetailModal = (data) => {
    //     onRef.current.setContactDetailsData(data._id, null);
    //     document.getElementById('contactDetails-btn').click();
    // }

    //onClick={() => props.history.push(`/investor-dashboard?investorId=${props.user._id}`)}
    return (
        <>
        <div className="tab-listing" onClick={(event) => { event.stopPropagation(); props.showDataInPopup(props.user); }} >
            <div className="profile-img">
                <img src={`${common.path.base_url}/getImage?id=${props.user._id}`} alt='pic' />
            </div>
            <div className="profile-info">
                <h3>{props.user && props.user.name}</h3>
                {/* <label>{props.user.title ? props.user.title : '-'}&nbsp;<span>at&nbsp;</span>&nbsp;{props.user.companyName ? props.user.companyName : '-'}</label> */}
                <label>{props.user.title && props.user.title}</label><br/>
                <label>{props.user.companyName && props.user.companyName}</label>
                <p>{props.item && props.item.interestComment}</p>
            </div>
            
        </div>
        </>
    );
}

const InvestorSentimentsDetails = props => {
    const [tags, setTags] = useState([]);
    const onRef = useRef();

    if (props.tags && props.tags.length > 0) {
        alert(1)
        setTags(tags);
    }

    const investorsSentimentsDetail = props.investorsSentimentsDetail;
    if (!investorsSentimentsDetail) {
        return (null);
    }
    const { company, noThanks, learnMore, participate, firstLine, totalInvestors } = investorsSentimentsDetail;
    const handleDelete = (i) => {
        const newTags = tags.slice(0)
        newTags.splice(i, 1)
        setTags(newTags);
        props.searchInvestorSentiments(newTags);
    }
    const handleAddition = (tag) => {
        setTags([].concat(tags, { id: tag, name: tag }));
        props.searchInvestorSentiments([].concat(tags, { id: tag, name: tag }));
    }
    
    const showDataInPopup = (data) => {
        openContactDetailModal(data);
    }

    const openContactDetailModal = (data) => {
        onRef.current.setContactDetailsData(data._id, null);
        document.getElementById('contactDetails-btn').click();
    }

    return (
        <>
            <div className="pipeline-startup-section recommendations-list investor-sentiments-list custom-investor-sentiments-list">
                
                <ContactDetailsModal
                    ref={onRef}
                    {...props}
                /> 
                <TButton onClick={() => { }} id='contactDetails-btn' dataTarget={`#contactDetailsModal`} dataToggle={`modal`} style={{ display: 'none' }} />
                   
                <div className="container-fluid">
                    <CompanyDetail
                        company={company}
                    />
                    <div className="invester-chart-list-card">
                        <div className="row">

                            <PieGraph
                                noThanks={noThanks}
                                learnMore={learnMore}
                                participate={participate}
                                firstLine={firstLine}
                                totalInvestors={totalInvestors}
                            />

                            <div className="col-xl-7 col-lg-7 col-md-7 col-sm-6 col-12">
                                <div className="investors-list-right-side">
                                    <div className="list-title">
                                        Investors List
                                    </div>
                                    <div className="full-search-box search">
                                        {/* <form className="form-group">
                                            <input className="form-control mr-sm-2" type="search" placeholder="Search Investor" aria-label="Search" />
                                            <button className="btn btn-
                                            outline-success my-2 my-sm-0" type="submit"></button>
                                        </form> */}
                                        <ReactTags
                                            allowNew={true}
                                            placeholder='Search Investor'
                                            tags={tags}
                                            handleDelete={(i) => handleDelete(i)}
                                            handleAddition={(value) => handleAddition(value.name)}
                                        />
                                    </div>
                                    <div className="segment-statrup-chart">
                                        <ul className="nav nav-tabs">
                                            <li className="nav-item active">
                                                <a className="nav-link" data-toggle="tab" href="#wanttobeinline">Want to be 1st in line ({firstLine.data.length})</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#wanttobeparticipant">Want to Participate ({participate.data.length})</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#needmoreclarification">Want to Learn More ({learnMore.data.length})</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#notInterested">No Thanks({noThanks.data.length})</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            
                                            <div className="tab-pane active" id="wanttobeinline">
                                                {   
                                                    firstLine && firstLine.data.length > 0 &&
                                                    firstLine.data.map(item => {
                                                        const { user } = item;
                                                        return <CardInterest 
                                                        user={user} item={item} history={props.history} 
                                                        showDataInPopup = { (user) => showDataInPopup(user) }
                                                        {...props}
                                                        />
                                                    })
                                                }

                                            </div>
                                            <div className="tab-pane" id="wanttobeparticipant">
                                                {
                                                    participate && participate.data.length > 0 &&
                                                    participate.data.map(item => {
                                                        const { user } = item;
                                                        return <CardInterest 
                                                            user={user} item={item} history={props.history} 
                                                            showDataInPopup = { (user) => showDataInPopup(user) }
                                                            {...props}
                                                        />
                                                    })
                                                }
                                            </div>
                                            <div className="tab-pane" id="needmoreclarification">
                                                {
                                                    learnMore && learnMore.data.length > 0 &&
                                                    learnMore.data.map(item => {
                                                        const { user } = item;
                                                        return <CardInterest 
                                                            user={user} item={item} history={props.history} 
                                                            showDataInPopup = { (user) => showDataInPopup(user) }
                                                            {...props}
                                                        />
                                                    })
                                                }
                                            </div>
                                            <div className="tab-pane" id="notInterested">
                                                {
                                                    noThanks && noThanks.data.length > 0 &&
                                                    noThanks.data.map(item => {
                                                        const { user } = item;
                                                        return <CardInterest 
                                                            user={user} item={item} history={props.history} 
                                                            showDataInPopup = { (user) => showDataInPopup(user) }
                                                            {...props}
                                                        />
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
const mapStateToProps = state => {
    return {
        investorsSentimentsDetail: state.investorsSentimentsDetail && state.investorsSentimentsDetail.data ? state.investorsSentimentsDetail.data : null,
    };
};

export default connect(mapStateToProps, null)(InvestorSentimentsDetails);
import axios from "axios";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import common from "../../common";
import Header from "../../components/Header";
import images from "../../images";
import { Documents } from "../../InvestorDashboard/InvestmentDetail/components/DetailView/DetailView";
import { getInvestmentLogoFromS3 } from "../../InvestorDashboard/shared/helper";
import "./exitedDetails.scss";

const ExitInvestmentDetails = (props) => {
  const [getDataCompany, setDataCompany] = useState(null);
  const [getDataInvestment, setDataInvestment] = useState(null);
  const [getUserInvestment, setUserInvestment] = useState(null);

  const [getUserAmount, setUserAmount] = useState(null);
  const [getTotalUserAmount, setTotalUserAmount] = useState(0);
  const [getPercentageInvestment, setPercentageInvestment] = useState(0);
  const [getPercentageValue, setPercentageValue] = useState(0);
  const [getManagementFee, setManagementFee] = useState(0);
  const [getSettingsManagementFee, setSettingsManagementFee] = useState(0);
  const [getDefaultPPS, setDefaultPPS] = useState(0);
  const [getMyPPS, setMyPPS] = useState(0);
  const [getPricePerShareAtExit, setPricePerShareAtExit] = useState(0);
  const [getNewPPSExit, setNewPPSExit] = useState(0);
  const [getSettingsCarryFee, setSettingsCarryFee] = useState(0);
  const [getCarryFee, setCarryFee] = useState(0);
  const [getUserMgFee, setUserMgFee] = useState(0);
  const [getCarryValue, setCarryValue] = useState(0);
  const [getSettingsCarryValue, setSettingsCarryValue] = useState(0);
  const [getSettingsCarryValue2, setSettingsCarryValue2] = useState(0);
  const [getSettingsCarryValue3, setSettingsCarryValue3] = useState(0);
  const [getEntityTotal, setEntityTotal] = useState(0);
  const [getMyNetProfit, setMyNetProfit] = useState(0);
  const [getProfitPPS, setProfitPPS] = useState(0);
  const [getMyTotalPPS, setMyTotalPPS] = useState(0);
  const [disbursementFunded, setDisbursmentFunded] = useState(null);
  const [disbursementFunded2, setDisbursmentFunded2] = useState(null);
  const [disbursementFunded3, setDisbursmentFunded3] = useState(null);

  // Only For Disbursement 2
  const [getPercentageValue2, setPercentageValue2] = useState(0);
  const [getCarryValue2, setCarryValue2] = useState(0);

  //? For Disbursement 3
  const [getPercentageValue3, setPercentageValue3] = useState(0);
  const [getCarryValue3, setCarryValue3] = useState(0);

  const { id, investorId } = props.match.params;

  useEffect(() => {
    let tokenpass = window.localStorage.getItem("token");
    const data = {
      investmentId: id,
      investorId: investorId,
    };

    //console.log(data);
    axios
      .post(`${common.path.base_url}/getInvestmentDetail`, data, {
        headers: {
          Authorization: tokenpass,
        },
      })
      .then((response) => {
        const res = response.data;
        if (!res.error) {
          const dataCode = res.data;
          //console.log(dataCode.company);
          const parseAmount = dataCode.investmentProcess.amount.replace(
            /[^0-9.-]+/g,
            ""
          );
          const amount = parseFloat(parseAmount);
          const totalNetInvestment = dataCode.investment.totalNetInvestment;
          const totalGrossGain =
            dataCode.investment.disbursement1 -
            dataCode.investment.totalNetInvestment;
          const pricePerShareAtExit = dataCode.investment.pricePerShareAtExit;

          const managementFee =
            dataCode.investmentProcess.managementFee != null
              ? dataCode.investmentProcess.managementFee
              : dataCode.settings.managementFee;

          let tsgFeeMg = (amount * managementFee) / 100;
          let amountTotal = amount - tsgFeeMg;
          let ppsShare = dataCode.investment.pricePerShare;
          let myPPS = ppsShare > 0 ? amountTotal / ppsShare : 0;
          let entityTotal =
            totalNetInvestment > 0
              ? (amountTotal / totalNetInvestment) * 100
              : 0;

          const newPPSExit =
            pricePerShareAtExit != null && pricePerShareAtExit > 0
              ? amountTotal / pricePerShareAtExit
              : 0;
          const myNetProfit =
            entityTotal > 0 && totalGrossGain > 0
              ? (entityTotal * totalGrossGain) / 100
              : 0;
          const myProfitPPS =
            myNetProfit > 0 && newPPSExit > 0
              ? myNetProfit / pricePerShareAtExit
              : 0;
          const myNetTotalPPS =
            parseFloat(newPPSExit) + parseFloat(myProfitPPS);
          if (totalNetInvestment != null && totalNetInvestment != 0) {
            let percentageInvestment = (amountTotal / totalNetInvestment) * 100;
            setPercentageInvestment(percentageInvestment);
          }

          const carryFee =
            dataCode.investmentProcess.carry != null
              ? dataCode.investmentProcess.carry
              : dataCode.settings.carry;

          if (totalGrossGain != null && totalGrossGain != 0) {
            let percentageInvestment = (amountTotal / totalNetInvestment) * 100;
            let totalGross = totalGrossGain;
            let totValue = (percentageInvestment * totalGross) / 100;

            let carryVal = totValue - (totValue * carryFee) / 100;
            setPercentageValue(totValue);
            setCarryValue(carryVal);

            if (carryFee === 0) {
              let carrySettingsValue =
                (totValue * dataCode.settings.carry) / 100;
              setSettingsCarryValue(carrySettingsValue);
            }
          }

          if (
            dataCode.investment.disbursement2 !== null &&
            dataCode.investment.disbursement2 !== 0
          ) {
            //write logic for calculating all the necessary values for the disbursement2
            const totalGrossGain2 = dataCode.investment.disbursement2;
            if (totalGrossGain2 !== null && totalGrossGain2 !== 0) {
              let percentageInvestment =
                (amountTotal / totalNetInvestment) * 100;
              let totalGross2 = totalGrossGain2;
              let totValue2 = (percentageInvestment * totalGross2) / 100;
              let carryVal2 = totValue2 - (totValue2 * carryFee) / 100;
              setPercentageValue2(totValue2);
              setCarryValue2(carryVal2);
              if (carryFee === 0) {
                let carrySettingsValue2 =
                  (totValue2 * dataCode.settings.carry) / 100;
                setSettingsCarryValue2(carrySettingsValue2);
              }
            }
          }

          //? write logic for calculating all the necessary values for the disbursement3
          if (
            dataCode.investment.disbursement3 !== null &&
            dataCode.investment.disbursement3 !== 0
          ) {
            const totalGrossGain3 = dataCode.investment.disbursement3;
            if (totalGrossGain3 !== null && totalGrossGain3 !== 0) {
              let percentageInvestment =
                (amountTotal / totalNetInvestment) * 100;
              let totalGross3 = totalGrossGain3;
              let totValue3 = (percentageInvestment * totalGross3) / 100;
              let carryVal3 = totValue3 - (totValue3 * carryFee) / 100;
              setPercentageValue3(totValue3);
              setCarryValue3(carryVal3);
              if (carryFee === 0) {
                let carrySettingsValue3 =
                  (totValue3 * dataCode.settings.carry) / 100;
                setSettingsCarryValue3(carrySettingsValue3);
              }
            }
          }

          if (dataCode.company) {
            const companyData = {
              ...dataCode.company,
              companyId: dataCode.company._id,
            }
            dataCode.company = companyData;
          }

          setMyTotalPPS(myNetTotalPPS);
          setProfitPPS(myProfitPPS);
          setMyNetProfit(myNetProfit);
          setPricePerShareAtExit(pricePerShareAtExit);
          setNewPPSExit(newPPSExit);
          setEntityTotal(entityTotal);
          setDefaultPPS(ppsShare);
          setMyPPS(myPPS);
          setManagementFee(managementFee);
          setCarryFee(carryFee);
          setSettingsManagementFee(dataCode.settings.managementFee);
          setSettingsCarryFee(dataCode.settings.carry);
          setDataCompany(dataCode.company);
          setDataInvestment(dataCode.investment);
          setUserInvestment(dataCode.investmentProcess);
          setDisbursmentFunded(dataCode.investmentProcess.disbursementFunded);
          setDisbursmentFunded2(dataCode.investmentProcess.disbursementFunded2);
          setDisbursmentFunded3(dataCode.investmentProcess.disbursementFunded3);
          setUserAmount(amount);
          setTotalUserAmount(amountTotal);
          if (managementFee === 0) {
            const tsgSeetingsFeeMg =
              (amount * dataCode.settings.managementFee) / 100;
            setUserMgFee(tsgSeetingsFeeMg);
          } else {
            setUserMgFee(tsgFeeMg);
          }

        }
      });
  }, [id, investorId]);

  const username = window.localStorage.getItem("username");
  const userRole = window.localStorage.getItem("role");

  const deleteInvestmentDoc = (file) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      const token = window.localStorage.getItem("token");
      const userId = window.localStorage.getItem("id");
      if (willDelete) {
        axios
          .delete(`${common.path.base_url}/investmentDoc`, {
            headers: { Authorization: token },
            data: {
              id: file._id,
              userId: userId,
            },
          })
          //@TODO CALL ON SUCCESS OF API
          .then((response) => {
            swal({
              title: "Success!",
              text: response.data.message,
              icon: "success",
              button: "Ok!",
            }).then(() => {
              window.location.reload();
            });
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message &&
              common.AUTH_EXPIRED === error.response.data.message
            ) {
              window.localStorage.clear();
              window.location.href = "/";
              return;
            } else {
              swal({
                title: "Error! #48512",
                text:
                  error.response &&
                    error.response.data &&
                    error.response.data.message
                    ? error.response.data.message
                    : "Something Went Wrong",
                icon: "error",
                button: "Ok!",
              });
            }
          });
      } else {
        swal({ text: `${file.name} file is safe!`, icon: "info" });
      }
    });
  };

  const exitHeaderCall = () => {
    let tsgExitTag =
      getDataCompany != null ? getDataCompany.exitProcessType : "-";
    if (tsgExitTag == "ipo") {
      tsgExitTag = "IPO";
    } else if (tsgExitTag == "acquisitionwithescrow") {
      tsgExitTag = "Acquisition with escrow";
    } else if (tsgExitTag == "acquisitionwithtwoescrow") {
      tsgExitTag = "Acquisition with Two escrow";
    } else if (tsgExitTag == "acquisition") {
      tsgExitTag = "Acquisition";
    }
    let totalDispersement, totalGrossGain;
    if (getDataInvestment !== null) {
      totalDispersement = Math.abs(
        (getDataInvestment.disbursement1 || 0) +
        (getDataInvestment.disbursement2 || 0) +
        (getDataInvestment.disbursement3 || 0)
      );
      totalGrossGain = totalDispersement - getDataInvestment.totalNetInvestment;
    }

    return (
      <div
        className={
          getDataInvestment != null &&
            getDataInvestment.disbursement1 !== null &&
            getDataInvestment.disbursement2 !== null
            ? "inner-header"
            : "inner-header align-center"
        }
      >
        <div>
          <div className="exit-investment-img">
            {getDataCompany && getDataCompany.companyLogo ? (
              <img
                className="exit-investment-img-tag"
                src={getInvestmentLogoFromS3(getDataCompany)}
                alt=""
              />
            ) : (
              <img
                id="compProfilePreview"
                src={images.path.placeholder}
                className="companyImage"
                alt=""
              />
            )}
          </div>
          <div className="exit-investment-name">
            <div className="exit-name-div">
              {getDataInvestment != null ? getDataInvestment.name : "-"}
            </div>
            <div className="exit-tag-div">{tsgExitTag}</div>
          </div>
        </div>
        <div className="amt-box-card">
          <div className="amt-title-div">
            TSG’s Original Total Net Investment
          </div>
          <div className="amt-value-div">
            $
            {getDataInvestment != null
              ? Number(getDataInvestment.totalNetInvestment).toLocaleString(
                undefined,
                { maximumFractionDigits: 2, minimumFractionDigits: 2 }
              )
              : ""}
          </div>
        </div>
        <div className="amt-box-card">
          <div className="amt-title-div">Net Disbursement To TSG</div>
          <div className="amt-value-div">
            $
            {getDataInvestment != null
              ? Number(totalDispersement).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
              : ""}
          </div>
          <div className="amt-diff-div">
            {getDataCompany &&
              (getDataCompany.exitProcessType === "acquisitionwithescrow" || 
                getDataCompany.exitProcessType === "acquisitionwithtwoescrow") &&
              getDataInvestment != null &&
              getDataInvestment.disbursement2 !== null &&
              getDataInvestment.disbursement2 !== 0 && (
                <p className="amt-diff-div">
                  ($
                  {Number(getDataInvestment.disbursement1).toLocaleString(
                    undefined,
                    { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                  )}{" "}
                  + $
                  {Number(getDataInvestment.disbursement2).toLocaleString(
                    undefined,
                    { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                  )}
                  {getDataCompany.exitProcessType === "acquisitionwithtwoescrow" && (
                    <> {" "}
                    + $
                  {Number(getDataInvestment.disbursement3).toLocaleString(
                    undefined,
                    { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                  )}
                    </>
                  )}
                  )
                </p>
              )}
          </div>
        </div>
        <div className="amt-box-card">
          <div className="amt-title-div amt-bold-card">{totalGrossGain < 0 ? 'Gross Loss' : 'Gross Gain'}</div>
          <div className="amt-value-div amt-bold-card">
            $
            {getDataInvestment != null
              ? Number(totalGrossGain).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
              : ""}
          </div>
          <div className="amt-diff-div">
            {getDataCompany &&
              (getDataCompany.exitProcessType === "acquisitionwithescrow" || getDataCompany.exitProcessType === "acquisitionwithtwoescrow") &&
              getDataInvestment != null &&
              getDataInvestment.disbursement2 !== null &&
              getDataInvestment.disbursement2 !== 0 && (
                <p className="amt-diff-div">
                  ($
                  {getDataInvestment &&
                    Number(totalDispersement).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}{" "}
                  - $
                  {getDataInvestment &&
                    Number(getDataInvestment.totalNetInvestment).toLocaleString(
                      undefined,
                      { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                    )}
                  )
                </p>
              )}
          </div>
        </div>
      </div>
    );
  };

  const exitAcquisition = () => {
    return (
      <div className="exited-body-box">
        <div className="tsg-div-width-100">
          <div className="exit-tsg-overview">Your Investment Overview</div>

          <div className="tot-org-net">
            <div className="tot-disb-tsg">Your Original Capital Commitment</div>
            <div className="tot-disb-tsg non-padding">
              $
              {getUserInvestment != null
                ? Number(getUserAmount).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
                : "-"}
            </div>
          </div>
          <div className="tot-org-net">
            <div>Management Fee ({getManagementFee}%)</div>
            <div className="tot-org-dollor non-padding">
              $
              {Number(getUserMgFee).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </div>
          </div>

          <div className="tot-org-net">
            <div className="tot-disb-tsg">Your Net Capital Invested</div>
            <div className="tot-disb-tsg non-padding">
              $
              {getUserInvestment != null
                ? Number(getTotalUserAmount).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
                : "-"}
            </div>
          </div>
          <div className="tot-org-net">
            <div>
              Your proportional % of the TSG investor entity
            </div>
            <div className="tot-org-dollor non-padding">
              {Number(getPercentageInvestment).toLocaleString(undefined, {
                maximumFractionDigits: 4,
                minimumFractionDigits: 2,
              })}
              %
            </div>
          </div>
          <div className="tot-org-net">
            <div>
              ($
              {getDataInvestment != null
                ? Number(
                  Math.abs(getDataInvestment.disbursement1 -
                    getDataInvestment.totalNetInvestment)
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
                : ""}{" "}
              X{" "}
              {Number(getPercentageInvestment).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
              %)
            </div>
            <div className="tot-org-dollor non-padding">
              ${" "}
              {Number(getPercentageValue).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </div>
          </div>
          <div className="tot-org-net">
            <div>
              {100 - getCarryFee}% of $
              {Number(getPercentageValue).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}{" "}
              (after {getCarryFee}% carry)
            </div>
            <div className="tot-org-dollor non-padding">
              $
              {Number(getCarryValue).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </div>
          </div>

          <div className="tot-org-net">
            <div className="tot-disb-tsg">Your Payout From Disbursement</div>
            <div className="tot-disb-tsg non-padding">
              $
              {Number(getTotalUserAmount + getCarryValue).toLocaleString(
                undefined,
                { maximumFractionDigits: 2, minimumFractionDigits: 2 }
              )}
            </div>
          </div>
          <div className="tot-org-net">
            <div>
              ($
              {Number(getTotalUserAmount).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}{" "}
              + $
              {Number(getCarryValue).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
              )
            </div>
          </div>
        </div>
      </div>
    );
  };

  const exitAcquisitionWithEscrow = () => {
    return (
      <div className="exited-body-box">
        <div className="tsg-div-box">
          <div className="exit-tsg-overview">Disbursement 1</div>
          <div className="tot-org-net">
            <div className="tot-disb-tsg">Your Original Capital Commitment</div>
            <div className="tot-disb-tsg non-padding">
              $
              {getUserInvestment != null
                ? Number(getUserAmount).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
                : "-"}
            </div>
          </div>
          <div className="tot-org-net">
            {getManagementFee === 0 && (
              <div>
                {getSettingsManagementFee}% Management Fee ($
                {Number(getUserMgFee).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
                ) Waived per Side Letter
              </div>
            )}
            {getManagementFee !== 0 && getManagementFee !== null && (
              <div>Management Fee ({getManagementFee}%)</div>
            )}
            {getManagementFee == null && (
              <div>Management Fee ({getSettingsManagementFee}%)</div>
            )}
            <div className="tot-org-dollor non-padding">
              $
              {Number(getManagementFee !== 0 ? getUserMgFee : 0).toLocaleString(
                undefined,
                { maximumFractionDigits: 2, minimumFractionDigits: 2 }
              )}
            </div>
          </div>

          <div className="tot-org-net">
            <div className="tot-disb-tsg">Your Net Capital Invested</div>
            <div className="tot-disb-tsg non-padding">
              $
              {getUserInvestment != null
                ? Number(getTotalUserAmount).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
                : "-"}
            </div>
          </div>
          <div className="tot-org-net">
            <div>
              Your proportional % of the TSG investor entity
            </div>
            <div className="tot-org-dollor non-padding">
              {Number(getPercentageInvestment).toLocaleString(undefined, {
                maximumFractionDigits: 4,
                minimumFractionDigits: 2,
              })}
              %
            </div>
          </div>
          { getDataInvestment && getDataInvestment.disbursement1 != null &&
            getDataInvestment.totalNetInvestment != null && Number(
            getDataInvestment.disbursement1 -
            getDataInvestment.totalNetInvestment
          ) > 0 &&
            <>
              <div className="tot-org-net">
                <div>
                  ($
                  {getDataInvestment != null
                    ? Number(
                      getDataInvestment.disbursement1 -
                      getDataInvestment.totalNetInvestment
                    ).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })
                    : ""}{" "}
                  X{" "}
                  {Number(getPercentageInvestment).toLocaleString(undefined, {
                    maximumFractionDigits: 4,
                    minimumFractionDigits: 2,
                  })}
                  %)
                </div>
                <div className="tot-org-dollor non-padding">
                  ${" "}
                  {Number(getPercentageValue).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
              </div>
              <div className="tot-org-net">
                {getCarryFee === 0 && (
                  <div>
                    {getSettingsCarryFee}% Carry ($
                    {Number(getSettingsCarryValue).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                    {/* ) Waived per Side Letter */}
                    ) Waived
                  </div>
                )}
                {getCarryFee !== 0 && getCarryFee !== null && (
                  <div>
                    {100 - getCarryFee}% of $
                    {Number(Math.abs(getPercentageValue)).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}{" "}
                    (after {getCarryFee}% carry)
                  </div>
                )}
                {getCarryFee == null && (
                  <div>
                    {100 - getSettingsCarryFee}% of $
                    {Number(getSettingsCarryValue).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}{" "}
                    (after {getSettingsCarryFee}% carry)
                  </div>
                )}
                <div className="tot-org-dollor non-padding">
                  $
                  {Number(getCarryFee !== 0 ? Math.abs(getCarryValue) : 0).toLocaleString(
                    undefined,
                    { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                  )}
                </div>
              </div>
            </>
          }

          <div className="tot-org-net">
            <div className="tot-disb-tsg">Your Payout From Disbursement</div>
            <div className="tot-disb-tsg non-padding">
              $
              {Number(getCarryValue + getTotalUserAmount).toLocaleString(
                undefined,
                { maximumFractionDigits: 2, minimumFractionDigits: 2 }
              )}
            </div>
          </div>
          <div className="exit-tsg-overview">Status : {disbursementFunded}</div>
        </div>
        <div className="tsg-div-box">
          {getDataInvestment && (
            <div className="exit-tsg-overview">Disbursement 2</div>
          )}
          {getDataInvestment &&
            getDataInvestment.disbursement2 !== null &&
            getDataInvestment.disbursement2 !== 0 && (
              <>
                <div className="tot-org-net">
                  <div>
                    Your proportional % of the TSG investor entity
                  </div>
                  <div className="tot-org-dollor non-padding">
                    {Number(getPercentageInvestment).toLocaleString(undefined, {
                      maximumFractionDigits: 4,
                      minimumFractionDigits: 2,
                    })}
                    %
                  </div>
                </div>
                <div className="tot-org-net">
                  <div>
                    ($
                    {getDataInvestment != null &&
                      getDataInvestment.disbursement2
                      ? Number(getDataInvestment.disbursement2).toLocaleString(
                        undefined,
                        { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                      )
                      : ""}{" "}
                    X{" "}
                    {Number(getPercentageInvestment).toLocaleString(undefined, {
                      maximumFractionDigits: 4,
                      minimumFractionDigits: 2,
                    })}
                    %)
                  </div>
                  <div className="tot-org-dollor non-padding">
                    ${" "}
                    {Number(getPercentageValue2).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </div>
                </div>
                <div className="tot-org-net">
                  {getCarryFee === 0 && (
                    <div>
                      {getSettingsCarryFee}% Carry ($
                      {Number(getSettingsCarryValue2).toLocaleString(
                        undefined,
                        { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                      )}
                      {/* ) Waived per Side Letter */}
                      ) Waived
                    </div>
                  )}
                  {getCarryFee !== 0 && getCarryFee !== null && (
                    <div>
                      {100 - getCarryFee}% of $
                      {Number(getPercentageValue2).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}{" "}
                      (after {getCarryFee}% carry)
                    </div>
                  )}
                  {getCarryFee == null && (
                    <div>
                      {100 - getSettingsCarryFee}% of $
                      {Number(getSettingsCarryValue2).toLocaleString(
                        undefined,
                        { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                      )}{" "}
                      (after {getSettingsCarryFee}% carry)
                    </div>
                  )}
                  <div className="tot-org-dollor non-padding">
                    $
                    {Number(
                      getCarryFee !== 0 ? getCarryValue2 : 0
                    ).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </div>
                </div>

                <div className="tot-org-net">
                  <div className="tot-disb-tsg">
                    Your Payout From Disbursement
                  </div>
                  <div className="tot-disb-tsg non-padding">
                    $
                    {Number(getCarryValue2).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </div>
                </div>
                <div className="exit-tsg-overview">Status : {disbursementFunded2}</div>
              </>
            )}
        </div>
      </div>
    );
  };

  const exitAcquisitionWithTwoEscrow = () => {
    return (
      <div className="exited-body-box">
        <div className="tsg-div-box">
          <div className="exit-tsg-overview">Disbursement 1</div>
          <div className="tot-org-net">
            <div className="tot-disb-tsg">Your Original Capital Commitment</div>
            <div className="tot-disb-tsg non-padding">
              $
              {getUserInvestment != null
                ? Number(getUserAmount).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
                : "-"}
            </div>
          </div>
          <div className="tot-org-net">
            {getManagementFee === 0 && (
              <div>
                {getSettingsManagementFee}% Management Fee ($
                {Number(getUserMgFee).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
                ) Waived per Side Letter
              </div>
            )}
            {getManagementFee !== 0 && getManagementFee !== null && (
              <div>Management Fee ({getManagementFee}%)</div>
            )}
            {getManagementFee == null && (
              <div>Management Fee ({getSettingsManagementFee}%)</div>
            )}
            <div className="tot-org-dollor non-padding">
              $
              {Number(getManagementFee !== 0 ? getUserMgFee : 0).toLocaleString(
                undefined,
                { maximumFractionDigits: 2, minimumFractionDigits: 2 }
              )}
            </div>
          </div>

          <div className="tot-org-net">
            <div className="tot-disb-tsg">Your Net Capital Invested</div>
            <div className="tot-disb-tsg non-padding">
              $
              {getUserInvestment != null
                ? Number(getTotalUserAmount).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
                : "-"}
            </div>
          </div>
          <div className="tot-org-net">
            <div>
              Your proportional % of the TSG investor entity
            </div>
            <div className="tot-org-dollor non-padding">
              {Number(getPercentageInvestment).toLocaleString(undefined, {
                maximumFractionDigits: 4,
                minimumFractionDigits: 2,
              })}
              %
            </div>
          </div>
          { getDataInvestment && getDataInvestment.disbursement1 != null &&
            getDataInvestment.totalNetInvestment != null && Number(
            getDataInvestment.disbursement1 -
            getDataInvestment.totalNetInvestment
          ) > 0 &&
            <>
              <div className="tot-org-net">
                <div>
                  ($
                  {getDataInvestment != null
                    ? Number(
                      getDataInvestment.disbursement1 -
                      getDataInvestment.totalNetInvestment
                    ).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })
                    : ""}{" "}
                  X{" "}
                  {Number(getPercentageInvestment).toLocaleString(undefined, {
                    maximumFractionDigits: 4,
                    minimumFractionDigits: 2,
                  })}
                  %)
                </div>
                <div className="tot-org-dollor non-padding">
                  ${" "}
                  {Number(getPercentageValue).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
              </div>
              <div className="tot-org-net">
                {getCarryFee === 0 && (
                  <div>
                    {getSettingsCarryFee}% Carry ($
                    {Number(getSettingsCarryValue).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                    {/* ) Waived per Side Letter */}
                    ) Waived
                  </div>
                )}
                {getCarryFee !== 0 && getCarryFee !== null && (
                  <div>
                    {100 - getCarryFee}% of $
                    {Number(Math.abs(getPercentageValue)).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}{" "}
                    (after {getCarryFee}% carry)
                  </div>
                )}
                {getCarryFee == null && (
                  <div>
                    {100 - getSettingsCarryFee}% of $
                    {Number(getSettingsCarryValue).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}{" "}
                    (after {getSettingsCarryFee}% carry)
                  </div>
                )}
                <div className="tot-org-dollor non-padding">
                  $
                  {Number(getCarryFee !== 0 ? Math.abs(getCarryValue) : 0).toLocaleString(
                    undefined,
                    { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                  )}
                </div>
              </div>
            </>
          }

          <div className="tot-org-net">
            <div className="tot-disb-tsg">Your Payout From Disbursement</div>
            <div className="tot-disb-tsg non-padding">
              $
              {Number(getCarryValue + getTotalUserAmount).toLocaleString(
                undefined,
                { maximumFractionDigits: 2, minimumFractionDigits: 2 }
              )}
            </div>
          </div>
          <div className="exit-tsg-overview">Status : {disbursementFunded}</div>
        </div>
        <div className="tsg-div-box">
          {getDataInvestment && (
            <div className="exit-tsg-overview">Disbursement 2</div>
          )}
          {getDataInvestment &&
            getDataInvestment.disbursement2 !== null &&
            getDataInvestment.disbursement2 !== 0 && (
              <>
                <div className="tot-org-net">
                  <div>
                    Your proportional % of the TSG investor entity
                  </div>
                  <div className="tot-org-dollor non-padding">
                    {Number(getPercentageInvestment).toLocaleString(undefined, {
                      maximumFractionDigits: 4,
                      minimumFractionDigits: 2,
                    })}
                    %
                  </div>
                </div>
                <div className="tot-org-net">
                  <div>
                    ($
                    {getDataInvestment != null &&
                      getDataInvestment.disbursement2
                      ? Number(getDataInvestment.disbursement2).toLocaleString(
                        undefined,
                        { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                      )
                      : ""}{" "}
                    X{" "}
                    {Number(getPercentageInvestment).toLocaleString(undefined, {
                      maximumFractionDigits: 4,
                      minimumFractionDigits: 2,
                    })}
                    %)
                  </div>
                  <div className="tot-org-dollor non-padding">
                    ${" "}
                    {Number(getPercentageValue2).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </div>
                </div>
                <div className="tot-org-net">
                  {getCarryFee === 0 && (
                    <div>
                      {getSettingsCarryFee}% Carry ($
                      {Number(getSettingsCarryValue2).toLocaleString(
                        undefined,
                        { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                      )}
                      {/* ) Waived per Side Letter */}
                      ) Waived
                    </div>
                  )}
                  {getCarryFee !== 0 && getCarryFee !== null && (
                    <div>
                      {100 - getCarryFee}% of $
                      {Number(getPercentageValue2).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}{" "}
                      (after {getCarryFee}% carry)
                    </div>
                  )}
                  {getCarryFee == null && (
                    <div>
                      {100 - getSettingsCarryFee}% of $
                      {Number(getSettingsCarryValue2).toLocaleString(
                        undefined,
                        { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                      )}{" "}
                      (after {getSettingsCarryFee}% carry)
                    </div>
                  )}
                  <div className="tot-org-dollor non-padding">
                    $
                    {Number(
                      getCarryFee !== 0 ? getCarryValue2 : 0
                    ).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </div>
                </div>

                <div className="tot-org-net">
                  <div className="tot-disb-tsg">
                    Your Payout From Disbursement
                  </div>
                  <div className="tot-disb-tsg non-padding">
                    $
                    {Number(getCarryValue2).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </div>
                </div>
                <div className="exit-tsg-overview">Status : {disbursementFunded2}</div>
              </>
            )}
          {getDataInvestment &&
            getDataInvestment.disbursement3 !== null &&
            getDataInvestment.disbursement3 !== 0 && (
              <>
              <div className="exit-tsg-header-3">Disbursement 3</div>
                <div className="tot-org-net">
                  <div>
                    Your proportional % of the TSG investor entity
                  </div>
                  <div className="tot-org-dollor non-padding">
                    {Number(getPercentageInvestment).toLocaleString(undefined, {
                      maximumFractionDigits: 4,
                      minimumFractionDigits: 2,
                    })}
                    %
                  </div>
                </div>
                <div className="tot-org-net">
                  <div>
                    ($
                    {getDataInvestment != null &&
                      getDataInvestment.disbursement3
                      ? Number(getDataInvestment.disbursement3).toLocaleString(
                        undefined,
                        { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                      )
                      : ""}{" "}
                    X{" "}
                    {Number(getPercentageInvestment).toLocaleString(undefined, {
                      maximumFractionDigits: 4,
                      minimumFractionDigits: 2,
                    })}
                    %)
                  </div>
                  <div className="tot-org-dollor non-padding">
                    ${" "}
                    {Number(getPercentageValue3).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </div>
                </div>
                <div className="tot-org-net">
                  {getCarryFee === 0 && (
                    <div>
                      {getSettingsCarryFee}% Carry ($
                      {Number(getSettingsCarryValue3).toLocaleString(
                        undefined,
                        { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                      )}
                      {/* ) Waived per Side Letter */}
                      ) Waived
                    </div>
                  )}
                  {getCarryFee !== 0 && getCarryFee !== null && (
                    <div>
                      {100 - getCarryFee}% of $
                      {Number(getPercentageValue3).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}{" "}
                      (after {getCarryFee}% carry)
                    </div>
                  )}
                  {getCarryFee == null && (
                    <div>
                      {100 - getSettingsCarryFee}% of $
                      {Number(getSettingsCarryValue3).toLocaleString(
                        undefined,
                        { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                      )}{" "}
                      (after {getSettingsCarryFee}% carry)
                    </div>
                  )}
                  <div className="tot-org-dollor non-padding">
                    $
                    {Number(
                      getCarryFee !== 0 ? getCarryValue3 : 0
                    ).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </div>
                </div>

                <div className="tot-org-net">
                  <div className="tot-disb-tsg">
                    Your Payout From Disbursement
                  </div>
                  <div className="tot-disb-tsg non-padding">
                    $
                    {Number(getCarryValue3).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </div>
                </div>
                <div className="exit-tsg-overview">Status : {disbursementFunded3}</div>
              </>
            )}
        </div>
      </div>
    );
  };

  const exitIPO = () => {
    return (
      <div className="exited-body-box">
        <div className="tsg-div-width-100">
          <div className="exit-tsg-overview">Your Investment Overview</div>

          <div className="tot-org-net">
            <div className="tot-disb-tsg">Your Original Capital Commitment</div>
            <div className="tot-disb-tsg non-padding">
              $
              {getUserInvestment != null
                ? Number(getUserAmount).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
                : "-"}
            </div>
          </div>
          <div className="tot-org-net">
            <div>- Management Fee ({getManagementFee}%)</div>
            <div className="tot-org-dollor non-padding">
              $
              {Number(getUserMgFee).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </div>
          </div>

          <div className="tot-ipo-net">
            <div className="tot-disb-tsg">- Your Net Capital Invested</div>
            <div className="tot-disb-tsg non-padding">
              $
              {getUserInvestment != null
                ? Number(getTotalUserAmount).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
                : "-"}
            </div>
          </div>

          <div className="border-add-ipo"></div>

          <div className="tot-org-net">
            <div>Share Price at Time of Investment</div>
            <div className="tot-org-dollor non-padding">
              $
              {Number(getDefaultPPS).toLocaleString(undefined, {
                maximumFractionDigits: 4,
                minimumFractionDigits: 2,
              })}
            </div>
          </div>

          <div className="tot-org-net">
            <div>
              Est. Shares Associated with Your Investment ($
              {Number(getTotalUserAmount).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}{" "}
              / $
              {Number(getDefaultPPS).toLocaleString(undefined, {
                maximumFractionDigits: 4,
                minimumFractionDigits: 2,
              })}{" "}
              )
            </div>
            <div className="tot-org-dollor non-padding">
              {Number(getMyPPS).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </div>
          </div>

          <div className="tot-org-net">
            <div>
              - Your % Share of the Entity Total{" "}
              {getDataInvestment != null
                ? "( $" +
                Number(getTotalUserAmount).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }) +
                " / " +
                "$" +
                Number(getDataInvestment.totalNetInvestment).toLocaleString(
                  undefined,
                  { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                ) +
                " * 100 )"
                : 0}
            </div>
            <div className="tot-org-dollor non-padding">
              {Number(getEntityTotal).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
              %
            </div>
          </div>

          <div className="border-add-ipo"></div>

          <div className="tot-org-net">
            <div className="tot-disb-tsg">Exited</div>
            <div className="tot-disb-tsg non-padding"></div>
          </div>

          <div className="tot-org-net">
            <div className="tot-disb-tsg exited-font16">
              Share Price at Time of Exit
            </div>
            <div className="tot-disb-tsg non-padding exited-font16">
              $
              {getDataInvestment &&
                getDataInvestment.pricePerShareAtExit &&
                getDataInvestment.pricePerShareAtExit != null
                ? Number(getDataInvestment.pricePerShareAtExit).toLocaleString(
                  undefined,
                  { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                )
                : 0}
            </div>
          </div>

          <div className="border-add-ipo"></div>

          <div className="tot-org-net">
            <div className="tot-disb-tsg exited-font16">
              Original Net Investment Returned
            </div>
            <div className="tot-disb-tsg non-padding exited-font16">
              $
              {Number(getTotalUserAmount).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </div>
          </div>

          <div className="tot-org-net">
            <div>- Corresponding # of Shares:</div>
            <div className="tot-org-dollor non-padding">
              {Number(getNewPPSExit).toLocaleString(undefined, {
                maximumFractionDigits: 4,
                minimumFractionDigits: 2,
              })}
            </div>
          </div>

          <div className="border-add-ipo"></div>

          <div className="tot-org-net">
            <div className="tot-disb-tsg exited-font16">
              Your Share of Total Net Profit
            </div>
            <div className="tot-disb-tsg non-padding exited-font16">
              $
              {Number(getMyNetProfit).toLocaleString(undefined, {
                maximumFractionDigits: 4,
                minimumFractionDigits: 2,
              })}
            </div>
          </div>

          <div className="tot-org-net">
            <div>- Corresponding # of shares:</div>
            <div className="tot-org-dollor non-padding">
              {Number(getProfitPPS).toLocaleString(undefined, {
                maximumFractionDigits: 4,
                minimumFractionDigits: 2,
              })}
            </div>
          </div>

          <div className="border-add-ipo"></div>

          <div className="tot-org-net">
            <div className="tot-disb-tsg">Total Shares to You</div>
            <div className="tot-disb-tsg non-padding">
              {Number(getMyTotalPPS).toLocaleString(undefined, {
                maximumFractionDigits: 4,
                minimumFractionDigits: 2,
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const [investmentDetailData, setInvestmentDetailData] = useState({});
  const [loading, setLoading] = useState(false);
  const [signOA, setSignOA] = useState(null);

  useEffect(() => {
    const myInvestmentsById = () => {
      const url = new URL(window.location.href);
      const userId = window.localStorage.getItem("id");
      const finalUserId = investorId ? investorId : userId;

      setLoading(true);
      const token = window.localStorage.getItem("token");

      axios
        .get(`${common.path.base_url}/myInvestmentsById/${finalUserId}/${id}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          axios
            .get(`${common.path.base_url}/getSignOA/${id}`, {
              headers: {
                Authorization: token,
              },
            })
            .then((signOARes) => {
              setLoading(false);

              if (signOARes && signOARes.data && signOARes.data.data) {
                setSignOA(signOARes.data.data);
              }

              if (response && response.data && response.data.data) {
                if (response.data.data.length > 0) {
                  setInvestmentDetailData(response.data.data[0]);
                }
              }
            })
            .catch(handleError);
        })
        .catch(handleError);
    };

    const handleError = (error) => {
      setLoading(false);

      if (
        error.response &&
        error.response.data &&
        error.response.data.message &&
        common.AUTH_EXPIRED === error.response.data.message
      ) {
        window.localStorage.clear();
        window.location.href = "/";
        return;
      } else if (error.response) {
        swal({
          title: "Error! #451220",
          text:
            error.response.data && error.response.data.message
              ? error.response.data.message
              : "Something Went Wrong",
          icon: "error",
          button: "Ok!",
        });
      } else {
        swal({
          title: "Network Error!",
          text:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : error.message,
          icon: "error",
          button: "Ok!",
        });
      }
    };

    myInvestmentsById();
  }, [id, investorId]);

  const redirect = () => {
    // const url = new URL(window.location.href);
    // const investorId = url.searchParams.get('investorId');
    if (investorId) {
      window.location.href = `/investor-dashboard?investorId=${investorId}`;
    } else {
      window.location.href = `/investor-dashboard`;
    }
  }

  const redirectToWallet = () => {
    window.location.href = `/investorWallet`;
  }

  const roleCode = window.localStorage.getItem('roleCode');

  return (
    <div className="updateProfile">
      <Header username={username} role={userRole} />

      <div className="exit-investment-box">
        <div className="cancel-btn-wraper">
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => redirect()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="exit-inner-box">
          <div className="exit-investment-header" style={{
            display: "flex",
            justifyContent: "space-between"
          }}>
            Exited Investment Details
            {
              roleCode === "005" &&
              <button className='btn btn-danger'
                style={{ postion: "absolute", right: "0px" }}
                onClick={() => redirectToWallet()}>
                View Wallet
              </button>
            }
          </div>
          {exitHeaderCall()}
          {getDataCompany != null &&
            getDataCompany.exitProcessType == "acquisition" &&
            exitAcquisition()}
          {getDataCompany != null &&
            getDataCompany.exitProcessType == "acquisitionwithescrow" &&
            exitAcquisitionWithEscrow()}
          {getDataCompany != null &&
            getDataCompany.exitProcessType == "acquisitionwithtwoescrow" &&
            exitAcquisitionWithTwoEscrow()}
          {getDataCompany != null &&
            getDataCompany.exitProcessType == "ipo" &&
            exitIPO()}

          {getDataCompany &&
            getDataCompany.exitNotes &&
            getDataCompany.exitNotes != null && (
              <div>
                <div className="notes-txt">Note:</div>
                <div
                  className="notes-all-txt"
                  dangerouslySetInnerHTML={{ __html: getDataCompany.exitNotes }}
                />
              </div>
            )}
          <div className="notes-txt">Documents:</div>
          <Documents
            investmentDetailData={investmentDetailData}
            isExitedProcess={true}
            deleteInvestmentDoc={(docFile) => deleteInvestmentDoc(docFile)}
          />
        </div>
      </div>
    </div>
  );
};

export default ExitInvestmentDetails;

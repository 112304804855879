const initialState = {
  userType: 'Guest'
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'IS_ADMIN':
      return { ...state, userType: action.userType };
    default:
      return state;
  }
}

export default reducer

import React from "react";
import "./Start.scss";
import Button from "../../shared/inputs/Button";

const DashboardStart = (props) => (
  <div className="deashbord">
    <div className="container-fluid">
      <div className="container">
        <h5>Welcome!</h5>
        {props.adminAccess && props.fullName && (
          <h2>
            <a
              className="dashName"
              href={`/viewProfile?id=${props.investorId}`}
            >
              {props.fullName}
            </a>
            <Button
              className="btn btn-danger"
              title="TSG Review"
              onClickHandler={() => props.history.push("/year-in-review")}
            />
          </h2>
        )}
      </div>
      {/* {
                !props.adminAccess &&
                <div className="btn-box">
                  <Button
                    className='btn btn-danger'
                    title='Year In Review'
                    
                />
                </div>
            } */}
    </div>
  </div>
);

export default DashboardStart;

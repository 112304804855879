import axios from 'axios';
import React, { Component } from 'react';
import swal from 'sweetalert';
import common from '../../common';
import Header from '../../components/Header';
import HeaderImage from '../../components/HeaderImage';
import LoadingSpinner from '../../components/Loader';
import ExitedUsers from './Exited';
import ExitProcessCommunication from './ExitProcessCommunication';
import ExitProcessEscrow from './ExitProcessEscrow';
import ExitProcessInitialDisbursement from './ExitProcessInitialDisbursement';
import ExitProcessSecondEscrow from './ExitProcessSecondEscrow'
import './StartupDetail.scss';
import DisbursmentUsers from './DisbursmentUsers';
import ContactDetailsModal from '../../components/Contact List/ContactDetailsModal';
import TButton from '../../components/TButton';
let userRole = '000';

class StartupDetail extends Component {
    constructor(props) {
        super(props);
        this.companyId = '';
        this.state = {
            name: '',
            Role: '',
            loading: false,
            userId: null,
            showExitInProcess: false,
            showExited: false,
            id: null,
            CompanyInvestmentData: null,
            exitInProcessUsers: [],
            exitInProcessEscrowUsers: [],
            exitInProcessSecondEscrowUsers: [],
            exitInProcessDisbursemenUsers: [],
            exitInProcessCompleteDisbursmentUsers: [],
            disbursmentUsersPagination: {
                currentPage: 0,
                totalPages: 1,
            },
            exitInProcessUsersPagination: {
                currentPage: 0,
                totalPages: 1,
            },
            exitInProcessSecondEscrowPagination: {
                currentPage: 0,
                totalPages: 1,
            },
            isAllowExitedUsersChecked: false,
            exitedUsers: [],
            exitedUsersPagination: {
                currentPage: 0,
                totalPages: 1,
            },
            showCommunication: false,
            showDisbursment: false,
            showEscrow: false,
            showSecondEscrow: false,
            companyInvestments: [],
        }
    }

    componentDidMount() {
        if (this.props.id) {
            this.props.history.push(`/investmentDetail?id=${this.props.id}&activeTab=exitInProcess`)
        }
        window.addEventListener('scroll', this.handleScroll, false);
        this.setState({ showCommunication: true });
    }

    componentWillMount() {
        const url = new URL(window.location.href);
        this.companyId = url.searchParams.get('id');
        // window.localStorage.setItem('investmentForVIPAccess', this.id);
        userRole = window.localStorage.getItem('role');
        this.setState({
            name: window.localStorage.getItem('username'),
            userId: window.localStorage.getItem('id')
        });
        this.setState({ Role: userRole });
        // this.getCompanyData();
        this.getCompanyInvestments();
    }

    getCompanyData() {
        let roleCode = window.localStorage.getItem('roleCode');
        if (roleCode === '003' || roleCode === '004' || roleCode === '005') {
            window.location.href = '/';
            return;
        }
        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loading: true });
        const id = window.localStorage.getItem('id');
        this.setState({ id: id })
        axios.get(`${common.path.base_url}/getCompanyDetail?companyId=${this.companyId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false, CompanyInvestmentData: response.data.data[0] }, () => {
                    const url = new URL(window.location.href);
                    // if (url.searchParams.get('activeTab')) {
                    //     temp.tabSelection(url.searchParams.get('activeTab'))
                    // } else {
                    temp.setState({ showExitInProcess: true })
                    temp.tabSelection('exitInProcess')
                    // }

                });

            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                window.location.href = '/';
            });
    }

    getCompanyInvestments() {
        let roleCode = window.localStorage.getItem('roleCode');
        if (roleCode === '003' || roleCode === '004' || roleCode === '005') {
            window.location.href = '/';
            return;
        }
        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loading: true });
        axios.get(`${common.path.base_url}/getCompanyInvestments/${this.companyId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false, companyInvestments: response.data.data.investments, CompanyInvestmentData: response.data.data }, () => {
                    temp.setState({ showExitInProcess: true });
                    temp.tabSelection('exitInProcess');
                });
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                window.location.href = '/';
            });
    }


    getInvestmentTitle() {
        let title = '';
        if (this.state.CompanyInvestmentData) {
            const CompanyInvestmentData = this.state.CompanyInvestmentData;
            window.localStorage.setItem('investmentCompanyName', CompanyInvestmentData.companyName);
            title += ' ' + CompanyInvestmentData.companyName;
            return title;
        }
        return '';
    }
    showLoadingHandler(loading) {
        this.setState({ loading })
    }

    getExitInProcessUsers(isFromLoadMore, tags, $sort, filterType, investmentFilterId) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const currentPage = this.state.exitInProcessUsersPagination && isFromLoadMore
            ? this.state.exitInProcessUsersPagination.currentPage + 1
            : 1;
        const data = {
            page: currentPage,
            limit: common.paginationLimit,
            $sort: $sort ? $sort : null,
        }
        if (tags && tags.length > 0) {
            data.tags = tags;
        }
        data.filterType = filterType;
        data.investmentFilterId = investmentFilterId;
        // getCommunicationUsers
        axios.post(`${common.path.base_url}/getCommunicationUsers/${this.companyId}`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    response.data.data = response.data.data && response.data.data.length > 0 && response.data.data.map(user => {
                        user.isChecked = false;
                        return user;
                    });
                }
                let exitInProcessUsers = [];
                if (isFromLoadMore) {
                    exitInProcessUsers = [...this.state.exitInProcessUsers, ...response.data.data,];
                } else {
                    exitInProcessUsers = response.data.data;
                }
                temp.setState({
                    loading: false,
                    exitInProcessUsers: this.makeArrayUnique(exitInProcessUsers),
                    exitInProcessUsersPagination: { currentPage: response.data.currentPage, totalPages: response.data.totalPages, },
                });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loading: false });
            });
    }

    getDisbursmentUsers(isFromLoadMore, tags, $sort, investmentFilterId) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const currentPage = this.state.disbursmentUsersPagination && isFromLoadMore
            ? this.state.disbursmentUsersPagination.currentPage + 1
            : 1;
        const data = {
            page: currentPage,
            limit: common.paginationLimit,
            $sort: $sort ? $sort : null,
            isEscrow: this.state.CompanyInvestmentData.exitProcessType === 'acquisitionwithescrow',
            isSecondEscrow: this.state.CompanyInvestmentData.exitProcessType === 'acquisitionwithtwoescrow',
        }
        if (tags && tags.length > 0) {
            data.tags = tags;
        }
        data.investmentFilterId = investmentFilterId;
        // getCommunicationUsers
        axios.post(`${common.path.base_url}/getDisbursementFundedUsers/${this.companyId}`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    response.data.data = response.data.data && response.data.data.length > 0 && response.data.data.map(user => {
                        user.isChecked = false;
                        return user;
                    });
                }
                let exitInProcessCompleteDisbursmentUsers = [];
                if (isFromLoadMore) {
                    exitInProcessCompleteDisbursmentUsers = [...this.state.exitInProcessCompleteDisbursmentUsers, ...response.data.data,];
                } else {
                    exitInProcessCompleteDisbursmentUsers = response.data.data;
                }
                temp.setState({
                    loading: false,
                    exitInProcessCompleteDisbursmentUsers: this.makeArrayUnique(exitInProcessCompleteDisbursmentUsers),
                    disbursmentUsersPagination: { currentPage: response.data.currentPage, totalPages: response.data.totalPages, },
                });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });
            });
    }

    getExitProcessEscrowUsers(isFromLoadMore, tags, $sort, investmentFilterId) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const currentPage = this.state.exitInProcessUsersPagination && isFromLoadMore
            ? this.state.exitInProcessUsersPagination.currentPage + 1
            : 1;
        const data = {
            page: currentPage,
            limit: common.paginationLimit,
            $sort: $sort ? $sort : null,
        }
        if (tags && tags.length > 0) {
            data.tags = tags;
        }
        data.investmentFilterId = investmentFilterId;
        // getCommunicationUsers
        axios.post(`${common.path.base_url}/getEscrowDisbursementUsers/${this.companyId}`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    response.data.data = response.data.data && response.data.data.length > 0 && response.data.data.map(user => {
                        user.isChecked = false;
                        return user;
                    });
                }
                let exitInProcessEscrowUsers = [];
                if (isFromLoadMore) {
                    exitInProcessEscrowUsers = [...this.state.exitInProcessEscrowUsers, ...response.data.data,];
                } else {
                    exitInProcessEscrowUsers = response.data.data;
                }
                temp.setState({
                    loading: false,
                    exitInProcessEscrowUsers: this.makeArrayUnique(exitInProcessEscrowUsers),
                    exitInProcessUsersPagination: { currentPage: response.data.currentPage, totalPages: response.data.totalPages, },
                });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });
            });
    }

    getExitProcessSecondEscrowUsers(isFromLoadMore, tags, $sort, investmentFilterId) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const currentPage = this.state.exitInProcessUsersPagination && isFromLoadMore
            ? this.state.exitInProcessUsersPagination.currentPage + 1
            : 1;
        const data = {
            page: currentPage,
            limit: common.paginationLimit,
            $sort: $sort ? $sort : null,
        }
        if (tags && tags.length > 0) {
            data.tags = tags;
        }
        data.investmentFilterId = investmentFilterId;
        // getCommunicationUsers
        axios.post(`${common.path.base_url}/getSecondEscrowDisbursementUsers/${this.companyId}`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    response.data.data = response.data.data && response.data.data.length > 0 && response.data.data.map(user => {
                        user.isChecked = false;
                        return user;
                    });
                }
                let exitInProcessEscrowUsers = [];
                if (isFromLoadMore) {
                    exitInProcessEscrowUsers = [...this.state.exitInProcessEscrowUsers, ...response.data.data,];
                } else {
                    exitInProcessEscrowUsers = response.data.data;
                }
                temp.setState({
                    loading: false,
                    exitInProcessSecondEscrowUsers: this.makeArrayUnique(exitInProcessEscrowUsers),
                    exitInProcessSecondEscrowPagination: { currentPage: response.data.currentPage, totalPages: response.data.totalPages, },
                });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });
            });
    }

    getExitProcessInitialDisbursementUsers(isFromLoadMore, tags, $sort, investmentFilterId) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const currentPage = this.state.exitInProcessUsersPagination && isFromLoadMore
            ? this.state.exitInProcessUsersPagination.currentPage + 1
            : 1;
        const data = {
            page: currentPage,
            limit: common.paginationLimit,
            $sort: $sort ? $sort : null,
        }
        if (tags && tags.length > 0) {
            data.tags = tags;
        }
        data.investmentFilterId = investmentFilterId;
        // getCommunicationUsers
        axios.post(`${common.path.base_url}/getDisbursementUsers/${this.companyId}`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    response.data.data = response.data.data && response.data.data.length > 0 && response.data.data.map(user => {
                        user.isChecked = false;
                        return user;
                    });
                }
                let exitInProcessDisbursemenUsers = [];
                if (isFromLoadMore) {
                    exitInProcessDisbursemenUsers = [...this.state.exitInProcessDisbursemenUsers, ...response.data.data,];
                } else {
                    exitInProcessDisbursemenUsers = response.data.data;
                }
                temp.setState({
                    loading: false,
                    exitInProcessDisbursemenUsers: this.makeArrayUnique(exitInProcessDisbursemenUsers),
                    exitInProcessUsersPagination: { currentPage: response.data.currentPage, totalPages: response.data.totalPages, },
                });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });
            });
    }

    getExitedUsers(isFromLoadMore, tags, $sort) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const currentPage = this.state.exitedUsersPagination && isFromLoadMore
            ? this.state.exitedUsersPagination.currentPage + 1
            : 1;
        const data = {
            page: currentPage,
            limit: common.paginationLimit,
            $sort: $sort ? $sort : null,
        }
        if (tags && tags.length > 0) {
            data.tags = tags;
        }
        axios.post(`${common.path.base_url}/getExitedUsers/${this.companyId}`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    response.data.data = response.data.data && response.data.data.length > 0 && response.data.data.map(user => {
                        user.isChecked = false;
                        return user;
                    });
                }
                let exitedUsers = [];
                if (isFromLoadMore) {
                    exitedUsers = [...this.state.exitInProcessUsers, ...response.data.data,];
                } else {
                    exitedUsers = response.data.data;
                }
                temp.setState({
                    loading: false,
                    exitedUsers: this.makeArrayUnique(exitedUsers),
                    exitedUsersPagination: { currentPage: response.data.currentPage, totalPages: response.data.totalPages, },
                    isAllowExitedUsersChecked: false,
                });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });
            });
    }

    tabSelection(tabValue) {
        switch (tabValue) {
            case 'exitInProcess':
                this.getExitInProcessUsers(false);
                // this.getExitProcessInitialDisbursementUsers(false);
                // this.getExitProcessEscrowUsers(false);
                this.setState({ showExitInProcess: true, showExited: false, });
                break;
            case 'exited':
                this.getExitedUsers(false);
                this.setState({ showExitInProcess: false, showExited: true, });
                break;
            default:
                this.setState({ showExitInProcess: false, showExited: false, });
                break;
        }
    }

    makeArrayUnique(array) {
        if(array.length === 0) {
            return array;
        } else {
            let uniqueIdsArray = [];
            let uniqueArray = [];
            array.forEach(item => {
                if (uniqueIdsArray.indexOf(item._id.toString()) === -1) {
                    uniqueIdsArray.push(item._id.toString());
                    uniqueArray.push(item);
                }
            });
            return uniqueArray;
        }
    }

    getCheckedExitedUsers() {
        return this.state.exitedUsers.filter(user => user.isChecked === true);
    };

    onCheckedSingleExitedUser(event, id) {
        let checkedCheckboxCount = 0;
        const users = this.state.exitedUsers && this.state.exitedUsers.length > 0 && this.state.exitedUsers.map(singleUser => {
            if (id === singleUser.user._id) {
                singleUser.isChecked = event.target.checked;

            }
            return singleUser;

        });
        this.setState({ exitedUsers: users, isAllowExitedUsersChecked: this._filterCheckedUsers('exitedUsers') });
    };

    onCheckedAllExitedUsers(event) {
        const users = this.state.exitedUsers && this.state.exitedUsers.length > 0 && this.state.exitedUsers.map(user => {
            user.isChecked = event.target.checked;
            return user;
        });
        this.setState({ exitedUsers: users, isAllowExitedUsersChecked: this._filterCheckedUsers('exitedUsers') });
    };

    ExitTabSelection(tabValue) {
        switch (tabValue) {
            case 'communication':
                this.getExitInProcessUsers(false);
                this.setState({ showCommunication: true, showDisbursment: false, showEscrow: false, showSecondEscrow: false, showDisbursmentUsers: false });
                break;
            case 'disbursment':
                this.getExitProcessInitialDisbursementUsers(false);
                this.setState({ showCommunication: false, showDisbursment: true, showEscrow: false, showSecondEscrow: false, showDisbursmentUsers: false });
                break;
            case 'escrow':
                this.getExitProcessEscrowUsers(false);
                this.setState({ showCommunication: false, showDisbursment: false, showEscrow: true, showSecondEscrow: false, showDisbursmentUsers: false });
                break;
            case 'secondEscrow':
                this.getExitProcessSecondEscrowUsers(false);
                this.setState({ showCommunication: false, showDisbursment: false, showEscrow: false, showSecondEscrow: true, showDisbursmentUsers: false });
                break;
            case 'disbursmentUsers':
                this.getDisbursmentUsers(false);
                this.setState({ showCommunication: false, showDisbursment: false, showEscrow: false, showSecondEscrow: false, showDisbursmentUsers: true });
                break;
            default:
                this.setState({ showCommunication: true, showDisbursment: false, showEscrow: false, showSecondEscrow: false, showDisbursmentUsers: false });
                break;
        }
    }

    openContactDetailModal() {
        document.getElementById('contactDetails-btn').click();
        this.setState({ loading: false });
    }

    render() {
        return (
            <div>
                {this.state.loading ? <LoadingSpinner /> : ""}
                <ContactDetailsModal
                    onRef={(ref) => this.viewContactUserDetailsChild = ref}
                    { ...this.props }
                />
                <TButton onClick={() => { }} id='contactDetails-btn' dataTarget={`#contactDetailsModal`} dataToggle={`modal`} style={{ display: 'none' }} />

                <Header
                    username={this.state.name}
                    role={this.state.Role}
                />
                <HeaderImage
                    heading={this.state.Role}
                />
                <div className="header-content exit-wrapper">
                    <div>
                        <label>{this.getInvestmentTitle()}</label>
                        <h5>
                            {this.state.CompanyInvestmentData && this.state.showExitInProcess &&
                                this.state.CompanyInvestmentData.exitProcessType === 'ipo' ? 'IPO' :
                                this.state.CompanyInvestmentData && this.state.showExitInProcess && this.state.CompanyInvestmentData.exitProcessType === 'acquisitionwithescrow' ? "Acquisition With Escrow" :
                                    this.state.CompanyInvestmentData && this.state.showExitInProcess && this.state.CompanyInvestmentData.exitProcessType === 'acquisition' ? 'Acquisition' :
                                    this.state.CompanyInvestmentData && this.state.showExitInProcess && this.state.CompanyInvestmentData.exitProcessType === 'acquisitionwithtwoescrow' ? 'Acquisition With Two Escrow' : ''}
                        </h5>
                    </div>
                    <div>
                        {/* {this.state.CompanyInvestmentData && this.state.CompanyInvestmentData.stage === "closed" ?
                            <TButton text='VIP ACCESS' className='invite-btn marginleft' onClick={() => this.props.history.push(`/investmentVIPAccess?InvestmentID=${this.id}`)} />
                            : ''} */}
                        {/* <TButton text='SUMMARY' className='invite-btn marginleft' onClick={() => this.props.history.push(`/investmentVIPAccess?InvestmentID=${this.id}`)} /> */}
                        {/* <TButton text='LEDGER' className='invite-btn marginleft' onClick={() => this.props.history.push(`/investmentVIPAccess?InvestmentID=${this.id}`)} /> */}
                    </div>
                </div>
                {/* <div className='exit-wrapper'>
                    <div className="row">
                        <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                            <div className="header-detail-card">
                                <label>Total Remainder</label>
                                <h4>$800,000</h4>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                            <div className="header-detail-card">
                                <label>Projected Remainder</label>
                                <h4>$200,000</h4>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                            <div className="header-detail-card">
                                <label>Total Funded</label>
                                <h4>$4,200,000</h4>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                            <div className="header-detail-card">
                                <label>Total Subscribed</label>
                                <h4>$6,000,000</h4>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                            <div className="header-detail-card">
                                <label>Total Allocation</label>
                                <h4>$5,000,000</h4>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                            <div className="header-detail-card">
                                <label>Average Investment</label>
                                <h4>$5,000</h4>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className='contact-header end exit-wrapper navresponsive'>
                    <nav className="contact-navbar ">
                        <button className={`navbtn ${this.state.showExitInProcess ? 'active' : ''}`} style={{ width: 130 }} onClick={() => this.tabSelection('exitInProcess')}>EXIT IN PROCESS</button>
                        <button className={`navbtn ${this.state.showExited ? 'active' : ''}`} style={{ width: 80 }} onClick={() => this.tabSelection('exited')}>EXITED</button>
                    </nav>
                </div>
                {
                    this.state.showExitInProcess &&
                    <div className="exit-wrapper exit-in-process">
                        <div className='header-navbar-box'>
                            <div className='exit-header'>
                                <nav className="exit-navbar ">
                                    <button className={`exit-navbtn ${this.state.showCommunication ? 'active' : ''}`} onClick={() => this.ExitTabSelection('communication')}>COMMUNICATION</button>
                                    <button className={`exit-navbtn ${this.state.showDisbursment ? 'active' : ''}`} style={{ width: 180 }} onClick={() => this.ExitTabSelection('disbursment')}>{this.state.CompanyInvestmentData && this.state.CompanyInvestmentData.exitProcessType === 'acquisitionwithescrow' ? 'INTIAL DISBURSEMENT' : 'DISBURSEMENT'}</button>
                                    {this.state.CompanyInvestmentData && (this.state.CompanyInvestmentData.exitProcessType === 'acquisitionwithescrow' || this.state.CompanyInvestmentData.exitProcessType === "acquisitionwithtwoescrow") ? <button className={`exit-navbtn ${this.state.showEscrow ? 'active' : ''}`} style={{ width: 190 }} onClick={() => this.ExitTabSelection('escrow')}>ESCROW DISBURSEMENT</button> : ""}
                                    {this.state.CompanyInvestmentData && this.state.CompanyInvestmentData.exitProcessType === 'acquisitionwithtwoescrow' ? <button className={`exit-navbtn ${this.state.showSecondEscrow ? 'active' : ''}`} style={{ width: 250 }} onClick={() => this.ExitTabSelection('secondEscrow')}>SECOND ESCROW DISBURSEMENT</button> : ""}
                                    <button className={`exit-navbtn ${this.state.showDisbursmentUsers ? 'active' : ''}`} style={{ width: 180 }} onClick={() => this.ExitTabSelection('disbursmentUsers')}>DISBURSEMENTS</button>
                                </nav>
                            </div>
                        </div>
                        {
                            this.state.showCommunication &&
                            <ExitProcessCommunication
                                id={this.companyId}
                                exitInProcessUsers={this.makeArrayUnique(this.state.exitInProcessUsers)}
                                getExitInProcessUsers={(isFromLoadMore, tags, sort, filterType, investmentFilterId) => this.getExitInProcessUsers(isFromLoadMore, tags, sort, filterType, investmentFilterId)}
                                getCompanyInvestments={() => this.getCompanyInvestments()}
                                showLoading={(loading) => this.showLoadingHandler(loading)}
                                investmentData={this.state.CompanyInvestmentData}
                                companyInvestmentsData={this.state.companyInvestments}
                                showDataInPopup={(id) => this.setState(this.viewContactUserDetailsChild.setContactDetailsData(id, null), this.openContactDetailModal())}
                                {...this.props} />
                            }
                        {this.state.showDisbursment &&
                            <ExitProcessInitialDisbursement
                            id={this.companyId}
                            exitInProcessDisbursemenUsers={this.makeArrayUnique(this.state.exitInProcessDisbursemenUsers)}
                            getExitProcessInitialDisbursementUsers={(isFromLoadMore, tags, sort, investmentFilterId) => this.getExitProcessInitialDisbursementUsers(isFromLoadMore, tags, sort, investmentFilterId)}
                            showLoading={(loading) => this.showLoadingHandler(loading)}
                            investmentData={this.state.CompanyInvestmentData}
                            companyInvestmentsData={this.state.companyInvestments}
                            showDataInPopup={(id) => this.setState(this.viewContactUserDetailsChild.setContactDetailsData(id,null), this.openContactDetailModal())}
                                {...this.props} />
                        }
                        {
                            this.state.showEscrow &&
                            <ExitProcessEscrow
                                id={this.companyId}
                                exitInProcessEscrowUsers={this.makeArrayUnique(this.state.exitInProcessEscrowUsers)}
                                getExitProcessEscrowUsers={(isFromLoadMore, tags, sort, investmentFilterId) => this.getExitProcessEscrowUsers(isFromLoadMore, tags, sort, investmentFilterId)}
                                showLoading={(loading) => this.showLoadingHandler(loading)}
                                investmentData={this.state.CompanyInvestmentData}
                                companyInvestmentsData={this.state.companyInvestments}
                                showDataInPopup={(id) => this.setState(this.viewContactUserDetailsChild.setContactDetailsData(id,null), this.openContactDetailModal())}
                                {...this.props} />
                        }
                        {
                            this.state.showSecondEscrow &&
                            <ExitProcessSecondEscrow
                                id={this.companyId}
                                exitInProcessEscrowUsers={this.makeArrayUnique(this.state.exitInProcessSecondEscrowUsers)}
                                getExitProcessSecondEscrowUsers={(isFromLoadMore, tags, sort, investmentFilterId) => this.getExitProcessSecondEscrowUsers(isFromLoadMore, tags, sort, investmentFilterId)}
                                showLoading={(loading) => this.showLoadingHandler(loading)}
                                investmentData={this.state.CompanyInvestmentData}
                                companyInvestmentsData={this.state.companyInvestments}
                                showDataInPopup={(id) => this.setState(this.viewContactUserDetailsChild.setContactDetailsData(id,null), this.openContactDetailModal())}
                                {...this.props} />
                        }
                        {
                            this.state.showDisbursmentUsers &&
                            <DisbursmentUsers
                                id={this.companyId}
                                isEscrow={this.state.CompanyInvestmentData && this.state.CompanyInvestmentData.exitProcessType === 'acquisitionwithescrow'}
                                isSecondEscrow={this.state.CompanyInvestmentData && this.state.CompanyInvestmentData.exitProcessType === 'acquisitionwithtwoescrow'}
                                disbursmentUsers={this.makeArrayUnique(this.state.exitInProcessCompleteDisbursmentUsers)}
                                getDisbursementUsers={(isFromLoadMore, tags, sort, investmentFilterId) => this.getDisbursmentUsers(isFromLoadMore, tags, sort, investmentFilterId)}
                                showLoading={(loading) => this.showLoadingHandler(loading)}
                                investmentData={this.state.CompanyInvestmentData}
                                companyInvestmentsData={this.state.companyInvestments}
                                showDataInPopup={(id) => this.setState(this.viewContactUserDetailsChild.setContactDetailsData(id,null), this.openContactDetailModal())}
                                {...this.props} />
                        }
                    </div>
                }
                <div className="exit-wrapper exit-in-process">
                    {
                        this.state.showExited &&
                        <div className="row">
                            <div className="col-sm-12">
                                <ExitedUsers
                                    id={this.companyId}
                                    exitedUsers={this.makeArrayUnique(this.state.exitedUsers)}
                                    pagination={this.state.exitedUsersPagination}
                                    getExitedUsers={(isFromLoadMore, tags, sort) => this.getExitedUsers(isFromLoadMore, tags, sort)}
                                    isAllowExitedUsersChecked={this.state.isAllowExitedUsersChecked}
                                    getCheckedExitedUsers={() => this.getCheckedExitedUsers()}
                                    onCheckedSingleExitedUser={(event, id) => this.onCheckedSingleExitedUser(event, id)}
                                    onCheckedAllExitedUsers={(event) => this.onCheckedAllExitedUsers(event)}
                                    showLoading={(loading) => this.showLoadingHandler(loading)}
                                    sendSingleUserMessage={(array, type) => this.sendSingleUserMessage(array, type)}
                                    sendMessageMultipleUsers={(message, type) => this.sendMessageMultipleUsers(message, type)}
                                    companyInvestmentsData={this.state.companyInvestments}
                                    {...this.props}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div >
        );
    }

}

export default StartupDetail;
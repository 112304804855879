import React, { Component } from 'react';
import './InvestmentDetail.scss';
import ExitProcessCommunication from './ExitProcessCommunication';
import ExitProcessEscrow from './ExitProcessEscrow';
import ExitProcessInitialDisbursement from './ExitProcessInitialDisbursement';

class ExitedInProcess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCommunication: false,
            showDisbursment: false,
            showEscrow: false,
        }
    }
    componentDidMount() {
        if (this.props.id) {
            this.props.history.push(`/investmentDetail?id=${this.props.id}&activeTab=exitInProcess`)
        }
        window.addEventListener('scroll', this.handleScroll, false);
        this.setState({ showCommunication: true });
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
    }
    handleScroll = () => {
        if ((Math.round(window.innerHeight + document.documentElement.scrollTop) === document.documentElement.offsetHeight)
            && (this.props.pagination.currentPage < this.props.pagination.totalPages)) {
            this.props.getExitInProcessUsers(true);
        }
    };
    tabSelection(tabValue) {
        switch (tabValue) {
            case 'communication':
                this.props.getExitInProcessUsers(false);
                this.setState({ showCommunication: true, showDisbursment: false, showEscrow: false });
                break;
            case 'disbursment':
                this.props.getExitProcessInitialDisbursementUsers(false);
                this.setState({ showCommunication: false, showDisbursment: true, showEscrow: false });
                break;
            case 'escrow':
                this.props.getExitProcessEscrowUsers(false);
                this.setState({ showCommunication: false, showDisbursment: false, showEscrow: true });
                break;
            default:
                this.setState({ showCommunication: true, showDisbursment: false, showEscrow: false });
                break;
        }
    }

    render() {
        return (
            <div>

                <div className='header-investment exit-in-process'>
                    <div className='contact-header'>
                        <nav className="contact-navbar ">
                            <button className={`navbtn ${this.state.showCommunication ? 'active' : ''}`} onClick={() => this.tabSelection('communication')}>COMMUNICATION</button>
                            <button className={`navbtn ${this.state.showDisbursment ? 'active' : ''}`} style={{ width: 150 }} onClick={() => this.tabSelection('disbursment')}>{this.props.investmentData && this.props.investmentData.exitProcessType === 'acquisitionwithescrow' ? 'INTIAL DISBURSEMENT' : 'DISBURSEMENT'}</button>
                            {this.props.investmentData && this.props.investmentData.exitProcessType === 'acquisitionwithescrow' ? <button className={`navbtn ${this.state.showEscrow ? 'active' : ''}`} style={{ width: 160 }} onClick={() => this.tabSelection('escrow')}>ESCROW DISBURSEMENT</button> : ""}
                        </nav>
                    </div>

                    {
                        this.state.showCommunication &&
                        <ExitProcessCommunication {...this.props} />
                    }
                    {this.state.showDisbursment &&
                        <ExitProcessInitialDisbursement {...this.props} />
                    }
                    {
                        this.state.showEscrow &&
                        <ExitProcessEscrow {...this.props} />
                    }

                </div>
            </div>
        );
    }

}

export default ExitedInProcess;
import React, { Component } from "react";
import Progress from 'react-progressbar';
import images from "../../images";
import _ from 'lodash';
import swal from 'sweetalert';
import common from '../../common';
import axios from 'axios';

import './InvestmentCard.scss';
import UploadedFilesCard from '../../components/UploadedFilesCard';


class InvestmentCard extends Component {


  handleClick(event) {
    this.props.toggleShowMenuForInvestmentsCard();
    event.preventDefault();
  }
  getExecutiveLetterStatus() {
    if (this.props.singleInvestment && this.props.singleInvestment.iir
      && this.props.singleInvestment.iir.length > 0) {
      const iirObject = this.props.singleInvestment.iir.filter(item => item.investmentId === this.props.id)[0];
      if (iirObject && iirObject['Founder Letter ']) {
        return true;
      }
      return false;
    }
    return false;

  }

  getMatchedMasterChecklist = (masterChecklistId) => {
    return this.props.masterChecklist.find(singleMasterChecklist => singleMasterChecklist._id === masterChecklistId);
  }

  getCheckListSateCount(type) {
    if (this.props.singleInvestment && this.props.singleInvestment && this.props.singleInvestment.checklist
      && this.props.singleInvestment.checklist.length > 0) {
      const checklistData = this.props.singleInvestment.checklist.filter(list => list.investmentId === this.props.id);

      if (this.props.singleInvestment && (this.props.singleInvestment.isGlobalCheckListApplicable || type === common.checklistTypes.close || type === common.checklistTypes.exit)) {
        let masterCheckListIds = [];
        if (type === common.checklistTypes.exit) {
          const { exitProcessType } = this.props.singleInvestment;
          masterCheckListIds = this.props.masterChecklist.filter(i => {
            if (i.type === type && i.subType === exitProcessType && i.investmentId === this.props.id) {
              return i._id;
            }
          });
          if (masterCheckListIds.length === 0) {
            masterCheckListIds = this.props.masterChecklist.filter(i => {
              if (i.type === type && i.subType === exitProcessType && !i.investmentId) {
                return i._id;
              }
            });
          }

        } else {
          masterCheckListIds = this.props.masterChecklist.filter(i => {
            if (i.type === type) {
              return i._id;
            }
          });
        }

        masterCheckListIds = masterCheckListIds.map(i => i._id);
        return checklistData.filter((item) => {
          if (masterCheckListIds.indexOf(item.masterCheckListId) !== -1 && item.type === type && item.status === true) {
            return item;
          }
        }).length;
        // return checklistData.filter((v) => v.status && v.type === type && this.getMatchedMasterChecklist(v.masterCheckListId)).length;
      }
      return checklistData.filter(v => v.status).length;
    }
    return 0;
  }
  getSateCount() {
    if (this.props.singleInvestment && this.props.singleInvestment.iir
      && this.props.singleInvestment.iir.length > 0) {
      const iirObject = this.props.singleInvestment.iir.filter(item => item.investmentId === this.props.id)[0];
      let sampleArray = {};
      sampleArray.Company = iirObject.Company;
      sampleArray.Leadership = iirObject.Leadership;
      sampleArray.Board = iirObject.Board;
      sampleArray['Market Performance and Summary'] = iirObject['Market Performance and Summary'];
      sampleArray['Investment Overview'] = iirObject['Investment Overview'];
      sampleArray['Product Statement'] = iirObject['Product Statement'];
      sampleArray['Solution Summary'] = iirObject['Solution Summary'];
      sampleArray.Differentiation = iirObject.Differentiation;
      sampleArray['Current Partners'] = iirObject['Current Partners'];
      sampleArray.Product = iirObject.Product;
      sampleArray['Major Investor'] = iirObject['Major Investor'];
      sampleArray['Funding'] = iirObject['Funding'];
      sampleArray['Historical and Projected Revenue'] = iirObject['Historical and Projected Revenue'];
      sampleArray['Key Customers'] = iirObject['Key Customers'];
      // sampleArray['Press'] = iirObject['Press'];
      sampleArray['Founder Letter '] = iirObject['Founder Letter '];
      sampleArray['isApproved'] = iirObject['isApproved'];
      if (sampleArray) {
        const keys = Object.keys(sampleArray)
        const values = Object.values(sampleArray)
        let z = [];
        let executiveLetter = [];
        keys.forEach((key, i) => {
          z.push({ title: key, state: values[i] })
        })
        z.forEach((ele, i) => {
          let title = ele.title.split(" ");
          let path = '';
          for (const word of title) {
            path += word;
          }
          path = path.substring(0, 1).toLowerCase() + path.substring(1);
          ele.path = path;
          if (ele.path === 'founderLetter') {
            executiveLetter.push(ele);
          }
        })
        const iirList = z.filter((iir) => iir.path !== 'founderLetter' && iir.path !== '_id');
        let stateCount = iirList && iirList.length > 0 ? iirList.filter(v => v.state).length : 0;
        return stateCount;
      }
    }
    return 0;
  }

  isPDFUploaded() {
    if (this.props.singleInvestment && this.props.singleInvestment.iir
      && this.props.singleInvestment.iir.length > 0) {
      const iirObject = this.props.singleInvestment.iir.filter(item => item.investmentId === this.props.id)[0];
      if (iirObject && iirObject.iirPDF) {
        return true;
      }
    }
    return false;

  }

  renderUploadedCards() {
    let cards = null;
    cards = this.props.filesListViaCompanyId.map((file, i) => {
      return (<div className="file-link" key={i}>
        <UploadedFilesCard
          onCardClick={() => { }}
          file={file}
          onActionClick={() => this.removeFile(file)}
        />
      </div>);
    })
    return cards;
  }
  removeFile(file) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        const token = localStorage.getItem('token');
        if (willDelete) {
          //@TODO CALL DELETE CONTACT API HERE WITH NECESSARY CHECKS
          this.setState({ loading: true })
          axios.delete(`${common.path.base_url}/deleteFile`, { headers: { "Authorization": token }, data: { id: file._id } }
          )
            //@TODO CALL ON SUCCESS OF API
            .then((response) => {
              this.setState({ loading: false });
              swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
                button: "Ok!",
              })
                .then(() => {
                  this.getSateCount();
                  this.getCheckListSateCount();
                  this.renderUploadedCards();
                });
            })
            .catch((error) => {
              this.setState({ loading: false });
              if ( error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                window.localStorage.clear();
                window.location.href = '/';
                return;
              }
              else {
                swal({
                  title: "Remove File Error!",
                  text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.response,
                  icon: "error",
                  button: "Ok!",
                });
              }
            });
        }
        else {
          swal({ text: `${file.documentName} file is safe!`, icon: 'info' });
        }
      });
  }

  getAcceptedCount() {
    let count = 0;
    if (this.props.investorList && this.props.investorList.length > 0) {
      let investors = this.props.investorList;
      investors.forEach((investor) => investor.invitationAccepted === '1' ? count += 1 : count);
    }
    return count;
  }

  getMasterChecklistLengthByType = (type) => {
    let finalMasterChecklist = [];
    if (type === common.checklistTypes.exit && this.props.singleInvestment) {
      const { exitProcessType } = this.props.singleInvestment;
      finalMasterChecklist = this.props.masterChecklist.filter(singleMasterChecklist => {
        if (singleMasterChecklist.type === type && singleMasterChecklist.subType === exitProcessType && singleMasterChecklist.investmentId === this.props.id) {
          return singleMasterChecklist;
        }
      });
      if (finalMasterChecklist.length === 0) {
        finalMasterChecklist = this.props.masterChecklist.filter(singleMasterChecklist => {
          if (singleMasterChecklist.type === type && singleMasterChecklist.subType === exitProcessType && !singleMasterChecklist.investmentId) {
            return singleMasterChecklist;
          }
        });

      }

    } else {
      finalMasterChecklist = this.props.masterChecklist.filter(singleMasterChecklist => singleMasterChecklist.type === type);
    }

    return finalMasterChecklist && finalMasterChecklist.length > 0 ? finalMasterChecklist.length : 1;
  }

  getOnBoardChecklistStatus = (type) => {
    if (this.props.singleInvestment && (this.props.singleInvestment.isGlobalCheckListApplicable || type === common.checklistTypes.close || type === common.checklistTypes.exit)) {
      const percentage = ((this.getCheckListSateCount(type) * 100) / this.getMasterChecklistLengthByType(type)).toFixed();
      return percentage > 100 ? 100 : percentage;
    }
    const percentage = ((this.getCheckListSateCount() / 21) * 100).toFixed();
    return percentage > 100 ? 100 : percentage;
  };

  getOnBoardChecklistProgress = (type) => {
    if (this.props.singleInvestment && (this.props.singleInvestment.isGlobalCheckListApplicable || type === common.checklistTypes.close || type === common.checklistTypes.exit)) {
      const percentage = ((this.getCheckListSateCount(type) * 100) / this.getMasterChecklistLengthByType(type)).toFixed()
      return percentage > 100 ? 100 : percentage;
    }
    const percentage = 4.76 * this.getCheckListSateCount();
    return percentage > 100 ? 100 : percentage;
  };

  render() {
    return (
      <div className="investment-card">
        <div className="card-header-section">
          <div className="card-header-txt">
            {this.props.companyNickName === undefined || this.props.companyNickName === "" ? this.props.companyName : this.props.companyNickName}
          </div>
          <div className={`card-menu ${this.props.singleInvestment.showMenu ? 'show-menu' : ''}`}>
            <div className='three-dots-main'>
              <a href="#" id="three-dots" onClick={(event) => { this.handleClick(event) }}>
                <img src={images.path.kebabMenuIcon} className="menu-icon" alt="Menu Icon"></img>
                <ul className={`dropdown-menu2`}>
                  <li className="resend" onClick={() => this.props.onEditInvestmentClick() && this.props.onEditInvestmentClick()}>Update Investment</li>
                </ul>
              </a>
            </div>
          </div>
        </div>
        <div className="card-header-txt invsestment-name">
          <a href="javascript:;" onClick={() => this.props.history.push(`/investmentDetail?id=${this.props.id}`)}>{this.props.investmentName}</a>
        </div>
        <div className="card-count-section">
          <div className="count-block">
            <div className="count-value">{this.props.invitationSentCount}</div>
            <div className="count-txt">{`Invites Sent`}</div>
          </div>
          <div className="count-block">
            <div className="count-value">{this.props.inviteAcceptedCount}</div>
            <div className="count-txt">{`Invites Accepted`}</div>
          </div>
        </div>
        <div className="count-block stage-wraper">
          <div className="count-txt">Stage</div>
          {
            <div className="stage">&nbsp;{this.props.stage.toUpperCase() === 'INVITE' ? 'PIPELINE' : this.props.stage.toUpperCase()}</div>
          }
        </div>
        <div className='progressBarSection custom-progressbar'>
          <div className='onBoardStepSection'>
            <div className='onBoardStepSectionLabels'>
              <div className="left-section">
                {/* Onboarding Step Progress */}
                <a href="javascript:;" onClick={() => this.props.history.push(`/onboardChecklist?companyId=${this.props.singleInvestment.companyId}&investmentId=${this.props.id}`)}> Investment Checklist </a>
              </div>
              <div className="right-section">
                {
                  `${this.getOnBoardChecklistStatus(common.checklistTypes.open)}% Completed`
                }
              </div>
            </div>
            <div className='progressBar'>
              <Progress className='subProgressBar' completed={this.getOnBoardChecklistProgress(common.checklistTypes.open)} />

            </div>
          </div>

          <div className='onBoardStepSection onboard-executiveprogress'>
            <div className='onBoardStepSectionLabels'>
              <div className="left-section">
                {/* Onboarding Step Progress */}
                <a href="javascript:;" onClick={() => this.props.history.push(`/executiveLetter?companyId=${this.props.singleInvestment.companyId}&investmentId=${this.props.id}`)}> Executive Letter </a>
              </div>
              <div className="right-section">
                {
                  (this.getExecutiveLetterStatus() || this.props.stage === 'closed') ?
                    `100% Completed` :
                    `0% Completed`
                }
              </div>
            </div>
            <div className='progressBar'>
              {this.props.stage === 'closed' ?
                <Progress className='subProgressBar' completed={100} /> :
                <Progress className='subProgressBar' completed={this.getExecutiveLetterStatus() ? 100 : 0} />
              }
            </div>
          </div>

          <div className='docUploadSection'>
            <div className='docUploadSectionLabels'>
              <div className="left-section">
                {/* Document Upload Progress */}
                <a href="javascript:;" onClick={() => this.props.history.push(`/iir/company?companyId=${this.props.singleInvestment.companyId}&investmentId=${this.props.id}`)}> Investment Intelligence Report </a>
              </div>
              <div className="right-section">
                {(this.props.stage === 'closed' || this.isPDFUploaded()) ?
                  '100% Completed' :
                  `${((this.getSateCount() / 14) * 100).toFixed()}% Completed`
                }
              </div>
            </div>
            <div className='progressBar'>
              {
                (this.props.stage === 'closed' || this.isPDFUploaded()) ?
                  <Progress className='subProgressBar' completed={100} /> :
                  <Progress className='subProgressBar' completed={7.2 * this.getSateCount()} />
              }
            </div>
          </div>


          <div className='onBoardStepSection'>
            <div className='onBoardStepSectionLabels'>
              <div className="left-section">
                {/* Onboarding Step Progress */}
                {
                  this.props.singleInvestment
                    && this.props.singleInvestment.stage === "closed"
                    ? <a
                      href="javascript:;"
                      onClick={() => this.props.history.push(`/onboardChecklist?companyId=${this.props.id}&investmentId=${this.props.singleInvestment._id}&type=${common.checklistTypes.close}`)}>
                      Post Close Investment Checklist
                      </a>
                    : <span style={{ color: '#d3d3d3' }}>Post Close Investment Checklist</span>
                }
              </div>
              <div className="right-section">
                {
                  this.props.singleInvestment
                    && this.props.singleInvestment.stage === "closed"
                    ? `${this.getOnBoardChecklistStatus(common.checklistTypes.close)}% Completed`
                    : `${0}% Completed`
                }
              </div>
            </div>
            <div className='progressBar'>
              {
                this.props.singleInvestment
                  && this.props.singleInvestment.stage === "closed"
                  ? <Progress className='subProgressBar' completed={this.getOnBoardChecklistProgress(common.checklistTypes.close)} />
                  : <Progress className='subProgressBar' completed={0} />
              }
            </div>
          </div>

          <div className='onBoardStepSection'>
            <div className='onBoardStepSectionLabels'>
              <div className="left-section">
                {/* Onboarding Step Progress */}
                {
                  this.props.singleInvestment
                    && this.props.singleInvestment.stage === "exited"
                    ? <a
                      href="javascript:;"
                      onClick={() => this.props.history.push(`/onboardChecklist?companyId=${this.props.id}&investmentId=${this.props.singleInvestment._id}&type=${common.checklistTypes.exit}`)}>
                      Exit Investment Checklist
                      </a>
                    : <span style={{ color: '#d3d3d3' }}>Exit Investment Checklist</span>
                }
              </div>
              <div className="right-section">
                {
                  this.props.singleInvestment
                    && this.props.singleInvestment.stage === "exited"
                    ? `${this.getOnBoardChecklistStatus(common.checklistTypes.exit)}% Completed`
                    : `${0}% Completed`
                }
              </div>
            </div>
            <div className='progressBar'>
              {
                this.props.singleInvestment
                  && this.props.singleInvestment.stage === "exited"
                  ? <Progress className='subProgressBar' completed={this.getOnBoardChecklistProgress(common.checklistTypes.exit)} />
                  : <Progress className='subProgressBar' completed={0} />
              }
            </div>
          </div>
        </div>
        {/* ---File Listing Section START--- */}
        <div className="card-header-txt">Uploaded Documents</div>
        <div className="file-listing">
          {!_.isEmpty(this.props.filesListViaCompanyId) ?
            (
              <div className="upload-contact-list">
                {this.renderUploadedCards()}
              </div>
            ) :
            (<div className="links-block no-list">
              <div className="txt">No Files to Show</div>
            </div>)}

        </div>
        {/* ---File Listing Section END--- */}
      </div>
    );
  }
}

export default InvestmentCard;
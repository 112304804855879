import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import axios from 'axios';
import common from '../../common';
import swal from 'sweetalert';
import './ledger.scss'; 
import _ from 'lodash';
import LedgerList from './LedgerList';

const Ledger = props => { 

    const [getLedgerList, setLedgerList ] = useState([]);
    const [getLedgerListEx, setLedgerListEx ] = useState([]);
    const [getInvestment, setInvestment ] = useState([]);
    const [tsgSetting, setTsgSetting ] = useState([]);
    const [tsgLoader, setTsgLoader ] = useState(false);
    
    useEffect(() => {
        
        const url = new URL(window.location.href);
        const id = url.searchParams.get('id');
        window.localStorage.setItem('summaryInvestmentId', id);
        props.history.push(`/investmentDetail?id=${id}&activeTab=ledger`);
        
        getSummaryList();
    }, [])
    
    function getSummaryList(tags =[]) {
        
        const url = new URL(window.location.href);
        const investmentId = url.searchParams.get('id');

        //console.log(investmentId);

        let tokenpass = window.localStorage.getItem('token');

        let newTags = [];
        let fillterOn = 0;
        if (tags && tags.length > 0) {
            newTags = tags.map(item => item.name);
            fillterOn = 1;
        }

        const data = {
            investmentId: investmentId,
            newTags : newTags,
        }

        setTsgLoader(true);
        axios.post(`${common.path.base_url}/ledger/getInvestmentLedgerList`, data, {
                headers: {
                    "Authorization": tokenpass
                }
            })
                .then((response) => {
                    if( response.data.status == 'success' ) {
                        
                        const data = response.data.data;
                        setLedgerList(data.investmentProcess);
                        setLedgerListEx(data.investmentProcessData);
                        setInvestment(data.investment);
                        setTsgSetting(data.setting);
                        setTsgLoader(false);
                    } else {
                        setTsgLoader(false);
                        swal({
                            title: "Error!",
                            text: response.data.message ? response.data.message : 'Something Went Wrong',
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                })
                .catch(function (error) {
                    setTsgLoader(false);
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                });    

    }

    return (
        <>
            {
                <div className='ledger-content'>
                    {/* <div className='ledger-header'>
                        <span className='ledger-head-text'>
                            Ledger - { (getInvestment) ? getInvestment.name : "" }
                        </span>
                    </div> */}
                    <div className='ledger-body'>
                        <LedgerList  
                            getLedgerList = { getLedgerList } 
                            tsgSetting = { tsgSetting }
                            investment = { getInvestment }
                            getSummaryList = { (tags) =>  getSummaryList(tags) }
                            tsgLoader = { tsgLoader }
                            getLedgerListEx = { getLedgerListEx }
                        />
                    </div>
                </div>
            }
        </>
    );
}

export default Ledger;
import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash';
import axios from 'axios';
import Select from 'react-select';
import { Multiselect } from "multiselect-react-dropdown";
import images from "../../images";
import common from '../../common';
import './pipelineCss/pipeline.scss';
import './pipelineCss/default.scss';
import { Icons } from './Icons';
import ReadMore from '../../components/ReadMore/ReadMore';
import LastRoundLister from './LastRoundLister';
import PriorityTag from './PriorityTag';


const PipelineList = props => {
    
    var linkdinUrl = 'https://www.linkedin.com/';
    var cbUrl = 'https://www.crunchbase.com/';

    const selectMultiRef = useRef(null);

    const [getLoader, setLoader] = useState(false);
    const [listData, setListData] = useState([]);
    const [getSearch, setSearch] = useState("");
    const [optionType, setOptionType] = useState([]);
    const [filterSelect, setFilterSelect ] = useState(false);
    const [filterSelectArr, setFilterSelectArr ] = useState([]);
    const [totalInvestors, setTotalInvestors ] = useState(0);
    
    //const ref = useRef<SelectInstance>(null);

    useEffect(() => {
        getPipelineStartData();
        setOptionType([
            {
                value:'1',
                label: 'IT'
            },
            {
                value: '2',
                label: 'Automotive'
            },
            {
                value: '3',
                label: 'Manufactoring'
            }
        ])
    }, [])

    // setArrCompanyType([
    //     { 'id' : 1, 'value':'IT' },
    //     { 'id' : 2, 'value':'Automotive' },
    //     { 'id' : 3, 'value':'Manufactoring' },
    // ])

    useEffect(() => {
        if(getSearch || filterSelectArr.length > 0) {
            const timer = setTimeout(() => {
                getPipelineStartData(getSearch, filterSelectArr);
                //console.log('This will run after 1 second!')
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [getSearch, filterSelectArr])
    
    async function getPipelineStartData(search = '', filterArr = []) {
        try {
          setLoader(true);
          const tokenpass = window.localStorage.getItem('token');
          let query = '';
    
          if (search !== '' && filterArr.length > 0) {
            query = `search=${search}&companyType=${JSON.stringify(filterArr)}`;
          } else if (filterArr.length > 0) {
            query = `companyType=${JSON.stringify(filterArr)}`;
          } else if (search !== '') {
            query = `search=${search}`;
          }
    
          const response = await axios.get(`${common.path.base_url}/getPipelineStartupsList?${query}`, {
            headers: {
                Authorization: tokenpass,
            },
          });
          setLoader(false);
          if (response.data.status === 'success') {
            const alllistdata = response.data.data.result;
            const totalInvestors = response.data.data.totalInvestors;
            setListData([...alllistdata]);
            setTotalInvestors(totalInvestors);
          }
        } catch (error) {
          console.error('API Error:', error);
          setLoader(false);
        }
      }
    
    const searchCompany = (e) => {
        const textCode = e.target.value;
        setSearch(textCode);
    }

    const addCompany = (e) => {
        window.location = "/pipeline/add";
    }

    const updateCompany = (id) => {
        window.location = `/pipeline/add/${id}`;
    }

    const onSelectCall = (arrObj) => { 
        if(arrObj != null && arrObj.length > 0) {
            setFilterSelectArr([...arrObj]);
        } else {
            arrObj = [];
            setFilterSelectArr([...arrObj]);
        }
    }
    const onRemoveCall = (arrObj) => {
        setFilterSelectArr([...arrObj]);
    }

    const updatePipelineStartupStatus = (id, status, type)  => {
        
        let tokenpass = window.localStorage.getItem('token');
        const data = { status, type };
      
        axios.put(`${common.path.base_url}/updatePipelineStartupStatus/${id}`, data, {
            headers: {
                Authorization: tokenpass,
            },
          })
          .then((resp) => {
            if (resp.data.status === 'success') {
              console.log("Updated status response:", resp.data);
              const updatedListData = listData.map((item) => {
                if (item._id === id) {
                  const updatedItem = {
                    ...item,
                    isPipelineStartupActive: status,
                  };
                  console.log("Updated item:", updatedItem);
                  return updatedItem;
                }
                return item;
              });
              console.log("Updated listData:", updatedListData);
              setListData(updatedListData);
            }
        })
        .catch((error) => {
          console.error("API Error:", error);
        });
    };

    // document.addEventListener("click", (evt) => {
    //     console.log(evt.target.id);
    //     if(evt.target.id != 'filterCall' && evt.target.id != 'filter') {
    //         if(filterSelect) {
    //             setFilterSelect(false);
    //         }
    //     }
    // });

    return (
        <>
            {
                <div className='containerDiv mt-60'>
                    <div className='actionCont'>
                        <div className='searchCont'>
                            <div className='searchDiv'>
                                <input type="text" name="" id="" className='searchInpt' placeholder='Search' onChange={ (e) => searchCompany(e) } />
                            </div>
                            <div className='searchiconCont'>{Icons.Search}</div>
                        </div>
                        <div className='multipleCode'>
                            <div id="filterCall" className='filterCont ml-10' onClick={() => setFilterSelect(!filterSelect ) } >
                                { filterSelectArr.length > 0 &&
                                    <span className='redDot'><i className="fa fa-circle" aria-hidden="true"></i></span>
                                }
                                {Icons.Filter}
                            </div>
                            { ( filterSelect ) &&
                            <div className='multipleselectBox'>
                                {/* <Multiselect
                                    displayValue="value"
                                    hideSelectedList
                                    avoidHighlightFirstOption = { true }
                                    closeOnSelect = { false }
                                    selectedValues={ filterSelectArr }
                                    onSelect={(e) => onSelectCall(e) } // Function will trigger on select event
                                    onRemove={(e) => onRemoveCall(e) }
                                    options={  optionType }
                                    showCheckbox
                                /> */}
                                <Select
                                    isMulti
                                    menuIsOpen = { true }
                                    name="value"
                                    options={ optionType }
                                    onChange={ (e) => onSelectCall(e) }
                                    defaultValue={filterSelectArr}
                                    id="selectObjFilter"
                                />
                            </div>
                            }
                        </div>
                        <div>
                            <button className='addCompBTN ml-20' onClick={ (e) => addCompany(e) } >Add Company</button>
                        </div>
                    </div>

                    <div className='compCont'>
                        { (listData.length > 0 && !getLoader ) ? 
                            listData.map((singleData, index) => {  
                                return <div className='compitemCont' key={ 'pipeline'+index }>
                                        <div>
                                        { (singleData.companyProfileImage && singleData.companyProfileImage != '' ) ? 
                                            <img src={ singleData.companyProfileImage } alt="" className='compLogo'/>
                                        : (singleData.profileImg != "" && singleData.profileImg != null && singleData.profileImg != 'undefined' ) ? 
                                            <img src={`${common.path.base_url}/getImage?type=company&id=${singleData._id}`} alt="" className='compLogo' />
                                            :
                                            <img src={ images.path.placeholder } alt="" className='compLogo' />
                                        }   
                                        </div>

                                        <div className='ml-25 descDiv'>
                                            <div className='compHead'>
                                                <div className='leftCompHead'>
                                                    <div className='compName' onClick={ () => updateCompany(singleData._id) } > { singleData.companyName }</div>
                                                    <div className='ml-15'><PriorityTag priority={singleData.priorityType} /></div>
                                                </div>
                                                <div className='rightCompHead'>
                                                    <div>Strong Sentiments: </div>
                                                    <div>
                                                        {
                                                            singleData.strongSentiments.length > 0 ?
                                                            <div className='sentimentsPer' >{parseFloat((( singleData.firstLine + singleData.participate) * 100) / ( singleData.pipelineStartupinterests.length )).toFixed(2)}%</div> :
                                                            <div className='sentimentsPer'>0%</div>
                                                        }
                                                    </div>
                                                    <label className="switch">
                                                    <input
                                                       type="checkbox"
                                                       onClick={(event) => event.stopPropagation()}
                                                       checked={singleData.isPipelineStartupActive}
                                                       onChange={(event) => {
                                                         event.stopPropagation();
                                                         console.log("Toggle checked:", event.target.checked);
                                                         updatePipelineStartupStatus(singleData._id, event.target.checked, 'Pipeline');
                                                       }}
                                                     />
                                                     <span className="slider round"></span>
                                                   </label>
                                                </div>
                                            </div>
                                            <div className='compDetail'>
                                                <ReadMore text={singleData.overview} />
                                            </div>
                                            
                                            <div className='compsocialdetailCont mt-20'>
                                            { (singleData.website != "" && singleData.website != null) && 
                                                    <div className='websiteCont'>
                                                        Website:&nbsp;
                                                        <a  href={singleData.website.startsWith('http' || 'https') ? singleData.website : `http://${singleData.website}`}
                                                            target="_blank" rel="noopener noreferrer" className='Link'>
                                                           {singleData.website}
                                                        </a>
                                                    </div>
                                                }
                                                <div className='socialiconCont'>
                                                    { (singleData.socialProfile && singleData.socialProfile.linkedin) &&
                                                        <div>
                                                            <a target="_blank" href={`${linkdinUrl}${singleData.socialProfile.linkedin}`}>{Icons.Linkedin}</a>
                                                        </div>
                                                    }
                                                    { (singleData.socialProfile && singleData.socialProfile.crunchbase) &&
                                                        <div className='ml-10'>
                                                            <a target="_blank" href={`${cbUrl}${singleData.socialProfile.crunchbase}`}>{Icons.Crunchbase}</a>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                             {
                                                (singleData.pipelineStartupinterests.length > 0 && singleData.pipelineStartupinterests) && <div>
                                                    <div className='websiteCont'>Investors Responded: <a href="" className='Link'>{singleData.pipelineStartupinterests.length}</a></div>
                                                </div>
                                             }       
                                            {singleData.investors && singleData.investors.length > 0 && <div className='currentInvestor mt-20'>
                                                    <div className='title'>Curent Investors</div>
                                                    <div className='invCards mt-5'>
                                                        {singleData.investors.map((inv, index)=>{
                                                            return(<img key={index} className='image' src={inv.imgUrl} alt=''></img>)
                                                            // return(<img height='30' width='50' src={`${common.path.base_url}/getImage?id=${inv._id}`}></img>)
                                                        })}
                                                    </div>
                                            </div>} 

                                            <LastRoundLister seedArray={singleData.seed} />

                                            {/* <div>
                                                <div className='sentimentsClass'>
                                                    <div className='sentimentsTitle'>Strong Sentiments</div>
                                                    {
                                                        singleData.strongSentiments.length > 0 ?
                                                            <div className='sentimentsPer' >{parseFloat(( singleData.strongSentiments.length * 100) / ( totalInvestors )).toFixed(2)}%</div> :
                                                            <div className='sentimentsPer'>0%</div>
                                                    }
                                                </div>

                                                <div className='sentimentsClass cursorAction' >
                                                    <div className='sentimentsTitle'>Investor Responded</div>
                                                    {
                                                        singleData.pipelineStartupinterests.length > 0 ?
                                                            <div className='sentimentsPer' >
                                                            {singleData.pipelineStartupinterests.length}/{totalInvestors}
                                                            </div> :
                                                            <div className='sentimentsPer'>0%</div>
                                                    }
                                                </div>
                                                { (singleData.name) &&
                                                <div className='sentimentsClass'>
                                                    <div className='sentimentsTitle'>Recommended By</div>
                                                    {
                                                        singleData.userrecommendations > 1 ?
                                                        <div className='sentimentsPer' >{singleData.name} & {singleData.userrecommendations - 1} others </div> :
                                                        <div className='sentimentsPer' >{singleData.name}</div>

                                                    }
                                                </div>    
                                                }
                                            </div> */}
                                        </div>
                                    </div>
                            })
                            
                            :
                            <> 
                            { (getLoader) ?
                                    <div className="spinner-border" role="status">
                                        <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                    </div>
                                :   
                                    <div className='compitemCont' >
                                        Data Not Found
                                    </div>
                            }
                            </>
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default PipelineList;
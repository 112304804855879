export const setNewInvitationData = (payload) => {
  return {
    type: 'SET_NEW_INVITATIONS_DATA',
    payload
  }
}

export const setPendingCounterSignData = (payload) => {
  return {
    type: 'SET_PENDING_COUNTER_SIGN_DATA',
    payload
  }
}

export const setNotParticipationData = (payload) => {
  return {
    type: 'SET_NOT_PARTICIPATION_DATA',
    payload
  }
}

export const setMyInvestmentsData = payload => {
  return {
    type: 'SET_MY_INVESTMENTS_DATA',
    payload,
  }
}

export const setExitedInvestmentsData = payload => {
  return {
    type: 'SET_EXITED_INVESTMENTS_DATA',
    payload,
  }
}
export const setExitInProcessInvestmentsData = payload => {
  return {
    type: 'SET_EXIT_IN_PROCESS_INVESTMENTS_DATA',
    payload,
  }
}
export const setPipelineInvestmentsData = payload => {
  return {
    type: 'SET_PIPELINE_INVESTMENTS_DATA',
    payload,
  }
}
export const setRecommendationData = payload => {
  return {
    type: 'SET_RECOMMENDATION_DATA',
    payload,
  }
}
export const setUserData = payload => {
  return {
    type: 'SET_USER_DATA',
    payload,
  }
}



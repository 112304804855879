import axios from 'axios';
import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import common from '../../../common';
import { path } from "../../imagesPath";
import "./NoInvestment.scss";

function NoInvestment(props) {
    const { investorId } = props;
    const [userData, setUserData] = useState();


    useEffect(() => {
        if (investorId) {
            getUserData();
        }
    }, []);

    const getUserData = () => {
        axios.get(`${common.path.base_url}/getUserById?_id=${investorId}`)
            .then((response) => {
                if (response.data.data) {
                    setUserData(response.data.data);
                } else {
                    swal({
                        title: "Error!",
                        text: response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            }).catch((error) => {
                if (error.response.data) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    return (
        userData && userData.investments.length == 0 ? (
            <div className='row display-flex' style={{ justifyContent: "center", padding: "10px", alignItems: "center" }}>
                <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12 display-none'>
                    <img src={path.noInvestment} alt="No Investment" />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                    <h2>No investments yet?</h2>
                    <h4 style={{
                        textAlign: "left",
                        letterSpacing: "0px",
                        padding: "20px 0px",
                        color: "#383F59",
                        width: "100%",
                        opacity: "1",
                        lineHeight: "1.5",
                        fontWeight: "500",
                    }}>Get started by participating in open investment opportunities, providing feedback on companies under TSG's consideration, or suggesting potential startups for our review!</h4>
                    <div className='row display-flex display-block'>
                        {/* <div className='col-lg-4 col-md-4 p-4'><img className='image-box' src={path.portfolioGrowth} alt="Portfolio Growth" /></div> */}
                        <div className='col-lg-4 col-md-4 p-4 cursor' onClick={() => { window.open('/investorFaqPreview', '_blank') }} ><img className='image-box' src={path.investorFAQ} alt="Investor FAQ" /></div>
                        <div className='col-lg-4 col-md-4 p-4 cursor' onClick={() => { window.open('/year-in-review', '_blank') }} ><img className='image-box' src={path.yearReviewImage} alt="Year Review Image" /></div>
                    </div>
                </div>
            </div>
        ) : null
    );
}

export default NoInvestment;

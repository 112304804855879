import React, { Component } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import common from '../../common';

class SendMessageVIPModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            message: null,
            count: 0,
            isFromMultipleSendMessage: false,
        }
    }

    componentWillReceiveProps(nextProps) {
        nextProps.onRef && nextProps.onRef(this);
    }

    resetSendMessageModal() {
        this.setState({
            user: null,
            message: null,
            count: this.state.count + 1,
            isFromMultipleSendMessage: false,
        });

    }
    setData({
        user,
        isFromMultipleSendMessage,
    }) {
        this.setState({ user, isFromMultipleSendMessage })
    }


    sendMessage() {
        if (this.state.isFromMultipleSendMessage) {
            this.props.sendMessageMultipleUsers(this.state.message);
            this.props.onSendMessageClick('clear');
        } else {
            let temp = this;
            temp.props.onSendMessageClick(true);
            const user = this.state.user;
            let tokenpass = window.localStorage.getItem('token');
            const data = {
                investmentId: user.investmentId,
                userId: user.userId,
                message: this.state.message,
            };
            axios.post(`${common.path.base_url}/sendMessageVIPAccess`, data, {
                headers: {
                    "Authorization": tokenpass
                }
            })
                .then((response) => {
                    swal({
                        title: "Message Sent",
                        text: `Message has been sent to ${user.emailAddress}`,
                        icon: "success",
                        button: "Ok!",
                    })
                    temp.setState({ loading: false, user: null, message: null });
                })
                .catch(function (error) {
                    temp.setState({ loading: false });
                    temp.props.onSendMessageClick('clear');
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                });
        }
    }


    render() {
        return (
            <div id="sendMessageModal" className="modal fade">
                <div className="modal-dialog">
                    <div className="modal-content invite-contact">
                        <div className="modal-header">
                            <h4 className="modal-title">Send Message</h4>
                        </div>
                        <div className="modal-body">
                            <form className="form-horizontal" id='myform'>
                                {
                                    !this.state.isFromMultipleSendMessage &&
                                    <>
                                        <div className="form-group">
                                            <label className="col-lg-4 col-sm-3  col-md-3 control-label">Name :</label>
                                            <div className="col-lg-8 col-sm-8 col-md-8">
                                                {this.state.user && this.state.user.name}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-lg-4 col-sm-3  col-md-3 control-label">Email :</label>
                                            <div className="col-lg-8 col-sm-8 col-md-8">
                                                {this.state.user && this.state.user.emailAddress}
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="form-group">
                                    <label className="col-lg-4 col-sm-3  col-md-3 control-label">MSG :</label>
                                    <div className="col-lg-8 col-sm-8 col-md-8">
                                        <textarea
                                            key={this.state.count}
                                            className="form-control" name='preferredStockTerms' id="notes" type="message" placeholder='Message' rows="4" cols="50"
                                            onChange={(evt) => this.setState({ message: evt.target.value })}
                                            value={this.state.message}></textarea>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <LaddaButton
                                id='lada-btn'
                                loading={this.state.loadinglada}
                                onClick={() => this.sendMessage()}
                                data-color="#eee"
                                data-size={XS}
                                data-style={EXPAND_RIGHT}
                                data-spinner-size={30}
                                data-spinner-color="#ddd"
                                data-spinner-lines={12}
                                data-dismiss="modal"
                                disabled={this.state.message ? false : true}
                            >
                                {'Send Message'}
                            </LaddaButton>
                            <input type="button" id="cancelAgreementModal" className="btn btn-default" data-dismiss="modal" value="CANCEL" onClick={() => { }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default SendMessageVIPModal;
import React, { Component } from 'react';
import Select from 'react-select';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import "react-datepicker/dist/react-datepicker.css";
import _ from 'lodash';
import './progressbarModal.scss';
import common from '../../../common';
import RequiredSign from '../../../components/RequiredSign';

class ProgressbarModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            documents: [{ value: 'Executive Letter', label: 'Executive Letter' }, { value: 'IIR', label: ' Investment Intelligence Report ' }, { value: 'startupFAQ', label: 'Startup FAQ' }, { value: 'Engagement Agreement', label: 'Engagement Agreement' }, { value: 'NDA', label: 'NDA' }],
            document: '',
        }
    }
    componentDidMount() {
        const userRoleCode = window.localStorage.getItem('roleCode');
        if (userRoleCode === '004') {
            this.setState({
                documents: [{ value: 'Executive Letter', label: 'Executive Letter' }, { value: 'IIR', label: ' Investment Intelligence Report ' }, { value: 'startupFAQ', label: 'Startup FAQ' }],
            });
        }
    }
    componentWillReceiveProps(nextProps) {
        nextProps.onRef && nextProps.onRef(this);
    }
    resetProgressbarData() {
        this.setState({ document: ''});
    }
    onGoClick() {
        document.getElementById('cancelProgressModal').click();
        this.props.onProgressbarDocAddClick(this.state.document);
    }
    render() {
        return (
            <div>
                {/* <!-- Progressbar Modal HTML --> */}
                <div id="progressbarDocAdd" className="modal fade">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Collaborative Documents<strong></strong> </h4>
                            </div>
                            <div className="modal-body">
                                <div className="form-horizontal" id=' myform'>
                                    <div className="form-group">
                                        <label className="col-sm-3  col-md-3 col-lg-3" style={{ fontSize: "14px" }}>Select Document  <RequiredSign /> :</label>
                                        <div className="col-sm-8 col-md-8">
                                            <Select
                                                id='Document'
                                                defaultValue={this.state.document}
                                                options={this.state.documents}
                                                value={this.state.document}
                                                onChange={(event) => this.setState({ document: event })}

                                            />
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <LaddaButton
                                            id='lada-btn'
                                            loading={this.state.loadinglada}
                                            onClick={() => this.onGoClick()}
                                            data-color="#eee"
                                            data-size={XS}
                                            className={!this.state.document ? 'disable' : ''}
                                            data-style={EXPAND_RIGHT}
                                            data-spinner-size={30}
                                            data-spinner-color="#ddd"
                                            data-spinner-lines={12}
                                        >
                                            GO
                                        </LaddaButton>
                                        <input type="button" id="cancelProgressModal" className="btn btn-default" data-dismiss="modal" onClick={() => this.resetProgressbarData()} value="CANCEL" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ProgressbarModal;
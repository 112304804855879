import React, { Component } from 'react';
import './StartUpEngagement.scss'
// Imported Components
import HeaderImage from '../../components/HeaderImage';
import LoadingSpinner from '../../components/Loader';
import Header from '../../components/Header';
// import TButton from '../../components/TButton';
import axios from 'axios';
import swal from 'sweetalert';
import common from '../../common';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
// import FileUploadBlobDoc from '../../components/FileUploadBlobDoc';
import _ from 'lodash';

// Imported Images
import images from "../../images";

let userRole = '000';

class StartUpEngagement extends Component {
    constructor(props) {
        super(props);
        this.companyId = '';
        this.investmentId = '';
        this.pdfUrl = '';
        this.imageSource = [];
        this.state = {
            checklistData: [],
            pdfUrl: '',
        }
    }

    componentWillMount() {
        const url = new URL(window.location.href);
        this.companyId = url.searchParams.get('companyId');
        this.investmentId = url.searchParams.get('investmentId');
        userRole = window.localStorage.getItem('role');
        this.userRoleCode = window.localStorage.getItem('roleCode');
        this.getData();
        this.setState({
            name: window.localStorage.getItem('username'),
            userId: window.localStorage.getItem('id')
        });
        this.setState({ Role: userRole })
    }


    getData() {
        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loading: true });
        const id = window.localStorage.getItem('id');
        this.setState({ id: id })
        axios.get(`${common.path.base_url}/getInvestmentCheckList?investmentId=${this.investmentId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                this.setState({
                    checklistData: response.data.data
                });
                this.getLinkToDownload();
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    getEngagementDataFromCheckList() {
        const engagementAgreement = _.filter(this.state.checklistData, (checklistData) => { return checklistData.title.toLowerCase().includes('engagement agreement') })[0];
        // const engagementAgreement = _.find(this.state.checklistData, (checklistData) => { return checklistData.order === 7 });
        return engagementAgreement
    }
    getLinkToDownload() {
        let temp = this;
        let token = window.localStorage.getItem('token');
        const id = window.localStorage.getItem('id');
        const engagementAgreementData = this.getEngagementDataFromCheckList();
        this.setState({ id: id })
        axios.get(`${common.path.base_url}/getFileNew?fileId=${engagementAgreementData.docId._id}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                // console.log(response,"food");

                this.setState({
                    pdfUrl: response
                })
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    render() {
        const engagementAgreement = this.getEngagementDataFromCheckList();
        return (
            <div>
                {this.state.loading ? <LoadingSpinner /> : ""}
                <Header
                    username={this.state.name}
                    role={this.state.Role}
                />
                <HeaderImage
                    heading={this.state.Role}
                />
                <div className="startUp">
                    <div className="closeButton">
                        <div className="cancel-btn-wraper">
                            <button type="button" className="close" aria-label="Close" onClick={() => this.props.history.goBack()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                    </div>

                    <div className="startUp-main">

                        <div className='heading'>
                            <h4>ENGAGEMENT AGREEMENT</h4>
                        </div>
                        <div className='embed-responsive embed-responsive-16by9 imageDisplayPostion'>
                            {
                                engagementAgreement !== undefined ? engagementAgreement.status ?
                                    <iframe className="embed-responsive-item"
                                        src={`${common.path.base_url}/getFileNew?fileId=${engagementAgreement.docId._id}`}
                                        type='application/pdf'
                                        allowFullScreen
                                        title="Pdf File"
                                    />
                                    : ''
                                    : ''
                            }
                        </div>
                        {engagementAgreement && engagementAgreement.docId ?
                            <div className="downloadButton">
                                <button className='download'>
                                    <a target="_blank" className="file-link" href={`${common.path.base_url}/getFileNew?fileId=${engagementAgreement.docId._id}`} download> <img src={images.path.addIcon} alt="" />DOWNLOAD</a>
                                </button>
                            </div> : 'Not Uploaded Yet!'
                        }
                    </div>
                </div>

            </div>
        )
    }
}
export default StartUpEngagement;
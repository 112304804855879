import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';

import images from '../../../images';
import SecondaryChecklist from './secondary-checklist';

const PrimaryChecklist = (props) => {
  return (
    <Droppable droppableId="checklists" type="checklists">
      {
        provided => (
          <div className="col-lg-7 col-xs-12" ref={provided.innerRef} {...provided.droppableProps}>
            {
              props.checklists && props.checklists.length > 0 &&
              props.checklists.map((singleChecklist, index) => {
                return <Draggable
                  key={singleChecklist._id}
                  draggableId={singleChecklist._id}
                  index={index}
                >
                  {
                    provided => (
                      <div className="management-console-list"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <div className="primary-checklist">
                          <i className="fa fa-bars" aria-hidden="true" {...provided.dragHandleProps}></i>
                          <label>
                            <div className="form-group">
                              <div className="box-cover">
                                <p>
                                  <div><span>{singleChecklist.title}</span></div>
                                  {
                                    singleChecklist.isDocNeeded ?
                                       singleChecklist.isMultipleDocUploadAllowed ?
                                      <i className="fa fa-files-o" aria-hidden="true"></i>:<i className="fa fa-file" aria-hidden="true"></i>:''
                                  }
                                </p>
                                <input className="form-control" type="text" name="remember" readOnly />
                              </div>
                              <img src={images.path.editChecklist}
                                className="edit-item"
                                alt="Edit Checklist"
                                data-toggle="modal"
                                data-target="#updateChecklistModal"
                                onClick={() => props.onPrimaryChecklistEdit(singleChecklist._id)}
                              />
                            </div>
                          </label>
                          <img src={images.path.deleteChecklist} className="delete-item" alt="Delete Checklist" onClick={() => props.onDeletePrimaryChecklist(singleChecklist._id)} />
                        </div>
                        <div className="primary-checklist sub-checklist">
                          <SecondaryChecklist
                            subChecklists={singleChecklist.subCheckLists}
                            primaryChecklistNum={index}
                            checklist={singleChecklist}
                            onClickAddSubChecklist={props.onClickAddSubChecklist}
                            onDeleteSubChecklist={props.onDeleteSubChecklist}
                          />
                        </div>
                      </div>
                    )
                  }
                </Draggable>
              })
            }
            {provided.placeholder}
          </div>
        )
      }
    </Droppable >
  );
}

PrimaryChecklist.propTypes = {
  checklists: PropTypes.array.isRequired,
  onPrimaryChecklistEdit: PropTypes.func.isRequired,
  onDeletePrimaryChecklist: PropTypes.func.isRequired,
  onClickAddSubChecklist: PropTypes.func.isRequired,
  onDeleteSubChecklist: PropTypes.func.isRequired,
};

export default PrimaryChecklist;
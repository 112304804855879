import React, { useState } from 'react';
import './TempleteEmail.scss';
import axios from 'axios';
import swal from 'sweetalert';
import common from '../../../../common';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';

const EditTemplateSms = (props) => {
    
    const [emailData, setEmailData] = useState(props);
    
    function editTempCode() {
        
        const tempData = props.editTemplateData;
        tempData.investmentId = props.investmentId; 
        
        let tokenpass = window.localStorage.getItem('token');
        axios.post(`${common.path.base_url}/editSMSTemplate`, tempData, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                //const allTemplate = response.data.data;
                // temp.setState({ loading: false });
                // temp.setState({ allTemplate: response.data.data });

                swal({
                    title: "Success",
                    text: response && response.data && response.data.message ? response.data.message : 'Something Went Wrong',
                    icon: "success",
                    button: "Ok!",
                });
                
            })
            .catch(function (error) {
                if (common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response && error.response.data && error.response.data.code && error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });

    }

    // useEffect(() => {
        
    //     //const compiledFunction = pug.compileFile(props.editTemplateData.html);

    //     // Render a set of data
    //     //console.log(compiledFunction());

    // },[]);

    function handleChange(id, newValue) {
        
        if (id === "subject") {
            setEmailData(props.editTemplateData.subject = newValue);    
        } 
        if(id === "message") {
            setEmailData(props.editTemplateData.message = newValue);
        }
        if(id === "title") {
            setEmailData(props.editTemplateData.title = newValue);
        }
    }

    return (
        <div>
            <div className="new-investment">
                <h2>Edit SMS Template</h2>

                <button className="back-list-btn" onClick={ () => props.backListPage() } >
                    Back
                </button>    
                
            </div>
            <div className="edit-temp-div">
                
                <div className="form-group">
                    <label for="exampleInputEmail1">Title</label>
                    <div>
                        <input type="text" id="title"  className="form-control" value={ props.editTemplateData.title } onChange={(event) => handleChange(event.target.id, event.target.value)} />
                    </div>
                </div>
                <div className="form-group">
                    <label for="exampleInputEmail1">Body</label>
                    <div>
                        <textarea id="message" name='message' rows={10} style={{ maxHeight:'160px', width:'650px', maxWidth:'650px'}} onChange={(event) => handleChange(event.target.id, event.target.value)} >
                            { props.editTemplateData.message }
                        </textarea>
                    </div>
                </div>

                <div className="form-group">
                <LaddaButton
                    id='lada-btn'
                    //loading={this.state.loadinglada2}
                    data-color="#eee"
                    data-size={XS}
                    onClick={editTempCode}
                    className="save-temp-btn"
                    data-style={EXPAND_RIGHT}
                    data-spinner-size={30}
                    data-spinner-color="#ddd"
                    data-spinner-lines={12}
                >SAVE</LaddaButton>

                </div>
            </div>    
        </div>
    );
}
export default EditTemplateSms;    
import React from 'react'
import Header from '../../components/Header';
import Ledger from '../components/Ledger';

const App = (props) => (
    <>
        <Header
        username={window.localStorage.getItem('username')}
        role={window.localStorage.getItem('role')}
        />
        <Ledger 
        {...props}
        />
    </>
)

export default App
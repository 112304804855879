import React, { Component } from "react";
import Select from "react-select";
import "./Profile.scss";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Header from "../../components/Header";
import HeaderImg from "../../components/HeaderImage";
import LoadingSpinner from "../../components/Loader";
import { withRouter } from "react-router-dom";
import LaddaButton, { XS, EXPAND_RIGHT } from "react-ladda";
import axios from "axios";
import swal from "sweetalert";
import common from "../../common";
import _ from "lodash";
import RequiredSign from "../../components/RequiredSign";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: "",
      to: "",
      subject: "",
      text: "",
      domain: "",
    };
  }

  sendEmail() {
    if (
      this.state.from &&
      this.state.to &&
      this.state.domain &&
      this.state.subject &&
      this.state.text
    ) {
      let temp = this;
      axios
        .post(`${common.path.base_url}/mailgunTest`, {
          fromEmail: this.state.from,
          toEmail: this.state.to,
          domain: this.state.domain,
          subject: this.state.subject,
          text: this.state.text,
        })
        .then(function (response) {
          swal({
            title: "Sent!",
            text: "Emal sent successfully!",
            icon: "success",
            button: "Ok!",
          });
        })
        .catch(function (error) {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        });
    } else {
      alert("Please enter require fields");
    }
  }

  render() {
    return (
      <div className="updateProfile">
        {this.state.loading ? <LoadingSpinner /> : ""}
        <HeaderImg heading={this.state.Role} />

        <div className="profile-main">
          <div className="heading">
            <h3>Mailgun Testing Email</h3>
          </div>
          <div className="profile">
            <div className="upload-profile-pic">
              <div className="profile-pic-box">
                <img
                  id="preview"
                  className="profile-pic"
                  src={`${common.path.base_url}/getImage?id=${this.state.id}`}
                  alt=""
                />
                <div className="p-image">
                  <i
                    onClick={() => this.chooseFile()}
                    className="fa fa-pencil upload-button"
                  ></i>
                  <input
                    id="choose-file"
                    onChange={() => this.previewFile()}
                    className="file-upload"
                    type="file"
                    accept="image/jpeg,image/png,image/jpg"
                  />
                </div>
              </div>
            </div>
            <div className="profile-details">
              {/* <React.Fragment>
                                <form className="form-horizontal"> */}
              <div className="form-horizontal">
                <div className="form-group">
                  <label className="col-md-3 control-label">
                    From <RequiredSign /> :
                  </label>
                  <div className="col-md-4">
                    <input
                      className="form-control"
                      id="fromEmail"
                      type="text"
                      placeholder="From Email"
                      onChange={(event) => {
                        this.setState({ from: event.target.value });
                      }}
                      value={this.state.from}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-md-3 control-label">
                    To <RequiredSign /> :
                  </label>
                  <div className="col-md-4">
                    <input
                      className="form-control"
                      id="toEmail"
                      type="text"
                      placeholder="To Email"
                      onChange={(event) => {
                        this.setState({ to: event.target.value });
                      }}
                      value={this.state.to}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-3 control-label">
                    Subject <RequiredSign /> :
                  </label>
                  <div className="col-md-4">
                    <input
                      className="form-control"
                      id="subject"
                      type="text"
                      placeholder="Subject"
                      onChange={(event) => {
                        this.setState({ subject: event.target.value });
                      }}
                      value={this.state.subject}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-3 control-label">
                    Text <RequiredSign /> :
                  </label>
                  <div className="col-md-4">
                    <textarea
                      className="form-control"
                      name="preferredStockTerms"
                      id="text"
                      type="message"
                      placeholder="Text"
                      rows="4"
                      cols="50"
                      onChange={(event) =>
                        this.setState({ text: event.target.value })
                      }
                    ></textarea>
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-3 control-label">
                    Domain <RequiredSign /> :
                  </label>
                  <select
                    className="col-md-4 status-box"
                    onChange={(event) =>
                      this.setState({ domain: event.target.value })
                    }
                  >
                    <option value="" disabled selected>
                      Select Domain
                    </option>
                    <option value="devmail1.syndicategroup.com">
                      devmail1.syndicategroup.com
                    </option>
                    <option value="mail1.syndicategroup.com">
                      mail1.syndicategroup.com
                    </option>
                  </select>
                </div>

                <div className="form-group btn-section">
                  <label className="col-md-3 control-label"></label>
                  <div className="col-xs-12 col-sm-6 col-md-8 updateButton">
                    <div className="col-sm-6 col-md-2 col-xs-6">
                      <LaddaButton
                        id="update-btn"
                        loading={this.state.loadinglada}
                        onClick={() => this.sendEmail()}
                        data-color="#fc6c85"
                        data-size={XS}
                        data-style={EXPAND_RIGHT}
                        data-spinner-size={30}
                        data-spinner-color="#fc6c85"
                        data-spinner-lines={12}
                        className={
                          !this.state.profileFormValidated
                            ? "profile-update"
                            : "profile-update"
                        }
                      >
                        SEND
                      </LaddaButton>
                    </div>

                    {/* <input type="reset" className="btn btn-default cancel" value="CANCEL"
                                                onClick={() => {
                                                    let path = `/dashboard`;
                                                    this.props.history.push(path);
                                                }} /> */}
                    <div className="col-sm-6 col-md-2 col-xs-6">
                      <LaddaButton
                        id="cancel-btn"
                        onClick={() => this.props.history.push("/dashboard")}
                        data-color="#fc6c85"
                        data-size={XS}
                        data-style={EXPAND_RIGHT}
                        className="profile-cancel"
                      >
                        CANCEL
                      </LaddaButton>
                    </div>
                    {/* Button that invisible and helps to redirecting back to previous page */}
                  </div>
                </div>
              </div>
              {/* </form>
                            </React.Fragment> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default Profile;
export default withRouter(Profile);

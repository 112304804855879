import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import swal from "sweetalert";
import common from "../../common";
import LoadingSpinner from "../../components/Loader";
import TButton from "../../components/TButton";
import { isLoading, isNotLoading } from "../../shared/actions/loadingSpinner";
import {
  setExitInProcessInvestmentsData,
  setExitedInvestmentsData,
  setMyInvestmentsData,
  setNewInvitationData,
  setNotParticipationData,
  setPendingCounterSignData,
  setPipelineInvestmentsData,
  setRecommendationData,
  setUserData,
} from "../actions/dashboardData";
import { expandOrCollapseClickAction } from "../actions/expandCollapseList";
import { changeViewClickAction } from "../actions/myInvestmentView";
import { resetPopover, setPopover } from "../actions/popover";
import { resetToDoPopover, setToDoPopover } from "../actions/toDoPopover";
import DashboardStart from "../components/DashboardStart/Start";
import BrockerageAccountModel from "../components/ExitInProcessInvestments/BrockerageAccountModel";
import ExitInProcessInvestments from "../components/ExitInProcessInvestments/ExitInProcessInvestments";
import VerifyAddressModel from "../components/ExitInProcessInvestments/VerifyAddressModel";
import MyInvestments from "../components/MyInvestments/MyInvestments";
import NewInvitations from "../components/NewInvitations/NewInvitations";
import ReSignDocModal from "../components/NewInvitations/ReSignDocModal";
import NoInvestment from "../components/NoInvestment/NoInvestment";
import NotParticipating from "../components/NotParticipating/NotParticipating";
import PendingCounterSigningInvestments from "../components/PendingCounterSigningInvestments/PendingCounterSigningInvestments";
import PipelineInvestments from "../components/PipelineInvestments/PipelineInvestments";
import InvestorService from "../services/investor.service";
import "./Container.scss";


class MainContainer extends Component {
  constructor() {
    super();
    this.adminAccess = false;
    this.isBlackList = false;
  }

  changeViewClickHandler = (type) => {
    if (type === "list") {
      this.props.expandOrCollapseClickAction(undefined, "reset");
    }
    this.props.changeViewClickAction(type);
  };
  expandOrCollapseClickHandler = (id, type) => {
    this.props.expandOrCollapseClickAction(id, type);
  };
  invitationActionHandler = (isParticipate, processId, investmentId) => {
    if (isParticipate) {
      this.investorInterest(isParticipate, processId, investmentId);
    } else {
      swal({
        title: "Are you sure?",
        text: "You are not interested to participate in this investment.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((action) => {
        if (action) {
          this.investorInterest(isParticipate, processId, investmentId);
        }
      });
    }
  };
  investorInterest = (isParticipate, processId, investmentId) => {
    this.props.isLoading();
    const url = new URL(window.location.href);
    this.investorId = url.searchParams.get("investorId");
    InvestorService.investorInterest({ isParticipate, processId })
      .then(() => {
        if (isParticipate && !this.investorId) {
          // const { history } = this.props;
          // history.push(`/investment-process?investmentId=${investmentId}`)
          window.location.href = `/investment-process?investmentId=${investmentId}`;
        } else {
          return this.investorDashboardHandler();
        }
      })
      .then(() => this.props.isNotLoading())
      .catch((error) => {
        this.props.isNotLoading();
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else {
          swal({
            title: "Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : "Something Went Wrong",
            icon: "error",
            button: "Ok!",
          });
        }
        // // TODO: handle error
        if (
          error.response &&
          error.response.data &&
          error.response.data.code &&
          error.response.data.code === 204
        ) {
          window.localStorage.clear();
          window.location.href = "/";
        }
      });
  };

  componentDidMount() {
    const url = new URL(window.location.href);
    this.investorId = url.searchParams.get("investorId");
    this.fullName = null;
    if (this.investorId) {
      this.adminAccess = true;
      InvestorService.getUserById(this.investorId)
        .then((res) => {
          if (res.data && res.data.data) {
            this.fullName = `${res.data.data.firstName} ${res.data.data.lastName}`;
          }
          if (res.data && res.data.data) {
            const type = res.data.data.investorCategories;
            this.isBlackList =
              type.length > 0 ? type.some((e) => e === "Blacklist") : false;
          }
          this.props.setUserData(res.data.data);
          this.loadDashboardData();
        })
        .catch((error) => { });
      } else {
        const roleCode = localStorage.getItem('roleCode');
        if (roleCode == '005') {
          this.adminAccess = false;
          this.fullName = localStorage.getItem('username');
          this.investorId = localStorage.getItem('id');
        }
        InvestorService.getUserById(this.investorId)
        .then((res) => {
          if (res.data && res.data.data) {
            this.fullName = `${res.data.data.firstName} ${res.data.data.lastName}`;
          }
          if (res.data && res.data.data) {
            const type = res.data.data.investorCategories;
            this.isBlackList =
              type.length > 0 ? type.some((e) => e === "Blacklist") : false;
          }
          this.props.setUserData(res.data.data);
          this.loadDashboardData();
        })
        .catch((error) => { });
    }
  }
  loadDashboardData = () => {
    this.props.isLoading();
    this.pendingCounterSignActionHandler();
    this.investorDashboardHandler()
      .then(() => this.props.isNotLoading())
      .catch((error) => {
        this.props.isNotLoading();
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else {
          swal({
            title: "Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : "Something Went Wrong",
            icon: "error",
            button: "Ok!",
          });
        }
        // // TODO: handle error
        if (
          error.response &&
          error.response.data &&
          error.response.data.code &&
          error.response.data.code === 204
        ) {
          window.localStorage.clear();
          window.location.href = "/";
        }
      });
  };
  getUserId = () => {
    if (this.investorId) {
      return this.investorId;
    } else {
      return window.localStorage.getItem("id");
    }
  };
  investorDashboardHandler = () => {
    const userId = this.getUserId();
    return new Promise((resolve, reject) => {
      InvestorService.newInvitationHandler(userId)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.props.setNewInvitationData(response.data.data);
          }
          return InvestorService.notParticipationHandler(userId);
        })
        .then((notParticipationRes) => {
          if (
            notParticipationRes &&
            notParticipationRes.data &&
            notParticipationRes.data.data
          ) {
            this.props.setNotParticipationData(notParticipationRes.data.data);
          }
          return InvestorService.myInvestmentsHandler(userId);
        })
        .then((myInvestments) => {
          if (myInvestments && myInvestments.data && myInvestments.data.data) {
            this.props.setMyInvestmentsData(myInvestments.data.data);
          }
          return InvestorService.exitInProcessInvestmentsHandler(userId);
        })
        .then((exitInProcessInvestments) => {
          if (
            exitInProcessInvestments &&
            exitInProcessInvestments.data &&
            exitInProcessInvestments.data.data
          ) {
            this.props.setExitInProcessInvestmentsData(
              exitInProcessInvestments.data.data
            );
          }
          return InvestorService.pipelineInvestments(userId);
        })
        .then((pipelineInvestments) => {
          if (
            pipelineInvestments &&
            pipelineInvestments.data &&
            pipelineInvestments.data.data
          ) {
            this.props.setPipelineInvestmentsData(
              pipelineInvestments.data.data
            );
          }
          resolve();
        })
        .catch((error) => {
          this.props.setMyInvestmentsData({
            error:
              "Please check your internet connection. You can also try to log out and log back in. If you are still experiencing a problem, please contact us at platform@Syndicategroup.com.",
          });
          reject(error);
        });
    });
  };

  filterChangeHandler = (evt) => {
    this.props.filterChange("SET_SELECTED_VALUE", evt);
    this.props.isLoading();
    InvestorService.myInvestmentsFilterHandler(evt.value)
      .then((myInvestments) => {
        this.props.isNotLoading();
        if (myInvestments && myInvestments.data && myInvestments.data.data) {
          this.props.setMyInvestmentsData(myInvestments.data.data);
        }
      })
      .catch((error) => {
        this.props.isNotLoading();
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else {
          swal({
            title: "Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : "Something Went Wrong",
            icon: "error",
            button: "Ok!",
          });
        }
      });

    this.props.filterChange("SET_SELECTED_VALUE", evt);
  };
  redirectToInvestmentProcess = (investmentId) => {
    // const { history } = this.props;
    // history.push(`/investment-process?investmentId=${investmentId}`)
    window.location.href = `/investment-process?investmentId=${investmentId}`;
  };

  setToDoPopover(id, type) {
    this.props.setToDoPopover(type, id);
    this.props.resetPopover();
  }
  resetToDoPopover() {
    const userId = this.getUserId();
    this.props.resetToDoPopover();
    InvestorService.pipelineInvestments(userId)
      .then((pipelineInvestments) => {
        if (
          pipelineInvestments &&
          pipelineInvestments.data &&
          pipelineInvestments.data.data
        ) {
          setTimeout(() => {
            this.props.setPipelineInvestmentsData(
              pipelineInvestments.data.data
            );
          }, 800);
        }
      })
      .catch();
  }

  setPopover(id, type) {
    this.props.setPopover(type, id);
    this.props.resetToDoPopover();
  }
  resetPopover() {
    const userId = this.getUserId();
    this.props.resetPopover();
    InvestorService.pipelineInvestments(userId)
      .then((pipelineInvestments) => {
        if (
          pipelineInvestments &&
          pipelineInvestments.data &&
          pipelineInvestments.data.data
        ) {
          setTimeout(() => {
            this.props.setPipelineInvestmentsData(
              pipelineInvestments.data.data
            );
          }, 800);
        }
      })
      .catch();
  }
  saveAddtionalThoughtsHandler(comment, companyId) {
    const userId = this.getUserId();
    InvestorService.saveAddtionalThoughts({ comment, companyId, userId })
      .then(() => this.resetPopover(), this.resetToDoPopover())
      .catch({});
  }
  investorSentimentInterestHandler(interestData, interestType) {
    const userId = this.getUserId();
    const data = {
      companyId: interestData._id,
      userId,
      interestType,
      name: interestData.companyName,
    };
    // this.props.isLoading();
    InvestorService.investorSntimentsInterest(data)
      .then(() => {
        return InvestorService.pipelineInvestments(userId);
      })
      .then((pipelineInvestments) => {
        if (
          pipelineInvestments &&
          pipelineInvestments.data &&
          pipelineInvestments.data.data
        ) {
          const data = this.props.pipelineInvestments.map((item) => {
            if (item._id === interestData._id) {
              return {
                ...item,
                pipelineStartupinterests: {
                  companyId: interestData._id,
                  userId,
                  interestType,
                  interestComment: "",
                  name: interestData.companyName,
                },
              };
            } else {
              return { ...item };
            }
          });
          this.setPopover(interestData._id, interestType);
          this.props.setPipelineInvestmentsData(data);
        }
        // this.props.isNotLoading()
      })
      .catch((error) => {
        this.props.isNotLoading();
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else {
          swal({
            title: "Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : "Something Went Wrong",
            icon: "error",
            button: "Ok!",
          });
        }
        // // TODO: handle error
        if (
          error.response &&
          error.response.data &&
          error.response.data.code &&
          error.response.data.code === 204
        ) {
          window.localStorage.clear();
          window.location.href = "/";
        }
      });
  }

  toDoInvestorSentimentInterestHandler(interestData, interestType) {
    const userId = this.getUserId();
    const data = {
      companyId: interestData._id,
      userId,
      interestType,
      name: interestData.companyName,
    };
    InvestorService.investorSntimentsInterest(data)
      .then(() => {
        return InvestorService.pipelineInvestments(userId);
      })
      .then((pipelineInvestments) => {
        if (
          pipelineInvestments &&
          pipelineInvestments.data &&
          pipelineInvestments.data.data
        ) {
          const data = this.props.pipelineInvestments.map((item) => {
            if (item._id === interestData._id) {
              return {
                ...item,
                pipelineStartupinterests: {
                  companyId: interestData._id,
                  userId,
                  interestType,
                  interestComment: "",
                  name: interestData.companyName,
                },
              };
            } else {
              return { ...item };
            }
          });
          this.setToDoPopover(interestData._id, interestType);
          this.props.setPipelineInvestmentsData(data);
        }
      })
      .catch((error) => {
        this.props.isNotLoading();
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else {
          swal({
            title: "Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : "Something Went Wrong",
            icon: "error",
            button: "Ok!",
          });
        }
        // // TODO: handle error
        if (
          error.response &&
          error.response.data &&
          error.response.data.code &&
          error.response.data.code === 204
        ) {
          window.localStorage.clear();
          window.location.href = "/";
        }
      });
  }

  cancelPopOver() {
    this.resetPopover();
  }
  saveRecommendation(data) {
    if (data.isCurrentPartner === "yes") {
      data.isCurrentPartner = true;
    } else {
      data.isCurrentPartner = false;
    }
    if (data.isMakeIntro === "yes") {
      data.isMakeIntro = true;
    } else {
      data.isMakeIntro = false;
    }
    this.props.isLoading();
    InvestorService.saveRecommendation(data)
      .then(() => {
        return InvestorService.recommendations();
      })
      .then((recommendations) => {
        if (
          recommendations &&
          recommendations.data &&
          recommendations.data.data
        ) {
          this.props.setRecommendationData(recommendations.data.data);
        }
        this.props.isNotLoading();
        swal({
          title: "Success!",
          text: "Thank you for your recommendation!",
          icon: "success",
          button: "Ok!",
        }).then(() => { });
      })
      .catch((error) => {
        this.props.isNotLoading();
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else {
          swal({
            title: "Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : "Something Went Wrong",
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }

  pendingCounterSignActionHandler = () => {
    const userId = this.getUserId();
    InvestorService.pendingCountersignHandler(userId)
      .then((pendingCounterSignInvestments) => {
        if (
          pendingCounterSignInvestments &&
          pendingCounterSignInvestments.data &&
          pendingCounterSignInvestments.data.data
        ) {
          this.props.setPendingCounterSignData(
            pendingCounterSignInvestments.data.data
          );
        }
      })
      .catch((error) => {
        this.props.isNotLoading();
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else {
          swal({
            title: "Error! #784SED",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : "Something Went Wrong",
            icon: "error",
            button: "Ok!",
          });
        }
      });
  };

  openResendDocModal() {
    document.getElementById("reSend-btn").click();
  }
  openVerifyAddressModal() {
    document.getElementById("verifyAddress-btn").click();
    this.setState({ loading: false });
  }
  openBrokerageAccountModal() {
    document.getElementById("brokerageAccount-btn").click();
    this.setState({ loading: false });
  }
  render() {
    const userId = this.getUserId();
    return (
      <>
        <ReSignDocModal onRef={(ref) => (this.ReSignDocModalChild = ref)} />
        <TButton
          onClick={() => { }}
          id="reSend-btn"
          dataTarget={`#reSignDocModal`}
          dataToggle={`modal`}
          style={{ display: "none" }}
        />

        <VerifyAddressModel onRef={(ref) => (this.verifyAddressChild = ref)} />
        <BrockerageAccountModel
          onRef={(ref) => (this.brokerageAccountChild = ref)}
        />

        {this.props.loadingSpinner ? (
          <LoadingSpinner />
        ) : (
          <div className="container container-padding">
            <div onClick={() => this.resetPopover()}>
              <DashboardStart
                adminAccess={this.adminAccess}
                fullName={this.fullName}
                investorId={this.investorId}
                {...this.props}
              />
              <NoInvestment
                investorId={this.investorId}
                {...this.props} />
              {/* <ToDoSection
                {...this.props}
                investorSentimentInterest={(interestData, interestType) =>
                  this.toDoInvestorSentimentInterestHandler(
                    interestData,
                    interestType,
                  )
                }
                resetPopover={() => this.resetToDoPopover()}
                cancelPopOver={() => this.resetToDoPopover()}
                saveAddtionalThoughts={(comment, id) =>
                  this.saveAddtionalThoughtsHandler(comment, id)
                }
                invitationAction={this.invitationActionHandler}
                redirectToInvestmentProcess={this.redirectToInvestmentProcess}
                adminAccess={this.adminAccess}
                investorId={this.investorId}
                isBlackList={this.isBlackList}
                reSendDocClick={(userId, investmentId) => {
                  this.setState(
                    { loading: true },
                    this.ReSignDocModalChild.getFormData(
                      userId,
                      investmentId,
                      () => this.openResendDocModal()
                    )
                  );
                }}
                verifyAddressClick={(data) => {
                  this.setState(
                    this.verifyAddressChild.setData(data),
                    this.openVerifyAddressModal()
                  );
                }}
                brokerageAccountClick={(data) => {
                  this.setState(
                    this.brokerageAccountChild.setData(data),
                    this.openBrokerageAccountModal()
                  );
                }}
              /> */}

              <NewInvitations
                invitationAction={this.invitationActionHandler}
                redirectToInvestmentProcess={this.redirectToInvestmentProcess}
                adminAccess={this.adminAccess}
                isBlackList={this.isBlackList}
                reSendDocClick={(userId, investmentId) => {
                  this.setState(
                    { loading: true },
                    this.ReSignDocModalChild.getFormData(
                      userId,
                      investmentId,
                      () => this.openResendDocModal()
                    )
                  );
                }}
              />

              <PendingCounterSigningInvestments
                adminAccess={this.adminAccess}
                isBlackList={this.isBlackList}
                {...this.props}
              />
              
              <PipelineInvestments
                adminAccess={this.adminAccess}
                isBlackList={this.isBlackList}
                investorSentimentInterest={(interestData, interestType) =>
                  this.investorSentimentInterestHandler(
                    interestData,
                    interestType
                  )
                }
                resetPopover={() => this.resetPopover()}
                cancelPopOver={() => this.cancelPopOver()}
                saveAddtionalThoughts={(comment, id) =>
                  this.saveAddtionalThoughtsHandler(comment, id)
                }
                investorId={this.investorId}
                {...this.props}
              />
              {/* <Recommendations
                    isBlackList={this.isBlackList}
                    saveRecommendation={(data) => this.saveRecommendation(data)}
                    {...this.props}
                  /> */}

              <MyInvestments
                changeViewClickHandler={this.changeViewClickHandler}
                filterChange={this.filterChangeHandler}
                expandOrCollapseClick={this.expandOrCollapseClickHandler}
                uploadClickHnadler={(data) => {
                  this.setState(
                    this.uploadDocumentModalChild.setInvestmentData(data, () =>
                      this.upload()
                    )
                  );
                }}
                adminAccess={this.adminAccess}
                investorId={this.investorId}
              />

              <ExitInProcessInvestments
                adminAccess={this.adminAccess}
                isBlackList={this.isBlackList}
                fromToDo={false}
                investorId={this.investorId}
                verifyAddressClick={(data) => {
                  this.setState(
                    this.verifyAddressChild.setData(data),
                    this.openVerifyAddressModal()
                  );
                }}
                brokerageAccountClick={(data) => {
                  this.setState(
                    this.brokerageAccountChild.setData(data),
                    this.openBrokerageAccountModal()
                  );
                }}
              />
              {/* <ExitedInvestments adminAccess={this.adminAccess} /> */}
              <NotParticipating
                invitationAction={this.invitationActionHandler}
                adminAccess={this.adminAccess}
              />
              <TButton
                onClick={() => { }}
                id="verifyAddress-btn"
                dataTarget={`#verifyAddressModel`}
                dataToggle={`modal`}
                style={{ display: "none" }}
              />
              <TButton
                onClick={() => { }}
                id="brokerageAccount-btn"
                dataTarget={`#brockerageAccountModel`}
                dataToggle={`modal`}
                style={{ display: "none" }}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loadingSpinner: state.loadingSpinner,
    pipelineInvestments:
      state.investorDashboardData.pipelineInvestments.length > 0
        ? state.investorDashboardData.pipelineInvestments
        : [],
    exitInProcessInvestments:
      state.investorDashboardData.exitInProcessInvestments.length > 0
        ? state.investorDashboardData.exitInProcessInvestments
        : [],
    myInvestments:
      state.investorDashboardData.myInvestments.length > 0
        ? state.investorDashboardData.myInvestments
        : state.investorDashboardData.myInvestments.error
          ? state.investorDashboardData.myInvestments
          : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoading: bindActionCreators(isLoading, dispatch),
    isNotLoading: bindActionCreators(isNotLoading, dispatch),
    changeViewClickAction: bindActionCreators(changeViewClickAction, dispatch),
    expandOrCollapseClickAction: bindActionCreators(
      expandOrCollapseClickAction,
      dispatch
    ),
    setNewInvitationData: bindActionCreators(setNewInvitationData, dispatch),
    setPendingCounterSignData: bindActionCreators(
      setPendingCounterSignData,
      dispatch
    ),
    setNotParticipationData: bindActionCreators(
      setNotParticipationData,
      dispatch
    ),
    setMyInvestmentsData: bindActionCreators(setMyInvestmentsData, dispatch),
    setExitedInvestmentsData: bindActionCreators(
      setExitedInvestmentsData,
      dispatch
    ),
    setExitInProcessInvestmentsData: bindActionCreators(
      setExitInProcessInvestmentsData,
      dispatch
    ),
    setPipelineInvestmentsData: bindActionCreators(
      setPipelineInvestmentsData,
      dispatch
    ),
    setRecommendationData: bindActionCreators(setRecommendationData, dispatch),
    setPopover: bindActionCreators(setPopover, dispatch),
    resetPopover: bindActionCreators(resetPopover, dispatch),
    setToDoPopover: bindActionCreators(setToDoPopover, dispatch),
    setUserData: bindActionCreators(setUserData, dispatch),
    resetToDoPopover: bindActionCreators(resetToDoPopover, dispatch),
    filterChange: (type, selectedValue) => dispatch({ type, selectedValue }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainContainer)
);

import axios from 'axios';
import React, { Component } from 'react';
import swal from 'sweetalert';

import common from '../../common';
import Header from '../../components/Header';
import HeaderImage from '../../components/HeaderImage';
import LoadingSpinner from '../../components/Loader';
import ExitChecklistManagement from '../ExitChecklistManagement/exitChecklistManagement.container';
import FundingInstructions from '../FundingInstructions/FundingInstructionsSettings';
import TempleteEmail from '../GlobalSettings/components/TempleteEmail/TempleteEmail';
import './investmentFollowup.scss';
import InvestmentFollowupMobileView from './InvestmentFollowupMobileView';
import WireToStartup from './WireToStartup';
import YearInReviewSetting from './YearReviewSettings';

class GlobalSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      userRoleCode: '',
      investmentName: '',
      role: '',
      name: '',
      investmentData: null,
      leftMenu: [
        { label: 'Investor Follow Up', isActive: false },
        { label: 'Funding Instructions', isActive: false },
        { label: 'Exit Checklist Managment', isActive: true },
        { label: 'TSG Review', isActive: false },
        { label: 'Template', isActive: false, isToggle: false },
        { label: 'Wire To Startup', isActive: false },
      ],
      tempSubMenu: [
        { label: 'Email', isActive: true },
        { label: 'SMS', isActive: false },
      ],
      invited: { duration: 1, times: 1 },
      participated: { duration: 1, times: 1 },
      interested: { duration: 1, times: 1 },
      executed: { duration: 1, times: 1 },
      daysOptions: [
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 },
        { label: 6, value: 6 },
        { label: 7, value: 7 },
        { label: 8, value: 8 },
        { label: 9, value: 9 },
        { label: 10, value: 10 },
        { label: 11, value: 11 },
        { label: 12, value: 12 },
        { label: 13, value: 13 },
        { label: 14, value: 14 },
        { label: 15, value: 15 },

      ],
      timeOptions: [
        { label: 0, value: 0 },
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 },
      ],
    }
  }

  componentDidMount() {
    if (this.props.location
      && this.props.match
      && this.props.match.params
      && this.props.match.params.id) {
      this.setState({
        userRoleCode: window.localStorage.getItem('roleCode'),
        role: window.localStorage.getItem('role'),
        name: window.localStorage.getItem('username'),
      });
    } else {
      this.props.history.replace('/dashboard');
    }
    this.getInvestmentSettings();
  }

  onDaysChange = (event, type) => {
    this.setState({
      [type]: { ...this.state[type], duration: +event.target.value }
    });
  };

  onTimeChange = (event, type) => {
    this.setState({
      [type]: { ...this.state[type], times: +event.target.value }
    })
  };

  getInvestmentSettings() {
    const temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem('token');
    axios.get(`${common.path.base_url}/investmentFollowUpSettings/${temp.props.match.params.id}`, {
      headers: {
        "Authorization": token
      }
    })
      .then(function (response) {
        temp.setState({ loading: false });
        if (response && response.data && response.data.data && response.data.data.name) {
          temp.setState({ investmentName: response.data.data.name })
          temp.setState({ investmentData: response.data.data });
        }
        if (response && response.data && response.data.data && response.data.data.automatedFollowUP) {
          temp.setState({ ...response.data.data.automatedFollowUP });
        } else {
          temp.getGlobalFollowupSettings();
        }
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
          window.localStorage.clear();
          window.location.href = '/';
          return;
        }
        else {
          swal({
            title: "Network Error!",
            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  };

  getGlobalFollowupSettings() {
    const temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem('token');
    axios.get(`${common.path.base_url}/followUpSettings`, {
      headers: {
        "Authorization": token
      }
    })
      .then(function (response) {
        temp.setState({ loading: false });
        if (response && response.data && response.data.data && response.data.data.automatedFollowUP) {
          temp.setState({ ...response.data.data.automatedFollowUP });
        }
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        swal({
          title: "Network Error!",
          text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
          icon: "error",
          button: "Ok!",
        });
      });
  };

  saveInvestmentSettings() {
    const temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem('token');
    axios.post(`${common.path.base_url}/investmentFollowUpSettings/${temp.props.match.params.id}`, {
      automatedFollowUP: {
        invited: this.state.invited,
        participated: this.state.participated,
        interested: this.state.interested,
        executed: this.state.executed,
      },
    },
      {
        headers: {
          "Authorization": token
        }
      })
      .then(function (response) {
        temp.setState({ loading: false });
        if (response && response.data && response.data.message) {
          swal({
            title: "Settings Done",
            text: response.data.message,
            icon: "success",
            button: "Ok!",
          });
        }
        temp.getInvestmentSettings();
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        swal({
          title: "Network Error!",
          text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
          icon: "error",
          button: "Ok!",
        });
        temp.getInvestmentSettings();
      });
  };
  onLeftMenuClick = (index) => {
    const leftMenuClone = this.state.leftMenu.map(singleMenu => {
      singleMenu.isActive = false;
      return singleMenu;
    });
    leftMenuClone[index].isActive = true;
    this.setState({ leftMenu: leftMenuClone });
  }

  subMenuTag = (index) => {
    
    const tempSubClone = this.state.tempSubMenu.map(singleMenu => {
      singleMenu.isActive = false;
      return singleMenu;
    });
    tempSubClone[index].isActive = true;
    this.setState({ tempSubMenu: tempSubClone });
  
  }
  
  render() {
    return (
      <div className="investment-followup">

        {this.state.loading ? <LoadingSpinner /> : ""}
        <Header
          username={this.state.name}
          role={this.state.role}
        />
        {
          (this.state.userRoleCode === '001' || this.state.userRoleCode === '002') &&
          <HeaderImage
            heading={this.state.currentInvestorName ? this.state.currentInvestorName : this.state.role}
          />
        }

        <div className="title-section wrapper">
          <div className="header-txt">Investment Settings {this.state.investmentName && ` - ${this.state.investmentName}`}</div>
        </div>
        <div className="hr-line wrapper"></div>

        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-2 col-sm-2">
              <div className="left-menu">
                <ul>
                  {
                    this.state.leftMenu.map((singleMenu, index) => {

                      if(index != 4) {

                        return <li key={index} onClick={() => this.onLeftMenuClick(index)} >
                                  <span className={singleMenu.isActive ? 'active' : ''}>{singleMenu.label}</span>
                              </li>
                      } else {
                        return <li key={index} onClick={() => this.onLeftMenuClick(index)} className="">
                              <span className={singleMenu.isActive ? 'active' : ''}>{singleMenu.label}<i className="fa fa-angle-down pull-right" aria-hidden="true"></i></span>
                              
                              <ul className={ singleMenu.isActive ? 'temp-menu-css' : 'temp-view-menu' }>
                                {
                                  this.state.tempSubMenu.map((subMenu, subIndex) => {
                                    return <li key={subIndex}><span className={subMenu.isActive ? 'active' : ''} onClick={() => this.subMenuTag(subIndex)} >{subMenu.label }</span></li>
                                  })
                                }
                              </ul>
                           </li>
                      }     
                    })
                  }
                </ul>
              </div>
            </div>

            <div className="col-lg-10 col-md-10 col-sm-10">
              {
                this.state.leftMenu[0].isActive &&
                <>
                  <div className="investors-followup desktop-view">
                    <h2>Investor Follow Up Settings</h2>
                    <table>
                      <thead>
                        <tr>
                          <th width="20%">Investment Stage</th>
                          <th width="20%">Duration(Days)</th>
                          <th width="20%">Times</th>
                          <th width="40%"></th>
                        </tr>
                      </thead>
                      <tbody>

                        <tr>
                          <td>
                            <div className="document">Invited <span>(Pending Invited User)</span></div>
                          </td>
                          <td>
                            <div className="select-item">
                              <select className="form-control" value={this.state.invited.duration} onChange={(event) => this.onDaysChange(event, 'invited')}>
                                {
                                  this.state.daysOptions.map((singleDay, index) => {
                                    return <option value={singleDay.value} key={index}>{singleDay.label}</option>
                                  })
                                }
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="select-item">
                              <select className="form-control" value={this.state.invited.times} onChange={(event) => this.onTimeChange(event, 'invited')}>
                                {
                                  this.state.timeOptions.map((singleTime, index) => {
                                    return <option value={singleTime.value} key={index}>{singleTime.label}</option>
                                  })
                                }
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="notified">Investor will be notified every<span>{this.state.invited.duration} days</span>for<span>{this.state.invited.times} times</span></div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="document">Participated <span>(Generete Docs Pending)</span></div>
                          </td>
                          <td>
                            <div className="select-item">
                              <select className="form-control" value={this.state.participated.duration} onChange={(event) => this.onDaysChange(event, 'participated')}>
                                {
                                  this.state.daysOptions.map((singleDay, index) => {
                                    return <option value={singleDay.value} key={index}>{singleDay.label}</option>
                                  })
                                }
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="select-item">
                              <select className="form-control" value={this.state.participated.times} onChange={(event) => this.onTimeChange(event, 'participated')}>
                                {
                                  this.state.timeOptions.map((singleTime, index) => {
                                    return <option value={singleTime.value} key={index}>{singleTime.label}</option>
                                  })
                                }
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="notified">Investor will be notified every<span>{this.state.participated.duration} days</span>for<span>{this.state.participated.times} times</span></div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="document">Generated Docs <span>(Docs Sign Pending)</span></div>
                          </td>
                          <td>
                            <div className="select-item">
                              <select className="form-control" value={this.state.interested.duration} onChange={(event) => this.onDaysChange(event, 'interested')}>
                                {
                                  this.state.daysOptions.map((singleDay, index) => {
                                    return <option value={singleDay.value} key={index}>{singleDay.label}</option>
                                  })
                                }
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="select-item">
                              <select className="form-control" value={this.state.interested.times} onChange={(event) => this.onTimeChange(event, 'interested')}>
                                {
                                  this.state.timeOptions.map((singleTime, index) => {
                                    return <option value={singleTime.value} key={index}>{singleTime.label}</option>
                                  })
                                }
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="notified">Investor will be notified every<span>{this.state.interested.duration} days</span>for<span>{this.state.interested.times} times</span></div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="document">Signed Docs <span>(Fund Pending)</span></div>
                          </td>
                          <td>
                            <div className="select-item">
                              <select className="form-control" value={this.state.executed.duration} onChange={(event) => this.onDaysChange(event, 'executed')}>
                                {
                                  this.state.daysOptions.map((singleDay, index) => {
                                    return <option value={singleDay.value} key={index}>{singleDay.label}</option>
                                  })
                                }
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="select-item">
                              <select className="form-control" value={this.state.executed.times} onChange={(event) => this.onTimeChange(event, 'executed')}>
                                {
                                  this.state.timeOptions.map((singleTime, index) => {
                                    return <option value={singleTime.value} key={index}>{singleTime.label}</option>
                                  })
                                }
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="notified">Investor will be notified every<span>{this.state.executed.duration} days</span>for<span>{this.state.executed.times} times</span></div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button className="tsg-btn" onClick={() => this.saveInvestmentSettings()}>Save</button>
                  </div>
                  <InvestmentFollowupMobileView
                    participated={this.state.participated}
                    interested={this.state.interested}
                    executed={this.state.executed}
                    daysOptions={this.state.daysOptions}
                    timeOptions={this.state.timeOptions}
                    onDaysChange={(event, type) => this.onDaysChange(event, type)}
                    onTimeChange={(event, type) => this.onTimeChange(event, type)}
                    saveInvestmentSettings={() => this.saveInvestmentSettings()}
                  />
                </>

              }
              {
                this.state.leftMenu[1].isActive &&
                <FundingInstructions
                  {...this.props}
                />
              }
              {
                this.state.leftMenu[2].isActive &&
                <ExitChecklistManagement
                  investmentId={this.props.match.params.id}
                />
              }
              {
                this.state.leftMenu[3].isActive &&
                <YearInReviewSetting
                  {...this.props}
                 />
              }
              {
                this.state.leftMenu[4].isActive &&
                <TempleteEmail
                getTempSubMenu={this.state.tempSubMenu}
                investmentId={ this.props.match.params.id }
                {...this.props}
                />
              }
              {
                this.state.leftMenu[5].isActive &&
                <WireToStartup
                  investmentData={this.state.investmentData}
                  {...this.props}
                 />
              }
              
            </div>
          </div>





        </div>
      </div>
    );
  }
}

export default GlobalSettings;
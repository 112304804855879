import React, { Component } from 'react';
import swal from 'sweetalert';
import axios from 'axios';

import LoadingSpinner from '../Loader';
import common from '../../common';
class UnsubscribeEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userId: '',
    }
  }

  componentDidMount() {
    if (this.props.match
      && this.props.match.params
      && this.props.match.params.userId
    ) {
      this.setState({
        userId: this.props.match.params.userId,
      });
      this.getUnsubscribeStatus();
    } else {
      this.props.history.replace('/');
    }
  }

  getUnsubscribeStatus = () => {
    let temp = this;
    axios.get(`${common.path.base_url}/unsubscribeEmail/${this.props.match.params.userId}`, {})
      .then((response) => {
        temp.confirmUnsubscribe();
      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
          window.localStorage.clear();
          window.location.href = '/';
          return;
        }
        else if (error.response && error.response.data.code === 204) {
          window.localStorage.clear();
          window.location.href = '/';
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.response,
            icon: "error",
            button: "Ok!",
          })
            .then(res => temp.props.history.replace('/'));
        } else {
          swal({
            title: "Error! #485250587@DS",
            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
        temp.setState({ loading: false });
      });
  };

  unsubscribeEmail = () => {
    let temp = this;
    axios.post(`${common.path.base_url}/unsubscribeEmail/${this.state.userId}`, {})
      .then((response) => {
        temp.setState({ loading: false });
        swal({
          title: "Unsubscribed",
          text: response.data.message,
          icon: "success",
          button: "Ok!",
        })
          .then(() => temp.props.history.replace('/'));
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
          window.localStorage.clear();
          window.location.href = '/';
          return;
        }
        else if (error.response && error.response.data.code === 204) {
          window.localStorage.clear();
          window.location.href = '/';
        } else {
          if (error.response) {
            swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              button: "Ok!",
            })
              .then(res => temp.props.history.replace('/'));
          } else {
            swal({
              title: "Network Error!",
              text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        }
      });
  };

  confirmUnsubscribe = () => {
    let temp = this;
    swal({
      title: "Unsubscribe",
      text: 'Are you sure you want to unsubscribe? Access to the TSG Platform will no longer be available.',
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(confirmed => {
        if (confirmed) {
          temp.setState({ loading: true });
          temp.unsubscribeEmail();
        }
        else {
          this.props.history.replace('/');
        }
      });
  }

  render() {
    return (
      <div className="unsubscribe-email">
        { this.state.loading ? <LoadingSpinner /> : ""}
      </div>
    )
  }
}

export default UnsubscribeEmail;
import React, { Component } from 'react';
import './signOA.scss';
import Header from '../../../components/Header';
import HeaderImg from '../../../components/HeaderImage';
import LoadingSpinner from '../../../components/Loader';
import common from '../../../common';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import ReactToPrint from 'react-to-print';
import TButton from '../../../components/TButton';
// import { Document, Page, pdfjs } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



class SignOA extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            signOA: null,
            numPages: null,
            setNumPages: [],
            pageNumber: 1,
            setPageNumber: 1

        }

    }
    onDocumentLoadSuccess(pdf) {
        // console.log(31, pdf._pdfInfo.numPages);
        const setNumPages = [];
        if (pdf._pdfInfo.numPages > 0) {

            for (let i = 0; i < pdf._pdfInfo.numPages; i++) {
                setNumPages.push(i + 1);
            }
        }
        console.log(setNumPages);
        this.setState({ numPages: pdf._pdfInfo.numPages, setNumPages });

    }

    componentWillMount() {
        const url = new URL(window.location.href);
        this.investmentId = url.searchParams.get('investmentId');
        this.getSignOA();
    }
    getSignOA() {
        let temp = this;
        let token = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/getSignOA/${this.investmentId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                console.log(60, response.data.data);
                if (response && response.data && response.data.data && response.data.data) {
                    temp.setState({ signOA: response.data.data })
                } else {
                    // window.location.href=`/preview?companyId=${this.companyId}&investmentId=${this.investmentId}`
                }

            })
            .catch(function (error) {
                temp.setState({ loadinglada: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error && error.response && error.response.data && error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }
    redirect() {
        this.props.history.goBack()
    }
    printIframe = (id) => {
        const iframe = document.frames
            ? document.frames[id]
            : document.getElementById(id);
        const iframeWindow = iframe.contentWindow || iframe;

        iframe.focus();
        iframeWindow.print();

        return false;
    };

    render() {
        const username = window.localStorage.getItem("username");
        const signOA = this.state.signOA;
        return (
            <div>
                <div className="preview">
                    {this.state.loading ? <LoadingSpinner /> : ""}
                    <Header username={username} role={this.state.Role} />
                    <HeaderImg heading={`Operating Agreement`} />
                    <div className="preview-main" ref={elements => this.componentRef = elements}>
                        <div className="close-btn icons">
                            {/* <ReactToPrint
                                trigger={() => <TButton text={`Print`} className='share-btn' />}
                                content={() => this.componentRef}
                                pageStyle={'padding : 40px'}
                            />                           
                         */}
                            {/* <TButton text={`Print`} className='share-btn' onClick={()=>this.printIframe('printIIR')} /> */}
                        </div>
                        {/* <div className="cancel-btn-wraper">
                            <button type="button" className="close" aria-label="Close" onClick={() => this.redirect()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div> */}
                        {
                            signOA ?
                                <>

                                    <iframe
                                        name='Sign OA'
                                        id="signOA"
                                        className="embed-responsive-item"
                                        src={`${common.path.base_url}/getFileNew?fileId=${signOA}`}
                                        title="Sign OA"
                                        allowFullScreen
                                        height="1000px"
                                        width="100%"
                                    >
                                    </iframe>
                                </> :
                                <>
                                    <h5 style={{ textAlign: 'center' }}>Document not uploaded yet!</h5>
                                </>

                        }
                        {
                            signOA &&
                            <div>
                                {/* <Document
                                file={`${common.path.base_url}/getDocument?fileId=${iirPDF}`}
                                onLoadSuccess={(pdf) => this.onDocumentLoadSuccess(pdf)}
                            >
                            
                            {
                                this.state.setNumPages.length > 0 &&
                                this.state.setNumPages.map(page => (
                                    <Page pageNumber={page} />
                                ))}
                            </Document> */}

                                {/* <p>Page {this.state.pageNumber} of {this.state.numPages}</p> */}
                            </div>
                        }


                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(SignOA);
import React, { useState, useEffect } from 'react';
import './pipelineCss/pipeline.scss';
import './pipelineCss/default.scss';

const LastRoundLister = ({ seedArray }) => {
  const [list, setList] = useState([]);
  useEffect(() => {
    if (seedArray && seedArray.length) {
      for (let index = seedArray.length - 1; index >= 0; index--) {
        const element = seedArray[index];
        if (element.raised !== '') {
          setList([element]);
          break;
        }
      }
    }
  }, [seedArray]);

  return (
    <>
      {list.length > 0 && (
        <div className="lastRound mt-20">
          <div className="title">Last Round</div>
          <div className="details">
            {list.map((item) => {
              return (
                <div key={item.date} className="lastRoundList mt-5">
                  <div>
                    <div className="title">Series</div>
                    <div>{item.series}</div>
                  </div>
                  <div>
                    <div className="title">Date</div>
                    <div>{item.date}</div>
                  </div>
                  <div>
                    <div className="title">Raised</div>
                    <div>{item.raised}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default LastRoundLister;


import React, { Component } from 'react'
import Header from '../../components/Header';
import NewAddPipeline from '../components/NewAddPipeline';

const Pipeline = props => {

    return (
        <>  
            <Header
            username={window.localStorage.getItem('username')}
            role={window.localStorage.getItem('role')}
            />
            <NewAddPipeline { ...props } />
                
        </>
    )       
}

export default Pipeline;
import React from "react";
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from "react-accessible-accordion";
import NumberFormat from "react-number-format";
import common from "../../../common";
import TButton from "../../../components/TButton";
import images from "../../../images";
import { companyValutionFormat, getCompanyLogoFromS3 } from "../../shared/helper";
import useScreenWidthLessThan768 from "../../shared/window_resize_hook";
import "./MyInvestments.scss";
import { getUserInvestmentAmount, mapDateFormat } from "./helper";

const DocumentAccordionItem = (props) => {
  const { documents } = props;
  return (
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton>
          <div className="folder-wraper">
            <div className="folder-name">
              <img src={images.path.folder} alt=""></img>
              {props.title}
            </div>
            {props.isUploadEnable && (
              <div className="upload-btn">
                <TButton
                  id="edit-btn"
                  text={`UPLOAD`}
                  onClick={() =>
                    props.uploadClickHnadler(props.myInvestment) &&
                    props.uploadClickHnadler()
                  }
                  tsgBtnclassName="tsg-btn"
                />
              </div>
            )}
          </div>
        </AccordionItemButton>
      </AccordionItemHeading>
      {documents &&
        documents.length > 0 &&
        documents.map((doc) => {
          return (
            <AccordionItemPanel>
              <div className="accordion-section-inner">
                <div className="accordion-section-inner-file">
                  <div className="accordion-left">
                    <div className="file-name">
                      <a
                        className="file-link"
                        target="_blank"
                        href={`${common.path.doc_download_url}/downloadInvestmentDoc?fileId=${doc._id}&id=${doc.investmentId}`}
                        download
                      >
                        {doc.name.split(".")[0]}
                      </a>
                    </div>
                    <div className="date">
                      {mapDateFormat(doc.createdAt)} |{" "}
                      {doc.name.split(".").pop().toUpperCase()}
                    </div>
                  </div>
                  <div className="accordion-right"></div>
                </div>
              </div>
            </AccordionItemPanel>
          );
        })}
    </AccordionItem>
  );
};

const Documents = (props) => {
  const roleCode = window.localStorage.getItem("roleCode");
  const { documents } = props.myInvestment;
  const myDocuments = documents
    .filter((doc) => doc.folderName === "My Documents")
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const taxDocuments = documents
    .filter((doc) => doc.folderName === "Tax Documents")
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const investmentDocuments = documents
    .filter((doc) => doc.folderName === "Investment Documents")
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const otherDocuments = documents
    .filter((doc) => doc.folderName === "Other Documents")
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  return (
    <div className="upload_document">
      <TButton
        id="edit-btn"
        text={`UPLOAD`}
        onClick={() =>
          props.uploadClickHnadler(props.myInvestment) &&
          props.uploadClickHnadler()
        }
        tsgBtnclassName="btn btn-danger"
      />
    </div>
  );
};

const redirect = (props) => {
  const { myInvestment } = props;
  if (props.investorId) {
    window.location.href = `/investment-detail?investmentId=${myInvestment.investment._id}&investorId=${props.investorId}`;
  } else {
    window.location.href = `/investment-detail?investmentId=${myInvestment.investment._id}`;
  }
};
const redirectFundInfo = (props) => {
  const { myInvestment } = props;
  window.location.href = `/investment-process?isFund=true&investmentId=${myInvestment.investment._id}`;
};

const iconRedirect = (data, type) => {
  const investment = data.investment;
  switch (type) {
    case "IIR":
      //window.open(`/iirPreviewPDF?companyId=${investment.companyId}&investmentId=${investment._id}`, '_blank')
      window.open(
        `/previewIIR?companyId=${investment.companyId}&investmentId=${investment._id}`,
        "_self"
      );
      break;
    case "EL":
      window.open(
        `/foundLetterPreview?companyId=${investment.companyId}&investmentId=${investment._id}`,
        "_self"
      );
      break;
    case "FAQ":
      window.open(`/investorFaqPreview`, "_self");
      break;
    default:
      break;
  }
};

const GridCard = (props) => {
  const { myInvestment, isCollapsed } = props;
  const roleCode = window.localStorage.getItem("roleCode");
  const isScreenResized = useScreenWidthLessThan768();

  function webinarPopup(userId, investmentId) {
    window.location.href =
      "/webinar-request?investor=" + userId + "&investment=" + investmentId;
  }
  console.log(myInvestment.investment);

  return (
    <div
      className={
        isCollapsed
          ? "col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xxl-3 card-min-width"
          : "col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xxl-3"
      }
      style={{
        minWidth: isScreenResized ? window.innerWidth : ""
      }}
    >
      <div className="card my-custom-card" key={myInvestment.investment._id}>
        <div className="item_box">
          <div
            className="series"
            style={{ cursor: "pointer" }}
            onClick={() => redirect(props)}
          >
            <div className="row">
              {/* <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3"> */}
              {/* </div> */}
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="text-center">
                  <img src={getCompanyLogoFromS3(myInvestment.company)} alt="" />
                  <p>{myInvestment.investment.name}</p>
                  <p>
                    {getUserInvestmentAmount(
                      myInvestment._id,
                      myInvestment.investment.userInvestments
                    ) ? (
                      <NumberFormat
                        fixedDecimalScale={true}
                        decimalScale={2}
                        value={getUserInvestmentAmount(
                          myInvestment._id,
                          myInvestment.investment.userInvestments
                        )}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    ) : (
                      <span>N/A</span>
                    )}
                  </p>
                  {/* {myInvestment.investment.stage === "closed" ? (
                    <button className="btn-success dark-bg">CLOSED</button>
                  ) : (
                    <button className="btn-success">COMPLETE</button>
                  )} */}
                </div>
              </div>
            </div>
          </div>
          <div className="my_prices">
            <table className="desktop_view">
              <tbody>
                <tr>
                  <td className="content-left">
                    <>&nbsp;</>
                    <span>
                      {mapDateFormat(myInvestment.investment.closedDate)}
                    </span>
                    <p>Close Date</p>
                  </td>
                  <td>
                    <span>
                      {myInvestment.investment &&
                        myInvestment.investment.valuationType ? (
                        myInvestment.investment.valuationType
                      ) : (
                        <span>&nbsp;</span>
                      )}
                    </span>

                    <span className="valuation-secondary">
                      {myInvestment.investment &&
                        myInvestment.investment.shareType &&
                        myInvestment.investment.shareType === "Common" &&
                        "~ "}
                      <NumberFormat
                        displayType={"text"}
                        value={
                          myInvestment.investment.companyValuation &&
                          companyValutionFormat(
                            myInvestment.investment.companyValuation
                          )
                        }
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </span>
                    <p>Valuation</p>
                  </td>
                  <td className="content-right">
                    <>&nbsp;</>
                    <span>
                      <NumberFormat
                        fixedDecimalScale={true}
                        value={myInvestment.investment.pricePerShare}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </span>
                    <p>PPS</p>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* <table className="mobile_view">
              <tbody>
                <tr>
                  <td>
                    <p>Close Date</p>
                  </td>
                  <td>
                    {" "}
                    <span>
                      {mapDateFormat(myInvestment.investment.closedDate)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Valuation</p>
                  </td>
                  <td>
                    <span className="valuation-secondary">
                      {myInvestment.investment &&
                        myInvestment.investment.shareType &&
                        myInvestment.investment.shareType === "Common" &&
                        "~ "}
                      //  <span>
                      //   {myInvestment.investment &&
                      //     myInvestment.investment.valuationType != undefined ? myInvestment.investment.valuationType : <>&nbsp;</>}
                      // </span> 
                      <NumberFormat
                        displayType={"text"}
                        value={
                          myInvestment.investment.companyValuation &&
                          companyValutionFormat(
                            myInvestment.investment.companyValuation
                          )
                        }
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>PPS</p>
                  </td>
                  <td>
                    <span>
                      <NumberFormat
                        value={myInvestment.investment.pricePerShare}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table> */}
          </div>
          <div className="detail-page">
            <div className="row">
              {/* <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xxl-6">
                <span
                  title="IIR"
                  className="view-span-icon-iir"
                  onClick={() => {
                    iconRedirect(props.myInvestment, "IIR");
                  }}
                >
                  <img
                    style={{ width: "20px" }}
                    src={images.path.iirDocIcon}
                    alt=""
                  />
                </span>
                <span
                  title="Executive Letter"
                  className="view-span-icon-ex"
                  onClick={() => {
                    iconRedirect(props.myInvestment, "EL");
                  }}
                >
                  <img
                    style={{ width: "18px" }}
                    src={images.path.googleDocs}
                    alt=""
                  />
                </span>
                <span
                  title="FAQs"
                  className="view-span-icon-faq"
                  onClick={() => {
                    iconRedirect(props.myInvestment, "FAQ");
                  }}
                >
                  <img
                    style={{ width: "16px" }}
                    src={images.path.question}
                    alt=""
                  />
                </span>
                {myInvestment.investment &&
                  ((myInvestment.investment.webinarLink1 != null &&
                    myInvestment.investment.webinarLink1.link != null) ||
                    (myInvestment.investment.webinarLink2 != null &&
                      myInvestment.investment.webinarLink2.link)) &&
                  // myInvestment.investment.stage !== "closed" && 
                  (
                    <div
                      style={{ "text-align": "center" }}
                      onClick={() =>
                        webinarPopup(
                          myInvestment._id,
                          myInvestment.investment._id
                        )
                      }
                    >
                      <span
                        title="WEBINAR"
                        className="view-span-icon-webinar"
                        style={{}}
                        onClick={() =>
                          webinarPopup(
                            myInvestment._id,
                            myInvestment.investment._id
                          )
                        }
                      >
                        <img
                          style={{ width: "40px" }}
                          src={images.path.webinar_link}
                          alt=""
                        />
                      </span>
                    </div>
                  )}
              </div> */}
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xxl-6" style={{ paddingLeft: 0, display: "flex", justifyContent: "center" }}>
                <button
                  className="btn btn-danger"
                  style={{
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    padding: "18px",
                    whiteSpace: "nowrap",
                    lineHeight: "15px",
                  }}
                  onClick={() => redirect(props)}
                >
                  View Investment Details
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GridCard;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import ReactTags from 'react-tag-autocomplete'
import './InvestmentDetail.scss';
import TButton from '../../components/TButton';
import NumberFormat from 'react-number-format';
import common from '../../common';
import swal from 'sweetalert';
import axios from 'axios';
import { CSVLink, CSVDownload } from "react-csv";
import ContactSendMessageModal  from '../../components/ContactSendMessageModal';
import { setSummaryInvestmentData  } from '../../SummaryStartups/actions/summaryStartups';

class ExecutedInvestmentDetailUsers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchInputTags: [],
            suggestions: [
                { id: 'YPO', name: 'YPO' },
                { id: 'Institutional', name: 'Institutional' },
                { id: 'Strategic', name: 'Strategic' },
                { id: 'Preferred', name: 'Preferred' },
                { id: 'Investment(s)', name: 'Investment(s)' }],
            arrowType: {
                name: 'down',
                emailAddress: 'down',
                company: 'down',
                investmentAmount: 'down',
                docSignDate: 'down',
            },
            $sort: null,
            listOfUsers:[],
            exportLoading:false,
        }

        this.csvLink = React.createRef();
    }
    componentDidMount() {

        this.props.resetCheckBox();
        this.props.setSummaryInvestmentData(this.props.investmentData);
        if (this.props.id) {
            this.props.history.push(`/investmentDetail?id=${this.props.id}&activeTab=executed`)
        }
        window.addEventListener('scroll', this.handleScroll, false);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
    }

    handleScroll = () => {
        if ((Math.round(window.innerHeight + document.documentElement.scrollTop) === document.documentElement.offsetHeight)
            && (this.props.pagination.currentPage < this.props.pagination.totalPages)) {
            this.props.getExecutedUsers(true, this.mapTags(), this.state.$sort);
        }
    };

    mapDateFormat(mongoDate) {
        if (!mongoDate) return null;
        const monthNames = [
            "01", "02", "03",
            "04", "05", "06", "07",
            "08", "09", "10",
            "11", "12"
        ];
        let date = new Date(mongoDate);
        let day = date.getDate();
        let monthIndex = date.getMonth();
        let year = date.getFullYear();
        return monthNames[monthIndex] + '/' + day + '/' + year;

    }
    mapTags(tags) {
        if (!tags) {
            tags = this.state.searchInputTags;
        }
        let tagIds = []
        if (tags && tags.length > 0) {
            tagIds = tags.map(tag => tag.name)
        }
        return tagIds;
    }
    handleSearchInputDelete(i) {
        const tags = this.state.searchInputTags.slice(0)
        tags.splice(i, 1)
        this.setState({ searchInputTags: tags }, () => {
            this.props.getExecutedUsers(false, this.mapTags(), this.state.$sort);
        });
    }
    handleSearchInputAddition(tag) {
        const tags = [].concat(this.state.searchInputTags, tag);
        this.setState({ searchInputTags: tags }, () => {
            this.props.getExecutedUsers(false, this.mapTags(), this.state.$sort);
        });
    }
    viewDocumentRedirect(envId, type) {
        window.open(` ${common.path.doc_download_url}/downloadPDF?envId=${envId}&type=${type}`, '_blank', '', 2)

    }
    toggleArrow(fieldType) {
        let arrowType = this.state.arrowType;
        let $sort = {};
        if (arrowType[fieldType] === 'down') {
            arrowType[fieldType] = 'up';
            $sort[fieldType] = -1;
        } else {
            arrowType[fieldType] = 'down';
            $sort[fieldType] = 1;
        }
        this.props.getExecutedUsers(false, this.mapTags(), $sort);
        this.setState({ arrowType, $sort });
    }
    getUserCitizenForm(isForeignCitizen) {
        return isForeignCitizen === true ? 'W8' : 'W9'
    }
    onReSendClick(type, userid, investmentId) {
        swal({
            title: "Are you sure?",
            text: "You want to Re-Send this Doc.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willReSend) => {
                if (willReSend) {
                    let temp = this;
                    let token = window.localStorage.getItem('token');
                    temp.setState({ loading: true });
                    const data = {
                        investmentId: investmentId,
                        userId: userid,
                    };
                    if (type === 'isQSA') {
                        data.isQSAResend = true;
                    }
                    if (type === 'isW8') {
                        data.isW8Resend = true;
                    }
                    if (type === 'isW9') {
                        data.isW9Resend = true;
                    }
                    if (type === 'sideletter') {
                        data.isSignSideLetterResend = true;
                    }

                    axios.post(`${common.path.base_url}/reSendDoc`, data, {
                        headers: {
                            "Authorization": token
                        }
                    })
                        .then(function (response) {
                            swal({
                                title: "Updated!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            }).then(() => { 
                                window.location.reload();
                            });
                        })
                        .catch(function (error) {
                            swal({
                                title: "Error!",
                                text: error.response.data.message,
                                icon: "error",
                                button: "Ok!",
                            });
                            temp.setState({ loading: false, loadinglada2: false });
                        });
                }
            });
    }

    needApprovalExportCSV() {
        
        let tokenpass = window.localStorage.getItem('token');
        const data = {
            investmentId:this.props.id
        }
        const tags = this.mapTags();
        if (tags && tags.length > 0) {
            data.tags = tags;
        }
        
        this.setState({ listOfUsers: [], exportLoading:true });

        let getCSVUrl = null;
        axios.post(`${common.path.base_url}/getNeedApprovalExportCSV`, data, {
            headers: {
                "Authorization": tokenpass
            }
        
        }).then((response) => {
            const UserArray = response.data.data;
            //console.log(UserArray);
            //const UserArray = contacts.userArray;
            if(UserArray.length > 0) {
                let arr = [];
                var userExData = new Promise((resolve, reject) => {
                    UserArray.forEach(function(val, index, UserArray) {
                        
                        let ObjCode = { 'First Name': (val.firstName) ? val.firstName : "",
                                        'Middle Name': (val.middleName) ? val.middleName : "",
                                        'Last Name': (val.lastName) ? val.lastName : "",     
                                        Email: (val.emailAddress) ? val.emailAddress : "",
                                        Company: (val.company) ? val.company : "-",
                                        Amount: (val.formattedAmount) ? "$"+val.formattedAmount : "$0.0",
                                        Tags: (val.user.investorCategories) ? val.user.investorCategories.join(', ') : "",  
                                    };
                        arr.push(ObjCode);
                        if (index === UserArray.length -1) resolve();
                    });
                    
                });
                userExData.then(() => {
                    this.setState({ listOfUsers: arr, exportLoading:false });
                    this.csvLink.current.link.click();
                });
            } else {

                swal({
                    title: "Export",
                    text: "Data not found.",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                });
                this.setState({ listOfUsers: [], exportLoading:false });
            }

        }).catch(function (error) {
            console.log(error);
            this.setState({ listOfUsers: [], exportLoading:false });
        });
    } 

    sendAllMessage() {
        document.getElementById('openModal-btn').click();
    }

    resetSMSEmailCard() {
        window.location.reload();
    }

    render() {

        let emailArr = [];
        let mobileArr = [];
        
        if( this.props.getSelectedExecutedUsers().length > 0 ) {
            
            this.props.getSelectedExecutedUsers().map(userLoop => {
                const users = userLoop.user;
                if( users.emailAddress != '' && users.emailAddress != 'undefined' && users.emailAddress != null ) {
                    emailArr.push( users.emailAddress );
                }

                if( users.mobile != '' && users.mobile != 'undefined' && users.mobile != null ) {
                    mobileArr.push( users.mobile );
                }
                
            });
            //this.setState({ allChkEmail : emailArr, allChkMobile : mobileArr });
        }

        return (
            <div className="box-wrapper-main">

                <TButton onClick={() => { }} id='openModal-btn' dataTarget={`#sendMessageModal`} dataToggle={`modal`} style={{ display: 'none' }} />
                <ContactSendMessageModal 
                    allCheckbox= { emailArr }
                    allSelectMobile = { mobileArr }
                    resetSMSEmailCard = { () => this.resetSMSEmailCard() }
                    webinarType="YES"
                    getCheckBoxAll = { this.props.getCheckBoxAll }
                    getRemoveCheckBox = { this.props.getRemoveCheckBox }
                    getSelectedCheckBox = { this.props.getSelectedCheckBox }
                    pageLocation = "needApproval"
                    filterVal = { this.mapTags() }
                />

                <div className='col-xs-12 col-sm-5 search-status allInvestmentDetail header-investment' style={{display: 'contents'}}>
                        <div className='search' style={{ width: '75%' ,padding:15, display:'inline-block'}}>
                            <ReactTags
                                allowNew={true}
                                placeholder='Search'
                                suggestions={this.state.suggestions}
                                tags={this.state.searchInputTags}
                                handleDelete={this.handleSearchInputDelete.bind(this)}
                                handleAddition={this.handleSearchInputAddition.bind(this)} />
                        </div>
                        <div className='follow_up' style={{ width: '11%', display:'inline-block', marginBottom:'-15px' }}>
                            <div className='follow_up' style={{ width: '100%' }}>
                                <a onClick={() => this.needApprovalExportCSV()} className="tsg-btn invite export-btn" >
                                    {
                                        this.state.exportLoading ? 
                                        <><i className="fa fa-spinner fa-spin ex-icon" aria-hidden="true" />Loading </>
                                        :
                                        <><i className="fa fa-file-text ex-icon" aria-hidden="true" />EXPORT</>
                                    }
                                </a>
                                <CSVLink 
                                    data={ this.state.listOfUsers } 
                                    filename={"need-approval.csv"}
                                    className="hidden"
                                    ref={this.csvLink}
                                    target="_blank"
                                />
                            </div>    
                        </div>
                                    
                        <div style={{ width: '12%', display:'inline-block', marginBottom:'-15px' }} >
                            <button type='button' href='javascript:void(0)' className="tsg-btn invite sendMessBtn" onClick={(e) => this.sendAllMessage(e) } disabled={ ((emailArr.length <=0 || mobileArr.length <=0) ? true : false ) }>
                                SEND MESSAGE
                            </button>
                        </div>


                </div>
                <div className="custom-table-view table-responsive">
                    <table className="table table-custom">
                        <thead>
                            <tr>
                                <th>
                                    <input
                                        type="checkbox"
                                        onChange={(event) => this.props.onCheckedAllExecutedUsers(event)}
                                        //checked={this.props.isAllInvitedUserChecked}
                                    />
                                    <label>&nbsp; &nbsp;All</label>
                                </th>
                                <th>NAME <i className={`arrow ${this.state.arrowType['name']}`} onClick={() => this.toggleArrow('name')}></i></th>
                                <th>EMAIL <i className={`arrow ${this.state.arrowType['emailAddress']}`} onClick={() => this.toggleArrow('emailAddress')}></i></th>
                                <th>DATE <i className={`arrow ${this.state.arrowType['docSignDate']}`} onClick={() => this.toggleArrow('docSignDate')}></i></th>
                                <th>COMPANY <i className={`arrow ${this.state.arrowType['company']}`} onClick={() => this.toggleArrow('company')}></i></th>
                                <th>AMOUNT <i className={`arrow ${this.state.arrowType['investmentAmount']}`} onClick={() => this.toggleArrow('investmentAmount')}></i></th>
                                <th>View Documents</th>
                                <th style={{ width:'120px' }} ></th>
                                <th style={{ width:'100px' }} ></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.executedUsers && this.props.executedUsers.length > 0 &&
                                this.props.executedUsers.map(executedUser => {
                                    return (
                                        <tr >
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={executedUser.isChecked}
                                                    onChange={(event) => this.props.onCheckedSingleExecutedUsers(event, executedUser.user._id)}
                                                />
                                            </td>
                                            <td className="username-row" 
                                            // onClick={() => this.props.history.push(`/viewProfile?id=${executedUser.user._id}`)}
                                            onClick={() => { this.props.showDataInPopup(executedUser.user._id) }}>
                                                {`${executedUser.firstName} ${executedUser.lastName}`}
                                            </td>
                                            <td>
                                                {executedUser.emailId}
                                            </td>

                                            <td>
                                                {this.mapDateFormat(executedUser.docSignDate)}
                                            </td>
                                            <td>

                                                {executedUser.investorCompanyName ? executedUser.investorCompanyName : executedUser.companyName ? executedUser.companyName : '-'}
                                            </td>
                                            <td>
                                                <NumberFormat
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                    value={executedUser.amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                <br />
                                                <a style={{ color: '#ef534a', cursor: 'pointer' }} onClick={() => this.props.adjustExceedAmount(executedUser)}>ADJUST</a> &nbsp; &nbsp;
                                            </td>
                                            <td>
                                                <a style={{ color: '#F15B4B', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.viewDocumentRedirect(executedUser.subscriptionEnvId, 'Subscription')}>View QSA Doc.</a> <a style={{ color: '#F15B4B', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.onReSendClick('isQSA', executedUser.user._id, executedUser.investmentId)}>| Re-Send</a><br />
                                                <a style={{ color: '#F15B4B', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.viewDocumentRedirect(executedUser.w9EnvId, this.getUserCitizenForm(executedUser.isForeignCitizen))}>
                                                    {
                                                        executedUser.isForeignCitizen ? 'View W-8 Doc.' : 'View W-9 Doc.'
                                                    }
                                                </a><a style={{ color: '#F15B4B', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.onReSendClick(executedUser.isForeignCitizen ? 'isW8' : 'isW9', executedUser.user._id, executedUser.investmentId)}>| Re-Send</a>
                                                <br />
                                                
                                                
                                                {(!executedUser.sideletterEnvId) ? "" : 
                                                <>
                                                <a style={{ color: '#F15B4B', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.viewDocumentRedirect(executedUser.sideletterEnvId, 'sideletter')}>View Side Letter</a> <a style={{ color: '#F15B4B', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.onReSendClick('sideletter', executedUser.user._id, executedUser.investmentId)}>| Re-Send</a>
                                                {   (executedUser.isFeeAndCarrySet && !executedUser.sideletterEnvId) &&
                                                    <>
                                                        <span style={{ color: '#ef534a', }} >Side Letter Pending</span>
                                                    </>
                                                }
                                                </>    
                                                }
                                            </td>
                                            <td>
                                                { (executedUser.isW9Resend || executedUser.isW8Resend || executedUser.isQSAResend || executedUser.isSignSideLetterResend ) 
                                                ?   
                                                    (<span style={{ color: '#ef534a', }} >Re-Sign Pending</span>) 
                                                :
                                                    <>
                                                        {
                                                        !executedUser.executedDate &&
                                                        <TButton text={`ACCEPT`} className='invite-btn' onClick={() => this.props.acceptParticipation(executedUser._id, (isSuccess) => {
                                                            if (isSuccess) {
                                                                this.setState({ searchInputTags: [] });
                                                            }
                                                        })} />
                                                        }
                                                    </>
                                                }
                                            </td>
                                            {/* <td> */}
                                            {/* {
                                                    !executedUser.executedDate &&
                                                    <TButton text={`ADJUST`} className='invite-btn' onClick={() => this.props.adjustAmount(executedUser)} />
                                                } */}
                                            {/* </td> */}
                                            <td>
                                                <TButton text='CANCEL' className='invite-btn'
                                                    onClick={() => this.props.onCancelInvestment(executedUser._id, 'executed', (isSuccess) => {
                                                        if (isSuccess) {
                                                            this.setState({ searchInputTags: [] });
                                                        }
                                                    })}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                this.props.executedUsers.length === 0 &&
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td> No investors found for executed!</td>
                                </tr>
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        setSummaryInvestmentData: bindActionCreators(setSummaryInvestmentData, dispatch),
    };
};

export default withRouter(connect(null, mapDispatchToProps)(ExecutedInvestmentDetailUsers));
import React, { Component } from 'react';
import './InvestmentOverview.scss';
import TButton from '../../../components/TButton';
import LoadingSpinner1 from '../../../components/IIR Loader';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import common from '../../../common';
import _ from 'lodash';
import RequiredSign from '../../../components/RequiredSign';


class InvestmentOverview extends Component {
    constructor(props) {
        super(props);
        this.companyId = '';
        this.investmentId = '';
        this.iirList = [];
        this.state = {
            sampleArray: [1, 2, 3],
            show: false,
            loading: false,
            loadinglada: false,
            Role: '',
            isEnable: false,
            series: '',
            share: '',
            percentage: '',
            isSeries: false,
            isShare: false,
            isOther: false,
            isPersentage: false,
            investmentOverviewData: [],
            investmentType: null,
            investmentValue: null,
            investmentOverviewId: null,
            message: '',
            customSeries: '',
            customShare: '',
        }
    }

    componentWillMount() {
        const userRole = window.localStorage.getItem('role');
        this.setState({ Role: userRole });
        const url = new URL(window.location.href);
        this.companyId = url.searchParams.get('companyId');
        this.investmentId = url.searchParams.get('investmentId');
        this.getInvestmentOverviewData();

    }

    componentDidUpdate() {
        document.addEventListener('keypress', function (event) {
            if (event.keyCode === 13) {
                // event.preventDefault()
            }
        })
    }

    getIIRList(init = true) {
        let temp = this;
        temp.setState({ loadinglada2: true });
        let token = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/getInvestmentIIR/${this.investmentId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                const sampleArray = response.data.data
                const keys = Object.keys(sampleArray)
                const values = Object.values(sampleArray)
                keys.forEach((key, i) => {
                    this.iirList.push(common.iirMapKey({ key, values, i }))
                })
                if (init) {
                    this.iirList.forEach((ele, i) => {
                        let title = ele.path.split(" ");
                        let path = '';
                        for (const word of title) {
                            path += word;
                        }
                        path = path.substring(0, 1).toLowerCase() + path.substring(1);
                        ele.path = path;
                    })
                    this.iirList.length = common.iirArrayLength;
                    this.props.setIirList(this.iirList);
                }
                temp.setState({ loadinglada2: false });

            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }
    setOptions() {
        let key = null;
        if (this.state.investmentType === 'series') {
            key = 'isSeries';
        } else if (this.state.investmentType === 'share') {
            key = 'isShare';
        } else if (this.state.investmentType === 'percentage') {
            key = 'isPersentage';
        } else if (this.state.investmentType === 'other') {
            key = 'isOther';
        }
        this.setState(
            {
                [key]: true,
                isEnable: true,

            });

    }

    getInvestmentOverviewData() {
        let temp = this;
        // temp.setState({ loading: true });
        let token = window.localStorage.getItem('token');
        const id = window.localStorage.getItem('id');
        this.setState({ id: id })
        axios.get(`${common.path.base_url}/getIIRInvestmentOverview/${this.investmentId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                // this.setState({ investmentOverviewData: response.data.data });
                if (response.data.data && response.data.data.length > 0) {
                    const { data } = response.data;
                    temp.setState(
                        {
                            investmentType: data[0].investmentType,
                            investmentOverviewId: data[0]._id,
                            series: data[0].series || '',
                            share: data[0].share || '',
                            percentage: data[0].percentage || '',
                            message: data[0].message || '',
                            customSeries: data[0].customSeries || '',
                            customShare: data[0].customShare || '',
                        }

                    )
                    this.setOptions();
                } else {
                    this.setState({ investmentOverviewId: null });
                }
            })
            .catch(function (error) {
                console.log(166, error);
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }


    saveInvestmentData() {

        let data = {};
        this.setState({ loading: true });
        let token = window.localStorage.getItem('token');
        if (this.state.isEnable) {
            if (this.state.isSeries) {
                data = {
                    companyId: this.companyId,
                    investmentId: this.investmentId,
                    investmentType: 'series',
                    series: this.state.series,
                    share: this.state.share,
                    message: this.state.message
                }
            } else if (this.state.isShare) {
                data = {
                    companyId: this.companyId,
                    investmentId: this.investmentId,
                    investmentType: 'share',
                    customSeries: this.state.customSeries,
                    customShare: this.state.customShare,
                    message: this.state.message
                }
            } else if (this.state.isPersentage) {
                data = {
                    companyId: this.companyId,
                    investmentId: this.investmentId,
                    investmentType: 'percentage',
                    percentage: this.state.percentage,
                    series: this.state.series,
                    message: this.state.message
                }
            } else if (this.state.isOther) {
                data = {
                    companyId: this.companyId,
                    investmentId: this.investmentId,
                    investmentType: 'other',
                    percentage: null,
                    series: null,
                    message: this.state.message
                }
            }
        }
        let method = 'post';
        let routeName = 'addIIRInvestmentOverview';
        let temp = this;
        if (this.state.investmentOverviewId) {
            data.id = this.state.investmentOverviewId;
            method = 'put';
            routeName = 'updateIIRInvestmentOverview';
        }

        axios[method](`${common.path.base_url}/${routeName}`, data,
            {
                headers: {
                    "Authorization": token
                }
            })
            .then((response) => {
                swal({
                    title: "Success!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(() => {
                        temp.removeStatus();
                        temp.setState({ show: false })
                        temp.getIIRList();
                    })
                temp.setState({ loadinglada: false });
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                    temp.setState({ loadinglada: false });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    removeStatus() {
        this.setState({
            series: '',
            share: '',
            percentage: '',
            isEnable: false,
            customSeries: '',
            customShare: '',
            message: '',
        });
        // this.setState({ share: '' });
        // this.setState({ percentage: '' });
        // this.setState({ isEnable: false });
    }


    handleChange(id, newValue) {
        console.log(id, newValue);

        if (id === "series")
            this.setState({ series: newValue });
        else if (id === 'share')
            this.setState({ share: newValue });
        else if (id === 'percentage')
            this.setState({ percentage: newValue });
        else if (id === 'message')
            this.setState({ message: newValue });
        else if (id === "customSeries")
            this.setState({ customSeries: newValue });
        else if (id === "customShare")
            this.setState({ customShare: newValue });

    }


    formValidaton(id, val) {
        if (id === 'series') {
            if (val === '') {
                this.setState({
                    seriesValidation: "series should not be empty*",
                })
            } else {
                this.setState({
                    seriesValidation: "",
                })
            }
        }
        else if (id === 'share') {
            if (val === '') {
                this.setState({
                    shareValidation: "share should not be empty*",
                })
            } else {
                this.setState({
                    shareValidation: "",
                })
            }
        }
        else if (id === 'percentage') {
            if (val === '') {
                this.setState({
                    percentageValidation: "percentage should not be empty*",
                })
            } else {
                this.setState({
                    percentageValidation: "",
                })
            }
        }
        else if (id === 'customShare') {
            if (val === '') {
                this.setState({
                    customShareValidation: "share should not be empty*",
                })
            } else {
                this.setState({
                    customShareValidation: "",
                })
            }
        }
        else if (id === 'customSeries') {
            if (val === '') {
                this.setState({
                    customSeriesValidation: "series should not be empty*",
                })
            } else {
                this.setState({
                    customSeriesValidation: "",
                })
            }
        }
        else if (id === 'message') {
            if (val === '') {
                this.setState({
                    noteVailidation: "Note should not be empty*",
                })
            } else {
                this.setState({
                    noteVailidation: "",
                })
            }
        }
    }


    setInvestmentType(event) {
        this.setState({ isEnable: true });

        if (event.target.value === 'series') {
            this.setState({ isOther: false, isSeries: true, isShare: false, isPersentage: false, investmentType: 'series', message: '' });
            // this.setState({ isShare: false });
            // this.setState({ isPersentage: false });
        } else if (event.target.value === 'share') {
            this.setState({ isOther: false, isSeries: false, isShare: true, isPersentage: false, investmentType: 'share' });
            // this.setState({ isShare: true });
            // this.setState({ isPersentage: false });
        } else if (event.target.value === 'percentage') {
            this.setState({ isOther: false, isSeries: false, isShare: false, isPersentage: true, investmentType: 'percentage', message: '' });
            // this.setState({ isShare: false });
            // this.setState({ isPersentage: true });
        } else if (event.target.value === 'other') {
            this.setState({ isOther: true, isSeries: false, isShare: false, isPersentage: false, investmentType: 'other', message: '' });

        }
    }
    checkValidation() {
        if (this.state.isSeries) {
            if (this.state.series == '' || this.state.share == '') return true;
            else return false;
        } else if (this.state.isShare) {
            if (this.state.customSeries == '' || this.state.customShare == '') return true;
            else return false;
        } else if (this.state.isPersentage) {
            if (this.state.series == '' || this.state.percentage == '') return true;
            else return false;
        } else if (this.state.isOther) {
            if (this.state.message === '') return true;
            else return false;
        }
        return true;
    }

    render() {
        return (
            <div className="productDesc">
                {this.state.loading ? <LoadingSpinner1 /> : ""}
                <div className="productDesc-main">
                    <div className='heading'>
                        <h3>Investment Overview</h3>
                    </div>
                    <div className="productDesc">
                        <div className="productDesc-details">
                            <form className="form-verticle">
                                <div className="form-group">
                                    <div className="row col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 invest-lbl">
                                            <label> Investment Type: </label>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                                            <div className="row" onChange={(e) => this.setInvestmentType(e)} >
                                                <input className="col-md-1 col-sm-1 col-xs-1" type="radio" value="series" name="investmenttype" checked={this.state.investmentType && this.state.investmentType === 'series'} />
                                                <div className="col-md-8 col-sm-11 col-xs-11">Participating directly in the Series __, buying preferred shares at $__ per share…</div>
                                            </div>
                                            <div className="row" onChange={(e) => this.setInvestmentType(e)}>
                                                <input className="col-md-1 col-sm-1 col-xs-1" type="radio" value="percentage" name="investmenttype" checked={this.state.investmentType && this.state.investmentType === 'percentage'} />
                                                <div className="col-md-8 col-sm-11 col-xs-11">Investing in the future Series __, buying preferred shares in advance at a __% discount to the pricing of the future round…</div>
                                            </div>
                                            <div className="row" onChange={(e) => this.setInvestmentType(e)}>
                                                <input className="col-md-1 col-sm-1 col-xs-1" type="radio" value="other" name="investmenttype" checked={this.state.investmentType && this.state.investmentType === 'other'} />
                                                <div className="col-md-8 col-sm-11 col-xs-11">Other</div>
                                            </div>
                                            {/* <div className="row" onChange={(e) => this.setInvestmentType(e)}>
                                                <input className="col-md-1 col-sm-1 col-xs-1" type="radio" value="share" name="investmenttype" checked={this.state.investmentType && this.state.investmentType === 'share'} />
                                                <div className="col-md-8 col-sm-11 col-xs-11">
                                                    <textarea className="form-control" id="message" type="textarea"
                                                        onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                        placeholder='Type Your Message'
                                                        disabled={this.state.investmentType && this.state.investmentType !== 'share'}
                                                        value={this.state.message} />
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="footBorder"></div>

                    {this.state.isEnable ? <div>
                        <form>
                            {(this.state.isSeries) ?
                                <div className="form-group">
                                    <div className="row investmentTextField">
                                        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                            <label className="control-label investmentValue">Investment Overview :</label>
                                        </div>

                                        <div className="col-md-8">
                                            <textarea className="form-control" id="message" type="textarea" rows="15"
                                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                placeholder='Enter Investment Overview(If Any)'
                                                value={this.state.message} />
                                        </div>
                                    </div>
                                    <div className="row investmentTextField">
                                        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                            <label className="control-label investmentValue">Series <RequiredSign />:</label>
                                        </div>

                                        <div className="col-md-8">
                                            <input className="form-control inputInvestmentType" id="series" type="text" placeholder='Series'
                                                onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                                onChange={(event) => { this.handleChange(event.target.id, event.target.value) }}
                                                value={this.state.series} />
                                            <p>{this.state.seriesValidation}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                            <label className="control-label investmentValue">$ Per Share <RequiredSign /> :</label>
                                        </div>

                                        <div className="col-md-8">
                                            <input className="form-control inputInvestmentType" id="share" type="text" placeholder='$ Per Share'
                                                onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                                onChange={(event) => { this.handleChange(event.target.id, event.target.value) }}
                                                value={this.state.share} />
                                            <p>{this.state.shareValidation}</p>
                                        </div>
                                    </div>
                                </div> :
                                this.state.isPersentage ?
                                    <div className="form-group">
                                        <div className="row investmentTextField">
                                            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                                <label className="control-label investmentValue">Investment Overview :</label>
                                            </div>

                                            <div className="col-md-8">
                                                <textarea className="form-control" id="message" type="textarea" rows="15"
                                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                    placeholder='Enter Investment Overview(If Any)'
                                                    value={this.state.message} />
                                            </div>
                                        </div>
                                        <div className="row investmentTextField">
                                            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                                <label className="control-label investmentValue">Series <RequiredSign /> :</label>
                                            </div>

                                            <div className="col-md-8">
                                                <input className="form-control inputInvestmentType" id="series" type="text" placeholder='Series'
                                                    onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                                    onChange={(event) => { this.handleChange(event.target.id, event.target.value) }}
                                                    value={this.state.series} />
                                                <p>{this.state.seriesValidation}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                                <label className="control-label">% Discount <RequiredSign /> :</label>
                                            </div>

                                            <div className="col-md-8">
                                                <input className="form-control inputInvestmentType" id="percentage" type="text" placeholder='% Discount'
                                                    onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                                    onChange={(event) => { this.handleChange(event.target.id, event.target.value) }}
                                                    value={this.state.percentage} />
                                                <p>{this.state.percentageValidation}</p>
                                            </div>
                                        </div>

                                    </div> :
                                    <div className="form-group">
                                        <div className="row investmentTextField">
                                            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                                <label className="control-label investmentValue">Investment Overview <RequiredSign /> :</label>
                                            </div>

                                            <div className="col-md-8">
                                                <textarea className="form-control" id="message" type="textarea" rows="15"
                                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                    placeholder='Enter Investment Overview'
                                                    value={this.state.message} />
                                            </div>
                                        </div>
                                    </div>

                            }


                            <div className="form-group">
                                <div className="row">
                                    <label className="col-md-3 control-label1"></label>
                                    <div className="col-md-8 updateButton">
                                        <TButton
                                            onClick={() => { this.saveInvestmentData() }}
                                            text={`SAVE`} id="update-btn"
                                            disabled={this.checkValidation()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div> : <div className="form-group">
                        <div className="row">
                            <label className="col-md-3 control-label1"></label>
                            <div className="col-md-8 updateButton">
                                <TButton
                                    onClick={() => { }}
                                    text={`SAVE`} id="update-btn"
                                    disabled={this.checkValidation()}
                                />
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        );
    }
}
// export default Profile;
export default withRouter(InvestmentOverview);
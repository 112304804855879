import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import axios from 'axios';
import swal from 'sweetalert';
import NumberFormat from 'react-number-format';
import common from '../../common';
import LoadingSpinner from '../../components/Loader';
import { isLoading, isNotLoading } from '../../shared/actions/loadingSpinner';
import { setSummaryStartupsData } from '../actions/summaryStartups';
import ContactSendMessageModal  from '../../components/ContactSendMessageModal';
import TButton from '../../components/TButton';

const SummaryTableData = props => {

    // const { summartList, investment } = props;
    let defaultpps = props.pricePerShare;
    let defaultMgfee = props.mgfee;
    let defaultAmtInst = props.amtInst;
    let defaultCarry = props.carry;
    let defaultShares = props.shares;
    let defaultViewMgFee = props.viewMgFee;
    let defaultNetAmt = props.netAmt;
    let allChkEmail = props.allChkEmail;
    let allChkMobile = props.allChkMobile;
    const getCheckBox = props.getCheckBox;
    const getCheckBoxAll = props.getCheckBoxAll;
    let getRemoveCheckBox = props.getRemoveCheckBox;
    let getSelectedCheckBox = props.getSelectedCheckBox;

    //console.log("govind55555", defaultNetAmt, "-", defaultShares, "-", defaultCarry);
    // console.log("govind66666", allChkMobile);
    
    let pricepps = (props.processId.updateRecord && props.processId.updatePricePerShare) ? props.processId.updatePricePerShare : defaultpps;
    let amtInst = (props.processId.updateRecord && props.processId.updateAmount) ? props.processId.updateAmount : defaultAmtInst;
    let mgfee = (props.processId.updateRecord && (props.processId.updateManagementFee >= 0)) ? props.processId.updateManagementFee : defaultMgfee;
    let carry = (props.processId.updateRecord && props.processId.updateCarry) ? props.processId.updateCarry : defaultCarry; 
    
    let mgfeeVal = ( mgfee > 0 ) ?  ( (mgfee / 100) * amtInst ) : 0;
    mgfeeVal = Number(mgfeeVal).toFixed(2);
    amtInst = Number(amtInst).toFixed(2);
    let addNetAmt = Number(amtInst) - Number(mgfeeVal); 
    addNetAmt = Number(addNetAmt).toFixed(2);
    let allShares = ((addNetAmt / pricepps));
    allShares = Number(allShares).toFixed(2);
    
    const [ pricePerShare, setPricePerShare ] = useState(pricepps);
    const [ valShare, setValShare ] = useState(allShares);
    const [ finalAmt, setFinalAmt ] = useState(amtInst);
    const [ netAmt, setNetAmt ] = useState(addNetAmt);
    const [ mgFeePercentage, setMgFeePercentage ] = useState(mgfee);
    const [ viewMgFee, setViewMgFee ] = useState(mgfeeVal);
    const [ mgCarry, setMgCarry ] = useState(carry);
    const [ viewRowUpdate, setRowUpdate ] = useState(0);
    const [ resetUpdate, setResetUpdate ] = useState('');
    
    //const [ companyName, setPricePerShare ] = useState(props.pricePerShare);
    // const pricePerShare = investment.pricePerShare;

    const [updateAllField, setUpdateAllField] = useState('')

    //console.log("asdasdasd", getRemoveCheckBox, "-------", props.processId.user._id);

    useEffect(() => { 
        
    },[ getCheckBox ]);

    useEffect(() => {
        if(updateAllField != "") {
            const delayDebounceFn = setTimeout(() => {
                // Send Axios request here

                let tokenpass = window.localStorage.getItem('token');
                const data = {
                    investmentProcessId: props.processId._id,
                    updateManagementFee : mgFeePercentage,
                    updateAmount: finalAmt,
                    updateCarry: mgCarry,
                    updatePricePerShare :pricePerShare,
                    updateTotalShare :valShare,
                    processType: 'update'
                }
                
                axios.post(`${common.path.base_url}/summary/updateUserInvestmentProcess`, data, {
                    headers: {
                        "Authorization": tokenpass
                    }
                }).then((response) => {
                    
                    const res = response.data;
                    if(!res.error) {
                        const dataCode = res.data;
                        //const listArray = dataCode.investmentProcess;
                        props.updateChanges(dataCode);
                    }   
                    //console.log(res);
                });
            
            }, 1000)

            return () => clearTimeout(delayDebounceFn)
        }
    }, [updateAllField])

    useEffect(() => {
        if(resetUpdate == "1") {
            //console.log("reset code");
            setResetUpdate("0");
        }
    
    }, [finalAmt, netAmt, valShare, pricePerShare, mgCarry, mgFeePercentage, resetUpdate]); 

    function ppsChanges(ppsval) {
        
        const beforeVal = pricePerShare;
        let epps = ppsval;
        let shareval = netAmt / epps; 
        shareval = Number(shareval).toFixed(2);
        
        setPricePerShare(epps);
        setValShare(shareval);

        updateField(epps, beforeVal);

        //console.log(valShare, defaultShares, "---");
    } 
    
    function mgCarryChanges(carryVal) {
        
        const beforeVal = "";
        let cVal = carryVal; 
        cVal = Number(cVal).toFixed(2);
        setMgCarry(cVal);
        updateField(cVal, beforeVal);
    } 

    function mgFeeChanges(feeVal) {
        
        const beforeVal = mgFeePercentage;
        let mgFee = feeVal;
        
        if(mgFee > 100) {
            mgFee = 100; 
        } else if(mgFee < 0 ) {
            mgFee = 0;
        }   
        let totMgFee =  (finalAmt * mgFee)/100;
        totMgFee = Number(totMgFee).toFixed(2);
        
        let netAmtVal = finalAmt - totMgFee;
        netAmtVal = Number(netAmtVal).toFixed(2);

        let shareval = netAmtVal / pricePerShare; 
        shareval = Number(shareval).toFixed(2);
        
        setViewMgFee(totMgFee);
        setNetAmt(netAmtVal);
        setMgFeePercentage(mgFee);
        setValShare(shareval);
        
        updateField(mgFee, beforeVal);
    }

    function valShareChanges(eVal) {
        
        let shareval = eVal;
        
        let epps = (shareval > 0 ) ? (netAmt / shareval) : 0; 
        epps = Number(epps).toFixed(4);

        setPricePerShare(epps);
        setValShare(shareval);
        updateField(shareval);
    } 

    function finalAmtChanges(amtfVal) {
        
        const beforeVal = finalAmt;
        let finalAmtVal = amtfVal;
        // shareVal = Number(shareVal).toFixed(2);
        let totMgFee =  (finalAmtVal * mgFeePercentage)/100;
        totMgFee = Number(totMgFee).toFixed(2);

        let netAmtVal = finalAmtVal - totMgFee;
        netAmtVal = Number(netAmtVal).toFixed(2);

        let shareVal = netAmtVal / pricePerShare;
        shareVal = Number(shareVal).toFixed(2);

        setFinalAmt(finalAmtVal);
        setNetAmt(netAmtVal);
        setValShare(shareVal);
        setViewMgFee(totMgFee);

        updateField(finalAmtVal, beforeVal);
    }

    function updateField(newData, oldData) {
        setUpdateAllField(newData);
    }

    function resetChanges() {
        
        let tokenpass = window.localStorage.getItem('token');
        const data = {
            investmentProcessId: props.processId._id,
            processType: 'reset'
        }
        
        axios.post(`${common.path.base_url}/summary/updateUserInvestmentProcess`, data, {
            headers: {
                "Authorization": tokenpass
            }
        }).then((resp) => {
            let record = resp.data;
            if(record.status == 'success') {
                updateResetChanges();
                setResetUpdate("1");

                const dataCode = record.data;
                //const listArray = dataCode.investmentProcess;
                props.updateChanges(dataCode);
                  
            }
        });
    }

    function updateResetChanges(){

        setPricePerShare(defaultpps);
        setFinalAmt(defaultAmtInst);
        setNetAmt(defaultNetAmt);
        setMgFeePercentage(defaultMgfee);
        setMgCarry(defaultCarry)
        setValShare(defaultShares);
        setViewMgFee(defaultViewMgFee);
    }

    function resetSMSEmailCard(){
        console.log("govind");
    } 

    function setCheckChanges(){
        
        swal({
            title: "Are you sure ?",
            text: "you want to change",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then( action => {
            
            if(action) {
                
                if( true ) {
                    
                    let token = window.localStorage.getItem('token');

                    const formatAmt = Number(finalAmt).toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2});
                    const data = {
                        amount: finalAmt,
                        formattedAmount: formatAmt,
                        processId: props.processId._id,
                        carry : mgCarry,
                        mgfee : mgFeePercentage,
                        pricePerShare : pricePerShare
                    }
                    
                    axios.post(`${common.path.base_url}/summary/changesInvestmentData`, data,
                    {
                        headers: {
                            "Authorization": token
                        }
                    })
                    .then((response) => {
                        //temp.setState({ loadinglada: false });
                        swal({
                            title: "Success!",
                            text: response.data.message,
                            icon: "success",
                            button: "Ok!",
                        }).then(() => { 
                            window.location.reload();
                        });
        
                    })
                    .catch((error) => {
                        
                        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                            window.localStorage.clear();
                            window.location.href = '/';
                            return;
                        }
                        else if (error.response) {
                            swal({
                                title: "Error!",
                                text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                                icon: "error",
                                button: "Ok!",
                            });
        
                        }
                        else {
                            swal({
                                title: "Network Error!",
                                text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                                icon: "error",
                                button: "Ok!",
                            });
                        }
                    });
                    
                }
            }    
        });
    }

    return (
        <>  
            {
                

            }
            {/* <TButton onClick={() => { }} id='openModal-btn' dataTarget={`#sendMessageModal`} dataToggle={`modal`} style={{ display: 'none' }} />
            <ContactSendMessageModal 
                allCheckbox= { getsMSEmailCard() }
                allSelectMobile = { userMobile }
                resetSMSEmailCard = { () => resetSMSEmailCard()}
            />     */}
            {
                <tr className={ (netAmt == Number(defaultNetAmt).toFixed(2) && valShare == defaultShares && mgCarry == defaultCarry) ? '' : 'updatedRow' }>
                    <td>
                        {   
                            <input
                                type="checkbox"
                                onChange={(event) => { event.stopPropagation(); props.onSingleSendMessage( event, props.processId.user )} }
                                checked={ ( getCheckBoxAll && ( !getRemoveCheckBox.includes(props.processId.user._id) ) ||  ( !getCheckBoxAll && getSelectedCheckBox.includes(props.processId.user._id)) ) }
                            />
                        }
                    </td>
                    <td> 
                        { props.name }
                        {
                            (props.processId.status == "Complete") ? <span className='funded-text'>Funded</span> : <span className='inprocess-text'>In Process</span>
                        }
                    </td>
                    <td> { props.companyName }</td>
                    <td> <span className={ ( props.strategicVal.includes('Strategic') ) ? 'greenColor':'' } > { ( props.strategicVal.includes('Strategic') ) ? "Strategic" : "Non-Strategic" } </span></td>
                    <td> 
                        {/* <input style={{ "width":"100px", "padding":"5px 0px 5px 4px" }} type='text' className='inputTable'  value={ finalAmt } onChange={ e => finalAmtChanges(e) }  /> */}
                        <NumberFormat
                            value={ finalAmt }
                            thousandSeparator={true}
                            prefix={'$'}
                            fixedDecimalScale={true}
                            placeholder=''
                            className='inputTable'
                            style={{ "width":"100px", "padding":"5px 0px 5px 4px" }}
                            onChange={ (e) => {
                                let valC = e.target.value;
                                valC = valC.replace(/\,/g,'');
                                valC = valC.replace(/\$/g,'');    
                                finalAmtChanges(valC) }
                            }
                        />
                        { ( Number(props.amtInst) != Number(finalAmt) ) && 
                            <span style={{ 'display':'block', 'fontSize':'12px', 'fontWeight':'500' }}>
                                <i>Initial:${ props.amtInst }</i>
                            </span>
                        }
                    </td>
                    <td> 
                        {/* <input style={{ "width":"50px", "padding":"5px 0px 5px 4px" }} type='text' className='inputTable' value={ mgFeePercentage } onChange={ e => mgFeeChanges(e) } /> */}
                        <NumberFormat
                            value={ mgFeePercentage }
                            thousandSeparator={true}
                            suffix={'%'}
                            fixedDecimalScale={true}
                            className='inputTable'
                            style={{ "width":"50px", "padding":"5px 0px 5px 4px" }}
                            onChange={ (e) => {
                                let valC = e.target.value;
                                valC = valC.replace(/\,/g,'');
                                valC = valC.replace(/\%/g,'');    
                                mgFeeChanges(valC)}
                            }
                        />

                        <span className='mgfeeview-span'>
                            { (viewMgFee) ? "$"+Number(viewMgFee).toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2}) : "" }
                        </span>
                    </td>
                    <td> 
                        {/* <input style={{ "width":"50px", "padding":"5px 0px 5px 4px" }} type='text' className='inputTable' defaultValue={ mgCarry }  /> */}
                        <NumberFormat
                            value={ mgCarry }
                            thousandSeparator={true}
                            suffix={'%'}
                            fixedDecimalScale={true}
                            className='inputTable'
                            style={{ "width":"50px", "padding":"5px 0px 5px 4px" }}
                            onChange={ (e) => {
                                let valC = e.target.value;
                                valC = valC.replace(/\,/g,'');
                                valC = valC.replace(/\%/g,'');    
                                mgCarryChanges(valC)}
                            }
                        />
                    </td>
                    <td> 
                        {/* <input style={{ "width":"70px", "padding":"5px 0px 5px 4px" }} type='text' className='inputTable' onChange={ e => ppsChanges(e) } value={ pricePerShare } /> */}
                        <NumberFormat
                            value={ pricePerShare }
                            thousandSeparator={true}
                            prefix={'$'}
                            fixedDecimalScale={true}
                            placeholder=''
                            className='inputTable'
                            style={{ "width":"70px", "padding":"5px 0px 5px 4px" }}
                            onChange={ (e) => {
                                let valC = e.target.value;
                                valC = valC.replace(/\,/g,'');
                                valC = valC.replace(/\$/g,'');    
                                ppsChanges(valC)}
                            }
                        />
                        { ( Number(props.pricePerShare) != Number(pricePerShare) ) &&
                            <span style={{ 'display':'block', 'fontSize':'12px', 'fontWeight':'500' }}>
                                <i>Initial:${ props.pricePerShare }</i>
                            </span>
                        }
                    </td>
                    <td> 
                        { ( netAmt ) ? '$'+ Number(netAmt).toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2}) : '$0.0' }
                    </td>
                    <td> 
                        {/* <input style={{ "width":"80px", "padding":"5px 0px 5px 4px" }} type='text' className='inputTable' onChange={ e => valShareChanges(e) } value={ valShare } /> */}
                        <NumberFormat
                            value={ valShare }
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            className='inputTable'
                            style={{ "width":"80px", "padding":"5px 0px 5px 4px" }}
                            onChange={ (e) => {
                                let valC = e.target.value;
                                valC = valC.replace(/\,/g,'');
                                valC = valC.replace(/\$/g,'');    
                                valShareChanges(valC)}
                            }
                        />

                        { ( Number(props.shares) != Number(valShare) ) && 
                            <span style={{ 'display':'block', 'fontSize':'12px', 'fontWeight':'500' }}>
                                <i>Initial:{ props.shares }</i>
                            </span>
                        }
                    </td>
                    <td> 
                    
                        {/* <button type={ 'button' }
                            id={ 'asdasd' }
                            className={`icon-table-action`}
                            onClick={ e => props.sendMessage(props.processId.user) }
                            // data-toggle={`modal`}
                            // data-target={`#sendMessageModal`}
                            title = {"Send Message"} 
                        >
                            <i className="fa fa-envelope" aria-hidden="true"></i>
                        </button> */}

                        {/* <span className='icon-table-action' title='Send Mail' onClick={ e => messageSend() }>
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                        </span> */}
                        <span className='icon-table-action' title='Reset' onClick={ e => resetChanges(e) } >
                            <i className="fa fa-repeat" aria-hidden="true"></i>
                        </span>

                        {/* { ( netAmt == defaultNetAmt && valShare == defaultShares && mgCarry == defaultCarry )  ? '' : 
                            <span className='icon-table-action' title='Check Change' onClick={ e => setCheckChanges(e) } >
                                <i class="fa fa-check-circle" aria-hidden="true"></i>
                            </span>
                        } */}
                    </td>
                </tr>
            }
        </>
    );
}

export default SummaryTableData;
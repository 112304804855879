import React, { Component } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import Select from 'react-select';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import DatePicker from "react-datepicker";
import _ from 'lodash';
import LoadingSpinner from '../../../components/Loader';
import './AddInvestment.scss';
import common from '../../../common';
import RequiredSign from '../../../components/RequiredSign';
import NumberFormat from 'react-number-format';






class AddInvestmentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedStages: '',
            stages: [{ value: 'invite', label: 'PIPELINE' }, { value: 'open', label: 'OPEN' }],
            exitProcess: [{ value: 'ipo', label: 'IPO' }, { value: 'acquisition', label: 'Acquisition' }, { value: 'acquisitionwithescrow', label: 'Acquisition with Escrow' }],
            selectedExitProcess: { value: 'ipo', label: 'IPO' },
            pricePerShare: '',
            pricePerShareAtExit : '',
            name: '',
            llcName: '',
            companyValuation: '',
            closedDate: null,
            validInvitaionForm: false,
            companyData: null,
            investmentId: null,
            isSwitchInvestment: false,
            switchInvestmentData: null,
            investments: [],
            investment: null,
            round: null,
            minAmount: null,
            formatedMinAmount: null,
            maxAmount: null,
            formatedMaxAmount: null,
            shareType: 'Preferred',
            disbursementDate: null,
            lockupDate: null,
            acquiringCompanyName: null,
            valuationType: '',
            investmentdeadlineDate: null,
            isShowInvestor: false,
            totalAllocation:'',
        }

        this.selectStage = this.selectStage.bind(this);
    }
    componentWillMount() {

    }
    componentWillReceiveProps(nextProps) {
        nextProps.onRef && nextProps.onRef(this);
    }
    selectStage(selectedStages) {
        if (!_.isEmpty(this.state.pricePerShare && this.state.name && this.state.llcName && this.state.companyValuation && this.state.round)) {
            this.setState({ validInvitaionForm: true, selectedStages })
        } else {
            this.setState({ validInvitaionForm: false, selectedStages })
        }

    }
    changeExitProcess(selectedExitProcess) {
        this.setState({ selectedExitProcess })
        setTimeout(() => {
            this.checkInvestmentFormValidity();
        }, 100);
    }
    formValidaton(id, val) {
        if (id === 'name') {
            if (val === '') {
                this.setState({
                    nameValidationMsg: "Investment name should not be empty*",
                    validInvitaionForm: false
                })
            } else {
                this.setState({
                    nameValidationMsg: "",
                })
            }
        }
        else if (id === 'llcName') {
            if (val === '') {
                this.setState({
                    llcNameValidationMsg: "Investment llc name should not be empty*",
                    validInvitaionForm: false
                })
            } else {
                this.setState({
                    llcNameValidationMsg: "",
                })
            }
        }
        else if (id === 'round') {
            if (val === '') {
                this.setState({
                    roundValidationMsg: "Investment round should not be empty*",
                    validInvitaionForm: false
                })
            } else {
                this.setState({
                    roundValidationMsg: "",
                })
            }
        }
        else if (id === 'pricePerShare') {
            if (val === '') {
                this.setState({
                    pricePerShareValidationMsg: "Investment price per share should not be empty*",
                })
            } else {
                this.setState({
                    pricePerShareValidationMsg: "",
                })
            }
        }
        else if (id === 'totalAllocation') {
            if (val === '') {
                this.setState({
                    totalAllocationValidationMsg: "Investment amount should not be empty*",
                })
            } else {
                this.setState({
                    totalAllocationValidationMsg: "",
                })
            }
        }
        else if (id === 'companyValuation') {
            if (val === '') {
                this.setState({
                    companyValuationValidationMsg: "Investment company valuation should not be empty*",
                    validInvitaionForm: false
                })
            } else {
                this.setState({
                    companyValuationValidationMsg: "",
                })
            }
        }
        setTimeout(() => {
            this.checkInvestmentFormValidity();
        }, 100);

    }
    checkInvestmentFormValidity() {
        if (this.state.selectedStages.value === 'exited' || this.state.selectedStages.value === 'exit in process') {
            if (!this.state.acquiringCompanyName) {
                this.setState({ validInvitaionForm: false });
                return;
            } else if (this.state.selectedExitProcess && this.state.selectedExitProcess.value === 'ipo' && !this.state.lockupDate) {
                this.setState({ validInvitaionForm: false })
                return;
            } else if (this.state.selectedExitProcess && (this.state.selectedExitProcess.value === 'acquisition' || this.state.selectedExitProcess.value === 'acquisitionwithescrow') && !this.state.disbursementDate) {
                this.setState({ validInvitaionForm: false });
                return;
            }

        }
        if (this.state.selectedStages && this.state.pricePerShare && this.state.name && this.state.round && this.state.llcName && this.state.companyValuation && this.state.closedDate && this.state.investmentdeadlineDate && this.state.minAmount && (this.state.minAmount < this.state.maxAmount || !this.state.maxAmount)) {

            this.setState({ validInvitaionForm: true, maxAmountValidationMsg: '' })
        } else {
            if (this.state.minAmount && this.state.maxAmount && this.state.minAmount >= this.state.maxAmount) {
                this.setState({ maxAmountValidationMsg: 'Maximum investment amount should be greater than Minimum amount' });
            } else {
                this.setState({ maxAmountValidationMsg: '' });
            }
            this.setState({ validInvitaionForm: false })

        }

    }
    handleDateChange(date) {
        this.setState({
            closedDate: date
        });
        setTimeout(() => {
            this.checkInvestmentFormValidity();
        }, 100);
    }
    handletextboxChange(value) {
        this.setState({
            isShowInvestor: value
        });
    }
    handleInvestmentDeadlineChange(date) {
        this.setState({
            investmentdeadlineDate: date
        });
        setTimeout(() => {
            this.checkInvestmentFormValidity();
        }, 100);
    }
    handleLockupDateChange(date) {
        this.setState({
            lockupDate: date
        });
        setTimeout(() => {
            this.checkInvestmentFormValidity();
        }, 100);
    }
    handleDisbursementDateChange(date) {
        this.setState({
            disbursementDate: date
        });
        setTimeout(() => {
            this.checkInvestmentFormValidity();
        }, 100);
    }
    setCompanyInvestment(data, _id) {
        const investmentData = { ...data };
        const temp = this;
        investmentData.investmentId = investmentData._id;
        investmentData._id = _id;
        temp.setState({
            loadinglada: false,
            companyData: investmentData,
            isSwitchInvestment: false,
            switchInvestmentData: null,
            investments: [],
            investment: null,
            // stages: [{ value: 'invite', label: 'PIPELINE' }, { value: 'open', label: 'OPEN' }, { value: 'closed', label: 'CLOSED' }, { value: 'exit in process', label: 'EXIT IN PROCESS' }, { value: 'exited', label: 'EXITED' }],
            stages: [{ value: 'invite', label: 'PIPELINE' }, { value: 'open', label: 'OPEN' }, { value: 'closed', label: 'CLOSED' }],
            selectedStages: investmentData.stage ? { value: investmentData.stage, label: investmentData.stage === 'invite' ? 'PIPELINE' : investmentData.stage.toUpperCase() } : '',
            name: investmentData.name,
            pricePerShare: investmentData.pricePerShare,
            pricePerShareAtExit : investmentData.pricePerShareAtExit,
            totalAllocation: (investmentData.totalAllocation) ? investmentData.totalAllocation : "",
            round: investmentData.round,
            minAmount: investmentData.minAmount,
            maxAmount: investmentData.maxAmount,
            companyValuation: investmentData.companyValuation,
            closedDate: investmentData.closedDate ? new Date(investmentData.closedDate) : '',
            investmentId: investmentData.investmentId,
            llcName: investmentData.llcName,
            validInvitaionForm: true,
            shareType: investmentData.shareType,
            valuationType: investmentData.valuationType,
            selectedExitProcess: investmentData.exitProcessType ? { value: investmentData.exitProcessType, label: investmentData.exitProcessType === 'acquisitionwithescrow' ? 'Acquisition with Escrow'.toUpperCase() : investmentData.exitProcessType.toUpperCase() } : [{ value: 'ipo', label: 'IPO' }],
            lockupDate: investmentData.lockupDate ? new Date(investmentData.lockupDate) : null,
            disbursementDate: investmentData.disbursementDate ? new Date(investmentData.disbursementDate) : null,
            investmentdeadlineDate: investmentData.investmentDeadlineDate ? new Date(investmentData.investmentDeadlineDate) : '',
            isShowInvestor: investmentData.isShowToInvestor,
        });
        // let token = window.localStorage.getItem('token');
        // axios['get'](`${common.path.base_url}/investment/${companyData._id}`,
        // {
        //     headers: {
        //         "Authorization": token
        //     }
        // })
        // .then((response) => {
        //     temp.setState({ loadinglada: false });
        //     if (response && response.data && response.data.data) {
        //         const investmentAllData = [...response.data.data];
        //         const investmentData = investmentAllData.splice(investmentAllData.length - 1,1)[0];
        //         if (investmentData) {
        //             this.setState({
        //                 selectedStages: investmentData.stage ? { value: investmentData.stage, label: investmentData.stage.toUpperCase()} : '',
        //                 name: investmentData.name,
        //                 pricePerShare: investmentData.pricePerShare,
        //                 companyValuation: investmentData.companyValuation,
        //                 closedDate: investmentData.closedDate ? new Date (investmentData.closedDate): '',
        //                 investmentId: investmentData._id,
        //                 llcName: investmentData.llcName,
        //             });
        //             this.formValidaton();
        //             callback();
        //         } else {
        //             this.resetInvestmentData();
        //             callback();
        //         }
        //     }

        // })
        // .catch((error) => {
        //     temp.setState({ loadinglada: false });
        // });
    }
    setInvestment(investmentData, callback) {
        this.setState({
            loadinglada: false,
            loading: false,
            isSwitchInvestment: false,
            switchInvestmentData: null,
            investments: [],
            investment: null,
        });
        if (investmentData) {
            this.setState({
                selectedStages: investmentData.stage ? { value: investmentData.stage, label: investmentData.stage.toUpperCase() } : '',
                name: investmentData.name,
                pricePerShare: investmentData.pricePerShare,
                pricePerShareAtExit : investmentData.pricePerShareAtExit,
                totalAllocation: (investmentData.totalAllocation) ? investmentData.totalAllocation : "",
                round: investmentData.round,
                minAmount: investmentData.minAmount,
                maxAmount: investmentData.maxAmount,
                companyValuation: investmentData.companyValuation,
                closedDate: investmentData.closedDate ? new Date(investmentData.closedDate) : '',
                investmentId: investmentData._id,
                llcName: investmentData.llcName,
                shareType: investmentData.shareType,
                valuationType: investmentData.valuationType,
                selectedExitProcess: investmentData.exitProcessType ? { value: investmentData.exitProcessType, label: investmentData.exitProcessType === 'acquisitionwithescrow' ? 'Acquisition with Escrow'.toUpperCase() : investmentData.exitProcessType.toUpperCase() } : [{ value: 'ipo', label: 'IPO' }],
                lockupDate: investmentData.lockupDate ? new Date(investmentData.lockupDate) : null,
                disbursementDate: investmentData.disbursementDate ? new Date(investmentData.disbursementDate) : null,
                investmentdeadlineDate: investmentData.investmentDeadlineDate ? new Date(investmentData.investmentDeadlineDate) : '',
                isShowInvestor: investmentData.isShowToInvestor,
            });
            this.formValidaton();
            callback();
        } else {
            this.resetInvestmentData();
            callback();
        }

    }

    switchInvestment(data) {
        this.setState({ loadinglada: false, loading: false });
        const investments = [];
        if (data && data.investment && data.investment.length > 0) {
            data.investment.forEach((item) => {
                investments.push({ value: item._id, label: item.name });
            });
        }
        this.setState({
            isSwitchInvestment: true,
            switchInvestmentData: data,
            investments,
            investment: null,

        });
    }
    setCompanyId(_id) {
        this.setState({ companyData: { _id } });
        this.resetInvestmentData();
    }
    handleChange(id, value) {
        // set values
        this.setState({ [id]: value })
        setTimeout(() => {
            this.checkInvestmentFormValidity();
        }, 100);
    }
    resetInvestmentData() {
        this.setState({
            selectedStages: '',
            pricePerShare: '',
            pricePerShareAtExit : '',
            name: '',
            llcName: '',
            companyValuation: '',
            closedDate: null,
            validInvitaionForm: false,
            investmentId: null,
            isSwitchInvestment: false,
            switchInvestmentData: null,
            round: null,
            minAmount: null,
            maxAmount: null,
            shareType: '',
            disbursementDate: null,
            lockupDate: null,
            selectedExitProcess: null,
            valuationType: '',
            investmentdeadlineDate: null,
            isShowInvestor: false,
        });

    }
    addInvestment() {
        let temp = this;
        temp.setState({ loadinglada: true });
        let token = window.localStorage.getItem('token');
        let method = 'post';
        const data = {
            name: this.state.name,
            llcName: this.state.llcName,
            pricePerShare: this.state.pricePerShare,
            pricePerShareAtExit : this.state.pricePerShareAtExit,
            companyValuation: this.state.companyValuation,
            closedDate: this.state.closedDate,
            stage: this.state.selectedStages && this.state.selectedStages.value ? this.state.selectedStages.value : '',
            companyId: this.state.companyData && this.state.companyData._id ? this.state.companyData._id : '',
            round: this.state.round ? this.state.round.toUpperCase() : '',
            minAmount: this.state.minAmount,
            maxAmount: this.state.maxAmount,
            shareType: this.state.shareType,
            valuationType: this.state.valuationType,
            investmentDeadlineDate: this.state.investmentdeadlineDate,
            isShowToInvestor: this.state.isShowInvestor,
            totalAllocation: this.state.totalAllocation
        }
        //console.log(data);
        if (this.state.investmentId) {
            data.id = this.state.investmentId;
            method = 'put'
            if (data.stage === 'exited' || data.stage === 'exit in process') {
                if (this.state.selectedExitProcess && this.state.selectedExitProcess.value) {
                    data.exitProcessType = this.state.selectedExitProcess.value;
                } else {
                    data.exitProcessType = 'ipo';
                }
                if (data.exitProcessType === 'ipo') {
                    data.lockupDate = this.state.lockupDate;
                } else {
                    data.disbursementDate = this.state.disbursementDate;
                }
                data.acquiringCompanyName = this.state.acquiringCompanyName;
            }
        }
        axios[method](`${common.path.base_url}/investment`, data,
            {
                headers: {
                    "Authorization": token
                }
            })
            .then((response) => {
                temp.setState({ loadinglada: false });
                document.getElementById('cancelInvestment').click();
                if (this.state.companyData) {
                    this.props.addUpdateInvestmentModalCallback('dashboard');
                } else {
                    this.props.addUpdateInvestmentModalCallback('investment');
                }


                swal({
                    title: "Success!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(() => {
                    })

            })
            .catch((error) => {
                temp.setState({ loadinglada: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });

                }
                else {
                    swal({
                        title: "Network Error!",
                        text: "Something went wrong",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }
    saveSwitchInvestment() {
        let temp = this;
        temp.setState({ loadinglada: true });
        let token = window.localStorage.getItem('token');
        const data = {
            investmentId: this.state.investment.value,
            companyId: this.state.switchInvestmentData && this.state.switchInvestmentData._id ? this.state.switchInvestmentData._id : null,
        }
        axios.post(`${common.path.base_url}/switchInvestment`, data,
            {
                headers: {
                    "Authorization": token
                }
            })
            .then((response) => {
                temp.setState({ loadinglada: false });
                document.getElementById('cancelInvestment').click();
                this.props.addUpdateInvestmentModalCallback('switchInvestment');
                swal({
                    title: "Success!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(() => {
                    })

            })
            .catch((error) => {
                temp.setState({ loadinglada: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });

                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }
    render() {
        return (
            <div>
                {/* <!-- Invite Modal HTML --> */}
                <div id="addInvestmentModal" className="modal fade">
                    <div className="modal-dialog">
                        {this.state.loadinglada ? <LoadingSpinner /> : ""}
                        {!this.state.isSwitchInvestment ? (
                            <div className="modal-content modal-size">
                                <div className="modal-header">
                                    <h4 className="modal-title">Investment</h4>
                                </div>
                                <div className="modal-body">
                                    <div className="form-horizontal add-investment-modal">
                                        <div className="form-group">
                                            <label className="col-sm-5  col-md-5 control-label">Stage  <RequiredSign /> :</label>
                                            <div className="col-sm-7 col-md-7">
                                                <Select
                                                    defaultValue={this.state.selectedStages}
                                                    options={this.state.stages}
                                                    value={this.state.selectedStages}
                                                    onChange={this.selectStage}
                                                    id='Investmentstages'
                                                />
                                            </div>
                                        </div>
                                        {/* {
                                            this.state.selectedStages && (this.state.selectedStages.value === 'exited' || this.state.selectedStages.value === 'exit in process') &&
                                            <>
                                                <div className="form-group">
                                                    <label className="col-sm-5  col-md-5 control-label">Exit Type <RequiredSign /> :</label>
                                                    <div className="col-sm-7 col-md-7">
                                                        <Select
                                                            defaultValue={this.state.selectedExitProcess}
                                                            options={this.state.exitProcess}
                                                            value={this.state.selectedExitProcess}
                                                            onChange={(event) => this.changeExitProcess(event)}
                                                            id='InvestmentTypes'
                                                        />
                                                    </div>
                                                </div>
                                                {
                                                    this.state.selectedExitProcess && this.state.selectedExitProcess.value === 'ipo' ?
                                                        <div className="form-group">
                                                            <label className="col-sm-5  col-md-5 control-label">Lockup Exp. <RequiredSign />  :</label>
                                                            <div className="col-sm-7 col-md-7 customDatePickerWidth">
                                                                <DatePicker
                                                                    selected={this.state.lockupDate}
                                                                    onChange={(date) => this.handleLockupDateChange(date)}
                                                                />
                                                            </div>
                                                        </div> :
                                                        <div className="form-group">
                                                            <label className="col-sm-5  col-md-5 control-label">Disbursement Date <RequiredSign /> :</label>
                                                            <div className="col-sm-7 col-md-7 customDatePickerWidth">
                                                                <DatePicker
                                                                    selected={this.state.disbursementDate}
                                                                    onChange={(date) => this.handleDisbursementDateChange(date)}
                                                                />
                                                            </div>
                                                        </div>
                                                }
                                                <div className="form-group">
                                                    <label className="col-sm-5  col-md-5 control-label">Acquiring Company Name <RequiredSign /> :</label>
                                                    <div className="col-sm-7 col-md-7">
                                                        <input className="form-control" id="acquiringCompanyName" type="text"
                                                            onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                                            onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                            placeholder='Acquiring Company Name'
                                                            value={this.state.acquiringCompanyName} />
                                                        <p>{this.state.nameValidationMsg}</p>
                                                    </div>
                                                </div>
                                            </>
                                        } */}

                                        <div className="form-group">
                                            <label className="col-sm-5  col-md-5 control-label">TSG LLC <RequiredSign /> :</label>
                                            <div className="col-sm-7 col-md-7">
                                                <input className="form-control" id="llcName" type="text"
                                                    onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                    placeholder=''
                                                    value={this.state.llcName} />
                                                <p>{this.state.llcNameValidationMsg}</p>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-5  col-md-5 control-label">Name <RequiredSign /> :</label>
                                            <div className="col-sm-7 col-md-7">
                                                <input className="form-control" id="name" type="text"
                                                    onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                    placeholder=''
                                                    value={this.state.name} />
                                                <p>{this.state.nameValidationMsg}</p>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-5  col-md-5 control-label">Round <RequiredSign /> :</label>
                                            <div className="col-sm-7 col-md-7">
                                                <input className="form-control" id="round" type="text"
                                                    onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                    placeholder=''
                                                    style={{ textTransform: 'uppercase' }}
                                                    value={this.state.round} />
                                                <p>{this.state.roundValidationMsg}</p>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-5  col-md-5 control-label">Price Per Share <RequiredSign /> :</label>
                                            <div className="col-sm-7 col-md-7 amount-investment">
                                                {/* <input className="form-control" id="pricePerShare" type="text"
                                                    onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                    placeholder='Price Per Share'
                                                    value={this.state.pricePerShare} /> */}
                                                <NumberFormat
                                                    value={this.state.pricePerShare && this.state.pricePerShare}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    placeholder=''
                                                    onValueChange={(values) => {
                                                        this.handleChange('pricePerShare', values.value)
                                                    }} />
                                                <p>{this.state.pricePerShareValidationMsg}</p>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            
                                            <label className="col-sm-5  col-md-5 control-label">Share Price at Time of Exit:</label>
                                            <div className="col-sm-7 col-md-7 amount-investment">
                                                <NumberFormat
                                                    value={ this.state.pricePerShareAtExit && this.state.pricePerShareAtExit }
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    placeholder=''
                                                    className='form-control'
                                                    onValueChange={(values) => {
                                                        this.handleChange('pricePerShareAtExit', values.value)
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-5  col-md-5 control-label">Total Allocation <RequiredSign /> :</label>
                                            <div className="col-sm-7 col-md-7 amount-investment">
                                                <NumberFormat
                                                    value={this.state.totalAllocation && this.state.totalAllocation}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    placeholder=''
                                                    onValueChange={(values) => {
                                                        this.handleChange('totalAllocation', values.value)
                                                    }} />
                                                <p>{this.state.totalAllocationValidationMsg}</p>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-5 col-md-5 control-label">Company Valuation <RequiredSign /> :</label>
                                            <div className="col-sm-7 col-md-7 amount-investment">
                                                <div className="drop-input-wraper">
                                                    <div className="custom-drop">
                                                        <select className="form-control form-control-lg" name="valuationtype" id="validationCustom" onChange={(event) => {
                                                            this.setState({ valuationType: event.target.value })
                                                        }}>
                                                            <option value=''></option>
                                                            <option value='~' selected={this.state.valuationType === '~'}>~</option>
                                                            <option value='>' selected={this.state.valuationType === '>'}> &#62; </option>
                                                        </select>
                                                    </div>
                                                    <NumberFormat
                                                        value={this.state.companyValuation && this.state.companyValuation}
                                                        thousandSeparator={true}
                                                        prefix={'$'}
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        placeholder=''
                                                        onValueChange={(values) => {
                                                            this.handleChange('companyValuation', values.value)
                                                        }} />
                                                </div>
                                                <p>{this.state.companyValuationValidationMsg}</p>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-5 col-md-5 control-label">Close Date <RequiredSign/>:</label>
                                            <div className="col-sm-7 col-md-7 customDatePickerWidth">
                                                <DatePicker
                                                    selected={this.state.closedDate}
                                                    onChange={(date) => this.handleDateChange(date)}
                                                />
                                                <p>{this.state.closeDateValidationMsg}</p>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-5  col-md-5 control-label">Investment Deadline <RequiredSign /> :</label>
                                            <div className="col-sm-7 col-md-7 customDatePickerWidth">
                                                <DatePicker
                                                    selected={this.state.investmentdeadlineDate}
                                                    onChange={(date) => this.handleInvestmentDeadlineChange(date)}
                                                />
                                                <p>{this.state.closeDateValidationMsg}</p>
                                                <div className="checkbox-deadline">
                                                    <input type='checkbox' id='investmentdeadline' onChange={(event) => this.handletextboxChange(event.currentTarget.checked)} checked={this.state.isShowInvestor} readOnly={true} />
                                                    <span >Show To Investor </span>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label className="col-sm-5  col-md-5 control-label">Minimum Investment Amount <RequiredSign /> :</label>
                                            <div className="col-sm-7 col-md-7 amount-investment">
                                                <NumberFormat
                                                    value={this.state.minAmount && this.state.minAmount}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                    placeholder=''
                                                    onValueChange={(values) => {
                                                        this.handleChange('minAmount', values.floatValue)
                                                    }} 
                                                />
                                                <p>{this.state.minAmountValidationMsg}</p>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-5  col-md-5 control-label">Maximum Investment Amount :</label>
                                            <div className="col-sm-7 col-md-7 amount-investment">
                                                <NumberFormat
                                                    value={this.state.maxAmount && this.state.maxAmount}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                    placeholder=''
                                                    // isAllowed={(values) => {
                                                    //     const { formattedValue, floatValue } = values
                                                    //     return formattedValue === '' || (floatValue <= 15 && floatValue >= 5)
                                                    // }}
                                                    onValueChange={(values) => {
                                                        this.handleChange('maxAmount', values.floatValue, values)
                                                    }} />
                                                <p>{this.state.maxAmountValidationMsg}</p>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-5  col-md-5 control-label">Type of Shares :</label>
                                            <div className="col-sm-7 col-md-7 amount-investment">
                                                <select className="form-control form-control-lg" name="category" id="validationCustom03" onChange={(event) => {
                                                    this.setState({ shareType: event.target.value })
                                                }}>
                                                    <option value='Preferred'>Preferred</option>
                                                    <option value='Common' selected={this.state.shareType === 'Common'}>Common</option>
                                                </select>
                                            </div>
                                        </div>



                                        <div className="modal-footer">

                                            <LaddaButton
                                                id='lada-btn'
                                                loading={this.state.loadinglada}
                                                onClick={() => this.addInvestment()}
                                                data-color="#eee"
                                                data-size={XS}
                                                className={!this.state.validInvitaionForm ? 'disable' : ''}
                                                data-style={EXPAND_RIGHT}
                                                data-spinner-size={30}
                                                data-spinner-color="#ddd"
                                                data-spinner-lines={12}
                                            >
                                                {this.state.investmentId ? 'UPDATE' : 'ADD'}
                                            </LaddaButton>
                                            <input type="button" id="cancelInvestment" className="btn btn-default" data-dismiss="modal" onClick={() => this.resetInvestmentData()} value="CANCEL" />
                                        </div>
                                    </div>
                                </div>
                            </div>) : (
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Switch Investment for <strong>{this.state.switchInvestmentData.companyName}</strong> </h4>
                                </div>
                                <div className="modal-body">
                                    <div className="form-horizontal" id=' myform'>
                                        <div className="form-group">
                                            <label className="col-sm-5  col-md-5 col-lg-3" style={{ fontSize: "14px" }}>Investments  <RequiredSign /> :</label>
                                            <div className="col-sm-7 col-md-7">
                                                <Select
                                                    id='investment'
                                                    defaultValue={this.state.investment}
                                                    options={this.state.investments}
                                                    value={this.state.investment}
                                                    onChange={(event) => this.setState({ investment: event })}

                                                />
                                            </div>
                                        </div>
                                        <div className="modal-footer">

                                            <LaddaButton
                                                id='lada-btn'
                                                loading={this.state.loadinglada}
                                                onClick={() => this.saveSwitchInvestment()}
                                                data-color="#eee"
                                                data-size={XS}
                                                className={!this.state.investment ? 'disable' : ''}
                                                data-style={EXPAND_RIGHT}
                                                data-spinner-size={30}
                                                data-spinner-color="#ddd"
                                                data-spinner-lines={12}
                                            >
                                                SAVE
                                    </LaddaButton>
                                            <input type="button" id="cancelInvestment" className="btn btn-default" data-dismiss="modal" onClick={() => this.resetInvestmentData()} value="CANCEL" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
export default AddInvestmentModal;
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import NumberFormat from "react-number-format";
import common from "../../common";
import "./emailRedirect.scss";
import images from "../../images";
import axios from "axios";
import swal from "sweetalert";
import Moment from "react-moment";
import HeaderImage from "../../components/HeaderImage";
import LoadingSpinner from "../../components/Loader";
import Header from "../../components/Header";
import ReactTags from "react-tag-autocomplete";

const WebinarReportList = (props) => {
    //const [checkRadio1, setCheckRadio1] = useState('');
    const { getInvestmentWebinar, getInvestmentData } = props;
    const webinarLink1 = getInvestmentData.webinarLink1;
    const webinarLink2 = getInvestmentData.webinarLink2;
    const [tags, _setTags] = useState([]);

    const tagsRef = useRef(tags);
    const setTags = (x) => {
        tagsRef.current = x; // keep updated
        _setTags(x);
    };

    function tagsArr(tag) {
        setTags([].concat(tags, { id: tag, name: tag }));
        const tagss = [].concat(tags, { id: tag, name: tag });
        return tagss;
    }

    const handleDelete = (i) => {
        const newTags = tags.slice(0);
        newTags.splice(i, 1);
        setTags(newTags);
        const reload = 1;
        props.getAllInvestmentWebinar(newTags);
    };

    const handleAddition = (tag) => {
        const newTags = tagsArr(tag);
        const reload = 1;
        props.getAllInvestmentWebinar(newTags);
        console.log("govindndd");
    };

    return (
        <>
            <div className="">
                <div className="custom-table-view table-responsive">
                    <div className="react-name-tag">
                        <ReactTags
                            allowNew={true}
                            placeholder="Search Investor"
                            tags={tags}
                            handleDelete={(i) => handleDelete(i)}
                            handleAddition={(value) =>
                                handleAddition(value.name)
                            }
                        />
                    </div>
                    <div className="react-table-report">
                        <table className="table table-custom sortable">
                            <thead>
                                <tr>
                                    <th>
                                        {/* <input
                                            type="checkbox"
                                            name="userCheckbox"
                                            //onChange={ (event) => onCheckedAllSendMessage(event) }
                                            className="chkbox-input"
                                        />
                                        <label className='chkbox-lable'>&nbsp; &nbsp;All</label> */}
                                        #
                                    </th>
                                    <th>NAME</th>
                                    <th>EMAIL</th>
                                    <th>
                                        WEBINAR 1<br />
                                        <Moment
                                            date={
                                                webinarLink1
                                                    ? webinarLink1.eventDate
                                                    : ""
                                            }
                                            format="MMMM DD, Y hh:mm A"
                                        />
                                    </th>
                                    <th>
                                        WEBINAR 2 <br />
                                        <Moment
                                            date={
                                                webinarLink2
                                                    ? webinarLink2.eventDate
                                                    : ""
                                            }
                                            format="MMMM DD, Y hh:mm A"
                                        />
                                    </th>
                                    {/* <th className='actionTh'>ACTION</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {getInvestmentWebinar.length > 0 ? (
                                    getInvestmentWebinar.map((val, i) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td>
                                                        {
                                                            i + 1
                                                            // <input
                                                            //     //type="checkbox"
                                                            //     //onChange={(event) => { event.stopPropagation(); props.onSingleSendMessage( event, props.processId.user )} }
                                                            //     //checked={ allChkEmail.includes(props.processId.user.emailAddress) ? true : false }
                                                            // />
                                                        }
                                                        .
                                                    </td>
                                                    <td
                                                        onClick={() => {
                                                            props.showDataInPopup(
                                                                val.userId._id
                                                            );
                                                        }}
                                                        style={{
                                                            "text-decoration":
                                                                "underline",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        {val.userId.name}
                                                    </td>
                                                    <td>
                                                        {
                                                            val.userId.emailAddress
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            <div className="statusTxt">
                                                                {val
                                                                    .webinarInvite
                                                                    .join1 ==
                                                                "yes" ? (
                                                                    <div className="greenTxt">
                                                                        Yes
                                                                    </div>
                                                                ) : val
                                                                      .webinarInvite
                                                                      .join1 ==
                                                                  "no" ? (
                                                                    <div className="redTxt">
                                                                        No
                                                                    </div>
                                                                ) : (
                                                                    <div className="orgTxt">
                                                                        Maybe
                                                                    </div>
                                                                )}
                                                            </div>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            <div className="statusTxt">
                                                                {val
                                                                    .webinarInvite
                                                                    .join2 ==
                                                                "yes" ? (
                                                                    <div className="greenTxt">
                                                                        Yes
                                                                    </div>
                                                                ) : val
                                                                      .webinarInvite
                                                                      .join2 ==
                                                                  "no" ? (
                                                                    <div className="redTxt">
                                                                        No
                                                                    </div>
                                                                ) : (
                                                                    <div className="orgTxt">
                                                                        Maybe
                                                                    </div>
                                                                )}
                                                            </div>
                                                        }
                                                    </td>
                                                    {/* <td>
                                                        <button type={ 'button' }
                                                                id={ 'asdasd' }
                                                                className={`icon-table-action`}
                                                                //onClick={ e => props.sendMessage(props.processId.user) }
                                                                // data-toggle={`modal`}
                                                                // data-target={`#sendMessageModal`}
                                                                title = {"Send Message"} >
                                                                <i className="fa fa-envelope" aria-hidden="true"></i>
                                                        </button>
                                                    </td> */}
                                                </tr>
                                            </>
                                        );
                                    })
                                ) : (
                                    <td
                                        colSpan={6}
                                        style={{
                                            textAlign: "center",
                                            paddingTop: "11px",
                                        }}
                                    >
                                        Record Not Found
                                    </td>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WebinarReportList;

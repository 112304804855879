import React, { useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { path } from "../../imagesPath";
import useScreenWidthLessThan768 from "../../shared/window_resize_hook";
import GridView from "./Grid";
import ListView from "./List";
import "./MyInvestments.scss";

const StageFilter = (props) => {
  return (
    <>
      <li>Filter by stage:</li>
      <li className="filter-drop-down" style={{ textAlign: "left !important" }}>
        <Select
          className="custom-selct-menu"
          value={props.stageFilterDropDown.selectedValue}
          options={props.stageFilterDropDown.options}
          onChange={(evt) => props.filterChange(evt)}
        />
      </li>
    </>
  );
};

const MyInvestments = (props) => {
  const { myInvestments, exitInProcessInvestments, pendingCounterSign } = props;
  const [exitInProcessUsers, setExitInProcessInvestments] = useState([]);
  const [isProfit, setIsProfit] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const isScreenResized = useScreenWidthLessThan768();


  // useEffect(() => {
  //   if (exitInProcessInvestments.length > 0) {
  //     const userInvestments = exitInProcessInvestments.map(data => {
  //       let list = data.investmentprocesses;
  //       const obj = {
  //         disbursement1: data.investment.disbursement1,
  //         disbursement2: data.investment.disbursement2,
  //         totalNetInvestment: data.investment.totalNetInvestment,
  //       }
  //       const exitProcessType = data.company.exitProcessType;
  //       const managementFee = list.managementFee != null ? list.managementFee : 0;
  //       const carryFee = list.carry != null ? list.carry : 0;
  //       const amount = list.amount;
  //       const totalGrossGain = obj.disbursement1 - obj.totalNetInvestment;
  //       const totalGrossGain2 = obj.disbursement2;
  //       const tsgFeeMg = (amount * managementFee) / 100;
  //       const amountTotal = amount - tsgFeeMg;
  //       const percentageInvestment = (amountTotal / obj.totalNetInvestment) * 100;
  //       if (totalGrossGain !== null && totalGrossGain !== 0) {
  //         const totValue = (percentageInvestment * totalGrossGain) / 100;
  //         const carryVal = totValue - (totValue * carryFee) / 100;
  //         const mapData = { ...list };
  //         if (exitProcessType == "acquisitionwithescrow") {
  //           mapData.disbursement1 = amountTotal + carryVal;
  //           list = mapData;
  //         } else {
  //           mapData.disbursement1 = Math.abs(amountTotal);
  //           list = mapData;
  //         }
  //       }
  //       if (totalGrossGain2 !== null && totalGrossGain2 !== 0) {
  //         const totValue2 = (percentageInvestment * totalGrossGain2) / 100;
  //         const carryVal2 = totValue2 - (totValue2 * carryFee) / 100;
  //         const mapData = { ...list };
  //         if (exitProcessType == "acquisitionwithescrow") {
  //           mapData.disbursement2 = carryVal2;
  //           list = mapData;
  //         } else {
  //           mapData.disbursement2 = carryVal2;
  //           list = mapData;
  //         }
  //       }
  //       return list;
  //     })
  //     setExitInProcessInvestments(userInvestments);
  //   }
  // }, [exitInProcessInvestments]);

  // const totalInvestments = useMemo(() => {
  //   let amount = 0;
  //   myInvestments.forEach((investment) => {
  //     amount += +getUserInvestmentAmount(investment._id, investment.investment.userInvestments);
  //   });
  //   pendingCounterSign.forEach((investment) => {
  //     amount += +getUserInvestmentAmount(investment._id, investment.investment.userInvestments);
  //   });
  //   exitInProcessUsers.forEach((investment) => {
  //     amount += +investment.amount;
  //   });
  //   const formattedAmount = Intl.NumberFormat('en-US').format(Number(amount).toFixed(2));
  //   return formattedAmount;
  // }, [myInvestments, exitInProcessUsers, pendingCounterSign]);

  // const totalCountOfInvestments = useMemo(() => {
  //   const length = myInvestments.length + exitInProcessUsers.length + pendingCounterSign.length;
  //   const formattedAmount = Intl.NumberFormat('en-US').format(Number(length).toFixed(2));
  //   return formattedAmount;
  // }, [myInvestments, exitInProcessUsers]);

  // const totalInvestedInvestments = useMemo(() => {
  //   let amount = 0;
  //   exitInProcessUsers.forEach((investment) => {
  //     amount += +investment.amount;
  //   });
  //   return amount;
  // }, [exitInProcessUsers]);

  // const totalReturnInvestments = useMemo(() => {
  //   let amount = 0;
  //   exitInProcessUsers.forEach((investment) => {
  //     amount += +getReturnedInvestment({ investment: investment });
  //   });
  //   const formattedAmount = Intl.NumberFormat('en-US').format(Number(amount).toFixed(2));
  //   return formattedAmount;
  // }, [exitInProcessUsers]);

  // const totalReturnInvestmentsForFunction = useMemo(() => {
  //   let amount = 0;
  //   exitInProcessUsers.forEach((investment) => {
  //     amount += +getReturnedInvestment({ investment: investment });
  //   });
  //   return amount;
  // }, [exitInProcessUsers]);


  // const totalPercentReturn = useMemo(() => {
  //   const myInvestment = totalInvestedInvestments;
  //   const returnInvestment = totalReturnInvestmentsForFunction;

  //   if (myInvestment > returnInvestment) {
  //     const investment = myInvestment - returnInvestment;
  //     const percentReturn = (investment / myInvestment) * 100;
  //     if (investment < 0) {
  //       setIsProfit(false);
  //     }
  //     return (percentReturn).toFixed(2);
  //   } else if (myInvestment < returnInvestment) {
  //     const investment = returnInvestment - myInvestment;
  //     const percentReturn = (investment / myInvestment) * 100;
  //     if (investment > 0) {
  //       setIsProfit(true);
  //     }
  //     return (percentReturn).toFixed(2);
  //   } else {
  //     return 0;
  //   }
  // }, [totalInvestedInvestments, totalReturnInvestmentsForFunction]);

  const isMyInvestmentCollapsed = () => {
    if (isCollapsed) {
      setIsCollapsed(false);
    } else {
      setIsCollapsed(true);
    }
  }

  return (
    <>
      <div className="my_invitation">
        <div className="container" style={{ padding: 15 }}>
          <div className="row">
            <div className="col-lg-12">
              <div className="my_invitation_filter">
                <div className="row d-flex" style={{ padding: "0px 4px" }}>
                  <img className="intial_icon" src={path.myInvestments} />
                  <div className="col-md-3">
                    <h3 style={{ fontSize: "20px", color: "#383F59" }}>
                      My Investments ({myInvestments.length})
                    </h3>
                  </div>
                  <div className="col-md-9"></div>
                </div>
                <div className="filter">
                  <ul>
                    <StageFilter {...props} />
                    {/* {props.type && props.type === "grid" && (
                      <li>
                        <ul className="menu_icon">
                          <li
                            className="menu-bg-white"
                            onClick={() => props.changeViewClickHandler("list")}
                          >
                            <img src={path.menuIcon && path.menuIcon} alt="" />
                          </li>
                          <li
                            className="menu-bg-red "
                            onClick={() => props.changeViewClickHandler("grid")}
                          >
                            <img src={path.tile && path.tile} alt="" />
                          </li>
                        </ul>
                      </li>
                    )} */}
                    {/* {props.type && props.type === "list" && (
                      <li>
                        <ul className="menu_icon">
                          <li
                            className="menu-bg-red-active"
                            onClick={() => props.changeViewClickHandler("list")}
                          >
                            <img src={path.list && path.list} alt="" />
                          </li>
                          <li
                            className="menu-bg-white-inactive"
                            onClick={() => props.changeViewClickHandler("grid")}
                          >
                            <img
                              src={path.tileDeactive && path.tileDeactive}
                              alt=""
                            />
                          </li>
                        </ul>
                      </li>
                    )} */}
                    {
                      myInvestments.length > 3 &&
                      <li>
                        <img src={isCollapsed ? path.drowpDownArrow : path.drowpDownArrowOpen} height={20} width={20} alt="dropdown" onClick={isMyInvestmentCollapsed} />
                      </li>
                    }
                  </ul>
                </div>
                {/* {myInvestments.length > 0 && isScreenResized ? (
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="card">
                        <div className="row">
                          <div className="col-lg-2 col-md-2 col-xs-6 col-6">
                            <span style={{ color: "#383F59" }}>
                              Total Investments
                            </span>
                            <br />
                            <span
                              style={{
                                fontSize: "30px",
                                color: "#383F59",
                                fontWeight: "bold",
                              }}
                            >
                              {totalCountOfInvestments}
                            </span>
                          </div>
                          <div className="col-lg-3 col-md-3 col-xs-6 col-6">
                            <span style={{ color: "#383F59" }}>
                              Total $ Investments{" "}
                            </span>
                            <br />
                            <span
                              style={{
                                fontSize: "30px",
                                color: "#5691E5",
                                fontWeight: "bold",
                              }}
                            >
                              ${totalInvestments}
                            </span>
                          </div>
                          <div className="col-lg-2 col-md-2 col-xs-6 col-6">
                            <span style={{ color: "#383F59" }}>
                              Gross Total $ Invested (Exited)
                            </span>
                            <br />
                            <span
                              style={{
                                fontSize: "30px",
                                color: "#383F59",
                                fontWeight: "bold",
                              }}
                            >
                              {totalInvestedInvestments}
                            </span>
                          </div>
                          <div className="col-lg-3 col-md-3 col-xs-6 col-6">
                            <span style={{ color: "#383F59" }}>
                              Net Total $ Returned (Exited)
                            </span>
                            <br />
                            <span
                              style={{
                                fontSize: "30px",
                                color: "#3CBA66",
                                fontWeight: "bold",
                              }}
                            >
                              ${totalReturnInvestments}
                            </span>
                          </div>
                          <div className="col-lg-2 col-md-2 col-xs-6 col-6">
                            <span style={{ color: "#383F59" }}>
                              Average Return %
                            </span>
                            <br />
                            <span
                              style={{
                                fontSize: "30px",
                                color: isProfit ? "#3CBA66" : totalPercentReturn == 0 ? "#FFBF00" : "#FF0000",
                                fontWeight: "bold",
                              }}
                            >
                              ({isProfit ? "+" : totalPercentReturn == 0 ? "" : "-"}{totalPercentReturn}%)
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : myInvestments.length > 0 && (
                  <div className="container-fluid" style={{ padding: 0 }}>
                    <div className="card">
                      <div className="row amount-section">
                        <div className="amount-section-width">
                          <span style={{ color: "#383F59" }}>
                            Total Investments
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: "30px",
                              color: "#383F59",
                              fontWeight: "bold",
                            }}
                          >
                            {totalCountOfInvestments}
                          </span>
                        </div>
                        <div className="amount-section-width">
                          <span style={{ color: "#383F59" }}>
                            Total $ Investments{" "}
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: "30px",
                              color: "#383F59",
                              fontWeight: "bold",
                            }}
                          >
                            ${totalInvestments}
                          </span>
                        </div>
                        <div className="amount-section-width">
                          <span style={{ color: "#383F59" }}>
                            Gross Total $ Invested (Exited)
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: "30px",
                              color: "#383F59",
                              fontWeight: "bold",
                            }}
                          >
                            ${totalInvestedInvestments}
                          </span>
                        </div>
                        <div className="amount-section-width">
                          <span style={{ color: "#383F59" }}>
                            Net Total $ Returned (Exited)
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: "30px",
                              color: "#3CBA66",
                              fontWeight: "bold",
                            }}
                          >
                            ${totalReturnInvestments}
                          </span>
                        </div>
                        <div className="amount-section-width">
                          <span style={{ color: "#383F59" }}>
                            Average Return %
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: "30px",
                              color: isProfit ? "#3CBA66" : totalPercentReturn == 0 ? "#FFBF00" : "#FF0000",
                              fontWeight: "bold",
                            }}
                          >
                            ({isProfit ? "+" : totalPercentReturn == 0 ? "" : "-"}{totalPercentReturn}%)
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
          </div>
          <>
            {props.type &&
              props.type === "list" &&
              myInvestments.length > 0 && <ListView {...props} isCollapsed={isCollapsed} />}
            {props.type &&
              props.type === "grid" &&
              myInvestments.length > 0 && (
                <GridView myInvestments={myInvestments} isCollapsed={isCollapsed} {...props} />
              )}
            {myInvestments.length === 0 && (
              <div>
                <h4>
                  You do not yet have any Completed or Closed investments.
                </h4>
              </div>
            )}
            {myInvestments && myInvestments.error && (
              <div>
                <h4>{myInvestments.error}</h4>
              </div>
            )}
          </>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    exitInProcessInvestments:
      state.investorDashboardData.exitInProcessInvestments.length > 0
        ? state.investorDashboardData.exitInProcessInvestments
        : [],
    myInvestments:
      state.investorDashboardData.myInvestments.length > 0
        ? state.investorDashboardData.myInvestments
        : state.investorDashboardData.myInvestments.error
          ? state.investorDashboardData.myInvestments
          : [],
    type:
      state.myInvestmentView && state.myInvestmentView.type
        ? state.myInvestmentView.type
        : "list",
    pendingCounterSign: state.investorDashboardData.pendingCounterSign.length > 0 ? state.investorDashboardData.pendingCounterSign : [],
    stageFilterDropDown: state.stageFilterDropDown,
  };
};

export default connect(mapStateToProps, null)(MyInvestments);

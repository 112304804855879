import React, { Component } from 'react';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import axios from 'axios';
import swal from 'sweetalert';
import common from '../../../../common';
import './SettingsModal.scss';
import { path } from "../../../imagesPath";




class Settings extends Component {
    constructor() {
        super();
        this.state = {
            name: '',
            email: '',
            loadinglada: false,
            settingsArray: [
                {
                    label: 'New Invitations',
                    value: 'newInvitations',
                },
                {
                    label: 'Investment Reminders',
                    value: 'reminders'
                },
                {

                    label: 'Documents Uploaded',
                    value: 'docUploaded'
                },
                {

                    label: 'TSG Review',
                    value: 'yearInReview',
                },
            ],
            notificationSettings: null,
            count: 0,
        }
    }
    componentDidUpdate() {
    }
    componentWillReceiveProps(nextProps) {
        nextProps.onRef && nextProps.onRef(this);
    }
    setNotificationData(ref, callback) {
        const temp = this;
        const id = window.localStorage.getItem('id');

        axios.get(`${common.path.base_url}/getUserById`, {
            params: {
                _id: id
            }
        })
            .then((response) => {
                if (response && response.data && response.data.data && response.data.data.notificationSettings) {
                    temp.setState({ notificationSettings: response.data.data.notificationSettings });
                } else {
                    const notificationSettings = {
                        email: {
                            newInvitations: true,
                            reminders: true,
                            docUploaded: true,
                            yearInReview: true,
                        },
                        sms: {
                            newInvitations: true,
                            reminders: true,
                            docUploaded: true,
                            yearInReview: true,

                        }
                    }
                    temp.setState({ notificationSettings })
                }
                temp.setState({ count: temp.state.count + 1 })
                callback(ref)
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else { }
            });

    }
    save() {
        const temp = this;
        let token = window.localStorage.getItem('token');
        this.setState({ loadinglada: true });
        axios.put(`${common.path.base_url}/updateNotificationSettings`, {

            notificationSettings: this.state.notificationSettings
        }, {
            headers: {
                "Authorization": token
            }
        })
            .then(function (response) {
                temp.setState({ loadinglada: false });

                swal({
                    text: 'Data saved successfully!',
                    icon: "success",
                    button: "Ok!",
                })
            })
            .catch(function (error) {
                swal({
                    title: "Network Error!",
                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                    icon: "error",
                    button: "Ok!",
                });
                temp.setState({ loadinglada: false });

            });
    }
    getStatus(data, type) {
        let isChecked = true;
        switch (data.label) {
            case 'New Invitations':
                if (this.state.notificationSettings && this.state.notificationSettings[type] && this.state.notificationSettings[type].hasOwnProperty('newInvitations')) {
                    if (this.state.notificationSettings[type].newInvitations) {
                        isChecked = true;
                    } else {
                        isChecked = false;
                    }
                }
                break;
            case 'Investment Reminders':
                if (this.state.notificationSettings && this.state.notificationSettings[type] &&
                    this.state.notificationSettings[type].hasOwnProperty('reminders')) {
                    if (this.state.notificationSettings[type].reminders) {
                        isChecked = true;
                    } else {
                        isChecked = false;
                    }
                }
                break;
            case 'Documents Uploaded':
                if (this.state.notificationSettings && this.state.notificationSettings[type] &&
                    this.state.notificationSettings[type].hasOwnProperty('docUploaded')) {
                    if (this.state.notificationSettings[type].docUploaded) {
                        isChecked = true;
                    } else {
                        isChecked = false;
                    }
                }
                break;
            case 'TSG Review':
                if (this.state.notificationSettings && this.state.notificationSettings[type] &&
                    this.state.notificationSettings[type].hasOwnProperty('yearInReview')) {
                    if (this.state.notificationSettings[type].yearInReview) {
                        isChecked = true;
                    } else {
                        isChecked = false;
                    }
                }
                break;
            default:
                break;

        }
        return isChecked;
    }
    onChange(item, type, event) {
        let notificationSettings = this.state.notificationSettings;
        if (!notificationSettings) {
            notificationSettings = {
                [type]: {
                    [item.value]: event.currentTarget.checked
                }
            }
        } else {
            if (!notificationSettings[type]) {
                notificationSettings[type] = {
                    [item.value]: event.currentTarget.checked
                }
            } else {
                notificationSettings[type][item.value] = event.currentTarget.checked;
            }
        }
        this.setState({ notificationSettings })

    }
    render() {
        return (
            <div id="notificationSettingModal" className="modal fade notification-settings" key={this.state.count}>
                <div className="modal-dialog">
                    <div className="modal-content invite-contact">
                        <div className="modal-header">
                            <div className="modal-title notification-settings-title">Notification Settings
                            </div>
                            <div className="closeButtonIcon" onClick={() => {
                                this.inputElement.click();
                            }}>
                                <img src={path.closeButtonIcon} alt="" />
                            </div>
                        </div>
                        <div className="modal-body">
                            <table className="table">
                                <thead className="table-head">
                                    <tr>
                                        <th scope="col">Notification Type</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">SMS</th>
                                    </tr>
                                </thead>
                                <tbody className="table-body">
                                    <>
                                        {
                                            this.state.settingsArray.map(item => {
                                                return (
                                                    <tr key={item.label}>
                                                        <td>{item.label}</td>
                                                        <td>
                                                            <label className="customcheck">
                                                                <input type="checkbox"
                                                                    id={item.value}
                                                                    name={item.label}
                                                                    defaultChecked={this.getStatus(item, 'email')}
                                                                    onChange={(event) => this.onChange(item, 'email', event)}
                                                                />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <label className="customcheck">
                                                                <input type="checkbox"
                                                                    id={item.value}
                                                                    name={item.label}
                                                                    defaultChecked={this.getStatus(item, 'sms')}
                                                                    onChange={(event) => this.onChange(item, 'sms', event)}
                                                                />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </td>

                                                    </tr>
                                                );

                                            })
                                        }
                                    </>
                                </tbody>
                            </table>
                        </div>
                        <button ref={input => this.inputElement = input} style={{ display: 'none' }} type="button" className="btn cancel-btn" data-dismiss="modal"></button>
                        <div className="modal-footer">
                            <LaddaButton
                                id='lada-btn'
                                loading={this.state.loadinglada}
                                onClick={() => this.save()}
                                data-color="#eee"
                                data-size={XS}
                                data-style={EXPAND_RIGHT}
                                data-spinner-size={30}
                                data-spinner-color="#ddd"
                                data-spinner-lines={12}
                                data-dismiss="modal"
                            >
                                {'SAVE'}
                            </LaddaButton>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Settings;
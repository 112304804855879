import React, { Component } from 'react';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import axios from 'axios';
import swal from 'sweetalert';
import common from '../../../common';
import RequiredSign from '../../../components/RequiredSign';
import { path } from '../../imagesPath';
import { getCompanyLogo } from '../../shared/helper';

class VerifyAddressModel extends Component {
    constructor() {
        super();
        this.state = {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipcode: '',
            bankName: '',
            routingNumber: '',
            accountType:'domestic',
            bankAddress:'',
            accountNumber:'',
            swiftCode:'',
            loadinglada: false,
            id: '',
            processId: '',
            investmentName: ''
        }
    }
    componentWillReceiveProps(nextProps) {
        nextProps.onRef && nextProps.onRef(this);

    }
    setData(detail) {
         this.setState({ address1: detail.investmentprocesses.address1 });
        this.setState({ address2: detail.investmentprocesses.address2 });
        this.setState({ city: detail.investmentprocesses.city });
        this.setState({ state: detail.investmentprocesses.state });
        this.setState({ zipcode: detail.investmentprocesses.zipcode });
        this.setState({ bankName: detail.investmentprocesses.bankName });
        this.setState({ accountType: detail.investmentprocesses.accountType });
        this.setState({ bankAddress: detail.investmentprocesses.bankAddress });
        this.setState({ accountNumber: detail.investmentprocesses.accountNumber });
        this.setState({ accountType: detail.investmentprocesses.accountType });
        this.setState({ swiftCode: detail.investmentprocesses.swiftCode });
        this.setState({ routingNumber: detail.investmentprocesses.routingNumber });
        this.setState({ investmentName: detail.investment.name });
        this.setState({ id: detail.company._id });
        this.setState({ processId: detail.investment._id });
    }
    resetData() {
        this.setState({
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipcode: '',
            bankName: '',
            routingNumber: '',
            accountNumber: '',
            accountType: '',
            swiftCode: '',
            bankAddress: '',
        })
    }
    verifyAddress() {
        const temp = this;
        let token = window.localStorage.getItem('token');
        let userId = window.localStorage.getItem('id');
        this.setState({ loadinglada: true });
        axios.post(`${common.path.base_url}/verifyAddress`, {
            userId,
            investmentId: this.state.processId,
            address1: this.state.address1,
            address2: this.state.address2,
            city: this.state.city,
            state: this.state.state,
            zipcode: this.state.zipcode, 
            bankName: this.state.bankName,
            routingNumber: this.state.routingNumber,
            accountType: this.state.accountType,
            bankAddress: this.state.bankAddress,
            routingNumber: this.state.routingNumber,
            accountNumber: this.state.accountNumber,
            swiftCode: this.state.swiftCode,
        }, {
            headers: {
                "Authorization": token
            }
        })
            .then(function (response) {
                temp.setState({ loadinglada: false });
                swal({
                    title: "Success!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(function () {
                        temp.resetData();
                        window.location.reload();
                    });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });

            });

    }
    render() {
        return (
            <div id="verifyAddressModel" className="modal fade" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Verify Address and Wire Information</h4>
                            <img src={path.close} type="button" id="verify-btn-cancel" data-dismiss="modal" onClick={() => this.resetData()} />
                        </div>
                        <div className="modal-body">
                            <div className="model-img-box">
                                <img src={getCompanyLogo(this.state.id)} alt='' />
                                {/* <img src={'https://api-dev.syndicategroup.com/getInvestmentCompanyLogo?companyId=5e42889b03a4339da57ce249'} alt='' /> */}
                                <h3>{this.state.investmentName}</h3>
                            </div>
                            <form className=" recommand_form">
                                <div className="row" style={{backgroundColor:"#F4F7FC", paddingBottom:"15px", borderRadius:"5px"}}>
                                    <div className="col-sm-12 col-md-6">
                                        <div className="margin-top-1">
                                            <label htmlFor="name">Address Line 1 <RequiredSign /> :</label>
                                            <div className='recommendation-input'>
                                                <input type="text"
                                                    value={this.state.address1}
                                                    onChange={(event) => this.setState({ address1: event.target.value })}
                                                    name="name" className="form-control" id="address1" placeholder="Enter Address 1"
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6">
                                        <div className="margin-top-1">
                                            <label htmlFor="name">Address Line 2 :</label>
                                            <div className='recommendation-input'>
                                                <input type="text"
                                                    value={this.state.address2}
                                                    onChange={(event) => this.setState({ address2: event.target.value })}
                                                    name="name" className="form-control" id="address2" placeholder="Enter Address 2"
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4">
                                        <div className="margin-top-1">
                                            <label htmlFor="name">City <RequiredSign /> :</label>
                                            <div className='recommendation-input'>
                                                <input type="text"
                                                    value={this.state.city}
                                                    onChange={(event) => this.setState({ city: event.target.value })}
                                                    name="name" className="form-control" id="city" placeholder="Enter City"
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4">
                                        <div className="margin-top-1">
                                            <label htmlFor="name">State <RequiredSign /> :</label>
                                            <div className='recommendation-input'>
                                                <input type="text"
                                                    value={this.state.state}
                                                    onChange={(event) => this.setState({ state: event.target.value })}
                                                    name="name" className="form-control" id="state" placeholder="Enter State"
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4">
                                        <div className="margin-top-1">
                                            <label htmlFor="name">Zip Code <RequiredSign /> :</label>
                                            <div className='recommendation-input'>
                                                <input type="text"
                                                    value={this.state.zipcode}
                                                    onChange={(event) => this.setState({ zipcode: event.target.value })}
                                                    name="name" className="form-control" id="zipcode" placeholder="Enter Zip Code"
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12" style={{marginBottom:"-15px"}}>
                                        <hr style={{backgroundColor:"#e5e5e5"}} />
                                    </div>
                                    <div className="col-sm-12 col-md-12">
                                        <label htmlFor="name">Account Type <RequiredSign /> :</label>
                                        <div className='recommendation-input'>
                                            <div className="col-md-3 col-sm-12 padding-left-zero">
                                                <div style={{display:"flex",alignItems:"baseline"}}>
                                                <input type="radio" 
                                                    className="form-check-input" 
                                                    id="radio3" name="optradio" 
                                                    value="domestic" 
                                                    checked={ this.state.accountType=="domestic" }
                                                    onChange={(event) => this.setState({ accountType: event.target.value })}
                                                />&nbsp;
                                                <label className="form-check-label" htmlFor="radio3"> Domestic  </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-12 padding-left-zero">
                                                <div style={{display:"flex",alignItems:"baseline"}}>
                                                <input type="radio" 
                                                    className="form-check-input" 
                                                    id="radio1" name="optradio" 
                                                    value="international" 
                                                    checked={ this.state.accountType=="international" }
                                                    onChange={(event) => this.setState({ accountType: event.target.value })}
                                                />&nbsp;
                                                <label className="form-check-label" htmlFor="radio1"> International </label>
                                                </div>
                                            </div>   
                                        </div>                                     
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="margin-top-1">
                                            <label htmlFor="bankName">Bank Name <RequiredSign /> :</label>
                                            <div className='recommendation-input'>
                                                <input type="text"
                                                    value={this.state.bankName}
                                                    onChange={(event) => this.setState({ bankName: event.target.value })}
                                                    name="bankName" className="form-control" id="bankName" placeholder="Enter Bank Name"
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="margin-top-1">
                                            <label htmlFor="routingNumber">Bank Address <RequiredSign /> :</label>
                                            <div className='recommendation-input'>
                                                <input type="text"
                                                    value={this.state.bankAddress}
                                                    onChange={(event) => this.setState({ bankAddress: event.target.value })}
                                                    name="bankAddress" className="form-control" id="bankAddress" placeholder="Enter Bank Address"
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="margin-top-1">
                                            <label htmlFor="accountNumber">Account Number <RequiredSign /> :</label>
                                            <div className='recommendation-input'>
                                                <input type="text"
                                                    value={this.state.accountNumber}
                                                    onChange={(event) => this.setState({ accountNumber: event.target.value })}
                                                    name="accountNumber" className="form-control" id="accountNumber" placeholder="Enter Account Number"
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="margin-top-1">
                                            <label htmlFor="routingNumber">Routing Number <RequiredSign /> :</label>
                                            <div className='recommendation-input'>
                                                <input type="text"
                                                    value={this.state.routingNumber}
                                                    onChange={(event) => this.setState({ routingNumber: event.target.value })}
                                                    name="routingNumber" className="form-control" id="routingNumber" placeholder="Enter Routing Number"
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    {this.state.accountType=="international"?
                                    <div className="col-sm-6 col-md-6">
                                        <div className="margin-top-1">
                                            <label htmlFor="swiftCode">Swift Code <RequiredSign /> :</label>
                                            <div className='recommendation-input'>
                                                <input type="text"
                                                    value={this.state.swiftCode}
                                                    onChange={(event) => this.setState({ swiftCode: event.target.value })}
                                                    name="swiftCode" className="form-control" id="swiftCode" placeholder="Enter Bank Name"
                                                />
                                            </div>

                                        </div>
                                    </div>:""
                                    }

                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <LaddaButton
                                id='lada-btn'
                                // style={{ width: '25%' }}
                                loading={this.state.loadinglada}
                                onClick={() => this.verifyAddress()}
                                data-color="#eee"
                                data-size={XS}
                                data-style={EXPAND_RIGHT}
                                data-spinner-size={30}
                                data-spinner-color="#ddd"
                                data-spinner-lines={12}
                                data-dismiss="modal"
                                className={this.state.address1 && this.state.city && this.state.state && this.state.zipcode && this.state.routingNumber && this.state.bankName && this.state.accountNumber && this.state.bankAddress && (this.state.accountType=='international'?(this.state.swiftCode?true:false):true) ? '' : 'disable'}

                            >
                                {'SUBMIT'}
                            </LaddaButton>
                            <LaddaButton
                                id='lada-btn'
                                loading={this.state.loadinglada}
                                onClick={() => this.resetData()}
                                data-color="#eee"
                                data-size={XS}
                                data-style={EXPAND_RIGHT}
                                data-spinner-size={30}
                                data-spinner-color="#ddd"
                                data-spinner-lines={12}
                                data-dismiss="modal"
                            >
                                {'CANCEL'}
                            </LaddaButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default VerifyAddressModel;
import React, { useState, useEffect } from 'react'

const PriorityTag = ({priority}) => {
    const [tag, setTag] = useState('');
    const [cssClass, setCssClass] = useState('');
    useEffect(() => {
        switch (priority) {
            case 'High Priority':
                setTag('High Priority');
                setCssClass('highPriorityTag');
                break;
            case 'Medium Priority':
                setTag('Medium Priority');
                setCssClass('mediumPriorityTag');
                break;
            case 'Low Priority':
                setTag('Low Priority');
                setCssClass('lowPriorityTag');
                break;
            default:
                break;
        }
    }, [priority])
    
  return (
    <div className={cssClass}>
        {tag}
    </div>
  )
}

export default PriorityTag
import React , { useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PropTypes from 'prop-types';
import './editor.scss';

// Add sizes to whitelist and register them
var Size = Quill.import('attributors/style/size');
Size.whitelist = ['12px', '14px', '16px', '18px','20px'];
Quill.register(Size, true);


// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = ["Montserrat"];
Quill.register(Font, true);

/*
 * Custom toolbar component including the custom heart button and dropdowns
 */
const CustomToolbar = () => (
  <div id="toolbar">

    <select className="ql-font">
      <option value="Montserrat" selected>Montserrat</option>
      {/* <option value="comic-sans">Comic Sans</option>
      <option value="courier-new">Courier New</option>
      <option value="georgia">Georgia</option>
      <option value="helvetica">Helvetica</option>
      <option value="lucida">Lucida</option> */}
    </select>

    <select className="ql-size">
      <option value="12px" selected>12px</option>
      <option value="14px">14px</option>
      <option value="16px">16px</option>
      <option value="18px">18px</option>
      <option value="20px">20px</option>
    </select>

    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
    <button className="ql-underline"></button>
    <button className="ql-strike"></button>

    <button className="ql-list" value="ordered"></button>
    <button className="ql-list" value="bullet"></button>

    <button className="ql-indent" value="-1"></button>
    <button className="ql-indent" value="+1"></button>

    <button className="ql-align" value={null}></button>
    <button className="ql-align" value="center"></button>
    <button className="ql-align" value="right"></button>
    <button className="ql-align" value="justify"></button>


    <button className="ql-link"></button>
    <button className="ql-clean"> </button>

  </div>
);

/*
 * Editor component with custom toolbar and content containers
 */
class Editor extends React.Component
{

  handleChange = (html, content, len, editor) =>
  {
    if (editor.getLength() === 1) 
    {
      return this.props.handleChange('');
    }

    return this.props.handleChange(html);
  }

  static formats = ['header', 'font', 'size','bold', 'italic', 'underline', 'strike', 'blockquote','list', 'bullet', 'indent','align','link'];

  static modules = {
    toolbar: {
      container: "#toolbar",
    }
  };

  render()
  {
    return (
      <div className="text-editor">
        <CustomToolbar />
        <ReactQuill 
          onChange={this.handleChange}
          value={this.props.editorHtml}
          modules={Editor.modules}
          formats={Editor.formats}
          placeholder={this.props.placeholder}
         />
       </div>
     )
  }
}

export default Editor
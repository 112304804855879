import React, { Component } from 'react';
import './FoundLetter.scss';
import LoadingSpinner1 from '../../../components/IIR Loader';
import FileUploadBlob from '../../../components/FileUploadBlob';
import { withRouter } from 'react-router-dom';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import axios from 'axios';
import swal from 'sweetalert';
import common from '../../../common';
import _ from 'lodash';
import images from "../../../images";



class FoundLetter extends Component {
    constructor(props) {
        super(props);
        this.companyId = '';
        this.state = {
            name: '',
            formLetter: '',
            formName: '',
            title: '',
            choosenFile: null,
            loading: false,
            loadinglada: false,
            Role: '',
            nameValidation: '',
            formLetterValidation: '',
            formNameValidation: '',
            titleValidation: '',
            companyLogoId: '',
            profileFormValidated: false,
            inputFile: null,
            imgData: '',
            imageUploading: false,

        }
    }

    componentWillMount() {
        const url = new URL(window.location.href);
        this.companyId = url.searchParams.get('companyId');
        this.getData();
        const userRole = window.localStorage.getItem('role');
        this.setState({ Role: userRole });
    }

    getIIRList() {
        let temp = this;
        let token = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/getIIR/${this.companyId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                const sampleArray = response.data.data
                const keys = Object.keys(sampleArray)
                const values = Object.values(sampleArray)
                let z = [];
                keys.forEach((key, i) => {
                    z.push({ title: key, state: values[i] })
                })
                z.forEach((ele, i) => {
                    let title = ele.title.split(" ");
                    let path = '';
                    for (const word of title) {
                        path += word;
                    }
                    path = path.substring(0, 1).toLowerCase() + path.substring(1);
                    ele.path = path;
                })
                z.length = 16;
                this.props.setIirList(z);
                temp.setState({ loadinglada2: false })

            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    getData() {
        let temp = this;
        let token = window.localStorage.getItem('token');
        // let CompanyId = window.localStorage.getItem('companyId');
        temp.setState({ loading: true });
        const id = window.localStorage.getItem('id');
        this.setState({ id: id })
        axios.get(`${common.path.base_url}/getIIRFoundLetter?companyId=${this.companyId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                this.setState({ name: response.data.data.companyName });
                this.setState({ formLetter: response.data.data.formLetter });
                this.setState({ formName: response.data.data.formName });
                this.setState({ title: response.data.data.title, companyLogoId: response.data.data.companyLogo });

            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    handleChange(id, newValue) {
        if (id === "companyName")
            this.setState({ name: newValue });
        else if (id === 'formLetter')
            this.setState({ formLetter: newValue });
        else if (id === 'formName')
            this.setState({ formName: newValue });
        else if (id === 'title')
            this.setState({ title: newValue });
    }

    gotoFoundLetterPreview() {
        this.props.history.push(`/foundLetterPreview?companyId=${this.companyId}`)
    }

    putData() {
        let temp = this;
        temp.setState({ loadinglada: true });
        const token = localStorage.getItem('token');
        axios.put(`${common.path.base_url}/updateIIRfoundLetter`, {
            companyName: this.state.name,
            companyId: this.companyId,
            formLetter: this.state.formLetter,
            formName: this.state.formName,
            title: this.state.title,
            companyLogo: this.state.companyLogoId
        },
            {
                headers: {

                    "Authorization": token
                }
            })
            .then(function (response) {
                swal({
                    title: "Updated!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(() => {
                        temp.getData();
                        temp.getIIRList();
                    });
                temp.setState({ profileFormValidated: false });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });
            });
    }

    formValidaton(id, val) {
        if (id === 'companyName') {
            let name = document.getElementById('companyName').value;
            if (name === '') {
                this.setState({
                    nameValidation: "Company name should not be empty*",
                })
            } else {
                this.setState({
                    nameValidation: "",
                })
            }
        }
        else if (id === 'formLetter') {
            let title = document.getElementById('formLetter').value;
            if (title === '') {
                this.setState({
                    formLetterValidation: "Form letter should not be empty*",
                })
            } else {
                this.setState({
                    formLetterValidation: "",
                })
            }
        }

        else if (id === 'formName') {
            let company = document.getElementById('formName').value;
            if (company === '') {
                this.setState({
                    formNameValidation: "Form Name should not be empty*",
                })
            } else {
                this.setState({
                    formNameValidation: "",
                })
            }
        }
        else if (id === 'title') {
            let company = document.getElementById('title').value;
            if (company === '') {
                this.setState({
                    titleValidation: "Title should not be empty*",
                })
            } else {
                this.setState({
                    titleValidation: "",
                })
            }
        }
    }

    uploadLogo(file) {
        if (file.size) {
            const temp = this;
            temp.setState({ imageUploading: true });
            let tokenpass = window.localStorage.getItem('token');
            let data = new FormData();
            data.append('file', file);
            const isPrivate = true;
            data.append('isPrivate', isPrivate)
            axios.post(`${common.path.base_url}/uploadFile`, data, {
                headers: {
                    "Authorization": tokenpass
                }
            })
                .then((response) => {
                    temp.setState({ companyLogoId: response.data.data.id, loadinglada: false, choosenFile: null });
                    temp.setState({ imageUploading: false });
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        window.localStorage.clear();
                        window.location.href = '/';
                        return;
                    } else { }
                });
        }
    }

    render() {
        return (
            <div className="foundLetter">
                {this.state.loading ? <LoadingSpinner1 /> : ""}
                <div className="foundLetter-main">
                    <div className='heading'>
                        <h3>Executive Letter</h3>
                    </div>
                    <div className='foundLetter'>
                        <div className='foundLetter-details'>
                            <React.Fragment>
                                <form className="form-horizontal">
                                    <div className="form-group">
                                        <label className="col-md-3 control-label">Company Legal Name:</label>
                                        <div className="col-md-8">
                                            <input className="form-control" id="companyName" type="text" placeholder='name'
                                                onKeyUp={(event) => this.formValidaton(event.target.id)}
                                                onChange={(event) => { this.handleChange(event.target.id, event.target.value) }}
                                                value={this.state.name} />
                                            <p>{this.state.nameValidation}</p>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-3 control-label company-label">Company logo:</label>
                                        <div className='col-md-8'>
                                            <div className='upload-foundLetter-pic '>
                                                <div className="foundLetter-pic-box">
                                                    <img id='preview' className="profile-pic" src={this.state.imageUploading ? `${images.path.loader}` : `${common.path.base_url}/getFileNew?fileId=${this.state.companyLogoId}`} alt='' />
                                                    <div className="p-image">
                                                        <i onClick={() => document.querySelector(`#chooseFile${1}`).click()} className="fa fa-pencil upload-button"></i>
                                                        <FileUploadBlob
                                                            id={`chooseFile${1}`}
                                                            getFile={(file) => { this.setState({ choosenFile: file }); this.uploadLogo(this.state.choosenFile) }}
                                                            acceptType={`image/*`}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-3 control-label">Letter:</label>
                                        <div className="col-md-8">
                                            <textarea className="form-control" id="formLetter" type="message" placeholder='Letter' rows="4" cols="50"
                                                onKeyUp={(event) => this.formValidaton(event.target.id)}
                                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                value={this.state.formLetter}></textarea>
                                            <p>{this.state.formLetterValidation}</p>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-3 control-label">Full Name:</label>
                                        <div className="col-md-8">
                                            <input className="form-control" id="formName" type='text' placeholder='Full Name'
                                                onKeyUp={(event) => this.formValidaton(event.target.id)}
                                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                value={this.state.formName} />
                                            <p >{this.state.formNameValidation}</p>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-3 control-label">Title:</label>
                                        <div className="col-md-8">
                                            <input className="form-control" id="title" type='text' placeholder='Title'
                                                onKeyUp={(event) => this.formValidaton(event.target.id)}
                                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                value={this.state.title} />
                                            <p >{this.state.titleValidation}</p>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-3 control-label1"></label>
                                        <div className="col-md-8 updateButton">
                                            <div className='foundBtn'>
                                                <LaddaButton
                                                    id="update-btn"
                                                    loading={this.state.loadinglada}
                                                    onClick={() => this.putData()}
                                                    data-color="#eee"
                                                    data-size={XS}
                                                    data-style={EXPAND_RIGHT}
                                                    data-spinner-size={30}
                                                    data-spinner-color="#ddd"
                                                    data-spinner-lines={12}
                                                    className={_.isEmpty(this.state.name && this.state.title && this.state.formLetter && this.state.formName) ? 'disable' : ''}
                                                >
                                                    SAVE
                                            </LaddaButton>
                                                <LaddaButton
                                                    id="update-btn"
                                                    loading={this.state.loadinglada2}
                                                    onClick={() => this.gotoFoundLetterPreview()}
                                                    data-color="#eee"
                                                    data-size={XS}
                                                    data-style={EXPAND_RIGHT}
                                                    data-spinner-size={30}
                                                    data-spinner-color="#ddd"
                                                    data-spinner-lines={12}
                                                    className={_.isEmpty(this.state.name && this.state.title && this.state.formLetter && this.state.formName) ? 'disable' : ''}

                                                >
                                                    PREVIEW
                                            </LaddaButton>
                                            </div>
                                            <input type="reset" className="btn btn-default cancel" value="CANCEL"
                                                onClick={() => {
                                                    let path = `/dashboard`;
                                                    this.props.history.push(path);
                                                }} />
                                        </div>
                                    </div>
                                </form>
                            </React.Fragment>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}
// export default Profile;
export default withRouter(FoundLetter);
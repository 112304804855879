import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './Container.scss';
import Start from '../components/Start/Start'

class MainContainer extends Component {
    
    render() {
        return (
            <>
                <Start />
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        
    };
};

export default connect(null, mapDispatchToProps)(MainContainer);
import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';

import images from '../../../images';

const SecondaryChecklist = (props) => {
  return (
    <Droppable droppableId={`droppable${props.checklist._id}`} type={props.primaryChecklistNum}>
      {
        provided => (
          <label ref={provided.innerRef} {...provided.droppableProps}>
            {
              props.subChecklists && props.subChecklists.length > 0 &&
              props.subChecklists.map((singleSublist, index) => {
                return <Draggable key={singleSublist._id}
                  draggableId={singleSublist._id}
                  index={index}
                >
                  {
                    provided => (
                      <div ref={provided.innerRef}
                        {...provided.draggableProps}
                        className="form-group"
                        key={index}
                        style={{ ...provided.draggableProps.style }}>
                        <i className="fa fa-bars" aria-hidden="true" {...provided.dragHandleProps}></i>
                        <input className="form-control secondary-checklists" type="text" name="remember" value={singleSublist.subTitle} readOnly />
                        <img
                          src={images.path.deleteChecklist}
                          className="delete-item"
                          alt="Delete Sub Checklist"
                          onClick={() => props.onDeleteSubChecklist(props.checklist._id, singleSublist._id)}
                        />
                      </div>
                    )
                  }
                </Draggable>
              })
            }
            {provided.placeholder}
            <div className="form-group">
              <p data-toggle="modal" data-target="#addSubChecklistModal">Add sub checklist <i className="fa fa-plus" aria-hidden="true" onClick={() => props.onClickAddSubChecklist(props.checklist._id)}></i></p>
            </div>
          </label>
        )
      }
    </Droppable >
  );
}

SecondaryChecklist.propTypes = {
  subChecklists: PropTypes.array.isRequired,
  checklist: PropTypes.object.isRequired,
  primaryChecklistNum: PropTypes.number.isRequired,
  onClickAddSubChecklist: PropTypes.func.isRequired,
};

export default SecondaryChecklist;
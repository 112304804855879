export const setSummaryStartupsData = (payload) => {
  return {
    type: 'SET_SUMMARY_STARTUPS_DATA',
    payload
  }
}

export const setSummaryInvestmentData = (payload) => {
  return {
    type: 'SET_SUMMARY_INVESTMENT_DATA',
    payload
  }
}
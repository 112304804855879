import axios from 'axios';
import _ from 'lodash';
import React, { Component } from 'react';
import Progress from 'react-progressbar';
import Select from 'react-select';
import common from '../../common';
import TButton from '../../components/TButton';
import images from "../../images";
import './CompanyCard.scss';



import swal from 'sweetalert';
import UploadedFilesCard from '../../components/UploadedFilesCard';

// Dummy List of Files
const fileList = [
    'Aug 10.2019 - Investor.csv',
    'Aug 9.2019 - Investor.csv',
    'Aug 8.2019 - Investor.csv',
    'Aug 7.2019 - Investor.csv',
    'Aug 6.2019 - Investor.csv',
    'Aug 5.2019 - Investor.csv',
    'Aug 4.2019 - Investor.csv',
    'Aug 3.2019 - Investor.csv',
    'Aug 2.2019 - Investor.csv',
    'Aug 1.2019 - Investor.csv',
    'Jul 30.2019 - Investor.csv',
    'Jul 29.2019 - Investor.csv',
    'Jul 28.2019 - Investor.csv',
    'Jul 27.2019 - Investor.csv',
    'Jul 26.2019 - Investor.csv',
    'Jul 25.2019 - Investor.csv',
    'Jul 24.2019 - Investor.csv',
    'Jul 23.2019 - Investor.csv',
    'Jul 22.2019 - Investor.csv',
    'Jul 21.2019 - Investor.csv',
];
class CompanyCard extends Component {
    constructor(props) {
        super(props);
        this.companyId = '';
        this.state = {
            iirList: [],
            cardDetails: [],
            // count: this.props.count,
            count: 0,
            checklistData: '',
            checklistCount: 0,//this.props.checklistCount,
            executiveProgress: 0, // this.props.executiveProgress,
            investment: null,
            [this.props.companyId]: this.props.dropdownData.activeInvestment,
            shouldUpdate: true,
        }
    }

    componentWillMount() {
        // console.log("--------");
        // console.log(this.props.dropdownData);
        const url = new URL(window.location.href);
        this.companyId = url.searchParams.get('companyId');
        const userRoleCode = localStorage.getItem('roleCode');
        this.setState({ roleCode: userRoleCode, count: 0, checklistCount: 0, executiveProgress: false });
        // this.getIIRList();
        // this.getData();

    }
    componentWillReceiveProps(nextProps) {
        this.props = nextProps;
        // this.setState({ count: nextProps.count, checklistCount: nextProps.checklistCount, executiveProgress: nextProps.executiveProgress  });  
        // this.getIIRList();
        // this.getData();
    }

    onDocUploadClick = (documentId, investmentId, companyId) => {
        if (documentId && investmentId && companyId) {
            let temp = this;
            temp.setState({ loadinglada: true });
            let token = window.localStorage.getItem('token');
            const params = {
                documentId, investmentId, companyId
            }
            axios.post(`${common.path.base_url}/importSingleConflictContact`, params,
                {
                    headers: {
                        "Authorization": token
                    }
                })
                .then((response) => {
                    this.props.getDashboardData();

                })
                .catch((error) => {
                    temp.setState({ loadinglada: false });
                    if (error.response && error.response.data && common.AUTH_EXPIRED === error.response.data.message) {
                        window.localStorage.clear();
                        window.location.href = '/';
                        return;
                    }
                    else if (error.response) {
                        swal({
                            title: "Error!",
                            text: (error.response && error.response.data && error.response.data.message) ? error.response.data.message : error.response,
                            icon: "error",
                            button: "Ok!",
                        });

                    }
                    else {
                        swal({
                            title: "Network Error!",
                            text: error.message,
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                });
        }
    };

    renderUploadedCards() {
        let cards = null;
        cards = this.props.fileList.map((file, i) => {
            return (<div className="file-link" key={i}>
                <UploadedFilesCard
                    onCardClick={() => { }}
                    file={file}
                    onActionClick={() => this.removeFile(file)}
                    onDocUploadClick={(documentId, investmentId, companyId) => this.onDocUploadClick(documentId, investmentId, companyId)}
                />
            </div>);
        })
        return cards;
    }

    removeFile(file) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this file",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                const token = localStorage.getItem('token');
                if (willDelete) {
                    //@TODO CALL DELETE CONTACT API HERE WITH NECESSARY CHECKS
                    this.setState({ loading: true })
                    axios.delete(`${common.path.base_url}/deleteFile`, { headers: { "Authorization": token }, data: { id: file._id } }
                    )
                        //@TODO CALL ON SUCCESS OF API
                        .then((response) => {
                            this.setState({ loading: false });
                            this.props.onRemoveUploadedDocClick()
                            swal({
                                title: "Success!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            })
                                .then(() => {
                                    this.getIIRList();
                                    this.getData();
                                    this.renderUploadedCards();
                                });
                        })
                        .catch((error) => {
                            this.setState({ loading: false });
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            } else if (error.response) {
                                swal({
                                    title: "Error!",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                    icon: "error",
                                    button: "Ok!",
                                });
                            } else {
                                swal({
                                    title: "Network Error!",
                                    text: error.message,
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });
                }
                else {
                    swal({ text: `${file.documentName} file is safe!`, icon: 'info' });
                }
            });
    }

    getAcceptedCount() {
        let count = 0;
        if (this.props.investorList && this.props.investorList.length > 0) {
            let investors = this.props.investorList;
            investors.forEach((investor) => investor.invitationAccepted === '1' ? count += 1 : count);
        }
        return count;
    }
    getIIRList() {
        if (this.props.investmentId && this.props.company && this.props.company.iir
            && this.props.company.iir.length > 0) {
            const iirObject = this.props.company.iir.filter(item => item.investmentId === this.props.investmentId)[0];
            if (iirObject) {
                //console.log(this.props.company);
                let sampleArray = {};
                sampleArray.Company = iirObject.Company;
                sampleArray.Leadership = iirObject.Leadership;
                sampleArray.Board = iirObject.Board;
                sampleArray['Market Performance and Summary'] = iirObject['Market Performance and Summary'];
                sampleArray['Investment Overview'] = iirObject['Investment Overview'];
                sampleArray['Product Statement'] = iirObject['Product Statement'];
                sampleArray['Solution Summary'] = iirObject['Solution Summary'];
                sampleArray.Product = iirObject.Product;
                sampleArray.Differentiation = iirObject.Differentiation;
                sampleArray['Current Partners'] = iirObject['Current Partners'];
                sampleArray['Major Investor'] = iirObject['Major Investor'];
                sampleArray['Funding'] = iirObject['Funding'];
                sampleArray['Historical and Projected Revenue'] = iirObject['Historical and Projected Revenue'];
                sampleArray['Key Customers'] = iirObject['Key Customers'];
                // sampleArray['Press'] = iirObject['Press'];
                sampleArray['Founder Letter '] = iirObject['Founder Letter '];
                // sampleArray['isApproved'] = iirObject['isApproved'];
                if (sampleArray) {
                    const keys = Object.keys(sampleArray)
                    const values = Object.values(sampleArray)
                    let z = [];
                    let executiveLetter = [];
                    keys.forEach((key, i) => {
                        z.push({ title: key, state: values[i] })
                    })
                    // z={id:this.props.id}   
                    z.forEach((ele, i) => {
                        let title = ele.title.split(" ");
                        let path = '';
                        for (const word of title) {
                            path += word;
                        }
                        path = path.substring(0, 1).toLowerCase() + path.substring(1);
                        ele.path = path;
                        if (ele.path === 'founderLetter') {
                            executiveLetter.push(ele);
                        }
                    })
                    // z.length = 15;
                    const iirList = z.filter((iir) => iir.path !== 'founderLetter' && iir.path !== '_id');
                    // z.push({[this.props.id]:{...z}}); 
                    // z.push({id:this.props.id})

                    let stateCount = iirList && iirList.length > 0 ? iirList.filter(v => v.state).length : 0;
                    return stateCount;
                }
            }
            return 0;
            // let temp = this;
            // let token = window.localStorage.getItem('token');
            // axios.get(`${common.path.base_url}/getInvestmentIIR/${this.props.investmentId}`, {
            //     headers: {
            //         "Authorization": token
            //     }
            // })
            // .then((response) => {
            //     temp.setState({ loading: false });
            //     const sampleArray = response.data.data;
            //     if (sampleArray) {
            //         temp.setState({ isApproved: sampleArray.isApproved });
            //         const keys = Object.keys(sampleArray)
            //         const values = Object.values(sampleArray)
            //         let z = [];
            //         let executiveLetter = [];
            //         keys.forEach((key, i) => {
            //             z.push({ title: key, state: values[i] })
            //         })
            //         // z={id:this.props.id}   
            //         z.forEach((ele, i) => {
            //             let title = ele.title.split(" ");
            //             let path = '';
            //             for (const word of title) {
            //                 path += word;
            //             }
            //             path = path.substring(0, 1).toLowerCase() + path.substring(1);
            //             ele.path = path;
            //             if (ele.path === 'founderLetter') {
            //                 executiveLetter.push(ele);
            //             }
            //         })
            //         // z.length = 15;
            //         const iirList = z.filter((iir) => iir.path !== 'founderLetter' && iir.path !== '_id');
            //         // z.push({[this.props.id]:{...z}}); 
            //         // z.push({id:this.props.id})
            //         this.setState({ iirList })
            //         this.getSateCount();
            //         this.getExecutiveLetterStatus(executiveLetter)
            //   }

            // })
            // .catch(function (error) {
            //     console.log(error);
            // });
        } else {
            // console.log('else');
            return 0;
        }

    }

    isPDFUploaded() {
        if (this.props.investmentId && this.props.company && this.props.company.iir
            && this.props.company.iir.length > 0) {
            const iirObject = this.props.company.iir.filter(item => item.investmentId === this.props.investmentId)[0];
            if (iirObject && iirObject.iirPDF) {
                return true;
            }
        }
        return false;

    }

    getExecutiveLetterStatus() {
        if (this.props.investmentId && this.props.company && this.props.company.iir
            && this.props.company.iir.length > 0) {
            const iirObject = this.props.company.iir.filter(item => item.investmentId === this.props.investmentId)[0];
            if (iirObject && iirObject['Founder Letter ']) {
                return true;
            }
            return false;
        }
        return false;
    }

    getMatchedMasterChecklist = (masterChecklistId) => {
        return this.props.masterChecklist.filter(singleMasterChecklist => singleMasterChecklist._id === masterChecklistId);
    }
    getData(activeInvestmentData, type) {
        if (this.props.investmentId && this.props.company && this.props.company.checklist && this.props.company.checklist.length > 0) {
            const checklistData = this.props.company.checklist.filter(list => list.investmentId === this.props.investmentId);
            if (activeInvestmentData && (activeInvestmentData.isGlobalCheckListApplicable || type === common.checklistTypes.close || type === common.checklistTypes.exit)) {
                let masterCheckListIds = [];
                if (type === common.checklistTypes.exit) {
                    const { exitProcessType } = activeInvestmentData;

                    masterCheckListIds = this.props.masterChecklist.filter(i => {
                        if (i.type === type && i.subType === exitProcessType && i.investmentId === this.props.investmentId) {
                            return i._id;
                        }
                    });
                    if (masterCheckListIds.length === 0) {
                        masterCheckListIds = this.props.masterChecklist.filter(i => {
                            if (i.type === type && i.subType === exitProcessType && !i.investmentId) {
                                return i._id;
                            }
                        });
                    }

                } else {
                    masterCheckListIds = this.props.masterChecklist.filter(i => {
                        if (i.type === type) {
                            return i._id;
                        }
                    });
                }


                masterCheckListIds = masterCheckListIds.map(i => i._id);
                const data = checklistData.filter((item) => {
                    if (masterCheckListIds.indexOf(item.masterCheckListId) !== -1 && item.type === type && item.status === true) {
                        return item;
                    }
                });
                return data.length;
                // return checklistData.filter((v) => v.status && v.type === type && this.getMatchedMasterChecklist(v.masterCheckListId)).length;
            }
            return checklistData.filter(v => v.status).length;

            // let temp = this;
            // let token = window.localStorage.getItem('token');
            // temp.setState({ loading: true });
            // const id = window.localStorage.getItem('id');
            // this.setState({ id: id })
            // axios.get(`${common.path.base_url}/getInvestmentCheckList?investmentId=${this.props.investmentId}`, {
            //     headers: {
            //         "Authorization": token
            //     }
            // })
            // .then((response) => {
            //     temp.setState({ loading: false });
            //     this.setState({
            //         checklistData: response.data.data
            //     });
            //     this.getCheckListSateCount();
            // })
            // .catch(function (error) {
            //     // console.log(error);
            // });
        } else {
            return 0;
        }

    }

    getCheckListSateCount() {
        var CheckListSateCount = this.state.checklistData.filter(v => v.status).length;
        this.setState({ checklistCount: CheckListSateCount })
    }

    getSateCount() {
        var stateCount = this.state.iirList && this.state.iirList.length > 0 ? this.state.iirList.filter(v => v.state).length : 0;
        this.setState({ count: stateCount });
    }
    redirectToStartupView(id, searchString) {
        // this.props.history.push(`/contacts?companyId=${id}&search=${searchString}`);
        this.props.history.push(`/investmentDetail?id=${this.props.investmentId}`);
        //investmentDetail?id=611e3e62f06c363a8a5d75c1&activeTab=invite
    }

    redirectToStartupSearch(id, searchString) {
        this.props.history.push(`/contacts?companyId=${id}&search=${searchString}`);
        //this.props.history.push(`/investmentDetail?id=${this.props.investmentId}`);
        //investmentDetail?id=611e3e62f06c363a8a5d75c1&activeTab=invite
    }

    handleClick(event) {
        let showMenu = 1;
        this.setState({ shouldUpdate: true });
        if (document.getElementById("company-option-" + this.props.companyCardIndex).classList.contains("show-menu")) {
            showMenu = 0;
        }

        this.props.toggleShowMenuForDashboardCard();

        if (showMenu == 1) {
            document.getElementById("company-option-" + this.props.companyCardIndex).classList.add("show-menu");
        }

        event.preventDefault();
    }

    handleCompanyClick(event) {
        this.props.toggleShowCompanyMenuForDashboardCard();
        event.preventDefault();
    }
    getInvestment(data, key) {
        const investmentAllData = [...data.company.investment];
        const investmentData = investmentAllData.splice(investmentAllData.length - 1, 1)[0];
        if (investmentData && investmentData[key]) {
            return investmentData[key];
        }
        return 'N/A';

    }

    getMasterChecklistLengthByType = (type) => {
        if (this.props.investmentId && this.props.activeInvestmentData) {
            let finalMasterChecklist = [];
            if (type === common.checklistTypes.exit) {
                const { exitProcessType } = this.props.activeInvestmentData;
                finalMasterChecklist = this.props.masterChecklist.filter(singleMasterChecklist => {
                    if (singleMasterChecklist.type === type && singleMasterChecklist.subType === exitProcessType && singleMasterChecklist.investmentId === this.props.investmentId) {
                        return singleMasterChecklist;
                    }
                });
                if (finalMasterChecklist.length === 0) {
                    finalMasterChecklist = this.props.masterChecklist.filter(singleMasterChecklist => {
                        if (singleMasterChecklist.type === type && singleMasterChecklist.subType === exitProcessType && !singleMasterChecklist.investmentId) {
                            return singleMasterChecklist;
                        }
                    });

                }

            } else {
                finalMasterChecklist = this.props.masterChecklist.filter(singleMasterChecklist => singleMasterChecklist.type === type);
            }
            return finalMasterChecklist && finalMasterChecklist.length > 0 ? finalMasterChecklist.length : 1;
        }
    }

    getOnBoardChecklistStatus = (activeInvestmentData, type) => {
        if (activeInvestmentData && (activeInvestmentData.isGlobalCheckListApplicable || type === common.checklistTypes.close || type === common.checklistTypes.exit)) {
            const percentage = ((this.getData(activeInvestmentData, type) * 100) / this.getMasterChecklistLengthByType(type, activeInvestmentData)).toFixed();
            return percentage > 100 ? 100 : percentage;
        }
        return ((this.getData(activeInvestmentData) / 21) * 100).toFixed() > 100 ? 100 : ((this.getData(activeInvestmentData) / 21) * 100).toFixed();
    };

    getOnBoardChecklistProgress = (activeInvestmentData, type) => {
        if (activeInvestmentData && (activeInvestmentData.isGlobalCheckListApplicable || type === common.checklistTypes.close || type === common.checklistTypes.exit)) {
            const percentage = ((this.getData(activeInvestmentData, type) * 100) / this.getMasterChecklistLengthByType(type)).toFixed()
            return percentage > 100 ? 100 : percentage;
        }
        return 4.76 * this.getData(activeInvestmentData) > 100 ? 100 : 4.76 * this.getData(activeInvestmentData);
    };

    render() {
        console.log(this.props.companyNickName);
        return (
            <div className="company-card" key={this.props.id}>

                {/* ---Header Section START--- */}
                <div className="card-header-section">
                    <div className="card-header-txt" onClick={() => this.redirectToStartupView(this.props.id, this.props.companyName)}>
                        { this.props.legalName !== undefined && this.props.legalName !== "" && this.props.legalName !== null 
                            ? this.props.legalName 
                            : this.props.companyNickName !== undefined && this.props.companyNickName !== "" && this.props.companyNickName !== null 
                            ? this.props.companyNickName 
                            : this.props.companyName }
                    </div>
                    <div id={`company-option-${this.props.companyCardIndex}`} className={`card-menu `}>
                        {/* <a href="#" onClick={() => this.props.onAddInvestmentClick() && this.props.onAddInvestmentClick()}> {this.props.company.investment && this.props.company.investment.length > 0 ?  'Update' : 'Add'} Investment</a> */}
                        <div className='three-dots-main'>
                            <a href="#" id="three-dots" onClick={(event) => { this.handleClick(event) }} >
                                <img src={images.path.kebabMenuIcon} className="menu-icon"></img>
                                <ul className={`dropdown-menu2`}>
                                    <li className="resend" onClick={() => this.props.onAddInvestmentClick() && this.props.onAddInvestmentClick()}>Create Investment</li>
                                    {this.props.company.investment.length > 0 &&
                                        <li className="resend" onClick={() => this.props.onEditInvestmentClick() && this.props.onEditInvestmentClick()}>Update Investment</li>
                                    }
                                    {this.props.company.investment.length > 0 &&
                                        <li className="resend" onClick={() => this.props.onDeleteInvestmentClick() && this.props.onDeleteInvestmentClick()}>Delete Investment</li>
                                    }
                                    {this.props.company.investment.length > 0 &&
                                        <li className="resend" onClick={() => this.props.history.push(`/investmentDetail?id=${this.props.investmentId}`)}>View Investment</li>
                                    }
                                    {/* {this.props.company.investment.length > 1 &&
                                        <li className="resend" onClick={() => this.props.onSwitchInvestmentClick() && this.props.onSwitchInvestmentClick()}>Switch Investment</li>
                                    } */}
                                    {this.props.company.investment.length > 0 &&
                                        <li className="resend" onClick={() => this.props.history.push(`/startupDetail?id=${this.props.id}`)}>View Startup</li>
                                    }
                                    {(this.state.roleCode === '001' || this.state.roleCode === '002') && <li className="resend" onClick={() => this.props.onEditCompanyClick() && this.props.onEditCompanyClick()}>{`Edit Startup`}</li>}

                                    {this.props.company.investment.length <= 0 &&
                                        (this.state.roleCode === '001' || this.state.roleCode === '002') && <li className="resend" onClick={() => this.props.onDeleteCompanyClick() && this.props.onDeleteCompanyClick()}>{`Delete Startup`}</li>
                                    }

                                    {(this.state.roleCode === '001' || this.state.roleCode === '002') && <li className="resend" onClick={() => this.props.history.push(`/investment-settings/${this.props.investmentId}`)}>Investment Settings</li>}

                                    {(this.state.roleCode === '001' || this.state.roleCode === '002') && <li className="resend" onClick={() => this.redirectToStartupSearch(this.props.id, this.props.companyName)}>View Team</li>}

                                </ul>
                            </a>
                        </div>
                    </div>
                    {/* <div className="card-menu">
                        <span className="icon-circle" onClick={() => this.props.onAddInvestorClick && this.props.onAddInvestorClick()} ><img src={images.path.addIcon}></img></span>
                    </div> */}

                </div>
                {/* ---Header Section END--- */}



                <div className="card-header-txt invsestment-name">
                    {
                        // this.getInvestment(this.props, 'name')
                        this.props.company && this.props.company.investment.length === 0 &&
                        'N/A'

                    }
                    {this.props.dropdownData && this.props.dropdownData.activeInvestment &&
                        <Select
                            id='investment'
                            options={this.props.dropdownData.investments}
                            // value={this.state[this.props.companyId]}
                            value={{
                                value: this.props.activeInvestmentData._id,
                                label: this.props.activeInvestmentData.name,
                            }}
                            onChange={(event) => {
                                this.setState({ [this.props.companyId]: event })
                                this.props.onSwitchInvestment(event)
                            }
                            }
                        />}
                </div>
                {/* ---Count Section START--- */}
                <div className="count-block stage-wraper">
                    <div className="count-txt">Stage</div>
                    {
                        <div className="stage">&nbsp;
                            {
                                this.props.activeInvestmentData && this.props.activeInvestmentData.stage ?
                                    this.props.activeInvestmentData.stage === 'invite' ? 'PIPELINE' : this.props.activeInvestmentData.stage.toUpperCase() : 'N/A'
                            }
                        </div>
                    }
                </div>

                <div className="card-count-section">


                    <div className="count-block">
                        <div className="count-value">{this.props.invitationSentCount}</div>
                        <div className="count-txt">{`Invites Sent`}</div>
                    </div>
                    <div className="count-block">
                        <div className="count-value">{this.props.inviteAcceptedCount}</div>
                        <div className="count-txt">{`Invites Accepted`}</div>
                    </div>
                </div>
                {/* ---Count Section END--- */}
                {
                    this.props.investmentId ? (
                        <div className='progressBarSection custom-progressbar'>
                            <div className='onBoardStepSection'>
                                <div className='onBoardStepSectionLabels'>
                                    <div className="left-section">
                                        {/* Onboarding Step Progress */}
                                        <a href="javascript:;" onClick={() => this.props.history.push(`/onboardChecklist?companyId=${this.props.id}&investmentId=${this.props.investmentId}`)}> Investment Checklist </a>
                                    </div>
                                    <div className="right-section">
                                        {

                                            `${this.getOnBoardChecklistStatus(this.props.activeInvestmentData, common.checklistTypes.open)}% Completed`
                                        }
                                    </div>
                                </div>
                                <div className='progressBar'>
                                    {
                                        <Progress className='subProgressBar' completed={this.getOnBoardChecklistProgress(this.props.activeInvestmentData, common.checklistTypes.open)} />
                                    }
                                </div>
                            </div>

                            <div className='onBoardStepSection onboard-executiveprogress'>
                                <div className='onBoardStepSectionLabels'>
                                    <div className="left-section">
                                        {/* Onboarding Step Progress */}
                                        <a href="javascript:;" onClick={() => this.props.history.push(`/executiveLetter?companyId=${this.props.id}&investmentId=${this.props.investmentId}`)}> Executive Letter </a>
                                    </div>
                                    <div className="right-section">
                                        {this.props.activeInvestmentData && this.props.activeInvestmentData.stage == 'closed' ? `100% Completed` :
                                            `${this.getExecutiveLetterStatus() ? `100% Completed` : `0% Completed`}`}
                                    </div>
                                </div>
                                <div className='progressBar'>
                                    {this.props.activeInvestmentData && this.props.activeInvestmentData.stage == 'closed' ?
                                        <Progress className='subProgressBar' completed={100} /> :
                                        <Progress className='subProgressBar' completed={this.getExecutiveLetterStatus() ? 100 : 0} />}
                                </div>
                            </div>

                            <div className='docUploadSection'>
                                <div className='docUploadSectionLabels'>
                                    <div className="left-section">
                                        {/* Document Upload Progress */}
                                        {/* (this.props.activeInvestmentData.iirReviewApproveStatus.isApproveByStartup && this.props.activeInvestmentData.iirReviewApproveStatus.isApproveByStartup.isNotifiedByTSG) */}
                                        <a href="javascript:;" onClick={() => this.props.history.push(`/iir/company?companyId=${this.props.id}&investmentId=${this.props.investmentId}`)}> Investment Intelligence Report </a>
                                    </div>
                                    <div className="right-section">
                                        {((this.props.activeInvestmentData && this.props.activeInvestmentData.stage === 'closed') || this.isPDFUploaded()) ?
                                            `${100}% Completed` :

                                            (this.props.activeInvestmentData && this.props.activeInvestmentData.iirReviewApproveStatus && (this.props.activeInvestmentData.iirReviewApproveStatus.isApproveByStartup && this.props.activeInvestmentData.iirReviewApproveStatus.isNotifiedByTSG)) ?

                                                `${100}% Completed`
                                                :
                                                `${((this.getIIRList() / 14) * 100).toFixed()}% Completed`}
                                    </div>
                                </div>
                                <div className='progressBar'>
                                    {((this.props.activeInvestmentData && this.props.activeInvestmentData.stage === 'closed') || this.isPDFUploaded()) ?
                                        <Progress className='subProgressBar' completed={100} /> :

                                        (this.props.activeInvestmentData && this.props.activeInvestmentData.iirReviewApproveStatus && (this.props.activeInvestmentData.iirReviewApproveStatus.isApproveByStartup && this.props.activeInvestmentData.iirReviewApproveStatus.isNotifiedByTSG)) ?
                                            <Progress className='subProgressBar' completed={100} />
                                            :
                                            <Progress className='subProgressBar' completed={7.2 * this.getIIRList()} />}
                                </div>
                            </div>
                            <div className='onBoardStepSection'>
                                <div className='onBoardStepSectionLabels'>
                                    <div className="left-section">
                                        {/* Onboarding Step Progress */}
                                        {
                                            this.props.activeInvestmentData
                                                && this.props.activeInvestmentData.stage === "closed"
                                                ? <a href="javascript:;"
                                                    onClick={() => this.props.history.push(`/onboardChecklist?companyId=${this.props.id}&investmentId=${this.props.investmentId}&type=${common.checklistTypes.close}`)}>
                                                    Post Close Investment Checklist
                                                </a>
                                                : <span style={{ color: '#808080' }}>Post Close Investment Checklist</span>
                                        }
                                    </div>
                                    <div className="right-section">
                                        {
                                            this.props.activeInvestmentData
                                                && this.props.activeInvestmentData.stage === "closed"
                                                ? `${this.getOnBoardChecklistStatus(this.props.activeInvestmentData, common.checklistTypes.close)}% Completed`
                                                : `${0}% Completed`
                                        }
                                    </div>
                                </div>
                                <div className='progressBar'>
                                    {
                                        this.props.activeInvestmentData
                                            && this.props.activeInvestmentData.stage === "closed"
                                            ? <Progress className='subProgressBar' completed={this.getOnBoardChecklistProgress(this.props.activeInvestmentData, common.checklistTypes.close)} />
                                            : <Progress className='subProgressBar' completed={0} />
                                    }
                                </div>
                            </div>
                            <div className='onBoardStepSection'>
                                <div className='onBoardStepSectionLabels'>
                                    <div className="left-section">
                                        {/* Onboarding Step Progress */}
                                        {
                                            this.props.activeInvestmentData
                                                && this.props.activeInvestmentData.stage === "exited"
                                                ? <a
                                                    href={() => false}
                                                    onClick={() => this.props.history.push(`/onboardChecklist?companyId=${this.props.id}&investmentId=${this.props.investmentId}&type=${common.checklistTypes.exit}`)}>
                                                    Exit Investment Checklist
                                                </a>
                                                : <span style={{ color: '#808080' }}>Exit Investment Checklist</span>
                                        }
                                    </div>
                                    <div className="right-section">
                                        {
                                            this.props.activeInvestmentData
                                                && this.props.activeInvestmentData.stage === "exited"
                                                ? `${this.getOnBoardChecklistStatus(this.props.activeInvestmentData, common.checklistTypes.exit)}% Completed`
                                                : `${0}% Completed`
                                        }
                                    </div>
                                </div>
                                <div className='progressBar'>
                                    {
                                        this.props.activeInvestmentData
                                            && this.props.activeInvestmentData.stage === "exited"
                                            ? <Progress className='subProgressBar' completed={this.getOnBoardChecklistProgress(this.props.activeInvestmentData, common.checklistTypes.exit)} />
                                            : <Progress className='subProgressBar' completed={0} />
                                    }
                                </div>
                            </div>

                        </div>
                    )
                        : (
                            <div className='progressBarSection custom-progressbar'>
                                <div className="createInvestment">
                                    <TButton onClick={() => this.props.onAddInvestmentClick() && this.props.onAddInvestmentClick()} text={`CREATE INVESTMENT`} className='contact-btn' />
                                </div>
                            </div>
                        )
                }
                {/*---Redirection To Pages---*/}
                {/* <div className="redirections">
                    <ul>
                        <li className="redirect" onClick={() => this.props.history.push(`/onboardChecklist?companyId=${this.props.id}`)}>Investment Checklist</li>
                        <li className="redirect" onClick={() => this.props.history.push(`/executiveLetter?companyId=${this.props.id}`)}>Executive Letter</li>
                        <li className="redirect" onClick={() => this.props.history.push(`/iir/company?companyId=${this.props.id}`)} >IIR </li>
                    </ul>
                </div> */}

                {/* ---File Listing Section START--- */}
                <div className="card-header-txt" style={{ display: 'inline-block' }}>Strategic Contacts</div>
                <div className="card-header-txt" style={{ display: 'inline-block', float: 'right', }}>
                    <TButton onClick={() => this.props.onImportContactsClick() && this.props.onImportContactsClick()} text={`UPLOAD`} className='contact-btn' disabled={(this.props.investmentId) ? false : true} />
                </div>
                <div className="file-listing">
                    {!_.isEmpty(this.props.fileList) ?
                        (
                            // <div className="links-block">
                            //     {this.props.fileList.map((doc) => 
                            //         <a className="file-link" href={`${common.path.base_url}/getFile?fileId=${doc._id}`} download>{doc.documentName}</a>)}
                            // </div>
                            <div className="upload-contact-list">
                                {this.renderUploadedCards()}
                            </div>
                        ) :
                        (<div className="links-block no-list">
                            <div className="txt">No Files to Show</div>
                        </div>)}

                </div>
                {/* ---File Listing Section END--- */}

            </div>
        );
    }

}

export default CompanyCard;
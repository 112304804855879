import common from '../../common';
import apiService from '../shared/services/api.service';


/* global window */

export default class AuthService {
  
  static getUserById(_id) {
    return new Promise((resolve) => {
      resolve(apiService.get({
        endpoint: `${common.path.base_url}/getUserById?_id=${_id}`,
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }

  static jwtAuthorizationHeader() {
    return 'JWT '.concat(window.localStorage.getItem('token'));
  }
}

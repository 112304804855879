import React from "react";
import "../../components/MyInvestments/MyInvestments.scss";
import "../../components/NotParticipating/NotParticipating.scss";
import { path } from "../../imagesPath";
import { viewDocument } from "../helper";

const InvestorDashboardDocuments = (props) => {
  const { invitation, fromToDoPending } = props;
  if (fromToDoPending) {
    invitation.investmentId = invitation._id;
  }
  function webinarPopup(data) {
    window.location.href =
      "/webinar-request?investor=" +
      data.userId +
      "&investment=" +
      data.investmentId;
  }

  const isWebinar = (invitation) => {
    return (invitation.webinarLink1 && invitation.webinarLink1.link !== null &&
      invitation.webinarLink1.link !== "") || (invitation.webinarLink2 && invitation.webinarLink2.link !== null &&
        invitation.webinarLink2.link !== "") || (invitation.webinarInvite && invitation.webinarInvite.join1 != null && invitation.webinarInvite.join1 !== "") || (invitation.webinarRecording && invitation.webinarRecording != null && invitation.webinarRecording !== "")
  }

  return (
    <div className="row card-footer-doc d-flex justify-start cursor">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xxl-12" style={{ paddingLeft: 0 }}>
        <span
          title="IIR"
          onClick={() => viewDocument(invitation, "IIR")}
          className="view-span-icon-iir"
        >
          <img
            src={path.report}
            alt=""
            className="report"
            style={{ width: "20px" }}
          />
        </span>
        <span
          title="Executive Letter"
          onClick={() => viewDocument(invitation, "executiveLetter")}
          className="view-span-icon-ex"
        >
          <img
            src={path.latter}
            alt=""
            className="latter"
            style={{ width: "20px" }}
          />
        </span>
        <span
          title="FAQs"
          onClick={() => viewDocument(invitation, "FAQ")}
          className="view-span-icon-faq"
        >
          <img
            src={path.question}
            alt=""
            className="question"
            style={{ width: "20px" }}
          />
        </span>

        {isWebinar(invitation) && (
          <span
            title="WEBINAR"
            onClick={() => webinarPopup(invitation)}
            style={{ marginLeft: '10px' }}
          >
            <img
              src={path.white_webinar_link}
              alt=""
              className="webinar"
              style={{ width: "40px" }}
            />
          </span>
        )}
      </div>
    </div>
  )
};

export default InvestorDashboardDocuments;

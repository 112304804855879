import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import './PipelineStartupsList.scss';
import defaultProIcon from '../../images/profile-icon.png';
import activeListIcon from '../../images/active-list-icon.svg';
import activeListIconDisable from '../../images/active-list-icon-disable.svg';
import recommendationIcon from '../../images/recommendation.svg';
import searchIcon from '../../images/search-icon.svg';
import rightArrow from '../../images/right-arrow.png';
import TButton from '../../components/TButton';
import PipelineStartupsService from '../services/PipelineStartups.service';
import { setPipelineStartupsData } from '../actions/pipelineStartups'
import { setRecommendationData } from '../actions/recommendationList'
import common from '../../common';
import images from '../../images';
import search from '../../images/icons/search.svg';
import { path } from '../../InvestorDashboard/imagesPath';

const PipelineStartupsList = props => {
    
    const [getFilterType, setFilterType] = useState();
    const [getShowType, setShowType] = useState();
    const [getSearchType, setSearchType] = useState();

    window.addEventListener('click', (event) => {
        
        let dataList = props.pipelineStartups;
        dataList.map((data) => {
            if(data.showMenu) {
                data.showMenu = false;
                
                let setMyProps = { result: []};
                props.setPipelineStartups(setMyProps);
                setMyProps = { result: dataList, totalInvestors: props.totalInvestors };
                props.setPipelineStartups(setMyProps);
            }
        });
        
        
    });

    const searchPipelineStartups = _.debounce((search) => {
        
        setSearchType(search);
        let showPipe = getShowType;
        let showType = (showPipe != 'undefined') ? showPipe : null;
        let sFileType = getFilterType;
        let filterType = (sFileType != 'undefined') ? sFileType : null;
        
        PipelineStartupsService.getPipelineStartupsList(search, filterType, showType)
            .then(response => {
                if (response && response.data && response.data.data) {
                    //setMyProps(response.data.data);
                    props.setPipelineStartups(response.data.data);
                    //props.setPipelineStartups(myProps);
                }
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else { }
            });
    }, 500);

    const searchRecommendationStartups = _.debounce((search) => {
        PipelineStartupsService.getUserRecommendation(search)
            .then(response => {
                if (response && response.data && response.data.data) {
                    //setMyProps = response.data.data;
                    props.setRecommendationData(response.data.data);
                    //props.setPipelineStartups(response.data.data);
                }
            })
            .catch(() => {

            });
    }, 500);

    const selectPipelineStartups = (filterType) => {

        setFilterType(filterType);
        let searchType = getSearchType;
        let showPipe = getShowType;
        let showType = (showPipe != 'undefined') ? showPipe : null;
        let search= (searchType != 'undefined' && searchType != "") ? searchType : null;

        
        PipelineStartupsService.getPipelineStartupsList(search, filterType, showType)
            .then(response => {
                if (response && response.data && response.data.data) {
                    
                    props.setPipelineStartups(response.data.data);
                    //console.log(response.data.data);
                }
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else { }
            });
    };

    const showPipelineStartups = (showType) => {
        
        setShowType(showType);

        let sFileType = getFilterType;
        let searchType = getSearchType;
        let filterType = (sFileType != 'undefined') ? sFileType : null;
        let search= (searchType != 'undefined' && searchType != "") ? searchType : null;
        
        PipelineStartupsService.getPipelineStartupsList(search, filterType, showType)
            .then(response => {
                if (response && response.data && response.data.data) {
                    
                    props.setPipelineStartups(response.data.data);
                    console.log(response.data.data);
                }
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else { }
            });
    };

    const threeDotClick = (cardIndex) => {
        
        let menuStateOpen = false;
        let dataList = props.pipelineStartups;
        menuStateOpen = (dataList[cardIndex].showMenu) ? dataList[cardIndex].showMenu : false;
        console.log(menuStateOpen);
        if (menuStateOpen) {
            menuStateOpen = false;
        } else {
            dataList.map((data) => {
                data.showMenu = false;
                data.description = data.description +'-'; 
            });
            menuStateOpen = true;
        }
        dataList[cardIndex].showMenu = menuStateOpen;
        console.log(dataList);
        let setMyProps = { result: [], totalInvestors: 0 };
        props.setPipelineStartups(setMyProps);
        //setMyProps = { result: dataList, totalInvestors: 10 };
        setMyProps = { result: dataList, totalInvestors: props.totalInvestors };
        props.setPipelineStartups(setMyProps);
    };

    const redirect = (link) => {
        let url;
        if (link) {
            if (!link.includes('http')) {
                url = `http://${link}`;
            } else {
                url = link;
            }
            window.open(url, '_blank');
            return;
        }
    }

    const activeTime = ( minutes ) => {

        // var hours = ( minutes / 60 );
        // var days = ( hours / 24 );
        var diffMs = ( minutes * 1000 * 60 )
        var diffDays = Math.floor(diffMs / 86400000); // days
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
        
        return diffDays +" Days "+ diffHrs +" Hrs";
        //console.log(diffDays)
    }

    return (
        <>
            {
                <div className="pipeline-startup-section recommendations-list investor-sentiments-list">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                                <div className="title">
                                    Investment Pipeline
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                <div className="startup-btn tsg-btn">
                                    <TButton onClick={() => props.onAddPipelineStartupsClick()} text={`ADD PIPELINE STARTUP`} dataTarget={`#addCompanyModal`} dataToggle={`modal`} className='contact-btn' />
                                </div>
                            </div>
                        </div>
                        <div className="segment-statrup">
                            <div className="tab-content">
                                <div className="tab-pane active exit-in-process" id="pipelineStartups">
                                    <div className="search-main-box">
                                        <div className='search addposition'>
                                            <form className="form-group">
                                                <input className="form-control mr-sm-2"
                                                    onChange={(event) => searchPipelineStartups(event.target.value)}
                                                    type="search"
                                                    placeholder="Search"
                                                    aria-label="Search" />
                                                <img src={search} className="search-icon" />
                                            </form>
                                        </div>
                                        <div className="all">
                                            <div className="select-box">
                                                <div className='status'>
                                                    <select className='status-box' onChange={(event) => selectPipelineStartups(event.target.value)}>
                                                        <option value="all">All Startup</option>
                                                        <option value="pipeline">Pipeline Startup</option>
                                                        <option value="recommendation">Recommendation Startup</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="all">
                                            <div className="select-box">
                                                <div className='status'>
                                                    <select className='status-box' onChange={(event) => showPipelineStartups(event.target.value)}>
                                                        <option value="all">All</option>
                                                        <option selected value="visible">Visible</option>
                                                        <option value="hidden">Hidden</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="startup-box">
                                        <div className="row">
                                            {
                                                props.pipelineStartups.map( (card, i) => {

                                                    return (
                                                        <div className={ `col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12`} key={card._id}>
                                                            {/* onClick={() => props.history.push(`/investor-sentiments-details?id=${card._id}`)} */}
                                                            <div className={ `box-list ${card.isPipelineStartupShow==false ? 'color-change' : ''}`}>
                                                                <div className="profile-icons-main">
                                                                    <div className="profile-icon all-startup-select">
                                                                        {
                                                                            card.isRecommendation && card.isRecommendation ?
                                                                                <img src={recommendationIcon} alt='' /> :
                                                                                <img src={defaultProIcon} alt='' />
                                                                        }
                                                                        <div className="title-url-select">
                                                                            <span>{card.companyNickName === undefined || card.companyNickName === "" || card.companyNickName === null ? card.companyName : card.companyNickName}</span>
                                                                            {card.website ?
                                                                                <div className="url">
                                                                                    <a onClick={(event) => {
                                                                                        event.stopPropagation()
                                                                                        redirect(card.website)
                                                                                    }}>{card.website}</a>
                                                                                </div> : <div className="url"><span>&nbsp;</span></div>
                                                                            }
                                                                            { ( ( card.activeDuration / 60 ) >= 1 ) &&
                                                                                <div className='active_cart'>
                                                                                    Active for: { activeTime(card.activeDuration) }
                                                                                </div>
                                                                            }
                                                                            {/* <div className="all-startup-select-box">
                                                                                        <div className='all-startup-status'>
                                                                                            <select className='all-startup-status-box'
                                                                                            // onChange={(event) => this.props.getExitInProcessUsers(false, this.mapTags(), this.state.$sort, 'all', event.target.value)}
                                                                                            >
                                                                                                <option value="all">All Startup</option>                                                                                        <option value="all">All Startup</option>
                                                                                                <option value="all">All Recommendation</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div> */}

                                                                        </div>
                                                                    </div>
                                                                    <div className="switch-label">
                                                                        <div className="toggle-btn1" onClick={(event) => event.stopPropagation()}>
                                                                            <label className="switch">
                                                                                <input type="checkbox"
                                                                                    onClick={(event) => event.stopPropagation()}
                                                                                    defaultChecked={card.isPipelineStartupActive}
                                                                                    onChange={(event) => {
                                                                                        event.stopPropagation()
                                                                                        props.updatePipelineStartupStatusToggle(card._id, event.target.checked, 'Pipeline')
                                                                                    }}
                                                                                />
                                                                                <span className="slider round"></span>
                                                                            </label>
                                                                            {/* <div>
                                                                                {card.isProspectStartup ?
                                                                                    <div className="tooltipcover-startup">
                                                                                        <span className="tooltiptext">Move to Active Startup List</span>
                                                                                        <img src={activeListIcon} alt="move" className="delete-startup" onClick={(event) => {
                                                                                            event.stopPropagation()
                                                                                            props.moveToActiveStartupList(card._id)
                                                                                        }} />
                                                                                    </div>
                                                                                    : ""
                                                                                    // <img src={activeListIconDisable} alt="move" className="delete-startup" />
                                                                                }
                                                                            </div> */}
                                                                            {/* <div className="edit-delete-box">
                                                                                <img src={path.edit_new} onClick={() => { props.onEditCompanyClick(card) }} className="delete-startup" />

                                                                                { card.investments.length <= 0 ? 
                                                                                    
                                                                                    <img src={images.path.deleteIcon} onClick={() => { props.onDeleteStartupClick(card) }} className="delete-startup" />

                                                                                : ""
                                                                                }

                                                                                
                                                                            </div>   */}

                                                                            <div className={`card-menu ${card.showMenu ? 'show-menu' : ''}`}>
                                                                                <div className='three-dots-main'>
                                                                                    <a href="javascript:void(0);"  id={`three-dots${i}`} onClick={ (event) => { 
                                                                                        event.stopPropagation()
                                                                                        threeDotClick(i) }} >
                                                                                        <img src={images.path.kebabMenuIcon} className="menu-icon"></img>
                                                                                        <ul className={`dropdown-menu2`}>
                                                                                            <li className="resend" onClick={() => { props.onEditCompanyClick(card) }} >Edit</li>
                                                                                            
                                                                                            { card.isProspectStartup ?
                                                                                                <li className="resend" onClick={(event) => {
                                                                                                    event.stopPropagation()
                                                                                                    props.moveToActiveStartupList(card._id)
                                                                                                }} >Move to Active Startup List</li>
                                                                                              :""  
                                                                                            }
                                                                                            
                                                                                            { card.investments.length <= 0 ? 
                                                                                            <li className="resend" onClick={() => { props.onDeleteStartupClick(card) }} >Delete</li>
                                                                                            :""
                                                                                            }
                                                                                            
                                                                                            {/* { (card.isPipelineStartupShow !='undefined' && card.isPipelineStartupShow !="") ? */}
                                                                                                <>
                                                                                                {
                                                                                                (card.isPipelineStartupShow==true) ? 
                                                                                                <li className="resend" onClick={() => { props.onHideStartupClick(card, 'hide') }} >Hide </li>
                                                                                                : 
                                                                                                (card.isPipelineStartupShow==false) ? 
                                                                                                <li className="resend" onClick={() => { props.onHideStartupClick(card, 'visible') }} >Visible </li>
                                                                                                : 
                                                                                                <li className="resend" onClick={() => { props.onHideStartupClick(card, 'hide') }} >Hide </li>
                                                                                                }
                                                                                                </>
                                                                                            
                                                                                        </ul>
                                                                                    </a>

                                                                                    {/* <li className="resend" onClick={() => { props.onEditCompanyClick(card) }} >Edit</li> */}
                                                                                </div>
                                                                            </div>    
                                                                        </div>
                                                                        {/* <div onClick={() => props.onAddInvestmentClick() && props.onAddInvestmentClick()}>
                                                                            <label>Add Investment</label>
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                                <div className="list-lable">
                                                                    <h2>Strong Sentiments</h2>
                                                                    {
                                                                        card.strongSentiments.length > 0 ?
                                                                            <p>{parseFloat((card.strongSentiments.length * 100) / (props.totalInvestors)).toFixed(2)}%</p> :
                                                                            <p>0%</p>
                                                                    }
                                                                </div>
                                                                <div className="hr"></div>
                                                                <div className="list-lable" onClick={() => props.history.push(`/investor-sentiments-details?id=${card._id}`)}>
                                                                    <h2>Investor Responded</h2>
                                                                    <p className="respond">{card.pipelineStartupinterests.length}/{props.totalInvestors} <span><img src={rightArrow} alt="arrow" /></span></p>
                                                                </div>
                                                                <div className="hr"></div>
                                                                <div className="list-lable" onClick={() => props.history.push(`/recommendation-details?id=${card._id}`)}>
                                                                    {card.name ?
                                                                        <>
                                                                            <h2>Recommended By</h2>
                                                                            {
                                                                                card.userrecommendations > 1 ?
                                                                                    <p className="respond">{card.name} & {card.userrecommendations - 1} others <img src={rightArrow} alt="" /></p> :
                                                                                    <p className="respond">{card.name}<img src={rightArrow} alt="" /></p>

                                                                            }
                                                                        </>
                                                                        : <span>&nbsp;</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
const mapStateToProps = state => {
    return {
        pipelineStartups: state.pipelineStartups.data && state.pipelineStartups.data.result && state.pipelineStartups.data.result.length > 0 ? state.pipelineStartups.data.result : [],
        recommendationList: state.recommendationList.data && state.recommendationList.data.length > 0 ? state.recommendationList.data : [],
        totalInvestors: state.pipelineStartups.data.totalInvestors,

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setPipelineStartups: bindActionCreators(setPipelineStartupsData, dispatch),
        setRecommendationData: bindActionCreators(setRecommendationData, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PipelineStartupsList);
import React, { Component } from 'react';
import './HeaderImage.scss'

class HeaderImage extends Component {
  
    render() {
        return (
           <div className={`headerImg ${this.props.showImage}`}>
               <div className="backgroundImg">
                <div className='header-heading'>
                 {this.props.heading}
                 </div>
               </div>
           </div>
        );
    }
}

export default HeaderImage;
import React, { Component } from 'react';
import _ from 'lodash';
import '../../InvestmentDetail/InvestmentDetail.scss';
import axios from 'axios';
import TButton from '../../../components/TButton';
import common from '../../../common';
import HeaderImage from '../../../components/HeaderImage';
import Header from '../../../components/Header';
import swal from 'sweetalert';
import downarrow from '../../../images/icons/downarrow.svg';
import search from '../../../images/icons/search.svg';
import { path } from '../../../InvestorDashboard/imagesPath';
import AddNewManagementFeesandCarryModal from './AddNewManagementFeesandCarryModal';

class ManagementFeesandCarry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            managementFeesandCarryUsers: [],
            pagination: {
                currentPage: 0,
                totalPages: 1,
            },
            searchInputTags: [],
            suggestions: [
                { id: 'YPO', name: 'YPO' },
                { id: 'Institutional', name: 'Institutional' },
                { id: 'Strategic', name: 'Strategic' },
                { id: 'Preferred', name: 'Preferred' },
                { id: 'Investment(s)', name: 'Investment(s)' }],
            arrowType: {
                'name': 'menu-icon',
                email: 'menu-icon',
                managementfees: 'menu-icon',
                carry: 'menu-icon',
                shareprice: 'menu-icon'
            },
            $sort: null,
            investmentcompanyname: '',
            Role: '',
            userName: '',
        }
    }
    componentWillMount() {
        this.setState({ userName: window.localStorage.getItem('username'), })
        this.setState({ Role: window.localStorage.getItem('role') });
        this.setState({ investmentcompanyname: window.localStorage.getItem('investmentCompanyName') });
        const url = new URL(window.location.href);
        this.InvestmentId = url.searchParams.get('InvestmentID');

    }
    getManagementFeesandCarryData() {
        let roleCode = window.localStorage.getItem('roleCode');
        if (roleCode === '003' || roleCode === '004' || roleCode === '005') {
            window.location.href = '/';
            return;
        }
        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loading: true });
        const data = {
            investmentId: this.InvestmentId,
            search: this.state.searchInputTags.name,
        };
        axios.post(`${common.path.base_url}/getAddedManagementFeeAndCarryUsers`, data, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false, managementFeesandCarryUsers: response.data.data, pagination: { currentPage: response.data.currentPage, totalPages: response.data.totalPages }, searchInputTags: [] });
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: "Something went wrong",
                        icon: "error",
                        button: "Ok!",
                    });
                }
                window.location.href = '/';
            });
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, false);
        this.getManagementFeesandCarryData();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
    }

    handleScroll = () => {
        if ((Math.round(window.innerHeight + document.documentElement.scrollTop) === document.documentElement.offsetHeight)
            && (this.state.pagination.currentPage < this.state.pagination.totalPages)) {

        }
    };

    searchManagementFeesandCarryUsers = _.debounce((key) => {
        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loading: true });
        const data = {
            investmentId: this.InvestmentId,
            search: key,
        };
        axios.post(`${common.path.base_url}/getAddedManagementFeeAndCarryUsers`, data, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false, managementFeesandCarryUsers: response.data.data, pagination: { currentPage: response.data.currentPage, totalPages: response.data.totalPages }, searchInputTags: [] });
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: "Something went wrong",
                        icon: "error",
                        button: "Ok!",
                    });
                }
                window.location.href = '/';
            });

    }, 800);

    toggleArrow(fieldType) {
        let arrowType = this.state.arrowType;
        let $sort = {};
        if (arrowType[fieldType] === 'menu-icon') {
            arrowType[fieldType] = 'up-arrow';
            $sort[fieldType] = -1;
        } else {
            arrowType[fieldType] = 'menu-icon';
            $sort[fieldType] = 1;
        }
        this.setState({ arrowType, $sort });
    }

    resetValue(id) {
        swal({
            title: "Are you sure?",
            text: "Once reset, you will not be able to recover this value",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                const token = localStorage.getItem('token');
                if (willDelete) {
                    //@TODO CALL DELETE CONTACT API HERE WITH NECESSARY CHECKS
                    this.setState({ loading: true })

                    axios.post(`${common.path.base_url}/resetManagementFeeAndCarry`,
                        { id }, {
                        headers: {
                            "Authorization": token
                        }
                    })
                        //@TODO CALL ON SUCCESS OF API
                        .then((response) => {
                            this.setState({ loading: false });
                            swal({
                                title: "Success!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            })
                                .then(() => {
                                    this.getManagementFeesandCarryData();
                                });
                        })
                        .catch((error) => {
                            this.setState({ loading: false });
                            if (common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else {
                                swal({
                                    title: "Error!",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });
                }
                else {
                    swal({ text: `file is safe!`, icon: 'info' });
                }
            });
    }
    render() {
        return (
            <div>
                <AddNewManagementFeesandCarryModal
                    onRef={(ref) => this.addNewManagementFeesandCarryModalChild = ref}
                />
                <Header
                    username={this.state.userName}
                    role={this.state.Role}
                />
                <HeaderImage
                    heading={this.state.Role}
                />
                <div className="wrapper">
                    <div className="management-main-content header-section  dashboad-header-section">
                        <div className="management-title-content">
                            <label className="header-title-txt">Management Fees and Carry</label>
                            <h4 className="header-txt">{this.state.investmentcompanyname}</h4>
                        </div>
                        <div className="management-note-button-content">
                            <h6>Note:</h6>&nbsp;<h5> These are investors who have different Management Fees, Carry or Price Per Class A Share from Global Settings.</h5>
                            <TButton text='ADD NEW' className='invite-btn investmentdetail-btn'
                                onClick={() => {
                                }}
                                dataTarget={`#addNewmanagementFeesandCarryModal`}
                                dataToggle={`modal`} />
                            <TButton text='BACK' className='invite-btn investmentdetail-btn' onClick={() => { this.props.history.push(`/investmentDetail?id=${this.InvestmentId}`) }} />
                        </div>
                    </div>
                </div>
                <div className="box-wrapper-main wrapper">
                    <div className='search addsearchposition'>
                        <form className="form-group">
                            <input className="form-control mr-sm-2 custominput"
                                onChange={(event) => this.searchManagementFeesandCarryUsers(event.target.value)}
                                type="search"
                                placeholder="Search"
                                aria-label="Search" />
                            <img src={search} className="search-icon" />
                        </form>
                    </div>
                    <div className="custom-table-view">
                        <table className="table table-custom">
                            <thead>
                                <tr>
                                    <th>NAME  <img src={downarrow} className={`menu-icon ${this.state.arrowType['name']}`} onClick={() => this.toggleArrow('name')} /></th>
                                    <th>EMAIL  <img src={downarrow} className={`menu-icon ${this.state.arrowType['email']}`} onClick={() => this.toggleArrow('email')} /></th>
                                    <th>MANAGEMENT FEES  <img src={downarrow} className={`menu-icon ${this.state.arrowType['managementfees']}`} onClick={() => this.toggleArrow('managementfees')} /></th>
                                    <th>CARRY  <img src={downarrow} className={`menu-icon ${this.state.arrowType['carry']}`} onClick={() => this.toggleArrow('carry')} /></th>
                                    {/* <th>PRICE PER CLASS A SHARE  <img src={downarrow} className={`menu-icon ${this.state.arrowType['shareprice']}`} onClick={() => this.toggleArrow('shareprice')} /></th> */}
                                    <th>ACTION  <img src={downarrow} className="menu-icon"></img></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.managementFeesandCarryUsers.length > 0 &&
                                    this.state.managementFeesandCarryUsers.map(user => {
                                        return (
                                            <tr key={user._id}>
                                                <td className="username-row" onClick={() => {

                                                    this.props.history.push(`/viewProfile?id=${user.userId}`)
                                                }}>
                                                    {user.name}
                                                </td>
                                                <td>
                                                    {user.emailAddress}
                                                </td>
                                                <td>
                                                    <div className='custom-row'>
                                                        <div className="two-part-main-content">
                                                            <div className="first-box">
                                                                <h4>{user.managementFee}</h4>
                                                            </div>
                                                            <div className="second-box">
                                                                <h5>%</h5>
                                                            </div>
                                                        </div>
                                                        {/* <img src={path.checkGreenBack} alr='' /> */}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='custom-row'>
                                                        <div className="two-part-main-content">
                                                            <div className="first-box">
                                                                <h4>{user.carry}</h4>
                                                            </div>
                                                            <div className="second-box">
                                                                <h5>%</h5>
                                                            </div>
                                                        </div>
                                                        {/* <img src={path.checkGreenBack} alr='' /> */}
                                                    </div>
                                                </td>
                                                <td>
                                                    <img src={path.reset} alr='' onClick={() => this.resetValue(user._id)} />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    this.state.managementFeesandCarryUsers.length === 0 &&
                                    <tr>
                                        <td>No investors found!</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        );
    }

}

export default ManagementFeesandCarry;
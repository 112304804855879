import React, { Component } from 'react';
import './AddAddressModal.scss';
import swal from 'sweetalert';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import _ from 'lodash';

class AddAddressModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address1: '',
            address1: '',
            city: '',
            state: '',
            zip: '',
            address1Validation:'',
            address2Validation:'',
            cityValidation:'',
            stateValidation:'',
            zipValidation:'',
            loadinglada: false,
            loadinglada2: false,

        }
    }

    handleChange(id, newValue) {
        if (id === "address1")
            this.setState({ address1: newValue });
        else if (id === 'address2')
            this.setState({ address2: newValue });
        else if (id === 'city')
            this.setState({ city: newValue });
        else if (id === 'state')
            this.setState({ state: newValue });
        else if (id === "zip") {
            this.setState({ zip: newValue });
        }
        console.log("39");
        
    }
    formValidaton(id) {
        if (id === 'address1') {
            let address1 = document.getElementById('address1').value;
            if (address1 === '') {
                this.setState({
                    address1Validation: "Address1 should not be empty*"
                })
            } else {
                this.setState({
                    address1Validation: ""
                })
            }
        }
        else if (id === 'address2') {
            let address2 = document.getElementById('address2').value;
            if (address2 === '') {
                this.setState({
                    address2Validation: "Address2 should not be empty*"
                })
            } else {
                this.setState({
                    address2Validation: ""
                })
            }
        }
        else if (id === 'city') {
            let city = document.getElementById('city').value;
            if (city=== "") {
                this.setState({
                    cityValidation: "City should not be empty*"
                })
            } else {
                this.setState({
                    cityValidation: ""
                })
            }
        }
        else if (id === 'state') {
            let state = document.getElementById('state').value;
            if (state=== "") {
                this.setState({
                    stateValidation: "State should not be empty*"
                })
            } else {
                this.setState({
                    stateValidation: ""
                })
            }
        } 
        else if (id === 'zip') {
            let zip = document.getElementById('zip').value;
            if (zip=== "") {
                this.setState({
                    zipValidation: "Zip should not be empty*"
                })
            } else {
                this.setState({
                    zipValidation: ""
                })
            }
        }
    }
    resetAddAddressData() {
        this.setState({
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            loadinglada2: false,
        });
    }

    render() {
        return (
            <div className='addAddress'>
                {/* <!-- Add Company Modal HTML --> */}
                <div id="addAddressModal" className="modal fade">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form>
                                <div className="modal-header">
                                    <h4 className="modal-title">Add Address</h4>
                                </div>
                                <div className="modal-body">
                                    <form className="form-horizontal">
                                        <div className="form-group">
                                            <label className="col-md-3 control-label">Address 1:</label>
                                            <div className="col-md-8">
                                                <input className="form-control" type="text" placeholder='' id="address1"
                                                    onKeyUp={(event) => this.formValidaton(event.target.id)}
                                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                    value={this.state.address1}
                                                />
                                                <p>{this.state.address1Validation}</p>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-md-3 control-label">Address 2:</label>
                                            <div className="col-md-8">
                                                <input className="form-control" type="text" placeholder='' id="address2"
                                                    onKeyUp={(event) => this.formValidaton(event.target.id)}
                                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                    value={this.state.address2} />
                                                <p>{this.state.address2Validation}</p>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-md-3 control-label">City:</label>
                                            <div className="col-md-8">
                                                <input className="form-control" type="text" placeholder='' id="city"
                                                    onKeyUp={(event) => this.formValidaton(event.target.id)}
                                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                    value={this.state.city} />
                                                <p>{this.state.cityValidation}</p>
                                            </div>

                                        </div>
                                        <div className="form-group">
                                            <label className="col-md-3 control-label">State:</label>
                                            <div className="col-md-8">
                                                <input className="form-control" type='text' placeholder='' id="state"
                                                    onKeyUp={(event) => this.formValidaton(event.target.id)}
                                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                    value={this.state.state} />
                                                <p>{this.state.stateValidation}</p>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-md-3 control-label">Zip:</label>
                                            <div className="col-md-8">
                                                <input className="form-control" type='text' placeholder='' id="zip"
                                                    onKeyUp={(event) => this.formValidaton(event.target.id)}
                                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                    value={this.state.zip} />
                                                <p>{this.state.zipValidation}</p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <LaddaButton
                                        id='lada-btn'
                                        loading={this.state.loadinglada2}
                                        data-color="#eee"
                                        data-size={XS}
                                        className={!_.isEmpty(this.state.address1 && this.state.address2 && this.state.city && this.state.state && this.state.zip) ? '' : 'disable'}
                                        data-style={EXPAND_RIGHT}
                                        data-spinner-size={30}
                                        data-spinner-color="#ddd"
                                        data-spinner-lines={12}
                                    >ADD</LaddaButton>
                                    <input type="button" id='company-cancel' className="btn btn-default" data-dismiss="modal" value="CANCEL" onClick={() => this.resetAddAddressData()} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default AddAddressModal;
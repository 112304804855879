import axios from "axios";
import moment from 'moment';
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import NumberFormat from "react-number-format";
import swal from "sweetalert";
import common from "../../../common";
import "./TransactionHistory.scss";


const redirect = () => {
    window.location.href = `/investor-dashboard`;
}
const redirectToWallet = () => {
    window.location.href = `/investorWallet`;
}

class TransactionHistory extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            userTransactionData: null,
            walletBalanceData: null,
            verivendId: null,
            exportLoading: false,
            exportTransactionData: []
        };
        this.csvLink = React.createRef();
    }

    componentDidMount() {
        this.userId = window.localStorage.getItem("id");
        this.getUserTransactionHistory();
        this.getUserById()
    }

    async getUserTransactionHistory() {
        let temp = this;
        let tokenpass = window.localStorage.getItem("token");

        temp.setState({ loading: true });
        axios
            .get(`${common.path.base_url}/getUserTransactionHistory/${this.userId}`, {
                headers: {
                    Authorization: tokenpass,
                },
            })
            .then((response) => {
                temp.setState({ loading: false });
                temp.setState({ userTransactionData: response.data.data });
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message &&
                    common.AUTH_EXPIRED === error.response.data.message
                ) {
                    window.localStorage.clear();
                    window.location.href = "/";
                    return;
                } else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                } else {
                    swal({
                        title: "Network Error!",
                        text:
                            error.response &&
                                error.response.data &&
                                error.response.data.message
                                ? error.response.data.message
                                : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    async getUserById() {
        let temp = this;
        temp.setState({ loading: true });
        let token = window.localStorage.getItem("token");
        axios
            .get(`${common.path.base_url}/getUserById?_id=${this.userId}`, {
                headers: {
                    Authorization: token,
                },
            })
            .then((response) => {
                temp.setState({ loading: false });
                if (response.data.data && response.data.data.verivendId) {
                    temp.setState({ verivendId: response.data.data.verivendId });
                    this.getWalletBalance(response.data.data.verivendId);
                }
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message &&
                    common.AUTH_EXPIRED === error.response.data.message
                ) {
                    window.localStorage.clear();
                    window.location.href = "/";
                    return;
                } else if (error.response) {
                    swal({
                        title: "Error! #451220",
                        text:
                            error.response &&
                                error.response.data &&
                                error.response.data.message
                                ? error.response.data.message
                                : "Something Went Wrong",
                        icon: "error",
                        button: "Ok!",
                    });
                } else {
                    swal({
                        title: "Network Error!",
                        text:
                            error.response &&
                                error.response.data &&
                                error.response.data.message
                                ? error.response.data.message
                                : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    async getWalletBalance(verivendId) {
        let temp = this;
        const token = window.localStorage.getItem("token");
        temp.setState({ loding: true });
        axios
            .get(`${common.path.base_url}/getWalletBalance/${verivendId}`, {
                headers: {
                    Authorization: token,
                },
            })
            .then((response) => {
                temp.setState({ loding: false });
                if (response.data && response.data.data) {
                    temp.setState({ walletBalanceData: response.data.data });
                }
            })
            .catch((error) => {
                temp.setState({ loding: false });
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message &&
                    common.AUTH_EXPIRED === error.response.data.message
                ) {
                    window.localStorage.clear();
                    window.location.href = "/";
                    return;
                } else {
                    swal({
                        title: "Error!",
                        text:
                            error.response &&
                                error.response.data &&
                                error.response.data.message
                                ? error.response.data.message
                                : "Something Went Wrong",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    transactionHistoryExportCSV() {
        this.setState({ exportTransactionData: [], exportLoading: true });
        if (this.state.userTransactionData && this.state.userTransactionData.length > 0) {
            let arr = [];

            var userExData = new Promise((resolve, reject) => {
                this.state.userTransactionData.forEach(val => {
                    let ObjCode = {
                        'From': (val.from) ? val.from : "",
                        'To': (val.to) ? val.to : "",
                        'Date': (val.date) ? this.mapDateFormat(val.date) : "",
                        'Amount': (val.totalAmount) ? val.totalAmount : "",
                        'Status': (val.status) ? val.status : ""
                    };
                    arr.push(ObjCode);
                });
                resolve();
            });
            userExData.then(() => {
                this.setState({ exportTransactionData: arr, exportLoading: false });
                this.csvLink.current.link.click();
            });
        } else {
            swal({
                title: "Export",
                text: "Data not found.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            });
            this.setState({ exportTransactionData: [], exportLoading: false });
        }
    }

    mapDateFormat(mongoDate) {
        const date = moment(mongoDate);
        return date.format('MMMM DD, YYYY');
    }

    render() {
        return (
            <>
                <div className="container">
                    <div className="back-dashboard">
                        <button className="btn btn-danger" onClick={() => redirect()}>
                            Back to Dashboard
                        </button>

                        <button className="btn btn-danger" onClick={() => redirectToWallet()}>
                            Wallet
                        </button>
                    </div>

                    <div className="card my-custom-card">
                        <div style={{ padding: "0px 10px 7px", marginBottom: 20, width: "100%", borderBottom: "1px solid grey", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <h3>Transaction History</h3>

                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <h4>Wallet Balance: </h4>
                                &nbsp;
                                <NumberFormat
                                    value={this.state.walletBalanceData?.currentBalance || 0}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    displayType={"text"}
                                />
                            </div>

                            <div>
                                <a onClick={() => this.transactionHistoryExportCSV()} className="tsg-btn invite export-btn" >
                                    {
                                        this.state.exportLoading ?
                                            <><i className="fa fa-spinner fa-spin ex-icon" aria-hidden="true" />Loading </>
                                            :
                                            <><i className="fa fa-file-text ex-icon" aria-hidden="true" />&nbsp;EXPORT</>
                                    }
                                </a>
                                <CSVLink
                                    data={this.state.exportTransactionData}
                                    filename={"Transaction History.csv"}
                                    className="hidden"
                                    ref={this.csvLink}
                                    target="_blank"
                                />
                            </div>
                        </div>

                        {this.state.userTransactionData &&
                            this.state.userTransactionData.length > 0 ? (
                            <div className="custom-table-view table-responsive">
                                <table className="table table-custom">
                                    <thead>
                                        <tr>
                                            <th>From</th>
                                            <th>To</th>
                                            <th>Date</th>
                                            <th style={{ textAlign: "right" }}>Amount</th>
                                            <th style={{ textAlign: "right" }}><span style={{ marginRight: "20px" }}>Status</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.userTransactionData.map((transaction) => {
                                            return (
                                                <tr key={transaction.transactionId}>
                                                    <td className="black-text">{transaction.from}</td>
                                                    <td className="black-text">{transaction.to}</td>
                                                    <td className="black-text">
                                                        {transaction.date ? this.mapDateFormat(transaction.date) : "-"}
                                                    </td>
                                                    <td className={transaction.from.includes("Disbursement") ? "black-text" : "transactionColor"} style={{ textAlign: "right" }}>
                                                        {transaction.from.includes("Disbursement") ? "" : <span>-</span>}{
                                                            <NumberFormat
                                                                value={transaction.totalAmount}
                                                                thousandSeparator={true}
                                                                prefix={"$"}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                displayType={"text"}
                                                            />
                                                        }
                                                    </td>
                                                    <td style={{ textAlign: "right" }}
                                                        className={
                                                            common.transactionStatus.failed ===
                                                                transaction.status
                                                                ? "transactionColor"
                                                                : common.transactionStatus.success ===
                                                                    transaction.status
                                                                    ? "successTransactionColor"
                                                                    : ""
                                                        }
                                                    >{transaction.status}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div>
                                <span style={{ fontSize: "15px", padding: "30px" }}>
                                    No Transactions Found.
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default TransactionHistory;
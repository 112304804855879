import React from 'react';
import PropTypes from 'prop-types';

const AddSubChecklistModal = (props) => {
  return (
    <div className="modal fade" id="addSubChecklistModal" tabIndex="-1" role="dialog" aria-labelledby="addSubChecklistModal" aria-hidden="true" key={props.addSubChecklistKey}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">Add Sub Checklist</h5>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <input className="form-control" type="text" name="remember" placeholder="Option Name" onChange={props.onSubChecklistTitleChange} />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn cancel-btn" data-dismiss="modal">Cancel</button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={props.onSubChecklistFormSubmit}
              disabled={props.isSubChecklistFormValid()}
              data-dismiss="modal"
            >
              Save
              </button>
          </div>
        </div>
      </div>
    </div>
  );
};

AddSubChecklistModal.propTypes = {
  onSubChecklistTitleChange: PropTypes.func.isRequired,
  onSubChecklistFormSubmit: PropTypes.func.isRequired,
  isSubChecklistFormValid: PropTypes.func.isRequired,
};

export default AddSubChecklistModal;
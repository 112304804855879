
import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Accordion } from 'react-bootstrap';
import axios from 'axios';
import './Container.scss';
import common from '../../common';
import swal from 'sweetalert';
import DashboardStart from '../components/DashboardStart/Start'
import LoadingSpinner from '../../components/Loader';
import StartupService from '../services/startup.service'
import { isLoading, isNotLoading } from '../../shared/actions/loadingSpinner';
import AddStartupContactModal from '../../containers/Dashboard/AddStartupContact';
import TButton from '../../components/TButton';
import FileUploadBlobDoc from '../../components/FileUploadBlobDoc';
import Documents from '../../components/Documents';
import images from "../../images";
import ContactCard from '../../components/ContactCard';
import UploadedFilesCard from '../../components/UploadedFilesCard';
import _ from 'lodash';
let userRoleCode = '000';


class MainContainer extends Component {
    constructor() {
        super()
        this.adminAccess = false;
        this.startupId = null;
        this.userRoleCode = '003';
        this.state = {
            name: '',
            data: [],
            Role: '',
            loading: false,
            choosenFile: null,
            loadinglada: false,
            dashboardData: [],
            profileData: {},
            startupContacts: [],
            startupContactsUI: [],
            userId: null,
            companyUploadedFiles: [],
            count: 0,
            isDocumentLoaded: false,
            executiveProgress: false,
            investorInvestments: [],
            investorCompanies: [],
            investorDocuments: [],
            investorInvestmentsForFilter: [],
            selectedstage: '',
            stages: [
                { value: 'all', label: 'All' },
                { value: 'invite', label: 'Pipeline' },
                { value: 'open', label: 'Open' },
                { value: 'closed', label: 'Closed' },
                { value: 'exited', label: 'Exited' }
            ],
            investmentOrder: ['pipeline', 'open', 'closed', 'exited'],
            currentStage: { value: 'all', label: 'All' },
            investorDashboardId: undefined,
            showStartupCards: true,
            showInvestmentsCards: false,
            investmentsData: [],
            activeInvestmentId: null,
            startupCount: 0,
            startupExecutiveProgress: false,
            startUpInvestments: [],
            selectedInvestment: null,
            selectedInvestmentDocuments: null,
            investmentForStartup: [],
            isAddTeamMemberDone: false,
            isUploadDone: false,
            showProgressBar: true,
            isDocumentCheck: false,
            currentInvestorName: null,
            companyData: [],
            invitedInvestments: [],
            filterInvitedInvestments: [],
            investmentCompanies: [],
            currentInvestorTitle: null,
            currentInvestorPhone: null,
            successImportData: null,
            masterChecklist: [],
            startupCompany: null,
            user: null,

        };
    }
    checkForActionIcon(user) {
        if (this.userRoleCode === '003') {
            return user._id === this.state.userId ? false : true;
        } else if (this.userRoleCode === '004') {
            if (user._id === this.state.userId) {
                return false;
            } else if (user.invitationFrom === this.state.userId) {
                return true;
            } else {
                return false;
            }
        }
    }

    getSateCount() {
        let stateCount = this.state.iirList && this.state.iirList.length > 0 ? this.state.iirList.filter(v => v.state).length : 0;
        this.setState({ startupCount: stateCount });
    }
    getExecutiveLetterStatus(arr) {
        const executiveStatus = arr && arr.length > 0 ? arr.filter(v => v.state).length : 0;
        this.setState({ startupExecutiveProgress: executiveStatus ? true : false });

    }

    getCompanyUploadedFiles(investmentId) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');

        const userRoleCode = this.userRoleCode;
        const companyId = this.state.startupCompany._id;
        const userId = this.state.user._id;
        let queryString = '';
        if (userRoleCode == '003') {
            queryString = `companyId=${companyId}&investmentId=${investmentId ? investmentId : this.state.selectedInvestment.value}`;
        } else {
            queryString = `companyId=${companyId}&userId=${userId}&investmentId=${investmentId ? investmentId : this.state.selectedInvestment.value}`;
        }
        axios.get(`${common.path.base_url}/getInvestmentFiles?${queryString}`, {
            headers: {
                "Authorization": tokenpass
            },
        })
            .then((response) => {
                const uploadedFiles = response.data.data;
                temp.setState({ loading: false });
                temp.setState({
                    companyUploadedFiles: uploadedFiles
                });
                this.loadDashboardData()
                // this.getIIRList(companyId);
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else {
                    swal({
                        title: "Error! #33001",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loading: false });
            });
    }
    getContactList() {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/getMasterList?role=${this.userRoleCode}&id=${this.startupId}`, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                const contacts = response.data.data;
                temp.setState({ loading: false, isDocumentLoaded: true });
                temp.setState({
                    startupContacts: contacts.startup[0].users,
                    startupContactsUI: contacts.startup[0].users
                });
                temp.getCompanyUploadedFiles();
            })
            .catch(function (error) {
                if (error && error.response && error.response.data && error.response.data.code === 204) {
                    // window.localStorage.clear();
                    // window.location.href = '/';
                }
                swal({
                    title: "Error!",
                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                    icon: "error",
                    button: "Ok!",
                });
                temp.setState({ loading: false });

            });
    }

    getIIRList(companyId) {
        this.setState({ loading: true });
        let temp = this;
        let token = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/investment/${companyId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((res) => {
                let investmentData = null;
                if (res.data.data && res.data.data.length > 0) {
                    const startUpInvestments = [];
                    res.data.data.forEach(investment => {
                        startUpInvestments.push({ value: investment._id, label: investment.name });
                    })
                    investmentData = this.getActiveStartupInvestmentData(res.data.data, this.state.activeInvestmentId);
                    if (investmentData) {
                        this.setState({
                            startUpInvestments,
                            selectedInvestment: { value: investmentData._id, label: investmentData.name },
                            selectedInvestmentDocuments: { value: investmentData._id, label: investmentData.name },
                        });
                    }
                }
                axios.get(`${common.path.base_url}/getInvestmentIIR/${investmentData._id}`, {
                    headers: {
                        "Authorization": token
                    }
                })
                    .then((response) => {
                        temp.setState({ loading: false });
                        const sampleArray = response.data.data;
                        if (sampleArray) {
                            temp.setState({ isApproved: sampleArray.isApproved });
                            const keys = Object.keys(sampleArray)
                            const values = Object.values(sampleArray)
                            let z = [];
                            let executiveLetter = [];
                            keys.forEach((key, i) => {
                                z.push({ title: key, state: values[i] })
                            })
                            z.forEach((ele, i) => {
                                let title = ele.title.split(" ");
                                let path = '';
                                for (const word of title) {
                                    path += word;
                                }
                                path = path.substring(0, 1).toLowerCase() + path.substring(1);
                                ele.path = path;
                                if (ele.path === 'founderLetter') {
                                    executiveLetter.push(ele);
                                }
                            })
                            const iirList = z.filter((iir) => iir.path !== 'founderLetter' && iir.path !== '_id');
                            // z.push({[this.props.id]:{...z}}); 
                            // z.push({id:this.props.id})
                            this.setState({ iirList })
                            this.getSateCount();
                            this.getExecutiveLetterStatus(executiveLetter)
                        }
                        this.getContactList();
                    })
                    .catch(function (error) {
                        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                            window.localStorage.clear();
                            window.location.href = '/';
                            return;
                        } else {
                            swal({
                                title: "IIR List Error!",
                                text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                icon: "error",
                                button: "Ok!",
                            });
                        }
                    });

            })
            .catch(function (error) {
                console.log(error);
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "IIR List Error",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });


    }

    componentDidMount() {

        if (this.props.location
            && this.props.match
            && this.props.match.params
            && this.props.match.params.id) {
            this.startupId = this.props.match.params.id;

        } else {

            this.props.history.replace('/');
        }
        if (this.startupId) {
            this.adminAccess = true;
            StartupService.getUserById(this.startupId)
                .then(res => {
                    if (res.data && res.data.data) {
                        console.log(45, res.data.data);
                        this.fullName = `${res.data.data.firstName} ${res.data.data.lastName}`;
                        this.setState({ startupCompany: res.data.data.companyId, user: res.data.data });
                        this.userRoleCode = res.data.data.role;
                        if (res.data.data.companyId.activeInvestmentId) {
                            this.setState({ activeInvestmentId: res.data.data.companyId.activeInvestmentId });
                        }
                        this.getIIRList(res.data.data.companyId._id);
                    }

                })
                .catch(error => {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                });
        } else {
            this.loadDashboardData();
        }
    }
    loadDashboardData = () => {
        // this.props.isLoading();
        this.props.isNotLoading();
    }
    getUserId = () => {
        if (this.investorId) {
            return this.investorId;
        } else {
            return window.localStorage.getItem('id');
        }
    }




    redirectToInvestmentProcess = investmentId => {
        // const { history } = this.props;
        // history.push(`/investment-process?investmentId=${investmentId}`)
        window.location.href = `/investment-process?investmentId=${investmentId}`
    }
    getActiveStartupInvestmentData(data, investmentId) {
        const investmentAllData = [...data];
        if (investmentId) {
            return investmentAllData.filter((investment) => investment._id === investmentId)[0];
        } else {
            if (investmentAllData && investmentAllData.length > 0) {
                const investmentData = investmentAllData.splice(investmentAllData.length - 1, 1)[0];
                return investmentData;
            }
        }
    }


    checkIfNoContacts() {
        let dataFound = true;
        dataFound = false;
        if (this.state.startupContactsUI.length > 0) {
            dataFound = true;
        }
        return dataFound;
    }
    renderStartupCards() {
        let cards = null;
        if (this.state.startupContactsUI && this.state.startupContactsUI.length > 0) {
            cards =
                <Accordion> {
                    this.state.startupContactsUI.map((user, i) => {
                        let isActionIcon = this.checkForActionIcon(user);
                        return (<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-list-contact contact-list-wraper">
                            <ContactCard
                                type={`1`}
                                imgSrc={`${common.path.base_url}/getImage?id=${user._id || ''}`}
                                contactData={user}
                                onCardClick={() => { }}
                                toggleShowMoreCard={(cardtype) => this.toggleShowMoreCard(cardtype, i)}
                                toggleShowCompanyMenuForDashboardCard
                                admin={user.role === '003' ? true : false}
                                // actionIcon={user._id === this.state.userId ? false : true}
                                actionIcon={isActionIcon}
                                showMenu={this.state.roleCode <= user.role ? true : false}
                                canResend={user.invitationAccepted != "1" ? true : false}
                            />
                        </div>);
                    })
                } </Accordion>
        }
        return cards;
    }

    renderUploadedCards() {
        let cards = null;
        if (this.state.companyUploadedFiles && this.state.companyUploadedFiles.length > 0) {
            cards = this.state.companyUploadedFiles.map((file, i) => {
                return (<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-list-contact">
                    <UploadedFilesCard
                        onCardClick={() => { }}
                        file={file}
                        onActionClick={() => this.removeFile(file)}
                        userRoleCode={this.userRoleCode}
                    />
                </div>);
            })
        }
        return cards;
    }
    getMatchArrObj(key, arr) {
        let result = [];
        let newArray = [];
        arr.forEach(user => {
            newArray.push({ name: user.name, emailAddress: user.emailAddress, title: user.title });
        });
        newArray.forEach((obj, index) => {
            let values = Object.values(obj);
            let found = false;
            values.forEach((ele) => {
                if (ele && ele.toString().includes(key)) {
                    found = true;
                }
            });
            if (found) { result.push(arr[index]) }
        });
        return result;
    }
    searchOnKeyChange = _.debounce((key) => {
        let filteredContacts = [];
        if (this.state.startupContacts.length > 0) {
            filteredContacts = this.getMatchArrObj(key, this.state.startupContacts);
        }
        this.setState({ startupContactsUI: filteredContacts })
    }, 500)

    getName() {
        if (this.state.startupCompany) {
            let companyName = this.state.startupCompany.companyNickname ? this.state.startupCompany.companyNickname : this.state.startupCompany.companyName;
            return `${companyName} - ${this.fullName}`;
        }
        return '';

    }
    onInvestmentChange(loading) {
        this.setState({ loading });
    }
    toggleShowMoreCard(popupType, cardIndex) {
        let showMoreStateOpen = false;
        let menuStateOpen = false;
        let contactList = this.state.startupContactsUI;

        if (popupType === 1) {
            showMoreStateOpen = contactList[cardIndex].showMore;
            if (showMoreStateOpen) {
                showMoreStateOpen = false;
            } else {
                contactList.map((contact) => {
                    contact.showMore = false;
                    contact.showMenu = false;
                });
                showMoreStateOpen = true;
            }
        } else if (popupType === 2) {
            menuStateOpen = contactList[cardIndex].showMenu;
            if (menuStateOpen) {
                menuStateOpen = false;
            } else {
                contactList.map((contact) => {
                    contact.showMore = false;
                    contact.showMenu = false;
                });
                menuStateOpen = true;
            }
        }
        contactList[cardIndex].showMore = showMoreStateOpen;
        contactList[cardIndex].showMenu = menuStateOpen;
        this.setState({ startupContactsUI: contactList });
    }
    removeFile(file) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this file",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                const token = localStorage.getItem('token');
                if (willDelete) {
                    //@TODO CALL DELETE CONTACT API HERE WITH NECESSARY CHECKS
                    this.setState({ loading: true })
                    axios.delete(`${common.path.base_url}/deleteFile`, { headers: { "Authorization": token }, data: { id: file._id } }
                    )
                        //@TODO CALL ON SUCCESS OF API
                        .then((response) => {
                            this.setState({ loading: false });
                            swal({
                                title: "Success!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            })
                                .then(() => {
                                    this.getCompanyUploadedFiles();
                                });

                        })
                        .catch((error) => {
                            this.setState({ loading: false });
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            } else {
                                swal({
                                    title: "Remove File Error!",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });
                }
                else {
                    swal({ text: `${file.documentName} file is safe!`, icon: 'info' });
                }
            });
    }


    render() {
        return (
            <>
                {
                    this.props.loadingSpinner ? <LoadingSpinner /> :
                        <>
                            <DashboardStart
                                adminAccess={this.adminAccess}
                                fullName={this.getName()}
                                startupId={this.startupId}
                                {...this.props}
                            />
                            <div>
                                <AddStartupContactModal
                                    selectedInvestment={this.state.selectedInvestment}
                                    getCompanyUploadedFiles={(investmentId) => this.getCompanyUploadedFiles(investmentId)}
                                />

                                <div className="startup-section wrapper fluid-container">

                                    {/* <ul id="progressbar" className={this.state.showProgressBar ? '' : 'closeProgressBar'}>
                                            <div className="cancel-btn-wraper">
                                                <button type="button" className="close" aria-label="Close" onClick={() => {
                                                    this.setState({ showProgressBar: false });
                                                    window.localStorage.removeItem('isUploadDone');
                                                    window.localStorage.removeItem('isDocumentCheck');
                                                    window.localStorage.removeItem('isAddTeamMemberDone');
                                                }
                                                }>
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <li className={this.state.isAddTeamMemberDone ? "active startup-progress" : 'startup-progress'} id="account" onClick={() => this.addTeamMember()}><strong>Create Team</strong></li>
                                            <li className={this.state.isUploadDone ? "active startup-progress" : 'startup-progress'} id="personal" onClick={() => this.uploadContact()}><strong>Upload Contacts</strong></li>
                                            <input
                                                id='directUpload'
                                                onChange={() => this.directUpload()}
                                                type="file"
                                                style={{ display: 'none' }}
                                            />
                                            <li className={this.state.isDocumentCheck ? "active" : ''} id="payment" onClick={() => { this.addDocuments() }}><strong> Collaborative Documents</strong></li>
                                        </ul> */}

                                    <div className="row">

                                        {/*------ Contact List Block START------*/}
                                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                            <div className="contact-list-section custom-list-team">
                                                <div className="header-txt invite-btn">
                                                    {this.state.startupCompany && (this.state.startupCompany.companyNickname || this.state.startupCompany.companyName)} Team
                                                    {/* <TButton id="add-team-btn" onClick={() => this.customModalChild.resetInviteData()} text={`ADD TEAM MEMBER`} dataTarget={`#inviteModal`} dataToggle={`modal`} className='invite-btn' /> */}
                                                </div>

                                                {/* Search Block */}
                                                <div className="search-block">
                                                    <div className="search-container">
                                                        <div className="search-section">
                                                            <input className="search-input" type="text" placeholder="Search" onChange={(e) => this.searchOnKeyChange(e.target.value)} ></input>
                                                            <img className="search-icon" src={images.path.searchIcon} />
                                                        </div>
                                                    </div>
                                                    {!this.checkIfNoContacts() ? <div className="no-result-found">
                                                        {`No Results to Show`}
                                                    </div> : null}
                                                    {/* Contact List Cards */}
                                                </div>
                                                <div className={`row contact-list-block custom-contact-list-section admin}`}>
                                                    {this.renderStartupCards()}
                                                </div>
                                            </div>
                                        </div>
                                        {/*------ Contact List Block END------*/}

                                        {/*------ Upload File Block START------*/}
                                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 user-upload-section">
                                            <div className="upload-section">
                                                <div className="header-txt">Upload Strategic Contacts</div>
                                                <div className="startupInvestment-upload">
                                                    {this.state.selectedInvestment && this.state.selectedInvestment.label}
                                                    {/* <Select
                                                                    id='startupInvestment'
                                                                    options={this.state.startUpInvestments}
                                                                    value={this.state.selectedInvestment}
                                                                    onChange={(event) => {
                                                                        this.setState({ selectedInvestment: event })
                                                                        this.getCompanyUploadedFiles(event.value)
                                                                    }
                                                                    }
                                                                /> */}
                                                </div>
                                                <div className="upload-block">
                                                    {/* <div className="choose-file" onClick={() => document.querySelector('#chooseFile').click()}>{(this.state.choosenFile && this.state.choosenFile.name) || `Select File`}</div>
                                                        <FileUploadBlobDoc getFile={(file) => this.setState({ choosenFile: file })} />
                                                        <TButton id="upload-doc-btn" className="upload-file" disabled={this.state.choosenFile === null ? true : false} onClick={() => this.uploadCSVFile(this.state.choosenFile)} text={`UPLOAD MY LIST`} />
                                                        <div class="vertical-line">
                                                        </div>
                                                        <TButton
                                                            id="upload-contact-btn"
                                                            className="upload-file"
                                                            text="Upload Contact"
                                                            dataTarget="#addStartupContactModal"
                                                            dataToggle="modal"
                                                            onClick={() => true}
                                                        /> */}
                                                </div>
                                                <div className={`row contact-list-block upload-contact-list `}>
                                                    {this.state.isDocumentLoaded && this.renderUploadedCards()}
                                                </div>
                                            </div>
                                        </div>
                                        {/*------ Upload File Block END------*/}

                                        {/*------ User Profile Section START------*/}
                                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 profile-section-wraper profile-button">
                                            <div className="profile-section-inner">
                                                {/* <MyProfileForm profileData={this.state.profileData} onEditClick={() => this.props.history.push('/profile')} {...this.props}/>                                 */}
                                                {
                                                    this.state.isDocumentLoaded &&
                                                    <Documents profileData={this.state.profileData}
                                                        iirCount={this.state.startupCount}
                                                        executiveProgress={this.state.startupExecutiveProgress}
                                                        userRoleCode={userRoleCode}
                                                        startUpInvestments={this.state.startUpInvestments}
                                                        selectedInvestmentDocuments={this.state.selectedInvestmentDocuments}
                                                        onInvestmentChange={(type) => { this.onInvestmentChange(type) }}
                                                        investments={this.state.investmentForStartup}
                                                        companyId={this.state.startupCompany._id}
                                                        {...this.props} />
                                                }
                                            </div>
                                        </div>
                                        {/*------ User Profile Section END------*/}

                                    </div>
                                </div>
                            </div>

                        </>
                }

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        loadingSpinner: state.loadingSpinner,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        isLoading: bindActionCreators(isLoading, dispatch),
        isNotLoading: bindActionCreators(isNotLoading, dispatch),

    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainContainer));
import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import axios from 'axios';
import images from "../../images";
import common from '../../common';
import swal from 'sweetalert';
import './pipelineCss/pipeline.scss';
import './pipelineCss/default.scss';
import { Icons } from './Icons';
import NumberFormat from 'react-number-format';

const EngageData = (props) => {

    const { index, singleData, engageDeleteHandler, updateEngage } = props;

    const [generalCall, setGeneralCall] = useState(false);
    const [engageEditArr, setEngageEditArr] = useState([]);
    const [engageEditTd1, setEngageEditTd1] = useState('');
    const [engageEditTd2, setEngageEditTd2] = useState('');
    const [engageEditTd3, setEngageEditTd3] = useState('');
    const [engageEditTd4, setEngageEditTd4] = useState('');
    const [engageEditTd5, setEngageEditTd5] = useState('');
    const [engageEditTd6, setEngageEditTd6] = useState('');
    const [engageEditTd7, setEngageEditTd7] = useState('');
    const [engageEditTd8, setEngageEditTd8] = useState('');

    const inputHandlerChange = (e)  => {

        const inputId = e.target.id;
        let inputValue = e.target.value;
        setGeneralCall(true);
        if(inputId == 'engageEditTd1') {
            setEngageEditTd1(inputValue);
        } else if(inputId == 'engageEditTd2') {
            setEngageEditTd2(inputValue);
        } else if(inputId == 'engageEditTd3') {
            setEngageEditTd3(inputValue);
        } else if(inputId == 'engageEditTd4') {
            setEngageEditTd4(inputValue);
        } else if(inputId == 'engageEditTd5') {
            setEngageEditTd5(inputValue);
        } else if(inputId == 'engageEditTd6') {
            setEngageEditTd6(inputValue);
        } else if(inputId == 'engageEditTd7') {
            setEngageEditTd7(inputValue);
        } else if(inputId == 'engageEditTd8') {
            setEngageEditTd8(inputValue);
        }
    }    

    useEffect(() => {
        
        setEngageEditTd1( singleData.round );
        setEngageEditTd2( singleData.totalRaise );
        setEngageEditTd3( singleData.allocation );
        setEngageEditTd4( singleData.pps );
        setEngageEditTd5( singleData.postValuation );
        setEngageEditTd6( singleData.leadInvestor );
        setEngageEditTd7( singleData.proRata );
        setEngageEditTd8( singleData.timeline );

    }, [ singleData ]);

    useEffect( () => {

        if(generalCall) {
            
            const newArr = {
                'round' : engageEditTd1.trim(),
                'totalRaise' : engageEditTd2.trim(),
                'allocation' : engageEditTd3.trim(),
                'pps' : engageEditTd4.trim(),
                'postValuation' : engageEditTd5.trim(),
                'leadInvestor' : engageEditTd6.trim(),
                'proRata' : engageEditTd7.trim(),
                'timeline' : engageEditTd8.trim(),
            }
            //setEngageEditArr([...engageEditArr, newArr]);
            //console.log(newArr);
            updateEngage(index, newArr);
        }
    }, [ engageEditTd1, engageEditTd2, engageEditTd3, engageEditTd4, engageEditTd5, engageEditTd6, engageEditTd7, engageEditTd8 ]);

    return (
        <>
            <tr key={ index+"_engage" }>
                <td>
                    <input id="engageEditTd1" type="text" className='compState piple-input' placeholder='Enter Round' value={ engageEditTd1 } onChange={ (e) => inputHandlerChange(e) } />
                </td>
                <td>
                    {/* <input id="engageEditTd2" type="text" className='compState piple-input' placeholder='Enter Total Raise' value={ engageEditTd2 } onChange={ (e) => inputHandlerChange(e) } /> */}
                    <NumberFormat
                        value={ engageEditTd2 }
                        thousandSeparator={true}
                        id="engageEditTd2"
                        prefix={'$'}
                        fixedDecimalScale={true}
                        placeholder='Enter Total Raise'
                        className='compState piple-input'
                        onChange={ (e) => inputHandlerChange(e) }
                    />
                </td>
                <td>
                    {/* <input id="engageEditTd3" type="text" className='compState piple-input' placeholder='Enter Allocation' value={ engageEditTd3 } onChange={ (e) => inputHandlerChange(e) } /> */}
                    <NumberFormat
                        value={ engageEditTd3 }
                        thousandSeparator={true}
                        id="engageEditTd3"
                        prefix={'$'}
                        fixedDecimalScale={true}
                        placeholder='Enter Allocation'
                        className='compState piple-input'
                        onChange={ (e) => inputHandlerChange(e) }
                    />
                </td>
                <td>
                    {/* <input id="engageEditTd4" type="text" className='compState piple-input' placeholder='Enter PPS' value={ engageEditTd4 } onChange={ (e) => inputHandlerChange(e) } /> */}
                    <NumberFormat
                        value={ engageEditTd4 }
                        thousandSeparator={true}
                        id="engageEditTd4"
                        prefix={'$'}
                        fixedDecimalScale={true}
                        placeholder='Enter PPS'
                        className='compState piple-input'
                        onChange={ (e) => inputHandlerChange(e) }
                    />
                </td>
                <td>
                    {/* <input id="engageEditTd5" type="text" className='compState piple-input' placeholder='Enter Post Valuation' value={ engageEditTd5 } onChange={ (e) => inputHandlerChange(e) } /> */}
                    <NumberFormat
                        value={ engageEditTd5 }
                        thousandSeparator={true}
                        id="engageEditTd5"
                        prefix={'$'}
                        fixedDecimalScale={true}
                        placeholder='Enter Post Valuation'
                        className='compState piple-input'
                        onChange={ (e) => inputHandlerChange(e) }
                    />
                </td>
                <td>
                    <input id="engageEditTd6" type="text" className='compState piple-input' placeholder='Enter Lead Investor' value={ engageEditTd6 } onChange={ (e) => inputHandlerChange(e) } />
                </td>
                <td>
                    <input id="engageEditTd7" type="text" className='compState piple-input' placeholder='Enter Pro-Rata' value={ engageEditTd7 } onChange={ (e) => inputHandlerChange(e) } />
                </td>
                <td>
                    <input id="engageEditTd8" type="text" className='compState piple-input' placeholder='Enter Timeline' value={ engageEditTd8 } onChange={ (e) => inputHandlerChange(e) } />
                </td>
                <td><div title='Delete' className='tableTrashicon' onClick={ () => engageDeleteHandler(index) }  >{Icons.Trash}</div></td>
            </tr>
        </>
    )
}

export default EngageData;
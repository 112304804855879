import React from "react";
import Invited from "./Invited";
import Participate from "./Participate";

const InvitationCard = (props) => {
  const { invitation, fromToDo } = props;

  return (
    <>
      {invitation != undefined &&
        invitation.status != undefined &&
        invitation.status != null &&
        invitation.status.toLowerCase() !== "invited" &&
        invitation.status.toLowerCase() !== "not interested" &&
        invitation.status.toLowerCase() !== "completed" && (
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 col-xxl-3">
            <Participate
              {...props}
              fromToDo={fromToDo}
              reSendDocClick={() =>
                props.reSendDocClick(invitation.userId, invitation.investmentId)
              }
            />
          </div>
        )}
      {invitation != undefined &&
        invitation.status != undefined &&
        invitation.status.toLowerCase() === "invited" && (
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 col-xxl-3">
            <Invited fromToDo={fromToDo} {...props} />
          </div>
        )}
    </>
  );
};
export default InvitationCard;

import React from 'react'
import Header from '../../components/Header';
import StartupDashboardContainer from '../containers';

const App = () => (
  <>
  
    <Header
      username={window.localStorage.getItem('username')}
      role={window.localStorage.getItem('role')}
    />
    <StartupDashboardContainer/>
    
  </>
)

export default App
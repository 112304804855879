import React, { Component } from 'react';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import swal from 'sweetalert';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import _ from 'lodash';
import './WebinarInvestment.scss';
import common from '../../common';
import DatePicker from "react-datepicker";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Moment from 'react-moment';
import moment from 'moment';

class WebinarInvestmentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            investmentData: null,
            zoomlink1 : "",
            zoomlink2 : '',
            startDate1: "",
            startDate2: "",
            webinarDesc1 : "",
            webinarDesc2 : "",
            webinarEmailMessage : "",
            webinarRecording : "",
        }
    }

    componentWillReceiveProps(nextProps) {
        nextProps.onRef && nextProps.onRef(this);
    }
    
    setInvestment(investmentData, callback) {
        const temp = this;

        if(investmentData && investmentData.webinarLink1 != null && investmentData.webinarLink2 != null) {

            const zoom1 = (investmentData.webinarLink1 != null && investmentData.webinarLink1.link != null) ? investmentData.webinarLink1.link : "";
            const zoom2 = (investmentData.webinarLink2 != null && investmentData.webinarLink2.link != null) ? investmentData.webinarLink2.link : "";
            const startDate1 = (investmentData.webinarLink1 != null && investmentData.webinarLink1.eventDate != null ) ? new Date(investmentData.webinarLink1.eventDate) : "";
            const startDate2 = (investmentData.webinarLink2 != null && investmentData.webinarLink2.eventDate != null ) ? new Date(investmentData.webinarLink2.eventDate) : "";
            const webinarDesc1 = (investmentData.webinarLink1 != null && investmentData.webinarLink1.linkDesc != null ) ? investmentData.webinarLink1.linkDesc : ""
            const webinarDesc2 = (investmentData.webinarLink2 != null && investmentData.webinarLink2.linkDesc != null ) ? investmentData.webinarLink2.linkDesc : ""
            
            temp.setState({ 
                investmentData: investmentData,   
                zoomlink1 : zoom1,
                zoomlink2 : zoom2,
                startDate1 : startDate1,
                startDate2 : startDate2,
                webinarDesc1 : webinarDesc1,
                webinarDesc2 : webinarDesc2,
                webinarEmailMessage : (investmentData.webinarEmailMessage != null) ? investmentData.webinarEmailMessage : "",
                webinarRecording : (investmentData.webinarRecording != null) ? investmentData.webinarRecording : "",
            });
        } else {
            temp.setState({ 
                investmentData: investmentData
            });    
        }
        //temp.setState({ webinarEmailMessage : investmentData.webinarEmailMessage  });
        console.log("GOOOOO", investmentData);
        callback();


    }

    setStartDate( datetime, type ) {
        
        if(type == 1) {
            this.setState({ startDate1 : (datetime != null) ? new Date(datetime) : null });
        } else if(type == 2) {
            this.setState({ startDate2 : (datetime != null) ? new Date(datetime) : null });
        }
    }

    handleChange(id, newValue) {
        //console.log(id, newValue);
        if (id === 'zoomlink1') {
            this.setState({ zoomlink1: newValue });
        } else if (id === 'zoomlink2') {
            this.setState({ zoomlink2: newValue });    
        } else if (id === 'messageType') {
            
            // const linkUrl1 = (this.props.investment.webinarLink1 && this.props.investment.webinarLink1.link != null) ? this.props.investment.webinarLink1.link : "";
            // const date1 = (this.props.investment.webinarLink1 && this.props.investment.webinarLink1.link != null) ? this.props.investment.webinarLink1.eventDate : "";
            // const linkUrl2 = (this.props.investment.webinarLink2 && this.props.investment.webinarLink2.link != null) ? this.props.investment.webinarLink2.link : "";
            // const date2 = (this.props.investment.webinarLink2 && this.props.investment.webinarLink2.link != null) ? this.props.investment.webinarLink2.eventDate : "";

            // const webDesc1 = (this.props.investment.webinarLink1 && this.props.investment.webinarLink1.linkDesc != null) ? this.props.investment.webinarLink1.linkDesc : "";
            // const webDesc2 = (this.props.investment.webinarLink2 && this.props.investment.webinarLink2.linkDesc != null) ? this.props.investment.webinarLink2.linkDesc : "";
           
            // this.setState({ 
            //     messageType: newValue,  
            //     zoomlink1 : linkUrl1, 
            //     startDate1: (date1 != "") ? new Date(date1) : "",
            //     zoomlink2 : linkUrl2,
            //     startDate2: (date2 != "") ? new Date(date2) : "",
            //     webinarDesc1: webDesc1,
            //     webinarDesc2: webDesc2,
            //     webinarEmailMessage :   
            // });
        } else if ( id == 'webinar_desc1' ) {
            this.setState({ webinarDesc1: newValue });
        } else if ( id == 'webinar_desc2' ) {
            this.setState({ webinarDesc2: newValue });
        } else if( id == 'webinarEmailMessage' ) {
            this.setState({ webinarEmailMessage: newValue });
        } else if( id == 'webinarRecording' ) {
            this.setState({ webinarRecording: newValue });
        }
    } 

    submitBtn() {
        
        let investmentId = this.state.investmentData._id;
        let zoomlink1 = this.state.zoomlink1;
        let zoomlink2 = this.state.zoomlink2;
        let startDate1 = this.state.startDate1;
        let startDate2 = this.state.startDate2;
        let webinarDesc1 = this.state.webinarDesc1;
        let webinarDesc2 = this.state.webinarDesc2;
        let webinarEmailMessage = this.state.webinarEmailMessage;
        let webinarRecording = this.state.webinarRecording;
        
        const formatedDate1 = (startDate1 != null) ? moment(startDate1).format("dddd MMMM DD, Y hh:mm A") : "";
        const formatedDate2 = (startDate2 != null) ? moment(startDate2).format("dddd MMMM DD, Y hh:mm A") : "";

        let data = new FormData();
        //data.append('file', inputFile);
        
        const dataArr = JSON.stringify({
            'zoomlink1': zoomlink1,
            'zoomlink2': zoomlink2,
            'startDate1' : startDate1,
            'startDate2' : startDate2,
            'formatedDate1' : formatedDate1,
            'formatedDate2' : formatedDate2,
            'webinarDesc1' : webinarDesc1,
            'webinarDesc2' : webinarDesc2,
            'investmentId' : investmentId,
            'webinarEmailMessage' : webinarEmailMessage,
            'webinarRecording' : webinarRecording
        });

        data.append('data', dataArr);
        let token = window.localStorage.getItem('token');
        
        axios.post(`${common.path.base_url}/addInvestmentWebinar`, data, {
            headers: {
                "Authorization": token
            }
        })
        .then((response) => {
            if(response.data.status =="success" ) {
                swal({
                    title: "Sent",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                }).then(function () {
                    window.location.reload();
                });
                
            } else {
                swal({
                    title: "Error!",
                    text: response.data.message,
                    icon: "error",
                    button: "Ok!",
                });
            }
        })
        .catch(function (error) {
            swal({
                title: "Error!",
                text: error.response.data.message,
                icon: "error",
                button: "Ok!",
            });
        });
            
    }
    
    render() {
        return (
            <div>
                {/* <!-- update investment Modal HTML --> */}
                <div id="webinarSettingModal" className="modal fade">
                    <div className="modal-dialog">
                        <div className="modal-content invite-contact card-mess">

                            <div className="modal-header">
                                <h4 className="modal-title"> Webinar Setting</h4>
                            </div>
                            <div className="modal-body">
                                <div>
                                    
                                    <div className="form-group">
                                        <label className="col-lg-3 col-sm-3  col-md-3 control-label">RSVP / Email Body:</label>
                                        <div className="col-lg-9 col-sm-9 col-md-9">
                                            <CKEditor
                                                id="webinarBody"
                                                editor={ ClassicEditor }
                                                data={ this.state.webinarEmailMessage }
                                                onInit={ editor => {
                                                    // You can store the "editor" and use when it's needed.
                                                    console.log( 'Editor is ready to use!', editor );
                                                } }
                                                onChange={ ( event, editor ) => {
                                                    const data = editor.getData();
                                                    //console.log( { event, editor, data } );
                                                    this.handleChange('webinarEmailMessage', data);
                                                } }
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="form-group">
                                        <label className="col-lg-3 col-sm-3  col-md-3 control-label">Webinar Link1:</label>
                                        <div className="col-lg-9 col-sm-9 col-md-9">
                                            <input className="form-control" id="zoomlink1" name="zoomlink1" type="text"
                                                placeholder=''
                                                value={this.state.zoomlink1} 
                                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="form-group messpopup">
                                        <label className="col-lg-3 col-sm-3  col-md-3 control-label">Webinar Date1:</label>
                                        <div className="col-lg-9 col-sm-9 col-md-9">
                                            <DatePicker
                                                selected={ this.state.startDate1 }
                                                onChange={(date) => this.setStartDate(date, 1)}
                                                showTimeSelect
                                                //filterTime={ this.filterPassedTime() }
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                className= { "form-control" }
                                                popperPlacement="bottom-start"
                                            />
                                            {
                                                //console.log("govind", this.props.investment)
                                                //this.state.investment.webinarLink1.eventDate
                                            }
                                        </div>
                                    </div>

                                    <div className="form-group webinar-link-desc">
                                        <label className="col-lg-3 col-sm-3  col-md-3 control-label">Webinar Desc1:</label>
                                        <div className="col-lg-9 col-sm-9 col-md-9">
                                            <CKEditor
                                                id="webinarDesc1"
                                                editor={ ClassicEditor }
                                                data={ this.state.webinarDesc1 }
                                                onInit={ editor => {
                                                    // You can store the "editor" and use when it's needed.
                                                    console.log( 'Editor is ready to use!', editor );
                                                } }
                                                onChange={ ( event, editor ) => {
                                                    const data = editor.getData();
                                                    //console.log( { event, editor, data } );
                                                    this.handleChange('webinar_desc1', data);
                                                } }
                                            />

                                        </div>
                                    </div>
                                    
                                    <div className="form-group">
                                        <label className="col-lg-3 col-sm-3  col-md-3 control-label">Webinar Link2:</label>
                                        <div className="col-lg-9 col-sm-9 col-md-9">
                                            <input className="form-control" id="zoomlink2" name="zoomlink2" type="text"
                                                placeholder=''
                                                value={this.state.zoomlink2} 
                                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="form-group messpopup">
                                        <label className="col-lg-3 col-sm-3  col-md-3 control-label">Webinar Date2:</label>
                                        <div className="col-lg-9 col-sm-9 col-md-9">
                                            <DatePicker
                                                selected={ this.state.startDate2 }
                                                onChange={(date) => this.setStartDate(date, 2)}
                                                showTimeSelect
                                                //filterTime={ this.filterPassedTime() }
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                className= { "form-control" }
                                                popperPlacement="top-start"
                                            />

                                        </div>
                                    </div>
                                    <div className="form-group webinar-link-desc">
                                        <label className="col-lg-3 col-sm-3  col-md-3 control-label">Webinar Desc2:</label>
                                        <div className="col-lg-9 col-sm-9 col-md-9">
                                            <CKEditor
                                                id="webinarDesc2"
                                                editor={ ClassicEditor }
                                                data={ this.state.webinarDesc2 }
                                                onInit={ editor => {
                                                    // You can store the "editor" and use when it's needed.
                                                    console.log( 'Editor is ready to use!', editor );
                                                } }
                                                onChange={ ( event, editor ) => {
                                                    const data = editor.getData();
                                                    //console.log( { event, editor, data } );
                                                    this.handleChange('webinar_desc2', data);
                                                } }
                                            />
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="form-group">
                                    <label className="col-lg-3 col-sm-3  col-md-3 control-label">Recording Link:</label>
                                    <div className="col-lg-9 col-sm-9 col-md-9">
                                        <input className="form-control" id="webinarRecording" name="webinarRecording" type="text"
                                            placeholder=''
                                            value={this.state.webinarRecording} 
                                            onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                        />
                                    </div>
                                </div>
                                
                                <div className='modelbtn'>
                                    <input type="button" id="cancelUploadDoc" className="btn btn-default cancelmodal" data-dismiss="modal" value="CANCEL" />
                                    
                                    <LaddaButton
                                        id='lada-btn'
                                        loading={this.state.loadinglada}
                                        onClick={() => this.submitBtn()}
                                        data-color="#eee"
                                        data-size={XS}
                                        //className={!this.state.amount ? 'disable' : ''}
                                        data-style={EXPAND_RIGHT}
                                        data-spinner-size={30}
                                        data-spinner-color="#ddd"
                                        data-spinner-lines={12}
                                    >
                                        {'Submit'}
                                    </LaddaButton>
                                </div>
                            </div>
                            <div className="modal-footer"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default WebinarInvestmentModal;
import React, { Component } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import common from '../../../common';
import { path } from '../../../InvestorDashboard/imagesPath';
import downarrow from '../../../images/icons/downarrow.svg';
import search from '../../../images/icons/search.svg';
import TButton from '../../../components/TButton';
import NumberFormat from 'react-number-format';
import _ from 'lodash';

class AddNewManagementFeesandCarryModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            managementFeesandCarryUsers: [],
            pagination: {
                currentPage: 0,
                totalPages: 1,
            },
            suggestions: [
                { id: 'YPO', name: 'YPO' },
                { id: 'Institutional', name: 'Institutional' },
                { id: 'Strategic', name: 'Strategic' },
                { id: 'Preferred', name: 'Preferred' },
                { id: 'Investment(s)', name: 'Investment(s)' }],
            arrowType: {
                'name': 'menu-icon',
                email: 'menu-icon',
                managementfees: 'menu-icon',
                carry: 'menu-icon',
                shareprice: 'menu-icon'
            },
            $sort: null,
            managementFee: null,
            carry: null,
            searchKey: null,
        }
        const url = new URL(window.location.href);
        this.InvestmentId = url.searchParams.get('InvestmentID');
    }

    componentWillReceiveProps(nextProps) {
        nextProps.onRef && nextProps.onRef(this);
    }

    componentDidMount() {
        this.getInvestmentGlobalSettingsforManagement();
    }

    toggleArrow(fieldType) {
        let arrowType = this.state.arrowType;
        let $sort = {};
        if (arrowType[fieldType] === 'menu-icon') {
            arrowType[fieldType] = 'up-arrow';
            $sort[fieldType] = -1;
        } else {
            arrowType[fieldType] = 'menu-icon';
            $sort[fieldType] = 1;
        }
        this.setState({ arrowType, $sort });
    }

    addManagementFeesandCarryValue(id, userId) {
        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loading: true });
        const data = {
            managementFee: this.state.managementFee,
            carry: this.state.carry,
            id: id,
            userId:userId,
        }
        axios.post(`${common.path.base_url}/addManagementFeeAndCarry`, data, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                swal({
                    title: "Success!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(() => {
                        this.searchManagementFeesandCarryUsers(this.state.searchKey);
                    });
            })
            .catch((error) => {
                temp.setState({ loading: false });
                if (common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });

                }
                else {
                    swal({
                        title: "Network Error!",
                        text: "Something went wrong",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    searchManagementFeesandCarryUsers = _.debounce((key) => {
        let temp = this;
        temp.setState({ searchKey: key });
        let token = window.localStorage.getItem('token');
        temp.setState({ loading: true });
        const data = {
            investmentId: this.InvestmentId,
            search: key,
        };
        axios.post(`${common.path.base_url}/getManagementFeeAndCarry`, data, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false, managementFeesandCarryUsers: response.data.data, pagination: { currentPage: response.data.currentPage, totalPages: response.data.totalPages } });
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: "Something went wrong",
                        icon: "error",
                        button: "Ok!",
                    });
                }
                window.location.href = '/';
            });

    }, 800);

    getInvestmentGlobalSettingsforManagement() {
        const temp = this;
        temp.setState({ loading: true });
        let token = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/settings`, {
            headers: {
                "Authorization": token
            }
        })
            .then(function (response) {
                temp.setState({ loading: false });
                if (response && response.data && response.data.data) {
                    temp.setState({
                        managementFee: response.data.data.managementFee
                    });
                    temp.setState({
                        carry: response.data.data.carry
                    });
                }

            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else {
                    swal({
                        title: "Network Error!",
                        text: "Something went wrong",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    };

    render() {
        return (
            <div id="addNewmanagementFeesandCarryModal" className="modal fade">
                <div className="modal-dialog">
                    <div className="modal-content invite-contact">
                        <div className="modal-header">
                            <h4 className="modal-title">Add New</h4>
                            <img alt="" src={path.close} type="button" id="addNewmanagementFeesandCarryModal" data-dismiss="modal" onClick={() => { window.location.reload(); }} />
                        </div>
                        <div className="modal-body modal-body-padding">
                            <div className="modal-box-wrapper-main wrapper">
                                <div className='search addsearchposition'>
                                    <form className="form-group">
                                        <input className="form-control mr-sm-2 custominput"
                                            onChange={(event) => this.searchManagementFeesandCarryUsers(event.target.value)}
                                            type="search"
                                            placeholder="Search"
                                            aria-label="Search" />
                                        <img src={search} className="search-icon" />
                                    </form>
                                </div>
                                <div className="custom-table-view">
                                    <table className="table table-custom">
                                        <thead>
                                            <tr>
                                                <th>NAME  <img src={downarrow} className={`menu-icon ${this.state.arrowType['name']}`} onClick={() => this.toggleArrow('name')} /></th>
                                                <th>EMAIL  <img src={downarrow} className={`menu-icon ${this.state.arrowType['email']}`} onClick={() => this.toggleArrow('email')} /></th>
                                                <th>MANAGEMENT FEES  <img src={downarrow} className={`menu-icon ${this.state.arrowType['managementfees']}`} onClick={() => this.toggleArrow('managementfees')} /></th>
                                                <th>CARRY  <img src={downarrow} className={`menu-icon ${this.state.arrowType['carry']}`} onClick={() => this.toggleArrow('carry')} /></th>
                                                {/* <th>PRICE PER CLASS A SHARE  <img src={downarrow} className={`menu-icon ${this.state.arrowType['shareprice']}`} onClick={() => this.toggleArrow('shareprice')} /></th> */}
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.managementFeesandCarryUsers.length > 0 &&
                                                this.state.managementFeesandCarryUsers.map(user => {
                                                    return (
                                                        <tr key={user._id}>
                                                            <td className="username-row" onClick={() => {
                                                                this.props.history.push(`/viewProfile?id=${user._id}`)
                                                            }}>
                                                                {user.name}
                                                            </td>
                                                            <td>
                                                                {user.emailAddress}
                                                            </td>
                                                            <td>
                                                                <div className="two-part-main-content">
                                                                    <div className="first-box amount-investment">
                                                                        <NumberFormat
                                                                            className="remove-border"
                                                                            value={this.state.managementFee}
                                                                            thousandSeparator={true}
                                                                            fixedDecimalScale={true}
                                                                            placeholder='0'
                                                                            onValueChange={(values) => {
                                                                                this.setState({ managementFee: values.value })
                                                                            }} />
                                                                    </div>
                                                                    <div className="second-box">
                                                                        <h5>%</h5>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="two-part-main-content">
                                                                    <div className="first-box amount-investment">
                                                                        <NumberFormat
                                                                            className="remove-border"
                                                                            value={this.state.carry}
                                                                            thousandSeparator={true}
                                                                            fixedDecimalScale={true}
                                                                            placeholder='0'
                                                                            onValueChange={(values) => {
                                                                                this.setState({ carry: values.value })
                                                                            }} />
                                                                    </div>
                                                                    <div className="second-box">
                                                                        <h5>%</h5>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <TButton text='ADD NEW' className='invite-btn investmentdetail-btn' onClick={() => { this.addManagementFeesandCarryValue(user._id, user.userId) }} />
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            {
                                                this.state.managementFeesandCarryUsers.length === 0 &&
                                                <tr>
                                                    <td>No investors found!</td>
                                                </tr>

                                            }

                                            {/* <tr>
                                                <td className="username-row" >
                                                    Alice Parker
                                            </td>
                                                <td>
                                                    aliceparker@gmail.com
                                            </td>
                                                <td>
                                                    <div className="two-part-main-content">
                                                        <div className="first-box amount-investment">
                                                            <NumberFormat
                                                                className="remove-border"
                                                                thousandSeparator={true}
                                                                fixedDecimalScale={true}
                                                                placeholder='0'
                                                                onValueChange={(values) => {
                                                                    this.setState({ managementFee: values.value })
                                                                }} />
                                                        </div>
                                                        <div className="second-box">
                                                            <h5>%</h5>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="two-part-main-content">
                                                        <div className="first-box amount-investment">
                                                            <NumberFormat
                                                                className="remove-border"
                                                                thousandSeparator={true}
                                                                fixedDecimalScale={true}
                                                                placeholder='0'
                                                                onValueChange={(values) => {
                                                                    this.setState({ carry: values.value })
                                                                }} />
                                                        </div>
                                                        <div className="second-box">
                                                            <h5>%</h5>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="col-xs-3 col-sm-5 col-md-5 col-lg-5">
                                                    <TButton text='ADD NEW' className='invite-btn investmentdetail-btn' onClick={() => { this.addManagementFeesandCarryValue('id') }} />
                                                </td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {/* <LaddaButton
                                id='lada-btn'
                                loading={this.state.loadinglada}
                                onClick={() => this.sendMessage()}
                                data-color="#eee"
                                data-size={XS}
                                data-style={EXPAND_RIGHT}
                                data-spinner-size={30}
                                data-spinner-color="#ddd"
                                data-spinner-lines={12}
                                data-dismiss="modal"
                                disabled={this.state.message ? false : true}
                            >
                                {'Send Message'}
                            </LaddaButton> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default AddNewManagementFeesandCarryModal;
import React from "react";
import { connect } from "react-redux";
import { path } from "../../imagesPath";
import useScreenWidthLessThan768 from "../../shared/window_resize_hook";
import { default as PipelineInvestmentCard } from "./PipelineInvestmentCard";
import "./PipelineInvestments.scss";

const PipelineInvestment = (props) => {
    const { isBlackList } = props;
    const roleCode = localStorage.getItem("roleCode");
    const isScreenResized = useScreenWidthLessThan768();
    const getRemaningSentimentsText = () => {
        let count = 0;
        props.pipelineInvestments.sort((comp1, comp2) => {
            comp1 = comp1.companyName.toLowerCase();
            comp2 = comp2.companyName.toLowerCase();
            return comp1 < comp2 ? -1 : comp1 > comp2 ? 1 : 0;
        });
        props.pipelineInvestments.length &&
            props.pipelineInvestments.forEach((element) => {
                if (!element.pipelineStartupinterests) {
                    count++;
                }
            });
        if (count > 0) {
            return `Startups Under Consideration`;
        } else {
            return "Startups Under Consideration";
        }
    };
    return (
        <>
            {props.pipelineInvestments.length > 0 && !isBlackList && (
                <>
                    <div className="invitations_sec">
                        <div className="container">
                            <div className={(isScreenResized || (window && window.innerWidth < 830)) ? "row d-flex padding-left-10" : "row"}>
                                <img className="intial_icon" src={path.underConsideration} />
                                <div className={window && window.innerWidth < 400 ? "col-xs-12" : "col-md-4"}>
                                    <h3 style={{ fontSize: "20px", color: "#383F59" }}>
                                        {getRemaningSentimentsText()}
                                    </h3>
                                </div>
                                <a
                                    className="view-all-sentiments"
                                    alt="view all"
                                    onClick={() => {
                                        props.investorId
                                            ? props.history.push(
                                                `/investor-sentiments?investorId=${props.investorId}`
                                            )
                                            : props.history.push("/investor-sentiments");
                                    }}
                                >
                                    View All
                                </a>
                            </div>
                            <div className={props.popover ? "clicked" : "row"}>
                                <div className="pipeline-scroll">
                                    {props.pipelineInvestments.map((pipelineInvestment, index) => {
                                        return (
                                            <PipelineInvestmentCard
                                                pipelineInvestment={pipelineInvestment}
                                                fromSentiments={false}
                                                index={index}
                                                key={index}
                                                {...props}
                                                total={props.pipelineInvestments.length}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
            }
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        popover: state.popover && state.popover.id ? state.popover.id : null,
        pipelineInvestments:
            state.investorDashboardData.pipelineInvestments.length > 0
                ? state.investorDashboardData.pipelineInvestments
                : [],
    };
};

export default connect(mapStateToProps, null)(PipelineInvestment);

import axios from 'axios';
import React, { Component } from 'react';
import LaddaButton, { EXPAND_RIGHT, XS } from 'react-ladda';
import ReactPhoneInput from 'react-phone-input-2';
import { withRouter } from 'react-router-dom';
import { scroller } from "react-scroll";
import Select from 'react-select';
import swal from 'sweetalert';
import common from '../../../common';
import FileUploadBlob from '../../../components/FileUploadBlob';
import LoadingSpinner1 from '../../../components/IIR Loader';
import images from "../../../images";
import './CompanyIIR.scss';

class CompanyIIR extends Component {
    constructor(props) {
        super(props);
        this.companyId = '';
        this.investmentId = '';
        this.state = {
            name: '',
            legalName: '',
            description: '',
            totalfunding: null,
            lastfunding: null,
            employees: null,
            address: '',
            overview: '',
            type: [{ value: 'c', label: 'Corporation' }, { value: 'l', label: 'LLC' }, { value: 'p', label: 'Partnership' }, { value: 'sp', label: 'Sole Proprietorship' }],
            selectedtype: '',
            status: [{ value: 'p', label: 'Private' }, { value: 'a', label: 'Aquired' }, { value: 'i', label: 'IPO' }],
            selectedstatus: '',
            phone: '',
            loading: false,
            loadinglada: false,
            Role: '',
            nameValidation: '',
            legalNameValidation: '',
            descriptionValidation: '',
            totalfundingValidation: '',
            lastfundingValidation: '',
            employeesValidation: '',
            typeValidation: '',
            phoneValidation: '',
            websiteValidation: '',
            addressValidation: '',
            overviewValidation: '',
            profileFormValidated: false,
            website: '',
            companyLogo: '',
            companyIIRProfileImage: '',
            choosenFile: null,
            imageUploading: false,
            profileImg: "",
        }

    }
    componentDidMount() {
        const url = new URL(window.location.href);
        const type = url.searchParams.get('type');
        if (type) {

            scroller.scrollTo(type, {
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
            });
            this[type].focus();
        }

    }

    componentWillMount() {
        const url = new URL(window.location.href);
        this.companyId = url.searchParams.get('companyId');
        this.investmentId = url.searchParams.get('investmentId');
        this.type = url.searchParams.get('type');
        if (this.type) {
            // this.scrollToSpecificPosition(this.type)
        }
        // this.companyId=window.localStorage.getItem('companyId');
        this.getData();
        const userRole = window.localStorage.getItem('role');
        this.setState({ Role: userRole });
    }

    getIIRList() {
        let temp = this;
        let token = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/getInvestmentIIR/${this.investmentId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                const sampleArray = response.data.data
                const keys = Object.keys(sampleArray)
                const values = Object.values(sampleArray)
                let z = [];
                keys.forEach((key, i) => {
                    // z.push({ title: key, state: values[i] })
                    z.push(common.iirMapKey({ key, values, i }))
                })
                z.forEach((ele, i) => {
                    let title = ele.path.split(" ");
                    let path = '';
                    for (const word of title) {
                        path += word;
                    }
                    path = path.substring(0, 1).toLowerCase() + path.substring(1);
                    ele.path = path;
                })
                z.length = common.iirArrayLength;
                this.props.setIirList(z);
                // temp.props.history.push(`/iir/leadership?companyId=${this.companyId}`);
                temp.setState({ loadinglada: false });
            })
            .catch(function (error) {
                temp.setState({ loadinglada: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error && error.response && error.response.data && error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: "Something went wrong",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    getData() {
        let temp = this;
        temp.setState({ loading: true });
        const id = window.localStorage.getItem('id');
        let token = window.localStorage.getItem('token');
        this.setState({ id: id })
        axios.get(`${common.path.base_url}/getIIRCompany?companyId=${this.companyId}&investmentId=${this.investmentId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                const companyDetails = response.data.data[0];
                this.setState({
                    name: companyDetails.companyName,
                    selectedtype: [{ value: companyDetails.companyType, label: companyDetails.companyType }],
                    address: companyDetails.address,
                    website: companyDetails.website,
                    phone: companyDetails.mobile,
                    overview: companyDetails.overview,
                    companyLogo: companyDetails.companyLogo,
                    legalName: companyDetails.legalName,
                    description: companyDetails.description,
                    totalfunding: companyDetails.totalFunding,
                    lastfunding: companyDetails.lastFunding,
                    employees: companyDetails.numberOfEmployee,
                    selectedstatus: [{ value: companyDetails.IPOStatus, label: companyDetails.IPOStatus }],
                    profileImg: companyDetails.iirCompanyImage ? companyDetails.iirCompanyImage : null,
                });
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: "Something went wrong",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    updateCompanyData() {
        let temp = this;
        temp.setState({ loadinglada: true });
        const token = localStorage.getItem('token');
        let mobile = (this.state.phone && this.state.phone != undefined && this.state.phone != null) ? this.state.phone.replace(/[- )(]/g, '') : "+1";
        axios.put(`${common.path.base_url}/updateIIRInvestmentCompany`, {
            companyId: this.companyId,
            investmentId: this.investmentId,
            companyName: this.state.name,
            companyType: this.state.selectedtype[0] === undefined ? this.state.selectedtype.label : this.state.selectedtype[0].label,
            address: this.state.address,
            website: this.state.website,
            // mobile: this.state.phone,
            mobile: mobile,
            overview: this.state.overview,
            companyLogo: this.state.companyLogo,
            companyProfileImage: this.state.companyIIRProfileImage,
            legalName: this.state.legalName,
            description: this.state.description,
            totalFunding: this.state.totalfunding,
            lastFunding: this.state.lastfunding,
            numberOfEmployee: this.state.employees,
            IPOStatus: this.state.selectedstatus[0] === undefined ? this.state.selectedstatus.label : this.state.selectedstatus[0].label,
        },
            {
                headers: {

                    "Authorization": token
                }
            })
            .then((response) => {
                swal({
                    title: "Updated!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(() => {
                        temp.getData();
                        temp.getIIRList()
                    })
                temp.setState({ profileFormValidated: false });
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                    temp.setState({ loadinglada: false });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: "Something went wrong",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    handleChange(id, newValue) {
        if (id === "companyName")
            this.setState({ name: newValue });
        else if (id === 'legalName')
            this.setState({ legalName: newValue });
        else if (id === 'address')
            this.setState({ address: newValue });
        else if (id === 'overview')
            this.setState({ overview: newValue });
        else if (id === 'website')
            this.setState({ website: newValue });
        else if (id === 'phone')
            this.setState({ phone: newValue });
        else if (id === 'oneLineDescription')
            this.setState({ description: newValue });
        else if (id === 'totalFunding')
            this.setState({ totalfunding: newValue });
        else if (id === 'lastFunding')
            this.setState({ lastfunding: newValue });
        else if (id === 'employees')
            this.setState({ employees: newValue })

    }

    selectType = (selectedtype) => {
        this.setState({
            selectedtype
        })
    }

    selectStatus = (selectedstatus) => {
        this.setState({
            selectedstatus
        })
    }

    formValidaton(id, val) {
        if (id === 'companyName') {
            if (val === '') {
                this.setState({
                    nameValidation: "Company name should not be empty*",
                })
            } else {
                this.setState({
                    nameValidation: "",
                })
            }
        }
        else if (id === 'companyNickname') {
            if (val === '') {
                this.setState({
                    legalNameValidation: "Comapany Nickname should not be empty*",
                })
            } else {
                this.setState({
                    legalNameValidation: "",
                })
            }
        }
        else if (id === 'type') {
            if (val.label === '') {
                this.setState({
                    typeValidation: "Type should not be empty*",
                })
            } else {
                this.setState({
                    typeValidation: "",
                })
            }
        }
        else if (id === 'address') {
            if (val === '') {
                this.setState({
                    addressValidation: "Address should not be empty*",
                })
            } else {
                this.setState({
                    addressValidation: "",
                })
            }
        }
        else if (id === 'oneLineDescription') {
            if (val === '') {
                this.setState({
                    descriptionValidation: "Description should not be empty*",
                })
            } else {
                this.setState({
                    descriptionValidation: "",
                })
            }
        }
        else if (id === 'totalFunding') {
            if (val === '') {
                this.setState({
                    totalfundingValidation: "Total Funding Date should not be empty*",
                })
            } else {
                this.setState({
                    totalfundingValidation: "",
                })
            }
        }
        else if (id === 'lastFunding') {
            if (val === '') {
                this.setState({
                    lastfundingValidation: "Last Funding Round and Date should not be empty*",
                })
            } else {
                this.setState({
                    lastfundingValidation: "",
                })
            }
        }
        else if (id === 'employees') {
            if (val === '') {
                this.setState({
                    employeesValidation: "Number of Employees should not be empty*",
                })
            } else {
                this.setState({
                    employeesValidation: "",
                })
            }
        }
        else if (id === 'IPOstatus') {
            if (val.label === '') {
                this.setState({
                    statusValidation: "IPO Status should not be empty*",
                })
            } else {
                this.setState({
                    statusValidation: "",
                })
            }
        }
        else if (id === 'overview') {
            if (val === '') {
                this.setState({
                    overviewValidation: "Overview should not be empty*",
                })
            } else {
                this.setState({
                    overviewValidation: "",
                })
            }
        }
        else if (id === 'website') {
            if (val === '') {
                this.setState({
                    websiteValidation: "Website should not be empty*",
                })
            } else {
                this.setState({
                    websiteValidation: "",
                })
            }
        }

        else if (id === 'phone') {
            const Mobile = document.getElementById('phone').value;
            let mobileExp = /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/;
            var mobileNo = mobileExp.test(Mobile);
            if (val === '') {
                this.setState({
                    phoneValidation: "company phone number should not be empty*",
                })
            } else if (mobileNo !== true) {
                let swissRegex = /^(\+?)(\d{2,4})(\s?)(\-?)((\(0\))?)(\s?)(\d{2})(\s?)(\-?)(\d{3})(\s?)(\-?)(\d{2})(\s?)(\-?)(\d{2})/;
                let swissres = swissRegex.test(Mobile);
                if (swissres !== true) {
                    this.setState({
                        phoneValidation: "Please enter valid phone number",
                    })
                } else {
                    this.setState({
                        phoneValidation: "",
                    })
                }
            } else {
                this.setState({
                    phoneValidation: "",
                })
            }
        }
    }

    uploadLogo(file) {
        if (file.size) {
            const temp = this;
            temp.setState({ imageUploading: true });
            let tokenpass = window.localStorage.getItem('token');
            let data = new FormData();
            data.append('file', file);
            const isPrivate = true;
            data.append('isPrivate', isPrivate)
            data.append('imgType', 'profile')
            data.append('companyId', this.companyId)
            axios.post(`${common.path.base_url}/uploadFile`, data, {
                headers: {
                    "Authorization": tokenpass
                }
            })
                .then((response) => {
                    temp.setState({ companyLogo: response.data.data.id });
                    // temp.setState({ imageUploading: false });

                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        window.localStorage.clear();
                        window.location.href = '/';
                        return;
                    } else { }
                });
        }
    }

    uploads3ProfileImage(file) {
        if (file.size) {
            const temp = this;
            temp.setState({ imageUploading: true });
            const tokenpass = window.localStorage.getItem('token');
            const data = new FormData();
            const path = "company-profile"
            data.append('file', file);
            data.append('path', path);
            axios.post(`${common.path.base_url}/s3ImageUploader`, data, {
                headers: {
                    "Authorization": tokenpass
                }
            })
                .then((response) => {
                    temp.setState({ companyIIRProfileImage: response.data.imageUrl });
                    temp.setState({ imageUploading: false });
                    temp.updateCompanyData();

                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        window.localStorage.clear();
                        window.location.href = '/';
                        return;
                    } else { }
                });
        }
    }


    saveCompanyData() {
        this.updateCompanyData();
    }

    handleOnChangePhone(value) {
        this.setState({ phone: `+${value}` });
    }
    onKeyPress(event) {
        if (event.which === 13 /* Enter */) {
            event.preventDefault();
        }
    }


    render() {
        return (
            <div className="companyIIR">
                {this.state.loading ? <LoadingSpinner1 /> : ""}
                <div className="companyIIR-main">
                    <div className='heading'>
                        <h3>Company Overview</h3>
                    </div>
                    <div className="companyIIR">
                        <div className='companyIIR-details'>
                            <React.Fragment>
                                <form className="form-horizontal">
                                    <div className="form-group">
                                        <label className="col-md-3 control-label">Company Legal Name:</label>
                                        <div className="col-md-8">
                                            <input className="form-control" id="legalName" type="text" placeholder='Nickname'
                                                onChange={(event) => { this.handleChange(event.target.id, event.target.value) }}
                                                onKeyPress={this.onKeyPress}
                                                value={this.state.legalName} />
                                            <p>{this.state.legalNameValidation}</p>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-3 control-label">Company Nickname:</label>
                                        <div className="col-md-8">
                                            <input className="form-control" id="companyName" type="text" placeholder='name'
                                                onChange={(event) => { this.handleChange(event.target.id, event.target.value) }}
                                                onKeyPress={this.onKeyPress}
                                                value={this.state.name} />
                                            <p>{this.state.nameValidation}</p>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-3 control-label company-label">Company logo:</label>
                                        <div className='col-md-8'>
                                            <div className='upload-companyIIR-pic '>
                                                <div className="companyIIR-pic-box">
                                                    <img id='preview' className="profile-pic" src={this.state.imageUploading ? `${images.path.loader}` : this.state.profileImg ? this.state.profileImg : `${common.path.base_url}/getFileNew?fileId=${this.state.companyLogo}`} alt='' />
                                                    <div className="p-image">
                                                        <i onClick={() => document.querySelector(`#chooseFile${1}`).click()} className="fa fa-pencil upload-button"></i>
                                                        <FileUploadBlob
                                                            id={`chooseFile${1}`}
                                                            getFile={(file) => { this.setState({ choosenFile: file }); this.uploadLogo(this.state.choosenFile); this.uploads3ProfileImage(this.state.choosenFile) }}
                                                            acceptType={`image/*`}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-3 control-label">Company Type:</label>
                                        <div className="col-md-8">
                                            <Select
                                                defaultValue={this.state.selectedtype}
                                                options={this.state.type}
                                                value={this.state.selectedtype}
                                                onChange={this.selectType}
                                                id='type'
                                            />
                                            <p >{this.state.typeValidation}</p>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label className="col-md-3 control-label">Company phone:</label>
                                        <div className="col-md-8">
                                            <ReactPhoneInput country={'us'} id="Phone"
                                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                onChange={(value) => this.handleOnChangePhone(value)}
                                                disableAreaCodes={true}
                                                value={this.state.phone}
                                                countryCodeEditable={false}
                                                autoComplete="none"
                                                placeholder="Phone Number" />
                                            <p>{this.state.phoneValidation}</p>
                                        </div>

                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-3 control-label">Company Website:</label>
                                        <div className="col-md-8">
                                            <input className="form-control" id="website" type='text' placeholder='Website'
                                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                onKeyPress={this.onKeyPress}
                                                value={this.state.website} />
                                            <p >{this.state.websiteValidation}</p>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-3 control-label">Headquarters Address:</label>
                                        <div className="col-md-8">
                                            <input className="form-control" id="address" type='text' placeholder='Address'
                                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                onKeyPress={this.onKeyPress}
                                                value={this.state.address} />
                                            <p >{this.state.addressValidation}</p>
                                        </div>
                                    </div>
                                    {/* <div className="form-group">
                                        <label className="col-md-3 control-label">Description:</label>
                                        <div className="col-md-8">
                                            <input className="form-control" id="oneLineDescription" type="text" placeholder='Description'
                                                onChange={(event) => { this.handleChange(event.target.id, event.target.value) }}
                                                onKeyPress={this.onKeyPress}
                                                value={this.state.description} />
                                            <p>{this.state.descriptionValidation}</p>
                                        </div>
                                    </div> */}
                                    <div className="form-group">
                                        <label className="col-md-3 control-label">Company Overview:</label>
                                        <div className="col-md-8">
                                            <textarea className="form-control" id="overview" type="message" placeholder='Company Overview' rows="5" cols="50"
                                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                value={this.state.overview}></textarea>
                                            <p>{this.state.overviewValidation}</p>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-3 control-label">Total Funding to Date:</label>
                                        <div className="col-md-8">
                                            <input className="form-control totalFund" id="totalFunding" type="text" placeholder='Total Funding'
                                                onChange={(event) => { this.handleChange(event.target.id, event.target.value) }}
                                                onKeyPress={this.onKeyPress}
                                                value={this.state.totalfunding}
                                                ref={(input) => { this.totalFund = input; }}
                                            />
                                            <p>{this.state.totalfundingValidation}</p>
                                        </div>
                                    </div>
                                    <div className="form-group" ref={this.fundingRoundDate}>
                                        <label className="col-md-3 control-label">Last Funding Round and Date:</label>
                                        <div className="col-md-8">
                                            <input className="form-control fundingRoundDate" id="lastFunding" type="text" placeholder='Last Funding Round'
                                                onChange={(event) => { this.handleChange(event.target.id, event.target.value) }}
                                                onKeyPress={this.onKeyPress}
                                                ref={(input) => { this.fundingRoundDate = input; }}
                                                value={this.state.lastfunding} />
                                            <p>{this.state.lastfundingValidation}</p>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-3 control-label">Number of Employees:</label>
                                        <div className="col-md-8">
                                            <input className="form-control" id="employees" type="number" placeholder='No. of Employees'
                                                // onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                                onChange={(event) => { this.handleChange(event.target.id, event.target.value) }}
                                                onKeyPress={this.onKeyPress}

                                                value={this.state.employees} />
                                            <p>{this.state.employeesValidation}</p>
                                        </div>
                                    </div>
                                    <div className="form-group" ref={this.IPOStatus}>
                                        <label className="col-md-3 control-label">IPO Status:</label>
                                        <div className="col-md-8 IPOStatus">
                                            <Select
                                                defaultValue={this.state.selectedstatus}
                                                options={this.state.status}
                                                value={this.state.selectedstatus}
                                                onChange={this.selectStatus}
                                                ref={(input) => { this.IPOStatus = input; }}
                                                id='IPOstatus'
                                            />
                                            <p >{this.state.statusValidation}</p>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-3 control-label1"></label>
                                        <div className="col-md-8 updateButton">
                                            <LaddaButton
                                                id="update-btn"
                                                loading={this.state.loadinglada}
                                                onClick={(event) => this.saveCompanyData(event.preventDefault())}
                                                data-color="#eee"
                                                data-size={XS}
                                                data-style={EXPAND_RIGHT}
                                                data-spinner-size={30}
                                                data-spinner-color="#ddd"
                                                data-spinner-lines={12}
                                            // className={(_.isEmpty(this.state.name && this.state.type && this.state.overview && this.state.address && this.state.phone && this.state.website) || !_.isEmpty(this.state.phoneValidation)) ? 'disable' : ''}
                                            >
                                                SAVE
                                            </LaddaButton>
                                        </div>
                                    </div>
                                </form>
                            </React.Fragment>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}
// export default Profile;
export default withRouter(CompanyIIR);
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import _ from "lodash";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import LaddaButton, { EXPAND_RIGHT, XS } from "react-ladda";
import NumberFormat from "react-number-format";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import swal from "sweetalert";
import common from "../../common";
import LoadingSpinner from "../../components/Loader";
import TButton from "../../components/TButton";
import RequiredSign from "../RequiredSign";
import "./CustomModal.scss";

class CustomModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      name: "",
      firstName: "",
      middleName: "",
      lastName: "",
      title: "",
      address: "",
      emailAddress: "",
      mobile: "",
      office: "",
      company: "",
      companyId: null,
      address1: "",
      address2: "",
      city: "",
      state1: "",
      zipcode: "",
      website: "",
      isProspectStartup: false,
      Company: "",
      CompanyNickName: "",
      phone: "",
      list: [],
      roles: [],
      roleCode: "000",
      loadinglada: false,
      loading: true,
      loadinglada2: false,
      nameValidation: "",
      firstNameValidation: "",
      lastNameValidation: "",
      titleValidation: "",
      companyValidation: "",
      emailValidation: "",
      CompanyValidation: "",
      addresValidation1: "",
      CityValidation: "",
      StateValidation: "",
      ZipCodeValidation: "",
      websiteValidation: "",
      emailValidated: false,
      emailForgotPasswordValidation: "",
      mobileValidation: "",
      officeValidation: "",
      phoneValidation: "",
      preSelected: -1,
      startupCompany: "",
      startupCompaniesList: [],
      selectedStartupCompanyOption: "",
      selectedStartupInvestmentOption: "",
      startupCompanyId: "",
      startupCompanyTags: [],
      startupInvestmentTags: [],
      investorReferralTags: [],
      startupInvestmentsList: [],
      investorList: [],
      selectedInvestorOption: "",
      selectedInvestmentOption: "",
      investorName: "",
      isAddNewInvestor: false,
      investorCompanyName: "",
      selectedInviteOption: "",
      notes: "",
      investorReferralList: [],
      isCompanyEdit: false,
      editcompanyId: null,
      selectedcategory: "",
      category: [
        { value: "YPO", label: "YPO" },
        { value: "Institutional", label: "Institutional" },
        { value: "Strategic", label: "Strategic" },
        { value: "Unsubscribed", label: "Unsubscribed" },
        { value: "Blacklist", label: "Blacklist" },
        { value: "Preferred", label: "Preferred" },
        { value: "Investment(s)", label: "Investment(s)" },
        { value: "Spire One", label: "Spire One" },
        { value: "International", label: "International" },
        { value: "Federal", label: "Federal" },
        { value: "CXO", label: "CXO" },
      ],
      selectedCategoryTags: [],
      key: 0,
      stages: [
        { value: "in progress", label: "IN PROGRESS" },
        { value: "exit in process", label: "EXIT IN PROCESS" },
        { value: "exited", label: "EXITED" },
      ],
      selectedStages: null,
      exitProcess: [
        { value: "ipo", label: "IPO" },
        { value: "acquisition", label: "Acquisition" },
        { value: "acquisitionwithescrow", label: "Acquisition with Escrow" },
      ],
      selectedExitProcess: "",
      disbursementDate: null,
      lockupDate: null,
      acquiringCompanyName: null,
      isPipelineStartupEdit: null,
      allInvestment: [],
      gainLoader: false,
      exitNotes: "",
    };
    this.routeChange = this.routeChange.bind(this);
    this.handleInvestorChange = this.handleInvestorChange.bind(this);
    this.selectStage = this.selectStage.bind(this);
  }

  componentWillMount() {
    this.getRoleData();
    // this.companySearch('')
    this.props.onRef && this.props.onRef(this);
  }

  componentWillReceiveProps(nextProps) {
    nextProps.onRef && nextProps.onRef(this);
  }
  handleTagChange(tags) {
    this.setState({ tags });
  }
  handleInvestorChange(id, newValue) {
    if (newValue.value === "000") {
      this.setState({
        investor: newValue.investorName,
        investorId: newValue.value,
        selectedInvestorOption: {
          label: newValue.investorName,
          value: newValue.value,
        },
        title: "",
        emailAddress: "",
        mobile: "",
        office: "",
        isAddNewInvestor: true,
        startupCompanyTags: [],
        investorReferralTags: [],
        investorName: newValue.investorName,
        investorCompanyName: "",
        notes: "",
        investorReferralList: [],
      });
    } else {
      const company = newValue.user.companyId;
      const companies = newValue.user.companies;
      const referrals = newValue.user.referrals;
      let startupCompanyTags = [],
        newstartupCompanyTags = [],
        investorReferralTags = [];
      // for depereacated support,
      if (company && company._id && company.companyName) {
        startupCompanyTags = [{ id: company._id, name: company.companyName }];
      }
      if (companies && companies.length > 0) {
        newstartupCompanyTags = companies.map((company) => {
          return { id: company._id, name: company.companyName };
        });
      }
      if (referrals && referrals.length > 0) {
        investorReferralTags = referrals.map((referral) => {
          return { id: referral._id, name: referral.name };
        });
      }
      this.setState({
        investor: newValue.label,
        investorId: newValue.value,
        selectedInvestorOption: newValue,
        title: newValue.user.title,
        emailAddress: newValue.user.emailAddress,
        investorCompanyName: newValue.user.investorCompanyName,
        mobile: newValue.user.mobile,
        office: newValue.user.office,
        startupCompanyTags: [...startupCompanyTags, ...newstartupCompanyTags],
        investorReferralTags,
        isAddNewInvestor: false,
        investorName: newValue.label,
        notes: newValue.user.notes,
      });
    }
    setTimeout(() => {
      this.checkInvitaionFormValidity();
    }, 100);
  }
  getRoleData(callback) {
    let temp = this;
    const roleCode = window.localStorage.getItem("roleCode");
    this.setState({ roleCode: roleCode });
    axios
      .get(`${common.path.base_url}/getRoles`, {
        params: {
          invitationFromCode: roleCode,
        },
      })
      .then(function (response) {
        // if (roleCode === '003') {
        //     const roles = response.data.data && response.data.data.filter(role => role.roleCode !== roleCode)
        //     temp.setState({ roles });
        //     temp.handleChange('invite-type', { value: '004' });
        // } else {
        //     temp.setState({ roles: response.data.data });
        // }
        temp.setState({ roles: response.data.data });
        callback();
        this.setState({ loading: false });
      })
      .catch(function (error) { })
      .finally(function () { });
  }

  setCompanyInvestor(companyData, callback) {
    this.getRoleData(() => {
      let inviteTypeIndex = 0;
      this.state.roles.forEach((ele, i) => {
        if (ele.roleName === "INVESTOR") {
          inviteTypeIndex = i;
        }
      });

      this.handleChange("company", {
        label: companyData.companyName,
        value: companyData._id,
      });
      this.handleChange("invite-type", {
        value: this.state.roles[inviteTypeIndex].roleCode,
      });
      // Since, Handle isnt taking care of SelecteOptions, we are setting state for it
      this.setState(
        {
          preSelected: inviteTypeIndex,
          selectedCompanyOption: {
            label: companyData.companyName,
            value: companyData._id,
          },
          companyId: companyData._id,
          company: companyData.companyName,
        },
        callback()
      );
    });
  }

  noPreselected() {
    this.setState({});
  }

  resetInviteData() {
    const key = this.state.key + 1;
    this.setState({
      type: "",
      name: "",
      firstName: "",
      lastName: "",
      middleName: "",
      title: "",
      address: "",
      emailAddress: "",
      mobile: "",
      office: "",
      company: "",
      companyId: null,
      selectedCompanyOption: null,
      list: [],
      loading: false,
      loadinglada: false,
      loadinglada2: false,
      nameValidation: "",
      titleValidation: "",
      companyValidation: "",
      emailValidation: "",
      CompanyValidation: "",
      addresValidation1: "",
      CityValidation: "",
      StateValidation: "",
      ZipCodeValidation: "",
      websiteValidation: "",
      emailValidated: false,
      validInvitaionForm: false,
      preSelected: -1,
      startupCompany: "",
      startupCompaniesList: [],
      selectedStartupCompanyOption: "",
      startupCompanyId: "",
      startupCompanyTags: [],
      investorReferralTags: [],
      investorName: "",
      investorCompanyName: "",
      selectedInviteOption: "",
      notes: "",
      investorReferralList: [],
      isCompanyEdit: false,
      selectedcategory: "",
      selectedCategoryTags: [],
      key,
      selectedInvestmentOption: "",
      isPipelineStartupEdit: null,
    });
    document.getElementById("invite-type").value = "";
  }

  resetAddCompanyData() {
    this.setState({
      address1: "",
      address2: "",
      city: "",
      state1: "",
      zipcode: "",
      website: "",
      Company: "",
      CompanyNickName: "",
      phone: "",
      isProspectStartup: false,
      loadinglada2: false,
      isCompanyEdit: false,
      editcompanyId: null,
      selectedStages: null,
      selectedExitProcess: null,
      lockupDate: null,
      disbursementDate: null,
      acquiringCompanyName: null,
      isPipelineStartupEdit: null,
    });
  }

  resetEmailData() {
    document.getElementById("emailForgotPassword").value = "";
    this.setState({ emailForgotPasswordValidation: "" });
  }

  routeChange() {
    let formValid = false;
    const email = document.getElementById("emailForgotPassword").value;
    var exp2 =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var res2 = exp2.test(email);
    if (email === "" || res2 !== true) {
      formValid = false;
    } else {
      formValid = true;
    }

    if (formValid) {
      let _swal = swal;
      axios
        .post(`${common.path.base_url}/forgotPassword`, {
          emailAddress: email,
        })
        .then(function (response) {
          if (
            response.data &&
            response.data.message &&
            common.AUTH_EXPIRED === response.data.message
          ) {
            window.localStorage.clear();
            window.location.href = "/";
            return;
          } else if (response.data.code === 204) {
            window.localStorage.clear();
            this.props.history.push("/");
          }
          _swal({
            title: "Email Sent!",
            text: response.data.message,
            icon: "success",
            button: "Ok!",
          }).then((val) => {
            document.getElementById("close-forgot").click();
            document.getElementById("emailForgotPassword").value = "";
          });
        })
        .catch(function (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message &&
            common.AUTH_EXPIRED === error.response.data.message
          ) {
            window.localStorage.clear();
            window.location.href = "/";
            return;
          } else if (error.response.data.code === 204) {
            window.localStorage.clear();
            window.location.href = "/";
          } else if (error.response) {
            swal({
              title: "Error!",
              text:
                error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : "Something Went Wrong",
              icon: "error",
              button: "Ok!",
            });
          } else {
            swal({
              title: "Error! #48754852",
              text: error.message,
              icon: "error",
              button: "Ok!",
            });
          }
          // alert(error.response.data.message);
        });
    } else {
      // document.getElementById("submitButton").disabled = true;
      //sweetalert
      // alert("invalid email");
    }
  }
  handleOnChangeMobile(value) {
    this.setState({ mobile: `+${value}` }, () => this.checkInvitaionFormValidity());
  }
  handleOnChangePhone(value) {
    this.setState({ phone: `+${value}` });
  }
  handleOnChangeOffice(value) {
    this.setState({ office: `+${value}` }, () => this.checkInvitaionFormValidity());
  }
  handleOnChangeCompany(value) {
    this.setState({ company: `+${value}` });
  }

  handleChange(id, newValue) {
    // let temp=this;
    if (id === "name") {
      let name = newValue;
      if (name === "") {
        this.setState({
          nameValidation: "Name should not be empty*",
        });
      } else {
        this.setState({
          nameValidation: "",
        });
      }
    } else if (id === "firstName") {
      // let name = document.getElementById('firstName').value;
      let name = newValue;
      if (name === "") {
        this.setState({
          firstNameValidation: "First Name (Legal) should not be empty*",
        });
      } else {
        this.setState({
          firstNameValidation: "",
        });
      }
    } else if (id === "lastName") {
      // let name = document.getElementById('lastName').value;
      let name = newValue;
      if (name === "") {
        this.setState({
          lastNameValidation: "Last Name should not be empty*",
        });
      } else {
        this.setState({
          lastNameValidation: "",
        });
      }
    } else if (id === "title") {
      let title = newValue;
      if (title === "") {
        this.setState({
          titleValidation: "Title should not be empty*",
        });
      } else {
        this.setState({
          titleValidation: "",
        });
      }
    } else if (id === "company") {
      let company = newValue;
      // if (company.options[company.selectedIndex].value === "") {
      //     this.setState({
      //         companyValidation: "Company should not be empty*"
      //     })
      // } else {
      //     this.setState({
      //         companyValidation: ""
      //     })
      // }
      if (company && company.value === "") {
        this.setState({
          companyValidation: "Company should not be empty*",
        });
      } else {
        this.setState({
          companyValidation: "",
        });
      }
    } else if (id === "email") {
      let email = newValue;
      let emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let res = emailRegex.test(email);
      if (email === "") {
        this.setState({
          emailValidation: "Email should not be empty*",
        });
      } else if (res !== true) {
        this.setState({
          emailValidation: "Please enter valid email*",
        });
      } else {
        this.setState({
          emailValidation: "",
        });
      }
    } else if (id === "Company") {
      let Company = newValue;
      if (Company === "") {
        this.setState({
          CompanyValidation: "Company name should not be empty*",
        });
      } else {
        this.setState({
          CompanyValidation: "",
        });
      }
    } else if (id === "Address1") {
      let address = newValue;
      if (address === "") {
        this.setState({
          addresValidation1: "Address1 should not be empty*",
        });
      } else {
        this.setState({
          addresValidation1: "",
        });
      }
    } else if (id === "City") {
      let address = newValue;
      if (address === "") {
        this.setState({
          CityValidation: "City should not be empty*",
        });
      } else {
        this.setState({
          CityValidation: "",
        });
      }
    } else if (id === "State1") {
      let address = newValue;
      if (address === "") {
        this.setState({
          StateValidation: "State should not be empty*",
        });
      } else {
        this.setState({
          StateValidation: "",
        });
      }
    } else if (id === "ZipCode") {
      let address = newValue;
      if (address === "") {
        this.setState({
          ZipCodeValidation: "Zip Code should not be empty*",
        });
      } else {
        this.setState({
          ZipCodeValidation: "",
        });
      }
    } else if (id === "Website") {
      let website = newValue;
      if (website !== "") {
        let websiteRegex =
          /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
        let reg = websiteRegex.test(website);
        if (website === "") {
          this.setState({
            websiteValidation: "Website should not be empty*",
          });
        } else if (reg !== true) {
          this.setState({
            websiteValidation: "Please enter valid Website*",
          });
        } else {
          this.setState({
            websiteValidation: "",
          });
        }
      } else {
        this.setState({
          websiteValidation: "",
        });
      }
    } else if (id === "mobile") {
      if (newValue !== "") {
        let mobile = newValue;
        let mobileRegex =
          /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/;
        let res = mobileRegex.test(mobile);
        if (res !== true) {
          let swissRegex =
            /^(\+?)(\d{2,4})(\s?)(\-?)((\(0\))?)(\s?)(\d{2})(\s?)(\-?)(\d{3})(\s?)(\-?)(\d{2})(\s?)(\-?)(\d{2})/;
          let swissres = swissRegex.test(mobile);
          if (swissres !== true) {
            this.setState({
              mobileValidation: "Please enter valid mobile number",
            });
          } else {
            this.setState({
              mobileValidation: "",
            });
          }
        } else {
          this.setState({
            mobileValidation: "",
          });
        }
      } else {
        this.setState({
          mobileValidation: "",
        });
      }
    } else if (id === "office") {
      if (newValue !== "") {
        let office = newValue;
        let officeRegex =
          /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/;
        let res = officeRegex.test(office);

        if (res !== true) {
          let swissRegex =
            /^(\+?)(\d{2,4})(\s?)(\-?)((\(0\))?)(\s?)(\d{2})(\s?)(\-?)(\d{3})(\s?)(\-?)(\d{2})(\s?)(\-?)(\d{2})/;
          let swissres = swissRegex.test(office);
          if (swissres !== true) {
            this.setState({
              officeValidation: "Please enter valid office number",
            });
          } else {
            this.setState({
              officeValidation: "",
            });
          }
        } else {
          this.setState({
            officeValidation: "",
          });
        }
      } else {
        this.setState({
          officeValidation: "",
        });
      }
    } else if (id === "Phone") {
      let phone = newValue;
      let phoneRegex =
        /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/;
      let res = phoneRegex.test(phone);
      if (res !== true) {
        let swissRegex =
          /^(\+?)(\d{2,4})(\s?)(\-?)((\(0\))?)(\s?)(\d{2})(\s?)(\-?)(\d{3})(\s?)(\-?)(\d{2})(\s?)(\-?)(\d{2})/;
        let swissres = swissRegex.test(phone);
        if (swissres !== true) {
          this.setState({
            phoneValidation: "Please enter valid phone number",
          });
        } else {
          this.setState({
            phoneValidation: "",
          });
        }
      } else {
        this.setState({
          phoneValidation: "",
        });
      }
    } else if (id === "emailForgotPassword") {
      let emailForgotPassword = newValue;
      let emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let res = emailRegex.test(emailForgotPassword);
      if (emailForgotPassword === "") {
        this.setState({
          emailForgotPasswordValidation: "Email should not be empty*",
        });
      } else if (res !== true) {
        this.setState({
          emailForgotPasswordValidation: "Please enter valid email ",
        });
      } else {
        this.setState({
          emailForgotPasswordValidation: "",
        });
      }
    }

    if (id === "name") this.setState({ name: newValue });
    else if (id === "title") this.setState({ title: newValue });
    else if (id === "firstName") this.setState({ firstName: newValue });
    else if (id === "middleName")
      this.setState({ middleName: newValue.trim() });
    else if (id === "lastName") this.setState({ lastName: newValue });
    else if (id === "address") this.setState({ address: newValue });
    else if (id === "company") {
      if (newValue.value === "000") {
        this.setState({
          company: this.state.company,
          companyId: this.state.companyId,
          selectedCompanyOption: {
            label: this.state.company,
            value: this.state.companyId,
          },
        });
        document.getElementById("addCompany-btn").click();
      } else {
        this.setState({
          company: newValue.label,
          companyId: newValue.value,
          selectedCompanyOption: newValue,
        });
      }
    } else if (id === "startupCompany") {
      if (newValue.value !== "000") {
        let startupCompanyTags = [
          ...new Set(
            [
              ...this.state.startupCompanyTags,
              { id: newValue.value, name: newValue.label },
            ].map((o) => JSON.stringify(o))
          ),
        ].map((s) => JSON.parse(s));
        this.setState({
          startupCompany: newValue.label,
          startupCompanyId: newValue.value,
          startupCompanyTags,
        });
      }
    } else if (id === "category") {
      let selectedCategoryTags = [
        ...new Set(
          [
            ...this.state.selectedCategoryTags,
            { id: newValue.value, name: newValue.label },
          ].map((o) => JSON.stringify(o))
        ),
      ].map((s) => JSON.parse(s));
      this.setState({ selectedCategoryTags });
    } else if (id === "email") this.setState({ emailAddress: newValue });
    else if (id === "mobile") this.setState({ mobile: newValue });
    else if (id === "office") this.setState({ office: newValue });
    else if (id === "invite-type") {
      this.setState({ type: newValue.value, selectedInviteOption: newValue });
      if (newValue.value === "001" || newValue.value === "002") {
        this.handleChange("company", {
          label: "The Syndicate Group",
          value: "5d6e78506f101b18789aeb44",
        });
      } else {
        if (newValue !== "005") {
          this.setState({
            company: "",
            companyId: null,
            selectedCompanyOption: null,
          });
        }
      }
    } else if (id === "Company") {
      this.setState({ Company: newValue });
    } else if (id === "CompanyNickName") {
      this.setState({ CompanyNickName: newValue });
    } else if (id === "Address1") this.setState({ address1: newValue });
    else if (id === "Address2") this.setState({ address2: newValue });
    else if (id === "City") this.setState({ city: newValue });
    else if (id === "State1") this.setState({ state1: newValue });
    else if (id === "ZipCode") this.setState({ zipcode: newValue });
    else if (id === "acquiringCompanyName")
      this.setState({ acquiringCompanyName: newValue });
    else if (id === "Website") this.setState({ website: newValue });
    else if (id === "Phone") this.setState({ phone: newValue });
    else if (
      id === "investorCompanyName" &&
      this.state.type &&
      this.state.type === "005"
    ) {
      this.setState({ investorCompanyName: newValue });
    } else if (id === "notes") {
      this.setState({ notes: newValue });
    } else if (id === "prospectStartup") {
      this.setState({ isProspectStartup: newValue });
    } else if (id === "exitNotes") {
      this.setState({ exitNotes: newValue });
    }

    // else if (id === 'totalNetInvestment') {
    //     this.setState({ totalNetInvestment: newValue })
    // } else if (id === 'totalGrossGain') {
    //     this.setState({ totalGrossGain: newValue })
    // }

    if (newValue.value === "005") {
      this.setState({
        investor: "",
        investorId: null,
        selectedInvestorOption: null,
        title: "",
        investorCompanyName: "",
        emailAddress: "",
        startupCompanyTags: [],
        notes: "",
        investorReferralList: [],
      });
    }

    setTimeout(() => {
      this.checkInvitaionFormValidity();
    }, 100);
  }

  sendEmailInvitation(tokenpass, id) {
    axios
      .put(
        `${common.path.base_url}/resendInvite`,
        { id },
        {
          headers: {
            Authorization: tokenpass,
          },
        }
      )
      .then((response) => { })
      .catch((error) => { });
  }

  mapCompaniesArray() {
    const companies = [...this.state.startupCompanyTags];
    if (!companies) return [];
    const companiesIds = companies.map((company) => company.id);
    return companiesIds;
  }
  mapReferralArray() {
    const referrals = [...this.state.investorReferralTags];
    if (!referrals) return [];
    return referrals.map((referral) => referral.id);
  }
  mapCategoryArray() {
    const categories = [...this.state.selectedCategoryTags];
    if (!categories) return [];
    return categories.map((categorie) => categorie.id);
  }
  sendInvitation(isAllowDuplicateName) {
    let tokenpass = window.localStorage.getItem("token");
    const self = this;
    this.setState({ loadinglada: true });
    let mobile = this.state.mobile.replace(/[- )(]/g, "");
    let office = this.state.office.replace(/[- )(]/g, "");
    let newType = "";
    if (
      this.state.type === "" &&
      (this.state.roleCode === "003" || this.state.roleCode === "004")
    ) {
      newType = this.state.roles[0].roleCode;
    }
    axios
      .post(
        `${common.path.base_url}/inviteMember`,
        {
          inviteRoleId: this.state.type || newType,
          // name: this.state.type === '005' ? this.state.name || this.state.investorName : this.state.firstName + ' ' + this.state.lastName,
          // name: this.state.firstName + ' ' + this.state.lastName,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          middleName: this.state.middleName,
          title: this.state.title,
          address: this.state.type === "005" ? this.state.address : "",
          notes: this.state.notes,
          companyId: this.state.companyId,
          emailAddress: this.state.emailAddress,
          // mobile: this.state.mobile,
          mobile: mobile,
          // office: this.state.office,
          office: office,
          companies: this.mapCompaniesArray(),
          referrals: [],
          investorCategories: this.mapCategoryArray(),
          investorCompanyName: this.state.investorCompanyName,
          isAllowDuplicateName: isAllowDuplicateName ? true : false,
          referralInvestmentId: this.state.selectedInvestmentOption.value,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: tokenpass,
          },
        }
      )
      .then((response) => {
        this.setState({ loadinglada: false });
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.isDuplicateName
        ) {
          swal({
            title: "Are you sure?",
            text: `Contact with name ${self.state.firstName} ${self.state.lastName} already exists`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDuplicate) => {
            if (willDuplicate) {
              self.sendInvitation(true);
            } else {
              // swal({ text: `This contact is safe!`, icon: '' });
            }
          });
        } else {
          if (
            (self.state.roleCode == "003" || self.state.roleCode == "004") &&
            self.state.type !== "005"
          ) {
            if (
              response &&
              response.data &&
              response.data.data &&
              response.data.data.id
            ) {
              this.sendEmailInvitation(tokenpass, response.data.data.id);
            }
          }
          if (typeof this.props.addInviteSuccess == "function") {
            this.props.addInviteSuccess();
          }
          swal({
            title: "Contact Added!",
            text: response.data.message,
            icon: "success",
            button: "Ok!",
          }).then(function () {
            self.resetInviteData();
            document.getElementById("cancel").click();
          });
        }
        // send invitation for team memeber added case
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response.data.code === 204) {
          window.localStorage.clear();
          window.location.href = "/";
        } else if (error.response) {
          this.setState({ loadinglada: false });
          swal({
            title: "Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : "Something Went Wrong",
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Error! #48754852",
            text: error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }

  addCompany() {
    let temp = this;
    const fulladdress = this.state.address1.concat(
      "\\$#",
      this.state.address2,
      "\\$#",
      this.state.city,
      "\\$#",
      this.state.state1,
      "\\$#",
      this.state.zipcode
    );
    this.setState({ loadinglada2: true });
    let mobile = this.state.phone.replace(/[- )(]/g, "");
    axios
      .post(`${common.path.base_url}/addCompany`, {
        companyName: this.state.Company,
        companyNickName: this.state.CompanyNickName,
        address: fulladdress,
        // mobile: this.state.phone,
        mobile: mobile,
        website: this.state.website,
        isProspectStartup: window.location.href.includes("pipeline-startups")
          ? true
          : this.state.isProspectStartup
            ? true
            : false,
      })
      .then(function (response) {
        // alert(response.data.message);
        const { data } = response.data;
        const companyObj = { label: data.companyName, value: data._id };
        temp.handleChange("company", companyObj);
        temp.setState({ selectedCompanyOption: companyObj });
        if (typeof temp.props.onAddCompanySuccessCallback === "function") {
          temp.props.onAddCompanySuccessCallback();
        }
        swal({
          // title: "Sent!",
          text: response.data.message,
          icon: "success",
          button: "Ok!",
        }).then(function () {
          temp.resetAddCompanyData();
          document.getElementById("company-cancel").click();
        });
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response.data.code === 204) {
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          if (error.response) {
            swal({
              title: "Error!",
              text:
                error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : "Something Went Wrong",
              icon: "error",
              button: "Ok!",
            });
          } else {
            swal({
              title: "Error! #48754852",
              text: error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        }
        temp.resetAddCompanyData();
        temp.setState({ loadinglada2: false });
      });
  }

  updateCompany() {
    let temp = this;
    const fulladdress = this.state.address1.concat(
      "\\$#",
      this.state.address2,
      "\\$#",
      this.state.city,
      "\\$#",
      this.state.state1,
      "\\$#",
      this.state.zipcode
    );
    this.setState({ loadinglada2: true });
    let mobile = this.state.phone
      ? this.state.phone.replace(/[- )(]/g, "")
      : "+1";
    // if (data.stage === 'exited' || data.stage === 'exit in process') {
    //     if (this.state.selectedExitProcess && this.state.selectedExitProcess.value) {
    //         data.exitProcessType = this.state.selectedExitProcess.value;
    //     } else {
    //         data.exitProcessType = 'ipo';
    //     }
    //     if (data.exitProcessType === 'ipo') {
    //         data.lockupDate = this.state.lockupDate;
    //     } else {
    //         data.disbursementDate = this.state.disbursementDate;
    //     }
    //     data.acquiringCompanyName = this.state.acquiringCompanyName;
    // }
    console.log("sAAs", this.state.selectedStages);
    axios
      .put(`${common.path.base_url}/updateCompany`, {
        companyName: this.state.Company,
        companyNickName: this.state.CompanyNickName,
        address: fulladdress,
        // mobile: this.state.phone,
        mobile: mobile,
        website: this.state.website,
        isProspectStartup: this.state.isProspectStartup,
        companyId: this.state.editcompanyId,
        stage: this.state.selectedStages.value,
        exitProcessType: this.state.selectedExitProcess.value,
        lockupDate: this.state.lockupDate,
        disbursementDate: this.state.disbursementDate,
        acquiringCompanyName: this.state.acquiringCompanyName,
        exitNotes: this.state.exitNotes,
        // totalGrossGain: this.state.totalGrossGain,
        // totalNetInvestment: this.state.totalNetInvestment
      })
      .then(function (response) {
        // alert(response.data.message);
        const { data } = response.data;
        if (typeof temp.props.onAddCompanySuccessCallback === "function") {
          temp.props.onAddCompanySuccessCallback();
        }

        swal({
          // title: "Sent!",
          text: response.data.message,
          icon: "success",
          button: "Ok!",
        }).then(function () {
          temp.resetAddCompanyData();
          document.getElementById("company-cancel").click();
        });
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response.data.code === 204) {
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          if (error.response) {
            swal({
              title: "Error!",
              text:
                error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : "Something Went Wrong",
              icon: "error",
              button: "Ok!",
            });
          } else {
            swal({
              title: "Error! #48754852",
              text: error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        }
        temp.resetAddCompanyData();
        temp.setState({ loadinglada2: false });
      });
  }

  companySearch = _.debounce((value) => {
    this.setState({
      Company: value,
    });
    let temp = this;
    axios
      .post(`${common.path.base_url}/searchCompany`, {
        query: value,
      })
      .then(function (response) {
        if (
          response.data &&
          response.data.message &&
          common.AUTH_EXPIRED === response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (response.data.code === 204) {
          window.localStorage.clear();
          this.props.history.push("/");
        }
        const companyList = response.data.data.map((opt) => ({
          label: opt.companyName,
          value: opt._id,
        }));

        temp.setState({
          list: [...companyList, { label: "Add New Company", value: "000" }],
        });
      })
      .catch(function (error) {
        if (error.response.data.code === 204) {
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          if (error.response) {
            swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              button: "Ok!",
            });
          } else {
            swal({
              title: "Network Error!",
              text: error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        }
      });
  }, 800);

  startupCompanySearch = _.debounce((value) => {
    this.setState({
      startupCompany: value,
    });
    let temp = this;
    axios
      .post(`${common.path.base_url}/searchCompany`, {
        query: value,
      })
      .then(function (response) {
        if (
          response.data &&
          response.data.message &&
          common.AUTH_EXPIRED === response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (response.data.code === 204) {
          window.localStorage.clear();
          this.props.history.push("/");
        }
        const companyList = response.data.data.map((opt) => ({
          label: opt.companyName,
          value: opt._id,
        }));
        if (companyList.length > 0) {
          temp.setState({ startupCompaniesList: [...companyList] });
        } else {
          temp.setState({
            startupCompaniesList: [{ label: "Not Found", value: "000" }],
          });
        }
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.code === 204
        ) {
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          if (error.response) {
            swal({
              title: "Error!",
              text:
                error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : error.response,
              icon: "error",
              button: "Ok!",
            });
          } else {
            swal({
              title: "Network Error!",
              text: error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        }
      });
  }, 800);

  startupInvestmentSearch = _.debounce((value) => {
    this.setState({
      startupCompany: value,
    });
    let temp = this;
    axios
      .post(`${common.path.base_url}/searchInvestment`, {
        query: value,
      })
      .then(function (response) {
        if (
          response.data &&
          response.data.message &&
          common.AUTH_EXPIRED === response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (response.data.code === 204) {
          window.localStorage.clear();
          this.props.history.push("/");
        }
        const investmentList = response.data.data.map((opt) => ({
          label: opt.name,
          value: opt._id,
        }));
        if (investmentList.length > 0) {
          temp.setState({ startupInvestmentsList: [...investmentList] });
        } else {
          temp.setState({
            startupInvestmentsList: [{ label: "Not Found", value: "000" }],
          });
        }
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.code === 204
        ) {
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          if (error.response) {
            swal({
              title: "Error!",
              text:
                error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : error.response,
              icon: "error",
              button: "Ok!",
            });
          } else {
            swal({
              title: "Network Error!",
              text: error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        }
      });
  }, 800);

  formValidaton(id, val) {
    if (id === "name") {
      let name = document.getElementById("name").value;
      if (name === "") {
        this.setState({
          nameValidation: "Name should not be empty*",
        });
      } else {
        this.setState({
          nameValidation: "",
        });
      }
    } else if (id === "firstName") {
      // let name = document.getElementById('firstName').value;
      let name = val;
      if (name === "") {
        this.setState({
          firstNameValidation: "First Name (Legal) should not be empty*",
        });
      } else {
        this.setState({
          firstNameValidation: "",
        });
      }
    } else if (id === "lastName") {
      // let name = document.getElementById('lastName').value;
      let name = val;
      if (name === "") {
        this.setState({
          lastNameValidation: "Last Name should not be empty*",
        });
      } else {
        this.setState({
          lastNameValidation: "",
        });
      }
    } else if (id === "title") {
      let title = document.getElementById("title").value;
      if (title === "") {
        this.setState({
          titleValidation: "Title should not be empty*",
        });
      } else {
        this.setState({
          titleValidation: "",
        });
      }
    } else if (id === "company") {
      let company = document.getElementById("company");
      if (company.options[company.selectedIndex].value === "") {
        this.setState({
          companyValidation: "Company should not be empty*",
        });
      } else {
        this.setState({
          companyValidation: "",
        });
      }
    } else if (id === "email") {
      let email = document.getElementById("email").value;
      let emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let res = emailRegex.test(email);
      if (email === "") {
        this.setState({
          emailValidation: "Email should not be empty*",
        });
      } else if (res !== true) {
        this.setState({
          emailValidation: "Please enter valid email*",
        });
      } else {
        this.setState({
          emailValidation: "",
        });
      }
    } else if (id === "Company") {
      let Company = document.getElementById("Company").value;
      if (Company === "") {
        this.setState({
          CompanyValidation: "Company name should not be empty*",
        });
      } else {
        this.setState({
          CompanyValidation: "",
        });
      }
    } else if (id === "Address1") {
      let address = document.getElementById("Address1").value;
      if (address === "") {
        this.setState({
          addresValidation1: "Address1 should not be empty*",
        });
      } else {
        this.setState({
          addresValidation1: "",
        });
      }
    } else if (id === "City") {
      let address = document.getElementById("City").value;
      if (address === "") {
        this.setState({
          CityValidation: "City should not be empty*",
        });
      } else {
        this.setState({
          CityValidation: "",
        });
      }
    } else if (id === "State1") {
      let address = document.getElementById("State1").value;
      if (address === "") {
        this.setState({
          StateValidation: "State should not be empty*",
        });
      } else {
        this.setState({
          StateValidation: "",
        });
      }
    } else if (id === "ZipCode") {
      let address = document.getElementById("ZipCode").value;
      if (address === "") {
        this.setState({
          ZipCodeValidation: "Zip Code should not be empty*",
        });
      } else {
        this.setState({
          ZipCodeValidation: "",
        });
      }
    } else if (id === "Website") {
      let website = document.getElementById("Website").value;
      if (website !== "") {
        let websiteRegex =
          /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
        let reg = websiteRegex.test(website);
        if (website === "") {
          this.setState({
            websiteValidation: "Website should not be empty*",
          });
        } else if (reg !== true) {
          this.setState({
            websiteValidation: "Please enter valid Website*",
          });
        } else {
          this.setState({
            websiteValidation: "",
          });
        }
      } else {
        this.setState({
          websiteValidation: "",
        });
      }
    } else if (id === "mobile") {
      if (this.state.mobile !== "") {
        let mobile = this.state.mobile;
        let mobileRegex =
          /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/;
        let res = mobileRegex.test(mobile);
        if (res !== true) {
          let swissRegex =
            /^(\+?)(\d{2,4})(\s?)(\-?)((\(0\))?)(\s?)(\d{2})(\s?)(\-?)(\d{3})(\s?)(\-?)(\d{2})(\s?)(\-?)(\d{2})/;
          let swissres = swissRegex.test(mobile);
          if (swissres !== true) {
            this.setState({
              mobileValidation: "Please enter valid mobile number",
            });
          } else {
            this.setState({
              mobileValidation: "",
            });
          }
        } else {
          this.setState({
            mobileValidation: "",
          });
        }
      } else {
        this.setState({
          mobileValidation: "",
        });
      }
    } else if (id === "office") {
      if (this.state.office !== "") {
        let office = this.state.office;
        let officeRegex =
          /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/;
        let res = officeRegex.test(office);

        if (res !== true) {
          let swissRegex =
            /^(\+?)(\d{2,4})(\s?)(\-?)((\(0\))?)(\s?)(\d{2})(\s?)(\-?)(\d{3})(\s?)(\-?)(\d{2})(\s?)(\-?)(\d{2})/;
          let swissres = swissRegex.test(office);
          if (swissres !== true) {
            this.setState({
              officeValidation: "Please enter valid office number",
            });
          } else {
            this.setState({
              officeValidation: "",
            });
          }
        } else {
          this.setState({
            officeValidation: "",
          });
        }
      } else {
        this.setState({
          officeValidation: "",
        });
      }
    } else if (id === "Phone") {
      let phone = this.state.phone;
      let phoneRegex =
        /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/;
      let res = phoneRegex.test(phone);
      if (res !== true) {
        let swissRegex =
          /^(\+?)(\d{2,4})(\s?)(\-?)((\(0\))?)(\s?)(\d{2})(\s?)(\-?)(\d{3})(\s?)(\-?)(\d{2})(\s?)(\-?)(\d{2})/;
        let swissres = swissRegex.test(phone);
        if (swissres !== true) {
          this.setState({
            phoneValidation: "Please enter valid phone number",
          });
        } else {
          this.setState({
            phoneValidation: "",
          });
        }
      } else {
        this.setState({
          phoneValidation: "",
        });
      }
    } else if (id === "emailForgotPassword") {
      let emailForgotPassword = document.getElementById(
        "emailForgotPassword"
      ).value;
      let emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let res = emailRegex.test(emailForgotPassword);
      if (emailForgotPassword === "") {
        this.setState({
          emailForgotPasswordValidation: "Email should not be empty*",
        });
      } else if (res !== true) {
        this.setState({
          emailForgotPasswordValidation: "Please enter valid email ",
        });
      } else {
        this.setState({
          emailForgotPasswordValidation: "",
        });
      }
    }
  }

  isEmail(email) {
    let emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let res = emailRegex.test(email);
    this.setState({ emailValidated: res });
  }

  checkInvitaionFormValidity() {
    const roleCode = this.state.roleCode;
    if (roleCode === "001" || roleCode === "002") {
      if (
        (this.state.type === "001" ||
          this.state.type === "002" ||
          this.state.type === "003" ||
          this.state.type === "004") &&
        !_.isEmpty(
          this.state.type &&
          this.state.firstName &&
          this.state.lastName &&
          this.state.company &&
          this.state.title
        )
      ) {
        this.setState({ validInvitaionForm: true });
      } else if (
        this.state.type === "005" &&
        !_.isEmpty(
          this.state.type && this.state.firstName && this.state.lastName
        )
      ) {
        this.setState({ validInvitaionForm: true });
      } else {
        this.setState({ validInvitaionForm: false });
      }
      // if ((this.state.type === '003' || this.state.type === '004' || this.state.type === '005') && (!_.isEmpty(this.state.type && this.state.emailAddress)) && (this.state.emailValidation === '')) {
      //     if (this.state.type === '005') {
      //         // if (this.state.startupCompanyTags && this.state.startupCompanyTags.length > 0) {
      //         if (this.state.isAddNewInvestor) {
      //             if (this.state.investorName && this.state.lastName) {
      //                 this.setState({ validInvitaionForm: true });
      //             } else {
      //                 this.setState({ validInvitaionForm: false })
      //             }
      //         } else {
      //             this.setState({ validInvitaionForm: true })
      //         }
      //         // } else {
      //         //     this.setState({ validInvitaionForm: false })
      //         // }
      //     } else {
      //         if (this.state.firstName && this.state.lastName && this.state.company && this.state.title) {
      //             this.setState({ validInvitaionForm: true })
      //         } else {
      //             this.setState({ validInvitaionForm: false })
      //         }
      //     }

      // }
      // else {
      //     this.setState({ validInvitaionForm: false })
      // }
    } else if (roleCode === "003" || roleCode === "004") {
      if (
        !_.isEmpty(
          this.state.firstName &&
          this.state.lastName &&
          this.state.title &&
          this.state.emailAddress
        ) &&
        this.state.emailValidation === ""
      ) {
        this.setState({ validInvitaionForm: true });
      } else {
        this.setState({ validInvitaionForm: false });
      }
    } else if (roleCode === "004") {
      if (
        !_.isEmpty(
          this.state.firstName &&
          this.state.lastName &&
          this.state.title &&
          this.state.type &&
          this.state.emailAddress
        ) &&
        this.state.emailValidation === ""
      ) {
        this.setState({ validInvitaionForm: true });
      } else {
        this.setState({ validInvitaionForm: false });
      }
    }
  }
  removeCompanyTag(tag) {
    if (tag.id) {
      this.setState({
        startupCompanyTags: this.state.startupCompanyTags.filter(
          (company) => company.id !== tag.id
        ),
      });
    }
  }
  removeCategoryTag(tag) {
    if (tag.id) {
      this.setState({
        selectedCategoryTags: this.state.selectedCategoryTags.filter(
          (company) => company.id !== tag.id
        ),
      });
    }
  }
  removeReferralTag(tag) {
    if (tag.id) {
      this.setState({
        investorReferralTags: this.state.investorReferralTags.filter(
          (user) => user.id !== tag.id
        ),
      });
    }
  }

  investorSearch = _.debounce((value) => {
    this.setState({
      investor: value,
    });
    let temp = this;
    axios
      .post(`${common.path.base_url}/searchInvestor`, {
        query: value,
      })
      .then(function (response) {
        const investorList = response.data.data.map((opt) => ({
          label: opt.name,
          value: opt._id,
          user: opt,
        }));
        if (investorList && investorList.length > 0) {
          temp.setState({ investorList: [...investorList] });
        } else {
          temp.setState({
            investorList: [
              { label: "Add New Investor", value: "000", investorName: value },
            ],
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text: error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }, 800);
  referralSearch = _.debounce((value) => {
    this.setState({
      investor: value,
    });
    let temp = this;
    axios
      .post(`${common.path.base_url}/searchReferral`, {
        query: value,
      })
      .then(function (response) {
        const investorReferralList = response.data.data.map((opt) => ({
          label: `${opt.firstName} ${opt.lastName}`,
          value: opt._id,
          user: opt,
        }));
        temp.setState({ investorReferralList });
      })
      .catch(function (error) {
        if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Error! #485SLK435",
            text: error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }, 800);

  handleReferralChange(obj) {
    this.setState({ selectedInvestmentOption: obj });
    // let investorReferralTags = [...new Set([...this.state.investorReferralTags, { id: obj.value, name: obj.label }].map(o => JSON.stringify(o)))].map(s => JSON.parse(s));
    // this.setState({ investorReferralTags, selectedReferralOption:obj.label });
  }

  getCompanyById(element, type, invesment, callback) {
    //console.log("asdsadsadasdasdasd",invesment.totalGrossGain, );
    let temp = this;
    let tokenpass = window.localStorage.getItem("token");
    axios
      .get(`${common.path.base_url}/getCompanyDetail?companyId=${element}`, {
        headers: {
          Authorization: tokenpass,
        },
      })
      .then(function (response) {
        const companyData = response.data.data[0];
        //console.log("asdasdasd", response.data.data);
        if (companyData) {
          let addressArray = companyData.address
            ? companyData.address.split(",")
            : "";
          let zipcode =
            addressArray.length > 0
              ? addressArray[3] && addressArray[3].split(" ")
              : "";
          temp.setState({
            companyId: companyData._id,
            editcompanyId: element,
            isCompanyEdit: type ? type : true,
            Company: companyData.legalName,
            CompanyNickName: companyData.companyNickName,
            address1: addressArray && addressArray[0] && addressArray[0],
            address2: addressArray && addressArray[1] && addressArray[1],
            city: addressArray && addressArray[2] && addressArray[2],
            state1: zipcode && zipcode[1] && zipcode[1],
            zipcode: zipcode && zipcode[2] && zipcode[2],
            phone: companyData.mobile,
            website: companyData.website,
            isProspectStartup: companyData.isProspectStartup,
            acquiringCompanyName: companyData.acquiringCompanyName,
            selectedStages: companyData.stage
              ? {
                value: companyData.stage,
                label: companyData.stage.toUpperCase(),
              }
              : "",
            selectedExitProcess: companyData.exitProcessType
              ? {
                value: companyData.exitProcessType,
                label:
                  companyData.exitProcessType === "acquisitionwithescrow"
                    ? "Acquisition with Escrow".toUpperCase()
                    : companyData.exitProcessType.toUpperCase(),
              }
              : "",
            lockupDate: companyData.lockupDate
              ? new Date(companyData.lockupDate)
              : null,
            disbursementDate: companyData.disbursementDate
              ? new Date(companyData.disbursementDate)
              : null,
            isPipelineStartupEdit: type,
            exitNotes: companyData.exitNotes,
            totalNetInvestment:
              invesment != null ? invesment.totalNetInvestment : "",
            totalGrossGain: invesment != null ? invesment.totalGrossGain : "",
          });

          temp.investmentGainModalCall("yes");
        }
        temp.formValidaton();
        callback();
      })
      .catch(function (error) { });
  }
  validate() {
    const isPipeLineStartup =
      window.location.href.includes("pipeline-startups");
    if (isPipeLineStartup) {
      if (!this.state.website || !this.state.Company) {
        return "disable";
      }
      return "";
    } else {
      return !_.isEmpty(
        this.state.Company &&
        this.state.address1 &&
        this.state.zipcode &&
        this.state.city &&
        this.state.state1
      )
        ? ""
        : "disable";
    }
  }

  selectStage(selectedStages) {
    this.setState({ selectedStages });
  }

  changeExitProcess(selectedExitProcess) {
    this.setState({ selectedExitProcess });
  }

  handleLockupDateChange(date) {
    this.setState({
      lockupDate: date,
    });
  }
  handleDisbursementDateChange(date) {
    this.setState({
      disbursementDate: date,
    });
  }
  checkValidation() {
    if (
      !this.state.Company ||
      !this.state.address1 ||
      !this.state.zipcode ||
      !this.state.city ||
      !this.state.state1
    ) {
      return "disable";
      // } else if (this.state.selectedStages && this.state.selectedStages.value === 'exited' || this.state.selectedStages.value === 'exit in process' && !this.state.acquiringCompanyName) {
      //     return 'disable';
    } else if (
      this.state.selectedExitProcess &&
      this.state.selectedExitProcess.value === "ipo" &&
      !this.state.lockupDate
    ) {
      return "disable";
    } else if (
      this.state.selectedExitProcess &&
      (this.state.selectedExitProcess.value === "acquisition" ||
        this.state.selectedExitProcess.value === "acquisitionwithescrow") &&
      !this.state.disbursementDate
    ) {
      return "disable";
    }
  }
  checkPipelineValidation() {
    if (!this.state.Company || !this.state.website) {
      return "disable";
    }
  }

  investmentGainModalCall(direct = "") {
    let temp = this;
    const companyId = this.state.companyId;
    if (direct == "") {
      document.getElementById("investmentGainModal-btn").click();
      temp.setState({ gainLoader: true });
    }
    let tokenpass = window.localStorage.getItem("token");
    axios
      .get(`${common.path.base_url}/getCompanyInvestmentDetail/${companyId}`, {
        headers: {
          Authorization: tokenpass,
        },
      })
      .then(function (response) {
        const companyData = response.data;
        //console.log(companyData.data)
        if (companyData.status === "success") {
          const investmentData = companyData.data;
          const allData = [];

          investmentData.map((data) => {
            const dataFetch = {
              _id: data._id,
              name: data.name,
              invesmtentAmt: data.totalNetInvestment
                ? data.totalNetInvestment
                : 0,
              // "gainAmt" : (data.totalGrossGain) ? data.totalGrossGain : 0,
              disbursement1: data.disbursement1 ? data.disbursement1 : 0,
              disbursement2: data.disbursement2 ? data.disbursement2 : 0,
            };
            allData.push(dataFetch);
          });

          temp.setState({ allInvestment: allData, gainLoader: false });
        }
      })
      .catch(function (error) { });
  }

  handleInvementChange(key, index, value) {
    if (key == "invesmtentAmt") {
      let allInvestment = [...this.state.allInvestment];
      allInvestment[index] = { ...allInvestment[index], invesmtentAmt: value };
      this.setState({ allInvestment });
    }

    if (key == "disbursement1") {
      let allInvestment = [...this.state.allInvestment];
      allInvestment[index] = { ...allInvestment[index], disbursement1: value };
      this.setState({ allInvestment });
    }
    if (key == "disbursement2") {
      let allInvestment = [...this.state.allInvestment];
      allInvestment[index] = { ...allInvestment[index], disbursement2: value };
      this.setState({ allInvestment });
    }
  }

  updateCompanyInvestment() {
    let temp = this;
    if (
      temp.state.allInvestment[0].disbursement1 !== "0" &&
      temp.state.allInvestment[0].disbursement2 !== "0"
    ) {
      const companyId = temp.state.companyId;
      const data = {
        companyId: companyId,
        investmentData: temp.state.allInvestment,
      };
      let tokenpass = window.localStorage.getItem("token");
      axios
        .post(`${common.path.base_url}/addCompanyInvestmentDetail`, data, {
          headers: {
            Authorization: tokenpass,
          },
        })
        .then(function (response) {
          const companyData = response.data;

          swal({
            text: "Investment & Gain have saved successfully",
            icon: "success",
            button: "Ok!",
          }).then(function () {
            document.getElementById("investment-cancel").click();
          });
        })
        .catch(function (error) { });
    } else {
      swal({
        text: "Please check Again",
        icon: "failed",
        button: "Ok!",
      });
    }
  }

  render() {
    return (
      <div>
        <TButton
          onClick={() => { }}
          id="investmentGainModal-btn"
          dataTarget={`#investmentGainModal`}
          dataToggle={`modal`}
          style={{ display: "none" }}
        />

        {/* <!-- Invite Modal HTML --> */}
        <div id="inviteModal" className="modal fade">
          <div className="modal-dialog " key={this.state.key}>
            <div className="modal-content invite-contact">
              <form>
                <div className="modal-header">
                  <h4 className="modal-title">Add Contact</h4>
                </div>
                <div className="modal-body">
                  <form className="form-horizontal" id=" myform">
                    <div className="form-group">
                      {/* Invite Type */}
                      <label className="col-lg-4 col-sm-3 col-md-3 control-label">
                        Contact Type <RequiredSign /> :
                      </label>
                      <div className="col-lg-8 col-sm-8 col-md-8">
                        {this.state.preSelected != -1 &&
                          this.state.preSelected < 5 &&
                          (this.state.roleCode === "001" ||
                            this.state.roleCode === "002") ? (
                          <input
                            id="invite-type"
                            className="form-control"
                            disabled
                            value={
                              this.state.roles.length &&
                              this.state.roles[this.state.preSelected].roleName
                            }
                          />
                        ) : this.state.roleCode === "003" ||
                          this.state.roleCode === "004" ? (
                          this.state.roleCode === "003" ? (
                            <Select
                              id="invite-type"
                              defaultValue={this.state.selectedInviteOption}
                              options={this.state.roles.map((role) => {
                                return {
                                  label: role.roleName,
                                  value: role.roleCode,
                                };
                              })}
                              onChange={(opt, action) => {
                                if (action && action.action === "clear") {
                                  this.setState({
                                    selectedInviteOption: null,
                                    validInvestmentForm: false,
                                  });
                                }
                                if (opt !== null && opt !== undefined) {
                                  this.handleChange("invite-type", opt);
                                }
                              }}
                              value={this.state.selectedInviteOption}
                            />
                          ) : (
                            <input
                              id="invite-type"
                              className="form-control"
                              disabled
                              value={
                                this.state.roles.length &&
                                this.state.roles[0].roleName
                              }
                            />
                          )
                        ) : (
                          <Select
                            id="invite-type"
                            defaultValue={this.state.selectedInviteOption}
                            options={this.state.roles.map((role) => {
                              return {
                                label: role.roleName,
                                value: role.roleCode,
                              };
                            })}
                            onChange={(opt, action) => {
                              if (action && action.action === "clear") {
                                this.setState({
                                  selectedInviteOption: null,
                                  validInvestmentForm: false,
                                });
                              }
                              if (opt !== null && opt !== undefined) {
                                this.handleChange("invite-type", opt);
                              }
                            }}
                            value={this.state.selectedInviteOption}
                          />
                          // <select id="company"
                          //     onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                          //     id="invite-type" className="mdb-select md-form"
                          // >
                          /* <option value="" disabled selected>Select</option>
                                                            {this.state.roles.map((element, index) => {
                                                                return <option value={element.roleCode} >{element.roleName}</option>
                                                            })} */

                          // </select>
                        )}
                      </div>
                    </div>

                    {/* {this.state.type && this.state.type === '005' ? null : <div>
                                        <div className="form-group">
                                            <label className="col-lg-4 col-sm-3  col-md-3 control-label">First Name <RequiredSign /> :</label>
                                            <div className="col-lg-8 col-sm-8 col-md-8">
                                                <input className="form-control" id="firstName" type="text"
                                                    onKeyUp={(event) => this.formValidaton(event.target.id)}
                                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                    placeholder='First Name'
                                                    value={this.state.firstName} />
                                                <p>{this.state.firstNameValidation}</p>
                                            </div>
                                        </div> 
                                        
                                        <div className="form-group">
                                            <label className="col-lg-4 col-sm-3  col-md-3 control-label">Last Name <RequiredSign /> :</label>
                                            <div className="col-lg-8 col-sm-8 col-md-8">
                                                <input className="form-control" id="lastName" type="text"
                                                    onKeyUp={(event) => this.formValidaton(event.target.id)}
                                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                    placeholder='Last Name'
                                                    value={this.state.lastName} />
                                                <p>{this.state.lastNameValidation}</p>
                                            </div>
                                        </div> </div>} */}

                    {/* {this.state.type && this.state.type === '005' ? <div className="form-group">
                                            <label className="col-lg-4 col-sm-3  col-md-3 control-label">Full Name <RequiredSign /> :</label>
                                            <div className="col-lg-8 col-sm-8 col-md-8">
                                                    <Select id="investor"
                                                    options={this.state.investorList}
                                                    onChange={(opt, action) => {
                                                        if (action && action.action === 'clear') {
                                                            this.setState({ selectedInvestorOption: null, investmentName: '', validInvestmentForm: false });
                                                        }
                                                        if (opt !== null && opt !== undefined) {
                                                            this.handleInvestorChange("investor", opt);
                                                        }
                                                    }}
                                                    onInputChange={(searchKey) => {
                                                        if (!_.isEmpty(searchKey)) {
                                                            this.investorSearch(searchKey);
                                                        }
                                                    }}
                                                    value={this.state.selectedInvestorOption}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    filterOption={(value1, value2) => {
                                                        return value2;
                                                    }}
                                                    deleteRemoves={true}
                                                    noOptionsMessage={() => `Type Something`}
                                                />
                                            </div>
                                        </div> : null } */}

                    <div className="form-group">
                      <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                        First Name (Legal) <RequiredSign /> :
                      </label>
                      <div className="col-lg-8 col-sm-8 col-md-8">
                        {
                          //    this.state.type && this.state.type === '005' ?
                          //    (
                          //     <Select id="investor"
                          //     options={this.state.investorList}
                          //     onChange={(opt, action) => {
                          //         if (action && action.action === 'clear') {
                          //             this.setState({ selectedInvestorOption: null, investmentName: '', validInvestmentForm: false });
                          //         }
                          //         if (opt !== null && opt !== undefined) {
                          //             this.handleInvestorChange("investor", opt);
                          //         }
                          //     }}
                          //     onInputChange={(searchKey) => {
                          //         if (!_.isEmpty(searchKey)) {
                          //             this.investorSearch(searchKey);
                          //         }
                          //     }}
                          //     value={this.state.selectedInvestorOption}
                          //     isClearable={true}
                          //     isSearchable={true}
                          //     filterOption={(value1, value2) => {
                          //         return value2;
                          //     }}
                          //     deleteRemoves={true}
                          //     noOptionsMessage={() => `Type Something`}
                          // />
                          //    ) :
                          //    (
                          <div>
                            <input
                              className="form-control"
                              id="firstName"
                              type="text"
                              onChange={(event) =>
                                this.handleChange(
                                  event.target.id,
                                  event.target.value
                                )
                              }
                              placeholder=""
                              value={this.state.firstName}
                            />
                            <p>{this.state.firstNameValidation}</p>
                          </div>
                          // )
                        }
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                        Middle Name :
                      </label>
                      <div className="col-lg-8 col-sm-8 col-md-8">
                        <input
                          className="form-control"
                          id="middleName"
                          type="text"
                          onChange={(event) =>
                            this.handleChange(
                              event.target.id,
                              event.target.value
                            )
                          }
                          placeholder=""
                          value={this.state.middleName}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                        Last Name <RequiredSign /> :
                      </label>
                      <div className="col-lg-8 col-sm-8 col-md-8">
                        <input
                          className="form-control"
                          id="lastName"
                          type="text"
                          onChange={(event) =>
                            this.handleChange(
                              event.target.id,
                              event.target.value
                            )
                          }
                          placeholder=""
                          value={this.state.lastName}
                        />
                        <p>{this.state.lastNameValidation}</p>
                      </div>
                    </div>

                    {/* original code of the full name before first name last name*/}

                    {/* <div className="form-group">
                                            <label className="col-lg-4 col-sm-3  col-md-3 control-label">Full Name <RequiredSign /> :</label>

                                            <div className="col-lg-8 col-sm-8 col-md-8">
                                                {
                                                   this.state.type && this.state.type === '005' ?
                                                   (
                                                    <Select id="investor"
                                                    options={this.state.investorList}
                                                    onChange={(opt, action) => {
                                                        if (action && action.action === 'clear') {
                                                            this.setState({ selectedInvestorOption: null, investmentName: '', validInvestmentForm: false });
                                                        }
                                                        if (opt !== null && opt !== undefined) {
                                                            this.handleInvestorChange("investor", opt);
                                                        }
                                                    }}
                                                    onInputChange={(searchKey) => {
                                                        if (!_.isEmpty(searchKey)) {
                                                            this.investorSearch(searchKey);
                                                        }
                                                    }}
                                                    value={this.state.selectedInvestorOption}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    filterOption={(value1, value2) => {
                                                        return value2;
                                                    }}
                                                    deleteRemoves={true}
                                                    noOptionsMessage={() => `Type Something`}
                                                />
                                                   ) : 
                                                   (
                                                    <div>
                                                        <input className="form-control" id="name" type="text"
                                                        onKeyUp={(event) => this.formValidaton(event.target.id)}
                                                        onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                        placeholder='Name'
                                                        value={this.state.name} />
                                                            <p>{this.state.nameValidation}</p>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div> */}

                    {(this.state.roleCode === "001" ||
                      this.state.roleCode === "002") &&
                      this.state.type !== "005" ? (
                      <div className="form-group company-add">
                        <label className="col-lg-4 col-sm-3 col-md-3 control-label">
                          Company <RequiredSign /> :
                        </label>
                        <div className="col-lg-8 col-sm-7 col-md-8 company">
                          <Select
                            id="company"
                            // defaultValue={this.state.Company}
                            options={this.state.list}
                            onChange={(opt, action) => {
                              if (action && action.action === "clear") {
                                this.setState({
                                  selectedCompanyOption: null,
                                  companyId: "",
                                  company: "",
                                  validInvitaionForm: false,
                                });
                              }
                              if (opt !== null && opt !== undefined) {
                                this.handleChange("company", opt);
                              }
                            }}
                            onInputChange={(searchKey) => {
                              if (!_.isEmpty(searchKey)) {
                                this.companySearch(searchKey);
                              }
                            }}
                            value={this.state.selectedCompanyOption}
                            isClearable={true}
                            isSearchable={true}
                            filterOption={(value1, value2) => {
                              return value2;
                            }}
                            deleteRemoves={true}
                            noOptionsMessage={() => `Type Something`}
                            isDisabled={
                              this.state.type === "001" ||
                                this.state.type === "002" ||
                                (this.state.preSelected != -1 &&
                                  this.state.preSelected < 5)
                                ? true
                                : false
                            }
                          />

                          <p>{this.state.companyValidation}</p>
                        </div>
                        <div
                          className="add-company"
                          style={{ display: "none" }}
                        >
                          {this.state.type === "001" ||
                            this.state.type === "002" ? null : (
                            <a
                              href="#addCompanyModal"
                              id="addCompany-btn"
                              className="add-button"
                              data-toggle="modal"
                            >
                              <i className="fa fa-plus" aria-hidden="true"></i>{" "}
                            </a>
                          )}
                        </div>
                      </div>
                    ) : (this.state.roleCode === "001" ||
                      this.state.roleCode === "002") &&
                      this.state.type === "005" ? (
                      <div className="form-group">
                        <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                          Company :
                        </label>
                        <div className="col-lg-8 col-sm-8 col-md-8">
                          <input
                            className="form-control"
                            id="investorCompanyName"
                            type="text"
                            onChange={(event) =>
                              this.handleChange(
                                event.target.id,
                                event.target.value
                              )
                            }
                            placeholder=""
                            value={this.state.investorCompanyName}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="form-group">
                      <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                        Title{" "}
                        {this.state.type !== "005" ? <RequiredSign /> : null}:
                      </label>
                      <div className="col-lg-8 col-sm-8 col-md-8">
                        <input
                          className="form-control"
                          id="title"
                          type="text"
                          onChange={(event) =>
                            this.handleChange(
                              event.target.id,
                              event.target.value
                            )
                          }
                          placeholder=""
                          value={this.state.title}
                        />
                        <p>{this.state.titleValidation}</p>
                      </div>
                    </div>

                    {this.state.type == "005" ? (
                      <div className="form-group">
                        <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                          Address :
                        </label>
                        <div className="col-lg-8 col-sm-8 col-md-8">
                          <input
                            className="form-control"
                            id="address"
                            type="text"
                            onChange={(event) =>
                              this.handleChange(
                                event.target.id,
                                event.target.value
                              )
                            }
                            placeholder=""
                            value={this.state.address}
                          />
                        </div>
                      </div>
                    ) : null}

                    <div className="form-group">
                      <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                        Email Address :
                      </label>
                      <div className="col-lg-8 col-sm-8 col-md-8">
                        <input
                          className="form-control"
                          id="email"
                          onChange={(event) =>
                            this.handleChange(
                              event.target.id,
                              event.target.value
                            )
                          }
                          type="text"
                          placeholder=""
                          value={this.state.emailAddress}
                        />
                        <p>{this.state.emailValidation}</p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-lg-4 col-sm-3 col-md-3 control-label">
                        Mobile:
                      </label>
                      <div className="col-lg-8 col-md-8 col-sm-8 ">
                        <ReactPhoneInput
                          country={"us"}
                          id="mobile"
                          onChange={(event) => {
                            this.handleOnChangeMobile(event);
                          }}
                          value={this.state.mobile}
                          countryCodeEditable={false}
                          disableAreaCodes={true}
                          autoComplete="none"
                          placeholder=""
                        />

                        <p>{this.state.mobileValidation}</p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-lg-4 col-sm-3 col-md-3 control-label">
                        Office:
                      </label>
                      <div className="col-lg-8 col-md-8 col-sm-8 ">
                        <ReactPhoneInput
                          country={"us"}
                          id="office"
                          onChange={(event) => {
                            this.handleOnChangeOffice(event);
                          }}
                          onKeyDown={(event) => this.formValidaton("office")}
                          value={this.state.office}
                          disableAreaCodes={true}
                          countryCodeEditable={false}
                          autoComplete="none"
                          placeholder=""
                        />
                        <p>{this.state.officeValidation}</p>
                      </div>
                    </div>
                    {(this.state.roleCode === "001" ||
                      this.state.roleCode === "002") && (
                        <div className="form-group">
                          <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                            Notes :
                          </label>
                          <div className="col-lg-8 col-sm-8 col-md-8">
                            <textarea
                              className="form-control"
                              name="preferredStockTerms"
                              id="notes"
                              type="message"
                              placeholder=""
                              rows="4"
                              cols="50"
                              onChange={(evt) =>
                                this.handleChange("notes", evt.target.value)
                              }
                              value={this.state.notes}
                            ></textarea>
                          </div>
                        </div>
                      )}
                    {this.state.type && this.state.type === "005" && (
                      <div>
                        {/* <div className="form-group company-add investor-companies">
                                                    <label className="col-lg-4 col-sm-3 col-md-3 control-label">Startup Lists:</label>
                                                    <div className="col-lg-8 col-sm-7 col-md-8 company">
                                                        <Select id="company"
                                                            // defaultValue={this.state.Company}
                                                            options={this.state.startupCompaniesList}
                                                            onChange={(opt, action) => {
                                                                if (action && action.action === 'clear') {
                                                                    this.setState({ selectedStartupCompanyOption: null, startupCompanyId: '', startupCompany: '' });
                                                                }
                                                                if (opt !== null && opt !== undefined) {
                                                                    this.handleChange("startupCompany", opt);
                                                                }
                                                            }}
                                                            onInputChange={(searchKey) => {
                                                                if (!_.isEmpty(searchKey)) {
                                                                    this.startupCompanySearch(searchKey);
                                                                }
                                                            }}
                                                            value={this.state.selectedStartupCompanyOption}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            filterOption={(value1, value2) => {
                                                                return value2;
                                                            }}
                                                            deleteRemoves={true}
                                                            noOptionsMessage={() => `Type Something`}
                                                            isDisabled={(this.state.type === '001' || this.state.type === '002' || (this.state.preSelected != -1 && this.state.preSelected < 5)) ? true : false}
                                                        />
                                                    </div>

                                                </div>
                                                <div className="form-group investor-companies-list">
                                                    <label className="col-lg-4 col-sm-3 col-md-3 control-label"></label>
                                                    <div className="col-lg-8 col-md-8 col-sm-8 ">
                                                        {
                                                            this.state.startupCompanyTags.length > 0 &&
                                                            this.state.startupCompanyTags.map(tag => {
                                                                return (<span key={tag.id} className="tag label label-info">
                                                                    <span>{tag.name}</span>
                                                                    <a><i className="remove glyphicon glyphicon-remove-sign glyphicon-white" onClick={() => this.removeCompanyTag(tag)}></i></a>
                                                                </span>)
                                                            })
                                                        }
                                                    </div>
                                                </div> */}
                        {/* <div className="form-group company-add investor-companies">
                                                    <label className="col-lg-4 col-sm-3 col-md-3 control-label">Add Startup Investments <RequiredSign /> :</label>
                                                    <div className="col-lg-8 col-sm-7 col-md-8 company">
                                                        <Select id="company"
                                                            // defaultValue={this.state.Company}
                                                            options={this.state.startupInvestmentsList}
                                                            onChange={(opt, action) => {
                                                                if (action && action.action === 'clear') {
                                                                    this.setState({ selectedStartupInvestmentOption: null, startupInvestmentId: '', startupInvestment: '' });
                                                                }
                                                                if (opt !== null && opt !== undefined) {
                                                                    this.handleChange("startupCompany", opt);
                                                                }
                                                            }}
                                                            onInputChange={(searchKey) => {
                                                                if (!_.isEmpty(searchKey)) {
                                                                    this.startupCompanySearch(searchKey);
                                                                }
                                                            }}
                                                            value={this.state.selectedStartupCompanyOption}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            filterOption={(value1, value2) => {
                                                                return value2;
                                                            }}
                                                            deleteRemoves={true}
                                                            noOptionsMessage={() => `Type Something`}
                                                            isDisabled={(this.state.type === '001' || this.state.type === '002' || (this.state.preSelected != -1 && this.state.preSelected < 5)) ? true : false}
                                                        />
                                                    </div>

                                                </div> 
                                                <div className="form-group investor-companies-list">
                                                    <label className="col-lg-4 col-sm-3 col-md-3 control-label"></label>
                                                    <div className="col-lg-8 col-md-8 col-sm-8 ">
                                                        {
                                                            this.state.startupCompanyTags.length > 0 &&
                                                            this.state.startupCompanyTags.map(tag => {
                                                                return (<span key={tag.id} className="tag label label-info">
                                                                    <span>{tag.name}</span>
                                                                    <a><i className="remove glyphicon glyphicon-remove-sign glyphicon-white" onClick={() => this.removeCompanyTag(tag)}></i></a>
                                                                </span>)
                                                            })
                                                        }
                                                    </div>
                                                </div>*/}

                        <div className="form-group">
                          <label className="col-lg-4 col-sm-3 col-md-3 control-label">
                            {" "}
                            Category :
                          </label>
                          <div className="col-lg-8 col-sm-8 col-md-8 investor-referral">
                            <Select
                              id="category"
                              defaultValue={this.state.selectedcategory}
                              options={_.sortBy(this.state.category, ["label"])}
                              value={this.state.selectedcategory}
                              onChange={(opt, action) => {
                                if (opt !== null && opt !== undefined) {
                                  this.handleChange("category", opt);
                                }
                              }}
                            />
                            <p>{this.state.categoryValidation}</p>
                          </div>
                        </div>
                        <div className="form-group investor-companies-list investorCategoryTags">
                          <label className="col-lg-4 col-sm-3 col-md-3 control-label"></label>
                          <div className="col-lg-8 col-md-8 col-sm-8 ">
                            {this.state.selectedCategoryTags.length > 0 &&
                              this.state.selectedCategoryTags.map((tag) => {
                                return (
                                  <span
                                    key={tag.id}
                                    className="tag label label-info"
                                  >
                                    <span>{tag.name}</span>
                                    <a>
                                      <i
                                        className="remove glyphicon glyphicon-remove-sign glyphicon-white"
                                        onClick={() =>
                                          this.removeCategoryTag(tag)
                                        }
                                      ></i>
                                    </a>
                                  </span>
                                );
                              })}
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                            Referred By Startup:
                          </label>
                          <div className="col-lg-8 col-sm-8 col-md-8 investor-referral">
                            <Select
                              id="investment"
                              options={this.state.startupInvestmentsList}
                              onChange={(opt, action) => {
                                if (action && action.action === "clear") {
                                  // this.setState({ selectedInvestmentOption: null });
                                }
                                if (opt !== null && opt !== undefined) {
                                  this.handleReferralChange(opt);
                                }
                              }}
                              onInputChange={(searchKey) => {
                                if (!_.isEmpty(searchKey)) {
                                  this.startupInvestmentSearch(searchKey);
                                }
                              }}
                              value={this.state.selectedInvestmentOption}
                              isClearable={true}
                              isSearchable={true}
                              filterOption={(value1, value2) => {
                                return value2;
                              }}
                              deleteRemoves={true}
                              noOptionsMessage={() => `Type Something`}
                            />
                          </div>
                        </div>
                        {/* <div className="form-group investor-companies-list">
                                                    <label className="col-lg-4 col-sm-3 col-md-3 control-label"></label>
                                                    <div className="col-lg-8 col-md-8 col-sm-8 ">
                                                        {
                                                            this.state.investorReferralTags.length > 0 &&
                                                            this.state.investorReferralTags.map(tag => {
                                                                return (<span key={tag.id} className="tag label label-info">
                                                                    <span>{tag.name}</span>
                                                                    <a><i className="remove glyphicon glyphicon-remove-sign glyphicon-white" onClick={() => this.removeReferralTag(tag)}></i></a>
                                                                </span>)
                                                            })
                                                        }
                                                    </div>
                                                </div> */}
                      </div>
                    )}
                  </form>
                </div>
                {this.state.roleCode === "003" ||
                  this.state.roleCode === "004" ? (
                  <div className="modal-footer">
                    {/* <input type="button" id="closeEditModal" className="btn btn-danger"
                                        onClick={() => this.sendInvitation()} value="SEND" /> */}
                    <LaddaButton
                      id="lada-btn"
                      loading={this.state.loadinglada}
                      onClick={() => this.sendInvitation()}
                      data-color="#eee"
                      data-size={XS}
                      className={
                        !this.state.validInvitaionForm ? "disable" : ""
                      }
                      data-style={EXPAND_RIGHT}
                      data-spinner-size={30}
                      data-spinner-color="#ddd"
                      data-spinner-lines={12}
                    >
                      INVITE
                    </LaddaButton>
                    <input
                      type="button"
                      id="cancel"
                      className="btn btn-default"
                      data-dismiss="modal"
                      value="CANCEL"
                      onClick={() => this.resetInviteData()}
                    />
                    {/* <button onClick={() => this.clear()} id="invite-cancel" className="btn btn-default" value="CANCEL" >CANCEL</button> */}
                  </div>
                ) : (
                  <div className="modal-footer">
                    {/* <input type="button" id="closeEditModal" className="btn btn-danger"
                                        onClick={() => this.sendInvitation()} value="SEND" /> */}
                    <LaddaButton
                      id="lada-btn"
                      loading={this.state.loadinglada}
                      onClick={(event) =>
                        this.sendInvitation(event.preventDefault())
                      }
                      data-color="red"
                      data-size={XS}
                      className={
                        !this.state.validInvitaionForm ? "disable" : ""
                      }
                      data-style={EXPAND_RIGHT}
                      data-spinner-size={30}
                      data-spinner-color="#ddd"
                      data-spinner-lines={12}
                    >
                      ADD
                    </LaddaButton>
                    <input
                      type="button"
                      id="cancel"
                      className="btn btn-default"
                      data-dismiss="modal"
                      value="CANCEL"
                      onClick={() => this.resetInviteData()}
                    />
                    {/* <button onClick={() => this.clear()} id="invite-cancel" className="btn btn-default" value="CANCEL" >CANCEL</button> */}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
        {/* <!-- Add Company Modal HTML --> */}
        <div id="addCompanyModal" className="modal fade">
          <div className="modal-dialog" key={this.state.key}>
            <div className="modal-content modal-size">
              <form>
                <div className="modal-header">
                  {window.location.href.includes("pipeline-startups") ? (
                    <h4 className="modal-title">
                      {" "}
                      {this.state.isCompanyEdit === "pipeline"
                        ? "Update"
                        : "Add"}{" "}
                      Pipeline Startup
                    </h4>
                  ) : (
                    <h4 className="modal-title">
                      {" "}
                      {this.state.isCompanyEdit ? "Update" : "Add"} Startup
                    </h4>
                  )}
                </div>
                <div
                  className="modal-body"
                  style={{ maxHeight: "450px", overflowY: "auto" }}
                >
                  <form className="form-horizontal">
                    {this.state.isCompanyEdit === true && (
                      <div className="form-group">
                        <label className="col-lg-4 col-sm-4  col-md-4 control-label">
                          Stage <RequiredSign /> :
                        </label>
                        <div className="col-sm-8 col-md-8">
                          <Select
                            defaultValue={this.state.selectedStages}
                            options={this.state.stages}
                            value={this.state.selectedStages}
                            onChange={this.selectStage}
                            id="Investmentstages"
                          />
                        </div>
                      </div>
                    )}
                    {this.state.isCompanyEdit === true &&
                      this.state.selectedStages &&
                      (this.state.selectedStages.value === "exited" ||
                        this.state.selectedStages.value ===
                        "exit in process") && (
                        <>
                          <div className="form-group">
                            <label className="col-lg-4 col-sm-4  col-md-4 control-label">
                              Exit Type <RequiredSign /> :
                            </label>
                            <div className="col-sm-8 col-md-8">
                              <Select
                                defaultValue={this.state.selectedExitProcess}
                                options={this.state.exitProcess}
                                value={this.state.selectedExitProcess}
                                onChange={(event) =>
                                  this.changeExitProcess(event)
                                }
                                id="ExitInvestmentTypes"
                              />
                            </div>
                          </div>
                          {this.state.selectedExitProcess &&
                            this.state.selectedExitProcess.value == "ipo" ? (
                            <div className="form-group">
                              <label className="col-lg-4 col-sm-4  col-md-4 control-label">
                                Lockup Exp. <RequiredSign /> :
                              </label>
                              {/* <label className="col-sm-3  col-md-3 control-label">Lockup Exp. <RequiredSign /> :</label> */}
                              <div className="col-sm-8 col-md-8 customDatePickerWidth">
                                <DatePicker
                                  selected={this.state.lockupDate}
                                  onChange={(date) =>
                                    this.handleLockupDateChange(date)
                                  }
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="form-group">
                              <label className="col-lg-4 col-sm-4  col-md-4 control-label">
                                Disbursement Date <RequiredSign /> :
                              </label>
                              {/* <label className="col-sm-3  col-md-3 control-label">Disbursement Date <RequiredSign /> :</label> */}
                              <div className="col-sm-8 col-md-8 customDatePickerWidth">
                                <DatePicker
                                  selected={this.state.disbursementDate}
                                  onChange={(date) =>
                                    this.handleDisbursementDateChange(date)
                                  }
                                />
                              </div>
                            </div>
                          )}
                          <div className="form-group">
                            <label className="col-lg-4 col-sm-4  col-md-4 control-label">
                              Acquiring Company Name <RequiredSign /> :
                            </label>
                            <div className="col-sm-8 col-md-8">
                              <input
                                className="form-control"
                                id="acquiringCompanyName"
                                type="text"
                                onKeyUp={(event) =>
                                  this.formValidaton(
                                    event.target.id,
                                    event.target.value
                                  )
                                }
                                onChange={(event) =>
                                  this.handleChange(
                                    event.target.id,
                                    event.target.value
                                  )
                                }
                                placeholder=""
                                value={this.state.acquiringCompanyName}
                              />
                              <p>{this.state.nameValidationMsg}</p>
                            </div>
                          </div>
                        </>
                      )}
                    {/* </> : ''} */}
                    <div className="form-group">
                      <label className="col-lg-4 col-sm-4  col-md-4 control-label">
                        Company Legal Name <RequiredSign />:
                      </label>
                      <div className="col-sm-8 col-md-8">
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                          id="Company"
                          onKeyUp={(event) =>
                            this.formValidaton(event.target.id)
                          }
                          onChange={(event) =>
                            this.handleChange(
                              event.target.id,
                              event.target.value
                            )
                          }
                          value={this.state.Company}
                        />
                        <p>{this.state.CompanyValidation}</p>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="col-lg-4 col-sm-4  col-md-4 control-label">
                        Company Nick Name :
                      </label>
                      <div className="col-sm-8 col-md-8">
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                          id="CompanyNickName"
                          onKeyUp={(event) =>
                            this.formValidaton(event.target.id)
                          }
                          onChange={(event) =>
                            this.handleChange(
                              event.target.id,
                              event.target.value
                            )
                          }
                          value={this.state.CompanyNickName}
                        />
                      </div>
                    </div>

                    {this.state.isCompanyEdit === true &&
                      this.state.selectedStages &&
                      this.state.selectedStages.value === "exit in process" && (
                        <>
                          <div className="form-group">
                            <label className="col-lg-4 col-sm-4 col-md-4 control-label">
                              Disbursements:
                            </label>
                            <div className="col-sm-8 col-md-8">
                              <input
                                type="button"
                                id="add_gain"
                                className="tsg-btn"
                                value={
                                  this.state.totalNetInvestment != null
                                    ? "Update"
                                    : "ADD"
                                }
                                onClick={(e) => this.investmentGainModalCall()}
                              />

                              {this.state.allInvestment.length > 0 && (
                                <div className="tooltipCode">
                                  <span className="i-icon tooltips">
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>
                                    <span className="tooltiptext">
                                      {this.state.allInvestment.map(
                                        (data, i) => (
                                          <div>
                                            <b>{data.name}</b> -{" "}
                                            {"$" +
                                              Number(
                                                data.invesmtentAmt
                                              ).toLocaleString(undefined, {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2,
                                              })}{" "}
                                            ( Investment ) :{" "}
                                            {"$" +
                                              Number(
                                                data.disbursement1
                                              ).toLocaleString(undefined, {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2,
                                              })}{" "}
                                            ( Disbursement1 ) :{" "}
                                            {data.disbursement2 !== null &&
                                              data.disbursement2 !== 0 &&
                                              "$" +
                                              Number(
                                                data.disbursement2
                                              ).toLocaleString(undefined, {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2,
                                              })}{" "}
                                            ( Disbursement2 )
                                          </div>
                                        )
                                      )}
                                    </span>
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="col-lg-4 col-sm-4  col-md-4 control-label">
                              Notes :
                            </label>
                            <div className="col-sm-8 col-md-8">
                              <CKEditor
                                id="exitNotes"
                                editor={ClassicEditor}
                                data={this.state.exitNotes}
                                onInit={(editor) => {
                                  console.log(
                                    "Editor is ready to use!",
                                    editor
                                  );
                                }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  this.handleChange("exitNotes", data);
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}

                    {/* <div className="form-group">
                                            <label className="col-lg-4 col-sm-4  col-md-4 control-label">Total Net Invesment<RequiredSign /> :</label>
                                            <div className="col-sm-7 col-md-7">
                                                <NumberFormat
                                                    value={this.state.totalNetInvestment}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    placeholder=''
                                                    className='form-control'
                                                    onValueChange={(values) => {
                                                        this.handleChange('totalNetInvestment', values.value)
                                                    }} />
                                            </div>
                                        </div> */}

                    {/* <div className="form-group">
                                            <label className="col-lg-4 col-sm-4  col-md-4 control-label">Total Gross Gain<RequiredSign /> :</label>
                                            <div className="col-sm-7 col-md-7">
                                                <NumberFormat
                                                    value={this.state.totalGrossGain}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    placeholder=''
                                                    className='form-control'
                                                    onValueChange={(values) => {
                                                        this.handleChange('totalGrossGain', values.value)
                                                    }} />
                                            </div>
                                        </div> */}

                    {window.location.href.includes("pipeline-startups") && (
                      <>
                        <div className="form-group">
                          <label className="col-lg-4 col-sm-4  col-md-4 control-label">
                            Website{" "}
                            {window.location.href.includes(
                              "pipeline-startups"
                            ) ? (
                              <RequiredSign />
                            ) : (
                              ""
                            )}
                            :
                          </label>
                          <div className="col-sm-7 col-md-7">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              id="Website"
                              onKeyUp={(event) =>
                                this.formValidaton(event.target.id)
                              }
                              onChange={(event) =>
                                this.handleChange(
                                  event.target.id,
                                  event.target.value
                                )
                              }
                              value={this.state.website}
                            />
                            {/* <p>{this.state.websiteValidation}</p> */}
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="col-lg-4 col-sm-4  col-md-4 control-label">
                            Phone:
                          </label>
                          <div className="col-sm-7 col-md-7">
                            <ReactPhoneInput
                              country={"us"}
                              id="Phone"
                              value={this.state.phone}
                              onChange={(event) =>
                                this.handleChange(
                                  event.target.id,
                                  event.target.value
                                )
                              }
                              onChange={(value) =>
                                this.handleOnChangePhone(value)
                              }
                              onKeyDown={(event) => this.formValidaton("Phone")}
                              disableAreaCodes={true}
                              value={this.state.phone}
                              countryCodeEditable={false}
                              // autoComplete={false}
                              // autocomplete="off"
                              autoComplete="none"
                              placeholder=""
                            />
                            <p>{this.state.phoneValidation}</p>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="form-group">
                      <label className="col-lg-4 col-sm-4  col-md-4 control-label">
                        Address 1{" "}
                        {window.location.href.includes("pipeline-startups") ? (
                          ""
                        ) : (
                          <RequiredSign />
                        )}
                        :
                      </label>
                      <div className="col-sm-8 col-md-8">
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                          id="Address1"
                          onKeyUp={(event) =>
                            this.formValidaton(event.target.id)
                          }
                          onChange={(event) =>
                            this.handleChange(
                              event.target.id,
                              event.target.value
                            )
                          }
                          value={this.state.address1}
                        />
                        <p>{this.state.addresValidation1}</p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-lg-4 col-sm-4  col-md-4 control-label">
                        Address 2:
                      </label>
                      <div className="col-sm-8 col-md-8">
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                          id="Address2"
                          onKeyUp={(event) =>
                            this.formValidaton(event.target.id)
                          }
                          onChange={(event) =>
                            this.handleChange(
                              event.target.id,
                              event.target.value
                            )
                          }
                          value={this.state.address2}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-lg-4 col-sm-4  col-md-4 control-label">
                        City
                        {window.location.href.includes("pipeline-startups") ? (
                          ""
                        ) : (
                          <RequiredSign />
                        )}
                        :
                      </label>
                      <div className="col-sm-8 col-md-8">
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                          id="City"
                          onKeyUp={(event) =>
                            this.formValidaton(event.target.id)
                          }
                          onChange={(event) =>
                            this.handleChange(
                              event.target.id,
                              event.target.value
                            )
                          }
                          value={this.state.city}
                        />
                        <p>{this.state.CityValidation}</p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-lg-4 col-sm-4  col-md-4 control-label">
                        State{" "}
                        {window.location.href.includes("pipeline-startups") ? (
                          ""
                        ) : (
                          <RequiredSign />
                        )}{" "}
                        :
                      </label>
                      <div className="col-sm-8 col-md-8">
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                          id="State1"
                          onKeyUp={(event) =>
                            this.formValidaton(event.target.id)
                          }
                          onChange={(event) =>
                            this.handleChange(
                              event.target.id,
                              event.target.value
                            )
                          }
                          value={this.state.state1}
                        />
                        <p>{this.state.StateValidation}</p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-lg-4 col-sm-4  col-md-4 control-label">
                        Zip Code{" "}
                        {window.location.href.includes("pipeline-startups") ? (
                          ""
                        ) : (
                          <RequiredSign />
                        )}{" "}
                        :
                      </label>
                      <div className="col-sm-8 col-md-8">
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                          id="ZipCode"
                          onKeyUp={(event) =>
                            this.formValidaton(event.target.id)
                          }
                          onChange={(event) =>
                            this.handleChange(
                              event.target.id,
                              event.target.value
                            )
                          }
                          value={this.state.zipcode}
                        />
                        <p>{this.state.ZipCodeValidation}</p>
                      </div>
                    </div>
                    {!window.location.href.includes("pipeline-startups") && (
                      <div className="form-group">
                        <label className="col-lg-4 col-sm-4  col-md-4 control-label">
                          Phone:
                        </label>
                        <div className="col-sm-8 col-md-8">
                          <ReactPhoneInput
                            country={"us"}
                            id="Phone"
                            value={this.state.phone}
                            onChange={(event) =>
                              this.handleChange(
                                event.target.id,
                                event.target.value
                              )
                            }
                            onChange={(value) =>
                              this.handleOnChangePhone(value)
                            }
                            onKeyDown={(event) => this.formValidaton("Phone")}
                            disableAreaCodes={true}
                            value={this.state.phone}
                            countryCodeEditable={false}
                            autoComplete="none"
                            placeholder=""
                          />
                          <p>{this.state.phoneValidation}</p>
                        </div>
                      </div>
                    )}

                    {!window.location.href.includes("pipeline-startups") && (
                      <>
                        <div className="form-group">
                          <label className="col-lg-4 col-sm-4  col-md-4 control-label">
                            Website:
                          </label>
                          <div className="col-sm-8 col-md-8">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              id="Website"
                              onKeyUp={(event) =>
                                this.formValidaton(event.target.id)
                              }
                              onChange={(event) =>
                                this.handleChange(
                                  event.target.id,
                                  event.target.value
                                )
                              }
                              value={this.state.website}
                            />
                            {/* <p>{this.state.websiteValidation}</p> */}
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            className="col-lg-5 col-sm-5 col-md-5"
                            for={`prospectStartup`}
                          >
                            Is Pipeline Startup :
                          </label>
                          <div className="col-lg-2 col-sm-2 col-md-2 checkbox-margin">
                            <input
                              type="checkbox"
                              id={`prospectStartup`}
                              onChange={(event) =>
                                this.handleChange(
                                  event.target.id,
                                  event.currentTarget.checked
                                )
                              }
                              checked={this.state.isProspectStartup}
                              readOnly={true}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </form>
                </div>
                <div className="modal-footer">
                  {this.state.isCompanyEdit ? (
                    <LaddaButton
                      id="lada-btn"
                      loading={this.state.loadinglada2}
                      onClick={(event) =>
                        this.updateCompany(event.preventDefault())
                      }
                      data-color="#eee"
                      data-size={XS}
                      className={
                        this.state.isCompanyEdit === "pipeline"
                          ? this.checkPipelineValidation()
                          : this.checkValidation()
                      }
                      data-style={EXPAND_RIGHT}
                      data-spinner-size={30}
                      data-spinner-color="#ddd"
                      data-spinner-lines={12}
                    >
                      UPDATE
                    </LaddaButton>
                  ) : (
                    <LaddaButton
                      id="lada-btn"
                      loading={this.state.loadinglada2}
                      onClick={(event) =>
                        this.addCompany(event.preventDefault())
                      }
                      data-color="#eee"
                      data-size={XS}
                      className={this.validate()}
                      data-style={EXPAND_RIGHT}
                      data-spinner-size={30}
                      data-spinner-color="#ddd"
                      data-spinner-lines={12}
                    >
                      ADD
                    </LaddaButton>
                  )}
                  <input
                    type="button"
                    id="company-cancel"
                    className="btn btn-default"
                    data-dismiss="modal"
                    value="CANCEL"
                    onClick={() => this.resetAddCompanyData()}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* investment & gain */}
        <div id="investmentGainModal" className="modal fade">
          <div className="modal-dialog">
            <div className="modal-content modal-size">
              <form>
                <div className="modal-header">
                  <h4 className="modal-title">Disbursements</h4>
                </div>
                <div
                  className="modal-body"
                  style={{ "max-height": "610px", overflow: "auto" }}
                >
                  <div className="col-lg-12 col-sm-12 col-md-12">
                    <div className="col-lg-3 col-sm-3 col-md-3"></div>
                    <div className="col-lg-3 col-sm-3 col-md-3">
                      <b>Investment</b>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-md-3">
                      <b>Disbursement 1</b>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-md-3">
                      <b>Disbursement 2</b>
                    </div>
                  </div>

                  {
                    <>
                      <div className="col-lg-12 col-sm-12 col-md-12 dialog-content">
                        {this.state.gainLoader && (
                          <div>
                            <LoadingSpinner class={true} />
                          </div>
                        )}
                        {this.state.allInvestment.length > 0 &&
                          !this.state.gainLoader &&
                          this.state.allInvestment.map((data, i) => (
                            <div>
                              <div className="form-group">
                                <div
                                  className="col-lg-3 col-sm-3 col-md-3"
                                  style={{ marginTop: "16px" }}
                                >
                                  <label
                                    className="control-label"
                                    style={{ whiteSpace: "break-spaces" }}
                                  >
                                    <b>{data.name}:</b>
                                  </label>
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="col-lg-3 col-sm-3 col-md-3">
                                  <NumberFormat
                                    value={data.invesmtentAmt}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    fixedDecimalScale={true}
                                    placeholder=""
                                    className="form-control"
                                    onValueChange={(values) => {
                                      this.handleInvementChange(
                                        "invesmtentAmt",
                                        i,
                                        values.value
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="col-lg-3 col-sm-3 col-md-3">
                                  <NumberFormat
                                    value={data.disbursement1}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    fixedDecimalScale={true}
                                    placeholder=""
                                    className="form-control"
                                    onValueChange={(values) => {
                                      this.handleInvementChange(
                                        "disbursement1",
                                        i,
                                        values.value
                                      );
                                    }}
                                  />
                                  {(data.disbursement1 === "0" ||
                                    this.state.allInvestment[0]
                                      .disbursement1 === "") && (
                                      <p>Disbursement Amount Can't Be Empty.</p>
                                    )}
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="col-lg-3 col-sm-3 col-md-3">
                                  <NumberFormat
                                    value={data.disbursement2}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    fixedDecimalScale={true}
                                    placeholder=""
                                    className="form-control"
                                    onValueChange={(values) => {
                                      this.handleInvementChange(
                                        "disbursement2",
                                        i,
                                        values.value
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  }
                </div>
                <div className="modal-footer">
                  <LaddaButton
                    id="lada-btn"
                    loading={this.state.loadinglada2}
                    onClick={(event) =>
                      this.updateCompanyInvestment(event.preventDefault())
                    }
                    data-color="#eee"
                    data-size={XS}
                    data-style={EXPAND_RIGHT}
                    data-spinner-size={30}
                    data-spinner-color="#ddd"
                    data-spinner-lines={12}
                    disabled={
                      this.state.allInvestment.lengths &&
                      (this.state.allInvestment[0].disbursement1 === "0" ||
                        this.state.allInvestment[0].disbursement1 === 0 ||
                        this.state.allInvestment[0].disbursement1 === "")
                    }
                  >
                    SAVE
                  </LaddaButton>

                  <input
                    type="button"
                    id="investment-cancel"
                    className="btn btn-default"
                    data-dismiss="modal"
                    value="CANCEL"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* <!-- ForgotPassword Modal HTML --> */}
        <div id="forgotPasswordModal" className="modal fade">
          <div className="modal-dialog">
            <div className="modal-content">
              <form>
                <div className="modal-header">
                  <h4 className="modal-title">Forgot Password</h4>
                </div>
                <div className="modal-body">
                  <form className="form-horizontal">
                    <div className="form-group">
                      <label className="col-md-3 control-label">
                        Enter Email
                      </label>
                      <div className="col-md-8">
                        <input
                          className="form-control"
                          type="text"
                          id="emailForgotPassword"
                          onKeyUp={(event) =>
                            this.formValidaton(event.target.id)
                          }
                          placeholder=""
                          onChange={(e) => this.isEmail(e.target.value)}
                        />
                        <p>{this.state.emailForgotPasswordValidation}</p>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <input
                    type="button"
                    id="reset-btn"
                    className={`btn-success ${!this.state.emailValidated && "disable"
                      }`}
                    value="RESET PASSWORD"
                    onClick={() => this.routeChange()}
                  />
                  <input
                    type="button"
                    id="close-forgot"
                    className="btn btn-default"
                    data-dismiss="modal"
                    value="CLOSE"
                    onClick={() => this.resetEmailData()}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CustomModal;

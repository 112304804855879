import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import swal from 'sweetalert';
import Modal from 'react-bootstrap-modal';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import _ from 'lodash';
import { Markup } from 'interweave';
import ReactToPrint from 'react-to-print';
import axios from 'axios';

// Imported Components
import './FaqPreview.scss';
import HeaderImage from '../../../../components/HeaderImage';
import LoadingSpinner from '../../../../components/Loader';
import Header from '../../../../components/Header';
import TButton from '../../../../components/TButton';
import common from '../../../../common';
import images from "../../../../images";

let userRole = '000';
let userRoleCode = '000';

class FaqPreview extends Component {
    constructor(props) {
        super(props);
        this.FaqPreview = false;
        this.state = {
            arrayFAQ: [],
            loading: false,
            headerShow: 'block',
            choosenFile: null,
            question: '',
            answer: '',
            questionValidation: '',
            answerValidation: '',
            loadinglada: false,
            userId: null,
            show: false,
            addFAQ: false,
            updateFAQ: false,
            modalTitle: '',
            modalButtonTitle: '',
            queIndex: '',
            type: 'startup'
        }
    }
    componentWillMount() {
        const url = new URL(window.location.href);
        this.FaqPreview = url.searchParams.get('faqPreview')
        userRole = window.localStorage.getItem('role');
        userRoleCode = window.localStorage.getItem('roleCode');
        this.getFAQ();
        this.setState({
            name: window.localStorage.getItem('username'),
            userId: window.localStorage.getItem('id')
        });
        this.setState({ Role: userRole })
    }

    handleChange(id, newValue) {
        if (id === "question")
            this.setState({ question: newValue });
        else if (id === 'answer')
            this.setState({ answer: newValue });
    }
    formValidaton(id) {
        if (id === 'question') {
            let question = document.getElementById('question').value;
            if (question === '') {
                this.setState({
                    questionValidation: "Question should not be empty*"
                })
            } else {
                this.setState({
                    questionValidation: ""
                })
            }
        }
        else if (id === 'answer') {
            let answer = document.getElementById('answer').value;
            if (answer === '') {
                this.setState({
                    answerValidation: "Answer should not be empty*"
                })
            } else {
                this.setState({
                    answerValidation: ""
                })
            }
        }
    }

    resetAddFAQData() {
        this.setState({
            question: '',
            answer: '',
            questionValidation: '',
            answerValidation: '',
            loadinglada: false,
            loadinglada2: false,
            show: false,
            addFAQ: false,
            updateFAQ: false
        });
    }

    apiFAQ() {
        let temp = this;
        temp.setState({ loadinglada2: true });
        let tokenpass = window.localStorage.getItem('token');
        if (temp.state.addFAQ) {
            axios.post(`${common.path.base_url}/saveFaq`, {
                type: this.state.type,
                question: temp.state.question,
                answer: temp.state.answer,
            }, {
                headers: {
                    "Authorization": tokenpass
                }
            })
                .then(function (response) {
                    temp.setState({ loadinglada2: false });
                    // alert(response.data.message);
                    swal({
                        title: "Success!",
                        text: response.data.message,
                        icon: "success",
                        button: "Ok!",
                    })
                        .then(function () {
                            temp.resetAddFAQData();
                            document.getElementById('company-cancel').click();
                            temp.getFAQ();
                        });
                })
                .catch(function (error) {
                    if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        window.localStorage.clear();
                        window.location.href = '/';
                        return;
                    }
                    else if (error.response) {
                        swal({
                            title: "Error!",
                            text: error.response.data.message,
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                    else {
                        swal({
                            title: "Network Error!",
                            text: "Something went wrong",
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                    temp.resetAddFAQData();
                    temp.setState({ loadinglada2: false });

                });
        }
        else if (temp.state.updateFAQ) {
            axios.put(`${common.path.base_url}/updateFaq`, {
                _id: temp.state.queIndex,
                question: temp.state.question,
                answer: temp.state.answer
            }, {
                headers: {
                    "Authorization": tokenpass
                }
            })
                .then(function (response) {
                    temp.setState({ loadinglada2: false });
                    // alert(response.data.message);
                    swal({
                        title: "Updated!",
                        text: response.data.message,
                        icon: "success",
                        button: "Ok!",
                    })
                        .then(function () {
                            temp.resetAddFAQData();
                            document.getElementById('company-cancel').click();
                            temp.getFAQ();
                        });
                })
                .catch(function (error) {
                    if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        window.localStorage.clear();
                        window.location.href = '/';
                        return;
                    }
                    else if (error.response) {
                        swal({
                            title: "Error!",
                            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                    else {
                        swal({
                            title: "Network Error!",
                            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                    temp.resetAddFAQData();
                    temp.setState({ loadinglada2: false });

                });
        }
    }
    deleteFAQ(index) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this FAQ",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willdelete) => {
                const token = localStorage.getItem('token');
                if (willdelete) {
                    this.setState({ loading: true })
                    axios.delete(`${common.path.base_url}/deleteFaq`, { headers: { "Authorization": token }, data: { _id: index } }
                    )
                        //@TODO CALL ON SUCCESS OF API
                        .then((response) => {
                            this.setState({ loading: false })
                            swal({
                                title: "Success!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            })
                                .then(() => {
                                    this.getFAQ();
                                });

                        })
                        .catch((error) => {
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else {
                                swal({
                                    title: "Error!",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });
                }
            })
    }
    getFAQ() {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        console.log(250, this.state.type);
        let param = this.state.type;
        axios.get(`${common.path.base_url}/getFaq?type=${param}`, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                temp.setState({
                    arrayFAQ: response.data.data
                });
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    }).then((val) => {
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: "Something went wrong",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    updateChecklistOrder = (updatedFaqs) => {
        if (updatedFaqs && updatedFaqs.length > 0) {
            const temp = this;
            temp.setState({ loading: true });
            const payload = { faqOrder: updatedFaqs.map(({ _id, order }) => ({ _id, order })), type: this.state.type };
            let tokenpass = window.localStorage.getItem('token');
            axios.post(`${common.path.base_url}/changeFaqOrder`, payload, {
                headers: {
                    "Authorization": tokenpass
                }
            })
                .then((response) => {
                    swal({
                        title: "Change FAQ Order",
                        text: response.data.message,
                        icon: "success",
                        button: "Ok!",
                    })
                        .then(function () {
                            temp.setState({ loading: false });
                            temp.getFAQ();
                        });
                })
                .catch((error) => {
                    this.getFAQ();
                    temp.setState({ loading: false });
                    if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        window.localStorage.clear();
                        window.location.href = '/';
                        return;
                    }
                    else if (error.response) {
                        swal({
                            title: "Error!",
                            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                    else {
                        swal({
                            title: "Network Error!",
                            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                });
        }
    };

    changeFaqOrder = (faqs) => {
        const updatedFaqs = faqs.map((singleFaq, index) => {
            singleFaq.order = index;
            return singleFaq
        })
        return updatedFaqs;
    };

    reOrderFaqArray = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    onFaqDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const reOrderedFaqs = this.reOrderFaqArray(this.state.arrayFAQ, result.source.index, result.destination.index);
        const updatedFaqs = this.changeFaqOrder(reOrderedFaqs);
        this.setState({ arrayFAQ: updatedFaqs });
        this.updateChecklistOrder(updatedFaqs);
    };

    render() {

        return (
            <div>
                <div className='faqPreview'>
                    <div className="faqPreview-main">
                        <div className='heading'>
                            <div className="faq-header-section">
                                <h4 className='faq-prev'>Startup FAQs</h4>
                                <div className="header-btn">
                                    <ReactToPrint
                                        trigger={() => <TButton text={`Print`} className='share-btn' />}
                                        content={() => this.componentRef}
                                        pageStyle={'padding : 40px'}
                                    />
                                    {this.FaqPreview ? '' : <TButton
                                        onClick={() => { this.setState({ show: true, addFAQ: true, modalTitle: 'Add FAQ', modalButtonTitle: 'ADD' }) }}
                                        text={`ADD FAQ`} className='invite-btn' />}
                                </div>
                            </div>
                        </div>
                        <div className='faqPreview-details' ref={elements => this.componentRef = elements} >
                            {
                                (userRoleCode === '001' || userRoleCode === '002')
                                    ? <DragDropContext onDragEnd={this.onFaqDragEnd}>
                                        <Droppable droppableId="faq-droppable">
                                            {
                                                (provided) => (
                                                    <div ref={provided.innerRef}>
                                                        {
                                                            this.state.arrayFAQ.map((element, index) => {
                                                                return <Draggable
                                                                    key={element._id}
                                                                    draggableId={element._id}
                                                                    index={index}
                                                                >
                                                                    {
                                                                        (provided) => (
                                                                            <div className='faqPreview-options' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                <div className='faqPreview-option '>
                                                                                    <div className="faq-ques">Q:&nbsp;<Markup content={element.question} /></div>
                                                                                    <p className="faq-content"><span>A:&nbsp;</span><Markup content={`${element.answer}`} /></p>
                                                                                </div>
                                                                                <div className='icons'>
                                                                                    {
                                                                                        this.FaqPreview
                                                                                            ? ''
                                                                                            : <i
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        show: true,
                                                                                                        updateFAQ: true,
                                                                                                        modalTitle: 'Update FAQ',
                                                                                                        modalButtonTitle: 'UPDATE',
                                                                                                        queIndex: element._id,
                                                                                                        question: element.question,
                                                                                                        answer: element.answer
                                                                                                    })
                                                                                                }}
                                                                                                className="fa fa-pencil add-address">
                                                                                            </i>
                                                                                    }
                                                                                    {
                                                                                        this.FaqPreview
                                                                                            ? ''
                                                                                            : <img onClick={() => this.deleteFAQ(element._id)} src={images.path.deleteIcon} alt="Delete Icon" />
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </Draggable>
                                                            })
                                                        }
                                                        {provided.placeholder}
                                                    </div>
                                                )
                                            }
                                        </Droppable>
                                    </DragDropContext>
                                    : <div>
                                        {
                                            this.state.arrayFAQ.map((element, index) => {
                                                return <div className='faqPreview-options'>
                                                    <div className='faqPreview-option '>
                                                        <div className="faq-ques">Q:&nbsp;<Markup content={element.question} /></div>
                                                        <p className="faq-content"><span>A:&nbsp;</span><Markup content={`${element.answer}`} /></p>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                            }
                        </div>
                    </div>
                </div>

                <Modal
                    id='updateFAQ'
                    aria-labelledby="ModalHeader"
                    show={this.state.show}
                >
                    <Modal.Header>
                        <Modal.Title id='ModalHeader'>{this.state.modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="form-horizontal">
                            <div className="form-group">
                                <label className="col-md-3 control-label">Question:</label>
                                <div className="col-md-8">
                                    <textarea className="form-control" type="text" placeholder='' id="question"
                                        onKeyUp={(event) => this.formValidaton(event.target.id)}
                                        onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                        value={this.state.question}
                                        rows="2"
                                        cols="50"
                                    />
                                    <p>{this.state.questionValidation}</p>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-3 control-label">Answer:</label>
                                <div className="col-md-8">
                                    <textarea className="form-control" type="text" placeholder='' id="answer"
                                        onKeyUp={(event) => this.formValidaton(event.target.id)}
                                        onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                        value={this.state.answer}
                                        rows="4"
                                        cols="50"
                                    />
                                    <p>{this.state.answerValidation}</p>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <LaddaButton
                            id='lada-btn'
                            loading={this.state.loadinglada2}
                            data-color="#eee"
                            data-size={XS}
                            onClick={() => this.apiFAQ()}
                            className={!_.isEmpty(this.state.question && this.state.answer) ? '' : 'disable'}
                            data-style={EXPAND_RIGHT}
                            data-spinner-size={30}
                            data-spinner-color="#ddd"
                            data-spinner-lines={12}
                        >{this.state.modalButtonTitle}</LaddaButton>
                        <input type="button" id='company-cancel' className="btn btn-default" data-dismiss="modal" value="CANCEL" onClick={() => this.resetAddFAQData()} />
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
export default FaqPreview;
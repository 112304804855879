import React, { useState, useEffect  } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import common from '../../common';


function AddressWireInfoModal (props){
    const [addressWireInfo, setAddressWireInfo] = useState({
        name:"",
        address1:"",
        address2:"",
        city:"",
        state:"",
        zipcode:"",
        accountType:"domestic",
        bankName:"",
        bankAddress:"",
        accountNumber:"",
        routingNumber:"",
        swiftCode:""
    });

    useEffect(() => {
        let isMounted = true
        if(isMounted){ 
            const addressWireInfoObj = props.addressWireInfo;
            const dataArr = {
                name:(addressWireInfoObj.name)?addressWireInfoObj.name:"",
                address1:(addressWireInfoObj.address1)?addressWireInfoObj.address1:"",
                address2:(addressWireInfoObj.address2)?addressWireInfoObj.address2:"",
                city:(addressWireInfoObj.city)?addressWireInfoObj.city:"",
                state:(addressWireInfoObj.state)?addressWireInfoObj.state:"",
                zipcode:(addressWireInfoObj.zipcode)?addressWireInfoObj.zipcode:"",
                accountType:(addressWireInfoObj.accountType)?addressWireInfoObj.accountType:"",
                bankName:(addressWireInfoObj.bankName)?addressWireInfoObj.bankName:"",
                bankAddress:(addressWireInfoObj.bankAddress)?addressWireInfoObj.bankAddress:"",
                accountNumber:(addressWireInfoObj.accountNumber)?addressWireInfoObj.accountNumber:"",
                routingNumber:(addressWireInfoObj.routingNumber)?addressWireInfoObj.routingNumber:"",
                swiftCode:(addressWireInfoObj.swiftCode)?addressWireInfoObj.swiftCode:""
            }
            setAddressWireInfo(dataArr);
        }
        return () => { isMounted = false }
    }, [props.addressWireInfo])
     
        return (
            <div id="addressWireInfoModal" className="modal fade">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{addressWireInfo.name}</h4>
                        </div>
                        <div className="modal-body"> 
                                <div className="row" > 
                                    <div className="col-sm-12 col-md-6">
                                        <div className="margin-top-1">
                                            <label htmlFor="name">Address Line 1 :</label>
                                            <div className='recommendation-input'>
                                                <input type="text"
                                                    value={addressWireInfo.address1} 
                                                    name="name" className="form-control" id="address1"  
                                                    readOnly
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6">
                                        <div className="margin-top-1">
                                            <label htmlFor="name">Address Line 2 :</label>
                                            <div className='recommendation-input'>
                                                <input type="text"
                                                    value={addressWireInfo.address2} 
                                                    name="name" className="form-control" id="address2"  
                                                    readOnly
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4">
                                        <div className="margin-top-1">
                                            <label htmlFor="name">City :</label>
                                            <div className='recommendation-input'>
                                                <input type="text"
                                                    value={addressWireInfo.city} 
                                                    name="name" className="form-control" id="city"  
                                                    readOnly
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4">
                                        <div className="margin-top-1">
                                            <label htmlFor="name">State :</label>
                                            <div className='recommendation-input'>
                                                <input type="text"
                                                    value={addressWireInfo.state} 
                                                    name="name" className="form-control" id="state"  
                                                    readOnly
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4">
                                        <div className="margin-top-1">
                                            <label htmlFor="name">Zip Code :</label>
                                            <div className='recommendation-input'>
                                                <input type="text"
                                                    value={addressWireInfo.zipcode} 
                                                    name="name" className="form-control" id="zipcode"  
                                                    readOnly
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12" style={{marginBottom:"-15px"}}>
                                        <hr style={{backgroundColor:"#e5e5e5"}} />
                                    </div>
                                     
                                    <div className="col-sm-6 col-md-6">
                                        <div className="margin-top-1">
                                            <label htmlFor="bankName">Bank Name:</label>
                                            <div className='recommendation-input'>
                                                <input type="text"
                                                    value={addressWireInfo.bankName} 
                                                    name="bankName" className="form-control" id="bankName"  
                                                    readOnly
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="margin-top-1">
                                            <label htmlFor="routingNumber">Bank Address:</label>
                                            <div className='recommendation-input'>
                                                <input type="text"
                                                    value={addressWireInfo.bankAddress} 
                                                    name="bankAddress" className="form-control" id="bankAddress"  
                                                    readOnly
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="margin-top-1">
                                            <label htmlFor="accountNumber">Account Number:</label>
                                            <div className='recommendation-input'>
                                                <input type="text"
                                                    value={addressWireInfo.accountNumber} 
                                                    name="accountNumber" className="form-control" id="accountNumber"  
                                                    readOnly
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="margin-top-1">
                                            <label htmlFor="routingNumber">Routing Number:</label>
                                            <div className='recommendation-input'>
                                                <input type="text"
                                                    value={addressWireInfo.routingNumber} 
                                                    name="routingNumber" className="form-control" id="routingNumber"  
                                                    readOnly
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    {addressWireInfo.accountType=="international"?
                                    <div className="col-sm-6 col-md-6">
                                        <div className="margin-top-1">
                                            <label htmlFor="swiftCode">Swift Code:</label>
                                            <div className='recommendation-input'>
                                                <input type="text"
                                                    value={addressWireInfo.swiftCode} 
                                                    name="swiftCode" className="form-control" id="swiftCode" 
                                                    readOnly
                                                />
                                            </div>

                                        </div>
                                    </div>:""
                                    }
                                </div>
                        </div>
                        <div className="modal-footer" style={{display:"block"}} >                             
                            <input type="button" id="cancelAddressWireInfoModal" className="btn btn-default btn-cancel pull-right" data-dismiss="modal" value="CLOSE" onClick={() => { }} />
                        </div>
                    </div>
                </div>
            </div>
        ); 
}
export default AddressWireInfoModal;
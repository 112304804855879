import React, { useState } from 'react';
import './ReadMore.scss';

const ReadMore = ({ text }) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text && text.slice(0, 449) : text}
      {text && text.length > 400 && <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? <><span className='trippleDots'>...</span><span>Read more</span></> : ' Show Less'}
      </span>}
    </p>
  );
};

export default ReadMore;

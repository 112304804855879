
const initialState = {
  data: null,
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_INVESTOR_SENTIMENS_DETAIL_DATA':
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

export default reducer
import React, { Component } from 'react';
import './CurrentPartners.scss'
import Header from '../../../components/Header';
import HeaderImg from '../../../components/HeaderImage';
import LoadingSpinner1 from '../../../components/IIR Loader';
import ProfileImage from '../../../components/ProfileImage';
import FileUploadBlob from '../../../components/FileUploadBlob';
import FileUploadBlobUpdate from '../../../components/FileUploadBlobUpdate';
import { withRouter } from 'react-router-dom';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import axios from 'axios';
import swal from 'sweetalert';
import common from '../../../common';
import _ from 'lodash';
import Modal from 'react-bootstrap-modal';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
// Imported Images
import images from "../../../images";

let userRole = '000';
let userRoleCode = '000';

const SortableItem = SortableElement(({ value, deleteMajor, editClick }) =>
    <div className="solutionSummaryDesc-option-mi">
        {/* logo-box */}
        <div className='solutionSummaryDesc-content-mi'>{value.name}
            <div className="leader-img-mi">
                {value && value.imageUrl ?
                    <ProfileImage
                        // id="preview1"
                        imgSrc={`${value.imageUrl}`}
                    /> :
                    <ProfileImage
                        // id="preview1"
                        imgSrc={`${common.path.base_url}/getFileNew?fileId=${value.logo}`}
                    />
                }
                {/* comapny-image */}
                <div className="icons-mi">
                    <img src={images.path.deleteIcon} onClick={() => deleteMajor(value._id)} className="delete"></img>
                    <i onClick={() => editClick(value)}
                        className="fa fa-pencil add-address"></i>
                </div>
            </div>
        </div>
    </div>);

const SortableList = SortableContainer(({ items, deleteMajor, editClick }) => {
    return (
        // logo-main
        <div className='solutionSummaryDesc-options-mi'>
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} deleteMajor={(i) => deleteMajor(i)} editClick={(value) => editClick(value)} />
            ))}
        </div>
    );
});


class CurrentPartners extends Component {
    constructor(props) {
        super(props);
        this.companyId = '';
        this.investmentId = '';
        this.state = {
            loading: false,
            headerShow: 'block',
            show: false,
            Role: '',
            name: '',
            companyName: '',
            companyNameValidation: '',
            companyName1: '',
            companyNameValidation1: '',
            profileFormValidated: false,
            userId: '',
            choosenFile: null,
            choosenFile1: null,
            imgSrc: '',
            picture: '',
            show: false,
            pictureUpdate: '',
            majorInvestorId: '',
            majorInvestorData: [],
            imageUploading: false,
            imageUploading1: false,
            enterKeyState: false,
            isUploaded: false
        }
    }

    componentWillMount() {
        const url = new URL(window.location.href);
        this.companyId = url.searchParams.get('companyId');
        this.investmentId = url.searchParams.get('investmentId');
        // this.companyId=window.localStorage.getItem('companyId');
        this.getCurrentPartnersData();
    }

    componentDidUpdate() {
        document.addEventListener('keypress', function (event) {
            if (event.keyCode === 13) {
                // event.preventDefault()
            }
        })
    }

    getIIRList() {
        let temp = this;
        temp.setState({ loadinglada1: true });
        let token = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/getInvestmentIIR/${this.investmentId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                const sampleArray = response.data.data
                const keys = Object.keys(sampleArray)
                const values = Object.values(sampleArray)
                let z = [];
                keys.forEach((key, i) => {
                    // z.push({ title: key, state: values[i] })
                    z.push(common.iirMapKey({ key, values, i }))
                })
                z.forEach((ele, i) => {
                    let title = ele.path.split(" ");
                    let path = '';
                    for (const word of title) {
                        path += word;
                    }
                    path = path.substring(0, 1).toLowerCase() + path.substring(1);
                    ele.path = path;
                })
                z.length = common.iirArrayLength;
                this.props.setIirList(z);
                // this.props.history.push(`/iir/keyCustomers?companyId=${this.companyId}`);
                temp.setState({ loadinglada1: false });

            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: "Something went wrong",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    getCurrentPartnersData() {
        let temp = this;
        temp.setState({ loading: true });
        let token = window.localStorage.getItem('token');
        const id = window.localStorage.getItem('id');
        this.setState({ id: id })
        axios.get(`${common.path.base_url}/getCustomerorInvestorInvestment/${this.investmentId}/CurrentPartners`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                this.setState({ majorInvestorData: response.data.data });

            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: "Something went wrong",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }
    addMajorInvestorData() {
        let temp = this;
        temp.setState({ loadinglada: true });
        let tokenpass = window.localStorage.getItem('token');
        axios.post(`${common.path.base_url}/addCustomerorInvestorIIRInvestment`, {
            companyId: this.companyId,
            investmentId: this.investmentId,
            type: "CurrentPartners",
            // name: temp.state.companyName,
            logo: temp.state.picture,
        }, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then(function (response) {
                temp.setState({ loadinglada: false });
                swal({
                    title: "Added",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(function () {
                        temp.getCurrentPartnersData();
                        temp.resetFields();
                        window.location.reload();
                        this.getIIRList();
                    });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: "Something went wrong",
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });

            });
    }

    uploadLogo(file) {
        if (file.size) {
            const temp = this;
            temp.setState({ imageUploading1: true })
            let tokenpass = window.localStorage.getItem('token');
            let data = new FormData();
            data.append('file', file);
            const isPrivate = true;
            data.append('isPrivate', isPrivate)
            axios.post(`${common.path.base_url}/uploadFile`, data, {
                headers: {
                    "Authorization": tokenpass
                }
            })
                .then((response) => {
                    temp.setState({ picture: response.data.data.id, isUploaded: true });
                    temp.setState({ imageUploading1: false })
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        window.localStorage.clear();
                        window.location.href = '/';
                        return;
                    } else { }
                });
        }
    }

    uploadUpdatedLogo(file) {
        if (file.size) {
            const temp = this;
            temp.setState({ imageUploading: true });
            let tokenpass = window.localStorage.getItem('token');
            let data = new FormData();
            data.append('file', file);
            const isPrivate = true;
            data.append('isPrivate', isPrivate)
            axios.post(`${common.path.base_url}/uploadFile`, data, {
                headers: {
                    "Authorization": tokenpass
                }
            })
                .then((response) => {
                    temp.setState({ pictureUpdate: response.data.data.id });
                    temp.setState({ imageUploading: false });
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        window.localStorage.clear();
                        window.location.href = '/';
                        return;
                    } else { }
                });
        }
    }

    deleteMajor(index) {
        this.setState({ enterKeyState: true })
        // if (this.state.majorInvestorData.length === 1) {
        //     swal({
        //         title: "Warning",
        //         text: "Can not delete the last data",
        //         icon: "warning",
        //         buttons: true,
        //         dangerMode: true,
        //     })
        //         .then(() => {
        //             this.setState({ enterKeyState: false })
        //         })
        // }
        // else {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willdelete) => {
                const token = localStorage.getItem('token');
                this.setState({ loading: false, enterKeyState: false })
                if (willdelete) {
                    this.setState({ loading: true })
                    axios.delete(`${common.path.base_url}/deleteIIRCustomerOrInvestor`, { headers: { "Authorization": token }, data: { id: index } }
                    )
                        .then((response) => {
                            this.setState({ loading: false })
                            swal({
                                title: "Success!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            })
                                .then(() => {
                                    this.getCurrentPartnersData();
                                });

                        })
                        .catch((error) => {
                            this.setState({ loading: false });
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else {
                                swal({
                                    title: "Error!",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });
                }
            })
        // }
    }

    updateMajorInvestor() {
        let temp = this;
        temp.setState({ loadinglada2: true });
        const token = localStorage.getItem('token');
        axios.put(`${common.path.base_url}/updateIIRCustomerorInvestorInvestment`, {
            companyId: this.companyId,
            investmentId: this.investmentId,
            type: 'CurrentPartners',
            id: this.state.majorInvestorId,
            // name: this.state.companyName1,
            logo: this.state.pictureUpdate,
        },
            {
                headers: {

                    "Authorization": token
                }
            })
            .then((response) => {
                swal({
                    title: "Updated!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(() => {
                        temp.getCurrentPartnersData();
                        temp.setState({ show: false })
                        window.location.reload()
                    })
                temp.setState({ loadinglada2: false, profileFormValidated: false });
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                    temp.setState({ loadinglada2: false });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: "Something went wrong",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    handleChange(id, newValue) {
        if (id === "companyName")
            this.setState({ companyName: newValue });
        else if (id === "companyName1")
            this.setState({ companyName1: newValue });
    }

    formValidaton(id) {
        let str = this.state.companyName
        if (id === 'companyName') {
            let name = document.getElementById('companyName').value;
            if (name === '') {
                this.setState({
                    companyNameValidation: "Name should not be empty*",
                })
            } else if (str.length > 18) {
                this.setState({
                    companyNameValidation: "Name should  not be more than 18 characters*",
                })
            } else {
                this.setState({
                    companyNameValidation: "",
                })
            }
        }
        let str1 = this.state.companyName1
        if (id === 'companyName1') {
            let name = document.getElementById('companyName1').value;
            if (name === '') {
                this.setState({
                    companyNameValidation1: "Name should not be empty*",
                })
            } else if (str1.length > 18) {
                this.setState({
                    companyNameValidation1: "Name should  not be more than 18 characters*",
                })
            } else {
                this.setState({
                    companyNameValidation1: "",
                })
            }
        }
    }

    resetFields() {
        this.setState({
            loading: false,
            companyName: '',
            companyNameValidation: '',
            companyName1: '',
            companyNameValidation1: '',
            profileFormValidated: false,
            choosenFile: null,
            choosenFile1: null,
            picture: '',
            pictureUpdate: '',
            show: false,
        })
    }

    // redirectToKeyCustumer() {
    //     this.props.history.push(`/iir/keyCustomers?companyId=${this.companyId}`);
    //     this.getIIRList();
    // }

    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState(({ majorInvestorData }) => ({
            majorInvestorData: arrayMove(majorInvestorData, oldIndex, newIndex),
        }));
        let order = this.state.majorInvestorData.map((ele, i) => {
            return ele.order
        })
        let id = this.state.majorInvestorData.map((ele, i) => {
            return ele._id
        })
        const token = localStorage.getItem('token');
        axios.put(`${common.path.base_url}/updateOrderCustomerorInvestorIIR`, {
            id: id[0],
            sequence: order,
        },
            {
                headers: {

                    "Authorization": token
                }
            })
            .then((response) => {
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else { }
            });
    }

    onEditClick(value) {
        this.setState({ show: true, companyName1: value.name, pictureUpdate: value.logo, majorInvestorId: value._id })
    }

    render() {
        return (
            <div className='majorInvestor'>
                {this.state.loading ? <LoadingSpinner1 /> : ""}
                <div className="majorInvestor-main">
                    <div className='heading'>
                        <h3>Current Partners</h3>
                    </div>
                    <div className="majorInvestor">
                        <div className='majorInvestor-details'>
                            <React.Fragment>
                                <form className="form-horizontal">
                                    <div className="form-group">
                                        <label className="col-md-3 control-label company-label">Image:</label>
                                        <div className='col-md-8'>
                                            <div className='upload-majorInvestor-pic  img-box'>
                                                <div className="majorInvestor-pic-box">
                                                    <img id='preview' className={`profile-pic ${this.state.isUploaded ? '' : 'hide-icon'}`} src={this.state.imageUploading1 ? `${images.path.loader}` : `${common.path.base_url}/getFileNew?fileId=${this.state.picture}`} alt='' />
                                                    {this.state.choosenFile !== null ?
                                                        '' : <div>
                                                            <div className="p-image">
                                                                <i onClick={() => document.querySelector(`#chooseFile${1}`).click()} className="fa fa-plus-circle upload-button"></i>
                                                                <FileUploadBlob
                                                                    id={`chooseFile${1}`}
                                                                    getFile={(file) => { this.setState({ choosenFile: file }); this.uploadLogo(this.state.choosenFile) }}
                                                                    acceptType={`image/*`}
                                                                />
                                                            </div>
                                                            <div className='logo-name'>Image</div>
                                                        </div>}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {/* <div className="form-group">
                                        <label className="col-md-3 control-label">Name:</label>
                                        <div className="col-md-8">
                                            <input className="form-control" id="companyName" type='text' placeholder='Enter Name'
                                                onKeyUp={(event) => this.formValidaton(event.target.id)}
                                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                value={this.state.companyName} />
                                            <p >{this.state.companyNameValidation}</p>
                                        </div>
                                    </div> */}
                                    <div className="form-group">
                                        <label className="col-md-3 control-label1"></label>
                                        <div className="col-md-8 addNewButton">
                                            <LaddaButton
                                                id="update-btn"
                                                loading={this.state.loadinglada}
                                                onClick={() => this.addMajorInvestorData()}
                                                data-color="#eee"
                                                data-size={XS}
                                                data-style={EXPAND_RIGHT}
                                                data-spinner-size={30}
                                                data-spinner-color="#ddd"
                                                data-spinner-lines={12}
                                                className={this.state.choosenFile === null || this.state.imageUploading1 === true ? 'disable' : ''}
                                            >
                                                ADD NEW
                                            </LaddaButton>
                                            {/* <div className="save-btn">
                                                <LaddaButton
                                                    id="update-btn"
                                                    loading={this.state.loadinglada1}
                                                    onClick={() => this.getIIRList()}
                                                    data-color="#eee"
                                                    data-size={XS}
                                                    data-style={EXPAND_RIGHT}
                                                    data-spinner-size={30}
                                                    data-spinner-color="#ddd"
                                                    data-spinner-lines={12}
                                                    className={_.isEmpty(this.state.majorInvestorData) ? 'disable' : ''}
                                                >
                                                    SAVE
                                            </LaddaButton>
                                            </div> */}
                                        </div>
                                    </div>
                                </form>
                            </React.Fragment>
                        </div>
                    </div>
                    <div className="footBorder"></div>
                    <SortableList
                        pressDelay={200}
                        axis={'xy'}
                        items={this.state.majorInvestorData}
                        onSortEnd={this.onSortEnd}
                        deleteMajor={(i) => this.deleteMajor(i)}
                        editClick={(value) => this.onEditClick(value)}
                    />
                </div>

                <Modal
                    id='updateFAQ'
                    aria-labelledby="ModalHeader"
                    show={this.state.show}
                >
                    <Modal.Header>
                        <Modal.Title id='ModalHeader'>Update Key Investors</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="majorInvestor">
                            <form className="form-horizontal">
                                <div className="form-group">
                                    <label className="col-md-3 control-label company-label">Logo:</label>
                                    <div className='col-md-8'>
                                        <div className='upload-majorInvestor-pic '>
                                            <div className="majorInvestor-pic-box">
                                                <img id='preview1' className="profile-pic" src={this.state.imageUploading ? `${images.path.loader}` : `${common.path.base_url}/getFileNew?fileId=${this.state.pictureUpdate}`} alt='' />
                                                <div>
                                                    <div className="p-image1">
                                                        <i onClick={() => document.querySelector(`#chooseFileUpdate`).click()} className="fa fa-pencil edit-button"></i>
                                                        <FileUploadBlobUpdate
                                                            id={`chooseFileUpdate`}
                                                            getFile1={(file1) => { this.setState({ choosenFile1: file1 }); this.uploadUpdatedLogo(this.state.choosenFile1) }}
                                                            acceptType={`image/*`}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* <div className="form-group">
                                    <label className="col-md-3 control-label">Name:</label>
                                    <div className="col-md-8">
                                        <input className="form-control" id="companyName1" type='text' placeholder='Enter Name'
                                            onKeyUp={(event) => this.formValidaton(event.target.id)}
                                            onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                            value={this.state.companyName1} />
                                        <p >{this.state.companyNameValidation1}</p>
                                    </div>
                                </div> */}
                            </form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <LaddaButton
                            id='lada-btn'
                            loading={this.state.loadinglada2}
                            data-color="#eee"
                            data-size={XS}
                            onClick={() => this.updateMajorInvestor()}
                            className={!_.isEmpty(this.state.pictureUpdate) && !this.state.imageUploading ? '' : 'disable'}
                            data-style={EXPAND_RIGHT}
                            data-spinner-size={30}
                            data-spinner-color="#ddd"
                            data-spinner-lines={12}
                        >UPDATE</LaddaButton>
                        <input type="button" id='company-cancel' className="btn btn-default" data-dismiss="modal" value="CANCEL" onClick={() => this.setState({ companyName1: '', inputFile1: false, show: false })} />
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
export default CurrentPartners;
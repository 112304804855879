const listView = () => {
  return {
    type: 'LIST',
  }
}
  
const gridView = () => ({
  type: 'GRID',
  
})
export const changeViewClickAction = type => {
  if (type === 'list') {
    return (dispatch) => dispatch(listView())
  } else {
    return (dispatch) => dispatch(gridView())
  }
}


import axios from 'axios';
import swal from 'sweetalert';
import common from '../../common';
export const getCompanyLogo = companyId => {
  return `${common.path.base_url}/getInvestmentCompanyLogo?companyId=${companyId}`
}

export const getInvestmentLogoFromS3 = (company) => {
  return company && company.companyProfileImage ? company.companyProfileImage : `${common.path.base_url}/getImage?type=company&id=${company.companyId ? company.companyId : company._id}`
}

export const getCompanyLogoFromS3 = (company) => {
  return company && company.companyProfileImage ? company.companyProfileImage : `${common.path.base_url}/getImage?type=company&id=${company.companyId ? company.companyId : company._id}`
}

export const viewDocument = (invitation, type) => {

  const getIIRList = () => {
    let token = window.localStorage.getItem('token');
    axios.get(`${common.path.base_url}/getIIR/${invitation.companyId}/${invitation.investmentId}`, {
      headers: {
        "Authorization": token
      }
    })
      .then((response) => {
        if (response && response.data && response.data.data && response.data.data.iirPDF) {
          window.location.href = `iirPreviewPDF?companyId=${invitation.companyId}&investmentId=${invitation.investmentId}`;
        } else {
          window.location.href = `/previewIIR?companyId=${invitation.companyId}&investmentId=${invitation.investmentId}`
        }

      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
          window.localStorage.clear();
          window.location.href = '/';
          return;
        }
        else if (error && error.response && error.response.data && error.response.data.code === 204) {
          window.localStorage.clear();
          window.location.href = '/';
        }
        else if (error.response) {
          swal({
            title: "Error!",
            text: (error && error.response && error.response.data && error.response.data.code) ? error.response.data.message : error.response,
            icon: "error",
            button: "Ok!",
          });
        }
        else {
          swal({
            title: "Network Error!",
            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }

  switch (type) {
    case 'IIR':
      getIIRList();
      return;
    // return window.location.href = `iirPreviewPDF?companyId=${invitation.companyId}&investmentId=${invitation.investmentId}`;
    case 'executiveLetter':
      return window.location.href = `/foundLetterPreview?companyId=${invitation.companyId}&investmentId=${invitation.investmentId}`
    case 'FAQ':
      return window.location.href = `/investorFaqPreview`
    default:
  }

}
export const mapDateFormat = mongoDate => {
  if (!mongoDate) return 'N/A';
  let date = new Date(mongoDate);
  const monthNames = [
    "01", "02", "03",
    "04", "05", "06", "07",
    "08", "09", "10",
    "11", "12"
  ];
  let day = date.getDate();
  let monthIndex = date.getMonth();
  let year = date.getFullYear();
  return monthNames[monthIndex] + '/' + day + '/' + year;

}
export const getUserInvestmentAmount = (userId, userInvestments) => {
  if (userInvestments && userInvestments.length > 0) {
    const investment = userInvestments.filter(investment => investment.investorId === userId);
    if (investment && investment.length > 0) {
      return investment[0].amount;
    } else {
      return null;
    }
  } else {
    return null;
  }
}
export const companyValutionFormat = valuation => {
  if (valuation) {
    const valuationArray = valuation.split('.');
    if (valuationArray.length > 1) {
      return valuationArray[0];
    }
    return valuation;

  }
  return 'N/A';
}
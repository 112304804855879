import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import CustomModal from '../../components/CustomModal'
import './forgotPassword.scss';
import images from '../../images';
import axios from 'axios';
import swal from 'sweetalert';
import common from '../../common';
import LoadingSpinner from '../../components/Loader';
import LaddaButton, { XL, EXPAND_RIGHT } from 'react-ladda';
import Spinner from 'react-bootstrap/Spinner';
import _ from 'lodash';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eye: false,
      loading: false,
      userToken: '',
      enterpassValidation: '',
      confirmpassValidation: '',
      invitationFromValidated: false
    };
    this.routeChange = this.routeChange.bind(this);
  }
  componentWillMount() {
    const url = new URL(window.location.href);
    const queryUserToken = url.searchParams.get('token');
    if (_.isEmpty(queryUserToken)) {
      this.props.history.push('/');
    }
    else {
      this.setState({ userToken: queryUserToken })
    }
  }
  routeChange(event) {
    //document.getElementById("forgotPasswordForm").preventDefault();
    event.preventDefault();
    this.setState({
      loading: !this.state.loading,
      progress: 0.5,
    });
    let formValid = false;
    const pass1 = document.getElementById("password1").value;
    const pass2 = document.getElementById("password2").value;
    var exp1 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    // var exp2 = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var res1 = exp1.test(pass1);
    var res2 = exp1.test(pass2);
    if (pass1 === "" || res1 !== true) {
      formValid = false;
    }
    else if (pass2 === "" || res2 !== true) {
      formValid = false;
    }
    // else if(pass1!==pass2)
    // {
    //   formValid = false;
    // }
    else {
      formValid = true;
    }
    if (formValid) {

      //we need to assign this to a variable because during the api call, this becomes null
      let temp = this;
      temp.setState({ loading: true });
      axios.put(`${common.path.base_url}/changePassword`, {
        // _id: tokenpass,
        newPass: pass1,
        confirmPass: pass2
      }, {
        headers: {
          "Accept": "application/json",
          "Content-type": "application/json",
          "Authorization": this.state.userToken
        }
      })
        .then(function (response) {
          if (response.data.error === false) {
            swal({
              title: "Password Changed",
              text: response.data.message,
              icon: "success",
              button: "Ok!",
            })
              .then(function () {
                temp.setState({ loadinglada: false });
                window.location = common.path.base_url2;
              });
            // temp.props.history.push('/');
            //window.location = common.path.base_url2;
          }
        })
        .catch(function (error) {
          temp.setState({ loading: false });
          if (error.response) {
            swal({
              title: "Error!",
              text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
          else {
            swal({
              title: "Network Error!",
              text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        });
    }
    else {
      // document.getElementById("submitButton").disabled = true;
      //sweetalert
      swal({
        title: "Error!",
        text: "The password should have atleast one capital letter, one alphanumeric character and one number",
        icon: "error",
        button: "Ok!",
      });
      this.setState({ loading: false });
    }
  }
  getFullYear() {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }
  passwordMatch() {
    let password = document.getElementById("password1").value;
    let list = document.querySelector("instructions");
    //  list.style.color = "green";
    if (password.length < 8) {
      document.getElementById("first").style.color = "red";
    }
    else {
      document.getElementById("first").style.color = "green";
    }
    if (/[0-9]/.test(password)) {
      document.getElementById("second").style.color = "green";
    }
    else {
      document.getElementById("second").style.color = "red";
    }
    if (/[!@#\$%\^&]/.test(password)) {
      document.getElementById("third").style.color = "green";
    }
    else {
      document.getElementById("third").style.color = "red";
    }
    if (/[A-Z]/.test(password)) {
      document.getElementById("fourth").style.color = "green";
    }
    else {
      document.getElementById("fourth").style.color = "red";
    }
  }
  formValidaton(id) {
    let validateFlag = true;
    if (id === 'password1') {
      let pass1 = document.getElementById('password1').value;
      var exp1 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
      var res1 = exp1.test(pass1);
      if (pass1 === '') {
        this.setState({
          enterpassValidation: "Password should not be empty*"
        })
        validateFlag = false;
      }
      else if (res1 !== true) {
        this.setState({
          enterpassValidation: "Enter valid Password*"
        })
        validateFlag = false;
      }
      else {
        this.setState({
          enterpassValidation: ""
        })
      }
    }
    else if (id === 'password2') {
      let pass1 = document.getElementById('password1').value;
      let pass2 = document.getElementById('password2').value;
      if (pass2 === '') {
        this.setState({
          confirmpassValidation: "Password should not be empty*",

        })
        validateFlag = false;
      }
      else if (pass1 !== pass2) {
        this.setState({
          confirmpassValidation: "Password does not match*",

        })
        validateFlag = false;
      }
      else {
        this.setState({
          confirmpassValidation: "",
          invitationFromValidated: true

        })
      }
    }
    this.setState({ invitationFromValidated: validateFlag });
  }
  render() {
    return (
      <div className="forgotPasswordCss">
        <Helmet>
          <meta charSet="utf-8" />
          <title>TSG</title>
        </Helmet>
        <CustomModal />
        <div className="login-container">
          {/* {this.state.loading ? <LoadingSpinner /> :""} */}
          <div className="LoginImage">
            <img src={images.path.login} alt=''></img>

          </div>
          <div className="LoginCredentials">
            <div className="login">Forgot Password</div>
            <form id="forgotPasswordForm">
              <div className="form-group">
                <label className="control-label">New Password</label>
                {/* <div className="col-md-8"> */}
                <input className="form-control" type="password"
                  id="password1"
                  name="username"
                  placeholder=""
                  onKeyUp={(event) => this.formValidaton(event.target.id)}
                  onKeyUp={this.passwordMatch}
                />
                <p >{this.state.enterpassValidation}</p>
                {/* <i className="fa fa-envelope icon"></i>  */}
                {/* </div> */}
              </div>
              <div className="form-group">
                <label className="control-label">Instructions</label>
                {/* <div className="col-md-8"> */}
                <ul id="instructions">
                  <li id="first">8 characters</li>
                  <li id="second">1 number</li>
                  <li id="third">1 non-alphanumeric (!, @, #, $, etc.)</li>
                  <li id="fourth">1 upper-case</li>
                </ul>
                {/* <i className="fa fa-envelope icon"></i>  */}
                {/* </div> */}
              </div>
              <div className="form-group">
                <label className="control-label">Confirm New Password</label>
                {/* <div className="col-md-8"> */}
                <input className="form-control" id="password2"
                  type="password"
                  name="password"
                  onKeyUp={(event) => this.formValidaton(event.target.id)}
                  placeholder="" />
                <p >{this.state.confirmpassValidation}</p>
              </div>
              {/* <button
                  type="button"
                  className="btn login-btn"
                  onClick={this.routeChange}
                >CHANGE PASSWORD</button> */}
              <LaddaButton
                loading={this.state.loading}
                onClick={this.routeChange}
                className={!this.state.invitationFromValidated ? 'disable' : ''}
                data-color="#eee"
                data-size={XL}
                data-style={EXPAND_RIGHT}
                data-spinner-size={30}
                data-spinner-color="#ddd"
                data-spinner-lines={12}
              >
                Change Password
      </LaddaButton>
            </form>

            <div className="copyright">
              Copyright © {this.getFullYear()} The Syndicate Group. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// const mapStateToProps = ({}) => ({

//         });
// export default connect(mapStateToProps, {})(LoginPage);
export default (ForgotPassword);

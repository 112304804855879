import React, { Component } from 'react';
import CSVReader from 'react-csv-reader';
import './ImportContacts.scss';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import FileUploadBlobDoc from '../../../components/FileUploadBlobDoc';
import RequiredSign from '../../../components/RequiredSign';
import axios from 'axios';
import swal from 'sweetalert';
import common from '../../../common';



class ImportContactsModal extends Component {
    constructor() {
        super();
        this.state = {
            investmentData: null,
            companyData: null,
            choosenUploadFile: null,
            validationMessage: null,
        }

        this.papaparseOptions = {
            delimiter: "",	// auto-detect
            newline: "",	// auto-detect
            skipEmptyLines: true,
            beforeFirstChunk: function (results, file) {
                console.log("Parsing beforeFirstChunk:", results, file);
            }

        }
    }

    componentWillReceiveProps(nextProps) {
        nextProps.onRef && nextProps.onRef(this);

    }
    setInvestmentData(investmentData, companyData, callback) {
        this.setState({ investmentData, companyData, validationMessage: null })
        setTimeout(() => {
            document.getElementById('file-selector').addEventListener('change', (event) => {
                const file = event.target.files;
                this.setState({ choosenUploadFile: file[0] }, this.handleRequiredFieldValidation());
            });
            callback()
        }, 100);

    }
    downloadDemoCSVFile() {
        window.open(` ${common.path.doc_download_url}/downloadDemoCSVFile`, '_blank')

    }
    resetData() {
        this.setState(
            {
                investmentData: null,
                companyData: null,
                choosenUploadFile: null,
                validationMessage: null,
            })
    }
    handleRequiredFieldValidation() {
        setTimeout(() => {
            if (this.state.choosenUploadFile) {
                this.setState({ validInvestmentForm: true })
            } else {
                this.setState({ validInvestmentForm: false })
            }
        }, 100);
    }


    importContacts() {
        let token = window.localStorage.getItem('token');
        let temp = this;
        temp.setState({ loadinglada: true });
        const file = this.state.choosenUploadFile;
        console.log(78, file);
        let data = new FormData();
        data.append('file', file);
        const param = JSON.stringify({
            companyId: this.state.companyData._id,
            investmentId: this.state.investmentData._id,
            createdFor: this.state.investmentData.name,
        })
        data.append('params', param);
        axios.post(`${common.path.base_url}/imortContacts`, data,
            {
                headers: {

                    "Authorization": token
                }
            })
            .then(function (response) {
                temp.setState({ loadinglada: false });
                document.getElementById('cancelUploadDoc').click();
                console.log(response.data.data, 77);
                temp.props.onImportContactsCallback(response.data.data)
                // document.getElementById
                // swal({
                //     title: "Success!",
                //     text: ,
                //     icon: "success",
                //     button: "Ok!",
                // });

            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response && error.response.data && error.response.data.code && error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });
            });
    }
    isEmpty(arr) {
        return arr.every(function (v) { return v === ""; });
    }

    onFileLoadedHandler(data, fileInfo) {
        if (fileInfo && fileInfo.type && fileInfo.type === 'text/csv') {
            // console.log(fileInfo);
            const keys = data[0];
            const headerFormatKeys = ["First Name", "Last Name", "Title", "Address", "Company", "Email", "Mobile", "Office", "Notes"];
            if (JSON.stringify(keys) === JSON.stringify(headerFormatKeys)) {
                //arrays are equal
                console.log('arrays are equal');
                // console.log(keys);
                data.shift();
                if (data && data.length > 0) {
                    const csvData = [];
                    data.forEach(element => {
                        if (!this.isEmpty(element)) {
                            const merged = keys.reduce((obj, key, index) => ({ ...obj, [key]: element[index] }), {});
                            console.log(139, merged);
                            csvData.push(merged);
                        }
                    });
                    const vailidateCSVData = []
                    if (csvData && csvData.length > 0) {
                        csvData.forEach(item => {
                            console.log(128, item);
                            let isValidate = true;
                            if (item && item['First Name'] === '') {
                                console.log(148, item['First Name']);
                                isValidate = false;
                            }
                            if (item && item['Last Name'] === '') {
                                console.log(149, item['Last Name']);
                                isValidate = false;
                            }
                            if (item && item['Email'] === '') {
                                console.log(150, item['Email']);
                                isValidate = false;
                            }
                            if (item && item['Email']) {
                                let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                let res = emailRegex.test(item['Email'].trim());
                                if (res !== true) {
                                    console.log(151, item['Email']);
                                    isValidate = false;
                                }
                            }
                            if (isValidate) {
                                vailidateCSVData.push(item);
                            }
                        })
                        console.log(vailidateCSVData.length, csvData.length);
                        if (vailidateCSVData.length !== csvData.length) {
                            this.setState({ validationMessage: 'The CSV file do not have some required fields (first name, last name, email) available. Please fill in those details and retry.' })
                        } else {
                            console.log(fileInfo);
                            // this.setState({ choosenUploadFile: fileInfo }, this.handleRequiredFieldValidation());
                            this.handleRequiredFieldValidation();
                        }
                    } else {
                        this.setState({ validationMessage: 'The CSV file is incorrect' })
                    }
                } else {
                    this.setState({ validationMessage: 'The CSV file is incorrect' })
                }
            } else {
                console.log('arrays are not equal');
                this.setState({ validationMessage: 'The CSV file format is incorrect. Please see our Demo file and match exactly same.' })
            }
        } else {
            // Invaild File Type
            this.setState({ validationMessage: 'File format is not vaild' })
        }
    }



    render() {
        return (
            <>
                {
                    this.state.investmentData &&
                    this.state.companyData &&
                    <div id="importContactsModal" className="modal fade">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Import Contacts</h4>
                                </div>
                                {!this.state.validationMessage ?
                                    <div className="modal-body">
                                        <div className="form-horizontal" id=' myform'>
                                            <div className="form-group">
                                                <label className="col-sm-3  col-md-3 control-label" style={{ "paddingLeft": "0px" }}>Startup :</label>
                                                <div className="col-sm-8 col-md-8 upload-section" style={{ "padding": "0px", "border": "none" }}>

                                                    <div className="upload-block">
                                                        {this.state.companyData.companyName}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-sm-3  col-md-3 control-label" style={{ "paddingLeft": "0px" }}>Investment :</label>
                                                <div className="col-sm-8 col-md-8 upload-section" style={{ "padding": "0px", "border": "none" }}>

                                                    <div className="upload-block">
                                                        {this.state.investmentData.name}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="col-sm-12 col-md-12 upload-section" style={{ "padding": "0px", "border": "none" }}>

                                                    <div className="upload-block" style={{ display: 'inline-block' }}>
                                                        Please download the <a style={{ cursor: 'pointer' }} onClick={() => { this.downloadDemoCSVFile() }}>Demo File</a> and make sure to upload .CSV file in the exact same format.
                                            </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="col-sm-3  col-md-3 control-label" style={{ "paddingLeft": "0px" }}>Choose File <RequiredSign /> :</label>
                                                <div className="col-sm-8 col-md-8 upload-section" style={{ "padding": "0px", "border": "none" }}>

                                                    <div className="upload-block">
                                                        {/* <div className="choose-file" style={{ "width": "100%", "marginRight": "0px" }} onClick={() => document.querySelector('#documentUpload').click()}>{(this.state.choosenUploadFile && this.state.choosenUploadFile.name) || `Select File`}</div> */}
                                                        {/* <FileUploadBlobDoc acceptType=".csv"  
                                                    getFile={(file) => {
                                                        this.setState({ choosenUploadFile: file }, this.handleRequiredFieldValidation());
                                                        console.log(file);
                                                    }
                                                    }
                                                id="documentUpload" /> */}
                                                        <CSVReader
                                                            cssClass="csv-reader-input"
                                                            onFileLoaded={(data, fileInfo) => this.onFileLoadedHandler(data, fileInfo)}
                                                            parserOptions={this.papaparseOptions}
                                                            inputId="file-selector"
                                                            inputStyle={{ color: 'red' }}
                                                        />

                                                    </div>
                                                </div>

                                            </div>

                                            <div className="modal-footer">
                                                <input type="button" id="cancelUploadDoc" className="btn btn-default" data-dismiss="modal" onClick={() => this.resetData()} value="CANCEL" />
                                                <LaddaButton
                                                    id='lada-btn'
                                                    loading={this.state.loadinglada}
                                                    onClick={() => this.importContacts()}
                                                    data-color="#eee"
                                                    data-size={XS}
                                                    className={!this.state.validInvestmentForm ? 'disable' : ''}
                                                    data-style={EXPAND_RIGHT}
                                                    data-spinner-size={30}
                                                    data-spinner-color="#ddd"
                                                    data-spinner-lines={12}
                                                >
                                                    UPLOAD
                                                </LaddaButton>

                                            </div>
                                        </div>
                                    </div> :
                                    <div className="modal-body">
                                        <div className="form-group" style={{ color: 'red' }}>
                                            {
                                                this.state.validationMessage === 'The CSV file format is incorrect. Please see our Demo file and match exactly same.' ?
                                                    <div>
                                                        The CSV file format is incorrect. Please see our <a style={{ cursor: 'pointer' }} onClick={() => { this.downloadDemoCSVFile() }}>Demo File</a> and match exactly same.
                                    </div> :
                                                    this.state.validationMessage
                                            }

                                        </div>
                                        <div className="modal-footer">
                                            <input type="button" id="cancelUploadDoc" className="btn btn-default" data-dismiss="modal" onClick={() => this.resetData()} value="DONE" />
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}
export default ImportContactsModal;
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import ReactTags from 'react-tag-autocomplete';
import common from '../../common';
import global from '../../images/global.png';
import downarrow from '../../images/icons/downarrow.svg';
import exitright from '../../images/icons/exitright.svg';
import search from '../../images/icons/search.svg';
import warning from '../../images/icons/warning.svg';
import './StartupDetail.scss';

class DisbursmentUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [
                { name: 'Previously invested in this Startup investment ', id: 1, isChecked: false },
                { name: 'Referred by Startup ', id: 2, isChecked: false },
                { name: 'Previously invested in any TSG investment', id: 3, isChecked: false },
                { name: 'Previously invited to a TSG investment', id: 4, isChecked: false },
            ],
            pagination: {
                currentPage: 0,
                totalPages: 1,
            },
            isFilterActive: false,
            tags: [],
            searchInputTags: [],
            suggestions: [
                { id: 'YPO', name: 'YPO' },
                { id: 'Institutional', name: 'Institutional' },
                { id: 'Strategic', name: 'Strategic' },
                { id: 'Preferred', name: 'Preferred' },
                { id: 'Investment(s)', name: 'Investment(s)' }],
            arrowType: {
                name: 'down',
                emailAddress: 'down',
                company: 'down',
            },
            $sort: null,
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, false);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
    }
    handleScroll = () => {
        if ((Math.round(window.innerHeight + document.documentElement.scrollTop) === document.documentElement.offsetHeight)
            && (this.state.pagination.currentPage < this.state.pagination.totalPages && !this.state.isFilterActive)) {
        }
    };
    mapTags(tags) {
        if (!tags) {
            tags = this.state.searchInputTags;
        }
        let tagIds = []
        if (tags && tags.length > 0) {
            tagIds = tags.map(tag => tag.name)
        }
        return tagIds;
    }
    handleDelete(i) {
        const tags = this.state.tags.slice(0)
        tags.splice(i, 1)
        this.setState({ tags });
    }
    handleAddition(tag) {
        if (tag && tag.id && tag.name) {
            const isExist = this.state.tags.filter(item => item.id === tag.id);
            if (isExist && isExist.length > 0) {
                return;
            }
            const tags = [].concat(this.state.tags, tag);
            this.setState({ tags });
        }
    }
    handleSearchInputDelete(i) {
        const tags = this.state.searchInputTags.slice(0)
        tags.splice(i, 1)
        this.setState({ searchInputTags: tags }, () => {
            this.props.getDisbursementUsers(false, this.mapTags())
        });
    }
    handleSearchInputAddition(tag) {
        const tags = [].concat(this.state.searchInputTags, tag);
        this.setState({ searchInputTags: tags }, () => {
            this.props.getDisbursementUsers(false, this.mapTags())
        });
    }
    toggleArrow(fieldType) {
        let arrowType = this.state.arrowType;
        let $sort = {};
        if (arrowType[fieldType] === 'down') {
            arrowType[fieldType] = 'up';
            $sort[fieldType] = -1;
        } else {
            arrowType[fieldType] = 'down';
            $sort[fieldType] = 1;
        }
        const selectedFilters = this.mapSelectedFilter(this.state.options);
        this.props.getDisbursementUsers(selectedFilters, this.state.searchInputTags, this.mapTags(), $sort);
        this.setState({ arrowType, $sort });
    }
    mapDateFormat(mongoDate) {
        if (!mongoDate) return 'N/A';
        const monthNames = [
            "01", "02", "03",
            "04", "05", "06", "07",
            "08", "09", "10",
            "11", "12"
        ];
        let date = new Date(mongoDate);
        let day = date.getDate();
        let monthIndex = date.getMonth();
        let year = date.getFullYear();
        return monthNames[monthIndex] + '/' + day + '/' + year;

    }
    viewDocumentRedirect(envId, type) {
        window.open(` ${common.path.doc_download_url}/downloadPDF?envId=${envId}&type=${type}`, '_blank', '', 2)

    }
    getUserCitizenForm(isForeignCitizen) {
        return isForeignCitizen === true ? 'W8' : 'W9'
    }

    render() {
        return (
            <div className="startup-box-wrapper-main margin-zero">
                <div className='header-investment exit-in-process'>
                    <div className="row searchpaddingtwopart">
                        <div className='search' style={{ width: '88%', position: 'relative' }}>
                            <ReactTags
                                allowNew={true}
                                placeholder='Search'
                                suggestions={this.state.suggestions}
                                tags={this.state.searchInputTags}
                                handleDelete={this.handleSearchInputDelete.bind(this)}
                                handleAddition={this.handleSearchInputAddition.bind(this)}
                            />
                            <img alt="" src={search} className="search-icon"></img>
                        </div>

                        <div className="all">
                            <div className="select-box">
                                <div className='status'>
                                    <select className='status-box' onChange={(event) => this.props.getDisbursementUsers(false, this.mapTags(), this.state.$sort, event.target.value)}>
                                        <option value="all">All Investment</option>
                                        {this.props.companyInvestmentsData && this.props.companyInvestmentsData.map((item) => {
                                            return <option value={item.id}>{item.name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="custom-table-view table-responsive table-disbursement">
                        <table className="table table-custom sortable">
                            <thead>
                                <tr>
                                    <th>INVESTOR <img alt="" src={downarrow} className="menu-icon"></img></th>
                                    <th>COMPANY NAME <img alt="" src={downarrow} className="menu-icon"></img></th>
                                    <th>GROSS INVESTMENT AMOUNT <img alt="" src={downarrow} className="menu-icon"></img></th>
                                    <th>INITIAL DISBURSEMENT AMOUNT <img alt="" src={downarrow} className="menu-icon"></img></th>
                                    <th>INITIAL DISBURSEMENT DATE <img alt="" src={downarrow} className="menu-icon"></img></th>
                                    {
                                        this.props.isEscrow ?
                                            <>
                                                <th>ESCROW RETURN AMOUNT<img alt="" src={downarrow} className="menu-icon"></img></th>
                                                <th>ESCROW DISBURSEMENT DATE<img alt="" src={downarrow} className="menu-icon"></img></th>
                                            </>
                                            : <></>
                                    }
                                    <th>DISBURSEMENT STATUS</th>
                                    <th>VIEW DOC.</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.disbursmentUsers.length > 0 &&
                                    this.props.disbursmentUsers.map(user => {
                                        const disbursementStaus = user.disbursementFunded2 && user.disbursementFunded2 === "Completed" ? user.disbursementFunded2 : user.disbursementFunded;

                                        return (
                                            <tr key={user._id}>
                                                <td>
                                                    <div className='row d-flex' style={{
                                                        justifyContent: "start",
                                                        flexDirection: 'row',
                                                        paddingLeft: '15px',
                                                    }}>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}>
                                                            <span className='cursor' onClick={() => { this.props.showDataInPopup(user.user._id) }}>{user.name}</span>
                                                            <span className='cursor' style={{ textDecoration: "underline" }} onClick={() => { this.props.showDataInPopup(user.user._id) }}>{user.emailId}</span>
                                                        </div>
                                                        <div className={user.isAcountVerified ? "tooltipcover" : this.props.investmentData.exitProcessType === 'ipo' ? "tooltipcover-brokerage" : "tooltipcover-notverified"}>
                                                            <span className="tooltiptext">{user.isAcountVerified ? 'Verified' : this.props.investmentData.exitProcessType === 'ipo' ? 'Brokerage account not verified' : 'Address not verified'}</span>
                                                            <img src={user.isAcountVerified ? exitright : warning} className="menu-icon"></img>
                                                        </div>
                                                        {user.isForeignCitizen && <div className="tooltipcover">
                                                            <span className="tooltiptext">International Investor</span>
                                                            <img src={global} className="menu-icon" style={{ width: '13px', height: '13px' }}></img>
                                                        </div>}
                                                    </div>
                                                </td>
                                                <td>
                                                    {user.companyName}
                                                </td>
                                                <td>
                                                    <NumberFormat
                                                        value={user.amount}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'} />

                                                </td>
                                                <td>
                                                    <NumberFormat
                                                        value={Number(user.disbursement1).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'} />
                                                </td>
                                                <td>
                                                    {this.mapDateFormat(user.disbursementDate)}
                                                </td>
                                                {
                                                    this.props.isEscrow ?
                                                        <>
                                                            <td>
                                                                <NumberFormat
                                                                    value={Number(user.disbursement2).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    prefix={'$'} />
                                                            </td>
                                                            <td>
                                                                {this.mapDateFormat(user.escrowDisbursementDate)}
                                                            </td>
                                                        </>
                                                        : <></>
                                                }
                                                <td>
                                                    <span style={{ color: "#00CC00" }}>{(disbursementStaus).toString().toUpperCase()}</span>
                                                </td>
                                                <td>
                                                    <a style={{ color: '#F15B4B', cursor: 'pointer' }} onClick={() => this.viewDocumentRedirect(user.subscriptionEnvId, 'Subscription')}>View QSA</a><br />
                                                    <a style={{ color: '#F15B4B', cursor: 'pointer' }} onClick={() => this.viewDocumentRedirect(user.w9EnvId, this.getUserCitizenForm(user.isForeignCitizen))}>{user.isForeignCitizen ? 'View W-8' : 'View W-9'}</a>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    this.props.disbursmentUsers.length === 0 &&
                                    <tr>
                                        <td> No investors found!</td>
                                    </tr>
                                }

                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        );
    }

}

export default DisbursmentUsers;
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import Header from '../../../components/Header';
import { hideSidebarNotification } from '../../actions/hideSidebarNotifications';
import InvestmentProcessContainer from '../../containers/InvestmentProcess.container';
import './InvestmentProcess.scss';

const App = props => {
  const hideNotificationSidebar = () => {
    props.hideSidebarNotification()
  }
  return (
    <div onClick={() => hideNotificationSidebar()}>
      <Header
        username={window.localStorage.getItem('username')}
        role={window.localStorage.getItem('role')}
      />
      <InvestmentProcessContainer />

    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    hideSidebarNotification: bindActionCreators(hideSidebarNotification, dispatch),

  };
};
export default withRouter(connect(null, mapDispatchToProps)(App));

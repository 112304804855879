import React, { Component } from 'react';
import Avtar from '../../components/avtar';
import images from "../../images";
import './Header without login.scss'

class HeaderWL extends Component {
    render() {
        const userName = window.localStorage.getItem('username');
        const userRole = window.localStorage.getItem('role');
        return (
            <div className="header-wl">
            <nav className="navbar navbar-default navbar-expand-xl navbar-light">
                <div className="navbar-header d-flex col logo">
                    <img src={images.path.productLogo} alt='' />
                </div>
            </nav>
            </div>

        );
    }
}

export default HeaderWL;
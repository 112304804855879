import common from '../../common';
import apiService from '../shared/services/api.service';


/* global window */

export default class AuthService {

  static notParticipationHandler(userId) {
    return new Promise((resolve) => {
      resolve(apiService.get({
        endpoint: `${common.path.base_url}/notParticipate/${userId}`,
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }
  static newInvitationHandler(userId) {
    return new Promise((resolve) => {
      resolve(apiService.get({
        endpoint: `${common.path.base_url}/newInvitation/${userId}`,
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }
  static pendingCountersignHandler(userId) {
    return new Promise((resolve) => {
      resolve(apiService.get({
        endpoint: `${common.path.base_url}/pendingCounterSignedInvestments/${userId}`,
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }
  static myInvestmentsHandler(userId) {
    return new Promise((resolve) => {
      resolve(apiService.get({
        endpoint: `${common.path.base_url}/myInvestments/${userId}`,
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }
  static myInvestmentsFilterHandler(stage) {
    return new Promise((resolve) => {
      resolve(apiService.get({
        endpoint: `${common.path.base_url}/myInvestmentsFilter/${window.localStorage.getItem('id')}/${stage}`,
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }

  static exitedInvestmentsHandler(userId) {
    return new Promise((resolve) => {
      resolve(apiService.get({
        endpoint: `${common.path.base_url}/exitedInvestments/${userId}`,
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }

  static exitInProcessInvestmentsHandler(userId) {
    return new Promise((resolve) => {
      resolve(apiService.get({
        endpoint: `${common.path.base_url}/exitInProcessInvestments/${userId}`,
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }

  static pipelineInvestments(userId) {
    return new Promise((resolve) => {
      resolve(apiService.get({
        endpoint: `${common.path.base_url}/pipelineInvestments/${userId}`,
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }

  static investorInterest({ isParticipate, processId }) {
    return new Promise((resolve) => {
      resolve(apiService.post({
        endpoint: `${common.path.base_url}/userInterest/${processId}`,
        body: { isParticipate },
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }

  static investorSntimentsInterest({ companyId, userId, interestType, name }) {
    return new Promise((resolve) => {
      resolve(apiService.post({
        endpoint: `${common.path.base_url}/pipelineInvestments/${userId}`,
        body: { companyId, userId, interestType, name },
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }

  static saveAddtionalThoughts({ comment, companyId, userId }) {
    return new Promise((resolve) => {
      resolve(apiService.put({
        endpoint: `${common.path.base_url}/saveAddtionalThought/${companyId}/${userId}`,
        body: { comment },
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }

  static getUserInvestmentProcess(investmentId) {
    return new Promise((resolve) => {
      resolve(apiService.get({
        endpoint: `${common.path.base_url}/getUserInvestmentProcessById/${window.localStorage.getItem('id')}/${investmentId}`,
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }
  static getUserData(_id) {
    return new Promise((resolve) => {
      resolve(apiService.get({
        endpoint: `${common.path.base_url}/getUserById?_id=${_id}`,
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }
  static getSettings() {
    return new Promise((resolve) => {
      resolve(apiService.get({
        endpoint: `${common.path.base_url}/settings`,
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }
  static signInvestmentProcess(body) {
    return new Promise((resolve) => {
      resolve(apiService.post({
        endpoint: `${common.path.base_url}/signInvestmentProcess`,
        body,
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }

  static signInvestmentDoc(id) {
    return new Promise((resolve) => {
      resolve(apiService.post({
        endpoint: `${common.path.base_url}/signInvestmentDoc/${id}`,
        body: {},
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }

  static getUserById(_id) {
    return new Promise((resolve) => {
      resolve(apiService.get({
        endpoint: `${common.path.base_url}/getUserById?_id=${_id}`,
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }
  static recommendations() {
    return new Promise((resolve) => {
      resolve(apiService.get({
        endpoint: `${common.path.base_url}/recommendations`,
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }
  static saveRecommendation(data) {
    return new Promise((resolve) => {
      resolve(apiService.post({
        endpoint: `${common.path.base_url}/saveRecommendation`,
        body: data,
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }


  static jwtAuthorizationHeader() {
    return 'JWT '.concat(window.localStorage.getItem('token'));
  }
}

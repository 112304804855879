import React, { Component } from 'react';
import './ClientEngLetter.scss';
import Select from 'react-select';
import HeaderImage from '../../components/HeaderImage';
import LoadingSpinner from '../../components/Loader';
import Header from '../../components/Header';
import { withRouter } from 'react-router-dom';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import axios from 'axios';
import swal from 'sweetalert';
import common from '../../common';
import _ from 'lodash';


class ClientEngLetter extends Component {
    constructor(props) {
        super(props);
        this.companyId = '';
        this.state = {
            name: '',
            Role: '',
            clientEngAgr: '',
            clientEngAgrValidation: '',
            fullName: '',
            fullNameValidation: '',
            date: '',
            dateValidation: '',
            stateOfIncorporation: [{ value: '1', label: '1' }, { value: '2', label: '2' }, { value: '3', label: '3' }],
            typeOfOffering: [{ value: '1', label: '1' }, { value: '2', label: '2' }, { value: '3', label: '3' }],
            typeOfStock: [{ value: '1', label: '1' }, { value: '2', label: '2' }, { value: '3', label: '3' }],
            selectedTypeOfStock: '',
            selectedStateOfIncorporation: '',
            selectedTypeOfOffering: '',
            allocation: '',
            allocationValidation: '',
            compensation: '',
            compensationValidation: '',
            adminCost: '',
            adminCostValidation: '',
            servicesFee: '',
            servicesFeeValidation: '',
            bodyOfLetter: '',
            bodyOfLetterValidation: '',
            preferredStockTerms: '',
            preferredStockTermsValidation: '',
            loading: false,
            loadinglada: false,
            formValidated: false,
        }
    }

    componentWillMount() {
        const url = new URL(window.location.href);
        this.companyId = url.searchParams.get('companyId');
        // this.companyId=window.localStorage.getItem('companyId');
        // this.getData();
        this.setState({
            name: window.localStorage.getItem('username'),
            Role: window.localStorage.getItem('role')
        });
    }

    getData() {
        let temp = this;
        temp.setState({ loading: true });
        const id = window.localStorage.getItem('id');
        let token = window.localStorage.getItem('token');
        this.setState({ id: id })
        axios.get(`${common.path.base_url}/getIIRCompany?companyId=${this.companyId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                const companyDetails = response.data.data[0];
                this.setState({
                    name: companyDetails.companyName,
                    selectedtype: [{ value: companyDetails.companyType, label: companyDetails.companyType }],
                    address: companyDetails.address,
                    website: companyDetails.website,
                    phone: companyDetails.mobile,
                    overview: companyDetails.overview,
                    companyLogo: companyDetails.companyLogo
                });
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                  }
              else  if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    updateCompanyData() {
        let temp = this;
        temp.setState({ loadinglada: true });
        const token = localStorage.getItem('token');
        axios.put(`${common.path.base_url}/updateIIRCompany`, {
            companyId: this.companyId,
            companyName: this.state.name,
            companyType: this.state.selectedtype[0] === undefined ? this.state.selectedtype.label : this.state.selectedtype[0].label,
            address: this.state.address,
            website: this.state.website,
            mobile: this.state.phone,
            overview: this.state.overview,
            companyLogo: this.state.companyLogo
        },
            {
                headers: {

                    "Authorization": token
                }
            })
            .then((response) => {
                swal({
                    title: "Updated!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(() => {
                        temp.getData();
                        temp.getIIRList()
                    })
                temp.setState({ profileFormValidated: false });
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                    temp.setState({ loadinglada: false });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    renderForm = () => {
        return (
            <form className="form-horizontal">
                <div className="form-group">
                    <label className="col-md-3 control-label">Client Engagement Agreement:</label>
                    <div className="col-md-8">
                        <input className="form-control" name='clientEngAgr' id="clientEngAgr" type="text" placeholder=''
                            onKeyUp={this.formValidaton}
                            onChange={this.handleChange}
                            onKeyPress={this.preventEnterKey}
                            value={this.state.clientEngAgr} />
                        <p>{this.state.clientEngAgrValidation}</p>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-md-3 control-label">Full Name:</label>
                    <div className="col-md-8">
                        <input className="form-control" name='fullName' id="fullName" type='text' placeholder=''
                            onKeyUp={this.formValidaton}
                            onChange={this.handleChange}
                            onKeyPress={this.preventEnterKey}
                            value={this.state.fullName} />
                        <p >{this.state.fullNameValidation}</p>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-md-3 control-label">Date:</label>
                    <div className="col-md-8">
                        <input className="form-control" name='date' id="date" type='text' placeholder=''
                            onKeyUp={this.formValidaton}
                            onChange={this.handleChange}
                            onKeyPress={this.preventEnterKey}
                            value={this.state.date} />
                        <p >{this.state.dateValidation}</p>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-md-3 control-label">State of Incorporation:</label>
                    <div className="col-md-8">
                        <Select
                            options={this.state.stateOfIncorporation}
                            value={this.state.selectedStateOfIncorporation}
                            onChange={this.selectStateOfIncorporation}
                            id='select'
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-md-3 control-label">Type of Offering:</label>
                    <div className="col-md-8">
                        <Select
                            options={this.state.typeOfOffering}
                            value={this.state.selectedTypeOfOffering}
                            onChange={this.selectTypeOfOffering}
                            id='select'
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-md-3 control-label">Preferred Stock Terms:</label>
                    <div className="col-md-8">
                        <textarea className="form-control" name='preferredStockTerms' id="preferredStockTerms" type="message" placeholder='' rows="4" cols="50"
                            onKeyUp={this.formValidaton}
                            onChange={this.handleChange}
                            value={this.state.preferredStockTerms}></textarea>
                        <p>{this.state.preferredStockTermsValidation}</p>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-md-3 control-label">Type of Stock:</label>
                    <div className="col-md-8">
                        <Select
                            options={this.state.typeOfStock}
                            value={this.state.selectedTypeOfStock}
                            onChange={this.selectTypeOfStock}
                            id='select'
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-md-3 control-label">Allocation:</label>
                    <div className="col-md-8">
                        <input className="form-control" name='allocation' id="allocation" type='text' placeholder=''
                            onKeyUp={this.formValidaton}
                            onChange={this.handleChange}
                            onKeyPress={this.preventEnterKey}
                            value={this.state.allocation} />
                        <p >{this.state.allocationValidation}</p>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-md-3 control-label">Compensation:</label>
                    <div className="col-md-8">
                        <input className="form-control" name='compensation' id="compensation" type='text' placeholder=''
                            onKeyUp={this.formValidaton}
                            onChange={this.handleChange}
                            onKeyPress={this.preventEnterKey}
                            value={this.state.compensation} />
                        <p >{this.state.compensationValidation}</p>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-md-3 control-label">Admin Cost:</label>
                    <div className="col-md-8">
                        <input className="form-control" name='adminCost' id="adminCost" type='text' placeholder=''
                            onKeyUp={this.formValidaton}
                            onChange={this.handleChange}
                            onKeyPress={this.preventEnterKey}
                            value={this.state.adminCost} />
                        <p >{this.state.adminCostValidation}</p>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-md-3 control-label">Services Fee:</label>
                    <div className="col-md-8">
                        <input className="form-control" name='servicesFee' id="servicesFee" type='text' placeholder=''
                            onKeyUp={this.formValidaton}
                            onChange={this.handleChange}
                            value={this.state.servicesFee}
                            onKeyPress={this.preventEnterKey} />
                        <p >{this.state.servicesFeeValidation}</p>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-md-3 control-label">Body of Letter:</label>
                    <div className="col-md-8">
                        <textarea className="form-control" name='bodyOfLetter' id="bodyOfLetter" type="message" placeholder='' rows="4" cols="50"
                            onKeyUp={this.formValidaton}
                            onChange={this.handleChange}
                            value={this.state.bodyOfLetter}></textarea>
                        <p>{this.state.bodyOfLetterValidation}</p>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-md-3 control-label1"></label>
                    <div className="col-md-8 updateButton">
                        <LaddaButton
                            id="update-btn"
                            loading={this.state.loadinglada}
                            onClick={() => this.saveCompanyData()}
                            data-color="#eee"
                            data-size={XS}
                            data-style={EXPAND_RIGHT}
                            data-spinner-size={30}
                            data-spinner-color="#ddd"
                            data-spinner-lines={12}
                            className={this.state.formValidated ? '' : 'disable'}
                        >
                            SUBMIT
                        </LaddaButton>
                        <input type="reset" className="btn btn-default cancel" value="CANCEL"
                            onClick={() => {
                                let path = `/dashboard`;
                                this.props.history.push(path);
                            }} />
                    </div>
                </div>
            </form>
        )
    }

    handleChange = evt => {
        this.setState({ [evt.target.name]: evt.target.value })
    }

    preventEnterKey = evt => {
        var keyCode = evt.keyCode || evt.which;
        if (keyCode == '13') {
            evt.preventDefault();
        }
    }

    selectStateOfIncorporation = (selectedStateOfIncorporation) => {
        this.setState({
            selectedStateOfIncorporation
        })
        setTimeout(() => {
            this.validateForm()

        }, 1000);
    }

    selectTypeOfOffering = (selectedTypeOfOffering) => {
        this.setState({
            selectedTypeOfOffering
        })
        setTimeout(() => {
            this.validateForm()

        }, 1000);
    }

    selectTypeOfStock = (selectedTypeOfStock) => {
        this.setState({
            selectedTypeOfStock
        })
        setTimeout(() => {
            this.validateForm()

        }, 1000);
    }

    formValidaton = evt => {
        const name = evt.target.name;
        const value = evt.target.value;
        let msg = ``;

        switch (name) {
            case 'clientEngAgr': {
                msg = value === '' ? `Client Engagement Agreement should not be empty*` : ``;
                this.setState({ clientEngAgrValidation: msg });
                break;
            }

            case 'fullName': {
                msg = value === '' ? `Full Name should not be empty*` : ``;
                this.setState({ fullNameValidation: msg });
                break;
            }

            case 'date': {
                const dateRegex = /^([0-2][0-9]|(3)[0-1])(\-)(((0)[0-9])|((1)[0-2]))(\-)\d{4}$/;
                let isValid = dateRegex.test(value)
                msg = value === '' ? `Date should not be empty*` : (isValid ? '' : 'Enter Valid Date');
                this.setState({ dateValidation: msg });
                break;
            }
            case 'preferredStockTerms': {
                msg = value === '' ? `Preferred Stock Terms should not be empty*` : ``;
                this.setState({ preferredStockTermsValidation: msg });
                break;
            }
            case 'allocation': {
                msg = value === '' ? `Allocation should not be empty*` : ``;
                this.setState({ allocationValidation: msg });
                break;
            }
            case 'compensation': {
                msg = value === '' ? `Compensation should not be empty*` : ``;
                this.setState({ compensationValidation: msg });
                break;
            }
            case 'adminCost': {
                msg = value === '' ? `Admin Cost should not be empty*` : ``;
                this.setState({ adminCostValidation: msg });
                break;
            }
            case 'servicesFee': {
                msg = value === '' ? `Services Fee should not be empty*` : ``;
                this.setState({ servicesFeeValidation: msg });
                break;
            }
            case 'bodyOfLetter': {
                msg = value === '' ? `Body Of Letter should not be empty*` : ``;
                this.setState({ bodyOfLetterValidation: msg });
                break;
            }
            default:
        }
        setTimeout(() => {
            this.validateForm()

        }, 1000);
    }

    validateForm = () => {
        let validityCheck = _.isEmpty(this.state.dateValidation)

        let dataCheck = !_.isEmpty(this.state.fullName && this.state.date && this.state.servicesFee
            && this.state.bodyOfLetter && this.state.compensation && this.state.adminCost
            && this.state.allocation && this.state.clientEngAgr && this.state.preferredStockTerms
            && this.state.selectedStateOfIncorporation && this.state.selectedTypeOfOffering && this.state.selectedTypeOfStock)

        if (validityCheck && dataCheck) {
            this.setState({ formValidated: true })
        }
        else {
            this.setState({ formValidated: false })
        }
    }

    render() {
        return (
            <div className="clientEngLetter">
                {this.state.loading ? <LoadingSpinner /> : ""}
                <Header
                    username={this.state.name}
                    role={this.state.Role}
                />
                <HeaderImage
                    heading={this.state.Role}
                />
                <div className="clientEngLetter-main">
                    <div className='heading'>
                        <h3>Client Engagement Letter</h3>
                    </div>
                    <div className="clientEngLetter">
                        <div className='clientEngLetter-details'>
                            <React.Fragment>
                                {this.renderForm()}
                            </React.Fragment>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ClientEngLetter);
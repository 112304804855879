import React, { Component } from 'react';
import LoadingSpinner from '../../../../src/components/Loader';
import { path } from '../../../InvestorDashboard/imagesPath';
import ReferAFriendModal from '../../../components/Header/referFriend';
import TButton from '../../../components/TButton';

class ReferColleagueModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentWillReceiveProps(nextProps) {
        nextProps.onRef && nextProps.onRef(this);
    }

    componentDidUpdate(prevProps) {
        window.onpopstate = (e) => {
            this.props.history.push('/dashboard');
        }
    }

    openReferFriendModal() {
        document.getElementById('contact-btn-cancel').click();
        document.getElementById('referFriend-btn').click();
        this.setState({ loading: false });
    }
    render() {
        return (
            <div id="referColleagueModal" className="modal fade" data-backdrop="static" data-keyboard="false">
                <ReferAFriendModal
                    onRef={(ref) => ref}
                />
                <TButton onClick={() => { }} id='referFriend-btn' dataTarget={`#referFriendModal`} dataToggle={`modal`} style={{ display: 'none' }} />
                {this.state.loading ? <LoadingSpinner /> : ""}
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header remove-border">
                            <img src={path.close} className="contact-close-img" type="button" data-dismiss="modal" id="contact-btn-cancel" />
                        </div>
                        <div className="modal-body">
                            <div className="refer-image-box">
                                <img src={path.refer} />
                            </div>
                            <div className="refer-content">
                                <label>Thank you for your investment!</label>
                                <h6>Do you want to refer a member to TSG?</h6>
                            </div>
                        </div>
                        <div className="refer-modal-footer">
                            <input type="button" id="cancelReferFriend" className="btn btn-tint-border" data-dismiss="modal" value="NOT NOW" />
                            <input type="button" id="referColleague" className="btn yes-btn" value="YES" onClick={() => { this.openReferFriendModal() }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ReferColleagueModal;
import React from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { companyValutionFormat, getCompanyLogoFromS3 } from '../../shared/helper';
import './MyInvestments.scss';
import { getUserInvestmentAmount, mapDateFormat } from './helper';

const redirect = (props) => {
  const { myInvestment } = props;
  if (props.investorId) {
    window.location.href = `/investment-detail?investmentId=${myInvestment.investment._id}&investorId=${props.investorId}`
  } else {
    window.location.href = `/investment-detail?investmentId=${myInvestment.investment._id}`
  }

}

const ListCard = props => {
  const { myInvestment, isCollapsed } = props;

  return (
    <div className={isCollapsed ? "col-lg-6 col-md-6 list-card-min-width" : "col-lg-12 col-md-12"}>
      <div className="card">
        <div className="item_box list-custom">
          <div className="series list-card-series">
            <div className='startup-image'>
              <img src={getCompanyLogoFromS3(myInvestment.company)} alt='' onClick={() => redirect(props)} style={{ cursor: 'pointer' }} />
            </div>

            <p className='list-card-custom' style={{ overflow: "visible" }} onClick={() => redirect(props)}>{myInvestment.investment.name}</p>
            {
              myInvestment.investment.stage === 'closed' ?
                <button style={{ marginLeft: '15px' }} className="btn-success dark-bg">CLOSED</button> :
                <button style={{ marginLeft: '15px' }} className="btn-success">COMPLETE</button>
            }
            <a className='list-documents-redirect' onClick={() => redirect(props)}>View Details and Documents</a>
          </div>
          <div className={isCollapsed ? "collapsed_box upload_document" : "upload_document"}>
            {
              getUserInvestmentAmount(myInvestment._id, myInvestment.investment.userInvestments) ?
                <h4>
                  <NumberFormat
                    fixedDecimalScale={true}
                    decimalScale={2}
                    value={getUserInvestmentAmount(myInvestment._id, myInvestment.investment.userInvestments)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                </h4> : <h4>N/A</h4>
            }
            <p>My Investment</p>
          </div>
          {
            props.expandCollapseArr.indexOf(myInvestment.investment._id) == -1 &&
            <a onClick={() => props.expandOrCollapseClick(myInvestment.investment._id, 'expand')} data-toggle="collapse" data-target={`#${myInvestment.investment._id}`} aria-expanded="true" aria-controls="collapseNEWS" className="btn btn-danger">
              Expand <i className="fa fa-angle-down" aria-hidden="true" />
            </a>
          }
          {
            props.expandCollapseArr.indexOf(myInvestment.investment._id) != -1 &&
            <a onClick={() => props.expandOrCollapseClick(myInvestment.investment._id)} data-toggle="collapse" data-target={`#${myInvestment.investment._id}`} aria-expanded="true" aria-controls="collapseNEWS" className="btn btn-danger">
              Collapse <i className="fa fa-angle-up" aria-hidden="true" />
            </a>
          }
          <div className="my_prices collapse" id={`${myInvestment.investment._id}`}>
            <table className="desktop_view">
              <tbody><tr>
                <td className='content-left'>
                  <span>{mapDateFormat(myInvestment.investment.closedDate)}</span>
                  <p>Close Date</p>
                </td>
                <td>
                  <span className='valuation-secondary'>
                    {
                      myInvestment.investment && myInvestment.investment.shareType &&
                      myInvestment.investment.shareType === 'Common' &&
                      '~ '
                    }

                    <NumberFormat
                      fixedDecimalScale={true}
                      value={companyValutionFormat(myInvestment.investment.companyValuation)} displayType={'text'} thousandSeparator={true} prefix={'$'} />

                  </span>
                  <p>Valuation</p>
                </td>
                <td className='content-right'>
                  <span>
                    <NumberFormat
                      fixedDecimalScale={true}
                      value={myInvestment.investment.pricePerShare}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </span>
                  <p>PPS</p>
                </td>
              </tr>
              </tbody>
            </table>
            <table className="mobile_view">
              <tbody><tr>
                <td>
                  <p>Close Date</p>
                </td>
                <td> <span>{mapDateFormat(myInvestment.investment.closedDate)}</span></td>
              </tr>
                <tr>
                  <td>
                    <p>Valuation</p>
                  </td>
                  <td>
                    <span className='valuation-secondary'>
                      {
                        myInvestment.investment && myInvestment.investment.shareType &&
                        myInvestment.investment.shareType === 'Common' &&
                        '~ '
                      }
                      <NumberFormat
                        fixedDecimalScale={true}
                        value={companyValutionFormat(myInvestment.investment.companyValuation)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span></td>
                </tr>
                <tr>
                  <td>
                    <p>PPS</p>
                  </td>
                  <td>
                    <span><NumberFormat
                      fixedDecimalScale={true}
                      value={myInvestment.investment.pricePerShare}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    /></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = state => {
  return {
    expandCollapseArr: state.expandCollapseList.expandCollapseArr ? state.expandCollapseList.expandCollapseArr : [],
  };
};

export default connect(mapStateToProps, null)(ListCard);
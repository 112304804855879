
import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import './Container.scss';
import swal from 'sweetalert';
import LoadingSpinner from '../../components/Loader';
import PipelineStartupsLists from '../components/PipelineStartupsList'
import { isLoading, isNotLoading } from '../../shared/actions/loadingSpinner';
import { setPipelineStartupsData } from '../actions/pipelineStartups'
import { setRecommendationData } from '../actions/recommendationList'
import CustomModal from '../../components/CustomModal';
import PipelineStartupsService from '../services/PipelineStartups.service';
import TButton from '../../components/TButton';
import common from '../../common';
import axios from 'axios';
import AddInvestmentStartupModal from '../components/AddInvestmentStartupModal';

class MainContainer extends Component {
    constructor() {
        super()
        this.adminAccess = false;
        this.state = {
            activeTab: 'pipelineStartups',
        }
    }


    moveToActiveStartupListHandler = (id) => {
        swal({
            title: "Confirmation",
            text: "Are you sure you want to move this startup to Active startup list?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((action) => {
                if (action) {
                    PipelineStartupsService.moveToActiveStartupList(id)
                        .then(() => {
                            this.loadPipelineStartups();
                        })
                        .catch((error) => {
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else {
                                swal({
                                    title: "Error! #330010",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });
                }
            })

    }
    componentDidMount() {
        const url = new URL(window.location.href);

        if (url && url.searchParams.get('tab') && url.searchParams.get('tab') === 'recommendation') {
            this.setState({ activeTab: 'recommendation' });
            window.history.replaceState(null, "", window.location.href.split("?")[0]);
        }
        this.loadPipelineStartups();
    }

    loadPipelineStartups = () => {
        this.props.isLoading();
        this.getPipelineStartupsList()
            .then(() => {
                return this.getUserRecommendation()
            })
            .then(() => {
                this.props.isNotLoading()
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error! #33008",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                if (error.response && error.response.data && error.response.data.code && error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
            })

    }
    getPipelineStartupsList = () => {
        return new Promise((resolve, reject) => {
            PipelineStartupsService.getPipelineStartupsList()
                .then(response => {
                    if (response && response.data && response.data.data) {
                        this.props.setPipelineStartups(response.data.data);
                    }
                    resolve()
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    getUserRecommendation = () => {
        return new Promise((resolve, reject) => {
            PipelineStartupsService.getUserRecommendation()
                .then(response => {
                    if (response && response.data && response.data.data) {
                        this.props.setRecommendationData(response.data.data);
                    }
                    resolve()
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    addCompanySuccessHandler() {
        this.loadPipelineStartups();
    }
    updatePipelineStartupStatus = (id, status, type) => {
        PipelineStartupsService.updatePipelineStartupStatus(id, status, type)
            .then(() => { })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else {
                    swal({
                        title: "Error! #33006",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }
    loadPipelineData() {
        this.props.isLoading();
        this.getPipelineStartupsList()
            .then(() => {
                this.setState({ activeTab: 'pipelineStartups' });
                this.props.isNotLoading()
            })
            .catch(() => {
            })
    }
    loadRecomendationData() {
        this.props.isLoading();
        this.getUserRecommendation()
            .then(() => {
                this.setState({ activeTab: 'recommendation' });
                this.props.isNotLoading()
            })
            .catch(() => {
            })
    }

    deleteCompany(file) {

        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this file",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                const token = localStorage.getItem('token');
                if (willDelete) {
                    //@TODO CALL DELETE CONTACT API HERE WITH NECESSARY CHECKS
                    this.setState({ loading: true })
                    axios.delete(`${common.path.base_url}/deleteCompany`, { headers: { "Authorization": token }, data: { id: file._id } }
                    )
                        //@TODO CALL ON SUCCESS OF API
                        .then((response) => {
                            this.setState({ loading: false });
                            swal({
                                title: "Success!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            })
                                .then(() => {
                                    this.loadPipelineStartups();
                                });

                        })
                        .catch((error) => {
                            this.setState({ loading: false });
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else {
                                swal({
                                    title: "Error! #33005",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });
                }
                else {
                    swal({ text: `${file.companyName} file is safe!`, icon: 'info' });
                }
            });
    }

    hideCompany(file, type) {
        swal({
            title: "Are you sure want to "+type+" ?",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willHide) => {
                const token = localStorage.getItem('token');
                if (willHide) {
                    
                    const data = {
                        id: file._id,
                        type: type, 
                    }
                    
                    axios.post(`${common.path.base_url}/hideVisibleCompany`, data, {
                        headers: {
                            "Authorization": token
                        }
                    })
                        .then((response) => {
                            this.setState({ loading: false });
                            swal({
                                title: "Success!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            })
                                .then(() => {
                                    this.loadPipelineStartups();
                                });

                        })
                        .catch((error) => {
                            this.setState({ loading: false });
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else {
                                swal({
                                    title: "Error! #33005",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });
                }
                else {
                    swal({ text: `${file.companyName} file is safe!`, icon: 'info' });
                }
            });
    } 
    openEditCompanyModal() {
        document.getElementById('editCompany-btn').click();
        this.setState({ loading: false });
    }
    openAddInvestmentModal() {
        document.getElementById('addInvestment-btn').click();
        this.setState({ loading: false });
    }

    threeDotClick(i) {
        console.log("govind");
    }

    render() {
        return (
            <>
                <CustomModal
                    onRef={(ref) => { this.customModalChild = ref }
                    }
                    onAddCompanySuccessCallback={() => this.addCompanySuccessHandler()}
                />
                <AddInvestmentStartupModal
                    onRef={(ref) => { this.addInvestmentStartupModalChild = ref }
                    }
                // onAddCompanySuccessCallback={() => this.addCompanySuccessHandler()}
                />
                <TButton onClick={() => { }} id='editCompany-btn' dataTarget={`#addCompanyModal`} dataToggle={`modal`} style={{ display: 'none' }} />
                <TButton onClick={() => { }} id='addInvestment-btn' dataTarget={`#addInvestmentStartupModal`} dataToggle={`modal`} style={{ display: 'none' }} />
                {
                    this.props.loadingSpinner ? <LoadingSpinner /> :
                        <>
                            {/* <DashboardStart  
                        adminAccess={this.adminAccess} 
                        fullName={this.fullName} 
                    /> */}
                    
                            <PipelineStartupsLists
                                adminAccess={true}
                                onAddPipelineStartupsClick={() => this.customModalChild.resetInviteData()}
                                updatePipelineStartupStatusToggle={(id, status, type) => this.updatePipelineStartupStatus(id, status, type)}
                                moveToActiveStartupList={(id) => this.moveToActiveStartupListHandler(id)}
                                loadPipelineData={() => this.loadPipelineData()}
                                loadRecomendationData={() => this.loadRecomendationData()}
                                activeTab={this.state.activeTab}
                                onDeleteStartupClick={(data) => { this.deleteCompany(data) }}
                                onHideStartupClick={(data, type) => { this.hideCompany(data, type) }}
                                onEditCompanyClick={(data) => { this.customModalChild.getCompanyById(data._id, 'pipeline', null,() => this.openEditCompanyModal()) }}
                                onAddInvestmentClick={() => this.openAddInvestmentModal()}
                                threeDotClick = { (i) => this.threeDotClick(i) }
                                {...this.props}
                            />

                        </>
                }

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        loadingSpinner: state.loadingSpinner,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        isLoading: bindActionCreators(isLoading, dispatch),
        isNotLoading: bindActionCreators(isNotLoading, dispatch),
        setPipelineStartups: bindActionCreators(setPipelineStartupsData, dispatch),
        setRecommendationData: bindActionCreators(setRecommendationData, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainContainer));
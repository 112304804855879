import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import common from '../../common';
import './emailRedirect.scss';
import images from "../../images";
import axios from 'axios';
import swal from 'sweetalert';
import Moment from 'react-moment';
import HeaderImage from '../../components/HeaderImage';
import LoadingSpinner from '../../components/Loader';
import Header from '../../components/Header';
import WebinarReportList from './WebinarReportList';
import TButton from '../../components/TButton';
import ContactDetailsModal from '../../components/Contact List/ContactDetailsModal';

const WebinarReport = props => {
    
    const onRef = useRef();
    const queryParams = new URLSearchParams(window.location.search);
    const [getInvestmentWebinar, setInvestmentWebinar] = useState('');
    const [getCompanyData, setCompanyData] = useState('');
    const [getInvestmentData, setInvestmentData] = useState('');
    
    const [getTotal1, setTotal1] = useState(0);
    const [getTotal2, setTotal2] = useState(0);
    const [getYes1, setYes1] = useState(0);
    const [getYes2, setYes2] = useState(0);
    const [getNo1, setNo1] = useState(0);
    const [getNo2, setNo2] = useState(0);
    const [getMay1, setMay1] = useState(0);
    const [getMay2, setMay2] = useState(0);
    const [getNoRes1, setNoRes1] = useState(0);
    const [getNoRes2, setNoRes2] = useState(0);
    
    let investId = queryParams.get('id');

    useEffect(() => {
        
        getAllInvestmentWebinar();
    
    }, []);

    useEffect(() => {
    
       const noResp1 =  Number(getTotal1 - Number(getYes1 + getNo1 + getMay1));
       const noResp2 =  Number(getTotal2 - (getYes2 + getNo2 + getMay2));
        //console.log(noResp1, noResp2);
       setNoRes1(noResp1);
       setNoRes2(noResp2);
    
    }, [getYes1, getYes2, getNo1, getNo2, getMay1, getMay2]);

    function getAllInvestmentWebinar(tags=[]) { 

        const investmentId = queryParams.get('id');
        
        let newTags = [];
        if (tags && tags.length > 0) {
            newTags = tags.map(item => item.name);
        }
        
        let token = window.localStorage.getItem('token');
        const data = {
            investmentId : investmentId,
            newTags: newTags,
        }
        
        axios.post(`${common.path.base_url}/getInvestmentWebinar`, data, {
            headers: {
                "Authorization": token
            }
        }).then((response) => {
            if(response.data.status == 'success') {
                const respData = response.data.data;
                const company = respData.investment.companyId;
                const investData = respData.investment;
                const investProcess = respData.InvestmentProcess;
                const totalCal = respData.totalCal;
                //console.log(investData);
                const totalSent = (investData.webinarMailCount) ? investData.webinarMailCount : totalCal.totalRecord;
                
                setInvestmentWebinar(investProcess);
                setCompanyData(company);
                setInvestmentData(investData);
                setTotal1(totalSent);
                setTotal2(totalSent);
                setYes1(totalCal.total1Yes);
                setYes2(totalCal.total2Yes);
                setNo1(totalCal.total1No);
                setNo2(totalCal.total2No);
                setMay1(totalCal.total1May);
                setMay2(totalCal.total2May);
            } else {
                // swal({
                //     title: "Error!",
                //     text: 'Something Went Wrong',
                //     icon: "error",
                //     button: "Ok!",
                // });
            }
        })
        .catch(function (error) {
            swal({
                title: "Error!",
                text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                icon: "error",
                button: "Ok!",
            });
        });

    }

    function backRedirect() {
        window.location.href = `/investmentDetail?id=${investId}`;
    }
    
    function showDataInPopup(userId) {
        onRef.current.setContactDetailsData(userId, null);
        document.getElementById('contactDetails-btn').click();
    }

    return (
        <>  
            <Header
                username={window.localStorage.getItem('username')}
                role={window.localStorage.getItem('role')}
            />

            <ContactDetailsModal
                ref={ onRef }
                { ...props }
                //addInviteSuccess={() => addInviteSuccessHandler(this)}
            />
            <TButton onClick={() => { }} id='contactDetails-btn' dataTarget={`#contactDetailsModal`} dataToggle={`modal`} style={{ display: 'none' }} />

            <div className="container">
                <div className="webinar-report-container">
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='web-report-hd'>Webinar Report</div>
                            <div className='comp-name'>{ getCompanyData.companyName }</div>
                        </div>
                        <div className='col-md-8'>
                            
                            <div className='backBtn'>
                                <TButton text='BACK' className='investmentdetail-btn' onClick={() => backRedirect() } />
                            </div>

                            <div className="head-view-section">
                                
                                <div className='reportCount'>
                                    <span className='reportSpan webSpanCls'>
                                        Webinar1 :
                                    </span>
                                    <span className='reportSpan totSpanCls'>
                                        Total: { getTotal1 }
                                    </span>
                                    <span className='reportSpan yesSpanCls'>
                                        Yes: { (getYes1 > 0) ? Number((getYes1/getTotal1)*100).toFixed(2)+"%" : '0%' } | { getYes1 }
                                    </span>
                                    <span className='reportSpan noSpanCls'>
                                        No: { (getNo1 > 0) ? Number((getNo1/getTotal1)*100).toFixed(2)+"%" : '0%' } | { getNo1 }
                                    </span>
                                    <span className='reportSpan maySpanCls'>
                                        Maybe: { (getMay1 > 0) ? Number((getMay1/getTotal1)*100).toFixed(2)+"%" : '0%' } | { getMay1 }
                                    </span>
                                    <span className='reportSpan noResSpanCls'>
                                        No Response: { (getNoRes1 > 0) ? Number((getNoRes1/getTotal1)*100).toFixed(2)+"%" : '0%' } | { getNoRes1 }
                                    </span>
                                </div>

                                <div className='reportCount'>
                                    <span className='reportSpan webSpanCls'>
                                        Webinar2 :
                                    </span>
                                    <span className='reportSpan totSpanCls'>
                                        Total: { getTotal2 }
                                    </span>
                                    <span className='reportSpan yesSpanCls'>
                                        Yes: { (getYes2 > 0) ? Number((getYes2/getTotal2)*100).toFixed(2)+"%" : '0%' } | { getYes2 }
                                    </span>
                                    <span className='reportSpan noSpanCls'>
                                        No: { (getNo2 > 0) ? Number((getNo2/getTotal2)*100).toFixed(2)+"%" : '0%' } | { getNo2 }
                                    </span>
                                    <span className='reportSpan maySpanCls'>
                                        Maybe: { (getMay2 > 0) ? Number((getMay2/getTotal2)*100).toFixed(2)+"%" : '0%' } | { getMay2 }
                                    </span>
                                    <span className='reportSpan noResSpanCls'>
                                        No Response: { (getNoRes2 > 0) ? Number((getNoRes2/getTotal2)*100).toFixed(2)+"%" : '0%' } | { getNoRes2 }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <WebinarReportList 
                                getInvestmentWebinar = { getInvestmentWebinar } 
                                getInvestmentData = { getInvestmentData }
                                getAllInvestmentWebinar={(tags) => getAllInvestmentWebinar(tags) }
                                showDataInPopup = { (userId) => showDataInPopup(userId) }
                            />

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



export default WebinarReport;
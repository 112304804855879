const expand = (id) => {
  return {
    type: 'EXPAND',
    id,
  }
}
const collapse = (id) => {
  return {
    type: 'COLLAPSE',
    id,
  }
}
export const expandOrCollapseClickAction = (id, type) => {
  if (type === 'expand') {
    return (dispatch) => dispatch(expand(id))
  } else if (type === 'reset') {
    return (dispatch) => dispatch(reset(type))
  } else {
    return (dispatch) => dispatch(collapse(id))
  }
}

export const reset = (id, type) => {
  return {
    type: 'RESET',
  }
}

import React, { Component } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import Select from 'react-select';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import _ from 'lodash';

import './UploadDocument.scss';
import common from '../../../common';
import RequiredSign from '../../../components/RequiredSign';
import FileUploadBlobDoc from '../../../components/FileUploadBlobDoc';

class UploadDocInvestmentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCompanyOption: '',
            companyId: '',
            validInvestmentForm: false,
            company: '',
            investmentName: '',
            selectedInvestorOption: '',
            investorId: '',
            investor: '',
            companyList: [],
            investorList: [],
            choosenUploadFile: '',
            selectedFoldeOption: { value: 'Tax Documents', label: 'Tax Documents' },
            selectedDocumentType: { value: 'K1', label: 'K1' },
            folders: [{ value: 'Tax Documents', label: 'Tax Documents' }, { value: 'Investment Documents', label: 'Investment Documents' }, { value: 'Other Documents', label: 'Other Documents' }, { value: 'Exit Documents', label: 'Exit Documents' }],
            documentType: [{ value: 'K1', label: 'K1' }, { value: 'Newsletter', label: 'Newsletter' }, { value: 'Other', label: 'Other' }],
            investmentId: null,
            investorUploadDocData: [],
            choosenInvestorUploadFile: '',
            roleCode: null,
            investmentsData: [],
        }
        this.selectFolder = this.selectFolder.bind(this);

    }
    componentWillMount() {
        let userRoleCode = window.localStorage.getItem('roleCode');
        this.setState({ roleCode: userRoleCode });
    }
    componentWillReceiveProps(nextProps) {
        nextProps.onRef && nextProps.onRef(this);
    }
    handleCompanyChange(id, newValue) {
        if (id === "company") {

            if (newValue.value) {
                let token = window.localStorage.getItem('token');
                axios['get'](`${common.path.base_url}/investment/${newValue.value}`,
                    {
                        headers: {
                            "Authorization": token
                        }
                    })
                    .then((response) => {
                        if (response && response.data && response.data.data) {
                            const { data } = response.data;
                            if (data && data.length > 0) {
                                this.setState({ investmentsData: data });
                                this.handleRequiredFieldValidation();
                            } else {
                                this.setState({ investmentsData: [], companyValidation: `Company doesn't have any investment yet. Please add first!` });
                                this.handleRequiredFieldValidation();


                            }
                        }
                    })
                    .catch(error => { });

                this.setState({ company: newValue.label, companyId: newValue.value, selectedCompanyOption: newValue });

            }
        }

    }
    handleInvestorChange(id, newValue) {
        this.setState({ investor: newValue.label, investorId: newValue.value, selectedInvestorOption: newValue });
        this.handleRequiredFieldValidation();
    }
    handleRequiredFieldValidation() {
        setTimeout(() => {
            if (!_.isEmpty(this.state.company && this.state.investmentName) && this.state.choosenUploadFile) {
                this.setState({ validInvestmentForm: true })
            } else {
                this.setState({ validInvestmentForm: false })
            }
        }, 100);
    }
    resetUploadDocData() {
        this.setState({
            selectedCompanyOption: '',
            companyId: '',
            validInvestmentForm: false,
            company: '',
            investmentName: '',
            selectedInvestorOption: '',
            investorId: '',
            investor: '',
            companyList: [],
            investorList: [],
            choosenUploadFile: '',
            investmentId: '',
            choosenInvestorUploadFile: '',
            investmentsData: [],
        });
    }

    companySearch = _.debounce((value) => {
        this.setState({
            company: value,
            investmentName: '',
            companyValidation: '',

        })
        let temp = this;
        axios.post(`${common.path.base_url}/searchCompany`, {
            query: value
        })
            .then(function (response) {
                const companyList = response.data.data.map(opt => ({ label: opt.companyName, value: opt._id }));
                temp.setState({ companyList });

            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }, 800);
    investorSearch = _.debounce((value) => {
        if (!this.state.investmentId) {
            return false;
        }
        this.setState({
            investor: value,
        })
        let temp = this;
        axios.post(`${common.path.base_url}/searchInvestor`, {
            query: value,
            investmentId: this.state.investmentId
        })
            .then(function (response) {
                const investorList = response.data.data.map(opt => ({ label: `${opt.firstName} ${opt.lastName}`, value: opt._id }));
                temp.setState({ investorList });

            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: "Something went wrong",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }, 800);

    selectFolder(selectedFoldeOption) {
        this.setState({ selectedFoldeOption });
    }
    addInvestmentDoc() {
        let token = window.localStorage.getItem('token');
        let temp = this;
        temp.setState({ loadinglada: true });
        const file = this.state.choosenUploadFile;
        let data = new FormData();
        data.append('file', file);
        const param = JSON.stringify({
            companyId: this.state.companyId,
            investorId: this.state.investorId,
            investmentId: this.state.investmentId,
            folderName: this.state.selectedFoldeOption.value,
            documentType: (this.state.roleCode === '001' || this.state.roleCode === '002') ? this.state.selectedDocumentType.value : '',
        })
        data.append('params', param);
        axios.post(`${common.path.base_url}/investment/upload`, data,
            {
                headers: {

                    "Authorization": token
                }
            })
            .then(function (response) {
                temp.setState({ loadinglada: false });
                document.getElementById('cancelUploadDoc').click();
                swal({
                    title: "Success!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                });

            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response && error.response.data && error.response.data.code && error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });
            });
    }
    setInvestmentData(data, callback) {
        this.setState({
            investorUploadDocData: data,
        });
        callback();
    }
    handleRequiredDocFieldValidation() {
        setTimeout(() => {
            if (this.state.choosenInvestorUploadFile) {
                this.setState({ validInvestmentForm: true })
            } else {
                this.setState({ validInvestmentForm: false })
            }
        }, 100);
    }

    addInvestorDoc() {
        let temp = this;
        temp.setState({ loadinglada: true });
        let token = window.localStorage.getItem('token');
        const url = new URL(window.location.href);
        let investorDashboardId = url.searchParams.get('investorId');
        const file = this.state.choosenInvestorUploadFile;
        let data = new FormData();
        data.append('file', file);
        const param = JSON.stringify({
            companyId: this.state.investorUploadDocData.companyId || this.state.investorUploadDocData.company._id,
            investorId: investorDashboardId || window.localStorage.getItem('id'),
            investmentId: this.state.investorUploadDocData.investment && this.state.investorUploadDocData.investment._id ? this.state.investorUploadDocData.investment._id : this.state.investorUploadDocData._id,
            folderName: this.state.roleCode === '005' ? 'My Documents' : this.state.selectedFoldeOption.value,
            documentType: (this.state.roleCode === '001' || this.state.roleCode === '002') ? this.state.selectedDocumentType.value : '',
        })
        data.append('params', param);
        let path = `${common.path.base_url}/investment/upload`;
        if (this.state.roleCode === '005') {
            path = `${common.path.base_url}/investment/docUpload`;
        }
        axios.post(path, data,
            {
                headers: {

                    "Authorization": token
                }
            })
            .then(function (response) {
                temp.setState({ loadinglada: false });
                temp.props.onUploadInvestorDocClick() && temp.props.onUploadInvestorDocClick();
                document.getElementById('cancelInvestorUploadDoc').click();
                swal({
                    title: "Success!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                });

            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error && error.response && error.response.data && error.response.data.code && error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });
            });
    }

    handleInvestmentChange(option) {
        this.setState({ investmentName: option.label, investmentId: option.value });
        this.handleRequiredFieldValidation();
    }
    docymentType(event) {
        this.setState({ selectedDocumentType: event });
        if (event.value === 'K1') {
            this.setState({ selectedFoldeOption: { value: 'Tax Documents', label: 'Tax Documents' } })
        } else {
            this.setState({ selectedFoldeOption: { value: 'Other Documents', label: 'Other Documents' } })
        }
    }
    render() {
        return (
            <div>
                {/* <!-- Invite Modal HTML --> */}
                <div id="uploadDocumentModal" className="modal fade">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Investor Documents</h4>
                            </div>
                            <div className="modal-body scroll-added">
                                <div className="form-horizontal" id=' myform'>
                                    <div className="form-group">
                                        <label className="col-sm-3  col-md-3 control-label" style={{ "paddingLeft": "0px" }}>Company  <RequiredSign /> :</label>
                                        <div className="col-sm-8 col-md-8">
                                            <Select id="company"
                                                options={this.state.companyList}
                                                onChange={(opt, action) => {
                                                    if (action && action.action === 'clear') {
                                                        this.setState({ selectedCompanyOption: null, companyId: '', company: '', validInvestmentForm: false, investmentName: '' });
                                                    }
                                                    if (opt !== null && opt !== undefined) {
                                                        this.handleCompanyChange("company", opt);
                                                    }
                                                }}
                                                onInputChange={(searchKey) => {
                                                    if (!_.isEmpty(searchKey)) {
                                                        this.companySearch(searchKey);
                                                    }
                                                }}
                                                value={this.state.selectedCompanyOption}
                                                isClearable={true}
                                                isSearchable={true}
                                                filterOption={(value1, value2) => {
                                                    return value2;
                                                }}
                                                deleteRemoves={true}
                                                noOptionsMessage={() => `Type Something`}
                                            />
                                            <p >{this.state.companyValidation}</p>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-3  col-md-3 control-label" style={{ "paddingLeft": "0px" }}>Investment <RequiredSign /> :</label>
                                        <div className="col-sm-8 col-md-8">
                                            <Select
                                                id="ChooseInvestmenttages"
                                                options={this.state.investmentsData.map((singleInvestment) => { return { label: singleInvestment.name, value: singleInvestment._id } })}
                                                onChange={(option, actions) => {
                                                    if (actions && actions.action === 'clear') {
                                                        this.setState({ investmentName: null, investmentId: null });
                                                    }
                                                    if (option) {
                                                        this.handleInvestmentChange(option);
                                                    }
                                                }
                                                }
                                                isClearable={true}
                                                placeholder={`Choose Investment`}
                                            />
                                            <p>{this.state.nameValidationMsg}</p>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-3  col-md-3 control-label" style={{ "paddingLeft": "0px" }}>Investor :</label>
                                        <div className="col-sm-8 col-md-8">
                                            <Select id="investor"
                                                options={this.state.investorList}
                                                onChange={(opt, action) => {
                                                    if (action && action.action === 'clear') {
                                                        this.setState({ selectedInvestorOption: null, investmentName: '', validInvestmentForm: false });
                                                    }
                                                    if (opt !== null && opt !== undefined) {
                                                        this.handleInvestorChange("investor", opt);
                                                    }
                                                }}
                                                onInputChange={(searchKey) => {
                                                    if (!_.isEmpty(searchKey)) {
                                                        this.investorSearch(searchKey);
                                                    }
                                                }}
                                                value={this.state.selectedInvestorOption}
                                                isClearable={true}
                                                isSearchable={true}
                                                filterOption={(value1, value2) => {
                                                    return value2;
                                                }}
                                                deleteRemoves={true}
                                                noOptionsMessage={() => `Type Something`}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-3  col-md-3 control-label" style={{ "paddingLeft": "0px" }}></label>
                                        <label className="col-sm-8 col-md-8" style={{ fontSize: '13px', whiteSpace: 'normal' }}>If you do not select an investor then this will add investor document for all the investor belonging to this investment.</label>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-3  col-md-3 control-label" style={{ "paddingLeft": "0px" }}>Choose File <RequiredSign /> :</label>
                                        <div className="col-sm-8 col-md-8 upload-section" style={{ "padding": "0px", "border": "none" }}>

                                            <div className="upload-block">
                                                <div className="choose-file" style={{ "width": "100%", "marginRight": "0px" }} onClick={() => document.querySelector('#documentUpload').click()}>{(this.state.choosenUploadFile && this.state.choosenUploadFile.name) || `Select File`}</div>
                                                <FileUploadBlobDoc getFile={(file) => this.setState({ choosenUploadFile: file }, this.handleRequiredFieldValidation())} id="documentUpload" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-3  col-md-3 control-label" style={{ "paddingLeft": "0px" }}>Document Type <RequiredSign /> :</label>
                                        <div className="col-sm-8 col-md-8">
                                            <Select
                                                options={this.state.documentType}
                                                value={this.state.selectedDocumentType}
                                                onChange={(event) => {
                                                    this.docymentType(event)
                                                }}
                                                id='InvestmentstagesofinvestorDocType'
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-3  col-md-3 control-label" style={{ "paddingLeft": "0px" }}>Folder Name <RequiredSign /> :</label>
                                        <div className="col-sm-8 col-md-8">
                                            <Select
                                                defaultValue={this.state.selectedFoldeOption}
                                                options={this.state.folders}
                                                value={this.state.selectedFoldeOption}
                                                onChange={this.selectFolder}
                                                id='Investmentstages'
                                            />
                                        </div>
                                    </div>


                                    <div className="modal-footer">

                                        <LaddaButton
                                            id='lada-btn'
                                            loading={this.state.loadinglada}
                                            onClick={() => this.addInvestmentDoc()}
                                            data-color="#eee"
                                            data-size={XS}
                                            className={!this.state.validInvestmentForm ? 'disable' : ''}
                                            data-style={EXPAND_RIGHT}
                                            data-spinner-size={30}
                                            data-spinner-color="#ddd"
                                            data-spinner-lines={12}
                                        >
                                            ADD
                                    </LaddaButton>
                                        <input type="button" id="cancelUploadDoc" className="btn btn-default" data-dismiss="modal" onClick={() => this.resetUploadDocData()} value="CANCEL" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="uploadInvestorDocModal" className="modal fade">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                {this.state.roleCode == '005' ?
                                    <h4 className="modal-title">Any uploaded documents will go to My Documents</h4> :
                                    <h4 className="modal-title"> Upload documents</h4>
                                }
                            </div>
                            <div className="modal-body">
                                <div className="form-horizontal" id=' myform'>
                                    <div className="form-group">
                                        <label className="col-sm-3  col-md-3 control-label" style={{ "paddingLeft": "0px" }}>Choose File <RequiredSign /> :</label>
                                        <div className="col-sm-8 col-md-8 upload-section" style={{ "padding": "0px", "border": "none" }}>
                                            <div className="upload-block">
                                                <div className="choose-file" style={{ "width": "100%", "marginRight": "0px" }} onClick={() => document.querySelector('#investorDocumentUpload').click()}>{(this.state.choosenInvestorUploadFile && this.state.choosenInvestorUploadFile.name) || `Select File`}</div>
                                                <FileUploadBlobDoc getFile={(file) => this.setState({ choosenInvestorUploadFile: file }, this.handleRequiredDocFieldValidation())} id="investorDocumentUpload" />
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.roleCode !== '005' &&
                                        <>
                                            <div className="form-group">
                                                <label className="col-sm-3  col-md-3 control-label" style={{ "paddingLeft": "0px" }}>Document Type <RequiredSign /> :</label>
                                                <div className="col-sm-8 col-md-8">
                                                    <Select
                                                        options={this.state.documentType}
                                                        value={this.state.selectedDocumentType}
                                                        onChange={(event) => {
                                                            this.docymentType(event)
                                                        }}
                                                        id='InvestmentstagesofinvestorDocType'
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-sm-3  col-md-3 control-label" style={{ "paddingLeft": "0px" }}>Folder Name <RequiredSign /> :</label>
                                                <div className="col-sm-8 col-md-8">
                                                    <Select
                                                        options={this.state.folders}
                                                        value={this.state.selectedFoldeOption}
                                                        onChange={this.selectFolder}
                                                        id='Investmentstagesofinvestor'
                                                        placeholder={`Choose Folder`}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    }

                                    <div className="modal-footer">
                                        <LaddaButton
                                            id='lada-btn'
                                            loading={this.state.loadinglada}
                                            onClick={() => this.addInvestorDoc()}
                                            data-color="#eee"
                                            data-size={XS}
                                            className={!this.state.validInvestmentForm ? 'disable' : ''}
                                            data-style={EXPAND_RIGHT}
                                            data-spinner-size={30}
                                            data-spinner-color="#ddd"
                                            data-spinner-lines={12}
                                        >
                                            UPLOAD
                                        </LaddaButton>
                                        <input type="button" id="cancelInvestorUploadDoc" className="btn btn-default" data-dismiss="modal" onClick={() => this.resetUploadDocData()} value="CANCEL" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default UploadDocInvestmentModal;

export const getUserInvestmentAmount = (userId, userInvestments) => {
  const replaceCommasWithSpace = (inputString) => {
    return inputString.replace(/,/g, '');
  }
  
  if (userInvestments && userInvestments.length > 0) {
    const investment = userInvestments.filter(investment => investment.investorId === userId);
    if (investment && investment.length > 0) {
      return replaceCommasWithSpace(investment[0].amount);
    } else {
      return null;
    }
  } else {
    return null;
  }
}


export const getReturnedInvestment = (data) => {
  const investment  = data.investment;
  if (investment.disbursement2 && investment.disbursement2 !== null) {
    const amount = investment.disbursement1 + investment.disbursement2;
    return amount;
  } else if (investment.disbursement1 && (investment.disbursement2 == null || !investment.disbursement2)) {
    return investment.disbursement1;
  } else {
    return 0;
  }
}
export const mapDateFormat = mongoDate => {
  if (!mongoDate) return 'N/A';
  let date = new Date(mongoDate);
  const monthNames = [
    "01", "02", "03",
    "04", "05", "06", "07",
    "08", "09", "10",
    "11", "12"
  ];
  let day = date.getDate();
  let monthIndex = date.getMonth();
  let year = date.getFullYear();
  return monthNames[monthIndex] + '/' + day + '/' + year;

}
import { path } from "../../imagesPath";
export const getActiveStatusClass = (invitation, type) => {
  switch (type) {
    case 'document':
      if (invitation.status.toLowerCase() === 'interested'
        || invitation.isDocSign) {
        return path.checkslide && path.checkslide
      }
      if (!invitation.isSubscribed) {
        return path.uncheckslideBorder && path.uncheckslideBorder;
      }
      return path.uncheckslide && path.uncheckslide
    case 'execute':
      if (invitation.isDocSign) {
        return path.checkslide;
      } else if (invitation.isSubscribed && !invitation.isDocSign) {
        return path.uncheckslideBorder && path.uncheckslideBorder;
      } else {
        return path.uncheckslide && path.uncheckslide
      }
    case 'fund':
      if (invitation.status.toLowerCase() === 'payment') {
        return path.checkslide;
      } else if (invitation.isSubscribed && invitation.isDocSign) {
        return path.uncheckslideBorder && path.uncheckslideBorder;
      } else {
        return path.uncheckslide && path.uncheckslide;
      }
    case 'signsideletter':
      if (invitation.isSignSideletter) {
        return path.checkslide;
      } else if (invitation.isSubscribed && invitation.isDocSign && !invitation.isSignSideletter) {
        return path.uncheckslideBorder && path.uncheckslideBorder;
      } else {
        return path.uncheckslide && path.uncheckslide;
      }
    default:
      return path.uncheckslide && path.uncheckslide

  }
}
export const getNextStatusLabel = (invitation) => {
  if (!invitation.isSubscribed) {
    return 'Continue With Documents';
  } else if (!invitation.isDocSign) {
    return 'Continue With Execute';
  } else if (invitation.isSubscribed && invitation.isDocSign) {
    return 'Continue With Funding';
  }
  return '';
}
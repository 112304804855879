export const setInvestmentProcessData = payload => {
  return {
    type: 'SET_INVESTMENT_PROCESS_DATA',
    payload
  }
}
export const setInvestmentUserData = payload => {
  return {
    type: 'SET_INVESTMENT_USER_DATA',
    payload
  }
}

import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash';
import axios from 'axios';
import images from "../../images";
import common from '../../common';
import swal from 'sweetalert';
import './pipelineCss/pipeline.scss';
import './pipelineCss/default.scss';
import { Icons } from './Icons';

const TeamRow = props => {
    const { type, saveTeamCompany, singleData, addTeamList, index, addBoardList } = props;

    const teamRowList = () => {
        const foundIndex = addTeamList.findIndex((obj) => obj._id === singleData._id); 
    
        return (!( foundIndex !==-1 )) && 
            (
                <>
                    <div className='TeamListItemCont'>
                        <div className='mr-10'>
                            { ( singleData.imageUrl != "") ?
                            <img src={ singleData.imageUrl } alt="" srcSet="" className='img-40' />
                            :
                            <img src={ images.path.placeholder } alt="" srcSet="" className='img-40' />
                            }
                        </div>
                        <div className='teamNameAdd'>{ singleData.name }</div>
                        <div className='ml-A' onClick={ (e) => saveTeamCompany( index ) } >{ Icons.PlusIcon }</div>
                    </div>
                </>
            );
    }

    const boardRowList = () => {
        const foundIndex = addBoardList.findIndex((obj) => obj._id === singleData._id); 
    
        return (!( foundIndex !==-1 )) && 
            (
                <>
                    <div className='TeamListItemCont'>
                        <div className='mr-10'>
                            { (singleData.imagePath != "none" && singleData.imagePath != null && singleData.imagePath != "") ?
                            <img src={ `${common.path.base_url}/getImage?id=${singleData._id}` } alt="" srcSet="" className='img-40' />
                            :
                            <img src={ images.path.placeholder } alt="" srcSet="" className='img-40' />
                            }
                        </div>
                        <div className='teamNameAdd'>{ singleData.name }</div>
                        <div className='ml-A' onClick={ (e) => saveTeamCompany( index ) } >{ Icons.PlusIcon }</div>
                    </div>
                </>
            );
    }
    
    return ( type == 'team' ) ?
            <>{ teamRowList() }</>
        
        : (( type == 'board' ) ?
            
            <>{ boardRowList() }</> : ""
        )

}

export default TeamRow;
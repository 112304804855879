import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { path } from "../../imagesPath";
import NotParticipatingCard from "../NotParticipating/NotParticipatingCard";
import InvitationCard from "./InvitationCard";
import "./NewInvitations.scss";

const NewInvitations = (props) => {
  const { isBlackList, invitations, notParticipation } = props;

  const [notParticipating, setNotParticipating] = useState([]);

  useEffect(() => {
    if (notParticipation.length > 0) {
      const data = notParticipation
        .filter((dataRow) => dataRow.stage === "open");
      setNotParticipating(data);
    }
  }, [notParticipation]);

  const newInvitations = invitations.length + notParticipating.length;

  return (
    <div className="container" style={{ padding: "0px 20px" }}>
      {newInvitations > 0 && <div className="invitations_sec">
        <div className="row d-flex" style={{ paddingLeft: "10px" }}>
          <img className="intial_icon" src={path.openInvitation} />
          <div className="col-md-3">
            <h3 style={{ fontSize: "20px", color: "#383F59" }}>
              Open Invitations ({newInvitations})
            </h3>
          </div>
          <div className="col-md-9"></div>
        </div>
        <div className="row">
          {invitations.length > 0 && !isBlackList && (
            invitations.map((invitation) => (
              <InvitationCard
                invitation={invitation}
                key={invitation._id}
                {...props}
              />
            ))
          )}
          {notParticipating.length > 0 && !isBlackList &&
            notParticipating.map((invitation) => (
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 col-xxl-3" key={invitation._id}>
                <NotParticipatingCard
                  invitation={invitation}
                  key={invitation._id}
                  {...props}
                  callFrom="NewInvitations"
                />
              </div>
            ))}
        </div>
      </div>}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    invitations:
      state.investorDashboardData.invitations.length > 0
        ? state.investorDashboardData.invitations
        : [],
    notParticipation:
      state.investorDashboardData.notParticipation.length > 0
        ? state.investorDashboardData.notParticipation
        : [],
  };
};

export default connect(mapStateToProps, null)(NewInvitations);

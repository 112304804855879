import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash';
import axios from 'axios';
import images from "../../images";
import common from '../../common';
import swal from 'sweetalert';
import './pipelineCss/pipeline.scss';
import './pipelineCss/default.scss';
import { Icons } from './Icons';
import NumberFormat from 'react-number-format';
import IncumbentData from './IncumbentData';
import StartupData from './StartupData';

const General = props => {

    const intialData = [
        {name: "Low Priority", id: "Low Priority"},
        {name: "Medium Priority", id: "Medium Priority"},
        {name: "High Priority", id: "High Priority"},
    ];

    const { companyDispaly, companyId, companyData } = props;
    
    const profileUploadRef = useRef(null);
    const incumbentuplodRef = useRef(null);
    const startupuplodRef = useRef(null);
    
    const [generalCall, setGeneralCall] = useState(false);
    const [gernalCompanyId, setGernalCompanyId] = useState(companyId);
    const [gernalCompImg, setGernalCompImg] = useState('');
    const [gernalCompName, setGernalCompName] = useState('');
    const [gernalCompType, setGernalCompType] = useState('');
    const [gernalPriorityType, setGernalPriorityType] = useState('');
    const [generalState, setGeneralState] = useState('');
    const [generalLegalName, setGeneralLegalName] = useState('');
    const [generalWebsite, setGeneralWebsite] = useState('');
    const [generalFounded, setGeneralFounded] = useState('');
    const [generalEmployees, setGeneralEmployees] = useState('');
    const [generalYearEnd, setGeneralYearEnd] = useState('');
    const [generalOverview, setGeneralOverview] = useState('');
    const [generalLinkedin, setGeneralLinkedin] = useState('');
    const [generalCrunchbase, setGeneralCrunchbase] = useState('');
    
    const [incumbentImg, setIncumbentImg] = useState({});
    const [incumbentArr, setIncumbentArr] = useState([]);
    const [incumbentName, setIncumbentName] = useState('');
    const [incumbentUpdate, setIncumbentUpdate] = useState('');
    const [incumbentLoader, setIncumbentLoader] = useState(false);
    
    const [startupImg, setStartupImg] = useState({});
    const [startupArr, setStartupArr] = useState([]);
    const [startupName, setStartupName] = useState('');
    const [startupUpdate, setStartupUpdate] = useState('');
    const [startupLoader, setStartupLoader] = useState(false);
    
    const [profileImg, setProfileImg] = useState('');
    const [companyLogo, setCompanyLogo] = useState('');
    const [companyProfileImg, setCompanyProfileImg] = useState('');
    
    const [arrCompanyType, setArrCompanyType] = useState([]);
    const [arrPriorityStatus, setArrPriorityStatus] = useState(intialData);

    const handleChanger = (event, type) => {
        if (type === 'incumbentName') {
            let txtFull = event.target.value;
            txtFull = (txtFull.trim() == "") ? "" : _.upperFirst(txtFull);
            setIncumbentName(txtFull);
        } else if (type === 'startupName') {
            let txtFull = event.target.value;
            txtFull = (txtFull.trim() == "") ? "" : _.upperFirst(txtFull);
            setStartupName(txtFull);
        }
    };

    const inputHandlerChange = (e)  => {

        const inputId = e.target.id;
        const inputValue = e.target.value;
        
        setGeneralCall(true);

        if( inputId == 'gernalCompName' ) {
            let txtFull = inputValue;
            txtFull = (txtFull.trim() == "") ? "" : _.upperFirst(txtFull);
            setGernalCompName(txtFull);
        } else if( inputId == 'gernalState' ) {
            let txtFull = inputValue;
            txtFull = (txtFull.trim() == "") ? "" : _.upperFirst(txtFull);
            setGeneralState(txtFull);
        } else if( inputId == 'generalLegalName' ) {
            let txtFull = inputValue;
            txtFull = (txtFull.trim() == "") ? "" : _.upperFirst(txtFull);
            setGeneralLegalName(txtFull);
        } else if( inputId == 'generalWebsite' ) {
            setGeneralWebsite(inputValue);
        } else if( inputId == 'generalFounded' ) {
            setGeneralFounded(inputValue);
        } else if( inputId == 'generalEmployees' ) {
            setGeneralEmployees(inputValue);
        } else if( inputId == 'generalYearEnd' ) {
            setGeneralYearEnd(inputValue);
        } else if( inputId == 'generalOverview' ) {
            setGeneralOverview(inputValue);
        } else if( inputId == 'generalLinkedin' ) {
            setGeneralLinkedin(inputValue);
        } else if( inputId == 'generalCrunchbase' ) {
            setGeneralCrunchbase(inputValue);
        } else if( inputId == 'compSelectType' ) {
            setGernalCompType(inputValue);
        } else if( inputId == 'priorityStatusType' ) {
            setGernalPriorityType(inputValue);
        }
    }

    const removeHandle = () => {
        swal({
            title: 'Are you sure you want to delete?',
            text: "This item will be delete immediately. You can't undo this action.",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#7A8DA7',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true,
        });
    };

    useEffect(() => {
        
        let token = window.localStorage.getItem('token');
        const compName = (gernalCompName != "") ? gernalCompName.trim() : "";

        if( generalCall ) {

            if( (  compName == "" || compName == null || compName == undefined ) ){

                swal({
                    title: 'Please enter company name',
                    //text: "This item will be delete immediately. You can't undo this action.",
                    icon: 'error',
                    // showCancelButton: true,
                    // cancelButtonColor: '#7A8DA7',
                    confirmButtonText: 'Yes, delete it!',
                    reverseButtons: true,
                });

            } else {

                const timer = setTimeout(() => {
                    
                    let dataInfo = new FormData();

                    const data = {
                        'companyId' : companyId,
                        'companyName' : gernalCompName,
                        'companyState' : generalState,
                        'companyType' : gernalCompType,
                        'companyProfileImage': gernalCompImg,
                        'priorityType' : gernalPriorityType,
                        'legalName' : generalLegalName,
                        'website' : generalWebsite,
                        'founded' : generalFounded,
                        'numberOfEmployee' : generalEmployees,
                        'fiscalYearEnd' : generalYearEnd,
                        'overview' : generalOverview,
                        'profileImg' : "",
                        'socialProfile' : { 'linkedin' : generalLinkedin, 'crunchbase' : generalCrunchbase  } 
                    };
                    
                    if(companyLogo){
                        data.companyLogo= companyLogo;
                    }
                    
                    if (Object.keys(gernalCompImg).length > 0) {
                        dataInfo.append('file', gernalCompImg.inputFile);
                    }
                    
                    data.imgType = 'profileImg';
                    dataInfo.append('data', JSON.stringify(data));
                    
                    axios.post(`${common.path.base_url}/addNewPipline`, dataInfo, {
                        headers: {
                            "Authorization": token
                        }
                    })
                    .then((response) => {
                        
                        const savedData = response.data.data;
                        if( companyId =='' || companyId == null ) {
                            props.setUpdateCompanyId(savedData._id);
                            //setIncumbentArr(savedData.incumbentPlayers);
                            window.history.replaceState(null, '', '/pipeline/add/'+savedData._id);
                        }
                    })
                    .catch((error) => {
                        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        
                            window.localStorage.clear();
                            window.location.href = '/';
                            return;
                        } else {
                            swal({
                                title: "Error! #33005",
                                text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                icon: "error",
                                button: "Ok!",
                            });
                        }
                    });

                }, 2000);
                return () => clearTimeout(timer);
            }
        }
    
    }, [ gernalCompName, generalState, generalLegalName, generalWebsite, generalFounded, generalEmployees, generalYearEnd, generalOverview, generalLinkedin, generalCrunchbase, gernalCompImg, gernalCompType, gernalPriorityType ])

    useEffect(() => {
        //console.log("govind", companyData.profileImg);
        if( companyData != "" && companyData != undefined && companyData != null) {
            setGernalCompName(companyData.companyName);
            setGeneralState(companyData.companyState);
            setGeneralLegalName(companyData.legalName);
            setGeneralWebsite(companyData.website);
            setGeneralFounded(companyData.founded);
            setGeneralEmployees(companyData.numberOfEmployee);
            setGeneralYearEnd(companyData.fiscalYearEnd);
            setGeneralOverview(companyData.overview);
            setGeneralLinkedin(companyData.socialProfile.linkedin);
            setGeneralCrunchbase(companyData.socialProfile.crunchbase);
            setCompanyLogo(companyData.companyLogo);

            setIncumbentArr(companyData.incumbentPlayers);
            setStartupArr(companyData.startupPlayers);
            setGernalCompType(companyData.companyType);
            setGernalPriorityType(companyData.priorityType);

            setProfileImg(companyData.profileImg);
            setCompanyProfileImg(companyData.companyProfileImage);
        }
        
        setArrCompanyType([
            { 'id' : 1, 'value':'IT' },
            { 'id' : 2, 'value':'Automotive' },
            { 'id' : 3, 'value':'Manufactoring' },
        ])
    }, [companyData])
    
    const incumbentSave = (action='add', codeType='incumbentPlayers') => {
        
        let updateData
        let sendData
        if(codeType == 'startupPlayers') {
            
            let newObj
            if(action == 'add') {
                newObj = {
                    'companyName' : startupName,
                    'imgUrl' : "",
                }
            } else if(action == 'delete') {
                newObj = [...startupArr];
            }
            
            sendData = { 'startupPlayers' : newObj, 'companyId' : companyId, 'companyName' : gernalCompName, 'imgType' : 'startupPlayers', 'startupType': action }
            
            setStartupLoader(true);
            setStartupName('');
            let preview = document.getElementById('startupPreview');
            preview.src = images.path.placeholder;
            setStartupImg({ });
            
        } else {

            let newObj
            if(action == 'add') {
                newObj = {
                    'companyName' : incumbentName,
                    'imgUrl' : ""
                }
            } else if(action == 'delete') {
                newObj = [...incumbentArr];
            }
            
            sendData = { 'incumbentPlayers' : newObj, 'companyId' : companyId, 'companyName' : gernalCompName, 'imgType' : 'incumbentPlayers', 'incumbentType': action }
            
            setIncumbentLoader(true);
            setIncumbentName('');
            let preview = document.getElementById('incumbentPreview');
            preview.src = images.path.placeholder;
            setIncumbentImg({ });
        }

        let dataInfo = new FormData();
        if (Object.keys(incumbentImg).length > 0  && codeType == 'incumbentPlayers' ) {
            dataInfo.append('file', incumbentImg.inputFile);
        } else if (Object.keys(startupImg).length > 0  && codeType == 'startupPlayers') {
            dataInfo.append('file', startupImg.inputFile);
        }
        dataInfo.append('data', JSON.stringify(sendData));

        let token = window.localStorage.getItem('token');
        axios.post(`${common.path.base_url}/addNewPipline`, dataInfo, {
            headers: {
                "Authorization": token
            }
        })
        .then((response) => {
            
            const savedData = response.data.data;
            if(codeType == 'startupPlayers') {
                setStartupArr(savedData.startupPlayers);
            } else {
                setIncumbentArr(savedData.incumbentPlayers);
            }
            setIncumbentLoader(false);
            setStartupLoader(false);
        })
        .catch((error) => {
            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
            
                window.localStorage.clear();
                window.location.href = '/';
                return;
            } else {
                swal({
                    title: "Error! #33005",
                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                    icon: "error",
                    button: "Ok!",
                });
            }
        });
    }

    const incumbentDelete = ( index, codeType='incumbentPlayers' ) => {

        swal({
            title: 'Are you sure you want to delete?',
            text: "This item will be delete immediately. You can't undo this action.",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#7A8DA7',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true,
        }).then((resp) => {

            if(resp) {

                if(codeType == 'startupPlayers' ) {
                    startupArr.splice(index, 1); 
                    setStartupArr([...startupArr]);
                    incumbentSave('delete', 'startupPlayers');
                } else {
                    incumbentArr.splice(index, 1); 
                    setIncumbentArr([...incumbentArr]);
                    incumbentSave('delete', 'incumbentPlayers');
                }
            }
        })
    }

    const updateIncumbent = (e, id, codeType='incumbentPlayers') => {

        if( codeType == 'startupPlayers' ) {
            // startupArr[index].companyName = e.target.value;
            // setStartupArr([...startupArr]);
            // setStartupUpdate(e.target.value);
            
        } else if( codeType == 'incumbentPlayers' ) {

            // incumbentArr[index].companyName = e.target.value;
            // setIncumbentUpdate(e.target.value);
            // setIncumbentArr([...incumbentArr]);
        }
    }

    useEffect(() => {
        if(incumbentUpdate != "") {
            const timer = setTimeout(() => {
                incumbentSave('update');
            }, 1500);
            return () => clearTimeout(timer);
        }
    }, [ incumbentUpdate ]);

    useEffect(() => {
        if(startupUpdate != "") {
            const timer = setTimeout(() => {
                incumbentSave('update', 'startupPlayers');
            }, 1500);
            return () => clearTimeout(timer);
        }
    }, [ startupUpdate ]);
    
    const previewFile = ( imgType ) => {
        
        let preview
        let imageFiles
        if(imgType == 'profile') {
            preview = document.getElementById('compProfilePreview');
            imageFiles = document.getElementById("inputProfileFile");
            companyProfileImageUpload(imageFiles);
        
        } else if( imgType == 'incumbent') {
        
            preview = document.getElementById('incumbentPreview');
            imageFiles = document.getElementById("inputIncumbentFile");
        } else if( imgType == 'startup' ) {
            
            preview = document.getElementById('startupPreview');
            imageFiles = document.getElementById("inputStartupFile");
        }
        
        const file = imageFiles.files[0];
        const data = new FormData()
        data.append('file', file);

        if( imgType == 'incumbent') {
            setIncumbentImg({ inputFile: file });
        } else if( imgType == 'startup' ) {
            setStartupImg({ inputFile: file });
        }
        
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            preview.src = reader.result;
        }, false);
        
        if (file) {
            reader.readAsDataURL(file);
        }
    }

    const companyProfileImageUpload = ( file) => {
        const tokenpass = window.localStorage.getItem('token');
        const data = new FormData();
        const path = "company-profile"
        data.append('file', file.files[0]);
        data.append('path', path);

        axios.post(`${common.path.base_url}/s3ImageUploader`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                if (response) {
                    setGeneralCall(true);
                    setGernalCompImg(response.data.imageUrl);
                }
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
            });
    }

    const redirectLinkUrl = (id, type) => {
        var linkdinUrl = 'https://www.linkedin.com/';
        var cbUrl = 'https://www.crunchbase.com/';
        
        let url = document.getElementById(id).value;
        if( url != null && url != 'undefined' && url != "" ) {
            if(type == 2) {
                url = `${cbUrl}${url}`;
            } else {
                url = `${linkdinUrl}${url}`;
            }
            window.open(url, '_blank');
        }
    }

    return (
        <>  
            { ( companyDispaly ) && 
                <div>Let's start with company name !</div>
            }
            <div className='sectionRow mt-20'>
                <div>
                    <div className='round mr-20' disabled = { companyDispaly }>

                        { (companyProfileImg != "" && companyProfileImg != null && companyProfileImg != 'undefined' ) ?
                            <img id='compProfilePreview' src={ companyProfileImg } className='companyImage' alt='' />
                         : (profileImg != "" && profileImg != null && profileImg != 'undefined' ) ? 
                            <img id='compProfilePreview' src={`${common.path.base_url}/getImage?type=company&id=${companyId}`} className='companyImage' alt='' />
                            :
                            <img id='compProfilePreview' src={ images.path.placeholder } className='companyImage' alt='' />
                        }
                        <div className='fileAction'>
                            <div className='fileactionIcon' onClick={(e) => (profileUploadRef.current.click())}>{Icons.Edit}
                                <input id='inputProfileFile' className='piple-input' type="file" ref={profileUploadRef} onChange={() => previewFile('profile')} accept="image/jpeg,image/png,image/jpg" />
                                {/* <input id='choose-file' onChange={() => this.previewFile()} className="file-upload" type="file" accept="image/jpeg,image/png,image/jpg" /> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='compdetailCont'>
                    <div>
                        <input type="text" id="gernalCompName" className='gCompName piple-input' placeholder='Enter Company Name' value={ gernalCompName } onChange ={ (e) => inputHandlerChange(e)  } />
                    </div>
                    <div className='mt-5'>
                        <input type="text" id="gernalState" className='compState piple-input' placeholder='Enter State' value={ generalState } onChange ={ (e) => inputHandlerChange(e)  }  />
                    </div>
                </div>
                <div className='priority-status-dropdown' disabled = { companyDispaly }>
                    <div className='fieldLable'>Priority Status</div>
                    <div className='mt-5'>
                        <select id="priorityStatusType" className='compState piple-select' onChange={ (e) => inputHandlerChange(e) } >
                            <option value="">Select Type</option>
                            { arrPriorityStatus.map((singleData, index) => {
                                    return <option key={ index+"op" } value={ singleData.id } selected={ (singleData.id === gernalPriorityType ) ? true : false } >{ singleData.name }</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className='sectionRow-6 mt-30' disabled = { companyDispaly }>
                <div>
                    <div className='fieldLable'>Legal Company Name</div>
                    <div className='mt-5'>
                        <input type="text" id="generalLegalName" className='compState piple-input' placeholder='Enter Legal Company Name' value={ generalLegalName } onChange ={ (e) => inputHandlerChange(e) } />
                    </div>

                </div>

                <div className='ml-20'>
                    <div className='fieldLable'>Website</div>
                    <div className='mt-5'>
                        <input type="text" id="generalWebsite" className='compState piple-input' placeholder='Enter Website' value={ generalWebsite } onChange ={ (e) => inputHandlerChange(e) } />
                    </div>
                </div>

                <div className='ml-20'>
                    <div className='fieldLable'>Founded</div>
                    <div className='mt-5'>
                        <input type="text" id="generalFounded" className='compState piple-input' placeholder='Founded In' value={ generalFounded } onChange ={ (e) => inputHandlerChange(e) } />
                    </div>
                </div>

                <div className='ml-20'>
                    <div className='fieldLable'>Total Employees</div>
                    <div className='mt-5'>
                        <NumberFormat
                            value={ generalEmployees }
                            thousandSeparator={true}
                            id="generalEmployees"
                            fixedDecimalScale={true}
                            placeholder='Enter Total Employees'
                            className='compState piple-input'
                            onChange={ (e) => { inputHandlerChange(e) } }
                        />
                    </div>
                </div>

                <div className='ml-20'>
                    <div className='fieldLable'>Fiscal Year End</div>
                    <div className='mt-5'>
                        <input type="text" id="generalYearEnd" className='compState piple-input' placeholder='Enter Fiscal Year End' value={ generalYearEnd } onChange ={ (e) => inputHandlerChange(e) } />
                    </div>
                </div>

                <div className='ml-20'>
                    <div className='fieldLable'>Company Type</div>
                    <div className='mt-5'>
                        <select id="compSelectType" className='compState piple-select' onChange={ (e) => inputHandlerChange(e) } >
                            <option value="">Select Type</option>
                            { arrCompanyType.map((singleData, index) => {
                                    return <option key={ index+"op" } value={ singleData.id } selected={ (singleData.id == gernalCompType) ? true : false } >{ singleData.value }</option>
                                })
                            }
                            {/* // <option value="it">IT</option>
                            // <option value="automotive">Automotive</option>
                            // <option value="manufactoring">Manufactoring</option>  */}
                        </select>
                    </div>
                </div>
            </div>

            <div className='sectionRow-1 mt-30' disabled = { companyDispaly }>
                <div className='fieldLable'>Company Overview</div>
                <div className='mt-5'>
                    <textarea className='textArea' id="generalOverview" placeholder='Enter Company Overview' value={ generalOverview } onChange ={ (e) => inputHandlerChange(e) } />
                </div>
            </div>

            <div disabled = { companyDispaly }>
                <div className='fieldLable'>Social Profiles</div>
                <div className='socialmediaCont mt-5'>
                    <div className='socialmediaItem'>
                        <div className='socialiconCont1 mr-10'>
                            <div className='mr-3'>{Icons.Linkedin_1}</div>
                            <div className='domainCont'>.com/</div>
                        </div>
                        <div className='socialinputCont externallinkCont mt-5'>
                            <div className='flex-1'>
                                <input type="text" id="generalLinkedin" className='socialInput piple-input' placeholder='Example michal-jordan2515' value={ generalLinkedin } onChange ={ (e) => inputHandlerChange(e) } />
                            </div>
                            <div className='ml-10' onClick={ (e) => redirectLinkUrl('generalLinkedin', 1) } >{Icons.External}</div>
                        </div>
                    </div>

                    <div className='socialmediaItem'>
                        <div className='socialiconCont1 mr-10'>
                            <div className='mr-3'>{Icons.Crunchbase2}</div>
                            <div className='domainCont'>.com/</div>
                        </div>
                        <div className='socialinputCont externallinkCont mt-5'>
                            <div className='flex-1'>
                                <input type="text" id="generalCrunchbase" className='socialInput piple-input' placeholder='Example michal-jordan2515' value={ generalCrunchbase } onChange ={ (e) => inputHandlerChange(e) } />
                            </div>
                            <div className='ml-10' onClick={ (e) => redirectLinkUrl('generalCrunchbase', 2) } >{Icons.External}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-30'>
                <hr />
            </div>

            <div disabled = { companyDispaly }>
                <div className='sectionHeading2'>Competitive Landscape</div>

                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='sectionHeading3'>Incumbent players</div>
                        <div id="incumbentRecord">
                        { (incumbentArr.length > 0) ?
                            incumbentArr.map((singleData, index) => {   
                            
                               return <IncumbentData 
                                            key={ 'incumbent_'+index }
                                            companyId = { companyId }
                                            singleData = { singleData }
                                            index = { index }
                                            incumbentDelete = { () => incumbentDelete(index) }
                                        />
                            })
                            : <></>
                        }
                        </div>
                        <div className={`listitemCont ${(incumbentArr.length > 0) ? 'listitemContOne':"" }`}>
                            <div className='uploadimageCont mr-10' onClick={(e) => (incumbentuplodRef.current.click())}>
                                <div className='uploadimage'>
                                    <img id='incumbentPreview' src={ images.path.placeholder } className='inputIncumbentImage' alt='' />
                                </div>
                                <input id="inputIncumbentFile" className='piple-input' type="file" ref={incumbentuplodRef} onChange={() => previewFile('incumbent')} accept="image/jpeg,image/png,image/jpg" />
                            </div>
                            <div className='placeholderName'>
                                <input type="text" className='incumbentName piple-input' placeholder='Enter Company Name' onChange={(e) => { handleChanger(e, 'incumbentName'); }} value={ incumbentName } />
                            </div>
                            { (incumbentLoader) ?
                                <><i className="fa fa-spinner fa-spin ex-icon" aria-hidden="true" />Loading </> 
                            : 
                            <div className={"mr-10 ml-10 " + (incumbentName.length > 0 ? 'showcheckIcon' : 'hidecheckIcon') } onClick={ () =>  incumbentSave() } >{Icons.Check}</div>
                            }
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='sectionHeading3 mt-15'>Startup players</div>
                        <div id="startupRecord">    
                        { (startupArr.length > 0) ?
                            startupArr.map((singleData, index) => { 
                            
                            // return <div key={ "startup_"+index } className='listitemCont'>
                            //     <div className='mr-10'>
                            //         { ( singleData.imgUrl != "" && singleData.imgUrl != null && singleData.imgUrl != 'undefined') ? 
                            //             <img src={`${common.path.base_url}/getImage?type=company&typeCode=startupPlayers&codeId=${singleData._id}&id=${companyId}`} className='incumbentImage' alt='' />
                            //         :
                            //             <img src={ images.path.placeholder } className='incumbentImage' alt='' />
                            //         } 
                            //     </div>
                            //     <div className='socialinputCont'>
                            //         <input type="text" className='defaultInput piple-input' placeholder='Enter Key Partner' value={ singleData.companyName } onChange={ (e) => updateIncumbent(e, singleData._id, 'startupPlayers') } />
                            //     </div>
                            //     <div className='keypartnersTrash mr-10' onClick={() => { incumbentDelete(index, 'startupPlayers'); }} >{Icons.Trash}</div>
                            // </div>
                            
                            return <StartupData 
                                        key={ 'startup_'+index }
                                        companyId = { companyId }
                                        singleData = { singleData }
                                        index = { index }
                                        startupDelete = { () => incumbentDelete(index, 'startupPlayers') }
                                    />

                            })
                            : <></>
                        }    

                        </div>    

                        <div className={`listitemCont ${(incumbentArr.length > 0) ? 'listitemContOne':"" }`} >
                            <div className='uploadimageCont mr-10' onClick={(e) => ( startupuplodRef.current.click() )}>
                                <div className='uploadimage'>
                                    <img id='startupPreview' src={ images.path.placeholder } className='inputIncumbentImage' alt='' />
                                </div>
                                <input id="inputStartupFile" className='piple-input' type="file" ref={startupuplodRef} onChange={() => previewFile('startup')} accept="image/jpeg,image/png,image/jpg" />
                            </div>
                            <div className='placeholderName'>
                                <input type="text" className='incumbentName' placeholder='Enter Startup Company Name' onChange={(e) => { handleChanger(e, 'startupName'); }} value={ startupName } />
                            </div>

                            { (startupLoader) ?
                                <><i className="fa fa-spinner fa-spin ex-icon" aria-hidden="true" />Loading </> 
                            : 
                                <div className={"mr-10 ml-10 " + (startupName.length > 0 ? 'showcheckIcon' : 'hidecheckIcon') } onClick={ () =>  incumbentSave('add', 'startupPlayers') } >{Icons.Check}</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default General;
import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import axios from 'axios';
import images from "../../images";
import common from '../../common';
import swal from 'sweetalert';
import Moment from 'react-moment';
import './pipelineCss/pipeline.scss';
import './pipelineCss/default.scss';
import { Icons } from './Icons';

const Sentiments = (props) => {

    const { companyId, companyData, sentiments } = props;
    //const [taskArrData, setTaskArrData] = useState([...taskArr]);
    
    const urlRedirect = (id) => {
        window.location.href = `/investor-sentiments-details?id=${id}`;
        ///investor-sentiments-details?id=
    }

    useEffect(() => {
        if( companyData != "" && companyData != undefined && companyData != null) {
            //setTaskArrData( companyData.tasks );
        }
    }, [companyData]);

    return (
        <>
            <div className='noteCont'>
                <div className='sectionHeading' >Sentiments</div>
                <div>
                    {/* <button className='addnewBTN' onClick={(e) => newAddHandle() }>Add New</button> */}
                </div>
            </div>
            <div>
                <div className='sentimentsClass'>
                    <div className='sentimentsTitle'>Strong Sentiments</div>
                    {
                        sentiments.strongSentiments > 0 ?
                            <div className='sentimentsPer' >{parseFloat(( sentiments.strongSentiments * 100) / ( sentiments.totalInvestors )).toFixed(2)}%</div> :
                            <div className='sentimentsPer'>0%</div>
                    }
                </div>
                <div className='sentimentsClass cursorAction' onClick={() => urlRedirect( companyId )} >
                    <div className='sentimentsTitle'>Investor Responded</div>
                    {
                        (sentiments.pipelineStartupInterest > 0) ?
                            <div className='sentimentsPer' >
                            {sentiments.pipelineStartupInterest}/{sentiments.totalInvestors}
                            </div> :
                            <div className='sentimentsPer'>0%</div>
                    }
                </div>

                { (sentiments.recommendationUser != null && sentiments.recommendationUser) &&
                    <div className='sentimentsClass'>
                        <div className='sentimentsTitle'>Recommended By</div>
                        {
                            sentiments.userRecommendation > 1 ?
                            <div className='sentimentsPer' >{ sentiments.recommendationUser.name } & { sentiments.userRecommendation - 1 } others </div> :
                            <div className='sentimentsPer' >{ sentiments.recommendationUser.name }</div>

                        }
                    </div>
                }
            </div>
        </>
    )
}

export default Sentiments;
import React, { Component } from 'react';
import './TempleteEmail.scss';
import axios from 'axios';
import swal from 'sweetalert';
import common from '../../../../common';
import { withRouter } from 'react-router-dom';


const ListTempleteSms = (props) => {
        
        return (
            <div>
                <div className="new-investment">
                    <h2>SMS Template</h2>
                </div>
                <div className="custom-table-view">
                    <table className='table table-custom'>
                        <thead>
                            <tr>
                                <th width="40%">Title</th>
                                <th width="10%">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.getSMSTempleteList && 
                                props.getSMSTempleteList.map((templateData) => {
                                    
                                    return <tr>
                                        <td>
                                            <div className="document">{ templateData.title }</div>
                                        </td>
                                        <td>
                                            <div className="select-item">
                                                <a title="Edit" style={{ cursor: 'pointer' }} onClick={ () => props.editTemplate(templateData) }>
                                                    <i className="fa fa-pencil fa-cust-pencil" aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                })
                            }    
                        </tbody>
                    </table>

                </div>
            </div>
        );
}
export default ListTempleteSms;    
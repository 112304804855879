import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import swal from 'sweetalert';
import common from '../../../common';
import Header from '../../../components/Header';
import { isLoading, isNotLoading } from '../../../shared/actions/loadingSpinner';
import { setPipelineInvestmentsData } from '../../actions/dashboardData';
import { hideSidebarNotification } from '../../actions/hideSidebarNotifications';
import { resetPopover, setPopover } from '../../actions/popover';
import InvestorService from '../../services/investor.service';
import PipelineInvestmentCard from './PipelineInvestmentCard';
import './PipelineInvestments.scss';

class InvestorSentiments extends Component {
    getRemaningSentimentsText() {
        let count = 0;
        this.props.pipelineInvestments.length &&
            this.props.pipelineInvestments.forEach(element => {
                if (!element.pipelineStartupinterests) {
                    count++;
                }
            });
        if (count > 0) {
            return `Startups Under Consideration`;
        } else {
            return 'Startups Under Consideration';
        }
    }
    sortArray() {
        const jsonArray = this.props.pipelineInvestments;
        jsonArray.sort((a, b) => {
            let nameA = a.companyName.toLowerCase();
            let nameB = b.companyName.toLowerCase();

            // Compare the names
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        return jsonArray;
    }
    setPopover(id, type) {
        this.props.setPopover(type, id)
    }
    resetPopover() {
        const userId = this.getUserId();
        this.props.resetPopover()
        InvestorService.pipelineInvestments(userId)
            .then((pipelineInvestments) => {
                if (pipelineInvestments && pipelineInvestments.data && pipelineInvestments.data.data) {
                    setTimeout(() => {
                        this.props.setPipelineInvestmentsData(pipelineInvestments.data.data);
                    }, 800);
                }
            })
            .catch();
    }
    getUserId = () => {
        if (this.investorId) {
            return this.investorId;
        } else {
            return window.localStorage.getItem('id');
        }
    }
    componentDidMount() {
        const url = new URL(window.location.href);
        this.investorId = url.searchParams.get('investorId');
        if (this.investorId) {
            InvestorService.pipelineInvestments(this.investorId)
                .then(pipelineInvestments => {
                    if (pipelineInvestments && pipelineInvestments.data && pipelineInvestments.data.data) {
                        this.props.setPipelineInvestmentsData(pipelineInvestments.data.data);
                    }
                }).catch({});
        } else {
            InvestorService.pipelineInvestments(window.localStorage.getItem('id'))
                .then(pipelineInvestments => {
                    if (pipelineInvestments && pipelineInvestments.data && pipelineInvestments.data.data) {
                        this.props.setPipelineInvestmentsData(pipelineInvestments.data.data);
                    }
                }).catch({});
        }


    }
    investorSentimentInterestHandler(interestData, interestType) {
        const userId = this.getUserId();
        const data = {
            companyId: interestData._id,
            userId,
            interestType,
            name: interestData.companyName,

        }
        // this.props.isLoading();
        InvestorService.investorSntimentsInterest(data)
            // .then(() => {
            //     return InvestorService.pipelineInvestments(userId);
            // })
            .then((pipelineInvestments) => {
                if (pipelineInvestments && pipelineInvestments.data && pipelineInvestments.data.data) {
                    const data = this.props.pipelineInvestments.map(item => {
                        if (item._id === interestData._id) {
                            return {
                                ...item,
                                pipelineStartupinterests: {
                                    companyId: interestData._id,
                                    userId,
                                    interestType,
                                    interestComment: '',
                                    name: interestData.companyName,
                                }
                            }
                        } else {
                            return { ...item, }
                        }
                    })
                    this.setPopover(interestData._id, interestType)
                    this.props.setPipelineInvestmentsData(data);
                    this.sortArray();
                }
                // this.props.isNotLoading()
            })
            .catch((error) => {
                console.log(83, error);
                // this.props.isNotLoading();
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                // // TODO: handle error
                if (error.response && error.response.data && error.response.data.code && error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
            });

    }
    saveAddtionalThoughtsHandler(comment, companyId) {
        const userId = this.getUserId();
        InvestorService.saveAddtionalThoughts({ comment, companyId, userId })
            .then(() => this.resetPopover())
            .catch({})
    }
    cancelPopOver() {
        this.resetPopover()
    }
    hideNotificationSidebar() {
        this.props.hideSidebarNotification()
    }
    render() {
        const { props } = this;
        const pipelineInvestments = this.sortArray();
        return (
            <div onClick={() => this.hideNotificationSidebar()}>
                <Header
                    username={window.localStorage.getItem('username')}
                    role={window.localStorage.getItem('role')}
                />
                {
                    pipelineInvestments.length > 0 &&
                    <div onClick={() => this.resetPopover()}>
                        <div className="invitations_sec">
                            <div className="container-fluid ">
                                <h3>{this.getRemaningSentimentsText()}</h3>
                                <div className={props.popover ? 'card clicked' : 'card'}>
                                    <div className="close-investor-sentiments">
                                        <button type="button" className="close" aria-label="Close" onClick={() => { this.investorId ? this.props.history.push(`/investor-dashboard?investorId=${this.investorId}`) : this.props.history.push(`/investor-dashboard`) }}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div>
                                        {
                                            pipelineInvestments.map((pipelineInvestment, index) => {
                                                return <PipelineInvestmentCard
                                                    pipelineInvestment={pipelineInvestment}
                                                    index={index}
                                                    key={index}
                                                    fromSentiments={true}
                                                    total={props.pipelineInvestments.length}
                                                    investorSentimentInterest={(interestData, interestType) => this.investorSentimentInterestHandler(interestData, interestType)}
                                                    saveAddtionalThoughts={(comment, id) => this.saveAddtionalThoughtsHandler(comment, id)}
                                                    cancelPopOver={() => this.cancelPopOver()}
                                                    {...props}

                                                />
                                            })

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )

    }

}
const mapStateToProps = state => {
    console.log(state.investorDashboardData.pipelineInvestments)
    return {
        popover: state.popover && state.popover.id ? state.popover.id : null,
        pipelineInvestments: state.investorDashboardData.pipelineInvestments.length > 0 ? state.investorDashboardData.pipelineInvestments : []
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        isLoading: bindActionCreators(isLoading, dispatch),
        isNotLoading: bindActionCreators(isNotLoading, dispatch),
        setPopover: bindActionCreators(setPopover, dispatch),
        resetPopover: bindActionCreators(resetPopover, dispatch),
        setPipelineInvestmentsData: bindActionCreators(setPipelineInvestmentsData, dispatch),
        hideSidebarNotification: bindActionCreators(hideSidebarNotification, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestorSentiments);
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { Alert } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import NumberFormat from 'react-number-format';
import LoadingSpinner from '../../components/Loader';
import common from '../../common';
import { isLoading, isNotLoading } from '../../shared/actions/loadingSpinner';
import { setSummaryStartupsData } from '../actions/summaryStartups';


const SummaryStartupsList = props => {

    const { investment } = props;
    const company = props.investment.companyId;
    const pricePerShare = investment.pricePerShare;
    const mgFee = 5;
    let totalInvestAmt = Number( props.totalInvestmentAmt ).toFixed(2);
    let totalDoucSignAmt = props.totalDocSignAmt;
    let totalNetDocuSignAmt = props.totalNetDocSignAmt;
    let totManagementFee = Number(props.totalManagementFee).toFixed(2);
    let totUserShares = Number(props.totalUserShares).toFixed(2);
    let companyValuation = (investment.totalAllocation) ? Number(investment.totalAllocation).toFixed(2) : 0;
    let getAllPPSCount = (companyValuation > 0) ? (companyValuation / pricePerShare) : 0;
    const userInvesterCount = props.userCount;
    const totalInvestmentCount = props.totalInvestmentCount;
    
    const [alertShow, setAlertShow] = useState(0);
    const [s1PPSCount, sets1PPSCount] = useState(pricePerShare);
    const [s2PPSCount, sets2PPSCount] = useState(pricePerShare);
    const [s1SHARES, sets1SHARES] = useState(0);
    const [s2SHARES, sets2SHARES] = useState(0);
    const [s1Total, sets1Total] = useState(0);
    const [s2Total, sets2Total] = useState(0);
    const [s1MgFee, sets1MgFee] = useState(0);
    const [s2MgFee, sets2MgFee] = useState(0);
    const [net1Total, setNet1Total] = useState(0);
    const [net2Total, setNet2Total] = useState(0);
    const [netFixedTotal, setNetFixedTotal] = useState(0);
    // Card Box
    const [totalInvestmentAmt, setTotalInvestmentAmt ] = useState(totalInvestAmt);
    const [totalDocSignAmt, setTotalDocSignAmt ] = useState(totalDoucSignAmt);
    const [totalNetDocSignAmt, setTotalNetDocSignAmt ] = useState(totalNetDocuSignAmt);
    const [totalDocSignShares, setTotalDocSignShares ] = useState();
    const [totalManagementFee, setTotalManagementFee ] = useState(totManagementFee);
    const [totalNetOfFunded, setTotalNetOfFunded ] = useState();
    const [totalGrossRemaining, setTotalGrossRemaining ] = useState();
    const [totalNetRemaining, setTotalNetRemaining ] = useState();
    const [totalUserShares, setTotalUserShares ] = useState(totUserShares);
    const [totalNetUserShares, setTotalNetUserShares ] = useState();
    const [investmentAverageAmt, setInvestmentAverageAmt ] = useState();
    
    const [getPPSLock, setPPSLock] = useState(false);
    const [getShareLock, setShareLock] = useState(false);
    const [getGrossLock, setGrossLock] = useState(true);

    useEffect(() => {  
        setTotalDocSignAmt(totalDoucSignAmt) 
        let docVal = totalDoucSignAmt / pricePerShare;
        setTotalDocSignShares(docVal);
    },[props.totalDocSignAmt]);
    
    useEffect(() => {  sets1PPSCount(pricePerShare); sets2PPSCount(pricePerShare);  }, [pricePerShare]);
    useEffect(() => { 
        const timer = setTimeout(() => {
            if( Number(companyValuation) <= 0 ) {
                setAlertShow(1); 
            } else {
                setAlertShow(0);
            } 
        }, 1500);
        return () => clearTimeout(timer);
    
    }, [companyValuation]);

    useEffect(() => {  

        let totalDcSignAmt
        let totalNetDcSignAmt
        let netOfFound = totalInvestAmt - totManagementFee;
        let grossRemaining = ( companyValuation > 0 ) ? ( (companyValuation/0.95) - totalInvestAmt ) : 0;

        //grossRemaining = ( grossRemaining > 0 ) ? grossRemaining : 0;
        //let netRemaining =  Number(totManagementFee) + Number(grossRemaining);
        let netRemaining =  Number(companyValuation) - Number(netOfFound);
        
        let investmentAvgAmt =  Number(netOfFound) / Number(userInvesterCount);
        investmentAvgAmt = Number(investmentAvgAmt).toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2});
        let totNetShares = 0;

        const numberNetRemaining = (netRemaining > 0 ) ? Number(netRemaining).toFixed(2) : 0;
        netRemaining = Number(netRemaining).toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2});
        grossRemaining = Number(grossRemaining).toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2});
        totManagementFee = Number(totManagementFee).toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2});
        netOfFound = Number(netOfFound).toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2 });
        totalDcSignAmt = Number(totalDoucSignAmt).toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2});
        totalNetDcSignAmt = Number(totalNetDocuSignAmt).toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2});
        totNetShares = (getAllPPSCount - totUserShares) > 0 ? (getAllPPSCount - totUserShares) : 0;
        totNetShares = Number(totNetShares).toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2});
        
        
        setTotalInvestmentAmt(totalInvestAmt); 
        setTotalDocSignAmt(totalDcSignAmt);
        setTotalNetDocSignAmt(totalNetDcSignAmt); 
        setTotalManagementFee(totManagementFee);
        setTotalNetOfFunded(netOfFound);
        setTotalGrossRemaining(grossRemaining);
        setTotalNetRemaining(netRemaining);
        setInvestmentAverageAmt(investmentAvgAmt);
        setTotalUserShares(totUserShares);
        setTotalNetUserShares(totNetShares);
        
        let docVal = totalDoucSignAmt / pricePerShare;
        setTotalDocSignShares(docVal);
        
        setNetFixedTotal(numberNetRemaining);
        setNet1Total(numberNetRemaining);
        calCallBoth(numberNetRemaining, 1, 1);
        
    },[ totalInvestAmt, totManagementFee, totalNetDocuSignAmt ]);

    function calCallBoth(netTotal, type, fixedtype=0){
        
        if( type == 1) {
            
            let lNetTotal = (netTotal > 0) ? netTotal : 0;
            let tots = (lNetTotal * 100) / (100 - s1MgFee);
            tots = Number(tots).toFixed(2);
            let sharesCount = (s1PPSCount > 0) ? ( tots / s1PPSCount ) : 0;
            sharesCount = Number(sharesCount).toFixed(2);
            
            let secondNet
            if(fixedtype == 1) {
                secondNet = 0;
            } else {
                secondNet = Number(netFixedTotal) - lNetTotal;
            }
            
            secondNet = (secondNet > 0) ? Number(secondNet).toFixed(2) : 0;
            let tots2 = (secondNet > 0) ? (secondNet * 100) / (100 - s2MgFee) : 0;
            tots2 = Number(tots2).toFixed(2);
            let shares2Count = (s2PPSCount > 0) ? ( tots2 / s2PPSCount ) : 0;
            shares2Count = Number(shares2Count).toFixed(2);
            
            sets1Total(tots);
            sets1SHARES(sharesCount);
            setNet2Total(secondNet);
            sets2Total(tots2);
            sets2SHARES(shares2Count);
        } else {

            let lNetTotal = (netTotal > 0) ? netTotal : 0;
            let tots2 = (lNetTotal * 100) / (100 - s2MgFee);
            tots2 = Number(tots2).toFixed(2);
            let shares2Count = (s2PPSCount > 0) ? ( tots2 / s2PPSCount ) : 0;
            shares2Count = Number(shares2Count).toFixed(2);
            
            let fristNet = Number(netFixedTotal) - lNetTotal;
            fristNet = (fristNet > 0) ? Number(fristNet).toFixed(2) : 0;
            let tots = (fristNet > 0) ? (fristNet * 100) / (100 - s1MgFee) : 0;
            tots = Number(tots).toFixed(2);
            let shares1Count = (s1PPSCount > 0) ? ( tots / s1PPSCount ) : 0;
            shares1Count = Number(shares1Count).toFixed(2);

            setNet1Total(fristNet);
            sets1Total(tots);
            sets1SHARES(shares1Count);
            sets2SHARES(shares2Count);
            sets2Total(tots2);
        }
    }   

    function calCallAll(netTotal, type, checkSet='', fixedPPS=false){

        if( type == 1) {
            
            let totShares = (pricePerShare > 0 &&  netTotal > 0) ?  netTotal / pricePerShare : 0;
            totShares = Number(totShares).toFixed(2);
            
            let lNetTotal = (netTotal > 0) ? netTotal : 0;
            let secondNet = Number(netFixedTotal) - lNetTotal;
            secondNet = (secondNet > 0) ? Number(secondNet).toFixed(2) : 0;
            
            let tots2 = (secondNet > 0) ? (Number(secondNet) + (Number(s2MgFee) > 0 ? Number(s2MgFee) : 0)) : 0;
            tots2 = Number(tots2).toFixed(2);
            
            let shares2Count = (pricePerShare > 0) ? ( secondNet / pricePerShare ) : 0;
            shares2Count = Number(shares2Count).toFixed(2);

            let pps2Count = (shares2Count > 0) ? ( tots2 / shares2Count ) : 0;
            pps2Count = Number(pps2Count).toFixed(2);
            
            if(!fixedPPS) {

                let tsgmgfee = s1Total - lNetTotal;
                tsgmgfee = (tsgmgfee > 0) ? tsgmgfee : 0;
                tsgmgfee = Number(tsgmgfee).toFixed(2);

                let currpps = (totShares > 0) ? (s1Total / totShares) : pricePerShare;
                currpps = Number(currpps).toFixed(4);
                
                sets1MgFee(tsgmgfee);
                sets1PPSCount(currpps);
            } else {

                let currtot = (totShares > 0) ? (s1PPSCount * totShares) : 0;
                currtot = Number(currtot).toFixed(2);
                
                let tsgmgfee = currtot - lNetTotal;
                tsgmgfee = (tsgmgfee > 0) ? tsgmgfee : 0;
                tsgmgfee = Number(tsgmgfee).toFixed(2);

                sets1MgFee(tsgmgfee);
                sets1Total(currtot);
            }

            if(checkSet != 'Shares') {
                sets1SHARES(totShares);
            }

            sets2Total(tots2);
            sets2SHARES(shares2Count);
            setNet2Total(secondNet);
            sets2PPSCount(pps2Count);
            
        } else {
            
            let totShares = (pricePerShare > 0 &&  netTotal > 0) ?  netTotal / pricePerShare : 0;
            totShares = Number(totShares).toFixed(2);
            
            let lNetTotal = (netTotal > 0) ? netTotal : 0;
            let fristNet = Number(netFixedTotal) - lNetTotal;
            fristNet = (fristNet > 0) ? Number(fristNet).toFixed(2) : 0;
            
            let tots = (fristNet > 0) ? (Number(fristNet) + (Number(s1MgFee) > 0 ? Number(s1MgFee) : 0)) : 0;
            tots = Number(tots).toFixed(2);
            
            let shares1Count = (pricePerShare > 0) ? ( fristNet / pricePerShare ) : 0;
            shares1Count = Number(shares1Count).toFixed(2);

            let pps1Count = (shares1Count > 0) ? ( tots / shares1Count ) : 0;
            pps1Count = Number(pps1Count).toFixed(2);
            
            if(!fixedPPS) {

                let tsgmgfee = s2Total - lNetTotal;
                tsgmgfee = (tsgmgfee > 0) ? tsgmgfee : 0;
                tsgmgfee = Number(tsgmgfee).toFixed(2);

                let currpps = (totShares > 0) ? (s2Total / totShares) : 0;
                currpps = Number(currpps).toFixed(4);
                
                sets2MgFee(tsgmgfee);
                sets2PPSCount(currpps);

            } else {

                let currtot = (totShares > 0) ? (s2PPSCount * totShares) : 0;
                currtot = Number(currtot).toFixed(2);
                
                let tsgmgfee = currtot - lNetTotal;
                tsgmgfee = (tsgmgfee > 0) ? tsgmgfee : 0;
                tsgmgfee = Number(tsgmgfee).toFixed(2);

                sets2MgFee(tsgmgfee);
                sets2Total(currtot);
            }

            if(checkSet != 'Shares') {
                sets2SHARES(totShares);
            }

            sets1Total(tots);
            sets1SHARES(shares1Count);
            setNet1Total(fristNet);
            sets1PPSCount(pps1Count);
        }
    } 

    function ppsChange(val, type) {
        
        if(type == 1) {
            
            let ppsnewVal = val;
            if(val == "" || val == 'undefined') { ppsnewVal = 0; }
            sets1PPSCount(val);
            
            if( !getShareLock ) {
                
                
                let totShares = (ppsnewVal > 0) ? (s1Total / ppsnewVal) : 0;
                totShares = Number(totShares).toFixed(2);

                let netTotAll = totShares * pricePerShare;
                netTotAll = Number(netTotAll).toFixed(2);

                let tsgmgfee = s1Total - netTotAll;
                tsgmgfee = Number(tsgmgfee).toFixed(2);

                sets1SHARES(totShares);
                setNet1Total(netTotAll);
                sets1MgFee(tsgmgfee);

                let lNetTotal = (netTotAll > 0) ? netTotAll : 0;
                let secondNet = Number(netFixedTotal) - lNetTotal;
                secondNet = (Number(secondNet) > 0) ? Number(secondNet).toFixed(2) : 0;
                
                let tots2 = (Number(secondNet) > 0) ? (Number(secondNet) + (Number(s2MgFee) > 0 ? Number(s2MgFee) : 0)) : 0;
                tots2 = Number(tots2).toFixed(2);
                
                let shares2Count = (pricePerShare > 0) ? ( secondNet / pricePerShare ) : 0;
                shares2Count = Number(shares2Count).toFixed(2);

                let pps2Count = (shares2Count > 0) ? ( tots2 / shares2Count ) : 0;
                pps2Count = Number(pps2Count).toFixed(2);

                sets2Total(tots2);
                sets2SHARES(shares2Count);
                setNet2Total(secondNet);
                sets2PPSCount(pps2Count);
            } else {

                let totShare = s1SHARES;
                let totall = (totShare > 0) ? (totShare * val) : 0;
                sets1Total(totall);

                let tsgmgfee = Number(totall) - Number(net1Total);
                tsgmgfee = Number(tsgmgfee).toFixed(2);
                sets1MgFee(tsgmgfee);
            }    
        
        } else if(type == 2){
            
            let ppsnewVal = val;
            if(val == "" || val == 'undefined') { ppsnewVal = 0; }
            sets2PPSCount(val);
            
            if( !getShareLock ) {

                let totShares = (ppsnewVal > 0) ? (s2Total / ppsnewVal) : 0;
                totShares = Number(totShares).toFixed(2);

                let netTotAll = totShares * pricePerShare;
                netTotAll = Number(netTotAll).toFixed(2);

                let tsgmgfee = s2Total - netTotAll;
                tsgmgfee = Number(tsgmgfee).toFixed(2);

                sets2SHARES(totShares);
                setNet2Total(netTotAll);
                sets2MgFee(tsgmgfee);

                let lNetTotal = (netTotAll > 0) ? netTotAll : 0;
                let fristNet = Number(netFixedTotal) - lNetTotal;
                fristNet = (Number(fristNet) > 0) ? Number(fristNet).toFixed(2) : 0;
                
                let tots = (Number(fristNet) > 0) ? (Number(fristNet) + (Number(s1MgFee) > 0 ? Number(s1MgFee) : 0)) : 0;
                tots = Number(tots).toFixed(2);
                
                let shares1Count = (pricePerShare > 0) ? ( fristNet / pricePerShare ) : 0;
                shares1Count = Number(shares1Count).toFixed(2);

                let pps1Count = (shares1Count > 0) ? ( tots / shares1Count ) : 0;
                pps1Count = Number(pps1Count).toFixed(2);

                sets1Total(tots);
                sets1SHARES(shares1Count);
                setNet1Total(fristNet);
                sets1PPSCount(pps1Count);
            
            } else {
                let totShare = s2SHARES;
                let totall = (totShare > 0) ? (totShare * val) : 0;
                sets2Total(totall);

                let tsgmgfee = Number(totall) - Number(net2Total);
                tsgmgfee = Number(tsgmgfee).toFixed(2);
                sets2MgFee(tsgmgfee);
            }    
        }    
    }
    
    function sharesChange(val, type) {
        if(type == 1) {
            
            sets1SHARES(val);
            if(val == "" || val == 'undefined') {  val = 0; }
            const sharesVal = val;
            
            let nettotval = val * pricePerShare;
            nettotval = Number(nettotval).toFixed(2);
            setNet1Total(nettotval);
            
            if( !getPPSLock ) {
            
                calCallAll(nettotval, 1, 'Shares');
            } else {
                
                calCallAll(nettotval, 1, 'Shares', true);
            }
        } else if(type == 2) {
            
            sets2SHARES(val);
            if(val == "" || val == 'undefined') {  val = 0; }
            const sharesVal = val;
            
            let nettotval = val * pricePerShare;
            nettotval = Number(nettotval).toFixed(2);
            setNet2Total(nettotval);
            
            // let totVal = (sharesVal > 0) ? (s2Total / sharesVal) : 0;
            // totVal = Number(totVal).toFixed(4);
            // sets2PPSCount(totVal);
            
            // let tsgmgfee = s2Total - nettotval;
            // tsgmgfee = Number(tsgmgfee).toFixed(2);
            // sets2MgFee(tsgmgfee);

            if( !getPPSLock ) {
            
                calCallAll(nettotval, 2, 'Shares');
            } else {
                
                calCallAll(nettotval, 2, 'Shares', true);
            }
        }
    } 
    
    function totalChange(val, type) {
        if(type == 1) {
            
            let tsgmgfee = val - net1Total;
            tsgmgfee = (tsgmgfee > 0) ? tsgmgfee : 0; 
            tsgmgfee = Number(tsgmgfee).toFixed(2);
            
            if( !getPPSLock ) {
                let newpps = (s1SHARES > 0) ? val / s1SHARES : 0; 
                newpps = Number(newpps).toFixed(4);
                
                sets1PPSCount(newpps);
                sets1MgFee(tsgmgfee);
                sets1Total(val);

            } else {
                
                let sharesV = (s1PPSCount > 0) ? ( val / s1PPSCount ) : 0;
                sharesV = Number(sharesV).toFixed(2);
                
                let totcount = (sharesV > 0) ? (sharesV * pricePerShare) : 0;
                totcount = Number(totcount).toFixed(2);
                
                let lNetTotal = totcount;
                let secondNet = Number(netFixedTotal) - lNetTotal;
                secondNet = (secondNet > 0) ? Number(secondNet).toFixed(2) : 0;

                let tots2 = (secondNet > 0) ? (Number(secondNet) + (Number(s2MgFee) > 0 ? Number(s2MgFee) : 0)) : 0;
                tots2 = Number(tots2).toFixed(2);
                
                let shares2Count = (pricePerShare > 0) ? ( secondNet / pricePerShare ) : 0;
                shares2Count = Number(shares2Count).toFixed(2);

                let pps2Count = (shares2Count > 0) ? ( tots2 / shares2Count ) : 0;
                pps2Count = Number(pps2Count).toFixed(2);

                let tsgmgfee = val - totcount;
                tsgmgfee = (tsgmgfee > 0) ? tsgmgfee : 0; 
                tsgmgfee = Number(tsgmgfee).toFixed(2);

                setNet1Total(totcount);
                sets1SHARES(sharesV);
                sets1MgFee(tsgmgfee);
                sets1Total(val);

                sets2Total(tots2);
                sets2SHARES(shares2Count);
                setNet2Total(secondNet);
                sets2PPSCount(pps2Count);
            }
            
        
        } else if(type == 2) {

            let tsgmgfee = val - net2Total;
            tsgmgfee = (tsgmgfee > 0) ? tsgmgfee : 0;
            tsgmgfee = Number(tsgmgfee).toFixed(2);

            if( !getPPSLock ) {
                let newpps = (s2SHARES > 0) ? val / s2SHARES : 0; 
                newpps = Number(newpps).toFixed(4);
                sets2PPSCount(newpps);
            } else {
                let sharesV = (s2PPSCount > 0) ? ( val / s2PPSCount ) : 0;
                sharesV = Number(sharesV).toFixed(2);
                
                let totcount = (sharesV > 0) ? (sharesV * pricePerShare) : 0;
                totcount = Number(totcount).toFixed(2);
                
                let lNetTotal = totcount;
                let fristNet = Number(netFixedTotal) - lNetTotal;
                fristNet = (fristNet > 0) ? Number(fristNet).toFixed(2) : 0;
                
                let tots = (fristNet > 0) ? (Number(fristNet) + (Number(s1MgFee) > 0 ? Number(s1MgFee) : 0)) : 0;
                tots = Number(tots).toFixed(2);
                
                let shares1Count = (pricePerShare > 0) ? ( fristNet / pricePerShare ) : 0;
                shares1Count = Number(shares1Count).toFixed(2);

                let pps1Count = (shares1Count > 0) ? ( tots / shares1Count ) : 0;
                pps1Count = Number(pps1Count).toFixed(2);

                setNet2Total(totcount);
                sets2SHARES(sharesV);
                
                sets1Total(tots);
                sets1SHARES(shares1Count);
                setNet1Total(fristNet);
                sets1PPSCount(pps1Count);
            }
            sets2MgFee(tsgmgfee);
            sets2Total(val);
        }
    }

    function mgFeeChange(val, type) {
        if( type == 1 ) {
            
            let tots = (net1Total > 0) ? (net1Total * 100) / (100 - val) : 0;
            tots = Number(tots).toFixed(2);
            let sharesCount = (s1PPSCount > 0) ? ( tots / s1PPSCount ) : 0;
            sharesCount = Number(sharesCount).toFixed(2);
        
            sets1MgFee(val);
            sets1Total(tots);
            sets1SHARES(sharesCount);
        
        } else if( type == 2 ) {
            
            let tots = (net2Total > 0) ? (net2Total * 100) / (100 - val) : 0;
            tots = Number(tots).toFixed(2);
            let sharesCount = (s2PPSCount > 0) ? ( tots / s2PPSCount ) : 0;
            sharesCount = Number(sharesCount).toFixed(2);
        
            sets2MgFee(val);
            sets2Total(tots);
            sets2SHARES(sharesCount);
        }    
    }
    function netTotalChange (val, type) {

        if(type == 1) {
            calCallAll(val, type);
            setNet1Total(val);
        
        } else {
            
            calCallAll(val, type);
            setNet2Total(val);
        }
    }

    
    function resetAllCal(val) {
        
        sets1PPSCount(pricePerShare);
        sets1MgFee(0);
        setNet1Total(netFixedTotal);
        sets1Total(netFixedTotal);
        sets2PPSCount(pricePerShare);
        sets2MgFee(0);
        sets2Total(0);
        setNet2Total(0);
        
        let lNetTotal = (netFixedTotal > 0) ? netFixedTotal : 0;
        let tots = Number(lNetTotal).toFixed(2);
        let sharesCount = (pricePerShare > 0) ? ( tots / pricePerShare ) : 0;
        sharesCount = Number(sharesCount).toFixed(2);
        sets1SHARES(sharesCount);
        sets2SHARES(0);
        grossLock();
        //calCallAll(netFixedTotal, 1);
        //calCallBoth(netFixedTotal, 1,1);
    }

    function backUrl(){
        window.location.href=`/investmentDetail?id=${ investment._id }&activeTab=invite`;
    }

    function ppsLock(){
        setPPSLock(true);
        setShareLock(false);
        setGrossLock(false);
    }
    
    function sharesLock() {
        setPPSLock(false);
        setShareLock(true);
        setGrossLock(false);
    }
    
    function grossLock() {
        setPPSLock(false);
        setShareLock(false);
        setGrossLock(true);
    }
    
    return (
        <>
            {
                <div className='col-md-12 box-part-sum cod-laptop'>
                    <div className='row'>
                        {   ( alertShow > 0 ) && 
                            <div>
                                <div role="alert" className="alert cust-alert-danger">
                                    Please update total allocation in invesment 
                                </div>
                            </div>
                        }

                        {/* <div className='col-md-12 header-summary'>
                            <div className='title'>Summary</div>
                            <div className='back-button'>  <button onClick={ e => backUrl() } className='tsg-btn contact-btn'>Back</button> </div>
                        </div> */}
                        <div className='col-md-12 box-row'>
                            <div className='row'>
                                {/* <div className='col-md-4'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='avg-inv-box-invest mrg-bot-10'>
                                                <span > { (company) ? 
                                                         <img id='preview' className="investment-logo" src={`${common.path.base_url}/getFileNew?fileId=${company.companyLogo}`} alt='' />
                                                        : 
                                                        "" }</span>
                                                <span className='avg-investment-name' >{ investment.name }</span>
                                            </div>  
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='avg-inv-box'>Average: 
                                                <span className='val-box'>${ investmentAverageAmt }</span>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>    
                                            <div className='avg-inv-box'>Total Investors: 
                                                <span className='val-box'>{ userInvesterCount }</span>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className='col-md-2'>
                                    <div className='full-box'>
                                        <span className='avg-full-box avg-border '>Average : 
                                            <span className='avg-full-mid'> ${ investmentAverageAmt }</span>
                                        </span>
                                        <div>
                                            <span className='avg-half-box avg-half65'>Total Investors  
                                                <span className='avg-half-mid'>{ totalInvestmentCount }</span>
                                            </span>
                                            <span className='avg-half-box clearboth avg-half35'>Funded  
                                                <span className='avg-half-mid'>{ userInvesterCount }</span>
                                            </span>
                                        </div>    
                                    </div>
                                </div>
                                {/* <div className='col-md-2'>
                                    <div className='full-box'>
                                        <span className='full-box-head'>Total Investors</span> 
                                        <span className='full-box-mid'>{ userInvesterCount }</span>
                                    </div>
                                </div>     */}
                                <div className='col-md-2'>
                                    <div className='full-box'>
                                        <span className='full-box-head'>Total Allocation</span> 
                                        <span className='full-box-mid'>${ Number(companyValuation).toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2}) }</span>
                                        <span className='full-box-foot'>{ Number(getAllPPSCount).toLocaleString(undefined, {maximumFractionDigits:2 }) } Shares</span>
                                    </div>  
                                </div>
                                <div className='col-md-2'>
                                    <div className='full-box'>
                                        <span className='full-box-head'>Fees Total 
                                            {/* <span className='i-icon'>i</span> */}
                                        </span> 
                                        <span className='full-box-mid'>${ totalManagementFee }</span>
                                    </div>  
                                </div>
                                <div className='col-md-6'>
                                    <div className='cal-box-s'>
                                        <div className='cal-s1-box'>
                                            <span className='ss-cal-text'>
                                                S-1: 
                                            </span>
                                            <span className='input-short'>
                                                <span className='input-txt-cal'>PPS
                                                    <span className='lock-call' onClick={ (e) => { ppsLock() } }>
                                                        { (getPPSLock) ?
                                                            <i className="fa fa-lock" aria-hidden="true"></i>
                                                            :
                                                            <i className="fa fa-unlock" aria-hidden="true"></i>
                                                        }
                                                    </span>
                                                </span>
                                                {/* <span className='icon-dollor'>$</span> */}
                                                {/* <input className='s-input' type='text' name='s1_pps' value={ s1PPSCount } onChange={e => ppsChange(e.target.value, 1)} maxLength={ 10 }/> */}
                                                <NumberFormat
                                                    value={ s1PPSCount }
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    placeholder=''
                                                    className='s-input'
                                                    onChange={ (e) => {
                                                        let valC = e.target.value;
                                                        valC = valC.replace(/\,/g,'');
                                                        valC = valC.replace(/\$/g,'');    
                                                        ppsChange(valC, 1)}
                                                    }
                                                />

                                            </span>
                                            <span className='icon-math'>
                                                <svg xmlns="http://www.w3.org/2000/svg"  fill="#000000" className="bi bi-x" viewBox="0 0 16 16">
                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                                </svg>
                                            </span>
                                            <span className='input-long'>
                                                <span className='input-txt-cal'>SHARES
                                                    <span className='lock-call' onClick={ (e) => { sharesLock() } }>
                                                        { (getShareLock) ?
                                                            <i className="fa fa-lock" aria-hidden="true"></i>
                                                            :
                                                            <i className="fa fa-unlock" aria-hidden="true"></i>
                                                        }
                                                    </span>
                                                </span>
                                                {/* <input className='s-input' type='text' name='s1_shares' value={ s1SHARES } onChange={e => sharesChange(e.target.value, 1)} /> */}
                                                <NumberFormat
                                                    value={ s1SHARES }
                                                    thousandSeparator={true}
                                                    fixedDecimalScale={true}
                                                    placeholder=''
                                                    className='s-input'
                                                    onChange={ (e) => {
                                                        let valC = e.target.value;
                                                        valC = valC.replace(/\,/g,'');
                                                        valC = valC.replace(/\$/g,'');    
                                                        sharesChange(valC, 1) }
                                                    }
                                                />

                                            </span>
                                            <span className='icon-math'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" className="bi bi-pause" viewBox="0 0 16 16">
                                                    <path d="M6 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5zm4 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5z"/>
                                                </svg>
                                            </span>
                                            <span className='input-long'>
                                                <span className='input-txt-cal'>GROSS
                                                    <span className='lock-call' onClick={ (e) => { grossLock() } }>
                                                        { (getGrossLock) ?
                                                            <i className="fa fa-lock" aria-hidden="true"></i>
                                                            :
                                                            <i className="fa fa-unlock" aria-hidden="true"></i>
                                                        }
                                                    </span>
                                                </span>
                                                {/* <input className='s-input' type='text' name='s1_shares' value={ s1Total } onChange={e => totalChange(e.target.value, 1)} /> */}
                                                <NumberFormat
                                                    value={ s1Total }
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    placeholder=''
                                                    className='s-input'
                                                    onChange={ (e) => {
                                                        let valC = e.target.value;
                                                        valC = valC.replace(/\,/g,'');
                                                        valC = valC.replace(/\$/g,'');    
                                                        totalChange(valC, 1)}
                                                    }
                                                    />
                                            </span>
                                            <span className='icon-math'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" className="bi bi-dash" viewBox="0 0 16 16">
                                                    <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                                                </svg>
                                            </span>
                                            <span className='input-short'>
                                                <span className='input-txt-cal'>FEE</span>
                                                {/* <input className='s-input' type='text' name='s1_shares' value={ s1MgFee } onChange={e => mgFeeChange(e.target.value, 1)} disabled /> */}
                                                <NumberFormat
                                                    value={ s1MgFee }
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    placeholder=''
                                                    className='s-input'
                                                    disabled = 'disabled'
                                                    onChange={ (e) => {
                                                        let valC = e.target.value;
                                                        valC = valC.replace(/\,/g,'');
                                                        valC = valC.replace(/\$/g,'');    
                                                        mgFeeChange(valC, 1)}
                                                    }
                                                />
                                            </span>
                                            <span className='icon-math'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" className="bi bi-pause" viewBox="0 0 16 16">
                                                    <path d="M6 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5zm4 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5z"/>
                                                </svg>
                                            </span>
                                            <span className='input-long'>
                                                <span className='input-txt-cal'>NET</span>
                                                {/* <input className='s-input' type='text' name='net_changes' value={ net1Total } onChange={e => netTotalChange(e.target.value, 1)} /> */}
                                                <NumberFormat
                                                    value={ net1Total }
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    className='s-input'
                                                    //onChange={ (e) => netTotalChange(e.value, 1) }
                                                    onChange={ (e) => {
                                                        let valC = e.target.value;
                                                        valC = valC.replace(/\,/g,'');
                                                        valC = valC.replace(/\$/g,'');    
                                                        netTotalChange(valC, 1)}
                                                    } 
                                                />
                                            </span>
                                            <span className='repeat-icon' title='Reset' onClick={ e => resetAllCal(1) }>
                                                <i className="fa fa-repeat" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                    </div>

                                    <div> 
                                        <div className='cal-s1-box'>
                                            <span className='ss-cal-text'>
                                                S-2: 
                                            </span>
                                            <span className='input-short'>
                                                {/* <input className='s-input' type='text' name='s2_pps' value={ s2PPSCount } onChange={e => ppsChange(e.target.value, 2)} maxLength={ 10 } /> */}
                                                <NumberFormat
                                                    value={ s2PPSCount }
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    className='s-input'
                                                    onChange={ (e) => {
                                                        let valC = e.target.value;
                                                        valC = valC.replace(/\,/g,'');
                                                        valC = valC.replace(/\$/g,'');    
                                                        ppsChange(valC, 2)}
                                                    } 
                                                />
                                            </span>
                                            <span className='icon-math'>
                                                <svg xmlns="http://www.w3.org/2000/svg"  fill="#000000" className="bi bi-x" viewBox="0 0 16 16">
                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                                </svg>
                                            </span>
                                            <span className='input-long'>
                                                {/* <input className='s-input' type='text' name='s2_shares' value={ s2SHARES } onChange={e => sharesChange(e.target.value, 2)} /> */}
                                                <NumberFormat
                                                    value={ s2SHARES }
                                                    thousandSeparator={true}
                                                    fixedDecimalScale={true}
                                                    placeholder=''
                                                    className='s-input'
                                                    onChange={ (e) => {
                                                        let valC = e.target.value;
                                                        valC = valC.replace(/\,/g,'');
                                                        valC = valC.replace(/\$/g,'');    
                                                        sharesChange(valC, 2) }
                                                    }
                                                />
                                            </span>
                                            <span className='icon-math'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" className="bi bi-pause" viewBox="0 0 16 16">
                                                    <path d="M6 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5zm4 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5z"/>
                                                </svg>
                                            </span>
                                            <span className='input-long'>
                                                {/* <input className='s-input' type='text' name='s2_shares' value={ s2Total } onChange={e => totalChange(e.target.value, 2)} /> */}
                                                <NumberFormat
                                                    value={ s2Total }
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    className='s-input'
                                                    onChange={ (e) => {
                                                        let valC = e.target.value;
                                                        valC = valC.replace(/\,/g,'');
                                                        valC = valC.replace(/\$/g,'');    
                                                        totalChange(valC, 2)}
                                                    } 
                                                />
                                            </span>
                                            <span className='icon-math'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" className="bi bi-dash" viewBox="0 0 16 16">
                                                    <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                                                </svg>
                                            </span>
                                            <span className='input-short'>
                                                {/* <input className='s-input' type='text' name='s2_shares' value={ s2MgFee } onChange={e => mgFeeChange(e.target.value, 2)} disabled /> */}
                                                <NumberFormat
                                                    value={ s2MgFee }
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    className='s-input'
                                                    disabled = 'disabled'
                                                    onChange={ (e) => {
                                                        let valC = e.target.value;
                                                        valC = valC.replace(/\,/g,'');
                                                        valC = valC.replace(/\$/g,'');    
                                                        mgFeeChange(valC, 2)}
                                                    } 
                                                />
                                            </span>
                                            <span className='icon-math'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" className="bi bi-pause" viewBox="0 0 16 16">
                                                    <path d="M6 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5zm4 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5z"/>
                                                </svg>
                                            </span>
                                            <span className='input-long'>
                                                {/* <input className='s-input' type='text' name='s2_shares' value={ net2Total } onChange={e => netTotalChange(e.target.value, 2)} /> */}
                                                <NumberFormat
                                                    value={ net2Total }
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    className='s-input'
                                                    onChange={ (e) => {
                                                        let valC = e.target.value;
                                                        valC = valC.replace(/\,/g,'');
                                                        valC = valC.replace(/\$/g,'');    
                                                        netTotalChange(valC, 2)}
                                                    } 
                                                />
                                            </span>
                                            {/* <span className='repeat-icon' title='Reset' onClick={ e => resetAllCal(2) } >
                                                <i class="fa fa-repeat" aria-hidden="true"></i>
                                            </span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-md-2'>
                                    <div className='full-box'>
                                        <span className='full-box-head'>Gross In Process<span className='i-icon tooltips'>i <span className='tooltiptext'>Total Amount Committed but not yet Funded </span> </span></span> 
                                        <span className='full-box-mid'>${ totalDocSignAmt }</span>
                                        
                                    </div>  
                                </div>
                                <div className='col-md-2'>
                                    <div className='full-box'>
                                        <span className='full-box-head'>Net In Process<span className='i-icon tooltips'>i <span className='tooltiptext'>Total Amount Committed but not yet Funded minus Fees</span></span></span> 
                                        <span className='full-box-mid'>${ totalNetDocSignAmt }</span>
                                        <span className='full-box-foot'>{ Number(totalDocSignShares).toLocaleString(undefined, {maximumFractionDigits:2} ) } Shares</span>
                                    </div>  
                                </div>
                                <div className='col-md-2'>
                                    <div className='full-box'>
                                        <span className='full-box-head'>Gross Funded <span className='i-icon tooltips'>i <span className='tooltiptext'>Final Allotment currently Funded</span> </span></span> 
                                        <span className='full-box-mid'>${ Number(totalInvestmentAmt).toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2}) }</span>
                                        {/* <span className='full-box-foot'>900,000 Shares</span> */}
                                    </div>  
                                </div>
                                <div className='col-md-2'>
                                    <div className='full-box'>
                                        <span className='full-box-head'>Net Funded <span className='i-icon tooltips'>i <span className='tooltiptext'>Gross Funded - Fees (Should equal the Total Allocation)</span></span></span> 
                                        <span className='full-box-mid'>${ totalNetOfFunded }</span>
                                        <span className='full-box-foot'>{ Number(totalUserShares).toLocaleString(undefined, {maximumFractionDigits:2}) } Shares</span>
                                    </div>  
                                </div>
                                <div className='col-md-2'>
                                    <div className='full-box'>
                                        <span className='full-box-head'>Gross Remaining <span className='i-icon tooltips'>i <span className='tooltiptext'>Total Allocation / .95 - Gross Funded (Should be a higher number than the allocation)</span> </span></span> 
                                        <span className='full-box-mid'>${ totalGrossRemaining }</span>
                                        {/* <span className='full-box-foot'>900,000 Shares</span> */}
                                    </div>  
                                </div>
                                <div className='col-md-2'>
                                    <div className='full-box'>
                                        <span className='full-box-head'>Net Remaining <span className='i-icon tooltips'>i <span className='tooltiptextright'>Allocation - Net Funded</span></span></span> 
                                        <span className='full-box-mid'>${ totalNetRemaining }</span>
                                        <span className='full-box-foot'>{ totalNetUserShares } Shares</span>
                                    </div>  
                                </div>
                            </div>
                        </div>        
                    </div>
                </div>
            }
        </>
    );
}

//export default SummaryStartupsList;
const mapStateToProps = state => {
    return {
        loadingSpinner: state.loadingSpinner,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        isLoading: bindActionCreators(isLoading, dispatch),
        isNotLoading: bindActionCreators(isNotLoading, dispatch),
        setSummaryStartupsData: bindActionCreators(setSummaryStartupsData, dispatch),
    };
};

export default SummaryStartupsList;
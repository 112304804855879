import React, { Component } from 'react';
import images from "../../images";
import './InvestorCard.scss';
import _ from 'lodash';
import TButton from '../TButton';
import common from '../../common';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import '../../../node_modules/react-accessible-accordion/dist/fancy-example.css';
import NumberFormat from 'react-number-format';

class InvestorCard extends Component {
    constructor(props) {
        super(props);
        this.companyId = '';
        this.state = {
            iirList: [],
            cardDetails: [],
            count: '',
            checklistData: '',
            checklistCount: '',
            currentUserId: null,
        }
    }

    mapDateFormat = mongoDate => {
        if (!mongoDate) return null;

        var monthNames = [
            "01", "02", "03",
            "04", "05", "06", "07",
            "08", "09", "10",
            "11", "12"
        ];
        let date = new Date(mongoDate);
        let day = date.getDate();
        let monthIndex = date.getMonth();
        let year = date.getFullYear();

        return monthNames[monthIndex] + '/' + day + '/ ' + year;

    };

    componentWillMount() {
        const url = new URL(window.location.href);
        this.companyId = url.searchParams.get('companyId');
        let id = window.localStorage.getItem('id');
        this.setState({ currentUserId: id })
    }

    mapCompanyName(data) {
        const company = this.props.companyData && this.props.companyData.filter(company => company._id === data.companyId);
        if (company && company.length > 0) {
            return `${company[0].companyName} `;
        }
        return '';
    }

    mapCompanyLogo(data) {
        const company = this.props.companyData && this.props.companyData.filter(company => company._id === data.companyId);
        if (company && company.length > 0) {
            return `${company[0].companyLogo} `;
        }
        return '';
    }

    mapInvestmentAmount(data) {
        const url = new URL(window.location.href);
        const investorId = url.searchParams.get('investorId');
        const id = window.localStorage.getItem('id');
        if (investorId) {
            const amount = data.userInvestments.filter(amt => amt.investorId === investorId);
            if (amount && amount.length > 0) {
                return `${amount[0].amount} `;
            }
        } else {
            const amount = data.userInvestments.filter(amt => amt.investorId === id);
            if (amount && amount.length > 0) {
                return `${amount[0].amount}`;
            }
        }
        return '';
    }

    render() {
        const id = window.localStorage.getItem('id');
        const roleCode = window.localStorage.getItem('roleCode');
        return (
            <div className="investor-card">
                <div className="card-header-section custom-progressbar-head">
                    <div className="card-header-txt">
                        <div className="companyLogo">
                            <img src={`${common.path.base_url}/getInvestmentCompanyLogo?companyId=${this.props.investmentData.companyId._id}`} className='logo logo-preview'></img>

                        </div>
                    </div>
                    <div className="text-comapny-name">
                        {/* {this.mapCompanyName(this.props.investmentData)} */}
                        {this.props.investmentData.companyId.companyName}
                        <div className="text-investname">
                        {this.props.investmentData.name}
                        </div>
                    </div>

                    {/* <div className="card-header-txt">
                        <img src={`${common.path.base_url}/getFileNew?fileId=${this.mapCompanyLogo(this.props.investmentData)}`} className='logo logo-preview'></img>
                            {this.mapCompanyName(this.props.investmentData)}
                            <div className="text-investname">
                                {this.props.investmentData.name}
                            </div>                       
                    </div> */}

                    <div className="card-menu">
                        {
                            this.props.investmentData.stage !== 'closed' ?
                            <span className="icon-circle-open">Complete</span> :
                            <span className="icon-circle">Closed</span>
                        }
                        {/* {this.props.investmentData.stage === 'invited' || this.props.investmentData.stage === 'invite' || this.props.investmentData.stage === 'closed' ?
                            <span className="icon-circle">{this.props.investmentData.stage === 'invite' ? 'Invited' : this.props.investmentData.stage}</span> :
                            this.props.investmentData.stage !== 'closed' ?
                                <span className="icon-circle-open">Complete</span> :
                                this.props.investmentData.stage === 'exited' ?
                                    <span className="icon-circle-exit">{this.props.investmentData.stage}</span> : <span></span>} */}
                    </div>
                </div>

                <div className="card-count-section">
                    <div className="count-block">
                        <div className="count-value">
                            {this.mapInvestmentAmount(this.props.investmentData) ?
                            <NumberFormat   
                                fixedDecimalScale={true}
                                value={`${this.mapInvestmentAmount(this.props.investmentData)}`} 
                                displayType={'text'}
                                thousandSeparator={true} 
                                prefix={'$'} 
                                decimalScale={2}
                            /> 
                            : 
                            'N/A'
                        }
                        </div>
                        <div className="count-txt">
                            {`My Investment`}
                            {
                                roleCode && (roleCode === '001' || roleCode === '002') &&
                                <i onClick={() => this.props.onUpdateInvestmentClick() && this.props.onUpdateInvestmentClick()}
                                    className="fa fa-pencil add-address"></i>
                            }
                        </div>
                    </div>
                    <div className="date-block">
                        <div className="date-value">{this.mapDateFormat(this.props.investmentData.closedDate)}</div>
                        <div className="date-txt">{`Date Investment Closed`}</div>
                    </div>
                </div>

                <div className="share-section">
                    <div className="data-block">
                        {/* <div className="data-value">${this.props.investmentData.pricePerShare}</div> */}
                        <div className="data-value">
                            <NumberFormat
                                fixedDecimalScale={true}
                                value={this.props.investmentData.pricePerShare} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                            </div>
                        <div className="data-txt">{`Price Per Share`}</div>
                    </div>
                </div>

                <div className="value-section custom-progressbar-iir">
                    <div className="value-block">
                        {/* <div className="value">${this.props.investmentData.companyValuation}</div> */}
                        <div className="value">
                        <NumberFormat
                                fixedDecimalScale={true}
                                decimalScale={2}
                                value={this.props.investmentData.companyValuation} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </div>
                        
                        <div className="value-txt">{`Company Valuation`}</div>
                    </div>
                </div>

                <div className="card-document-section custom-progressbar">
                    <div className="document-block">
                        <div className="document-txt">{`Documents`}</div>
                        {roleCode && (roleCode === '001' || roleCode === '002') &&
                            <div className="upload-btn">
                                <TButton
                                    id='edit-btn'
                                    onClick={() => this.props.uploadClickHnadler() && this.props.uploadClickHnadler()}
                                    text={`UPLOAD`}
                                    tsgBtnClass="tsg-btn"
                                />
                            </div>
                        }
                    </div>
                </div>

                <div className="accordion-section">
                    <Accordion allowZeroExpanded="true">
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <img src={images.path.folder} alt="folder"></img>
                                    Tax Documents
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                {this.props.documents.taxDocument ? this.props.documents.taxDocument.map((file, i) => {
                                    if (file.investmentId === this.props.investmentData._id) {
                                        return (<div className="accordion-section-inner">
                                            <div className="accordion-section-inner-file">
                                                <div className="accordion-left">
                                                    <div className="file-name">
                                                        <a className="file-link" target="_blank" href={`${common.path.doc_download_url}/downloadInvestmentDoc?fileId=${file._id}&id=${id}`} download onClick={this.props.downloadClickHnadler}>{file.name.split('.')[0]}</a>
                                                    </div>
                                                    <div className="date">
                                                        {this.mapDateFormat(file.createdAt)} | {file.name.split('.').pop().toUpperCase()}
                                                    </div>
                                                </div>
                                                <div className="accordion-right">
                                                    {file.createdBy === this.state.currentUserId &&
                                                        <img className="delete-icon" src={images.path.deleteIcon} onClick={() => this.props.deleteInvestmentDoc && this.props.deleteInvestmentDoc(file)} />
                                                    }
                                                    {
                                                        file && file.isDownloaded ?
                                                            <img className="mailopen-icon" src={images.path.mailopen} alt="open"></img> :
                                                            <img className="mail-icon" src={images.path.mail} alt="close"></img>
                                                    }
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                }) : <div></div>}
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <img src={images.path.folder}></img>
                                    Investment Documents
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                {(this.props.documents.investmentDocument) ? this.props.documents.investmentDocument.map((file, i) => {
                                    if (file.investmentId === this.props.investmentData._id) {
                                        return (<div className="accordion-section-inner">
                                            <div className="accordion-section-inner-file">
                                                <div className="accordion-left">
                                                    <div className="file-name">
                                                        {/* {file.name.split('.')[0]} */}
                                                        <a className="file-link" target="_blank" href={`${common.path.doc_download_url}/downloadInvestmentDoc?fileId=${file._id}&id=${id}`} download onClick={this.props.downloadClickHnadler}>{file.name.split('.')[0]}</a>
                                                    </div>
                                                    <div className="date">
                                                        {this.mapDateFormat(file.createdAt)} | {file.name.split('.').pop().toUpperCase()}
                                                    </div>
                                                </div>
                                                <div className="accordion-right">
                                                    {file.createdBy === this.state.currentUserId &&
                                                        <img className="delete-icon" src={images.path.deleteIcon} onClick={() => this.props.deleteInvestmentDoc && this.props.deleteInvestmentDoc(file)} />
                                                    }
                                                    {file && file.isDownloaded ?
                                                        <img className="mailopen-icon" src={images.path.mailopen} alt="open"></img> :
                                                        <img className="mail-icon" src={images.path.mail} alt="close" ></img>
                                                    }
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                }) : <div></div>}
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <img src={images.path.folder} alt="folder"></img>
                                    Other Documents
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                {this.props.documents.otherDocument ? this.props.documents.otherDocument.map((file, i) => {
                                    if (file.investmentId === this.props.investmentData._id) {
                                        return (<div className="accordion-section-inner">
                                            <div className="accordion-section-inner-file">
                                                <div className="accordion-left">
                                                    <div className="file-name">
                                                        <a className="file-link" target="_blank" href={`${common.path.doc_download_url}/downloadInvestmentDoc?fileId=${file._id}&id=${id}`} download onClick={this.props.downloadClickHnadler}>{file.name.split('.')[0]}</a>
                                                    </div>
                                                    <div className="date">
                                                        {this.mapDateFormat(file.createdAt)} | {file.name.split('.').pop().toUpperCase()}
                                                    </div>
                                                </div>
                                                <div className="accordion-right">
                                                    {file.createdBy === this.state.currentUserId &&
                                                        <img className="delete-icon" src={images.path.deleteIcon} onClick={() => this.props.deleteInvestmentDoc && this.props.deleteInvestmentDoc(file)} />
                                                    }
                                                    {
                                                        file && file.isDownloaded ?
                                                            <img className="mailopen-icon" src={images.path.mailopen} alt="open"></img> :
                                                            <img className="mail-icon" src={images.path.mail} alt="close"></img>
                                                    }
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                }) : <div></div>}
                            </AccordionItemPanel>
                        </AccordionItem>
                        {roleCode == '005' ? <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <div className="folder-wraper">
                                        <div className="folder-name">
                                            <img src={images.path.folder}></img>
                                            My Documents
                                        </div>
                                        <div className="upload-btn">
                                            <TButton
                                                id='edit-btn'
                                                onClick={() => this.props.uploadClickHnadler() && this.props.uploadClickHnadler()}
                                                text={`UPLOAD`}
                                                tsgBtnClass="tsg-btn"
                                            />
                                        </div>
                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                {this.props.documents.myDocument ? this.props.documents.myDocument.map((file, i) => {
                                    if (file.investmentId === this.props.investmentData._id) {
                                        return (<div className="accordion-section-inner">
                                            <div className="accordion-section-inner-file">
                                                <div className="accordion-left">
                                                    <div className="file-name">
                                                        {/* {file.name.split('.')} */}
                                                        <a className="file-link" target="_blank" href={`${common.path.doc_download_url}/downloadInvestmentDoc?fileId=${file._id}&id=${id}`} download onClick={this.props.downloadClickHnadler}>{file.name.split('.')[0]}</a>
                                                    </div>
                                                    <div className="date">
                                                        {this.mapDateFormat(file.createdAt)} | {file.name.split('.').pop().toUpperCase()}
                                                    </div>
                                                </div>
                                                <div className="accordion-right">
                                                    {file.createdBy === this.state.currentUserId &&
                                                        <img className="delete-icon" src={images.path.deleteIcon} onClick={() => this.props.deleteInvestmentDoc && this.props.deleteInvestmentDoc(file)} />
                                                    }
                                                    {/* {
                                                        file && file.isDownloaded ?
                                                            <img className="mailopen-icon" src={images.path.mailopen} alt="open"></img> :
                                                            <img className="mail-icon" src={images.path.mail} alt="close" ></img>
                                                    } */}
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                }) : <div></div>}
                            </AccordionItemPanel>
                        </AccordionItem> : null}
                    </Accordion>
                </div>
            </div>
        );
    }
}

export default InvestorCard;
import React, { Component } from 'react';
import './TempleteEmail.scss';
import axios from 'axios';
import swal from 'sweetalert';
import common from '../../../../common';
import { withRouter } from 'react-router-dom';
import ListTempleteEmail from './ListTempleteEmail';
import EditTemplateEmail from './EditTemplateEmail';
import ListTempleteSms from './ListTempleteSms';
import EditTemplateSms from './EditTemplateSms';

class TempleteEmail extends Component {
    constructor(props) {
        super(props);
        //console.log(props);
        this.state = {
            allTemplateEmail: null,
            allTemplateSMS: null,
            loading: null,
            listviewEmail: true,
            listviewSms: true,
            editTemplateData: null,
            updateComp: true
        }
    }

    async componentDidMount() {
        await this.getTempleteList();
        await this.getSMSTempleteList();
    }

    getTempleteList() {
        let temp = this;
        //temp.setState({ loading: 'email' });
        let investmentId = (this.props.investmentId) ? this.props.investmentId : "";
        let tokenpass = window.localStorage.getItem('token');

        axios.get(`${common.path.base_url}/getAllEmailTemplate`, { 
            params: { 'investmentId': investmentId },
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                //console.warn(response.data.data); 
                // let allTemp = response.data.data;

                // allTemp.map((data, index) => { 
                    // if ((data.investments) && typeof(data.investments) !== 'undefined' && data.investments != null) {
                    //         data.html = data.investments.html;
                    //         data.subject = data.investments.subject;
                    // }
                // })
                
                temp.setState({ allTemplateEmail: response.data.data });
            })
            .catch(function (error) {
                    if (common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response && error.response.data && error.response.data.code && error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loading: false });
            });
    };

    getSMSTempleteList() {
        let temp = this;
        //temp.setState({ loading: 'sms' });
        let investmentId = (this.props.investmentId) ? this.props.investmentId : "";
        let tokenpass = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/getAllSMSTemplate`, {
            params: { 'investmentId': investmentId },
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                
                let allTemp = response.data.data;

                {allTemp.map((data, index) => { 
                    //console.log(data);
                    if ((data.investments) && typeof(data.investments[0]) !== 'undefined' && data.investments[0] != null) {
                        var investTemp = data.investments[0].find((ele) => { 
                                return ele.code === data.code; 
                            });
                        if (typeof(investTemp) !== 'undefined' && investTemp != null) {
                            data.message = investTemp.message;
                        }
                    }
                })}
                
                temp.setState({ allTemplateSMS: response.data.data });
                //console.warn(this.state);  
            })
            .catch(function (error) {
                if (common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response && error.response.data && error.response.data.code && error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loading: false });
            });
    };

    editTemplate(tempData, type) {
        console.warn(type);
        console.warn(tempData);
        let temp = this;
        if(type == 'email') { 
            temp.setState({ editTemplateData: tempData, listviewEmail: false, listviewSms: true });
        } else {
            temp.setState({ editTemplateData: tempData, listviewSms: false, listviewEmail: true });
        }
    };

    backListPage(type) {
        let temp = this;
        if(type == 'email')
        temp.setState({ listviewEmail: true });
        else 
        temp.setState({ listviewSms: true });
    }

    render() {

        return (

            <div className="checklist-container container-fluid">
                {   
                    
                    <>
                    {   
                        this.props.getTempSubMenu[0].isActive &&
                        <>
                        {
                            this.state.listviewEmail &&
                            <ListTempleteEmail
                                getTempleteList={this.state.allTemplateEmail}
                                editTemplate={(tempData, type='email') => this.editTemplate(tempData, type='email')}
                            />
                        }
                        {
                            !this.state.listviewEmail &&
                            <EditTemplateEmail
                                backListPage={() => this.backListPage('email')}
                                editTemplateData = { this.state.editTemplateData }
                                investmentId = { (this.props.investmentId) ? this.props.investmentId : ""  }
                            />
                        }
                        </>
                    }
                    
                    {
                        this.props.getTempSubMenu[1].isActive &&    
                        <>
                        {
                            this.state.listviewSms &&
                            <ListTempleteSms
                                getSMSTempleteList={this.state.allTemplateSMS}
                                editTemplate={(tempData, type='sms') => this.editTemplate(tempData, type='sms')}
                            />
                        }
                        {
                            !this.state.listviewSms &&
                            <EditTemplateSms
                                backListPage={() => this.backListPage('sms')}
                                editTemplateData = { this.state.editTemplateData }
                                investmentId = { (this.props.investmentId) ? this.props.investmentId : "" }
                            />
                     
                        }
                        </>
                    }
                    </>
                }

            </div>
        );
    }
}
export default withRouter(TempleteEmail);
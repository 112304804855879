import axios from 'axios';
import React, { Component } from 'react';
import LaddaButton, { EXPAND_RIGHT, XS } from 'react-ladda';
import ReactPhoneInput from 'react-phone-input-2';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import common from '../../common';
import LoadingSpinner from '../../components/Loader';
import RequiredSign from '../../components/RequiredSign';
import './FundingInstructionsSettings.scss';

class FundingInstructions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bankName: '',
            address: '',
            accountName: '',
            accountNumber: '',
            routingNumber: '',
            tsgAddress: '',
            organizationName: "",
            companyName: "",
            taxId: "",
            emailAddress: "",
            address1: "",
            address2: "",
            city: "",
            postalCode: "",
            phoneNumber: "",
            country: "",
            stateProvince: "",
            businessType: "LLC",
            verivendBankDetals: "",
            organizationDetails: null,
            disabled: false,
            loading: false,
        }
        this.investmentId = null;
    }

    componentWillMount() {
        this.setState({ loading: false });
        if (this.props.location
            && this.props.match
            && this.props.match.params
            && this.props.match.params.id) {
            this.investmentId = this.props.match.params.id;
        }
        if (this.investmentId) {
            this.getInvestmentSettings()
        } else {
            this.getInvestmentGlobalSettings()
        }

    }
    getInvestmentGlobalSettings() {
        const temp = this;
        temp.setState({ loading: true });
        let token = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/settings`, {
            headers: {
                "Authorization": token
            }
        })
            .then(function (response) {
                temp.setState({ loading: false });
                if (response && response.data && response.data.data && response.data.data.bankDetail) {
                    temp.setState({ ...response.data.data.bankDetail });
                }
            })
            .catch(function (error) {
                console.log(error);
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    };
    getInvestmentSettings() {
        let temp = this;
        temp.setState({ loading: true });
        let token = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/investmentFollowUpSettings/${this.investmentId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then(function (response) {
                axios.get(`${common.path.base_url}/settings`, {
                    headers: {
                        "Authorization": token
                    }
                })
                    .then(function (res) {
                        let bankDetail = null;
                        let verivendBankDetals = null;
                        let globalBankDetail = null;
                        temp.setState({ loading: false });
                        if (response.data.data && response.data.data.name) {
                            temp.setState({ organizationName: response.data.data.name });
                        }
                        if (response && response.data && response.data.data && response.data.data.bankDetail) {
                            bankDetail = response.data.data.bankDetail;
                        }
                        if (response.data.data.verivendId) {
                            temp.getOrganizationDetails(response.data.data.verivendId);
                        }
                        if (response && response.data && response.data.data && response.data.data.verivendBankData) {
                            verivendBankDetals = response.data.data.verivendBankData;
                        }
                        if (res && res.data && res.data.data && res.data.data.bankDetail) {
                            globalBankDetail = res.data.data.bankDetail;
                        }

                        const bankName = (bankDetail && bankDetail.bankName) || (globalBankDetail && globalBankDetail.bankName) || '';
                        const address = (bankDetail && bankDetail.address) || (globalBankDetail && globalBankDetail.address) || '';
                        const accountName = (bankDetail && bankDetail.accountName) || '';
                        const accountNumber = (bankDetail && bankDetail.accountNumber) || '';
                        const routingNumber = (bankDetail && bankDetail.routingNumber) || (globalBankDetail && globalBankDetail.routingNumber) || '';
                        const tsgAddress = (bankDetail && bankDetail.tsgAddress) || (globalBankDetail && globalBankDetail.tsgAddress) || '';
                        temp.setState({
                            bankName,
                            address,
                            accountName,
                            accountNumber,
                            routingNumber,
                            tsgAddress
                        });
                        if (verivendBankDetals != null) {
                            temp.setState({ disabled: true });
                            const taxId = (verivendBankDetals && verivendBankDetals.taxId) || '';
                            const address1 = (verivendBankDetals && verivendBankDetals.address1) || '';
                            const emailAddress = (verivendBankDetals && verivendBankDetals.emailAddress) || '';
                            const phoneNumber = (verivendBankDetals && verivendBankDetals.phoneNumber) || '';
                            const address2 = (verivendBankDetals && verivendBankDetals.address2) || '';
                            const city = (verivendBankDetals && verivendBankDetals.city) || '';
                            const postalCode = (verivendBankDetals && verivendBankDetals.postalCode) || '';
                            const country = (verivendBankDetals && verivendBankDetals.country) || '';
                            const stateProvince = (verivendBankDetals && verivendBankDetals.stateProvince) || '';
                            temp.setState({
                                taxId,
                                address1,
                                emailAddress,
                                phoneNumber,
                                address2,
                                city,
                                postalCode,
                                country,
                                stateProvince,
                            });
                        }

                    })
                    .catch(function (error) {
                        console.log(error);
                        temp.setState({ loading: false });
                        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                            window.localStorage.clear();
                            window.location.href = '/';
                            return;
                        } else {
                            swal({
                                title: "Network Error!",
                                text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                                icon: "error",
                                button: "Ok!",
                            });
                        }
                    });
            })
            .catch(function (error) {
                console.log(error);
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    };
    save() {
        let temp = this;
        temp.setState({ loading: true });
        let data = {
            bankName: this.state.bankName,
            address: this.state.address,
            accountName: this.state.accountName,
            accountNumber: this.state.accountNumber,
            routingNumber: this.state.routingNumber,
            tsgAddress: this.state.tsgAddress,
        };
        let url = `${common.path.base_url}/saveFundingInstructions`;
        if (this.investmentId) {
            data = {
                ...this.state,
                phoneNumber: this.state.phoneNumber.replace("+", ""),
            };
            url = `${common.path.base_url}/saveInvestmentFundingSettings/${this.investmentId}`;
        }
        axios.post(`${url}`, data, {
            headers: { Authorization: window.localStorage.getItem('token') },
        })
            .then((response) => {
                temp.setState({ loading: false });
                swal({
                    title: "Success!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(() => {

                    });
            })
            .catch((error) => {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }
    getOrganizationDetails(verivendId) {
        let temp = this;
        temp.setState({ loading: true })
        const token = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/getOrganizationDetails/${verivendId}`, {
            headers: {
                Authorization: token,
            }
        }).then((response) => {
            temp.setState({ loading: false })
            if (response.data.data) {
                temp.setState({ organizationDetails: response.data.data });
            }
        }).catch((error) => {
            temp.setState({ loading: false });
            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                window.localStorage.clear();
                window.location.href = '/';
                return;
            }
            else {
                swal({
                    title: "Error!",
                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                    icon: "error",
                    button: "Ok!",
                });
            }
        });

    }
    createVerifyWalletHandler() {
        let temp = this;
        const token = window.localStorage.getItem('token');
        swal({
            title: "Are you sure with the filled Details?",
            text: "You won't be able to change the details of this account",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((sure) => {
            if (sure) {
                temp.setState({ loading: true });
                const data = {
                    ...this.state,
                    investmentId: this.investmentId,
                    phoneNumber: this.state.phoneNumber.replace("+", ""),
                };
                axios.post(`${common.path.base_url}/createVerifiedOrganization`, data, {
                    headers: {
                        Authorization: token,
                    }
                }).then((response) => {
                    temp.setState({ loading: false });
                    if (response.data.data) {
                        temp.setState({ disabled: true });
                        temp.getOrganizationDetails(response.data.data.verivendId);
                        this.save();
                    }
                }).catch((error) => {
                    temp.setState({ loading: false });
                    if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        window.localStorage.clear();
                        window.location.href = '/';
                        return;
                    }
                    else {
                        swal({
                            title: "Error!",
                            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                });
            }
        });
    }
    isValidate() {
        if (this.investmentId) {
            if (!this.state.phoneNumber || !this.state.address1 || !this.state.taxId || !this.state.emailAddress) {
                return false;
            }
        } else {
            if (!this.state.bankName || !this.state.address || !this.state.routingNumber || !this.state.tsgAddress) {
                return false;
            }
        }
        return true;
    }
    onChange(id, value) {
        this.setState({ [id]: value })
    }

    render() {
        return (
            <div className="checklist-container container-fluid">
                {this.state.loading ? <LoadingSpinner /> : ""}
                <div className="new-investment">
                    <h2>Funding Instructions</h2>
                </div>
                <div className="invitations_right invitations_right_fund recommend-startup">
                    <div className="col-lg-6 col-md-6">
                        <div className="form-horizontal recommand_form">
                            {
                                !this.investmentId &&
                                <>
                                    <div className="row">
                                        <div className="col-sm-7 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="name">Bank Name<RequiredSign /> :</label>
                                                <div className='recommendation-input'>
                                                    <input type="text"
                                                        value={this.state.bankName}
                                                        onChange={(event) => this.onChange(event.target.id, event.target.value)}
                                                        name="bankName"
                                                        className="form-control"
                                                        id="bankName"
                                                        placeholder=""
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-7 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="name">Address<RequiredSign /> :</label>
                                                <div className='recommendation-input'>
                                                    <input type="text"
                                                        value={this.state.address}
                                                        onChange={(event) => this.onChange(event.target.id, event.target.value)}
                                                        name="address"
                                                        className="form-control"
                                                        id="address"
                                                        placeholder=""
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-7 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="name">Routing Number<RequiredSign /> :</label>
                                                <div className='recommendation-input'>
                                                    <input type="text"
                                                        value={this.state.routingNumber}
                                                        onChange={(event) => this.onChange(event.target.id, event.target.value)}
                                                        name="routingNumber"
                                                        className="form-control"
                                                        id="routingNumber"
                                                        placeholder=""
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-7 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="name">TSG Address<RequiredSign /> :</label>
                                                <div className='recommendation-input'>
                                                    <input type="text"
                                                        value={this.state.tsgAddress}
                                                        onChange={(event) => this.onChange(event.target.id, event.target.value)}
                                                        name="tsgAddress"
                                                        className="form-control"
                                                        id="tsgAddress"
                                                        placeholder=""
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                this.investmentId &&
                                <>
                                    <div className="row">
                                        <div className="col-sm-7 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="name">Tax Id <RequiredSign /> : </label>
                                                <div className='recommendation-input'>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="taxId"
                                                        name="taxId"
                                                        value={this.state.taxId}
                                                        disabled={this.state.disabled}
                                                        onChange={(event) => this.onChange(event.target.id, event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-7 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="name">Email Address<RequiredSign /> : </label>
                                                <div className='recommendation-input'>
                                                    <input type="text"
                                                        value={this.state.emailAddress}
                                                        disabled={this.state.disabled}
                                                        onChange={(event) => this.onChange(event.target.id, event.target.value)}
                                                        name="emailAddress"
                                                        className="form-control"
                                                        id="emailAddress"
                                                        placeholder=""
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-7 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="name">Mobile Number<RequiredSign /> :</label>
                                                <div className='recommendation-input'>
                                                    <ReactPhoneInput
                                                        country={'us'}
                                                        id="phoneNumber"
                                                        onChange={(event) => this.onChange("phoneNumber", `+${event}`)}
                                                        placeholder=''
                                                        disableAreaCodes={true}
                                                        countryCodeEditable={false}
                                                        autoComplete="none"
                                                        value={this.state.phoneNumber}
                                                        disabled={this.state.disabled} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-7 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="name">Address 1<RequiredSign /> :</label>
                                                <div className='recommendation-input'>
                                                    <input type="text"
                                                        value={this.state.address1}
                                                        disabled={this.state.disabled}
                                                        onChange={(event) => this.onChange(event.target.id, event.target.value)}
                                                        name="address1"
                                                        className="form-control"
                                                        id="address1"
                                                        placeholder=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-7 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="name">Address 2 :</label>
                                                <div className='recommendation-input'>
                                                    <input type="text"
                                                        value={this.state.address2}
                                                        disabled={this.state.disabled}
                                                        onChange={(event) => this.onChange(event.target.id, event.target.value)}
                                                        name="address2"
                                                        className="form-control"
                                                        id="address2"
                                                        placeholder=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-7 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="name">City <RequiredSign /> :</label>
                                                <div className='recommendation-input'>
                                                    <input type="text"
                                                        value={this.state.city}
                                                        disabled={this.state.disabled}
                                                        onChange={(event) => this.onChange(event.target.id, event.target.value)}
                                                        name="city"
                                                        className="form-control"
                                                        id="city"
                                                        placeholder=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-7 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="name">State Or Province <RequiredSign /> :</label>
                                                <div className='recommendation-input'>
                                                    <input type="text"
                                                        value={this.state.stateProvince}
                                                        disabled={this.state.disabled}
                                                        onChange={(event) => this.onChange(event.target.id, event.target.value)}
                                                        name="stateProvince"
                                                        className="form-control"
                                                        id="stateProvince"
                                                        placeholder=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-7 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="name">Postal Code <RequiredSign /> :</label>
                                                <div className='recommendation-input'>
                                                    <input type="text"
                                                        value={this.state.postalCode}
                                                        disabled={this.state.disabled}
                                                        onChange={(event) => this.onChange(event.target.id, event.target.value)}
                                                        name="postalCode"
                                                        className="form-control"
                                                        id="postalCode"
                                                        placeholder=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-7 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="name">Country <RequiredSign /> :</label>
                                                <div className='recommendation-input'>
                                                    <input type="text"
                                                        value={this.state.country}
                                                        disabled={this.state.disabled}
                                                        onChange={(event) => this.onChange(event.target.id, event.target.value)}
                                                        name="country"
                                                        className="form-control"
                                                        id="country"
                                                        placeholder=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            <div className="row">
                                <div className="col-sm-7 col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="name"></label>
                                        <div className='fundding-instructions'>
                                            <LaddaButton
                                                id='lada-btn'
                                                loading={this.state.loadinglada}
                                                onClick={() => this.investmentId ? this.createVerifyWalletHandler() : this.save()}
                                                data-color="#eee"
                                                data-size={XS}
                                                className={!this.isValidate() ? 'disable' : this.state.disabled ? 'disable' : ""}
                                                data-style={EXPAND_RIGHT}
                                                data-spinner-size={30}
                                                data-spinner-color="#ddd"
                                                data-spinner-lines={12}
                                            >
                                                SAVE
                                            </LaddaButton>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.organizationDetails &&
                        <div className="col-lg-6 col-md-6" style={{ paddingTop: "20px" }}>
                            <div className="row">
                                <div className="col-sm-7 col-md-12">
                                    <div className="flex-column">
                                        <h4 className="header-txt">Organization Name</h4>
                                        <h5 className="header-txt" style={{ paddingTop: 5, fontSize: "16px", fontWeight: 400, }}>{this.state.organizationDetails.name}</h5>
                                    </div>
                                    <div className="flex-column">
                                        <h4 className="header-txt">Address</h4>
                                        <h5 className="header-txt" style={{ paddingTop: 5, fontSize: "16px", fontWeight: 400, }}>{this.state.organizationDetails.addresses[0].address1}</h5>
                                    </div>
                                    <div className="flex-column">
                                        <h4 className="header-txt">Address2</h4>
                                        <h5 className="header-txt" style={{ paddingTop: 5, fontSize: "16px", fontWeight: 400, }}>{this.state.organizationDetails.addresses[0].address2}</h5>
                                    </div>
                                    <div className="flex-column">
                                        <h4 className="header-txt">City</h4>
                                        <h5 className="header-txt" style={{ paddingTop: 5, fontSize: "16px", fontWeight: 400, }}>{this.state.organizationDetails.addresses[0].city}</h5>
                                    </div>
                                    <div className="flex-column">
                                        <h4 className="header-txt">Postal Code</h4>
                                        <h5 className="header-txt" style={{ paddingTop: 5, fontSize: "16px", fontWeight: 400, }}>{this.state.organizationDetails.addresses[0].postalCode}</h5>
                                    </div>
                                    <div className="flex-column">
                                        <h4 className="header-txt">Country</h4>
                                        <h5 className="header-txt" style={{ paddingTop: 5, fontSize: "16px", fontWeight: 400, }}>{this.state.organizationDetails.addresses[0].country}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div >
        );
    }
}
export default withRouter(FundingInstructions);
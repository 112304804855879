const initialState = {
  data: null
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SUMMARY_STARTUPS_DATA':
      return { ...state, data: action.payload };
    case 'SET_SUMMARY_INVESTMENT_DATA':
      return { ...state, investment: action.payload };
    default:
      return state;
  }
}

export default reducer
import React from "react";
import TButton from "../../../components/TButton";
import InvestorDashboardDocuments from "../../shared/components/InvestorDashboardDocuments.component";
import { getCompanyLogoFromS3 } from "../../shared/helper";

const NotParticipatingCard = (props) => {
  const { invitation, callFrom, fromToDo } = props;
  return (
    <>
      <div
        className={fromToDo ? '' : "card"}
        key={invitation._id}
        id={`invitation_${invitation.investmentId}`}
      >
        <div className="row card-content">
          <div className="img-left">
            <img
              src={getCompanyLogoFromS3(invitation)}
              alt=""
            />
          </div>
          <div
            className="col-lg-9 col-md-9 col-sm-9 col-9"
            style={{ minHeight: "100px" }}
          >
            <div className="card-header">
              {invitation.investmentName}
            </div>

            <div>
              <div style={{ marginRight: "10px", marginTop: "5px" }}>
                {invitation.stage !== "closed" &&
                  callFrom == "NewInvitations" ? (
                  <>
                    {invitation.type !== "old" && (
                      <div className="row card-content">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <div>
                            Do you still want to participate?
                          </div>
                          <div className="btn-box" style={{ marginTop: "5px" }}>
                            <TButton onClick={() => { props.invitationAction(true, invitation._id, invitation.investmentId,) }} text={`Yes`} className='contact-btn' disabled={false} />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <p>This investment closed by TSG!</p>
                )}

              </div>
            </div>
          </div>
        </div>
        <div>&nbsp;</div>
        {callFrom === "NewInvitations" && <InvestorDashboardDocuments isDisable={invitation.stage === "closed" &&
          callFrom !== "NewInvitations"} {...props} />}
      </div>
    </>
  );
}




export default NotParticipatingCard;
import React, { useState, useEffect } from "react";

const IframePdfElement = (props) => {
  const { pdfUrl } = props;
  const [iframePdfHtml,setIframePdfHtml] = useState(false);

  useEffect(() => {
    let isMounted = true
    if(isMounted){
      setIframePdfHtml(true);
    }
    return () => { isMounted = false;
      setIframePdfHtml(false);
    }
  }, []);  

  return (
    <>
        {(iframePdfHtml)?<iframe src={`https://docs.google.com/gview?url=${pdfUrl}&embedded=true`} 
        width="100%" height="100%" frameBorder="0"></iframe>:""}
    </>
  );
};

export default IframePdfElement;

import axios from "axios";
import _ from "lodash";
import React, { Component } from "react";
import LaddaButton, { EXPAND_RIGHT, XS } from "react-ladda";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { withRouter } from "react-router-dom";
import swal from "sweetalert";
import common from "../../common";
import HeaderWL from "../../components/Header without login";
import HeaderImg from "../../components/HeaderImage";
import LoadingSpinner from "../../components/Loader";
import RequiredSign from "../../components/RequiredSign";
import TButton from "../../components/TButton";
import "./AcceptInvitation.scss";
import AcceptTermAndConditionModal from "./AcceptTermAndConditionModal/AcceptTermAndConditionModal";

class AcceptInvitation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      firstName: "",
      lastName: "",
      middleName: "",
      title: "",
      company: "",
      companyId: null,
      emailAddress: "",
      pass1: "",
      pass2: "",
      loading: false,
      loadinglada: false,
      inputFile: "",
      id: "",
      token: "",
      userToken: null,
      inviteRole: null,
      inviteType: null,
      invitationFrom: "",
      nameValidation: "",
      titleValidation: "",
      companyValidation: "",
      enterpassValidation: "",
      mobileValidation: "",
      officeValidation: "",
      emailAddressValidation: "",
      confirmpassValidation: "",
      invitationFromValidated: false,
      inviteRoleCode: "",
      titleVaild: false,
      isAccepted: false,
      lastNameValidation: "",
      firstNameValidation: "",
      firstNamePreferred: "",
    };
  }

  componentWillMount() {
    //     const url = new URL(window.location.href);
    //     const queryUserToken = url.searchParams.get('token');
    //     if (_.isEmpty(queryUserToken)) {
    //         this.props.history.push('/');
    //     }
    //     else {
    //         this.setState({ userToken: queryUserToken })
    //         this.getData(queryUserToken);
    //     }
  }

  componentDidMount() {
    // if (!this.state.isAccepted) {
    //         this.openTermsAndConditionModal();
    // } else {
    //         const url = new URL(window.location.href);
    //         const queryUserToken = url.searchParams.get('token');
    //         if (_.isEmpty(queryUserToken)) {
    //             this.props.history.push('/');
    //         }
    //         else {
    //             this.setState({ userToken: queryUserToken })
    //             this.getData(queryUserToken);
    //         }
    //     }
    const url = new URL(window.location.href);
    const queryUserToken = url.searchParams.get("token");
    if (_.isEmpty(queryUserToken)) {
      this.props.history.push("/");
    } else {
      this.setState({ userToken: queryUserToken });
      this.getData(queryUserToken);
    }
  }

  previewFile() {
    const preview = document.getElementById("preview");
    const file = document.querySelector("input[type=file]").files[0];
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        preview.src = reader.result;
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  }
  chooseFile() {
    document.getElementById("choose-file").click();
  }

  getData(token) {
    let temp = this;
    temp.setState({ loading: true });
    axios
      .get(`${common.path.base_url}/invitationGetByToken`, {
        params: {
          token: token,
        },
      })
      .then((response) => {
        temp.setState({ loading: false });
        // const splitName = response.data.data.name.split(' ');

        if (
          response.data &&
          response.data.data &&
          response.data.data.invitationAccepted === "1"
        ) {
          // swal({
          //     title: response.data.data && response.data.message,
          //     text: `Let's go to your Dashboard`,
          //     icon: "success",
          //     button: "Ok!",
          // })
          //     .then(function () {
          //         temp.props.history.push(`/?username=${response.data.data.emailAddress}`);
          //     });
          temp.props.history.push(
            `/?username=${response.data.data.emailAddress}`
          );
        } else {
          temp.setState({ loading: false });
          temp.setState({
            // name: response.data.data.name,
            firstName: response.data.data.firstName,
            lastName: response.data.data.lastName,
            middleName: response.data.data.middleName,
            title: response.data.data.title,
            company: response.data.data.companyId
              ? response.data.data.companyId.companyName
              : response.data.data.investorCompanyName
                ? response.data.data.investorCompanyName
                : "",
            companyId: response.data.data.companyId
              ? response.data.data.companyId._id
              : "",
            emailAddress: response.data.data.emailAddress,
            mobile: response.data.data.mobile,
            office: response.data.data.office,
            invitationFrom: response.data.data.invitationFrom,
            id: response.data.data._id,
            inviteRole: response.data.data.roleName,
            inviteRoleCode: response.data.data.role,
            invitationAccepted: response.data.data.invitationAccepted,
          });
          if (!temp.state.isAccepted) {
            temp.openTermsAndConditionModal();
          }
        }
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.code &&
          error.response.data.code === 204
        ) {
          window.localStorage.clear();
          window.location.href = "/";
        }
        if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Error! #96584122@DEF",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  handleOnChange1(value) {
    this.setState({ mobile: value });
  }
  handleOnChange2(value) {
    this.setState({ office: value });
  }
  handleChange(id, newValue) {
    if (id === "name") this.setState({ name: newValue });
    else if (id === "firstName") this.setState({ firstName: newValue });
    else if (id === "firstNamePreferred")
      this.setState({ firstNamePreferred: newValue });
    else if (id === "middleName")
      this.setState({ middleName: newValue.trim() });
    else if (id === "lastName") this.setState({ lastName: newValue });
    else if (id === "title") this.setState({ title: newValue });
    else if (id === "company") this.setState({ company: newValue });
    else if (id === "email") this.setState({ emailAddress: newValue });
    else if (id === "mobile") {
      this.setState({ mobile: `+${newValue}` }, () =>
        this.formValidaton("mobile", newValue)
      );
    } else if (id === "office") {
      if (newValue && newValue.length > 2) {
        this.setState({ office: `+${newValue}` });
      } else {
        this.setState({ office: "" });
      }
    } else if (id === "password1") this.setState({ pass1: newValue });
    else if (id === "password2") this.setState({ pass2: newValue });
  }
  updateInvitation(event) {
    this.setState({
      loadinglada: true,
      progress: 0.5,
    });
    let temp = this;
    let formValid = false;
    const pass1 = document.getElementById("password1").value;
    const pass2 = document.getElementById("password2").value;
    var exp1 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    var res1 = exp1.test(pass1);
    var res2 = exp1.test(pass2);
    if (pass1 === "" || res1 !== true) {
      formValid = false;
      swal({
        title: "Error!",
        text: "Password is Invalid",
        icon: "error",
        button: "Ok!",
      });
    } else if (pass2 === "" || res2 !== true) {
      formValid = false;
      swal({
        title: "Error!",
        text: "Password is Invalid",
        icon: "error",
        button: "Ok!",
      });
    } else {
      formValid = true;
    }
    if (formValid) {
      const file = document.querySelector("input[type=file]").files[0];
      let data = new FormData();
      data.append("file", file);
      let mobile = this.state.mobile.replace(/[- )(]/g, "");
      let office = this.state.office
        ? this.state.office.replace(/[- )(]/g, "")
        : null;
      const param = JSON.stringify({
        token: this.state.userToken,
        name: this.state.firstName + " " + this.state.lastName,
        firstName: this.state.firstName,
        firstNamePreferred: this.state.firstNamePreferred,
        lastName: this.state.lastName,
        title: this.state.title,
        mobile: mobile,
        office: office,
        password: this.state.pass2,
        middleName: this.state.middleName,
        email: this.state.emailAddress,
        investorCompanyName:
          this.state.inviteRoleCode === "005" && this.state.company
            ? this.state.company
            : "",
      });
      data.append("params", param);
      temp.setState({ loadinglada: true });
      axios
        .put(`${common.path.base_url}/acceptInvitation`, data)
        .then(function (response) {
          temp.setState({ loadinglada: false });
          swal({
            title: "Updated!",
            text: response.data.message,
            icon: "success",
            button: "Ok!",
          }).then(function () {
            temp.setState({ loadinglada: false });
            temp.props.history.push(`/?username=${temp.state.emailAddress}`);
          });
        })
        .catch(function (error) {
          temp.setState({ loading: false });
          if (
            error.response &&
            error.response.data &&
            error.response.data.message &&
            common.AUTH_EXPIRED === error.response.data.message
          ) {
            window.localStorage.clear();
            window.location.href = "/";
            return;
          } else if (error.response) {
            swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              button: "Ok!",
            });
            temp.setState({ loadinglada: false });
          } else {
            swal({
              title: "Network Error!",
              text: error.message,
              icon: "error",
              button: "Ok!",
            });
            temp.setState({ loadinglada: false });
          }
        });
    } else {
      // document.getElementById("submitButton").disabled = true;
      event.preventDefault();
      swal({
        title: "Error!",
        text: "The password should have atleast one capital letter, one alphanumeric character and one number",
        icon: "error",
        button: "Ok!",
      });
      this.setState({ loadinglada: false });
    }
    this.setState({ loading: false });
  }
  passwordMatch() {
    let password = document.getElementById("password1").value;
    let password2 = document.getElementById("password2").value;
    let list = document.querySelector("instructions");
    let validateFlag = true;
    //  list.style.color = "green";
    if (password.length < 8) {
      document.getElementById("first").style.color = "red";
      validateFlag = false;
    } else {
      document.getElementById("first").style.color = "green";
    }
    if (/[0-9]/.test(password)) {
      document.getElementById("second").style.color = "green";
    } else {
      document.getElementById("second").style.color = "red";
      validateFlag = false;
    }
    if (/[!@#\$%\^&]/.test(password)) {
      document.getElementById("third").style.color = "green";
    } else {
      document.getElementById("third").style.color = "red";
      validateFlag = false;
    }
    if (/[A-Z]/.test(password)) {
      document.getElementById("fourth").style.color = "green";
    } else {
      document.getElementById("fourth").style.color = "red";
      validateFlag = false;
    }

    if (password2.trim() === "") {
      validateFlag = false;
    }

    this.setState({ invitationFromValidated: validateFlag });
  }
  formValidaton(id, val) {
    let validateFlag = true;
    if (id === "name") {
      let name = document.getElementById("name").value;
      if (name === "") {
        this.setState({
          nameValidation: "Name should not be empty*",
        });
        validateFlag = false;
      } else {
        this.setState({
          nameValidation: "",
        });
      }
    } else if (id === "firstName") {
      // let name = document.getElementById('firstName').value;
      let name = val;
      if (name === "") {
        this.setState({
          firstNameValidation: "First Name should not be empty*",
        });
        validateFlag = false;
      } else {
        this.setState({
          firstNameValidation: "",
        });
      }
    } else if (id === "lastName") {
      // let name = document.getElementById('lastName').value;
      let name = val;
      if (name === "") {
        this.setState({
          lastNameValidation: "Last Name should not be empty*",
        });
        validateFlag = false;
      } else {
        this.setState({
          lastNameValidation: "",
        });
      }
    } else if (id === "email") {
      let email = document.getElementById("email").value;
      let emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let res = emailRegex.test(email);
      if (email === "") {
        this.setState({
          emailAddressValidation: "Email should not be empty*",
        });
        validateFlag = false;
      } else if (res !== true) {
        this.setState({
          emailAddressValidation: "Please enter valid email*",
        });
        validateFlag = false;
      } else {
        this.setState({
          emailAddressValidation: "",
        });
      }
    } else if (id === "title") {
      let title = document.getElementById("title").value;
      if (title === "" && this.state.inviteRoleCode !== "005") {
        this.setState({
          titleValidation: "Title should not be empty*",
        });
        validateFlag = false;
      } else {
        this.setState({
          titleValidation: "",
        });
      }
    } else if (id === "company") {
      let company = document.getElementById("company").value;
      // if (company === '') {
      //     this.setState({
      //         companyValidation: "Company should not be empty*",
      //     })
      //     validateFlag = false;
      // } else {
      //     this.setState({
      //         companyValidation: ""
      //     })
      // }
    } else if (id === "password1") {
      let pass1 = document.getElementById("password1").value;
      var exp1 =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
      var res1 = exp1.test(pass1);
      if (pass1 === "") {
        this.setState({
          enterpassValidation: "Password should not be empty*",
        });
        validateFlag = false;
      } else if (res1 !== true) {
        this.setState({
          enterpassValidation: "Enter valid Password*",
        });
        validateFlag = false;
      } else {
        this.setState({
          enterpassValidation: "",
        });
      }
    } else if (id === "password2") {
      let pass1 = document.getElementById("password1").value;
      let pass2 = document.getElementById("password2").value;
      if (pass2 === "") {
        this.setState({
          confirmpassValidation: "Password should not be empty*",
        });
        validateFlag = false;
      } else if (pass1 !== pass2) {
        this.setState({
          confirmpassValidation: "Password does not match*",
        });
        validateFlag = false;
      } else {
        this.setState({
          confirmpassValidation: "",
        });
      }
    } else if (id === "mobile") {
      let mobile = val;
      let mobileRegex =
        /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/;
      let res = mobileRegex.test(mobile);
      if (res !== true) {
        let swissRegex =
          /^(\+?)(\d{2,4})(\s?)(\-?)((\(0\))?)(\s?)(\d{2})(\s?)(\-?)(\d{3})(\s?)(\-?)(\d{2})(\s?)(\-?)(\d{2})/;
        let swissres = swissRegex.test(mobile);
        if (swissres !== true) {
          this.setState({
            mobileValidation: "Please enter valid mobile number",
          });
          validateFlag = false;
        } else {
          this.setState({
            mobileValidation: "",
          });
        }
      } else {
        this.setState({
          mobileValidation: "",
        });
      }
    } else if (id === "office") {
      let office = val;
      if (val.length > 2) {
        let officeRegex =
          /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/;
        let res = officeRegex.test(office);
        if (res !== true) {
          let swissRegex =
            /^(\+?)(\d{2,4})(\s?)(\-?)((\(0\))?)(\s?)(\d{2})(\s?)(\-?)(\d{3})(\s?)(\-?)(\d{2})(\s?)(\-?)(\d{2})/;
          let swissres = swissRegex.test(office);
          if (swissres !== true) {
            this.setState({
              officeValidation: "Please enter valid office number",
            });
            validateFlag = false;
          } else {
            this.setState({
              officeValidation: "",
            });
          }
        } else {
          this.setState({
            officeValidation: "",
          });
        }
      } else {
        this.setState({
          officeValidation: "",
        });
      }
    }
    this.setState({ invitationFromValidated: validateFlag, titleVaild: true });
  }

  openTermsAndConditionModal() {
    document.getElementById("openModal-btn").click();
    this.setState({ loading: false });
  }
  vaildForTitle() {
    return this.state.inviteRoleCode !== "005" && !this.state.title
      ? false
      : true;
  }

  accept(value) {
    if (value === "accept") {
      this.setState({ isAccepted: true }, () => {
        this.componentDidMount();
      });
    } else if (value === "cancel") {
      this.setState({ isAccepted: false });
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <div className="AcceptInvitation">
        {this.state.loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <HeaderWL />
            <HeaderImg heading={this.state.inviteRole} />

            <AcceptTermAndConditionModal
              onRef={(ref) => (this.acceptTermAndConditionModalChild = ref)}
              agreeTremsModalCallback={(value) => this.accept(value)}
            />

            <TButton
              onClick={() => { }}
              id="openModal-btn"
              dataTarget={`#acceptTermsAndCondition`}
              dataToggle={`modal`}
              style={{ display: "none" }}
            />

            <div className="update">
              <div className="newInvitationHeading">
                New Member Login
                {/* New Invitation from {this.state.invitationFrom} */}
              </div>
              <div className="newInvitationContent">
                {/* You have been invited from &nbsp;{this.state.invitationFrom}&nbsp;to be associated as a &nbsp;{this.state.inviteRole} . Please update your information before accepting the invitation. */}
              </div>
              <div className="profile">
                <div className="profile-details">
                  {/* <form className="form-horizontal"> */}
                  <div className="form-horizontal">
                    {/* <div className="form-group">
                                    <label className="col-md-3 control-label">Full Name <RequiredSign /> :</label>
                                    <div className="col-md-8">
                                        <input className="form-control" id="name" type="text" placeholder='Name'
                                            onKeyUp={(event) => this.formValidaton(event.target.id)}
                                            onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                            value={this.state.name} />
                                        <p>{this.state.nameValidation}</p>
                                    </div>
                                </div> */}

                    <div className="form-group">
                      <label className="col-md-3 control-label">
                        First Name
                        <RequiredSign /> :
                      </label>
                      <div className="col-md-8">
                        <input
                          className="form-control"
                          id="firstName"
                          type="text"
                          placeholder=""
                          onKeyUp={(event) =>
                            this.formValidaton(event.target.id)
                          }
                          onChange={(event) =>
                            this.handleChange(
                              event.target.id,
                              event.target.value
                            )
                          }
                          value={this.state.firstName}
                        />
                        <p>{this.state.firstNameValidation}</p>
                      </div>
                    </div>
                    {/* <div className="form-group">
                                            <label className="col-md-3 control-label">First Name (Preferred) :</label>
                                            <div className="col-md-8">
                                                <input className="form-control" id="firstNamePreferred" type="text" placeholder=''
                                                    // onKeyUp={(event) => this.formValidaton(event.target.id)}
                                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                    value={this.state.firstNamePreferred} />

                                            </div>
                                        </div> */}

                    <div className="form-group">
                      <label className="col-md-3 control-label">
                        Middle Name :
                      </label>
                      <div className="col-md-8">
                        <input
                          className="form-control"
                          id="middleName"
                          type="text"
                          placeholder=""
                          // onKeyUp={(event) => this.formValidaton(event.target.id)}
                          onChange={(event) =>
                            this.handleChange(
                              event.target.id,
                              event.target.value
                            )
                          }
                          value={this.state.middleName}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="col-md-3 control-label">
                        Last Name <RequiredSign /> :
                      </label>
                      <div className="col-md-8">
                        <input
                          className="form-control"
                          id="lastName"
                          type="text"
                          placeholder=""
                          onKeyUp={(event) =>
                            this.formValidaton(event.target.id)
                          }
                          onChange={(event) =>
                            this.handleChange(
                              event.target.id,
                              event.target.value
                            )
                          }
                          value={this.state.lastName}
                        />
                        <p>{this.state.lastNameValidation}</p>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="col-md-3 control-label">
                        Title{" "}
                        {this.state.inviteRoleCode !== "005" ? (
                          <RequiredSign />
                        ) : null}{" "}
                        :
                      </label>
                      <div className="col-md-8">
                        <input
                          className="form-control"
                          id="title"
                          type="text"
                          placeholder=""
                          onKeyUp={(event) =>
                            this.state.inviteRoleCode !== "005"
                              ? this.formValidaton(event.target.id)
                              : ""
                          }
                          onChange={(event) =>
                            this.handleChange(
                              event.target.id,
                              event.target.value
                            )
                          }
                          value={this.state.title}
                        />
                        <p>{this.state.titleValidation}</p>
                      </div>
                    </div>
                    {
                      <div className="form-group">
                        <label className="col-md-3 control-label">
                          Company{" "}
                          {this.state.inviteRoleCode !== "005" ? (
                            <RequiredSign />
                          ) : null}{" "}
                          :
                        </label>
                        <div className="col-md-8">
                          <input
                            className="form-control"
                            id="company"
                            type="text"
                            placeholder=""
                            onKeyUp={(event) =>
                              this.formValidaton(event.target.id)
                            }
                            onChange={(event) =>
                              this.handleChange(
                                event.target.id,
                                event.target.value
                              )
                            }
                            value={this.state.company}
                          />
                          <p>{this.state.companyValidation}</p>
                        </div>
                      </div>
                    }

                    <div className="form-group">
                      <label className="col-md-3 control-label">
                        Email Address <RequiredSign /> :
                      </label>
                      <div className="col-md-8">
                        <input
                          className="form-control"
                          id="email"
                          type="text"
                          placeholder=""
                          onKeyUp={(event) =>
                            this.formValidaton(event.target.id)
                          }
                          onChange={(event) =>
                            this.handleChange(
                              event.target.id,
                              event.target.value
                            )
                          }
                          value={this.state.emailAddress}
                        />
                        <p>{this.state.emailAddressValidation}</p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-md-3 control-label">
                        Enter Password <RequiredSign /> :
                      </label>
                      <div className="col-md-8">
                        <input
                          className="form-control"
                          type="password"
                          id="password1"
                          name="username"
                          onKeyUp={(event) =>
                            this.formValidaton(event.target.id)
                          }
                          onChange={(event) =>
                            this.handleChange(
                              event.target.id,
                              event.target.value
                            )
                          }
                          value={this.state.pass1}
                          placeholder=""
                          onKeyUp={() => this.passwordMatch()}
                        />
                        <p>{this.state.enterpassValidation}</p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className=" col-md-3 control-label">
                        Instructions
                      </label>
                      <div className="col-md-8">
                        <ul id="instructions">
                          <li id="first">8 characters</li>
                          <li id="second">1 number</li>
                          <li id="third">
                            1 non-alphanumeric (!, @, #, $, etc.)
                          </li>
                          <li id="fourth">1 upper-case</li>
                        </ul>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-md-3 control-label">
                        Confirm New Password <RequiredSign /> :
                      </label>
                      <div className="col-md-8">
                        <input
                          className="form-control"
                          id="password2"
                          type="password"
                          name="password"
                          onKeyUp={(event) =>
                            this.formValidaton(event.target.id)
                          }
                          onChange={(event) =>
                            this.handleChange(
                              event.target.id,
                              event.target.value
                            )
                          }
                          value={this.state.pass2}
                          placeholder=""
                        />
                        <p>{this.state.confirmpassValidation}</p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-md-3 control-label">
                        Mobile <RequiredSign /> :
                      </label>
                      <div className="col-md-8 mobile-num">
                        <ReactPhoneInput
                          country={"us"}
                          id="mobile"
                          onChange={(value) =>
                            this.handleChange("mobile", value)
                          }
                          placeholder=""
                          disableAreaCodes={true}
                          countryCodeEditable={false}
                          autoComplete="none"
                          value={this.state.mobile}
                        />
                        <p>{this.state.mobileValidation}</p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-md-3 control-label">Office:</label>
                      <div className="col-md-8">
                        <ReactPhoneInput
                          country={"us"}
                          id="office"
                          onChange={(value) =>
                            this.handleChange("office", value)
                          }
                          placeholder=""
                          disableAreaCodes={true}
                          countryCodeEditable={false}
                          autoComplete="none"
                          value={this.state.office}
                        />
                        <p>{this.state.officeValidation}</p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-md-3 control-label"></label>
                      <div className="col-md-7 updateButton">
                        {/* <input type="button" 
                                        onClick={()=>this.updateInvitation()}
                                        className="btn btn-success" value="UPDATE & ACCEPT" /> */}
                        <LaddaButton
                          loading={this.state.loadinglada}
                          // id="submitButton"
                          // onClick={(event) => this.updateInvitation(event)}
                          onClick={(event) => this.updateInvitation(event)}
                          className={
                            !this.state.invitationFromValidated ||
                              _.isEmpty(
                                this.vaildForTitle() &&
                                this.state.mobile &&
                                this.state.firstName &&
                                this.state.lastName &&
                                ((this.state.company && this.state.companyId) ||
                                  !this.state.companyId) &&
                                this.state.pass1 &&
                                this.state.pass2
                              )
                              ? "disable"
                              : ""
                          }
                          data-color="#fc6c85"
                          data-size={XS}
                          data-style={EXPAND_RIGHT}
                          data-spinner-size={30}
                          data-spinner-color="#ddd"
                          data-spinner-lines={12}
                        >
                          UPDATE & ACCEPT
                        </LaddaButton>
                      </div>
                    </div>
                  </div>
                  {/* </form> */}
                </div>
                <div className="upload-profile-pic">
                  <div className="profile-pic-box">
                    <img
                      id="preview"
                      className="profile-pic"
                      src={`${common.path.base_url}/getImage?id=${this.state.id}`}
                      alt=""
                    />
                    <div className="p-image">
                      <i
                        onClick={() => this.chooseFile()}
                        className="fa fa-plus upload-button"
                      ></i>
                      <input
                        id="choose-file"
                        onChange={() => this.previewFile()}
                        className="file-upload"
                        type="file"
                        accept="image/jpeg,image/png,image/jpg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
export default withRouter(AcceptInvitation);
// export default AcceptInvitation;

const initialState = {
  isHide: true
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'HIDE_SIDEBAR_NOTIFICATION':
      return { ...state, isHide: true };
    case 'RESET': 
      return { ...state, isHide: false };
    default:
      return state;
  }
}

export default reducer
import React, { Component } from 'react';
import './Preview.scss';
import Header from '../../../components/Header';
import HeaderImg from '../../../components/HeaderImage';
import LoadingSpinner from '../../../components/Loader';
import common from '../../../common';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import ReactToPrint from 'react-to-print';
import TButton from '../../../components/TButton';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import images from "../../../images";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


class Preview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            iirPDF: null,
            numPages: null,
            setNumPages: [],
            pageNumber: 1,
            setPageNumber: 1,

        }
        this.screenWidth = null;

    }
    onDocumentLoadSuccess(pdf) {
        const setNumPages = [];
        if (pdf._pdfInfo.numPages > 0) {

            for (let i = 0; i < pdf._pdfInfo.numPages; i++) {
                setNumPages.push(i + 1);
            }
        }
        this.setState({ numPages: pdf._pdfInfo.numPages, setNumPages });
    }

    componentWillMount() {
        const url = new URL(window.location.href);
        this.companyId = url.searchParams.get('companyId');
        this.investmentId = url.searchParams.get('investmentId');
        this.screenWidth = `${window.screen.width}`;
        if (window.screen.width > 1280) {
            this.screenWidth = '1400';
        }
        else if (window.screen.width > 1000) {
            this.screenWidth = '800';
        } else if (window.screen.width < 768 && window.screen.width > 375) {
            this.screenWidth = '700';
        } else {
            this.screenWidth = '375';
        }
        this.getIIRList();
    }
    getIIRList() {
        let temp = this;
        let token = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/getIIR/${this.companyId}/${this.investmentId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                console.log(response.data.data);
                if (response && response.data && response.data.data && response.data.data.iirPDF) {
                    temp.setState({ iirPDF: response.data.data.iirPDF })
                    // window.open(`${common.path.base_url}/getDocument?fileId=${response.data.data.iirPDF}`, '_blank');
                    // this.props.history.goBack()
                } else {
                    window.location.href = `/previewIIR?companyId=${this.companyId}&investmentId=${this.investmentId}`
                }

            })
            .catch(function (error) {
                console.log(error);
                temp.setState({ loadinglada: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error && error.response && error.response.data && error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }
    redirect() {
        this.props.history.goBack()
    }
    printIframe = (id) => {
        const iframe = document.frames
            ? document.frames[id]
            : document.getElementById(id);
        const iframeWindow = iframe.contentWindow || iframe;

        iframe.focus();
        iframeWindow.print();

        return false;
    };

    render() {
        const username = window.localStorage.getItem("username");
        const iirPDF = this.state.iirPDF;
        return (
            <div>
                <div className="preview">
                    {this.state.loading ? <LoadingSpinner /> :
                        <>
                            <Header username={username} role={this.state.Role} />
                            <HeaderImg heading={`Investment Intelligence Report`} />
                            <div className="pdf-preview-main" ref={elements => this.componentRef = elements}>
                                <div className="close-btn-wraper">
                                    <a className='printerIcon' rel="noopener noreferrer" target='_blank' href={`${common.path.base_url}/getDocument?fileId=${iirPDF}`}>
                                        <img src={images.path.printerIcon} alt='' />
                                    </a>
                                </div>
                                <div className="close-btn-wraper">

                                    <button type="button" className="close" aria-label="Close" onClick={() => this.props.history.goBack()}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="preview-main">


                                    {
                                        iirPDF &&
                                        <div style={{ marginBottom: '40px' }}>
                                            <Document
                                                file={`${common.path.base_url}/getDocument?fileId=${iirPDF}`}
                                                onLoadSuccess={(pdf) => this.onDocumentLoadSuccess(pdf)}
                                            >

                                                {
                                                    this.state.setNumPages.length > 0 &&
                                                    this.state.setNumPages.map(page => (
                                                        <Page pageNumber={page} width={this.screenWidth} className="pdf-page" />
                                                    ))}
                                            </Document>

                                        </div>
                                    }
                                </div>

                            </div>
                        </>
                    }
                </div>
            </div>
        );
    }
}
export default withRouter(Preview);
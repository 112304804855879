import React, { Component } from 'react';

// Imported Components
import HeaderImage from '../../components/HeaderImage';
import LoadingSpinner from '../../components/Loader';
import CustomModal from '../../components/CustomModal';
import InvestmentModal from './InvestmentModal/AddInvestment';
import UploadDocumentModal from './InvestmentUploadDocumentModal/UploadDocument';
import ImportContactsModal from './ImportContactsModal/ImportContacts';
import SuccessImportContactsModal from './ImportContactsModal/SuccessImportContacts';
import InvestorInvestmentModal from '../../components/InvestorInvestmentModal/InvestorInvestment';
import ProgressbarModal from '../Dashboard/progressbarModal/progressbarModal';

import Header from '../../components/Header';
import TButton from '../../components/TButton';
import CompanyCard from '../../components/CompanyCard';
// import FileUploadBlobDoc from '../../components/FileUploadBlobDoc';
import ContactCard from '../../components/ContactCard';
import UploadedFilesCard from '../../components/UploadedFilesCard';
import Documents from '../../components/Documents';
import InvestmentCard from '../../components/InvestmentCard';
// Imported Images
import images from "../../images";

// Imported Styling
import './Dashboard.scss';

// Imported Utility Libraries
import axios from 'axios';
import common from '../../common';
import swal from 'sweetalert';
import _ from 'lodash';
import $ from 'jquery';
import FileUploadBlobDoc from '../../components/FileUploadBlobDoc';
import InvestorCard from '../../components/InvestorCard';
import Select from 'react-select';
import { Accordion } from 'react-bootstrap';
import ParticipateCard from '../../components/ParticipateCard/ParticipateCard';
import AddStartupContactModal from './AddStartupContact/';

let userRole = '000';
let userRoleCode = '000';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            data: [],
            Role: '',
            loading: false,
            choosenFile: null,
            loadinglada: false,
            dashboardData: [],
            newDashboardData: [],
            profileData: {},
            startupContacts: [],
            startupContactsUI: [],
            userId: null,
            companyUploadedFiles: [],
            count: 0,
            isDocumentLoaded: false,
            executiveProgress: false,
            investorInvestments: [],
            investorCompanies: [],
            investorDocuments: [],
            investorInvestmentsForFilter: [],
            selectedstage: '',
            stages: [
                { value: 'all', label: 'All' },
                { value: 'invite', label: 'Pipeline' },
                { value: 'open', label: 'Open' },
                { value: 'closed', label: 'Closed' },
                { value: 'exited', label: 'Exited' }
            ],
            startupStages: [
                { value: 'open', label: 'In Process' },
                { value: 'exit_in_process', label: 'Exit In Process' },
                { value: 'exited', label: 'Exited' }
            ],
            singleStartupStageChanged : { value: 'open', label: 'Open' },
            startupStageChanged : 'open',
            investmentStages: [
                { value: 'all', label: 'All' },
                { value: 'open', label: 'Open' },
                { value: 'invite', label: 'Pipeline' },
                { value: 'closed', label: 'Closed' },
            ],
            singleInvestmentStagesChanged : { value: 'open', label: 'Open' },
            investmentStagesChanged : 'open',
            investmentOrder: ['pipeline', 'open', 'closed', 'exited'],
            currentStage: { value: 'all', label: 'All' },
            investorDashboardId: undefined,
            showStartupCards: true,
            showInvestmentsCards: false,
            investmentsData: [],
            activeInvestmentId: null,
            startupCount: 0,
            startupExecutiveProgress: false,
            startUpInvestments: [],
            selectedInvestment: null,
            selectedInvestmentDocuments: null,
            investmentForStartup: [],
            isAddTeamMemberDone: false,
            isUploadDone: false,
            showProgressBar: true,
            isDocumentCheck: false,
            currentInvestorName: null,
            companyData: [],
            invitedInvestments: [],
            filterInvitedInvestments: [],
            investmentCompanies: [],
            currentInvestorTitle: null,
            currentInvestorPhone: null,
            successImportData: null,
            masterChecklist: [],
            updateDorpDown : 0, 
        }


        this.renderHeaderBtn = this.renderHeaderBtn.bind(this);
        const url = new URL(window.location.href);
        this.investorDashboardUserId = url.searchParams.get('investorId');


    }


    componentDidMount() {
        if (userRoleCode === '001' || userRoleCode === '002') {
            this.getMasterChecklist();
        }
    }

    componentWillMount() {

        userRole = window.localStorage.getItem('role');
        userRoleCode = window.localStorage.getItem('roleCode');
        if (userRoleCode === '005') {
            this.props.history.push('/investor-dashboard');
        }

        this.setState({
            name: window.localStorage.getItem('username'),
            userId: window.localStorage.getItem('id')
        });
        this.setState({ Role: userRole })
        const url = new URL(window.location.href);
        let investorDashboardId = url.searchParams.get('investorId');
        if (investorDashboardId) {
            this.getUserInvestments();
        } else {
            // this.getDashboardData();
            this.getProfileData();

            if (userRoleCode === '001' || userRoleCode === '002') {
                this.getDashboardData();
            }
            if (userRoleCode === '005') {
                this.getUserInvestments();
            }

            if (userRoleCode === '003' || userRoleCode === '004') {
                const isUploadDone = window.localStorage.getItem('isUploadDone');
                const isDocumentCheck = window.localStorage.getItem('isDocumentCheck');
                const isAddTeamMemberDone = window.localStorage.getItem('isAddTeamMemberDone');
                this.setState({ isUploadDone, isDocumentCheck, isAddTeamMemberDone });
                this.getContactList();
            }

            $('.modal').on('show', function (e) {
            })
        }
        this.status = url.searchParams.get('status');
        this.processId = url.searchParams.get('processId');
        this.envId = url.searchParams.get('env');
        this.type = url.searchParams.get('type');
        if (this.processId && this.status === 'completed' && this.envId && this.type) {
            let temp = this;
            temp.setState({ loading: true });
            let tokenpass = window.localStorage.getItem('token');
            const data = {
                id: this.processId,
                type: this.type,
                envId: this.envId,
            }
            axios.post(`${common.path.base_url}/signInvestmentProcess`, data, {
                headers: {
                    "Authorization": tokenpass
                }
            })
                .then((response) => {
                    temp.setState({ loading: false });
                    window.location.href = '/';
                })
                .catch(function (error) {
                    if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        window.localStorage.clear();
                        window.location.href = '/';
                        return;
                    }
                    else {
                        swal({
                            title: "Error!",
                            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                    temp.setState({ loadinglada: false });
                });
        }
    }
    getDashboardData() {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/getTSGDashboardUsers`, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => { 
                temp.setState({ loading: false, isDocumentLoaded: true });
                this.setState({ dashboardData: response.data.data, companyData: response.data.data }, () => {
                    temp.mapInvestmentsData();
                    if (temp.state.searchText) {
                        //console.log("1111111111111",temp.state.searchText);
                        temp.searchMapData(temp.state.searchText);
                    }
                    if(this.state.showStartupCards) {
                        //console.log("asdasdasdasd",temp.state.searchText);
                        this.StartupStageChanged('open');
                    }
                });
                
                
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response && error.response.data && error.response.data.code && error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });
            });
    }
    getContactList() {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');

        axios.get(`${common.path.base_url}/getMasterList`, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                const contacts = response.data.data;
                temp.setState({ loading: false, isDocumentLoaded: true });
                temp.setState({
                    startupContacts: contacts.startup[0].users,
                    startupContactsUI: contacts.startup[0].users
                });
            })
            .catch(function (error) {
                if (error && error.response && error.response.data && error.response.data.code === 204) {
                    // window.localStorage.clear();
                    // window.location.href = '/';
                }
                temp.setState({ loading: false });
            });
    }

    getMasterChecklist() {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');

        axios.get(`${common.path.base_url}/checkList?type=${common.checklistTypes.all}`, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                const checklists = response.data.data;
                temp.setState({ loading: false, masterChecklist: checklists });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loading: false });
            });
    }

    getCompanyUploadedFiles(investmentId) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');

        const userRoleCode = window.localStorage.getItem('roleCode');
        const companyId = window.localStorage.getItem('companyId');
        const userId = window.localStorage.getItem('id');
        let queryString = '';
        if (userRoleCode == '003') {
            queryString = `companyId=${companyId}&investmentId=${investmentId ? investmentId : this.state.selectedInvestment.value}`;
        } else {
            queryString = `companyId=${companyId}&userId=${userId}&investmentId=${investmentId ? investmentId : this.state.selectedInvestment.value}`;
        }
        axios.get(`${common.path.base_url}/getInvestmentFiles?${queryString}`, {
            headers: {
                "Authorization": tokenpass
            },
        })
            .then((response) => {
                const uploadedFiles = response.data.data;
                temp.setState({ loading: false });
                temp.setState({
                    companyUploadedFiles: uploadedFiles
                });
                // this.getIIRList(companyId);
            })
            .catch(function (error) {
                temp.setState({ loading: false });
            });
    }


    uploadCSVFile(file) {
        if (file.size) {
            const temp = this;
            if (!this.state.selectedInvestment) {
                swal({
                    title: "Investment not exists",
                    text: 'There is no any investment belongs to current startup. Please first create investment then upload contact!',
                    icon: "error",
                    button: "Ok!",
                });
                return;
            }
            let tokenpass = window.localStorage.getItem('token');
            let data = new FormData();
            data.append('file', file);
            this.setState({ loadinglada: true });
            temp.setState({ loading: true });
            data.append('investmentId', this.state.selectedInvestment && this.state.selectedInvestment.value);
            axios.post(`${common.path.base_url}/csvInvestmentUpload`, data, {
                headers: {
                    "Authorization": tokenpass
                }
            })
                .then((response) => {
                    swal({
                        title: "File Uploaded Successfully!",
                        text: response.data.message,
                        icon: "success",
                        button: "Ok!",
                    })
                        .then(function () {
                            temp.setState({ loading: false });
                            temp.getCompanyUploadedFiles();
                            temp.setState({ loadinglada: false, choosenFile: null });
                        });
                })
                .catch((error) => {
                    temp.setState({ loadinglada: false });
                    if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        window.localStorage.clear();
                        window.location.href = '/';
                        return;
                    }
                    else if (error.response) {
                        swal({
                            title: "Error!",
                            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                    else {
                        swal({
                            title: "Network Error!",
                            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                });
        }
        else {
            swal({
                title: "File Warning",
                text: "No Files/ Unsupported File",
                icon: "warning",
                button: "Ok!",
            });
        }
    }

    filterByStage(filterType, filterValue) {
        let investList = this.state.investorInvestmentsForFilter;
        let invitedList = this.state.filterInvitedInvestments;
        let newInvestmentList = [];
        let newInvitedInvestments = [];
        if (filterValue !== '') {
            switch (filterValue) {

                case 'invite':
                    newInvestmentList = [];
                    newInvitedInvestments = invitedList.filter(investment => {
                        const found = investment.investmentprocesses && investment.investmentprocesses.filter(process => process.status === 'Invited');
                        if (found && found.length > 0) return investment;
                    });
                    break;
                case 'participate':
                    newInvestmentList = [];
                    newInvitedInvestments = invitedList.filter(investment => {
                        const found = investment.investmentprocesses && investment.investmentprocesses.filter(
                            (process) => {
                                if (process.status !== 'Invited'
                                    && process.status !== 'Not Interested'
                                    && process.status !== 'Complete'
                                ) {
                                    return process;
                                }
                            });
                        if (found && found.length > 0) return investment;

                    });
                    break;
                case 'complete':
                    newInvitedInvestments = [];
                    newInvestmentList = investList.filter((investment) => investment.stage !== 'closed')
                    break;
                case 'closed':
                    newInvitedInvestments = [];
                    newInvestmentList = investList.filter((investment) => investment.stage === 'closed')
                    break;
                default:
                    newInvestmentList = this.state.investorInvestmentsForFilter;
                    newInvitedInvestments = this.state.filterInvitedInvestments;
                    break;

            }

        } else {
            newInvestmentList = this.state.investorInvestmentsForFilter;
            newInvitedInvestments = this.state.filterInvitedInvestments;
        }
        this.setState({ investorInvestments: newInvestmentList, invitedInvestments: newInvitedInvestments });
        // this.renderInvitedInvestorsCards();
        // this.renderInvestorsCards();
    }


    renderHeaderBtn = () => {
        const role = userRoleCode;
        switch (role) {

            case '001':
                // TSG ADMIN
                return (<div className="header-btn">
                    <TButton onClick={() => this.uploadDocumentModalChild.resetUploadDocData()} text={`ADD INVESTOR DOCUMENTS`} dataTarget={`#uploadDocumentModal`} dataToggle={`modal`} className='contact-btn' />
                    <TButton onClick={() => this.props.history.push('/contacts')} text={`MASTER CONTACT LIST`} className='contact-btn' />
                    <TButton onClick={() => this.props.history.push('/pipeline')} text={`PIPELINE STARTUPS`} className='contact-btn' />
                    {/* <TButton onClick={() => this.customModalChild.resetInviteData()} text={`ADD STARTUP`} dataTarget={`#addCompanyModal`} dataToggle={`modal`} className='contact-btn' /> */}
                    <TButton onClick={() => this.customModalChild.resetInviteData()} text={`ADD CONTACT`} dataTarget={`#inviteModal`} dataToggle={`modal`} className='contact-btn' />
                    <TButton onClick={() => this.props.history.push(`/global-settings`)} text={`GLOBAL SETTINGS`} className='invite-btn' />
                </div>);

            case '002':
                // TSG MANAGER
                return (<div className="header-btn">
                    <TButton onClick={() => this.uploadDocumentModalChild.resetUploadDocData()} text={`ADD INVESTOR DOCUMENTS`} dataTarget={`#uploadDocumentModal`} dataToggle={`modal`} className='contact-btn' />
                    <TButton onClick={() => this.props.history.push('/contacts')} text={`MASTER CONTACT LIST`} className='contact-btn' />
                    <TButton onClick={() => this.props.history.push('/pipeline')} text={`PIPELINE STARTUPS`} className='contact-btn' />
                    {/* <TButton onClick={() => this.customModalChild.resetInviteData()} text={`ADD STARTUP`} dataTarget={`#addCompanyModal`} dataToggle={`modal`} className='contact-btn' /> */}
                    <TButton onClick={() => this.customModalChild.resetInviteData()} text={`ADD CONTACT`} dataTarget={`#inviteModal`} dataToggle={`modal`} className='contact-btn' />
                    <TButton onClick={() => this.props.history.push(`/global-settings`)} text={`GLOBAL SETTINGS`} className='invite-btn' />
                </div>);

            case '003':
                // CLIENT ADMIN
                return (<div className="header-btn">
                    <TButton onClick={() => this.props.history.push('/contacts')} text={`MY CONTACTS`} className='contact-btn-startup' tsgBtnclassName="tsg-blue-btn" />
                </div>);

            case '004':
                // CLIENT MANAGER
                return (<div className="header-btn">
                    <TButton onClick={() => this.props.history.push('/contacts')} text={`MY CONTACTS`} className='contact-btn-startup' tsgBtnclassName="tsg-blue-btn" />
                    {/* <TButton onClick={() => this.props.history.push(`/faqPreview?faqPreview=${true}`)} text={`Startup FAQ`} className='invite-btn' /> */}
                </div>);

            case '005':
                // INVESTOR
                return (<div className="header-btn">
                    {/* <p>Hey It's Working!!!</p> */}
                    <label className="control-label">Filter by stage:</label>
                    <div className="select-box">
                        <div className='status'>
                            <select className='status-box' onChange={(event) => this.filterByStage('stage', event.target.value)}>
                                <option value="" disabled selected>Select Status</option>
                                <option value="all">All</option>
                                <option value="invite">Invited</option>
                                <option value="participate">Participate</option>
                                <option value="complete">Complete</option>
                                <option value="closed">Closed</option>
                                {/* <option value="open">Open</option>
                                <option value="exited">Exited</option> */}
                            </select>
                        </div>
                    </div>
                    {/* <TButton onClick={() => { }} text={`MASTER CONTACT LIST`} /> */}
                    {/* <TButton onClick={() => { }} text={`INVITE`} icon={images.path.addIcon} dataTarget={`#inviteModal`} dataToggle={`modal`} /> */}
                </div>);

            default:
                return null;
        }
    }

    openEditCompanyModal() {
        document.getElementById('editCompany-btn').click();
        this.setState({ loading: false });
    }
    openImportContactsModal() {
        document.getElementById('upload-contacts').click();
        this.setState({ loading: false });
    }
    openInviteModal() {
        document.getElementById('inviteOpen-btn').click();
        this.setState({ loading: false });
    }
    openInvestmentModal() {
        document.getElementById('investmentOpen-btn').click();
        //this.setState({ loading: false });
    }
    openUploadDocInvestmentModal() {
        document.getElementById('uploadDocument-btn').click();
        this.setState({ loading: false });
    }
    addUpdateInvestmentModalCallback(data) {
        this.getDashboardData();
    }

    addUpdateInvestorInvestmentModalCallback() {
        this.getUserInvestments();
        this.setState({ loading: false });
    }

    openInvestorInvestmentModal() {
        document.getElementById('updateInvestment-btn').click();
        this.setState({ loading: false });
    }

    deleteCompany(file) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this file",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                const token = localStorage.getItem('token');
                if (willDelete) {
                    //@TODO CALL DELETE CONTACT API HERE WITH NECESSARY CHECKS
                    this.setState({ loading: true })
                    axios.delete(`${common.path.base_url}/deleteCompany`, { headers: { "Authorization": token }, data: { id: file._id } }
                    )
                        //@TODO CALL ON SUCCESS OF API
                        .then((response) => {
                            this.setState({ loading: false });
                            swal({
                                title: "Success!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            })
                                .then(() => {
                                    this.getDashboardData();
                                });

                        })
                        .catch((error) => {
                            this.setState({ loading: false });
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else {
                                swal({
                                    title: "Error!",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });
                }
                else {
                    swal({ text: `${file.companyName} file is safe!`, icon: 'info' });
                }
            });
    }

    deleteInvestment(company, investment) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this file",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                const token = localStorage.getItem('token');
                if (willDelete) {
                    //@TODO CALL DELETE CONTACT API HERE WITH NECESSARY CHECKS
                    this.setState({ loading: true })
                    axios.delete(`${common.path.base_url}/deleteInvestment`,
                        {
                            headers: { "Authorization": token },
                            data: { investmentId: investment._id, companyId: company._id }
                        }
                    )
                        //@TODO CALL ON SUCCESS OF API
                        .then((response) => {
                            this.setState({ loading: false });
                            swal({
                                title: "Success!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            })
                                .then(() => {
                                    this.getDashboardData();
                                });

                        })
                        .catch((error) => {
                            this.setState({ loading: false });
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else {
                                swal({
                                    title: "Error!",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });
                }
                else {
                    swal({ text: `Investment is safe!`, icon: 'info' });
                }
            });
    }

    getInvestmentId(data) {
        if (data.activeInvestmentId) {
            return data.activeInvestmentId;
        } else {
            const investmentAllData = [...data.investment];
            if (investmentAllData && investmentAllData.length > 0) {
                
                let investId = this.state.updateDorpDown;
                let sdds = investmentAllData.filter((invest) => invest._id === investId)[0];
                if (sdds) {
                    // const investmentData = investmentAllData[0];
                    return sdds._id;
                } else if (investId !== 0) {
                    const investmentData = investmentAllData[0];
                    return investmentData._id;
                } else {
                    const investmentData = investmentAllData[0];
                    return investmentData._id;
                }
                
            }
            return null;
        }
    }
    getCurrentInvestmentDocs(data) {
        let investmentId = null;
        if (data.activeInvestmentId) {
            investmentId = data.activeInvestmentId;
        } else {
            const investmentAllData = [...data.investment];
            if (investmentAllData && investmentAllData.length > 0) {

                let investId = this.state.updateDorpDown;
                let sdds = investmentAllData.filter((invest) => invest._id === investId)[0];

                if (sdds) {
                    const investmentData = investmentAllData.splice(investmentAllData.length - 1, 1)[0];
                    investmentId = investmentData._id;
                } else if (investId !== 0) {
                    const investmentData = investmentAllData;
                    investmentId = investmentData[0]._id;
                } else {
                    const investmentData = investmentAllData.splice(investmentAllData.length - 1, 1)[0];
                    investmentId = investmentData._id;
                }
                // console.log("---------");
                // console.log(sdds);
                // console.log(investmentId);
                // console.log(investmentAllData);
            }
        }
        if (investmentId && data.documents && data.documents.length > 0) {
            const docs = data.documents.filter(doc => {
                if (doc.investmentId === investmentId && doc.documentType !== 'iirpdf') {
                    const userId = doc.createdBy;
                    doc.createdBy = { _id: userId, name: doc.createdByName }
                    return doc;
                }
            });
            return docs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        }
        return [];

    }
    getActiveInvestmentData(data) {
        let investmentId = null;
        if (data.activeInvestmentId) {
            investmentId = data.activeInvestmentId;
        }
        const investmentAllData = [...data.investment];
        
        if (investmentId) {
            let sdd = investmentAllData.filter((investment) => investment._id === investmentId)[0];
            return sdd;
        } else {
            let investId = this.state.updateDorpDown;
            let sdds = investmentAllData.filter((invest) => invest._id === investId)[0];
            if (investmentAllData && investmentAllData.length > 0 ) {    
                //console.log('GGGGGG',investId, investmentAllData);
                if (sdds) {
                    const investmentData = sdds;
                    return investmentData;
                } else if (investId !== 0) {
                    //const investmentData = investmentAllData.splice(investmentAllData.length - 1, 2)[0];
                    //console.log(investmentData);
                    //console.log("govind");
                    const investmentData = investmentAllData[0];
                    return investmentData;
                } else {
                    //const investmentData = investmentAllData.splice(investmentAllData.length - 1, 2)[0];
                    //console.log("govind33",investmentAllData);
                    const investmentData = investmentAllData[0];
                    return investmentData;
                }
                //console.log(investmentData);
            }
        }
    }

    getDropdownData(data) {
        let newData = {
            investments: [],
            activeInvestment: null,
        };
        if (data && data.investment && data.investment.length > 0) {
            data.investment.reverse().forEach((item) => {
                newData.investments.push({ value: item._id, label: item.name });
            });
            let investmentId = null;
            if (data.activeInvestmentId) {
                investmentId = data.activeInvestmentId;
            } else {
                let investId = this.state.updateDorpDown;
                let sdds = data.investment.filter((invest) => invest._id === investId)[0];
                if ( sdds ) {
                    investmentId = data.investment.reverse()[0]._id;
                } else if (investId !== 0 ) {
                    investmentId = data.investment[0]._id;
                } else {
                    investmentId = data.investment.reverse()[0]._id;
                }
                //investmentId = data.investment.reverse()[0]._id;
            }
            const currentInvestment = data.investment.filter(investment => investment._id === investmentId)[0];
            if (currentInvestment) {
                newData.activeInvestment = {
                    value: currentInvestment._id,
                    label: currentInvestment.name,
                };
            }
        }
        return newData;
    }

    switchInvestment(data, id) {
        
        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loadinglada: true });
        //temp.setState({ 'dashboardData.activeInvestment': data });
        temp.setState({ updateDorpDown: data.value });
        
        const params = {
            investmentId: data.value,
            companyId: id,
        }
        axios.post(`${common.path.base_url}/switchInvestment`, params,
            {
                headers: {
                    "Authorization": token
                }
            })
            .then((response) => {
                //temp.setState({ 'dashboardData.activeInvestment': data });
                //temp.getDashboardData();
                
                //temp.setState({ loadinglada: true });
                // swal({
                //     title: "Success!",
                //     text: response.data.message,
                //     icon: "success",
                //     button: "Ok!",
                // })
                //     .then(() => {
                //         this.getDashboardData();
                //     })

            })
            .catch((error) => {
                temp.setState({ loadinglada: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });

                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });

    }

    getActiveStartupInvestmentData(data, investmentId) {
        const investmentAllData = [...data];
        if (investmentId) {
            return investmentAllData.filter((investment) => investment._id === investmentId)[0];
        } else {
            if (investmentAllData && investmentAllData.length > 0) {
                const investmentData = investmentAllData.splice(investmentAllData.length - 1, 1)[0];
                return investmentData;
            }
        }
    }
    onRemoveUploadedDocClickHandler() {
        this.getDashboardData()
    }
    getInvitationCount(investmentprocesses, investmentId) {
        let count = 0;
        if (investmentprocesses && investmentprocesses.length > 0) {
            const invitedInvestmetns = investmentprocesses.filter(investment => investment.investmentId === investmentId);
            return invitedInvestmetns.length;
        }

        return count;
    }
    getInviteAcceptedCount(investmentprocesses, investmentId) {
        let count = 0;
        if (investmentprocesses && investmentprocesses.length > 0) {
            const invitedInvestmetns = investmentprocesses.filter(investment => investment.investmentId === investmentId && investment.isSubscribed);
            return invitedInvestmetns.length;
        }

        return count;
    }
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    renderCompanyCards() {
        let cards = null;
        // const dashboard = [...this.state.dashboardData];
        if (!_.isEmpty(this.state.dashboardData)) {
            //console.log("dashboardData....",this.state.dashboardData);
            //let companyCards = this.state.dashboardData.sort((a, b) => a.companyName.localeCompare(b.companyName));
            const arrCard = []
            this.state.dashboardData.map((ele, i) => { 
                ele.companyName = this.capitalizeFirstLetter(ele.companyName)
                //console.log(ele.companyName);
                arrCard.push(ele);
            });
            //let companyCards = this.state.dashboardData.sort((a, b) => { return (a.companyName) - (b.companyName) } );
            let companyCards = arrCard.sort((a, b) => a.companyName.localeCompare(b.companyName));
            //console.log(companyCards);
            companyCards = companyCards.sort((a, b) => {

                let x = (a.investment.length > 0) ? a.investment[0].stage : "",
                    y = (b.investment.length > 0) ? b.investment[0].stage : "";

                return x == y ? 0 : (x < y ? 1 : -1);
            });
            
            console.log('=----',companyCards);
            //this.setState({ newDashboardData : [...companyCards })

            cards = companyCards.map((ele, i) => {
                //if ( (ele.investment.length > 0)  ) {
                
                    //let filterData = ele.investment.filter(item => (item.stage === this.state.startupStageChanged ));

                    // let investmentStagesChanged = this.state.investmentStagesChanged;
                    // if(investmentStagesChanged != 'all') {
                    //     filterData = ele.investment.filter(item => (item.stage === investmentStagesChanged ));
                    // }
                    
                    //if(filterData.length > 0 ) {
                
                        return (< div className="col-xs-12 col-sm-6 col-md-6 col-lg-4" key={"sss_"+ele._id}>
                            <CompanyCard
                                companyName={ele.companyName}
                                companyNickName={ele.companyNickName}
                                legalName={ele.legalName}
                                company={ele}
                                id={ele._id}
                                companyCardIndex={i}
                                fileList={this.getCurrentInvestmentDocs(ele)}
                                investorList={ele.investers}
                                onAddInvestorClick={() => { this.setState({ loading: true }, this.customModalChild.setCompanyInvestor(ele, () => this.openInviteModal())) }}
                                onAddInvestmentClick={() => { this.setState(this.investmentModalChild.setCompanyId(ele._id), this.openInvestmentModal()) }}
                                onEditInvestmentClick={() => { this.setState(this.investmentModalChild.setCompanyInvestment(this.getActiveInvestmentData(ele), ele._id), this.openInvestmentModal()) }}
                                onDeleteInvestmentClick={() => { this.deleteInvestment(ele, this.getActiveInvestmentData(ele)) }}
                                toggleShowMenuForDashboardCard={() => { this.toggleShowMenuForDashboardCard(i) }}
                                onEditCompanyClick={() => { this.customModalChild.getCompanyById(ele._id, null, this.getActiveInvestmentData(ele), () => this.openEditCompanyModal()) }}
                                onImportContactsClick={() => this.setState(this.importContactsModalChild.setInvestmentData(this.getActiveInvestmentData(ele), ele, () => this.openImportContactsModal()))}
                                onDeleteCompanyClick={() => { this.deleteCompany(ele) }}
                                investmentId={this.getInvestmentId(ele)}
                                activeInvestmentData={this.getActiveInvestmentData(ele)}
                                dropdownData={this.getDropdownData(ele)}
                                onSwitchInvestment={(data) => { this.switchInvestment(data, ele._id) }}
                                onSwitchInvestmentClick={() => { this.setState(this.investmentModalChild.switchInvestment(ele), this.openInvestmentModal()) }}
                                onRemoveUploadedDocClick={() => this.onRemoveUploadedDocClickHandler()}
                                masterChecklist={this.state.masterChecklist}
                                invitationSentCount={this.getInvitationCount(ele.investmentprocesses, this.getInvestmentId(ele))}
                                inviteAcceptedCount={this.getInviteAcceptedCount(ele.investmentprocesses, this.getInvestmentId(ele))}
                                getDashboardData={() => this.getDashboardData()}
                                {...this.props}
                            />

                        </div >);
                    //}    
                //}
            })
        }
        return cards;

    }
    getIIRList(companyId) {
        this.setState({ loading: true });
        let temp = this;
        let token = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/investment/${companyId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((res) => {
                let investmentData = null;
                if (res.data.data && res.data.data.length > 0) {
                    const startUpInvestments = [];
                    res.data.data.forEach(investment => {
                        startUpInvestments.push({ value: investment._id, label: investment.name });
                    })
                    investmentData = this.getActiveStartupInvestmentData(res.data.data, this.state.activeInvestmentId);
                    if (investmentData) {
                        this.setState({
                            startUpInvestments,
                            selectedInvestment: { value: investmentData._id, label: investmentData.name },
                            selectedInvestmentDocuments: { value: investmentData._id, label: investmentData.name, iirReviewApproveStatus: investmentData.iirReviewApproveStatus },
                        });
                    }
                }
                axios.get(`${common.path.base_url}/getInvestmentIIR/${investmentData._id}`, {
                    headers: {
                        "Authorization": token
                    }
                })
                    .then((response) => {
                        temp.setState({ loading: false });
                        const sampleArray = response.data.data;
                        if (sampleArray) {
                            temp.setState({ isApproved: sampleArray.isApproved });
                            const keys = Object.keys(sampleArray)
                            const values = Object.values(sampleArray)
                            let z = [];
                            let executiveLetter = [];
                            keys.forEach((key, i) => {
                                z.push({ title: key, state: values[i] })
                            })
                            z.forEach((ele, i) => {
                                let title = ele.title.split(" ");
                                let path = '';
                                for (const word of title) {
                                    path += word;
                                }
                                path = path.substring(0, 1).toLowerCase() + path.substring(1);
                                ele.path = path;
                                if (ele.path === 'founderLetter') {
                                    executiveLetter.push(ele);
                                }
                            })
                            const iirList = z.filter((iir) => iir.path !== 'founderLetter' && iir.path !== '_id');
                            // z.push({[this.props.id]:{...z}}); 
                            // z.push({id:this.props.id})
                            this.setState({ iirList })
                            this.getSateCount();
                            this.getExecutiveLetterStatus(executiveLetter)
                            temp.getCompanyUploadedFiles();
                        }
                    })
                    .catch(function (error) {
                        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                            window.localStorage.clear();
                            window.location.href = '/';
                            return;
                        } else { }
                    });

            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else { }
            });


    }
    getSateCount() {
        let stateCount = this.state.iirList && this.state.iirList.length > 0 ? this.state.iirList.filter(v => v.state).length : 0;
        this.setState({ startupCount: stateCount });
    }
    getExecutiveLetterStatus(arr) {
        const executiveStatus = arr && arr.length > 0 ? arr.filter(v => v.state).length : 0;
        this.setState({ startupExecutiveProgress: executiveStatus ? true : false });

    }

    removeFile(file) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this file",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                const token = localStorage.getItem('token');
                if (willDelete) {
                    //@TODO CALL DELETE CONTACT API HERE WITH NECESSARY CHECKS
                    this.setState({ loading: true })
                    axios.delete(`${common.path.base_url}/deleteFile`, { headers: { "Authorization": token }, data: { id: file._id } }
                    )
                        //@TODO CALL ON SUCCESS OF API
                        .then((response) => {
                            this.setState({ loading: false });
                            swal({
                                title: "Success!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            })
                                .then(() => {
                                    this.getCompanyUploadedFiles();
                                });

                        })
                        .catch((error) => {
                            this.setState({ loading: false });
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else {
                                swal({
                                    title: "Error!",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });
                }
                else {
                    swal({ text: `${file.documentName} file is safe!`, icon: 'info' });
                }
            });
    }
    getUserInvestments() {
        let temp = this;
        temp.setState({ loading: true });
        let id = window.localStorage.getItem('id');
        const url = new URL(window.location.href);
        let investorDashboardId = url.searchParams.get('investorId');
        if (investorDashboardId) {
            id = investorDashboardId;
        }
        const token = localStorage.getItem('token');
        axios.get(`${common.path.base_url}/getUserInvestments/${id}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                axios.get(`${common.path.base_url}/getUserInvestmentProcess/${id}`, {
                    headers: {
                        "Authorization": token
                    }
                })
                    .then((invitedUsers) => {
                        if (invitedUsers && invitedUsers.data && invitedUsers.data.data && invitedUsers.data.data.invitedInvestments &&
                            invitedUsers.data.data.invitedInvestments.length > 0) {
                            this.setState({
                                invitedInvestments: invitedUsers.data.data.invitedInvestments,
                                filterInvitedInvestments: invitedUsers.data.data.invitedInvestments,
                                investmentCompanies: invitedUsers.data.data.investmentCompanies,
                                currentInvestorTitle: invitedUsers.data.data.title,
                                currentInvestorPhone: invitedUsers.data.data.mobile,
                            });
                        }

                        if (response && response.data && response.data.data && response.data.data.length > 0) {
                            const currentInvestorName = response.data.data[0].name;
                            const { investments } = response.data.data[0];
                            const { companies } = response.data.data[0];
                            const { documents } = response.data.data[0];
                            if (documents && documents.length > 0) {
                                const document = [];
                                document.taxDocument = documents.filter(element => element.folderName === 'Tax Documents');
                                document.investmentDocument = documents.filter(element => element.folderName === 'Investment Documents');
                                document.myDocument = documents.filter(element => element.folderName === 'My Documents');
                                document.otherDocument = documents.filter(element => element.folderName === 'Other Documents');
                                this.setState({ investorDocuments: document })
                            }
                            if (investments && investments.length > 0) {
                                this.setState({
                                    investorInvestmentsForFilter: investments,
                                    investorInvestments: investments,
                                    investorCompanies: companies
                                })
                            }
                            const url = new URL(window.location.href);
                            let investorDashboardId = url.searchParams.get('investorId');
                            temp.setState({ loading: false, investorDashboardId, currentInvestorName });
                        }


                    })
                    .catch(error => {
                    })



            })
            .catch(function (error) {
                temp.setState({ loading: false });
            });
    }
    getProfileData() {
        let temp = this;
        temp.setState({ loading: true });
        const id = window.localStorage.getItem('id');
        axios.get(`${common.path.base_url}/getUserById`, {
            params: {
                _id: id
            }
        })
            .then((response) => {
                const userRoleCode = window.localStorage.getItem('roleCode');
                if (userRoleCode !== '005') {
                    window.localStorage.setItem('companyId', response.data.data.companyId && response.data.data.companyId._id);
                    window.localStorage.setItem('companyName', response.data.data.companyId && response.data.data.companyId.companyName);
                    window.localStorage.setItem('companyNickname', response.data.data.companyId && response.data.data.companyId.companyNickname);
                }
                if (userRoleCode === '003' || userRoleCode === '004') {
                    if (response.data.data.companyId && response.data.data.companyId.activeInvestmentId) {
                        temp.setState({ activeInvestmentId: response.data.data.companyId.activeInvestmentId });
                    }
                    // temp.getCompanyUploadedFiles(response.data.data.companyId.activeInvestmentId);
                    if (response.data.data.companyId && response.data.data.companyId._id) {
                        temp.getIIRList(response.data.data.companyId._id);
                        temp.getInvestmentDataForStartup(response.data.data.companyId._id);
                    }

                }
                temp.setState({ loading: false });
                temp.setState({ profileData: response.data ? response.data.data : [] });
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    toggleShowMoreCard(popupType, cardIndex) {
        let showMoreStateOpen = false;
        let menuStateOpen = false;
        let contactList = this.state.startupContactsUI;
        
        if (popupType === 1) {
            showMoreStateOpen = contactList[cardIndex].showMore;
            if (showMoreStateOpen) {
                showMoreStateOpen = false;
            } else {
                contactList.map((contact) => {
                    contact.showMore = false;
                    contact.showMenu = false;
                });
                showMoreStateOpen = true;
            }
        } else if (popupType === 2) {
            menuStateOpen = contactList[cardIndex].showMenu;
            if (menuStateOpen) {
                menuStateOpen = false;
            } else {
                contactList.map((contact) => {
                    contact.showMore = false;
                    contact.showMenu = false;
                });
                menuStateOpen = true;
            }
        }
        contactList[cardIndex].showMore = showMoreStateOpen;
        contactList[cardIndex].showMenu = menuStateOpen;
        this.setState({ startupContactsUI: contactList });
    }


    toggleShowMenuForDashboardCard(cardIndex) {
        var elems = document.querySelectorAll(".card-menu.show-menu");

        [].forEach.call(elems, function(el) {
            el.classList.remove("show-menu");
        });

        let menuStateOpen = false;
        // let dataList = this.state.dashboardData;
        // menuStateOpen = dataList[cardIndex].showMenu;
        
        const arrCard = []
        this.state.dashboardData.map((ele, i) => { 
            ele.companyName = this.capitalizeFirstLetter(ele.companyName)
            arrCard.push(ele);
        });
        let companyCards = arrCard.sort((a, b) => a.companyName.localeCompare(b.companyName));
        companyCards = companyCards.sort((a, b) => {
            let x = (a.investment.length > 0) ? a.investment[0].stage : "",
                y = (b.investment.length > 0) ? b.investment[0].stage : "";

            return x == y ? 0 : x < y ? 1 : -1;
        });
        //console.log(companyCards);
        let dataList = [...companyCards];
        menuStateOpen = dataList[cardIndex].showMenu; 
        
        if (menuStateOpen) {
            menuStateOpen = false;
        } else {
            dataList.map((data) => {
                data.showMenu = false;
            });
            menuStateOpen = true;
        }
        dataList[cardIndex].showMenu = menuStateOpen;

        //this.setState({ dashboardData: dataList });
    }
    toggleShowMenuForInvestmentsCard(investmentId) {
        let menuStateOpen = false;
        let dataList = this.state.investmentsData;
        
        const cardIndex = dataList.findIndex(investment => investment._id.toString() === investmentId.toString());
        if (cardIndex > -1) {
            menuStateOpen = dataList[cardIndex].showMenu;
            if (menuStateOpen) {
                menuStateOpen = false;
            } else {
                dataList.map((data) => {
                    data.showMenu = false;
                });
                menuStateOpen = true;
            }
            dataList[cardIndex].showMenu = menuStateOpen;

            this.setState({ investmentsData: dataList });

        }

    }
    toggleShowCompanyMenuForDashboardCard(cardIndex) {
        let menuStateOpen = false;
        let dataList = this.state.dashboardData;

        menuStateOpen = dataList[cardIndex].showCompanyMenu;
        if (menuStateOpen) {
            menuStateOpen = false;
        } else {
            dataList.map((data) => {
                data.showCompanyMenu = false;
            });
            menuStateOpen = true;
        }
        dataList[cardIndex].showCompanyMenu = menuStateOpen;

        this.setState({ dashboardData: dataList });
    }

    checkForActionIcon(user) {
        if (userRoleCode === '003') {
            return user._id === this.state.userId ? false : true;
        } else if (userRoleCode === '004') {
            if (user._id === this.state.userId) {
                return false;
            } else if (user.invitationFrom === this.state.userId) {
                return true;
            } else {
                return false;
            }
        }
    }

    renderStartupCards() {
        let cards = null;
        if (this.state.startupContactsUI && this.state.startupContactsUI.length > 0) {
            cards =
                <Accordion> {
                    this.state.startupContactsUI.map((user, i) => {
                        let isActionIcon = this.checkForActionIcon(user);
                        return (<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-list-contact contact-list-wraper">
                            <ContactCard
                                type={`1`}
                                imgSrc={`${common.path.base_url}/getImage?id=${user._id || ''}`}
                                contactData={user}
                                onCardClick={() => { }}
                                toggleShowMoreCard={(cardtype) => this.toggleShowMoreCard(cardtype, i)}
                                toggleShowCompanyMenuForDashboardCard
                                onActionClick={() => this.discardContact(i)}
                                admin={user.role === '003' ? true : false}
                                // actionIcon={user._id === this.state.userId ? false : true}
                                actionIcon={isActionIcon}
                                showMenu={this.state.roleCode <= user.role ? true : false}
                                canResend={user.invitationAccepted != "1" ? true : false}
                            />
                        </div>);
                    })
                } </Accordion>
        }
        return cards;
    }

    renderUploadedCards() {
        let cards = null;
        if (this.state.companyUploadedFiles && this.state.companyUploadedFiles.length > 0) {
            cards = this.state.companyUploadedFiles.map((file, i) => {
                return (<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-list-contact">
                    <UploadedFilesCard
                        onCardClick={() => { }}
                        file={file}
                        onActionClick={() => this.removeFile(file)}
                        userRoleCode={userRoleCode}
                    />
                </div>);
            })
        }
        return cards;
    }
    download(file) {
        this.getUserInvestments();
    }
    upload() {
        document.getElementById('uploadInvestorDocument-btn').click();
    }

    deleteInvestmentDoc(file) {

        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this file",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                const token = window.localStorage.getItem('token');
                const userId = window.localStorage.getItem('id');
                if (willDelete) {
                    this.setState({ loading: true })
                    axios.delete(`${common.path.base_url}/investmentDoc`, {
                        headers: { "Authorization": token },
                        data: {
                            id: file._id,
                            userId: userId
                        }
                    }
                    )
                        //@TODO CALL ON SUCCESS OF API
                        .then((response) => {
                            this.setState({ loading: false });
                            swal({
                                title: "Success!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            })
                                .then(() => {
                                    this.getUserInvestments();
                                    this.renderInvestorsCards();
                                });
                        })
                        .catch((error) => {
                            this.setState({ loading: false });
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else {
                                swal({
                                    title: "Error!",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });
                }
                else {
                    swal({ text: `${file.name} file is safe!`, icon: 'info' });
                }
            });
    }

    mapCompanyName(data) {
        const company = this.state.investorCompanies && this.state.investorCompanies.filter(company => company._id === data.companyId);
        if (company && company.length > 0) {
            return `${company[0].companyName} `;
        }
        return '';
    }
    mapInvitedCompanyName(id) {
        const company = this.state.investmentCompanies && this.state.investmentCompanies.filter(company => company._id === id);
        if (company && company.length > 0) {
            return `${company[0].companyName} `;
        }
        return '';
    }
    renderInvitedInvestorsCards() {
        return this.state.invitedInvestments &&
            this.state.invitedInvestments.length > 0 &&
            this.state.invitedInvestments.map((investment) => {
                return (
                    <ParticipateCard
                        invitedInvestment={investment}
                        companyName={this.mapInvitedCompanyName(investment.companyId)}
                        userInterest={(id, isParticipate) => this.userInterestHandler(id, isParticipate)}
                        title={this.state.currentInvestorTitle}
                        phoneNumber={this.state.currentInvestorPhone}
                        {...this.props}

                    />

                );
            });
    }

    renderInvestorsCards() {
        let cards = [];
        
        if (this.state.investorCompanies && this.state.investorInvestments && this.state.investorDocuments) {
            const investments = [];
            this.state.investorInvestments.forEach((investment) => {
                investment.companyName = this.mapCompanyName(investment);
                investments.push(investment);
            });
            const invite = investments.filter(obj => obj.stage === 'invite').sort((a, b) => a.companyName.localeCompare(b.companyName));
            const open = investments.filter(obj => obj.stage === 'open').sort((a, b) => a.companyName.localeCompare(b.companyName));
            const closed = investments.filter(obj => obj.stage === 'closed').sort((a, b) => a.companyName.localeCompare(b.companyName));
            const exited = investments.filter(obj => obj.stage === 'exited').sort((a, b) => a.companyName.localeCompare(b.companyName));

            cards = [...invite, ...open, ...closed, ...exited].map((file, i) => {
                return (< div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <InvestorCard
                        investmentData={file}
                        companyData={this.state.investorCompanies}
                        documents={this.state.investorDocuments}
                        downloadClickHnadler={() => this.download(file)}
                        uploadClickHnadler={() => { this.setState(this.uploadDocumentModalChild.setInvestmentData(file, () => this.upload())) }}
                        index={i}
                        onUpdateInvestmentClick={() => { this.setState({ loading: true }, this.investorInvestmentModalChild.setInvestment(file, () => this.openInvestorInvestmentModal())) }}
                        deleteInvestmentDoc={(docFile) => this.deleteInvestmentDoc(docFile)}
                        {...this.props}
                    />
                </div>);
            })

        }
        if (cards.length === 0 && !this.state.loading && this.state.invitedInvestments.length === 0) {
            cards = (< div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="data-not-found">You do not have any investment yet.</div>
            </div>);
        }
        return cards;
    }
    discardContact(index) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this contact",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                let newList = this.state.startupContactsUI;
                const deleteContact = newList[index];
                const token = localStorage.getItem('token');
                if (willDelete) {
                    //@TODO CALL DELETE CONTACT API HERE WITH NECESSARY CHECKS
                    this.setState({ loading: true })
                    axios.delete(`${common.path.base_url}/deleteUser`, { headers: { "Authorization": token }, data: { id: newList[index]._id } }
                    )
                        //@TODO CALL ON SUCCESS OF API
                        .then((response) => {
                            this.setState({ loading: false })
                            swal({
                                title: "Success!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            })
                                .then(() => {
                                    this.getContactList();
                                });
                            this.setState({ startupContactsUI: newList });

                        })
                        .catch((error) => {
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else if (error.response.data.code === 204) {
                                window.localStorage.clear();
                                window.location.href = '/';
                            }
                            swal({
                                title: "Error!",
                                text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                                icon: "error",
                                button: "Ok!",
                            });
                        });
                }
                else {
                    swal({ text: `${deleteContact.name}'s contact is safe!`, icon: 'info' });
                }
            });
    }


    getMatchArrObj(key, arr) {
        let result = [];
        let newArray = [];
        arr.forEach(user => {
            newArray.push({ name: user.name, emailAddress: user.emailAddress, title: user.title });
        });
        newArray.forEach((obj, index) => {
            let values = Object.values(obj);
            let found = false;
            values.forEach((ele) => {
                if (ele && ele.toString().includes(key)) {
                    found = true;
                }
            });
            if (found) { result.push(arr[index]) }
        });
        return result;
    }



    searchOnKeyChange = _.debounce((key) => {
        let filteredContacts = [];
        if (this.state.startupContacts.length > 0) {
            filteredContacts = this.getMatchArrObj(key, this.state.startupContacts);
        }
        this.setState({ startupContactsUI: filteredContacts })
    }, 500)

    checkIfNoContacts() {
        let dataFound = true;
        dataFound = false;
        if (this.state.startupContactsUI.length > 0) {
            dataFound = true;
        }
        return dataFound;
    }
    uploadInvestorDocHandler() {
        this.getUserInvestments();
    }
    addCompanySuccessHandler() {
        this.getDashboardData();
    }
    tabSelection(tabValue) {
        switch (tabValue) {
            case 'startup':
                this.setState({ showStartupCards: true, showInvestmentsCards: false });
                break;
            case 'investment':
                this.setState({ showInvestmentsCards: true, showStartupCards: false });
                break;
            default:
                this.setState({ showStartupCards: true, showInvestmentsCards: false });
                break;
        }
    }
    mapInvestmentsData( newdashboardData="" ) {
        
        let investments = [];
        if(this.state.showStartupCards) { 
            let dashboardData = (newdashboardData != "") ? newdashboardData : this.state.dashboardData;
            
            dashboardData.map((singleDashboardData) => {

                //console.log("---kkk---",singleDashboardData);
                singleDashboardData.investment.map((singleInvestment) => {
                    
                    singleInvestment.companyName = singleDashboardData.companyName;
                    singleInvestment.companyNickName = singleDashboardData.companyNickName;
                    investments.push(singleInvestment);
                    let iir = [];
                    let investmentprocesses = [];
                    let checklist = [];
                    let documents = [];
                    if (singleDashboardData.iir && singleDashboardData.iir.length > 0) {
                        iir = singleDashboardData.iir.filter(item => item.investmentId === singleInvestment._id);

                    }
                    if (singleDashboardData.investmentprocesses && singleDashboardData.investmentprocesses.length > 0) {
                        investmentprocesses = singleDashboardData.investmentprocesses.filter(item => item.investmentId === singleInvestment._id);

                    }
                    if (singleDashboardData.checklist && singleDashboardData.checklist.length > 0) {
                        checklist = singleDashboardData.checklist.filter(item => item.investmentId === singleInvestment._id);
                    }
                    if (singleDashboardData.documents && singleDashboardData.documents.length > 0) {
                        documents = singleDashboardData.documents.filter(item => {
                            if (item.investmentId === singleInvestment._id && item.documentType !== 'iirpdf') {
                                const userId = item.createdBy;
                                item.createdBy = { _id: userId, name: item.createdByName }
                                return item;
                            }
                        });
                    }
                    singleInvestment.investmentprocesses = investmentprocesses;
                    singleInvestment.iir = iir;
                    singleInvestment.checklist = checklist;
                    singleInvestment.documents = documents.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

                    return singleInvestment;
                });
                return singleDashboardData;
            });
            
            this.setState({ investmentsData: [] });
            this.setState({ investmentsData: investments });
            //console.log("sdddddd/....",investments);

            //, () => {
                // if (this.state.currentStage.value !== this.state.stages[0].value) {
                //     const filteredInvestmentData = this.state.investmentsData.filter(singleInvestment => singleInvestment.stage === this.state.currentStage.value);
                //     this.setState({ investmentsData: filteredInvestmentData });
                // }
            //});
        } else {
            
            let dashboardData = this.state.companyData;
            
            dashboardData.map((singleDashboardData) => {

                //console.log("---kkk---",singleDashboardData);
                singleDashboardData.investment.map((singleInvestment) => {
                    
                    singleInvestment.companyName = singleDashboardData.companyName;
                    singleInvestment.companyNickName = singleDashboardData.companyNickName;
                    investments.push(singleInvestment);
                    // singleInvestment.investmentprocesses = investmentprocesses;
                    // singleInvestment.iir = iir;
                    // singleInvestment.checklist = checklist;
                    // singleInvestment.documents = documents.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

                    return singleInvestment;
                });
                return singleDashboardData;
            });
            
            this.setState({ investmentsData: [] });
            this.setState({ investmentsData: investments });

        }    
    }
    renderInvesmentCards() {
        let investmentCards = null;
        if (this.state.investmentsData.length > 0) {
            investmentCards = this.state.investmentsData.sort((a, b) => {
                if (a.companyId && a.companyName && b.companyId && b.companyName) {
                    return a.companyName.localeCompare(b.companyName);
                }
            });

            const investmentSortCard = _.sortBy(investmentCards, (singleData) => _.indexOf(this.state.investmentOrder, singleData.stage.toLowerCase()));
            
            return investmentSortCard.map((singleInvestment, index) => {
                return (
                    <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4" key={index}>
                        <InvestmentCard
                            investmentName={singleInvestment.name}
                            companyName={singleInvestment.companyName ? singleInvestment.companyName : 'N/A'}
                            companyNickName={singleInvestment.companyNickName}
                            id={singleInvestment._id}
                            stage={singleInvestment.stage}
                            singleInvestment={singleInvestment}
                            // onEditInvestmentClick={() => { this.setState(this.investmentModalChild.setCompanyInvestment(this.getActiveInvestmentData(singleInvestment), singleInvestment._id), this.openInvestmentModal()) }}
                            onEditInvestmentClick={() => { this.setState(this.investmentModalChild.setInvestment(singleInvestment, () => this.openInvestmentModal())) }}
                            // onEditInvestmentClick={() => { this.setState(this.investmentModalChild.setCompanyInvestment(singleInvestment, () => this.openInvestmentModal())) }}
                            //  onEditInvestmentClick={() => { this.setState(this.investmentModalChild.setCompanyInvestment(this.getActiveInvestmentData(ele), ele._id), this.openInvestmentModal()) }}
                            toggleShowMenuForInvestmentsCard={() => { this.toggleShowMenuForInvestmentsCard(singleInvestment._id) }}
                            // filesListViaCompanyId={this.getDocumentsByCompanyId(singleInvestment.companyId, "files")}
                            filesListViaCompanyId={singleInvestment.documents}
                            investorList={this.getDocumentsByCompanyId(singleInvestment.companyId, "investorList")}
                            invitationSentCount={this.getInvitationCount(singleInvestment.investmentprocesses, singleInvestment._id)}
                            inviteAcceptedCount={this.getInviteAcceptedCount(singleInvestment.investmentprocesses, singleInvestment._id)}
                            index={index}
                            masterChecklist={this.state.masterChecklist}
                            {...this.props}
                        />

                    </div >
                )
            });
        }

        return investmentCards;
    }
    onInvestmentChange(loading) {
        this.setState({ loading });
    }

    getDocumentsByCompanyId(companyId, type) {
        if (!_.isEmpty(this.state.dashboardData)) {
            const singleCompanyData = this.state.dashboardData.find((singleCompany) => singleCompany._id === companyId);
            switch (type) {
                case "files":
                    return singleCompanyData.csvFiles;
                case "investorList":
                    return singleCompanyData && singleCompanyData.investers && singleCompanyData.investers;
                default:
                    break;
            }
        }
    }
    getInvestmentDataForStartup(companyId) {
        let temp = this;
        temp.setState({ loading: true });
        let token = window.localStorage.getItem('token');
        const id = companyId;
        axios.get(`${common.path.base_url}/investment/${id}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                // investment = response.data.data;
                this.setState({
                    investmentForStartup: response.data.data[0],
                })
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    addTeamMember() {
        document.getElementById('add-team-btn').click();
        this.setState({ isAddTeamMemberDone: true })
        window.localStorage.setItem('isAddTeamMemberDone', true);
    }

    addDocuments() {
        document.getElementById('progress-btn').click();
    }

    uploadContact() {
        document.querySelector('#directUpload').click();
    }
    directUpload() {
        const file = document.querySelector('#directUpload').files[0];
        this.uploadCSVFile(file);
        this.setState({ isUploadDone: true })
        window.localStorage.setItem('isUploadDone', true);
    }

    progressbarDocAddClick(data) {
        if (data.value) {
            const companyId = window.localStorage.getItem('companyId');
            const investmentId = this.state.selectedInvestmentDocuments && this.state.selectedInvestmentDocuments.value;
            if (!companyId || !investmentId) return;
            if (data.value == 'Executive Letter') {
                this.props.history.push(`/foundLetterPreview?companyId=${companyId}&investmentId=${investmentId}`);
                // this.props.history.push(`foundLetterPreview?companyId=${this.state.profileData.companyId !== undefined ? this.state.profileData.companyId._id : ''}&investmentId=${this.state.profileData.companyId.activeInvestmentId}`);
                // <a className="executive-letter-link" target="_blank" href={`${common.path.base_url}/foundLetterPreview?companyId=${this.state.profileData.companyId !== undefined ? this.state.profileData.companyId._id : ''}&investmentId=${this.state.profileData.companyId.activeInvestmentId}`}> </a>
            } else if (data.value == 'IIR') {
                if (companyId && investmentId) {
                    this.props.history.push(`/iirPreviewPDF?companyId=${companyId}&investmentId=${investmentId}`);
                }
            } else if (data.value == 'startupFAQ') {
                this.props.history.push('/faqPreview?faqPreview=true');
            } else if (data.value == 'Engagement Agreement') {
                this.props.history.push(`/startUpEngagement?companyId=${companyId}&investmentId=${investmentId}`);
            } else if (data.value == 'NDA') {
                this.props.history.push(`/ndaPdf?companyId=${companyId}&investmentId=${investmentId}`);
            }
        }
        this.setState({ isDocumentCheck: true })
        window.localStorage.setItem('isDocumentCheck', true);
    }
    companySearchOnKeyChange = _.debounce((searchText) => {
        this.searchMapData(searchText);

    }, 500);

    searchMapData(searchText) {
        this.setState({
            searchText
        });
        // this.state.dashboardData.sort((a, b) => a.companyName.localeCompare(b.companyName));
        
        if( this.state.showStartupCards ) {
            if (!searchText) {

                const startupStageChanged = this.state.startupStageChanged ;
                this.StartupStageChanged(startupStageChanged);
                //this.setState({ dashboardData: this.state.dashboardData });
            };
            searchText = searchText.toLowerCase();
            let result = [];
            const dashboardData = [...this.state.dashboardData];
            dashboardData.forEach((obj) => {
                if (obj.companyName && obj.companyName.toLowerCase().includes(searchText)) {
                    result.push(obj);
                }
            });
            this.setState({ dashboardData: result });
            //this.mapInvestmentsData();
        } else {
            
            if (!searchText) {

                //const dashboardData = [...this.state.companyData ];
                this.mapInvestmentsData()


            } else {
                searchText = searchText.toLowerCase();
                let result = [];
                const dashboardData = (!searchText) ? [...this.state.companyData ] : [...this.state.investmentsData ];
                dashboardData.forEach((obj) => {
                    if (obj.companyName && obj.companyName.toLowerCase().includes(searchText)) {
                        result.push(obj);
                    }
                });
                this.setState({ investmentsData: result });
                //this.mapInvestmentsData();
            }
        }
    }

    userInterestHandler(id, isParticipate) {
        if (!isParticipate) {
            swal({
                title: "Are you sure?",
                text: "You are not interested to participate in this investment.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(action => {
                    if (action) {
                        let temp = this;
                        temp.setState({ loading: true });
                        const token = localStorage.getItem('token');
                        axios.post(`${common.path.base_url}/userInterest/${id}`, { isParticipate }, {
                            headers: {
                                "Authorization": token
                            }
                        })
                            .then((response) => {
                                temp.getUserInvestments();

                            })
                            .catch(function (error) {
                                temp.setState({ loading: false });
                            });
                    }
                })

        } else {
            let temp = this;
            temp.setState({ loading: true });
            const token = localStorage.getItem('token');
            axios.post(`${common.path.base_url}/userInterest/${id}`, { isParticipate }, {
                headers: {
                    "Authorization": token
                }
            })
                .then((response) => {
                    temp.getUserInvestments();

                })
                .catch(function (error) {
                    temp.setState({ loading: false });
                });
        }
    }
    importContactsHandler(data) {
        this.setState(this.successImportContactsModalChild.setData(data, () => {
            document.getElementById('success-import-contacts').click()
        }));
        this.getDashboardData();

    }

    handleOnStageChanged(event) {
        const currentStage = this.state.stages.find(singleStage => singleStage.value === event.target.value);
        this.setState({ currentStage }, () =>
            this.mapInvestmentsData(this.state.currentStage.value)
        );
    }

    async handleStartupStageChanged(event) {
        let currentStage = this.state.startupStages.find(singleStage => singleStage.value === event.target.value);
        let valueStage = currentStage.value; 
        //let singleInvestmentStagesChanged = currentStage 
        this.setState({ singleStartupStageChanged: currentStage });
        this.StartupStageChanged(valueStage);
    }

    async StartupStageChanged(codeStage) { 
        
        let codeCheck = (codeStage == 'exit_in_process') ? 'exit in process' : ( codeStage == 'exited' ? 'exited' : ( codeStage == 'open' ? 'open' : 'all' ) );
        this.setState({ startupStageChanged: codeCheck });

        let result = [];
        const dashboardData = [...this.state.companyData];
        //const getInvestmentStage = this.state.investmentStagesChanged;
        
        await dashboardData.forEach((obj) => {

            const investment = obj.investment;
            if(investment.length > 0) {
                
                if( codeCheck == 'all' ) {
                    //obj.investment = filterData
                    result.push(obj);
                
                } else if( codeCheck != 'open' ) {

                    const filterData = investment.filter(item => (item.stage === codeCheck ));
                    if(filterData.length > 0 ) {
                        
                        obj.investment = filterData
                        result.push(obj);
                    }
                } else {

                    const filterData = investment.filter(item => ( item.stage === 'open' || item.stage === 'invite' || item.stage === 'closed' ));
                    
                    if(filterData.length > 0 ) {
                        
                        obj.investment = filterData
                        result.push(obj);
                    }

                    // } else {
                    //     const filterData = investment.filter(item => (item.stage === getInvestmentStage ));
                    //     if(filterData.length > 0 ) {
                            
                    //         obj.investment = filterData
                    //         result.push(obj);
                    //     }
                    // }
                }    
            } else {

                if( codeCheck == 'all' ) {
                    result.push(obj);
                } else if( codeCheck == 'open' ) {
                    result.push(obj);
                }
            }
        });

        //console.log("ssdsdsdsdsdsdsdsd",result)
        this.setState({ dashboardData: result });
        //await this.mapInvestmentsData( result );
    }

    // async handleInvestmentStageChanged(event) {
    //     const currentStage = this.state.investmentStages.find(singleStage => singleStage.value === event.target.value);
    //     let codeCheck = currentStage.value;
    //     this.setState({ singleInvestmentStagesChanged: currentStage });
    //     this.InvestmentStageChanged(codeCheck);
    // }

    // async InvestmentStageChanged(codeStage) {
    //     let codeCheck = codeStage;
        
    //     this.setState({ investmentStagesChanged: codeCheck });
    //     let result = [];
    //     const dashboardData = [...this.state.companyData];
    //     await dashboardData.forEach((obj) => {
            
    //         const investment = obj.investment;
    //         if(investment.length > 0) {
                
    //             if(codeCheck == 'all') {
    //                 const filterData = investment.filter(item => ( item.stage === 'open' || item.stage === 'invite' || item.stage === 'closed' ));
    //                 if(filterData.length > 0 ) {
                        
    //                     obj.investment = filterData
    //                     result.push(obj);
    //                 }
    //             } else {
    //                 const filterData = investment.filter(item => ( item.stage === codeCheck ));
    //                 if(filterData.length > 0 ) {
                        
    //                     obj.investment = filterData
    //                     result.push(obj);
    //                 }
    //             }
    //         }
    //     });

    //     this.setState({ dashboardData: result });
    //     //await this.mapInvestmentsData( result );
    // }

    render() {
        const companyName = window.localStorage.getItem('companyName');
        const companyNickname = window.localStorage.getItem('companyNickname');
        return (
            <div>
                {this.state.loading ? <LoadingSpinner /> : ""}
                <Header
                    username={this.state.name}
                    role={this.state.Role}
                />
                {
                    (userRoleCode === '001' || userRoleCode === '002') &&
                    <HeaderImage
                        heading={this.state.currentInvestorName ? this.state.currentInvestorName : this.state.Role}
                    />
                }
                {(userRoleCode === '003' || userRoleCode === '004') &&
                    <HeaderImage
                        heading={`${companyNickname != "null" && companyNickname ? companyNickname : companyName != "null" && companyName ? companyName : ''}`}
                    />
                }
                {userRoleCode === '005' &&
                    <HeaderImage
                        heading={`Investor Dashboard`}
                    />
                }
                <ImportContactsModal
                    onRef={(ref) => this.importContactsModalChild = ref}
                    onImportContactsCallback={(data) => this.importContactsHandler(data)}
                />
                <SuccessImportContactsModal
                    onRef={(ref) => this.successImportContactsModalChild = ref}

                />

                <CustomModal
                    onRef={(ref) => {
                        this.customModalChild = ref
                    }

                    }
                    onAddCompanySuccessCallback={() => this.addCompanySuccessHandler()}
                />
                <UploadDocumentModal
                    onRef={(ref) => this.uploadDocumentModalChild = ref}
                    onUploadInvestorDocClick={() => this.uploadInvestorDocHandler()}
                />
                {/* <UploadInvestorDocumentModal
                    onRef={(ref) => this.uploadDocumentModalChild = ref}
                /> */}
                <InvestmentModal
                    onRef={(ref) => this.investmentModalChild = ref}
                    addUpdateInvestmentModalCallback={(param) => this.addUpdateInvestmentModalCallback(param)}
                />
                <InvestorInvestmentModal
                    onRef={(ref) => this.investorInvestmentModalChild = ref}
                    addUpdateInvestorInvestmentModalCallback={() => this.addUpdateInvestorInvestmentModalCallback()}
                />
                <ProgressbarModal
                    onRef={(ref) => this.progressbarModalChild = ref}
                    onProgressbarDocAddClick={(param) => this.progressbarDocAddClick(param)}
                />
                {/* Dummy Hidden Button just to trigger invite open */}
                <TButton onClick={() => { }} id='inviteOpen-btn' dataTarget={`#inviteModal`} dataToggle={`modal`} style={{ display: 'none' }} />
                <TButton onClick={() => { }} id='uploadDocument-btn' dataTarget={`#uploadDocumentModal`} dataToggle={`modal`} style={{ display: 'none' }} />
                <TButton onClick={() => { }} id='investmentOpen-btn' dataTarget={`#addInvestmentModal`} dataToggle={`modal`} style={{ display: 'none' }} />
                <TButton onClick={() => { }} id='uploadInvestorDocument-btn' dataTarget={`#uploadInvestorDocModal`} dataToggle={`modal`} style={{ display: 'none' }} />
                <TButton onClick={() => { }} id='updateInvestment-btn' dataTarget={`#updateInvestmentModal`} dataToggle={`modal`} style={{ display: 'none' }} />
                <TButton onClick={() => { }} id='editCompany-btn' dataTarget={`#addCompanyModal`} dataToggle={`modal`} style={{ display: 'none' }} />
                <TButton onClick={() => { this.progressbarModalChild.resetProgressbarData() }} id='progress-btn' dataTarget={`#progressbarDocAdd`} dataToggle={`modal`} style={{ display: 'none' }} />
                <TButton onClick={() => { }} id='upload-contacts' dataTarget={`#importContactsModal`} dataToggle={`modal`} style={{ display: 'none' }} />
                <TButton onClick={() => { }} id='success-import-contacts' dataTarget={`#successImportContactsModal`} dataToggle={`modal`} style={{ display: 'none' }} />

                {/* {this.state.profileData.companyId.activeInvestmentId && <a id="executive-letter-link" target="_blank" href={`${common.path.base_url}/foundLetterPreview?companyId=${this.state.profileData.companyId !== undefined ? this.state.profileData.companyId._id : ''}&investmentId=${this.state.profileData.companyId.activeInvestmentId}`}> </a>} */}
                {/* <a id="IIR-link" target="_blank" href={`${common.path.base_url}/preview?companyId=${this.state.profileData.companyId !== undefined ? this.state.profileData.companyId._id : ''}&investmentId=${this.state.profileData.companyId ? this.state.profileData.companyId.activeInvestmentId : ''}`}> </a>
                <a id="startupFAQ-link" target="_blank" href={`${common.path.base_url}/faqPreview?faqPreview=true`}> </a>
                <a id="engagement-agreement-link" target="_blank" href={`${common.path.base_url}/startUpEngagement?companyId=${this.state.profileData.companyId !== undefined ? this.state.profileData.companyId._id : ''}&investmentId=${this.state.profileData.companyId ? this.state.profileData.companyId.activeInvestmentId : '' }`}> </a>
                <a id="NDA-link" target="_blank" href={`${common.path.base_url}/ndaPdf?companyId=${this.state.profileData.companyId !== undefined ? this.state.profileData.companyId._id : ''}&investmentId=${this.state.profileData.companyId ? this.state.profileData.companyId.activeInvestmentId : ''}`}> </a> */}

                {/*------ Page Header Section START------*/}
                <div className="main-container-cls">
                    {/*------ Company Cards Section END------*/}

                    {/* ONLY FOR STARTUP/COMPANY */}
                    {
                        !this.investorDashboardUserId ?
                            <div>
                                <div className="header-section wrapper dashboad-header-section">
                                    <div className="header-txt">{`Dashboard`}</div>
                                    {this.renderHeaderBtn()}
                                </div>
                                <div className="hr-line wrapper"></div>
                                {/*------ Page Header Section END------*/}

                                {/* ONLY FOR THE SYNDICATE GROUP */}
                                {/*------ Company Cards Section START------*/}
                                {userRoleCode === '001' || userRoleCode === '002' ? (
                                    <div className='contact-main tsg-dashboard'>
                                        <div className='contact-header'>
                                            <nav className="contact-navbar ">
                                                <button className={`navbtn ${this.state.showStartupCards ? 'active' : ''}`} onClick={() => this.tabSelection('startup')}>STARTUP</button>
                                                <button className={`navbtn ${this.state.showInvestmentsCards ? 'active' : ''}`} onClick={() => this.tabSelection('investment')}>INVESTMENT</button>

                                            </nav>
                                        </div>
                                        <div className='search-section row'>
                                            {
                                                // this.state.showInvestmentsCards &&
                                                // <div className="search col-xs-12 col-sm-4">
                                                //     <select className='status-box' onChange={(event) => this.handleOnStageChanged(event)} value={this.state.currentStage.value}>
                                                //         {
                                                //             this.state.stages.map((singleOption, index) => {
                                                //                 return <option value={singleOption.value} key={index}>
                                                //                     {singleOption.label} 
                                                //                 </option>;
                                                //             })
                                                //         }
                                                //     </select>
                                                // </div>
                                            }
                                            <div className={this.state.showInvestmentsCards ? 'search col-md-10 col-xs-12 col-sm-8' : 'search col-md-10 col-xs-12 col-sm-8'}>
                                                <input className='search-box' type='text' placeholder='Search' onChange={(e) => this.companySearchOnKeyChange(e.target.value)} defaultValue={this.state.searchText} />
                                            </div>

                                            {
                                                !this.state.showInvestmentsCards &&
                                                <>
                                                    <div className={this.state.showInvestmentsCards ? 'search col-md-2 col-xs-12 col-sm-4' : 'search col-xs-12 col-md-2 col-sm-4'}>
                                                        <select className='status-box' onChange={(event) => this.handleStartupStageChanged(event)} value={ this.state.singleStartupStageChanged.value } > 
                                                            {
                                                                this.state.startupStages.map((option, index) => {
                                                                    return <option value={option.value} key={index}>
                                                                        {option.label}
                                                                    </option>;
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    {/* <div className={this.state.showInvestmentsCards ? 'search col-xs-4 col-sm-4' : 'search col-xs-2'}>
                                                        <select className='status-box' onChange={(event) => this.handleInvestmentStageChanged(event)} disabled = { this.state.startupStageChanged =='open' ? false : true } value={ this.state.singleInvestmentStagesChanged.value } >
                                                            {
                                                                this.state.investmentStages.map((option, index) => {
                                                                    return <option value={option.value} key={index}>
                                                                        {option.label}
                                                                    </option>;
                                                                })
                                                            }
                                                        </select>
                                                    </div> */}
                                                </>
                                            }        
                                        </div>

                                        <div className="company-section wrapper">
                                            {
                                                this.state.showStartupCards &&
                                                <div className="row company-section-row">
                                                    {this.renderCompanyCards()}
                                                </div>
                                            }
                                            {
                                                this.state.showInvestmentsCards &&
                                                <div className="row company-section-row">
                                                    {this.renderInvesmentCards()}
                                                </div>
                                            }
                                        </div>
                                    </div>) : null}
                                {(userRoleCode === '003' || userRoleCode === '004') ? (
                                    <div>
                                        <AddStartupContactModal
                                            selectedInvestment={this.state.selectedInvestment}
                                            getCompanyUploadedFiles={(investmentId) => this.getCompanyUploadedFiles(investmentId)}
                                        />
                                        {this.state.isDocumentLoaded &&
                                            <div className="startup-section wrapper fluid-container">

                                                <ul id="progressbar" className={this.state.showProgressBar ? '' : 'closeProgressBar'}>
                                                    <div className="cancel-btn-wraper">
                                                        <button type="button" className="close" aria-label="Close" onClick={() => {
                                                            this.setState({ showProgressBar: false });
                                                            window.localStorage.removeItem('isUploadDone');
                                                            window.localStorage.removeItem('isDocumentCheck');
                                                            window.localStorage.removeItem('isAddTeamMemberDone');
                                                        }
                                                        }>
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <li className={this.state.isAddTeamMemberDone ? "active startup-progress" : 'startup-progress'} id="account" onClick={() => this.addTeamMember()}><strong>Create Team</strong></li>
                                                    <li className={this.state.isUploadDone ? "active startup-progress" : 'startup-progress'} id="personal" onClick={() => this.uploadContact()}><strong>Upload Contacts</strong></li>
                                                    <input
                                                        id='directUpload'
                                                        onChange={() => this.directUpload()}
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                    />
                                                    <li className={this.state.isDocumentCheck ? "active" : ''} id="payment" onClick={() => { this.addDocuments() }}><strong> Collaborative Documents</strong></li>
                                                </ul>

                                                <div className="row">

                                                    {/*------ Contact List Block START------*/}
                                                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                                        <div className="contact-list-section custom-list-team">
                                                            <div className="header-txt invite-btn">
                                                                {window.localStorage.getItem('companyNickname') || window.localStorage.getItem('companyName')} Team
                                                    <TButton id="add-team-btn" onClick={() => this.customModalChild.resetInviteData()} text={`ADD TEAM MEMBER`} dataTarget={`#inviteModal`} dataToggle={`modal`} className='invite-btn' />
                                                            </div>

                                                            {/* Search Block */}
                                                            <div className="search-block">
                                                                <div className="search-container">
                                                                    <div className="search-section">
                                                                        <input className="search-input" type="text" placeholder="Search" onChange={(e) => this.searchOnKeyChange(e.target.value)} ></input>
                                                                        <img className="search-icon" src={images.path.searchIcon} />
                                                                    </div>
                                                                </div>
                                                                {!this.checkIfNoContacts() ? <div className="no-result-found">
                                                                    {`No Results to Show`}
                                                                </div> : null}
                                                                {/* Contact List Cards */}
                                                            </div>
                                                            <div className={`row contact-list-block custom-contact-list-section ${userRoleCode === '001' || userRoleCode === '003' ? 'admin' : ''}`}>
                                                                {this.state.isDocumentLoaded && this.renderStartupCards()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/*------ Contact List Block END------*/}

                                                    {/*------ Upload File Block START------*/}
                                                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 user-upload-section">
                                                        <div className="upload-section">
                                                            <div className="header-txt">Upload Strategic Contacts</div>
                                                            <div className="startupInvestment-upload">
                                                                {this.state.selectedInvestment && this.state.selectedInvestment.label}
                                                                {/* <Select
                                                                    id='startupInvestment'
                                                                    options={this.state.startUpInvestments}
                                                                    value={this.state.selectedInvestment}
                                                                    onChange={(event) => {
                                                                        this.setState({ selectedInvestment: event })
                                                                        this.getCompanyUploadedFiles(event.value)
                                                                    }
                                                                    }
                                                                /> */}
                                                            </div>
                                                            <div className="upload-block">
                                                                <div className="choose-file" onClick={() => document.querySelector('#chooseFile').click()}>{(this.state.choosenFile && this.state.choosenFile.name) || `Select File`}</div>
                                                                <FileUploadBlobDoc getFile={(file) => this.setState({ choosenFile: file })} />
                                                                <TButton id="upload-doc-btn" className="upload-file" disabled={this.state.choosenFile === null ? true : false} onClick={() => this.uploadCSVFile(this.state.choosenFile)} text={`UPLOAD MY LIST`} />
                                                                <div className="vertical-line">
                                                                </div>
                                                                <TButton
                                                                    id="upload-contact-btn"
                                                                    className="upload-file"
                                                                    text="UPLOAD CONTACT"
                                                                    dataTarget="#addStartupContactModal"
                                                                    dataToggle="modal"
                                                                    onClick={() => true}
                                                                />
                                                            </div>
                                                            <div className={`row contact-list-block upload-contact-list `}>
                                                                {this.state.isDocumentLoaded && this.renderUploadedCards()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/*------ Upload File Block END------*/}

                                                    {/*------ User Profile Section START------*/}
                                                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 profile-section-wraper profile-button">
                                                        <div className="profile-section-inner">
                                                            {/* <MyProfileForm profileData={this.state.profileData} onEditClick={() => this.props.history.push('/profile')} {...this.props}/>                                 */}
                                                            {
                                                                this.state.isDocumentLoaded &&
                                                                <Documents profileData={this.state.profileData}
                                                                    iirCount={this.state.startupCount}
                                                                    executiveProgress={this.state.startupExecutiveProgress}
                                                                    userRoleCode={userRoleCode}
                                                                    startUpInvestments={this.state.startUpInvestments}
                                                                    selectedInvestmentDocuments={this.state.selectedInvestmentDocuments}
                                                                    onInvestmentChange={(type) => { this.onInvestmentChange(type) }}
                                                                    investments={this.state.investmentForStartup}
                                                                    {...this.props} />
                                                            }
                                                        </div>
                                                    </div>
                                                    {/*------ User Profile Section END------*/}

                                                </div>
                                            </div>}
                                    </div>) : null}

                                {userRoleCode === '005' ? (
                                    <div className="company-section wrapper">
                                        <div className="row company-section-row">
                                            {
                                                this.renderInvitedInvestorsCards()
                                            }
                                            {
                                                this.renderInvestorsCards()
                                            }
                                        </ div>

                                    </div>
                                ) : null}
                            </div> 
                        :
                            <div>
                                {
                                    this.investorDashboardUserId &&
                                    <div className="company-section wrapper">
                                        <div className="row company-section-row">
                                            {this.renderInvestorsCards()}
                                        </div>
                                    </div>
                                }
                            </div>
                    }
                </div>
            </div >
        )
    }
}
export default Dashboard;
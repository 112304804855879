import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import axios from 'axios';
import images from "../../images";
import common from '../../common';
import swal from 'sweetalert';
import DatePicker from "react-datepicker";
import './pipelineCss/pipeline.scss';
import './pipelineCss/default.scss';
import General from './General';
import StageMaturity from './StageMaturity';
import TeamBoardInvestor from './TeamBoardInvestor';
import TsgOpportunity from './TsgOpportunity';
import Notes from './Notes';
import Tasks from './Tasks';
import Sentiments from './Sentiments';
import { Icons } from './Icons';
import NumberFormat from 'react-number-format';
import activeListIcon from '../../images/active-list-icon.svg';
import deleteNew from '../../images/delete-new.svg';
import eyeIcon from '../../images/eyeIcon.svg'; 
import eyeIconHide from '../../images/eyeIconHide.svg'; 

const NewAddPipeline = (props) => {

    const companyParams = (props && props.match && props.match.params) ? props.match.params : "";
    const cid = (companyParams != "") ? companyParams.cid : "";
    
    const [listData, setListData] = useState([]); 
    const [compName, setCompName] = useState('');
    const [fullName, setFullName] = useState('');
    const [companyDispaly, setCompanyDispaly] = useState(true);
    const [companyId, setCompanyId] = useState('');
    const [companyData, setCompanyData] = useState('');
    const [sentiments, setSentiments] = useState({
        'pipelineStartupInterest' : 0,
        'strongSentiments' : 0,
        'totalInvestors': 0,
        'userRecommendation': 0 
    });
    
    const [generalCall, setGeneralCall] = useState(false);
    
    const [notesArr, setNotesArr] = useState([]);
    const [notesDate, setNotesDate] = useState('');
    const [notesEvent, setNotesEvent] = useState('');
    const [notesBody, setNotesBody] = useState('');
    const [notesTitle, setNotesTitle] = useState('');
    
    const [taskArr, setTaskArr] = useState([]);
    const [taskDate, setTaskDate] = useState('');
    const [taskAssigned, setTaskAssigned] = useState('');
    const [taskBody, setTaskBody] = useState('');
    const [taskTitle, setTaskTitle] = useState('');

    const [taskNoteIndex, setTaskNoteIndex] = useState('');
    const [taskNoteAction, setTaskNoteAction] = useState('');

    const [ revenueTitle, setRevenueTitle ] = useState('');
    const [ revenueYear, setRevenueYear ] = useState('');
    const [ revenueVal, setRevenueVal ] = useState([]);
    const [ revenueNotes, setRevenueNotes ] = useState('');
    const [ modalError, setModalError ] = useState(false);
    const [ revenueType, setRevenueType ] = useState('');
    const [ revenueLocalVal, setRevenueLocalVal ] = useState([]);

    const setUpdateCompanyId = (id) => {

        if(id != "" && id != null && id != undefined) {
            setCompanyId(id);
            setCompanyDispaly(false);
        }
    }

    function scrollToTargetAdjusted(event, li_id) {
        for (const li of document.querySelectorAll("li div.activeTab")) {
            li.classList.remove("activeTab");
        }
        event.currentTarget.classList.add("activeTab");

        var element = document.getElementById(li_id);
        var headerOffset = 210;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }

    window.addEventListener('scroll', () => {
        
        let windowTop = document.documentElement.scrollTop;
        windowTop = windowTop + 65;
        //const generalTab = document.querySelector('#generalTab').offsetTop;
        const stagTab = document.querySelector('#stagematurityTab').offsetTop;
        const teamTab = document.querySelector('#teamTab').offsetTop;
        const opportunityTab = document.querySelector('#opportunityTab').offsetTop;
        const noteTab = document.querySelector('#noteTab').offsetTop;
        const taskTab = document.querySelector('#taskTab').offsetTop;
        
        if(windowTop >= taskTab) {

            for (const li of document.querySelectorAll("li div.activeTab")) {
                li.classList.remove("activeTab");
            } 
            document.getElementById('taskdiv-tab').classList.add("activeTab");
        
        } else if(windowTop >= noteTab) {

            for (const li of document.querySelectorAll("li div.activeTab")) {
                li.classList.remove("activeTab");
            } 
            document.getElementById('notediv-tab').classList.add("activeTab");
        
        } else if(windowTop >= opportunityTab) {

            for (const li of document.querySelectorAll("li div.activeTab")) {
                li.classList.remove("activeTab");
            } 
            document.getElementById('oppdiv-tab').classList.add("activeTab");
        
        } else if(windowTop >= teamTab) {
            
            for (const li of document.querySelectorAll("li div.activeTab")) {
                li.classList.remove("activeTab");
            } 
            document.getElementById('tediv-tab').classList.add("activeTab");
        
        } else if(windowTop >= stagTab) {

            for (const li of document.querySelectorAll("li div.activeTab")) {
                li.classList.remove("activeTab");
            } 
            document.getElementById('stdiv-tab').classList.add("activeTab");
        
        } else {

            for (const li of document.querySelectorAll("li div.activeTab")) {
                li.classList.remove("activeTab");
            }
            document.getElementById('gdiv-tab').classList.add("activeTab");    
        }
    });

    useEffect(() => { 
        
        if( cid !='' && cid != undefined && cid != null ) {

            let token = window.localStorage.getItem('token');
            setCompanyId(cid);
            axios.get(`${common.path.base_url}/getPiplineCompany/${cid}`, {
                headers: {
                    "Authorization": token
                }
            })
            .then((response) => {
                
                const result = response.data;
                if( result.status == "success" ) {
                    const data = result.data;
                    setCompanyData(data.result);
                    setCompanyDispaly(false);

                    setSentiments({
                        'pipelineStartupInterest' : data.pipelineStartupInterest,
                        'strongSentiments' : data.strongSentiments,
                        'totalInvestors': data.totalInvestors,
                        'userRecommendation': data.userRecommendation,
                        'recommendationUser' : (data.recommendationUser) ? data.recommendationUser : null 
                    })
                } else {
                    swal({
                        title: "Error! #33456",
                        text: result.message ? result.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else {
                    swal({
                        title: "Error! #33005",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
        }
        
    }, [cid])

    useEffect(() => {
        
        let token = window.localStorage.getItem('token');
        
        if( generalCall ) {
            if( (  companyId == "" || companyId == null || companyId == undefined ) ){

                swal({
                    title: 'Please enter company name',
                    icon: 'error',
                    confirmButtonText: 'Yes, delete it!',
                    reverseButtons: true,
                });

            } else {

                const timer = setTimeout(() => {

                    let dataInfo = new FormData();
                    const data = {
                        'companyId' : companyId,
                        'notes' : notesArr,
                        'tasks' : taskArr,
                    }
                    dataInfo.append('data', JSON.stringify(data));

                    axios.post(`${common.path.base_url}/addNewPipline`, dataInfo, {
                        headers: {
                            "Authorization": token
                        }
                    })
                    .then((response) => {
                        const savedData = response.data.data;
                    })
                    .catch((error) => {
                        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        
                            window.localStorage.clear();
                            window.location.href = '/';
                            return;
                        } else {
                            swal({
                                title: "Error! #33005",
                                text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                icon: "error",
                                button: "Ok!",
                            });
                        }
                    });

                }, 1500);
                return () => clearTimeout(timer);
            }
        }
    
    }, [companyId, generalCall, notesArr, taskArr]);

    useEffect(() => { 
        if( companyData != "" && companyData != undefined && companyData != null) {
            setNotesArr(companyData.notes);
            setTaskArr(companyData.tasks);
        }
    },[ companyData ]);

    const notesSave = (typeCode) => {

        setGeneralCall(true);
        if( typeCode == 'notes' ) {
        
            const noteNewArr = {
                'date' : notesDate,
                'event' : notesEvent,
                'notes' : notesBody
            }
            setNotesArr([...notesArr, noteNewArr]);
            document.getElementById('notesCancel').click();
            popReset('notes');

        } else if( typeCode == 'task' ) {
            
            const taskNewArr = {
                'date' : taskDate,
                'assigned' : taskAssigned,
                'task' : taskBody
            }
            setTaskArr([...taskArr, taskNewArr]);
            document.getElementById('taskCancel').click();
            popReset('task');
        }
    }

    const popReset = (code) => {
        if(modalError){
            setModalError(false);
        }

        if(code == 'notes') {
        
            setNotesEvent('');
            setNotesDate('');
            setNotesBody('');
        
        } else if(code == 'task') {
            
            setTaskAssigned('');
            setTaskDate('');
            setTaskBody('');
        }
    }

    const deleteHandle = (index, codeType) => {
        
        swal({
            title: 'Are you sure you want to delete?',
            text: "This item will be delete immediately. You can't undo this action.",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#7A8DA7',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true,
        }).then((resp) => {
            if(resp) {

                setGeneralCall(true);
                if(codeType == 'notes') {

                    notesArr.splice(index, 1);
                    setNotesArr([...notesArr]);
                
                } else  if(codeType == 'task') {
                    
                    taskArr.splice(index, 1); 
                    setTaskArr([...taskArr]);
                }
            }
        }) 
    }

    const editHandle = (index, codeType) => {
        
        setTaskNoteAction('edit');
        setTaskNoteIndex(index);
        if(codeType == 'notes') {

            setNotesTitle("Update Notes");
            const editArr = notesArr[index];
            console.log(editArr);
            setNotesDate((editArr.date != null) ? new Date(editArr.date) : null );
            setNotesEvent(editArr.event);
            setNotesBody(editArr.notes);
            
            document.getElementById('editNoteModalbtn').click();
        
        } else if(codeType == 'task') {

            setTaskTitle("Update Task");
            const editArr = taskArr[index];
            setTaskDate((editArr.date != null) ? new Date(editArr.date) : null );
            setTaskAssigned(editArr.assigned);
            setTaskBody(editArr.task);
            
            document.getElementById('editTaskModalbtn').click();
        }
    }

    const updateTaskAndNote = (codeType) => {
        
        setGeneralCall(true);
        
        if(codeType == 'notes') {

            notesArr[taskNoteIndex].date = notesDate;
            notesArr[taskNoteIndex].event = notesEvent;
            notesArr[taskNoteIndex].notes = notesBody;
            setNotesArr([...notesArr]);

            document.getElementById('editNoteModalbtn').click();
        
        } else if(codeType == 'task') {

            taskArr[taskNoteIndex].date = taskDate;
            taskArr[taskNoteIndex].assigned = taskAssigned;
            taskArr[taskNoteIndex].task = taskBody;
            setTaskArr([...taskArr]);
            
            document.getElementById('editTaskModalbtn').click();
        }
    }
    
    const newAddHandle = (codeType)  => {

        setTaskNoteAction('add');
        if(codeType == 'notes') {

            setNotesDate("");
            setNotesEvent("");
            setNotesBody("");
            setNotesTitle("Add Notes");
            document.getElementById('editNoteModalbtn').click();
        
        } else if(codeType == 'task') {
            
            setTaskDate("");
            setTaskAssigned("");
            setTaskBody("");
            setTaskTitle("Add Task");
            document.getElementById('editTaskModalbtn').click();
        }
    }

    const revenueModelOn = (data, options, series, rVal=true) =>{
        var d = new Date();

        var fYear1 = d.getFullYear();
        var fYear2 = d.getFullYear() + 1;
        var fYear3 = d.getFullYear() + 2;
        let title

        if(data == 'projected') {
            title = "Projected";
            setRevenueTitle(title);
            setRevenueType(data);
            setRevenueYear([ fYear1, fYear2, fYear3 ]);
        } else {
            title = "Past 3yrs Revenue";
            setRevenueTitle(title);
            setRevenueType(data);
            if(options.length > 0){
                setRevenueYear(options);
            } else {
                setRevenueYear(["", "", ""]);
            }
        }
        
        //console.log("dfsdfsdf", series);
        if(series.length <= 0) {
            console.log("dfsdfsdf");
            setRevenueVal(["", "", ""]);
        } else {
            setRevenueVal([...series]);
        }
        document.getElementById('sendRenModal-btn').click();
    }

    const fnRevenueCall = (val, id) => {

        if(id == 1) {
            revenueVal[0] = val;
        } else if(id == 2) {
            revenueVal[1] = val;
        } else if(id == 3) { 
            revenueVal[2] = val;
        } else if(id == 'revenueNotes') {  
            revenueVal['notes'] = val;
        }
        console.log(revenueVal);
        setRevenueLocalVal([...revenueVal]);
    }


    const fnRevenueYear = (val, id) => {

        if(id == 1) {
            revenueYear[0] = val;
        } else if(id == 2) {
            revenueYear[1] = val;
        } else if(id == 3) { 
            revenueYear[2] = val;
        } 
        setRevenueYear([...revenueYear]);
    }

    const revenueSave = () => {
        if(revenueYear[0] !== "" && revenueYear[1] !== "" && revenueYear[2] !== "" ){
            setModalError(false)
            setRevenueVal([...revenueVal]);
            setRevenueYear([...revenueYear]);
            document.getElementById('revenueCancel').click();
        } else {
            setModalError(true)
        }
        console.log(revenueVal, revenueType);
        // revenueModelOn(revenueType, revenueYear, revenueVal, false);
    }

    const updatePipelineStartupStatus = (id, status, type)  => {
        
        let tokenpass = window.localStorage.getItem('token');
        const data = { status, type };  
        //setLoader(true);
        axios.put(`${common.path.base_url}/updatePipelineStartupStatus/${id}`, data, {
            headers: {
                "Authorization": tokenpass
            }
        
        }).then((resp) => {
        if (resp.data.status === 'success') {
            const updatedListData = listData.map((item) => {
                if (item._id === id) {
                    return {
                        ...item,
                        status: status,
                    };
                }
                return item;
            });

            setListData(updatedListData);
        }
    })
    }

    const fnMoveToActiveStartupList = (id) => {
        swal({
            title: "Confirmation",
            text: "Are you sure you want to move this startup to Active startup list?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((action) => {

               if(action){

                    let tokenpass = window.localStorage.getItem('token');
                    const data = {  };  
                    axios.put(`${common.path.base_url}/moveToActiveStartupList/${id}`, data, {
                        headers: {
                            "Authorization": tokenpass
                        }
                    }).then((resp) => {
                        console.log(resp.data.data);
                        window.location.reload();
                        //setLoader(false);
                        // if(resp.data.status == 'success'){
                        //     const alllistdata = resp.data.data.result;
                        //     const totalInvestors = resp.data.data.totalInvestors;
                        //     //console.log("sadasdasd",alllistdata);
                        //     setListData([...alllistdata]);
                        //     setTotalInvestors(totalInvestors);
                            
                        // }
                    })
                }
            });
    }

    const onDeleteStartupClick = ( file ) => {
        
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this file",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                const token = localStorage.getItem('token');
                if (willDelete) {
                    //@TODO CALL DELETE CONTACT API HERE WITH NECESSARY CHECKS
                    axios.delete(`${common.path.base_url}/deleteCompany`, { headers: { "Authorization": token }, data: { id: file._id } }
                    )
                        //@TODO CALL ON SUCCESS OF API
                        .then((response) => {
                            swal({
                                title: "Success!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            }).then(() => {
                                window.location.href = '/pipeline';
                            });
                        })
                        .catch((error) => {
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else {
                                swal({
                                    title: "Error! #33005",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });
                }
                else {
                    swal({ text: `${file.companyName} file is safe!`, icon: 'info' });
                }
            });
    }

    const onHideStartupClick = (file, type) => {
        swal({
            title: "Are you sure want to "+type+" ?",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willHide) => {
                const token = localStorage.getItem('token');
                if (willHide) {
                    
                    const data = {
                        id: file._id,
                        type: type, 
                    }
                    
                    axios.post(`${common.path.base_url}/hideVisibleCompany`, data, {
                        headers: {
                            "Authorization": token
                        }
                    })
                        .then((response) => {
                            swal({
                                title: "Success!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            
                            }).then(() => { window.location.reload();  });
                        })
                        .catch((error) => {
                            
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else {
                                swal({
                                    title: "Error! #33005",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });
                }
                else {
                    swal({ text: `${file.companyName} file is safe!`, icon: 'info' });
                }
            });    
    }

    return (
        <>
            <div className='maincontentBody'>
                <div className='topMaincontentEmpty'></div>
                <div className='container contentBox pt-0' id='generalTab'>
                    <div className='row'>
                        <div className='companysectionlistCont'>
                            <ul className='companysectionItem ml-10 '>
                                <li><div id="gdiv-tab" onClick={(e) => scrollToTargetAdjusted(e, 'generalTab')} >General</div></li>
                                <li><div id="stdiv-tab" onClick={(e) => scrollToTargetAdjusted(e, 'stagematurityTab')} >Stage / Maturity</div></li>
                                <li><div id="tediv-tab" onClick={(e) => scrollToTargetAdjusted(e, 'teamTab')} >Team, Board & Investors</div></li>
                                <li><div id="oppdiv-tab" onClick={(e) => scrollToTargetAdjusted(e, 'opportunityTab')}>TSG Opportunity</div></li>
                                <li><div id="notediv-tab" onClick={(e) => scrollToTargetAdjusted(e, 'noteTab')}>Notes / Activity Tracking</div></li>
                                <li><div id="taskdiv-tab" onClick={(e) => scrollToTargetAdjusted(e, 'taskTab')}>Task</div></li>
                                <li><div id="sentiments-tab" onClick={(e) => scrollToTargetAdjusted(e, 'sentimentTab')}>Sentiments</div></li>
                                
                            </ul>
                        </div>
                    </div>
                    <div className='generalcontentCont'>
                        <div className='sectionHeading generalButtonAdd'>
                            <div>General</div>
                            <div className='generalAllBtn'>
                            <label title='' className="switch">
                                    <input type="checkbox"
                                         onClick={(event) =>{ event.stopPropagation();  updatePipelineStartupStatus(companyData._id, event.target.checked, 'Pipeline')}}
                                         defaultChecked={companyData.isPipelineStartupActive}
                                         onChange={(event) => {
                                             // event.stopPropagation()
                                             // updatePipelineStartupStatus(companyData._id, event.target.checked, 'Pipeline')
                                         }}
                                    />
                                    <span className="slider round"></span>
                            </label>

                                { ( companyData.isProspectStartup ) ?
                                    <div onClick={ () => { fnMoveToActiveStartupList(companyData._id) } } title='Move to Active Startup List' className='moveToActiveBtn'>
                                        <img src={activeListIcon} alt="move" className="delete-startup" />
                                    </div>
                                    : ""
                                }
                                
                                { ( companyData.isPipelineStartupShow==true ) ? 

                                        <div onClick={() => onHideStartupClick(companyData, 'hide') } title='Hide' className='moveToActiveBtnEyeIcon'>
                                            <img src={ eyeIconHide } alt="move" className="delete-startup" />
                                        </div>
                                    : 
                                    (companyData.isPipelineStartupShow==false) ? 
                                        <div onClick={() => onHideStartupClick(companyData, 'visible') } title='Visible' className='moveToActiveBtnEyeIcon'>
                                            <img src={ eyeIcon } alt="move" className="delete-startup" />
                                        </div>
                                    : 
                                        <div onClick={() => onHideStartupClick(companyData, 'hide') } title='Hide' className='moveToActiveBtnEyeIcon'>
                                            <img src={ eyeIconHide } alt="move" className="delete-startup" />
                                        </div>
                                }

                                {!companyData.activeInvestmentId && (
                                <div onClick={ () => { onDeleteStartupClick(companyData) } } title='Delete' className='moveToActiveBtnDelete'>
                                    <img src={deleteNew} alt="move" className="delete-startup" />
                                </div>
                                )}
                            </div>
                        </div>
                        <General 
                        companyDispaly={ companyDispaly } 
                        setUpdateCompanyId = { (id) => setUpdateCompanyId(id) }
                        companyId = { companyId }
                        companyData = { companyData }  
                        />
                    </div>
                </div >

                <div className='container contentBox1 mt-25' id='stagematurityTab' disabled={companyDispaly} >
                    <div className='sectionHeading' >Stage / Maturity</div>
                        <StageMaturity 
                        companyId = { companyId }
                        companyData = { companyData }
                        revenueModelOn = { (data, options, series) => revenueModelOn(data, options, series) }
                        revenueYear = { revenueYear }
                        revenueVal = { revenueVal }
                        rtype = {revenueType}
                        />
                </div>

                <div className='container contentBox1 mt-25' id='teamTab' disabled={companyDispaly}>
                    <TeamBoardInvestor 
                    companyId = { companyId }
                    companyData = { companyData }
                    />
                </div>
                
                <div className='container contentBox1 mt-25' id='opportunityTab' disabled={companyDispaly}>
                    
                    <TsgOpportunity 
                    companyId = { companyId }
                    companyData = { companyData }
                    />

                </div>
                
                <div className='container contentBox1 mt-25' id='noteTab' disabled={companyDispaly}>
                    <Notes 
                        companyId = { companyId }
                        companyData = { companyData }
                        notesArr = { notesArr }
                        deleteHandle = { (index) => deleteHandle(index, 'notes') }
                        editHandle = { (index) => editHandle(index, 'notes') }
                        newAddHandle = { () => newAddHandle('notes')  }
                    />
                </div>
                
                <div className='container contentBox1 mt-25 mb-35' id='taskTab' disabled={companyDispaly}>
                    <Tasks 
                        companyId = { companyId }
                        companyData = { companyData }
                        taskArr = { taskArr }
                        deleteHandle = { (index) => deleteHandle(index, 'task') }
                        editHandle = { (index) => editHandle(index, 'task') }
                        newAddHandle = { () => newAddHandle('task')  }
                    />
                </div>

                <div className='container contentBox1 mt-25 mb-35' id='sentimentTab' disabled={companyDispaly}>
                    <Sentiments 
                        companyId = { companyId }
                        companyData = { companyData }
                        sentiments = { sentiments }
                    />
                </div>
            </div>

            <button id="editNoteModalbtn" style={{ display: "none" }} data-toggle="modal" data-target="#noteModel"></button>
            <button id="editTaskModalbtn" style={{ display: "none" }} data-toggle="modal" data-target="#taskModel"></button>

            <div className="modal fade" id="noteModel" data-backdrop="static" data-keyboard="false" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header modalHeader">
                            <h4 className="modal-title" id="myModalLabel">{ notesTitle }</h4>
                            <button type="button" className="close closeIcon"  data-dismiss="modal" onClick={ (e) => popReset('notes') }>
                                {Icons.Close}
                            </button>
                            
                        </div>
                        <div className="modal-body modalBody">
                            
                            <div className='formfieldGroup mb-20'>
                                <div className='inputLable'>Date:</div>
                                <div className='inputtextCont noteModelPopup'>
                                    <DatePicker
                                        selected={ notesDate }
                                        onChange={(date) => setNotesDate(date, 1)}
                                        dateFormat="MMMM d, yyyy"
                                        className= { "form-control" }
                                    />
                                </div>
                            </div>

                            <div className='formfieldGroup mb-20'>
                                <div className='inputLable'>Event:</div>
                                <div className='inputtextCont'>
                                    <input type="text" className="form-control formInput" id="Event" placeholder="Enter Event" onChange={ (e) => setNotesEvent(_.upperFirst(e.target.value)) } value={ notesEvent} />
                                </div>
                            </div>

                            <div className='formfieldGroup mb-20'>
                                <div className='inputLable'>Notes:</div>
                                <div className='inputtextCont'>
                                    <textarea className="form-control formInput" id="" rows="3" placeholder="Enter Note" onChange={ (e) => setNotesBody(e.target.value) } value={ notesBody } ></textarea>
                                </div>
                            </div>

                            <div className='formfieldGroup mt-25'>
                                <div className='inputLable'></div>
                                <div className='btnGroup'>
                                    { ( taskNoteAction == 'add') ? 
                                        <button className='btnPrimary mr-15' onClick={ () => notesSave('notes') } >Add</button> 
                                        :
                                        <button className='btnPrimary mr-15' onClick={ () => updateTaskAndNote('notes') } >Update</button> 
                                    }

                                    <button type="button" id="notesCancel" className='btnCancel' data-dismiss="modal" onClick={ (e) => popReset('notes') } >Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="taskModel" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header modalHeader">
                            <h4 className="modal-title" id="myModalLabel">{ taskTitle }</h4>
                            <button type="button" className="close closeIcon" data-dismiss="modal" aria-label="Close">
                                {Icons.Close}
                            </button>
                        </div>
                        <div className="modal-body modalBody">

                            <div className='formfieldGroup mb-20'>
                                <div className='inputLable'>Date:</div>
                                <div className='inputtextCont noteModelPopup'>
                                    <DatePicker
                                        selected={ taskDate }
                                        onChange={(date) => setTaskDate(date, 1)}
                                        dateFormat="MMMM d, yyyy"
                                        className= { "form-control" }
                                    />
                                </div>
                            </div>

                            <div className='formfieldGroup mb-20'>
                                <div className='inputLable'>Assigned To:</div>
                                <div className='inputtextCont'>
                                    <input type="email" className="form-control formInput" id="Event" placeholder="Enter Event" onChange={ (e) => setTaskAssigned(_.upperFirst(e.target.value)) } value={ taskAssigned } />
                                </div>
                            </div>

                            <div className='formfieldGroup mb-20'>
                                <div className='inputLable'>Notes:</div>
                                <div className='inputtextCont'>
                                    <textarea className="form-control formInput" rows="3" placeholder="Enter Note" onChange={ (e) => setTaskBody(e.target.value) } value={ taskBody } ></textarea>
                                </div>
                            </div>

                            <div className='formfieldGroup mt-25'>
                                <div className='inputLable'></div>
                                <div className='btnGroup'>
                                    { ( taskNoteAction == 'add') ? 
                                        <button className='btnPrimary mr-15' onClick={ () => notesSave('task') } >Add</button> 
                                        :
                                        <button className='btnPrimary mr-15' onClick={ () => updateTaskAndNote('task') } >Update</button> 
                                    }
                                    <button type='button' id="taskCancel" className='btnCancel' data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <button id="sendRenModal-btn" style={{ display: "none" }} data-toggle="modal" data-target="#revenueModel"></button>
            <div className="modal fade" id="revenueModel" data-backdrop="static" data-keyboard="false" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header modalHeader">
                            <h4 className="modal-title" id="myModalLabel">{ revenueTitle }</h4>
                            <button type="button" className="close closeIcon"  data-dismiss="modal" onClick={ (e) => popReset('notes') }>
                                {Icons.Close}
                            </button>
                        </div>
                        <div className="modal-body modalBody">
                            <div className='formfieldGroup mb-20'>
                                <div className='inputLable d-flex'>
                                    { revenueTitle === 'Projected' ? 
                                    <>
                                        <input type="text"  style={{cursor:'not-allowed'}} disabled className="form-control formInput" id="1stYear" placeholder="1st Year" onChange={ (e) => fnRevenueYear(e.target.value, 1) } value={ (revenueYear) ? revenueYear[0] : '' }  /><div className='mt-10 ml-10'>:</div>
                                    </> :
                                    <>
                                        <input type="text" className="form-control formInput" id="1stYear" placeholder="1st Year" onChange={ (e) => fnRevenueYear(e.target.value, 1) } value={ (revenueYear) ? revenueYear[0] : '' }  /><div className='mt-10 ml-10'>:</div>
                                    </>
                                    }
                                    
                                </div>
                                <div className='inputtextCont'>
                                    <NumberFormat
                                        value={ (revenueVal) ? revenueVal[0] : "" }
                                        thousandSeparator={true}
                                        id="Event"
                                        fixedDecimalScale={true}
                                        prefix={'$'}
                                        placeholder=''
                                        className="form-control formInput"
                                        onChange={ (e) => {
                                            let reVal = e.target.value;
                                            reVal = reVal.replace(/\,/g,'');
                                            reVal = reVal.replace(/\$/g,'');
                                            
                                            fnRevenueCall(reVal, '1');
                                            }}
                                    />
                                </div>
                            </div>
                            { modalError && revenueYear[0] == "" && <p>Please fill the 1st year</p> }
                            <div className='formfieldGroup mb-20'>
                                <div className='inputLable d-flex'>
                                    { revenueTitle === 'Projected' ?
                                     <>
                                        <input type="text" className="form-control formInput" style={{cursor:'not-allowed'}} disabled id="2ndYear" placeholder="2nd Year" onChange={ (e) => fnRevenueYear(e.target.value, 2) } value={ (revenueYear) ? revenueYear[1] : '' }  /><div className='mt-10 ml-10'>:</div>
                                     </> 
                                     : 
                                     <>
                                        <input type="text" className="form-control formInput" id="2ndYear" placeholder="2nd Year" onChange={ (e) => fnRevenueYear(e.target.value, 2) } value={ (revenueYear) ? revenueYear[1] : '' }  /><div className='mt-10 ml-10'>:</div>
                                     </> 
                                    }
                                </div>
                                <div className='inputtextCont'>
                                    {/* <input type="text" className="form-control formInput" id="Event" placeholder="Enter Event" onChange={ (e) => fnRevenueCall(e.target.value, '2')} value={ (revenueVal) ? revenueVal[1] : "" } /> */}
                                    <NumberFormat
                                        value={ (revenueVal) ? revenueVal[1] : "" }
                                        thousandSeparator={true}
                                        id="Event"
                                        fixedDecimalScale={true}
                                        placeholder=''
                                        prefix={'$'}
                                        className="form-control formInput"
                                        onChange={ (e) => {
                                            let reVal = e.target.value;
                                            reVal = reVal.replace(/\,/g,'');
                                            reVal = reVal.replace(/\$/g,'');
                                            
                                            fnRevenueCall(reVal, '2');
                                            }}
                                    />
                                </div>
                            </div>
                                { modalError && revenueYear[1] == "" && <p>Please fill the 2nd year</p> }
                            <div className='formfieldGroup mb-20'>
                                <div className='inputLable d-flex'>
                                    {  revenueTitle === 'Projected' ?
                                    <>
                                        <input type="text" className="form-control formInput"  style={{cursor:'not-allowed'}} disabled id="3rdYear" placeholder="3rd Year" onChange={ (e) => fnRevenueYear(e.target.value, 3) } value={ (revenueYear) ? revenueYear[2] : '' }  /><div className='mt-10 ml-10'>:</div>
                                    </> 
                                    : 
                                    <>
                                        <input type="text" className="form-control formInput" id="3rdYear" placeholder="3rd Year" onChange={ (e) => fnRevenueYear(e.target.value, 3) } value={ (revenueYear) ? revenueYear[2] : '' }  /><div className='mt-10 ml-10'>:</div>
                                    </> 
                                    }
                                </div>
                                <div className='inputtextCont'>
                                    {/* <input type="text" className="form-control formInput" id="Event" placeholder="Enter Event" onChange={ (e) => fnRevenueCall(e.target.value, '3') } value={ (revenueVal) ? revenueVal[2] : "" } /> */}
                                    <NumberFormat
                                        value={ (revenueVal) ? revenueVal[2] : "" }
                                        thousandSeparator={true}
                                        id="Event"
                                        fixedDecimalScale={true}
                                        placeholder=''
                                        prefix={'$'}
                                        className="form-control formInput"
                                        onChange={ (e) => {
                                            let reVal = e.target.value;
                                            reVal = reVal.replace(/\,/g,'');
                                            reVal = reVal.replace(/\$/g,'');
                                            
                                            fnRevenueCall(reVal, '3');
                                            }}
                                    />
                                </div>
                            </div>
                                { modalError && revenueYear[2] == "" && <p>Please fill the 3rd year</p> }
                            <div className='formfieldGroup mb-20'>
                                <div className='inputLable'>Notes:</div>
                                <div className='inputtextCont'>
                                    <textarea className="form-control formInput" id="Event" placeholder="Enter Event" rows={5} onChange={ (e) => {
                                        let reVal = e.target.value;
                                        fnRevenueCall(reVal, 'revenueNotes');
                                    }}>
                                    </textarea>    
                                </div>
                            </div>
                            <div className='formfieldGroup mt-25'>
                                <div className='inputLable'></div>
                                <div className='btnGroup'>
                                    <button className='btnPrimary mr-15' onClick={ () => revenueSave() } >Save</button>
                                    <button type="button" id="revenueCancel" className='btnCancel' data-dismiss="modal" onClick={ (e) => popReset('notes') } >Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewAddPipeline;
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import ReactTags from 'react-tag-autocomplete';
import common from '../../common';
import TButton from '../../components/TButton';
import bell from '../../images/icons/bell.svg';
import bellwhite from '../../images/icons/bellwhite.svg';
import chat from '../../images/icons/chat.svg';
import chatwhite from '../../images/icons/chatwhite.svg';
import downarrow from '../../images/icons/downarrow.svg';
import exitright from '../../images/icons/exitright.svg';
import search from '../../images/icons/search.svg';
import warning from '../../images/icons/warning.svg';
import DetailCommunicationModel from './DetailCommunicationModal';
import IntialNotificationModal from './IntialNotificationModal';
import './InvestmentDetail.scss';


class ExitProcessCommunication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [
                { name: 'Previously invested in this Startup investment ', id: 1, isChecked: false },
                { name: 'Referred by Startup ', id: 2, isChecked: false },
                { name: 'Previously invested in any TSG investment', id: 3, isChecked: false },
                { name: 'Previously invited to a TSG investment', id: 4, isChecked: false },
            ],
            optionSelected: null,
            pagination: {
                currentPage: 0,
                totalPages: 1,
            },
            tags: [],
            searchInputTags: [],
            suggestions: [
                { id: 'YPO', name: 'YPO' },
                { id: 'Institutional', name: 'Institutional' },
                { id: 'Strategic', name: 'Strategic' },
                { id: 'Preferred', name: 'Preferred' },
                { id: 'Investment(s)', name: 'Investment(s)' }],
            arrowType: {
                name: 'down',
                emailAddress: 'down',
                company: 'down',
            },
            $sort: null,
        }
    }
    handleDelete(i) {
        const tags = this.state.tags.slice(0)
        tags.splice(i, 1)
        this.setState({ tags });
    }
    handleAddition(tag) {
        if (tag && tag.id && tag.name) {
            const isExist = this.state.tags.filter(item => item.id === tag.id);
            if (isExist && isExist.length > 0) {
                return;
            }
            const tags = [].concat(this.state.tags, tag);
            this.setState({ tags });

        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, false);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
    }

    handleScroll = () => {
        if ((Math.round(window.innerHeight + document.documentElement.scrollTop) === document.documentElement.offsetHeight)
            && (this.state.pagination.currentPage < this.state.pagination.totalPages)) {
        }
    };
    toggleArrow(fieldType) {
        let arrowType = this.state.arrowType;
        let $sort = {};
        if (arrowType[fieldType] === 'down') {
            arrowType[fieldType] = 'up';
            $sort[fieldType] = -1;
        } else {
            arrowType[fieldType] = 'down';
            $sort[fieldType] = 1;
        }
        this.props.getExitInProcessUsers(false, this.mapTags(), $sort)
        this.setState({ arrowType, $sort });
    }
    viewDocumentRedirect(envId, type) {
        window.open(` ${common.path.doc_download_url}/downloadPDF?envId=${envId}&type=${type}`, '_blank', '', 2)

    }
    getUserCitizenForm(isForeignCitizen) {
        return isForeignCitizen === true ? 'W8' : 'W9'
    }
    mapTags(tags) {
        if (!tags) {
            tags = this.state.searchInputTags;
        }
        let tagIds = []
        if (tags && tags.length > 0) {
            tagIds = tags.map(tag => tag.name)
        }
        return tagIds;
    }
    handleSearchInputDelete(i) {
        const tags = this.state.searchInputTags.slice(0)
        tags.splice(i, 1)
        this.setState({ searchInputTags: tags }, () => {
            this.props.getExitInProcessUsers(false, this.mapTags())
        });
    }
    handleSearchInputAddition(tag) {
        const tags = [].concat(this.state.searchInputTags, tag);
        this.setState({ searchInputTags: tags }, () => {
            this.props.getExitInProcessUsers(false, this.mapTags())
        });
    }
    enableDisableCommuniction(detail, type) {
        if (detail) {
            if (type === 'initial') {
                if (detail.isInitialCommunicationDone) return true;
            } else {
                if (detail.isDetailCommunicationDone) return true;
                else if (!detail.isDetailCommunicationDone && !detail.isInitialCommunicationDone) return true;
            }
        }
        else if (!detail) {
            return true;
        }
        return false;
    }
    mapDateFormat(mongoDate) {
        if (!mongoDate) return 'N/A';
        const monthNames = [
            "01", "02", "03",
            "04", "05", "06", "07",
            "08", "09", "10",
            "11", "12"
        ];
        let date = new Date(mongoDate);
        let day = date.getDate();
        let monthIndex = date.getMonth();
        let year = date.getFullYear();
        return monthNames[monthIndex] + '/' + day + '/' + year;

    }

    render() {
        return (
            <div className="box-wrapper-main">
                <IntialNotificationModal
                    onRef={(ref) => this.sendInitialNotificationChild = ref}
                    onSendInitialNotification={() => this.props.getExitInProcessUsers(false, this.mapTags())}

                />
                <DetailCommunicationModel
                    onRef={(ref) => this.sendDetailCommunicationChild = ref}
                    onSendDetailCommunication={() => this.props.getExitInProcessUsers(false, this.mapTags())}
                />
                <div className="row searchpadding">

                    <div className='col-xs-12 col-sm-12 col-lg-6 col-md-4 search-status allInvestmentDetail header-investment'>
                        <div className='search' style={{ width: '100%', position: 'relative' }}>
                            <ReactTags
                                allowNew={true}
                                placeholder='Search'
                                suggestions={this.state.suggestions}
                                tags={this.state.searchInputTags}
                                handleDelete={this.handleSearchInputDelete.bind(this)}
                                handleAddition={this.handleSearchInputAddition.bind(this)}
                            />
                            <img src={search} className="search-icon"></img>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-lg-6 col-md-8 text-md-right allInvestmentDetail" >
                        <div className="all">
                            <div className="select-box">
                                <div className='status'>
                                    <select className='status-box'
                                        // onChange={(event) => this.filterByStage('stage', event.target.value)}
                                        onChange={(event) => this.props.getExitInProcessUsers(false, this.mapTags(), this.state.$sort, event.target.value)}
                                    >
                                        {/* <option value="" disabled selected>Select Status</option> */}
                                        <option value="all">All</option>
                                        <option value="verified">Verified</option>
                                        <option value="not verified">Not Verified</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className='exit-buttons'>
                            <TButton text={`INTIAL NOTIFICATION`} className='invite-btn' icon={bellwhite}
                                onClick={() => {
                                    this.setState(this.sendInitialNotificationChild.resetIntialNotificationData(), this.sendInitialNotificationChild.setData({
                                        investmentId: this.props.id,
                                        isFromMultipleSendMessage: true,
                                        companyName: this.props.investmentData.companyId.companyName,
                                        usersid: this.props.exitInProcessUsers,
                                        investmentType: this.props.investmentData.exitProcessType,
                                        correspondingllcname: this.props.investmentData.llcName,
                                        acquiringcompanyname: this.props.investmentData.acquiringCompanyName,
                                        ipodate: this.mapDateFormat(this.props.investmentData.lockupDate)

                                    }));
                                }}
                                dataTarget={`#intialNotificationModal`}
                                dataToggle={`modal`}
                                disabled={this.enableDisableCommuniction(this.props.exitInProcessUsers[0], 'initial')}
                            />
                        </div>
                        <div className='exit-buttons'>
                            <TButton text={`DETAIL COMMUNICATION`} className='invite-btn' icon={chatwhite}
                                onClick={() => {
                                    this.setState(this.sendDetailCommunicationChild.resetIntialNotificationData(), this.sendDetailCommunicationChild.setData({
                                        companyName: this.props.investmentData.companyId.companyName,
                                        investmentId: this.props.id,
                                        isFromMultipleSendMessage: true,
                                        usersid: this.props.exitInProcessUsers,
                                        investmentType: this.props.investmentData.exitProcessType,
                                        correspondingllcname: this.props.investmentData.llcName,
                                        acquiringcompanyname: this.props.investmentData.acquiringCompanyName,
                                        ipodate: this.mapDateFormat(this.props.investmentData.lockupDate),
                                        initialpayoutdate: this.mapDateFormat(this.props.investmentData.disbursementDate),
                                        escrowpercentageholdback: null,
                                        escrowexpirationdate: this.mapDateFormat(this.props.investmentData.disbursementDate),
                                    }));

                                }}
                                dataTarget={`#detailCommunicationModal`}
                                dataToggle={`modal`}
                                disabled={this.enableDisableCommuniction(this.props.exitInProcessUsers[0], 'detail')}
                            />
                        </div>
                    </div>
                </div>

                <div className="custom-table-view">
                    <table className="table table-custom">
                        <thead>
                            <tr>
                                <th>INVESTOR <img src={downarrow} className="menu-icon"></img></th>
                                {/* <i className={`arrow ${this.state.arrowType['name']}`} onClick={() => this.toggleArrow('name')}></i> */}
                                <th>COMPANY NAME <img src={downarrow} className="menu-icon"></img></th>
                                {/* <i className={`arrow ${this.state.arrowType['emailAddress']}`} onClick={() => this.toggleArrow('emailAddress')}></i> */}
                                <th>GROSS INVESTMENT AMOUNT <img src={downarrow} className="menu-icon"></img></th>
                                {/* <i className={`arrow ${this.state.arrowType['company']}`} onClick={() => this.toggleArrow('company')}></i> */}
                                <th>VIEW DOC.</th>
                                <th>ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.exitInProcessUsers.length > 0 &&
                                this.props.exitInProcessUsers.map(user => {
                                    return (
                                        <tr key={user._id}>
                                            <td>
                                                {user.name}
                                                <div className={user.isAcountVerified ? "tooltipcover" : this.props.investmentData.exitProcessType === 'ipo' ? "tooltipcover-brokerage" : "tooltipcover-notverified"}>
                                                    <span className="tooltiptext">{user.isAcountVerified ? 'Verified' : this.props.investmentData.exitProcessType === 'ipo' ? 'Brokerage account not verified' : 'Address not verified'}</span>
                                                    <img src={user.isAcountVerified ? exitright : warning} className="menu-icon"></img>
                                                </div>
                                            </td>
                                            <td>
                                                {user.companyName}
                                            </td>
                                            <td>
                                                <NumberFormat
                                                    fixedDecimalScale={true}
                                                    value={user.amount}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                />
                                            </td>
                                            <td>
                                                <a style={{ color: '#F15B4B', cursor: 'pointer' }} onClick={() => this.viewDocumentRedirect(user.subscriptionEnvId, 'Subscription')}>View QSA</a><br />
                                                <a style={{ color: '#F15B4B', cursor: 'pointer' }} onClick={() => this.viewDocumentRedirect(user.w9EnvId, this.getUserCitizenForm(user.isForeignCitizen))}>
                                                    {
                                                        user.isForeignCitizen ? 'View W-8' : 'View W-9'
                                                    }
                                                </a>
                                            </td>
                                            <td>

                                                <div className="tooltipcover-intial-notification">
                                                    <span className="tooltiptext">Intial notification</span>
                                                    <img src={bell} className="menu-icon"
                                                        data-target={`#intialNotificationModal`}
                                                        data-toggle={`modal`}
                                                        onClick={() => {
                                                            this.setState(this.sendInitialNotificationChild.resetIntialNotificationData(), this.sendInitialNotificationChild.setData({
                                                                user: user.user,
                                                                companyName: this.props.investmentData.companyId.companyName,
                                                                investmentId: this.props.id,
                                                                isFromMultipleSendMessage: false,
                                                                investmentType: this.props.investmentData.exitProcessType,
                                                                correspondingllcname: this.props.investmentData.llcName,
                                                                acquiringcompanyname: this.props.investmentData.acquiringCompanyName,
                                                                ipodate: this.mapDateFormat(this.props.investmentData.lockupDate),

                                                            }));
                                                        }}
                                                    />

                                                </div>
                                                <div className="tooltipcover-communication">
                                                    <span className="tooltiptext">Communication</span>
                                                    <img
                                                        src={chat}
                                                        className="menu-icon"
                                                        data-target={`#detailCommunicationModal`}
                                                        data-toggle={`modal`}
                                                        onClick={() => {
                                                            this.setState(this.sendDetailCommunicationChild.resetIntialNotificationData(), this.sendDetailCommunicationChild.setData({
                                                                user: user.user,
                                                                companyName: this.props.investmentData.companyId.companyName,
                                                                investmentId: this.props.id,
                                                                isFromMultipleSendMessage: false,
                                                                investmentType: this.props.investmentData.exitProcessType,
                                                                correspondingllcname: this.props.investmentData.llcName,
                                                                acquiringcompanyname: this.props.investmentData.acquiringCompanyName,
                                                                ipodate: this.mapDateFormat(this.props.investmentData.lockupDate),
                                                                initialpayoutdate: this.mapDateFormat(this.props.investmentData.disbursementDate),
                                                                escrowpercentageholdback: null,
                                                                escrowexpirationdate: this.mapDateFormat(this.props.investmentData.disbursementDate),
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                this.props.exitInProcessUsers.length === 0 &&
                                <tr>
                                    <td>No investors found for invite!</td>
                                </tr>

                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

}

export default ExitProcessCommunication;


const reducer = (state = {}, action) => {
  let expandCollapseArr = [];
  let index = null;
  if (state.expandCollapseArr) {
    expandCollapseArr = [...state.expandCollapseArr]
  }
  switch (action.type) {
    case 'EXPAND':
      index = expandCollapseArr.indexOf(action.id);
      if (index < 0) {
        expandCollapseArr.push(action.id);
      }
      return {
        expandCollapseArr
      };
    case 'COLLAPSE':
      index = expandCollapseArr.indexOf(action.id);
      if (index > -1) {
        expandCollapseArr.splice(index, 1);
      }
      return {
        expandCollapseArr
      }
      case 'RESET':
        return {
          expandCollapseArr: []
        }
    default:
      return state;
  }
}

export default reducer
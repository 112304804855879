import React from 'react';
import './ContactCard.scss';
import ProfileImage from '../ProfileImage';
import images from '../../images';
import common from '../../common';
import { Accordion, Card } from 'react-bootstrap';

const getStatus = (status) => {
    switch (status) {
        case '0':
            return 'red';

        case '1':
            return 'green';

        case '2':
            return 'amber';

        default:
            return '';
    }
}

function ContactCard(props) {
    const contactData = props.contactData;
    const showMenu = contactData.showMenu ? contactData.showMenu : false;
    const showMore = contactData.showMore ? contactData.showMore : false;

    switch (props.type) {
        case '1':
            return (
                <div className={`contact-card1 contact-list   ${contactData.role === '001' || contactData.role === '003' ? 'admin' : ''}`} >
                    <div className={`profile-block custom-profile ${showMore ? 'expand' : ''} ${showMenu ? 'show-menu' : ''} `} onClick={() => props.toggleShowMoreCard && props.toggleShowMoreCard(1)}>
                        <Card className="nav navbar-nav">
                            <Accordion.Toggle as={Card.Header} eventKey={`${contactData._id}`} id='user' className="nav-item">
                                <ProfileImage
                                    shape={`circle`}
                                    imgSrc={props.imgSrc}
                                    size={`40px`}
                                    status={getStatus(contactData.invitationAccepted)}
                                />
                                <div className="contact-block">
                                    <div className="contact-txt">{`${contactData && contactData.name ? contactData.name : ''}`}</div>
                                    <div className="contact-txt">{`${contactData && contactData.title ? contactData.title : ''}`}</div>
                                </div>

                                {props.actionIcon ? (<div className="action-icon no-action" onClick={() => { props.onActionClick && props.onActionClick() }} >
                                    <img src={images.path.deleteIcon} />
                                </div>) : null}
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={`${contactData._id}`} className={`${showMore ? 'expand' : 'collapse'}`}>
                                <Card.Body>
                                    <ul className={`new-dropdown`}>
                                        <li className="dropdown-item emailAddress"><b>Email Address:</b><br />
                                            {contactData && contactData.emailAddress ? contactData.emailAddress : ''}
                                        </li>
                                        <div className='mobile-office'>
                                            <li className="dropdown-item"><b>Mobile:</b><br />
                                                {contactData && contactData.mobile ? contactData.mobile : ''}
                                            </li>
                                            <li className="dropdown-item"><b>Office:</b><br />
                                                {contactData && contactData.office ? contactData.office : ''}
                                            </li>
                                        </div>
                                    </ul>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </div>
                </div>
            );

        case '2':
            return (
                <div onClick={() => props.toggleShowMoreCard && props.toggleShowMoreCard(1)} className={`contact-list ${showMore ? 'expand' : ''} ${showMenu ? 'show-menu' : ''}  ${contactData.role === '001' || contactData.role === '003' ? 'admin' : ''}`}>
                    <ul className="nav navbar-nav ">
                        <li id='user' className="nav-item">
                            <ProfileImage
                                imgSrc={`${common.path.base_url}/getImage?id=${contactData._id || ''}`}
                            // size={`40px`}
                            />
                            <div id="name-role">
                                <b>{contactData && contactData.name ? contactData.name : ''}</b><br />
                                <p>{contactData && contactData.roleName ? contactData.roleName : ''}</p>
                            </div>
                        </li>
                    </ul>

                    <ul className={`dropdown-menu`}>
                        <li className="emailAddress"><b>Email Address:</b><br />
                            {contactData && contactData.emailAddress ? contactData.emailAddress : ''}
                        </li>
                        <div className='mobile-office'>
                            <li><b>Mobile:</b><br />
                                {contactData && contactData.mobile ? contactData.mobile : ''}
                            </li>
                            <li><b>Office:</b><br />
                                {contactData && contactData.office ? contactData.office : ''}
                            </li>
                        </div>
                    </ul>

                    {showMenu && <div className='three-dots-main'>
                        <a href="#" id="three-dots" onClick={(event) => { this.props.toggleShowMoreCard(2); event.stopPropagation() }} >
                            <img src={images.path.kebabMenuIcon} className="menu-icon"></img>
                            <ul className={`dropdown-menu2`}>
                                <li>Hide</li>
                                <li className="resend">Send Invite</li>
                            </ul>
                        </a>
                    </div>}
                </div >
            );
        default:
            return null;
    }
}

export default ContactCard;



// <div className={`contact-card1 contact-list   ${contactData.role === '001' || contactData.role === '003' ? 'admin' : ''}`} >
//                 <div className={`profile-block custom-profile ${showMore ? 'expand' : ''} ${showMenu ? 'show-menu' : ''} `} onClick={() => props.toggleShowMoreCard && props.toggleShowMoreCard(1)}>
//                     <ul className="nav navbar-nav ">
//                         <li id='user' className="nav-item">
//                             <ProfileImage
//                                 shape={`circle`}
//                                 imgSrc={props.imgSrc}
//                                 size={`40px`}
//                                 status={getStatus(contactData.invitationAccepted)}
//                             />
//                             <div className="contact-block">
//                             <div className="contact-txt">{`${contactData && contactData.name ? contactData.name : 'Unknown'}`}</div>
//                             <div className="contact-txt">{`${contactData && contactData.title ? contactData.title : 'Unknown'}`}</div>
//                             </div>
//                         </li>
//                     </ul>
//                     <ul className={`dropdown-menu`}>
//                         <li class="emailAddress"><div className="main-ttl-lbl">Email Address:</div>
//                         <div className="main-ttl-detail">
//                         {contactData && contactData.emailAddress ? contactData.emailAddress : ''}
//                         </div>
//                         </li>
//                         <li><div className="main-ttl-lbl">Mobile:</div>
//                         <div className="main-ttl-detail">
//                         {contactData && contactData.mobile ? contactData.mobile : ''}
//                         </div>
//                         </li>
//                         <li>
//                             <div className="main-ttl-lbl">Office:</div>
//                             <div className="main-ttl-detail">
//                             {contactData && contactData.office ? contactData.office : ''}
//                             </div>

//                         </li>
//                     </ul>
//                 </div>
//                 {props.actionIcon ? (<div className="action-icon no-action" onClick={() => { props.onActionClick && props.onActionClick() }} >
//                     <img src={images.path.deleteIcon} />
//                 </div>) : null}
//             </ div >


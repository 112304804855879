import React, { Component } from 'react';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import swal from 'sweetalert';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import _ from 'lodash';
import './InvestorInvestment.scss';
import common from '../../common';

class InvestorInvestmentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: '',
            formattedValue: '',
            investmentData: null,
        }
    }
    componentWillReceiveProps(nextProps) {
        nextProps.onRef && nextProps.onRef(this);
    }


    setInvestment(investmentData, callback) {
        const temp = this;
        temp.setState({ investmentData });
        let amount = investmentData.amount;
        this.setState({ amount: amount });
        callback();
    }

    handleChange(id, value, formattedValue) {
        // set values
        this.setState({ amount: value, formattedValue })
    }


    resetInvestmentData() {
        this.setState({
            amount: '',
            formattedValue: null,
            investmentData: null,

        });
    }


    adjustAmount() {
        let temp = this;
        temp.setState({ loadinglada: true });
        let token = window.localStorage.getItem('token');
        let method = 'put';
        const data = {
            amount: this.state.amount,
            formattedAmount: this.state.formattedValue.slice(1),
            processId: this.state.investmentData._id,
        }
        axios[method](`${common.path.base_url}/updateInvestmentAmount`, data,
            {
                headers: {
                    "Authorization": token
                }
            })
            .then((response) => {
                temp.setState({ loadinglada: false });
                document.getElementById('cancelUploadDoc').click();
                this.props.addUpdateInvestorInvestmentModalCallback();

                swal({
                    title: "Success!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(() => {
                    })

            })
            .catch((error) => {
                temp.setState({ loadinglada: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });

                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    render() {
        return (
            <div>
                {/* <!-- update investment Modal HTML --> */}
                <div id="updateInvestmentModal" className="modal fade">
                    <div className="modal-dialog">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h4 className="modal-title">ADJUST AMOUNT</h4>
                            </div>
                            <div className="modal-body">
                                {/* <div className="form-group">
                                            <label className="col-sm-5  col-md-5 control-label">Amount <RequiredSign /> :</label>
                                            <div className="col-sm-7 col-md-7">
                                                {/* <input className="form-control" id="amount" type="text"
                                                    onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                    placeholder='Investment Amount'
                                                    value={this.state.amount} /> *
                                                    <NumberFormat 
                                                        value={this.state.amount}
                                                        thousandSeparator={true} 
                                                        prefix={'$'} 
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        onValueChange={(values) => {
                                                            this.handleChange('amount', values.value)
                                                    }}/>
                                                <p>{this.state.amountValidationMsg}</p>
                                            </div>
                                        </div> */}
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 field-label-responsive">
                                        <label htmlFor="name">Amount<sup>*</sup> :</label>
                                    </div>
                                    <div className="col-lg-7 col-md-9">
                                        <div className="form-group amount-investment">
                                            {/* <input type="text" name="name" className="form-control" id="name" placeholder="Enter Amount" required autofocus /> */}
                                            <NumberFormat
                                                value={this.state.amount}
                                                thousandSeparator={true}
                                                prefix={'$'}
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                onValueChange={(values) => {
                                                    this.handleChange('amount', values.value, values.formattedValue)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <input type="button" id="cancelUploadDoc" className="btn btn-default" data-dismiss="modal" onClick={() => this.resetInvestmentData()} value="CANCEL" />
                                <LaddaButton
                                    id='lada-btn'
                                    loading={this.state.loadinglada}
                                    onClick={() => this.adjustAmount()}
                                    data-color="#eee"
                                    data-size={XS}
                                    className={!this.state.amount ? 'disable' : ''}
                                    data-style={EXPAND_RIGHT}
                                    data-spinner-size={30}
                                    data-spinner-color="#ddd"
                                    data-spinner-lines={12}
                                >
                                    {'ADJUST'}
                                </LaddaButton>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default InvestorInvestmentModal;
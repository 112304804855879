import React from "react";
import "./MyInvestments.scss";
import GridCard from "./Grid.card";

const GridView = (props) => {
  const { myInvestments, isCollapsed } = props;
  return (
    <div className="my_invitation">
      <div>
        <div className={isCollapsed ? "row pipeline-scroll invitation_scroll" : "row"}>
          {myInvestments.map((myInvestment, index) => {
            return (
              <GridCard
                myInvestment={myInvestment}
                index={index}
                key={index}
                {...props}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GridView;

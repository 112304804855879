import React from 'react'
import Header from '../../components/Header';
import SummaryStartupsComponent from '../components/SummaryStartups';

const App = () => (
    <>
        <Header
        username={window.localStorage.getItem('username')}
        role={window.localStorage.getItem('role')}
        />
        <SummaryStartupsComponent />
    </>
)

export default App
import React, { Component } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import common from '../../common';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


class FollowUpModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            message: null,
            subject:'',
            count: 0,
            isFromMultipleFollowUp: false,
            apiLoading : false
        }
    }

    componentWillReceiveProps(nextProps) {
        nextProps.onRef && nextProps.onRef(this);
    }

    resetFollowUpData() {
        this.setState({
            user: null,
            message: null,
            count: this.state.count + 1,
            isFromMultipleFollowUp: false,
        });

    }

    getMessage({ companyName, user, isFromMultipleFollowUp, investmentStatus }) {
        if (!isFromMultipleFollowUp) {
            const reviewOpportunityEmail = `Sending over a friendly reminder that there is a new investment opportunity in ${companyName} available to you in the TSG Platform. If you’re interested please click below to view the opportunity including access to the FAQ, Investment Intelligence Report and Executive Letter.`;

            const reminderToParticipate = `We noticed you viewed the opportunity to invest in ${companyName} but have not made a decision re:participation. Just a quick reminder this will remain available to you for another week at which time the opportunity will be closing. If you are interested, please select accordingly to move forward in the process.`;

            const remindCompleteProfile = `We are pleased to see you are interested in investing in ${companyName}! It looks like you haven’t completed your Investment Profile yet. If you could do so at your earliest convenience we will get you your documents needed to complete the investment.`;


            if (user.invitationAccepted === "1" && investmentStatus === common.investmentStatuses.invited)
                return reminderToParticipate;
            else if (user.invitationAccepted === '1' && investmentStatus === common.investmentStatuses.participate)
                return remindCompleteProfile
            else
                return reviewOpportunityEmail;
        }
        return '';
    };

    setData({
        user,
        companyName,
        investmentId,
        isFromMultipleFollowUp,
        investmentStatus,
        round,
        startupName
    }) {
        //this.setState({ user, message: this.getMessage({ companyName, user, isFromMultipleFollowUp, investmentStatus }), investmentId, isFromMultipleFollowUp })
        this.setState({ subject : (companyName ? companyName : startupName) +' - '+ round +' Investment Invitation Final Reminder' })


        let temp = this;
        temp.setState({ apiLoading: true });
        
        let tokenpass = window.localStorage.getItem('token');
        const data = {
            'investmentId' : investmentId,
            'code' : 'followup_reminder'
        };
        
        axios.post(`${common.path.base_url}/getFilterTemplate`, data, {
            headers: {
                "Authorization": tokenpass
            }
        }).then((response) => {
            const data = response.data.data[0];
            //this.getMessage({ companyName, user, isFromMultipleFollowUp, investmentStatus })
            const messageCode = data.html;
            const subjectCode = data.subject;

            temp.setState({ apiLoading: false, user, message: messageCode, investmentId, isFromMultipleFollowUp, subject: subjectCode })

        })
        .catch(function (error) {
            temp.setState({ apiLoading: false });
            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                window.localStorage.clear();
                window.location.href = '/';
                return;
            } else {
                swal({
                    title: "Error!",
                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                    icon: "error",
                    button: "Ok!",
                });
            }
        });

    }


    followUp() {
        if (this.state.isFromMultipleFollowUp) {
            //this.props.followUpMultipleInvitedUsers(this.state.message);
            this.props.handleMultipleSendFollow( this.state.message, this.state.subject );
            this.props.onFollowUpClick('clear');
        } else {
            const user = this.state.user;

            if(user.emailAddress !="" && user.emailAddress != null && user.emailAddress != undefined ) {
                let temp = this;
                temp.props.onFollowUpClick(true);
                const message = this.state.message;
                const subject = this.state.subject;
                const url = new URL(window.location.href);
                const investmentId = url.searchParams.get('id');
                let tokenpass = window.localStorage.getItem('token');
                const data = {
                    investmentId,
                    userId: user._id,
                    message,
                    subject
                };
                
                axios.post(`${common.path.base_url}/inviteUserForInvestment`, data, {
                    headers: {
                        "Authorization": tokenpass
                    }
                }).then((response) => {
                        swal({
                            title: "Follow Up Email Sent",
                            text: `Followup an email has been sent to ${user.emailAddress}`,
                            icon: "success",
                            button: "Ok!",
                        }).then(function () {

                        });
                        temp.props.onFollowUpClick(false, user);
                        temp.setState({ loading: false, user: '', message: '' });
                })
                .catch(function (error) {
                    temp.setState({ loading: false });
                    if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        window.localStorage.clear();
                        window.location.href = '/';
                        return;
                    } else {
                        swal({
                            title: "Error!",
                            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                });
            } else {
                swal({
                    title: "Please provide a valid email address.",
                    text: ``,
                    icon: "error",
                    button: "CANCEL",
                })
            }    
        }
    }

    handleMessageChange(id, data){
        this.setState({ message: data });
    }

    render() {

        return (
            <div id="followUpModal" className="modal fade">
                <div className="modal-dialog">
                    <div className="modal-content invite-contact">
                        <div className="modal-header">
                            <h4 className="modal-title">Follow Up</h4>
                        </div>
                        <div className="modal-body">
                            {  ( this.state.apiLoading ) ?

                                <div class="spinner-border" role="status" style={{ 'fontSize':'45px', 'text-align':'center' }}>
                                    <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                </div>

                                : 
                                
                                <form className="form-horizontal" id='myform'>
                                    {
                                        !this.state.isFromMultipleFollowUp &&
                                        <>
                                            <div className="form-group">
                                                <label className="col-lg-2 col-sm-3  col-md-3 control-label">Name :</label>
                                                <div className="col-lg-10 col-sm-8 col-md-8">
                                                    {this.state.user && this.state.user.firstName}
                                                    {` `}
                                                    {this.state.user && this.state.user.lastName}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-lg-2 col-sm-3  col-md-3 control-label">Email :</label>
                                                <div className="col-lg-10 col-sm-8 col-md-8">
                                                    {this.state.user && this.state.user.emailAddress}
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className="form-group">
                                        <label className="col-lg-2 col-sm-3  col-md-3 control-label">Subject :</label>
                                        <div className="col-lg-10 col-sm-8 col-md-8">
                                            <input type="text" name='subject' id="subject" className='form-control' onChange={(evt) => this.setState({ subject: evt.target.value })} value={this.state.subject} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-lg-2 col-sm-3  col-md-3 control-label">MSG :</label>
                                        <div className="col-lg-10 col-sm-8 col-md-8">
                                            {/* <textarea
                                                key={this.state.count}
                                                className="form-control" name='preferredStockTerms' id="notes" type="message" placeholder='' rows="4" cols="50"
                                                onChange={(evt) => this.setState({ message: evt.target.value })}
                                                value={this.state.message}></textarea> */}
                                            <div>
                                            <CKEditor
                                                id="emailhtml"
                                                editor={ ClassicEditor }
                                                data={ this.state.message }
                                                onInit={ editor => {
                                                    // You can store the "editor" and use when it's needed.
                                                    console.log( 'Editor is ready to use!', editor );
                                                } }
                                                onChange={ ( event, editor ) => {
                                                    const data = editor.getData();
                                                    //console.log( { event, editor, data } );
                                                    this.handleMessageChange('emailhtml', data);
                                                } }
                                            />
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            
                            }

                            
                        </div>

                        {  ( !this.state.apiLoading ) &&
                            <div className="modal-footer">
                                <LaddaButton
                                    id='lada-btn'
                                    loading={this.state.loadinglada}
                                    onClick={() => this.followUp()}
                                    data-color="#eee"
                                    data-size={XS}
                                    data-style={EXPAND_RIGHT}
                                    data-spinner-size={30}
                                    data-spinner-color="#ddd"
                                    data-spinner-lines={12}
                                    data-dismiss="modal"
                                    disabled={ (this.state.message) ? false : true}
                                >
                                    {'Follow Up'}
                                </LaddaButton>
                                <input type="button" id="cancelAgreementModal" className="btn btn-default" data-dismiss="modal" value="CANCEL" onClick={() => { }} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
export default FollowUpModal;
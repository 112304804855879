import React, { Component } from 'react';
// import './YearReview.scss';
import axios from 'axios';
import swal from 'sweetalert';
import LoadingSpinner from '../../components/Loader';
import common from '../../common';
import { withRouter } from 'react-router-dom';
import images from '../../images';
import { Document, Page, pdfjs } from 'react-pdf';


class YearReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            yearReviewFileId: null,
            numPages: null,
            setNumPages: [],
            pageNumber: 1,
            setPageNumber: 1
        }
        this.screenWidth = null;
    }
    onDocumentLoadSuccess(pdf) {
        const setNumPages = [];
        if (pdf._pdfInfo.numPages > 0) {

            for (let i = 0; i < pdf._pdfInfo.numPages; i++) {
                setNumPages.push(i + 1);
            }
        }
        this.setState({ numPages: pdf._pdfInfo.numPages, setNumPages });

    }
    componentWillMount() {
        this.setState({ loading: false });
        this.getYearInReviewPDFID();
        const url = new URL(window.location.href);
        this.investmentID = url.searchParams.get('id');
    }
    sendNotifications() {
        swal({
            title: "Confirmation",
            text: "This will send an email to all investors except for blacklisted. Please confirm.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((isSend) => {
                if (isSend) {
                    this.setState({ loading: true })
                    axios.post(`${common.path.base_url}/sendYearReviewNotofications`, {}, {
                        headers: { Authorization: window.localStorage.getItem('token') },
                    })
                        .then((response) => {
                            this.setState({ loading: false });
                            swal({
                                title: "Success!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            })
                                .then(() => {

                                });
                        })
                        .catch((error) => {
                            this.setState({ loading: false });
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else {
                                swal({
                                    title: "Error!",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                            if (error.response.data.code === 204) {
                                window.localStorage.clear();
                                window.location.href = '/';
                            }
                        });
                } else {

                }
            });
    }
    uploadPDF() {
        if (!this.state.isUpload)
            this.setState({ isUpload: true });

        document.querySelector('#directUpload').click();
    }
    getYearInReviewPDFID() {
        let temp = this;
        temp.setState({ loading: true })
        let token = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/getYearInReviewPDF?investmentId=${this.props.match.params.id}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                if (response && response.data) {
                    temp.setState({ yearReviewFileId: response.data.data })
                }

            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error && error.response && error.response.data && error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                else {
                    temp.setState({ loading: false });
                }
            });
    }
    directUpload() {
        if (!this.state.isUpload) {
            return;
        }
        const investmentID = this.props.match.params.id;
        const file = document.querySelector('#directUpload').files[0];
        if (file && file.size) {
            const temp = this;
            temp.setState({ loading: true });
            let tokenpass = window.localStorage.getItem('token');
            let data = new FormData();
            data.append('file', file);
            data.append('investmentId', investmentID);
            axios.post(`${common.path.base_url}/uploadYearInReviewPDF`, data, {
                headers: {
                    "Authorization": tokenpass
                }
            })
                .then((response) => {
                    swal({
                        title: "File Uploaded Successfully!",
                        text: response.data.message,
                        icon: "success",
                        button: "Ok!",
                    })
                        .then(function () {
                            temp.getYearInReviewPDFID()

                        });
                })
                .catch((error) => {
                    temp.setState({ loading: false });
                    if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        window.localStorage.clear();
                        window.location.href = '/';
                        return;
                    }
                    else if (error.response) {
                        swal({
                            title: "Error!",
                            text: error.response.data.message,
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                    else {
                        swal({
                            title: "Network Error!",
                            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                });
        }
        else {
            swal({
                title: "File Warning",
                text: "No Files/ Unsupported File",
                icon: "warning",
                button: "Ok!",
            });
        }
    }
    render() {
        return (
            <div className="checklist-container container-fluid">
                {this.state.loading ? <LoadingSpinner /> : ""}
                <div className="new-investment yearinreview-header">
                    <h2>TSG Review</h2>
                    <button className="tsg-btn" onClick={() => this.sendNotifications()}>SEND NOTIFICATION</button>
                </div>
                {
                    this.state.yearReviewFileId ?
                        <>
                            <div className="yearinreview-pdf-container" style={{ marginBottom: '40px' }}>
                                <Document
                                    file={`${common.path.base_url}/getFile?fileId=${this.state.yearReviewFileId}`}
                                    onLoadSuccess={(pdf) => this.onDocumentLoadSuccess(pdf)}
                                >

                                    {
                                        this.state.setNumPages.length > 0 &&
                                        this.state.setNumPages.map(page => (
                                            <Page pageNumber={page} width={this.screenWidth} className="pdf-page" />
                                        ))}
                                </Document>
                            </div>
                            <div className='change-button'>
                                <button className="tsg-btn bottom-margin" onClick={() => this.uploadPDF()}>CHANGE</button>
                                <input
                                    id='directUpload'
                                    onChange={() => this.directUpload()}
                                    type="file"
                                    style={{ display: 'none' }}
                                    accept='.pdf'
                                />
                            </div>
                        </>
                        :
                        <div className="yearinreview-content">
                            <img src={images.path.upload}></img>
                            <label>Upload TSG Review</label>
                            <>
                                <button className="tsg-btn" onClick={() => this.uploadPDF()}>UPLOAD</button>
                                <input
                                    id='directUpload'
                                    onChange={() => this.directUpload()}
                                    type="file"
                                    style={{ display: 'none' }}
                                    accept='.pdf'
                                />
                            </>
                        </div>
                }

            </div>
        );
    }
}
export default withRouter(YearReview);
import React from 'react'
import { connect } from 'react-redux';
import { Link, scroller } from 'react-scroll';
import InfiniteScroll from "react-infinite-scroll-component";
import './SidebarNotifications.scss';
import { path } from "../../imagesPath";
import SettingsModal from './NotificationSettings/SettingsModal';
import TButton from '../../../components/TButton';
import common from '../../../common';
import TimeAgo from 'react-timeago'




const SidebarNotifications = props => {
  let sideModalRefChild, inputElement = null;
  let sidebarNotificationData =  props.sidebarNotificationData();
  // if (props.isSidebarHide) {
  //   sidebarNotificationData = sidebarNotificationData.map(item => {
  //     return {
  //       ...item,
  //       isRead: true,
  //     };
  //   })
  // }
  return (
    <>
    <SettingsModal
        onRef={(ref) => sideModalRefChild = ref}
    />
    <input
      type="button" 
      style={{display: 'none'}}
      data-target={`#notificationSettingModal`}
      ref={input => inputElement = input}
      data-toggle={`modal`}
    />
      <div id="notificationSidebarList" className={props.isSidebarHide ? 'notification-sidebar' : 'notification-sidebar active'} onClick={(event) => event.stopPropagation()}>
        <div className="notification-header">
          <h2>Notifications </h2>
          <p className="sidebar-setting-link" onClick={() => {
            sideModalRefChild.setNotificationData(inputElement, (ref) => {
              ref.click();
            })
            
            props.hideNotificationSidebar()
          }}
          
          ><img src={path.sidebarNotificationSettingsIcon} alt='' />Settings</p>
        </div>
        <div className="clear"></div>
        {
          sidebarNotificationData && sidebarNotificationData.length > 0 && 
          <>
            <InfiniteScroll
              dataLength={sidebarNotificationData.length}
              next={() => props.getNotifications(true)}
              hasMore={props.hasMore}
              height={600}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              {
                sidebarNotificationData.map(item => {
                  let elementExists = document.getElementById(`invitation_${item.investmentId}`)
                  return elementExists ?
                    <Link key={item._id}  to={item.investmentId && `invitation_${item.investmentId}`} spy={true} smooth={true} offset={-100} duration={500}>
                    <div className={!item.isRead ? 'notibox-cover unread' : 'notibox-cover' } onClick={() => props.notificationRedirect(item)}>
                      <div className="notibox">
                        <div className="valtix">
                          <img src={`${common.path.base_url}/getInvestmentCompanyLogo?companyId=${item.companyId}`} alt='' />
                        </div>
                        <div className="valtix-text">
                          <h3>{item.title}</h3>
                          <p>{item.description}</p> 
                          <small><TimeAgo date={item.createdAt} /></small>
                        </div>
                      </div>
                    </div>
                    </Link> :
                    <div key={item._id} className={!item.isRead ? 'notibox-cover unread' : 'notibox-cover' } onClick={() => props.notificationRedirect(item)}>
                      <div className="notibox">
                        <div className="valtix">
                          <img src={`${common.path.base_url}/getInvestmentCompanyLogo?companyId=${item.companyId}`} alt='' />
                        </div>
                        <div className="valtix-text">
                          <h3>{item.title}</h3>
                          <p>{item.description}</p> 
                          <small><TimeAgo date={item.createdAt} /></small>
                        </div>
                      </div>
                    </div>
                })
                
              }
            </InfiniteScroll>
          
          </>
        }
      </div>

    </>
  );

}

const mapStateToProps = state => {
  return {
      isSidebarHide: state.hideSidebarNotification && state.hideSidebarNotification.isHide ? true : false
  };
};
export default connect(mapStateToProps, null)(SidebarNotifications);
import React, { Component } from "react";
//import 'react-phone-input-2/lib/style.css';
import "./UserSelectedList.scss";
import LoadingSpinner from "../Loader";
import axios from "axios";
import swal from "sweetalert";
import common from "../../common";
import LaddaButton, { XS, EXPAND_RIGHT } from "react-ladda";
import _ from "lodash";
import { Redirect } from "react-router-dom";

class UserSelectedListModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 1,
    };

    //this.selectedRemovePopup = this.selectedRemovePopup.bind(this);
    // this.closeSmsEmail = React.createRef();
  }

  componentWillMount() {
    this.props.onRef && this.props.onRef(this);
  }

  render() {
    return (
      <div>
        {/* <!-- Invite Modal HTML --> */}
        <div id="userSelectedModal" className="modal fade">
          <div className="modal-dialog " key={this.state.key}>
            <div className="modal-content invite-contact">
              <div className="modal-header">
                <h4 className="modal-title">Selected Contact </h4>
              </div>
              <div className="modal-body list-body">
                <div className="list-box">
                  {this.props.allListPopUser.map((val, index) => {
                    return (
                      <>
                        <div className="list-popup">
                          <div className="popup-username">{val.name}</div>
                          <div className="mobile-email">
                            {val.mobile}{" "}
                            {val.mobile && val.email
                              ? " | " + val.email
                              : val.email}
                          </div>
                          <div>
                            <a
                              href="javascript:void(0)"
                              className="remove-user"
                              onClick={() =>
                                this.props.selectedRemovePopup(val)
                              }
                            >
                              Remove
                            </a>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="modal-footer">
                <LaddaButton
                  id="lada-btn"
                  onClick={() => this.props.selectedSendMessagePopup()}
                  data-color="#eee"
                  data-size={XS}
                  data-style={EXPAND_RIGHT}
                  data-spinner-size={30}
                  data-spinner-color="#ddd"
                  data-spinner-lines={12}
                  style={{
                    padding: "5px 15px",
                    "font-size": "20px",
                    width: "auto",
                  }}
                >
                  SEND MESSAGE
                </LaddaButton>
                <input
                  type="button"
                  id="pop_cancel_list"
                  className="btn btn-default"
                  data-dismiss="modal"
                  value="CANCEL"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default UserSelectedListModal;

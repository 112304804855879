import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import axios from 'axios';
import common from '../../common';
import TButton from '../../components/TButton';
import Avtar from '../../components/avtar';
import images from "../../images";
import './Header.scss'
import ProfileImage from '../ProfileImage';

class ShortHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }
    componentWillMount() {
    }
    
    render() {
        
        return (
            <>
                <div className='header'>
                    <div className='navbar'>
                        <a href={this.props.redirectUrl ? this.props.redirectUrl : '/dashboard'}>
                            <img className='logoimg' src={images.path.productLogo} alt='' />
                        </a>
                    </div>
                </div>
            </>

        );
    }
}

export default ShortHeader;
export const setUserRecommendations = (payload) => {
  return {
    type: 'SET_USER_RECOMMENDATION_DATA',
    payload
  }
}
export const setCompanyData = payload => {
  return {
    type: 'SET_COMPANY_DATA',
    payload
  }
}

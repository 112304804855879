import React from 'react'
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import Header from '../../components/Header';
import InvestorInvestmentDetailContainer from './container/rootContainer';
import { hideSidebarNotification } from '../actions/hideSidebarNotifications'

const App = props => {
  const hideNotificationSidebar = () => {
    props.hideSidebarNotification()
  }
  return (
    <div onClick={() => hideNotificationSidebar()}>
      <Header
        username={window.localStorage.getItem('username')}
        role={window.localStorage.getItem('role')}
      />
      <InvestorInvestmentDetailContainer/>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    hideSidebarNotification: bindActionCreators(hideSidebarNotification, dispatch),
      
  };
};
export default withRouter(connect(null, mapDispatchToProps)(App));
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import common from '../../common';
import Header from '../../components/Header/short-header';
import './emailRedirect.scss';
import images from "../../images";
import axios from 'axios';
import swal from 'sweetalert';
import Moment from 'react-moment';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

const EmailRedirect = props => {
    
    const [checkRadio1, setCheckRadio1] = useState('');
    const [checkRadio2, setCheckRadio2] = useState('');
    const [getCompanyName, setCompanyName] = useState('');
    const [getUserName, setUserName] = useState('');
    const [getWebinarData1, setWebinarData1] = useState('');
    const [getWebinarData2, setWebinarData2] = useState('');
    const [getWebinarEmailMessage, setWebinarEmailMessage] = useState('');
    const [getWebinarRecordingUrl, setWebinarRecordingUrl] = useState('');

    useEffect(() => {
        
        const queryParams = new URLSearchParams(window.location.search);
        const investor = queryParams.get('investor');
        const investmentId = queryParams.get('investment');
        const data = {
            investmentId: investmentId,
            userId: investor
        }
        
        if( (investmentId !="" &&  investmentId !=null ) && (investor != "" && investor != null) ) {

            axios.get(`${common.path.base_url}/getWebinarInfo?investmentId=${investmentId}&investorId=${investor}`)
            .then((response) => {
                if(response.data.status == 'success') {
                    const data = response.data.data;
                    console.log(data.webinarLink1);
                    setUserName(data.userFirstName);
                    setCompanyName(data.companyName);
                    setWebinarData1(data.webinarLink1);
                    setWebinarData2(data.webinarLink2);
                    setWebinarEmailMessage(data.webinarEmailMessage);
                    setWebinarRecordingUrl(data.webinarRecording);
                } else {
                    
                    swal({
                        title: "Error!",
                        text: 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    }).then(() => { 
                        window.location.href = '/';
                    });
                }
            })
            .catch(function (error) {
                swal({
                    title: "Error!",
                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                    icon: "error",
                    button: "Ok!",
                }).then(() => { 
                    window.location.href = '/';
                });
            });
        } else {
            window.location.href = '/';
        }        
    
    }, [])

    function radioWebinarBtn(e, type) {
        if(type == 1) {
            //console.log(e.target.value);
            setCheckRadio1(e.target.value);
        } else if(type == 2) {
            setCheckRadio2(e.target.value);
        }
    }

    function webinarSubmit() {
        if( true ) {
            
            const queryParams = new URLSearchParams(window.location.search);
            const investorId = queryParams.get('investor');
            const investmentId = queryParams.get('investment');

            const data = {
                checkRadio1: checkRadio1,
                checkRadio2: checkRadio2,
                investorId : investorId,
                investmentId : investmentId
            }
            //console.log(data);
            axios.post(`${common.path.base_url}/submitWebinarInvite`, data)
            .then((response) => {
            
                swal({
                    title: "Success!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                }).then(() => { 
                    window.location.href = '/';
                });
            
            }).catch((error) => {
                
                if ( error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message ) {

                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                
                } else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                
                } else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
        }
    }

    function webinarRecordingUrl() {
        //console.log(getWebinarRecordingUrl);
        window.open(
            getWebinarRecordingUrl,
            '_blank' // <- This is what makes it open in a new window.
        );
    }

    const currDate = moment().format("YYYY-MM-DD");
    const formatedDate1 = (getWebinarData1.eventDate != null) ? moment(getWebinarData1.eventDate).format("YYYY-MM-DD") : "";
    const formatedDate2 = (getWebinarData2.eventDate != null) ? moment(getWebinarData2.eventDate).format("YYYY-MM-DD") : "";
    const pcificTime1 = (getWebinarData1.eventDate != null) ? moment(getWebinarData1.eventDate).tz("America/Los_Angeles") : "";
    const pcificTime2 = (getWebinarData2.eventDate != null) ? moment(getWebinarData2.eventDate).tz("America/Los_Angeles") : "";
   
    return (
        <>  
            <Header />
            <div className="container web-full-container">
                <div className="webinar-container">
                    <div>
                        <div className='headline-webinar'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='col-md-4'>
                                        <div className='upcoming-web'>

                                            { ( ( getWebinarRecordingUrl == null || getWebinarRecordingUrl == "" ) ) ? 
                                                "Upcoming Webinar" 
                                            : ( formatedDate1 != "" && formatedDate2 != "" ) ? "Webinar Is Over" : "" }
                                        
                                        </div>
                                    </div>
                                    <div className='col-md-8'>
                                        <img src={images.path.webinarInvite} alt="webinarInvite" className='web-invite-img'></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='col-md-12'>
                            { (getCompanyName != "") ?
                                <div className='txt-complete-code'>
                                    <div className='txt-web'>Hi <b>{getUserName},</b></div> 
                                    <div className='txt-web'>
                                            {/* (currDate < formatedDate1 || currDate < formatedDate2 ) &&      */}
                                        { ( ( getWebinarRecordingUrl == null || getWebinarRecordingUrl == "" ) ) ?
                                        <>
                                            { (getWebinarEmailMessage != null ) ?
                                                
                                                    <div className='webinar-desc' dangerouslySetInnerHTML={{ __html: getWebinarEmailMessage }}></div>

                                                    // <>
                                                    //     TSG is excited to be investing in and partnering with <b>{ getCompanyName }</b>, the leading provider of SaaS Security Management software. Please RSVP below to join one of our scheduled webinars with <b>{ getCompanyName }</b> CEO and Co-Founder, Brendan O'Connor for a company & market overview, vision for future growth, steps on how to join the partner program and participate in the exclusive TSG investment opportunity.
                                                    // </>
                                                :
                                                    <>
                                                        Please join us for an interactive <b>TSG Webinar</b> with the <b> { getCompanyName }</b> Inc executive team to learn more about <b> { getCompanyName }</b>, their partner program, and how to invest.
                                                    </>
                                            
                                            }
                                        </>
                                        :
                                        <>
                                            Please click below to watch the recorded webinar.
                                         </>
                                        }
                                    </div>
                                    
                                    { (  ( getWebinarRecordingUrl == null || getWebinarRecordingUrl == "" ) ) ?
                                    <>
                                        { ( getWebinarData1.link != "" && getWebinarData1.link != null) &&
                                            <div> 
                                                <div className='webinar-date'>
                                                    {/* <div>
                                                        <b>Local Time:</b> <Moment date={getWebinarData1.eventDate} format="dddd MMMM DD, Y hh:mm A"/> 
                                                    </div>  
                                                    <div>
                                                        <b>Pacific Time:</b> <Moment date={pcificTime1} format="dddd MMMM DD, Y hh:mm A"/> 
                                                    </div> */}

                                                    <Moment date={getWebinarData1.eventDate} format="ddd, MMM DD [at] hh:mm A"/> Local Time ( <Moment date={pcificTime1} format="ddd, MMM DD [at] hh:mm A"/> PST )

                                                </div>
                                                <div className='webinar-url'>
                                                    To join the webinar: <a href={ getWebinarData1.link }>{ getWebinarData1.link } </a>
                                                </div>  
                                                {/* <div className='webinar-desc'>
                                                    <div dangerouslySetInnerHTML={{ __html: getWebinarData1.linkDesc }}></div>
                                                </div>   */}

                                                { //( currDate < formatedDate1 ) &&
                                                    
                                                    <div className='join-radio-webinar'>
                                                        <div className='radio-webinar'>
                                                            
                                                            <input type="radio" 
                                                                className="form-check-input" 
                                                                id="radio3" name="optradio" 
                                                                value="yes" 
                                                                onChange={(event) => radioWebinarBtn(event,1) }
                                                            />&nbsp;
                                                            <label className="form-check-label" htmlFor="radio3"> Yes </label>
                                                        </div>
                                                        <div className='radio-webinar'>
                                                            <input type="radio" 
                                                                className="form-check-input" 
                                                                id="radio1" name="optradio" 
                                                                value="may_be"
                                                                onChange={(event) => radioWebinarBtn(event,1) } 
                                                            />&nbsp;
                                                            <label className="form-check-label" htmlFor="radio1"> Maybe</label>
                                                        </div>
                                                        <div className='radio-webinar'>
                                                            <input type="radio" 
                                                                className="form-check-input" 
                                                                id="radio2" name="optradio" 
                                                                value="no" 
                                                                onChange={(event) => radioWebinarBtn(event,1) }
                                                            />&nbsp;
                                                            <label className="form-check-label" htmlFor="radio2"> No</label>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }

                                        { ( getWebinarData2.link != "" && getWebinarData2.link != null) &&
                                            <div> 
                                                <div className='webinar-date'>
                                                    {/* <div>
                                                        <b>Local Time:</b> <Moment date={getWebinarData2.eventDate} format="dddd MMMM DD, Y hh:mm A"/> 
                                                    </div>  
                                                    <div>
                                                        <b>Pacific Time:</b> <Moment date={pcificTime2} format="dddd MMMM DD, Y hh:mm A"/> 
                                                    </div> */}
                                                    <Moment date={getWebinarData2.eventDate} format="ddd, MMM DD [at]  hh:mm A"/> Local Time ( <Moment date={pcificTime2} format="ddd, MMM DD [at] hh:mm A"/> PST )
                                                </div>
                                                <div className='webinar-url'>
                                                    To join the webinar: <a href={ getWebinarData2.link }>{ getWebinarData2.link } </a>
                                                </div>
                                                {/* <div className='webinar-desc' dangerouslySetInnerHTML={{ __html: getWebinarData2.linkDesc }}></div> */}
                                                
                                                { //( currDate <= formatedDate2 ) &&    

                                                    <div className='join-radio-webinar'>
                                                        <div className='radio-webinar'>
                                                            
                                                            <input type="radio" 
                                                                className="form-check-input" 
                                                                id="radio6" name="optradio2" 
                                                                value="yes" 
                                                                onChange={(event) => radioWebinarBtn(event,2) }
                                                            />&nbsp;
                                                            <label className="form-check-label" htmlFor="radio6"> Yes </label>
                                                        </div>
                                                        <div className='radio-webinar'>
                                                            <input type="radio" 
                                                                className="form-check-input" 
                                                                id="radio4" name="optradio2" 
                                                                value="may_be"
                                                                onChange={(event) => radioWebinarBtn(event,2) } 
                                                            />&nbsp;
                                                            <label className="form-check-label" htmlFor="radio4"> Maybe</label>
                                                        </div>
                                                        <div className='radio-webinar'>
                                                            <input type="radio" 
                                                                className="form-check-input" 
                                                                id="radio5" name="optradio2" 
                                                                value="no" 
                                                                onChange={(event) => radioWebinarBtn(event,2) }
                                                            />&nbsp;
                                                            <label className="form-check-label" htmlFor="radio5"> No</label>
                                                        </div>
                                                    </div>
                                                }       
                                            </div>
                                        }
                                        <div className='webinar-submit'>
                                            { //( currDate <= formatedDate1 || currDate <= formatedDate2 ) &&
                                                <button className='tsg-btn' onClick={ () => webinarSubmit() } disabled={ (checkRadio1 != "" && (checkRadio2 != "" || (getWebinarData2.link == null || getWebinarData2.link == "") )) ? false : true  } >
                                                    Submit
                                                </button>
                                            }
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className='webinar-submit'>
                                            { //( currDate <= formatedDate1 || currDate <= formatedDate2 ) &&
                                                <button className='tsg-btn' onClick={ () => webinarRecordingUrl() } >
                                                    View Webinar
                                                </button>
                                            }
                                        </div>
                                    </>
                                    }
                                </div>
                            : 
                                <div>
                                    <i className="fa fa-spinner fa-spin ex-icon" aria-hidden="true" />
                                </div>
                            } 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



export default EmailRedirect;
import React from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import './FeedbackSlack.scss'
import LaddaButton, { S, EXPAND_RIGHT } from 'react-ladda';
import axios from 'axios';
import common from '../../../common';
import swal from 'sweetalert';
import _ from 'lodash';
import { setUserLogin } from '../../../UserLogin/actions/login'
// import SlackFeedback, { themes } from 'react-slack-feedback'

class FeedbackSlack extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openForm: false,
            feedback: false,
            message: '',
            subject: '',
            firstName: '',
            lastName: '',
            email: '',
        };
    }

    openFeedbackForm() {
        if ((localStorage.getItem('isLoggedIn') && localStorage.getItem('roleCode')) != null) {
            const username = localStorage.getItem('username');
            let loggedUserName = username.split(' ');
            let userEmail = window.localStorage.getItem('emailAddress');
            console.log(userEmail);
            this.setState({ 
                firstName: loggedUserName[0],
                lastName: loggedUserName[1]
            })
            this.setState({ message: '', subject: '', email: userEmail});
        }
        if ((localStorage.getItem('isLoggedIn') && localStorage.getItem('roleCode')) == null) {
            this.setState({ message: '', subject: '', firstName: '', lastName: ''});
        }
        let value = this.state.openForm;
        this.setState({ openForm: !value });
    }

    handleChange (id,value) {
        // set values
        this.setState({ [id]: value});
        this.validateForm();
    }

    validateForm() {
        if (!_.isEmpty(this.state.subject && this.state.message && this.state.firstName && this.state.lastName && this.state.email)) {
            this.setState({feedback: true});
        } else {
            this.setState({feedback: false});
        }
    }

    saveFeedbackData() {
        console.log(this.state.subject , this.state.message);
        const temp = this;
        let token = window.localStorage.getItem('token');
        if (this.state.subject && this.state.message) {
            axios.post(`${common.path.base_url}/sendFeedback`, {
                subject: this.state.subject,
                message: this.state.message,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email
            },
            {
                headers: {
                    "Authorization": token
                }
            })
                .then(function (response) {
                    temp.setState({ loading: false });
                    console.log(response.data.message);  
                    if (response && response.data && response.data.message) {
                        swal({
                            title: "Feedback Sent",
                            text: response.data.message,
                            icon: "success",
                            button: "Ok!",
                        });
                    }
                    temp.openFeedbackForm();
                })
                .catch(function (error) {
                temp.setState({ loading: false });
                swal({
                    title: "Network Error!",
                    text: "Something went wrong",
                    icon: "error",
                    button: "Ok!",
                });
                temp.openFeedbackForm();
            });
        }
    }

    componentDidMount() {
        this.props.setUserLogin(window.localStorage.role);
    }
    
    render() {
        
        return (
            <div>
                { (this.props.userType != "TSG ADMIN") && (this.props.userType != "TSG MANAGER") &&
                
                    <button className="open-button" onClick={() => this.openFeedbackForm()}>
                        {/* <i className="fa fa-commenting" aria-hidden="true"></i> */}
                        <span>
                        Let's Connect &nbsp;<i className="fa fa-comments-o" aria-hidden="true"></i>
                        </span>
                    </button>

                }
                <div className={`chat-popup ${this.state.openForm ? 'show-menu' : ''}`} id="myForm">
                    <div className="form-container">
                        <div className="header-txt">
                            <p className="txt">Feedback</p>
                        </div>
                        <div id="feedbackDiv" className="form-group">
                            <div className="col-sm-12 col-md-12">
                                <input className="form-control" id="firstName" type="text"
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                    placeholder='First Name'
                                    value={this.state.firstName} />
                            </div>

                            <div className="col-sm-12 col-md-12">
                                <input className="form-control" id="lastName" type="text"
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                    placeholder='Last Name'
                                    value={this.state.lastName} />
                            </div>

                            <div className="col-sm-12 col-md-12">
                                <input className="form-control" id="email" type="text"
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                    placeholder='Email'
                                    value={this.state.email} />
                            </div>

                            {/* <label className="col-sm-12  col-md-12 control-label">Subject :</label> */}
                            <div className="col-sm-12 col-md-12">
                                <input className="form-control" id="subject" type="text"
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                    placeholder='Subject'
                                    value={this.state.subject} />
                            </div>
                            <div className="col-sm-12 col-md-12">
                                <textarea className="form-control" id="message" type="textarea"
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                    placeholder='Type Your Message'
                                    value={this.state.message} />
                            </div>
                        </div>
                        {/* <label for="msg"><b>Message</b></label>
                        <textarea placeholder="Type message.." name="msg" required></textarea> */}
                        <div className="btn-section">
                            <input type="button" id="cancelFeedback" className="btn btn-default" data-dismiss="modal" onClick={() => this.openFeedbackForm()} value="CANCEL"  />
                            <div className={!this.state.feedback ? 'feedbackButton disable' : 'feedbackButton enable'}>
                                <LaddaButton
                                    id="feedback-btn"
                                    loading={this.state.loadinglada}
                                    onClick={() => this.saveFeedbackData()}
                                    data-color="#eee"
                                    data-size={S}
                                    data-style={EXPAND_RIGHT}
                                    data-spinner-size={30}
                                    data-spinner-color="#ddd"
                                    data-spinner-lines={12}
                                    className={!this.state.feedback ? 'disable' : 'enable'}
                                >
                                    SUBMIT
                                </LaddaButton>
                            </div>
                            {/* <button type="submit" className="btn">Send</button> */}
                            {/* <button type="button" className="btn cancel" onClick={() => this.openFeedbackForm()}>Close</button> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
 
const mapDispatchToProps = (dispatch) => {
    return {
        setUserLogin: bindActionCreators(setUserLogin, dispatch),
    };
};

const mapStateToProps = state => {
    return {
        userType: state.userLogin.userType
    };
};

//export default FeedbackSlack
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FeedbackSlack));
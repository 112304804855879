import React, { Component } from 'react';
import _ from 'lodash';
import ReactTags from 'react-tag-autocomplete'
import axios from 'axios';
import TButton from '../../components/TButton';
import './InvestmentDetail.scss';
import common from '../../common';
import HeaderImage from '../../components/HeaderImage';
import Header from '../../components/Header';
import swal from 'sweetalert';
import SendMessageVIPModal from './SendMessageVIPModal';

class InvestmentVIPAccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            closedinvestmentUsers: [],
            pagination: {
                currentPage: 0,
                totalPages: 1,
            },
            tags: [],
            searchInputTags: [],
            suggestions: [
                { id: 'YPO', name: 'YPO' },
                { id: 'Institutional', name: 'Institutional' },
                { id: 'Strategic', name: 'Strategic' },
                { id: 'Preferred', name: 'Preferred' },
                { id: 'Investment(s)', name: 'Investment(s)' }],
            arrowType: {
                name: 'down',
                emailAddress: 'down',
                company: 'down',
            },
            $sort: null,
            investmentcompanyname: '',
            Role: '',
            userName: '',
        }
    }
    componentWillMount() {
        this.setState({ userName: window.localStorage.getItem('username'), })
        this.setState({ Role: window.localStorage.getItem('role') });
        this.setState({ investmentcompanyname: window.localStorage.getItem('investmentCompanyName') });
        const url = new URL(window.location.href);
        this.InvestmentId = url.searchParams.get('InvestmentID');

    }
    getData() {
        let roleCode = window.localStorage.getItem('roleCode');
        if (roleCode === '003' || roleCode === '004' || roleCode === '005') {
            window.location.href = '/';
            return;
        }
        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loading: true });
        const data = {
            investmentId: this.InvestmentId,
            tags: this.state.searchInputTags.name,
        };
        axios.post(`${common.path.base_url}/getVIPAccessUsers`, data, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false, closedinvestmentUsers: response.data.data, pagination: { currentPage: response.data.currentPage, totalPages: response.data.totalPages }, searchInputTags: [], tags: [] });
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                window.location.href = '/';
            });
    }


    mapTags(tags) {
        if (!tags) {
            tags = this.state.tags;
        }
        let tagIds = []
        if (tags && tags.length > 0) {
            tagIds = tags.map(tag => tag.id)
        }
        return tagIds;
    }



    handleDelete(i) {
        const tags = this.state.tags.slice(0)
        tags.splice(i, 1)
        this.setState({ tags });
    }

    handleAddition(tag) {
        if (tag && tag.id && tag.name) {
            const isExist = this.state.tags.filter(item => item.id === tag.id);
            if (isExist && isExist.length > 0) {
                return;
            }
            const tags = [].concat(this.state.tags, tag);
            this.setState({ tags });

        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, false);
        this.getData();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
    }


    handleScroll = () => {
        if ((Math.round(window.innerHeight + document.documentElement.scrollTop) === document.documentElement.offsetHeight)
            && (this.state.pagination.currentPage < this.state.pagination.totalPages)) {

        }
    };

    handleSearchInputDelete(i) {
        const tags = this.state.searchInputTags.slice(0)
        tags.splice(i, 1)
        this.setState({ searchInputTags: tags }, () => {
            this.handleSearch(this.state.searchInputTags);
        });
    }

    handleSearchInputAddition(tag) {
        const tags = [].concat(this.state.searchInputTags, tag);
        this.setState({ searchInputTags: tags }, () => {
            this.handleSearch(this.state.searchInputTags);
        });
    }

    handleSearch = (tags) => {
        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loading: true });
        const data = {
            investmentId: this.InvestmentId
        };
        if (tags && tags.length > 0) {
            data.tags = tags.map(tag => tag.name);
        }
        axios.post(`${common.path.base_url}/getVIPAccessUsers`, data, {
            headers: {
                "Authorization": token
            }
        })
            .then(function (response) {
                temp.setState({ loading: false, closedinvestmentUsers: response.data.data, pagination: { currentPage: response.data.currentPage, totalPages: response.data.totalPages } });
            })
            .catch(() => { });
    }
    toggleArrow(fieldType) {
        let arrowType = this.state.arrowType;
        let $sort = {};
        if (arrowType[fieldType] === 'down') {
            arrowType[fieldType] = 'up';
            $sort[fieldType] = -1;
        } else {
            arrowType[fieldType] = 'down';
            $sort[fieldType] = 1;
        }
        this.setState({ arrowType, $sort });
    }

    sendMessageHandler(loading, user) {
        if (user) {
            const sendMessage = [...this.state.sendMessage];
            sendMessage[user._id] = true;
            this.setState({ sendMessage });
            this.props.showLoading(loading);
        } else if (loading === 'clear') {
            this.setState({ searchInputTags: [] })
        } else {
            this.setState({ loading: true });
        }

    }

    changeStatus(userid, isVIPAccess) {
        swal({
            title: "Are you sure?",
            text: isVIPAccess ? "You want to remove VIP access to this user." : "You want to give VIP access to this user.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willUnlock) => {
                if (willUnlock) {
                    let temp = this;
                    let token = window.localStorage.getItem('token');
                    temp.setState({ loading: true });
                    const data = {
                        investmentId: this.InvestmentId,
                        userId: userid,
                    };
                    axios.post(`${common.path.base_url}/updateVIPAccessUsers`, data, {
                        headers: {
                            "Authorization": token
                        }
                    })
                        .then(function (response) {
                            temp.getData();
                            swal({
                                title: "Updated!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            })
                        })
                        .catch(function (error) {
                            swal({
                                title: "Error!",
                                text: error.response.data.message,
                                icon: "error",
                                button: "Ok!",
                            });
                            temp.setState({ loading: false, loadinglada2: false });
                        });
                }
            });
    }
    render() {
        return (
            <div>
                <SendMessageVIPModal
                    onRef={(ref) => this.sendMessageChild = ref}
                    onSendMessageClick={(loading, user) => this.sendMessageHandler(loading, user)}
                // sendMessageMultipleUsers={(message, type) => this.props.sendMessageMultipleUsers(message, type)}
                />
                <Header
                    username={this.state.userName}
                    role={this.state.Role}
                />
                <HeaderImage
                    heading={this.state.Role}
                />
                <div>
                    <div className="header-section wrapper dashboad-header-section">
                        <div className="header-txt">{this.state.investmentcompanyname}</div>
                    </div>
                    <div className="hr-line wrapper"></div>
                    <div className='header-investment exit-in-process search-message searchpadding'>
                        <div className='search' style={{ width: '100%' }}>
                            <ReactTags
                                allowNew={true}
                                placeholder='Search'
                                suggestions={this.state.suggestions}
                                tags={this.state.searchInputTags}
                                handleDelete={this.handleSearchInputDelete.bind(this)}
                                handleAddition={this.handleSearchInputAddition.bind(this)} />
                        </div>
                        {/* <div className='send-message'>
                            <TButton text='Send Message' className='invite-btn'
                                onClick={() => {
                                    this.setState(this.sendMessageChild.resetSendMessageModal(), this.sendMessageChild.setData({
                                        investmentId: this.InvestmentId,
                                        isFromMultipleSendMessage: true,
                                        type: 'exitedUsers',
                                    }));
                                }}
                                dataTarget={`#sendMessageModal`}
                                dataToggle={`modal`}
                            // disabled={!areCheckboxActive}
                            />
                        </div> */}
                    </div>
                </div>
                <div className="custom-table-view paddingleftright">
                    <table className="table table-custom">
                        <thead>
                            <tr>
                                <th>NAME<i className={`arrow ${this.state.arrowType['name']}`} onClick={() => this.toggleArrow('name')}></i></th>
                                <th>EMAIL<i className={`arrow ${this.state.arrowType['emailAddress']}`} onClick={() => this.toggleArrow('emailAddress')}></i></th>
                                <th>STAGE</th>
                                <th>ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.closedinvestmentUsers.length > 0 &&
                                this.state.closedinvestmentUsers.map(user => {
                                    return (
                                        <tr key={user._id}>
                                            <td className="username-row" onClick={() => {

                                                this.props.history.push(`/viewProfile?id=${user.userId}`)
                                            }}>
                                                {user.name}
                                            </td>
                                            <td className="col-xs-3 col-sm-4 col-md-4 col-lg-4">
                                                {user.emailAddress}
                                            </td>
                                            <td className="col-xs-3 col-sm-1 col-md-1 col-lg-1">
                                                {user.stage}
                                            </td>
                                            <td className="col-xs-3 col-sm-5 col-md-5 col-lg-5">
                                                <div className="action-buttons">
                                                    <TButton text={user && user.isVIPAccess ? `REMOVE VIP ACCESS` : `GIVE VIP ACCESS`} className='invite-btn' disabled={false} onClick={() => { this.changeStatus(user.userId, user.isVIPAccess) }} />
                                                    <TButton text='SEND MESSAGE' className='invite-btn'
                                                        onClick={() => {
                                                            this.setState(this.sendMessageChild.resetSendMessageModal(), this.sendMessageChild.setData({
                                                                user: user,
                                                                isFromMultipleSendMessage: false,
                                                            }));
                                                        }}
                                                        dataTarget={`#sendMessageModal`}
                                                        dataToggle={`modal`}
                                                    // disabled={!areCheckboxActive}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                this.state.closedinvestmentUsers.length === 0 &&
                                <tr>
                                    <td>No investors found!</td>
                                </tr>

                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

}

export default InvestmentVIPAccess;
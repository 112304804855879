/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./Start.scss";
import ReferAFriendModal from "../../../components/Header/referFriend";

const DashboardStart = (props) => {
  return (
    <>
      <ReferAFriendModal onRef={(ref) => ref} />
      <div className="deashbord">
        <div className="container-fluid">
          <div className="container">
            <h5
              style={{
                color: " #6F8198",
                fontSize: "16px",
                opacity: 1,
              }}
            >
              Welcome!
            </h5>
            {
              !props.adminAccess && !props.fullName && (
                <h2
                  style={{ display: "flex", justifyContent: "start", gap: "10px" }}
                >
                  <a
                    className="dashName"
                    href={`/viewProfile?id=${!props.investorId}`}
                  >
                    {props.fullName}
                  </a>
                </h2>
              )}

            {
              !props.adminAccess && props.fullName && (
                <h1
                  style={{color: " #000", display: "flex", justifyContent: "start" }}
                >
                  <a
                    className="dashName"
                    href={`/viewProfile?id=${props.investorId}`}
                  >
                    {props.fullName}
                  </a>
                </h1>)
            }

            {/* {
                !props.adminAccess &&
                <div className="btn-box">
                  <Button
                    className='btn btn-danger'
                    title='Year In Review'
                    
                />
                </div>
            } */}
          </div>
        </div>
      </div>
    </>)
};

export default DashboardStart;

const initialState = {
  type: 'grid'
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LIST':
      return {
        type: 'list',
      };
    case 'GRID':
      return {
        type: 'grid',
      };
    default:
      return state;
  }
}

export default reducer
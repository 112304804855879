import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import axios from 'axios';
import images from "../../images";
import common from '../../common';
import swal from 'sweetalert';
import './pipelineCss/pipeline.scss';
import './pipelineCss/default.scss';
import { Icons } from './Icons';
import NumberFormat from 'react-number-format';
import EngageData from './EngageData';

const TsgOpportunity = (props) => {

    const { companyId, companyData } = props;

    const [generalCall, setGeneralCall] = useState(false);
    const [advisorFeedback, setAdvisorFeedback] = useState("");
    const [partnerFeedback, setPartnerFeedback] = useState("");

    const [engageArr, setEngageArr] = useState([]);
    const [engageTd1, setEngageTd1] = useState('');
    const [engageTd2, setEngageTd2] = useState('');
    const [engageTd3, setEngageTd3] = useState('');
    const [engageTd4, setEngageTd4] = useState('');
    const [engageTd5, setEngageTd5] = useState('');
    const [engageTd6, setEngageTd6] = useState('');
    const [engageTd7, setEngageTd7] = useState('');
    const [engageTd8, setEngageTd8] = useState('');

    const inputHandlerChange = (e)  => {

        const inputId = e.target.id;
        const inputValue = e.target.value;

        setGeneralCall(true);
        
        if( inputId == 'advisorFeedback' ) {
            setAdvisorFeedback(inputValue);
        } else if( inputId == 'partnerFeedback' ) {
            setPartnerFeedback(inputValue);
        }
    }
    
    const addNewSave = () => {

        if( engageTd1.trim() != "" ) { 
            
            setGeneralCall(true);
            const newArr = {
                'round' : engageTd1.trim(),
                'totalRaise' : engageTd2.trim(),
                'allocation' : engageTd3.trim(),
                'pps' : engageTd4.trim(),
                'postValuation' : engageTd5.trim(),
                'leadInvestor' : engageTd6.trim(),
                'proRata' : engageTd7.trim(),
                'timeline' : engageTd8.trim(),
            }

            console.log(newArr);
            setEngageArr([...engageArr, newArr]);
            clearEngageRow();
        
        } else {
        
            swal({
                title: 'Please enter round',
                icon: 'error',
                confirmButtonText: 'Yes, delete it!',
                reverseButtons: true,
            });
        }    
    }

    const updateEngage = (index, updateArr) => {

        setGeneralCall(true);
        engageArr[index] = updateArr;
        setEngageArr([...engageArr]);
        //console.log(index, updateArr);
    }

    const clearEngageRow = () => {

        setEngageTd1('');
        setEngageTd2('');
        setEngageTd3('');
        setEngageTd4('');
        setEngageTd5('');
        setEngageTd6('');
        setEngageTd7('');
        setEngageTd8('');
    }
    
    useEffect(() => {
        
        let token = window.localStorage.getItem('token');
        
        if( generalCall ) {
            if( (  companyId == "" || companyId == null || companyId == undefined ) ){

                swal({
                    title: 'Please enter company name',
                    icon: 'error',
                    confirmButtonText: 'Yes, delete it!',
                    reverseButtons: true,
                });

            } else {

                const timer = setTimeout(() => {
                    
                    let dataInfo = new FormData();
                    const data = {
                        'companyId' : companyId,
                        'advisorFeedback' : advisorFeedback,
                        'partnerFeedback' : partnerFeedback,
                        'engage' : engageArr,
                    }
                    dataInfo.append('data', JSON.stringify(data));
                    axios.post(`${common.path.base_url}/addNewPipline`, dataInfo, {
                        headers: {
                            "Authorization": token
                        }
                    })
                    .then((response) => {
                        const savedData = response.data.data;
                    })
                    .catch((error) => {
                        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        
                            window.localStorage.clear();
                            window.location.href = '/';
                            return;
                        } else {
                            swal({
                                title: "Error! #33005",
                                text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                icon: "error",
                                button: "Ok!",
                            });
                        }
                    });

                }, 1500);
                return () => clearTimeout(timer);
            }
        }
    
    }, [ advisorFeedback, partnerFeedback, engageArr ]);

    useEffect(() => {
        if( companyData != "" && companyData != undefined && companyData != null) {
            setAdvisorFeedback(companyData.advisorFeedback);
            setPartnerFeedback(companyData.partnerFeedback);
            setEngageArr(companyData.engage);
        }
    }, [companyData]);

    const engageDeleteHandler = (index) => {
        
        swal({
            title: 'Are you sure you want to delete?',
            text: "This item will be delete immediately. You can't undo this action.",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#7A8DA7',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true,
        }).then((resp) => {
            if(resp) {
                
                setGeneralCall(true);
                engageArr.splice(index, 1); 
                setEngageArr([...engageArr]);
            }
        }) 
    }

    return (
        <>
            <div className='sectionHeading'>TSG Opportunity</div>
            <div>
                <div className='oppCont border-b'>
                    <div className='sectionHeading2 mt-25'>TSG Advisor Feedback</div>
                    <div className='mt-10'>
                        <textarea className="textArea" id="advisorFeedback" placeholder="Enter TSG Advisor Feedback" value={ advisorFeedback } onChange={ (e) => { inputHandlerChange( e )  } } ></textarea>
                    </div>
                </div>
            </div>
            <div className='mt-25'>
                <div className='oppCont border-b'>
                    <div className='sectionHeading2 mt-25'>Channel Partner Feedback</div>
                    <div className='mt-10'>
                        <textarea className="textArea" id="partnerFeedback" placeholder="Enter Channel Partner Feedback" value={ partnerFeedback } onChange={ (e) => { inputHandlerChange( e )  } }></textarea>
                    </div>
                </div>
            </div>
            <div>
                <div className='sectionHeading2 mt-25'>How would TSG engage?</div>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className="table-responsive-lg">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Round</th>
                                        <th scope="col">Total Raise</th>
                                        <th scope="col">TSG Allocation</th>
                                        <th scope="col">PPS</th>
                                        <th scope="col">Post Valuation</th>
                                        <th scope="col">Lead Investor</th>
                                        <th scope="col">Insiders Taking Pro-Rata?</th>
                                        <th scope="col">Timeline</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                { (engageArr.length > 0) ?
                                    engageArr.map((singleData, i) => { 
                                        return <EngageData 
                                                    key = { i +"_engage" }
                                                    singleData = {singleData}
                                                    index = { i }
                                                    engageDeleteHandler = { (i) => engageDeleteHandler(i) }
                                                    updateEngage = { (i, updateArr) => updateEngage(i, updateArr) }
                                                />
                                    }) 
                                    :
                                    ""
                                }
                                    <tr className='seedFixedtr'>
                                        <td>
                                            <input type="text" className='compState piple-input' placeholder='Enter Round' value={ engageTd1 } onChange={ (e) => setEngageTd1(e.target.value) } />
                                        </td>
                                        <td>
                                            <NumberFormat
                                                value={ engageTd2 }
                                                thousandSeparator={true}
                                                id="totalCustomers"
                                                prefix={'$'}
                                                fixedDecimalScale={true}
                                                placeholder='Enter Total Raise'
                                                className='compState piple-input'
                                                onChange={ (e) => setEngageTd2(e.target.value) }
                                            />
                                        </td>
                                        <td>
                                            <NumberFormat
                                                value={ engageTd3 }
                                                thousandSeparator={true}
                                                id="totalCustomers"
                                                prefix={'$'}
                                                fixedDecimalScale={true}
                                                placeholder='Enter Allocation'
                                                className='compState piple-input'
                                                onChange={ (e) => setEngageTd3(e.target.value) }
                                            />
                                        </td>
                                        <td>
                                            <NumberFormat
                                                value={ engageTd4 }
                                                thousandSeparator={true}
                                                id="totalCustomers"
                                                prefix={'$'}
                                                fixedDecimalScale={true}
                                                placeholder='Enter PPS'
                                                className='compState piple-input'
                                                onChange={ (e) => setEngageTd4(e.target.value) }
                                            />
                                        </td>
                                        <td>
                                            <NumberFormat
                                                value={ engageTd5 }
                                                thousandSeparator={true}
                                                id="totalCustomers"
                                                prefix={'$'}
                                                fixedDecimalScale={true}
                                                placeholder='Enter Post Valuation'
                                                className='compState piple-input'
                                                onChange={ (e) => setEngageTd5(e.target.value) }
                                            />
                                        </td>
                                        <td>
                                            <input type="text" className='compState piple-input' placeholder='Enter Lead Investor' value={ engageTd6 } onChange={ (e) => setEngageTd6(e.target.value) } />
                                        </td>
                                        <td>
                                            <input type="text" className='compState piple-input' placeholder='Enter Pro-Rata' value={ engageTd7 } onChange={ (e) => setEngageTd7(e.target.value) } />
                                        </td>
                                        <td>
                                            <input type="text" className='compState piple-input' placeholder='Enter Timeline' value={ engageTd8 } onChange={ (e) => setEngageTd8(e.target.value) } />
                                        </td>
                                        <td>
                                            <div title='Clear' className='tableTrashicon' onClick={() => { clearEngageRow() }}>{Icons.RemoveG}</div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='addmoreCont' onClick={ (e) => addNewSave(e) }>
                    <div className='addmoreList' >+Add / Save</div>
                </div>
            </div>
        </>
    )
}

export default TsgOpportunity;
import React from 'react'
import { hydrate, render } from "react-dom";
import { Provider } from 'react-redux'
import { Router } from 'react-router'
import store, { history } from './store'
import App from './containers/App';
import LogOutInactiveUser from './containers/App/LogOutInactiveUser';
import FeedbackSlack from './containers/App/FeedbackSlack/FeedbackSlack'
import './index.css'

const target = document.querySelector('#root')
render(<Provider store={store}>
    <Router history={history}>
        <div>
            <LogOutInactiveUser />
            <FeedbackSlack />
            <App />
        </div>
    </Router>
</Provider>, target);

const initialState = {
  investmentProcess: null,
  userData: null,
  
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_INVESTMENT_PROCESS_DATA':
        return { ...state, investmentProcess: action.payload };
    case 'SET_INVESTMENT_USER_DATA':
          return { ...state, userData: action.payload };
    default:
      return state;
  }
}

export default reducer
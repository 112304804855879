import React, { Component } from 'react';
import ReactTags from 'react-tag-autocomplete'
import NumberFormat from 'react-number-format';
import DatePicker from "react-datepicker";

import './InvestmentDetail.scss';
import TButton from '../../components/TButton';
import common from '../../common';
import SendMessageModal from './SendMessageModal';

class Exited extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sendMessage: [],
            searchInputTags: [],
            suggestions: [
                { id: 'YPO', name: 'YPO' },
                { id: 'Institutional', name: 'Institutional' },
                { id: 'Strategic', name: 'Strategic' },
                { id: 'Preferred', name: 'Preferred' },
                { id: 'Investment(s)', name: 'Investment(s)' }],
            arrowType: {
                name: 'down',
                emailId: 'down',
                executedDate: 'down',
                company: 'down',
                amount: 'down',
            } 
        }
    }
    componentDidMount() {
        if (this.props.id) {
            this.props.history.push(`/investmentDetail?id=${this.props.id}&activeTab=exited`)
        }
        
        window.addEventListener('scroll', this.handleScroll, false);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
    }
    handleScroll = () => {
        if ((Math.round(window.innerHeight + document.documentElement.scrollTop) === document.documentElement.offsetHeight)
            && (this.props.pagination.currentPage < this.props.pagination.totalPages)) {
            this.props.getExitedUsers(true);
        }
    };
    mapDateFormat(mongoDate) {
        if (!mongoDate) return 'N/A';
        const monthNames = [
            "01", "02", "03",
            "04", "05", "06", "07",
            "08", "09", "10",
            "11", "12"
        ];
        let date = new Date(mongoDate);
        let day = date.getDate();
        let monthIndex = date.getMonth();
        let year = date.getFullYear();
        return monthNames[monthIndex] + '/' + day + '/' + year;

    }

    mapTags(tags) {
        if (!tags) {
            tags = this.state.searchInputTags;
        }
        let tagIds = []
        if (tags && tags.length > 0) {
            tagIds = tags.map(tag => tag.name)
        }
        return tagIds;
    }
    handleSearchInputDelete(i) {
        const tags = this.state.searchInputTags.slice(0)
        tags.splice(i, 1)
        this.setState({ searchInputTags: tags }, () => {
            this.props.getExitedUsers(false, this.mapTags())
        });
    }
    handleSearchInputAddition(tag) {
        const tags = [].concat(this.state.searchInputTags, tag);
        this.setState({ searchInputTags: tags }, () => {
            this.props.getExitedUsers(false, this.mapTags())
        });
    }
    viewDocumentRedirect(envId, type, number){
        window.open(` ${common.path.doc_download_url}/downloadPDF?envId=${envId}&type=${type}&number=${number}`, '_blank', '', 2) 
    
    }

    toggleArrow(fieldType) {
        let arrowType  = this.state.arrowType;
        let $sort = {};
        if (arrowType[fieldType] === 'down') {
            arrowType[fieldType] = 'up';
            $sort[fieldType] =  -1;
        } else {
            arrowType[fieldType] = 'down';
            $sort[fieldType] = 1;
        }
        this.props.getExitedUsers(false, this.mapTags(), $sort)
        this.setState({arrowType, $sort});
    }
    sendMessageHandler(loading, user) {
        if (user) {
            const sendMessage = [...this.state.sendMessage];
            sendMessage[user._id] = true;
            this.setState({ sendMessage });
            this.props.showLoading(loading);
        } else if (loading === 'clear') {
            this.setState({searchInputTags: []})
        } else {
            this.props.showLoading(loading);
        }
        
    }

    render() {
        const areCheckboxActive = this.props.getCheckedExitedUsers().length > 0 ? true : false;
        return (
            <div className="box-wrapper-main">
                <SendMessageModal
                    onRef={(ref) => this.sendMessageChild = ref}
                    onSendMessageClick={(loading, user) => this.sendMessageHandler(loading, user)}
                    sendMessageMultipleUsers={(message, type) => this.props.sendMessageMultipleUsers(message, type)}
                />
             
                <div className='header-investment exit-in-process'>
                    <div className="row searchpaddingtwopart">
                        <div className='search' style={{ width: '90%' }}>
                            <ReactTags
                                allowNew={true}
                                placeholder='Search'
                                suggestions={this.state.suggestions}
                                tags={this.state.searchInputTags}
                                handleDelete={this.handleSearchInputDelete.bind(this)}
                                handleAddition={this.handleSearchInputAddition.bind(this)} />
                        </div>
                        <div className='send-message'>
                            <TButton text={this.props.getCheckedExitedUsers().length > 0 ? `Send Message (${this.props.getCheckedExitedUsers().length})` : 'Send Message' } className='invite-btn'
                                onClick={() => {
                                    this.setState(this.sendMessageChild.resetSendMessageModal(), this.sendMessageChild.setData({
                                        investmentId: this.props.id,
                                        isFromMultipleSendMessage: true,
                                        type: 'exitedUsers',
                                    }));
                                }}
                                dataTarget={`#sendMessageModal`}
                                dataToggle={`modal`}
                                disabled={!areCheckboxActive}
                             />
                        </div>
                </div>
                <div className="custom-table-view table-responsive">
                    <table className="table table-custom sortable">
                        <thead>
                            <tr>
                                <th>
                                    <input
                                        type="checkbox"
                                        onChange={(event) => this.props.onCheckedAllExitedUsers(event)}
                                        checked={this.props.isAllowExitedUsersChecked}
                                    />
                                </th>
                                <th>NAME<i className={`arrow ${this.state.arrowType['name']}`} onClick={() => this.toggleArrow('name')}></i></th>
                                <th>EMAIL<i className={`arrow ${this.state.arrowType['emailId']}`} onClick={() => this.toggleArrow('emailId')}></i></th>
                                <th>DATE<i className={`arrow ${this.state.arrowType['executedDate']}`} onClick={() => this.toggleArrow('executedDate')}></i></th>
                                <th>COMPANY<i className={`arrow ${this.state.arrowType['company']}`} onClick={() => this.toggleArrow('company')}></i></th>
                                <th>AMOUNT<i className={`arrow ${this.state.arrowType['amount']}`} onClick={() => this.toggleArrow('amount')}></i></th>
                                <th>EXITED</th>
                                <th>PROFIT/LOSS</th>
                                <th>TYPE</th>
                                <th>ACTION</th>
                            </tr>   
                        </thead>
                        <tbody>
                            {
                                this.props.exitedUsers.length > 0 &&
                                this.props.exitedUsers.map(user => {
                                    return (
                                        <tr key={user._id}>
                                            <td className='customcheck'>
                                                {<>
                                                    <input
                                                        type="checkbox"
                                                        onChange={(event) => this.props.onCheckedSingleExitedUser(event, user.user._id)}
                                                        checked={user.isChecked}
                                                    />
                                                    <label></label>
                                                </>
                                                }
                                            </td>
                                            <td className="username-row" 
                                            // onClick={() => this.props.history.push(`/viewProfile?id=${user.user._id}`)}
                                            onClick={() => { this.props.showDataInPopup(user.user._id) }}>
                                                {`${user.firstName} ${user.lastName}`}
                                            </td>
                                            <td>
                                                {user.emailId}
                                            </td>
                                            <td>
                                                {this.mapDateFormat(user.executedDate)}
                                            </td>
                                            <td>
                                                {user.investorCompanyName ? user.investorCompanyName : user.companyName ? user.companyName : ''}
                                            </td>
                                            <td>
                                                <NumberFormat
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                    value={user.amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                            </td>
                                            <td>
                                                <NumberFormat
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                    value={user.amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                            </td>
                                            <td>
                                                <NumberFormat
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                    value={user.amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                            </td>
                                            <td>
                                                IPO
                                            </td>
                                            <td>
                                                <TButton text='Send Message' className='invite-btn' 
                                                    onClick={() => {
                                                        this.setState(this.sendMessageChild.resetSendMessageModal(), this.sendMessageChild.setData({
                                                            user: user.user,
                                                            investmentId: this.props.id,
                                                            isFromMultipleSendMessage: false,
                                                            investmentStatus: user.status,
                                                            type: 'exitedUsers',
                                                        }));
                                                    }}
                                                    dataTarget={`#sendMessageModal`}
                                                    dataToggle={`modal`}
                                                      />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                this.props.exitedUsers.length === 0 &&
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td> No investors found for Exited!</td>
                                </tr>
                            }

                        </tbody>
                    </table>
                </div>
            </div>
            </div>
        );
    }

}

export default Exited;
import React, { Component } from 'react';
import './IIR.scss'
import { Route, Link } from 'react-router-dom'
import HeaderImage from '../../components/HeaderImage';
import LoadingSpinner from '../../components/Loader';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import Header from '../../components/Header';
import CompanyIIR from './CompanyIIR';
import Leadership from './Leadership';
import MajorInvestor from './MajorInvestor';
import CurrentPartners from './CurrentPartners';
import KeyInvestor from './KeyInvestor';
import Board from './Board';
import Press from './Press';
import MarketOverview from './MarketOverview';
import HistAndProjRevenue from './HistAndProjRevenue';
import CompetitionDesc from './CompetitionDesc';
import ProblemStatementDesc from './ProblemStatementDesc';
import ProductDesc from './ProductDesc';
import FundingDesc from './FundingDesc';
import SolutionSummaryDesc from './SolutionSummaryDesc';
import Differentiation from './Differentiation';
import MarketPerf from './MarketPerf';
import MarketTrend from './MarketTrend';
import FoundLetter from './FoundLetter';
import axios from 'axios';
import swal from 'sweetalert';
import common from '../../common';
import TButton from '../../components/TButton';
import InvestmentOverview from './InvestmentOverview';

let userRole = '000';
let userRoleCode = '000';
const RenderIirComponents = React.memo((props) => {
    const {match, setIirList} = props
    return (
        <div>
            <Route path={`${match.url}/company`} component={() => <CompanyIIR setIirList={setIirList} />} />
            <Route path={`${match.url}/leadership`} component={() => <Leadership setIirList={setIirList} />} />
            <Route path={`${match.url}/majorInvestor`} component={() => <MajorInvestor setIirList={setIirList} {...props} />} />
            <Route path={`${match.url}/currentPartners`} component={() => <CurrentPartners setIirList={setIirList} {...props} />} />
            <Route path={`${match.url}/majorInvestors`} component={() => <MajorInvestor setIirList={setIirList} {...props} />} />
            <Route path={`${match.url}/keyCustomers`} component={() => <KeyInvestor setIirList={setIirList} {...props} />} />
            <Route path={`${match.url}/board`} component={() => <Board setIirList={setIirList} />} />
            <Route path={`${match.url}/press`} component={() => <Press setIirList={setIirList} />} />
            <Route path={`${match.url}/marketSize`} component={() => <MarketOverview setIirList={setIirList} />} />
            <Route path={`${match.url}/historicalandProjectedRevenue`} component={() => <HistAndProjRevenue setIirList={setIirList} />} />

            <Route path={`${match.url}/competitionDescription`} component={() => <CompetitionDesc setIirList={setIirList} />} />
            <Route path={`${match.url}/competition`} component={() => <CompetitionDesc setIirList={setIirList} />} />

            <Route path={`${match.url}/productStatementDescription`} component={() => <ProblemStatementDesc setIirList={setIirList} {...props} />} />
            <Route path={`${match.url}/productStatement`} component={() => <ProblemStatementDesc setIirList={setIirList} {...props} />} />

            <Route path={`${match.url}/productDescription`} component={() => <ProductDesc setIirList={setIirList} />} />
            <Route path={`${match.url}/product`} component={() => <ProductDesc setIirList={setIirList} />} />

            <Route path={`${match.url}/fundingDescription`} component={() => <FundingDesc setIirList={setIirList} />} />
            <Route path={`${match.url}/funding`} component={() => <FundingDesc setIirList={setIirList} />} />

            <Route path={`${match.url}/solutionSummaryDescription`} component={() => <SolutionSummaryDesc setIirList={setIirList} />} />
            <Route path={`${match.url}/solutionSummary`} component={() => <SolutionSummaryDesc setIirList={setIirList} />} />

            <Route path={`${match.url}/differentiation`} component={() => <Differentiation setIirList={setIirList} />} />

            <Route path={`${match.url}/marketPerformanceandSummary`} component={() => <MarketPerf setIirList={setIirList} />} />
            <Route path={`${match.url}/marketTrends`} component={() => <MarketTrend setIirList={setIirList} />} />
            <Route path={`${match.url}/founderLetter`} component={() => <FoundLetter setIirList={setIirList} />} />
            <Route path={`${match.url}/executiveLetter`} component={() => <FoundLetter setIirList={setIirList} />} />
            <Route path={`${match.url}/investmentoverview`} component={() => <InvestmentOverview setIirList={setIirList} />} />
        </div>
    )
})
class IIR extends Component {
    constructor(props) {
        super(props);
        this.companyId = '';
        this.investmentId = '';
        this.selectedPath = '';
        this.investmentName = '';
        this.state = {
            loading: false,
            loadinglada: false,
            loadinglada2: false,
            show: false,
            Role: '',
            name: '',
            userId: '',
            iirList: [],
            selectedPath: '',
            roleCode: '',
            // isApproved: false,
            iirPDF: null,
            isUpload: false,
            investmentData: null,
            companyinvestment: '',
            isStartupApproved: '',
        }
    }

    componentWillMount() {
        const url = new URL(window.location.href);
        this.companyId = url.searchParams.get('companyId');
        this.investmentId = url.searchParams.get('investmentId');
        this.selectedPath = url.pathname;
        userRole = window.localStorage.getItem('role');
        userRoleCode = window.localStorage.getItem('roleCode');
        this.setState({
            name: window.localStorage.getItem('username'),
            userId: window.localStorage.getItem('id'),
            roleCode: window.localStorage.getItem('roleCode'),
            Role: userRole,
        });
        // this.checkStatusAndRedirect();

    }

    componentDidMount() {
        this.getInvestment();
        this.getIIRList();
        
        this.getIIRPDF();
        console.log('adauhujuendkjd')
    }


    componentWillReceiveProps(props) {
        console.log('poropshasjahdsdajdhagdahjdj')
        this.selectedPath = props.location.pathname;
    }

    notifyIIR() {
        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loadinglada: true })
        axios.post(`${common.path.base_url}/notifyIIR`, {
            companyId: this.companyId,
            investmentId: this.investmentId,
        }, {
            headers: {
                "Authorization": token
            }
        })
            .then(function (response) {
                temp.setState({ loadinglada: false })
                swal({
                    title: "Updated!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });
            });
    }

    approveIir() {
        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loadinglada2: true })
        axios.put(`${common.path.base_url}/isApproved`, {
            companyId: this.companyId,
            investmentId: this.investmentId,
        }, {
            headers: {
                "Authorization": token
            }
        })
            .then(function (response) {
                temp.setState({ loadinglada2: false })
                swal({
                    title: "Updated!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada2: false });
            });
    }
    getInvestment() {
        let temp = this;
        let token = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/investmentById/${this.investmentId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((investment) => {
                const investmentData = investment.data.data;
                temp.setState({ isStartupApproved: investmentData.iirReviewApproveStatus.isApproveByStartup });
                temp.setState({ companyinvestment: investmentData.name });
                temp.setState({ investmentData });
                temp.investmentName = investmentData;
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });

    }

    getIIRList() {
        let temp = this;
        let token = window.localStorage.getItem('token');
        // let CompanyId = window.localStorage.getItem('companyId');
        // temp.setState({ loading: true });
        // const id = window.localStorage.getItem('id');
        // this.setState({ id: id })
        // axios.get(`${common.path.base_url}/getIIR/${this.investmentId}`, {
        axios.get(`${common.path.base_url}/getInvestmentIIR/${this.investmentId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                const sampleArray = response.data.data;
                // temp.setState({ isApproved: sampleArray.isApproved });
                const keys = Object.keys(sampleArray)
                const values = Object.values(sampleArray)
                let z = [];
                keys.forEach((key, i) => {
                    //z.push({ title: this.mapKeyTitle(key), state: values[i] , path : key })
                    z.push(common.iirMapKey({ key, values, i }))
                })
                z.forEach((ele, i) => {
                    let title = ele.path.split(" ");
                    let path = '';
                    for (const word of title) {
                        path += word;
                    }
                    path = path.substring(0, 1).toLowerCase() + path.substring(1);
                    ele.path = path;
                })
                z.length = common.iirArrayLength;
                const sortedArray =  z.sort((a, b) => {
                    return common.orderMap[a.title] - common.orderMap[b.title];
                  });
                this.setState({ iirList: sortedArray })
                this.setState({ iirList: z })

            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else
                    if (error.response) {
                        swal({
                            title: "Error!",
                            text: error.response.data.message,
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                    else {
                        swal({
                            title: "Network Error!",
                            text: "Something went wrong",
                            icon: "error",
                            button: "Ok!",
                        });
                    }
            });
    }

    setIirList = (value) => {
        this.setState({ iirList: value })
    }

    // for manually marking IIR list as complete/incomplete
    checkIIR = (state, path) => {
        // option only available for TSG admin and manager
        if (this.state.roleCode == "001" || this.state.roleCode == "002") {

            // tempText is the secondary main text that is displayed based on condition
            let tempText = "";

            if (state === true) {
                tempText = "Mark this section as incomplete ?";
            }
            else {
                tempText = "Mark this section as complete ?";
            }

            swal(
                {
                    title: "Check IIR",
                    text: tempText,
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                // if the user press ok
                .then((check) => {
                    const token = localStorage.getItem('token');
                    if (check) {
                        this.setState({ loading: true })

                        // Making the api connection to make the changes in DB
                        axios.put(`${common.path.base_url}/updateIIRList`,
                            {
                                key: path,
                                value: !state,
                                companyId: this.companyId,
                                investmentId: this.investmentId,
                            },
                            {
                                headers: {
                                    "Authorization": token
                                }
                            })
                            // if success then update
                            .then((response) => {
                                this.setState({ loading: false });
                                swal({
                                    title: "Section updated Successfully!",
                                    text: response.data.message,
                                    icon: "success",
                                    button: "Ok!",
                                })
                                    .then(() => {
                                        window.location.reload();
                                    });
                            })
                            // if error display and revert
                            .catch((error) => {
                                this.setState({ loading: false });
                                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                    window.localStorage.clear();
                                    window.location.href = '/';
                                    return;
                                }
                                else {
                                    swal({
                                        title: "Error!",
                                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                        icon: "error",
                                        button: "Ok!",
                                    });
                                }
                            });
                    }
                });
        }
    };

    renderIirList(match) {
        return (
            this.state.iirList.map((list, i) => {
                // var stateCount = this.state.iirList.filter(v => v.state).length;
                const c = 1;
                let state = false;
                if (i == 0) {
                    state = this.state.iirList[i].state;
                } else {
                    state = this.state.iirList[i - 1].state;
                }

                return (
                    <div className="chk-list">
                        <div className={`circle  ${this.selectedPath === `${match.url}/${list.path}` ? 'circle-red' : `${this.state.roleCode == '001' || this.state.roleCode == '002' ? `${list.state ? `checked-circle` : ``}` : ``}`} `} onClick={(event) => this.checkIIR(list.state, list.path)}><p>{i + 1}</p></div>
                        <i style={{
                            display: this.selectedPath === `${match.url}/${list.path}` ? 'none' : `${this.state.roleCode == '001' || this.state.roleCode == '002' ? `${list.state ? `block` : ``}` : ``}`,
                        }}
                            className={`fa fa-check-circle  ${list.status ? `checked-circle` : ``}`} onClick={() => this.checkIIR(list.state, list.path)} aria-hidden="true"></i>

                        {/* {i===0?this.state.iirList[0].state?<Link className={`iir-title ${this.selectedPath === `${match.url}/${list.path}` ? 'iir-checked-title' : `${list.state ? 'iir-checked-title' : ''}`} ${list.state ? '' : 'disabled'}`}
                            to={`${match.url}/company?companyId=${this.companyId}`} >{list.title}</Link>:<h3 className={`${this.selectedPath === `${match.url}/${list.path}` ? 'iir-checked-title' : ''}`}>{list.title}</h3>:state?<Link className={`iir-title ${this.selectedPath === `${match.url}/${list.path}` ? 'iir-checked-title' : `${list.state ? 'iir-checked-title' : ''}`} ${list.state ? '' : 'disabled'}`}
                            to={`${match.url}/${list.path}?companyId=${this.companyId}`} >{list.title}</Link> : <h3 className={`${this.selectedPath === `${match.url}/${list.path}` ? 'iir-checked-title' : ''}`}>{list.title}</h3>} */}

                        {/* {this.state.iirList[0].state ?<Link className={`iir-title ${this.selectedPath === `${match.url}/${list.path}` ? 'iir-checked-title' : `${list.state ? 'iir-checked-title' : ''}`} ${list.state ? '' : 'disabled'}`}
                            to={`${match.url}/company?companyId=${this.companyId}`} >{list.title}</Link>:<h3 className={`${this.selectedPath === `${match.url}/${list.path}` ? 'iir-checked-title' : ''}`}>{list.title}</h3>} */}


                        {/* <Link className={`iir-title ${this.selectedPath === `${match.url}/${list.path}` ? 'iir-checked-title' : `${list.state ? 'iir-checked-title' : ''}`} ${list.state ? '' : ''}`}
                            to={`${match.url}/${list.path}?companyId=${this.companyId}`} >{list.title}</Link>  */}
                        <Link className={`iir-title ${this.selectedPath === `${match.url}/${list.path}` ? 'iir-checked-title' : `${this.state.roleCode == '001' || this.state.roleCode == '002' ? `${list.state ? `iir-checked-title` : ``}` : ``}`}`}
                            to={`${match.url}/${list.path}?companyId=${this.companyId}&investmentId=${this.investmentId}`} >{list.title == 'Historical and Projected Revenue' ? 'Historical and Projected Revenue' : list.title}</Link>

                    </div>
                )
            })
        )
    }

    uploadPDF() {
        if (!this.state.isUpload)
            this.setState({ isUpload: true });

        document.querySelector('#directUpload').click();
    }
    getIIRPDF() {
        let temp = this;
        let token = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/getIIR/${this.companyId}/${this.investmentId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                if (response && response.data && response.data.data && response.data.data.iirPDF) {
                    temp.setState({ iirPDF: response.data.data.iirPDF })
                }

            })
            .catch(function (error) {
                temp.setState({ loadinglada: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error && error.response && error.response.data && error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    directUpload() {
        if (!this.state.isUpload) {
            return;
        }
        const file = document.querySelector('#directUpload').files[0];
        if (file && file.size) {
            const temp = this;
            temp.setState({ loading: true });
            let tokenpass = window.localStorage.getItem('token');
            let data = new FormData();
            data.append('file', file);
            data.append('investmentId', this.investmentId);
            axios.post(`${common.path.base_url}/uploadIIRPDF`, data, {
                headers: {
                    "Authorization": tokenpass
                }
            })
                .then((response) => {
                    swal({
                        title: "File Uploaded Successfully!",
                        text: response.data.message,
                        icon: "success",
                        button: "Ok!",
                    })
                        .then(function () {
                            temp.getIIRPDF()

                        });
                })
                .catch((error) => {
                    temp.setState({ loading: false });
                    if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        window.localStorage.clear();
                        window.location.href = '/';
                        return;
                    }
                    else if (error.response) {
                        swal({
                            title: "Error!",
                            text: error.response.data.message,
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                    else {
                        swal({
                            title: "Network Error!",
                            text: "Something went wrong",
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                });
        }
        else {
            swal({
                title: "File Warning",
                text: "No Files/ Unsupported File",
                icon: "warning",
                button: "Ok!",
            });
        }
    }
    viewPDF() {
        window.location.href = `/iirPreviewPDF?companyId=${this.companyId}&investmentId=${this.investmentId}`
    }
    deleteIIRPDF() {
        const temp = this;
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this file",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                const token = localStorage.getItem('token');
                if (willDelete) {
                    //@TODO CALL DELETE CONTACT API HERE WITH NECESSARY CHECKS
                    this.setState({ loading: true })
                    axios.delete(`${common.path.base_url}/deleteIIRPDF`,
                        {
                            headers:
                            {
                                "Authorization": token
                            },
                            data: { investmentId: this.investmentId, iirPDFId: this.state.iirPDF }
                        }
                    )
                        //@TODO CALL ON SUCCESS OF API
                        .then((response) => {
                            swal({
                                title: "File deleted Successfully!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            })
                                .then(function () {
                                    temp.setState({ iirPDF: null });
                                    temp.getIIRPDF()

                                });
                        })
                        .catch((error) => {
                            this.setState({ loading: false });
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else {
                                swal({
                                    title: "Error!",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });
                }
                else {
                    swal({ text: `file is safe!`, icon: 'info' });
                }
            });
    }
    validatateForApprove() {
        if (this.state.investmentData && this.state.investmentData.iirReviewApproveStatus) {
            const iirReviewApproveStatus = this.state.investmentData.iirReviewApproveStatus;
            const { isNotifiedByTSG, isApproveByStartup, c } = iirReviewApproveStatus;
            if (isNotifiedByTSG && isApproveByStartup && isApproveByStartup) {
                return true;
            }
        }
        return false;
    }
    unlockIIR() {
        const temp = this;
        swal({
            title: "Are you sure?",
            text: "Once unlocked, you'll need approval again from startup admin.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willUnlock) => {
                const token = localStorage.getItem('token');
                if (willUnlock) {
                    let token = window.localStorage.getItem('token');
                    temp.setState({ loading: true })
                    temp.setState({ loadinglada2: true })
                    axios.put(`${common.path.base_url}/unlockInvestmentIIR`, {
                        companyId: this.companyId,
                        investmentId: this.investmentId,
                    }, {
                        headers: {
                            "Authorization": token
                        }
                    })
                        .then(function (response) {
                            temp.setState({ loading: false, loadinglada2: false })
                            temp.getInvestment();
                            swal({
                                title: "Updated!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            })
                        })
                        .catch(function (error) {
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else {
                                swal({
                                    title: "Error!",
                                    text: error.response.data.message,
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                            temp.setState({ loading: false, loadinglada2: false });
                        });
                }
            });
    }
    syncButtonHandler = () => {
        swal({
            title: 'Are you sure you want to sync IIR with SCP?',
            text: "This data will be synced. You can't undo this action.",
            icon: 'warning',
            buttons: true,
        }).then((resp) => {
            if(resp) {
                this.setState({ loading: true});
                this.syncCompanytoIIR();
            }
        }) 
    }
    
    syncCompanytoIIR = async () => {
        let token = window.localStorage.getItem('token');
        const response = await axios.get(`${common.path.base_url}/syncCompanytoIIR/${this.companyId}/${this.investmentId}`,
        {
            headers: {
                "Authorization": token
            }
        });
        if(response.data){
            this.setState({ loading: false });
            swal({
                title: "Success",
                text: response.data.message,
                icon: "success",
                button: "Ok!",
            }).then(function() {
                window.location.reload();
            });
        } else if(!response.data) {
            swal({
                title: "Error!",
                text: response.data.message,
                icon: "error",
                button: "Ok!",
            });
        }
    }

    render() {
        const { match } = this.props;
        const iirLeftStyle = {
            width: 'auto', maxWidth: '100%', display: 'block', padding: '42px 30px', textAlign: 'center',
        }
        const iirPrevBtnStyle = {
            flexDirection: 'column', alignItems: 'center'
        }
        const noneStyle = {}
        const companyNickname = window.localStorage.getItem("companyNickname");
        const companyName = window.localStorage.getItem("companyName");
        return (
            <div>
                {this.state.loading ? <LoadingSpinner /> : ""}
                <Header
                    username={this.state.name}
                    role={this.state.Role}
                />
                {(this.state.roleCode === '003' || this.state.roleCode === '004') ?
                    <HeaderImage
                        heading={`${companyNickname ? companyNickname : companyName ? companyName : ''}`}
                    />
                    : <HeaderImage
                        heading={this.state.Role}
                    />
                }
                <div className='iir-main'>
                    <div className='iir-left' style={this.validatateForApprove() ? iirLeftStyle : noneStyle}>
                        <h3>{this.state.companyinvestment}</h3>
                        <>
                        <div className="col-md-12 col-sm-12 iir-left-panel">
                        <div className='iirPreview-btn' style={this.validatateForApprove() ? iirPrevBtnStyle : noneStyle}>
                            {this.validatateForApprove() && (this.state.roleCode === '003' || this.state.roleCode === '004') && <p>Your IIR is approved By TSG Admin. Please Click On Preview button to Preview IIR</p>}
                            {this.validatateForApprove() && (this.state.roleCode === '001' || this.state.roleCode === '002') && <p>You and Startup Admin have approved the IIR. It is now locked.</p>}
                            {!this.validatateForApprove() && (this.state.roleCode === '001' || this.state.roleCode === '002') ?
                                <div className='previewAndNotifyBut'>
                                    <LaddaButton
                                            className='notify-iir'
                                            loading={this.state.loadinglada}
                                            data-color="#eee"
                                            data-size={XS}
                                            onClick={() => this.props.history.push(`/previewIIR?companyId=${this.companyId}&investmentId=${this.investmentId}`)}
                                            data-style={EXPAND_RIGHT}
                                            data-spinner-size={30}
                                            data-spinner-color="#ddd"
                                            data-spinner-lines={12}
                                        >PREVIEW</LaddaButton>
                                        <LaddaButton
                                            className='notify-iir'
                                            loading={this.state.loadinglada}
                                            data-color="#eee"
                                            data-size={XS}
                                            onClick={() => {this.syncButtonHandler() }}
                                            data-style={EXPAND_RIGHT}
                                            data-spinner-size={30}
                                            data-spinner-color="#ddd"
                                            data-spinner-lines={12}
                                        >SYNC
                                        </LaddaButton>
                                </div>
                                :
                                <div className='previewAndUnlockBut'>
                                    <TButton
                                        className={`preview-iir1 ${this.validatateForApprove() ? 'client-preview' : ''}`} onClick={() => this.props.history.push(`/previewIIR?companyId=${this.companyId}&investmentId=${this.investmentId}`)} text={`PREVIEW`} />
                                    {
                                        this.validatateForApprove() && (this.state.roleCode === '001' || this.state.roleCode === '002') &&
                                        <>
                                            <br />
                                            <TButton
                                                className={`preview-iir1 ${this.validatateForApprove() ? 'client-preview' : ''}`} onClick={() => this.unlockIIR()} text={`UNLOCK`} />
                                        </>
                                    }

                                </div>
                            }
                            <div className='previewAndNotifyBut'>
                                {this.validatateForApprove() && (this.state.roleCode === '001' || this.state.roleCode === '002') ?
                                    <>
                                        {
                                            this.state.iirPDF &&
                                            <div className='viewIIRPDF'>
                                                <LaddaButton
                                                    className='notify-iir'
                                                    data-color="#ef534a"
                                                    onClick={() => this.viewPDF()}
                                                    data-size={XS}
                                                    data-style={EXPAND_RIGHT}
                                                    data-spinner-size={30}
                                                    data-spinner-color="#ef534a"
                                                    data-spinner-lines={12}
                                                >VIEW IIR PDF
                                                </LaddaButton>
                                            </div>
                                        }
                                        {
                                            this.state.iirPDF &&
                                            <div className='viewIIRPDF'>
                                                <LaddaButton
                                                    className='approve-iir'
                                                    data-color="#ef534a"
                                                    onClick={() => this.deleteIIRPDF()}
                                                    data-size={XS}
                                                    data-style={EXPAND_RIGHT}
                                                    data-spinner-size={30}
                                                    data-spinner-color="#ef534a"
                                                    data-spinner-lines={12}
                                                >DELETE IIR PDF
                                                </LaddaButton>
                                            </div>
                                        }
                                    </>
                                    :
                                    <>
                                        <>
                                            {
                                                this.state.iirPDF &&
                                                <LaddaButton
                                                    className='notify-iir'
                                                    data-color="#ef534a"
                                                    onClick={() => this.viewPDF()}
                                                    data-size={XS}
                                                    data-style={EXPAND_RIGHT}
                                                    data-spinner-size={30}
                                                    data-spinner-color="#ef534a"
                                                    data-spinner-lines={12}
                                                >VIEW IIR PDF
                                                </LaddaButton>
                                            }
                                            {
                                                this.state.iirPDF &&
                                                <LaddaButton
                                                    className='approve-iir'
                                                    data-color="#ef534a"
                                                    onClick={() => this.deleteIIRPDF()}
                                                    data-size={XS}
                                                    data-style={EXPAND_RIGHT}
                                                    data-spinner-size={30}
                                                    data-spinner-color="#ef534a"
                                                    data-spinner-lines={12}
                                                >DELETE IIR PDF
                                                </LaddaButton>
                                            }
                                        </>
                                    </>
                                }
                            </div>
                            <>
                                {!this.validatateForApprove() && (this.state.roleCode === '001' || this.state.roleCode === '002') ?
                                    <>
                                    <div className='previewAndNotifyBut'>
                                        {/* <TButton className={`preview-iir`} onClick={() => this.props.history.push(`/preview?companyId=${this.companyId}`)} text={`PREVIEW`} /> */}
                                        {/* <LaddaButton
                                            className='notify-iir'
                                            loading={this.state.loadinglada}
                                            data-color="#eee"
                                            data-size={XS}
                                            onClick={() => this.props.history.push(`/previewIIR?companyId=${this.companyId}&investmentId=${this.investmentId}`)}
                                            data-style={EXPAND_RIGHT}
                                            data-spinner-size={30}
                                            data-spinner-color="#ddd"
                                            data-spinner-lines={12}
                                        >PREVIEW</LaddaButton> */}
                                        {
                                            (!this.validatateForApprove() && (this.state.roleCode === '001' || this.state.roleCode === '002')) &&
                                            <>
                                                <LaddaButton
                                                    className='approve-iir'
                                                    data-color="#ef534a"
                                                    onClick={() => this.uploadPDF()}
                                                    data-size={XS}
                                                    data-style={EXPAND_RIGHT}
                                                    data-spinner-size={30}
                                                    data-spinner-color="#ef534a"
                                                    data-spinner-lines={12}
                                                >Upload IIR PDF
                                                </LaddaButton>
                                                <input
                                                    id='directUpload'
                                                    onChange={() => this.directUpload()}
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    accept='.pdf'
                                                />
                                            </>
                                        } 
                                        <LaddaButton
                                            className='notify-iir'
                                            loading={this.state.loadinglada}
                                            data-color="#eee"
                                            data-size={XS}
                                            onClick={() => this.notifyIIR()}
                                            data-style={EXPAND_RIGHT}
                                            data-spinner-size={30}
                                            data-spinner-color="#ddd"
                                            data-spinner-lines={12}
                                        >NOTIFY</LaddaButton>
                                </div>

                                    </> :
                                    ''
                                }
                            </>
                            <>
                                {!this.validatateForApprove() && (this.state.roleCode === '001' || this.state.roleCode === '002') ? <div className="previewAndNotifyBut mt-10">
                                        <LaddaButton
                                            className={this.state.isStartupApproved ? 'approve-iir' : 'disable isapproved-iir'}
                                            loading={this.state.loadinglada2}
                                            data-color="#eee"
                                            data-size={XS}
                                            onClick={() => this.approveIir()}
                                            data-style={EXPAND_RIGHT}
                                            data-spinner-size={30}
                                            data-spinner-color="#ddd"
                                            data-spinner-lines={12}
                                        >APPROVE</LaddaButton>
                                    </div> : ''
                                }
                            </>
                        </div>
                        </div>
                        </>
                        {!this.validatateForApprove() && this.state.isStartupApproved && (this.state.roleCode === '001' || this.state.roleCode === '002') ?
                            <>
                                <h5>Startup has approved the IIR.</h5>
                            </>
                            : this.state.isStartupApproved === false && (this.state.roleCode === '001' || this.state.roleCode === '002') ?
                                <>
                                    <h6>Pending approval from the startup.</h6>
                                </>
                                : <>
                                    <h6><div style={{ margin: "5% auto" }}></div></h6>
                                </>
                        }
                        {this.validatateForApprove() ? '' : this.renderIirList(match)}
                    </div>
                    <div className={`iir-right`} style={{ display: this.validatateForApprove() ? 'none' : '' }}>
                        {this.validatateForApprove() ? '' : <RenderIirComponents match={match} setIirList={this.setIirList} {...this.props}/>}
                    </div>
                </div>
            </div>
        )
    }
}
export default IIR;
const reducer = (state = {}, action) => {
  if (action && action.type && !action.fromToDo) {
    switch (action.type) {
      case 'No Thanks':
        return {
          ...state,
          id: `${action.id}_no_thanks`
        };
      case 'Learn More':
        return {
          ...state,
          id: `${action.id}_learn_more`
        } ;
      case 'Participate':
        return {
          ...state,
          id: `${action.id}_participate`
        } ;
      case 'First Line':
        return {
          ...state,
          id:`${action.id}_first_line`
        };
      case 'Reset': 
      return {
        ...state,
        id: null,
      };
      default:
        return state;
    }
  }
   return state;
  
}

export default reducer
export const setToDoPopover = (type, id) => {
    return {
        type,
        id,
        fromToDo: true,
    }
}

export const resetToDoPopover = () => ({
    type: 'Reset',
    fromToDo: true,
})
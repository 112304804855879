import React from 'react';
import './IIR Loader.scss';
const LoadingSpinner1 = () => (
    <div className="loaderCss1">
          <div>
    <i className="fa fa-spinner fa-spin" /> 
  </div>
    </div>

);

export default LoadingSpinner1;


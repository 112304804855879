import axios from "axios";
import React, { Component, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import swal from "sweetalert";
import common from "../../../../common";
import BankAccountVerification from "../../../../components/Header/bankAccountVerification";
import LoadingSpinner from "../../../../components/Loader";
import RequiredSign from "../../../../components/RequiredSign";
import TButton from "../../../../components/TButton";
import "../../../components/PendingCounterSigningInvestments/PendingCounterSigningInvestments.scss";
import { path } from "../../../imagesPath";
import "./DetailView.scss";

// Custom hook to listen to window resize events
function useScreenWidthLessThan768() {
  const [isLessThan768, setIsLessThan768] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsLessThan768(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures this effect only runs once after initial render

  return isLessThan768;
}

// Higher Order Component to use the hook and pass values as props
function withWindowSize(Component) {
  return function WrappedComponent(props) {
    const size = useScreenWidthLessThan768();
    return <Component {...props} windowSize={size} />;
  };
}

class DetailView extends Component {
  constructor() {
    super();
    this.state = {
      userDetailData: null,
      signOA: null,
      bankAccountId: null,
      isBankChecked: null,
      verivendData: null,
      verivendId: null,
      achNickname: "",
      achAccountNumber: "",
      achAccountName: "",
      achRoutingNumber: "",
      amount: "",
      walletBalanceData: {},
      bankAccounts: [],
      loadinglada: false,
      loading: false,
      setBankData: false,
      currentTab: 0,
      transferAmount: 0,
      legelName:null,
      isLegalNameConfirmed: false,
      userTransactionData:null,
    };
  }
  componentDidMount() {
    const url = new URL(window.location.href);
    this.userId = window.localStorage.getItem("id");
    this.investorId = url.searchParams.get("investorId");
    if (this.investorId) {
      this.userId = this.investorId;
    }
    this.getUserById();
    this.getUserTransactionHistory();
  }

  getUserById() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    axios
      .get(`${common.path.base_url}/getUserById?_id=${this.userId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        temp.setState({ loading: false });
        temp.setState({ userDetailData: response.data.data });
        temp.setState({ legelName : response.data.data.name})
        if (response.data.data && response.data.data.verivendId) {
          temp.setState({
            showWalletButton: false,
            verivendId: response.data.data.verivendId,
          });
          this.getUserVerivendData(response.data.data.verivendId);
          this.getWalletBalance(response.data.data.verivendId);
        } else {
          temp.setState({ showWalletButton: true });
          temp.setState({ currentTab: 0 });
        }
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error! #451220",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : "Something Went Wrong",
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }

  getUserVerivendData(verivendId) {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    axios
      .get(`${common.path.base_url}/userVerivendData/${verivendId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        temp.setState({ loading: false });
        if (response.data.data && response.data.data.verivendData) {
          const totalResponse = response.data.data;
          temp.setState({
            verivendData: response.data.data.verivendData,
            bankAccounts: response.data.data.bankAccounts || [],
            currentTab: 1,
          });
          if (
            totalResponse.bankAccounts &&
            totalResponse.bankAccounts.length > 0
          ) {
            const bankAccount =
              totalResponse.bankAccounts[totalResponse.bankAccounts.length - 1];
            temp.setState({
              achNickname: bankAccount.bankAccountName,
              achAccountNumber: bankAccount.bankAccountNumber,
              achAccountName: bankAccount.bankAccountOwner,
              achRoutingNumber: bankAccount.bankRoutingNumber,
              setBankData: true,
              currentTab: 2,
            });
          }
        }
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error! #451220",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : "Something Went Wrong",
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }

  createWalletHandler() {
    let tokenpass = window.localStorage.getItem("token");
    const emailAddress = window.localStorage.getItem("emailAddress");
    const userId = window.localStorage.getItem("id");
    const data = {
      orgName: `${this.state.legelName}`,
      emailAddress: `${emailAddress}`,
      userId,
    };
    let temp = this;
    temp.setState({ loading: true });
    axios
      .post(`${common.path.base_url}/createOrganization`, data, {
        headers: {
          Authorization: tokenpass,
        },
      })
      .then((response) => {
        temp.setState({ loading: false });
        if (response.data) {
          swal({
            title: "Success",
            text: response.data.message,
            icon: "success",
            button: "Ok!",
          }).then(() => {
            temp.handleCurrentTab(1);
            temp.setState({ userDetailData: response.data.data, verivendId: response.data.data.verivendId });
            temp.getUserVerivendData(response.data.data.verivendId);
          });
        }
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          }).then(() => {
            window.location.reload();
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }

  handleCurrentTab(value) {
    this.setState({ currentTab: value });
  }

  intiateMicroDeposit(bankAccountId) {
    let temp = this;
    temp.setState({ loading: true });
    let tokenpass = window.localStorage.getItem("token");
    const data = {
      bankAccountId,
      orgId: this.state.verivendData.orgId,
    };
    axios
      .post(`${common.path.base_url}/intiateBankVerify`, data, {
        headers: {
          Authorization: tokenpass,
        },
      })
      .then((response) => {
        temp.setState({ loading: false });
        swal({
          title: "Success",
          text: response.data.message,
          icon: "success",
          button: "Ok!",
        }).then(() => {
          temp.getUserVerivendData(temp.state.verivendId);
        });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }

  async deleteBankAccount(bankAccountId,status) {
    let temp = this;
    let tokenpass = window.localStorage.getItem("token");
    let isDelete = false;

    if (status === 'VERIFIED') {
      const willDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this Bank Account",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })

      if (willDelete) {
        isDelete = willDelete;
      } else {
        swal({ text: `This Bank Account is safe!`, icon: 'info' });
      }
    }

    if (status !== 'VERIFIED' || isDelete) {
      temp.setState({ loading: true });
      axios
      .delete(`${common.path.base_url}/deleteBankAccount/${this.state.verivendId}/${bankAccountId}`, {
        headers: {
            Authorization: tokenpass,
          },
        })
        .then((response) => {
          temp.setState({ loading: false });
          swal({
            title: "Success",
            text: response.data.message,
            icon: "success",
            button: "Ok!",
          }).then(() => {
            temp.getUserVerivendData(temp.state.verivendId);
          });
        })
        .catch(function (error) {
          temp.setState({ loading: false });
          if (
            error.response &&
            error.response.data &&
            error.response.data.message &&
            common.AUTH_EXPIRED === error.response.data.message
          ) {
            window.localStorage.clear();
            window.location.href = "/";
            return;
          } else if (error.response) {
            swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              button: "Ok!",
            });
          } else {
            swal({
              title: "Network Error!",
              text:
                error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        });
    }
  }

  async getUserTransactionHistory() {
    let temp = this;
    let tokenpass = window.localStorage.getItem("token");

    temp.setState({ loading: true });
    axios
      .get(`${common.path.base_url}/getUserTransactionHistory/${this.userId}`, {
        headers: {
          Authorization: tokenpass,
        },
      })
      .then((response) => {
        temp.setState({ loading: false });
        temp.setState({ userTransactionData: response.data.data})
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }

  mapDateFormat(mongoDate) {
    if (!mongoDate) return 'N/A';
    const monthNames = [
      "01", "02", "03",
      "04", "05", "06", "07",
      "08", "09", "10",
      "11", "12"
    ];
    let date = new Date(mongoDate);
    let day = date.getDate();
    let monthIndex = date.getMonth();
    let year = date.getFullYear();
    return monthNames[monthIndex] + '/' + day + '/' + year;

  }

  clearForm() {
    this.setState({
      achNickname: "",
      achAccountNumber: "",
      achAccountName: "",
      achRoutingNumber: "",
      setBankData: false,
    });
  }

  transferFundsToBankAccount() {
    let temp = this;
    temp.setState({ loading: true });
    let tokenpass = window.localStorage.getItem("token");
    const data = {
      amount: Number(this.state.transferAmount),
      bankAccountId: this.state.bankAccountId,
    };
    if (data.amount <= this.state.walletBalanceData.currentBalance) {
      axios
        .post(
          `${common.path.base_url}/transferToBankAccount/${this.state.verivendData._id}`,
          data,
          {
            headers: {
              Authorization: tokenpass,
            },
          }
        )
        .then((response) => {
          temp.setState({ loading: false });
          swal({
            title: "Success",
            text: response.data.message,
            icon: "success",
            button: "Ok!",
          }).then(() => {
            temp.setState({ currentTab: 3 });
            temp.setState({
              isBankChecked: null,
              bankAccount: null,
              transferAmount: 0,
            });
            temp.getWalletBalance(this.state.verivendId);
          });
        })
        .catch(function (error) {
          temp.setState({ loading: false });
          if (
            error.response &&
            error.response.data &&
            error.response.data.message &&
            common.AUTH_EXPIRED === error.response.data.message
          ) {
            window.localStorage.clear();
            window.location.href = "/";
            return;
          } else if (error.response) {
            swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              button: "Ok!",
            });
          } else {
            swal({
              title: "Network Error!",
              text:
                error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        });
    } else {
      temp.setState({ loading: false });
      swal({
        title: "Error!",
        text: "You don't have enough balance in wallet to fund your bank account",
        icon: "error",
        button: "Ok!",
      });
    }
  }

  addBankAccountHandler() {
    let temp = this;
    temp.setState({ loading: true });
    let tokenpass = window.localStorage.getItem("token");
    const data = {
      name: this.state.achNickname,
      accNumber: this.state.achAccountNumber,
      accRoutingNumber: this.state.achRoutingNumber,
      accOwnerName: this.state.achAccountName,
    };
    axios
      .post(
        `${common.path.base_url}/addBankAccount/${this.state.verivendData._id}`,
        data,
        {
          headers: {
            Authorization: tokenpass,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });

        swal({
          title: "Success",
          text: response.data.message,
          icon: "success",
          button: "Ok!",
        }).then(() => {
          temp.setState({ currentTab: 2 });
          temp.getUserVerivendData(this.state.verivendData._id);
        });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }

  getWalletBalance(verivendId) {
    let temp = this;
    const token = window.localStorage.getItem("token");
    temp.setState({ loding: true });
    axios
      .get(`${common.path.base_url}/getWalletBalance/${verivendId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        temp.setState({ loding: false });
        if (response.data && response.data.data) {
          temp.setState({ walletBalanceData: response.data.data });
        }
      })
      .catch((error) => {
        temp.setState({ loding: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else {
          swal({
            title: "Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : "Something Went Wrong",
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }

  setBankAccountId(bankAccountId) {
    this.setState({ bankAccountId: bankAccountId });
  }

  isValidate() {
    if (this.state.bankAccounts.length > 0) {
      return this.state.setBankData;
    }
    return (
      this.state.achRoutingNumber === "" &&
      this.state.achAccountName === "" &&
      this.state.achNickname === "" &&
      this.state.achAccountNumber === ""
    );
  }
  showLastFourChars(str) {
    if (str.length <= 4) {
      return str;
    }
    const lastFourChars = str.slice(-4);
    const remainingLength = str.length - 4;
    const replacement = "*".repeat(remainingLength);
    return replacement + lastFourChars;
  }

  handleBankAccount(id) {
    this.setState({ isBankChecked: id });
    this.setState({ bankAccountId: id });
  }
  getActiveBankClass(id) {
    return this.state.isBankChecked === id
      ? path.checkslide
      : path.uncheckslideBorder;
  }
  render() {
    return (
      <>
        {this.state.loading ? <LoadingSpinner /> : ""}
        <div className="col-xl-2 col-md-3 col-12">
          <BankAccountVerification
            onRef={(ref) => ref}
            verivendData={this.state.verivendData}
            id={this.state.bankAccountId}
            onVerifyBankAccountSuccess={() =>
              this.getUserVerivendData(this.state.verivendId)
            }
          />
          <div className="invitations_left_content_wallet">
            <ul className="direct-fund">
              <li
                style={{ cursor: "pointer" }}
                className={
                  this.state.userDetailData &&
                    this.state.userDetailData.verivendId
                    ? `active`
                    : ""
                }
                onClick={() => this.handleCurrentTab(0)}
              >
                {this.state.userDetailData &&
                  this.state.userDetailData.verivendId ? (
                  <span>
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </span>
                ) : (
                  <span></span>
                )}
                <p>Create Wallet</p>
              </li>
              <li
                style={{ cursor: "pointer" }}
                className={this.state.bankAccounts.length > 0 ? `active` : ""}
                onClick={() => this.handleCurrentTab(1)}
              >
                {this.state.bankAccounts.length > 0 ? (
                  <span>
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </span>
                ) : (
                  <span></span>
                )}
                <p>Connect Account</p>
              </li>
              <li
                style={{ cursor: "pointer" }}
                className={
                  this.state.bankAccounts.length > 0 &&
                    this.state.bankAccounts.some((e) => e.verified)
                    ? `active`
                    : ""
                }
                onClick={() => this.handleCurrentTab(2)}
              >
                {this.state.bankAccounts.length > 0 &&
                  this.state.bankAccounts.some((e) => e.verified) ? (
                  <span>
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </span>
                ) : (
                  <span></span>
                )}
                <p>Verify Account</p>
              </li>
              { !this.investorId && (
                <li
                  style={{ cursor: "pointer" }}
                  className={(this.state.currentTab === 3 || this.state.currentTab === 4)  ? `active` : ""}
                  onClick={() => this.handleCurrentTab(3)}
                >
                  {(this.state.currentTab === 3 || this.state.currentTab === 4) ? (
                    <span>
                      <i className="fa fa-check" aria-hidden="true"></i>
                    </span>
                  ) : (
                    <span></span>
                  )}
                  <p>Tranfer Funds to Bank</p>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="col-md-8 col-12">
          {this.state.currentTab === 0 && (
            <div className="card my-custom-card">
              <div className="item_box">
                <div className="new_investment" style={{ padding: "0px 10px" }}>
                  <h2>Verivend Wallet Policy</h2>
                </div>
                <div className="invitations_right invitations_right_fund">
                  <div className="col">
                    <div
                      style={{
                        padding: "30px 10px",
                        textAlign: "justify",
                        lineHeight: "normal",
                      }}
                    >
                      <h4 style={{ fontWeight: "400" }}>
                        Verivend’s proprietary Digital Wallet technology is an
                        immutable and fully auditable ledger of all historical
                        and current fund ownership within Verivend’s payment
                        network that provides a detailed record of all
                        transaction activity maintained using Verivend’s
                        proprietary Digital Wallet ledgering technology. All
                        Capital Contributions within TSG’s Verivend Digital
                        Wallet are completely under the control of TSG as the
                        recipient of the funds. Available funds can be withdrawn
                        at any time to an external bank account at TSG’s
                        discretion or set to automatically transfer if
                        designated by TSG. Capital Accounts will be maintained
                        through the TSG’s relationship with Verivend and its
                        banking partners, using Verivend’s proprietary Digital
                        Wallet technology. Capital Accounts will be maintained
                        in accordance with U.S. federal income tax guidelines.
                      </h4>
                    </div>

                    { this.state.showWalletButton && (
                      <div className="legalname-container">
                        <div className="row">
                          <div className="col-md-6 col-10">
                            <div className="form-group">
                              <div className="investor-input">
                                <label>Legal Name</label>
                                <input
                                  type="text"
                                  value={this.state.legelName}
                                  onChange={(event) =>
                                    this.setState({
                                      legelName: event.target.value,
                                    })
                                  }
                                  name="legalName"
                                  className="form-control"
                                  placeholder="Enter Your Legal Name"
                                  disabled={this.state.isLegalNameConfirmed}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex check-container">
                          <input type="checkbox"
                            onChange={() => {
                              this.setState({ isLegalNameConfirmed: !this.state.isLegalNameConfirmed })
                            }}
                            checked={this.state.isLegalNameConfirmed}
                          />
                          <span>I confirm my legal name is {this.state.legelName}.</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {
                    <div>
                      <button
                        className="btn btn-danger top-margin"
                        onClick={() => {
                          if (this.state.showWalletButton) {
                            this.createWalletHandler();
                          } else {
                            this.setState({ currentTab: 1 });
                          }
                        }}
                        disabled={this.investorId || (this.state.showWalletButton && !this.state.isLegalNameConfirmed)}
                      >
                        Create Wallet
                      </button>
                    </div>
                  }
                </div>
              </div>
            </div>
          )}
          {this.state.currentTab === 1 && (
            <div className="card my-custom-card">
              <div
                className="new_investment"
                style={{ padding: "0px 10px", marginBottom: 20 }}
              >
                <h2>Add Bank Account</h2>
              </div>
              <div className="item_box" style={{ padding: "0px 10px" }}>
                <form className="investor-form-horizontal investor-account-form">
                  <div className="row">
                    <div className="col-sm-8 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label htmlFor="name">
                          Account Owner <RequiredSign /> :
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-8 col-md-6 col-lg-6">
                      <div className="form-group">
                        <div className="investor-input">
                          <input
                            type="text"
                            value={this.state.achAccountName}
                            onChange={(event) =>
                              this.setState({
                                achAccountName: event.target.value,
                              })
                            }
                            name="achAccountName"
                            className="form-control"
                            id="achAccountName"
                            placeholder="Enter Owner Name"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-8 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label htmlFor="name">
                          Account Nick Name <RequiredSign /> :
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-8 col-md-6 col-lg-6">
                      <div className="form-group">
                        <div className="brokerage-input">
                          <input
                            type="text"
                            value={this.state.achNickname}
                            onChange={(event) =>
                              this.setState({ achNickname: event.target.value })
                            }
                            name="achNickname"
                            className="form-control"
                            id="achNickname"
                            placeholder="Enter Owner Name"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-8 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label htmlFor="name">
                          Account Number <RequiredSign /> :
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-8 col-md-6 col-lg-6">
                      <div className="form-group">
                        <div className="brokerage-input">
                          <NumberFormat
                            value={this.state.achAccountNumber}
                            thousandSeparator={false}
                            decimalScale={0}
                            inputMode="numeric"
                            allowEmptyFormatting={false}
                            allowLeadingZeros={true}
                            onValueChange={(values) => {
                              this.setState({achAccountNumber: values.value})              
                            }}
                            className="form-control"
                            id="achAccountNumber"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-8 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label htmlFor="name">
                          Account Routing Number <RequiredSign /> :
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-8 col-md-6 col-lg-6">
                      <div className="form-group">
                        <div className="brokerage-input">
                          <NumberFormat
                            value={this.state.achRoutingNumber}
                            thousandSeparator={false}
                            decimalScale={0}
                            inputMode="numeric"
                            allowEmptyFormatting={false}
                            allowLeadingZeros={true}
                            onValueChange={(values) => {
                              this.setState({achRoutingNumber: values.value})              
                            }}
                            className="form-control"
                            id="achRoutingNumber"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-7 col-md-12 col-lg-12">
                      <div className="form-group">
                        <div
                          className="row text-center"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <div style={{ paddingLeft: 10 }}>
                            <TButton
                              onClick={() => this.addBankAccountHandler()}
                              id="save-btn"
                              disabled={this.isValidate() || this.investorId}
                              text={"SAVE"}
                            />
                          </div>
                          <div style={{ paddingLeft: 10 }}>
                            <TButton
                              onClick={() => this.clearForm()}
                              id="addBankAccountButton"
                              disabled={!this.isValidate() || this.investorId}
                              text={"ADD BANK ACCOUNT"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
          {this.state.currentTab === 2 && (
            <div className="card my-custom-card">
              <div
                className="new_investment"
                style={{ padding: "0px 10px", marginBottom: 20 }}
              >
                <h2>Bank Accounts</h2>
              </div>
              <div className="item_box">
                {this.state.bankAccounts && this.state.bankAccounts.length > 0 ? (
                  <>
                    {this.state.bankAccounts.map((bankAccount, index) => {
                      return (
                        <>
                          <div className="wallet-details">
                            <div className="flex-column">
                              <h4 className="header-txt">Bank Account Name</h4>
                              <h5
                                className="header-txt"
                                style={{ marginBottom: "10px" }}
                              >
                                {bankAccount.bankAccountName}
                              </h5>
                              <h4 className="header-txt">
                                Bank Account Verification
                              </h4>
                              <h5 className="header-txt">
                                {bankAccount.status === "PENDING_VERIFICATION"
                                  ? "Pending Verification"
                                  : bankAccount.verified
                                    ? "Verified"
                                    : bankAccount.intiated
                                      ? "Intiated"
                                      : "Pending"}
                              </h5>
                            </div>
                            <div className="flex-column">
                              <h4 className="header-txt">
                                Bank Account Number
                              </h4>
                              <h5
                                className="header-txt"
                                style={{ marginBottom: "10px" }}
                              >
                                {this.showLastFourChars(
                                  bankAccount.bankAccountNumber
                                )}
                              </h5>
                              <h4 className="header-txt">
                                Bank Routing Number
                              </h4>
                              <h5 className="header-txt">
                                {bankAccount.bankRoutingNumber}
                              </h5>
                            </div>
                            <div className="flex-column">
                              <h4 className="header-txt">Email Address</h4>
                              <h5
                                className="header-txt"
                                style={{ marginBottom: "10px" }}
                              >
                                {window.localStorage.getItem("emailAddress")}
                              </h5>
                              <h4 className="header-txt">
                                Check Bank Account Status
                              </h4>
                              <h5 className="header-txt">
                                {!bankAccount.intiated &&
                                  !bankAccount.verified ? (
                                  <TButton
                                    onClick={() => {
                                      this.intiateMicroDeposit(
                                        bankAccount.bankAccountId
                                      );
                                    }}
                                    id="intiateMicroDeposit-btn"
                                    text={"INTIATE MICRO DEPOSIT"}
                                    disabled={this.investorId}
                                  />
                                ) : bankAccount.intiated &&
                                  !bankAccount.verified ? (
                                  <TButton
                                    onClick={() =>
                                      this.setBankAccountId(
                                        bankAccount.bankAccountId
                                      )
                                    }
                                    id="bankAccountVerification-btn"
                                    dataTarget={`#bankAccountVerificationModal`}
                                    dataToggle={`modal`}
                                    text={"VERIFY BANK ACCOUNT"}
                                    disabled={this.investorId}
                                  />
                                ) : (
                                  <>{bankAccount.status}</>
                                )}
                              </h5>
                            </div>
                            {!this.investorId && (!bankAccount.intiated || bankAccount.verified) && (
                              <img
                                src={path.delete_new}
                                alt=""
                                className="delete-icon"
                                onClick={() =>
                                  this.deleteBankAccount(
                                    bankAccount._id,
                                    bankAccount.status
                                  )
                                }
                              />
                            )}
                          </div>
                        </>
                      );
                    })}
                    <br />
                    <div style={{ fontSize: "14px" }}>
                      <b>Bank Accounts</b> connected are verified with{" "}
                      <b>Micro Deposit</b> transactions. This is an
                      industry-standard method of ensuring that the entity or
                      individual owns the bank account that is connected. The
                      verification process only needs to be completed once per{" "}
                      <b>Bank Account</b> and ensures that funds withdrawn from
                      your digital wallet will be deposited to the intended and
                      correct <b>Bank Account</b>.
                      <br />
                      <br />
                      When a <b>Bank Account</b> is connected,{" "}
                      <b>Micro Deposit Verification</b> is required to be
                      completed before funds can be withdrawn from your digital
                      wallet to the <b>Bank Account</b>. This requirement
                      provides greater security to confirm account ownership and
                      accuracy before funds are sent, ensuring that all funds
                      withdrawn from your digital wallet will be received in the
                      expected <b>Bank Account</b>.
                    </div>
                  </>
                ) : (
                  <div>
                    <span style={{ fontSize: "15px", padding: "30px" }}>
                      {" "}
                      Please add and verify a bank account.
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
          {this.state.currentTab === 3 && (
            <div className="card my-custom-card">
              <div
                className="new_investment"
                style={{ padding: "0px 10px", marginBottom: 20 }}
              >
                <h2>Transfer Funds to Bank Account</h2>
              </div>

              <div
                className="row d-flex"
                style={{ padding: "0px 20px", marginBottom: 20 }}
              >
                <div className="col-sm-7 col-md-12">
                  <h4>
                    Wallet Balance :
                    <NumberFormat
                      style={{ marginLeft: "20px" }}
                      value={this.state.walletBalanceData.currentBalance || 0}
                      thousandSeparator={true}
                      prefix={"$"}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      displayType={"text"}
                    />
                  </h4>
                </div>
              </div>
              {this.state.bankAccounts.length > 0 ? (
                <div className="item_box">
                  <div style={{ marginBottom: "10px" }}>
                    {this.state.bankAccounts.map((bankAccount, index) => {
                      return (
                        <>
                          <div
                            className="row flex-row"
                            key={`${index}____${bankAccount.bankAccountName}`}
                            style={{
                              display: "flex",
                              maxWidth: "1000px",
                              alignItems: "center",
                              padding: "10px 30px",
                            }}
                          >
                            <div className="col-md-12">
                              <div
                                className="row d-flex flex-row"
                                style={{ margin: "0px 5px" }}
                              >
                                {bankAccount.intiated && bankAccount.verified ? (
                                  <img
                                    style={{ width: "15px" }}
                                    src={this.getActiveBankClass(
                                      bankAccount.bankAccountId
                                    )}
                                    alt=""
                                    onClick={() =>
                                      this.handleBankAccount(
                                        bankAccount.bankAccountId
                                      )
                                    }
                                  />
                                ) : (
                                  <></>
                                )}
                                <h4>
                                  <b style={{ marginLeft: "10px" }}>
                                    <span>{bankAccount.bankAccountName}</span>{" "}
                                    <span>
                                      {this.showLastFourChars(
                                        bankAccount.bankAccountNumber
                                      )}
                                    </span>
                                  </b>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <form className="investor-form-horizontal investor-account-form" style={{ padding: "0px 20px" }}>
                    <div className="row">
                      <div className="col-md-3 col-lg-4">
                        <div className="form-group">
                          <label htmlFor="name" style={{ marginTop: '5px', fontSize: '1.125em' }}>
                            Amount <RequiredSign /> :
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-9">
                        <div className="form-group amount-investment">
                          <NumberFormat
                            value={this.state.transferAmount}
                            thousandSeparator={true}
                            prefix={"$"}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            onValueChange={(values) => {
                              this.setState({ transferAmount: Number(values.value) });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-7 col-md-12 col-lg-12">
                        <div className="form-group">
                          <div
                            className="row text-center"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <input type="button" id="cancel_button" className="btn btn-default" value="CANCEL" onClick={() => window.location.href = "/investor-dashboard"} style={{ marginLeft: "10px", padding: "4px 12px" }} />
                            <div style={{ paddingLeft: 10 }}>
                              <TButton
                                onClick={() =>
                                  this.transferFundsToBankAccount()
                                }
                                disabled={
                                  this.state.transferAmount === 0 ||
                                  this.state.transferAmount === 0.00
                                  || this.state.bankAccountId == null
                                }
                                text={"SUBMIT"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div>
                  <span style={{ fontSize: "15px", padding: "30px" }}>
                    {" "}
                    Please add and verify a bank account.
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}
export default withWindowSize(DetailView);

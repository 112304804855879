import React from 'react'
const Button = props => {
  return (
    <button
      type="button"
      className={props.className ? props.className : 'btn btn-danger'}
      onClick={props.onClickHandler && props.onClickHandler}
    >{props.title}</button>
  )
}
export default Button
import React, { Component } from 'react';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import axios from 'axios';
import swal from 'sweetalert';
import common from '../../../common';
import RequiredSign from '../../../components/RequiredSign';
import { path } from '../../imagesPath';
import { getCompanyLogo } from '../../shared/helper';
import './ExitInProcessInvestments.scss';

class BrockerageAccountModel extends Component {
    constructor() {
        super();
        this.state = {
            loadinglada: false,
            isWellsFargo: "Wells Fargo",
            title: '',
            accountno: '',
            receivingfirmname: '',
            receivingfirmno: '',
            receivingfirmacctitle: '',
            receivingfirmaccno: '',
            id: '',
            processId: '',
            investmentName: '',
        }
    }
    componentWillReceiveProps(nextProps) {
        nextProps.onRef && nextProps.onRef(this);

    }
    setData(detail) {
        if (detail.investmentprocesses.brokerageAccount) {
            this.setState({ isWellsFargo: detail.investmentprocesses.brokerageAccount.deliveryType });
            this.setState({ title: detail.investmentprocesses.brokerageAccount.WFAccountTitle });
            this.setState({ accountno: detail.investmentprocesses.brokerageAccount.WFAccountNumber });
            this.setState({ receivingfirmname: detail.investmentprocesses.brokerageAccount.receivingFirmName });
            this.setState({ receivingfirmno: detail.investmentprocesses.brokerageAccount.receivingFirmDTCNumber });
            this.setState({ receivingfirmacctitle: detail.investmentprocesses.brokerageAccount.receivingFirmAccountTitle });
            this.setState({ receivingfirmaccno: detail.investmentprocesses.brokerageAccount.receivingAccountNumber });
        }
        this.setState({ investmentName: detail.investment.name });
        this.setState({ id: detail.company._id });
        this.setState({ processId: detail.investment._id });
    }
    resetData() {
        this.setState({
            title: '',
            accountno: '',
            receivingfirmname: '',
            receivingfirmno: '',
            receivingfirmacctitle: '',
            receivingfirmaccno: '',
        })
    }
    brokerageAccount() {
        const temp = this;
        let token = window.localStorage.getItem('token');
        let userId = window.localStorage.getItem('id');
        this.setState({ loadinglada: true });
        axios.post(`${common.path.base_url}/brokerageAccount`, {
            userId,
            investmentId: this.state.processId,
            deliveryType: this.state.isWellsFargo,
            WFAccountTitle: this.state.title,
            WFAccountNumber: this.state.accountno,
            receivingFirmName: this.state.receivingfirmname,
            receivingFirmDTCNumber: this.state.receivingfirmno,
            receivingFirmAccountTitle: this.state.receivingfirmacctitle,
            receivingAccountNumber: this.state.receivingfirmaccno,
        }, {
            headers: {
                "Authorization": token
            }
        })
            .then(function (response) {
                temp.setState({ loadinglada: false });
                swal({
                    title: "Success!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(function () {
                        temp.resetData();
                        window.location.reload();
                    });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });

            });

    }
    render() {
        return (
            <div id="brockerageAccountModel" className="modal fade" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content invite-contact">
                        <div className="modal-header">
                            <h4 className="modal-title">Brokerage Account</h4>
                            <img src={path.close} type="button" id="verify-btn-cancel" data-dismiss="modal" onClick={() => this.resetData()} />
                        </div>
                        <div className="modal-body">
                            <div className="model-img-box">
                                <img src={getCompanyLogo(this.state.id)} alt='' />
                                {/* <img src={'https://api-dev.syndicategroup.com/getInvestmentCompanyLogo?companyId=5e42889b03a4339da57ce249'} alt='' /> */}
                                <h3>{this.state.investmentName}</h3>
                            </div>
                            <form className="brokerage-form-horizontal brokerage-account-form">
                                <div className="row">
                                    <div className="col-sm-5">
                                        <div className="form-group">
                                            <label htmlFor="name">Delivery Type <RequiredSign /> :</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-7">
                                        <div className="form-group">
                                            <div className="checklist_bg radio-mainbox">
                                                <div className="chiller_cb">
                                                    <input id="myCheckbox" type="radio"
                                                        onChange={() => this.setState({ isWellsFargo: "Wells Fargo" })}
                                                        value="yes"
                                                        checked={this.state.isWellsFargo === "Wells Fargo"}
                                                        name="wellFargo" />
                                                    <label htmlFor="myCheckbox">Wells Fargo</label>
                                                    <span />
                                                </div>
                                                <div className="chiller_cb">
                                                    <input id="myCheckbox2" type="radio" name="wellFargo"
                                                        value="no"
                                                        checked={this.state.isWellsFargo === "Non-Wells Fargo"}
                                                        onChange={() => this.setState({ isWellsFargo: "Non-Wells Fargo" })}
                                                    />
                                                    <label htmlFor="myCheckbox2">Non-Wells Fargo</label>
                                                    <span />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.isWellsFargo === "Wells Fargo" ?
                                    <>
                                        <div className="row">
                                            <div className="col-sm-5">
                                                <div className="form-group">
                                                    <label htmlFor="name">WFA Account Title <RequiredSign /> :</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-7">
                                                <div className="form-group">
                                                    <div className='brokerage-input'>
                                                        <input type="text"
                                                            value={this.state.title}
                                                            onChange={(event) => this.setState({ title: event.target.value })}
                                                            name="WFAtitle" className="form-control" id="title" placeholder="Enter Title"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-5">
                                                <div className="form-group">
                                                    <label htmlFor="name">WFA Account Number <RequiredSign /> :</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-7">
                                                <div className="form-group">
                                                    <div className='brokerage-input'>
                                                        <input type="text"
                                                            value={this.state.accountno}
                                                            onChange={(event) => this.setState({ accountno: event.target.value })}
                                                            name="WFAaccNo" className="form-control" id="number" placeholder="Enter Account Number"
                                                        />
                                                    </div></div></div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {/* Non-Wells Fargo */}
                                        <div className="row">
                                            <div className="col-sm-5">
                                                <div className="form-group">
                                                    <label htmlFor="name">Receiving Firm Name <RequiredSign /> :</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-7">
                                                <div className="form-group">
                                                    <div className='brokerage-input'>
                                                        <input type="text"
                                                            value={this.state.receivingfirmname}
                                                            onChange={(event) => this.setState({ receivingfirmname: event.target.value })}
                                                            name="name" className="form-control" id="name" placeholder="Enter Receiving Firm Name"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-5">
                                                <div className="form-group">
                                                    <label htmlFor="name">Receiving Firm DTC Number <RequiredSign /> :</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-7">
                                                <div className="form-group">
                                                    <div className='brokerage-input'>
                                                        <input type="text"
                                                            value={this.state.receivingfirmno}
                                                            onChange={(event) => this.setState({ receivingfirmno: event.target.value })}
                                                            name="name" className="form-control" id="name" placeholder="Enter Receiving Firm DTC Number"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-5">
                                                <div className="form-group">
                                                    <label htmlFor="name">Full Receiving Account Title <RequiredSign /> :</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-7">
                                                <div className="form-group">
                                                    <div className='brokerage-input'>
                                                        <input type="text"
                                                            value={this.state.receivingfirmacctitle}
                                                            onChange={(event) => this.setState({ receivingfirmacctitle: event.target.value })}
                                                            name="name" className="form-control" id="name" placeholder="Enter Receiving Account Title"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-5">
                                                <div className="form-group">
                                                    <label htmlFor="name">Receiving Account Number <RequiredSign /> :</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-7">
                                                <div className="form-group">
                                                    <div className='brokerage-input'>
                                                        <input type="text"
                                                            value={this.state.receivingfirmaccno}
                                                            onChange={(event) => this.setState({ receivingfirmaccno: event.target.value })}
                                                            name="name" className="form-control" id="name" placeholder="Enter Receiving Account Number"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                {/* <div className="row">
                                    <div className="col-sm-7 col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="name">Delivery Type <RequiredSign /> :</label>
                                            <div className="checklist_bg radio-mainbox">
                                                <div className="chiller_cb">
                                                    <input id="myCheckbox" type="radio"
                                                        onChange={() => this.setState({ isWellsFargo: "Wells Fargo" })}
                                                        value="yes"
                                                        checked={this.state.isWellsFargo === "Wells Fargo"}
                                                        name="wellFargo" />
                                                    <label htmlFor="myCheckbox">Wells Fargo</label>
                                                    <span />
                                                </div>
                                                <div className="chiller_cb">
                                                    <input id="myCheckbox2" type="radio" name="wellFargo"
                                                        value="no"
                                                        checked={this.state.isWellsFargo === "Non-Wells Fargo"}
                                                        onChange={() => this.setState({ isWellsFargo: "Non-Wells Fargo" })}
                                                    />
                                                    <label htmlFor="myCheckbox2">Non-Wells Fargo</label>
                                                    <span />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        </>
                                    }
                                </div> */}
                            </form>
                        </div>
                        <div className="modal-footer">
                            {this.state.isWellsFargo == "Wells Fargo" && (
                                <LaddaButton
                                    id='lada-btn'
                                    loading={this.state.loadinglada}
                                    onClick={() => this.brokerageAccount()}
                                    data-color="#eee"
                                    data-size={XS}
                                    data-style={EXPAND_RIGHT}
                                    data-spinner-size={30}
                                    data-spinner-color="#ddd"
                                    data-spinner-lines={12}
                                    data-dismiss="modal"
                                    className={!this.state.title || !this.state.accountno ? 'disable' : ''}

                                >
                                    {'SUBMIT'}
                                </LaddaButton>
                            )}
                            {this.state.isWellsFargo == "Non-Wells Fargo" && (
                                <LaddaButton
                                    id='lada-btn'
                                    loading={this.state.loadinglada}
                                    onClick={() => this.brokerageAccount()}
                                    data-color="#eee"
                                    data-size={XS}
                                    data-style={EXPAND_RIGHT}
                                    data-spinner-size={30}
                                    data-spinner-color="#ddd"
                                    data-spinner-lines={12}
                                    data-dismiss="modal"
                                    className={!this.state.receivingfirmname || !this.state.receivingfirmno || !this.state.receivingfirmacctitle || !this.state.receivingfirmaccno ? 'disable' : ''}

                                >
                                    {'SUBMIT'}
                                </LaddaButton>
                            )}
                            <LaddaButton
                                id='lada-btn'
                                loading={this.state.loadinglada}
                                onClick={() => this.resetData()}
                                data-color="#eee"
                                data-size={XS}
                                data-style={EXPAND_RIGHT}
                                data-spinner-size={30}
                                data-spinner-color="#ddd"
                                data-spinner-lines={12}
                                data-dismiss="modal"
                            >
                                {'CANCEL'}
                            </LaddaButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default BrockerageAccountModel;
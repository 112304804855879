import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import axios from 'axios';
import common from '../../common';
import swal from 'sweetalert';
import './summary.scss';
import _ from 'lodash';
import LoadingSpinner from '../../components/Loader';
import { isLoading, isNotLoading } from '../../shared/actions/loadingSpinner';
import { setSummaryStartupsData } from '../actions/summaryStartups';
import SummaryStartupsCalComponent from './SummaryStartupsList';
import SummaryStartupsUserList from './SummaryStartupsUserList';

class SummaryStartups extends Component {

    constructor() {
        super()
        //this.adminAccess = false;
        this.state = {
            loading : false,
            summartList : [],
            searchSummartList : [],
            investment : [],
            userCount : 0,
            totalInvestmentAmt : 0,
            totalDocSignAmt : 0,
            totalNetDocSignAmt : 0,
            totalManagementFee : 0,
            totalUserShares : 0,
            currentPageLocal : 0,
            totalPagesLocal : 1,
            totalInvestmentCount : 0,
            exportData : [],
            tsgSetting : [],
            strtype : 0, 
        }
    }

    componentWillMount() {
        const url = new URL(window.location.href);
        this.id = url.searchParams.get('id');
        window.localStorage.setItem('summaryInvestmentId', this.id);

        if ( (url.searchParams.get('type') &&  url.searchParams.get('type')>=0 && url.searchParams.get('type') <=2 ) ) { 
            this.props.history.push(`/investmentDetail?id=${this.id}&activeTab=summary&type=${url.searchParams.get('type')}`);
            this.getSummaryList( [], url.searchParams.get('type') );
        } else {
            this.props.history.push(`/investmentDetail?id=${this.id}&activeTab=summary`);
            this.getSummaryList();
        }
    }

    getSummaryList(tags =[], strType, reload=0) {
        
        let temp = this;
        temp.setState({ loading: true, strtype: strType });
        let tokenpass = window.localStorage.getItem('token');
        // if(reload == 1) {
        //     this.setState({ 
        //         currentPageLocal : 0, 
        //     });
        // }
        //const currPage = (this.state.summaryPagination.currentPage > 0 ) ? (this.state.summaryPagination.currentPage + 1) : 1;
        const currPage = (this.state.currentPageLocal > 0 && reload != 1) ? (this.state.currentPageLocal + 1) : 1;
        const totPages = (this.state.totalPagesLocal > 0) ? this.state.totalPagesLocal : 1;

        //console.log(currPage, totPages);

        //if( summaryPagination.currentPage <= summaryPagination.totalPages && currPage <= summaryPagination.totalPages ) {
        if( currPage <= totPages ){
            
            if(reload == 1) {
                this.setState({ 
                    summartList: []
                });    
            }
            
            let newTags = [];
            let fillterOn = 0;
            if (tags && tags.length > 0) {
                newTags = tags.map(item => item.name);
                fillterOn = 1;
            }
            if(strType > 0 ) {
                fillterOn = 1;
            }
            
            const data = {
                page: currPage,
                //limit: 3, //GKTest
                investmentId: this.id,
                newTags : newTags,
                strType : strType,
            }
            //console.log(data);
            axios.post(`${common.path.base_url}/summary/getInvestmentUserList`, data, {
                headers: {
                    "Authorization": tokenpass
                }
            })
                .then((response) => {

                    const record = response.data;
                    const allArray = response.data.data;
                    const listArray = allArray.investmentProcess;
                    const investment = allArray.investment;
                    const investmentCount = allArray.investmentProcessCount
                    const currentPage = record.currentPage;
                    const totalPages = record.totalPages;
                    const docsPending = allArray.docsPending;
                    const fundComplate = allArray.calFund;
                    const setting = allArray.setting;
                    
                    this.setState({ 
                        loading: false, 
                        summartList: [...this.state.summartList, ...listArray.result], 
                        investment: investment, 
                        currentPageLocal : currentPage, 
                        totalPagesLocal : totalPages, 
                        tsgSetting : setting,
                    });

                    if(fillterOn != 1) {
                        const userCount = (fundComplate.totalAmount[0] && fundComplate.totalCount[0].count) ? fundComplate.totalCount[0].count : 0;
                        const totalInvestmentAmt = (fundComplate.totalAmount[0] && fundComplate.totalAmount[0].totalAmount) ? fundComplate.totalAmount[0].totalAmount : 0;
                        const totalDocSignAmt = ( (docsPending.totalAmount[0]) && docsPending.totalAmount[0].totalAmount) ? docsPending.totalAmount[0].totalAmount : 0;
                        const totalNetDocSignAmt = ( (docsPending.totalAmount[0]) && docsPending.totalAmount[0].totalNetAmount) ? docsPending.totalAmount[0].totalNetAmount : 0;
                        const totalManagementFee = (fundComplate.totalAmount[0] && fundComplate.totalAmount[0].totalManagementFee) ? fundComplate.totalAmount[0].totalManagementFee : 0;
                        const totalUserShares = (fundComplate.totalAmount[0] && fundComplate.totalAmount[0].totalUserShares) ? fundComplate.totalAmount[0].totalUserShares : 0;
                        const totInvestmentCount = ( investmentCount.totalCount[0] && investmentCount.totalCount[0].count ) ? investmentCount.totalCount[0].count : 0;

                        this.setState({ 
                            userCount: userCount,
                            totalInvestmentAmt : totalInvestmentAmt,
                            totalDocSignAmt : totalDocSignAmt,
                            totalNetDocSignAmt : totalNetDocSignAmt,
                            totalManagementFee : totalManagementFee,
                            totalUserShares : totalUserShares,
                            totalInvestmentCount : totInvestmentCount
                            
                        });
                    }
                    //console.log("apiii",(currentPage), this.state.currentPageLocal);
                })
                .catch(function (error) {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                    temp.setState({ loading: false });

                });
        }        
    }

    getSummaryPagination() {
        return this.state.summaryPagination;
    }

    updateChanges(dataArr){

        let updateData = dataArr.investmentProcess;
        let docsPending = dataArr.docsPending;

        const totalInvestmentAmt = (updateData.totalAmount[0] && updateData.totalAmount[0].totalAmount) ? updateData.totalAmount[0].totalAmount : 0;
        const totalManagementFee = (updateData.totalAmount[0] && updateData.totalAmount[0].totalManagementFee) ? updateData.totalAmount[0].totalManagementFee : 0;
        const totalUserShares = (updateData.totalAmount[0] && updateData.totalAmount[0].totalUserShares) ? updateData.totalAmount[0].totalUserShares : 0;
        const totalDocSignAmt = ( (docsPending.totalAmount[0]) && docsPending.totalAmount[0].totalAmount) ? docsPending.totalAmount[0].totalAmount : 0;
        
        
        this.setState({ 
            totalInvestmentAmt : totalInvestmentAmt,
            totalManagementFee : totalManagementFee,
            totalUserShares : totalUserShares,
            totalDocSignAmt : totalDocSignAmt,
        });
    }

    exportSummary(tags =[], strType) {

        let tokenpass = window.localStorage.getItem('token');
        
        let newTags = [];
        let fillterOn = 0;
        if (tags && tags.length > 0) {
            newTags = tags.map(item => item.name);
            fillterOn = 1;
        }
        if(strType > 0 ) {
            fillterOn = 1;
        }
        
        const data = {
            investmentId: this.id,
            newTags : newTags,
            strType : strType,
        }
        
        axios.post(`${common.path.base_url}/summary/export`, data, {
            headers: {
                "Authorization": tokenpass
            }
        }).then((resp) => {
            let record = resp.data;
            if( !record.error ) {
                const recordData = record.data;

                this.setState({ 
                    exportData : recordData,
                });    
            }
        });
    }
    render() {
        //console.log(this.state.tsgSetting);
        return (
        <>
            {
                <div className='cod-fluid container-fluid' style={{ 'marginTop':'30px', 'width':'100%', 'display':'block', 'backgroundColor': '#F5F6FA' }}>
                    
                <SummaryStartupsCalComponent 
                    key={ 1 } 
                    investment = { this.state.investment }
                    userCount = { this.state.userCount }
                    totalInvestmentAmt = { this.state.totalInvestmentAmt }
                    totalDocSignAmt = { this.state.totalDocSignAmt }
                    totalNetDocSignAmt = { this.state.totalNetDocSignAmt }
                    totalManagementFee = { this.state.totalManagementFee }
                    totalUserShares = { this.state.totalUserShares }
                    totalInvestmentCount = { this.state.totalInvestmentCount }
                    //totDocsPending = { this.state.totDocsPending }
                    {...this.props} 
                /> 
                
                <SummaryStartupsUserList 
                   key={ 2 } 
                   summartList = { this.state.summartList }
                   investment = { this.state.investment } 
                   getSummaryList={(tags, strType, reload) => this.getSummaryList(tags, strType, reload)}
                   //getSummarySearchList = { (tags) => this.getSummarySearchList(tags) }
                   updateChanges={ (updateData) => this.updateChanges(updateData) }
                   exportSummary={ (tags, strType) => this.exportSummary(tags, strType) }
                   exportData = { this.state.exportData }
                   tsgSetting = { this.state.tsgSetting}
                   sendStrtype = { this.state.strtype }
                   {...this.props} 
                />

                </div>
            }
        </>
        );
    }
}

//export default SummaryStartupsList;
const mapStateToProps = state => {
    return {
        loadingSpinner: state.loadingSpinner,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        isLoading: bindActionCreators(isLoading, dispatch),
        isNotLoading: bindActionCreators(isNotLoading, dispatch),
        setSummaryStartupsData: bindActionCreators(setSummaryStartupsData, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SummaryStartups));
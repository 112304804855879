import React from 'react'
import './Graph.scss';
import { path }  from "../../../imagesPath";


const Graph = () => (
  <div className="col-lg-7 col-md-12">
        <div className="card">
          <div className="invitations_right invitations_right_fund">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="investment-details">
                  <div className="investment-col-1">
                    <label>Total invested</label>
                    <p>$10,000</p>
                  </div>
                  <div className="investment-col-2">
                    <label>Price Per Share</label>
                    <p>$200</p>
                  </div>
                  <div className="investment-col-3">
                    <label>Current valuation</label>
                    <p className="active">$12,500</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-end">
              <div className="col-lg-12 col-md-12">
                <div className="investment-year">
                  <div className="investment-year-col-1">
                    <div className="form-group">
                      <select className="form-control form-control-lg" name="category" id="validationCustom03">
                        <option value>Yearly</option>
                        <option value="Yearly01">Yearly01</option>
                        <option value="Yearly02">Yearly02</option>
                        <option value="Yearly03">Yearly03</option>
                        <option value="Yearly03">Yearly03</option>
                      </select>
                    </div>
                  </div>
                  <div className="investment-year-col-2">
                    <div className="form-group">
                      <select className="form-control form-control-lg" name="category" id="validationCustom03">
                        <option value>2019</option>
                        <option value={2020}>2020</option>
                        <option value={1999}>1999</option>
                        <option value={2000}>2000</option>
                        <option value={2001}>2001</option>
                        <option value={2002}>2002</option>
                        <option value={2003}>2003</option>
                        <option value={2004}>2004</option>
                        <option value={2005}>2005</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-mg-12">
                <div className="graph">
                  <img src={path.graph && path.graph} alt=''  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="investment_close">
                  <div className="investment-col-1">
                    <label>Date Investment Closed</label>
                    <p>11/23/2019</p>
                  </div>
                  <div className="investment-col-2">
                    <label>Company Valuation</label>
                    <p>$2,50,000</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
)


export default Graph
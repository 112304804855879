import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import axios from 'axios';
import swal from 'sweetalert';
import _ from 'lodash';

import './ExecutiveLetter.scss';
import '../IIR//IIR.scss';
import LoadingSpinner1 from '../../components/IIR Loader';
import FileUploadBlob from '../../components/FileUploadBlob';
import common from '../../common';
import images from "../../images";
import Header from '../../components/Header';
import HeaderImg from '../../components/HeaderImage';
import TButton from '../../components/TButton';


class FoundLetter extends Component {
    constructor(props) {
        super(props);
        this.companyId = '';
        this.investmentId = '';
        this.state = {
            name: '',
            formLetter: '',
            formName: '',
            title: '',
            choosenFile: null,
            loading: false,
            loadinglada: false,
            loadinglada2: false,
            loadinglada3: false,
            Role: '',
            nameValidation: '',
            formLetterValidation: '',
            formNameValidation: '',
            titleValidation: '',
            companyLogoId: '',
            iirCompanyImage: '',
            profileFormValidated: false,
            inputFile: null,
            imgData: '',
            imageUploading: false,
            excutiveId: '',
            executiveLatterData: null,
            roleCode: '',
            investmentName:''
        }
    }

    componentWillMount() {
        const url = new URL(window.location.href);
        this.companyId = url.searchParams.get('companyId');
        this.investmentId = url.searchParams.get('investmentId');
        this.getData();
        this.getInvestmentName();
        const userRole = window.localStorage.getItem('role');
        // userRoleCode = window.localStorage.getItem('roleCode');
        this.setState({
            Role: userRole,
            roleCode: window.localStorage.getItem('roleCode'),
        });
    }



    getData() {
        let temp = this;
        let token = window.localStorage.getItem('token');
        // let CompanyId = window.localStorage.getItem('companyId');
        temp.setState({ loading: true });
        const id = window.localStorage.getItem('id');
        this.setState({ id: id })
        axios.get(`${common.path.base_url}/getInvestmentIIRExecutiveLetter?companyId=${this.companyId}&investmentId=${this.investmentId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                const executiveLatterData = response.data.data;
                temp.setState({ loading: false });
                this.setState({ excutiveId: response.data.data._id })
                this.setState({ name: response.data.data.companyName });
                this.setState({ formLetter: response.data.data.formLetter });
                this.setState({ iirCompanyImage: response.data.data.iirCompanyImage });
                this.setState({ formName: response.data.data.formName });
                this.setState({ title: response.data.data.title, companyLogoId: response.data.data.companyLogo });
                temp.setState({ executiveLatterData });

            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    getInvestmentName() {
        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loading: true });
        const id = window.localStorage.getItem('id');
        this.setState({ id: id });
        axios.get(`${common.path.base_url}/investmentById/${this.investmentId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((res) => {
                this.setState({ investmentName: res.data.data.name });
                 })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });


    }

    handleChange(id, newValue) {
        if (id === "companyName")
            this.setState({ name: newValue });
        else if (id === 'formLetter')
            this.setState({ formLetter: newValue });
        else if (id === 'formName')
            this.setState({ formName: newValue });
        else if (id === 'title')
            this.setState({ title: newValue });
    }

    gotoFoundLetterPreview() {
        this.putData(true)
    }

    putData(isPreview) {
        let temp = this;
        temp.setState({ loadinglada: true });
        const token = localStorage.getItem('token');
        // const letterToStore = this.state.formLetter.replace(/\n/g, "</p>\n<p>");
        const letterToStore = this.state.formLetter;

        axios.put(`${common.path.base_url}/updateIIRExecutiveLetter`, {
            companyName: this.state.name,
            companyId: this.companyId,
            investmentId: this.investmentId,
            formLetter: letterToStore,
            formName: this.state.formName,
            title: this.state.title,
            companyLogo: this.state.companyLogoId
        },
            {
                headers: {

                    "Authorization": token
                }
            })
            .then(function (response) {
                if (isPreview) {
                    temp.props.history.push(`/foundLetterPreview?companyId=${temp.companyId}&investmentId=${temp.investmentId}`)

                } else {
                    swal({
                        title: "Updated!",
                        text: response.data.message,
                        icon: "success",
                        button: "Ok!",
                    })
                        .then(() => {
                            // temp.getData();
                            // temp.getIIRList();
                            temp.props.history.push(`/dashboard`);
                        });
                }

                temp.setState({ profileFormValidated: false });
                temp.setState({ loadinglada: false });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });
            });
    }

    formValidaton(id, val) {
        if (id === 'companyName') {
            let name = document.getElementById('companyName').value;
            if (name === '') {
                this.setState({
                    nameValidation: "Company name should not be empty*",
                })
            } else {
                this.setState({
                    nameValidation: "",
                })
            }
        }
        else if (id === 'formLetter') {
            let title = document.getElementById('formLetter').value;
            if (title === '') {
                this.setState({
                    formLetterValidation: "Form letter should not be empty*",
                })
            } else {
                this.setState({
                    formLetterValidation: "",
                })
            }
        }

        else if (id === 'formName') {
            let company = document.getElementById('formName').value;
            if (company === '') {
                this.setState({
                    formNameValidation: "Form Name should not be empty*",
                })
            } else {
                this.setState({
                    formNameValidation: "",
                })
            }
        }
        else if (id === 'title') {
            let company = document.getElementById('title').value;
            if (company === '') {
                this.setState({
                    titleValidation: "Title should not be empty*",
                })
            } else {
                this.setState({
                    titleValidation: "",
                })
            }
        }
    }

    uploadLogo(file) {
        if (file.size) {
            const temp = this;
            temp.setState({ imageUploading: true });
            let tokenpass = window.localStorage.getItem('token');
            let data = new FormData();
            data.append('file', file);
            const isPrivate = true;
            data.append('isPrivate', isPrivate)
            axios.post(`${common.path.base_url}/uploadFile`, data, {
                headers: {
                    "Authorization": tokenpass
                }
            })
                .then((response) => {
                    temp.setState({ companyLogoId: response.data.data.id, loadinglada: false, choosenFile: null });
                    temp.setState({ imageUploading: false });
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        window.localStorage.clear();
                        window.location.href = '/';
                        return;
                    } else { }
                });
        }
    }

    approveExl() {
        console.log(this.state.excutiveId, "i am at get data")
        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loadinglada2: true })
        axios.put(`${common.path.base_url}/isApprovedExecutiveLetter`, {
            companyId: this.companyId,
            investmentId: this.investmentId,
            execLetterId: this.state.excutiveId
        }, {
            headers: {
                "Authorization": token
            }
        })
            .then(function (response) {
                console.log(response.data, "ex app response")
                temp.setState({ loadinglada2: false })
                swal({
                    title: "Updated!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
            })
            .catch(function (error) {
                console.log(error.response.data, "error")
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada2: false });
            });
    }

    notifyExl() {
        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loadinglada3: true })
        axios.post(`${common.path.base_url}/notifyExecutiveLetter`, {
            companyId: this.companyId,
            investmentId: this.investmentId,
            execLetterId: this.state.excutiveId
        }, {
            headers: {
                "Authorization": token
            }
        })
            .then(function (response) {
                console.log(response.data, "ex app response")
                temp.setState({ loadinglada3: false })
                swal({
                    title: "Updated!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
            })
            .catch(function (error) {
                console.log(error.response.data, "error")
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada3: false });
            });
    }

    // For Executive Latter Approved and Preview
    validatateForApprove() {
        if (this.state.executiveLatterData && this.state.executiveLatterData.execLetterReviewApproveStatus) {
            const execLetterReviewApproveStatus = this.state.executiveLatterData.execLetterReviewApproveStatus;
            const { isNotifiedByTSG, isApproveByStartup, c } = execLetterReviewApproveStatus;
            if (isNotifiedByTSG && isApproveByStartup && isApproveByStartup) {
                return true;
            }
        }
        return false;
    }

    unlockExl() {
        const temp = this;
        swal({
            title: "Are you sure?",
            text: "Once unlocked, you'll need approval again from startup admin.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willUnlock) => {
                const token = localStorage.getItem('token');
                if (willUnlock) {
                    let token = window.localStorage.getItem('token');
                    temp.setState({ loading: true })
                    temp.setState({ loadinglada2: true })
                    axios.put(`${common.path.base_url}/unlockExecutiveLetter`, {
                        execLetterId: this.state.excutiveId,
                    }, {
                        headers: {
                            "Authorization": token
                        }
                    })
                        .then(function (response) {
                            temp.setState({ loading: false, loadinglada2: false });
                            temp.getData();
                            swal({
                                title: "Updated!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            })
                        })
                        .catch(function (error) {
                            console.log(error.response)
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else {
                                swal({
                                    title: "Error!",
                                    text: error.response.data.message,
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                            temp.setState({ loading: false, loadinglada2: false });
                        });
                }
            });
    }

    previewExl(is) {
        if (is) {
            this.props.history.push(`/foundLetterPreview?companyId=${this.companyId}&investmentId=${this.investmentId}`)

        } else {
        }
    }
    render() {
        const username = window.localStorage.getItem("username");
        const iirLeftStyle = {
            width: '100%', maxWidth: '100%', display: 'flex', padding: '42px 30px', textAlign: 'center',
        }
        const iirPrevBtnStyle = {
            flexDirection: 'column', alignItems: 'center'
        }
        const noneStyle = {}
        return (
            <div className="foundLetter">
                {this.state.loading ? <LoadingSpinner1 /> : ""}
                <Header username={username} role={this.state.Role} />
                <HeaderImg 
                // heading={this.state.Role}
                heading={this.state.investmentName} 
                />

                {!this.validatateForApprove() && (this.state.roleCode === '001' || this.state.roleCode === '002') ?
                    <>
                        <div className="foundLetter-main">
                            <div className='heading'>
                                <h3>Executive Letter</h3>
                            </div>
                            <div className='buttoncontainer'>
                                <div className="form-group">
                                    <LaddaButton
                                        className='approve-exl'
                                        loading={this.state.loadinglada3}
                                        data-color="#eee"
                                        data-size={XS}
                                        onClick={() => this.notifyExl()}
                                        data-style={EXPAND_RIGHT}
                                        data-spinner-size={30}
                                        data-spinner-color="#ddd"
                                        data-spinner-lines={12}
                                    >NOTIFY</LaddaButton>
                                </div>
                                <div className="form-group">
                                    <LaddaButton
                                        className='approve-exl'
                                        loading={this.state.loadinglada2}
                                        data-color="#eee"
                                        data-size={XS}
                                        onClick={() => this.approveExl()}
                                        data-style={EXPAND_RIGHT}
                                        data-spinner-size={30}
                                        data-spinner-color="#ddd"
                                        data-spinner-lines={12}
                                    >APPROVE</LaddaButton>
                                </div>
                            </div>
                            <div className='foundLetter'>
                                <div className='foundLetter-details'>
                                    <React.Fragment>
                                        <div className="form-horizontal">
                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Company Legal Name:</label>
                                                <div className="col-md-8">
                                                    <input className="form-control" id="companyName" type="text" placeholder=''
                                                        onKeyUp={(event) => this.formValidaton(event.target.id)}
                                                        onChange={(event) => { this.handleChange(event.target.id, event.target.value) }}
                                                        value={this.state.name} />
                                                    <p>{this.state.nameValidation}</p>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-md-3 control-label company-label">Company logo:</label>
                                                <div className='col-md-8'>
                                                    <div className='upload-foundLetter-pic '>
                                                        <div className="foundLetter-pic-box">
                                                            <img id='preview' className="profile-pic" src={this.state.iirCompanyImage ? this.state.iirCompanyImage : `${common.path.base_url}/getFileNew?fileId=${this.state.companyLogoId}`} alt='' />
                                                            {/* <div className="p-image">
                                                                <i onClick={() => document.querySelector(`#chooseFile${1}`).click()} className="fa fa-pencil upload-button"></i>
                                                                <FileUploadBlob
                                                                    id={`chooseFile${1}`}
                                                                    getFile={(file) => { this.setState({ choosenFile: file }); this.uploadLogo(this.state.choosenFile) }}
                                                                    acceptType={`image/*`}
                                                                />
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Letter:</label>
                                                <div className="col-md-8">
                                                    <textarea className="form-control" id="formLetter" type="message" placeholder='' rows="4" cols="50"
                                                        onKeyUp={(event) => this.formValidaton(event.target.id)}
                                                        onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                        value={this.state.formLetter}></textarea>
                                                    <p>{this.state.formLetterValidation}</p>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Full Name:</label>
                                                <div className="col-md-8">
                                                    <input className="form-control" id="formName" type='text' placeholder=''
                                                        onKeyUp={(event) => this.formValidaton(event.target.id)}
                                                        onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                        value={this.state.formName} />
                                                    <p >{this.state.formNameValidation}</p>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Title:</label>
                                                <div className="col-md-8">
                                                    <input className="form-control" id="title" type='text' placeholder=''
                                                        onKeyUp={(event) => this.formValidaton(event.target.id)}
                                                        onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                        value={this.state.title} />
                                                    <p >{this.state.titleValidation}</p>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-md-3 control-label1"></label>
                                                <div className="col-md-8 updateButton">
                                                    <div className='foundBtn'>
                                                        <LaddaButton
                                                            id="update-btn"
                                                            loading={this.state.loadinglada}
                                                            onClick={() => this.putData()}
                                                            data-color="#eee"
                                                            data-size={XS}
                                                            data-style={EXPAND_RIGHT}
                                                            data-spinner-size={30}
                                                            data-spinner-color="#ddd"
                                                            data-spinner-lines={12}
                                                            className={_.isEmpty(this.state.name && this.state.title && this.state.formLetter && this.state.formName) ? 'disable' : ''}
                                                        >
                                                            SAVE
                                                        </LaddaButton>
                                                        <LaddaButton
                                                            id="update-btn"
                                                            loading={this.state.loadinglada2}
                                                            onClick={() => this.gotoFoundLetterPreview()}
                                                            data-color="#eee"
                                                            data-size={XS}
                                                            data-style={EXPAND_RIGHT}
                                                            data-spinner-size={30}
                                                            data-spinner-color="#ddd"
                                                            data-spinner-lines={12}
                                                            className={_.isEmpty(this.state.name && this.state.title && this.state.formLetter && this.state.formName) ? 'disable' : ''}

                                                        >
                                                            PREVIEW
                                                        </LaddaButton>
                                                    </div>
                                                    <input type="reset" className="btn btn-default cancel" value="CANCEL"
                                                        onClick={() => {
                                                            let path = `/dashboard`;
                                                            this.props.history.push(path);
                                                        }} />
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                </div>

                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className='iir-main'>
                            <div className='iir-left' style={this.validatateForApprove() ? iirLeftStyle : noneStyle}>
                                <div className='iir-left-panel'>
                                    <div className='iirPreview-btn' style={this.validatateForApprove() ? iirPrevBtnStyle : noneStyle}>
                                        {/* <div className='buttoncontainer'> */}
                                        <h3>{this.state.name}</h3>
                                        {this.validatateForApprove() && (this.state.roleCode === '001' || this.state.roleCode === '002') && <p>You and Startup Admin have approved the Executive Letter. It is now locked.</p>}
                                        <div className='previewAndUnlockBut'>
                                            <TButton className={`preview-iir1 ${this.validatateForApprove() ? 'client-preview' : ''}`} onClick={() => this.previewExl(true)} text={`PREVIEW`} />
                                            {/* className="form-group"  */}

                                            {/* className={`form-group ${this.validatateForApprove() ? 'form-group' : ''}`} onClick={() => this.previewExl(true)} text={`PREVIEW`}/> */}
                                            {
                                                this.validatateForApprove() && (this.state.roleCode === '001' || this.state.roleCode === '002') &&
                                                <>
                                                    <br />
                                                    <TButton className={`preview-iir1 ${this.validatateForApprove() ? 'client-preview' : ''}`} onClick={() => this.unlockExl()} text={`UNLOCK`} />
                                                </>
                                            }
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                }
            </div>

        );
    }
}
// export default Profile;
export default withRouter(FoundLetter);
import common from '../../common';
import apiService from '../shared/services/api.service';


/* global window */

export default class AuthService {

  static getPipelineStartupsList(search, filterType, showType) {
    return new Promise((resolve) => {
      let query = '';
      let endpoint = `${common.path.base_url}/getPipelineStartupsList`;
      if (search && !filterType) {
        query = `search=${search}`
      } else if (filterType && !search) {
        query = `filterType=${filterType}`
      } else if (search && filterType) {
        query = `search=${search}&filterType=${filterType}`
      }

      if(showType != null) {
          if( query != "" ) {
            query = query+`&showType=${showType}`;
          } else {
            query = `showType=${showType}`;
          }
      } else {
        query = query+`&showType=visible`;
      }

      endpoint = `${common.path.base_url}/getPipelineStartupsList?${query}`;
      resolve(apiService.get({
        endpoint,
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }

  static getUserRecommendation(search) {
    return new Promise((resolve) => {
      resolve(apiService.post({
        endpoint: `${common.path.base_url}/getUserRecommendation`,
        body: { search },
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }

  static updatePipelineStartupStatus(id, status, type) {
    return new Promise((resolve) => {
      resolve(apiService.put({
        endpoint: `${common.path.base_url}/updatePipelineStartupStatus/${id}`,
        body: { status, type },
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }
  static moveToActiveStartupList(id) {
    return new Promise((resolve) => {
      resolve(apiService.put({
        endpoint: `${common.path.base_url}/moveToActiveStartupList/${id}`,
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }
  static getInvestorsSentiments(id, tags) {
    return new Promise((resolve) => {
      resolve(apiService.post({
        endpoint: `${common.path.base_url}/getInvestorsSentiments/${id}`,
        body: { tags },
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }
  static recommendationDetail(id) {
    return new Promise((resolve) => {
      resolve(apiService.get({
        endpoint: `${common.path.base_url}/recommendationDetail/${id}`,
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }
  static recommendation(id, search) {
    return new Promise((resolve) => {
      resolve(apiService.post({
        endpoint: `${common.path.base_url}/recommendation/${id}`,
        body: { search },
        headers: { Authorization: window.localStorage.getItem('token') },
      }));
    });
  }
  static jwtAuthorizationHeader() {
    return 'JWT '.concat(window.localStorage.getItem('token'));
  }
}

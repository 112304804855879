import common from '../../../common';
import axios from 'axios';
import swal from 'sweetalert';

export const viewDocs = (companyId, investmentId, type) => {

  const getIIRList = () => {
    let token = window.localStorage.getItem('token');
    axios.get(`${common.path.base_url}/getIIR/${companyId}/${investmentId}`, {
      headers: {
        "Authorization": token
      }
    })
      .then((response) => {
        if (response && response.data && response.data.data && response.data.data.iirPDF) {
          window.location.href = `iirPreviewPDF?companyId=${companyId}&investmentId=${investmentId}`;
        } else {
          window.location.href = `/previewIIR?companyId=${companyId}&investmentId=${investmentId}`
        }

      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
          window.localStorage.clear();
          window.location.href = '/';
          return;
        }
        else if (error && error.response && error.response.data && error.response.data.code === 204) {
          window.localStorage.clear();
          window.location.href = '/';
        }
        else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        }
        else {
          swal({
            title: "Network Error!",
            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }

  switch (type) {
    case 'IIR':
      getIIRList();
      return;
    case 'FAQ':
      return window.location.href = `/investorFaqPreview`
    default:
  }

}


import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import swal from 'sweetalert';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import './Container.scss';
import LoadingSpinner from '../../components/Loader';
import InvestorSentimentsDetails from '../components/InvestorSentimentsDetails'
import { isLoading, isNotLoading } from '../../shared/actions/loadingSpinner';
import PipelineStartupsService from '../services/PipelineStartups.service';
import { setInvestorsSentimentsDetails } from '../actions/investorsSentimentsDetails'
import common from '../../common';

class MainContainer extends Component {
    constructor() {
        super()
        this.adminAccess = false;
        this.id = null;
        this.state = {
            tags: [],
        }
    }
    componentDidMount() {
        this.props.isNotLoading()
        const url = new URL(window.location.href);
        this.id = url.searchParams.get('id');
        if (!this.id) {
            this.props.history.push('/pipeline-startups')
        } else {
            this.loadInvestorSentimentData()
        }
    }
    loadInvestorSentimentData = () => {
        this.props.isLoading();
        this.getInvestorsSentiments()
            .then(() => {
                this.props.isNotLoading()
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error! #33003",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                if (error.response && error.response.data && error.response.data.code && error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
            })

    }
    getInvestorsSentiments = (tags) => {
        return new Promise((resolve, reject) => {
            PipelineStartupsService.getInvestorsSentiments(this.id, tags)
                .then(response => {
                    if (response && response.data && response.data.data) {
                        console.log(55, response.data.data);
                        this.props.setInvestorsSentimentsDetails(response.data.data);
                    }
                    resolve()
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    searchInvestorSentiments(tags) {
        let newTags = [];
        if (tags && tags.length > 0) {
            newTags = tags.map(item => item.name);
        }

        // this.props.isLoading();
        this.setState(tags);
        // this.getInvestorsSentiments(newTags)
        // .then(() => { 
        //     this.props.isNotLoading()
        // })
        // .catch((error) => {
        //     swal({
        //         title: "Error!",
        //         text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
        //         icon: "error",
        //         button: "Ok!",
        //     });
        //     if (error.response && error.response.data && error.response.data.code && error.response.data.code === 204) {
        //         window.localStorage.clear();
        //         window.location.href = '/';
        //     }
        // })
    }
    render() {
        return (
            <>
                {
                    this.props.loadingSpinner ? <LoadingSpinner /> :
                        <>
                            <InvestorSentimentsDetails
                                adminAccess={true}
                                tags={this.state.tags}
                                searchInvestorSentiments={(tags) => this.searchInvestorSentiments(tags)}
                                {...this.props}
                            />

                        </>
                }

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        loadingSpinner: state.loadingSpinner,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        isLoading: bindActionCreators(isLoading, dispatch),
        isNotLoading: bindActionCreators(isNotLoading, dispatch),
        setInvestorsSentimentsDetails: bindActionCreators(setInvestorsSentimentsDetails, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainContainer));
import React, { Component } from 'react';
import _ from 'lodash';
import ReactTags from 'react-tag-autocomplete'
import axios from 'axios';
import Select, { components } from "react-select";
import makeAnimated from "react-select/animated";

import TButton from '../../components/TButton';
import './InvestmentDetail.scss';
import common from '../../common';


const Option = props => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};
// () => push('/about-us')
const allOption = {
    label: "Select all",
    value: "*"
};

const ValueContainer = ({ children, ...props }) => {
    const currentValues = props.getValue();
    let toBeRendered = children;
    if (currentValues.some(val => val.value === allOption.value)) {
        toBeRendered = [[children[0][0]], children[1]];
    }

    return (
        <components.ValueContainer {...props}>
            {toBeRendered}
        </components.ValueContainer>
    );
};

const MultiValue = props => {
    let labelToBeDisplayed = `${props.data.label}, `;
    if (props.data.value === allOption.value) {
        labelToBeDisplayed = "All is selected";
    }
    return (
        <components.MultiValue {...props}>
            <span>{labelToBeDisplayed}</span>
        </components.MultiValue>
    );
};

const animatedComponents = makeAnimated();



class InviteInvestmentDetailUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inviteUsers: [],
            allInviteUsers: [],
            filter: [],
            options: [
                { name: 'Previously invested in this Startup investment ', id: 1, isChecked: false },
                { name: 'Referred by Startup ', id: 2, isChecked: false },
                { name: 'Previously invested in any TSG investment', id: 3, isChecked: false },
                { name: 'Previously invited to a TSG investment', id: 4, isChecked: false },
            ],
            optionSelected: null,
            pagination: {
                currentPage: 0,
                totalPages: 1,
            },
            isFilterActive: false,
            tags: [],
            searchInputTags: [],
            searchText: null,
            suggestions: [],
            typeSuggestions: [
                { id: 'YPO', name: 'YPO' },
                { id: 'Institutional', name: 'Institutional' },
                { id: 'Strategic', name: 'Strategic' },
                { id: 'Preferred', name: 'Preferred' },
                { id: 'Investment(s)', name: 'Investment(s)' },
                { id: 'Spire One', name: 'Spire One' },
                { id: 'International', name: 'International' },
                { id: 'Federal', name: 'Federal' },
                { id: 'CXO', name: 'CXO' }],
            arrowType: {
                name: 'down',
                emailAddress: 'down',
                company: 'down',
            },
            UserIndicatorOption : [
                { value: 0, label: 'All' },
                { value: 1, label: 'Invitation Accepted' },
                { value: 2, label: 'Invitation Not Accepted' },
            ],
            userIndicatorVal : 0,
            $sort: null,
        }
        // this.onSelect = this.onSelect.bind(this);
        // this.onRemove = this.onRemove.bind(this);

    }

    mapTags(tags) {
        if (!tags) {
            tags = this.state.tags;
        }
        let tagIds = []
        if (tags && tags.length > 0) {
            tagIds = tags.map(tag => tag.id)
        }
        return tagIds;
    }

    mapSelectedFilter(options) {
        let tagIds = []
        if (options && options.length > 0) {
            tagIds = options.map(tag => tag.isChecked && tag.id).filter(id => id);
        }
        return tagIds;
    }

    handleDelete(i) {
        const tags = this.state.tags.slice(0)
        tags.splice(i, 1)
        this.setState({ tags });
        const selectedFilters = this.mapSelectedFilter(this.state.options);
        this.props.filterInvestors(selectedFilters, this.state.searchInputTags, this.mapTags(tags))
    }

    handleAddition(tag) {
        if (tag && tag.id && tag.name) {
            const isExist = this.state.tags.filter(item => item.id === tag.id);
            if (isExist && isExist.length > 0) {
                return;
            }
            const tags = [].concat(this.state.tags, tag);
            this.setState({ tags });
            const selectedFilters = this.mapSelectedFilter(this.state.options);
            this.props.filterInvestors(selectedFilters, this.state.searchInputTags, this.mapTags(tags))

        }
    }
    handleInputChange = _.debounce((key) => {
        let temp = this;
        axios.post(`${common.path.base_url}/searchInvestment`, {
            query: key
        })
            .then(function (response) {
                const suggestions = response.data.data.map(opt => ({ name: opt.name, id: opt._id }));
                if (suggestions.length > 0) {
                    temp.setState({ suggestions: [...suggestions], });

                } else {
                    temp.setState({ suggestions: [], });
                }
            })
            .catch(() => { });
    }, 100);

    handleChange = selected => {
        this.setState({
            optionSelected: selected
        });
    };
    componentDidMount() {
        if (this.props.id) {
            this.setState({ inviteUsers: this.props.inviteUsers, allInviteUsers: this.props.inviteUsers, pagination: this.props.pagination, });
            this.props.history.push(`/investmentDetail?id=${this.props.id}&activeTab=invite`)
        }
        window.addEventListener('scroll', this.handleScroll, false);


    }

    componentDidUpdate(prevProps) {
        if (this.props.inviteUsers !== prevProps.inviteUsers) {
            this.setState({ inviteUsers: this.props.inviteUsers, allInviteUsers: this.props.inviteUsers, pagination: this.props.pagination });
        }
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
    }
    mapFilterId(selectedList) {
        const filter = [];
        if (selectedList && selectedList.length > 0) {
            selectedList.forEach(list => {
                filter.push(list.id);
            });
        }
        this.setState({ filter });
        return filter;
    }

    handleScroll = () => {
        if ((Math.round(window.innerHeight + document.documentElement.scrollTop) === document.documentElement.offsetHeight)
            && (this.state.pagination.currentPage < this.state.pagination.totalPages && !this.state.isFilterActive)) {
            this.props.getInviteUser(true, this.state.filter, this.state.searchInputTags, this.mapTags(), this.state.$sort)
        }
    };

    handleSearchInputDelete(i) {
        const tags = this.state.searchInputTags.slice(0)
        tags.splice(i, 1)
        this.setState({ searchInputTags: tags }, () => {
            const selectedFilters = this.mapSelectedFilter(this.state.options);
            this.props.filterInvestors(selectedFilters, this.state.searchInputTags, this.mapTags(), this.state.$sort)
        });
    }

    handleSearchInputAddition(tag) {
        const tags = [].concat(this.state.searchInputTags, tag);
        this.setState({ searchInputTags: tags }, () => {
            const selectedFilters = this.mapSelectedFilter(this.state.options);
            this.props.filterInvestors(selectedFilters, this.state.searchInputTags, this.mapTags(), this.state.$sort)
        });
    }

    async inviteMultiple() {
        this.setState({ tags: [], searchInputTags: [] });
        const selectedFilters = this.mapSelectedFilter(this.state.options);
        
        console.log(this.state.userIndicatorVal);
        this.props.inviteMultipleInvitees(selectedFilters, this.state.searchInputTags, this.mapTags(), this.state.$sort, this.state.userIndicatorVal)
    }

    onFilterChange(event, id) {
        event.stopPropagation();
        const updatedOptions = this.state.options.map(singleOption => {
            singleOption.isChecked = singleOption.id === id ? singleOption.isChecked = event.target.checked : singleOption.isChecked;
            return singleOption;
        });
        this.setState({ options: updatedOptions });
    };

    onFilterClear(event) {
        event.stopPropagation();
        const updatedOptions = this.state.options.map(singleOption => {
            singleOption.isChecked = false;
            return singleOption;
        });
        this.setState({ options: updatedOptions });
    };

    onFilterSubmit() {
        const selectedFilters = this.mapSelectedFilter(this.state.options);
        this.props.filterInvestors(selectedFilters, this.state.searchInputTags, this.mapTags(), this.state.$sort);
    }
    toggleArrow(fieldType) {
        let arrowType = this.state.arrowType;
        let $sort = {};
        if (arrowType[fieldType] === 'down') {
            arrowType[fieldType] = 'up';
            $sort[fieldType] = -1;
        } else {
            arrowType[fieldType] = 'down';
            $sort[fieldType] = 1;
        }
        const selectedFilters = this.mapSelectedFilter(this.state.options);
        this.props.filterInvestors(selectedFilters, this.state.searchInputTags, this.mapTags(), $sort);
        this.setState({ arrowType, $sort });
    }

    userIndicatorChanged(event) {
        console.log(event.value);
        this.setState({userIndicatorVal: event.value}, () =>{
            const selectedFilters = this.mapSelectedFilter(this.state.options);
            this.props.filterInvestors(selectedFilters, this.state.searchInputTags, this.mapTags(), this.state.$sort, this.state.userIndicatorVal)
        });
    }

    render() {
        return (
            <div className="box-wrapper-main">
                <div className="row searchpaddingleftright">
                    <div className='col-xs-12 col-sm-2 search-status' >

                        <div className="filter_section">
                            <div className="dropdown">
                                <span className="btn btn-secondary dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-filter" aria-hidden="true"></i> Filter
                                </span>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <form>
                                        <div className="fiter-head">
                                            <button type="button" className="clear" onClick={(event) => this.onFilterClear(event)}>clear</button>
                                            <span>Filter</span>
                                            <button type="button" className="btn-success" onClick={() => this.onFilterSubmit()}>Done</button>
                                        </div>
                                        <div className="checklist_bg">
                                            {
                                                this.state.options && this.state.options.length > 0 &&
                                                this.state.options.map(singleOption => {
                                                    return <div className="chiller_cb">
                                                        <input id={singleOption.id} type="checkbox" checked={singleOption.isChecked} onChange={(event) => this.onFilterChange(event, singleOption.id)} />
                                                        <label for={singleOption.id}>{singleOption.name}</label>
                                                        <span></span>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-xs-12 col-sm-4 search-status'>
                        <div className='search' style={{ width: '100%' }}>
                            <ReactTags
                                allowNew={true}
                                placeholder='Search'
                                suggestions={this.state.typeSuggestions}
                                tags={this.state.searchInputTags}
                                handleDelete={this.handleSearchInputDelete.bind(this)}
                                handleAddition={this.handleSearchInputAddition.bind(this)} />
                        </div>
                    </div>
                    <div className='col-xs-12 col-sm-4 search-status multi-select'>
                        <div className='search-status'>
                            <div className='search invite-search-filter'>
                                <ReactTags
                                    noSuggestionsText={'No suggestions found'}
                                    allowNew={true}
                                    placeholder='Previous invested in'
                                    tags={this.state.tags}
                                    suggestions={this.state.suggestions}
                                    handleDelete={this.handleDelete.bind(this)}
                                    handleInputChange={this.handleInputChange}
                                    handleAddition={this.handleAddition.bind(this)} />


                            </div>
                        </div>
                    </div>
                    <div className='follow_up' style={{ width: '15%', display:'inline-block' }}>
                        <div className='follow_up' style={{ width: '100%', padding: '15px 5px' }}>
                            <Select
                                defaultValue={ this.state.UserIndicatorOption[0] }
                                id='userIndicator'
                                options={this.state.UserIndicatorOption}
                                //value={this.state.followupOptionVal}
                                onChange={(event) => this.userIndicatorChanged(event)}
                                className="col-md-12 select-followup"
                            />
                        </div>    
                    </div>
                </div>
                <div className="custom-table-view">
                    <table className="table table-custom">
                        <thead>
                            <tr>
                                <th>
                                    <input
                                        type="checkbox"
                                        onChange={(event) => this.props.onCheckedAllInviteeUser(event)}
                                        checked={this.props.isAllUserChecked}
                                    />
                                    <label>&nbsp; &nbsp;All</label>
                                </th>
                                <th></th>
                                <th>NAME<i className={`arrow ${this.state.arrowType['name']}`} onClick={() => this.toggleArrow('name')}></i></th>
                                <th>EMAIL<i className={`arrow ${this.state.arrowType['emailAddress']}`} onClick={() => this.toggleArrow('emailAddress')}></i></th>
                                <th>COMPANY<i className={`arrow ${this.state.arrowType['company']}`} onClick={() => this.toggleArrow('company')}></i></th>
                                <th>STAGE</th>
                                {/* {
                                    this.props.stage && this.props.stage === 'open' ? */}
                                <th><TButton text='Invite Selected' className='invite-btn' onClick={() => this.inviteMultiple()} /></th>
                                {/* :
                                        <th><TButton text='Invite Selected' className='invite-btn' disabled={true} /></th>
                                } */}

                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.inviteUsers.length > 0 &&
                                this.state.inviteUsers.map(user => {
                                    return (
                                        <tr key={user._id}>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={user.isChecked}
                                                    onChange={(event) => this.props.onCheckedSingleInviteeUser(event, user._id)}
                                                />
                                            </td>
                                            <td>
                                                <span className={user.invitationAccepted === '1' ? 'status-indicator' : 'status-indicator-red'} ></span>
                                            </td>
                                            <td className="username-row"
                                                // onClick={() => { this.props.history.push(`/viewProfile?id=${user._id}`) }}
                                                onClick={() => { this.props.showDataInPopup(user._id) }}>
                                                {`${user.firstName} ${user.lastName}`}
                                            </td>
                                            <td className="col-xs-3 col-sm-4 col-md-4 col-lg-4">
                                                {user.emailAddress}
                                            </td>
                                            <td className="col-xs-3 col-sm-4 col-md-4 col-lg-4">
                                                {user.investorCompanyName ? user.investorCompanyName : user.companyName ? user.companyName : ''}
                                            </td>
                                            <td className="col-xs-3 col-sm-1 col-md-1 col-lg-1">
                                                {/* {
                                                    this.props.stage && this.props.stage === 'open' ? */}
                                                <TButton text={`INVITE`} className='invite-btn' onClick={() => this.props.inviteInvestor(user, (isVaild) => {
                                                    if (isVaild) {
                                                        const updatedOptions = this.state.options.map(singleOption => {
                                                            singleOption.isChecked = false;
                                                            return singleOption;
                                                        });
                                                        this.setState({ options: updatedOptions, searchInputTags: [], tags: [] });
                                                    }
                                                })} />
                                                {/* :
                                                        <TButton text={`INVITE`} className='invite-btn' disabled={true} />
                                                } */}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                this.state.inviteUsers.length === 0 &&
                                <tr>
                                    <td>No investors found for invite!</td>
                                </tr>

                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

}

export default InviteInvestmentDetailUsers;
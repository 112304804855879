import { combineReducers } from 'redux'
// import { reducer as formReducer } from 'redux-form';
import myInvestmentView from '../InvestorDashboard/reducers/myInvestmentView'
import expandCollapseList from '../InvestorDashboard/reducers/expandCollapseList'
import loadingSpinner from '../shared/reducers/loadingSpinner'
import investorDashboardData from '../InvestorDashboard/reducers/dashboardData'
import stageFilterDropDown from '../InvestorDashboard/reducers/stageFilter'
import investmentProcessData from '../InvestorDashboard/reducers/investmentProcessData'
import pipelineStartups from '../PipelineStartups/reducers/pipelineStartupsData'
import recommendationList from '../PipelineStartups/reducers/recommendationList'
import userRecommendations from '../PipelineStartups/reducers/userRecommendations'
import popover from '../InvestorDashboard/reducers/popover'
import toDoPopover from '../InvestorDashboard/reducers/toDoPopover'
import investorsSentimentsDetail from '../PipelineStartups/reducers/investorsSentimentsDetail'
import settings from '../InvestorDashboard/reducers/settings'
import hideSidebarNotification from '../InvestorDashboard/reducers/hideSidebarNotifications'
import summaryStartup from '../SummaryStartups/reducers/summaryStartups'
import userLogin from '../UserLogin/reducers/login'

export default combineReducers({
    myInvestmentView,
    expandCollapseList,
    loadingSpinner,
    investorDashboardData,
    stageFilterDropDown,
    investmentProcessData,
    pipelineStartups,
    popover,
    toDoPopover,
    investorsSentimentsDetail,
    recommendationList,
    userRecommendations,
    settings,
    hideSidebarNotification,
    summaryStartup,
    userLogin
})
import React, { useState, useEffect, useRef } from 'react'
import ReactTags from 'react-tag-autocomplete';
import axios from 'axios';
import common from '../../common';
import swal from 'sweetalert';
import { CSVLink, CSVDownload } from "react-csv";
import _ from 'lodash';

const LedgerList = props => { 

    const { getLedgerList, investment, tsgSetting, tsgLoader, getLedgerListEx } = props;

    const [tags, setTags] = useState([]);
    const [exportData, setExportData] = useState([]);
    const [exportCall, setExportCall] = useState(0);
    const [exportLoading, setExportLoading] = useState(0);
    const csvLink = useRef();

    const handleDelete = (i) => {
        const newTags = tags.slice(0)
        newTags.splice(i, 1)
        setTags(newTags);

        props.getSummaryList(newTags);
    }
    
    const handleAddition = (tag) => {
        const newTags = tagsArr(tag);
    
        props.getSummaryList(newTags);
    }

    function tagsArr(tag){
        setTags([].concat(tags, { id: tag, name: tag }));
        const tagss = [].concat(tags, { id: tag, name: tag });
        return tagss;
    }   

    const exportSummary = () => {
        const val = parseInt(exportCall) + 1;
        setExportCall(val);    
    }

    useEffect(() => { 
        
        const UserArray = (getLedgerListEx) ? [...getLedgerListEx] : [];
        if(UserArray.length > 0) {
            let arr = [];

            var userExData = new Promise((resolve, reject) => {
                UserArray.forEach(function(singleData, index, UserArray) {
                    //console.log("loll--",singleData);
                    let amtInst = (singleData.amount) ? Number(singleData.amount).toFixed(2) : 0;   
                    const mgfee = ( singleData.isFeeAndCarrySet && singleData.managementFee >=0) ? singleData.managementFee : props.tsgSetting.managementFee;
                    const mgfeeVal = ( mgfee > 0 ) ?  ( (mgfee / 100) * amtInst ) : 0;
                    const carry = ( singleData.isFeeAndCarrySet && singleData.carry >=0 ) ? singleData.carry : props.tsgSetting.carry;
                    const netAmt =  amtInst - mgfeeVal;

                    let strategicVal = (singleData.user.investorCategories) ? singleData.user.investorCategories : [];
                    let defaultpps = (singleData.pricePerShare > 0) ? singleData.pricePerShare : investment.pricePerShare;
                    let shares = (defaultpps > 0) ? (amtInst / 1) : netAmt;
                    let subShares = (defaultpps > 0) ? (netAmt / defaultpps) : netAmt;

                    let fullAddress = singleData.address1;
                    if (singleData.address2) {
                        fullAddress += ', ' + singleData.address2;
                    }
                    fullAddress += ', ' + singleData.city;
                    fullAddress += ', ' + singleData.state;
                    fullAddress += ', ' + singleData.zipcode;
                    
                    let ObjCode = { 
                        'FIRST NAME': (singleData.user.firstName) ? singleData.user.firstName : singleData.firstName,
                        'LAST NAME': (singleData.user.lastName) ? singleData.user.lastName : singleData.lastName,
                        //'TYPE': ( strategicVal.includes('Strategic') ) ? "Strategic" : "Non-Strategic",
                        'ENTITY': (singleData.type =='entity') ? ( singleData.entityName ) : '-',
                        'ADDRESS1': (singleData.address1),
                        'ADDRESS2': (singleData.address2),
                        'CITY': (singleData.city),
                        'STATE': (singleData.state),
                        'ZIP': (singleData.zipcode),
                        'TAX ID NUMBER': (singleData.taxIdNumber!= "" && singleData.taxIdNumber!=null ) ? singleData.taxIdNumber : singleData.foreignTaxId,
                        'GROSS CAPITAL COMMITMENT': '$'+amtInst,
                        'NUMBER OF UNITS': Number(shares).toFixed(4),
                        'MANAGEMENT FEE': Number(mgfeeVal).toFixed(2),
                        'NET CAPITAL': '$'+netAmt,
                        'EST TO ACTUAL SHARES': Number(subShares).toFixed(2),
                        'CARRY': carry+'%',
                    };
                    arr.push(ObjCode);
                    if (index === UserArray.length -1) resolve();
                });
                
            });
            userExData.then(() => {
                setExportData(arr);
                csvLink.current.link.click();
            });
        }
    },[ exportCall]);

    const getLedgerListCall = (props) => {
        
        //console.log(getLedgerList);
        const arrData = (getLedgerList.result) ? [...getLedgerList.result] : [];

        if( arrData.length > 0 ) {
            return (arrData.map((singleData, i) => {
                //console.log("lliiii", singleData);
                let amtInst = (singleData.amount) ? Number(singleData.amount).toFixed(2) : 0;   
                const mgfee = ( singleData.isFeeAndCarrySet && singleData.managementFee >=0) ? singleData.managementFee : tsgSetting.managementFee;
                const mgfeeVal = ( mgfee > 0 ) ?  ( (mgfee / 100) * amtInst ) : 0;
                const carry = ( singleData.isFeeAndCarrySet && singleData.carry >=0 ) ? singleData.carry : tsgSetting.carry;
                const netAmt =  amtInst - mgfeeVal;

                let strategicVal = (singleData.user.investorCategories) ? singleData.user.investorCategories : [];
                let defaultpps = (singleData.pricePerShare > 0) ? singleData.pricePerShare : investment.pricePerShare;
                let shares = (defaultpps > 0) ? (amtInst / 1) : netAmt;
                let subShares = (defaultpps > 0) ? (netAmt / defaultpps) : netAmt;

                return( 
                <>  
                    <tr>
                        <td style={{ 'textTransform': 'capitalize' }}>{ (singleData.user.name) ? singleData.user.name : singleData.firstName }</td>
                        <td><span className={ ( strategicVal.includes('Strategic') ) ? 'greenColor':'' } > { ( strategicVal.includes('Strategic') ) ? "Strategic" : "Non-Strategic" } </span></td>
                        <td>{ (singleData.type =='entity') ? 
                                ( singleData.entityName ) 
                                : 
                                <span style={{ 'backgroundColor':'#A8A8AE', 'color':'#fff', 'padding':'4px 6px', 'borderRadius':'5px' }}>Individual</span> 
                            }
                        </td>
                        <td>${ amtInst }</td>
                        <td>{ Number(shares).toFixed(4) }</td>
                        <td>({ Number(mgfeeVal).toFixed(2) }) { mgfee}%</td>
                        <td>${ Number(netAmt).toFixed(2) }</td>
                        <td>{ Number(subShares).toFixed(2) }</td>
                        <td>{ carry }%</td>
                    </tr>    
                </>
                );
            
            }))
        } else {
            return (
                <tr>
                    <td colSpan={9} style={{ "textAlign":"center" }}>
                        <b>No data found</b>
                    </td>
                </tr>
            )
        }
    }

    return (
        <>  
            <div className='row'>
                <div className='col-md-12 cod-laptop'>
                    <div className='col-xs-12 col-sm-5 filter-bar-status'>
                        <div className='search col-md-11 react-card' style={{ 'padding':'12px 0px','display':'inline-block' }}>
                            <ReactTags
                                allowNew={true}
                                placeholder='Search Investor'
                                tags={tags}
                                handleDelete={(i) => handleDelete(i)}
                                handleAddition={(value) => handleAddition(value.name)}
                            />
                        </div>
                        <div className='follow_up col-md-1' style={{ 'padding': '0px' }}>
                            <div className='follow_up' style={{ 'padding': '12px 0px', 'display':'inline-block' }} >
                                <a href='javascript:void(0)' className="tsg-btn invite exportBtn" onClick={(e) => exportSummary(e) } >
                                    {
                                        (exportLoading > 0) ? 
                                        <><i className="fa fa-spinner fa-spin ex-icon" aria-hidden="true" />Loading </>
                                        :
                                        <><i className="fa fa-file-text ex-icon" aria-hidden="true" />&nbsp;&nbsp;EXPORT</>
                                    }
                                </a>
                                <CSVLink 
                                    data={ exportData } 
                                    filename={"ledger_list.csv"}
                                    className="hidden"
                                    ref={ csvLink }
                                    target="_blank"
                                />
                            </div>    
                        </div>
                    </div>

                    <div className="custom-table-view table-responsive">
                        
                        <table className="table table-custom sortable">
                            <thead>
                                <tr>
                                    <th>NAME</th>
                                    <th>TYPE</th>
                                    <th>ENTITY</th>
                                    <th>GROSS CAPITAL COMMITMENT</th>
                                    <th>NUMBER OF UNITS</th>
                                    <th>MANAGEMENT FEE</th>
                                    <th>NET CAPITAL</th>
                                    <th>EST TO ACTUAL SHARES</th>
                                    <th>CARRY</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                (tsgLoader) ?   
                                    <tr>
                                        <td colSpan={9} style={{ "textAlign":"center" }}>
                                            <div className="spinner-border" role="status" style={{ 'fontSize':'25px' }}>
                                                <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                            </div>
                                        </td>
                                    </tr>
                                :
                                    getLedgerListCall() 
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </> 
    )       

}

export default LedgerList;
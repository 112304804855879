export const setPopover = (type, id) => {
  return {
    type,
    id,
  }
}
  
export const resetPopover = () => ({
  type: 'Reset',
  
})
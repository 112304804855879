import React, { Component } from "react";
import "./foundLetterPreview.scss";
import "react-phone-input-2/lib/style.css";
import AddAddressModal from "../../components/AddAddressModal";
import Header from "../../components/Header";
import HeaderImg from "../../components/HeaderImage";
import LoadingSpinner from "../../components/Loader";
import FileUploadBlob from "../../components/FileUploadBlob";
import { withRouter } from "react-router-dom";
import LaddaButton, { XS, EXPAND_RIGHT } from "react-ladda";
import axios from "axios";
import swal from "sweetalert";
import Modal from "react-bootstrap-modal";
import common from "../../common";
import images from "../../images";
import _ from "lodash";
import { Markup } from "interweave";
import TButton from "../../components/TButton";
import ReactToPrint from "react-to-print";

class FoundLetterPreview extends Component {
    constructor(props) {
        super(props);
        this.companyId = "";
        this.investmentId = "";
        this.state = {
            formLetter: "",
            formName: "",
            companyLogoId: "",
            roleCode: null,
        };
    }

    componentWillMount() {
        const userRole = window.localStorage.getItem("role");
        this.setState({ Role: userRole });
        const userRoleCode = window.localStorage.getItem("roleCode");
        this.setState({ roleCode: userRoleCode });
        const url = new URL(window.location.href);
        this.companyId = url.searchParams.get("companyId");
        this.investmentId = url.searchParams.get("investmentId");
        this.getData();
    }
    // getData() {
    //     let temp = this;
    //     temp.setState({ loading: true });
    //     let token = window.localStorage.getItem('token');
    //     let CompanyId = window.localStorage.getItem('companyId');
    //     // const id = window.localStorage.getItem('_id');
    //     // this.setState({ id: id })
    //     axios.get(`${common.path.base_url}/getFounderLetter?companyId=${CompanyId}`, {
    //         headers: {
    //             "Authorization": token
    //         }
    //     })
    //         .then((response) => {
    //             temp.setState({ loading: false });
    //             temp.setState({
    //                 id: response.data.data._id,
    //                 bankName: response.data.data.bankName,
    //                 bankAddress: response.data.data.bankAddress,
    //                 accHolderName: response.data.data.accountHolderName,
    //                 accNumber: response.data.data.accountNumber,
    //                 routingNo: response.data.data.routingNumber,
    //                 memo: response.data.data.memo
    //             })
    //         })
    //         .catch(function (error) {
    //             temp.setState({ loading: false });
    //             if (error.response) {
    //                 swal({
    //                     title: "Error!",
    //                     text: error.response.data.message,
    //                     icon: "error",
    //                     button: "Ok!",
    //                 });
    //             }
    //             else {
    //                 swal({
    //                     title: "Network Error!",
    //                     text: "Something went wrong",
    //                     icon: "error",
    //                     button: "Ok!",
    //                 });
    //             }
    //         });
    // }

    getData() {
        let temp = this;
        let token = window.localStorage.getItem("token");
        const url = new URL(window.location.href);
        this.companyId = url.searchParams.get("companyId");
        // let CompanyId = window.localStorage.getItem('companyId');
        temp.setState({ loading: true });
        const id = window.localStorage.getItem("id");
        this.setState({ id: id });
        axios
            .get(
                `${common.path.base_url}/getInvestmentIIRExecutiveLetter?companyId=${this.companyId}&investmentId=${this.investmentId}`,
                {
                    headers: {
                        Authorization: token,
                    },
                }
            )
            .then((response) => {
                temp.setState({ loading: false });
                this.setState({ name: response.data.data.companyName });
                this.setState({ formLetter: response.data.data.formLetter });
                this.setState({ formName: response.data.data.formName });
                this.setState({
                    title: response.data.data.title,
                    companyLogoId: response.data.data.companyLogo,
                });
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message &&
                    common.AUTH_EXPIRED === error.response.data.message
                ) {
                    window.localStorage.clear();
                    window.location.href = "/";
                    return;
                } else if (error.response) {
                    swal({
                        title: "Error!",
                        text:
                            error.response &&
                                error.response.data &&
                                error.response.data.message
                                ? error.response.data.message
                                : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                } else {
                    swal({
                        title: "Network Error!",
                        text:
                            error.response &&
                                error.response.data &&
                                error.response.data.message
                                ? error.response.data.message
                                : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    render() {
        const username = window.localStorage.getItem("username");
        const companyNickname = window.localStorage.getItem("companyNickname");
        const companyName = window.localStorage.getItem("companyName");
        return (
            <div>
                <div className="FoundPreviewinstuction">
                    {this.state.loading ? <LoadingSpinner /> : ""}
                    <Header username={username} role={this.state.Role} />
                    {(this.state.roleCode === '003' || this.state.roleCode === '004') ?
                        <HeaderImg
                            heading={`${companyNickname ? companyNickname : companyName ? companyName : ''}`}
                        />
                        : <HeaderImg
                            heading={`Executive Letter`}
                        />
                    }
                    <div className="FoundPreviewinstuction-main" >
                        <div className="close-btn">
                            {/* <ReactToPrint
                                trigger={() => <TButton text={`Print`} className='share-btn' />}
                                content={() => this.componentRef}
                                pageStyle={'padding : 40px'}
                            /> */}

                            {this.state.roleCode !== "005" &&
                                this.state.roleCode !== "004" ? (
                                <div className="edit-section">
                                    <i
                                        className="fa fa-pencil add-address"
                                        onClick={() =>
                                            this.props.history.push(
                                                `/executiveLetter?companyId=${this.companyId !== undefined ? this.companyId : ""
                                                }&investmentId=${this.investmentId}`
                                            )
                                        }
                                    ></i>
                                </div>
                            ) : (
                                <div className="edit-section">
                                    {/* <i  className="fa fa-pencil add-address" onClick={() => this.props.history.push(`/executiveLetter?companyId=${this.companyId !== undefined ? this.companyId : ''}`)}></i> */}
                                </div>
                            )}
                        </div>

                        <div className="close-btn-wraper">
                            <button
                                type="button"
                                className="close"
                                aria-label="Close"
                                onClick={() => this.props.history.goBack()}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div
                            className="Inner-most-box"
                            ref={(elements) => (this.componentRef = elements)}
                        >
                            <div className="Inner-most-boxHeader">
                                <img src={images.path.productLogo} className="logo"></img>
                                {this.state.companyLogoId && (
                                    <img
                                        src={`${common.path.base_url}/getFileNew?fileId=${this.state.companyLogoId}`}
                                        className="logo logo-preview"
                                    ></img>
                                )}
                            </div>
                            <div className="Inner-most-boxContain">
                                <div className="title-txt"> STRATEGIC CAPITAL PROGRAM </div>
                                <div className="Inner-most-boxContain-head">
                                    <div className="exLetterName">
                                        {" "}
                                        {this.state.name && this.state.name}{" "}
                                    </div>
                                    {/* <div className="edit-section">
                                        
                                    <i  className="fa fa-pencil add-address" onClick={() => this.props.history.push(`/executiveLetter?companyId=${this.companyId !== undefined ? this.companyId : ''}`)}></i>
                                    </div> */}
                                </div>
                                <div className="Inner-most-boxContain-body">
                                    {/* {this.state.formLetter && this.state.formLetter } */}
                                    {this.state.formLetter && (
                                        <Markup content={this.state.formLetter} />
                                    )}
                                </div>
                                <div className="Inner-most-boxContain-foot">
                                    <div className="salutation">
                                        <div className="founderName">
                                            {this.state.formName && this.state.formName}
                                            &nbsp;&nbsp;
                                            {this.state.formName && (
                                                <>{this.state.title ? this.state.title : "Executive"}</>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="confidential-txt">
                                    <div className="footer-logo">
                                        <img src={images.path.productLogo}></img>
                                    </div>
                                confidential
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    id="updateFAQ"
                    aria-labelledby="ModalHeader"
                    show={this.state.show}
                >
                    <Modal.Header>
                        <Modal.Title id="ModalHeader">Add Address</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="form-horizontal">
                            <div className="form-group">
                                <label className="col-md-3 control-label">Address 1:</label>
                                <div className="col-md-8">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        id="address1"
                                        onKeyUp={(event) => this.formValidaton(event.target.id)}
                                        onChange={(event) =>
                                            this.handleChange(event.target.id, event.target.value)
                                        }
                                        value={this.state.address1}
                                    />
                                    <p>{this.state.address1Validation}</p>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-3 control-label">Address 2:</label>
                                <div className="col-md-8">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        id="address2"
                                        onKeyUp={(event) => this.formValidaton(event.target.id)}
                                        onChange={(event) =>
                                            this.handleChange(event.target.id, event.target.value)
                                        }
                                        value={this.state.address2}
                                    />
                                    <p>{this.state.address2Validation}</p>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-3 control-label">City:</label>
                                <div className="col-md-8">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        id="city"
                                        onKeyUp={(event) => this.formValidaton(event.target.id)}
                                        onChange={(event) =>
                                            this.handleChange(event.target.id, event.target.value)
                                        }
                                        value={this.state.city}
                                    />
                                    <p>{this.state.cityValidation}</p>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-3 control-label">State:</label>
                                <div className="col-md-8">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        id="state"
                                        onKeyUp={(event) => this.formValidaton(event.target.id)}
                                        onChange={(event) =>
                                            this.handleChange(event.target.id, event.target.value)
                                        }
                                        value={this.state.state}
                                    />
                                    <p>{this.state.stateValidation}</p>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-3 control-label">Zip:</label>
                                <div className="col-md-8">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        id="zip"
                                        onKeyUp={(event) => this.formValidaton(event.target.id)}
                                        onChange={(event) =>
                                            this.handleChange(event.target.id, event.target.value)
                                        }
                                        value={this.state.zip}
                                    />
                                    <p>{this.state.zipValidation}</p>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <LaddaButton
                            id="lada-btn"
                            loading={this.state.loadinglada2}
                            data-color="#eee"
                            data-size={XS}
                            onClick={() => this.putaddressData()}
                            className={
                                !_.isEmpty(
                                    this.state.address1 &&
                                    this.state.address2 &&
                                    this.state.city &&
                                    this.state.state &&
                                    this.state.zip
                                )
                                    ? ""
                                    : "disable"
                            }
                            data-style={EXPAND_RIGHT}
                            data-spinner-size={30}
                            data-spinner-color="#ddd"
                            data-spinner-lines={12}
                        >
                            ADD
                        </LaddaButton>
                        <input
                            type="button"
                            id="company-cancel"
                            className="btn btn-default"
                            data-dismiss="modal"
                            value="CANCEL"
                            onClick={() => this.resetAddAddressData()}
                        />
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
// export default Profile;
export default withRouter(FoundLetterPreview);

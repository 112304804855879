import React from 'react';
import propTypes from 'prop-types';

import './globalInvestorsFollowupMobileView.scss'
const InvestmentFollowup = (props) => {
  return (
    <div className="investors-followup mobile-view">
      <h2>Investor Follow Up Settings</h2>
      <table>
        <thead>
          <tr>
            <th width="">Investment Stage</th>
            <th width="">Duration(Days)</th>
            <th width="">Times</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>
              <div className="document">Participated <span>(Generete Docs Pending)</span></div>
            </td>
            <td>
              <div className="select-item">
                <select className="form-control" value={props.participated.duration} onChange={(event) => props.onDaysChange(event, 'participated')}>
                  {
                    props.daysOptions.map((singleDay, index) => {
                      return <option value={singleDay.value} key={index}>{singleDay.label}</option>
                    })
                  }
                </select>
              </div>
            </td>
            <td>
              <div className="select-item">
                <select className="form-control" value={props.participated.times} onChange={(event) => props.onTimeChange(event, 'participated')}>
                  {
                    props.timeOptions.map((singleTime, index) => {
                      return <option value={singleTime.value} key={index}>{singleTime.label}</option>
                    })
                  }
                </select>
              </div>
            </td>

          </tr>
          <tr>
            <td colSpan="2" className="padding-remove"></td>

            <td className="padding-remove">
              <div className="notified">Investor will be notified every<span>{props.participated.duration} days</span>for<span>{props.participated.times} times</span></div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="document">Generated Docs <span>(Docs Sign Pending)</span></div>
            </td>
            <td>
              <div className="select-item">
                <select className="form-control" value={props.interested.duration} onChange={(event) => props.onDaysChange(event, 'interested')}>
                  {
                    props.daysOptions.map((singleDay, index) => {
                      return <option value={singleDay.value} key={index}>{singleDay.label}</option>
                    })
                  }
                </select>
              </div>
            </td>
            <td>
              <div className="select-item">
                <select className="form-control" value={props.interested.times} onChange={(event) => props.onTimeChange(event, 'interested')}>
                  {
                    props.timeOptions.map((singleTime, index) => {
                      return <option value={singleTime.value} key={index}>{singleTime.label}</option>
                    })
                  }
                </select>
              </div>
            </td>

          </tr>
          <tr>
            <td colSpan="2" className="padding-remove"></td>

            <td className="padding-remove">
              <div className="notified">Investor will be notified every<span>{props.interested.duration} days</span>for<span>{props.interested.times} times</span></div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="document">Signed Docs <span>(Fund Pending)</span></div>
            </td>
            <td>
              <div className="select-item">
                <select className="form-control" value={props.executed.duration} onChange={(event) => props.onDaysChange(event, 'executed')}>
                  {
                    props.daysOptions.map((singleDay, index) => {
                      return <option value={singleDay.value} key={index}>{singleDay.label}</option>
                    })
                  }
                </select>
              </div>
            </td>
            <td>
              <div className="select-item">
                <select className="form-control" value={props.executed.times} onChange={(event) => props.onTimeChange(event, 'executed')}>
                  {
                    props.timeOptions.map((singleTime, index) => {
                      return <option value={singleTime.value} key={index}>{singleTime.label}</option>
                    })
                  }
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan="2" className="padding-remove"></td>

            <td className="padding-remove">
              <div className="notified">Investor will be notified every<span>{props.executed.duration} days</span>for<span>{props.executed.times} times</span></div>
            </td>
          </tr>
        </tbody>
      </table>
      <button className="tsg-btn" onClick={() => props.saveInvestmentSettings()}>Save</button>
    </div>
  )
};

InvestmentFollowup.propTypes = {
  participated: propTypes.object.isRequired,
  interested: propTypes.object.isRequired,
  executed: propTypes.object.isRequired,
  daysOptions: propTypes.array.isRequired,
  onDaysChange: propTypes.func.isRequired,
  onTimeChange: propTypes.func.isRequired,
  timeOptions: propTypes.array.isRequired,
  saveInvestmentSettings: propTypes.func.isRequired
};

export default InvestmentFollowup;
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import axios from "axios";
import _ from "lodash";
import LaddaButton, { EXPAND_RIGHT, XS } from "react-ladda";
import NumberFormat from "react-number-format";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import swal from "sweetalert";
import { hideSidebarNotification } from "../../InvestorDashboard/actions/hideSidebarNotifications";
import { default as common, default as editorConfig } from "../../common";
import Header from "../../components/Header";
import HeaderImg from "../../components/HeaderImage";
import LoadingSpinner from "../../components/Loader";
import RequiredSign from "../../components/RequiredSign";
import TButton from "../../components/TButton";
import "./Profile.scss";
import UserCheckModal from "./UserCheckModal";

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import countryJson from './countries.json';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      name: '',
      firstName: '',
      firstNamePreferred: '',
      middleName: '',
      lastName: '',
      title: '',
      company: '',
      emailAddress: '',
      emailAddress2: '',
      mobile: '',
      office: '',
      address2: '',
      city: '',
      stateName: '',
      zipcode: '',
      country: '',
      loading: false,
      loadinglada: false,
      Role: '',
      roleCode: '',
      nameValidation: '',
      firstNameValidation: '',
      lastNameValidation: '',
      titleValidation: '',
      addressValidation: '',
      companyValidation: '',
      emailAddressValidation: '',
      emailAddress2Validation: '',
      mobileValidation: '',
      officeValidation: '',
      profileFormValidated: true,
      inputFile: null,
      notes: '',
      startupCompanyTags: [],
      startupInvestmentTags: [],
      investorReferralTags: [],
      defaulutInvestorReferralTags: [],
      startupCompaniesList: [],
      startupInvestmentsList: [],
      selectedStartupCompanyOption: '',
      selectedStartupInvestmentOption: '',
      investorReferralList: [],
      selectedReferralOption: '',
      selectedcategory: '',
      category: [{ value: 'YPO', label: 'YPO' }, { value: 'Institutional', label: 'Institutional' }, { value: 'Strategic', label: 'Strategic' }, { value: 'Unsubscribed', label: 'Unsubscribed' }, { value: 'Blacklist', label: 'Blacklist' }, { value: 'Preferred', label: 'Preferred' }, { value: 'Investment(s)', label: 'Investment(s)' }, { value: 'Spire One', label: 'Spire One' }, { value: 'International', label: 'International' }, { value: 'Federal', label: 'Federal' }, { value: 'CXO', label: 'CXO' }],
      selectedCategoryTags: [],
      enterpassValidation: '',
      confirmpassValidation: '',
      pass1: '',
      pass2: '',
      isModalValid: false,
      userRole: null,
      allowChangePassword: false,
      notesCheck: false,
      loginMobile: "",
      isEmailFailure: false
    }
    const url = new URL(window.location.href);

    this.userId = url.searchParams.get('id');
    this.loggedInUserID = window.localStorage.getItem('id');
    this.goback = this.goback.bind(this);
    //console.log("asdasdasdasdasdsad11111");
  }


  setProfileImage() {
    let elements = document.querySelectorAll("#header-img");
    elements.forEach((imgEle) => {
      imgEle.src = `${imgEle.src}&update=${new Date()}`;
    });
  }

  componentWillMount() {
    this.getData();
    const userRole = window.localStorage.getItem("role");
    this.setState({ Role: userRole });
    const userRoleCode = window.localStorage.getItem("roleCode");
    this.setState({ roleCode: userRoleCode });

    //console.log("saasdasdasdasdasdsad");
    const loginId = window.localStorage.getItem("id");
    //console.log("saasdasdasdasdasdsad",loginId);
    axios
      .get(`${common.path.base_url}/getUserById`, {
        params: {
          _id: loginId,
        },
      })
      .then((res) => {
        const data = res.data.data;
        //console.log("daasdasdsad",data.mobile)
        this.setState({ loginMobile: data.mobile });
      });
  }
  goback(event) {
    event.preventDefault();
    this.props.history.goBack();
  }
  getData() {
    const userRoleCode = window.localStorage.getItem("roleCode");
    if (this.state.inputFile) {
      this.setProfileImage();
    }
    let temp = this;
    temp.setState({ loading: true });
    let id = window.localStorage.getItem("id");

    if (this.userId) {
      id = this.userId;
    } else {
      this.userId = id;
    }
    if (this.userId === window.localStorage.getItem("id")) {
      this.setState({ allowChangePassword: true });
    }

    this.setState({ id: id });
    const userId = window.localStorage.getItem("id");
    axios
      .get(`${common.path.base_url}/getUserById?_id=${id}&user=${userId}`)
      .then((response) => {
        console.log(response.data.data);
        // const splitName = response.data.data.name.split(' ');
        temp.setState({ loading: false });
        this.setState({ firstName: response.data.data.firstName });
        this.setState({
          firstNamePreferred: response.data.data.firstNamePreferred,
        });
        this.setState({ lastName: response.data.data.lastName });
        this.setState({ middleName: response.data.data.middleName });
        this.setState({ title: response.data.data.title });
        this.setState({
          notes:
            response.data.data.notes != undefined
              ? response.data.data.notes
              : "",
        });
        this.setState({ userRole: response.data.data.role });
        let startupCompanyTags = [];
        let startupInvestmentTags = [];
        let defaulutInvestorReferralTags = [];
        let selectedCategoryTags = [];
        if (
          response.data.data.companies &&
          response.data.data.companies.length > 0
        ) {
          startupCompanyTags = response.data.data.companies.map((company) => {
            return { id: company._id, name: company.companyName };
          });
        }
        if (
          response.data.data.referralsInvestmentIds &&
          response.data.data.referralsInvestmentIds.length > 0
        ) {
          startupInvestmentTags = response.data.data.referralsInvestmentIds.map(
            (investment) => {
              return { id: investment._id, name: investment.name };
            }
          );
        }
        if (
          response.data.data.referrals &&
          response.data.data.referrals.length > 0
        ) {
          defaulutInvestorReferralTags = response.data.data.referrals.map(
            (referral) => {
              return { id: referral._id, name: referral.name };
            }
          );
        }
        if (
          response.data.data.investorCategories &&
          response.data.data.investorCategories.length > 0
        ) {
          selectedCategoryTags = response.data.data.investorCategories.map(
            (category) => {
              return { id: category, name: category };
            }
          );
        }
        this.setState({
          startupCompanyTags,
          defaulutInvestorReferralTags,
          selectedCategoryTags,
          startupInvestmentTags,
        });
        this.setState({
          notes:
            response.data.data.notes != undefined
              ? response.data.data.notes
              : "",
        });
        this.setState({ emailAddress: response.data.data.emailAddress });
        this.setState({ isEmailFailure: response.data.data.isEmailFailure });
        this.setState({ emailAddress2: response.data.data.emailAddress2 });
        this.setState({ mobile: response.data.data.mobile });
        this.setState({
          office: response.data.data.office ? response.data.data.office : "",
        });
        this.setState({ address: response.data.data.address });

        this.setState({ address2: response.data.data.address2 });
        this.setState({ city: response.data.data.city });
        this.setState({ stateName: response.data.data.state });
        this.setState({ zipcode: response.data.data.zipcode });
        this.setState({ country: response.data.data.country });
        if (response.data.data && response.data.data.role !== "005") {
          this.setState({
            company: response.data.data.companyName
              ? response.data.data.companyName
              : response.data.data.companyId &&
                response.data.data.companyId.companyName
                ? response.data.data.companyId.companyName
                : "",
          });
        } else {
          this.setState({
            company: response.data.data.investorCompanyName
              ? response.data.data.investorCompanyName
              : response.data.data.companyName
                ? response.data.data.companyName
                : "",
          });
        }
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  chooseFile() {
    document.getElementById("choose-file").click();
  }

  handleChange(id, newValue) {
    if (id === "name") this.setState({ name: newValue });
    else if (id === "firstName") this.setState({ firstName: newValue });
    else if (id === "firstNamePreferred")
      this.setState({ firstNamePreferred: newValue });
    else if (id === "lastName") this.setState({ lastName: newValue });
    else if (id === "middleName")
      this.setState({ middleName: newValue.trim() });
    else if (id === "title") this.setState({ title: newValue });
    else if (id === "company") this.setState({ company: newValue });
    else if (id === "email") this.setState({ emailAddress: newValue });
    else if (id === "email2") this.setState({ emailAddress2: newValue });
    else if (id === "mobile") {
      this.setState({ mobile: `+${newValue}` }, () =>
        this.formValidaton("mobile", newValue)
      );
    } else if (id === "office") {
      this.setState({ office: `+${newValue}` }, () =>
        this.formValidaton("office", newValue)
      );
    } else if (id === "notes") this.setState({ notes: newValue });
    else if (id === "category") {
      let selectedCategoryTags = [
        ...new Set(
          [
            ...this.state.selectedCategoryTags,
            { id: newValue.value, name: newValue.label },
          ].map((o) => JSON.stringify(o))
        ),
      ].map((s) => JSON.parse(s));
      this.setState({ selectedCategoryTags });
    } else if (id === "password1") this.setState({ pass1: newValue });
    else if (id === "password2") this.setState({ pass2: newValue });
    else if (id === "address") this.setState({ address: newValue });
    else if (id === "address2") this.setState({ address2: newValue });
    else if (id === "city") this.setState({ city: newValue });
    else if (id === "state") this.setState({ stateName: newValue });
    else if (id === "zipcode") this.setState({ zipcode: newValue });
    else if (id === "country") this.setState({ country: newValue });
  }

  handleModalChange(id, newValue) {
    if (id === "password1") this.setState({ pass1: newValue });
    else if (id === "password2") this.setState({ pass2: newValue });
  }

  previewFile() {
    const preview = document.getElementById('preview');
    const file = document.querySelector('input[type=file]').files[0];
    const data = new FormData()
    data.append('file', file);
    this.setState({ inputFile: file })
    const reader = new FileReader();
    reader.addEventListener("load", () => {
        preview.src = reader.result;
        this.validateProfileForm();
    }, false);
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  mapCompaniesArray() {
    const companies = [...this.state.startupCompanyTags];
    if (!companies) return [];
    const companiesIds = companies.map((company) => company.id);
    return companiesIds;
  }
  mapInvestmentsArray() {
    const investments = [...this.state.startupInvestmentTags];
    if (!investments) return [];
    const investmentsIds = investments.map((investment) => investment.id);
    return investmentsIds;
  }
  mapReferralArray() {
    const referrals = [
      ...this.state.investorReferralTags,
      ...this.state.defaulutInvestorReferralTags,
    ];
    if (!referrals) return [];
    return [...new Set(referrals.map((referral) => referral.id))];
  }
  mapCategoryArray() {
    const categories = [...this.state.selectedCategoryTags];
    if (!categories) return [];
    return categories.map((categorie) => categorie.id);
  }
  putData() {
    this.setState({
      loadinglada: !this.state.loadinglada,
      progress: 0.5,
    });
    let temp = this;
    temp.setState({ loadinglada: true });
    const token = localStorage.getItem("token");
    let id = window.localStorage.getItem("id");
    if (this.userId) {
      id = this.userId;
    }
    const file = document.querySelector("input[type=file]").files[0];
    let data = new FormData();
    data.append("file", file);
    let mobile = this.state.mobile.replace(/[- )(]/g, "");
    let office = this.state.office
      ? this.state.office.replace(/[- )(]/g, "")
      : "";
    const param = JSON.stringify({
      address: this.state.address,
      token: id,
      firstName: this.state.firstName,
      firstNamePreferred: this.state.firstNamePreferred,
      lastName: this.state.lastName,
      title: this.state.title,
      notes: this.state.notes,
      companyName: this.state.company,
      companies: this.mapCompaniesArray(),
      referralsInvestmentIds: this.mapInvestmentsArray(),
      // referrals: this.mapReferralArray(),
      investorCategories: this.mapCategoryArray(),
      mobile: mobile,
      office: office,
      id: this.userId ? this.userId : null,
      investorCompanyName: this.state.company ? this.state.company : "",
      middleName: this.state.middleName,
      email: this.state.emailAddress,
      email2: this.state.emailAddress2,
      address2: this.state.address2,
      city: this.state.city,
      state: this.state.stateName,
      zipcode: this.state.zipcode,
      country: this.state.country,
      notesChange: true, //this.state.notesCheck,
      // New paasword if there is Password entered
      // password: this.state.pass2 ? this.state.pass2 : '',
    });
    data.append("params", param);
    console.log(data);
    axios
      .put(`${common.path.base_url}/updateMember`, data, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (response) {
        temp.getData();
        swal({
          title: "Updated!",
          text: response.data.message,
          icon: "success",
          button: "Ok!",
        }).then((result) =>
          document.getElementById("cancelInvestmentModal").click()
        );
        temp.setState({ loadinglada: false, profileFormValidated: true });
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.code &&
          error.response.data.code === 204
        ) {
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          swal({
            title: "Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : "Something Went Wrong",
            icon: "error",
            button: "Ok!",
          });
        }
        temp.setState({ loadinglada: false });
      });
  }

  formValidaton(id, val) {
    if (id === "name") {
      let name = document.getElementById("name").value;
      if (name === "") {
        this.setState({
          nameValidation: "Name should not be empty*",
        });
      } else {
        this.setState({
          nameValidation: "",
        });
      }
    } else if (id === "firstName") {
      // let name = document.getElementById(id).value;
      let name = val;

      if (name === "") {
        this.setState({
          firstNameValidation: "First Name should not be empty*",
        });
      } else {
        this.setState({
          firstNameValidation: "",
        });
      }
    } else if (id === "lastName") {
      // let name = document.getElementById('lastName').value;
      let name = val;

      if (name === "") {
        this.setState({
          lastNameValidation: "Last Name should not be empty*",
        });
      } else {
        this.setState({
          lastNameValidation: "",
        });
      }
    }
    // else if (id === 'title') {
    //     let title = document.getElementById('title').value;
    //     if (title === '') {
    //         this.setState({
    //             titleValidation: "Title should not be empty*",
    //         })
    //     } else {
    //         this.setState({
    //             titleValidation: "",
    //         })
    //     }
    // }
    else if (id === "company") {
      let company = document.getElementById("company").value;
      if (company === "") {
        this.setState({
          companyValidation: "Company should not be empty*",
        });
      } else {
        this.setState({
          companyValidation: "",
        });
      }
    } else if (id === "mobile") {
      let mobile = val;
      let mobileRegex =
        /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/;
      let res = mobileRegex.test(mobile);
      if (res !== true) {
        let swissRegex =
          /^(\+?)(\d{2,4})(\s?)(\-?)((\(0\))?)(\s?)(\d{2})(\s?)(\-?)(\d{3})(\s?)(\-?)(\d{2})(\s?)(\-?)(\d{2})/;
        let swissres = swissRegex.test(mobile);
        if (swissres !== true) {
          this.setState({
            mobileValidation: "Please enter valid mobile number",
          });
        } else {
          this.setState({
            mobileValidation: "",
          });
        }
      } else {
        this.setState({
          mobileValidation: "",
        });
      }
    } else if (id === "office") {
      if (val.length > 2) {
        let office = val;
        let officeRegex =
          /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/;
        let res = officeRegex.test(office);
        if (res !== true) {
          let swissRegex =
            /^(\+?)(\d{2,4})(\s?)(\-?)((\(0\))?)(\s?)(\d{2})(\s?)(\-?)(\d{3})(\s?)(\-?)(\d{2})(\s?)(\-?)(\d{2})/;
          let swissres = swissRegex.test(office);
          if (swissres !== true) {
            this.setState({
              officeValidation: "Please enter valid office number",
            });
          } else {
            this.setState({
              officeValidation: "",
            });
          }
        } else {
          this.setState({
            officeValidation: "",
          });
        }
      } else {
        this.setState({
          officeValidation: "",
        });
      }
    } else if (id === "email") {
      let email = val;
      let emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let res = emailRegex.test(email);
      if (email === "") {
        this.setState({
          emailAddressValidation: "Email should not be empty*",
        });
      } else if (res !== true) {
        this.setState({
          emailAddressValidation: "Please enter valid email*",
        });
      } else {
        this.setState({
          emailAddressValidation: "",
        });
      }
    } else if (id === "email2") {
      let email = val;
      let emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      let res = emailRegex.test(email);
      if (email != "" && res !== true) {
        this.setState({
          emailAddress2Validation: "Please enter valid email",
        });
      } else {
        this.setState({
          emailAddress2Validation: "",
        });
      }
    }
    setTimeout(() => {
      this.validateProfileForm();
    }, 100);
  }

  resetModalData() {
    this.setState({
      pass1: "",
      pass2: "",
      isModalValid: false,
    });
  }

  modalValidaton(id) {
    if (id === "password1") {
      let pass1 = document.getElementById("password1").value;
      var exp1 =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
      var res1 = exp1.test(pass1);
      if (pass1 === "") {
        this.setState({
          enterpassValidation: "Password should not be empty*",
        });
      } else if (res1 !== true) {
        this.setState({
          enterpassValidation: "Enter valid Password*",
        });
      } else {
        this.setState({
          enterpassValidation: "",
        });
      }
    } else if (id === "password2") {
      let pass1 = document.getElementById("password1").value;
      let pass2 = document.getElementById("password2").value;
      if (pass1 !== null && pass2 === "") {
        this.setState({
          confirmpassValidation: "Confirm Password should not be empty*",
        });
      } else if (pass1 !== pass2) {
        this.setState({
          confirmpassValidation: "Password does not match*",
        });
      } else {
        this.setState({
          confirmpassValidation: "",
          isModalValid: true,
        });
      }
    }
  }

  validateProfileForm() {
    console.log(this.state.emailAddress2Validation);
    if (!this.userId) {
      if (
        !_.isEmpty(
          this.state.firstName &&
          this.state.lastName &&
          this.state.emailAddress &&
          this.state.mobile
        ) &&
        this.state.mobileValidation === "" &&
        this.state.emailAddressValidation === "" &&
        this.state.emailAddress2Validation === ""
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        this.userId === this.loggedInUserID &&
        !_.isEmpty(
          this.state.firstName &&
          this.state.lastName &&
          this.state.mobile &&
          this.state.emailAddress
        ) &&
        this.state.emailAddressValidation === "" &&
        this.state.emailAddress2Validation === ""
      ) {
        //this.state.city && this.state.country &&
        return true;
      } else if (
        this.state.userRole != "005" &&
        this.userId !== this.loggedInUserID &&
        !_.isEmpty(
          this.state.firstName &&
          this.state.lastName &&
          this.state.mobile &&
          this.state.emailAddress
        ) &&
        this.state.emailAddressValidation === "" &&
        this.state.emailAddress2Validation === ""
      ) {
        //&& this.state.city && this.state.country
        return true;
      } else if (
        this.userId !== this.loggedInUserID &&
        this.state.userRole === "005"
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  handleStartupCompanyChange(newValue) {
    if (newValue.value !== "000") {
      let startupCompanyTags = [
        ...new Set(
          [
            ...this.state.startupCompanyTags,
            { id: newValue.value, name: newValue.label },
          ].map((o) => JSON.stringify(o))
        ),
      ].map((s) => JSON.parse(s));
      this.setState({
        startupCompany: newValue.label,
        startupCompanyId: newValue.value,
        startupCompanyTags,
      });
    }
  }
  handleStartupInvestmentChange(newValue) {
    if (newValue.value !== "000") {
      let startupInvestmentTags = [
        ...new Set(
          [
            ...this.state.startupInvestmentTags,
            { id: newValue.value, name: newValue.label },
          ].map((o) => JSON.stringify(o))
        ),
      ].map((s) => JSON.parse(s));
      this.setState({
        startupInvestment: newValue.label,
        startupInvestmentId: newValue.value,
        startupInvestmentTags,
      });
    }
  }
  removeCompanyTag(tag) {
    if (tag.id) {
      this.setState({
        startupCompanyTags: this.state.startupCompanyTags.filter(
          (company) => company.id !== tag.id
        ),
      });
    }
  }
  removeInvestmentTag(tag) {
    if (tag.id) {
      this.setState({
        startupInvestmentTags: this.state.startupInvestmentTags.filter(
          (investment) => investment.id !== tag.id
        ),
      });
    }
  }
  removeReferralTag(tag) {
    if (tag.id) {
      this.setState({
        investorReferralTags: this.state.investorReferralTags.filter(
          (user) => user.id !== tag.id
        ),
      });
    }
  }
  removeCategoryTag(tag) {
    if (tag.id) {
      this.setState({
        selectedCategoryTags: this.state.selectedCategoryTags.filter(
          (company) => company.id !== tag.id
        ),
      });
    }
  }

  confirmUnbounced = () => {
    let temp = this;
    swal({
      title: "Confirmation",
      text: "Are you sure you have corrected the email address?",
      icon: "warning",
      buttons: ["CANCEL", "YES"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        temp.setState({ loading: true });
        axios
          .post(`${common.path.base_url}/unbounceEmail`, {
            userId: this.state.id,
          })
          .then((response) => {
            temp.setState({ loading: false });
            swal({
              title: "Success",
              text: response.data.message,
              icon: "success",
              button: "Ok",
            }).then(() => temp.setState({ isEmailFailure: false }));
          })
          .catch(function (error) {
            temp.setState({ loading: false });
            if (
              error.response &&
              error.response.data &&
              error.response.data.message &&
              common.AUTH_EXPIRED === error.response.data.message
            ) {
              window.localStorage.clear();
              window.location.href = "/";
              return;
            } else if (error.response && error.response.data.code === 204) {
              window.localStorage.clear();
              window.location.href = "/";
            } else {
              if (error.response) {
                swal({
                  title: "Error!",
                  text: error.response.data.message,
                  icon: "error",
                  button: "Ok!",
                }).then((res) => temp.props.history.replace("/"));
              } else {
                swal({
                  title: "Network Error!",
                  text:
                    error.response &&
                      error.response.data &&
                      error.response.data.message
                      ? error.response.data.message
                      : error.message,
                  icon: "error",
                  button: "Ok!",
                });
              }
            }
          });
      } else {
      }
    });
  };

  startupCompanySearch = _.debounce((value) => {
    this.setState({
      startupCompany: value,
    });
    let temp = this;
    axios
      .post(`${common.path.base_url}/searchCompany`, {
        query: value,
      })
      .then(function (response) {
        if (response.data.code === 204) {
          window.localStorage.clear();
          this.props.history.push("/");
        }
        const companyList = response.data.data.map((opt) => ({
          label: opt.companyName,
          value: opt._id,
        }));
        if (companyList.length > 0) {
          temp.setState({ startupCompaniesList: [...companyList] });
        } else {
          temp.setState({
            startupCompaniesList: [{ label: "Not Found", value: "000" }],
          });
        }
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response.data.code === 204) {
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          if (error.response) {
            swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              button: "Ok!",
            });
          } else {
            swal({
              title: "Network Error!",
              text:
                error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        }
      });
  }, 800);

  startupInvestmentSearch = _.debounce((value) => {
    this.setState({
      startupCompany: value,
    });
    let temp = this;
    axios
      .post(`${common.path.base_url}/searchInvestment`, {
        query: value,
      })
      .then(function (response) {
        if (response.data.code === 204) {
          window.localStorage.clear();
          this.props.history.push("/");
        }
        const investmentList = response.data.data.map((opt) => ({
          label: opt.name,
          value: opt._id,
        }));
        if (investmentList.length > 0) {
          temp.setState({ startupInvestmentsList: [...investmentList] });
        } else {
          temp.setState({
            startupInvestmentsList: [{ label: "Not Found", value: "000" }],
          });
        }
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response.data.code === 204) {
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          if (error.response) {
            swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              button: "Ok!",
            });
          } else {
            swal({
              title: "Network Error!",
              text:
                error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        }
      });
  }, 800);

  handleReferralChange(obj) {
    // let investorReferralTags = [...new Set([...this.state.investorReferralTags, { id: obj.value, name: obj.label }].map(o => JSON.stringify(o)))].map(s => JSON.parse(s));
    // this.setState({ investorReferralTags, selectedReferralOption: null });
  }
  referralSearch = _.debounce((value) => {
    this.setState({
      investor: value,
    });
    let temp = this;
    axios
      .post(`${common.path.base_url}/searchReferral`, {
        query: value,
      })
      .then(function (response) {
        const investorReferralList = response.data.data.map((opt) => ({
          label: opt.name,
          value: opt._id,
          user: opt,
        }));
        temp.setState({ investorReferralList });
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }, 800);

  passwordMatch() {
    let password = document.getElementById("password1").value;
    let list = document.querySelector("instructions");
    //  list.style.color = "green";
    if (password.length < 8) {
      document.getElementById("first").style.color = "red";
    } else {
      document.getElementById("first").style.color = "green";
    }
    if (/[0-9]/.test(password)) {
      document.getElementById("second").style.color = "green";
    } else {
      document.getElementById("second").style.color = "red";
    }
    if (/[!@#\$%\^&]/.test(password)) {
      document.getElementById("third").style.color = "green";
    } else {
      document.getElementById("third").style.color = "red";
    }
    if (/[A-Z]/.test(password)) {
      document.getElementById("fourth").style.color = "green";
    } else {
      document.getElementById("fourth").style.color = "red";
    }
  }

  updatePassword() {
    this.setState({
      loadinglada: !this.state.loadinglada,
      progress: 0.5,
    });
    let temp = this;
    temp.setState({ loadinglada: true });
    const token = localStorage.getItem("token");
    let id = window.localStorage.getItem("id");
    const param = {
      id: this.userId ? this.userId : id,
      newPass: this.state.pass1 ? this.state.pass1 : "",
      confirmPass: this.state.pass2 ? this.state.pass2 : "",
    };
    axios
      .put(`${common.path.base_url}/changePassword`, param, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (response) {
        temp.getData();
        swal({
          title: "Updated!",
          text: response.data.message,
          icon: "success",
          button: "Ok!",
        });
        temp.resetModalData();
        temp.setState({ loadinglada: false, profileFormValidated: true });
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.code &&
          error.response.data.code === 204
        ) {
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          swal({
            title: "Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : "Something Went Wrong",
            icon: "error",
            button: "Ok!",
          });
        }
        temp.setState({ loadinglada: false });
      });
  }
  hideNotificationSidebar() {
    this.props.hideSidebarNotification();
  }
  getRequireSign() {
    if (this.state.userRole != "005" && this.userId !== this.loggedInUserID) {
      return <RequiredSign />;
    } else if (this.userId === this.loggedInUserID) {
      return <RequiredSign />;
    } else {
      return null;
    }
  }

  sendNoteOTPModal() {
    if (this.state.roleCode === "001" || this.state.roleCode === "002") {
      let temp = this;

      let tokenpass = window.localStorage.getItem("token");
      //temp.setState({ loading: true });

      let id = window.localStorage.getItem("id");
      const param = {
        id: id,
        userId: this.userId ? this.userId : id,
      };

      axios
        .post(`${common.path.base_url}/sendNotesOtp/`, param, {
          headers: {
            Authorization: tokenpass,
          },
        })
        .then(function (response) {
          // const investorReferralList = response.data.data.map(opt => ({ label: opt.name, value: opt._id, user: opt }));
          // temp.setState({ investorReferralList });
        })
        .catch(function (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message &&
            common.AUTH_EXPIRED === error.response.data.message
          ) {
            //window.localStorage.clear();
            //window.location.href = '/';
            return;
          } else if (error.response) {
            swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              button: "Ok!",
            });
          } else {
            swal({
              title: "Network Error!",
              text:
                error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        });

      document.getElementById("sendOTPModal-btn").click();
    } else {
      swal({
        //title: "You are not authorized to access this Note !",
        text: "You are not authorized to access this Note",
        icon: "warning",
        button: "Ok!",
      });
    }
  }

  checkNotes(data) {
    this.setState({ notes: data, notesCheck: true });
  }

  render() {
    const username = window.localStorage.getItem("username");
    return (
      <div
        className="updateProfile"
        onClick={() => this.hideNotificationSidebar()}
      >
        {this.state.loading ? <LoadingSpinner /> : ""}
        <Header username={username} role={this.state.Role} />
        <HeaderImg heading={this.state.Role} />

        <UserCheckModal
          mobile={this.state.loginMobile}
          userId={this.userId}
          checkNotes={(notes) => this.checkNotes(notes)}
        />
        <TButton
          onClick={() => { }}
          id="sendOTPModal-btn"
          dataTarget={`#sendOTPModal`}
          dataToggle={`modal`}
          style={{ display: "none" }}
        />

        <div className="profile-main">
          <div className="heading">
            <h3>{this.userId ? "Edit Profile" : "Profile"}</h3>
          </div>
          <div className="profile">
            <div className="upload-profile-pic">
              <div className="profile-pic-box">
                <img
                  id="preview"
                  className="profile-pic"
                  src={`${common.path.base_url}/getImage?id=${this.state.id}`}
                  alt=""
                />
                <div className="p-image">
                  <i
                    onClick={() => this.chooseFile()}
                    className="fa fa-pencil upload-button"
                  ></i>
                  <input
                    id="choose-file"
                    onChange={() => this.previewFile()}
                    className="file-upload"
                    type="file"
                    accept="image/jpeg,image/png,image/jpg"
                  />
                </div>
              </div>
            </div>
            <div className="profile-details">
              <React.Fragment>
                <form className="form-horizontal">
                  <div className="form-group">
                    <label className="col-md-3 control-label">
                      First Name{this.getRequireSign()} :
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        id="firstName"
                        type="text"
                        placeholder="First Name (Legal)"
                        onKeyUp={(event) =>
                          this.formValidaton(
                            event.target.id,
                            event.target.value
                          )
                        }
                        onChange={(event) => {
                          this.handleChange(
                            event.target.id,
                            event.target.value
                          );
                        }}
                        value={this.state.firstName}
                      />
                      {/* <p>{this.state.firstNameValidation}</p> */}
                    </div>
                  </div>

                  {/* <div className="form-group">
                                        <label className="col-md-3 control-label">First Name (Preferred) :</label>
                                        <div className="col-md-8">
                                            <input className="form-control" id="firstNamePreferred" type="text" placeholder='First Name (Preferred)'
                                                onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                                onChange={(event) => { this.handleChange(event.target.id, event.target.value) }}
                                                value={this.state.firstNamePreferred} />
                                        </div>
                                    </div> */}

                  <div className="form-group">
                    <label className="col-md-3 control-label">
                      Middle Name :
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        id="middleName"
                        type="text"
                        placeholder="Middle Name"
                        onKeyUp={(event) =>
                          this.formValidaton(
                            event.target.id,
                            event.target.value
                          )
                        }
                        onChange={(event) => {
                          this.handleChange(
                            event.target.id,
                            event.target.value
                          );
                        }}
                        value={this.state.middleName}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="col-md-3 control-label">
                      Last Name {this.getRequireSign()} :
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        id="lastName"
                        type="text"
                        placeholder="Last Name"
                        onKeyUp={(event) =>
                          this.formValidaton(
                            event.target.id,
                            event.target.value
                          )
                        }
                        onChange={(event) => {
                          this.handleChange(
                            event.target.id,
                            event.target.value
                          );
                        }}
                        value={this.state.lastName}
                      />
                      {/* <p>{this.state.lastNameValidation}</p> */}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3 control-label">Title :</label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        id="title"
                        type="text"
                        placeholder="Title"
                        onKeyUp={(event) => this.formValidaton(event.target.id)}
                        onChange={(event) =>
                          this.handleChange(event.target.id, event.target.value)
                        }
                        value={this.state.title}
                      />
                      {/* <p>{this.state.titleValidation}</p> */}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3 control-label">Company:</label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        id="company"
                        type="text"
                        placeholder="Company"
                        onKeyUp={(event) => this.formValidaton(event.target.id)}
                        onChange={(event) =>
                          this.handleChange(event.target.id, event.target.value)
                        }
                        value={this.state.company}
                      //readOnly={(common.allowedRolesToModifyCompany.indexOf(this.state.roleCode) < 0 || this.state.roleCode === '005') ? true : false}
                      />
                      {/* <p >{this.state.companyValidation}</p> */}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3 control-label">
                      Email Address {this.getRequireSign()}:
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        id="email"
                        type="text"
                        placeholder="Email Address"
                        onKeyUp={(event) =>
                          this.formValidaton(
                            event.target.id,
                            event.target.value
                          )
                        }
                        onChange={(event) =>
                          this.handleChange(event.target.id, event.target.value)
                        }
                        value={this.state.emailAddress}
                      />
                      {/* <p >{this.state.emailAddressValidation}</p> */}
                      {this.state.isEmailFailure ? (
                        <>
                          <div className="bouncedContainer">
                            <div className="bouncedLable">
                              <span className="bouncedDot"></span>Bounced Email
                            </div>
                            <button
                              type="button"
                              className="bouncedCorrected"
                              onClick={() => this.confirmUnbounced()}
                            >
                              Corrected
                            </button>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="col-md-3 control-label">
                      Email Address ( Secondary ) :
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        id="email2"
                        type="text"
                        placeholder="Email Address"
                        onKeyUp={(event) =>
                          this.formValidaton(
                            event.target.id,
                            event.target.value
                          )
                        }
                        onChange={(event) =>
                          this.handleChange(event.target.id, event.target.value)
                        }
                        value={this.state.emailAddress2}
                      />
                      {/* <p >{this.state.emailAddressValidation}</p> */}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3 control-label">Mobile:</label>
                    <div className="col-md-8 mobile-num">
                      <ReactPhoneInput
                        country={"us"}
                        id="mobile"
                        value={this.state.mobile}
                        onChange={(value) => this.handleChange("mobile", value)}
                        disableAreaCodes={true}
                        countryCodeEditable={false}
                        autoComplete="none"
                        placeholder="Mobile"
                      />
                      {/* {!this.userId && <p>{this.state.mobileValidation}</p>} */}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3 control-label">Office:</label>
                    <div className="col-md-8">
                      <ReactPhoneInput
                        country={"us"}
                        id="office"
                        value={this.state.office}
                        onChange={(value) => this.handleChange("office", value)}
                        disableAreaCodes={true}
                        countryCodeEditable={false}
                        autoComplete="none"
                        placeholder="Office"
                      />
                      {/* <p>{this.state.officeValidation}</p> */}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3 control-label">Address 1:</label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        id="address"
                        type="text"
                        placeholder="Address"
                        onKeyUp={(event) => this.formValidaton(event.target.id)}
                        onChange={(event) =>
                          this.handleChange(event.target.id, event.target.value)
                        }
                        value={this.state.address}
                      />
                      {/* <p>{this.state.addressValidation}</p> */}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3 control-label">Address 2:</label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        id="address2"
                        type="text"
                        placeholder="Enter Address 2"
                        onKeyUp={(event) => this.formValidaton(event.target.id)}
                        onChange={(event) =>
                          this.handleChange(event.target.id, event.target.value)
                        }
                        value={this.state.address2}
                      />
                      {/* <p>{this.state.addressValidation}</p> */}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3 control-label">
                      City {/* {this.getRequireSign()}  */}:
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        id="city"
                        type="text"
                        placeholder="Enter City"
                        onKeyUp={(event) => this.formValidaton(event.target.id)}
                        onChange={(event) =>
                          this.handleChange(event.target.id, event.target.value)
                        }
                        value={this.state.city}
                      />
                      {/* <p>{this.state.addressValidation}</p> */}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3 control-label">State :</label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        id="state"
                        type="text"
                        placeholder="Enter State"
                        onKeyUp={(event) => this.formValidaton(event.target.id)}
                        onChange={(event) =>
                          this.handleChange(event.target.id, event.target.value)
                        }
                        value={this.state.stateName}
                      />
                      {/* <p>{this.state.addressValidation}</p> */}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3 control-label">
                      Postal code :
                    </label>
                    <div className="col-md-8">
                      <div className="form-group amount-investment-Edit">
                        <NumberFormat
                          placeholder="Enter Postal Code"
                          value={this.state.zipcode}
                          onValueChange={(values) => {
                            this.handleChange("zipcode", values.value);
                          }}
                        />
                        {/* <p>{this.state.addressValidation}</p> */}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3 control-label">Country :</label>
                    <div className="col-md-8">
                      <select
                        id="country"
                        className="form-control"
                        value={this.state.country}
                        onChange={(event) =>
                          this.handleChange(event.target.id, event.target.value)
                        }
                      >
                        <option hidden="true">Select...</option>

                        {countryJson.map((countryObj) => {
                          return (
                            <option
                              key={countryObj.country}
                              value={countryObj.country}
                            >
                              {countryObj.country}
                            </option>
                          );
                        })}
                      </select>
                      <p>{this.state.addressValidation}</p>
                    </div>
                  </div>
                  {(this.state.roleCode === "001" ||
                    this.state.roleCode === "002") && (
                      <div>
                        <div className="form-group">
                          <label className="col-md-3 control-label">Notes:</label>
                          <div className="col-md-8">
                            <CKEditor
                              id="notes"
                              editor={ClassicEditor}
                              data={this.state.notes}
                              config={editorConfig}
                              onInit={(editor) => {
                                // You can store the "editor" and use when it's needed.
                                console.log("Editor is ready to use!", editor);
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                //console.log( { event, editor, data } );
                                this.handleChange("notes", data);
                              }}
                            />
                          </div>
                        </div>
                        {this.state.userRole === "005" && (
                          <div>
                            {/* <div className="form-group">
                                                <label className="col-md-3 control-label">Startup Lists :</label>
                                                <div className="col-md-8">
                                                    <Select id="company"
                                                        // defaultValue={this.state.Company}
                                                        options={this.state.startupCompaniesList}
                                                        onChange={(opt, action) => {
                                                            if (action && action.action === 'clear') {
                                                                this.setState({ selectedStartupCompanyOption: null, startupCompanyId: '', startupCompany: '' });
                                                            }
                                                            if (opt !== null && opt !== undefined) {
                                                                this.handleStartupCompanyChange(opt);
                                                            }
                                                        }}
                                                        onInputChange={(searchKey) => {
                                                            if (!_.isEmpty(searchKey)) {
                                                                this.startupCompanySearch(searchKey);
                                                            }
                                                        }}
                                                        value={this.state.selectedStartupCompanyOption}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        filterOption={(value1, value2) => {
                                                            return value2;
                                                        }}
                                                        deleteRemoves={true}
                                                        noOptionsMessage={() => `Type Something`}
                                                        isDisabled={(this.state.type === '001' || this.state.type === '002' || (this.state.preSelected != -1 && this.state.preSelected < 5)) ? true : false}
                                                    />
                                                </div>

                                            </div>
                                            <div className="form-group investor-companies-list">
                                                <label className="col-lg-3 col-sm-3 col-md-3 control-label"></label>
                                                <div className="col-lg-8 col-md-8 col-sm-8 company-tags">
                                                    {
                                                        this.state.startupCompanyTags.length > 0 &&
                                                        this.state.startupCompanyTags.map(tag => {
                                                            return (<span key={tag.id} className="tag label label-info">
                                                                <span>{tag.name}</span>
                                                                <a><i className="remove glyphicon glyphicon-remove-sign glyphicon-white" onClick={() => this.removeCompanyTag(tag)}></i></a>
                                                            </span>)
                                                        })
                                                    }
                                                </div>
                                            </div> */}
                            {/* <div className="form-group" id="investmentForm">
                                                <label className="col-md-3 control-label">Investments :</label>
                                                <div className="col-md-8">
                                                    <Select id="investment"
                                                        // defaultValue={this.state.Company}
                                                        options={this.state.startupInvestmentsList}
                                                        onChange={(opt, action) => {
                                                            if (action && action.action === 'clear') {
                                                                this.setState({ selectedStartupInvestmentOption: null, startupCompanyId: '', startupCompany: '' });
                                                            }
                                                            if (opt !== null && opt !== undefined) {
                                                                this.handleStartupInvestmentChange(opt);
                                                            }
                                                        }}
                                                        onInputChange={(searchKey) => {
                                                            if (!_.isEmpty(searchKey)) {
                                                                this.startupInvestmentSearch(searchKey);
                                                            }
                                                        }}
                                                        value={this.state.selectedStartupInvestmentOption}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        filterOption={(value1, value2) => {
                                                            return value2;
                                                        }}
                                                        deleteRemoves={true}
                                                        noOptionsMessage={() => `Type Something`}
                                                        isDisabled={(this.state.type === '001' || this.state.type === '002' || (this.state.preSelected != -1 && this.state.preSelected < 5)) ? true : false}
                                                    />
                                                </div>

                                            </div>
                                            <div className="form-group investor-companies-list">
                                                <label className="col-lg-3 col-sm-3 col-md-3 control-label"></label>
                                                <div className="col-lg-8 col-md-8 col-sm-8 company-tags">
                                                    {
                                                        this.state.startupInvestmentTags.length > 0 &&
                                                        this.state.startupInvestmentTags.map(tag => {
                                                            return (<span key={tag.id} className="tag label label-info">
                                                                <span>{tag.name}</span>
                                                                <a><i className="remove glyphicon glyphicon-remove-sign glyphicon-white" onClick={() => this.removeInvestmentTag(tag)}></i></a>
                                                            </span>)
                                                        })
                                                    }
                                                </div>
                                            </div> */}
                            <div className="form-group" id="investmentForm">
                              <label className="col-md-3 control-label">
                                Referred by Startup :
                              </label>
                              <div className="col-md-8">
                                <Select
                                  id="investment"
                                  // defaultValue={this.state.Company}
                                  options={this.state.startupInvestmentsList}
                                  onChange={(opt, action) => {
                                    if (action && action.action === "clear") {
                                      this.setState({
                                        selectedStartupInvestmentOption: null,
                                        startupCompanyId: "",
                                        startupCompany: "",
                                      });
                                    }
                                    if (opt !== null && opt !== undefined) {
                                      this.handleStartupInvestmentChange(opt);
                                    }
                                  }}
                                  onInputChange={(searchKey) => {
                                    if (!_.isEmpty(searchKey)) {
                                      this.startupInvestmentSearch(searchKey);
                                    }
                                  }}
                                  value={
                                    this.state.selectedStartupInvestmentOption
                                  }
                                  isClearable={true}
                                  isSearchable={true}
                                  filterOption={(value1, value2) => {
                                    return value2;
                                  }}
                                  deleteRemoves={true}
                                  noOptionsMessage={() => `Type Something`}
                                  isDisabled={
                                    this.state.type === "001" ||
                                      this.state.type === "002" ||
                                      (this.state.preSelected != -1 &&
                                        this.state.preSelected < 5)
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group investor-companies-list">
                              <label className="col-lg-3 col-sm-3 col-md-3 control-label"></label>
                              <div className="col-lg-8 col-md-8 col-sm-8 company-tags">
                                {this.state.startupInvestmentTags.length > 0 &&
                                  this.state.startupInvestmentTags.map((tag) => {
                                    return (
                                      <span
                                        key={tag.id}
                                        className="tag label label-info"
                                      >
                                        <span>{tag.name}</span>
                                        <a>
                                          <i
                                            className="remove glyphicon glyphicon-remove-sign glyphicon-white"
                                            onClick={() =>
                                              this.removeInvestmentTag(tag)
                                            }
                                          ></i>
                                        </a>
                                      </span>
                                    );
                                  })}
                              </div>
                            </div>
                            {/* <div className="form-group">
                                                <label className="col-md-3 control-label">Referral Name :</label>
                                                <div className="col-md-8 investor-referral">
                                                    <Select id="investor"
                                                        options={this.state.investorReferralList}
                                                        onChange={(opt, action) => {
                                                            if (action && action.action === 'clear') {
                                                                this.setState({ selectedReferralOption: null });
                                                            }
                                                            if (opt !== null && opt !== undefined) {
                                                                this.handleReferralChange(opt);
                                                            }
                                                        }}
                                                        onInputChange={(searchKey) => {
                                                            if (!_.isEmpty(searchKey)) {
                                                                this.referralSearch(searchKey);
                                                            }
                                                        }}
                                                        value={this.state.selectedReferralOption}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        filterOption={(value1, value2) => {
                                                            return value2;
                                                        }}
                                                        deleteRemoves={true}
                                                        noOptionsMessage={() => `Type Something`}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group investor-companies-list">
                                                <label className="col-lg-3 col-sm-3 col-md-3 control-label"></label>
                                                <div className="col-lg-8 col-md-8 col-sm-8 user-referral">
                                                    {
                                                        this.state.investorReferralTags.length > 0 &&
                                                        this.state.investorReferralTags.map(tag => {
                                                            return (<span key={tag.id} className="tag label label-info">
                                                                <span>{tag.name}</span>
                                                                <a><i className="remove glyphicon glyphicon-remove-sign glyphicon-white" onClick={() => this.removeReferralTag(tag)}></i></a>
                                                            </span>)
                                                        })
                                                    }
                                                </div>
                                            </div> */}

                            <div className="form-group">
                              <label className="col-lg-3 col-sm-3 col-md-3 control-label">
                                {" "}
                                Tags :
                              </label>
                              <div className="col-lg-8 col-sm-8 col-md-8 investor-referral">
                                <Select
                                  id="category"
                                  defaultValue={this.state.selectedcategory}
                                  options={_.sortBy(this.state.category, [
                                    "label",
                                  ])}
                                  value={this.state.selectedcategory}
                                  onChange={(opt) => {
                                    if (opt !== null && opt !== undefined) {
                                      this.handleChange("category", opt);
                                    }
                                  }}
                                />
                                <p>{this.state.categoryValidation}</p>
                              </div>
                            </div>

                            <div className="form-group investor-companies-list investorCategoryTags">
                              <label className="col-lg-3 col-sm-3 col-md-3 control-label"></label>
                              <div className="col-lg-8 col-md-8 col-sm-8 ">
                                {this.state.selectedCategoryTags.length > 0 &&
                                  this.state.selectedCategoryTags.map((tag) => {
                                    return (
                                      <span
                                        key={tag.id}
                                        className="tag label label-info"
                                      >
                                        <span>{tag.name}</span>
                                        <a>
                                          <i
                                            className="remove glyphicon glyphicon-remove-sign glyphicon-white"
                                            onClick={() =>
                                              this.removeCategoryTag(tag)
                                            }
                                          ></i>
                                        </a>
                                      </span>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  <div className="form-group btn-section"></div>
                  <div className="form-group investor-companies-list changePasswordLink">
                    <label className="col-md-3 control-label"></label>
                    <div className="col-xs-12 col-sm-6 col-md-8 updateButton">
                      {this.state.allowChangePassword && (
                        <div className="change-password">
                          <a
                            href="#changePassword"
                            id="openPasswordModal-btn"
                            className="add-button"
                            data-toggle="modal"
                          >
                            Click Here
                          </a>{" "}
                          to change your password
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group btn-section">
                    <label className="col-md-3 control-label"></label>
                    <div className="col-xs-12 col-sm-6 col-md-8 updateButton">
                      <div className="col-sm-6 col-md-2 col-xs-6">
                        <LaddaButton
                          id="update-btn"
                          loading={this.state.loadinglada}
                          onClick={() => this.putData()}
                          data-color="#fc6c85"
                          data-size={XS}
                          data-style={EXPAND_RIGHT}
                          data-spinner-size={30}
                          data-spinner-color="#fc6c85"
                          data-spinner-lines={12}
                          className={
                            !this.validateProfileForm()
                              ? "disable profile-update"
                              : "profile-update"
                          }
                        >
                          UPDATE
                        </LaddaButton>
                      </div>

                      {/* <input type="reset" className="btn btn-default cancel" value="CANCEL"
                                                onClick={() => {
                                                    let path = `/dashboard`;
                                                    this.props.history.push(path);
                                                }} /> */}
                      <div className="col-sm-6 col-md-2 col-xs-6">
                        <LaddaButton
                          id="cancel-btn"
                          onClick={this.goback}
                          data-color="#fc6c85"
                          data-size={XS}
                          data-style={EXPAND_RIGHT}
                          className="profile-cancel"
                        >
                          CANCEL
                        </LaddaButton>
                      </div>
                      {/* Button that invisible and helps to redirecting back to previous page */}
                      {/* <input type="button" id="cancelInvestmentModal" className="btn btn-default" data-dismiss="modal" onClick={() => window.location.href = `/viewProfile?id=${this.userId}`} value="CANCEL" /> */}
                      <input
                        type="button"
                        id="cancelInvestmentModal"
                        className="btn btn-default"
                        data-dismiss="modal"
                        onClick={(event) => this.goback(event)}
                        value="CANCEL"
                      />
                    </div>
                  </div>
                </form>
              </React.Fragment>
            </div>
          </div>
        </div>

        <div id="changePassword" className="modal fade">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Change Password New</h4>
              </div>
              <div className="modal-body">
                <div className="form-group in-modal">
                  <label className="col-lg-5 col-sm-4 col-md-4 control-label">
                    New Password
                  </label>
                  <div className="col-lg-7 col-sm-7 col-md-7">
                    <input
                      className="form-control"
                      type="password"
                      id="password1"
                      name="username"
                      placeholder="Enter new password"
                      onKeyUp={(event) => this.modalValidaton(event.target.id)}
                      onChange={(event) =>
                        this.handleModalChange(
                          event.target.id,
                          event.target.value
                        )
                      }
                      value={this.state.pass1}
                      onKeyUp={() => this.passwordMatch()}
                    />
                    <p>{this.state.enterpassValidation}</p>
                  </div>
                </div>

                <div className="form-group in-modal">
                  <label className="col-lg-5 col-sm-4 col-md-4 control-label">
                    Instructions
                  </label>
                  <div className="col-lg-7 col-sm-7 col-md-7">
                    <ul id="instructions">
                      <li id="first">8 characters</li>
                      <li id="second">1 number</li>
                      <li id="third">1 non-alphanumeric (!, @, #, $, etc.)</li>
                      <li id="fourth">1 upper-case</li>
                    </ul>
                  </div>
                </div>

                <div className="form-group in-modal">
                  <label className="col-lg-5 col-sm-4 col-md-4 control-label">
                    Confirm New Password
                  </label>
                  <div className="col-lg-7 col-sm-7 col-md-7">
                    <input
                      className="form-control"
                      id="password2"
                      type="password"
                      name="password"
                      onKeyUp={(event) => this.modalValidaton(event.target.id)}
                      onChange={(event) =>
                        this.handleModalChange(
                          event.target.id,
                          event.target.value
                        )
                      }
                      value={this.state.pass2}
                      placeholder="Enter password again"
                    />
                    <p>{this.state.confirmpassValidation}</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer in-footer">
                <LaddaButton
                  id="lada-btn"
                  loading={this.state.loadinglada}
                  onClick={() => this.updatePassword()}
                  data-color="#eee"
                  data-size={XS}
                  data-style={EXPAND_RIGHT}
                  data-spinner-size={30}
                  data-spinner-color="#ddd"
                  data-spinner-lines={12}
                  data-dismiss="modal"
                  className={!this.state.isModalValid ? "disable" : ""}
                >
                  {"UPDATE"}
                </LaddaButton>
                <input
                  type="button"
                  id="cancelAgreementModal"
                  className="btn btn-default"
                  data-dismiss="modal"
                  value="CANCEL"
                  onClick={() => this.resetModalData()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default Profile;
const mapDispatchToProps = (dispatch) => {
  return {
    hideSidebarNotification: bindActionCreators(
      hideSidebarNotification,
      dispatch
    ),
  };
};

export default connect(null, mapDispatchToProps)(Profile);

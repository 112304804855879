import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash';
import LoadingSpinner from '../../components/Loader';
import axios from 'axios';
import images from "../../images";
import common from '../../common';
import swal from 'sweetalert';
import './pipelineCss/pipeline.scss';
import './pipelineCss/default.scss';
import './../../containers/IIR/Board/Board.scss';
import TeamRow from './TeamRow';
import { Icons } from './Icons';
import Modal from 'react-bootstrap-modal';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import { useForm } from "react-hook-form";

const TeamBoardInvestor = props => {
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        defaultValues: {
          name: '',
          title: '',
          bio: '',
          file: ''
        }
      });
    const teamuplodRef = useRef(null);
    const boardimageuploadRef = useRef(null);
    const investoruploadRef = useRef(null);

    const [generalCall, setGeneralCall] = useState(false);
    const [teamModalCall, setTeamModalCall] = useState(true);
    const [teamUser, setTeamUser] = useState([]);
    const [filterTeamUser, setFilterTeamUser] = useState([]);
    const [addTeamList, setAddTeamList] = useState([]);
    const [searchTeamBox, setSearchTeamBox] = useState('');

    const [boardModalCall, setBoardModalCall] = useState(true);
    const [filterBoardUser, setFilterBoardUser] = useState([]);
    const [addBoardList, setAddBoardList] = useState([]);
    const [searchBoardBox, setSearchBoardBox] = useState([]);

    const [companyVibe, setCompanyVibe] = useState("");
    const [genuineComm, setGenuineComm] = useState("");

    const [investorAction, setInvestorAction] = useState('');
    const [investorDelete, setInvestorDelete] = useState([]);
    const [investorImg, setInvestorImg] = useState({ });
    const [investorArr, setInvestorArr] = useState([]);
    const [ showTeamModal, setShowTeamModal ] = useState(false);
    const [ editModel, setEditModel ] = useState(false);
    const [ setModalTitle, setSetModalTitle ] = useState("");
    const [imageUploading, setImageUploading] = useState(false);
    const [dataUploading, setDataUploading] = useState(false);
    const [imgId, setImgId] = useState("");
    const [selectedFile, setSelectedFile] = useState()
    const [preview, setPreview] = useState()
    const [investorImgUrl, setInvestorImgUrl] = useState('')

    const { companyId, companyData } = props;

    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const onSelectFile = e => {
        setImageUploading(true);
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedFile(e.target.files[0])
        setImageUploading(false);
    }

    const removeInvestorHandle = (index) => {
        swal({
            title: 'Are you sure you want to delete?',
            text: "This item will be delete immediately. You can't undo this action.",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#7A8DA7',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true,
            // customClass: {
            //     confirmButton: 'primaryBTN',
            // }
        }).then((resp) => {
            if(resp) {
                setGeneralCall(true);
                setInvestorAction('delete');
                investorArr.splice(index, 1); 
                setInvestorDelete([...investorArr]);
            }
        })
    };

    const onSubmit = data => {
        if(data) {
            // setDataUploading(true);
            let token = window.localStorage.getItem('token');
            if(selectedFile){
                let imageData = new FormData();

                imageData.append('file', selectedFile);
                imageData.append('path', 'startup-contacts');

                axios.post(`${common.path.base_url}/s3ImageUploader`, imageData, {
                    headers: {
                        "Authorization": token
                    }
                })
                .then((response) => {
                    if(!response.data.error) {
                        setImgId(response.data.imageUrl);
                        setValue('file', response.data.imageUrl );
                        setImageUploading(false);
                        data.file = response.data.imageUrl;
                        teamBoardMemeberAPI(data, token);
                    }
                    
                })
                .catch((error) => {
                    setImageUploading(false);
                    setDataUploading(false);
                    if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        window.localStorage.clear();
                        window.location.href = '/';
                        return;
                    } else { 
                    }
                });
            } else {
                teamBoardMemeberAPI(data, token);
            }
           
        }
    }

    const teamBoardMemeberAPI = (data, token) => {
        const params = {
            companyId: companyId,
            name: data.name,
            title: data.title,
            bio: data.bio,
            role : setModalTitle === 'Add Team' || setModalTitle === 'Edit Team' ? "team" : "board",
            imageUrl: data.file,
        }
        if(editModel) {
            axios.post(`${common.path.base_url}/startupContactsUpdate/${data.id}`, params,
            {
                headers: {
                    "Authorization": token
                }
            })
            .then((response) => {
                setShowTeamModal(false);
                console.log(response);
                if(response.data.data){
                    const users = response.data.data.startupContacts;
                    setAddTeamList(users.filter(e => e.role === "team"));
                    setAddBoardList(users.filter(e => e.role === "board"));
                }
                setSelectedFile(undefined);
                setPreview(undefined);
                reset({
                    name: '',
                    title: '',
                    bio: '',
                    file: '',
                    id: '',
                })
                setDataUploading(false);
            })
            .catch((error) => {
                setDataUploading(false);
                if ( error.response && error.response.data && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: ( error.response && error.response.data && error.response.data.message ) ? error.response.data.message : error.response,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
        } else {
            axios.post(`${common.path.base_url}/startupContacts`, params,
            {
                headers: {
                    "Authorization": token
                }
            })
            .then((response) => {
                setShowTeamModal(false);
                console.log(response)
                if(response.data.data){
                    const users = response.data.data.startupContacts;
                    setAddTeamList(users.filter(e => e.role === "team"));
                    setAddBoardList(users.filter(e => e.role === "board"));
                }
                setSelectedFile(undefined);
                setPreview(undefined);
                reset({
                    name: '',
                    title: '',
                    bio: '',
                    file: ''
                })
                setDataUploading(false);
            })
            .catch((error) => {
                setDataUploading(false);
                if ( error.response && error.response.data && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: ( error.response && error.response.data && error.response.data.message ) ? error.response.data.message : error.response,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
        }
    }

    const inputHandlerChange = (e) => {

        const inputId = e.target.id;
        const inputValue = e.target.value;
        setGeneralCall(true);
        setInvestorImg({ });

        if( inputId == 'companyVibe' ) {
            setCompanyVibe(inputValue);
        } else if( inputId == 'genuineComm' ) {
            setGenuineComm(inputValue);
        }
    }

    const searchMember = (e, type) => {
        let keyCode = e.target.value;
        
        (type == 'board') ? setSearchBoardBox(keyCode) : setSearchTeamBox(keyCode);
        
        if(keyCode.trim() != "" && keyCode.trim() != undefined ) {
            if(type == 'board') {
                
                keyCode = keyCode.trim()
                const filterUser = teamUser.filter((data) => data.name.toLowerCase().includes( keyCode.toLowerCase() )); 
                setFilterBoardUser(filterUser);
            
            } else if( type =='team' ){

                keyCode = keyCode.trim()
                const filterUser = teamUser.filter((data) => data.name.toLowerCase().includes( keyCode.toLowerCase() )); 
                setFilterTeamUser(filterUser);
            }
        } else {
            setFilterTeamUser([]);
        }
    };

    const saveTeamCompany = ( index, type ) => {

        setGeneralCall(true);
        setInvestorImg({ });

        if(type == 'team') {
            setSearchTeamBox('');
            setTeamModalCall(true);
            const saveTeam = filterTeamUser.splice(index, 1);
            setFilterTeamUser(filterTeamUser); 
            setAddTeamList([...addTeamList, saveTeam[0]]);
        } else if( type =='board'){
            setSearchBoardBox('');
            setBoardModalCall(true);
            const saveTeam = filterBoardUser.splice(index, 1);
            setFilterBoardUser(filterBoardUser); 
            setAddBoardList([...addBoardList, saveTeam[0]]);
        }
    }

    useEffect(() => {
        //console.log("govind", companyData);
        if( companyData != "" && companyData != undefined && companyData != null) {
        
            setCompanyVibe(companyData.companyVibe);
            setGenuineComm(companyData.genuineComm);
            const users = companyData.startupContacts;
            setAddTeamList(users.filter(e => e.role === "team"));
            setAddBoardList(users.filter(e => e.role === "board"));
            // setAddTeamList(companyData.teamMember);
            // setAddBoardList(companyData.boardMember);
            setInvestorArr(companyData.investors);
        }
    }, [companyData]);

    useEffect(() => { 
        
        let token = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/getTSGUserList`, {
            headers: {
                "Authorization": token
            }
        })
        .then((response) => {
            
            const result = response.data;

            if( result.status =="success" ) {
                
                const data = result.data;
                setTeamUser(data);
            
            } else {
                swal({
                    title: "Error! #33456",
                    text: result.message ? result.message : 'Something went wrong',
                    icon: "error",
                    button: "Ok!",
                });
            }  
        })
        .catch((error) => {
            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
            
                window.localStorage.clear();
                window.location.href = '/';
                return;
            } else {
                swal({
                    title: "Error! #33005",
                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                    icon: "error",
                    button: "Ok!",
                });
            }
        });
        
    }, [])

    useEffect(() => {
        
        let token = window.localStorage.getItem('token');
        
        if( generalCall ) {
            if( (  companyId == "" || companyId == null || companyId == undefined ) ){

                swal({
                    title: 'Please enter company name',
                    icon: 'error',
                    confirmButtonText: 'Yes, delete it!',
                    reverseButtons: true,
                });

            } else {

                const timer = setTimeout(() => {
                    
                    const teamMember = addTeamList.map((singleData, index) => {
                        return  singleData._id
                    });
                    const boardMember = addBoardList.map((singleData, index) => {
                        return  singleData._id
                    });

                    let dataInfo = new FormData();

                    const data = {
                        'companyId' : companyId,
                        'teamMember' : teamMember,
                        'boardMember' : boardMember,
                        'companyVibe' : companyVibe,
                        'genuineComm' : genuineComm,
                        'investorImg' : (Object.keys(investorImg).length > 0) ? { 'imgUrl' : "" } : "",
                        'investorDelete' : investorDelete,
                        'investorAction' : investorAction,
                        'investorImgUrl' : investorImgUrl !== '' ? { 'imgUrl' : investorImgUrl } : ''
                    }

                    if (Object.keys(investorImg).length > 0) {
                        dataInfo.append('file', investorImg.inputFile);
                        data.imgType = 'investors';
                    }
                    
                    dataInfo.append('data', JSON.stringify(data));
                    //console.log(data);
                    axios.post(`${common.path.base_url}/addNewPipline`, dataInfo, {
                        headers: {
                            "Authorization": token
                        }
                    })
                    .then((response) => {
                        const savedData = response.data;
                        if(savedData.status == "success") {
                            const objData = savedData.data;
                            //setKeycustomerImg({});
                            setInvestorArr(objData.investors);
                            setInvestorImgUrl('');
                        }
                    })
                    .catch((error) => {
                        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        
                            window.localStorage.clear();
                            window.location.href = '/';
                            return;
                        } else {
                            swal({
                                title: "Error! #33005",
                                text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                icon: "error",
                                button: "Ok!",
                            });
                            setInvestorImgUrl('');
                        }
                    });

                }, 1500);
                return () => clearTimeout(timer);
            }
    }
    
    }, [addTeamList, addBoardList, companyVibe, genuineComm, investorImg, investorDelete, generalCall, companyId, investorAction, investorImgUrl]);

    const previewFile = ( file ) => {
        const tokenpass = window.localStorage.getItem('token');
        const data = new FormData();
        data.append('file', file.target.files[0]);
        data.append('path', 'investor');

        axios.post(`${common.path.base_url}/s3ImageUploader`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
        .then((response) => {
            if(response){
                const arrCode = [...investorArr];
                arrCode.push({ 'imgUrl': response.data.imageUrl });
                setGeneralCall(true);
                setInvestorArr(arrCode);
                setInvestorAction('add');
                setInvestorImgUrl(response.data.imageUrl);
            }
        })
        .catch((error) => {
            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                window.localStorage.clear();
                window.location.href = '/';
                return;
            } 
        });
    }

    const deleteTeam = ( index , data) => {

        swal({
            title: 'Are you sure you want to delete?',
            text: "This item will be delete immediately. You can't undo this action.",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#7A8DA7',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true,
            // customClass: {
            //     confirmButton: 'primaryBTN',
            // }
        }).then((resp) => {
            if(resp) {
                deleteMember(data);
                setGeneralCall(true);
                addTeamList.splice(index, 1);
                setAddTeamList([...addTeamList]);
            }
        });        
    }

    const deleteBoard = (index, data) => {
        swal({
            title: 'Are you sure you want to delete?',
            text: "This item will be delete immediately. You can't undo this action.",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#7A8DA7',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true,
            // customClass: {
            //     confirmButton: 'primaryBTN',
            // }
        }).then((resp) => {
            if(resp) {
                deleteMember(data);
                setGeneralCall(true);
                addBoardList.splice(index, 1);
                setAddBoardList([...addBoardList]);
            }
        });   
    }


    const deleteMember = (data) => {
        const token = localStorage.getItem('token');
        if(token){
            axios.delete(`${common.path.base_url}/startupContacts/${data._id}`, { headers: { "Authorization": token }, }).then((response) => {
                swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
                button: "Ok!",
                })
            })  
        }
    }  

    const editModalFunction = (type, data) => {
        if( type == 'team' ) {
            setSetModalTitle("Edit Team");
        } else if( type == 'board' ){
            setSetModalTitle("Edit Board");
        }
        setEditModel(true);
        setShowTeamModal(true);
        setPreview(data.imageUrl);
        setValue('id', data._id);
        setValue('file', data.imageUrl);
        setValue('name', data.name);
        setValue('title', data.title);
        setValue('bio', data.bio);
    }
    
    const userModalCallFn = (type) => {
        setEditModel(false);
        if( type == 'team' ) {
            setSetModalTitle("Add Team");
            setShowTeamModal(true);
            // setSearchTeamBox('');
            // setTeamModalCall(!teamModalCall)
            // setFilterTeamUser([]);
        } else if( type == 'board' ){
            setSetModalTitle("Add Board");
            setShowTeamModal(true);
            // setSearchBoardBox('');
            // setBoardModalCall(!boardModalCall)
            // setFilterBoardUser([]);
        }
    }

    const uploadUpdatedLogo = (file) => {
        if (file.target.files.length) {
            setImageUploading(true);
            let tokenpass = window.localStorage.getItem('token');
            let data = new FormData();
            data.append('image', file.target.files[0]);
            axios.post(`${common.path.base_url}/s3ImageUploader`, data, {
                headers: {
                    "Authorization": tokenpass
                }
            })
            .then((response) => {
                setImgId(response.data.imageUrl);
                setValue('file', response.data.imageUrl );
                setImageUploading(false);
            })
            .catch((error) => {
                setImageUploading(false);
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else { 

                }
            });
        }
    }

    const onCloseHandler = () => {
        setShowTeamModal(false);
        setImgId(null)
        setSelectedFile(undefined);
        setPreview(undefined);
        reset({
            name: '',
            title: '',
            bio: '',
            file: ''
        })
    }
    return (
        
        <>
            {/* Team Code */}
            <div className='sectionHeading'>Team</div>
            <div className='teamCont'>
                
                {   (addTeamList.length > 0) ?
                    addTeamList.map((singleData, index) => {  
                        return <div key={ "teamlist_"+index } className='teamlistCont'>
                            <div className='mr-10'>
                                { (singleData.imageUrl != "" ) ?
                                    <img src={ singleData.imageUrl } alt="" srcSet="" className='teamImage' />
                                    :
                                    <img src={ images.path.placeholder } alt="" srcSet="" className='teamImage' />
                                }
                            </div>
                            <div className='maxWidthContainer'>
                                <div className='teamName'>{ singleData.name }</div>
                                <div className='teamDesignation'>{ singleData.title }</div>
                                <div className='teamDesignation'>{ singleData.bio }</div>
                            </div>
                            {/* <div onClick={ () => deleteTeam(index, singleData) } className='teamTrash'>{Icons.Edit}</div> */}
                            <div  className='teamTrash'>
                                <div className="p-image1"><i onClick={ () => editModalFunction('team',singleData) } className="fa fa-pencil edit-button teamTrash"></i>
                                </div>
                                <span onClick={ () => deleteTeam(index, singleData) }>{Icons.Trash}</span>
                            </div>
                        </div>
                    }) : ""
                }
                <div className='teamlistCont pos'>
                    <div className='teamNewAdd' onClick={ (e) => userModalCallFn('team') }>
                        <div className='uploadteamimageCont mr-10'>
                            <div className='teamuploadimage'>
                                {Icons.User}</div>
                            <input type="file" className='uploadimageController' />
                        </div>
                        <div>
                            <div className='addNew'>
                                Add New
                            </div>
                        </div>
                    </div>
                    <div className={`teamMemberItemCont ${ (teamModalCall) ? 'teamNewAddActive' :"" }`}>
                        <div className='srhBarCont'>
                            <div className='flex-1'>
                                <input type="text" className="form-control srhBarTextBox" placeholder="Search Member" onChange={ (e) => searchMember(e, 'team') } value={ searchTeamBox } />
                            </div>
                            <div className='mr-10 ml-10'>{Icons.Search}</div>
                        </div>
                        <div className='TeamListCont1 mt-10'>

                            {   (filterTeamUser.length > 0) ?
                                filterTeamUser.map((singleData, index) => {  
                                    
                                    return <TeamRow 
                                            key={ "teamfilter_"+index }
                                            type='team'
                                            singleData={ singleData } 
                                            addTeamList={ addTeamList }
                                            saveTeamCompany = { (i) => saveTeamCompany(i, 'team') } 
                                            index = { index } 
                                        />

                                }) : "Please Search Member" 
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* Board Code */}
            <div className='sectionHeading mt-25'>Board</div>
            <div className='teamCont'>

                {   (addBoardList.length > 0) ?
                    addBoardList.map((singleData, index) => {  
                
                        return <div key={ "addboard_"+index } className='teamlistCont'>
                            <div className='mr-10'>
                                { ( singleData.imageUrl != "") ?
                                    <img src={ singleData.imageUrl } alt="" srcSet="" className='teamImage' />
                                    :
                                    <img src={ images.path.placeholder } alt="" srcSet="" className='teamImage' />
                                }
                            </div>
                            <div className='maxWidthContainer'>
                                <div className='teamName'>{ singleData.name }</div>
                                <div className='teamDesignation'>{ singleData.bio }</div>
                                {/* <div className='teamDesignation'>{ singleData.title }</div> */}
                            </div>
                            <div className='teamTrash'>
                                <div className="p-image1"><i onClick={ () => editModalFunction('board',singleData) } className="fa fa-pencil edit-button teamTrash"></i>
                                </div>
                                <span onClick={ () => deleteBoard(index,singleData) } >{Icons.Trash}</span>
                            </div>
                        </div>
                    }) : ""
                }

                <div className='teamlistCont pos'>

                    {/* <div className='teamlistCont' onClick={ (e) => setTeamModalCall(!teamModalCall) }>
                        <div className='uploadteamimageCont mr-10' onClick={(e) => (boardimageuploadRef.current.click())}>
                            <div className='teamuploadimage'>
                                {Icons.User}</div>
                            <input type="file" className='uploadimageController' ref={boardimageuploadRef} />
                        </div>
                        <div className='addNew'>
                            Add New
                        </div>
                    </div> */}

                    <div className='teamNewAdd' onClick={ (e) => userModalCallFn('board') }>
                        <div className='uploadteamimageCont mr-10'>
                            <div className='teamuploadimage'>
                                {Icons.User}</div>
                            <input type="file" className='uploadimageController' />
                        </div>
                        <div>
                            <div className='addNew'>
                                Add New
                            </div>
                        </div>
                    </div>
                    <div className={`teamMemberItemCont ${ (boardModalCall) ? 'teamNewAddActive' :"" }`}>
                        <div className='srhBarCont'>
                            <div className='flex-1'>
                                <input type="text" className="form-control srhBarTextBox" placeholder="Search Member" onChange={ (e) => searchMember(e, 'board') } value={ searchBoardBox } />
                            </div>
                            <div className='mr-10 ml-10'>{Icons.Search}</div>
                        </div>
                        <div className='TeamListCont1 mt-10'>

                            {   (filterBoardUser.length > 0) ?
                                filterBoardUser.map((singleData, index) => {  
                                    
                                    return <TeamRow 
                                            key={ "teamrow_"+index }
                                            type='board'
                                            singleData={ singleData } 
                                            addBoardList={ addBoardList }
                                            saveTeamCompany = { (i) => saveTeamCompany(i, 'board') } 
                                            index = { index } 
                                        />

                                }) : "Please Search Member" 
                            }
                        </div>
                    </div>

                </div>
            </div>

            <div className='sectionHeading mt-25'>Investors</div>
            <div className='keycustomerCont mt-15'>
                <div className='addkeycustomer mr-15'>
                    <div className='addiconCont' onClick={(e) => (investoruploadRef.current.click())}>{Icons.Addicon}</div>
                    {/* <input type="file" className='keycustomerUpload' ref={investoruploadRef} /> */}
                    <input id="investorFile" className='keycustomerUpload' type="file" ref={investoruploadRef} onChange={(e) => previewFile(e)} accept="image/jpeg,image/png,image/jpg" />
                </div>
                
                {
                    (investorArr.map( (singleData, index) => {
                        
                        return <div key={ "investor_"+index } className='addkeycustomer mr-15'>
                                <div className='customerlogoCont'>
                                    {singleData.imageUrl !== '' && <img src={singleData.imgUrl} className='keycustomerImage' alt='' />}
                                    <div className='keycustomerRemove' onClick={() => removeInvestorHandle(index)}>{Icons.RemoveG}</div>
                                </div>
                            </div>
                    
                    })) 
                }
                {/* <div className='addkeycustomer mr-15'>
                    <div className='customerlogoCont'>
                        <img src="assets/norwest.jpg" className='keycustomerImage' alt='' />
                        <div className='keycustomerRemove' onClick={() => { removeHandle(); }}>{Icons.RemoveG}</div>
                    </div>
                </div>

                <div className='addkeycustomer mr-15'>
                    <div className='customerlogoCont'>
                        <img src="assets/merlin.jpg" className='keycustomerImage' alt='' />
                        <div className='keycustomerRemove' onClick={() => { removeHandle(); }}>{Icons.RemoveG}</div>
                    </div>
                </div> */}

            </div>

            <div className='mt-20'>
                <hr />
            </div>

            <div className='row'>
                <div className='col-lg-12'>
                    <div className='border-b pb-15 mb-15'>
                        <div className='fieldLable'>Company Culture (rain or sunshine ahead?)</div>
                        <div className='mt-5'>
                            {/* <input type="text" id="companyVibe" className='compState piple-input' placeholder='Enter Company Vibe' value={ companyVibe } onChange={ (e) => inputHandlerChange(e) } /> */}
                            <textarea className="textArea" id="companyVibe" placeholder="Culture description" value={ companyVibe } onChange={ (e) => { inputHandlerChange( e )  } }></textarea>
                        </div>
                    </div>
                    <div className='mt-15'>
                        <div className='fieldLable'>Genuine commitment to channel?</div>
                        <div className='mt-5'>
                            {/* <input type="text" id="genuineComm" className='compState piple-input' placeholder='Enter genuine commitment to channel' value={ genuineComm } onChange={ (e) => inputHandlerChange(e) } /> */}
                            <textarea className="textArea" id="genuineComm" placeholder="Enter genuine commitment to channel" value={ genuineComm } onChange={ (e) => { inputHandlerChange( e )  } }></textarea>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                id='addNewTeam'
                aria-labelledby="ModalHeader"
                show={showTeamModal}
                >
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header>
                        <Modal.Title id='ModalHeader'>{setModalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        { dataUploading && <div className='member-modal-loader'>
                        <LoadingSpinner />
                        </div>}
                        <div className="board">
                            <div className="board">
                                <div className="form-horizontal">
                                    <div className="form-group">
                                        <label className="col-md-3 control-label company-label">Picture:</label>
                                        <div className='col-md-8'>
                                            <div className='upload-board-pic img-box'>
                                                <div className="board-pic-box">
                                                {/* <img id='preview1' className="profile-pic" src={imageUploading ? `${images.path.loader}` : imgId ? imgId : `${common.path.base_url}/getImage?id=5e37c40f79f2d70b224d9eed`} alt='' /> */}
                                                    <img id='preview1' className="profile-pic" src={preview ? preview : "https://tsg-dev-images.s3.amazonaws.com/default.jpeg"} alt='' />
                                                    <div>
                                                        <div className="p-image1">
                                                            <i onClick={() => document.querySelector(`#chooseFileUpdate`).click()} className="fa fa-pencil edit-button"></i>
                                                            <input id={`chooseFileUpdate`} onChange={onSelectFile} type="file" style={{display: 'none'}}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-3 control-label">Full Name:</label>
                                        <div className="col-md-8">
                                            <input className="form-control" id="name" type='text' placeholder='Full name' {...register("name", { required: true })}/>
                                            {errors.name && <p>Name should not be empty*</p>}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-3 control-label">Title :</label>
                                        <div className="col-md-8">
                                            <input className="form-control" id="title" type='text' placeholder='Title' {...register("title", { required: true })} />
                                            {errors.title && <p>Title should not be empty*</p>}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-3 control-label">Bio:</label>
                                        <div className="col-md-8">
                                            <textarea className="form-control" id="bio" type="message" placeholder='Bio' rows="4" cols="50" {...register("bio")}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <LaddaButton
                            id='lada-btn'
                            type='submit'
                            data-color="#eee"
                            data-size={XS}
                            className={ dataUploading === false ? '' : 'disable'}
                            data-style={EXPAND_RIGHT}
                            data-spinner-size={30}
                            data-spinner-color="#ddd"
                            data-spinner-lines={12}
                        >{editModel ? 'UPDATE' :'ADD NEW'}</LaddaButton>
                        <input type="button" id='company-cancel' className="btn btn-default" data-dismiss="modal" value="CANCEL" onClick={onCloseHandler} />
                    </Modal.Footer>
                    </form>
                </Modal>

        </>
    )
}

export default TeamBoardInvestor;
import React, { Component } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import axios from 'axios';
import swal from 'sweetalert';

import './checklistManagement.scss'
import LoadingSpinner from '../../components/Loader';
import Header from '../../components/Header';
import HeaderImage from '../../components/HeaderImage';
import PrimaryChecklist from './components/primary-checklist';
import ChecklistModal from './components/add-checklist-modal';
import UpdateChecklistModal from './components/update-checklist-modal';
import AddSubChecklistModal from './components/add-subchecklist-modal';
import common from '../../common';

export default class ChecklistManagment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      role: '',
      username: '',
      roleCode: '',
      sampleChecklist: [
        { id: "check-0", content: "Certificate of Formation", order: 1, subChecklist: [{ id: "sub-0", content: "Prepped" }, { id: "sub-1", content: "Accepted" }, { id: "sub-3", content: "Pending" }] },
        { id: "check-1", content: "EIN", subChecklist: [], order: 3 },
        { id: "check-2", content: "Statement of Information", subChecklist: [], order: 4 }
      ],
      checklistData: [],
      checklistForm: {
        title: '',
        isDocNeeded: false,
        isMultipleDocUploadAllowed: false,
      },
      selectedChecklistId: '',
      subChecklistForm: {
        subTitle: ''
      },
      key: 0,
    };
  }

  componentDidMount() {
    this.setState({
      role: window.localStorage.getItem('role'),
      username: window.localStorage.getItem('username'),
      roleCode: window.localStorage.getItem('roleCode'),
    });

    this.getChecklistData();
  }

  getChecklistData() {
    let temp = this;
    temp.setState({ loading: true });
    let tokenpass = window.localStorage.getItem('token');
    axios.get(`${common.path.base_url}/checkList`, {
      headers: {
        "Authorization": tokenpass
      }
    })
      .then((response) => {
        temp.setState({ loading: false });
        this.setState({ checklistData: response.data.data, });
      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
          window.localStorage.clear();
          window.location.href = '/';
          return;
        }
        else if (error.response && error.response.data && error.response.data.code && error.response.data.code === 204) {
          window.localStorage.clear();
          window.location.href = '/';
        }
        swal({
          title: "Error!",
          text: error.message,
          icon: "error",
          button: "Ok!",
        });
        temp.setState({ loading: false });
      });
  };

  deleteChecklist(checklistId) {
    if (checklistId) {
      swal({
        title: "Delete Checklist",
        text: "Are you sure you want to delete checklist?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((shouldDelete) => {
          if (shouldDelete) {
            const temp = this;
            temp.setState({ loading: true });
            let tokenpass = window.localStorage.getItem('token');
            axios.delete(`${common.path.base_url}/checkList/${checklistId}`, {
              headers: {
                "Authorization": tokenpass
              }
            })
              .then((response) => {
                this.resetChecklistForm();
                swal({
                  title: "Delete Checklist",
                  text: response.data.message,
                  icon: "success",
                  button: "Ok!",
                })
                  .then(function () {
                    temp.setState({ loading: false });
                    temp.getChecklistData();
                  });
              })
              .catch((error) => {
                this.resetChecklistForm();
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                  window.localStorage.clear();
                  window.location.href = '/';
                  return;
                }
                else if (error.response) {
                  swal({
                    title: "Error!",
                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                    icon: "error",
                    button: "Ok!",
                  });
                }
                else {
                  swal({
                    title: "Network Error!",
                    text: error.message,
                    icon: "error",
                    button: "Ok!",
                  });
                }
              });
          }
        });
    }
  };

  deleteSubChecklist(checklistId, subChecklistId) {
    if (checklistId && subChecklistId) {
      swal({
        title: "Delete Sub Checklist",
        text: "Are you sure you want to delete Sub checklist?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((shouldDelete) => {
          if (shouldDelete) {
            const temp = this;
            temp.setState({ loading: true });
            let tokenpass = window.localStorage.getItem('token');
            axios.delete(`${common.path.base_url}/subCheckList/${checklistId}/${subChecklistId}`, {
              headers: {
                "Authorization": tokenpass
              }
            })
              .then((response) => {
                this.resetSubChecklistForm();
                swal({
                  title: "Delete Checklist",
                  text: response.data.message,
                  icon: "success",
                  button: "Ok!",
                })
                  .then(function () {
                    temp.setState({ loading: false });
                    temp.getChecklistData();
                  });
              })
              .catch((error) => {
                this.resetSubChecklistForm();
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                  window.localStorage.clear();
                  window.location.href = '/';
                  return;
                }
                else if (error.response) {
                  swal({
                    title: "Error!",
                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                    icon: "error",
                    button: "Ok!",
                  });
                }
                else {
                  swal({
                    title: "Network Error!",
                    text: error.message,
                    icon: "error",
                    button: "Ok!",
                  });
                }
              });
          }
        });
    }
  };

  updateChecklistOrder = (newChecklistOrder) => {
    if (newChecklistOrder && newChecklistOrder.length > 0) {
      const temp = this;
      temp.setState({ loading: true });
      const payload = { checkListOrder: newChecklistOrder.map(({ _id, order }) => ({ _id, order })) };
      let tokenpass = window.localStorage.getItem('token');
      axios.post(`${common.path.base_url}/changeCheckListOrder`, payload, {
        headers: {
          "Authorization": tokenpass
        }
      })
        .then((response) => {
          swal({
            title: "Change checklist order",
            text: response.data.message,
            icon: "success",
            button: "Ok!",
          })
            .then(function () {
              temp.setState({ loading: false });
              temp.getChecklistData();
            });
        })
        .catch((error) => {
          this.getChecklistData();
          temp.setState({ loading: false });
          if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
            window.localStorage.clear();
            window.location.href = '/';
            return;
          }
          else if (error.response) {
            swal({
              title: "Error!",
              text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
          else {
            swal({
              title: "Network Error!",
              text: error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        });
    }
  };

  updateSubChecklistOrder = (newSubChecklistOrder, checklistId) => {
    if (checklistId && (newSubChecklistOrder && newSubChecklistOrder.length > 0)) {
      const temp = this;
      temp.setState({ loading: true });
      const payload = { subCheckListOrder: newSubChecklistOrder.map(({ _id, order }) => ({ _id, order })) };
      let tokenpass = window.localStorage.getItem('token');
      axios.post(`${common.path.base_url}/changeSubCheckListOrder/${checklistId}`, payload, {
        headers: {
          "Authorization": tokenpass
        }
      })
        .then((response) => {
          swal({
            title: "Change checklist order",
            text: response.data.message,
            icon: "success",
            button: "Ok!",
          })
            .then(function () {
              temp.setState({ loading: false });
              temp.getChecklistData();
            });
        })
        .catch((error) => {
          this.getChecklistData();
          temp.setState({ loading: false });
          if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
            window.localStorage.clear();
            window.location.href = '/';
            return;
          }
          else if (error.response) {
            swal({
              title: "Error!",
              text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
          else {
            swal({
              title: "Network Error!",
              text: error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        });
    }
  };

  changeChecklistOrder = (checklists) => {
    const updatedChecklist = checklists.map((singleChecklist, index) => {
      singleChecklist.order = index + 1;
      return singleChecklist
    })
    return updatedChecklist;
  };

  reOrderArray = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  handleChecklistsDrag = (source, destination) => {
    const reOrderedChecklist = this.reOrderArray(this.state.checklistData, source.index, destination.index);
    const updatedChecklist = this.changeChecklistOrder(reOrderedChecklist);
    this.setState({ checklistData: updatedChecklist });
    this.updateChecklistOrder(updatedChecklist);
  };

  handleSubChecklistsDrag = (source, destination, type) => {
    const checklists = [...this.state.checklistData];
    const subChecklists = this.reOrderArray(checklists[parseInt(type, 10)].subCheckLists, source.index, destination.index);
    const updatedSubChecklists = this.changeChecklistOrder(subChecklists);
    checklists[type].subCheckLists = updatedSubChecklists;
    this.setState({ checklistData: checklists });
    this.updateSubChecklistOrder(updatedSubChecklists, checklists[type]._id);
  };

  onPrimaryChecklistDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.type === "checklists") {
      this.handleChecklistsDrag(result.source, result.destination);
    } else {
      this.handleSubChecklistsDrag(result.source, result.destination, result.type);
    }
  };

  resetChecklistForm = () => {
    this.setState({
      checklistForm: {
        title: '',
        isDocNeeded: false,
        isMultipleDocUploadAllowed: false,
      }
    });
  };

  resetSubChecklistForm = () => {
    this.setState({
      subChecklistForm: {
        subTitle: '',
      }
    });
  };

  /* Add Checklist Modal start */

  addChecklist(checklistData) {
    if (checklistData) {
      const temp = this;
      temp.setState({ loading: true });
      this.setState({ key: this.state.key + 1 });
      let tokenpass = window.localStorage.getItem('token');
      axios.post(`${common.path.base_url}/checkList`, checklistData, {
        headers: {
          "Authorization": tokenpass
        }
      })
        .then((response) => {
          this.resetChecklistForm();
          swal({
            title: "Add Checklist",
            text: response.data.message,
            icon: "success",
            button: "Ok!",
          })
            .then(function () {
              temp.setState({ loading: false });
              temp.getChecklistData();
            });
        })
        .catch((error) => {
          this.resetChecklistForm();
          temp.setState({ loading: false });
          if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
            window.localStorage.clear();
            window.location.href = '/';
            return;
          }
          else if (error.response) {
            swal({
              title: "Error!",
              text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
          else {
            swal({
              title: "Network Error!",
              text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        });
    }
  };


  onTitleChange = (event) => {
    this.setState({
      checklistForm: {
        title: event.target.value,
        isDocNeeded: this.state.checklistForm.isDocNeeded,
        isMultipleDocUploadAllowed: this.state.checklistForm.isMultipleDocUploadAllowed,
      }
    });
  };

  onIsDocRequiredChange = (event) => {
    this.setState({
      checklistForm: {
        title: this.state.checklistForm.title,
        isDocNeeded: event.target.checked,
        isMultipleDocUploadAllowed: this.state.checklistForm.isMultipleDocUploadAllowed,
      }
    });
  };

  onIsMultiDocUploadChange = (event) => {
    this.setState({
      checklistForm: {
        title: this.state.checklistForm.title,
        isDocNeeded: this.state.checklistForm.isDocNeeded,
        isMultipleDocUploadAllowed: event.target.checked,
      }
    });
  }

  isChecklistFormValid = () => {
    return this.state.checklistForm.title ? false : true;
  };

  onChecklistFormSubmit = () => {
    this.addChecklist(this.state.checklistForm);
  };

  /* Add Checklist Modal End */

  /* Edit Checklist Modal Start */

  updateChecklist(checklistData, checklistId) {
    if (checklistData && checklistId) {
      const temp = this;
      temp.setState({ loading: true });
      let tokenpass = window.localStorage.getItem('token');
      axios.put(`${common.path.base_url}/checkList/${checklistId}`, checklistData, {
        headers: {
          "Authorization": tokenpass
        }
      })
        .then((response) => {
          this.resetChecklistForm();
          swal({
            title: "Update Checklist",
            text: response.data.message,
            icon: "success",
            button: "Ok!",
          })
            .then(function () {
              temp.setState({ loading: false });
              temp.getChecklistData();
            });
        })
        .catch((error) => {
          temp.setState({ loading: false });
          this.resetChecklistForm();
          if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
            window.localStorage.clear();
            window.location.href = '/';
            return;
          }
          else if (error.response) {
            swal({
              title: "Error!",
              text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
          else {
            swal({
              title: "Network Error!",
              text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        });
    }
  };

  onPrimaryChecklistEdit = (checklistId) => {
    const selectedChecklist = this.state.checklistData.find(singleChecklist => singleChecklist._id === checklistId);
    this.setState({
      checklistForm: {
        title: selectedChecklist.title,
        isDocNeeded: selectedChecklist.isDocNeeded,
        isMultipleDocUploadAllowed: selectedChecklist.isMultipleDocUploadAllowed,
      },
      selectedChecklistId: checklistId,
    });
  }

  onChecklistFormUpdate = () => {
    this.updateChecklist(this.state.checklistForm, this.state.selectedChecklistId);
  };

  /* Edit Checklist Modal End */

  /* Add Sub Checklist Modal start */

  addSubChecklist(subChecklistData, checklistId) {
    if (subChecklistData && checklistId) {
      this.setState({ key: this.state.key + 1 });
      const temp = this;
      temp.setState({ loading: true });
      let tokenpass = window.localStorage.getItem('token');
      axios.post(`${common.path.base_url}/subCheckList/${checklistId}`, subChecklistData, {
        headers: {
          "Authorization": tokenpass
        }
      })
        .then((response) => {
          this.resetSubChecklistForm();
          swal({
            title: "Add Sub Checklist",
            text: response.data.message,
            icon: "success",
            button: "Ok!",
          })
            .then(function () {
              temp.setState({ loading: false });
              temp.getChecklistData();
            });
        })
        .catch((error) => {
          this.resetSubChecklistForm();
          temp.setState({ loading: false });
          if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
            window.localStorage.clear();
            window.location.href = '/';
            return;
          }
          else if (error.response) {
            swal({
              title: "Error!",
              text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
          else {
            swal({
              title: "Network Error!",
              text: error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        });
    }
  };


  onSubChecklistTitleChange = (event) => {
    this.setState({
      subChecklistForm: {
        subTitle: event.target.value,
      }
    });
  };

  isSubChecklistFormValid = () => {
    return this.state.subChecklistForm.subTitle ? false : true;
  };

  onSubChecklistFormSubmit = () => {
    this.addSubChecklist(this.state.subChecklistForm, this.state.selectedChecklistId);
  };

  onClickAddSubChecklist = (selectedChecklistId) => {
    if (selectedChecklistId) {
      this.setState({ selectedChecklistId: selectedChecklistId });
    }
  }

  /* Add Checklist Modal End */

  render() {
    return (
      <div className="checklist-container container-fluid">
        { this.state.loading && <LoadingSpinner />}
        <div className="new-investment">
          <h2>Investment Checklist Management Console</h2>
        </div>
        <div className="management-console">
          {
            this.state.checklistData.length > 0
              ? <div className="row">
                <DragDropContext onDragEnd={this.onPrimaryChecklistDragEnd} onDragUpdate={this.onDragUpdate}>
                  <PrimaryChecklist
                    checklists={this.state.checklistData}
                    onPrimaryChecklistEdit={(checklistId) => this.onPrimaryChecklistEdit(checklistId)}
                    onDeletePrimaryChecklist={(checklistId) => this.deleteChecklist(checklistId)}
                    onClickAddSubChecklist={(checklistId) => this.onClickAddSubChecklist(checklistId)}
                    onDeleteSubChecklist={(checklistId, subChecklistId) => this.deleteSubChecklist(checklistId, subChecklistId)}
                  />
                </DragDropContext>
              </div>
              : <div className="row">
                <div className="col-lg-7 col-xs-12">No Checklist Found</div>
              </div>
          }

          <div className="row">
            <div className="col-lg-7 col-xs-12">
              <button className="btn btn-danger add-checklist-btn" data-toggle="modal" data-target="#addChecklistModal">Add Checklist Option</button>
            </div>
          </div>
        </div>

        <ChecklistModal
          checklistFormData={this.state.checklistForm}
          onTitleChange={(event) => this.onTitleChange(event)}
          onIsDocRequiredChange={(event) => this.onIsDocRequiredChange(event)}
          onIsMultiDocUploadChange={(event) => this.onIsMultiDocUploadChange(event)}
          onChecklistFormSubmit={() => this.onChecklistFormSubmit()}
          isChecklistFormValid={() => this.isChecklistFormValid()}
          addChecklistModalkey={this.state.key}
        />

        <UpdateChecklistModal
          checklistFormData={this.state.checklistForm}
          isChecklistFormValid={() => this.isChecklistFormValid()}
          onTitleChange={(event) => this.onTitleChange(event)}
          onIsDocRequiredChange={(event) => this.onIsDocRequiredChange(event)}
          onIsMultiDocUploadChange={(event) => this.onIsMultiDocUploadChange(event)}
          onChecklistFormUpdate={() => this.onChecklistFormUpdate()}
        />

        <AddSubChecklistModal
          isSubChecklistFormValid={() => this.isSubChecklistFormValid()}
          onSubChecklistFormSubmit={() => this.onSubChecklistFormSubmit()}
          onSubChecklistTitleChange={(event) => this.onSubChecklistTitleChange(event)}
          addSubChecklistKey={this.state.key}
        />
      </div>
    );
  }
}
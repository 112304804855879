import React from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import common from '../../../common';
import images from "../../../images";
import { path } from "../../imagesPath";
import { getCompanyLogoFromS3 } from '../../shared/helper';
import { default as useScreenWidthLessThan768 } from '../../shared/window_resize_hook';
import './PendingCounterSigningInvestments.scss';


const PendingCounterSigningInvestmentCard = props => {
  const { pendingCounterSignInvestment } = props;
  const isScreenResized = useScreenWidthLessThan768();
  function webinarPopup(data) {
    window.location.href = "/webinar-request?investor=" + data.userId + "&investment=" + data.investmentId;
  }
  //console.log("govind..", pendingCounterSignInvestment);
  const viewDocumentRedirect = (envId, type) => {
    let queryString = `?envId=${envId}&type=${type}`;
    window.open(` ${common.path.doc_download_url}/downloadPDF${queryString}`, '_blank', '', 2)

  }
  const getWForm = (id, isForeignCitizen) => {
    let type = 'W-9';
    if (isForeignCitizen) {
      type = 'W-8';
    }
    return (
      <>
        <h5 onClick={() => viewDocumentRedirect(id, type)}>View {type} Doc</h5>
      </>
    );
  }

  const getQSA = (id) => {
    return (
      <>
      </>
    );
  }

  const iconRedirect = (data, type) => {
    const investment = data.investment;
    switch (type) {
      case 'IIR':
        //window.open(`/iirPreviewPDF?companyId=${investment.companyId}&investmentId=${investment._id}`, '_blank')
        window.open(`/previewIIR?companyId=${investment.companyId}&investmentId=${investment._id}`, "_self")
        break;
      case 'EL':
        window.open(`/foundLetterPreview?companyId=${investment.companyId}&investmentId=${investment._id}`, "_self")
        break;
      case 'FAQ':
        window.open(`/investorFaqPreview`, "_self")
        break;
      default:
        break;
    }
  }

  const getSideLetter = (investment) => {
    //console.log("asdasd", investment);  

    if (investment.investmentprocesses.isSignSideLetterResend) {
      const processId = investment.investmentprocesses && investment.investmentprocesses._id;
      let powerFormID = process.env.REACT_APP_SIDE_LETTER_ID;
      const mFee = investment.investmentprocesses.managementFee;
      let mFeePercentage = mFee;
      const carry = investment.investmentprocesses.carry;
      let investorCarry = carry;
      let investorCarryAll = (100 - carry);
      let userName = investment.investmentprocesses.firstName + ' ' + investment.investmentprocesses.lastName;
      let userEmail = investment.investmentprocesses.emailId;

      return (
        <>
          {/* <h5 onClick={() => viewDocumentRedirect(id, 'Subscription', 1)}>Re-Sign Side Letter</h5> */}
          <form action={`${process.env.REACT_APP_DOCUSIGN_HOST}/Member/PowerFormSigning.aspx?PowerFormId=${powerFormID}`} method="POST">
            <input type="hidden" name="env" id="env" value={process.env.REACT_APP_DOCUSIGN_ENV} />
            <input type="hidden" name="acct" id="acct" value={process.env.REACT_APP_DOCUSIGN_ACCOUNT_ID} />
            <input type="hidden" name="v" id="v" value='2' />
            <input type="hidden" name="EnvelopeField_userId" id="EnvelopeField_userId" value={window.localStorage.getItem('id')} />
            <input type="hidden" name="EnvelopeField_webhookEnv" id="EnvelopeField_webhookEnv" value={process.env.REACT_APP_WEBHOOK_ENV} />
            <input type="hidden" name="EnvelopeField_investmentId" id="EnvelopeField_investmentId" value={investment._id} />
            <input type="hidden" name="EnvelopeField_companyId" id="EnvelopeField_companyId" value={investment.companyId} />
            <input type="hidden" name="EnvelopeField_processId" id="EnvelopeField_processId" value={processId} />
            <input type="hidden" name="EnvelopeField_type" id="EnvelopeField_type" value='sideletter' />
            <input type="hidden" name="investor_entityName" id="investor_entityName" value={investment.llcName} />
            <input type="hidden" name="investor_UserName" id="investor_UserName" value={userName} />
            <input type="hidden" name="investor_Email" id="investor_Email" value={userEmail} />
            <input type="hidden" name="investor_address" id="investor_address" value={investment.investmentprocesses.address1} />
            <input type="hidden" name="investor_address2" id="investor_address2" value={investment.investmentprocesses.address2} />
            <input type="hidden" name="investor_managementFee" id="investor_managementFee" value={mFeePercentage} />
            <input type="hidden" name="investor_managementFeeValue" id="investor_managementFeeValue" value={mFee} />
            <input type="hidden" name="investor_carry" id="investor_carry" value={investorCarry} />
            <input type="hidden" name="investor_carryAll" id="investor_carryAll" value={investorCarryAll} />
            <input type="hidden" name="EnvelopeField_reSend" id="EnvelopeField_reSend" value={false} />
            <button type='submit' className='btn btn-danger' style={{ marginTop: '10px' }} >
              Re-Sign Side Letter
            </button>
          </form>
        </>
      );

    } else if (!investment.investmentprocesses.isSignSideletter && !investment.investmentprocesses.sideletterEnvId && investment.investmentprocesses.isFeeAndCarrySet) {

      const processId = investment.investmentprocesses && investment.investmentprocesses._id;
      let powerFormID = process.env.REACT_APP_SIDE_LETTER_ID;
      const mFee = investment.investmentprocesses.managementFee;
      let mFeePercentage = mFee;
      const carry = investment.investmentprocesses.carry;
      let investorCarry = carry;
      let investorCarryAll = (100 - carry);
      let userName = investment.investmentprocesses.firstName + ' ' + investment.investmentprocesses.lastName;
      let userEmail = investment.investmentprocesses.emailId;

      return (
        <>
          {/* <h5 onClick={() => viewDocumentRedirect(id, 'Subscription', 1)}>Re-Sign Side Letter</h5> */}
          <form action={`${process.env.REACT_APP_DOCUSIGN_HOST}/Member/PowerFormSigning.aspx?PowerFormId=${powerFormID}`} method="POST">
            <input type="hidden" name="env" id="env" value={process.env.REACT_APP_DOCUSIGN_ENV} />
            <input type="hidden" name="acct" id="acct" value={process.env.REACT_APP_DOCUSIGN_ACCOUNT_ID} />
            <input type="hidden" name="v" id="v" value='2' />
            <input type="hidden" name="EnvelopeField_userId" id="EnvelopeField_userId" value={window.localStorage.getItem('id')} />
            <input type="hidden" name="EnvelopeField_webhookEnv" id="EnvelopeField_webhookEnv" value={process.env.REACT_APP_WEBHOOK_ENV} />
            <input type="hidden" name="EnvelopeField_investmentId" id="EnvelopeField_investmentId" value={investment._id} />
            <input type="hidden" name="EnvelopeField_companyId" id="EnvelopeField_companyId" value={investment.companyId} />
            <input type="hidden" name="EnvelopeField_processId" id="EnvelopeField_processId" value={processId} />
            <input type="hidden" name="EnvelopeField_type" id="EnvelopeField_type" value='sideletter' />
            <input type="hidden" name="investor_entityName" id="investor_entityName" value={investment.llcName} />
            <input type="hidden" name="investor_UserName" id="investor_UserName" value={userName} />
            <input type="hidden" name="investor_Email" id="investor_Email" value={userEmail} />
            <input type="hidden" name="investor_address" id="investor_address" value={investment.investmentprocesses.address1} />
            <input type="hidden" name="investor_address2" id="investor_address2" value={investment.investmentprocesses.address2} />
            <input type="hidden" name="investor_managementFee" id="investor_managementFee" value={mFeePercentage} />
            <input type="hidden" name="investor_managementFeeValue" id="investor_managementFeeValue" value={mFee} />
            <input type="hidden" name="investor_carry" id="investor_carry" value={investorCarry} />
            <input type="hidden" name="investor_carryAll" id="investor_carryAll" value={investorCarryAll} />
            <input type="hidden" name="EnvelopeField_reSend" id="EnvelopeField_reSend" value={false} />
            <button type='submit' className='btn btn-danger' style={{ marginTop: '10px' }} >
              Sign Side Letter
            </button>
          </form>
        </>
      );
    } else {

      if (investment.investmentprocesses.sideletterEnvId) {
        return (
          <>
            <div className='d-flex'>
              <h5 style={{ marginLeft: isScreenResized ? "3px" : "0px" }} onClick={() => viewDocumentRedirect(investment.investmentprocesses.sideletterEnvId, 'sideLetter', 2)}>View Side Letter <img src={images.path.writing_icon} style={{ marginLeft: "5px" }} height={20} width={20} /></h5>
            </div>
          </>
        );
      } else {
        return false;
      }
    }
  }
  //console.log("sssssss---",pendingCounterSignInvestment)

  return (
    <>
      <div className="card cardbox box-card-Countersignature" key={pendingCounterSignInvestment.investment.name.toLowerCase()}>
        <div className="exit_box">
          <div className="series_d">
            <img src={getCompanyLogoFromS3(pendingCounterSignInvestment.company)} alt='' />
          </div>
          <div className='text-content'>
            <h2>{pendingCounterSignInvestment.investment.name}</h2>
            <NumberFormat
              className="amount-text"
              value={pendingCounterSignInvestment.investmentprocesses.amount}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'} />
            <div className='d-flex'>
              <h5 className='margin-left-0' onClick={() => viewDocumentRedirect(pendingCounterSignInvestment.investmentprocesses.subscriptionEnvId, 'Subscription', 1)}>View QSA Doc</h5>  <h5>|</h5>  {getWForm(pendingCounterSignInvestment.investmentprocesses.w9EnvId, pendingCounterSignInvestment.investmentprocesses.isForeignCitizen)}
            </div>
            {<>{getSideLetter(pendingCounterSignInvestment)}</>}
          </div>
          {/* {((pendingCounterSignInvestment.investment.webinarLink1 != null && pendingCounterSignInvestment.investment.webinarLink1.link != null) || (pendingCounterSignInvestment.investment.webinarLink2 != null && pendingCounterSignInvestment.investment.webinarLink2.link != null)) &&
            <>
              <div className='webinarLink' onClick={() => } >
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                  <i className="fa fa-play-circle" aria-hidden="true" style={{ fontSize: '25px', 'color': '#d9534f' }}></i> &nbsp;&nbsp;
                  {(pendingCounterSignInvestment.investment.webinarRecording != null && pendingCounterSignInvestment.investment.webinarRecording != "") ?
                    'View Recorded Webinar' : 'Attend Webinar'
                  }
                </div>
              </div>
            </>
          } */}
        </div>
      </div>
      <div className='icon-view-box d-flex justify-start' style={{ padding: "10px" }}>
        <span
          title="IIR"
          onClick={() => { iconRedirect(pendingCounterSignInvestment, 'IIR') }}
          className="view-span-icon-iir"
        >
          <img
            src={
              props.isDisable
                ? (path && path.groupDisable) || path.report
                : path.report && path.report
            }
            alt=""
            className="report"
            style={{ width: "20px" }}
          />
        </span>
        <span
          title="Executive Letter"
          onClick={() => { iconRedirect(pendingCounterSignInvestment, 'EL') }}
          className="view-span-icon-ex"
        >
          <img
            src={
              path.latter
            }
            alt=""
            className="latter"
            style={{ width: "20px" }}
          />
        </span>
        <span
          title="FAQs"
          onClick={() => { iconRedirect(pendingCounterSignInvestment, 'FAQ') }}
          className="view-span-icon-faq"
        >
          <img
            src={
              props.isDisable
                ? (path.questionDisable && path.questionDisable) ||
                path.question
                : path.question && path.question
            }
            alt=""
            className={props.isDisable ? "report-disable" : "question"}
            style={{ width: "20px" }}
          />
        </span>
        {((pendingCounterSignInvestment.investment.webinarLink1 != null &&
          pendingCounterSignInvestment.investment.webinarLink1.link != null) ||
          (pendingCounterSignInvestment.investment.webinarLink2 != null &&
            pendingCounterSignInvestment.investment.webinarLink2.link != null)) && <span
              title="Webinar"
              onClick={() => { webinarPopup(pendingCounterSignInvestment.investmentprocesses) }}
              style={{ marginLeft: '10px' }}
            >
            <img
              src={path.white_webinar_link}
              alt=""
              style={{ width: "40px" }}
            />
          </span>}
        {/* <div className='col-md-3'>
          <span className='first-icon-span' onClick={() => { iconRedirect(pendingCounterSignInvestment, 'IIR') }}  >
            <img style={{ 'width': '24px' }} src={images.path.iirDocIcon} alt="" />
            <span className='icon-txt-sp'>IIR</span>
          </span>
        </div>
        <div className='col-md-6'>
          <span className='mid-icon-span' onClick={() => { iconRedirect(pendingCounterSignInvestment, 'EL') }}  >
            <img style={{ 'width': '20px' }} src={images.path.googleDocs} alt="" />
            <span className='icon-txt-sp'>Executive Letter</span>
          </span>
        </div>
        <div className='col-md-3'>
          <span className='end-icon-span' onClick={() => { iconRedirect(pendingCounterSignInvestment, 'FAQ') }}  >
            <img style={{ 'width': '16px' }} src={images.path.question} alt="" />
            <span className='icon-txt-sp'>FAQ</span>
          </span>
        </div> */}
        {/* <div className="col-md-6" style={{ textAlign: "left", display: "flex" }}>
          <span
            title="IIR"
            className="view-span-icon-iir"
            onClick={() => { iconRedirect(pendingCounterSignInvestment, 'IIR') }}
          >
            <img
              style={{ width: "20px" }}
              src={images.path.iirDocIcon}
              alt=""
            />
          </span>
          <span
            title="Executive Letter"
            className="view-span-icon-ex"
            onClick={() => {
              iconRedirect(pendingCounterSignInvestment, 'EL')
            }}
          >
            <img
              style={{ width: "18px" }}
              src={images.path.googleDocs}
              alt=""
            />
          </span>
          <span
            title="FAQs"
            className="view-span-icon-faq"
            onClick={() => {
              iconRedirect(pendingCounterSignInvestment, 'FAQ')
            }}
          >
            <img
              style={{ width: "16px" }}
              src={images.path.question}
              alt=""
            />
          </span>
        </div> */}
      </div>
    </>
  );
};

const PendingCounterSigningInvestments = props => {
  const { pendingCounterSign } = props;
  const { isBlackList } = props;
  return (
    <>
      <div className="pending_countersign">
        {
          pendingCounterSign && pendingCounterSign.length > 0 && !isBlackList &&
          <div className="container" style={{ padding: "0px 20px" }}>
            <div className="row d-flex" style={{ paddingLeft: "10px" }}>
              <img className="intial_icon" src={path.openInvitation} />
              <div className="col-md-5">
                <h3 style={{ fontSize: "20px", color: "#383F59" }}>
                  Pending Counter Signing ({pendingCounterSign.length})
                </h3>
              </div>
            </div>
            <div className="row">
              {
                pendingCounterSign.map(pendingCounterSignInvestment => {
                  return <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 col-xxl-3" key={pendingCounterSignInvestment.investment.name.toLowerCase()}>
                    <PendingCounterSigningInvestmentCard
                      pendingCounterSignInvestment={pendingCounterSignInvestment}
                      {...props} />
                  </div>

                })
              }
            </div>
          </div>
        }
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    pendingCounterSign: state.investorDashboardData.pendingCounterSign.length > 0 ? state.investorDashboardData.pendingCounterSign : [],
  };
};

export default connect(mapStateToProps, null)(PendingCounterSigningInvestments);

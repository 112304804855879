import React from 'react';
import PropTypes from 'prop-types';

const ChecklistModal = (props) => {
  return (
    <div className="modal fade" id="addChecklistModal" tabIndex="-1" role="dialog" aria-labelledby="addChecklistModal" aria-hidden="true" key={props.addChecklistModalkey}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">Add checklist Option</h5>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <input className="form-control" type="text" name="remember" placeholder="Option Name" onChange={props.onTitleChange} />
            </div>
            <div className="form-group">
              <p>Requires Document Upload</p>
              <label className="switch">
                <input type="checkbox" onChange={props.onIsDocRequiredChange} />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="form-group">
              <p>Multiple Document Upload</p>
              <label className="switch">
              {props.checklistFormData.isDocNeeded ?
                <input type="checkbox"  onChange={props.onIsMultiDocUploadChange} checked={props.checklistFormData.isMultipleDocUploadAllowed && props.checklistFormData.isDocNeeded} /> : 
                <input type="checkbox" style={{cursor:'not-allowed'}} disabled onChange={props.onIsMultiDocUploadChange} checked={props.checklistFormData.isMultipleDocUploadAllowed && props.checklistFormData.isDocNeeded} /> 
                }
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn cancel-btn" data-dismiss="modal">Cancel</button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={props.onChecklistFormSubmit}
              disabled={props.isChecklistFormValid()}
              data-dismiss="modal"
            >
              Save
              </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ChecklistModal.propTypes = {
  onTitleChange: PropTypes.func.isRequired,
  onIsDocRequiredChange: PropTypes.func.isRequired,
  onIsMultiDocUploadChange: PropTypes.func.isRequired,
  onChecklistFormSubmit: PropTypes.func.isRequired,
  isChecklistFormValid: PropTypes.func.isRequired,
};

export default ChecklistModal;
import React, { Component } from 'react';
import './NdaPdf.scss';
// Imported Components
import HeaderImage from '../../components/HeaderImage';
import LoadingSpinner from '../../components/Loader';
import Header from '../../components/Header';
// import TButton from '../../components/TButton';
import axios from 'axios';
import swal from 'sweetalert';
import common from '../../common';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
// import FileUploadBlobDoc from '../../components/FileUploadBlobDoc';
import _ from 'lodash';
// Imported Images
import images from "../../images";
let userRole = '000';
class NdaPdf extends Component {
    constructor(props) {
        super(props);
        this.companyId = '';
        this.investmentId = '';
        this.pdfUrl = '';
        this.state = {
            fileName: '',
            status: 0,
            pdfFile: null,
            loading: false,
            choosenFile: null,
            loadinglada: false,
            loadinglada1: false,
            userId: null,
            loadingPdf: false,
            preferredName: '',
            preferredNameValidation: '',
            mailSentMessage: '',
            checklistData: [],
        }
    }
    componentWillMount() {
        const url = new URL(window.location.href);
        this.companyId = url.searchParams.get('companyId');
        this.investmentId = url.searchParams.get('investmentId');
        userRole = window.localStorage.getItem('role');
        this.userRoleCode = window.localStorage.getItem('roleCode');
        // this.getPdf();
        this.getData();
        this.setState({
            name: window.localStorage.getItem('username'),
            userId: window.localStorage.getItem('id')
        });
        this.setState({ Role: userRole })
    }
    getPdf() {
        let temp = this;
        temp.setState({ loading: true });
        let token = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/getNdaPdf/${this.companyId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                const ndaPdfData = response.data;
                this.pdfUrl = ndaPdfData.data.pdfUrl;
                temp.setState({ mailSentMessage: ndaPdfData.message, status: ndaPdfData.data.status })
            })
            .catch(function (error) {
                temp.setState({ loading: false });
            });
    }
    getData() {
        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loading: true });
        const id = window.localStorage.getItem('id');
        this.setState({ id: id })
        axios.get(`${common.path.base_url}/getInvestmentCheckList?investmentId=${this.investmentId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                this.setState({
                    checklistData: response.data.data
                });
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }
    sendMailToSignPdf() {
        let temp = this;
        temp.setState({ loadinglada1: true });
        let token = window.localStorage.getItem('token');
        axios.post(`${common.path.base_url}/sendNDAESign`, {
            companyId: temp.companyId,
            preferredTerm: temp.state.preferredName,
        }, {
            headers: {
                "Authorization": token
            }
        })
            .then(function (response) {
                temp.setState({ loadinglada1: false });
                swal({
                    title: "Success!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(function () {
                        temp.setState({ preferredName: '' });
                        temp.getPdf();
                    });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ preferredName: '' });
                temp.setState({ loadinglada1: false });
            });
    }
    resendMailToSignPdf() {
        let temp = this;
        temp.setState({ loadinglada2: true });
        let token = window.localStorage.getItem('token');
        axios.post(`${common.path.base_url}/resentNDA`, {
            companyId: temp.companyId,
        }, {
            headers: {
                "Authorization": token
            }
        })
            .then(function (response) {
                temp.setState({ loadinglada2: false });
                swal({
                    title: "Success!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(function () {
                        temp.getPdf();
                    });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada2: false });
            });
    }
    // uploadPdfFile(file) {
    //     if (file.size) {
    //         const temp = this;
    //         temp.setState({ loadingPdf: true })
    //         let tokenpass = window.localStorage.getItem('token');
    //         let data = new FormData();
    //         data.append('file', file);
    //         const isPrivate = true;
    //         data.append('isPrivate', isPrivate)
    //         axios.post(`${common.path.base_url}/uploadFile`, data, {
    //             headers: {
    //                 "Authorization": tokenpass
    //             }
    //         })
    //             .then((response) => {
    //                 temp.setState({ picture: response.data.data.id });
    //                 axios.post(`${common.path.base_url}/uploadNdaPdf`, {
    //                     fileId: this.state.picture,
    //                     companyId: this.companyId
    //                 }, {
    //                     headers: {
    //                         "Authorization": tokenpass
    //                     }
    //                 })
    //                     .then((response) => {
    //                         const ndaPdfData = response.data.data;
    //                         this.newStr = ndaPdfData.filePath.replace('././public', '');
    //                         temp.setState({
    //                             loadingPdf: false,
    //                             fileId: ndaPdfData._id,
    //                             fileName: ndaPdfData.fileName,
    //                         })
    //                     })
    //                     .catch((error) => {
    //                         temp.setState({ loadingPdf: false });
    //                         if (error.response) {
    //                             swal({
    //                                 title: "Error!",
    //                                 text: error.response.data.message,
    //                                 icon: "error",
    //                                 button: "Ok!",
    //                             });
    //                         }
    //                         else {
    //                             swal({
    //                                 title: "Network Error!",
    //                                 text: "Something went wrong",
    //                                 icon: "error",
    //                                 button: "Ok!",
    //                             });
    //                         }
    //                     });
    //             })
    //             .catch((error) => {
    //             });
    //     }
    // }
    handleChange(name, value) {
        this.setState({ [name]: value })
    }
    formValidaton(name, value) {
        if (name === 'preferredName') {
            if (value === '') {
                this.setState({
                    preferredNameValidation: 'Preferred Term should not be empty'
                })
            }
            else {
                this.setState({
                    preferredNameValidation: ''
                })
            }
        }
    }

    getNdaPdfDataFromCheckList() {
        const ndaPdfDataFromChecklist = _.filter(this.state.checklistData, (checklistData) => { return checklistData.title.toLowerCase().includes('mnda') })[0];
        // const ndaPdfDataFromChecklist = _.find(this.state.checklistData, (checklistData) => { return checklistData.order === 9});
        console.log('ndaPdfDataFromChecklist', ndaPdfDataFromChecklist)
        return ndaPdfDataFromChecklist;
    }

    render() {
        const ndaPdfData = this.getNdaPdfDataFromCheckList();
        return (
            <div>
                {this.state.loading ? <LoadingSpinner /> : ""}
                <Header
                    username={this.state.name}
                    role={this.state.Role}
                />
                <HeaderImage
                    heading={this.state.Role}
                />
                <div className='ndaPdf'>
                    <div className="ndaPdf-main">
                        <div className='heading'>
                            <h4>NDA PDF</h4>
                        </div>
                        <div className="ndaPdf-content">
                            <div className="ndaPdf-left" style={{ display: this.userRoleCode === '003' ? 'none' : '' }}>
                                <form className="form-horizontal">
                                    <div className="form-group">
                                        <label className="col-md-3 control-label">Preferred Term:</label>
                                        <div className="col-md-8">
                                            <input className="form-control" name="preferredName" type="text" placeholder='Preferred Term'
                                                onKeyUp={(event) => this.formValidaton(event.target.name, event.target.value)}
                                                onChange={(event) => this.handleChange(event.target.name, event.target.value)}
                                                value={this.state.preferredName} />
                                            <p>{this.state.preferredNameValidation}</p>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-3 control-label btn-label"></label>
                                        <div className="col-md-8 updateButton">
                                            <LaddaButton
                                                id="update-btn"
                                                loading={this.state.loadinglada1}
                                                onClick={() => this.sendMailToSignPdf()}
                                                data-color="#eee"
                                                data-size={XS}
                                                data-style={EXPAND_RIGHT}
                                                data-spinner-size={30}
                                                data-spinner-color="#ddd"
                                                data-spinner-lines={12}
                                                className={_.isEmpty(this.state.preferredName) ? 'disable' : ''}
                                            >
                                                SEND
                                            </LaddaButton>
                                            {this.state.status === 1 ? '' : <LaddaButton
                                                id="update-btn"
                                                loading={this.state.loadinglada2}
                                                onClick={() => this.resendMailToSignPdf()}
                                                data-color="#eee"
                                                data-size={XS}
                                                data-style={EXPAND_RIGHT}
                                                data-spinner-size={30}
                                                data-spinner-color="#ddd"
                                                data-spinner-lines={12}
                                                className={this.state.status === 0 ? 'disable' : ''}
                                            >
                                                REMIND
                                            </LaddaButton>}
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className='ndaPdf-right' style={{ width: this.userRoleCode === '003' ? '100%' : '' }}>
                                <div className='embed-responsive embed-responsive-16by9'>
                                    {
                                        ndaPdfData !== undefined ? ndaPdfData.status ?
                                            <iframe
                                                className="embed-responsive-item"
                                                src={`${common.path.base_url}/getFileNew?fileId=${ndaPdfData.docId._id}`}
                                                title="Nda Pdf"
                                                allowFullScreen
                                            >
                                            </iframe>
                                            : <div className='sample-pdf'>
                                                <p>{this.state.mailSentMessage}</p>
                                                <img className='pdf-img' src={`${images.path.pdf_file}`} alt='pdf' />
                                                {/* <img class='pdf-img' src={`${images.path.pdf_file}`} alt='pdf' /> */}
                                            </div>
                                            : ''
                                    }
                                </div>

                                <div className="ndaPdf-footer">
                                    {ndaPdfData && ndaPdfData.status && ndaPdfData.docId ?
                                        <button className='download'>

                                            <a target="_blank" className="file-link" href={`${common.path.base_url}/getFileNew?fileId=${ndaPdfData.docId._id}`} download> <img src={images.path.addIcon} alt="" />DOWNLOAD</a>
                                        </button>
                                        : "Not Uploaded Yet !"
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default NdaPdf;

const initialState = {
  invitations: [],
  pendingCounterSign:[],
  notParticipation: [],
  myInvestments: [],
  exitedInvestments: [],
  exitInProcessInvestments: [],
  pipelineInvestments: [],
  recommendations: [],
  investorUserData: null,
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_NEW_INVITATIONS_DATA':
      return { ...state, invitations: action.payload };
    case 'SET_PENDING_COUNTER_SIGN_DATA':
      return { ...state, pendingCounterSign: action.payload };
    case 'SET_NOT_PARTICIPATION_DATA':
      return { ...state, notParticipation: action.payload };
    case 'SET_MY_INVESTMENTS_DATA':
      return { ...state, myInvestments: action.payload };
    case 'SET_EXIT_IN_PROCESS_INVESTMENTS_DATA':
      return { ...state, exitInProcessInvestments: action.payload };
    case 'SET_EXITED_INVESTMENTS_DATA':
      return { ...state, exitedInvestments: action.payload };
    case 'SET_PIPELINE_INVESTMENTS_DATA':
      return { ...state, pipelineInvestments: action.payload };
    case 'SET_RECOMMENDATION_DATA':
      return { ...state, recommendations: action.payload };
    case 'SET_USER_DATA':
      return { ...state, investorUserData: action.payload };
    default:
      return state;
  }
}

export default reducer
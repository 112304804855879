import React, { Component } from 'react';
import './YearReview.scss';
import axios from 'axios';
import HeaderImg from '../../components/HeaderImage';
// import swal from 'sweetalert';
import LoadingSpinner from '../../components/Loader';
import TButton from '../../components/TButton';
import common from '../../common';
import { withRouter } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
// import MetaTags from 'react-meta-tags';
import Header from '../../components/Header';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import IframePdfElement from "./IframePdfElement";

// import images from "../../images";
// import {Helmet} from "react-helmet";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


class YearReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            numPages: null,
            setNumPages: [],
            pageNumber: 1,
            setPageNumber: 1,
            userData: null,
            yearReviewFileId: null,

        }
        this.screenWidth = null;
        this.isUserLoggedIn = false;

    }
    onDocumentLoadSuccess(pdf) {
        const setNumPages = [];
        if (pdf._pdfInfo.numPages > 0) {

            for (let i = 0; i < pdf._pdfInfo.numPages; i++) {
                setNumPages.push(i + 1);
            }
        }
        this.setState({ numPages: pdf._pdfInfo.numPages, setNumPages });

    }

    componentWillMount() {
        if (window.localStorage.getItem('token') && window.localStorage.getItem('id')) {
            this.isUserLoggedIn = true;
        }
        this.screenWidth = `${window.screen.width}`;
        if (window.screen.width > 1920) {
            this.screenWidth = '1650';
        }
        else if (window.screen.width > 1650) {
            this.screenWidth = '1000';
        }
        else if (window.screen.width > 1280) {
            this.screenWidth = '600';
        }
        else if (window.screen.width > 1200 && window.screen.width > 1025) {
            this.screenWidth = '500';
        } 
        else if (window.screen.width < 768 && window.screen.width > 375) {
            this.screenWidth = '400';
        } else {
            this.screenWidth = '400';
        }
        // this.yearReviewFileId = process.env.REACT_APP_YEAR_REVIEW_FILE_ID;
        this.getYearInReviewPDFID()
        const url = new URL(window.location.href);
        this.id = url.searchParams.get('id');
        this.setState({ loading: true });
        if (this.id) {
            let temp = this;
            let token = window.localStorage.getItem('token');
            axios.get(`${common.path.base_url}/checkUserStatus?_id=${this.id}`, {
                headers: {
                    "Authorization": token
                }
            })
                .then((response) => {
                    temp.setState({ loading: false });
                    if (response && response.data && response.data.data && response.data.data) {
                        temp.setState({ userData: response.data.data })
                    }
                })
                .catch(function (error) {
                    temp.setState({ loading: false });
                });

        } else {
            this.setState({ loading: false });
        }
        if (this.isUserLoggedIn) {
            const token = window.localStorage.getItem('token')
            axios.put(`${common.path.base_url}/updateYearInReviewStatus`, {}, {
                headers: {
                    "Authorization": token
                }
            })
                .then((response) => {
                })
                .catch(function (error) {
                });
        }
    }
    getYearInReviewPDFID() {
        let temp = this;
        temp.setState({ loading: true })
        let token = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/getYearInReviewPDF`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                if (response && response.data) {
                    temp.setState({ yearReviewFileId: response.data.data })
                }

            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error && error.response && error.response.data && error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
            });
    }
    redirect() {
        if (this.id) {
            const user = this.state.userData;
            if (user && user.isUserExist) {
                if (user.isInvitationAccepeted) {
                    this.props.history.push('/');
                } else if (user.token) {
                    this.props.history.push(`acceptInvitation?token=${user.token}`);
                } else {
                    this.props.history.push('/');
                }
            } else {
                this.props.history.push('/become-investor');
            }
        } else {
            this.props.history.push('/become-investor');
        }
    }

    render() {
        return (
            <div className="preview">
                {/* <MetaTags>
                        <title>Year In Review 2020</title>
                        <meta property="og:title" content="The Syndicate Group" />
                        <meta name="description" content="2020 was a year many would like to soon forget, but the reality is it will always be remembered. While our collective TSG heart breaks for those who have endured the worst of what 2020 has brought, we stand tall and hold our heads high as we look back on the incredible resilience of the human spirit which pulled us all through. It is our pleasure to share with you some highlights of TSG’s 2020." />
                        <meta property="og:image" content='https://dev.syndicategroup.com/year-in-review-meta.png'/>
                    </MetaTags> */}
                {/* <Helmet>
                        <meta charSet="utf-8" />
                        <title>Year In Review 2020</title>
                        <meta name="description" content="2020 was a year many would like to soon forget, but the reality is it will always be remembered. While our collective TSG heart breaks for those who have endured the worst of what 2020 has brought, we stand tall and hold our heads high as we look back on the incredible resilience of the human spirit which pulled us all through. It is our pleasure to share with you some highlights of TSG’s 2020." />
                        <link rel="canonical" href='https://dev.syndicategroup.com/year-in-review-meta.png' />
                    </Helmet> */}
                {
                    this.state.loading ? <LoadingSpinner /> :
                        <>
                            {
                                this.isUserLoggedIn &&
                                <Header
                                    username={window.localStorage.getItem('username')}
                                    role={window.localStorage.getItem('role')}
                                />
                            }
                            <HeaderImg heading={`TSG Review`} />

                            <div className="pdf-preview-main" ref={elements => this.componentRef = elements}>

                                {
                                    this.isUserLoggedIn &&
                                    <div className="close-btn-wraper">
                                        <button type="button" className="close" aria-label="Close" onClick={() => this.props.history.push('/investor-dashboard')}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                }
                                <div className="preview-main">
                                    {
                                        this.id || this.isUserLoggedIn ?
                                            // <div className="year-in-review-join-public">
                                            //     <TButton onClick={() => { this.redirect() }} text='TSG PLATFORM' className='contact-btn' />
                                            // </div> 
                                            ""
                                            :
                                            <div className="year-in-review-join-public">
                                                {/* <TButton className="contact-btn tsg-platform" onClick={() => { this.props.history.push('/') }} text='TSG PLATFORM' /> */}
                                                <TButton onClick={() => { this.redirect() }} text='BECOME AN INVESTOR' className='contact-btn become-investor' />
                                            </div>


                                    }

                                    {
                                        this.state.yearReviewFileId && 
                                        <div className="yearinreview-pdf-container" style={{ margin: '0px' }}> 
                                            <IframePdfElement pdfUrl={`${common.path.base_url}/getDocument?fileId=${this.state.yearReviewFileId}`} />
                                        </div>
                                    }
                                    {
                                        this.id || this.isUserLoggedIn ?
                                            // <div className="year-in-review-join-public">
                                            //     <TButton onClick={() => { this.redirect() }} text='TSG PLATFORM' className='contact-btn' />
                                            // </div> 
                                            ""
                                            :
                                            <div className="year-in-review-join-public">
                                                {/* <TButton className="contact-btn tsg-platform" onClick={() => { this.props.history.push('/') }} text='TSG PLATFORM' /> */}
                                                <TButton onClick={() => { this.redirect() }} text='BECOME AN INVESTOR' className='contact-btn become-investor' />
                                            </div>


                                    }





                                </div>
                            </div>
                        </>
                }
            </div>
        );
    }
}
export default withRouter(YearReview);
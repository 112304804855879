import React, { Component } from 'react';
import swal from 'sweetalert';

import './ClientOnboardChecklist.scss';
import HeaderImage from '../../components/HeaderImage';
import LoadingSpinner from '../../components/Loader';
import Header from '../../components/Header';
import TButton from '../../components/TButton';
import FileUploadBlobDoc from '../../components/FileUploadBlobDoc';
import axios from 'axios';
import common from '../../common';
import images from '../../images';
import ClientOnboardChecklistV2 from './components/ClientOnBoardChecklistv2';

let userRole = '000';
let userRoleCode = '000';

class ClientOnboardChecklist extends Component {
    constructor(props) {
        super(props);
        this.companyId = '';
        this.investmentId = '';
        this.type = '';
        this.state = {
            name: '',
            Role: '',
            loading: false,
            uploadFileId: '',
            checklistData: [],
            chklistId: '',
            chklistIndex: '',
            status: false,
            headerShow: 'block',
            choosenFile: null,
            loadinglada: false,
            userId: null,
            chklistErrorMsg: '',
            selectedChklist: '',
            show: false,
            isGlobalCheckListApplicable: false,
            investmentName: ''
        }
    }
    componentWillMount() {
        const url = new URL(window.location.href);
        this.companyId = url.searchParams.get('companyId');
        this.investmentId = url.searchParams.get('investmentId');
        this.type = url.searchParams.get('type');
        userRole = window.localStorage.getItem('role');
        userRoleCode = window.localStorage.getItem('roleCode');
        this.getData();
        this.setState({
            name: window.localStorage.getItem('username'),
            userId: window.localStorage.getItem('id')
        });
        this.setState({ Role: userRole })
    }
    getData() {
        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loading: true });
        const id = window.localStorage.getItem('id');
        this.setState({ id: id });
        axios.get(`${common.path.base_url}/investmentById/${this.investmentId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((res) => {
                this.setState({ investmentName: res.data.data.name });
                if ((res.data.data && res.data.data.isGlobalCheckListApplicable) || (this.type === 'Close' || this.type === 'Exit')) {
                    let endPoint = `getMasterInvestmentCheckList/${this.investmentId}`;
                    if (this.type) {
                        endPoint = `getMasterInvestmentCheckList/${this.investmentId}?type=${this.type}`;
                    }
                    if (this.type === 'Exit' && res.data.data.exitProcessType === 'ipo') {
                        endPoint = `getMasterInvestmentCheckList/${this.investmentId}?type=${this.type}&subType=ipo`;
                    }
                    if (this.type === 'Exit' && res.data.data.exitProcessType === 'acquisition') {
                        endPoint = `getMasterInvestmentCheckList/${this.investmentId}?type=${this.type}&subType=acquisition`;
                    }
                    axios.get(`${common.path.base_url}/${endPoint}`, {
                        headers: {
                            "Authorization": token
                        }
                    })
                        .then((response) => {
                            temp.setState({ loading: false });
                            this.setState({
                                checklistData: response.data.data,
                                isGlobalCheckListApplicable: true,
                            });

                        })
                        .catch(function (error) {
                            temp.setState({ loading: false });
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else if (error.response) {
                                swal({
                                    title: "Error!",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });
                } else {

                    axios.get(`${common.path.base_url}/getInvestmentCheckList?investmentId=${this.investmentId}`, {
                        headers: {
                            "Authorization": token
                        }
                    })
                        .then((response) => {
                            temp.setState({ loading: false });
                            this.setState({
                                checklistData: response.data.data
                            });

                        })
                        .catch(function (error) {
                            temp.setState({ loading: false });
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else if (error.response) {
                                swal({
                                    title: "Error!",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });
                }





            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });


    }
    uploadCSVFile(file) {
        const temp = this;
        this.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        let data = new FormData();
        data.append('file', file);
        const isPrivate = true;
        data.append('isPrivate', isPrivate)
        axios.post(`${common.path.base_url}/uploadFile`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                this.setState({ loading: false });
                this.setState({ uploadFileId: response.data.data.id })
                swal({
                    title: "File Uploaded Successfully!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(function () {
                        temp.setState({ loading: true });
                        let endpoint = 'updateCheckList';
                        let payload = {
                            checkListId: temp.state.chklistId,
                            fileId: temp.state.uploadFileId,
                        };
                        let requestType = 'put';

                        if (temp.state.isGlobalCheckListApplicable || temp.type === 'Closed') {
                            const selectedChecklist = temp.state.checklistData.find(singleChecklist => singleChecklist._id === temp.state.chklistId);
                            endpoint = `updateInvestmentCheckList/${temp.state.chklistId}/${temp.investmentId}`;
                            payload = {
                                status: true,
                                subCheckListId: selectedChecklist.investmentsCheckList && selectedChecklist.investmentsCheckList.subCheckListId ? selectedChecklist.investmentsCheckList.subCheckListId : [],
                                documentId: temp.state.uploadFileId,
                            };
                            if (temp.type) {
                                payload.type = temp.type;
                            }
                            requestType = 'post';
                        }

                        axios[requestType](`${common.path.base_url}/${endpoint}`, payload, {
                            headers: {
                                "Authorization": tokenpass
                            }
                        })
                            .then(function (response) {
                                temp.getData();
                            })
                            .catch(function (error) {
                                temp.setState({ loading: false });
                                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                    window.localStorage.clear();
                                    window.location.href = '/';
                                    return;
                                }
                                else if (error.response) {
                                    swal({
                                        title: "Error!",
                                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                                        icon: "error",
                                        button: "Ok!",
                                    });
                                }
                                else {
                                    swal({
                                        title: "Network Error!",
                                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                                        icon: "error",
                                        button: "Ok!",
                                    });
                                }
                            });
                        temp.setState({ loadinglada: false, choosenFile: null });
                    });
            })
            .catch((error) => {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    updateChecklist(id, fileId) {
        this.setState({ loading: true });
        const temp = this;
        let tokenpass = window.localStorage.getItem('token');
        axios.put(`${common.path.base_url}/updateCheckList`, {
            checkListId: id,
            fileId: fileId
        }, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then(function (response) {
                temp.getData();
                temp.setState({ loading: false });
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
        temp.setState({ loadinglada: false, choosenFile: null });
    }

    updateSubChecklist1(chkListId, chkListName) {
        const temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        axios.put(`${common.path.base_url}/updateCheckList`, {
            checkListId: chkListId,
            radioButton: chkListName
        }, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then(function (response) {
                temp.getData();
                temp.setState({ loading: false });
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
        temp.setState({ loadinglada: false, choosenFile: null });
    }

    updateSubChecklist2(chkListId, chkListName) {
        const temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        axios.put(`${common.path.base_url}/updateCheckList`, {
            checkListId: chkListId,
            radioButton: chkListName
        }, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then(function (response) {
                temp.getData();
                temp.setState({ loading: false });
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
        temp.setState({ loadinglada: false, choosenFile: null });
    }

    chklistErrorMsg(index) {
        this.setState({ selectedChklist: index, chklistErrorMsg: 'Please upload document first' })
    }
    discardDocment(docId, checkListId) {
        const temp = this;
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this document",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                const token = localStorage.getItem('token');
                if (willDelete) {
                    this.setState({ loading: true });
                    let payload = { id: docId, checkListId };
                    if (temp.state.isGlobalCheckListApplicable) {
                        payload.docId = docId;
                    };

                    axios.delete(`${common.path.base_url}/removeCheckListFile`, { headers: { "Authorization": token }, data: payload }
                    )
                        //@TODO CALL ON SUCCESS OF API
                        .then((response) => {
                            this.setState({ loading: false });
                            swal({
                                title: "Success!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            })
                                .then(() => {
                                    temp.getData();
                                    temp.setState({ loading: false });
                                });

                        })
                        .catch((error) => {
                            this.setState({ loading: false });
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else {
                                swal({
                                    title: "Error!",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });
                }
                else {
                    swal({ text: `Document is safe!`, icon: 'info' });
                }
            });
    }

    /* Client On Board Checklist v2 start */
    checkForChecklistErr(index) {
        this.setState({ selectedChklist: index, chklistErrorMsg: 'Please upload document first' })
    }

    isFileUploadButtonDisabled = (singleChecklist) => {
        const subCheckListLength = singleChecklist.subCheckLists && singleChecklist.subCheckLists.length;
        const selectedSubChecklistLength = singleChecklist.investmentsCheckList
            && singleChecklist.investmentsCheckList
            && singleChecklist.investmentsCheckList.subCheckListId
            && singleChecklist.investmentsCheckList.subCheckListId.length;
        if (singleChecklist && singleChecklist.subCheckLists && singleChecklist.subCheckLists.length === 0) {
            return false;
        } else if (singleChecklist && singleChecklist.subCheckLists && (subCheckListLength !== selectedSubChecklistLength)) {
            return true;
        } else {
            return false;
        }
    };

    onFileUploadButtonClick = (elementId, checklistIndex) => {
        document.querySelector(`#upload-${elementId}-${checklistIndex}`).click();
        this.setState({ chklistErrorMsg: '' });
    };

    getUploadedFile = (elementId, checklistIndex, file) => {
        this.setState({ chklistId: elementId, chklistIndex: checklistIndex, choosenFile: file }, () => this.uploadCSVFile(this.state.choosenFile));
    };


    updateChecklistV2 = (payload, checklistIndex, masterCheckListId, selectedChecklist) => {
        if (payload && checklistIndex > -1 && masterCheckListId && selectedChecklist) {
            const temp = this;
            temp.setState({ loading: true });
            let tokenpass = window.localStorage.getItem('token');
            if (this.type) {
                payload.type = this.type;
            }

            axios.post(`${common.path.base_url}/updateInvestmentCheckList/${masterCheckListId}/${this.investmentId}`, payload, {
                headers: {
                    "Authorization": tokenpass
                }
            })
                .then(function (response) {
                    temp.getData();
                    temp.setState({ loading: false });
                })
                .catch(function (error) {
                    temp.setState({ loading: false });
                    if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        window.localStorage.clear();
                        window.location.href = '/';
                        return;
                    }
                    else if (error.response) {
                        swal({
                            title: "Error!",
                            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                    else {
                        swal({
                            title: "Network Error!",
                            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                });
        }
    };

    getSelectedChecklist = (checklistIndex) => {
        if (checklistIndex > -1) {
            return this.state.checklistData[checklistIndex];
        }
    };

    getUpdateChecklistPayload = (event, selectedChecklist) => {
        if (event && selectedChecklist) {
            return {
                status: event.target.checked,
                subCheckListId: selectedChecklist.investmentsCheckList && selectedChecklist.investmentsCheckList.subCheckListId ? selectedChecklist.investmentsCheckList.subCheckListId : [],
                documentId: '',
            };
        }
    };

    onUpdateChecklist = (event, checklistIndex, masterCheckListId) => {
        if (masterCheckListId) {
            const selectedChecklist = this.getSelectedChecklist(checklistIndex);
            const payload = this.getUpdateChecklistPayload(event, selectedChecklist);
            if (selectedChecklist.isDocNeeded) {
                this.checkForChecklistErr(checklistIndex);
            } else {
                this.updateChecklistV2(payload, checklistIndex, masterCheckListId, selectedChecklist);
            }
        }
    };

    getSelectedSubChecklist = (selectedChecklist, subChecklistIndex) => {
        if (selectedChecklist && subChecklistIndex > -1) {
            return selectedChecklist.subCheckLists[subChecklistIndex]._id;
        }
    };

    pushSelectedSubChecklistId = (selectedSubChecklist, selectedChecklist) => {
        if (selectedSubChecklist && selectedChecklist) {
            if (selectedChecklist.investmentsCheckList
                && selectedChecklist.investmentsCheckList.subCheckListId
                && selectedChecklist.investmentsCheckList.subCheckListId.length > 0) {
                const selectedSubChecklistArray = selectedChecklist.investmentsCheckList.subCheckListId;
                selectedSubChecklistArray.push(selectedSubChecklist);
                return selectedSubChecklistArray
            }
            return [selectedSubChecklist];
        }
    };

    removeSelectedSubChecklistId = (selectedSubChecklist, selectedChecklist) => {
        if (selectedSubChecklist && selectedChecklist) {
            if (selectedChecklist.investmentsCheckList
                && selectedChecklist.investmentsCheckList.subCheckListId
                && selectedChecklist.investmentsCheckList.subCheckListId.length > 0) {
                const selectedSubChecklistArray = selectedChecklist.investmentsCheckList.subCheckListId;
                const arrToBeRemoved = selectedSubChecklistArray.indexOf(selectedSubChecklist)
                selectedSubChecklistArray.splice(arrToBeRemoved, 1);
                return selectedSubChecklistArray
            }
            return [];
        }
    };

    getSelectedSubChecklistArray = (event, selectedSubChecklist, selectedChecklist) => {
        if (event.target.checked) {
            return this.pushSelectedSubChecklistId(selectedSubChecklist, selectedChecklist);
        }
        return this.removeSelectedSubChecklistId(selectedSubChecklist, selectedChecklist)
    };

    onUpdateSubChecklist = (event, checklistIndex, subChecklistIndex, masterCheckListId) => {
        if (checklistIndex > -1 && subChecklistIndex > -1 && masterCheckListId) {
            const selectedChecklist = this.getSelectedChecklist(checklistIndex);
            const selectedSubChecklist = this.getSelectedSubChecklist(selectedChecklist, subChecklistIndex);
            const selectedSubChecklistArray = this.getSelectedSubChecklistArray(event, selectedSubChecklist, selectedChecklist);
            const payload = {
                status: selectedChecklist.investmentsCheckList && selectedChecklist.investmentsCheckList.status ? selectedChecklist.investmentsCheckList.status : false,
                subCheckListId: selectedSubChecklistArray,
                documentId: ''
            };
            this.updateChecklistV2(payload, checklistIndex, masterCheckListId, selectedChecklist);
        }
    };

    isSubChecklistChecked = (singleChecklist, subChecklistId) => {
        if (singleChecklist.investmentsCheckList && singleChecklist.investmentsCheckList.subCheckListId && singleChecklist.investmentsCheckList.subCheckListId.length > 0) {
            return singleChecklist.investmentsCheckList.subCheckListId.indexOf(subChecklistId) > -1 ? true : false;
        }
        return false;
    };


    /* Client On Board Checklist v2 end */

    render() {
        return (
            <div>
                {this.state.loading ? <LoadingSpinner /> : ""}
                <Header
                    username={this.state.name}
                    role={this.state.Role}
                />
                <HeaderImage
                    // heading={this.state.Role}
                    heading={this.state.investmentName}
                />

                <div className='checklist'>
                    <div className="checklist-main">
                        <div className='heading'>
                            {
                                this.type && this.type === 'Close' ?
                                    <h4>Post Close Checklist</h4> :
                                    <h4>Investment Checklist</h4>

                            }

                        </div>
                        {
                            (this.state.isGlobalCheckListApplicable || this.type === 'Close' || this.type === 'Exit')
                                ? <ClientOnboardChecklistV2
                                    checklistData={this.state.checklistData}
                                    isFileUploadButtonDisabled={(singleChecklist) => this.isFileUploadButtonDisabled(singleChecklist)}
                                    onFileUploadButtonClick={(elementId, checklistIndex) => this.onFileUploadButtonClick(elementId, checklistIndex)}
                                    getUploadedFile={(elementId, checklistIndex, file) => this.getUploadedFile(elementId, checklistIndex, file)}
                                    onUpdateChecklist={(event, checklistIndex, masterCheckListId) => this.onUpdateChecklist(event, checklistIndex, masterCheckListId)}
                                    onUpdateSubChecklist={(event, checklistIndex, subChecklistIndex, masterCheckListId) => this.onUpdateSubChecklist(event, checklistIndex, subChecklistIndex, masterCheckListId)}
                                    selectedChklist={this.state.selectedChklist}
                                    chklistErrorMsg={this.state.chklistErrorMsg}
                                    isSubChecklistChecked={(singleChecklist, subChecklistId) => this.isSubChecklistChecked(singleChecklist, subChecklistId)}
                                    discardDocment={(docId, checkListId) => this.discardDocment(docId, checkListId)}
                                />
                                : <div className='checklist-details'>
                                    {
                                        this.state.checklistData.map((element, index) => {
                                            return (
                                                <div className='checklist-options'>
                                                    <div className='checklist-option checkbox checkbox-warning checkbox-circle' >
                                                        <input type="checkbox" id={`chkWarning${index}`} onChange={element.docNeeded && element.title.toLowerCase() !== 'notify lawyers of closing' ? () => this.chklistErrorMsg(index) : (e) => this.updateChecklist(element._id, null)} checked={element.status} />
                                                        <label for={`chkWarning${index}`}>{element.title}</label>
                                                        {this.state.selectedChklist === index ? <p>{this.state.chklistErrorMsg}</p> : ''}
                                                        {element.subTitle ? element.title === 'Certificate of Formation' ? <div className="sub-checklist">
                                                            <input type='checkbox' id={`chkWarning29`} onChange={() => this.updateSubChecklist1(element._id, 'prepped')} checked={element.prepped} />
                                                            <label for={`chkWarning29`}>Prepped</label>
                                                            <input type='checkbox' id={`chkWarning28`} onChange={() => this.updateSubChecklist2(element._id, 'accepted')} checked={element.accepted} />
                                                            <label for={`chkWarning28`}>Accepted</label>
                                                        </div> :
                                                            element.title === 'LLC 5' ?
                                                                <div className="sub-checklist">
                                                                    <input type='checkbox' id={`chkWarning30`} onChange={() => this.updateSubChecklist1(element._id, 'submitted')} checked={element.submitted} />
                                                                    <label for={`chkWarning30`}>Submitted</label>
                                                                    <input type='checkbox' id={`chkWarning31`} onChange={() => this.updateSubChecklist2(element._id, 'accepted')} checked={element.accepted} />
                                                                    <label for={`chkWarning31`}>Accepted</label>
                                                                </div> : element.title === 'Form D' ?
                                                                    <div className="sub-checklist">
                                                                        <input type='checkbox' id={`chkWarning32`} onChange={() => this.updateSubChecklist1(element._id, 'submitted')} checked={element.submitted} />
                                                                        <label for={`chkWarning32`}>Submitted</label>
                                                                        <input type='checkbox' id={`chkWarning33`} onChange={() => this.updateSubChecklist2(element._id, 'accepted')} checked={element.accepted} />
                                                                        <label for={`chkWarning33`}>Accepted</label>
                                                                    </div> : '' : ''}
                                                    </div>

                                                    {
                                                        element.docNeeded && element.title.toLowerCase() !== 'notify lawyers of closing'
                                                            ? <div className="upload-block">
                                                                <div style={{ "marginRight": "40px" }}>
                                                                    <div className="pdf-section1">
                                                                        <div className="pdf-section">
                                                                            {
                                                                                element.docId && <a
                                                                                    className="file-link"
                                                                                    target="_blank"
                                                                                    href={`${common.path.base_url}/getFileNew?fileId=${element.docId !== undefined ? element.docId._id : ''}`} download>
                                                                                    {element.docId !== undefined ? element.docId.fileName : ''}
                                                                                </a>
                                                                            }
                                                                            {
                                                                                element.docId &&
                                                                                <a onClick={() => this.discardDocment(element.docId._id, element._id)}>
                                                                                    <img src={images.path.deleteIcon} />
                                                                                </a>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    {
                                                                        element.documents && element.documents.length > 0 &&
                                                                        element.documents.map(doc => {
                                                                            return (
                                                                                <div className="pdf-section">
                                                                                    <a className="file-link" target="_blank" href={`${common.path.base_url}/getFileNew?fileId=${doc._id !== undefined ? doc._id : ''}`} download>{doc._id !== undefined ? doc.fileName : ''}</a>
                                                                                    <a onClick={() => this.discardDocment(doc._id, element._id)}> <img src={images.path.deleteIcon} /></a>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                <FileUploadBlobDoc
                                                                    id={`chooseFile${index + 1}`}
                                                                    getFile={(file) => { this.setState({ chklistId: element._id, chklistIndex: index + 1, choosenFile: file }); this.uploadCSVFile(this.state.choosenFile) }}
                                                                    acceptType={``}
                                                                />
                                                                {
                                                                    element.subTitle
                                                                        ? <TButton
                                                                            className={`upload-file`}
                                                                            onClick={() => { document.querySelector(`#chooseFile${index + 1}`).click(); this.setState({ chklistErrorMsg: '' }) }}
                                                                            disabled={(element.prepped || element.submitted) && element.accepted ? false : true}
                                                                            text={`UPLOAD`}
                                                                        />
                                                                        : <TButton
                                                                            className={`upload-file`}
                                                                            onClick={() => { document.querySelector(`#chooseFile${index + 1}`).click(); this.setState({ chklistErrorMsg: '' }) }}
                                                                            text={`UPLOAD`}
                                                                        />}
                                                            </div>
                                                            : ''
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                        }
                    </div>
                </div >
            </div>
        )
    }
}

export default ClientOnboardChecklist;
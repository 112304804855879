import React from 'react';
import PropTypes from 'prop-types';

import common from '../../../common';
import images from '../../../images';
import FileUploadBlobDoc from '../../../components/FileUploadBlobDoc';
import TButton from '../../../components/TButton';

const getStatus = element => {
  if (element.investmentsCheckList && element.investmentsCheckList.status) {
    if (element.isDocNeeded) {
      if(element.investmentsCheckList.documents && element.investmentsCheckList.documents.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    return false;
  }
  // return element.investmentsCheckList && element.investmentsCheckList.status ? element.investmentsCheckList.status : false
}
const ClientOnboardChecklistV2 = props => {
  return (
    <div className='checklist-details'>
      {
        props.checklistData && props.checklistData.length > 0 &&
        props.checklistData.map((element, index) => {
          return <div className='checklist-options' key={index}>
            <div className='checklist-option checkbox checkbox-warning checkbox-circle'>
              <input
                type="checkbox"
                id={`chkWarning${index}`}
                onChange={(event) => props.onUpdateChecklist(event, index, element._id)}
                checked={getStatus(element)}
              />
              <label htmlFor={`chkWarning${index}`}>{element.title}</label>
              {props.selectedChklist === index ? <p>{props.chklistErrorMsg}</p> : ''}

              {
                element.subCheckLists && element.subCheckLists.length > 0
                && element.subCheckLists.map((singleSubChecklist, SubChecklistIndex) => {
                  return <div className="sub-checklist" key={`${element._id}-${SubChecklistIndex}`}>
                    <input
                      type='checkbox'
                      id={`${element._id}-${SubChecklistIndex}`}
                      checked={props.isSubChecklistChecked(element, singleSubChecklist._id)}
                      onChange={(event) => props.onUpdateSubChecklist(event, index, SubChecklistIndex, element._id)}
                    />
                    <label htmlFor={`${element._id}-${SubChecklistIndex}`}>{singleSubChecklist.subTitle}</label>
                  </div>
                })
              }
            </div>

            {
              element.isDocNeeded &&
              <div className="upload-block">
                <div>
                  {
                    element.investmentsCheckList && element.investmentsCheckList.documents && element.investmentsCheckList.documents.length > 0 &&
                    element.investmentsCheckList.documents.map((singleDoc) => {
                      return (
                        <div className="pdf-section" key={singleDoc._id}>
                          <a
                            className="file-link"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${common.path.base_url}/getFileNew?fileId=${singleDoc._id ? singleDoc._id : ''}`}
                            download>
                            {singleDoc._id ? singleDoc.fileName : ''}
                          </a>
                          <a onClick={() => props.discardDocment(singleDoc._id, element.investmentsCheckList._id)}>
                            <img src={images.path.deleteIcon} alt="Delete Icon" />
                          </a>
                        </div>
                      )
                    })
                  }
                </div>

                <FileUploadBlobDoc
                  id={`upload-${element._id}-${index}`}
                  getFile={(file) => props.getUploadedFile(element._id, index, file)}
                />
                <TButton
                  className="upload-file"
                  disabled={props.isFileUploadButtonDisabled(element)}
                  text="UPLOAD"
                  onClick={() => props.onFileUploadButtonClick(element._id, index)}
                />
              </div>
            }
          </div>
        })
      }
    </div>
  );
};

ClientOnboardChecklistV2.propTypes = {
  checklistData: PropTypes.array.isRequired,
  isFileUploadButtonDisabled: PropTypes.func.isRequired,
  onFileUploadButtonClick: PropTypes.func.isRequired,
  getUploadedFile: PropTypes.func.isRequired,
  onUpdateChecklist: PropTypes.func.isRequired,
  selectedChklist: PropTypes.any.isRequired,
  chklistErrorMsg: PropTypes.string.isRequired,
  onUpdateSubChecklist: PropTypes.func.isRequired,
  isSubChecklistChecked: PropTypes.func.isRequired,
  discardDocment: PropTypes.func.isRequired,
};

export default ClientOnboardChecklistV2;
import axios from "axios";
import React, { Component } from "react";
import LaddaButton, { EXPAND_RIGHT, S } from "react-ladda";
import swal from "sweetalert";
import common from "../../common";
import { path } from "../../InvestorDashboard/imagesPath";
import RequiredSign from "../RequiredSign";
import NumberFormat from "react-number-format";

class BankAccountVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      investmentAmount1: "0",
      investmentAmount2: "0",
      orgId: "",
      bankAccountId: "",
      loadinglada: false,
      isChecked: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    nextProps.onRef && nextProps.onRef(this);
  }

  resetData() {
    this.setState({
      investmentAmount1: "",
      investmentAmount2: "",
      loadinglada: false,
      isChecked: true,
    });
  }

  submitData() {
    const { investmentAmount1, investmentAmount2 } = this.state;
    let temp = this;
    temp.setState({ loadinglada: true });
    let tokenpass = window.localStorage.getItem("token");
    const data = {
      amount1: investmentAmount1,
      amount2: investmentAmount2,
      orgId: this.props.verivendData.orgId,
      bankAccountId: this.props.id,
    };

    console.log(data);
    axios
      .post(`${common.path.base_url}/verifyBankAccount`, data, {
        headers: {
          Authorization: tokenpass,
        },
      })
      .then((response) => {
        temp.setState({
          investmentAmount1: "",
          investmentAmount2: "",
          loadinglada: false,
          isChecked: false,
        });
        swal({
          title: "Success",
          text: "Your Bank Account has been successfully verified.",
          icon: "success",
          button: "Ok!",
        }).then((response) => {
          document.getElementById("cancelBankVerification").click();
          temp.setState({ loadinglada: false });
          temp.props.onVerifyBankAccountSuccess(response);
        });
      })
      .catch(function (error) {
        temp.setState({ loadinglada: false });
        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
          window.localStorage.clear();
          window.location.href = '/';
          return;
        }
        else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        }
        else {
          swal({
            title: "Network Error!",
            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }

  getActiveCheckedImage() {
    return this.state.isChecked ? path.checkslide : path.uncheckslideBorder;
  }

  handleCheckButton() {
    this.setState({ isChecked: !this.state.isChecked });
  }

  handleInputChange(event, type) {
    this.setState({ [type]: event });
    console.log(this.state[type]);
  }

  render() {
    return (
      <div id="bankAccountVerificationModal" className="modal fade">
        <div className="modal-dialog">
          <div className="modal-content invite-contact">
            <div className="modal-header">
              <h4 className="modal-title">
                Bank Account Verification Confirmation
              </h4>
            </div>
            <div className="modal-body" style={{ paddingBottom: "0" }}>
              {" "}
              Please enter the micro deposit amounts you have recieved in the
              Bank Account.
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-2">
                  <div className="form-group">
                    <label htmlFor="investmentAmount1">
                      Amount 1 <RequiredSign /> :{" "}
                    </label>
                  </div>
                </div>
                <div className="col-sm-12 col-md-8 col-lg-10">
                  <div className="form-group amount-investment">
                    <NumberFormat
                      value={this.state.investmentAmount1}
                      thousandSeparator={true}
                      prefix={'$'}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      onValueChange={(e) => this.handleInputChange(e.value, 'investmentAmount1')}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-2">
                  <div className="form-group">
                    <label htmlFor="investmentAmount2">
                      Amount 2 <RequiredSign /> :{" "}
                    </label>
                  </div>
                </div>
                <div className="col-sm-12 col-md-8 col-lg-10">
                  <div className="form-group amount-investment">
                    <NumberFormat
                      value={this.state.investmentAmount2}
                      thousandSeparator={true}
                      prefix={'$'}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      onValueChange={(e) => this.handleInputChange(e.value, 'investmentAmount2')}
                    />
                  </div>
                </div>
              </div>
              {/* <div
                className="row"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 10px",
                }}
                onClick={() => this.handleCheckButton()}
              >
                <img
                  style={{ height: "18px", width: "18px" }}
                  src={this.getActiveCheckedImage()}
                  alt=""
                />
                <span style={{ marginLeft: "10px" }}>
                  I agree to the terms and conditions
                </span>
              </div> */}
              <br />
            </div>
            <div className="modal-footer">
              <LaddaButton
                id="lada-btn"
                loading={this.state.loadinglada}
                onClick={() => this.submitData()}
                data-color="#eee"
                data-size={S}
                data-style={EXPAND_RIGHT}
                data-spinner-size={30}
                data-spinner-color="#ddd"
                data-spinner-lines={12}
                className={this.state.isChecked && this.state.investmentAmount1 > 0.00 && this.state.investmentAmount2 > 0.00 ? "" : "disable"}
              >
                VERIFY
              </LaddaButton>
              <input
                type="button"
                id="cancelBankVerification"
                className="btn btn-default"
                data-dismiss="modal"
                value="CANCEL"
                onClick={() => {
                  this.resetData();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BankAccountVerification;

import axios from "axios";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import swal from "sweetalert";
import common from "../../../common";
import TButton from "../../../components/TButton";
import InvestorDashboardDocuments from "../../shared/components/InvestorDashboardDocuments.component";
import { getCompanyLogoFromS3 } from "../../shared/helper";

const Invited = (props) => {
  const { invitation, fromToDo } = props;
  const [investmentData, setInvestmentData] = useState(null);

  useEffect(() => {
    let temp = this;
    let token = window.localStorage.getItem("token");
    axios
      .get(
        `${common.path.base_url}/getUserInvestmentProcessById/${invitation.userId}/${invitation.investmentId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        const investment = response.data.data.invitedInvestments[0] || null;
        setInvestmentData(investment); // Update the state with investment data
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }, [invitation.userId, invitation.investmentId]);

  return (
    <div
      className={fromToDo ? '' : "card"}
      key={invitation._id}
      id={`invitation_${invitation.investmentId}`}
    >
      <div className="row card-content">
        <div className="img-left">
          <img
            src={getCompanyLogoFromS3(invitation)}
            // src="https://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg?size=338&ext=jpg&ga=GA1.1.1788614524.1703203200&semt=ais"
            alt=""
            className="image-size"
          />
        </div>
        <div
          className="col-lg-9 col-md-9 col-sm-9 col-9"
          style={{ minHeight: "118px" }}
        >
          <div className="card-header">
            {invitation.investmentName}
          </div>
          <h6>
            {investmentData && (
              <div style={{ fontSize: "12px", marginTop: 2 }}>
                {investmentData.minAmount && (
                  <>
                    Min:{" "}
                    <NumberFormat
                      value={investmentData.minAmount}
                      thousandSeparator={true}
                      prefix={"$"}
                      fixedDecimalScale={true}
                      displayType={"text"}
                    />
                  </>
                )}
                {investmentData.maxAmount && (
                  <>
                    {" "}
                    / Max:{" "}
                    <NumberFormat
                      value={investmentData.maxAmount}
                      thousandSeparator={true}
                      prefix={"$"}
                      fixedDecimalScale={true}
                      displayType={"text"}
                    />
                  </>
                )}
              </div>
            )}
          </h6>

          <div style={{ fontSize: "12px" }}>
            (Variance Requests:{" "}
            <a href="mailto:platform@syndicategroup.com">
              <u>platform@syndicategroup.com</u>
            </a>
            )
          </div>

          <div className="d-flex align-items-center justify-content-between">
            <div className="col-lg-9 col-md-9 col-sm-9 col-9 card-content" style={{ display: "block", paddingLeft: 0 }}>
              <div>
                Do you want to participate?
              </div>
              <div style={{
                display: "flex",
                gap: "10px",
                marginTop: "10px",
              }}>
                <TButton onClick={() => { props.invitationAction(true, invitation._id, invitation.investmentId,) }} text={`Yes`} className='contact-btn' disabled={false} />
                <input type="button" className="btn btn-default" value="No" onClick={() => { props.invitationAction(false, invitation._id, invitation.investmentId,) }} style={{ height: "30px", lineHeight: 1, }} />
                {/* <TButton  text={`No`} className='contact-btn' disabled={true} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <InvestorDashboardDocuments {...props} />
    </div>
  );
};
export default Invited;

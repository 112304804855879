import React from 'react'
import './MyInvestments.scss';
import ListCard from './List.card'

const ListView = (props) => {
  const { myInvestments, isCollapsed } = props;
  return (
    <div className={isCollapsed ? "row deashbord_new_2 pipeline-scroll" : "row deashbord_new_2"}>
      {
        myInvestments.map((myInvestment, index) => {
          return <ListCard
            myInvestment={myInvestment}
            key={index}
            {...props}
          />
        })
      }
    </div>
  );
}

export default ListView
export const path = {
  series1: require("../images/assets/images/series1.png"),
  checkslide: require("../images/assets/images/checkslide.svg"),
  uncheckslide: require("../images/assets/images/uncheckslide.svg"),
  uncheckslideBorder: require("../images/assets/images/uncheckslide-border.svg"),
  report: require("../images/assets/images/report.svg"),
  latter: require("../images/assets/images/latter.svg"),
  question: require("../images/assets/images/question.svg"),
  openInvitation: require("../images/icons/statistics.svg"),
  toDoInvestments: require("../images/icons/list.svg"),
  myInvestments: require("../images/icons/my_investments.svg"),
  underConsideration: require("../images/icons/briefcase.svg"),
  exitProcessInvestment: require("../images/icons/logout.svg"),
  leftArrow: require("../images/icons/left-arrow.svg"),
  rightArrow: require("../images/icons/right-arrow.svg"),
  notParticipating: require("../images/icons/clock.png"),
  series2: require("../images/assets/images/series2.png"),

  drowpDownArrow: require("../images/assets/images/drowp-down-arr.svg"),
  drowpDownArrowOpen: require("../images/assets/images/dropdown_above.png"),
  menuIcon: require("../images/assets/images/menu-icon.svg"),
  tileDeactive: require("../images/assets/images/tile-deactive.svg"),
  tile: require("../images/assets/images/tile.svg"),
  list: require("../images/assets/images/list.svg"),
  Lineage_OS_Logo: require("../images/assets/images/Lineage_OS_Logo.png"),
  u3lfhzq6: require("../images/assets/images/u3lfhzq6.png"),
  download: require("../images/assets/images/download.svg"),
  yearReviewImage: require("../images/assets/images/year-review.svg"),
  investorFAQ: require("../images/assets/images/investor-faq.svg"),
  portfolioGrowth: require("../images/assets/images/portfolio-growth.svg"),
  noInvestment: require("../images/assets/images/no-investment.svg"),

  graph: require("../images/assets/images/graph.jpg"),
  folder: require("../images/assets/images/folder.svg"),
  mail: require("../images/assets/images/mail.svg"),
  mail2: require("../images/assets/images/mail-2.svg"),

  questionDisable: require("../images/assets/images/question-disable.svg"),
  googleDocDisable: require("../images/assets/images/google-docs-disable.svg"),
  groupDisable: require("../images/assets/images/group-disable.svg"),
  webinar_attend_link_disable: require("../images/assets/images/attend_webinar_grey.svg"),
  investorRecommendation: require("../images/assets/images/investor-recommendation.png"),
  sidebarNotificationSettingsIcon: require("../images/assets/images/settings.svg"),
  closeButtonIcon: require("../images/assets/images/close-cancel-button.svg"),
  close: require("../images/assets/images/close.svg"),

  profile_r: require("../images/assets/images/profile_r.svg"),
  editProfile_r: require("../images/assets/images/edit_r.svg"),
  dashboard_r: require("../images/assets/images/dashboard_r.svg"),
  delete_r: require("../images/assets/images/delete_r.svg"),
  send_r: require("../images/assets/images/send_r.svg"),
  resend_r: require("../images/assets/images/resend_r.svg"),
  edit_round: require("../images/assets/images/edit_round.svg"),
  send_round: require("../images/assets/images/send_round.svg"),
  dashboard_round: require("../images/assets/images/dashboard_round.svg"),
  delete_round: require("../images/assets/images/delete_round.svg"),
  dashboard_new: require("../images/assets/images/dashboard-new.svg"),
  edit_new: require("../images/assets/images/edit-new.svg"),
  send_mail_new: require("../images/assets/images/send-mail-new.svg"),
  delete_new: require("../images/assets/images/delete-new.svg"),
  refer: require("../images/assets/images/refer.svg"),
  checkGreenBack: require("../images/assets/images/checkGreenBack.svg"),
  reset: require("../images/assets/images/reset.svg"),
  webinar_link: require("../images/assets/images/webinarlink.svg"),
  white_webinar_link: require("../images/assets/images/webinar_link.png"),
  webinar_attend_link: require("../images/assets/images/webinarlink.svg"),
};


import axios from 'axios';
import React, { Component } from "react";
import LaddaButton, { EXPAND_RIGHT, XS } from 'react-ladda';
import ReactPhoneInput from 'react-phone-input-2';
import swal from 'sweetalert';

import common from '../../../common';
import RequiredSign from '../../../components/RequiredSign';

class AddStartupContact extends Component {

  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      formData: {
        firstName: '',
        middleName: '',
        lastName: '',
        title: '',
        address: '',
        investorCompanyName: '',
        emailAddress: '',
        mobile: '',
        office: '',
        notes: '',
        companyId: '',
      },
      investorCategories: { label: 'Strategic', value: 'Strategic' },
      validInvitaionForm: false,
      loadinglada: false,
      firstNameValidation: '',
      lastNameValidation: '',
      emailValidation: '',
      mobileValidation: '',
      officeValidation: '',
      companyValidation: '',
    }
  }

  componentDidMount() {
    this.setState({
      formData: { ...this.state.formData, mobile: '' }
    });
  }

  addStartupContact(event) {
    this.setState({ key: Math.floor(Math.random() * 10) });
    event.stopPropagation();
    let tokenpass = window.localStorage.getItem('token');
    const self = this;
    this.setState({ loadinglada: true });
    const mobile = this.state.formData.mobile.replace(/[- )(]/g, '');
    const office = this.state.formData.office.replace(/[- )(]/g, '');
    const payload = {
      ...this.state.formData,
      mobile,
      office,
      investmentId: this.props.selectedInvestment && this.props.selectedInvestment.value ? this.props.selectedInvestment.value : '',
      companyId: window.localStorage.getItem('companyId')
    };
    axios.post(`${common.path.base_url}/importSingleContact`, payload, {
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": tokenpass
      }
    })
      .then((response) => {
        self.setState({ loadinglada: false });
        swal({
          title: "Contact Added!",
          text: response.data.message,
          icon: "success",
          button: "Ok!",
        });
        this.props.getCompanyUploadedFiles(payload.investmentId);
        this.setState({
          formData: { ...this.state.formData, mobile: '' }
        });
      })
      .catch((error) => {
        self.setState({ loadinglada: false });
        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
          window.localStorage.clear();
          window.location.href = '/';
          return;
        }
        else if (error.response.data.code === 204) {
          window.localStorage.clear();
          window.location.href = '/';
        } else {
          this.setState({ loadinglada: false });
          if (error.response) {
            swal({
              title: "Error!",
              text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
          else {
            swal({
              title: "Network Error!",
              text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        }
        this.props.getCompanyUploadedFiles(payload.investmentId);
        this.setState({
          formData: { ...this.state.formData, mobile: '' }
        });
      });
  }

  checkInvitaionFormValidity = () => {
    if (this.state.formData.firstName
      && this.state.formData.lastName
      && this.state.formData.investorCompanyName
      && (!this.state.emailValidation && this.state.formData.emailAddress)
    ) {
      this.setState({ validInvitaionForm: true });

      if (this.state.formData.mobile && this.state.formData.mobile.length > 4) {
        if (this.state.mobileValidation) {
          this.setState({ validInvitaionForm: false });
        }
      }
    } else {
      this.setState({ validInvitaionForm: false })
    }
  }

  formValidaton(id, val) {
    if (id === 'firstName') {
      let name = val;
      if (name === '') {
        this.setState({
          firstNameValidation: "First Name (Legal) should not be empty*"
        })
      } else {
        this.setState({
          firstNameValidation: ""
        })
      }
    } else if (id === 'lastName') {
      let name = val
      if (name === '') {
        this.setState({
          lastNameValidation: "Last Name should not be empty*"
        })
      } else {
        this.setState({
          lastNameValidation: ""
        })
      }
    } else if (id === 'company') {
      let name = val;
      if (name === '') {
        this.setState({
          companyValidation: "Company Name should not be empty*"
        })
      } else {
        this.setState({
          companyValidation: ""
        })
      }
    } else if (id === 'email') {
      let email = val;
      let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let res = emailRegex.test(email);
      if (email === '') {
        this.setState({
          emailValidation: "Email should not be empty*"
        })
      } else if (res !== true) {
        this.setState({
          emailValidation: "Please enter valid email*"
        })
      } else {
        this.setState({
          emailValidation: ""
        })
      }
    } else if (id === 'mobile') {
      if (this.state.formData.mobile !== '') {
        let mobile = this.state.formData.mobile;
        let mobileRegex = /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/;
        let res = mobileRegex.test(mobile)
        if (res !== true) {
          let swissRegex = /^(\+?)(\d{2,4})(\s?)(\-?)((\(0\))?)(\s?)(\d{2})(\s?)(\-?)(\d{3})(\s?)(\-?)(\d{2})(\s?)(\-?)(\d{2})/;
          let swissres = swissRegex.test(mobile);
          if (swissres !== true) {
            this.setState({
              mobileValidation: "Please enter valid mobile number",
            })
          } else {
            this.setState({
              mobileValidation: "",
            })
          }
        } else {
          this.setState({
            mobileValidation: ""
          })
        }

      } else {
        this.setState({
          mobileValidation: ""
        })
      }
    } else if (id === 'office') {
      if (this.state.formData.office !== '') {
        let office = this.state.formData.office;
        let officeRegex = /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/;
        let res = officeRegex.test(office)

        if (res !== true) {
          let swissRegex = /^(\+?)(\d{2,4})(\s?)(\-?)((\(0\))?)(\s?)(\d{2})(\s?)(\-?)(\d{3})(\s?)(\-?)(\d{2})(\s?)(\-?)(\d{2})/;
          let swissres = swissRegex.test(office);
          if (swissres !== true) {
            this.setState({
              officeValidation: "Please enter valid office number",
            })
          } else {
            this.setState({
              officeValidation: "",
            })
          }
        } else {
          this.setState({
            officeValidation: ""
          })
        }
      } else {
        this.setState({
          officeValidation: ""
        })
      }
    }
  }

  handleChange(id, newValue) {
    if (id === 'firstName')
      this.setState({ formData: { ...this.state.formData, firstName: newValue.trim(), } });
    else if (id === 'middleName')
      this.setState({ formData: { ...this.state.formData, middleName: newValue.trim(), } });
    else if (id === 'lastName')
      this.setState({ formData: { ...this.state.formData, lastName: newValue.trim(), } });
    else if (id === 'company')
      this.setState({ formData: { ...this.state.formData, investorCompanyName: newValue, } });
    else if (id === 'title')
      this.setState({ formData: { ...this.state.formData, title: newValue, } });
    else if (id === 'address')
      this.setState({ formData: { ...this.state.formData, address: newValue, } });
    else if (id === 'investorCompanyName')
      this.setState({ formData: { ...this.state.formData, investorCompanyName: newValue, } });
    else if (id === 'email')
      this.setState({ formData: { ...this.state.formData, emailAddress: newValue, } });
    else if (id === 'office')
      this.setState({ formData: { ...this.state.formData, office: newValue, } });
    else if (id === 'notes')
      this.setState({ formData: { ...this.state.formData, notes: newValue, } });
    else if (id === "address")
      this.setState({ formData: { ...this.state.formData, address: newValue, } });
    else if (id === "mobile")
      this.setState({ formData: { ...this.state.formData, mobile: newValue, } });

    setTimeout(() => {
      this.checkInvitaionFormValidity();
    }, 100);
  };

  handleOnChangeMobile(value) {
    this.setState({ formData: { ...this.state.formData, mobile: `+${value}` } }, () => this.checkInvitaionFormValidity());
  };

  handleOnChangeOffice(value) {
    this.setState({ formData: { ...this.state.formData, office: `+${value}` } }, () => this.checkInvitaionFormValidity());
  };
  resetData() {
    const key = this.state.key + 1;
    this.setState({
      formData: {
        firstName: '',
        middleName: '',
        lastName: '',
        title: '',
        address: '',
        investorCompanyName: '',
        emailAddress: '',
        mobile: '',
        office: '',
        notes: '',
        companyId: '',
      },
      key,
      validInvitaionForm: false,
      loadinglada: false,
      firstNameValidation: '',
      lastNameValidation: '',
      emailValidation: '',
      mobileValidation: '',
      officeValidation: '',
      companyValidation: '',
    });
  }

  render() {
    return (
      <div id="addStartupContactModal" className="modal fade" key={this.state.key}>
        <div className="modal-dialog " key={this.state.key}>
          <div className="modal-content invite-contact">
            <div className="modal-header">
              <h4 className="modal-title">Add Contact</h4>
            </div>
            <div className="modal-body">
              <form className="form-horizontal" id="myform">
                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">First Name (Legal) <RequiredSign /> :</label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <input className="form-control" id="firstName" type="text"
                      onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                      onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                      placeholder=''
                    />
                    <p>{this.state.firstNameValidation}</p>
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">Middle Name :</label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <input className="form-control" id="middleName" type="text"
                      onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                      onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                      placeholder=''
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">Last Name <RequiredSign /> :</label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <input className="form-control" id="lastName" type="text"
                      onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                      onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                      placeholder=''
                    />
                    <p>{this.state.lastNameValidation}</p>
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">Company <RequiredSign /> :</label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <input className="form-control" id="company" type="text"
                      onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                      onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                      placeholder=''
                    />
                    <p>{this.state.companyValidation}</p>
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">Title:</label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <input className="form-control" id="title" type="text"
                      onKeyUp={(event) => this.formValidaton(event.target.id)}
                      onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                      placeholder=''
                    />
                    <p>{this.state.titleValidation}</p>
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">Address :</label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <input className="form-control" id="address" type="text"
                      onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                      placeholder=''
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">Email Address <RequiredSign /> :</label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <input
                      className="form-control"
                      id="email"
                      onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                      onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                      type='text'
                      placeholder=''
                    />
                    <p>{this.state.emailValidation}</p>
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-lg-4 col-sm-3 col-md-3 control-label">Mobile:</label>
                  <div className="col-lg-8 col-md-8 col-sm-8 ">
                    <ReactPhoneInput
                      country={'us'}
                      id="mobile"
                      onKeyDown={(event) => this.formValidaton("mobile")}
                      countryCodeEditable={false}
                      disableAreaCodes={true}
                      autoComplete="none"
                      value={this.state.formData.mobile}
                      onChange={(value) => this.handleOnChangeMobile(value)}
                      placeholder=""
                    />
                    <p>{this.state.mobileValidation}</p>
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-lg-4 col-sm-3 col-md-3 control-label">Office:</label>
                  <div className="col-lg-8 col-md-8 col-sm-8 ">
                    <ReactPhoneInput country={'us'} id="office"
                      onKeyDown={(event) => this.formValidaton("office")}
                      disableAreaCodes={true}
                      onChange={(value) => this.handleOnChangeOffice(value)}
                      countryCodeEditable={false}
                      autoComplete="none"
                      placeholder=""
                      value={this.state.formData.office}
                    />
                    <p>{this.state.officeValidation}</p>
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">Notes :</label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <textarea
                      className="form-control"
                      id="notes"
                      type="message"
                      placeholder=''
                      rows="4"
                      cols="50"
                      onChange={(evt) => this.handleChange('notes', evt.target.value)}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <LaddaButton
                id='lada-btn'
                loading={this.state.loadinglada}
                onClick={(event) => this.addStartupContact(event)}
                data-color="#eee"
                data-size={XS}
                className={!this.state.validInvitaionForm ? 'disable' : ''}
                data-style={EXPAND_RIGHT}
                data-spinner-size={30}
                data-spinner-color="#ddd"
                data-spinner-lines={12}
                data-dismiss="modal"
              >
                ADD
              </LaddaButton>
              <input type="button" id="cancel" className="btn btn-default" data-dismiss="modal" value="CANCEL" onClick={() => this.resetData()} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddStartupContact;
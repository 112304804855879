import React, { Component } from "react";
import "./Wireinstuction.scss";
import "react-phone-input-2/lib/style.css";
import AddAddressModal from "../../components/AddAddressModal";
import Header from "../../components/Header";
import HeaderImg from "../../components/HeaderImage";
import LoadingSpinner from "../../components/Loader";
import FileUploadBlob from "../../components/FileUploadBlob";
import { withRouter } from "react-router-dom";
import LaddaButton, { XS, EXPAND_RIGHT } from "react-ladda";
import axios from "axios";
import swal from "sweetalert";
import Modal from "react-bootstrap-modal";
import common from "../../common";
import _ from "lodash";

class WireInstruction extends Component {
  constructor(props) {
    super(props);
    this.companyId = "";
    this.state = {
      bankName: "",
      bankAddress: "",
      accHolderName: "",
      accNumber: "",
      routingNo: "",
      memo: "",
      loading: false,
      loadinglada: false,
      Role: "",
      id: "",
      bankAddressValidation: "",
      bankNameValidation: "",
      accHolderNameValidation: "",
      accNumberValidation: "",
      routingNoValidation: "",
      memoValidation: "",
      formValidated: false,
      choosenFile: null,
      inputFile: null,
      show: false,
      address1: "",
      address1: "",
      city: "",
      state: "",
      zip: "",
      address1Validation: "",
      address2Validation: "",
      cityValidation: "",
      stateValidation: "",
      zipValidation: "",
    };
  }

  setProfileImage() {
    let elements = document.querySelectorAll("#header-img");
    elements.forEach((imgEle) => {
      imgEle.src = `${imgEle.src}&update=${new Date()}`;
    });
  }

  componentWillMount() {
    const url = new URL(window.location.href);
    this.companyId = url.searchParams.get("companyId");
    this.getData();
    const userRole = window.localStorage.getItem("role");
    this.setState({ Role: userRole });
  }
  getData() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem("token");
    axios
      .get(
        `${common.path.base_url}/getWireInstruction?companyId=${this.companyId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        temp.setState({
          id: response.data.data._id,
          bankName: response.data.data.bankName,
          bankAddress: response.data.data.bankAddress,
          accHolderName: response.data.data.accountHolderName,
          accNumber: response.data.data.accountNumber,
          routingNo: response.data.data.routingNumber,
          memo: response.data.data.memo,
        });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  chooseFile() {
    document.getElementById("choose-file").click();
  }

  handleChange(id, newValue) {
    if (id === "name") this.setState({ name: newValue });
    else if (id === "bankName") this.setState({ bankName: newValue });
    else if (id === "bankAddress") this.setState({ bankAddress: newValue });
    else if (id === "accHolderName") this.setState({ accHolderName: newValue });
    else if (id === "accNumber") this.setState({ accNumber: newValue });
    else if (id === "routingNo") this.setState({ routingNo: newValue });
    else if (id === "memo") this.setState({ memo: newValue });
    else if (id === "address1") this.setState({ address1: newValue });
    else if (id === "address2") this.setState({ address2: newValue });
    else if (id === "city") this.setState({ city: newValue });
    else if (id === "state") this.setState({ state: newValue });
    else if (id === "zip") {
      this.setState({ zip: newValue });
    }
  }

  formValidaton(id) {
    if (id === "bankName") {
      let bankName = document.getElementById("bankName").value;
      if (bankName === "") {
        this.setState({
          bankNameValidation: "Bank name should not be empty*",
        });
      } else {
        this.setState({
          bankNameValidation: "",
        });
      }
    } else if (id === "bankAddress") {
      let bankAddress = document.getElementById("bankAddress").value;
      if (bankAddress === "") {
        this.setState({
          bankAddressValidation: "Bank Address should not be empty*",
        });
      } else {
        this.setState({
          bankAddressValidation: "",
        });
      }
    } else if (id === "accHolderName") {
      let accHolderName = document.getElementById("accHolderName").value;
      if (accHolderName === "") {
        this.setState({
          accHolderNameValidation: "Account Holder name should not be empty*",
        });
      } else {
        this.setState({
          accHolderNameValidation: "",
        });
      }
    } else if (id === "accNumber") {
      const accNumber = document.getElementById("accNumber").value;
      var account =
        /[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}$/;
      var accountNo = account.test(accNumber);
      if (accNumber === "") {
        this.setState({
          accNumberValidation: "Account Number should not be empty*",
        });
      } else if (accountNo !== true) {
        this.setState({
          accNumberValidation: "Enter Valid account number*",
        });
      } else {
        this.setState({
          accNumberValidation: "",
        });
      }
    } else if (id === "routingNo") {
      const routingNo = document.getElementById("routingNo").value;
      if (routingNo === "") {
        this.setState({
          routingNoValidation: "Routing Number should not be empty*",
        });
      } else {
        this.setState({
          routingNoValidation: "",
        });
      }
    } else if (id === "memo") {
      let memo = document.getElementById("memo").value;
      if (memo === "") {
        this.setState({
          memoValidation: "Memo should not be empty*",
        });
      } else {
        this.setState({
          memoValidation: "",
        });
      }
    } else if (id === "address1") {
      let address1 = document.getElementById("address1").value;
      if (address1 === "") {
        this.setState({
          address1Validation: "Address1 should not be empty*",
        });
      } else {
        this.setState({
          address1Validation: "",
        });
      }
    } else if (id === "address2") {
      let address2 = document.getElementById("address2").value;
      if (address2 === "") {
        this.setState({
          address2Validation: "Address2 should not be empty*",
        });
      } else {
        this.setState({
          address2Validation: "",
        });
      }
    } else if (id === "city") {
      let city = document.getElementById("city").value;
      if (city === "") {
        this.setState({
          cityValidation: "City should not be empty*",
        });
      } else {
        this.setState({
          cityValidation: "",
        });
      }
    } else if (id === "state") {
      let state = document.getElementById("state").value;
      if (state === "") {
        this.setState({
          stateValidation: "State should not be empty*",
        });
      } else {
        this.setState({
          stateValidation: "",
        });
      }
    } else if (id === "zip") {
      let zip = document.getElementById("zip").value;
      if (zip === "") {
        this.setState({
          zipValidation: "Zip should not be empty*",
        });
      } else {
        this.setState({
          zipValidation: "",
        });
      }
    }
    setTimeout(() => {
      this.validateForm();
    }, 100);
  }
  resetAddAddressData() {
    this.setState({
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      address1Validation: "",
      address2Validation: "",
      cityValidation: "",
      stateValidation: "",
      zipValidation: "",
      show: false,
      loadinglada2: false,
    });
  }
  uploadLogo(file) {
    if (file.size) {
      const temp = this;
      let tokenpass = window.localStorage.getItem("token");
      let data = new FormData();
      data.append("file", file);
      const isPrivate = true;
      data.append("isPrivate", isPrivate);
      axios
        .post(`${common.path.base_url}/uploadFile`, data, {
          headers: {
            Authorization: tokenpass,
          },
        })
        .then((response) => {
          temp.setState({
            companyLogoId: response.data.data.id,
            loadinglada: false,
            choosenFile: null,
          });
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message &&
            common.AUTH_EXPIRED === error.response.data.message
          ) {
            window.localStorage.clear();
            window.location.href = "/";
            return;
          } else {
          }
        });
    }
  }
  putData() {
    this.setState({
      loadinglada: !this.state.loadinglada,
      progress: 0.5,
    });
    let temp = this;
    temp.setState({ loading: false });
    let CompanyId = window.localStorage.getItem("companyId");
    const token = localStorage.getItem("token");
    const id = window.localStorage.getItem("_id");
    axios
      .put(
        `${common.path.base_url}/updateWireInstruction`,
        {
          companyId: CompanyId,
          _id: this.state.id,
          bankAddress: this.state.bankAddress,
          bankName: this.state.bankName,
          accountHolderName: this.state.accHolderName,
          accountNumber: this.state.accNumber,
          routingNumber: this.state.routingNo,
          memo: this.state.memo,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        temp.setState({ loading: false });
        swal({
          title: "Updated!",
          text: response.data.message,
          icon: "success",
          button: "Ok!",
        }).then(() => {
          temp.getData();
        });
        temp.setState({ loadinglada: false, formValidated: false });
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else {
          swal({
            title: "Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : "Something Went Wrong",
            icon: "error",
            button: "Ok!",
          });
        }
        temp.setState({ loadinglada: false });
      });
  }
  validateForm() {
    if (
      !_.isEmpty(
        this.state.bankName &&
        this.state.bankAddress &&
        this.state.accHolderName &&
        this.state.accNumber &&
        this.state.routingNo &&
        this.state.memo
      )
    ) {
      this.setState({ formValidated: true });
    } else {
      this.setState({ formValidated: false });
    }
  }
  putaddressData() {
    const address = `${this.state.address1}, ${this.state.address2}, ${this.state.city}, ${this.state.state}, ${this.state.zip}`;
    this.setState({
      address: address,
      show: false,
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      address1Validation: "",
      address2Validation: "",
      cityValidation: "",
      stateValidation: "",
      zipValidation: "",
    });
  }
  render() {
    const username = window.localStorage.getItem("username");
    return (
      <div>
        <div className="Wireinstuction">
          {this.state.loading ? <LoadingSpinner /> : ""}
          <Header username={username} role={this.state.Role} />
          <HeaderImg heading={this.state.Role} />
          <div className="Wireinstuction-main">
            <div className="heading">
              <h3>Wire Instuction</h3>
            </div>
            <div className="Wireinstuction">
              <div className="Wireinstuction-details">
                <form className="form-horizontal"></form>
              </div>
              <div className="bank-details">
                <form className="form-horizontal">
                  <div className="form-group">
                    <label className="col-md-3 control-label">Bank Name:</label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        id="bankName"
                        type="text"
                        placeholder="Bank Name"
                        onKeyUp={(event) => this.formValidaton(event.target.id)}
                        onChange={(event) => {
                          this.handleChange(
                            event.target.id,
                            event.target.value
                          );
                        }}
                        value={this.state.bankName}
                      />
                      <p>{this.state.bankNameValidation}</p>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3 control-label">
                      Bank Address:
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        id="bankAddress"
                        type="text"
                        placeholder="Bank Address"
                        onKeyUp={(event) => this.formValidaton(event.target.id)}
                        onChange={(event) => {
                          this.handleChange(
                            event.target.id,
                            event.target.value
                          );
                        }}
                        value={this.state.bankAddress}
                      />
                      <p>{this.state.bankAddressValidation}</p>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3 control-label">
                      Account Holder Name:
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        id="accHolderName"
                        type="text"
                        placeholder="Account Holder Name"
                        onKeyUp={(event) => this.formValidaton(event.target.id)}
                        onChange={(event) => {
                          this.handleChange(
                            event.target.id,
                            event.target.value
                          );
                        }}
                        value={this.state.accHolderName}
                      />
                      <p>{this.state.accHolderNameValidation}</p>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3 control-label">
                      Account Number:
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        id="accNumber"
                        type="text"
                        placeholder="Account Number"
                        onKeyUp={(event) => this.formValidaton(event.target.id)}
                        onChange={(event) => {
                          this.handleChange(
                            event.target.id,
                            event.target.value
                          );
                        }}
                        value={this.state.accNumber}
                      />
                      <p>{this.state.accNumberValidation}</p>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3 control-label">
                      Routing Number:
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        id="routingNo"
                        type="text"
                        placeholder="Routing Number"
                        onKeyUp={(event) => this.formValidaton(event.target.id)}
                        onChange={(event) => {
                          this.handleChange(
                            event.target.id,
                            event.target.value
                          );
                        }}
                        value={this.state.routingNo}
                        maxlength="9"
                      />
                      <p>{this.state.routingNoValidation}</p>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3 control-label">Memo:</label>
                    <div className="col-md-8">
                      <textarea
                        id="memo"
                        type="message"
                        placeholder="Memo"
                        rows="4"
                        cols="50"
                        onKeyUp={(event) => this.formValidaton(event.target.id)}
                        onChange={(event) => {
                          this.handleChange(
                            event.target.id,
                            event.target.value
                          );
                        }}
                        value={this.state.memo}
                      ></textarea>
                      <p>{this.state.memoValidation}</p>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3 control-label"></label>
                    <div className="col-md-8 updateButton">
                      <LaddaButton
                        id="update-btn"
                        loading={this.state.loadinglada}
                        onClick={() => this.putData()}
                        data-color="#eee"
                        data-size={XS}
                        data-style={EXPAND_RIGHT}
                        data-spinner-size={30}
                        data-spinner-color="#ddd"
                        data-spinner-lines={12}
                        className={
                          !this.state.formValidated ||
                            !_.isEmpty(
                              this.state.accNumberValidation ||
                              this.state.routingNoValidation
                            )
                            ? "disable"
                            : ""
                        }
                      >
                        SAVE
                      </LaddaButton>
                      <input
                        type="reset"
                        className="btn btn-default cancel"
                        value="CANCEL"
                        onClick={() => {
                          let path = `/dashboard`;
                          this.props.history.push(path);
                        }}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Modal
          id="updateFAQ"
          aria-labelledby="ModalHeader"
          show={this.state.show}
        >
          <Modal.Header>
            <Modal.Title id="ModalHeader">Add Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form-horizontal">
              <div className="form-group">
                <label className="col-md-3 control-label">Address 1:</label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Address 1"
                    id="address1"
                    onKeyUp={(event) => this.formValidaton(event.target.id)}
                    onChange={(event) =>
                      this.handleChange(event.target.id, event.target.value)
                    }
                    value={this.state.address1}
                  />
                  <p>{this.state.address1Validation}</p>
                </div>
              </div>
              <div className="form-group">
                <label className="col-md-3 control-label">Address 2:</label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Address 2"
                    id="address2"
                    onKeyUp={(event) => this.formValidaton(event.target.id)}
                    onChange={(event) =>
                      this.handleChange(event.target.id, event.target.value)
                    }
                    value={this.state.address2}
                  />
                  <p>{this.state.address2Validation}</p>
                </div>
              </div>
              <div className="form-group">
                <label className="col-md-3 control-label">City:</label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="City"
                    id="city"
                    onKeyUp={(event) => this.formValidaton(event.target.id)}
                    onChange={(event) =>
                      this.handleChange(event.target.id, event.target.value)
                    }
                    value={this.state.city}
                  />
                  <p>{this.state.cityValidation}</p>
                </div>
              </div>
              <div className="form-group">
                <label className="col-md-3 control-label">State:</label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="State"
                    id="state"
                    onKeyUp={(event) => this.formValidaton(event.target.id)}
                    onChange={(event) =>
                      this.handleChange(event.target.id, event.target.value)
                    }
                    value={this.state.state}
                  />
                  <p>{this.state.stateValidation}</p>
                </div>
              </div>
              <div className="form-group">
                <label className="col-md-3 control-label">Zip:</label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Zip"
                    id="zip"
                    onKeyUp={(event) => this.formValidaton(event.target.id)}
                    onChange={(event) =>
                      this.handleChange(event.target.id, event.target.value)
                    }
                    value={this.state.zip}
                  />
                  <p>{this.state.zipValidation}</p>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <LaddaButton
              id="lada-btn"
              loading={this.state.loadinglada2}
              data-color="#eee"
              data-size={XS}
              onClick={() => this.putaddressData()}
              className={
                !_.isEmpty(
                  this.state.address1 &&
                  this.state.address2 &&
                  this.state.city &&
                  this.state.state &&
                  this.state.zip
                )
                  ? ""
                  : "disable"
              }
              data-style={EXPAND_RIGHT}
              data-spinner-size={30}
              data-spinner-color="#ddd"
              data-spinner-lines={12}
            >
              ADD
            </LaddaButton>
            <input
              type="button"
              id="company-cancel"
              className="btn btn-default"
              data-dismiss="modal"
              value="CANCEL"
              onClick={() => this.resetAddAddressData()}
            />
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
// export default Profile;
export default withRouter(WireInstruction);

import axios from 'axios';
import _ from 'lodash';
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import LaddaButton, { EXPAND_RIGHT, XL } from 'react-ladda';
import OtpInput from 'react-otp-input';
import ReactPhoneInput from 'react-phone-input-2';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import swal from 'sweetalert';
import { setUserLogin } from '../../UserLogin/actions/login';
import common from '../../common';
import CustomModal from '../../components/CustomModal';
import LoadingSpinner from '../../components/Loader';
import images from '../../images';
import SCPModal from './SCPModal/SCPModal';
import TSGReviewModal from './TSGReviewModal/TSGReview';
import './loginPage.scss';

// import { FormErrors } from './FormErrors.jsx';
// import 'font-awesome/css/font-awesome.min.css'
// import '../node_modules/font-awesome/css/font-awesome.min.css'; 
// const img2 = require("../../images/log-in.png");
class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eye: true,
      loading: false,
      formValid: false,
      remembermeLogin: false,
      usernameValidation: null,
      passvalidation: null,
      username: null,
      password: null,
      isLoginSuccess: false,
      verifyCode: null,
      mobileNo: '',
      displayOTP: null,
      userData: null,
      isMobileVerified: false,
      isYearInReviewVisited: false,

    };
    this.routeChange = this.routeChange.bind(this);
    this.verifyCodeHandler = this.verifyCodeHandler.bind(this);
    this.resend = this.resend.bind(this);
    this.sendMobileOTP = this.sendMobileOTP.bind(this);
    this.changeMobileNo = this.changeMobileNo.bind(this);

  }

  componentWillMount() {

    this.routeCheck();

    const url = new URL(window.location.href);
    const queryUsername = url.searchParams.get('username');
    this.setState({ username: queryUsername, usernameValidation: '' });
  }


  componentDidMount() {
    if (this.state.username) {
      if (document.getElementById("password")) {
        document.getElementById("password").focus();
      }
    }
  }


  routeChange(event) {

    event.preventDefault();
    this.setState({
      loading: !this.state.loading,
      progress: 0.5,
    });
    let formValid = false;
    const username = this.state.username;
    const pass = this.state.password;

    var exp1 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    var exp2 = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var res1 = exp1.test(this.state.password);
    var res2 = exp2.test(this.state.username);

    if (username === '' || res2 !== true) {
      formValid = false
    }
    else if (pass === '' || res1 !== true) {
      formValid = false
    }
    else {
      formValid = true;
    }
    if (formValid) {
      this.setState({
        formValid: true
      });

      //we need to assign this to a variable because during the api call, this becomes null
      let temp = this;
      axios.post(`${common.path.base_url}/login`, {
        emailAddress: username,
        password: pass
      })
        .then(function (response) {
          if (common.AUTH_EXPIRED === response.data.message) {
            window.localStorage.clear();
            window.location.href = '/';
            return;
          }
          else if (response.data.code === 204) {
            window.localStorage.clear();
            this.props.history.push('/');
          }


          if (document.getElementById('checkbox').checked) {
            // this.createCookie("isLoggedIn",true,1)
            window.localStorage.setItem('isLoggedIn', true);
          } else {
            window.localStorage.setItem('isLoggedIn', false);
          }

          console.log(response.data.data.otp, process.env.REACT_APP_ENV);
          temp.setState({
            loading: false, isLoginSuccess: true,
            mobileNo: response.data.data.mobile,
            displayOTP: response.data.data.otp,
            userData: response.data,
            isMobileVerified: response.data.data.isMobileVerified,
            isYearInReviewVisited: response.data.data.isYearInReviewVisited,
          });
          // window.localStorage.setItem('isLoggedIn', true);


          // console.log(133, response.data.data.mobile);

          // let { role, roleCode, roleName, _id, name, imagePath } = response.data.data;
          // let _role = roleCode ? roleCode : role;
          // window.localStorage.setItem('emailAddress', temp.state.username);
          // window.localStorage.setItem('id', _id);
          // window.localStorage.setItem('token', response.data.token);
          // window.localStorage.setItem('username', name);
          // window.localStorage.setItem('imagePath', imagePath);
          // window.localStorage.setItem('role', roleName);
          // window.localStorage.setItem('roleCode', _role);
          // temp.props.history.push('/dashboard')

        })
        .catch(function (error) {
          temp.setState({ loading: false });
          if (error.response) {
            swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              button: "Ok!",
            });
          }
          else {
            swal({
              title: "Network Error!",
              text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        });
    }
    else {
      // document.getElementById("submitButton").disabled = true;
      //sweetalert
      let temp = this;
      swal({
        title: "Error!",
        text: "Invalid Username or password",
        icon: "error",
        button: "Ok!",
        confirmButtonClass: "btn-danger",
        closeOnConfirm: true
      });
      this.setState({ loading: false });
    }

  }


  passwordLook() {
    var x = document.getElementById("password");
    if (x.type === "password") {
      this.setState({ eye: false })
      x.type = "text";
    } else {
      this.setState({ eye: true })
      x.type = "password";
    }
  }
  routeCheck() {
    if (!_.isEmpty(window.localStorage.getItem('roleCode'))) {
      this.props.history.push('/dashboard');
    }
  }




  formValidaton(id, value) {
    if (id === 'username') {
      let username = value;
      this.setState({ username: value });
      const exp1 = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const res1 = exp1.test(username);
      if (username === '') {
        this.setState({
          usernameValidation: "Email should not be empty*"
        })
      }
      else if (res1 !== true) {
        this.setState({
          usernameValidation: "Please Enter Valid Email*"
        })
      } else {
        this.setState({
          usernameValidation: ""
        })
      }
    }
    if (id === 'password') {
      let pass1 = value;
      this.setState({ password: value });

      const exp2 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
      const res2 = exp2.test(pass1);
      if (pass1 === '') {
        this.setState({
          passvalidation: "Password should not be empty*"
        })
      }
      else if (res2 !== true) {
        this.setState({
          passvalidation: "Please Enter Valid Password*"
        })
      } else {
        this.setState({
          passvalidation: ""
        })
      }
    }
  }
  verifyCodeHandler(OTP) {
    this.setState({
      loading: !this.state.loading,
      progress: 0.5,
    });
    let mobile = this.state.mobileNo.replace(/[- )(]/g, '');
    if(!mobile.includes("+")) {
      mobile = `+${mobile}`;
    }
    const temp = this;
    if (this.state.userData && this.state.userData.data && this.state.userData.data._id) {
      axios.post(`${common.path.base_url}/verifyMobileCode`, {
        id: this.state.userData.data._id,
        // mobile: this.state.mobileNo,
        mobile: mobile,
        code: OTP ? OTP : this.state.verifyCode
      })
        .then(function (response) {
          const userData = temp.state.userData;
          let { role, roleCode, roleName, _id, firstName, lastName, imagePath } = userData.data;
          let _role = roleCode ? roleCode : role;
          window.localStorage.setItem('emailAddress', temp.state.username);
          window.localStorage.setItem('id', _id);
          window.localStorage.setItem('token', userData.token);
          window.localStorage.setItem('username', `${firstName} ${lastName}`);
          window.localStorage.setItem('imagePath', imagePath);
          window.localStorage.setItem('role', roleName);
          window.localStorage.setItem('roleCode', _role);
          if (role === '005') {
            temp.props.setUserLogin(roleName);
            // if (temp.state.isYearInReviewVisited) {
            window.location.href = '/investor-dashboard';
            // } else {
            //   window.location.href = '/yearinreview';
            // }
          } else {

            temp.props.setUserLogin(roleName);
            temp.setState({ loading: false });
            temp.props.history.push('/dashboard');
          }
        })
        .catch((error) => {
          temp.setState({ loading: false });
          if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
            window.localStorage.clear();
            window.location.href = '/';
            return;
          }
          else {
            swal({
              title: "Invaild",
              text: 'Invaild verification code',
              icon: "error",
              button: "Ok!",
            });
          }
        });
    }
    // if (this.state.verifyCode != this.state.displayOTP) {
    //   swal({
    //     title: "Invaild Code!",
    //     text: "Please enter vaild code",
    //     icon: "error",
    //     button: "Ok!",
    //   });
    // } else {
    //   console.log(this.state.userData);
    //   const userData = this.state.userData;
    //   let { role, roleCode, roleName, _id, name, imagePath } = userData.data;
    //   let _role = roleCode ? roleCode : role;
    //   window.localStorage.setItem('emailAddress', this.state.username);
    //   window.localStorage.setItem('id', _id);
    //   window.localStorage.setItem('token', userData.token);
    //   window.localStorage.setItem('username', name);
    //   window.localStorage.setItem('imagePath', imagePath);
    //   window.localStorage.setItem('role', roleName);
    //   window.localStorage.setItem('roleCode', _role);
    //   this.props.history.push('/dashboard')
    // }


    //function call for verify the OTP
  }
  handleCodeChange(otp) {
    console.log(otp);
    this.setState({ verifyCode: otp });
    console.log(otp.length);
    if (otp.length === 6) {
      this.verifyCodeHandler(otp);
    }
  }
  resend() {
    const temp = this;
    if (this.state.userData && this.state.userData.data && this.state.userData.data._id) {
      axios.post(`${common.path.base_url}/sendOTP`, {
        id: this.state.userData.data._id,
      })
        .then(function (response) {
          temp.setState({ loading: false });
          console.log(response.data.message);
          if (response && response.data && response.data.message) {
            temp.setState({ displayOTP: response.data.data.otp });
            swal({
              title: "Code Sent",
              text: response.data.message,
              icon: "success",
              button: "Ok!",
            });
          }
        })
        .catch(function (error) {
          temp.setState({ loading: false });
          swal({
            title: "Network Error!",
            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
            icon: "error",
            button: "Ok!",
          });
        });
    }

  }
  maskingFunction = () => {
    let subNum = this.state.mobileNo && this.state.mobileNo.toString().replace("+", "").substring(0, 2)
    subNum = subNum + "-XXX-XXX-XX" + this.state.mobileNo.toString().slice(-2);
    return subNum
  }
  sendMobileOTP() {
    this.setState({
      loading: !this.state.loading,
      progress: 0.5,
    });
    let mobile = this.state.mobileNo.replace(/[- )(]/g, '');
    if(!mobile.includes("+")) {
      mobile = `+${mobile}`;
    }
    const temp = this;
    if (this.state.userData && this.state.userData.data && this.state.userData.data._id) {
      axios.post(`${common.path.base_url}/sendMobileOTP`, {
        id: this.state.userData.data._id,
        // mobile: this.state.mobileNo,
        mobile: mobile,
      })
        .then(function (response) {
          temp.setState({ loading: false, isMobileVerified: true });
          if (response && response.data && response.data.data && response.data.data.otp) {
            temp.setState({ displayOTP: response.data.data.otp });
          }
        })
        .catch(function (error) {
          temp.setState({ loading: false });
          swal({
            title: "Network Error!",
            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
            icon: "error",
            button: "Ok!",
          });
        });
    }
  }
  changeMobileNo() {
    this.setState({ isMobileVerified: false })
  }
  getFullYear() {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }
  render() {

    if (this.state.remembermeLogin) {
      return (<LoadingSpinner />)
    }

    return (
      <div className="loginPageCss">
        <Helmet>
          <meta charSet="utf-8" />
          <title>TSG</title>
        </Helmet>
        <CustomModal />
        <TSGReviewModal />
        {// removed this as client asked
          (0) ?
            <SCPModal /> : ""
        }
        <div className="login-container">
          <div className="LoginImage">
            <img src={images.path.login} alt='' style={{ width: '100%' }}></img>
          </div>
          {!this.state.isLoginSuccess ?


            <div className="LoginCredentials">
              <div className="login">Member Log In</div>
              <form >
                <div className="form-group">
                  {/* <div className={`form-group ${this.errorClass(this.state.formErrors.email)}`}> */}
                  <label className="control-label">Email Address</label>
                  {/* <div className="col-md-8"> */}
                  <input className="form-control" type="text"
                    name="username"
                    placeholder="Username"
                    id="username"
                    onChange={(event) => this.formValidaton(event.target.id, event.target.value)}
                    value={this.state.username}
                  // onChange={this.handleUserInput}
                  />
                  <p>{this.state.usernameValidation}</p>
                  <i className="fa fa-envelope icon"></i>
                  {/* </div> */}
                </div>
                <div className="form-group">
                  {/* <div className={`form-group ${this.errorClass(this.state.formErrors.password)}`}> */}
                  <label className="control-label">Password</label>
                  {/* <div className="col-md-8"> */}
                  <input className="form-control" id="password"
                    type="password"
                    name="password"
                    placeholder="Enter password"
                    onChange={(event) => this.formValidaton(event.target.id, event.target.value)}
                    value={this.state.password}
                  // onChange={this.handleUserInput}
                  />
                  <p>{this.state.passvalidation}</p>
                  <i className="fa fa-lock icon"></i>
                  <i className={`fa icon right ${(this.state.eye ? 'fa-eye-slash' : 'fa-eye')}`} onClick={() => this.passwordLook()}></i>
                  {/* </div> */}
                  {/* </div> */}
                </div>
                <div className="forgotPassword">
                  <div className="xyz">
                    <label className="container">
                      <input type="checkbox" id="checkbox" />

                    </label>
                    <a href="#forgotPasswordModal" data-toggle="modal">Forgot password?</a>
                  </div>
                </div>
                <LaddaButton
                  loading={this.state.loading}
                  onClick={this.routeChange}
                  className={!(this.state.passvalidation === '' && this.state.usernameValidation === '') ? 'disable' : ''}
                  data-color="#eee"
                  data-size={XL}
                  data-style={EXPAND_RIGHT}
                  data-spinner-size={30}
                  data-spinner-color="#ddd"
                  data-spinner-lines={12}
                >
                  Login
                </LaddaButton>
              </form>
              <div className="tsgReview">
                <a href="#tsgReviewModal" data-toggle="modal">Apply for TSG Membership</a>
              </div>
              {// removed this as client requested
                (0) ?
                  <div className="tsgReview">
                    <a href="#SCPModal" data-toggle="modal">Launch Your Own SCP</a>
                  </div> : ""
              }
              <div className="privacy-policy-login">
                <a href="javascript:;" onClick={() => this.props.history.push(`/privacyPolicy`)}> Privacy Policy </a>
                {/* <a href='' > Privacy Policy</a> */}
              </div>
              <div className="copyright">
                Copyright © {this.getFullYear()} The Syndicate Group. All rights reserved.
              </div>
            </div> :
            this.state.isMobileVerified ?
              <div className="content-2FA">
                <div className="content-inner-2FA">Enter your verification code</div>
                <div>Input the code we sent to {this.maskingFunction()} to access your account.</div>
                <div><a onClick={this.changeMobileNo}>Click here</a> to change your  mobile number</div>
                {/* <div className="form-group">
                    <label className="control-label"></label>
                    <input style={{ width: '100%', height: '42px', marginBottom: '5px'}} className="form-control" type="number" maxLength="6"
                      name="verificationCode"
                      id="verificationCode"
                      onChange={(event) => this.handleCodeChange(event)}
                    />
                    <a onClick={this.resend}>Resend Code</a>
                    {
                      this.state.displayOTP && 
                      <label>For testing enter this code {this.state.displayOTP}</label>
                    } 
                  </div> */}
                <div className="otp-section">
                  <OtpInput
                    onChange={otp => this.handleCodeChange(otp)}
                    numInputs={6}
                    isInputNum={false}
                    value={this.state.verifyCode}
                    shouldAutoFocus={true}

                  />
                  <div className="resend-section">
                    <a onClick={this.resend}>Resend Code by SMS and Email</a>
                    {
                      process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'devlopment' && this.state.displayOTP &&
                      <label>For testing enter this code {this.state.displayOTP}</label>
                    }
                  </div>
                </div>
                <div style={{ width: '100%', height: '42px' }}>
                  <LaddaButton
                    loading={this.state.loading}
                    onClick={this.verifyCodeHandler}
                    className={(this.state.verifyCode === null || this.state.verifyCode.length < 6) ? 'disable' : ''}
                    data-color="#eee"
                    data-size={XL}
                    data-style={EXPAND_RIGHT}
                    data-spinner-size={30}
                    data-spinner-color="#ddd"
                    data-spinner-lines={12}
                  >
                    Verify
                  </LaddaButton>
                </div>
              </div> :
              <div className="content-2FA">
                <div className="content-inner-2FA">Verify Phone Number</div>
                <div className="form-group">
                  <label className="col-md-3 control-label"></label>
                  <div className="col-md-8 mobile-num" style={{ width: '100%', height: '42px', marginBottom: '5px', paddingLeft: '0px', paddingRight: '2px' }}>
                    <ReactPhoneInput country={'us'}
                      id="mobile"
                      onChange={(value) => this.setState({ mobileNo: value })}
                      placeholder='Mobile'
                      disableAreaCodes={true}
                      countryCodeEditable={false}
                      autoComplete="none"
                      value={this.state.mobileNo} />
                    {/* <p style={{ color: 'black', 'paddingTop': "5px", }} >We will contact you at the number above with a verification code.</p> */}
                  </div>

                  {/* <input style={{ width: '100%', height: '42px', marginBottom: '5px'}} className="form-control" type="number" maxLength="6"
                      name="verificationCode"
                      id="verificationCode"
                      onChange={(event) => this.handleCodeChange(event)}
                    /> */}
                </div>
                {/* <div className="resend-section">
                <a onClick={this.resend}>Resend Code</a>
                    {
                      process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'devlopment' && this.state.displayOTP && 
                      <label>For testing enter this code {this.state.displayOTP}</label>
                    }                 
                </div> */}
                <div style={{ width: '100%', height: '42px' }}>
                  <LaddaButton
                    loading={this.state.loading}
                    onClick={this.sendMobileOTP}
                    className={!this.state.mobileNo ? 'disable' : ''}
                    data-color="#eee"
                    data-size={XL}
                    data-style={EXPAND_RIGHT}
                    data-spinner-size={30}
                    data-spinner-color="#ddd"
                    data-spinner-lines={12}
                  >
                    Verify
                  </LaddaButton>
                </div>
              </div>
          }
        </div>
      </div >
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUserLogin: bindActionCreators(setUserLogin, dispatch),
  };
};

//export default withRouter(LoginPage);
export default withRouter(connect(null, mapDispatchToProps)(LoginPage));
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, withRouter } from "react-router-dom";
//import LoadingSpinner from '../Loader';
import axios from 'axios';
import swal from 'sweetalert';
import Select from 'react-select';
import common from '../../common';
import './Profile.scss';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import _ from 'lodash';
import OtpInput from 'react-otp-input';

class UserCheckModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key:1,
            inputFile:null,
            verifyCode : null,
            notes : null
        }
    }
    
    componentDidMount() {
        console.log("ssss",this.props)
        
    }

    componentWillMount() {
    //    this.props.onRef && this.props.onRef(this);
    }

    maskingFunction = () => {
        
        // let mobile = window.localStorage.getItem('mobile');
        // let subNum = (this.props.mobile != null) ? this.props.mobile.toString().substring(0, 2) : null;
        // subNum = (subNum != null) ?  subNum + "-XXX-XXX-XX" + this.props.mobile.toString().slice(-2) : "";
        // return subNum
    }
    
    sendNoteVerifyCode() {

        const userRoleCode = window.localStorage.getItem('roleCode');

        if( userRoleCode === '001' || userRoleCode === '002') {
            
            let temp = this;
            let tokenpass = window.localStorage.getItem('token');
            let id = window.localStorage.getItem('id');
            const param = {
                id: id,
                userId: this.props.userId ? this.props.userId : id,
                verifyCode : this.state.verifyCode
            }

            //console.log(param);

            axios.post(`${common.path.base_url}/verifyNotesOtp/`, param, {
                headers: {
                    "Authorization": tokenpass
                }
            }).then(function (response) {
                // const investorReferralList = response.data.data.map(opt => ({ label: opt.name, value: opt._id, user: opt }));
                // temp.setState({ investorReferralList });
                if( response.data.status == 'success' ) {
                
                    const notes = response.data.data.notes;
                    //temp.setState({ 'notes': notes });
                    temp.props.checkNotes(notes);
                    document.getElementById('cancel-send').click();
                
                } else {
                    swal({
                        title: "Error!",
                        text: response.data.message,
                        icon: "error",
                        button: "Ok!",
                    }); 
                }

            }).catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                
                } else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                
                } else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
            
        } else {
            swal({
                //title: "You are not authorized to access this Note !",
                text: "You are not authorized to access this Note",
                icon: "warning",
                button: "Ok!",
            });
        }

    }

    handleCodeChange(otp) {
        console.log(otp);
        this.setState({ verifyCode: otp });
    }

    render() {
        return (
            <div>
                {/* <!-- Invite Modal HTML --> */}
                <div id="sendOTPModal" className="modal fade" style={{ "z-index" : "9999"}}>
                    <div className="modal-dialog" key={this.state.key}>
                        <div className="modal-content invite-contact card-mess">
                            {/* <form> */}
                                <div className="modal-header">
                                    <h4 className="modal-title">Verify OTP</h4>
                                </div>
                                <div className="modal-body">
                                    <form className="form-horizontal" id='myform'>
                                        <div className="form-group text-center">
                                            
                                            <div className='verifyCode'><b>Enter your verification code</b></div>
                                            {/* <div className='verifyText'>Input the code we sent to { this.maskingFunction() } to access this notes.</div> */}
                                            
                                            <div className="otp-section">
                                                <OtpInput
                                                    onChange={otp => this.handleCodeChange(otp)}
                                                    numInputs={6}
                                                    isInputNum={false}
                                                    value={this.state.verifyCode}
                                                    shouldAutoFocus={true}
                                                    style ={{ 'justify-content': 'center' }}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                
                                <div className="modal-footer">
                                    {/* <input type="button" id="closeEditModal" className="btn btn-danger"
                                    onClick={() => this.sendInvitation()} value="SEND" /> */}
                                    <LaddaButton
                                        id='lada-btn'
                                        onClick={() => this.sendNoteVerifyCode()}
                                        data-color="#eee"
                                        data-size={XS}
                                        data-style={EXPAND_RIGHT}
                                        data-spinner-size={30}
                                        data-spinner-color="#ddd"
                                        data-spinner-lines={12}
                                        style={{ "padding":"6px 4px", "fontSize":"20px"}}
                                    >
                                        SUBMIT
                                    </LaddaButton>
                                    <input type="button" id="cancel-send" className="btn btn-default" data-dismiss="modal" value="CANCEL" ref={this.closeSmsEmail } />
                                </div>
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default UserCheckModal;
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect, withRouter } from "react-router-dom";
//import 'react-phone-input-2/lib/style.css';
import "./ContactMessage.scss";
import LoadingSpinner from "../Loader";
import DatePicker from "react-datepicker";
import axios from "axios";
import swal from "sweetalert";
import Select from "react-select";
import common from "../../common";
import LaddaButton, { XS, EXPAND_RIGHT } from "react-ladda";
import _ from "lodash";
import Moment from "react-moment";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import RequiredSign from "../../components/RequiredSign";

class ContactSendMessageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 1,
      subject: "",
      smsMessage: "",
      emailMessage: "",
      zoomlink1: "",
      zoomlink2: "",
      messageType: this.props.webinarType == "YES" ? 0 : 1,
      checkRadio: "both",
      inputFile: null,
      fileText: "",
      getstrategic: [
        { value: 0, label: "Select Type" },
        { value: 1, label: "General" },
        { value: 2, label: "Webinar" },
      ],
      startDate1: "",
      startDate2: "",
      webinarDesc1: "",
      webinarDesc2: "",
    };
    this.onValueChange = this.onValueChange.bind(this);
    this.closeSmsEmail = React.createRef();
  }

  componentDidMount() {
    console.log("sadasdasdsad", this.props.investment);
  }

  componentWillMount() {
    this.props.onRef && this.props.onRef(this);
  }

  resetMessageData() {
    this.setState({
      subject: "",
      message: "",
      inputFile: null,
      fileText: "",
    });
  }

  onValueChange(event) {
    this.setState({ checkRadio: event.target.value });
  }

  handleChange(id, newValue) {
    //console.log(id, newValue);
    if (id === "subject") {
      this.setState({ subject: newValue });
    } else if (id === "email_message") {
      this.setState({ emailMessage: newValue });
    } else if (id === "sms_message") {
      this.setState({ smsMessage: newValue });
    } else if (id === "zoomlink1") {
      this.setState({ zoomlink1: newValue });
    } else if (id === "zoomlink2") {
      this.setState({ zoomlink2: newValue });
    } else if (id === "messageType") {
      const linkUrl1 =
        this.props.investment.webinarLink1 &&
          this.props.investment.webinarLink1.link != null
          ? this.props.investment.webinarLink1.link
          : "";
      const date1 =
        this.props.investment.webinarLink1 &&
          this.props.investment.webinarLink1.link != null
          ? this.props.investment.webinarLink1.eventDate
          : "";
      const linkUrl2 =
        this.props.investment.webinarLink2 &&
          this.props.investment.webinarLink2.link != null
          ? this.props.investment.webinarLink2.link
          : "";
      const date2 =
        this.props.investment.webinarLink2 &&
          this.props.investment.webinarLink2.link != null
          ? this.props.investment.webinarLink2.eventDate
          : "";

      const webDesc1 =
        this.props.investment.webinarLink1 &&
          this.props.investment.webinarLink1.linkDesc != null
          ? this.props.investment.webinarLink1.linkDesc
          : "";
      const webDesc2 =
        this.props.investment.webinarLink2 &&
          this.props.investment.webinarLink2.linkDesc != null
          ? this.props.investment.webinarLink2.linkDesc
          : "";

      const emailMessage =
        this.props.investment.webinarEmailMessage &&
          this.props.investment.webinarEmailMessage != null &&
          newValue == 2
          ? this.props.investment.webinarEmailMessage
          : "";

      this.setState({
        messageType: newValue,
        zoomlink1: linkUrl1,
        startDate1: date1 != "" && date1 != null ? new Date(date1) : "",
        zoomlink2: linkUrl2,
        startDate2: date2 != "" && date2 != null ? new Date(date2) : "",
        webinarDesc1: webDesc1,
        webinarDesc2: webDesc2,
        emailMessage: emailMessage,
      });
    } else if (id == "webinar_desc1") {
      this.setState({ webinarDesc1: newValue });
    } else if (id == "webinar_desc2") {
      this.setState({ webinarDesc2: newValue });
    }
  }

  sendInvitation() {
    console.log(this.state.checkRadio);
    if (this.state.messageType != "" && this.state.messageType != 0) {
      if (this.props.pageLocation != "mcl") {
        // Only Cron Job Code

        var childthis = this;
        const getSelectedCheckBox = this.props.getSelectedCheckBox;
        let subject = this.state.subject;
        let emailMessage = this.state.emailMessage;
        let smsMessage = this.state.smsMessage;
        let zoomlink1 = this.state.zoomlink1;
        let zoomlink2 = this.state.zoomlink2;
        let startDate1 = this.state.startDate1;
        let startDate2 = this.state.startDate2;

        subject = subject.trim();
        emailMessage = emailMessage.trim();
        smsMessage = smsMessage.trim();
        zoomlink1 = zoomlink1.trim();

        if (
          (this.state.checkRadio == "both" ||
            this.state.checkRadio == "email") &&
          (emailMessage.length <= 0 || subject.length <= 0)
        ) {
          let messCode;
          if (emailMessage.length <= 0) {
            messCode = "Please enter email message";
          } else if (subject.length <= 0) {
            messCode = "Please enter email subject";
          }
          swal({
            title: "Error!",
            text: messCode,
            icon: "error",
            button: "Ok!",
          });
        } else if (
          (this.state.checkRadio == "both" || this.state.checkRadio == "sms") &&
          smsMessage.length <= 0
        ) {
          swal({
            title: "Error!",
            text: "Please enter message",
            icon: "error",
            button: "Ok!",
          });
        } else if (
          this.state.messageType == "2" &&
          (zoomlink1.length <= 0 || startDate1 == null || startDate1 == "")
        ) {
          let messCode;
          if (zoomlink1.length <= 0) {
            messCode = "Please enter webinar link";
          } else if (startDate1 == null || startDate1 == "") {
            messCode = "Please enter webinar date";
          }

          swal({
            title: "Error!",
            text: messCode,
            icon: "error",
            button: "Ok!",
          });
        } else if (
          this.state.messageType == "2" &&
          ((zoomlink2.length > 0 && (startDate2 == null || startDate2 == "")) ||
            (zoomlink2.length <= 0 && startDate2 != null && startDate2 != ""))
        ) {
          console.log(zoomlink2.length, startDate2);
          let messCode;
          if (zoomlink2.length <= 0) {
            messCode = "Please enter webinar link2";
          } else if (startDate2 == null || startDate2 == "") {
            messCode = "Please enter webinar date2";
          }

          swal({
            title: "Error!",
            text: messCode,
            icon: "error",
            button: "Ok!",
          });
        } else if (
          this.props.getCheckBoxAll ||
          getSelectedCheckBox.length > 0
        ) {
          let checkRadio = this.state.checkRadio;
          let inputFile = this.state.inputFile;
          let messageType = this.state.messageType;
          let webinarDesc1 = this.state.webinarDesc1;
          let webinarDesc2 = this.state.webinarDesc2;
          let getRemoveCheckBox = this.props.getRemoveCheckBox;
          let getCheckBoxAll = this.props.getCheckBoxAll;
          let pageLocation = this.props.pageLocation
            ? this.props.pageLocation
            : null;

          let filterSearchValue = this.props.filterVal
            ? this.props.filterVal
            : null;
          let filterFollowupValue = this.props.followupOptionVal
            ? this.props.followupOptionVal
            : null;
          let filterUserStatusValue = this.props.userIndicatorVal
            ? this.props.userIndicatorVal
            : null;

          // console.log(this.props.getCheckBoxAll);
          // console.log(getSelectedCheckBox);
          // console.log(this.props.getRemoveCheckBox);

          const formatedDate1 =
            startDate1 != null
              ? moment(startDate1).format("dddd MMMM DD, Y hh:mm A")
              : "";
          const formatedDate2 =
            startDate2 != null
              ? moment(startDate2).format("dddd MMMM DD, Y hh:mm A")
              : "";

          let data = new FormData();
          data.append("file", inputFile);

          const dataArr = JSON.stringify({
            checkBoxAll: getCheckBoxAll,
            selectedCheckBox: getSelectedCheckBox,
            removeCheckBox: getRemoveCheckBox,
            subject: subject,
            smsMessage: smsMessage,
            emailMessage: emailMessage,
            checkRadio: checkRadio,
            type: messageType,
            investmentId: this.props.investment._id
              ? this.props.investment._id
              : "",
            zoomlink1: zoomlink1,
            zoomlink2: zoomlink2,
            startDate1: startDate1,
            startDate2: startDate2,
            formatedDate1: formatedDate1,
            formatedDate2: formatedDate2,
            pageLocation: pageLocation,
            webinarDesc1: webinarDesc1,
            webinarDesc2: webinarDesc2,
            filterSearchValue: filterSearchValue,
            filterFollowupValue: filterFollowupValue,
            filterUserStatusValue: filterUserStatusValue,
          });
          data.append("data", dataArr);
          let token = window.localStorage.getItem("token");

          axios
            .post(`${common.path.base_url}/addRemoteJobTask`, data, {
              headers: {
                Authorization: token,
              },
            })
            .then((response) => {
              if (response.data.status == "success") {
                swal({
                  title: "Sent",
                  text: response.data.message,
                  icon: "success",
                  button: "Ok!",
                }).then(function () {
                  childthis.closeSmsEmail.current.click();
                  childthis.props.resetSMSEmailCard();
                });
              } else {
                swal({
                  title: "Error!",
                  text: response.data.message,
                  icon: "error",
                  button: "Ok!",
                });
              }
            })
            .catch(function (error) {
              swal({
                title: "Error!",
                text: error.response.data.message,
                icon: "error",
                button: "Ok!",
              });
            });
        } else {
          swal({
            title: "Error!",
            text: "Please select investor",
            icon: "error",
            button: "Ok!",
          });
        }
      } else {
        //console.log("govindddd565");
        var childthis = this;
        const usersMail = this.props.allCheckbox;
        const usersMobile = this.props.allSelectMobile;

        if (usersMail.length > 0 || usersMobile.length > 0) {
          let subject = this.state.subject;
          let smsMessage = this.state.smsMessage;
          let emailMessage = this.state.emailMessage;
          let checkRadio = this.state.checkRadio;
          let inputFile = this.state.inputFile;
          let messageType = this.state.messageType;

          let resMess = "";
          if (checkRadio == "email") {
            resMess = "Email Sent";
          } else if (checkRadio == "sms") {
            resMess = "SMS Sent";
          } else if (checkRadio == "both") {
            resMess = "Email & SMS Sent";
          }

          if (subject.trim() == "" && checkRadio != "sms") {
            swal({
              title: "Error!",
              text: "Please enter subject",
              icon: "error",
              button: "Ok!",
            });
          } else if (
            (checkRadio != "email" && smsMessage.trim() == "") ||
            (checkRadio != "sms" && emailMessage.trim() == "")
          ) {
            swal({
              title: "Error!",
              text: "Please enter message",
              icon: "error",
              button: "Ok!",
            });
          } else {
            let data = new FormData();
            data.append("file", inputFile);

            const dataArr = JSON.stringify({
              usersMail: usersMail,
              usersMobile: usersMobile,
              subject: subject,
              smsMessage: smsMessage,
              emailMessage: emailMessage,
              checkRadio: checkRadio,
              type: messageType,
            });
            data.append("data", dataArr);
            let token = window.localStorage.getItem("token");

            //console.log("asdasdsad..",dataArr);

            axios
              .post(`${common.path.base_url}/mclEmailSend`, data, {
                headers: {
                  Authorization: token,
                },
              })
              .then((response) => {
                if (response.data.status == "success") {
                  swal({
                    title: resMess,
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                  }).then(function () {
                    childthis.closeSmsEmail.current.click();
                    childthis.props.resetSMSEmailCard();
                  });
                } else {
                  swal({
                    title: "Error!",
                    text: response.data.message,
                    icon: "error",
                    button: "Ok!",
                  });
                }
              })
              .catch(function (error) {
                swal({
                  title: "Error!",
                  text: error.response.data.message,
                  icon: "error",
                  button: "Ok!",
                });
              });
          }
        } else {
          swal({
            title: "Error!",
            text: "Please select user",
            icon: "error",
            button: "Ok!",
          });
        }
      }
    } else {
      swal({
        title: "Error!",
        text: "Please select message type",
        icon: "error",
        button: "Ok!",
      });
    }
  }

  chooseFile() {
    document.getElementById("choose-browse-file").click();
  }

  previewFile() {
    //1const preview = document.getElementById('preview');
    const file = document.querySelector("input[type=file]").files[0];
    const data = new FormData();
    data.append("file", file);
    this.setState({ fileText: file.name, inputFile: file });
    // const reader = new FileReader();

    // reader.addEventListener("load", () => {
    //     preview.src = reader.result;
    //     this.validateProfileForm();
    // }, false);

    // if (file) {
    //     reader.readAsDataURL(file);
    // }
  }

  setStartDate(datetime, type) {
    if (type == 1) {
      this.setState({
        startDate1: datetime != null ? new Date(datetime) : null,
      });
    } else if (type == 2) {
      this.setState({
        startDate2: datetime != null ? new Date(datetime) : null,
      });
    }
  }
  filterPassedTime() { }

  render() {
    return (
      <div>
        {/* <!-- Invite Modal HTML --> */}
        <div id="sendMessageModal" className="modal fade">
          <div className="modal-dialog " key={this.state.key}>
            <div className="modal-content invite-contact card-mess">
              {/* <form> */}
              <div className="modal-header">
                <h4 className="modal-title">Message</h4>
              </div>
              <div className="modal-body">
                <form className="form-horizontal" id=" myform">
                  {this.props.webinarType == "YES" && (
                    <div className="form-group">
                      <label className="col-lg-3 col-sm-3  col-md-3 control-label">
                        Message Type:
                        <RequiredSign />
                      </label>
                      <div className="col-lg-9 col-sm-9 col-md-9">
                        <Select
                          id="messageType"
                          options={this.state.getstrategic}
                          onChange={(event) =>
                            this.handleChange("messageType", event.value)
                          }
                          className=""
                        />
                      </div>
                    </div>
                  )}
                  <div className="form-group">
                    <label className="col-lg-3 col-sm-3  col-md-3 control-label"></label>
                    <div className="col-lg-9 col-sm-9 col-md-9">
                      <div className="col-md-3 padding-left-zero">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="radio3"
                          name="optradio"
                          value="both"
                          checked={this.state.checkRadio == "both"}
                          onChange={this.onValueChange}
                        />
                        &nbsp;
                        <label className="form-check-label" htmlFor="radio3">
                          {" "}
                          Email & SMS{" "}
                        </label>
                      </div>
                      <div className="col-md-2">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="radio1"
                          name="optradio"
                          value="email"
                          checked={this.state.checkRadio == "email"}
                          onChange={this.onValueChange}
                        />
                        &nbsp;
                        <label className="form-check-label" htmlFor="radio1">
                          {" "}
                          Email
                        </label>
                      </div>
                      <div className="col-md-2">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="radio2"
                          name="optradio"
                          value="sms"
                          checked={this.state.checkRadio == "sms"}
                          onChange={this.onValueChange}
                        />
                        &nbsp;
                        <label className="form-check-label" htmlFor="radio2">
                          {" "}
                          SMS
                        </label>
                      </div>
                    </div>
                  </div>

                  {(this.state.checkRadio == "email" ||
                    this.state.checkRadio == "both") && (
                      <div className="form-group">
                        <label className="col-lg-3 col-sm-3  col-md-3 control-label">
                          Subject:
                          <RequiredSign />
                        </label>
                        <div className="col-lg-9 col-sm-9 col-md-9">
                          <input
                            className="form-control"
                            id="subject"
                            name="subject"
                            type="text"
                            placeholder=""
                            value={this.state.subject}
                            onChange={(event) =>
                              this.handleChange(
                                event.target.id,
                                event.target.value
                              )
                            }
                          />
                          <p></p>
                          {this.state.checkRadio == "both" && (
                            <span style={{ fontStyle: "italic" }}>
                              This subject is not applicable on SMS
                            </span>
                          )}
                        </div>
                      </div>
                    )}

                  {(this.state.checkRadio == "email" ||
                    this.state.checkRadio == "both") && (
                      <div className="form-group">
                        <label className="col-lg-3 col-sm-3  col-md-3 control-label">
                          Email Message:
                          <RequiredSign />
                        </label>
                        <div className="col-lg-9 col-sm-9 col-md-9">
                          <CKEditor
                            id="emailhtml"
                            editor={ClassicEditor}
                            data={this.state.emailMessage}
                            onInit={(editor) => {
                              // You can store the "editor" and use when it's needed.
                              console.log("Editor is ready to use!", editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log( { event, editor, data } );
                              this.handleChange("email_message", data);
                            }}
                          />
                        </div>
                      </div>
                    )}

                  {(this.state.checkRadio == "sms" ||
                    this.state.checkRadio == "both") && (
                      <div className="form-group">
                        <label className="col-lg-3 col-sm-3  col-md-3 control-label">
                          SMS Message:
                          <RequiredSign />
                        </label>
                        <div className="col-lg-9 col-sm-9 col-md-9">
                          <textarea
                            className="form-control"
                            name="sms_message"
                            id="sms_message"
                            type="message"
                            placeholder="Message"
                            rows="5"
                            cols="50"
                            onChange={(event) =>
                              this.handleChange(
                                event.target.id,
                                event.target.value
                              )
                            }
                            value={this.state.smsMessage}
                          ></textarea>
                        </div>
                      </div>
                    )}
                  {(this.state.checkRadio == "email" ||
                    this.state.checkRadio == "both") && (
                      <div className="form-group">
                        <label className="col-lg-3 col-sm-3  col-md-3 control-label">
                          Attachment :
                        </label>
                        <div className="col-lg-9 col-sm-9 col-md-9">
                          <button
                            type="button"
                            onClick={() => this.chooseFile()}
                            className="btn btn-default"
                            style={{
                              margin: "6px 0px 6px",
                              float: "left",
                              color: "#ffffff",
                              backgroundColor: "#ef534a",
                              border: "0",
                            }}
                          >
                            BROWSE
                          </button>
                          <input
                            id="choose-browse-file"
                            className="file-upload"
                            name="browse"
                            type="file"
                            onChange={() => this.previewFile()}
                          />
                          <span
                            style={{
                              margin: "10px 15px",
                              display: "inline-block",
                            }}
                          >
                            {this.state.fileText}
                          </span>

                          {this.state.checkRadio == "both" && (
                            <span
                              style={{
                                fontStyle: "italic",
                                clear: "both",
                                display: "block",
                              }}
                            >
                              This attachment is not applicable on SMS
                            </span>
                          )}
                        </div>
                      </div>
                    )}

                  {this.state.messageType == "2" && (
                    <>
                      <div className="form-group">
                        <label className="col-lg-3 col-sm-3  col-md-3 control-label">
                          Webinar Link1:
                          <RequiredSign />
                        </label>
                        <div className="col-lg-9 col-sm-9 col-md-9">
                          <input
                            className="form-control"
                            id="zoomlink1"
                            name="zoomlink1"
                            type="text"
                            placeholder=""
                            value={this.state.zoomlink1}
                            onChange={(event) =>
                              this.handleChange(
                                event.target.id,
                                event.target.value
                              )
                            }
                          />
                        </div>
                      </div>

                      <div className="form-group messpopup">
                        <label className="col-lg-3 col-sm-3  col-md-3 control-label">
                          Webinar Date1:
                          <RequiredSign />
                        </label>
                        <div className="col-lg-9 col-sm-9 col-md-9">
                          <DatePicker
                            selected={this.state.startDate1}
                            onChange={(date) => this.setStartDate(date, 1)}
                            showTimeSelect
                            filterTime={this.filterPassedTime()}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            className={"form-control"}
                            popperPlacement="top-start"
                          />
                          {
                            //console.log("govind", this.props.investment)
                            //this.state.investment.webinarLink1.eventDate
                          }
                        </div>
                      </div>

                      <div className="form-group webinar-link-desc">
                        <label className="col-lg-3 col-sm-3  col-md-3 control-label">
                          Webinar DESC1:
                        </label>
                        <div className="col-lg-9 col-sm-9 col-md-9">
                          <CKEditor
                            id="webinarDesc1"
                            editor={ClassicEditor}
                            data={this.state.webinarDesc1}
                            onInit={(editor) => {
                              // You can store the "editor" and use when it's needed.
                              console.log("Editor is ready to use!", editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log( { event, editor, data } );
                              this.handleChange("webinar_desc1", data);
                            }}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-lg-3 col-sm-3  col-md-3 control-label">
                          Webinar Link2:
                        </label>
                        <div className="col-lg-9 col-sm-9 col-md-9">
                          <input
                            className="form-control"
                            id="zoomlink2"
                            name="zoomlink2"
                            type="text"
                            placeholder=""
                            value={this.state.zoomlink2}
                            onChange={(event) =>
                              this.handleChange(
                                event.target.id,
                                event.target.value
                              )
                            }
                          />
                        </div>
                      </div>

                      <div className="form-group messpopup">
                        <label className="col-lg-3 col-sm-3  col-md-3 control-label">
                          Webinar Date2:
                        </label>
                        <div className="col-lg-9 col-sm-9 col-md-9">
                          <DatePicker
                            selected={this.state.startDate2}
                            onChange={(date) => this.setStartDate(date, 2)}
                            showTimeSelect
                            filterTime={this.filterPassedTime()}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            className={"form-control"}
                            popperPlacement="top-start"
                          />
                        </div>
                      </div>
                      <div className="form-group webinar-link-desc">
                        <label className="col-lg-3 col-sm-3  col-md-3 control-label">
                          Webinar DESC2:
                        </label>
                        <div className="col-lg-9 col-sm-9 col-md-9">
                          <CKEditor
                            id="webinarDesc2"
                            editor={ClassicEditor}
                            data={this.state.webinarDesc2}
                            onInit={(editor) => {
                              // You can store the "editor" and use when it's needed.
                              console.log("Editor is ready to use!", editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log( { event, editor, data } );
                              this.handleChange("webinar_desc2", data);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </form>
              </div>

              <div className="modal-footer">
                {/* <input type="button" id="closeEditModal" className="btn btn-danger"
                                        onClick={() => this.sendInvitation()} value="SEND" /> */}
                <LaddaButton
                  id="lada-btn"
                  //loading={this.state.loadinglada}
                  onClick={() => this.sendInvitation()}
                  data-color="#eee"
                  data-size={XS}
                  //className={!this.state.validInvitaionForm ? 'disable' : ''}
                  data-style={EXPAND_RIGHT}
                  data-spinner-size={30}
                  data-spinner-color="#ddd"
                  data-spinner-lines={12}
                  style={{ padding: "6px 4px", fontSize: "20px" }}
                >
                  SUBMIT
                </LaddaButton>
                <input
                  type="button"
                  id="cancel"
                  className="btn btn-default"
                  data-dismiss="modal"
                  value="CANCEL"
                  ref={this.closeSmsEmail}
                />
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//export default SummaryStartupsList;
const mapStateToProps = (state) => {
  return {
    investment:
      state.summaryStartup && state.summaryStartup.investment
        ? state.summaryStartup.investment
        : "",
  };
};

export default withRouter(
  connect(mapStateToProps, null)(ContactSendMessageModal)
);

import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import axios from 'axios';
import images from "../../images";
import common from '../../common';
import swal from 'sweetalert';
import './pipelineCss/pipeline.scss';
import './pipelineCss/default.scss';
import { Icons } from './Icons';
import NumberFormat from 'react-number-format';

const SeedData = (props) => {

    const { index, singleData, removeSeedHandle, updateSeed } = props;

    const [generalCall, setGeneralCall] = useState(false);
    const [seedEditArr, setSeedEditArr] = useState([]);
    const [seedEditTd1, setSeedEditTd1] = useState('');
    const [seedEditTd2, setSeedEditTd2] = useState('');
    const [seedEditTd3, setSeedEditTd3] = useState('');
    const [seedEditTd4, setSeedEditTd4] = useState('');
    const [seedEditTd5, setSeedEditTd5] = useState('');
    const [seedEditTd6, setSeedEditTd6] = useState('');
    
    const inputHandlerChange = (e)  => {

        const inputId = e.target.id;
        let inputValue = e.target.value;
        setGeneralCall(true);
        if(inputId == 'seedEditTd1') {
            setSeedEditTd1(inputValue);
        } else if(inputId == 'seedEditTd2') {
            setSeedEditTd2(inputValue);
        } else if(inputId == 'seedEditTd3') {
            setSeedEditTd3(inputValue);
        } else if(inputId == 'seedEditTd4') {
            setSeedEditTd4(inputValue);
        } else if(inputId == 'seedEditTd5') {
            setSeedEditTd5(inputValue);
        } else if(inputId == 'seedEditTd6') {
            setSeedEditTd6(inputValue);
        }
    }    

    useEffect(() => {
        
        setSeedEditTd1( singleData.series );
        setSeedEditTd2( singleData.date );
        setSeedEditTd3( singleData.raised );
        setSeedEditTd4( singleData.valuation );
        setSeedEditTd5( singleData.pps );
        setSeedEditTd6( singleData.investors );
    
    }, [ singleData ]);

    useEffect( () => {

        if(generalCall) {
            
            const newArr = {
                'series' : seedEditTd1.trim(),
                'date' : seedEditTd2.trim(),
                'raised' : seedEditTd3.trim(),
                'valuation' : seedEditTd4.trim(),
                'pps' : seedEditTd5.trim(),
                'investors' : seedEditTd6.trim(),
            }
            
            //setSeedEditArr([...seedEditArr, newArr]);
            //console.log(newArr);
            updateSeed(index, newArr);
        }
    }, [ seedEditTd1, seedEditTd2, seedEditTd3, seedEditTd4, seedEditTd5, seedEditTd6 ]);

    return (
        <>  
            <tr key={ index+"_seed" } >
                <td>
                    <input id="seedEditTd1" type="text" className='compState piple-input' placeholder='Enter Series' value={ seedEditTd1 } onChange={ (e) => inputHandlerChange(e) } />
                </td>
                <td>
                    <input id="seedEditTd2" type="text" className='compState piple-input' placeholder='Enter Date' value={ seedEditTd2 } onChange={ (e) => inputHandlerChange(e) } />
                </td>
                <td>
                    {/* <input id="seedEditTd3" type="text" className='compState piple-input' placeholder='Enter Raised' value={ seedEditTd3 } onChange={ (e) => inputHandlerChange(e) } /> */}
                    <NumberFormat
                        value={ seedEditTd3 }
                        thousandSeparator={true}
                        id="seedEditTd3"
                        prefix={'$'}
                        fixedDecimalScale={true}
                        placeholder='Enter Raised'
                        className='compState piple-input'
                        onChange={ (e) => inputHandlerChange(e) }
                    />
                </td>
                <td>
                    {/* <input id="seedEditTd4" type="text" className='compState piple-input' placeholder='Enter Valuation' value={ seedEditTd4 } onChange={ (e) => inputHandlerChange(e) } /> */}
                    <NumberFormat
                        value={ seedEditTd4 }
                        thousandSeparator={true}
                        id="seedEditTd4"
                        prefix={'$'}
                        fixedDecimalScale={true}
                        placeholder='Enter Valuation'
                        className='compState piple-input'
                        onChange={ (e) => inputHandlerChange(e) }
                    />
                </td>
                <td>
                    {/* <input id="seedEditTd5" type="text" className='compState piple-input' placeholder='Enter PPS' value={ seedEditTd5 } onChange={ (e) => inputHandlerChange(e) } /> */}
                    <NumberFormat
                        value={ seedEditTd5 }
                        thousandSeparator={true}
                        id="seedEditTd5"
                        prefix={'$'}
                        fixedDecimalScale={true}
                        placeholder='Enter PPS'
                        className='compState piple-input'
                        onChange={ (e) => inputHandlerChange(e) }
                    />
                </td>
                <td>
                    <input id="seedEditTd6" type="text" className='compState piple-input' placeholder='Enter Investors' value={ seedEditTd6 } onChange={ (e) => inputHandlerChange(e) } />
                </td>
                <td><div title='Delete' className='tableTrashicon' onClick={(e) => { removeSeedHandle(index); }}>{Icons.Trash}</div></td>
            </tr>
        </>
    )
}

export default SeedData;
import React, { Component } from 'react';
import './ParticipateCard.scss';
import _ from 'lodash';
import RequiredSign from '../../components/RequiredSign';
import Select from 'react-select';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import common from '../../common';
import images from "../../images";
import TButton from '../../components/TButton';
import axios from 'axios';
import swal from 'sweetalert';
import DatePicker from "react-datepicker";
import NumberFormat from 'react-number-format';



class ParticipateCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedType: '',
            types: [{ value: 'entity', label: 'Entity' }, { value: 'individual', label: 'Individual' }],
            isEntity: true,
            amount: '',
            type: 'entity',
            entity: '',
            firstName: '',
            middleName: '',
            lastName: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipcode: '',
            // isDocument: true,
            // isExecute: false,
            // isFund: false,
            enableCheckbox1: false,
            enableCheckbox2: false,
            wireDate: '',
            wireConfirmation: '',
            depositeDate: '',
            enableCard1: true,
            enableCard2: false,
            documents: null,
        }
    }
    getTabStatus(investmentDetail, type) {
        let isActive = false;
        switch(type){
            case 'document':
              if (investmentDetail && (investmentDetail.status === 'Invited' || investmentDetail.status === 'Participate')) {
                isActive = true;
              } else {
                isActive = false;
              }
              break;
            case 'execute':
                if (investmentDetail && investmentDetail.status === 'Interested') {
                    isActive = true;
                } else {
                    isActive = false;
                }
             break;
            case 'fund':
                if (investmentDetail && 
                    (investmentDetail.status === 'Executed' || investmentDetail.status === 'Payment' ||
                    investmentDetail.status === 'Complete')) {
                    isActive = true;
                } else {
                    isActive = false;
                }
            break;
            default:
                return false;
        }
        return isActive;

    }
    componentDidUpdate(prevProps) {
        if (this.props.invitedInvestment.investmentprocesses !== prevProps.invitedInvestment.investmentprocesses){
            console.log(82, this.props.invitedInvestment);
            const investmentProcesses = this.props.invitedInvestment.investmentprocesses;
            if (investmentProcesses && investmentProcesses.length > 0 &&
                investmentProcesses[0].status !== 'Invited' 
                && investmentProcesses[0].status !== 'Not Interested'
                && investmentProcesses[0].status !== 'Complete'  ) {
                const investmentDetail = investmentProcesses[0];
                let selectedType = { value: 'entity', label: 'Entity'};
                let isEntity = true;
                if (investmentDetail && investmentDetail.type === 'individual') {
                    selectedType = { value: 'individual', label: 'Individual'};
                    isEntity = false;
                }
                this.setState({
                    documents: {
                        [investmentDetail._id]: {
                            amount: investmentDetail.amount,
                            status: investmentDetail.status,
                            type: investmentDetail.type ? investmentDetail.type : 'entity',
                            firstName: investmentDetail.firstName,
                            middleName: investmentDetail.middleName,
                            lastName: investmentDetail.lastName,
                            entityName: investmentDetail.entityName,
                            taxIdNumber: investmentDetail.taxIdNumber,
                            address1: investmentDetail.address1,
                            address2: investmentDetail.address2,
                            city: investmentDetail.city,
                            state: investmentDetail.state,
                            zipcode: investmentDetail.zipcode,
                            interestedDate:investmentDetail.interestedDate,
                            executedDate:investmentDetail.executedDate,
                            isDocSign:investmentDetail.isDocSign,
                            paymentDate:investmentDetail.paymentDate ? new Date (investmentDetail.paymentDate): '',
                            fundedDate:investmentDetail.fundedDate ? new Date (investmentDetail.fundedDate): '',
                            memoNo:investmentDetail.memoNo,
                            isSubscribed: (investmentDetail.isSubscribed === true),
                            paymentType: investmentDetail.paymentType && investmentDetail.paymentType === 'check' ? 'check' : 'wire',
                            emailId: investmentDetail.emailId ? investmentDetail.emailId : null,
                            selectedType,
                            isEntity,
                            isSignSubscriptionAggrement: investmentDetail.isSignSubscriptionAggrement ? true : this.processId && this.processId.toString() === investmentDetail._id.toString() && this.status === 'completed' && this.type === 'subscription' ? true : false,
                            isSignW9: investmentDetail.isSignW9 ? true : this.processId && this.processId.toString() === investmentDetail._id.toString() && this.status === 'completed' && this.type === 'signw9' ? true : false,
                            subscriptionEnvId: investmentDetail.subscriptionEnvId ? investmentDetail.subscriptionEnvId : this.processId && this.processId.toString() === investmentDetail._id.toString() && this.status === 'completed' && this.type === 'subscription' ? this.envId : null,
                            w9EnvId: investmentDetail.w9EnvId ? investmentDetail.w9EnvId : this.processId && this.processId.toString() === investmentDetail._id.toString() && this.status === 'completed' && this.type === 'signw9' ? this.envId : null,
                            isDocument: this.getTabStatus(investmentDetail, 'document'),
                            isExecute: this.getTabStatus(investmentDetail, 'execute'),
                            isFund: this.getTabStatus(investmentDetail, 'fund'),
                        }
                    }
                });
            }
        }
    }

    componentDidMount() {
        const url = new URL(window.location.href);
        this.status = url.searchParams.get('status');
        this.processId = url.searchParams.get('processId');
        this.type = url.searchParams.get('type');
        this.envId = url.searchParams.get('env');
        const investmentProcesses = this.props.invitedInvestment.investmentprocesses;
        console.log(81, this.props.invitedInvestment);
        if (investmentProcesses && investmentProcesses.length > 0 &&
            investmentProcesses[0].status !== 'Invited' 
            && investmentProcesses[0].status !== 'Not Interested'
            && investmentProcesses[0].status !== 'Complete'  ) {
            const investmentDetail = investmentProcesses[0];
            let selectedType = { value: 'entity', label: 'Entity'};
            let isEntity = true;
            if (investmentDetail && investmentDetail.type === 'individual') {
                selectedType = { value: 'individual', label: 'Individual'};
                isEntity = false;
            }
            this.setState({
                documents: {
                    [investmentDetail._id]: {
                        amount: investmentDetail.amount,
                        status: investmentDetail.status,
                        type: investmentDetail.type ? investmentDetail.type : 'entity',
                        firstName: investmentDetail.firstName,
                        middleName: investmentDetail.middleName,
                        lastName: investmentDetail.lastName,
                        entityName: investmentDetail.entityName,
                        address1: investmentDetail.address1,
                        address2: investmentDetail.address2,
                        city: investmentDetail.city,
                        state: investmentDetail.state,
                        zipcode: investmentDetail.zipcode,
                        taxIdNumber: investmentDetail.taxIdNumber,
                        interestedDate:investmentDetail.interestedDate,
                        executedDate:investmentDetail.executedDate,
                        isDocSign:investmentDetail.isDocSign,
                        paymentDate:investmentDetail.paymentDate ? new Date (investmentDetail.paymentDate): '',
                        fundedDate:investmentDetail.fundedDate ? new Date (investmentDetail.fundedDate): '',
                        memoNo:investmentDetail.memoNo,
                        isSubscribed: (investmentDetail.isSubscribed === true),
                        paymentType: investmentDetail.paymentType && investmentDetail.paymentType === 'check' ? 'check' : 'wire',
                        emailId: investmentDetail.emailId ? investmentDetail.emailId : null,
                        selectedType,
                        isEntity,
                        isSignSubscriptionAggrement: investmentDetail.isSignSubscriptionAggrement ? true : this.processId && this.processId.toString() === investmentDetail._id.toString() && this.status === 'completed' && this.type === 'subscription' ? true : false,
                        isSignW9: investmentDetail.isSignW9 ? true : this.processId && this.processId.toString() === investmentDetail._id.toString() && this.status === 'completed' && this.type === 'signw9' ? true : false,
                        subscriptionEnvId: investmentDetail.subscriptionEnvId ? investmentDetail.subscriptionEnvId : this.processId && this.processId.toString() === investmentDetail._id.toString() && this.status === 'completed' && this.type === 'subscription' ? this.envId : null,
                        w9EnvId: investmentDetail.w9EnvId ? investmentDetail.w9EnvId : this.processId && this.processId.toString() === investmentDetail._id.toString() && this.status === 'completed' && this.type === 'signw9' ? this.envId : null,
                        isDocument: this.getTabStatus(investmentDetail, 'document'),
                        isExecute: this.getTabStatus(investmentDetail, 'execute'),
                        isFund: this.getTabStatus(investmentDetail, 'fund'),
                    }
                }
            });
        }
    }

    menuClick(selected, id) {
        if (selected === 'document') {
            const documents = {...this.state.documents};
            documents[id].isDocument = true;
            documents[id].isExecute = false;
            documents[id].isFund = false;
            this.setState({ documents });
        } else if (selected ==='execute') {
            const documents = {...this.state.documents};
            if (documents[id].isSubscribed) {
                documents[id].isDocument = false;
                documents[id].isExecute = true;
                documents[id].isFund = false;
                this.setState({ documents });
            }
        } else {
            const documents = {...this.state.documents};
            if (documents[id].isDocSign) {
                documents[id].isDocument = false;
                documents[id].isExecute = false;
                documents[id].isFund = true;
                this.setState({ documents });
            }
        }
    }

    selectType(event, investmentDetailId) {
        console.log(event);
        let isEntity = false;
        if (event.value === 'entity') {
            isEntity = true;
            this.setState({ isEntity: true, type: event });
        } else {
            this.setState({ isEntity: false, type: event });
        }
        const documents = {...this.state.documents};
        documents[investmentDetailId].type = event.value;
        documents[investmentDetailId].isEntity = isEntity;
        documents[investmentDetailId].selectedType = event;
        this.setState({ documents })
        // console.log(this.state.isEntity);
    }

    handleChange(id, value, investmentDetailId) {
        if (id == 'signSubscription') {
            this.setState({ enableCheckbox1: !this.state.enableCheckbox1 })
        } else if (id == 'signW-9') {
            this.setState({ enableCheckbox2: !this.state.enableCheckbox2 })
        } else if (id === 'amount') {
            const documents = {...this.state.documents};
            documents[investmentDetailId].amount = value;
            documents[investmentDetailId].isSubscribed = true;
            this.setState({ documents })
        } else if (id === 'firstName') {
            const documents = {...this.state.documents};
            documents[investmentDetailId].firstName = value;
            this.setState({ documents })
        } else if (id === 'middleName') {
            const documents = {...this.state.documents};
            documents[investmentDetailId].middleName = value;
            this.setState({ documents })
        } else if (id === 'lastName') {
            const documents = {...this.state.documents};
            documents[investmentDetailId].lastName = value;
            this.setState({ documents })
        } else if (id === 'entityName') {
            const documents = {...this.state.documents};
            documents[investmentDetailId].entityName = value;
            this.setState({ documents })
        } else if (id === 'employerIdentification') {
            const documents = {...this.state.documents};
            documents[investmentDetailId].taxIdNumber = value;
            this.setState({ documents })
        } else if (id === 'SSN') {
            const documents = {...this.state.documents};
            documents[investmentDetailId].taxIdNumber = value;
            this.setState({ documents })
        } else if (id === 'address1') {
            const documents = {...this.state.documents};
            documents[investmentDetailId].address1 = value;
            this.setState({ documents })
        } else if (id === 'address2') {
            const documents = {...this.state.documents};
            documents[investmentDetailId].address2 = value;
            this.setState({ documents })
        } else if (id === 'city') {
            const documents = {...this.state.documents};
            documents[investmentDetailId].city = value;
            this.setState({ documents })
        } else if (id === 'state') {
            const documents = {...this.state.documents};
            documents[investmentDetailId].state = value;
            this.setState({ documents })
        } else if (id === 'zipcode') {
            const documents = {...this.state.documents};
            documents[investmentDetailId].zipcode = value;
            this.setState({ documents })
        } else if (id === 'type') {
            const documents = {...this.state.documents};
            documents[investmentDetailId].type = value;
            this.setState({ documents })
        }  else if (id === 'wireConfirmation') {
            const documents = {...this.state.documents};
            documents[investmentDetailId].memoNo = value;
            this.setState({ documents })
        } else if (id === 'subscription') {
            const documents = {...this.state.documents};
            if (documents[investmentDetailId].isSubscribed) {
                documents[investmentDetailId].isSubscribed = false;
            } else {
                documents[investmentDetailId].isSubscribed = true;
            }
            this.setState({ documents })
        } else {
            // set values
            this.setState({ [id]: value })
        }
    }

    addDocument(id) {
        let temp = this;
        temp.setState({ loading: true });
        let token = window.localStorage.getItem('token');
        const data = {...this.state.documents[id]}
        axios.post(`${common.path.base_url}/generateInvestmentDoc/${id}`, data,  {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                const documents = {...this.state.documents};
                documents[id].isExecute = true;
                documents[id].isDocument = false;
                documents[id].isFund = false;
                documents[id].interestedDate =  Date(Date.now());
                this.setState({ documents });
                
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                  }
               else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.response,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Error! #452100ERDE",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    execute(id) {
        let temp = this;
        temp.setState({ loading: true });
        let token = window.localStorage.getItem('token');
        axios.post(`${common.path.base_url}/signInvestmentDoc/${id}`, {},  {
            headers: {
                "Authorization": token
            }
        })
        .then((response) => {
            const documents = {...this.state.documents};
            documents[id].isDocument = false;
            documents[id].isExecute = false;
            documents[id].isFund = true;
            documents[id].isDocSign = true;
            this.setState({ documents });
        })
        .catch(function (error) {
            temp.setState({ loading: false });
            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                window.localStorage.clear();
                window.location.href = '/';
                return;
              }
           else if (error.response) {
                swal({
                    title: "Error!",
                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.response,
                    icon: "error",
                    button: "Ok!",
                });
            }
            else {
                swal({
                    title: "Error! #658424505SDR@E",
                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                    icon: "error",
                    button: "Ok!",
                });
            }
        });
    }

    fund(id,currentPaymentStatus) {
        const documents = {...this.state.documents};
        const data = {
            paymentDate: documents[id].paymentDate,
            memoNo: documents[id].memoNo,
            paymentType: documents[id].paymentType,
            status:currentPaymentStatus=='Complete'?'Complete':'Payment'
        };
        let temp = this;
        temp.setState({ loading: true });
        let token = window.localStorage.getItem('token');
        axios.post(`${common.path.base_url}/paymentInvestment/${id}`, data,  {
            headers: {
                "Authorization": token
            }
        })
        .then((response) => {
            const documents = {...this.state.documents};
            documents[id].isDocument = false;
            documents[id].isExecute = false;
            documents[id].isFund = true;
            documents[id].isDocSign = true;
            documents[id].isFunded = true;
            documents[id].status = 'Payment';
            this.setState({ documents });
            swal({
                title: "Success!",
                text: 'You have successfully submitted payment detail. Please wait for TSG confirmation!',
                icon: "success",
                button: "Ok!",
            })
            .then(() => {
            });

        })
        .catch(function (error) {
            temp.setState({ loading: false });
            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                window.localStorage.clear();
                window.location.href = '/';
                return;
              }
           else if (error.response) {
                swal({
                    title: "Error!",
                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.response,
                    icon: "error",
                    button: "Ok!",
                });
            }
            else {
                swal({
                    title: "Network Error!",
                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                    icon: "error",
                    button: "Ok!",
                });
            }
        });
    }

    cardClicked(value, id) {
        const documents = {...this.state.documents};
        
        if (value === 'wire') {
            documents[id].paymentType = 'wire';
        } else {
            documents[id].paymentType = 'check';
        }
        this.setState({ documents });
    }
    redirect(investment, type) {
        switch(type){
            case 'IIR':
                window.location.href = `/iirPreviewPDF?companyId=${investment.companyId}&investmentId=${investment._id}`
                break; 
            case 'EL':
                window.location.href =`/foundLetterPreview?companyId=${investment.companyId}&investmentId=${investment._id}`
                break; 
            case 'FAQ':
                window.location.href = `/investorFaqPreview`
                break; 
            default: 
            break;     

        }
        
    }

    handleDateChange (date, id ){
        // console.log(date, id)
        const documents = {...this.state.documents};
        documents[id].paymentDate = date;
        this.setState({ documents });
    }
    validInvitaionForm(id) {
        const documents = {...this.state.documents};
        const document = documents[id];
        // console.log(document);
        if (!document.amount || !document.type || !document.address1 || !document.city ) {
            return false;
        }
        if (document.type === 'individual' && (!document.firstName  || !document.lastName || !document.state || !document.zipcode || !document.taxIdNumber)) {
            return false;

        } else if (document.type === 'entity' && (!document.entityName || !document.state || !document.zipcode || !document.taxIdNumber)) {
            return false;
        }
        return true;
    }
    viewDocumentRedirect(envId, type){
        // setTimeout(() => {
            window.open(` ${common.path.doc_download_url}/downloadPDF?envId=${envId}&type=${type}`, '_blank')    
        // }, 15000);
        
       
    }

    render() {
        const investmentProcesses = this.props.invitedInvestment.investmentprocesses;
        if (investmentProcesses && investmentProcesses.length > 0 && investmentProcesses[0].status === 'Invited') {
        return (
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6" key={this.props.invitedInvestment._id}>
            
            <div className="investor-card" style={{height: '850px'}}>
                <div className="card-header-section custom-progressbar-head">
                    <div className="card-header-txt">
                        <div className="companyLogo">
                             <img src={`${common.path.base_url}/getInvestmentCompanyLogo?companyId=${this.props.invitedInvestment.companyId}`} className='logo logo-preview'></img>
                        </div>
                    </div>
                    <div className="text-comapny-name">
                            {this.props.companyName}
                            <div className="text-investname">
                                    {this.props.invitedInvestment.name}
                            </div>
                    </div>
                    <div className="card-menu">
                        <span className="icon-circle-open">INVITED</span>
                    </div>
                </div>
                { 
                 this.props.invitedInvestment.stage && this.props.invitedInvestment.stage !== 'closed' && 
                <div className="card-header-section custom-progressbar-head">
                    <div className="card-header-participate">
                        <div className='participate-investment' onClick={() => {this.redirect(this.props.invitedInvestment, 'IIR')}}>
                            <div className="image-iir">
                            
                                <img src={images.path.iirDocIcon} alt="" /> 
                                {/* <img  src={images.path.file} height="100px" width="100px" />  */}
                            </div>
                            <div className="text">
                                <a>Investment Intelligence Report</a>
                            </div>
                        </div>
                        <div className='participate-investment executive-main' onClick={() => {this.redirect(this.props.invitedInvestment, 'EL')}}>
                            <div className="image-execute">
                                {/* <img  src={images.path.file} height="100px" width="100px" />  */}
                                    <img src={images.path.googleDocs} alt="" />
                                {/* <div>

                                </div> */}
                            </div>
                            <div className="text executive-doc">
                                <a>Executive Letter</a>
                            </div>
                        </div>
                        <div className='participate-investment' onClick={() => {this.redirect(this.props.invitedInvestment, 'FAQ')}}>
                            <div className="image-faq">
                                    <img src={images.path.question} alt="" />
                                {/* <div></div> */}
                            </div>
                            <div className="text FAQ-doc">
                                <a>Investor FAQ's</a>
                            </div>
                        </div>
                        
                        {/* <div className="text-comapny-name">
                            <div className="text-investname">
                                <img  src={images.path.file} height="100px" width="100px" /><br/>
                            </div>
                            <div>

                            </div>
                            
                        </div> */}
                    </div>
                    
                    {/* <div className="card-menu">
                    <img  src={images.path.file} height="100px" width="100px" /><br/>
                    <span style={{paddingLeft: "20px"}}>IIR</span>
                    </div> */}
                </div>
                }
                {
                    this.props.invitedInvestment.stage && this.props.invitedInvestment.stage === 'closed' ? 
                    (<div className="participate-alert">
                        <div className="txt">
                           Investment is closed by TSG!
                        </div>
                    </div>): (
                        <div className="participate-alert">
                        <div className="txt">
                            Do you want to participate ?
                        </div>
                        <div className="btn">
                        <TButton onClick={() => this.props.userInterest(investmentProcesses[0]._id, true)} text={`YES`} className="size"   />
                        <TButton onClick={() => this.props.userInterest(investmentProcesses[0]._id, false)} text={`NO`} className="size"  />
                            </div>
                        </div>
                    )
                    
                }
                
            </div>
            </div>
        ) } else if (investmentProcesses && investmentProcesses.length > 0 && 
            investmentProcesses[0].status !== 'Invited' && investmentProcesses[0].status !== 'Not Interested'
            && investmentProcesses[0].status !== 'Complete') {
            return (
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6" key={this.props.invitedInvestment._id}>
                <div className="participate-card">
                <div className="card-header-section custom-progressbar-head">
                    <div className="card-header-txt">
                        <div className="companyLogo">
                            <img src={`${common.path.base_url}/getInvestmentCompanyLogo?companyId=${this.props.invitedInvestment.companyId}`} className='logo logo-preview' alt=""></img>
                        </div>
                    </div>
                    <div className="text-comapny-name">
                            {this.props.companyName}
                            <div className="text-investname">
                                    {this.props.invitedInvestment.name}
                            </div>
                    </div>
                    <div className="card-menu">
                        <span className="icon-circle-open">PARTICIPATE</span>
                    </div>
                </div>
                <div className='menu-header'>
                    <nav className="menu-navbar">
                        <button className={`navbtn ${this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].isDocument ? 'active' : ''}`} onClick={() => this.menuClick('document', investmentProcesses[0]._id)}>DOCUMENTS</button>
                        <button className={`navbtn ${this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].isExecute ? 'active' : ''}`} onClick={() => this.menuClick('execute', investmentProcesses[0]._id)}>EXECUTE</button>
                        <button className={`navbtn ${this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].isFund ? 'active' : ''}`} onClick={() => this.menuClick('fund', investmentProcesses[0]._id)}>FUND</button>
                    </nav>
                </div>

                {this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].isDocument && <div className="document-form">
                    { this.props.invitedInvestment.stage && this.props.invitedInvestment.stage !== 'closed' ? (
                    <div className="form-horizontal">
                        <div className="form-group amount-number">
                            <label className="col-sm-3  col-md-4 col-lg-3 control-label">Amount <RequiredSign /> :</label>
                            <div className="col-sm-8 col-md-7 col-lg-8 amount-investment">
                                {/* <input className="form-control" id="amount" type="text"
                                    // onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value, investmentProcesses[0]._id)}
                                    placeholder='Enter Amount'
                                    value={this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].amount} /> */}
                                    <NumberFormat 
                                    value={this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].amount} 
                                    thousandSeparator={true} 
                                    prefix={'$'} 
                                    fixedDecimalScale={true}
                                    // decimalScale={2}
                                    onValueChange={(values) => {
                                        this.handleChange('amount', values.value, investmentProcesses[0]._id)
                                }}/>


                                <p>{this.state.amountValidationMsg}</p>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-3  col-md-4 col-lg-3 control-label">Type <RequiredSign /> :</label>
                            <div className="col-sm-8 col-md-7 col-lg-8">
                                <Select
                                    options={this.state.types}
                                    value={this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].selectedType}
                                    onChange={(event) => { this.selectType(event, investmentProcesses[0]._id)}}
                                    id='type'
                                />
                            </div>
                        </div>
                        {this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].isEntity ? (<div className="form-group">
                            <label className="col-sm-3  col-md-4 col-lg-3 control-label">Entity Name <RequiredSign /> :</label>
                            <div className="col-sm-8 col-md-7 col-lg-8">
                                <input className="form-control" id="entityName" type="text"
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value, investmentProcesses[0]._id)}
                                    placeholder=''
                                    value={this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].entityName} />
                                <p>{this.state.entityValidationMsg}</p>
                            </div>
                        </div>): 
                        ( 
                        <div>
                        <div className="form-group">
                            <label className="col-sm-3  col-md-4 col-lg-3 control-label">First Name <RequiredSign /> :</label>
                            <div className="col-sm-8 col-md-7 col-lg-8">
                                <input className="form-control" id="firstName" type="text"
                                    // onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value, investmentProcesses[0]._id)}
                                    placeholder=''
                                    value={this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].firstName} />
                                <p>{this.state.firstNameValidationMsg}</p>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-3  col-md-4 col-lg-3 control-label">Middle Name :</label>
                            <div className="col-sm-8 col-md-7">
                                <input className="form-control" id="middleName" type="text"
                                    // onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value, investmentProcesses[0]._id)}
                                    placeholder=''
                                    value={this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].middleName} />
                                <p>{this.state.middleNameValidationMsg}</p>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-3  col-md-4 col-lg-3 control-label">Last Name <RequiredSign /> :</label>
                            <div className="col-sm-8 col-md-7 col-lg-8">
                                <input className="form-control" id="lastName" type="text"
                                    // onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value, investmentProcesses[0]._id)}
                                    placeholder=''
                                    value={this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].lastName} />
                                <p>{this.state.lastNameValidationMsg}</p>
                            </div>
                        </div>
                        </div>)}
                        <div className="form-group">
                            <label className="col-sm-3  col-md-4 col-lg-3 control-label">Address 1 <RequiredSign /> :</label>
                            <div className="col-sm-8 col-md-7 col-lg-8">
                                <input className="form-control" id="address1" type="text"
                                    // onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value, investmentProcesses[0]._id)}
                                    placeholder=''
                                    value={this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].address1} />
                                <p>{this.state.address1ValidationMsg}</p>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-3  col-md-4 col-lg-3 control-label">Address 2 :</label>
                            <div className="col-sm-8 col-md-7 col-lg-8">
                                <input className="form-control" id="address2" type="text"
                                    // onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value, investmentProcesses[0]._id)}
                                    placeholder=''
                                    value={this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].address2} />
                                <p>{this.state.address2ValidationMsg}</p>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-3  col-md-4 col-lg-3 control-label">City <RequiredSign /> :</label>
                            <div className="col-sm-8 col-md-7 col-lg-8">
                                <input className="form-control" id="city" type="text"
                                    // onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value, investmentProcesses[0]._id)}
                                    placeholder=''
                                    value={this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].city} />
                                <p>{this.state.cityValidationMsg}</p>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-3  col-md-4 col-lg-3 control-label">State <RequiredSign /> :</label>
                            <div className="col-sm-8 col-md-7 col-lg-8">
                                <input className="form-control" id="state" type="text"
                                    // onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value, investmentProcesses[0]._id)}
                                    placeholder=''
                                    value={this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].state} />
                                <p>{this.state.stateValidationMsg}</p>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-3  col-md-4 col-lg-3 control-label">Zip Code <RequiredSign /> :</label>
                            <div className="col-sm-8 col-md-7 col-lg-8">
                                <input className="form-control" id="zipcode" type="text"
                                    // onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value, investmentProcesses[0]._id)}
                                    placeholder=''
                                    value={this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].zipcode} />
                                <p>{this.state.zipcodeValidationMsg}</p>
                            </div>
                        </div>
                        {this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].isEntity ? (<div className="form-group">
                            <label className="col-sm-3  col-md-4 col-lg-3 control-label">Tax I.D. Number: <RequiredSign /> :</label>
                            <div className="col-sm-8 col-md-7 col-lg-8">
                                <input className="form-control" id="employerIdentification" type="text"
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value, investmentProcesses[0]._id)}
                                    placeholder=''
                                    value={this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].taxIdNumber} />
                            </div>
                        </div>): 
                        ( <div className="form-group">
                            <label className="col-sm-3  col-md-4 col-lg-3 control-label">SSN: <RequiredSign /> :</label>
                            <div className="col-sm-8 col-md-7 col-lg-8">
                                <input className="form-control" id="SSN" type="text"
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value, investmentProcesses[0]._id)}
                                    placeholder=''
                                    value={this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].taxIdNumber} />
                            </div>
                            </div>
                        )
                        }
                        {/* <div className="form-group">
                        <label className="col-sm-3  col-md-4 col-lg-3 control-label">Subscription <RequiredSign /> :</label>
                            <div className="col-sm-8 col-md-7 col-lg-8">
                                <input type="checkbox" id="subscription" 
                                checked={this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].isSubscribed}
                                onChange={(event) => this.handleChange(event.target.id, event.target.value, investmentProcesses[0]._id)}
                             />
                            </div>
                        </div> */}
                        <div className="document-btn">
                            <LaddaButton
                                id='generateDoc'
                                loading={this.state.loadinglada}
                                onClick={() => this.addDocument(investmentProcesses[0]._id)}
                                data-color="#eee"
                                data-size={XS}
                                className={!this.validInvitaionForm(investmentProcesses[0]._id) ? 'disable' : ''}
                                data-style={EXPAND_RIGHT}
                                data-spinner-size={30}
                                data-spinner-color="#ddd"
                                data-spinner-lines={12}
                            >
                                GENERATE DOCS
                            </LaddaButton>
                            <input type="button" id="cancelDoc" className="btn btn-default" data-dismiss="modal"  value="CANCEL" />
                        </div>
                    </div>) :(
                        <div>
                            Investment is  closed by  TSG!
                        </div>
                    ) }
                </div>}

                {this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].isExecute && 
                <div>
                {
                this.props.invitedInvestment.stage && this.props.invitedInvestment.stage !== 'closed' ? ( 
                <div className="execute-form">
                    {
                        this.state.documents[investmentProcesses[0]._id].isSubscribed
                        && !this.state.documents[investmentProcesses[0]._id].isDocSign  ? 
                        (<div>
                            <div className="execute-txt"> Checklist </div>

                            <div className="checkbox-section">
                                <div className='form-group custom-form-group'>
                                    <input className='col-lg-1 col-sm-1 col-md-1 checkbox-margin' type='checkbox' id={`signSubscription`} 
                                    checked={this.state.documents[investmentProcesses[0]._id].isSignSubscriptionAggrement ? true : false} />
                                    <label className="col-lg-11 col-sm-11 col-md-11 custom-label" htmlFor={`signSubscription`}>
                                        {
                                            this.state.documents[investmentProcesses[0]._id].isSignSubscriptionAggrement ?
                                            <a className="file-link" onClick={() => this.viewDocumentRedirect(this.state.documents[investmentProcesses[0]._id].subscriptionEnvId, 'subscription')}>View Subscription Agreement and Questionnaire</a> :

                                            this.state.documents[investmentProcesses[0]._id].type === 'individual' ?
                                            <a href={`${process.env.REACT_APP_DOCUSIGN_HOST}/Member/PowerFormSigning.aspx?PowerFormId=${process.env.REACT_APP_SUB_PF_ID_INDIVIDUAL}&env=demo&acct=${process.env.REACT_APP_DOCUSIGN_ACCOUNT_ID}&v=2&EnvelopeField_userId=${window.localStorage.getItem('id')}&EnvelopeField_webhookEnv=${process.env.REACT_APP_WEBHOOK_ENV}&EnvelopeField_investmentId=${investmentProcesses[0].investmentId}&EnvelopeField_processId=${investmentProcesses[0]._id}&EnvelopeField_type=subscription&individualInvestor_UserName=${window.localStorage.getItem('username')}&individualInvestor_amount=${this.state.documents[investmentProcesses[0]._id].amount}&individualInvestor_classAUnits=${this.state.documents[investmentProcesses[0]._id].amount}&individualInvestor_contactPerson=${this.state.documents[investmentProcesses[0]._id].firstName} ${this.state.documents[investmentProcesses[0]._id].lastName}&individualInvestor_investorEmail=${window.localStorage.getItem('emailAddress')}&individualInvestor_address=${this.state.documents[investmentProcesses[0]._id].address1}&individualInvestor_state=${this.state.documents[investmentProcesses[0]._id].state}&individualInvestor_taxIdNumber=${this.state.documents[investmentProcesses[0]._id].taxIdNumber}&individualInvestor_phoneNumber=${this.props.phoneNumber}`}>Sign Subscription Agreement and Questionnaire</a>:
                                            <a href={`${process.env.REACT_APP_DOCUSIGN_HOST}/Member/PowerFormSigning.aspx?PowerFormId=${process.env.REACT_APP_SUB_PF_ID_ENTITY}&env=demo&acct=${process.env.REACT_APP_DOCUSIGN_ACCOUNT_ID}&v=2&EnvelopeField_userId=${window.localStorage.getItem('id')}&EnvelopeField_webhookEnv=${process.env.REACT_APP_WEBHOOK_ENV}&EnvelopeField_investmentId=${investmentProcesses[0].investmentId}&EnvelopeField_processId=${investmentProcesses[0]._id}&EnvelopeField_type=subscription&entityInvestor_UserName=${window.localStorage.getItem('username')}&entityInvestor_amount=${this.state.documents[investmentProcesses[0]._id].amount}&entityInvestor_classAUnits=${this.state.documents[investmentProcesses[0]._id].amount}&entityInvestor_contactPerson=${window.localStorage.getItem('username').split(' ')[0]} ${window.localStorage.getItem('username').split(' ')[1]}&entityInvestor_investorEmail=${window.localStorage.getItem('emailAddress')}&entityInvestor_entityName=${this.state.documents[investmentProcesses[0]._id].entityName}&entityInvestor_title=${this.props.title}&entityInvestor_address=${this.state.documents[investmentProcesses[0]._id].address1}&entityInvestor_taxIdNumber=${this.state.documents[investmentProcesses[0]._id].taxIdNumber}&entityInvestor_phoneNumber=${this.props.phoneNumber}`}>Sign Subscription Agreement and Questionnaire</a>
                                        }
                                        
                                        
                                        
                                        </label>
                                </div>
                                <div className='form-group custom-form-group'>
                                    <input className='col-lg-1 col-sm-1 col-md-1 checkbox-margin' type='checkbox' id={`signW-9`}
                                        checked={this.state.documents[investmentProcesses[0]._id].isSignW9 ? true : false}
                                     />
                                    <label className="col-lg-11 col-sm-11 col-md-11 custom-label" htmlFor={`signW-9`}>
                                        {
                                            this.state.documents[investmentProcesses[0]._id].isSignW9 ?
                                            <a className="file-link" onClick={() => this.viewDocumentRedirect(this.state.documents[investmentProcesses[0]._id].w9EnvId, 'w9')}>View W-9</a>:
                                            
                                            // <a href={`https://demo.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=9affe9b5-9c2d-48f8-96ea-959094bf0a55&env=demo&acct=8ba9929a-4b95-4e35-b924-7fc429ccd928&v=2&EnvelopeField_userId=${window.localStorage.getItem('id')}&EnvelopeField_webhookEnv=${process.env.REACT_APP_WEBHOOK_ENV}&EnvelopeField_investmentId=${investmentProcesses[0].investmentId}&EnvelopeField_processId=${investmentProcesses[0]._id}&EnvelopeField_type=subscription&investor_UserName=David`}>Sign Subscription Agreement and Questionnaire</a>
                                            this.state.documents[investmentProcesses[0]._id].type === 'individual' ?
                                            <a href={`${process.env.REACT_APP_DOCUSIGN_HOST}/Member/PowerFormSigning.aspx?PowerFormId=${process.env.REACT_APP_W9_PF_ID}&env=demo&acct=${process.env.REACT_APP_DOCUSIGN_ACCOUNT_ID}&v=2&EnvelopeField_userId=${window.localStorage.getItem('id')}&EnvelopeField_webhookEnv=${process.env.REACT_APP_WEBHOOK_ENV}&EnvelopeField_investmentId=${investmentProcesses[0].investmentId}&EnvelopeField_processId=${investmentProcesses[0]._id}&EnvelopeField_type=signw9&signW9_UserName=${window.localStorage.getItem('username')}&signW9_name=${window.localStorage.getItem('username')}&signW9_cityStateZip=${this.state.documents[investmentProcesses[0]._id].city},${this.state.documents[investmentProcesses[0]._id].state},${this.state.documents[investmentProcesses[0]._id].zipcode}&signW9_ssn=${this.state.documents[investmentProcesses[0]._id].taxIdNumber}&signW9_investorEmail=${window.localStorage.getItem('emailAddress')}&signW9_email=${window.localStorage.getItem('emailAddress')}`}>Sign W-9</a>:
                                            <a href={`${process.env.REACT_APP_DOCUSIGN_HOST}/Member/PowerFormSigning.aspx?PowerFormId=${process.env.REACT_APP_W9_PF_ID}&env=demo&acct=${process.env.REACT_APP_DOCUSIGN_ACCOUNT_ID}&v=2&EnvelopeField_userId=${window.localStorage.getItem('id')}&EnvelopeField_webhookEnv=${process.env.REACT_APP_WEBHOOK_ENV}&EnvelopeField_investmentId=${investmentProcesses[0].investmentId}&EnvelopeField_processId=${investmentProcesses[0]._id}&EnvelopeField_type=signw9&signW9_UserName=${window.localStorage.getItem('username')}&signW9_name=${window.localStorage.getItem('username')}&signW9_cityStateZip=${this.state.documents[investmentProcesses[0]._id].city},${this.state.documents[investmentProcesses[0]._id].state},${this.state.documents[investmentProcesses[0]._id].zipcode}&signW9_taxIdNumber=${this.state.documents[investmentProcesses[0]._id].taxIdNumber}&signW9_entityName=${this.state.documents[investmentProcesses[0]._id].entityName}&signW9_email=${window.localStorage.getItem('emailAddress')}`}>Sign W-9</a>
                                            
                                        }
                                        
                                    </label>
                                </div>
                                {/* <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <a href={`https://demo.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=9affe9b5-9c2d-48f8-96ea-959094bf0a55&env=demo&acct=8ba9929a-4b95-4e35-b924-7fc429ccd928&v=2&EnvelopeField_userId=${window.localStorage.getItem('id')}&EnvelopeField_investmentId=${investmentProcesses[0].investmentId}&EnvelopeField_processId=${investmentProcesses[0]._id}`}>Investor questionnaire and subscription agreement</a>
                                </div> */}
                                {/* <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {
                                    this.processId && this.processId.toString() === investmentProcesses[0]._id.toString()
                                    && this.status && this.status === 'completed' &&
                                    <a className="file-link" onClick={() => this.viewDocumentRedirect()}>View Document</a>
                                }
                                </div> */}
                            </div>
                            {(this.state.documents[investmentProcesses[0]._id].isSignSubscriptionAggrement && this.state.documents[investmentProcesses[0]._id].isSignW9) && <div className="execute-btn">
                                <LaddaButton
                                    id='execute-btn'
                                    loading={this.state.loadinglada}
                                    onClick={() => this.execute(investmentProcesses[0]._id)}
                                    data-color="#eee"
                                    data-size={XS}
                                    className=''
                                    data-style={EXPAND_RIGHT}
                                    data-spinner-size={30}
                                    data-spinner-color="#ddd"
                                    data-spinner-lines={12}
                                >
                                    CONTINUE
                                </LaddaButton>
                            </div>}
                        </div> ) : 
                        
                            !this.state.documents[investmentProcesses[0]._id].executedDate && 
                            this.state.documents[investmentProcesses[0]._id].isDocSign ? 
                            (
                                <div className="description">
                                <p className="description-txt">You have already signed doc.</p>
                                <p className="description-txt">Your investment is currently pending.</p>
                                <p className="description-txt">Once confirmed, you will receive funding instructions.</p>
                                </div>
                            ):
                            !this.state.documents[investmentProcesses[0]._id].paymentDate && 
                            this.state.documents[investmentProcesses[0]._id].executedDate && 
                            this.state.documents[investmentProcesses[0]._id].isDocSign ?
                            (
                                <div className="description">
                                    <p className="description-txt">You have already signed doc. Please process for payment.</p>
                                </div>
                            ):
                            this.state.documents[investmentProcesses[0]._id].paymentDate && 
                            this.state.documents[investmentProcesses[0]._id].executedDate && 
                            this.state.documents[investmentProcesses[0]._id].isDocSign ?
                            (
                                <div className="description">
                                    <p className="description-txt">You have already signed doc.</p>
                                    <p className="description-txt">Your investment is currently pending.</p>
                                    <p className="description-txt">Once your payment confirmed by TSG, your investment process will complete.</p>
                                </div>
                            ): null

                    }
                    </div>):
                (<div className="execute-form"> Investment is closed by TSG! </div>) }
                    </div>
                }

                {this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].isFund && <div className="fund-form">
                    
                { this.props.invitedInvestment.stage && this.props.invitedInvestment.stage !== 'closed' ? ( 
                    <div>
                    {
                        this.state.documents[investmentProcesses[0]._id].executedDate &&
                        this.state.documents[investmentProcesses[0]._id].status !== 'Payment' ?
                        (<div>
                        <div className="fund-title"> Select your funding </div>

                    <div className="fund-card-section">
                        <div className="card-section" onClick={() => this.cardClicked('wire', investmentProcesses[0]._id)}> {/* onClick={() => {this.cardClicked('1')}} */}
                            <div className="card-title">Wire</div>
                            <div className={this.state.documents[investmentProcesses[0]._id].paymentType === 'wire' ? 'fund-card selected' : 'fund-card'}>
                                <div className="fund-data-title">Bank Name:</div>
                                <div className="fund-data">Wells Fargo Bank</div>
                                <div className="fund-data-title custom-margin">Address:</div>
                                <div className="fund-data">Syndicate Venture Partners Deposit</div>
                                <div className="fund-data-title custom-margin">Account Name:</div>
                                <div className="fund-data">Syndicate Venture Partner 2019 Series 6, LLC</div>
                                <div className="fund-data-title custom-margin">Account Number:</div>
                                <div className="fund-data">9854844151251541</div>
                                <div className="fund-data-title custom-margin">Routing Number:</div>
                                <div className="fund-data">2548965841</div>
                            </div>
                        </div>

                             <div className="card-section" onClick={() => this.cardClicked('check', investmentProcesses[0]._id)}> {/* onClick={() => {this.cardClicked('2')}} */}
                            <div className="card-title">Check</div>
                            <div className={this.state.documents[investmentProcesses[0]._id].paymentType === 'check' ? 'fund-card selected' : 'fund-card'}>
                                <div className="fund-data-title">Bank Name:</div>
                                <div className="fund-data">Wells Fargo Bank</div>
                                <div className="fund-data-title custom-margin">Account Name:</div>
                                <div className="fund-data">Syndicate Venture Partner 2019 Series 6, LLC</div>
                                <div className="fund-data-title custom-margin">Account Number:</div>
                                <div className="fund-data">9854844151251541</div>
                                <div className="fund-description">You may take a personal check to any Wells Fargo branch and deposit to the account detailed above.</div>
                            </div>
                        </div>
                    </div>

                    <div className="fund-form-section">
                        {this.state.documents[investmentProcesses[0]._id].paymentType === 'wire' && <div className="form-section">
                            <div className="form-group">
                                <label className="control-label">Wire Date :</label>
                                <div className="control-input">
                                    {/* <input className="form-control" id="wireDate" type="text"
                                        // onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                        onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                        placeholder='Wire Date'
                                        value={this.state.wireDate} /> */}
                                        <div  className="wireDate">
                                        <DatePicker
                                        selected={this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].paymentDate}
                                        onChange={(date) => this.handleDateChange(date, investmentProcesses[0]._id)}
                                        maxDate={new Date()}
                                        />
                                        </div>
                                    <p>{this.state.wireDateValidationMsg}</p>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label">Wire Confirmation # :</label>
                                <div className="control-input">
                                    <input className="form-control" id="wireConfirmation" type="text"
                                        // onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                        onChange={(event) => this.handleChange(event.target.id, event.target.value, investmentProcesses[0]._id)}
                                        placeholder=''
                                        value={this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].memoNo} />
                                    <p>{this.state.wireConfirmationValidationMsg}</p>
                                </div>
                            </div>
                        </div>}
                        {this.state.documents[investmentProcesses[0]._id].paymentType === 'check' && <div className="form-section form-section-fund">
                            <div className="form-group">
                                {/* <label className="control-label">Wire Date :</label> */}
                                <div className="control-input">
                                    {/* <input className="form-control" id="wireDate" type="text"
                                        // onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                        onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                        placeholder='Wire Date'
                                        value={this.state.wireDate} /> */}
                                        <div  className="wireDate">
                                        {/* <DatePicker
                                        selected={this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].paymentDate}
                                        onChange={(date) => this.handleDateChange(date, investmentProcesses[0]._id)}
                                        maxDate={new Date()}
                                        /> */}
                                        </div>
                                    <p>{this.state.wireDateValidationMsg}</p>
                                </div>
                            </div>
                            <div className="form-group form-check">
                                <label className="control-label">Deposite Date <RequiredSign /> :</label>
                                <div className="control-input">
                                    {/* <input className="form-control" id="depositeDate" type="text"
                                        // onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                        onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                        placeholder='Deposite Date'
                                        value={this.state.depositeDate} /> */}
                                        <div  className="wireDate">
                                    <DatePicker
                                        selected={this.state.documents && this.state.documents[investmentProcesses[0]._id] && this.state.documents[investmentProcesses[0]._id].paymentDate}
                                        onChange={(date) => this.handleDateChange(date, investmentProcesses[0]._id)}
                                        maxDate={new Date()}
                                    />
                                    </div>
                                    <p>{this.state.depositeDateValidationMsg}</p>
                                    
                                </div>
                            </div>
                        </div>}

                        <div className="form-btn">
                            <LaddaButton
                                id='form-btn'
                                loading={this.state.loadinglada}
                                onClick={() => this.fund(investmentProcesses[0]._id,this.state.documents[investmentProcesses[0]._id].status)}
                                data-color="#eee"
                                data-size={XS}
                                className={!this.state.documents[investmentProcesses[0]._id].paymentDate ? 'disable' : ''}
                                data-style={EXPAND_RIGHT}
                                data-spinner-size={30}
                                data-spinner-color="#ddd"
                                data-spinner-lines={12}
                            >
                                CONTINUE
                            </LaddaButton>
                        </div>
                    </div>
                    </div>): 
                        this.state.documents[investmentProcesses[0]._id].status === 'Payment' ?
                        (
                            <div className="description">
                                <p className="description-txt">You have successfully submitted payment detail. Please wait for TSG confirmation!</p>
                            </div>
                        ):
                        (
                            <div className="description">
                            <p className="description-txt">Your investment is currently pending.</p>
                            <p className="description-txt">Once confirmed, you will receive funding instructions.</p>
                            </div>
                        )
                    
                    
                    }

                    </div>):(
                        <div>Investment is closed by TSG!</div>
                    )}
                    
                </div>}
            </div>
            </div>
            );
            
        } else {
            return null;
        }
        
    }
}

export default ParticipateCard;
import React, { Component } from 'react';
import LaddaButton, { EXPAND_RIGHT, S } from 'react-ladda';
import NumberFormat from 'react-number-format';
import { path } from '../../../imagesPath';

class BankTransferConfirmationModel extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentWillReceiveProps(nextProps) {
        nextProps.onRef && nextProps.onRef(this);
    }

    componentDidUpdate(prevProps) {
        window.onpopstate = (e) => {
            this.props.history.push('/dashboard');
        }
    }

    render() {
        return (
            <div id="bankTransferConfirmationModel" className="modal fade" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Confirmation</h4>
                            <img src={path.close} style={{ width: "15px" }} alt="" className="contact-close-img" type="button" data-dismiss="modal" id="contact-btn-cancel" />
                        </div>
                        <div className="modal-body">
                            <div className="confirmation-content">
                                <div className="row d-flex" style={{ padding: "0px 20px" }}>
                                    <div className="col-md-12" style={{ textAlign: "left" }}>
                                        <div className="row">
                                            <h4 style={{ fontWeight: "normal" }}>From: Wallet Balance <NumberFormat
                                                className="amount-text"
                                                value={this.props.walletBalanceData.currentBalance || 0}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                prefix={'$'} />
                                            </h4>
                                        </div>
                                        <br />
                                        <div className="row d-flex">
                                            <h4 style={{ fontWeight: "normal", marginRight: "10px" }}>To: </h4>
                                            { this.props.bankAccountData &&
                                                (<h4>{this.props.bankAccountData.bankAccountName} {this.props.bankAccountData.bankAccountNumber}</h4>)
                                            }
                                        </div>
                                        <br />
                                        <div className="row">
                                            <h4 style={{ fontWeight: "normal" }}>Tranfer Amount:   <NumberFormat
                                                className="amount-text"
                                                value={this.props.transferAmount || 0}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                prefix={'$'} />
                                            </h4>
                                        </div>
                                        <br />
                                        <div className="row">
                                            <h5 style={{ fontStyle: "italic", fontSize: "1em" }}>Please allow 3-5 business days for the funds to be posted to your specified bank account.</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="confirmation-modal-footer px-3">
                            {/* <TButton className="btn" text={'Cancel'} onClick={() => { }} id='walletFundingConfirmation-btn' dataTarget={`#BankTransferConfirmationModel`} dataToggle={`modal`} /> */}
                            <input type="button" data-target={`#BankTransferConfirmationModel`} className="btn btn-default" style={{ padding: "4.5px 12px", margin: "5px" }} data-dismiss="modal" value="CANCEL" onClick={() => { }} />
                            <LaddaButton
                                style={{
                                    margin: "6px",
                                    color: "#ffffff",
                                    backgroundColor: "#ef534a",
                                    borderRadius: "4px",
                                    fontSize: "14px",
                                }}
                                onClick={() => this.props.onSubmitButtonClick()}
                                data-color="#eee"
                                data-size={S}
                                data-dismiss={'modal'}
                                data-style={EXPAND_RIGHT}
                                data-spinner-size={30}
                                data-spinner-color="#ddd"
                                data-spinner-lines={12}
                                id='walletFundingConfirmation'
                            >
                                SUBMIT
                            </LaddaButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default BankTransferConfirmationModel;
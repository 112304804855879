import React from 'react';
import './Loader.scss';
const LoadingSpinner = (props) => (
    <div className={props.class ? props.class : 'loaderCss'}>
          <div>
    <i className="fa fa-spinner fa-spin" /> 
  </div>
    </div>

);

export default LoadingSpinner;


import React from 'react';
import './UploadedFilesCard.scss';
import images from '../../images';
import common from '../../common';

const formatAMPM = date => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
};
const mapDateFormat = mongoDate => {
    if (!mongoDate) return null;

    var monthNames = [
        "January", "February", "March",
        "April", "May", "June", "July",
        "August", "September", "October",
        "November", "December"
    ];
    let date = new Date(mongoDate);
    let day = date.getDate();
    let monthIndex = date.getMonth();
    let year = date.getFullYear();

    return monthNames[monthIndex] + ' ' + day + ', ' + year + ' ' + formatAMPM(date);

};

function UploadedFilesCard(props) {
    const file = props.file;
    let userRoleCode = window.localStorage.getItem('roleCode');
    if (props.userRoleCode) {
        userRoleCode = props.userRoleCode;
    }
    return (
        <>
            <div className={`custom-upload-card`} onClick={() => { props.onCardClick && props.onCardClick() }} >
                <div className="upload-file-inner upload-file-right">
                    <div className="user-name">{file && file.createdBy && file.createdBy.name && file.createdBy.name.toUpperCase()}</div>
                    <div className="upload-date">{file && file.createdAt && mapDateFormat(file.createdAt)}</div>
                    {
                        file && file.documentName && file.documentPath &&
                        <div className="links-block">
                            {
                                file.totalContacts > -1 && file.documentType !== 'bulk upload' 
                                    ? <img src={images.path.userIcon} alt="User Icon" />
                                    : <img src={images.path.csvFile} alt="CSV Icon" />
                            }
                            <a className="file-link" href={`${common.path.doc_download_url}/getFile?fileId=${file._id}`} download>{file.documentName}</a>
                        </div>
                    }

                </div>
                {
                    (
                        <div className="action-icon no-action" >
                            <span>
                                <img src={images.path.deleteIcon} alt='remove' onClick={() => { props.onActionClick && props.onActionClick() }} />
                            </span>
                            {
                                file.conflictsContacts > 0
                                    ? userRoleCode && (userRoleCode === '001' || userRoleCode === '002')
                                        ? <>
                                            {
                                                file.documentType !== 'bulk upload' ? 
                                                <div className="upload-text" onClick={() => props.onDocUploadClick(file._id, file.investmentId, file.companyId)}>UPLOAD</div>:
                                                <div className="upload-text"></div>
                                            }
                                            {
                                                file.documentType === 'bulk upload' ? 
                                                <a className="file-link" href={`${common.path.doc_download_url}/downloadContactConflictsFile?id=${file._id}`} download>See Conflict</a>:
                                                <span>Contact Conflict</span>


                                            }
                                            
                                        </>
                                        : <>
                                            <div className="upload-text">Pending Approval</div>
                                            <span>Contact Conflict</span>
                                        </>
                                    : <>
                                        <div className="upload-text"><br /></div>
                                        {file.documentType === 'single upload' && <span>Contact Uploaded</span>}
                                    </>
                            }
                        </div>
                    )
                }
            </div>
        </>

    );

}

export default UploadedFilesCard;



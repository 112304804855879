export const hideSidebarNotification = () => {
  return (dispatch, getState) => {
    const isHide = getState().hideSidebarNotification.isHide
    if(!isHide) {
      dispatch({
        type: 'HIDE_SIDEBAR_NOTIFICATION',
        
      });
    }
    
  }
  // return  {
  //   type: 'HIDE_SIDEBAR_NOTIFICATION',
    
  // }
}
export const reset = () => {
  return {
    type: 'RESET',
    
  }
}



import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash';
import axios from 'axios';
import images from "../../images";
import common from '../../common';
import swal from 'sweetalert';
import './pipelineCss/pipeline.scss';
import './pipelineCss/default.scss';
import { Icons } from './Icons';
import NumberFormat from 'react-number-format';
import ReactApexChart from "react-apexcharts";
import SeedData from './SeedData';
import KeyPartnersData from './KeyPartnersData';

const StageMaturity = props => {

    const { companyId, companyData, revenueModelOn, revenueYear, revenueVal, rtype } = props;

    const profileUploadRef = useRef(null);
    const keycustomeruploadRef = useRef(null);
    const keyparterUploadRef = useRef(null);

    const [generalCall, setGeneralCall] = useState(false);
    const [numberOfSalesTeams, setNumberOfSalesTeams] = useState('');
    const [activeRegions, setActiveRegions] = useState('');
    const [totalCustomers, setTotalCustomers] = useState('');
    const [verticalFocus, setVerticalFocus] = useState('');
    const [publicSector, setPublicSector] = useState('');
    const [totalPartnersSigned, setTotalPartnersSigned] = useState('');
    const [activelyEngaged, setActivelyEngaged] = useState('');
    const [companyAnnualRevenue, setCompanyAnnualRevenue] = useState('');
    const [desiredAnnualRevenue, setDesiredAnnualRevenue] = useState('');
    const [idealPartner, setIdealPartner] = useState('');
    const [servicesPlay, setServicesPlay] = useState('');
    
    const [keycustomerAction, setKeycustomerAction] = useState('');
    const [keycustomerDelete, setKeycustomerDelete] = useState([]);
    const [keycustomerImg, setKeycustomerImg] = useState('');
    const [keycustomerArr, setKeycustomerArr] = useState([]);

    const [ pastRevenueOption, setPastRevenueOption ] = useState({});
    const [ pastRevenueSeries, setPastRevenueSeries ] = useState({});

    const [ projectedRevenueOption, setProjectedRevenueOption ] = useState({});
    const [ projectedRevenueSeries, setProjectedRevenueSeries ] = useState({});

    const [ projectedArr, setProjectedArr ] = useState({
        'options' : [],
        'series' : [],
    });
    const [ postArr, setPostArr ] = useState({
        'options' : [],
        'series' : [],
    });
    
    const [seedArr, setSeedArr] = useState([]);
    const [seedTd1, setSeedTd1] = useState('');
    const [seedTd2, setSeedTd2] = useState('');
    const [seedTd3, setSeedTd3] = useState('');
    const [seedTd4, setSeedTd4] = useState('');
    const [seedTd5, setSeedTd5] = useState('');
    const [seedTd6, setSeedTd6] = useState('');

    const [keyPartnerArr, setKeyPartnerArr] = useState([]);
    const [keyPartner, setKeyPartner] = useState('');
    const [keyPartnerAdd, setKeyPartnerAdd] = useState({});
    const [keyPartnersAction, setKeyPartnersAction] = useState('');
    const [keypartnersImg, setKeypartnersImg] = useState('');

    const inputHandlerChange = (e) => {

        const inputId = e.target.id;
        let inputValue = e.target.value;

        setGeneralCall(true);
        //setKeycustomerImg({});
        // setKeypartnersImg({});
        if (inputId == 'numberOfSalesTeams') {
            setNumberOfSalesTeams(inputValue);
        } else if (inputId == 'activeRegions') {
            let txtFull = inputValue;
            txtFull = (txtFull.trim() == "") ? "" : _.upperFirst(txtFull);
            setActiveRegions(txtFull);
        } else if (inputId == 'totalCustomers') {
            setTotalCustomers(inputValue);
        } else if (inputId == 'verticalFocus') {
            setVerticalFocus(inputValue);
        } else if (inputId == 'publicSector') {
            setPublicSector(inputValue);
        } else if (inputId == 'totalPartnersSigned') {  // Channel Maturity
            setTotalPartnersSigned(inputValue);
        } else if (inputId == 'activelyEngaged') {
            setActivelyEngaged(inputValue);
        } else if (inputId == 'companyAnnualRevenue') {
            setCompanyAnnualRevenue(inputValue);
        } else if (inputId == 'desiredAnnualRevenue') {
            setDesiredAnnualRevenue(inputValue);
        } else if (inputId == 'idealPartner') {
            setIdealPartner(inputValue);
        } else if (inputId == 'servicesPlay') {
            setServicesPlay(inputValue);
        } else if (inputId == 'keyPartner') {

            inputValue = (inputValue.trim() == "") ? "" : _.upperFirst(inputValue);
            setKeyPartner(inputValue);
        }
    }

    useEffect(() => {

        let token = window.localStorage.getItem('token');

        if (generalCall) {
            if ((companyId == "" || companyId == null || companyId == undefined)) {

                swal({
                    title: 'Please enter company name',
                    icon: 'error',
                    confirmButtonText: 'Yes, delete it!',
                    reverseButtons: true,
                });

            } else {
                const timer = setTimeout(() => {
                    let dataInfo = new FormData();

                    const data = {
                        'companyId': companyId,
                        'numberOfSalesTeams': numberOfSalesTeams,
                        'activeRegions': activeRegions,
                        'totalCustomers': totalCustomers,
                        'verticalFocus': verticalFocus,
                        'publicSector': publicSector,
                        'totalPartnersSigned': totalPartnersSigned,
                        'activelyEngaged': activelyEngaged,
                        'companyAnnualRevenue': companyAnnualRevenue,
                        'desiredAnnualRevenue': desiredAnnualRevenue,
                        'idealPartner': idealPartner,
                        'servicesPlay': servicesPlay,
                        'keyCustomerImgUrl': keycustomerImg != '' ? { 'imgUrl': keycustomerImg } : "",
                        'keycustomerImg': "",
                        'keycustomerDelete': keycustomerDelete,
                        'keycustomerAction': keycustomerAction,
                        'keypartners': (Object.keys(keyPartnerAdd).length > 0) ? keyPartnerAdd : "",
                        'keypartnersAction': keyPartnersAction,
                        'seed': seedArr,
                        'pastRevenue': postArr,
                        'projectedRevenue': projectedArr,
                    }

                    if (Object.keys(keycustomerImg).length > 0) {
                        dataInfo.append('file', keycustomerImg.inputFile);
                        data.imgType = 'keycustomer';
                    } else if (Object.keys(keypartnersImg).length > 0) {
                        dataInfo.append('file', keypartnersImg.inputFile);
                        data.imgType = 'keypartners';
                    }
                    dataInfo.append('data', JSON.stringify(data));

                    axios.post(`${common.path.base_url}/addNewPipline`, dataInfo, {
                        headers: {
                            "Authorization": token
                        }
                    })
                        .then((response) => {
                            const savedData = response.data;
                            if (savedData.status == "success") {
                                const objData = savedData.data;
                                // setKeycustomerImg("");
                                setKeycustomerArr(objData.keyCustomers)
                                setKeycustomerAction('');

                                setKeyPartnerArr(objData.keyPartners);
                                setKeyPartnersAction('');
                                setKeypartnersImg('');
                            }
                        })
                        .catch((error) => {
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {

                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            } else {
                                swal({
                                    title: "Error! #33005",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });

                }, 1500);
                return () => clearTimeout(timer);
            }
        }

    }, [numberOfSalesTeams, activeRegions, totalCustomers, verticalFocus, publicSector, totalPartnersSigned, activelyEngaged, companyAnnualRevenue, desiredAnnualRevenue, idealPartner, servicesPlay, keycustomerImg, keyPartnerAdd, seedArr, projectedArr, postArr, keycustomerDelete])

    useEffect(() => {
        //console.log("govind", companyData);
        if (companyData != "" && companyData != undefined && companyData != null) {
            setNumberOfSalesTeams(companyData.numberOfSalesTeams);
            setActiveRegions(companyData.activeRegions);
            setTotalCustomers(companyData.totalCustomers);
            setVerticalFocus(companyData.verticalFocus);
            setPublicSector(companyData.publicSector);

            setTotalPartnersSigned(companyData.totalPartnersSigned);
            setActivelyEngaged(companyData.activelyEngaged);
            setCompanyAnnualRevenue(companyData.companyAnnualRevenue);
            setDesiredAnnualRevenue(companyData.desiredAnnualRevenue);
            setIdealPartner(companyData.idealPartner);
            setServicesPlay(companyData.servicesPlay);

            setKeycustomerArr(companyData.keyCustomers)
            setKeyPartnerArr(companyData.keyPartners);
            setSeedArr(companyData.seed);

            if (companyData.pastRevenue) {
                const arrOptions = (companyData.pastRevenue.options) ? companyData.pastRevenue.options : [];
                const arrSeries = (companyData.pastRevenue.series) ? companyData.pastRevenue.series : [];
                if (arrSeries.length > 0) {
                    pastRevenueSeriesFn(arrOptions, arrSeries)
                }
            }

            if (companyData.projectedRevenue) {
                const arrOptions = (companyData.projectedRevenue.options) ? companyData.projectedRevenue.options : [];
                const arrSeries = (companyData.projectedRevenue.series) ? companyData.projectedRevenue.series : [];
                if (arrSeries.length > 0) {
                    projectedRevenueSeriesFn(arrOptions, arrSeries)
                }
            }
        }
    }, [companyData]);

    useEffect(() => {

        //console.log("revenueTyp55e...");

        if (revenueVal.length > 0 && (revenueVal[0].length > 0)) {

            setGeneralCall(true);

            if (rtype == 'projected') {

                projectedRevenueSeriesFn(revenueYear, revenueVal);
                //setProjectedArr( { 'options' : [...revenueYear], 'series' : [...revenueVal] } );

            } else if (rtype == 'past') {

                pastRevenueSeriesFn(revenueYear, revenueVal);

            }
        }
    }, [revenueYear, revenueVal])

    const pastRevenueSeriesFn = (arrOptions, arrSeries) => {

        let series = [{
            'name': "",
            'data': [...arrSeries],
        }];

        let options = {
            'chart': {
                'id': 'past',
                'toolbar': {
                    'show': true,
                    'tools': { 'download': false }
                }
            },
            'xaxis': {
                'categories': [...arrOptions]
            },
            'colors': ['#2fa360'],
            'yaxis': {
                'labels': {
                    formatter: function (value) {
                        let yval = value;
                        yval = Number(value).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                        return '$' + yval;
                    }
                }
            },
            'dataLabels': {
                formatter: function (val) {
                    let yval = val;
                    yval = Number(yval).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                    return '$' + yval;
                }
            },
            'plotOptions': { 'bar': { 'borderRadius': 10 } },
        }
        setPastRevenueSeries(series);
        setPastRevenueOption(options);
        setPostArr({ 'options': [...arrOptions], 'series': [...arrSeries] })
    }

    const projectedRevenueSeriesFn = (arrOptions, arrSeries) => {

        let series = [{
            'name': "",
            'data': [...arrSeries]
        }];

        let options = {
            'chart': {
                'id': 'projected',
                'toolbar': {
                    'show': true,
                    'tools': { 'download': false }
                }
            },
            'xaxis': {
                'categories': [...arrOptions]
            },
            'colors': ['#2fa360'],
            'yaxis': {
                'labels': {
                    formatter: function (value) {
                        let yval = value;
                        yval = Number(yval).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                        return '$' + yval;
                    }
                }
            },
            'dataLabels': {
                formatter: function (val) {
                    let yval = val;
                    yval = Number(yval).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                    return '$' + yval;
                }
            },
            'plotOptions': { 'bar': { 'borderRadius': 10 } },
        }
        setProjectedRevenueSeries(series);
        setProjectedRevenueOption(options);
        setProjectedArr({ 'options': [...arrOptions], 'series': [...arrSeries] });
    }

    //seed
    const addNewSave = () => {

        if (seedTd1.trim() != "") {
            //&& seedTd2.trim() != "" && seedTd3.trim() != "" && seedTd4.trim() != "" && seedTd5.trim() != "" && seedTd6.trim() != "" 

            setGeneralCall(true);
            const newArr = {
                'series': seedTd1,
                'date': seedTd2,
                'raised': seedTd3,
                'valuation': seedTd4,
                'pps': seedTd5,
                'investors': seedTd6,
            }
            //const allArr = [...seedArr, newArr];
            setSeedArr([...seedArr, newArr]);
            clearSeedRow();

        } else {

            swal({
                title: 'Please enter series number',
                icon: 'error',
                confirmButtonText: 'Yes, delete it!',
                reverseButtons: true,
            });
        }
    }

    const clearSeedRow = () => {
        setSeedTd1('');
        setSeedTd2('');
        setSeedTd3('');
        setSeedTd4('');
        setSeedTd5('');
        setSeedTd6('');
    }

    //Add key partners
    const saveKeyPartners = () => {
        const newArr = {
            'name': keyPartner,
            'imgUrl': keypartnersImg,
        }

        setKeyPartnerAdd(newArr);
        setKeyPartnersAction('add');
        setKeyPartner('');
        let preview = document.getElementById('keypartnerPreview');
        preview.src = images.path.placeholder;
    }

    //Partners remove
    const removePartnersHandle = (index) => {
        swal({
            title: 'Are you sure you want to delete?',
            text: "This item will be delete immediately. You can't undo this action.",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#7A8DA7',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true,
        }).then((resp) => {
            if (resp) {

                setGeneralCall(true);
                setKeyPartnersAction('delete');
                keyPartnerArr.splice(index, 1);
                setKeyPartnerAdd([...keyPartnerArr]);
            }
        })
    };

    const removeSeedHandle = (index) => {
        swal({
            title: 'Are you sure you want to delete?',
            text: "This item will be delete immediately. You can't undo this action.",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#7A8DA7',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true,
        }).then((resp) => {
            if (resp) {
                setGeneralCall(true);
                seedArr.splice(index, 1);
                setSeedArr([...seedArr]);
            }
        })
    };

    const channelLink = (e) => {
        const url = document.getElementById('idealPartner').value;
        if (url != null && url != 'undefined' && url != "") {
            //window.location.href = url + ' target="_blank';
            window.open(url, '_blank');
        }
    }

    const callRevenue = (data) => {

        if (data == 'projected') {
            revenueModelOn(data, projectedArr.options, projectedArr.series);
        } else if (data == 'past') {
            revenueModelOn(data, postArr.options, postArr.series);
        }
    }

    const previewFile = (imgType, file) => {
        const tokenpass = window.localStorage.getItem('token');
        const data = new FormData();
        const path = imgType === "keycustomer" ? "keycustomer" : "keypartner"
        data.append('file', file.target.files[0]);
        data.append('path', path);

        axios.post(`${common.path.base_url}/s3ImageUploader`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                if (response) {
                    if (imgType == 'keycustomer') {
                        const arrCode = [...keycustomerArr];
                        arrCode.push({ 'imgUrl': response.data.imageUrl });
                        setGeneralCall(true);
                        setKeycustomerAction('add');
                        setKeycustomerArr(arrCode);
                        setKeycustomerImg(response.data.imageUrl);
                    } else {
                        setKeypartnersImg(response.data.imageUrl);
                    }
                }
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
            });
        let preview;
        if (imgType == 'keyparter') {
            preview = document.getElementById('keypartnerPreview');
        }
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            if (imgType != 'keycustomer') {
                preview.src = reader.result;
            }
        }, false);

        if (file) {
            reader.readAsDataURL(file.target.files[0]);
        }
    }

    const removeKeyCustomers = (index) => {
        setGeneralCall(true);
        setKeycustomerAction('delete');
        keycustomerArr.splice(index, 1);
        setKeycustomerDelete([...keycustomerArr])
    }

    const updateSeed = (index, updateArr) => {
        setGeneralCall(true);
        seedArr[index] = updateArr;
        setSeedArr([...seedArr]);
    }

    return (
        <>
            <div className='row mt-25'>
                <div className='col-lg-3'>
                    <div className='fieldLable'>Number of Sales Teams</div>
                    <div className='mt-5'>
                        {/* <input type="text" id="numberOfSalesTeams" className='compState piple-input' placeholder='Enter Number of Sales Teams' onChange ={ (e) => inputHandlerChange(e) } value={ numberOfSalesTeams } /> */}
                        <NumberFormat
                            value={numberOfSalesTeams}
                            thousandSeparator={true}
                            id="numberOfSalesTeams"
                            fixedDecimalScale={true}
                            placeholder='Enter Number of Sales Teams'
                            className='compState piple-input'
                            onChange={(e) => { inputHandlerChange(e) }}
                        />
                    </div>
                </div>
                <div className='col-lg-3'>
                    <div className='fieldLable'>Active Regions</div>
                    <div className='mt-5'>
                        <input type="text" id="activeRegions" className='compState piple-input' placeholder='Enter Active Regions' onChange={(e) => inputHandlerChange(e)} value={activeRegions} />
                    </div>
                </div>
                <div className='col-lg-3'>
                    <div className='fieldLable'>Total Customers</div>
                    <div className='mt-5'>
                        {/* <input type="text" id="totalCustomers" className='compState piple-input' placeholder='Enter Total Customers' onChange ={ (e) => inputHandlerChange(e) } value={ totalCustomers } /> */}
                        <NumberFormat
                            value={totalCustomers}
                            thousandSeparator={true}
                            id="totalCustomers"
                            fixedDecimalScale={true}
                            placeholder='Enter Total Customers'
                            className='compState piple-input'
                            onChange={(e) => { inputHandlerChange(e) }}
                        />
                    </div>
                </div>
                <div className='col-lg-3'>
                    <div className='fieldLable'>Vertical Focus</div>
                    <div className='mt-5'>
                        <input type="text" id="verticalFocus" className='compState piple-input' placeholder='Enter Vertical Focus' onChange={(e) => inputHandlerChange(e)} value={verticalFocus} />
                    </div>
                </div>

                <div className='col-lg-12 mt-30'>
                    <div className='fieldLable'>Public Sector / Fed Strategy</div>
                    <div className='mt-5'>
                        {/* <input type="text" id="publicSector" className='compState piple-input' placeholder='Enter Public Sector / Fed Strategy' onChange ={ (e) => inputHandlerChange(e) } value={ publicSector } /> */}
                        <textarea className="textArea" id="publicSector" placeholder="Enter Public Sector / Fed Strategy" value={publicSector} onChange={(e) => { inputHandlerChange(e) }} ></textarea>
                    </div>
                </div>
            </div>
            <div className='mt-25'>
                <hr />
            </div>

            <div className='sectionHeading2'>Key Customers</div>
            <div className='keycustomerCont mt-15'>
                <div className='addkeycustomer mr-15' onClick={(e) => (keycustomeruploadRef.current.click())}>
                    <div className='addiconCont'>{Icons.Addicon}</div>
                    <input id="inputKeyCustomerFile" className='keycustomerUpload piple-input' type="file" ref={keycustomeruploadRef} onChange={(e) => previewFile('keycustomer', e)} accept="image/jpeg,image/png,image/jpg" />
                </div>

                {
                    (keycustomerArr.map((singleData, index) => {

                        return <div key={'keycustomer_' + index} className='addkeycustomer mr-15'>
                            <div className='customerlogoCont'>
                                <img src={singleData.imgUrl.includes("http") ? singleData.imgUrl : `${common.path.base_url}/getImage?type=company&typeCode=keyCustomers&codeId=${singleData._id}&id=${companyId}`} className='keycustomerImage' alt='' />
                                <div className='keycustomerRemove' onClick={() => { removeKeyCustomers(index); }}>{Icons.RemoveG}</div>
                            </div>
                        </div>

                    }))
                }

                {/* <div className='addkeycustomer mr-15'>
                    <div className='customerlogoCont'>
                        <img src="assets/flugger.png" className='keycustomerImage' alt='' />
                        <div className='keycustomerRemove' onClick={() => { removeHandle(); }}>{Icons.RemoveG}</div>
                    </div>
                </div> */}
                {/* <div className='addkeycustomer mr-15'>
                    <div className='customerlogoCont'>
                        <img src="assets/cadacri.png" className='keycustomerImage' alt='' />
                        <div className='keycustomerRemove' onClick={() => { removeHandle(); }}>{Icons.RemoveG}</div>
                    </div>
                </div> */}
            </div>
            <div className='mt-20'>
                <hr />
            </div>


            <div className='sectionHeading2'>Channel Maturity</div>
            <div className='row'>
                <div className='col-lg-6'>
                    <div className='channelmaturityitemCont'>
                        <div className='fieldLable'>Total Partners Signed</div>
                        <div className='mt-5'>
                            {/* <input type="text" id="totalPartnersSigned" className='compState piple-input' placeholder='Enter Total Partners Signed' onChange ={ (e) => inputHandlerChange(e) } value={ totalPartnersSigned } /> */}
                            <NumberFormat
                                value={totalPartnersSigned}
                                thousandSeparator={true}
                                id="totalPartnersSigned"
                                fixedDecimalScale={true}
                                placeholder='Enter Total Partners Signed'
                                className='compState piple-input'
                                onChange={(e) => { inputHandlerChange(e) }}
                            />
                        </div>
                    </div>

                    <div className='channelmaturityitemCont'>
                        <div className='fieldLable'>% of annual revenue currently through channel</div>
                        <div className='mt-5'>
                            <input type="text" id="companyAnnualRevenue" className='compState piple-input' placeholder='Enter annual revenue currently through channel' onChange={(e) => inputHandlerChange(e)} value={companyAnnualRevenue} />
                        </div>
                    </div>

                    <div className='channelmaturityitemCont'>
                        <div className='fieldLable'>Ideal channel partner profile</div>
                        <div className='externallinkCont mt-5'>
                            <div className='flex-1'>
                                <input type="text" id="idealPartner" className='compState piple-input' placeholder='Enter Ideal channel partner profile' onChange={(e) => inputHandlerChange(e)} value={idealPartner} />
                            </div>
                            <div className='ml-10' onClick={(e) => channelLink(e)} >{Icons.External}</div>
                        </div>
                    </div>
                </div>

                <div className='col-lg-6'>
                    <div className='channelmaturityitemCont'>
                        <div className='fieldLable'>Portion of them actively engaged</div>
                        <div className='mt-5'>
                            {/* <input type="text" id="activelyEngaged" className='compState piple-input' placeholder='Enter Portion of them actively engaged' onChange ={ (e) => inputHandlerChange(e) } value={ activelyEngaged } /> */}
                            <NumberFormat
                                value={activelyEngaged}
                                thousandSeparator={true}
                                id="activelyEngaged"
                                fixedDecimalScale={true}
                                placeholder='Enter Portion of them actively engaged'
                                className='compState piple-input'
                                onChange={(e) => { inputHandlerChange(e) }}
                            />
                        </div>
                    </div>

                    <div className='channelmaturityitemCont'>
                        <div className='fieldLable'>Desired % of annual revenue through channel</div>
                        <div className='mt-5'>
                            <input type="text" id="desiredAnnualRevenue" className='compState piple-input' placeholder='Enter Desired % of annual revenue through channel' onChange={(e) => inputHandlerChange(e)} value={desiredAnnualRevenue} />
                        </div>
                    </div>

                    <div className='channelmaturityitemCont'>
                        <div className='fieldLable'>Services play for channel</div>
                        <div className='mt-5'>
                            <input type="text" id="servicesPlay" className='compState piple-input' placeholder='Enter Services play for channel' onChange={(e) => inputHandlerChange(e)} value={servicesPlay} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='sectionHeading2'>Key Partners</div>
            <div className='keypartnerCont'>
                {(keyPartnerArr.length > 0) ?
                    keyPartnerArr.map((singleData, index) => {

                        // return <div className='keypartnerItem'>
                        //     <div className='mr-10'>
                        //         { ( singleData.imgUrl != "" && singleData.imgUrl != null && singleData.imgUrl != 'undefined') ? 
                        //             <img src={`${common.path.base_url}/getImage?type=company&typeCode=keyPartners&codeId=${singleData._id}&id=${companyId}`} className='incumbentImage' alt='' />
                        //         :
                        //             <img src={ images.path.placeholder } className='incumbentImage' alt='' />
                        //         }
                        //     </div>
                        //     <div className='socialinputCont'>
                        //         <input type="text" className='defaultInput piple-input' placeholder='Enter Key Partner' value={ singleData.name } />
                        //     </div>
                        //     <div className='keypartnersTrash mr-10' onClick={() => { removePartnersHandle(index); }}>{Icons.Trash}</div>
                        // </div>

                        return <KeyPartnersData
                            key={'keyPartner_' + index}
                            singleData={singleData}
                            index={index}
                            removePartnersHandle={() => removePartnersHandle(index)}
                            companyId={companyId}
                        />

                    })
                    : ""
                }

                <div className='keypartnerItem ml-15'>
                    <div className='uploadimageCont mr-10' onClick={(e) => (keyparterUploadRef.current.click())}>
                        <div className='uploadimage'>
                            <img id='keypartnerPreview' src={images.path.placeholder} className='inputIncumbentImage' alt='' />
                        </div>
                        <input id="keypartnerFile" className='uploadimageController piple-input' type="file" ref={keyparterUploadRef} onChange={(e) => previewFile('keyparter', e)} accept="image/jpeg,image/png,image/jpg" />
                    </div>
                    <div className='placeholderName'>
                        <input type="text" id="keyPartner" className='incumbentName piple-input' placeholder='Full Name' onChange={(e) => { inputHandlerChange(e); }} value={keyPartner} />
                    </div>
                    <div className={keyPartner.length > 0 ? 'showcheckIcon mr-10 ml-10' : 'hidecheckIcon mr-10 ml-10'} onClick={() => saveKeyPartners()}>{Icons.Check}</div>
                </div>
            </div>

            <div className='sectionHeading2 mt-25'>Revenue</div>
            <div className='revenueBarChart'>
                <div className='revenueBarChartChild'>
                    <div className='revenueTBtn'>
                        <div className='revenueTitle'>Past 3yrs Revenue</div>
                        <div>
                            <button className='addnewBTN' onClick={(e) => callRevenue('past')} >Add New</button>
                        </div>
                    </div>
                    {(Object.keys(pastRevenueOption).length > 0) &&
                        <ReactApexChart options={pastRevenueOption} series={pastRevenueSeries} type="bar" width={500} />
                    }
                </div>
                <div className='revenueBarChartChild'>
                    <div className='revenueTBtn'>
                        <div className='revenueTitle'>Projected</div>
                        <div>
                            <button className='addnewBTN' onClick={(e) => callRevenue('projected')} >Add New</button>
                            {/* <button className='addnewBTN' data-toggle="modal" data-target="#revenueModel">Add New</button> */}
                        </div>
                    </div>
                    {(Object.keys(projectedRevenueOption).length > 0) &&
                        <ReactApexChart options={projectedRevenueOption} series={projectedRevenueSeries} type="bar" width={500} />
                    }
                </div>
            </div>


            <div className='sectionHeading2 mt-25'>Seed</div>
            <div className='row'>
                <div className='col-lg-12'>
                    <div className="table-responsive-lg">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Series</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Raised</th>
                                    <th scope="col">Valuation</th>
                                    <th scope="col">PPS</th>
                                    <th scope="col">Investors</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>


                                {(seedArr.length > 0) ?
                                    seedArr.map((singleData, i) => {

                                        return <SeedData
                                            key={i + "_seed"}
                                            singleData={singleData}
                                            index={i}
                                            removeSeedHandle={(i) => removeSeedHandle(i)}
                                            updateSeed={(i, updateArr) => updateSeed(i, updateArr)}
                                        />
                                    })
                                    : ""
                                }

                                <tr className='seedFixedtr'>
                                    <td>
                                        <input type="text" className='compState piple-input' placeholder='Enter Series' onChange={(e) => setSeedTd1(e.target.value)} value={seedTd1} />
                                    </td>
                                    <td>
                                        <input type="text" className='compState piple-input' placeholder='Enter Date' onChange={(e) => setSeedTd2(e.target.value)} value={seedTd2} />
                                    </td>
                                    <td>
                                        {/* <input type="text" className='compState piple-input' placeholder='Enter Raised' onChange={ (e) => setSeedTd3(e.target.value) } value={ seedTd3 } /> */}
                                        <NumberFormat
                                            value={seedTd3}
                                            thousandSeparator={true}
                                            id="totalCustomers"
                                            prefix={'$'}
                                            fixedDecimalScale={true}
                                            placeholder='Enter Raised'
                                            className='compState piple-input'
                                            onChange={(e) => setSeedTd3(e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        {/* <input type="text" className='compState piple-input' placeholder='Enter Valuation' onChange={ (e) => setSeedTd4(e.target.value) } value={ seedTd4 } /> */}
                                        <NumberFormat
                                            value={seedTd4}
                                            thousandSeparator={true}
                                            id="totalCustomers"
                                            prefix={'$'}
                                            fixedDecimalScale={true}
                                            placeholder='Enter Valuation'
                                            className='compState piple-input'
                                            onChange={(e) => setSeedTd4(e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        {/* <input type="text" className='compState piple-input' placeholder='Enter PPS' onChange={ (e) => setSeedTd5(e.target.value) } value={ seedTd5 } /> */}
                                        <NumberFormat
                                            value={seedTd5}
                                            thousandSeparator={true}
                                            id="totalCustomers"
                                            prefix={'$'}
                                            fixedDecimalScale={true}
                                            placeholder='Enter PPS'
                                            className='compState piple-input'
                                            onChange={(e) => setSeedTd5(e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input type="text" className='compState piple-input' placeholder='Enter Investors' onChange={(e) => setSeedTd6(e.target.value)} value={seedTd6} />
                                    </td>
                                    <td>
                                        <div title='Clear' className='tableTrashicon' onClick={() => { clearSeedRow() }}>{Icons.RemoveG}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className='addmoreCont'>
                <div className='addmoreList' onClick={() => addNewSave()}>+Add / Save</div>
            </div>
        </>
    )
}

export default StageMaturity;
import axios from 'axios';
import React, { Component } from 'react';
import LaddaButton, { EXPAND_RIGHT, XS } from 'react-ladda';
import NumberFormat from "react-number-format";
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import common from '../../common';
import RequiredSign from '../../components/RequiredSign';
import './investmentFollowup.scss';

class WireToStartup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadinglada: false,
            loading: false,
            name: '',
            accountNumber: '',
            routingNumber: '',
            amount: '',
            email: '',
            investmentId: '',
            walletBalanceData: {},
            disabled: false,
        }
        this.screenWidth = null;
    }
    componentWillMount() {
        if (this.props.investmentData != null) {
            const data = this.props.investmentData;
            if (data.wireDetails && data.wireDetails.amount > 0) {
                this.setState({
                    name: data.wireDetails.name,
                    accountNumber: data.wireDetails.accountNumber,
                    routingNumber: data.wireDetails.routingNumber,
                    amount: data.wireDetails.amount,
                    email: data.wireDetails.email,
                });
            }
        }
        this.investmentID = this.props.match.params.id;
        this.setState({ investmentId: this.investmentID });
        if (this.props.investmentData.verivendId) {
            this.getWalletBalance(this.props.investmentData.verivendId);
        }
    }
    isValidate() {
        return this.state.name !== "" && this.state.accountNumber !== "" && this.state.routingNumber !== "" && this.state.email !== "" && Number(this.state.amount) > 0;
    }
    onChange(id, value) {
        this.setState({ [id]: value })
    }

    getWalletBalance(investmentId) {
        let temp = this;
        const token = window.localStorage.getItem('token');
        temp.setState({ loadinglada: true });
        axios.get(`${common.path.base_url}/getWalletBalance/${investmentId}`, {
            headers: {
                Authorization: token,
            }
        }).then(response => {
            temp.setState({ loadinglada: false });
            if (response.data && response.data.data) {
                temp.setState({ walletBalanceData: response.data.data });
            }
        }).catch((error) => {
            temp.setState({ disabled: true });
            temp.setState({ loadinglada: false });
            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                window.localStorage.clear();
                window.location.href = '/';
                return;
            }
            else {

                swal({
                    title: "Error!",
                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                    icon: "error",
                    button: "Ok!",
                });
            }
        });
    }

    save() {
        let temp = this;
        const token = window.localStorage.getItem('token');
        const userId = window.localStorage.getItem('id');
        temp.setState({ loadinglada: true });
        const data = {
            ...this.state,
            userId,
            investmentName: this.props.investmentData.name,
        };
        axios.post(`${common.path.base_url}/wirePaymentToStartup`, data, {
            headers: {
                Authorization: token,
            }
        }).then(response => {
            temp.setState({ loadinglada: false });
            if (response.data) {
                swal({
                    title: "Success!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                });
            }
        }).catch((error) => {
            temp.setState({ loadinglada: false });
            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                window.localStorage.clear();
                window.location.href = '/';
                return;
            }
            else {
                swal({
                    title: "Error!",
                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                    icon: "error",
                    button: "Ok!",
                });
            }
        });;
    }

    render() {
        return (
            <div className="checklist-container container-fluid">
                <div className="new-investment">
                    <h2>Wire To Startup</h2>
                </div>
                <div className="invitations_right invitations_right_fund recommend-startup">
                    <div className="col-lg-6 col-md-6">
                        <div className="form-horizontal recommand_form">
                            <div className="row d-flex">
                                <div className="col-sm-7 col-md-12" style={{ paddingBottom: "20px" }}>
                                    <h4>Wallet Balance <p></p>
                                        <NumberFormat
                                            value={this.state.walletBalanceData.currentBalance || 0}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            fixedDecimalScale={true}
                                            // decimalScale={2}
                                            displayType={'text'}
                                        />
                                    </h4>
                                </div>
                                <div className="col-sm-7 col-md-12" style={{ paddingBottom: "20px" }}>
                                    <h4>Pending Balance <p></p>
                                        <NumberFormat
                                            value={this.state.walletBalanceData.pendingBalance || 0}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            fixedDecimalScale={true}
                                            // decimalScale={2}
                                            displayType={'text'}
                                        />
                                    </h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-7 col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="name">Name<RequiredSign /> :</label>
                                        <div className='recommendation-input'>
                                            <input type="text"
                                                value={this.state.name}
                                                onChange={(event) => this.onChange(event.target.id, event.target.value)}
                                                name="name"
                                                className="form-control"
                                                id="name"
                                                disabled={this.state.disabled}
                                                placeholder=""
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-7 col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="name">Email Address<RequiredSign /> : </label>
                                        <div className='recommendation-input'>
                                            <input type="text"
                                                value={this.state.email}
                                                disabled={this.state.disabled}
                                                onChange={(event) => this.onChange(event.target.id, event.target.value)}
                                                name="email"
                                                className="form-control"
                                                id="email"
                                                placeholder=""
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-7 col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="name">Routing Number<RequiredSign /> :</label>
                                        <div className='recommendation-input'>
                                            <input type="text"
                                                value={this.state.routingNumber}
                                                disabled={this.state.disabled}
                                                onChange={(event) => this.onChange(event.target.id, event.target.value)}
                                                name="routingNumber"
                                                className="form-control"
                                                id="routingNumber"
                                                placeholder=""
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-7 col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="name">Account Number<RequiredSign /> :</label>
                                        <div className='recommendation-input'>
                                            <input type="text"
                                                value={this.state.accountNumber}
                                                disabled={this.state.disabled}
                                                onChange={(event) => this.onChange(event.target.id, event.target.value)}
                                                name="accountNumber"
                                                className="form-control"
                                                id="accountNumber"
                                                placeholder=""
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-7 col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="name">Amount <RequiredSign /> :</label>
                                        <div className='recommendation-input'>
                                            <input type="text"
                                                value={this.state.amount}
                                                disabled={this.state.disabled}
                                                onChange={(event) => this.onChange(event.target.id, event.target.value)}
                                                name="amount"
                                                className="form-control"
                                                id="amount"
                                                placeholder=""
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-7 col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="name"></label>
                                        <div className='fundding-instructions'>
                                            <LaddaButton
                                                id='lada-btn'
                                                loading={this.state.loadinglada}
                                                onClick={() => this.save()}
                                                data-color="#eee"
                                                data-size={XS}
                                                className={!this.isValidate() ? 'disable' : ''}
                                                data-style={EXPAND_RIGHT}
                                                data-spinner-size={30}
                                                data-spinner-color="#ddd"
                                                data-spinner-lines={12}
                                            >
                                                PAYMENT
                                            </LaddaButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(WireToStartup);
import axios from 'axios/index';
import jsonpLib from 'jsonp';

axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('token');

function _urlWithQueryParams(url, queryParams) {
  let paramsText = '';
  Object.keys(queryParams).forEach((paramKey, index) => {
    paramsText += paramKey + '=' + queryParams[paramKey]
    if (index < Object.keys(queryParams).length - 1) {
      paramsText += '&';
    }
  })
  return url + '/?' + paramsText;
}

function _buildEndpoint(url, queryParams) {
  return queryParams && Object.keys(queryParams).length ? _urlWithQueryParams(url, queryParams) : url;
}

// TODO: The key 'data' in the options object isn't being properly read by backend for some reason.
// Figure out.
// Handles GET requests
function get({ endpoint, body, headers, queryParams }) {
  const builtEndpoint = _buildEndpoint(endpoint, queryParams);
  const options = {
    url: builtEndpoint,
    method: 'GET',
    headers,
    data: body,
  };
  return new Promise((resolve) => {
    resolve(axios(options));
  });
}

// Handles POST requests
function post({ endpoint, body, headers, queryParams }) {

  const options = {
    url: _buildEndpoint(endpoint, queryParams),
    method: 'POST',
    headers,
    data: body,
  };
  return new Promise((resolve) => {
    resolve(axios(options));
  });
}

function formPost({ endpoint, formData, headers }) {
  return new Promise((resolve) => {
    resolve(axios.post(endpoint, formData, {headers}));
  });
}

function jsonp(endpoint) {
  return new Promise((resolve) => {
    jsonpLib(endpoint, null, (err, data) => {
      if (err) {
        resolve();
      } else {
        resolve(data);
      }
    });
  });
}

// Handles PUT requests
function put({ endpoint, body, headers, queryParams }) {

  const options = {
    url: _buildEndpoint(endpoint, queryParams),
    method: 'PUT',
    headers,
    data: body,
  };
  return new Promise((resolve) => {
    resolve(axios(options));
  });
}

const verbs = {
  get,
  post,
  jsonp,
  formPost,
  put,
};

export default verbs;

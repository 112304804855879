import React, { Component, useState, useEffect } from 'react';
import './FundingDesc.scss';
import '../SolutionSummaryDesc/SolutionSummaryDesc.scss';
import LoadingSpinner1 from '../../../components/IIR Loader';
import { withRouter } from 'react-router-dom';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import axios from 'axios';
import swal from 'sweetalert';
import common from '../../../common';
import _ from 'lodash';
import images from "../../../images";
import FileUploadBlob from '../../../components/FileUploadBlob';
import FileUploadBlobUpdate from '../../../components/FileUploadBlobUpdate';
import ProfileImage from '../../../components/ProfileImage';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import TButton from '../../../components/TButton';
import Modal from 'react-bootstrap-modal';
import Editor from "../Editor";
import { Markup } from 'interweave';
import { memo } from "react";
import moment from 'moment';

const SortableItem = SortableElement(({ value, deleteText, deleteImage, editClick, editClickText }) =>
    <li className={value.headerId == null ? `` : `sortableStyle`}>
        {value.text && value.text != '' ?
            <div className='solutionSummaryDesc-options'>
                <div className={value.headerId == null ? `solutionSummaryDesc-option` : `solutionSummaryDesc-option marg-l-1p`}>
                    {/* <Markup content={value.text} /> */}
                    <p className="ql-editor"><Markup content={value.text} /></p>
                    <div className='icons'>
                        <i onClick={() => editClickText(value)}
                            className="fa fa-pencil add-address"></i>
                        <img className='delete' onClick={() => deleteText(value._id)} src={images.path.deleteIcon}></img>
                    </div>
                </div>
            </div>
            : ''}
        {(value.pictureId && value.pictureId != '') ?
            <div className='solutionSummaryDesc-options'>
                <div className={value.headerId == null ? `solutionSummaryDesc-option` : `solutionSummaryDesc-option marg-l-1p`}>
                    <div className="leader-img">
                        {/* <div className="dragicons-mi">
                            <i className="fa fa-bars" aria-hidden="true"  ></i>
                        </div> */}
                        <ProfileImage
                            imgSrc={`${common.path.base_url}/getFileNew?fileId=${value.pictureId}`}
                        />
                        <div className="icons">
                            <i onClick={() => editClick(value)}
                                className="fa fa-pencil add-address"></i>
                            <img src={images.path.deleteIcon} onClick={() => deleteImage(value._id)} className="delete"></img>
                        </div>
                    </div>
                </div>
            </div> : ''}
    </li>);

const SortableList = SortableContainer(({ items, deleteText, deleteImage, editClick, editClickText }) => {
    return (
        // logo-main
        <div className='solutionSummaryDesc-options-mi'>
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} deleteText={(index) => deleteText(index)} deleteImage={(index) => deleteImage(index)} editClick={(value) => editClick(value)} editClickText={(value) => editClickText(value)} />
            ))}
        </div>
    );
});



const FundingDesc = memo((props) => {
    // company and investments declared
    const url = new URL(window.location.href);
    const companyId = url.searchParams.get('companyId');
    const investmentId = url.searchParams.get('investmentId');

    // initial states of columns in case if this is the first time editing this document
    var initialColumns = {
        col1: "Round",
        col2: "Date",
        col3: "Total Raise (MM)",
        col4: "Price per Share",
        col5: "Valuation (MM)",
        col6: "Investors"
    }

    // initial states of rows in case if this is the first time editing this document
    // var initialRows = [
    //     { col11: "", col21: "", col31: "", col41: "", col51: "", col61: "" },
    //     { col12: "", col22: "", col32: "", col42: "", col52: "", col62: "" },
    //     { col13: "", col23: "", col33: "", col43: "", col53: "", col63: "" },
    //     { col14: "", col24: "", col34: "", col44: "", col54: "", col64: "" },
    //     { col15: "", col25: "", col35: "", col45: "", col55: "", col65: "" },
    //     { col16: "", col26: "", col36: "", col46: "", col56: "", col66: "" },
    //     { col17: "", col27: "", col37: "", col47: "", col57: "", col67: "" },
    //     { col18: "", col28: "", col38: "", col48: "", col58: "", col68: "" },
    // ];

    // Setting states of other required variables
    const [loading, setLoading] = useState(true);
    const [Role, setRole] = useState('');
    const [columns, setColumns] = useState(initialColumns);
    const [rows, setRows] = useState([]);
    const [decision, setDecision] = useState("add");
    const [isuploaded, setisUploaded] = useState(false);
    const [imageuploading, setimageUploading] = useState(false);
    const [choosenFile, setChoosenFile] = useState(null);
    const [picture, setPicture] = useState('');
    const [fundingData, setFundingData] = useState([]);
    const [imageloading, setImageLoading] = useState(false);

    const [show, setShow] = useState(false);
    const [imagePop, setImagePop] = useState(false);
    const [textPop, setTextPop] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalButtonTitle, setModalButtonTitle] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [text, setText] = useState('');

    // after rendering - runnning once
    useEffect(() => {
        const userRole = window.localStorage.getItem('role');
        setRole(userRole);
        getfundingDescData();
    }, []);
    // getting the data if it already exists
    const getfundingDescData = async () => {
        // getting token for authorization
        let token = window.localStorage.getItem('token');
        // API to to get data from DB
        const response = await axios.get(`${common.path.base_url}/getMarketInvestment/${investmentId}/FundingDesc`,
        {
            headers: {
                "Authorization": token
            }
        })
        if(response.data){
            let responseData = response.data.data[0];
            
            // if data exists then update states
            if (responseData !== undefined) {
                setLoading(false);
                let responselen = response.data.data[0].data;
                let respData = response.data.data[0].data[responselen.length - 1].fundingDesc;
                setFundingData(response.data.data[0].data);
                // if (respData[0]) {
                //     setColumns(respData[0]);
                // }
                if (respData[1]) {
                    const jsonArr = respData[1];
                    const newJsonData = {
                        series: "",
                        date: "",
                        raised: "",
                        pps: "",
                        valuation: "",
                        investors: ""
                        };
                    if(jsonArr.length < 8){
                        for (let i = 0; jsonArr.length < 8; i++) {
                            jsonArr.push(newJsonData);
                        }
                        setRows(jsonArr);
                    } else {
                        setRows(respData[1]);
                    }
                }
            } else {
                const newJsonData = {
                    series: "",
                    date: "",
                    raised: "",
                    pps: "",
                    valuation: "",
                    investors: ""
                    };
                const data = [];
                for (let i = 0; data.length < 8; i++) {
                    data.push(newJsonData);
                }
                setRows(data);
                setLoading(false);
            }
        } else {
            setLoading(false);
            if (response) {
                swal({
                    title: "Error!",
                    text: response.data.message,
                    icon: "error",
                    button: "Ok!",
                });
            }
        }
    }

    // On columns changes
    // const onColumnChange = (event) => {
    //     setColumns({
    //         ...columns,
    //         [event.target.name]: event.target.value
    //     });
    // }

    // On row changes
    const onRowChange = (data, event, dataIndex, eventValue) => {
        let newData = {...rows[dataIndex]};
        newData[event] = eventValue.target.value;

        let newRows = [...rows];
        newRows[dataIndex] = newData;
        setRows(newRows);
    }


    // function to Add or Update the table details
    const updateTable = event => {
        // getting the column values
        let ColValues = Object.values(columns);
        // variables used to know the last row and column of the table
        var lastCol = 0;
        var lastrow = 0;
        var temp_arr = [];

        // loop to get the last column
        for (let i = ColValues.length; i > 0; i--) {
            let index = i - 1;
            if (ColValues[index] !== "") {
                lastCol = i;
                break
            }
        }

        // loop to get the last row
        rows.map((actual_row) => {
            let RowValues = Object.values(actual_row);
            let temp_status = 0;

            RowValues.map((item) => {
                if (item !== "") {
                    temp_status = 1;
                }
            });

            temp_arr.push(temp_status);
        });

        for (let i = temp_arr.length; i > 0; i--) {
            let index = i - 1;
            if (temp_arr[index] !== 0) {
                lastrow = i;
                break
            }
        }

        // constructing variable to pass to DB
        let data = [];
        data.push(columns);
        data.push(rows);
        data.push(lastCol);
        data.push(lastrow);

        // Displaying loading and getting token for authorization
        setLoading(true);
        let token = window.localStorage.getItem('token');

        // Deciding if to add or update
        var route_to;
        var method;

        decision === "add" ? route_to = "addFundingDesc" : route_to = "updateFundingDesc";
        decision === "add" ? method = "post" : method = "put";

        // API to push data to DB
        axios[method](`${common.path.base_url}/${route_to}`,
            {
                companyid: companyId,
                investmentid: investmentId,
                marketType: "FundingDesc",
                updateData: data,
                pictureId: picture,
                text: text,
            },
            {
                headers: {
                    "Authorization": token
                }
            })
            .then((response) => {
                setLoading(false);
                resetFields();
                getfundingDescData();
                swal({
                    title: "Updated!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
            })
            .catch((error) => {
                setLoading(false);
                if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }
    const uploadLogo = (file) => {
        if (file.size) {
            setimageUploading(true);
            let tokenpass = window.localStorage.getItem('token');
            let data = new FormData();
            data.append('file', file);
            const isPrivate = true;
            data.append('isPrivate', isPrivate)
            axios.post(`${common.path.base_url}/uploadFile`, data, {
                headers: {
                    "Authorization": tokenpass
                }
            })
                .then((response) => {
                    setPicture(response.data.data.id);
                    setisUploaded(true);
                    setimageUploading(false);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        window.localStorage.clear();
                        window.location.href = '/';
                        return;
                    } else { }
                });
        }
    }

    // Handling Functions For ADD IMAGE & ADD TEXT Module
    const editorHandleChange = (editorHtml) => {
        setText(editorHtml);
    }
    const onEditClick = (element) => {
        setShow(true);
        setIsEdit(true);
        setImagePop(true);
        setModalTitle('Update Image');
        setModalButtonTitle('UPDATE');
        setPicture(element.pictureId);

    }
    const onEditClickText = (element) => {
        setShow(true);
        setIsEdit(true);
        setTextPop(true);
        setModalTitle('Update Text');
        setModalButtonTitle('UPDATE');
        setText(element.text);
    }
    const deleteText = (index, type) => {

        var method;
        method = "delete";
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willdelete) => {

            const token = localStorage.getItem('token');
            if (willdelete) {
                setLoading(true);
                axios.delete(`${common.path.base_url}/deleteImageInFundingDesc`, {
                    headers: { "Authorization": token }, data: {
                        companyId: companyId,
                        investmentId: investmentId,
                        marketType: "FundingDesc",
                        id: index,
                        type: 'text'
                    }
                }
                )
                    //@TODO CALL ON SUCCESS OF API
                    .then((response) => {
                        setLoading(false);
                        swal({
                            title: "Success!",
                            text: response.data.message,
                            icon: "success",
                            button: "Ok!",
                        })
                            .then(() => {
                                setLoading(false);
                                resetFields();
                                getfundingDescData();

                            })
                    })
                    .catch((error) => {
                        setLoading(false);
                        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                            window.localStorage.clear();
                            window.location.href = '/';
                            return;
                        }
                        else {
                            swal({
                                title: "Error!",
                                text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                icon: "error",
                                button: "Ok!",
                            });
                        }
                    });

            }
        })
    }
    const deleteImage = (index, type) => {

        var method;
        method = "delete";
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willdelete) => {

            const token = localStorage.getItem('token');
            if (willdelete) {
                setLoading(true);
                axios.delete(`${common.path.base_url}/deleteImageInFundingDesc`, {
                    headers: { "Authorization": token }, data: {
                        companyId: companyId,
                        investmentId: investmentId,
                        marketType: "FundingDesc",
                        id: index,
                        type: 'picture'
                    }
                }
                )
                    //@TODO CALL ON SUCCESS OF API
                    .then((response) => {
                        setLoading(false);
                        swal({
                            title: "Success!",
                            text: response.data.message,
                            icon: "success",
                            button: "Ok!",
                        })
                            .then(() => {
                                setLoading(false);
                                resetFields();
                                getfundingDescData();
                            })
                    })
                    .catch((error) => {
                        setLoading(false);
                        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                            window.localStorage.clear();
                            window.location.href = '/';
                            return;
                        }
                        else {
                            swal({
                                title: "Error!",
                                text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                icon: "error",
                                button: "Ok!",
                            });
                        }
                    });

            }
        })
    }
    const resetFields = () => {
        setText('');
        setShow(false);
        setImagePop(false);
        setTextPop(false);
        setIsEdit(false);
        setChoosenFile(null);
        setPicture('');
    }


    // const syncButtonHandler = () => {
    //     swal({
    //         title: 'Are you sure you want to sync IIR with SCP?',
    //         text: "This data will be synced. You can't undo this action.",
    //         icon: 'warning',
    //         buttons: true,
    //     }).then((resp) => {
    //         if(resp) {
    //             syncFundingtoIIR();
    //             // setGeneralCall(true);
    //             // engageArr.splice(index, 1); 
    //             // setEngageArr([...engageArr]);
    //         } 
    //     }) 
    // }

    return (
        <div className="fundingDesc">
            {loading ? <LoadingSpinner1 /> : ""}
            <div className="fundingDesc-main">
                <div className='heading'>
                    <h3>Funding Information / History</h3>
                </div>
                <div className="solutionSummaryDesc">
                    <div className="solutionSummaryDesc">
                        <div className='solutionSummaryDesc-details'>
                            <form className="form-horizontal">
                                <div className="form-group">
                                    <div className="col-md-8 addNewButton ">
                                        <TButton
                                            onClick={() => { setShow(true); setImagePop(true); setModalTitle('Add Image'); setModalButtonTitle('ADD'); }}
                                            text={`ADD IMAGE`} id="update-btn"
                                        />

                                        <div className="add-txt">
                                            <TButton
                                                onClick={() => { setShow(true); setTextPop(true); setModalTitle('Add Text'); setModalButtonTitle('ADD'); }}
                                                text={`ADD TEXT`} id="update-btn"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* <div className="majorInvestor">
                    <div className='majorInvestor-details'>
                        <React.Fragment>
                            <form className="form-horizontal">
                                <div className="form-group">
                                    <label className="col-md-3 control-label company-label">Image:</label>
                                    <div className='col-md-8'>
                                        <div className='upload-majorInvestor-pic  img-box'>
                                            <div className="majorInvestor-pic-box">
                                                <img id='preview' className={`profile-pic ${isuploaded ? '' : 'hide-icon'}`} src={imageuploading ? `${images.path.loader}` : `${common.path.base_url}/getFileNew?fileId=${picture}`} alt='' />
                                                {choosenFile !== null ?
                                                    '' : <div>
                                                        <div className="p-image">
                                                            <i onClick={() => document.querySelector(`#chooseFile${1}`).click()} className="fa fa-plus-circle upload-button"></i>
                                                            <FileUploadBlob
                                                                id={`chooseFile${1}`}
                                                                getFile={(file) => { setChoosenFile(file); uploadLogo(file) }}
                                                                acceptType={`image/*`}
                                                            />
                                                        </div>
                                                        <div className='logo-name'>Image</div>
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="form-group">
                                    <label className="col-md-3 control-label1"></label>
                                    <div className="col-md-8 addNewButton">
                                        <LaddaButton
                                            id="update-btn"
                                            loading={imageloading}
                                            onClick={() => this.addMajorInvestorData()}
                                            data-color="#eee"
                                            data-size={XS}
                                            data-style={EXPAND_RIGHT}
                                            data-spinner-size={30}
                                            data-spinner-color="#ddd"
                                            data-spinner-lines={12}
                                            className={choosenFile === null || imageuploading === true ? 'disable' : ''}
                                        >
                                            ADD NEW
                                            </LaddaButton>
                                        <div className="save-btn">
                                            <LaddaButton
                                                id="update-btn"
                                                loading={imageloading}
                                                onClick={() => this.getIIRList()}
                                                data-color="#eee"
                                                data-size={XS}
                                                data-style={EXPAND_RIGHT}
                                                data-spinner-size={30}
                                                data-spinner-color="#ddd"
                                                data-spinner-lines={12}
                                                className={_.isEmpty(fundingData) ? 'disable' : ''}
                                            >
                                                SAVE
                                            </LaddaButton>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </React.Fragment>
                    </div>
                </div> */}
                <div className="footBorder"></div>
                <div className='syncData'>
                <div className="syncButton">
                    <h5 className="info">Please fill in the corresponding information in each field to populate fundraising data points.</h5>
                        {/* <TButton
                            onClick={() => { syncButtonHandler()}}
                            text={`SYNC`} id="sync-button"
                        /> */}
                </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className="fundingDesc table-responsive-lg">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className='text-center'>{columns.col1}</th>
                                        <th className='text-center'>{columns.col2}</th>
                                        <th className='text-center'>{columns.col3}</th>
                                        <th className='text-center'>{columns.col4}</th>
                                        <th className='text-center'>{columns.col5}</th>
                                        <th className='text-center'>{columns.col6}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { (rows.length > 0) ?
                                        rows.map((singleData, i ) => { 
                                            
                                            return <DefinedRows
                                                key = { i +"_seed" }
                                                data = {singleData}
                                                callback={onRowChange} 
                                                cols={columns}
                                                dataIndex = {i}
                                            />
                                        }) 
                                        : "" 
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> 

                <LaddaButton
                    id='lada-btn'
                    loading={loading}
                    onClick={updateTable}
                    data-color="#eee"
                    data-size={XS}
                    className="update_btn"
                    data-style={EXPAND_RIGHT}
                    data-spinner-size={30}
                    data-spinner-color="#ddd"
                    data-spinner-lines={12}
                >{decision === "add" ? "Add" : "Update"}</LaddaButton>
                <SortableList pressDelay={200}
                    items={fundingData}
                    deleteText={deleteText}
                    deleteImage={deleteImage}
                    editClick={onEditClick}
                    editClickText={onEditClickText}
                    // onSortEnd={this.onSortEnd}
                    // deleteSolutionSummaryDescData={(i) => this.deleteSolutionSummaryDescData(i)}
                    // editClick={(value) => this.onEditClick(value)}
                    // editClickText={(value) => this.onEditClickText(value)}
                    // addTextHandler={(id) => this.addTextHandler(id)}
                    // addImageHandler={(id) => this.addImageHandler(id)}
                    // onDragEndHandler={(result) => this.onDragEnd(result)}
                    // updateHeaderText={(value) => this.onUpdateHeaderText(value)}
                    // deleteHeader={(data) => this.deleteHeader(data)}
                    {...props}
                />
            </div>

            <Modal
                id='addimage'
                aria-labelledby="ModalHeader"
                show={show}
            >
                <Modal.Header>
                    <Modal.Title id='ModalHeader'>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {imagePop ? <div className="solutionSummaryDesc">
                        <div className="solutionSummaryDesc">
                            <form className="form-horizontal">
                                <div className="form-group picture">
                                    <label className="col-md-3 control-label company-label">Image:</label>
                                    <div className='col-md-8'>
                                        <div className='upload-solutionSummaryDesc-pic img-box'>
                                            {isEdit ? <div className="solutionSummaryDesc-pic-box">
                                                <img id='preview' className="profile-pic" src={imageuploading ? `${images.path.loader}` : `${common.path.base_url}/getFileNew?fileId=${picture}`} alt='' />
                                                <div>
                                                    <div className="p-image1">
                                                        <i onClick={() => document.querySelector(`#chooseFileUpdate`).click()} className="fa fa-pencil edit-button"></i>
                                                        <FileUploadBlob
                                                            id={`chooseFileUpdate`}
                                                            getFile={(file) => { setChoosenFile(file); uploadLogo(file) }}
                                                            acceptType={`image/*`}
                                                        />
                                                    </div>
                                                </div>
                                            </div> : <div className="solutionSummaryDesc-pic-box">
                                                <img id='preview' className={`profile-pic ${isuploaded ? '' : 'hide-icon'}`} src={imageuploading ? `${images.path.loader}` : `${common.path.base_url}/getFileNew?fileId=${picture}`} alt='' />
                                                {choosenFile !== null ?
                                                    '' : <div>
                                                        <div className="p-image">
                                                            <i onClick={() => document.querySelector(`#chooseFile${1}`).click()} className="fa fa-plus-circle upload-button"></i>
                                                            <FileUploadBlob
                                                                id={`chooseFile${1}`}
                                                                getFile={(file) => { setChoosenFile(file); uploadLogo(file) }}
                                                                acceptType={`image/*`}
                                                            />
                                                        </div>
                                                        <div className='logo-name'>Image</div>
                                                    </div>}
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> : textPop ? <Editor
                        placeholder={'Enter Text...'}
                        {...props}
                        editorHtml={text}
                        handleChange={(editorHtml) => editorHandleChange(editorHtml)}
                    /> :
                        ""
                    }
                </Modal.Body>
                <Modal.Footer>
                    {imagePop ?
                        <LaddaButton
                            id='lada-btn'
                            loading={imageloading}
                            data-color="#eee"
                            data-size={XS}
                            onClick={() => updateTable()}
                            className={isEdit ? imageuploading ? 'disable' : '' : (choosenFile === null || imageuploading) ? 'disable' : ''}
                            data-style={EXPAND_RIGHT}
                            data-spinner-size={30}
                            data-spinner-color="#ddd"
                            data-spinner-lines={12}
                        >{modalButtonTitle}</LaddaButton> :
                        textPop ?
                            <LaddaButton
                                id='lada-btn'
                                loading={imageloading}
                                data-color="#eee"
                                data-size={XS}
                                onClick={() => updateTable()}
                                className={isEdit ? !_.isEmpty(text) ? '' : 'disable' : !_.isEmpty(text) ? '' : 'disable'}
                                data-style={EXPAND_RIGHT}
                                data-spinner-size={30}
                                data-spinner-color="#ddd"
                                data-spinner-lines={12}
                            >{modalButtonTitle}</LaddaButton>
                            : ""
                        // <LaddaButton
                        //     id='lada-btn'
                        //     loading={imageloading}
                        //     data-color="#eee"
                        //     data-size={XS}
                        //     onClick={() => this.addHeader()}
                        //     className={isEdit ? !_.isEmpty(heading) ? '' : 'disable' : !_.isEmpty(heading) ? '' : 'disable'}
                        //     data-style={EXPAND_RIGHT}
                        //     data-spinner-size={30}
                        //     data-spinner-color="#ddd"
                        //     data-spinner-lines={12}
                        // >{modalButtonTitle}</LaddaButton>
                    }
                    <input type="button" id='company-cancel' className="btn btn-default" data-dismiss="modal" value="CANCEL" onClick={() => resetFields()} />
                </Modal.Footer>
            </Modal>
        </div>
    );

})

// constructing rows 
function DefinedRows({key, data, callback, cols, dataIndex }) {
    // const mainRows = data.map((actual_row, index) =>
    return   <tr key={key} className={data.hasOwnProperty('_id') ? 'disable-raw ' : ''}>
            <ConstructedCols rowData={data} dataIndex={dataIndex} onChangeFunc={callback} col={cols}/>
        </tr>
    // );

    // return (
    //     <>
    //         {mainRows}
    //     </>
    // );
}

// constructing columns of the particular row
function ConstructedCols({ rowData, onChangeFunc, col, dataIndex }) {
    const rowsCols = Object.keys(rowData).map((key, index) => {
        let tempValue = index + 1;
        var tempPlaceholder = "";

        switch (tempValue) {
            case 1: tempPlaceholder = col.col1;
                break;
            case 2: tempPlaceholder = col.col2;
                break;
            case 3: tempPlaceholder = col.col3;
                break;
            case 4: tempPlaceholder = col.col4;
                break;
            case 5: tempPlaceholder = col.col5;
                break;
            case 6: tempPlaceholder = col.col6;
                break;
            default: tempPlaceholder = "values";
                break;
        }

        return (
            key !== "_id" && (
                <td key={key + index} className={index}>
                    <input type="text" name={key} placeholder={tempPlaceholder !== "" ? tempPlaceholder : "Values"} value={rowData[key]} onChange={(e) => onChangeFunc(rowData, key, dataIndex, e)} />
                </td>
            ) 
        );
        
    });

    return (
        <>
            {rowsCols}
        </>
    )
}

// export default Profile;
export default withRouter(FundingDesc);
const path = {
  avatar: require("./images/sample-avatar.jpeg"),
  // productLogo: require("./images/logo.png"),
  productLogo: require("./images/logo-syndicate-new1.svg"),
  headBbar: require("./images/head_bar.svg"),
  tsgAdmin: require("./images/tsg_admin.svg"),
  blank_profile: require("./images/shape-32.svg"),
  pdf_file: require("./images/pdf.png"),
  // cash: require('./images/cash.png')
  login: require("./images/layer-3.png"),
  id: "",

  //Icons
  addIcon: require("./images/icons/plus-icon.svg"),
  editICon: require("./images/icons/edit.svg"),
  deleteIcon: require("./images/icons/trashCan-icon.svg"),
  searchIcon: require("./images/icons/search-icon.svg"),
  downArrowIcon: require("./images/icons/downArrow-icon.svg"),
  kebabMenuIcon: require("./images/icons/kebab-menu-icon.svg"),
  loader: require("./images/circular-loader.svg"),
  complete: require("./images/Ellipse-69.svg"),
  folder: require("./images/folder.svg"),
  mail: require("./images/mail.svg"),
  mailopen: require("./images/mail-open.svg"),
  file: require("./images/file.png"),
  iirDocIcon: require("./images/icons/iir-doc-icon.png"),
  googleDocs: require("./images/icons/google-docs.svg"),
  question: require("./images/icons/question.svg"),
  editChecklist: require("./images/icons/edit-checklist.svg"),
  deleteChecklist: require("./images/icons/delete-checklist.svg"),
  csvFile: require("./images/icons/csv-file-icon.svg"),
  userIcon: require("./images/icons/user-icon.svg"),
  upArrow: require("./images/up-arrow.png"),
  defaultProIcon: require("./images/profile-icon.png"),
  yearInReviewMetaTag: require("./images/assets/images/year-in-review-meta.png"),
  bell: require("./images/assets/images/bell.svg"),
  bellReadAll: require("./images/assets/images/bell-read-all.svg"),
  printerIcon: require("./images/assets/images/printer.svg"),
  writing_icon: require("./images/icons/signature.png"),

  dollarIcon: require("./images/assets/images/dollar.png"),
  ipoStatusIcon: require("./images/assets/images/ipo_status.png"),
  fundingRoundDate: require("./images/assets/images/funding_round_date.png"),
  productArchitecture: require("./images/assets/images/product-architecture.png"),
  differentiation: require("./images/assets/images/differentiation.png"),
  saphare: require("./images/assets/images/saphare.png"),
  gc: require("./images/assets/images/gc.png"),
  kholsa: require("./images/assets/images/kholsa.png"),
  lightSpeed: require("./images/assets/images/light-speed.png"),
  fundingInstructions: require("./images/assets/images/funding-instructions.png"),
  valuation: require("./images/assets/images/valuation.png"),
  revenue: require("./images/assets/images/revenue.png"),
  arp: require("./images/assets/images/arp.png"),

  walmart: require("./images/assets/images/walmart.png"),
  sag: require("./images/assets/images/sag.png"),
  hulu: require("./images/assets/images/hulu.png"),
  openTable: require("./images/assets/images/open-table.png"),
  gat: require("./images/assets/images/gat.png"),
  exon: require("./images/assets/images/exon.png"),
  suncrop: require("./images/assets/images/suncrop.png"),
  cisco: require("./images/assets/images/cisco.png"),
  sterling: require("./images/assets/images/sterling.png"),
  siemens: require("./images/assets/images/siemens.png"),
  bt: require("./images/assets/images/bt.png"),
  marketopertunity: require("./images/assets/images/market-opertunity.png"),
  upload: require("./images/assets/images/upload.svg"),
  webinarInvite: require("./images/webinar_invite.png"),
  placeholder: require("./images/placeholderPipeline.jpg"),
  webinar_link: require("./images/assets/images/webinarlink.svg"),
  view_wallet: require("./images/icons/wallet.svg"),
  view_w9: require("./images/icons/w9_doc.svg"),
  view_qsa: require("./images/icons/qsa_doc.svg"),
};
export default {
    path
}


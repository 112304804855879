import axios from 'axios';
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import swal from 'sweetalert';
import common from '../../common';
import UserCheckModal from '../../containers/Profle/UserCheckModal';
import downarrow from '../../images/icons/downarrow.svg';
import { path } from '../../InvestorDashboard/imagesPath';
import LoadingSpinner from '../Loader';
import './ContactList.scss';

class ContactDetailsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: null,
            title: null,
            companyName: null,
            emailId: null,
            mobileNo: null,
            officeMobileNo: null,
            location: null,
            id: null,
            canResend: null,
            roleCode: null,
            contactData: [],
            address1: null,
            address2: null,
            city: null,
            state: null,
            country: null,
            zipcode: null,
            notes: null,
            arrowType: {
                'companyName': 'menu-icon',
                amount: 'menu-icon',
                investmentName: 'menu-icon',
                fundedDate: 'menu-icon',
                stage: 'menu-icon',
                returnAmount: 'menu-icon',
                profit: 'menu-icon'
            },
            referredByTags: [],
            Tags: [],
            comeFlag: null,
            userInvestmentHistory: [],
            notesCheck: false,
        }
    }

    componentWillReceiveProps(nextProps) {
        nextProps.onRef && nextProps.onRef(this);
    }

    resetData() {
        this.setState({
            userName: null,
            title: null,
            companyName: null,
            emailId: null,
            mobileNo: null,
            officeMobileNo: null,
            location: null,
            id: null,
            canResend: null,
            roleCode: null,
            address1: null,
            address2: null,
            city: null,
            state: null,
            country: null,
            zipcode: null,
            notes: null,
            comeFlag: null,
            userInvestmentHistory: [],
        });

    }
    setContactDetailsData(userID, comeFrom) {
        this.setState({ comeFlag: comeFrom });
        this.getUserInvestmentHistory(false, this.mapTags(), '', userID);
        this.getUserData(userID);
        // let referredByTags = [];
        // let Tags = [];
        // if (data.referralsInvestmentIds && data.referralsInvestmentIds.length > 0) {
        //     referredByTags = data.referralsInvestmentIds.map(referral => {
        //         return { id: referral._id, name: referral.name }
        //     });
        // }
        // if (data.investorCategories && data.investorCategories.length > 0) {
        //     Tags = data.investorCategories.map(category => {
        //         return { id: category, name: category }
        //     });
        // }
        // this.setState({
        //     id: data._id,
        //     userName: data.name,
        //     title: data.title,
        //     companyName: data.companyName,
        //     emailId: data.emailAddress,
        //     mobileNo: data.mobile,
        //     officeMobileNo: data.office,
        //     location: data.address ? data.address + ',' : '' + data.address2 ? data.address2 + ',' : '' + data.city ? data.city + ',' : '' + data.state ? data.state + ',' : '' + data.country ? data.country + ',' : '' + data.zipcode ? data.zipcode : '',
        //     canResend: data.invitationAccepted,
        //     // canResend: data.invitationAccepted != "1" ? true : false,
        //     roleCode: data.role,
        //     contactData: data,
        //     address1: data.address ? data.address : '',
        //     address2: data.address2 ? data.address2 : '',
        //     city: data.city ? data.city : '',
        //     state: data.state ? data.state : '',
        //     country: data.country ? data.country : '',
        //     zipcode: data.zipcode ? data.zipcode : '',
        //     notes: data.notes ? data.notes : '',
        // });

    }

    resendInvite() {
        const temp = this;
        this.setState({ loadinglada: true });
        let tokenpass = window.localStorage.getItem('token');

        axios.put(`${common.path.base_url}/resendInvite`, { id: this.state.id }, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                document.getElementById('contactDetailsModal').click();
                // this.props.addInviteSuccess();

                swal({
                    title: "Email Sent",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(function () {
                        temp.setState({ loadinglada: false });
                        window.location.reload();
                        document.getElementById('contactDetailsModal').click();
                    });
            })
            .catch((error) => {
                temp.setState({ loadinglada: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.response,
                        icon: "error",
                        button: "Ok!",
                    });
                } else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                } else {
                    swal({
                        title: "Error!",
                        text: error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    discardContact() {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this contact",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                let newList = this.state.contactsUI;
                let userId = null;
                // const deleteContact = newList[index];
                const token = window.localStorage.getItem('token');
                if (willDelete) {
                    //@TODO CALL DELETE CONTACT API HERE WITH NECESSARY CHECKS
                    this.setState({ loading: true })
                    axios.delete(`${common.path.base_url}/deleteUser`, { headers: { "Authorization": token }, data: { id: this.state.id } }
                    )
                        //@TODO CALL ON SUCCESS OF API
                        .then((response) => {
                            this.setState({ loading: false })
                            swal({
                                title: "Success!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            })
                                .then(() => {
                                    document.getElementById('contactDetailsModal').click();
                                    // this.props.addInviteSuccess();
                                    window.location.reload();
                                });
                            this.setState({ contactsUI: newList });

                        })
                        .catch((error) => {
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else if (error.response && error.response.data && error.response.data.code === 204) {
                                window.localStorage.clear();
                                window.location.href = '/';
                            }
                            swal({
                                title: "Error!",
                                text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                icon: "error",
                                button: "Ok!",
                            });
                        });
                }
                else {
                    swal({ text: `This contact is safe!`, icon: 'info' });
                }
            });
    }

    editContact() {
        document.getElementById('contactDetailsModal').click();
        this.props.history.push(`/profile?id=${this.state.contactData._id}`);
    }

    toggleArrow(fieldType) {

        let arrowType = this.state.arrowType;
        let $sort = {};
        if (arrowType[fieldType] === 'menu-icon') {
            arrowType[fieldType] = 'up-arrow';
            $sort[fieldType] = -1;
        } else {
            arrowType[fieldType] = 'menu-icon';
            $sort[fieldType] = 1;
        }
        this.getUserInvestmentHistory(false, this.mapTags(), $sort)
        this.setState({ arrowType });
    }

    getUserInvestmentHistory(isFromLoadMore, tags, $sort, userId) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const currentPage = this.state.exitedUsersPagination && isFromLoadMore
            ? this.state.exitedUsersPagination.currentPage + 1
            : 1;
        const data = {
            page: currentPage,
            limit: common.paginationLimit,
            $sort: $sort ? $sort : null,
        }
        if (tags && tags.length > 0) {
            data.tags = tags;
        }
        axios.post(`${common.path.base_url}/getInvestorInvestmentHistory/${userId}`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                temp.setState({ totalInvestment: response.data.totalInvestments });
                temp.setState({ totalInvestmentAmount: response.data.totalInvestmentsAmount });
                temp.setState({ totalReturnAmount: response.data.totalReturnAmount });
                temp.setState({ totalProfit: response.data.totalProfit });
                if (response.data.data && response.data.data.length > 0) {
                    temp.setState({ userInvestmentHistory: response.data.data });
                } else {
                    temp.setState({ userInvestmentHistory: [] });
                }
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                } else {
                    swal({
                        title: "Error!",
                        text: error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loading: false });
            });
    }
    mapDateFormat(mongoDate) {
        if (!mongoDate) return 'N/A';
        const monthNames = [
            "01", "02", "03",
            "04", "05", "06", "07",
            "08", "09", "10",
            "11", "12"
        ];
        let date = new Date(mongoDate);
        let day = date.getDate();
        let monthIndex = date.getMonth();
        let year = date.getFullYear();
        return monthNames[monthIndex] + '/' + day + '/' + year;

    }
    mapTags(tags) {
        if (!tags) {
            tags = this.state.searchInputTags;
        }
        let tagIds = []
        if (tags && tags.length > 0) {
            tagIds = tags.map(tag => tag.name)
        }
        return tagIds;
    }

    getUserData(id) {

        let temp = this;
        temp.setState({ loading: true, notesCheck: false });
        const userId = window.localStorage.getItem('id');
        axios.get(`${common.path.base_url}/getUserById?_id=${id}&user=${userId}`)
            .then((response) => {
                temp.setState({ loading: false });
                let data = response.data.data;
                let referredByTags = [];
                let Tags = [];

                if (data.referralsInvestmentIds && data.referralsInvestmentIds.length > 0) {
                    referredByTags = data.referralsInvestmentIds.map(referral => {
                        return { id: referral._id, name: referral.name }
                    });
                }
                if (data.investorCategories && data.investorCategories.length > 0) {
                    Tags = data.investorCategories.map(category => {
                        return { id: category, name: category }
                    });
                }
                // this.setState({ referredByTags: referredByTags });
                // this.setState({ Tags: Tags });
                this.setState({
                    id: data._id,
                    userName: data.name,
                    title: data.title,
                    companyName: data.companyName ? data.companyName : data.investorCompanyName ? data.investorCompanyName : '',
                    emailId: data.emailAddress,
                    mobileNo: data.mobile,
                    officeMobileNo: data.office,
                    location: data.address ? data.address + ',' : '' + data.address2 ? data.address2 + ',' : '' + data.city ? data.city + ',' : '' + data.state ? data.state + ',' : '' + data.country ? data.country + ',' : '' + data.zipcode ? data.zipcode : '',
                    canResend: data.invitationAccepted,
                    // canResend: data.invitationAccepted != "1" ? true : false,
                    roleCode: data.role,
                    contactData: data,
                    address1: data.address ? data.address : '',
                    address2: data.address2 ? data.address2 : '',
                    city: data.city ? data.city : '',
                    state: data.state ? data.state : '',
                    country: data.country ? data.country : '',
                    zipcode: data.zipcode ? data.zipcode : '',
                    notes: data.notes ? data.notes : '',
                    referredByTags,
                    Tags,
                });

            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else if (error.response) {
                    swal({
                        title: "Error! #7895454",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                } else {
                    swal({
                        title: "Error! #4014587",
                        text: error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }
    getTitle(title, companyName) {
        if (title && companyName) {
            return <label className="title-label">{title}&nbsp;<span>at&nbsp;</span>{companyName}</label>;
        } else if (title) {
            return <label className="title-label">{title}</label>;
        } else if (companyName) {
            return <label className="title-label">{companyName}</label>;
        }
    }

    sendNoteOTPModal() {

        const userRoleCode = window.localStorage.getItem('roleCode');

        if (userRoleCode === '001' || userRoleCode === '002') {
            let temp = this;

            let tokenpass = window.localStorage.getItem('token');
            //temp.setState({ loading: true });

            let id = window.localStorage.getItem('id');
            const param = {
                id: id,
                userId: this.userId ? this.userId : id
            }

            axios.post(`${common.path.base_url}/sendNotesOtp/`, param, {
                headers: {
                    "Authorization": tokenpass
                }
            }).then(function (response) {
                // const investorReferralList = response.data.data.map(opt => ({ label: opt.name, value: opt._id, user: opt }));
                // temp.setState({ investorReferralList });

            }).catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    //window.localStorage.clear();
                    //window.location.href = '/';
                    return;

                } else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });

                } else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });

            document.getElementById('sendOTPModal-btn').click();

        } else {
            swal({
                //title: "You are not authorized to access this Note !",
                text: "You are not authorized to access this Note",
                icon: "warning",
                button: "Ok!",
            });
        }
    }

    checkNotes(data) {
        this.setState({ 'notes': data, 'notesCheck': true });
    }

    render() {
        let currentUser = window.localStorage.getItem('id');
        let currentUserRole = window.localStorage.getItem('roleCode');
        return (
            <>
                <UserCheckModal
                    mobile={this.state.mobileNo}
                    userId={this.state.id}
                    checkNotes={(notes) => this.checkNotes(notes)}
                    {...this.props}
                />

                <button id="sendOTPModal-btn" type="button" className="tsg-btn " data-toggle="modal" data-target="#sendOTPModal" style={{ "display": "none" }}></button>

                <div id="contactDetailsModal" className="modal fade">

                    {this.state.loading ? <LoadingSpinner /> : ""}
                    <div className="modal-dialog custom-modal">
                        <div className="modal-content invite-contact">
                            <div className="modal-header contact-modal-header remove-padding added-padding">
                                <div className="contact-model-img-box">
                                    <img src={`${common.path.base_url}/getImage?id=${this.state.id}`} alt="Profile" />
                                </div>
                                <div className="contact-detail-box">
                                    <h2>{this.state.userName}</h2>
                                    <label className="title-label">{this.getTitle(this.state.title, this.state.companyName)}</label>
                                </div>
                                {/* <img src={path.close} className="contact-close-img" type="button" data-dismiss="modal" id="contact-btn-cancel" /> */}
                            </div>
                            <div className="icons-box-main">
                                {this.state.comeFlag != "fromInvestmentDetail" &&
                                    <>
                                        {this.state.canResend === undefined && this.state.emailId &&
                                            <div className="contact-icons-box tooltipcover-send" onClick={() => {
                                                setTimeout(() => {
                                                    this.resendInvite()
                                                }, 1);
                                            }}>
                                                <span className="tooltiptext">Send Invitation</span>
                                                <img src={path.send_round} alt='' className="white-img" />
                                                <img src={path.send_r} alt='' className="filled-img" />
                                            </div>}
                                        {this.state.canResend === '0' && this.state.emailId &&
                                            <div className="contact-icons-box tooltipcover-resend" onClick={() => {
                                                setTimeout(() => {
                                                    this.resendInvite()
                                                }, 1);
                                            }}>
                                                <span className="tooltiptext">Re-Send Invitation</span>
                                                <img src={path.resend_r} alt='' className="white-img" />
                                                <img src={path.send_r} alt='' className="filled-img" />
                                            </div>}
                                    </>}

                                {this.state.roleCode && this.state.roleCode === '005' && this.state.canResend === '1' &&
                                    <a href={`/investor-dashboard?investorId=${this.state.id}`}>
                                        <div className="contact-icons-box tooltipcover-dashboard">
                                            <span className="tooltiptext">Dashboard</span>
                                            <img src={path.dashboard_round} alt='' className="white-img" />
                                            <img src={path.dashboard_r} alt='' className="filled-img" />
                                        </div>
                                    </a>}

                                {this.state.roleCode && (this.state.roleCode === '003' || this.state.roleCode === '004') && this.state.canResend === '1' &&
                                    <a href={`/startup-dashboard/${this.state.id}`}>
                                        <div className="contact-icons-box tooltipcover-dashboard">
                                            <span className="tooltiptext">Dashboard</span>
                                            <img src={path.dashboard_round} alt='' className="white-img" />
                                            <img src={path.dashboard_r} alt='' className="filled-img" />
                                        </div>
                                    </a>}

                                {currentUser !== this.state.id && (currentUserRole === '001' || currentUserRole === '002') &&
                                    <div className="contact-icons-box tooltipcover-editprofile" onClick={() => { this.editContact() }}>
                                        <span className="tooltiptext">Edit</span>
                                        <img src={path.edit_round} alt='' className="white-img" />
                                        <img src={path.editProfile_r} alt='' className="filled-img" />
                                    </div>}

                                {currentUser !== this.state.id && (currentUserRole === '001' || currentUserRole === '002') &&
                                    <div className="contact-icons-box tooltipcover-deleteprofile" onClick={() => { this.discardContact() }}>
                                        <span className="tooltiptext">Delete</span>
                                        <img src={path.delete_round} alt='' className="white-img" />
                                        <img src={path.delete_r} alt='' className="filled-img" />
                                    </div>}
                            </div>
                            <div className="modal-body remove-padding">

                                <div className="row bottom-padding">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div className="contact-box">
                                            <p>Email Address</p>
                                            <label>{this.state.emailId ? this.state.emailId : '-'}</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div className="contact-box">
                                            <p>Phone Numbers</p>
                                            {(this.state.mobileNo || this.state.officeMobileNo) ?
                                                <>
                                                    <label>M: {this.state.mobileNo ? this.state.mobileNo : '-'} | </label> <label>O: {this.state.officeMobileNo ? this.state.officeMobileNo : '-'}</label>
                                                </>
                                                : <label>-</label>}

                                        </div>
                                    </div>
                                    {/* <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="contact-box">
                                            <p>Office</p>
                                            <label>{this.state.officeMobileNo ? this.state.officeMobileNo : '-'}</label>
                                        </div>
                                    </div> */}
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="contact-box">
                                            <p>Location</p>
                                            {/* <label>{this.state.location ? this.state.location : '-'}</label> */}
                                            {this.state.address1 || this.state.address2 || this.state.city || this.state.state || this.state.country || this.state.zipcode ?
                                                <label>{this.state.address1 ? this.state.address1 + ',' : ''}{this.state.address2 ? this.state.address2 + ',' : ''}{this.state.city ? this.state.city + ',' : ''}{this.state.state ? this.state.state + ',' : ''}{this.state.country ? this.state.country + ',' : ''}{this.state.zipcode ? this.state.zipcode + '.' : ''}</label>
                                                : <label>-</label>}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="contact-box investor-tags-list">
                                            <p>Tags</p>
                                            {/* <label>{this.state.location ? this.state.location : '-'}</label> */}
                                            {this.state.Tags.length > 0 ?
                                                <div>
                                                    {
                                                        this.state.Tags.length > 0 &&
                                                        this.state.Tags.map(tag => {
                                                            return (<span key={tag.id} className="tag label label-info">
                                                                <span>{tag.name}</span>
                                                                {/* <a><i className="remove glyphicon glyphicon-remove-sign glyphicon-white" onClick={() => this.removeReferralTag(tag)}></i></a> */}
                                                            </span>)
                                                        })
                                                    }
                                                </div>
                                                : <label>-</label>}

                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="contact-box investor-tags-list">
                                            <p>Referred By Startup</p>
                                            {/* <label>{this.state.officeMobileNo ? this.state.officeMobileNo : '-'}</label> */}
                                            {this.state.referredByTags.length > 0 ?
                                                <div>
                                                    {
                                                        this.state.referredByTags.length > 0 &&
                                                        this.state.referredByTags.map(tag => {
                                                            return (<span key={tag.id} className="tag label label-info">
                                                                <span>{tag.name}</span>
                                                                {/* <a><i className="remove glyphicon glyphicon-remove-sign glyphicon-white" onClick={() => this.removeReferralTag(tag)}></i></a> */}
                                                            </span>)
                                                        })
                                                    }
                                                </div>
                                                : <label>-</label>}

                                        </div>
                                    </div>
                                    {(currentUserRole === '001' || currentUserRole === '002') ?
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="contact-box">
                                                <p>
                                                    <span style={{ "float": "left" }}>Notes</span>
                                                    {/* <TButton onClick={() => { this.sendNoteOTPModal() }} id='contactDetails-btn' text={`View`} /> */}
                                                    {/* {(!this.state.notesCheck) &&
                                                    <button type="button" className="tsg-btn" onClick={() => { this.sendNoteOTPModal() }} style={{ "marginTop":"-5px", "marginLeft":"6px"  }}><span>View</span></button>
                                                } */}
                                                </p>
                                                <p style={{ 'wordBreak': 'break-all', 'color': '#000', 'display': 'inline-block', 'width': '100%' }} ><div className='contactNotesDetail' style={{ "marginTop": "5px", }} dangerouslySetInnerHTML={{ __html: this.state.notes ? this.state.notes : '-' }} ></div></p>
                                            </div>
                                        </div> : ""}
                                </div>

                                {(currentUser != this.state.id) && (this.state.roleCode === '005') &&
                                    <>
                                        <hr />
                                        <div>
                                            <h4>Investment History</h4>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="detail-card">
                                                        <label>Total Investments</label>
                                                        <h4>{this.state.totalInvestment ? this.state.totalInvestment : '-'}</h4>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="detail-card">
                                                        <label>Total Investment Amount</label>
                                                        {this.state.totalInvestmentAmount ?
                                                            <NumberFormat
                                                                className="number-text"
                                                                value={this.state.totalInvestmentAmount}
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                                fixedDecimalScale
                                                                decimalScale={2}
                                                                prefix={'$'}
                                                            /> : <h4>-</h4>}
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="detail-card">
                                                        <label>Total Return</label>
                                                        {this.state.totalReturnAmount ?
                                                            <NumberFormat
                                                                className="number-text"
                                                                value={this.state.totalReturnAmount}
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                                fixedDecimalScale
                                                                decimalScale={2}
                                                                prefix={'$'}
                                                            /> : <h4>-</h4>}
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="detail-card">
                                                        <label>Total Profit</label>
                                                        <br/>
                                                        {this.state.totalProfit ?
                                                            <NumberFormat
                                                                className="number-text"
                                                                value={this.state.totalProfit}
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                                fixedDecimalScale
                                                                decimalScale={2}
                                                                suffix={"%"}
                                                            /> : <h4>-</h4>}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* className={`arrow ${this.state.arrowType['user.name']}`}  */}
                                            <div className="custom-table-view table-responsive">
                                                <table className="table table-custom sortable table-border">
                                                    <thead>
                                                        <tr>
                                                            <th>INVESTMENT NAME <img src={downarrow} className={`menu-icon  ${this.state.arrowType['investmentName']}`} onClick={() => this.toggleArrow('investmentName')}></img></th>
                                                            <th>STARTUP NAME <img src={downarrow} className={`menu-icon  ${this.state.arrowType['companyName']}`} onClick={() => this.toggleArrow('companyName')} ></img></th>
                                                            <th>INVESTMENT AMOUNT <img src={downarrow} className={`menu-icon  ${this.state.arrowType['amount']}`} onClick={() => this.toggleArrow('amount')} ></img></th>
                                                            <th>DATE <img src={downarrow} className={`menu-icon  ${this.state.arrowType['fundedDate']}`} onClick={() => this.toggleArrow('fundedDate')} ></img></th>
                                                            <th>STAGE</th>
                                                            <th>RETURN AMOUNT<img src={downarrow} className="menu-icon" ></img></th>
                                                            <th>PROFIT % <img src={downarrow} className="menu-icon" ></img></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.userInvestmentHistory && this.state.userInvestmentHistory.length > 0 &&
                                                            this.state.userInvestmentHistory.map(user => {
                                                                return (
                                                                    <tr key={user._id}>
                                                                        <td className='grey-text'>{user.investmentName}</td>
                                                                        <td className='grey-text'>{user.companyName}</td>
                                                                        <td className='grey-text'>
                                                                            {user.amount ?
                                                                                <NumberFormat
                                                                                    className="number-text"
                                                                                    value={user.amount}
                                                                                    displayType={'text'}
                                                                                    thousandSeparator={true}
                                                                                    fixedDecimalScale
                                                                                    decimalScale={2}
                                                                                    prefix={'$'}
                                                                                /> : ""}
                                                                        </td>
                                                                        <td className='grey-text'> {this.mapDateFormat(user.fundedDate)}</td>
                                                                        <td className='grey-text'>{user.stage}</td>
                                                                        <td className='grey-text'>
                                                                            {user.returnAmount ?
                                                                                <NumberFormat
                                                                                    className="number-text"
                                                                                    value={user.returnAmount}
                                                                                    displayType={'text'}
                                                                                    thousandSeparator={true}
                                                                                    fixedDecimalScale
                                                                                    decimalScale={2}
                                                                                    prefix={'$'}
                                                                                /> : "-"}
                                                                        </td>
                                                                        <td className='green-text'>
                                                                            {user.profit ?
                                                                                <NumberFormat
                                                                                    className="number-text"
                                                                                    value={user.profit}
                                                                                    displayType={'text'}
                                                                                    thousandSeparator={true}
                                                                                    fixedDecimalScale
                                                                                    decimalScale={2}
                                                                                    suffix={"%"}
                                                                                /> : "-"}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            this.state.userInvestmentHistory && this.state.userInvestmentHistory.length === 0 &&
                                                            <tr>
                                                                <td> No investors history found!</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </>
                                }
                                {/* <div className="row">
                                    {this.state.canResend &&
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <div className="contact-box" onClick={() => {
                                                setTimeout(() => {
                                                    this.resendInvite()
                                                }, 1);
                                            }}>
                                                <img src={path.send_r} alt='' />
                                                <label>Send Invitation</label>
                                            </div>
                                        </div>}

                                    {this.state.roleCode && this.state.roleCode === '005' &&
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <a href={`/investor-dashboard?investorId=${this.state.id}`}>
                                                <div className="contact-box">
                                                    <img src={path.dashboard_r} alt='' />
                                                    <label>Investor Dashboard</label>
                                                </div>
                                            </a>
                                        </div>}

                                    {this.state.roleCode && (this.state.roleCode === '003' || this.state.roleCode === '004') &&
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <a href={`/startup-dashboard/${this.state.id}`}>
                                                <div className="contact-box">
                                                    <img src={path.dashboard_r} alt='' />
                                                    <label>Startup Dashboard</label>
                                                </div>
                                            </a>
                                        </div>}

                                    {currentUser !== this.state.id && (currentUserRole === '001' || currentUserRole === '002') &&
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <a href={`/viewProfile?id=${this.state.id}`}>
                                                <div className="contact-box">
                                                    <img src={path.profile_r} alt='' />
                                                    <label>View Profile</label>
                                                </div>
                                            </a>
                                        </div>}

                                    {currentUser !== this.state.id && (currentUserRole === '001' || currentUserRole === '002') &&
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <div className="contact-box" onClick={() => { this.editContact() }}>
                                                <img src={path.profile_r} alt='' />
                                                <label>Edit</label></div>
                                        </div>}


                                    {currentUser !== this.state.id && (currentUserRole === '001' || currentUserRole === '002') &&
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <div className="contact-box" onClick={() => { this.discardContact() }}>
                                                <img src={path.profile_r} alt='' />
                                                <label>Delete</label>
                                            </div>
                                        </div>}

                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default ContactDetailsModal;
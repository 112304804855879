import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import Intiate from '../components/InvestmentProcess/initiate.component';
import InvestorService from '../services/investor.service'
import LoadingSpinner from '../../components/Loader';
import { isLoading, isNotLoading } from '../../shared/actions/loadingSpinner';
import { setInvestmentProcessData, setInvestmentUserData } from '../actions/investmentProcessData';
import { setSettingData } from '../actions/settings'
import common from '../../common';



class InvestmentProcessContainer extends Component {
    componentDidMount() {
        const url = new URL(window.location.href);
        this.investmentId = url.searchParams.get('investmentId');
        this.userId = window.localStorage.getItem('id');
        this.status = url.searchParams.get('status');
        this.processId = url.searchParams.get('processId');
        this.envId = url.searchParams.get('env');
        this.type = url.searchParams.get('type');
        this.isResend = url.searchParams.get('isResend');
        this.isFund = url.searchParams.get('isFund');
        
        this.props.isLoading();
        if (this.processId && this.status === 'completed' && this.envId && this.type) {
            const data = {
                id: this.processId,
                type: this.type,
                envId: this.envId,
            }
            InvestorService.signInvestmentProcess(data)
                .then(() => {
                    return true;
                })
                .then(() => {
                    // return InvestorService.signInvestmentDoc(this.processId)
                    return true;
                })
                .then(() => {
                    if(this.isResend === 'true') {
                        window.location.href = `/investor-dashboard`;
                    } else {
                        window.location.href = `/investment-process?investmentId=${this.investmentId}`;
                    }
                })
                .catch((error) => {
                    this.props.isNotLoading();
                    if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        window.localStorage.clear();
                        window.location.href = '/';
                        return;
                    }
                    else {
                        swal({
                            title: "Error! #984SA",
                            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                    // // TODO: handle error
                    if (error.response && error.response.data && error.response.data.code && error.response.data.code === 204) {
                        window.localStorage.clear();
                        window.location.href = '/';
                    }
                })
        } else {
            
            this.investmentProcessHandler()
                .then(() => this.props.isNotLoading())
                .catch((error) => {
                    this.props.isNotLoading();
                    if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                        window.localStorage.clear();
                        window.location.href = '/';
                        return;
                    }
                    else {
                        swal({
                            title: "Error! #458SDE",
                            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                    // // TODO: handle error
                    if (error.response && error.response.data && error.response.data.code && error.response.data.code === 204) {
                        window.localStorage.clear();
                        window.location.href = '/';
                    }
                })
        }
    }

    investmentProcessHandler = () => {
        return new Promise((resolve, reject) => {
            InvestorService.getUserInvestmentProcess(this.investmentId)
                .then(response => {
                    if (response && response.data && response.data.data) {
                        
                        if (response.data.data && response.data.data.length === 0) {
                            window.location.href = '/investor-dashboard';
                        } else {
                            //console.log("---00000000sdsdsdsd",response.data.data);
                            this.props.setInvestmentProcessData(response.data.data);
                        }
                    }
                })
                .then(() => {
                    return InvestorService.getUserData(this.userId)
                })
                .then(resUser => {
                    if (resUser && resUser.data && resUser.data.data) {
                        this.props.setInvestmentUserData(resUser.data.data);
                    }
                    return InvestorService.getSettings()
                })
                .then((settings) => {
                    if (settings && settings.data && settings.data.data) {
                        this.props.setSettingData(settings.data.data);
                    }
                    resolve()
                })

                .catch((error) => {
                    reject(error);
                });
        });
    }
    render() {
        return (
            <>
                {
                    this.props.loadingSpinner ? <LoadingSpinner /> :
                        <>
                            <Intiate
                                {...this.props} />
                        </>
                }
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        loadingSpinner: state.loadingSpinner,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        isLoading: bindActionCreators(isLoading, dispatch),
        isNotLoading: bindActionCreators(isNotLoading, dispatch),
        setInvestmentProcessData: bindActionCreators(setInvestmentProcessData, dispatch),
        setInvestmentUserData: bindActionCreators(setInvestmentUserData, dispatch),
        setSettingData: bindActionCreators(setSettingData, dispatch),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestmentProcessContainer);